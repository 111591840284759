import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ItemLabels } from './item-labels';

export interface State extends EntityState<ItemLabels> {
  selected?: string;
  loading: boolean;
}

export const adapter = createEntityAdapter<ItemLabels>({});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
