<div>
  <ng-template [ngIf]="callback" [ngIfElse]="routeByLink">
    <ng-container [ngSwitch]="icon">
      <a (click)="checkLicense()" [matTooltip]="_showOnlyIcons? (title | translate) : ''"
         mat-list-item class="sidebar--link rippleCustom" href="#"
         (click)="$event.preventDefault(); callback()">
        <mat-icon mat-list-icon *ngSwitchCase="'fivef_desktop'" svgIcon="fivef_desktop"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'datev_data_service_logo'" svgIcon="datev_data_service_logo"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'elo_dms'" svgIcon="elo_dms"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'account_tree'" svgIcon="account_tree"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'labels'" svgIcon="labels"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'fibu_workflow'" svgIcon="fibu_workflow"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'templates'" svgIcon="templates"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'lohn'" svgIcon="lohn"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'process'" svgIcon="projectroom"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'projectroom'" svgIcon="process"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'milestone'" svgIcon="timeline"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'tasks'" svgIcon="tasks"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'sign'" svgIcon="sign"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'third_party'" svgIcon="third_party"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'kanban'" svgIcon="kanban"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'manage_accounts'" svgIcon="manage_accounts"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'insertChartOutlined'" svgIcon="insertChartOutlined"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'newspaper'" svgIcon="newspaper"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'article'" svgIcon="article"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'monitor_heart'" svgIcon="monitor_heart"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'policy'" svgIcon="policy"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'recycling'" svgIcon="recycling"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'gt'" svgIcon="gt"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchDefault>{{icon}}</mat-icon>
        <p style="font-weight: 400; white-space: normal" *ngIf="!_showOnlyIcons"
           class="sidebar--link--content" mat-line>
          <span [matBadge]="count" [matBadgeColor]="infoLevel" matBadgeOverlap="false">
            <span class="mat-badge-title">{{ title | translate }}</span>
          </span>
        </p>
      </a>
    </ng-container>
  </ng-template>

  <ng-template #routeByLink>
    <ng-container [ngSwitch]="icon">
      <a (click)="checkLicense()" [matTooltip]="_showOnlyIcons? (title | translate) : ''" mat-list-item
         class="sidebar--link rippleCustom"
         [routerLinkActiveOptions]="{ exact: false }" [routerLink]="path"
         routerLinkActive="dvtx-active-sidebar-link">
        <mat-icon mat-list-icon *ngSwitchCase="'fivef_desktop'" svgIcon="fivef_desktop"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'datev_data_service_logo'" svgIcon="datev_data_service_logo"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'elo_dms'" svgIcon="elo_dms"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'account_tree'" svgIcon="account_tree"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'labels'" svgIcon="labels"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'fibu_workflow'" svgIcon="fibu_workflow"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'templates'" svgIcon="templates"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'lohn'" svgIcon="lohn"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'process'" svgIcon="projectroom"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'projectroom'" svgIcon="process"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'milestone'" svgIcon="timeline"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'tasks'" svgIcon="tasks"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'sign'" svgIcon="sign"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'third_party'" svgIcon="third_party"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'kanban'" svgIcon="kanban"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'manage_accounts'" svgIcon="manage_accounts"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'insertChartOutlined'" svgIcon="insertChartOutlined"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'newspaper'" svgIcon="newspaper"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'article'" svgIcon="article"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'monitor_heart'" svgIcon="monitor_heart"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'policy'" svgIcon="policy"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'recycling'" svgIcon="recycling"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchCase="'gt'" svgIcon="gt"></mat-icon>
        <mat-icon mat-list-icon *ngSwitchDefault>{{icon}}</mat-icon>
        <!--
          <span *ngIf="icon === 'groups' || icon === 'settings'" class="five-f-new-feature-button-tag" matTooltip="{{'GENERAL.NEW_FEATURE' | translate}}">
            {{ 'GENERAL.NEW' | translate }}
          </span>
        -->

        <p style="font-weight: 400; white-space: normal"
           *ngIf="!_showOnlyIcons" class="sidebar--link--content" mat-line>
          <span [matBadge]="count" [matBadgeColor]="infoLevel" matBadgeOverlap="false">
            <span class="mat-badge-title">{{ title | translate }}</span>
          </span>
        </p>
      </a>
    </ng-container>
  </ng-template>
</div>
