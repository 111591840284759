
import {throwError as observableThrowError, of as observableOf, BehaviorSubject, Observable} from 'rxjs';

import {delay, catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {ResourceBuilder} from '../../../shared/modules/api/builders/resource.builder';
import {ApiErrorBuilder} from '../../../shared/modules/api/builders/api-error.builder';
import {environment} from 'environments/environment';
import {OrganizationMember} from '../../contacts/models/contact';
import {Employee} from '../../../shared/modules/api/models/employee';
import {EmployeeBuilder} from '../../../shared/modules/api/builders/employee.builder';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

@Injectable()
export class MemberService {
  employeeObservable: BehaviorSubject<Employee[]>;
  employees: Employee[];
  basePath;

  constructor(private _tokenService: HttpClient,
              private _http: HttpClient) {
    this.basePath = `${environment.contactApiBase}/api/v1/members`;
    this.employees = [];
    this.employeeObservable = new BehaviorSubject<Employee[]>(this.employees);
  }

  getAll() {
    return this._http.get(`${this.basePath}`).pipe(
      map((res: any) => ResourceBuilder),
      catchError(this.handleError),);
  }

  addEmployeeToOrga(emp: Employee): Observable<any> {
    return this._tokenService.post('organization/my/adduser', EmployeeBuilder.toRequest(emp)).pipe(
      map((response) => response),
      catchError(this.handleError),);
  }

  getOrgaMembers(): Observable<Employee[]> {
    return this._tokenService.get('organization/my/users').pipe(map((resp) => {
      return EmployeeBuilder.fromResponseList(resp)
    }),
      catchError(this.handleError),);
  }

  deleteOrgaMember(id: string): Observable<any> {
    return this._tokenService.delete('organization/my/users/' + id);
  }

  create(contact) {
    return this._http.post(`${this.basePath}`, ResourceBuilder.toRequest(contact)).pipe(
      map((res: any) => {
        return <OrganizationMember>ResourceBuilder.fromResponse(res);
      }),
      catchError(this.handleError),);
  }

  save() {
    const apiResponse = {
      success: true,
      message: 'Mitarbeiter erfolgreich gespeichert.'
    };
    return observableOf(apiResponse).pipe(delay(100));
  }

  private handleError(error: HttpErrorResponse) {
    console.log('ContactService#handleError', error);
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}
