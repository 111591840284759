import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ProcessTreeNode} from 'app/+store/process-tree/process-tree';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Subject, BehaviorSubject, of} from 'rxjs';
import {concatMap, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {ProcessArtifactSelectors} from 'app/+store/process-artifact';
import {ProcessTreeNodeType} from 'app/+store/process-tree/process-tree.interface';
import {Process} from 'app/+store/process/process';

@Component({
  selector: 'dvtx-document-structure',
  templateUrl: './document-structure.component.html',
  styleUrls: ['./document-structure.component.scss']
})
export class DocumentStructureComponent implements OnInit, OnDestroy {
  onDestroy = new Subject();

  _id: string;
  processesNodes$: BehaviorSubject<ProcessTreeNode[]> = new BehaviorSubject<ProcessTreeNode[]>([]);
  process$ = new BehaviorSubject<Process>(null);
  _process;
  @Output() onSelect = new EventEmitter();

  @Input() set process(process: Process) {
    if (process) {
      this._process = process;
      this.process$.next(process);
    }
  }

  constructor(private _store: Store<AppState>) {
    this.process$.pipe(
      distinctUntilChanged(),
      concatMap((process) => {
        if (process) {
          switch (process.processType) {
            case 'quickcollector':
              return this._store.select(ProcessArtifactSelectors.groupedDocumentsForCollecto(process.id));
            case 'project':
            case 'quickstart':
            case 'cac':
            case 'cac_request':
            case 'quickshare_v2':
              return this._store.select(ProcessArtifactSelectors.groupedDocumentsBySubProject(process.id));
            default:
              return this._store.select(ProcessArtifactSelectors.groupedDocumentsBySubProject(process.id));
          }
        } else {
          return of([])
        }
      }),
      map(nodes => {
        const root = new ProcessTreeNode(
          '00000000-0000-0000-0000-000000000000',
          ProcessTreeNodeType.Root,
          null,
          'PROJECT_ROOM.ALL_DOCUMENTS',
          new Date()
        );
        root.icon = 'insert_drive_file';

        const processNodes = [];
        nodes.forEach(node => {
          const _node = new ProcessTreeNode(
            node.id,
            null,
            root.id,
            node.title,
            new Date()
          );
          switch (node.type) {
            case 'collector':
            case 'quickcollector':
              _node.type = ProcessTreeNodeType.CollectoElement;
              _node.icon = 'playlist_add_check';
              break;
            case 'project':
              _node.type = ProcessTreeNodeType.Process;
              _node.icon = 'process';
              _node.isSvgIcon = true;
              _node.isProject = true;
              break;
            case 'cac':
            case 'cac_request':
              _node.type = ProcessTreeNodeType.Process;
              _node.icon = 'exchange_circle';
              _node.isSvgIcon = true;
              _node.isCac = true;
              break;
            case 'quickshare_v2':
            case 'quickshare':
              _node.type = ProcessTreeNodeType.Process;
              _node.icon = 'share';
              _node.isSvgIcon = false;
              _node.isCac = false;
              break;
            case 'cav':
            case 'audit_contact_imports':
            case 'contact_verification':
            case 'audit_contact_verifications':
              _node.type = ProcessTreeNodeType.Process;
              _node.icon = 'person_verif';
              _node.isSvgIcon = true;
              _node.isCac = false;
              break;
            case 'signature':
              _node.type = ProcessTreeNodeType.Process;
              _node.icon = 'sign';
              _node.isSvgIcon = true;
              _node.isCac = false;
              break;
            case 'fastdocs_employee_master_data':
              _node.type = ProcessTreeNodeType.Process;
              _node.icon = 'lohn';
              _node.isSvgIcon = true;
              _node.isCac = false;
              break;
            case 'third_party':
            case 'leasing':
            case 'bank':
            case 'safekeeping':
            case 'expert':
            case 'insurance':
            case 'claim_invoice':
            case 'claim_balance':
            case 'liability_balance':
            case 'liability_invoice':
            case 'third_party_request':
              _node.type = ProcessTreeNodeType.Process;
              _node.icon = 'third_party';
              _node.isSvgIcon = true;
              _node.isCac = false;
              break;
            default:
              _node.type = ProcessTreeNodeType.Process;
              _node.icon = 'projectroom';
              _node.isSvgIcon = true;
              _node.isProjectRoom = true;
          }
          processNodes.push(_node);
          if (node.parentType === 'quickcollector') {
            node.items.forEach(item => {
              const __node = new ProcessTreeNode(
                item.id,
                ProcessTreeNodeType.CollectoElement,
                node.id,
                item.subTitle,
                new Date()
              );
              __node.icon = 'playlist_add_check';
              processNodes.push(__node);
            });
          }
        });
        return [root, ...processNodes];
      }),
      takeUntil(this.onDestroy)
    )
      .subscribe(nodes => {
        this.processesNodes$.next(nodes);
      });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.processesNodes$.complete();
    this.process$.complete();
  }

  selectItem(node) {
    if (!node) return;
    this.onSelect.emit(node);
  }

  onExpandItem = (node) => {
    if (!node) return;
    this.onSelect.emit(node);
  }
}
