import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dvtx-confirmation-dialog-simple',
  templateUrl: './confirmation-dialog-simple.component.html',
  styleUrls: ['./confirmation-dialog-simple.component.scss']
})
export class ConfirmationDialogSimpleComponent {
  submitButtonTitle = 'GENERAL.CONFIRM_ACTION';
  cancelButtonTitle = 'GENERAL.CANCEL_ACTION';
  actionButtonTitle = 'GENERAL.ACTION_TITLE';
  showActionButton = false;
  color: any = '#007aff';

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogSimpleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogSimpleData
  ) {
    if (this.data.submitButtonTitle) {
      this.submitButtonTitle = this.data.submitButtonTitle;
    }
    if (this.data.cancelButtonTitle) {
      this.cancelButtonTitle = this.data.cancelButtonTitle;
    }
    if (this.data.actionButtonTitle) {
      this.actionButtonTitle = this.data.actionButtonTitle;
    }
    if (this.data.color) {
      this.color = this.data.color;
    }
    if (this.data.disableClose) {
      this.dialogRef.disableClose = true;
    }
    if (this.data.showActionButton) {
      this.showActionButton = true;
    }
  }

  onSubmitClick(): void {
    this.data.onSubmitAction();
    this.dialogRef.close();
  }

  onActionClick(): void {
    this.data.onAction();
    this.dialogRef.close();
  }

  onNoClick(): void {
    if (this.data.onCancelAction) {
      this.data.onCancelAction();
    }
    this.dialogRef.close();
  }
}

export interface ConfirmationDialogSimpleData {
  title: string;
  color: string;
  message: string;
  disableClose: boolean;
  showActionButton: boolean;
  onSubmitAction: () => void;
  onAction: () => void;
  onCancelAction?: () => void;
  submitButtonTitle?: string;
  cancelButtonTitle?: string;
  actionButtonTitle?: string;
}
