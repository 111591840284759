import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {FoxdoxRegisterComponent} from './components/foxdox-register/foxdox-register.component';
import {FoxdoxLoginComponent} from './components/foxdox-login/foxdox-login.component';
import {FoxdoxCreateComponent} from './components/foxdox-create/foxdox-create.component';
import {FoxdoxService} from './services/foxdox.service';
import {FoxdoxConnectComponent} from './components/foxdox-connect/foxdox-connect.component';
import { FoxdoxStatusComponent } from './components/foxdox-status/foxdox-status.component';
import { FoxdoxStatusContainerComponent } from '../user-profile/containers/foxdox-container-status/foxdox-container-status.component';
import { OrganizationCardModule } from 'app/five-f/organization-card/organization-card.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    OrganizationCardModule
  ],
  declarations: [
    FoxdoxCreateComponent,
    FoxdoxLoginComponent,
    FoxdoxRegisterComponent,
    FoxdoxConnectComponent,
    FoxdoxStatusContainerComponent,
    FoxdoxStatusComponent
  ],
  exports: [
    FoxdoxCreateComponent,
    FoxdoxLoginComponent,
    FoxdoxRegisterComponent,
    FoxdoxConnectComponent,
    FoxdoxStatusContainerComponent,
    FoxdoxStatusComponent
  ],
  providers: [
    FoxdoxService
  ]
})
export class FoxdoxModule { }
