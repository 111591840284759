import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, SPACE } from '@angular/cdk/keycodes';

@Component({
  selector: 'dvtx-upload-file-detail',
  templateUrl: './upload-file-detail.component.html',
})
export class UploadFileDetailComponent {
  TAG_SEPARATOR_KEY_CODES = [ENTER, SPACE];

  @Input() form: UntypedFormGroup;

  tags = [];
  removable = true;
  addOnBlur: true;

  doc_types = [
    {name: 'Dokument', value: 0},
    {name: 'Tabelle', value: 1},
    {name: 'Präsentation', value: 2},
    {name: 'Photo', value: 3},
    {name: 'Grafik', value: 4},
    {name: 'Diagramm', value: 5},
    {name: 'Rechnung', value: 6}
  ];

  constructor(private _fb: UntypedFormBuilder) { }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value ? event.value.trim() : '';

    if (value.trim()) {
      if (!this.tags.find(tag => tag.name === value)) {
        this.tags.push({ name: value });
        const control = <UntypedFormArray>this.form.controls['tags'];
        control.push(this._fb.group({ name: value }));
      }
    }

    if (input) {
      input.value = '';
    }
  }

  removeTag(tag: any): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
    const control = <UntypedFormArray>this.form.controls['tags'];
    control.removeAt(index);
  }
}
