import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  DocumentPreviewDocumentActionTypes,
  RequestDocuments,
  RequestDocumentsFail,
  RequestDocumentsSuccess
} from './document-preview-document.actions';
import {DocumentPreviewDocument} from './document-preview-document';
import {DocumentPreviewDocumentService} from './document-preview-document.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class DocumentPreviewDocumentEffects {
  @Effect()
  requestDocuments$ = this.actions.pipe(
    ofType(DocumentPreviewDocumentActionTypes.RequestDocuments),
    switchMap((action: RequestDocuments) => {
      return this._svc.requestPreviews(action.ids)
        .pipe(
          first(),
          concatMap((res: DocumentPreviewDocument[]) => {
            return [new RequestDocumentsSuccess(res)];
          }),
          catchError(err => {
            console.error(err);
            return of(new RequestDocumentsFail(err));
          }));
    })
  );

  constructor(private actions: Actions,
              private _svc: DocumentPreviewDocumentService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
