<mat-card>
  <mat-card-content class="p-0">
    <div mat-dialog-content class="pb-1 text-center">
      <h2 class="mb-1 font-weight-medium">{{data.title | translate}}</h2>
      <h3 class="mb-1 font-weight-light" [innerHtml]="(data.message | translate).replace('${requiredLicence}', data.requiredLicence)"></h3>
    </div>
  </mat-card-content>
  <mat-card-actions class="m-0 p-1">
    <div class="d-flex justify-content-center">
      <button mat-button *ngIf="data.cancelButtonTitle" (click)="onNoClick()">{{ cancelButtonTitle | translate }}</button>
      <button mat-raised-button color="primary" *ngIf="data.secondarySubmitButtonTitle" (click)="onSecondarySubmitClick()">{{ secondarySubmitButtonTitle | translate }}</button>
      <button mat-raised-button color="primary" (click)="onSubmitClick()">{{ submitButtonTitle | translate }}</button>
    </div>
  </mat-card-actions>
</mat-card>
