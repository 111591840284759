import {Component, forwardRef, Injector, OnInit} from '@angular/core';
import {DvtxControlValueAccessor} from '../../DvtxControlValueAccessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {TextInputCvaComponent} from '../text-input-cva/text-input-cva.component';

@Component({
  selector: 'dvtx-listbuilder-cva',
  templateUrl: './listbuilder-cva.component.html',
  styleUrls: ['./listbuilder-cva.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListbuilderCvaComponent),
      multi: true,
    }
  ]
})
export class ListbuilderCvaComponent extends DvtxControlValueAccessor implements OnInit {
  inputLabel = '';
  inputValue = '';

  itemList = [];

  constructor(protected injector: Injector) {
    super()
  }

  ngOnInit() {
  }

  writeValue(obj: string[]): void {
    this.itemList = obj;
  }

  addItem() {
    console.log(this.itemList);
    if (this.inputValue !== '' && this.inputLabel !== '') {
      this.itemList.push({ label: this.inputLabel, value: this.inputValue });
      this.notifyOnChange(this.itemList);
      this.inputLabel = '';
      this.inputValue = '';
    }
  }

  removeItem(index: number) {
    this.itemList.splice(index, 1);
    this.notifyOnChange(this.itemList);
  }

  keyUpOnInput(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.addItem()
    }
  }
}
