import {Component, Input, OnChanges} from '@angular/core';

@Component({
  selector: 'dvtx-wysiwyg-editor-error',
  templateUrl: './error-wysiwyg.component.html',
  styleUrls: ['./error-wysiwyg.component.scss'],
})
export class ErrorWysiwygComponent {

    _dasError;
    _fileError;

    @Input() set dasError(error) {
      this._dasError = error;
    }
  
    get dasError() {
      return this._dasError;
    }


    @Input() set fileError(error) {
      this._fileError = error;
    }
  
    get fileError() {
      return this._fileError;
    }
}
