import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dvtx-alert-box-success',
  templateUrl: './alert-box-success.component.html',
  styleUrls: ['./alert-box-success.component.scss']
})

export class AlertBoxSuccessComponent {
  @Input() title: string;
  @Input() message: string ;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  onClick(): void {
    this.closeModal.emit();
  }
}
