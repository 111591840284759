import {Action} from '@ngrx/store';
import {BookmanClient} from './bookman-client';

export enum BookmanClientActionTypes {
  LoadAll = '[BookmanClient] Load All',
  LoadAllSuccess = '[BookmanClient] Load All Success',
  LoadAllFail = '[BookmanClient] Load All Fail',
  Refresh = '[BookmanClient] Refresh',
}


export class LoadAll implements Action {
  readonly type = BookmanClientActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = BookmanClientActionTypes.LoadAllSuccess;

  constructor(public clients: BookmanClient[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = BookmanClientActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Refresh implements Action {
  readonly type = BookmanClientActionTypes.Refresh;

  constructor() {
  }
}

export type BookmanClientActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Refresh;
