import {CollectorElementType, ICollectorCategory} from '../collector/collector.interface';
import {CollectorItem} from '../collector-item/collector-item';
import {BomDataNode} from '../bom/bom-data-node';

export class CollectorCategory extends BomDataNode implements ICollectorCategory {
  readonly type = CollectorElementType.Category;
  items: CollectorItem[] = [];

  public effortsSumValue: number = 0;
  public sumValue: number = 0;
  public progressSumValue: number = 0;

  constructor(public id,
              public processId,
              public title,
              public description,
              public color,
              public preDueDate,
              public dueDate,
              public startedAt,
              public completedAt,
              public createdAt,
              public updatedAt,
              public isOverdued: boolean = false,
              public priority = 'medium',
              public startsAt = null,
              public endsAt = null,
              public order = null) {
    super(id, null, title, description, color, preDueDate, dueDate, startsAt, completedAt,
      createdAt, updatedAt, order, isOverdued, priority, startsAt, endsAt, null, null);
  }

  clone(): CollectorCategory {
    return CollectorCategory.clone(this);
  }

  static clone(category): CollectorCategory {
    return new CollectorCategory(
      category.id,
      category.processId,
      category.title,
      category.description,
      category.color,
      category.preDueDate,
      category.dueDate,
      category.startedAt,
      category.completedAt,
      category.createdAt,
      category.updatedAt);
  }
}
