import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './document-preview-preview.state';
import {DocumentPreviewDocumentSelectors} from '../document-preview-document';

export const stateKey = 'document-preview-preview';
const getDocumentPreviewPreviewState = createFeatureSelector<State>(stateKey);

/**
 * HELP: Selectors are predefined queries aka prepared statements.
 */

export const {
  selectEntities: getDocumentPreviewPreviewEntities,
  selectAll: getAllDocumentPreviewPreviews,
} = adapter.getSelectors(getDocumentPreviewPreviewState);

export const loadingState = createSelector(
  getDocumentPreviewPreviewState,
  (state) => state.loading
);

export const previewWithId = (id: string) => createSelector(
  getDocumentPreviewPreviewEntities,
  (previewEntities) => previewEntities[id]
);

export const previewWithUrl = (url: string) => createSelector(
  getAllDocumentPreviewPreviews,
  (previews) => previews.find(preview => preview.url === url)
);

export const previewsOfDocumentWithId = (document_id: string) => createSelector(
  DocumentPreviewDocumentSelectors.documentWithId(document_id),
  getAllDocumentPreviewPreviews,
  (doc, previews) => {
    const doc_previews = doc.previews;
    const c: number = doc_previews.length;
    return previews.filter(preview => {
      if (preview.documentId) { return preview.documentId === document_id; }
      for (let i = 0; i < c; i++) {
        const doc_p = doc_previews[i];
        if (doc_p.id === preview.id) { return true; }
      }
      return false;
    });
  }
);
