import {Action} from '@ngrx/store';
import {ProcessEvent} from './process-event';
import {IInstantMessage} from '../message/message';
import {CommentReactionType} from '../comment/comment.interface';

export enum ProcessEventActionTypes {
  LoadAll = '[ProcessEvent] Load All Events',
  LoadAllSuccess = '[ProcessEvent] Load All Events Success',
  LoadAllFail = '[ProcessEvent] Load All Events Fail',
  LoadOne = '[ProcessEvent] Load One Events',
  LoadOneSuccess = '[ProcessEvent] Load One Events Success',
  DeleteSuccess = '[ProcessEvent] Delete Events Success',
  LoadOneFail = '[ProcessEvent] Load One Events Fail',
  SendInstantMessage = '[ProcessEvent] Send Instant Message',
  SendInstantMessageSuccess = '[ProcessEvent] Send Instant Message Success',
  SendInstantMessageFail = '[ProcessEvent] Send Instant Message Fail',
  Read = '[ProcessEvent] Read Event',
  ReadSuccess = '[ProcessEvent] Read Event Success',
  ReadFail = '[ProcessEvent] Read Event Fail',
  React = '[ProcessEvent] Thumb Up Event',
  ReactSuccess = '[ProcessEvent] Thumb Up Event Success',
  ReactFail = '[ProcessEvent] Thumb Up Event Fail',
  Reset = '[ProcessEvent] Reset',
  RefreshUploads = '[ProcessEvent] RefreshUploads',
}

export class Reset implements Action {
  readonly type = ProcessEventActionTypes.Reset;

  constructor(public payload: any = null) {
  }
}

export class LoadAll implements Action {
  readonly type = ProcessEventActionTypes.LoadAll;

  constructor(public id: string, public page: number = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ProcessEventActionTypes.LoadAllSuccess;

  constructor(public payload: ProcessEvent[], public events: ProcessEvent[] = [], public refresh = false) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ProcessEventActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = ProcessEventActionTypes.LoadOne;

  constructor(public processId: string, public eventId: string, public parentId = null) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = ProcessEventActionTypes.LoadOneSuccess;

  constructor(public event: ProcessEvent) {
  }
}

export class LoadOneFail implements Action {
  readonly type = ProcessEventActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class SendInstantMessage implements Action {
  readonly type = ProcessEventActionTypes.SendInstantMessage;

  constructor(public id: string, public message: IInstantMessage, public replyTo: string = null) {
  }
}

export class SendInstantMessageSuccess implements Action {
  readonly type = ProcessEventActionTypes.SendInstantMessageSuccess;

  constructor(public event: ProcessEvent) {
  }
}

export class SendInstantMessageFail implements Action {
  readonly type = ProcessEventActionTypes.SendInstantMessageFail;

  constructor(public payload: any) {
  }
}

export class Read implements Action {
  readonly type = ProcessEventActionTypes.Read;

  constructor(public processId: string, public eventId: string) {
  }
}

export class ReadSuccess implements Action {
  readonly type = ProcessEventActionTypes.ReadSuccess;

  constructor(public event: ProcessEvent) {
  }
}

export class ReadFail implements Action {
  readonly type = ProcessEventActionTypes.ReadFail;

  constructor(public payload: any) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = ProcessEventActionTypes.DeleteSuccess;

  constructor(public eventId: string) {
  }
}

export class React implements Action {
  readonly type = ProcessEventActionTypes.React;

  constructor(public processId: string, public eventId: string, public reactionType: CommentReactionType) {
  }
}

export class ReactSuccess implements Action {
  readonly type = ProcessEventActionTypes.ReactSuccess;

  constructor(public event: ProcessEvent) {
  }
}

export class ReactFail implements Action {
  readonly type = ProcessEventActionTypes.ReactFail;

  constructor(public payload: any) {
  }
}

/**
 * Used to refresh upload events after an edit. Example the item was renamed, then
 * this fact should be evaluated as well inside the timeline.
 */
export class RefreshUploads implements Action {
  readonly type = ProcessEventActionTypes.RefreshUploads;

  constructor(public processId: string, public documentId) {
  }
}

export type ProcessEventActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | DeleteSuccess
  | SendInstantMessage
  | SendInstantMessageSuccess
  | SendInstantMessageFail
  | Read
  | ReadSuccess
  | ReadFail
  | React
  | ReactSuccess
  | ReactFail
  | Reset
  | RefreshUploads;
