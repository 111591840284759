import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'dvtx-info-text',
  templateUrl: './info-text.component.html',
  styleUrls: ['./info-text.component.scss']
})
export class InfoTextComponent implements OnInit {

  name = '';
  auditorName = '';
  organizationName = '';
  title = '';
  auditorEmail = '';
  auditorPhone = '';
  businessYear;

  @Input() headline;
  _values;

  @Input()
  set values(values) {
    this._values = values;
    if (values && values.distributor) {
      const anrede = 'Guten Tag '; // tslint:disable-line
      const title = values.distributor.title ? values.distributor.title : '';
      const name = title + ' ' + values.distributor.first_name + ' ' + values.distributor.last_name;
      this.name = anrede + ' ' + name;
      this.title = values.title;
      this.auditorName = `${values.audit_f_name} ${values.audit_l_name}`;
      this.auditorEmail = values.audit_email;
      this.organizationName = values.client_data;
      this.auditorPhone = `${values.audit_code_phone_01 ? values.audit_code_phone_01 : '' } ${values.audit_phone_01 ? values.audit_phone_01 : ''}`;
      this.businessYear = values.audit_date_03;
    }
  };

  get values() {
    return this._values;
  }

  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
}
