import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {DocumentPreviewDocumentEffects} from './document-preview-document.effects';
import {DocumentPreviewDocumentService} from './document-preview-document.service';
import {reducer} from './document-preview-document.reducer';
import {stateKey} from './document-preview-document.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([DocumentPreviewDocumentEffects])
  ],
  providers: [
    DocumentPreviewDocumentEffects,
    DocumentPreviewDocumentService
  ]
})
export class DocumentPreviewDocumentStoreModule {
}
