import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './task-resource.state';

export const stateKey = 'task-resource';
const getTaskResourceState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getTaskResourceEntities,
  selectAll: getAllTaskResources,
} = adapter.getSelectors(getTaskResourceState);

export const getLoadingState = createSelector(
  getTaskResourceState,
  state => state.loading
);
