import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FibuBookmanAccountEffects } from './fibu-bookman-account.effects';
import { FibuBookmanAccountService } from './fibu-bookman-account.service';
import { reducer } from './fibu-bookman-account.reducer';
import { stateKey } from './fibu-bookman-account.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([FibuBookmanAccountEffects])
  ],
  providers: [
    FibuBookmanAccountEffects,
    FibuBookmanAccountService
  ]
})
export class FibuBookmanAccountStoreModule {
}
