import {EventFilterClass, EventLogLevel, IProcessEvent, ProcessEventType} from './process-event.interface';
import {Comment} from '../comment/comment';

export abstract class ProcessEvent implements IProcessEvent {
  performerName;
  backtrackId: string;
  details: string;
  parentId: string;
  itemId: string;
  changeset;
  // Changelog on events. Used for example
  // inside document events to show rename/
  changelog = [];
  title: string;
  added = [];
  removed = [];
  logLevel: EventLogLevel;
  log: string;
  logColor: string;

  // Evant pagination
  total: number;
  perPage: number;
  records: number;
  adminAction?: boolean;
  reactionMap = {};
  reactionList = [];
  editedAt: Date;
  deletedAt: Date;

  constructor(public id,
              public type: ProcessEventType,
              public filterClass: EventFilterClass,
              public activity: string,
              public processId: string,
              public performer,
              public createdAt,
              public updatedAt,
              public seenBy: string[] = [],
              public reactions: string[] = []) {
  }

  public static iconForType(type): { icon: string, isSvgIcon: boolean } {
    const config = {
      icon: null,
      isSvgIcon: false
    };
    switch (type) {
      case 'conversation':
        config.icon = 'question_answer';
        break;
      case 'received':
        config.icon = 'call_received';
        config.isSvgIcon = true;
        break;
      case 'updates':
        config.icon = 'update';
        break;
      case 'documents':
        config.icon = 'insert_drive_file';
        break;
      default:
        config.icon = 'update';
    }
    return config;
  }
}

export const EVENT_TYPES = [
  'conversation',
  'documents',
  'received',
  'updates'
]

export const EVENT_TYPES_TITLES = {
  'conversation': 'PROJECT_ROOM.COMMENTS',
  'documents': 'DMS.DOCUMENTS',
  'updates': 'Updates',
  'received': 'PROJECT_ROOM.RECEIVED_OR_VERIFIED'
}

export class GenericAuditEvent extends ProcessEvent {
}

export class ItemApplicabilityChanged extends ProcessEvent {
  type = ProcessEventType.ItemApplicability;
  title: string;
  appendixId: string;
  applicable: boolean;
  societyName: string;
}

export class ItemDueDateChanged extends ProcessEvent {
  type = ProcessEventType.ItemDueDateChanged;
  title: string;
  itemId: string;
  from: Date;
  to: Date;
}

export class ExternalAccessLinkEvent extends ProcessEvent {
  url: string = '';
  documents: string[] = [];
}

export class ContactVerifiedEvent extends ProcessEvent {
  type = ProcessEventType.ContactVerified;
  processTitle: string;
  performer: string;
  performerName: string;
  reviewerName: string;
  countryCode: string;
  partnerNo: string;
  societyName: string;
  email: string;
  firstName: string;
  lastName: string;
}

export class ItemStatusChanged extends ProcessEvent {
  type = ProcessEventType.ItemStatus;
  title: string;
  appendixId: string;
  societyName: string;
  previous: string;
  next: string;
}

export class EmailSentEvent extends ProcessEvent {
  type = ProcessEventType.EmailSent;
  title: string;
  recipientName: string;
  recipientEmail: string;
  societyName: string;
}

export class CavContactEvent extends ProcessEvent {
  firstName: string;
  lastName: string;
  email: string;
  societyName: string;
}

export class ExternalMessageEvent extends ProcessEvent {
  type = ProcessEventType.ExternalMessage;
  commentsEnabled = false;
}

export class InstantMessageEvent extends ProcessEvent {
  type = ProcessEventType.InstantMessage;
  recipients: string[] = [];
  comments: Comment[] = [];
  commentsEnabled = false;
  referenceId: string;
  replyTo: string = null;
  replyToId: string = null;
  replyToDate: Date = null;
  replyToAuthor: string = null;
  publicComment = false;
}

export class CommentEvent extends InstantMessageEvent {
  type = ProcessEventType.Comment;
  commentsEnabled = false;
  title: string;
  referenceId: string;
  commentId: string;
  publicComment = true;
  enableReply = true;
}

export class ArtifactCommentEvent extends CommentEvent {
  type = ProcessEventType.ArtifactComment;
  commentsEnabled = false;
  title: string;
  referenceId: string;
  commentId: string;
  publicComment = true;
  enableReply = false;
  artifactId: string;
}

export class ParticipationEvent extends ProcessEvent {
  type: ProcessEventType;
  participant: string;
  newOwnerEmail?: string;
  newOwnerName ?: string;
}

export class ReminderEvent extends ProcessEvent {
  type: ProcessEventType;
  recipient: string;
}

/**
 * ATTENTION: The content is depending on balance or invoice requests.
 * balance requests do not contain a invoice_no, invoice_date.
 * invoice requests do not have a request type.
 *
 * {
 * 			"id": "9c55a288-89bf-472b-95e4-7ca9356d6933",
 * 			"type": "audit_event_third_party_details",
 * 			"attributes": {
 * 				"resource_id": "a97d96e4-0e6c-4e68-83fa-913eaac2f441",
 * 				"performer": "andreas.baier+third-party@paperbird.org",
 * 				"created_at": "2022-05-29T10:37:29.650+02:00",
 * 				"url": "/workflows/audit/jap-bestaetigung-dritte/preview/a97d96e4-0e6c-4e68-83fa-913eaac2f441/3009",
 * 				"line_items": [
 * 					{
 * 						"id": 3009,
 * 						"third_party_type": "claim_balance",
 * 						"request_type": "balance-closed",
 * 						"recipient_organization_no": "12",
 * 						"recipient_organization_name": "Wittl, Madubuko und Grimm",
 * 						"sequence_no": "1",
 * 						"reported_value": "2000",
 * 						"confirmed_value": 3000.34,
 * 						"value_confirmed": false,
 * 						"invoice_no": null,
 * 						"invoice_date": null,
 * 						"commit_message": "<undefined>sind mehr</undefined>",
 * 						"color": "#bb3354"
 * 					}
 * 				],
 * 				"worker_info": {
 * 					"third_party_email": "tes@test.com",
 * 					"third_party_place": null,
 * 					"third_party_function": "test FUNC",
 * 					"third_party_lastname": "Test LN",
 * 					"third_party_firstname": "Test FN",
 * 					"third_party_company_name": "Test Org"
 * 				},
 * 				"updated_at": "2022-05-29T10:37:29.650+02:00"
 * 			}
 * 		}
 */
export class ThirdPartyDetailsEvent extends ProcessEvent {
  type: ProcessEventType;
  url: string;
  workerInfo: ThirdPartyWorkerInfo;
  lineItems: ThirdPartyDetailsLineItem[];
}

export class ThirdPartyWorkerInfo {
  constructor(public thirdPartyEmail: string,
              public thirdPartyFirstname: string,
              public thirdPartyLastname: string,
              public thirdPartyFunction: string,
              public thirdPartyCompanyName: string,
              public thirdPartyPlace: string) {
  }

  static fromResponse(response) {
    return new ThirdPartyWorkerInfo(response.third_party_email, response.third_party_firstname,
      response.third_party_lastname, response.third_party_function, response.third_party_company_name,
      response.third_party_place);
  }
}

export class ThirdPartyDetailsLineItem {
  constructor(public id: string,
              public type: string,
              public requestType: string,
              public recipientOrganizationNo: string,
              public recipientOrganizationName: string,
              public recipientEmail: string,
              public recipientTitle: string,
              public recipientName: string,
              public identifier: string,
              public account: string,
              public reportedValue: number,
              public unitOfValue: string,
              public confirmedValue: number,
              public valueConfirmed: boolean,
              public deviation: boolean,
              public invoiceNo: string,
              public invoiceDate: Date,
              public commitMessage: string,
              public color: string) {
  }

  static fromResponse(response) {
    return new ThirdPartyDetailsLineItem(response.id, response.third_party_type,
      response.request_type, response.recipient_organization_no, response.recipient_organization_name,
      response.recipient_email, response.recipient_title, response.recipient_name,
      response.identifier, response.identifier, response.reported_value, response.unit_of_value, response.confirmed_value,
      response.account, response.deviation, response.invoice_no, response.invoice_date, response.commit_message,
      response.color);
  }
}

export class ThirdPartyAuditActionEvent extends ProcessEvent {
  type: ProcessEventType;
  public auditedValue: number;
  public previousAuditedValue: number;
  public auditedAttribute: 'confirmed_value' | 'audited_value';
  public actionType: string;
  public commitMessage: string;
  public unitOfValue: string;
  public identifier: string;
  public recipientOrganizationNo: string;
  public recipientOrganizationName: string;
  public recipientTitle: string;
  public recipientName: string;
  public recipientEmail: string;
  public invoiceNo: string;
  public invoiceDate: Date;
}

export class ProcessAttributeEvent extends ProcessEvent {
  type: ProcessEventType;
  participant: string;
  from: any;
  to: any;
}

export class ProcessTitleEvent extends ProcessEvent {
  type: ProcessEventType;
  from: any;
  to: any;
}

export class ProcessParentProjectChangedEvent extends GenericAuditEvent {
  type: ProcessEventType;
  processId: string;
  processTitle: string;
  sourceId: string;
  sourceTitle: string;
  targetId: string;
  targetTitle: string;
}

export class ProcessStartedEvent extends ProcessEvent {
  type: ProcessEventType;
  participant: string;
}

export class DmsFolderAuditProof extends ProcessEvent {
  type: ProcessEventType;
  lockedUntil: Date;
  folderName: string;
}

export class ProcessClosedEvent extends ProcessEvent {
  type: ProcessEventType;
  participant: string;
}

export class DocumentEvent extends ProcessEvent {
  type: ProcessEventType;
  title: string;
  backtrackId: string;
}

export class DocumentUploadedEvent extends DocumentEvent {
  type: ProcessEventType;
  url: string;
}

export class DocumentRevisionUploadedEvent extends DocumentUploadedEvent {
  type: ProcessEventType;
  parentFilename: string;
  revisionFilename: string;
  revision: string;

  // @ts-ignore
  get title() {
    return this.revisionFilename;
  }
}

export class SignatureEvent extends ProcessEvent {
  type: ProcessEventType;
  fp_sign_user_name: string;
  fp_sign_user_email: string;
}

export class CollectorUpdatedAttributesEvent extends ProcessEvent {
  type: ProcessEventType;
}

export class ExportReadyEvent extends ProcessEvent {
  type: ProcessEventType;
  title: string;
  expired: boolean;
  export: string;
  url: string;
}

export class TaskCreationEvent extends ProcessEvent {
  type: ProcessEventType;
  title: string;
  taskId: string;
}

export class DoubleOptInEvent extends ProcessEvent {
  type: ProcessEventType;
  name: string;
  email: string;
}

export class LabelAddedEvent extends ProcessEvent {
  itemTitle: string;
  itemId: string;
  labelTitle: string;
  labelId: string;
  labelColor: string;
  labelIcon: string;
}

export class LabelRemovedEvent extends ProcessEvent {
  itemTitle: string;
  itemId: string;
  labelTitle: string;
  labelId: string;
  labelColor: string;
  labelIcon: string;
}

export class SharepointSyncSettingsEvent extends ProcessEvent {
  type: ProcessEventType;
  ms_team_title: string;
  ms_channel_title: string;
  ms_folder_name: string;
  auto_sync_state: boolean;
}
