import {IFibu} from './fibu.interface';
import {Process, ProcessStatus} from '../process/process';
import {Client} from '../client/client';

export class Fibu implements IFibu {
  readonly type = 'workflow_engine_projects';
  client: Client;
  organizationId: string;

  constructor(
    public id: string,
    public parentId: string,
    public title: string,
    public identifier: string,
    public referenceId: string,
    public description: string,
    public state: string,
    public creatorName: string,
    public creatorEmail: string,
    public ownerName: string,
    public ownerEmail: string,
    public dmsFolderId: string,
    public dueDate: Date,
    public createdAt: Date,
    public updatedAt: Date,
    public bookmanCockpitEnabled: boolean,
    public fiveFSyncEnabled: boolean,
    public documentExpiryTime: number,
    public color: string = null
  ) {
  }

  clone() {
    return Fibu.clone(this);
  }

  static asProcess(fibu): Process {
    const p = new Process(
      fibu.id,
      fibu.title,
      null,
      fibu.description,
      fibu.dueDate,
      fibu.createdAt,
      'workflow_engine_projects',
      fibu.updatedAt
    );
    if (fibu.client) {
      p.client = fibu.client;
    }
    if (fibu.state) {
      p.status = new ProcessStatus(fibu.state)
    }
    return p;
  }

  static clone(fibu) {
    return new Fibu(
      fibu.id,
      fibu.parentId,
      fibu.title,
      fibu.identifier,
      fibu.referenceId,
      fibu.description,
      fibu.state,
      fibu.creatorName,
      fibu.creatorEmail,
      fibu.ownerName,
      fibu.ownerEmail,
      fibu.dmsFolderId,
      fibu.dueDate,
      fibu.createdAt,
      fibu.updatedAt,
      fibu.bookmanCockpitEnabled,
      fibu.fiveFSyncEnabled,
      fibu.documentExpiryTime,
    );
  }
}
