import {ProjectRoomActions, ProjectRoomActionTypes} from './project-room.actions';
import {adapter, initialState, State} from './project-room.state';

export function reducer(state = initialState, action: ProjectRoomActions): State {
  switch (action.type) {
    case ProjectRoomActionTypes.LoadOne:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case ProjectRoomActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.project, {
        ...state,
        selected: action.project,
        loading: false,
      });
    case ProjectRoomActionTypes.LoadOneFail:
      return {
        ...state,
        loading: false
      };
  }
  return state;
}

