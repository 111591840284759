import {Action} from '@ngrx/store';
import {MinimalProcess} from './process-lookup';
import {IProcessQuery} from '../process-event/process-event.interface';

export enum ProcessLookupActionTypes {
  LoadSelection = '[ProcessLookup] LoadSelection',
  LoadSelectionSuccess = '[ProcessLookup] LoadSelection Success',
  LoadSelectionFail = '[ProcessLookup] LoadSelection Fail'
}

export class LoadSelection implements Action {
  readonly type = ProcessLookupActionTypes.LoadSelection;

  constructor(public params: IProcessQuery = null) {
  }
}

export class LoadSelectionSuccess implements Action {
  readonly type = ProcessLookupActionTypes.LoadSelectionSuccess;

  constructor(public payload: MinimalProcess[]) {
  }
}

export class LoadSelectionFail implements Action {
  readonly type = ProcessLookupActionTypes.LoadSelectionFail;

  constructor(public payload: any) {
  }
}

export type ProcessLookupActions =
  | LoadSelection
  | LoadSelectionSuccess
  | LoadSelectionFail;
