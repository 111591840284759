import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmitSuccessComponent } from './components/submit-success/submit-success.component';
import { AccessTokenNotFoundComponent } from './components/access-token-not-found/access-token-not-found.component';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ExternalAccessService } from '../../shared/modules/api/services/external-access.service';
import { FilesUploadModule } from '../../shared/modules/file-upload/file-upload.module';
import { InfoTextComponent } from './components/info-text/info-text.component';
import { InfoComponent } from './containers/info/info.component';
import { IsAuthenticatedDialogComponent } from './components/is-authenticated-dialog/is-authenticated-dialog.component';
import { authenticatedccessRoutes } from './authenticated-access.routes';
import { AuthenticatedAccessDispatcherComponent } from './containers/authenticated-access-dispatcher/authenticated-access-dispatcher.component';

@NgModule({
    imports: [
        CommonModule,
        authenticatedccessRoutes,
        ReactiveFormsModule,
        SharedModule,
        FilesUploadModule,
    ],
    declarations: [
        AuthenticatedAccessDispatcherComponent,
        SubmitSuccessComponent,
        AccessTokenNotFoundComponent,
        InfoTextComponent,
        InfoComponent,
        IsAuthenticatedDialogComponent
    ],
    providers: [
        ExternalAccessService
    ]
})
export class AuthenticatedAccessModule { }
