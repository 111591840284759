import {RESET_TFA, TFA_IS_WRONG, TfaActions, USER_NEEDS_TFA} from '../actions/tfa.actions';

export interface TfaState {
  currentUserNeedsTFA: boolean;
  tfaIsWrong: boolean;
}

// initial user state
export const tfaInitialState: TfaState = {
  currentUserNeedsTFA: false,
  tfaIsWrong: false
};

export function tfaReducer(state: TfaState = tfaInitialState, action: TfaActions): TfaState {
  switch (action.type) {
    case USER_NEEDS_TFA:
      return {
        ...state,
        currentUserNeedsTFA: action.payload
      };
    case TFA_IS_WRONG:
      return {
        ...state,
        tfaIsWrong: action.payload
      };
    case RESET_TFA:
      return {
        currentUserNeedsTFA: false,
        tfaIsWrong: false
      };
    default: {
      return state;
    }
  }
}
