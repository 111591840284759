import { IProcess } from './process.interface';
import {DmsAccountType} from 'app/+store/dms-folder/dms-folder.interface';
import {Client} from 'app/+store/client/client';

export class Process implements IProcess {
  public status = null;
  public dms_account_type: DmsAccountType;
  public client: Client;
  public clientId: string;
  public identifier = null;
  public profile?: string;

  constructor(public id, public type, public revision,
              public title, public subtitle = '', public description = '',
              public due_date = null, public dms_folder_id: string = null) {}

  toForm() {
    return {};
  }
}
