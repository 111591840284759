import {Action} from '@ngrx/store';
import {GroupContextType, IKanbanGroupParams} from './kanban-group.interface';
import {KanbanGroup} from './kanban-group';

export enum KanbanGroupActionTypes {
  Create = '[KanbanGroup] Create',
  CreateSuccess = '[KanbanGroup] Create Success',
  CreateFail = '[KanbanGroup] Create Fail',
  Edit = '[KanbanGroup] Edit',
  EditSuccess = '[KanbanGroup] Edit Success',
  EditFail = '[KanbanGroup] Edit Fail',
  LoadAll = '[KanbanGroup] Load All',
  LoadAllSuccess = '[KanbanGroup] Load All Success',
  LoadAllFail = '[KanbanGroup] Load All Fail',
  LoadOne = '[KanbanGroup] Load One',
  LoadOneSuccess = '[KanbanGroup] Load One Success',
  LoadOneFail = '[KanbanGroup] Load One Fail',
  Remove = '[KanbanGroup] Remove',
  RemoveSuccess = '[KanbanGroup] Remove Success',
  RemoveFail = '[KanbanGroup] Remove Fail',
  Reset = '[KanbanItem] Reset',
}

export class Create implements Action {
  readonly type = KanbanGroupActionTypes.Create;

  constructor(public labelId: string, public isContextual: boolean = false, public contextType: GroupContextType = GroupContextType.NO_CONTEXT, public referenceId: string = null) {
  }
}

export class CreateSuccess implements Action {
  readonly type = KanbanGroupActionTypes.CreateSuccess;

  constructor(public kanbanGroup: KanbanGroup) {
  }
}

export class CreateFail implements Action {
  readonly type = KanbanGroupActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}


export class Edit implements Action {
  readonly type = KanbanGroupActionTypes.Edit;

  constructor(public id: string, public params: IKanbanGroupParams) {
  }
}

export class EditSuccess implements Action {
  readonly type = KanbanGroupActionTypes.EditSuccess;

  constructor(public kanbanGroup: KanbanGroup) {
  }
}

export class EditFail implements Action {
  readonly type = KanbanGroupActionTypes.EditFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = KanbanGroupActionTypes.LoadAll;

  constructor(public boardId: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = KanbanGroupActionTypes.LoadAllSuccess;

  constructor(public kanbanGroups: KanbanGroup[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = KanbanGroupActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = KanbanGroupActionTypes.LoadOne;

  constructor(public boardId: string, public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = KanbanGroupActionTypes.LoadOneSuccess;

  constructor(public kanbanGroup: KanbanGroup) {
  }
}

export class LoadOneFail implements Action {
  readonly type = KanbanGroupActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = KanbanGroupActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = KanbanGroupActionTypes.RemoveSuccess;

  constructor(public id: string) {
  }
}

export class RemoveFail implements Action {
  readonly type = KanbanGroupActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Reset implements Action {
  readonly type = KanbanGroupActionTypes.Reset;

  constructor() {
  }
}

export type KanbanGroupActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Edit
  | EditSuccess
  | EditFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Reset;
