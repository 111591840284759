import {Pipe, PipeTransform} from '@angular/core';
import {ListingService} from '../modules/api/services/listing.service';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

@Pipe({ name: 'legalFormNameById'})
export class LegalFormNameByIdPipe implements PipeTransform  {
  private static legalForms: Observable<{name: string, value: number}[]>;

  constructor(private listingService: ListingService) {}
  transform(value) {
    if (!LegalFormNameByIdPipe.legalForms) {
      LegalFormNameByIdPipe.legalForms = this.listingService.fetchListing('legal-form').pipe(shareReplay(1));
    }

    return LegalFormNameByIdPipe.legalForms.pipe(
      map((legalForms) => {
        const numberValue = parseInt(value, 10);
        const matchingLegalForm = legalForms.find((legalForm) => legalForm.value === numberValue);

        return matchingLegalForm ? matchingLegalForm.name : null;
      }),
    );
  }
}
