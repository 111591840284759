import {CollectorExportActions, CollectorExportActionTypes} from './collector-export.actions';
import {initialState, State} from './collector-export.state';

export function reducer(state = initialState, action: CollectorExportActions): State {
  switch (action.type) {
    case CollectorExportActionTypes.Download:
      return {
        ...state,
        loading: true
      };

    case CollectorExportActionTypes.DownloadSuccess:
    case CollectorExportActionTypes.DownloadFail:
      return {
        ...state,
        loading: false
      };
  }
}

