import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OauthCallbackComponent} from './components/oauth-callback/oauth-callback.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    OauthCallbackComponent
  ],
  exports: [
    OauthCallbackComponent
  ]
})
export class OAuthModule { }
