import { NgModule } from '@angular/core';
import { onboardingRoutes } from './onboarding.routes';
import { SharedModule } from '../../shared/shared.module';
import { OnboardingComponent } from './onboarding.component';
import { PartnerTypeService } from './services/partner-type.service';
import { PaymentDataService } from './services/payment-data.service';
import { UserService } from './services/user.service';
import { ContactsModule } from 'app/modules/contacts/contacts.module';
import { OrganizationModule } from 'app/modules/organization/organization.module';

@NgModule({
    imports: [
        onboardingRoutes,
        SharedModule,
        ContactsModule,
        OrganizationModule
    ],
    declarations: [
        OnboardingComponent
    ],
    providers: [
        PartnerTypeService,
        PaymentDataService,
        UserService
    ]
})
export class OnboardingModule {
}
