import {Action} from '@ngrx/store';
import {Process} from './process';
import {CommandQueue} from './process.interface';
import {IProcessQuery} from '../process-event/process-event.interface';
import CommandQueueName = CommandQueue.CommandQueueName;

export enum ProcessActionTypes {
  LoadAll = '[Process] Load All',
  LoadAllSuccess = '[Process] Load All Success',
  LoadAllFail = '[Process] Load All Fail',
  LoadAllFromParent = '[Process] Load All From Parent',
  LoadAllFromParentSuccess = '[Process] Load All From Parent Success',
  LoadAllFromParentFail = '[Process] Load All From Parent Fail',
  Unauthorized = '[Process] Unauthorized',
  LoadOne = '[Process] Load One',
  LoadOneSuccess = '[Process] Load One Success',
  LoadOneFail = '[Process] Load One Fail',
  RunCommand = '[Process] Run Command',
  RunCommandSuccess = '[Process] Run Command Success',
  RunCommandFail = '[Process] Run Command Fail',
  Move = '[Process] Move',
  MoveSuccess = '[Process] Move Success',
  MoveFail = '[Process] Move Fail',
  AddFavorite = '[Favorite] Add Favorite',
  AddFavoriteSuccess = '[Favorite] Add Favorite Success',
  AddFavoriteFail = '[Favorite] Add Favorite Fail',
  Remove = '[Process] Remove',
  RemoveSuccess = '[Process] Remove Success',
  RemoveFail = '[Process] Remove Fail',
  Track = '[Process] Track',
  TrackSuccess = '[Process] TrackSuccess',
  ChangeSortingBy = '[Process] ChangeSortingBy',
  ChangePaginationEnd = '[Process] ChangePaginationEnd',
  ProcessLoadingSuccess = '[Process] ProcessLoadingSuccess'
}

export class LoadAll implements Action {
  readonly type = ProcessActionTypes.LoadAll;

  constructor(public params: IProcessQuery = null) {
  }
}

export class Unauthorized implements Action {
  readonly type = ProcessActionTypes.Unauthorized;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ProcessActionTypes.LoadAllSuccess;

  constructor(public payload: Process[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ProcessActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadAllFromParent implements Action {
  readonly type = ProcessActionTypes.LoadAllFromParent;

  constructor(public id: string, public dense = false) {
  }
}

export class LoadAllFromParentSuccess implements Action {
  readonly type = ProcessActionTypes.LoadAllFromParentSuccess;

  constructor(public payload: Process[]) {
  }
}

export class LoadAllFromParentFail implements Action {
  readonly type = ProcessActionTypes.LoadAllFromParentFail;

  constructor(public payload: any) {
  }
}

export class AddFavorite implements Action {
  readonly type = ProcessActionTypes.AddFavorite;

  constructor(public payload: Process = null, public id: string = null) {
  }
}

export class AddFavoriteSuccess implements Action {
  readonly type = ProcessActionTypes.AddFavoriteSuccess;

  constructor(public payload: { id: any; favorite: boolean }) {
  }
}

export class AddFavoriteFail implements Action {
  readonly type = ProcessActionTypes.AddFavoriteFail;

  constructor(public payload: any) {
  }
}

export class Move implements Action {
  readonly type = ProcessActionTypes.Move;

  constructor(public id: string, public targetId: string) {
  }
}

export class MoveSuccess implements Action {
  readonly type = ProcessActionTypes.MoveSuccess;

  constructor(public process: Process) {
  }
}

export class MoveFail implements Action {
  readonly type = ProcessActionTypes.MoveFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = ProcessActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = ProcessActionTypes.RemoveSuccess;

  constructor(public id: string) {
  }
}

export class RemoveFail implements Action {
  readonly type = ProcessActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = ProcessActionTypes.LoadOne;

  constructor(public id: string, public dense: boolean = false, public loadingTrue = true) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = ProcessActionTypes.LoadOneSuccess;

  constructor(public process: Process) {
  }
}

export class LoadOneFail implements Action {
  readonly type = ProcessActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class RunCommand implements Action {
  readonly type = ProcessActionTypes.RunCommand;

  constructor(public id: string, public action: string, public queue: CommandQueueName) {
  }
}

export class RunCommandSuccess implements Action {
  readonly type = ProcessActionTypes.RunCommandSuccess;

  constructor(public process: Process, public queue: CommandQueueName) {
  }
}

export class RunCommandFail implements Action {
  readonly type = ProcessActionTypes.RunCommandFail;

  constructor(public payload: any) {
  }
}

export class Track implements Action {
  readonly type = ProcessActionTypes.Track;

  constructor(public id: string) {
  }
}

export class TrackSuccess implements Action {
  readonly type = ProcessActionTypes.TrackSuccess;

  constructor(public payload: any) {
  }
}

export class ChangeSortingBy implements Action {
  readonly type = ProcessActionTypes.ChangeSortingBy;

  constructor(public sortComparer: any) {
  }
}

export class ChangePaginationEnd implements Action {
  readonly type = ProcessActionTypes.ChangePaginationEnd;

  constructor(public end: number) {
  }
}

export class ProcessLoadingSuccess implements Action {
  readonly type = ProcessActionTypes.ProcessLoadingSuccess;

  constructor() {
  }
}

export type ProcessActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadAllFromParent
  | LoadAllFromParentSuccess
  | LoadAllFromParentFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | RunCommand
  | RunCommandSuccess
  | RunCommandFail
  | Unauthorized
  | Move
  | MoveSuccess
  | MoveFail
  | AddFavorite
  | AddFavoriteSuccess
  | AddFavoriteFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Track
  | TrackSuccess
  | ChangeSortingBy
  | ChangePaginationEnd
  | ProcessLoadingSuccess;
