import {IResource} from 'app/shared/modules/api/models/resource.interface';

export interface IProcessEventStatus {
  title: string;
  icon: string;
  infoLevel;
}

export interface IProcessQuery {
  processIds?: string[];
  workflow?: string;
  dense?: boolean;
  forceLoadingState?: boolean;
  dmsFolderId?: string;
  debug?: string;
}

export enum EventFilterClass {
  Access = 'Access',
  Attributes = 'Attributes',
  Document = 'Document',
  GeneralEvent = 'GeneralEvent',
  Message = 'Message',
  Participation = 'Participation',
  Task = 'Task'
}

export enum EventLogLevel {
  INFO = 'INFO',
  Msg = "MSG\u00A0",
  Warn = 'WARN',
  Error = "ERR",
  DOC = 'DOC',
  Debug = 'Debug',
  Auth = 'Auth',
  Visit = 'Visit'
}

export enum ProcessEventType {
  AccessDenied = 'AccessDenied',
  ArtifactComment = 'ArtifactComment',
  Audit = 'Audit',
  AuditProof = 'AuditProof',
  ItemApplicability = 'ItemApplicability',
  ItemDueDateChanged = 'ItemDueDateChanged',
  ItemStatus = 'ItemStatus',
  CollectorPublished = 'CollectorPublished',
  CollectorGroupCreated = 'CollectorGroupCreated',
  CollectorGroupDeleted = 'CollectorGroupDeleted',
  CollectorItemCreated = 'CollectorItemCreated',
  CollectorItemDeleted = 'CollectorItemDeleted',
  CollectorUpdatedAttributes = 'CollectorUpdatedAttributes',
  ContactVerified = 'ContactVerified',
  Comment = 'Comment',
  CommentDeleted = 'CommentDeleted',
  CommentEdited = 'CommentEdited',
  Description = 'Description',
  DocumentAdded = 'DocumentAdded',
  DocumentUploaded = 'DocumentUploaded',
  DocumentRemoved = 'DocumentRemoved',
  DocumentDeleted = 'DocumentDeleted',
  DocumentRevisionUploaded = 'DocumentRevisionUploaded',
  DocumentsSynced = 'DocumentsSynced',
  DocumentsDownloaded = 'DocumentsDownloaded',
  DocumentsDownloadedExternally = 'DocumentsDownloadedExternally',
  DoubleOptIn = 'DoubleOptIn',
  DmsFolderGrant = 'DmsFolderGrant',
  DueDate = 'DueDate',
  EmailSent = 'EmailSent',
  EmailSentFailure = 'EmailSentFailure',
  ExportReady = 'ExportReady',
  ExternalAccessReceived = 'ExternalAccessReceived',
  ExternalMessage = 'ExternalMessage',
  ExternalLinkCreated = 'ExternalLinkCreated',
  ExternalLinkDeleted = 'ExternalLinkDeleted',
  ExternalUploadCreated = 'ExternalUploadCreated',
  ExternalUploadDeleted = 'ExternalUploadDeleted',
  Error = 'Error',
  InstantMessage = 'InstantMessage',
  FastdocsWorkerEntered = 'FastdocsWorkerEntered',
  ParticipantAdded = 'ParticipantAdded',
  ParticipantRoleChange = 'ParticipantRoleChange',
  ParticipantRemoved = 'ParticipantRemoved',
  ProcessAttributeUpdate = 'ProcessAttributeUpdate',
  ProcessUpdate = 'ProcessUpdate',
  ProcessClosed = 'ProcessClosed',
  ProcessOwnerChanged = 'ProcessOwnerChanged',
  ProcessParentChanged = 'ProcessParentChanged',
  ProcessPublished = 'ProcessPublished',
  ProcessReopened = 'ProcessReopened',
  ProcessStarted = 'ProcessStarted',
  PublicFileDeleted = 'PublicFileDeleted',
  PublicFileUpload = 'PublicFileUpload',
  Reminded = 'Reminded',
  RecipientAdded = 'RecipientAdded',
  SeenBy = 'SeenBy',
  SignatureCompleted = 'SignatureCompleted',
  SignatureCanceled = 'SignatureCanceled',
  SignatureRejected = 'SignatureRejected',
  SignatureSigned = 'SignatureSigned',
  TaskCreated = 'TaskCreated',
  TaskDeleted = 'TaskDeleted',
  Title = 'Title',
  ThumbUp = 'ThumbUp',
  ThumbDown = 'ThumbDown',
  ThirdPartyDetails = 'ThirdPartyDetails',
  ThirdPartyAuditAction = 'ThirdPartyAuditAction',
  Visited = 'Visited',
  CavContactAdded = 'CavContactAdded',
  CavContactRemoved = 'CavContactRemoved',
  CavContactUpdated = 'CavContactUpdated',
  CavContactVerified = 'CavContactVerified',
  LabelAdded = 'LabelAdded',
  LabelRemoved = 'LabelRemoved',
  ResponsibleChanged= 'ResponsibleChanged',
  LockUpload = 'LockUpload',
  UnlockUpload = 'UnlockUpload',
  SharepointSyncSettings = 'process_event_sync_settings'
}


// actions;
// details?: string;
// message?: string;
// status: IProcessEventStatus;
// commentsEnabled: boolean;
// comments;
// backtrackId?: string;
// recipients: string[];

export interface IProcessEvent extends IResource {
  type: ProcessEventType;
  processId: string;
  parentId: string;
  performer;
  performerName?: string;
  logLevel: EventLogLevel;
  log: string;
  logColor: string;
  createdAt: Date;
  updatedAt: Date;
  seenBy?: string[];
  thumbedUpBy?: string[];
  filterClass: EventFilterClass;
  activity: string;

  // Event pagination
  total: number;
  perPage: number;
  records: number;
}
