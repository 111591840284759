import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './bookman-client.state';

export const stateKey = 'bookman-client';
const getBookmanClientState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getBookmanClientEntities,
  selectAll: getAllBookmanClientProcesses,
} = adapter.getSelectors(getBookmanClientState);

export const getSelected = createSelector(
  getBookmanClientState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getBookmanClientEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllBookmanClientProcesses,
  (entities) => entities
);

export const loadingState = createSelector(
  getBookmanClientState,
  (state) => state.loading
);
