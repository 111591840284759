<ng-container [ngSwitch]="action">
  <!-- Button to open the dialog -->
  <ng-container *ngSwitchCase="DocumentSignatureSelectionMenuViewType.Button">
    <button mat-icon-button (click)="openWorkflowDialog()" [matTooltip]="'SIGNATURE.START_WORKFLOW_DIALOG.OPEN_SIGNATURE_DIALOG' | translate" color="gray">
      <mat-icon svgIcon="sign"></mat-icon>
      <!--<mat-icon>import_export</mat-icon>-->
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="DocumentSignatureSelectionMenuViewType.IconButton">
    <button (click)="openWorkflowDialog()"
            [matTooltip]="'SIGNATURE.START_WORKFLOW_DIALOG.OPEN_SIGNATURE_DIALOG' | translate"
            mat-icon-button color="gray"
            [disabled]="menuItemButtonDisabled">
      <mat-icon svgIcon="sign"></mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="DocumentSignatureSelectionMenuViewType.ListItem">
    <li class="d-flex align-items-center p-2 dvtx-pointer"
        [dvtxDisabled]="!supported"
        [matTooltip]="'SIGNATURE.START_WORKFLOW_DIALOG.OPEN_SIGNATURE_DIALOG' | translate"
        (click)="openWorkflowDialog()">
      <mat-icon svgIcon="sign" class="mr-2"></mat-icon>
      {{ 'SIGNATURE.START_WORKFLOW_DIALOG.CREATE_SIGNATURE' | translate }}
    </li>
  </ng-container>

  <ng-container *ngSwitchCase="DocumentSignatureSelectionMenuViewType.MenuItem">
    <button (click)="openWorkflowDialog()"
            [matTooltip]="'SIGNATURE.START_WORKFLOW_DIALOG.OPEN_SIGNATURE_DIALOG' | translate"
            class="d-flex align-items-center dvtx-process-folder-export-menu-item" [disabled]="menuItemButtonDisabled">
      <mat-icon svgIcon="sign"></mat-icon>
      {{ menuItemButtonText | translate }}
    </button>
  </ng-container>

  <!-- Embeddable view to be used inside the settings dialog -->
  <ng-container *ngSwitchCase="DocumentSignatureSelectionMenuViewType.Embedded">
    <ng-template [ngIf]="contextPortal">
      <div class="d-flex flex-column" style="width: 100%">
        <div>
          <ng-template [cdkPortalOutlet]="contextPortal"></ng-template>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="DocumentSignatureSelectionMenuViewType.Quickstart">
    <ng-container *ngIf="(featureSet$ | async)?.canCreateSignature">
      <button class="d-flex align-items-center" mat-menu-item (click)="openWorkflowDialog()">
        <mat-icon svgIcon="sign"></mat-icon>
        <div class="item__text d-flex flex-column">
          <span>{{ 'SIGNATURE.START_WORKFLOW_DIALOG.CREATE_SIGNATURE' | translate }}</span>
          <sub>{{ 'SIGNATURE.START_WORKFLOW_DIALOG.CREATE_SIGNATURE_QUICKSTART_SUBTTITLE' | translate}}</sub>
        </div>
      </button>
    </ng-container>
  </ng-container>

  <!-- Custom view to be used inside the settings dialog -->
  <ng-container *ngSwitchCase="DocumentSignatureSelectionMenuViewType.Custom">
    <ng-content></ng-content>
  </ng-container>
</ng-container>

<ng-template cdkPortal #context>
  <ng-template [ngIf]="workflowFlowIsLoading" [ngIfElse]="workflowMenu">
    <h2 class="mb-2">
    <span class="dvtx-loading-indicator">
      {{ 'SIGNATURE.LOADING_SIGNATURE_WORKFLOW' | translate }}<span>.</span><span>.</span><span>.</span>
    </span>
    </h2>

    <div class="pt-2 pl-4">
      <ul>
        <li class="dvtx-option pb-1" *ngIf="!createStandaloneSignatureProcess">
          <h4 class="m-0">
            <span class="dvtx-option-icon mr-2" [ngClass]="{ 'dvtx-option-success': !processLoading }">
              <mat-icon [style.color]="!processLoading ? '#00ca5c' : '#b1b1b1'">{{ !processLoading ? 'check_circle' : 'radio_button_unchecked' }}</mat-icon>
            </span>
            <ng-template [ngIf]="processLoading" [ngIfElse]="processTitle">
                <span class="dvtx-loading-indicator">
                    {{ 'PROJECT_ROOM.LOADING_PROJECT_ROOM_2' | translate }}<span>.</span><span>.</span><span>.</span>
                </span>
            </ng-template>

            <ng-template #processTitle>
              <span class="dvtx-fg-color dvtx-fg-fivef-blue-300 text-truncate">{{ 'PROJECT_ROOM.TITLE' | translate }}:</span> {{ (process$ | async)?.title }}
            </ng-template>
          </h4>
        </li>

        <li class="dvtx-option pb-1" *ngIf="!provideUpload">
          <h4 class="m-0">
            <span class="dvtx-option-icon mr-2" [ngClass]="{ 'dvtx-option-success': !documentLoading }">
              <mat-icon [style.color]="!documentLoading ? '#00ca5c' : '#b1b1b1'">{{ !documentLoading ? 'check_circle' : 'radio_button_unchecked' }}</mat-icon>
            </span>
            <ng-template [ngIf]="documentLoading" [ngIfElse]="documentTitle">
                <span class="dvtx-loading-indicator">
                    {{ 'SIGNATURE.WIZARD.CHECK_DOCUMENT_ACCESS' | translate }}<span>.</span><span>.</span><span>.</span>
                </span>
            </ng-template>

            <ng-template #documentTitle>
              <span class="dvtx-fg-color dvtx-fg-fivef-blue-300">{{ 'MILESTONE.DOCUMENT' | translate }}:</span> {{ (document$ | async)?.title }}
            </ng-template>
          </h4>
        </li>

        <li class="dvtx-option pb-1" *ngIf="!provideUpload">
          <h4 class="m-0">
            <span class="dvtx-option-icon mr-2" [ngClass]="{ 'dvtx-option-success': !pdfLoading }">
              <mat-icon [style.color]="!pdfLoading ? '#00ca5c' : '#b1b1b1'">{{ !pdfLoading ? 'check_circle' : 'radio_button_unchecked' }}</mat-icon>
            </span>
            <ng-template [ngIf]="pdfLoading" [ngIfElse]="pdfAvailable">
                <span class="dvtx-loading-indicator">
                    {{ 'SIGNATURE.WIZARD.CHECK_PDF_AVAILABLE' | translate }}<span>.</span><span>.</span><span>.</span>
                </span>
            </ng-template>

            <ng-template #pdfAvailable>
              <ng-template [ngIf]="pdfIsAvailable" [ngIfElse]="pdfNotAvailable">
                <span class="dvtx-fg-color dvtx-fg-fivef-blue-300">{{ 'SIGNATURE.WIZARD.PDF_AVAILABILITY' | translate }}:</span> {{ 'SIGNATURE.WIZARD.AVAILABLE' | translate }}
              </ng-template>

              <ng-template #pdfNotAvailable>
                <span class="dvtx-fg-color dvtx-fg-fivef-blue-300">{{ 'SIGNATURE.WIZARD.PDF_AVAILABILITY' | translate }}:</span> {{ 'SIGNATURE.WIZARD.NOT_AVAILABLE' | translate }}
              </ng-template>
            </ng-template>
          </h4>
        </li>

        <li class="dvtx-option pb-1">
          <h4 class="m-0">
            <span class="dvtx-option-icon mr-2" [ngClass]="{ 'dvtx-option-success': !accountLoading }">
              <mat-icon *ngIf="!accountLoadingError" [style.color]="!accountLoading ? '#00ca5c' : '#b1b1b1'">{{ !accountLoading ? 'check_circle' : 'radio_button_unchecked' }}</mat-icon>
              <mat-icon *ngIf="accountLoadingError" class="status-color-danger">cancel</mat-icon>
            </span>
            <ng-template [ngIf]="accountLoading" [ngIfElse]="accountLoaded">
                <span class="dvtx-loading-indicator">
                  {{ 'SIGNATURE.WIZARD.CHECK_ACCOUNT' | translate }}<span>.</span><span>.</span><span>.</span>
                </span>
            </ng-template>

            <ng-template #accountLoaded>
              <ng-template [ngIf]="accountLoadingError" [ngIfElse]="accountSuccessfullyLoaded">
                <span class="dvtx-fg-color dvtx-fg-fivef-blue-300">{{ 'SIGNATURE.WIZARD.ACCOUNT_LOADING_ERROR' | translate }}
                  <a (click)="$event.preventDefault(); navigateToSettings()" href="#">{{ 'SIGNATURE.WIZARD.SIGNATURE_SETTINGS' | translate }}</a>
                </span>
              </ng-template>

              <ng-template #accountSuccessfullyLoaded>
                <span class="dvtx-fg-color dvtx-fg-fivef-blue-300">{{ 'SIGNATURE.WIZARD.ACCOUNT_USERNAME' | translate }}:</span> {{ (account$ | async)?.id }}
              </ng-template>
            </ng-template>
          </h4>
        </li>

        <li class="dvtx-option pb-1">
          <h4 class="m-0">
            <span class="dvtx-option-icon mr-2" [ngClass]="{ 'dvtx-option-success': !settingsLoading }">
              <mat-icon *ngIf="!settingsLoadingError" [style.color]="!settingsLoading ? '#00ca5c' : '#b1b1b1'">{{ !settingsLoading ? 'check_circle' : 'radio_button_unchecked' }}</mat-icon>
              <mat-icon *ngIf="settingsLoadingError" class="status-color-danger">cancel</mat-icon>
            </span>
            <ng-template [ngIf]="settingsLoading" [ngIfElse]="settingsLoaded">
                <span class="dvtx-loading-indicator">
                    {{ 'SIGNATURE.WIZARD.LOAD_SIGNATURE_SETTINGS' | translate }}<span>.</span><span>.</span><span>.</span>
                </span>
            </ng-template>

            <ng-template #settingsLoaded>
              <ng-template [ngIf]="settingsLoadingError" [ngIfElse]="settingsSuccessfullyLoaded">
                <span class="dvtx-fg-color dvtx-fg-fivef-blue-300">{{ 'SIGNATURE.WIZARD.ACCOUNT_SETTINGS_LOADING_ERROR' | translate }}
                  <a (click)="$event.preventDefault(); navigateToSettings()" href="#">{{ 'SIGNATURE.WIZARD.SIGNATURE_SETTINGS' | translate }}</a>
                </span>
              </ng-template>

              <ng-template #settingsSuccessfullyLoaded>
                <span class="dvtx-fg-color dvtx-fg-fivef-blue-300">{{ 'SIGNATURE.WIZARD.SIGNATURE_SETTINGS' | translate }}:</span> {{ 'SIGNATURE.WIZARD.LOADED' | translate }}
              </ng-template>
            </ng-template>
          </h4>
        </li>
      </ul>
    </div>
  </ng-template>

  <ng-template #workflowMenu>
    <h2 class="mb-2">{{ 'SIGNATURE.START_WORKFLOW_DIALOG.CREATE_SIGNATURE' | translate }}</h2>
    <mat-nav-list class="dvtx-create-workflow-list">
      <a mat-list-item class="p-1" (click)="startSignature(DocumentSignatureRequestProfileType.JustMe)">
        <mat-icon svgIcon="sign" mat-list-icon></mat-icon>
        <h4 mat-line>{{ 'SIGNATURE.START_WORKFLOW_DIALOG.SIGN_DOCUMENT_MENU_ITEM' | translate }}</h4>
        <p mat-line> {{ 'SIGNATURE.START_WORKFLOW_DIALOG.SIGN_DOCUMENT_MENU_ITEM_SUBTITLE' | translate }} </p>
      </a>
      <a mat-list-item class="p-1" (click)="startSignature(DocumentSignatureRequestProfileType.Circular)">
        <mat-icon svgIcon="sign" mat-list-icon></mat-icon>
        <h4 mat-line>{{ 'SIGNATURE.START_WORKFLOW_DIALOG.SIGN_DOCUMENT_RUN_MENU_ITEM' | translate }}</h4>
        <p mat-line> {{ 'SIGNATURE.START_WORKFLOW_DIALOG.SIGN_DOCUMENT_RUN_MENU_ITEM_SUBTITLE' | translate }} </p>
      </a>
      <a mat-list-item class="p-1" (click)="startSignature(DocumentSignatureRequestProfileType.Delegated)">
        <mat-icon svgIcon="sign" mat-list-icon></mat-icon>
        <h4 mat-line>{{ 'SIGNATURE.START_WORKFLOW_DIALOG.SIGN_DOCUMENT_RUN_OTHERS_MENU_ITEM' | translate }}</h4>
        <p mat-line> {{ 'SIGNATURE.START_WORKFLOW_DIALOG.SIGN_DOCUMENT_RUN_OTHERS_MENU_ITEM_SUBTITLE' | translate }} </p>
      </a>
    </mat-nav-list>
  </ng-template>
</ng-template>

<ng-template cdkPortal #buttonsToolbar>
  <div class="d-flex justify-content-end">
    <div class="mr-2">
      <button mat-button (click)="closeDialog()">{{ 'GENERAL.CLOSE_ACTION' | translate }}</button>
    </div>
    <div>
      <button mat-raised-button color="primary"
              [disabled]="creatingWorkflowRequest || accountLoading || settingsLoading || !createStandaloneSignatureProcess && processLoading || !provideUpload && (documentLoading || pdfLoading || !pdfIsAvailable)"
              (click)="createRequestWorkflow()">{{ 'GENERAL.CREATE_ACTION' | translate }}</button>
    </div>
  </div>
</ng-template>
