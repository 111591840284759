import {ProcessActionActions, ProcessActionActionTypes} from './process-action.actions';
import {adapter, initialState, State} from './process-action.state';

export function reducer(state = initialState, action: ProcessActionActions): State {
  switch (action.type) {
    case ProcessActionActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case ProcessActionActionTypes.LoadAllSuccess:
      return adapter.setAll(action.payload, {
          ...state,
          loading: false,
      });
    case ProcessActionActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false
      };
    case ProcessActionActionTypes.Run:
      return {
        ...state,
        loading: true
      };
    case ProcessActionActionTypes.RunSuccess:
      return {
        ...state,
        loading: false,
      };
    case ProcessActionActionTypes.RunFail:
      return {
        ...state,
        loading: false
      };
  }
  return state;
}

