import {Component, forwardRef, Injector, OnInit} from '@angular/core';
import {DvtxControlValueAccessor} from '../../../../../../shared/modules/base-form-elements/components/1_control-value-accessor-components/DvtxControlValueAccessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ContactVisibility, visibilityTypes} from '../../../../../../models/contact-list-dto.model';

@Component({
  selector: 'dvtx-contact-visibility-chooser',
  templateUrl: './contact-visibility-chooser.component.html',
  styleUrls: ['./contact-visibility-chooser.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactVisibilityChooserComponent),
      multi: true,
    }
  ]
})
export class ContactVisibilityChooserComponent extends DvtxControlValueAccessor implements OnInit {
  visibilities = [
    {
      label: 'CONTACTS.PRIVATE',
      value: visibilityTypes.visiblePrivate,
      icon: 'lock',
      tooltip_active: 'CONTACTS.TYPE_PRIVATE_TOOLTIP_ACTIVE',
      tooltip_inactive: 'CONTACTS.TYPE_PRIVATE_TOOLTIP_INACTIVE'
    },
    {
      label: 'CONTACTS.PUBLIC',
      value: visibilityTypes.visiblePublic,
      icon: 'visibility',
      tooltip_active: 'CONTACTS.TYPE_PUBLIC_TOOLTIP_ACTIVE',
      tooltip_inactive: 'CONTACTS.TYPE_PUBLIC_TOOLTIP_INACTIVE'
    },
    // { label: 'Global', value:  'published', icon: 'business', tooltip: 'Der Kontakt ist für Ihre Geschäftspartner sichtbar.' }
  ];

  contactVisibility = visibilityTypes.visiblePublic;

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {
  }

  writeValue(obj: ContactVisibility): void {
    if (obj) {
      this.contactVisibility = obj.type;
    }
  }

  notify() {
    const notObj: ContactVisibility = {
      type: this.contactVisibility,
      visibleFor: ''
    };

    this.notifyOnChange(notObj);
    this.notifyOnTouch();
  }

}
