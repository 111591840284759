import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Wizard} from '../../../models/wizard';

@Component({
  selector: 'dvtx-wizard-bar-copy',
  templateUrl: './wizard-bar-copy.component.html',
  styleUrls: ['./wizard-bar-copy.component.scss']
})

export class WizardCopyComponent {
  @Input() wizards: Wizard[];
  @Input() currentWizard: Wizard;
  @Output() selectWizard: EventEmitter<Wizard> = new EventEmitter();

  constructor() {
  }

  setCurrentWizard(wizard: Wizard): void {
    // previous wizard
    const prevWizard = this.wizards[wizard.id - 1];

    // check if prevwizard exists(means that current wizard isn't first)
    // and is filled or is not locked.
    if (prevWizard && !prevWizard.isFilled || wizard.isLocked) {
      return;
    }
    this.selectWizard.emit(wizard);
  }
}
