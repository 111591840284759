import { Component, Input } from '@angular/core';
import { ShoppingCart } from 'app/shared/modules/api/models/shopping-cart';

@Component({
  selector: 'dvtx-cart-details',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.scss']
})
export class CartDetailsComponent {
  @Input() cart: ShoppingCart;
}
