import {Injectable} from '@angular/core';
import {catchError, map, mapTo, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {CppApiService} from '../../services/cpp-api.service';
import {Store} from '@ngrx/store';
import {
  AddAttribute,
  AddAttributeGroup,
  AddAttributeGroupSuccess,
  AddAttributeSuccess,
  AddFurtherAddress,
  AddFurtherAddressSuccess,
  AddFurtherEmail,
  AddFurtherEmailSuccess,
  AddFurtherPhoneNumber,
  AddFurtherPhoneNumberSuccess,
  ContactActionTypes,
  DeleteAttribute,
  DeleteAttributeGroup,
  DeleteAttributeGroupSuccess,
  DeleteAttributeSuccess,
  DeleteContact,
  DeleteContactSuccess,
  ImportContacts,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  NewContact,
  Reload,
  RemoveFurtherAddress,
  RemoveFurtherAddressSuccess,
  RemoveFurtherEmail,
  RemoveFurtherEmailSuccess,
  RemoveFurtherPhoneNumber,
  RemoveFurtherPhoneNumberSuccess
} from './contact.actions';
import {
  ConstactListDtoResponseElement,
  ContactListDto,
  ContactListDtoReponse,
  contactListDtoType,
  ImportContactRow
} from '../../models/contact-list-dto.model';
import {ContactItemResponse} from '../../models/contact-item';
import {ContactOrganizationBaseForm} from '../../modules/organization/models/contact-organization-base-form';
import {ContactPersonBaseForm} from '../../modules/organization/models/person-contact-base-form';
import {Organization} from '../../models/organization.model';
import {OrganizationSelectors} from '../organization'
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationActions} from '../notification';
import {
  SimpleAddress,
  SimpleEmailAddress,
  SimplePhoneNumber,
  SimpleSingleAddressResponse,
  SimpleSingleEmailAddressResponse
} from '../../modules/contacts/models/contact.interface'
import {
  ContactAttribute,
  ContactAttributeGroup,
  ContactAttributeGroupResponse
} from '../../models/contact-attributes.model';

@Injectable()
export class ContactEffects {
  @Effect()
  loadAll = this.actions.pipe(
    ofType(ContactActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this.cppApiService.get<ContactListDtoReponse>('organization/' + action.ofOrga.id + '/addressbook/' + action.ofOrga.addressbookId + '/contacts').pipe(
        map((res: ContactListDtoReponse) => {
          const timestamp = new Date().toDateString();
          const dtos: ContactListDto[] = res.data.map((item: ConstactListDtoResponseElement) => {
            const [lastName, firstName] = item.attributes.name.split(', ');
            return {
              id: item.id,
              key: `${item.id}|${timestamp}`,
              name: item.attributes.name,
              firstName: firstName || '',
              lastName: lastName || '',
              telephone: item.attributes.telephone,
              email: item.attributes.email,
              type: contactListDtoType[item.attributes.type],
              belongsToOrganizationId: action.ofOrga.id,
                  hasAccount: item.attributes.has_account,
                  assignedPeople: item.attributes.assigned_people,
                  assignedOrganization: item.attributes.related_orgas ? item.attributes.related_orgas.map((orga) => {
                    return {name: orga.name, legalFormId: orga.legal_form_id};
                  }) : [],
                  contactVisibility: item.attributes.contact_visibility,
                  legalForm: item.attributes.legal_form_id,
                  createdBy: item.attributes.created_by
              }
            });
            return new LoadAllSuccess(dtos);
          }),
          catchError((err) => of(new NotificationActions.ShowHttpError(err)))
        )
      }
    )
  );

  @Effect()
  addAttributeGroup = this.actions.pipe(
    ofType(ContactActionTypes.AddAttributeGroup),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected).pipe(map(x => x))),
    switchMap(([action, org]: [AddAttributeGroup, Organization]) => {
      return this.cppApiService
        .post<any>(`organization/${org.id}/addressbook/${org.addressbookId}/contacts/${action.contactId}/attrgroup`, {
          name: action.groupname
        }).pipe(
          switchMap((res: ContactAttributeGroupResponse) => {
            const ret: ContactAttributeGroup = {
              id: res.data.id,
              name: res.data.attributes.name,
                items: [],
              };
            return [
              new AddAttributeGroupSuccess(ret, action.contactId),
            ]
            }
          ),
          catchError((err) => {
            return of(new NotificationActions.ShowHttpError(err))
          })
        )
    })
  );

  @Effect()
  addAttribute = this.actions.pipe(
    ofType(ContactActionTypes.AddAttribute),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected).pipe(map(x => x))),
    switchMap(([action, org]: [AddAttribute, Organization]) => {
      return this.cppApiService
        .post<any>(`organization/${org.id}/addressbook/${org.addressbookId}/contacts/${action.contactId}/attrgroup/${action.attributeGroupId}/attr`, {
          key: action.key,
          value: action.value
        }).pipe(
          switchMap((res: any) => {
            const ret: ContactAttribute = {
              id: res.data.id,
              key: res.data.attributes.attributekey,
              value: res.data.attributes.attributevalue
            };
            return [
              new AddAttributeSuccess(ret, action.attributeGroupId, action.contactId)
            ]
          }),
          catchError((err) => {
            return of(new NotificationActions.ShowHttpError(err))
          })
        )
    })
  );

  @Effect()
  deleteAttributeGroup = this.actions.pipe(
    ofType(ContactActionTypes.DeleteAttributeGroup),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected).pipe(map(x => x))),
    switchMap(([action, org]: [DeleteAttributeGroup, Organization]) => {
      return this.cppApiService
        .delete(`organization/${org.id}/addressbook/${org.addressbookId}/contacts/${action.contactId}/attrgroup/${action.attributeGroupId}`)
        .pipe(
          switchMap((res: ContactAttributeGroupResponse) => {
              return [
                new DeleteAttributeGroupSuccess(action.contactId, action.attributeGroupId),
              ]
            }
          ),
          catchError((err) => {
            return of(new NotificationActions.ShowHttpError(err))
          })
        )
    })
  );

  @Effect()
  deleteAttribute = this.actions.pipe(
    ofType(ContactActionTypes.DeleteAttribute),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected).pipe(map(x => x))),
    switchMap(([action, org]: [DeleteAttribute, Organization]) => {
      return this.cppApiService
        .delete(`organization/${org.id}/addressbook/${org.addressbookId}/contacts/${action.contactId}/attrgroup/${action.attributeGroupId}/attr/${action.attributeId}`)
        .pipe(
          switchMap((res: any) => {
            return [
              new DeleteAttributeSuccess(action.contactId, action.attributeGroupId, action.attributeId)
            ]
          }),
          catchError((err) => {
            return of(new NotificationActions.ShowHttpError(err))
          })
        )
    })
  );

  @Effect()
  reload$ = this.actions.pipe(
    ofType(ContactActionTypes.Reload),
    map((action: Reload) => {
        return new LoadAll(action.organization);
      }
    )
  );

  @Effect()
  loadContact = this.actions.pipe(
    ofType(ContactActionTypes.LoadOne),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected).pipe(map(x => x))),
    switchMap(([action, org]: [LoadOne, Organization]) => {
      return this.cppApiService.get<any>(`organization/${org.id}/addressbook/${org.addressbookId}/contacts/${action.id}`).pipe(
        switchMap((res: any) => {
          const timestamp = new Date().toDateString();
          const item = res.data;
          const dto = {
            id: item.id,
            key: `${item.id}|${timestamp}`,
            name: item.attributes.name,
            firstName: item.attributes.first_name || '',
            lastName: item.attributes.last_name || '',
            telephone: item.attributes.telephone,
            email: item.attributes.email,
            type: contactListDtoType[item.attributes.type],
            belongsToOrganizationId: org.id,
            hasAccount: item.attributes.has_account,
            assignedPeople: item.attributes.assigned_people,
            assignedOrganization: item.attributes.related_orgas ? item.attributes.related_orgas.map((orga) => {
              return {name: orga.name, legalFormId: orga.legal_form_id};
            }) : [],
            contactVisibility: item.attributes.contact_visibility,
            legalForm: item.attributes.legal_form_id,
            createdBy: item.attributes.created_by
          };
          return [
            new LoadOneSuccess(dto)
          ];
        }),
        catchError((err) => {
          console.error(err);
          return of(new NotificationActions.ShowHttpError(err))
        })
      )
    })
  );

  @Effect()
  deleteContact = this.actions.pipe(
    ofType(ContactActionTypes.DeleteContact),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected).pipe(map(x => x))),
    switchMap(([action, org]: [DeleteContact, Organization]) => {
      return this.cppApiService.delete<any>(`organization/${org.id}/addressbook/${org.addressbookId}/contacts/${action.id}`).pipe(
        switchMap((res: any) =>
          [
            new DeleteContactSuccess(action.id),
            new LoadAll(org)
          ]
        ),
        catchError((err) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  );

  @Effect()
  newContact = this.actions.pipe(
    ofType(ContactActionTypes.NewContact),
    switchMap((action: NewContact) => {
      let endpoint: string;
      let body: any;
      if (action.contactToCreate.discriminator === 'ContactOrganizationBaseForm') {
        endpoint = 'organizationcontacts';
        const contact = <ContactOrganizationBaseForm>action.contactToCreate;

        body = {
          name: contact.contactOrganization.name,
          email: contact.contactOrganization.mainEmailAddress.toCppRequest(),
          address: contact.contactOrganization.mainAddress.toCppRequest(),
          phone: contact.contactOrganization.mainPhoneNumber.toCppRequest(),
          legal_form_id: String(contact.contactOrganization.legalFormId),
          contact_visibility: action.visibility.type,
          visible_for_id: action.visibility.visibleFor,
          organization_type_id: String(contact.contactOrganization.organization_type_id) || '',
        }

      } else if (action.contactToCreate.discriminator === 'ContactPersonBaseForm') {
        endpoint = 'personcontacts';
        const contact = <ContactPersonBaseForm>action.contactToCreate;

        body = {
          natural_person_profile: {
            first_name: contact.contactPerson.firstName,
            last_name: contact.contactPerson.lastName,
            title_id: String(contact.contactPerson.title),
          },
          email: contact.contactPerson.mainEmailAddress.toCppRequest(),
          address: contact.contactPerson.mainAddress.toCppRequest(),
          phone: contact.contactPerson.mainPhoneNumber.toCppRequest(),
          contact_visibility: action.visibility.type,
          visible_for_id: action.visibility.visibleFor
        }
      }
        return this.cppApiService.post<ContactItemResponse>('organization/' + action.ofOrga.id + '/addressbook/' + action.ofOrga.addressbookId + '/' + endpoint, body).pipe(
          map((res: ContactItemResponse) => {
            return new LoadAll(action.ofOrga);
          }),
          catchError((err) => of(new NotificationActions.ShowHttpError(err)))
        )
      }
    )
  );


  @Effect()
  addFurtherEmail = this.actions.pipe(
    ofType(ContactActionTypes.AddFurtherEMail),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [AddFurtherEmail, Organization]) => {
      return this.cppApiService.post<SimpleSingleEmailAddressResponse>(`organization/${org.id}/addressbook/${org.addressbookId}/contacts/${action.payload.contactId}/email`, action.payload.emailAddress.toCppRequest()).pipe(
        map((res: SimpleSingleEmailAddressResponse) => {
          const emailAddress = new SimpleEmailAddress();
          emailAddress.id = res.data.id;
          emailAddress.emailAddress = res.data.attributes.email_address;
          emailAddress.locationOrType = res.data.attributes.address_type_id;
          return new AddFurtherEmailSuccess({
            contactId: action.payload.contactId,
            emailAddress
          })
        }),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  );

  @Effect()
  removeFurtherEmail = this.actions.pipe(
    ofType(ContactActionTypes.RemoveFurtherEMail),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [RemoveFurtherEmail, Organization]) => {
      return this.cppApiService.delete<any>(`organization/${org.id}/addressbook/${org.addressbookId}/contacts/${action.payload.contactId}/email/${action.payload.emailAddressId}`).pipe(
        map(res => new RemoveFurtherEmailSuccess(action.payload.contactId, action.payload.emailAddressId)),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  );

  @Effect()
  addFurtherAddress = this.actions.pipe(
    ofType(ContactActionTypes.AddFurtherAddress),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [AddFurtherAddress, Organization]) => {
      return this.cppApiService.post<SimpleSingleAddressResponse>(`organization/${org.id}/addressbook/${org.addressbookId}/contacts/${action.payload.contactId}/address`, action.payload.address.toCppRequest()).pipe(
        map((res: SimpleSingleAddressResponse) => {
          const address = new SimpleAddress()
          address.id = res.data.id;
          address.city = res.data.attributes.city;
          address.countryName = res.data.attributes.country;
          address.locationOrType = res.data.attributes.address_type_id;
          address.street = res.data.attributes.street;
          address.streetNo = res.data.attributes.street_number;
          address.zip = res.data.attributes.post_code;

          return new AddFurtherAddressSuccess({
            contactId: action.payload.contactId,
            address
          });
        }),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  );

  @Effect()
  removeFurtherAddress = this.actions.pipe(
    ofType(ContactActionTypes.RemoveFurtherAddress),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [RemoveFurtherAddress, Organization]) => {
      return this.cppApiService.delete<any>(`organization/${org.id}/addressbook/${org.addressbookId}/contacts/${action.payload.contactId}/address/${action.payload.addressId}`).pipe(
        map(res => new RemoveFurtherAddressSuccess(action.payload.contactId, action.payload.addressId)),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  );

  @Effect()
  addFurtherPhoneNumber = this.actions.pipe(
    ofType(ContactActionTypes.AddFurtherPhoneNumber),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [AddFurtherPhoneNumber, Organization]) => {
      return this.cppApiService.post<any>(`organization/${org.id}/addressbook/${org.addressbookId}/contacts/${action.payload.contactId}/phone`, action.payload.phoneNumber.toCppRequest()).pipe(
        map(res => {
          const phoneNumber = new SimplePhoneNumber();
          phoneNumber.id = res.data.id;
          phoneNumber.countryCode = res.data.attributes.country_code;
          phoneNumber.phoneNumber = res.data.attributes.phone_number;
          phoneNumber.locationOrType = res.data.attributes.address_type_id;

          return new AddFurtherPhoneNumberSuccess({
            contactId: action.payload.contactId,
            phoneNumber
          })
        }),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  );

  @Effect()
  removeFurtherPhoneNumber = this.actions.pipe(
    ofType(ContactActionTypes.RemoveFurtherPhoneNumber),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [RemoveFurtherPhoneNumber, Organization]) => {
      return this.cppApiService.delete<any>(`organization/${org.id}/addressbook/${org.addressbookId}/contacts/${action.payload.contactId}/phone/${action.payload.phoneNumberId}`).pipe(
        mapTo(new RemoveFurtherPhoneNumberSuccess(action.payload.contactId, action.payload.phoneNumberId)),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  );
  @Effect()
  importContacts = this.actions.pipe(
    ofType(ContactActionTypes.ImportContacts),
    switchMap((action: ImportContacts) => {
      const data = action.contactsToCreate.map((contact: ImportContactRow) => {
        const addresses = [];
        const phoneNumbers = [];

        if (contact.street_business || contact.city_business || contact.zip_business || contact.country_business) {
          addresses.push({
            street: contact.street_business,
            post_code: contact.zip_business,
            city: contact.city_business,
            country: contact.country_business,
            address_type_id: '0'
          });
        }

        if (contact.street_private || contact.city_private || contact.zip_private || contact.country_private) {
          addresses.push({
            street: contact.street_private,
            post_code: contact.city_private,
            city: contact.zip_private,
            country: contact.country_private,
            address_type_id: '1'
          });
        }

        if (contact.phone_main) {
          phoneNumbers.push({
            phone_number: contact.phone_main,
            address_type_id: '0'
          });
        }

        if (contact.phone_business) {
          phoneNumbers.push({
            phone_number: contact.phone_business,
            address_type_id: '0'
          });
        }

        if (contact.phone_mobile) {
          phoneNumbers.push({
            phone_number: contact.phone_mobile,
            address_type_id: '1'
          });
        }

        if (contact.phone_private) {
          phoneNumbers.push({
            phone_number: contact.phone_private,
            address_type_id: '1'
          });
        }

        if (contact.fax_business) {
          phoneNumbers.push({
            phone_number: contact.fax_business,
            address_type_id: '2'
          });
        }

        let titleId;

        if (contact.title && ['frau', 'fr.', 'fr', 'ms', 'ms.', 'miss', 'mrs', 'mrs.', 'missis', 'missus'].includes(contact.title.toLowerCase())) {
          titleId = '1';
        } else if (contact.title && ['herr', 'hr.', 'hr', 'mr', 'mr.', 'mister'].includes(contact.title.toLowerCase())) {
          titleId = '0';
        }

        return {
          natural_person_profile: {
            first_name: contact.first_name,
            last_name: contact.last_name,
            title_id: titleId,
          },
          email: {
            email_address: contact.email,
            address_type_id: 'Geschäftlich'
          },
          addresses: addresses,
          phone_numbers: phoneNumbers,
          contact_visibility: action.visibility.type,
          visible_for_id: action.visibility.visibleFor
        };
      });

      return this.cppApiService.post<ContactItemResponse>('organization/' + action.ofOrga.id + '/addressbook/' + action.ofOrga.addressbookId + '/personcontactslist', {data}).pipe(
        map((res: ContactItemResponse) => {
          return new LoadAll(action.ofOrga);
        }),
        catchError(err => of(new LoadAllFail(err)))
      )
    })
  );


  constructor(private actions: Actions,
              private cppApiService: CppApiService,
              private store: Store<any>
  ) {
  }
}
