import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {startWith, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ProcessListingV3Statistics} from 'app/+store/process/process';

@Component({
  selector: 'dvtx-project-overview-stats',
  templateUrl: './project-overview-stats.component.html',
  styleUrls: ['./project-overview-stats.component.scss']
})
export class ProjectOverviewStatsComponent implements OnInit, OnDestroy {
  onDestroy = new Subject<void>();

  chartData: any[];

  colorScheme = { domain: ['#99d9f5', '#00BD00', '#7b8490'] }; // ORANGE, BLUE, GREEN}
  view: any[] = [450, 150];
  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  showReset = true;

  _stats: ProcessListingV3Statistics;
  @Input() set stats(stats: ProcessListingV3Statistics) {
    this._stats = stats;
    this._updateChart(stats);
  }

  lang = 'de';

  @Output() onResetFilter = new EventEmitter();
  @Output() onSelectFilter = new EventEmitter();

  constructor(private _translateSvc: TranslateService,
              private route: Router,
              private _cdr: ChangeDetectorRef) {
    this.chartData = [{
      id: 'open',
      name: this._translateSvc.instant('STATUS.IN_PROGRESS'),
      value: 0
    }, {
      id: 'closed',
      name: this._translateSvc.instant('STATUS.CLOSED'),
      value: 0
    }, {
      id: 'draft',
      name: this._translateSvc.instant('STATUS.DRAFT'),
      value: 0
    }];
  }

  ngOnInit() {
    this._translateSvc.onLangChange
      .pipe(
        startWith({lang: this._translateSvc.currentLang}),
        takeUntil(this.onDestroy))
      .subscribe(lang => {
        this.lang = lang.lang;
        this._updateChart(this._stats, true);
      })
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private _updateChart(stats: ProcessListingV3Statistics, runChangeDetection = false) {
    if (!stats) {
      return;
    }

    this.chartData = [
      {
        id: 'open',
        name: this._translateSvc.instant('STATUS.IN_PROGRESS'),
        value: stats.open
      },
      {
        id: 'closed',
        name: this._translateSvc.instant('STATUS.CLOSED'),
        value: stats.closed
      },
      {
        id: 'draft',
        name: this._translateSvc.instant('STATUS.DRAFT'),
        value: stats.draft
      }
    ];

    if (runChangeDetection) {
      this._cdr.detectChanges();
    }
  }

  resetFilter() {
    this.onResetFilter.emit({ reset: true });
  }

  onSelect(event) {
    this.onSelectFilter.emit(event);
  }
}
