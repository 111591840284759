import {Action} from '@ngrx/store';
import {DmsDocumentExport} from './dms-document-export';
import {ExportType, IDmsDocumentExportParams} from './dms-document-export.interface';

export enum DmsDocumentExportActionTypes {
  Create = '[DmsDocumentExport] Create',
  CreateSuccess = '[DmsDocumentExport] Create Success',
  CreateFail = '[DmsDocumentExport] Create Fail',

  LoadAll = '[DmsDocumentExport] Load All',
  LoadAllSuccess = '[DmsDocumentExport] Load All Success',
  LoadAllFail = '[DmsDocumentExport] Load All Fail',

  LoadOne = '[DmsDocumentExport] Load One',
  LoadOneSuccess = '[DmsDocumentExport] Load One Success',
  LoadOneFail = '[DmsDocumentExport] Load One Fail',

  Remove = '[DmsDocumentExport] Remove',
  RemoveSuccess = '[DmsDocumentExport] Remove Success',
  RemoveFail = '[DmsDocumentExport] Remove Fail',
}

export class LoadAll implements Action {
  readonly type = DmsDocumentExportActionTypes.LoadAll;

  constructor(public processId: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = DmsDocumentExportActionTypes.LoadAllSuccess;

  constructor(public fileExports: DmsDocumentExport[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = DmsDocumentExportActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Create implements Action {
  readonly type = DmsDocumentExportActionTypes.Create;

  constructor(public processId: string, public exportType: ExportType = ExportType.StandardExport, public ids: string[] = null, public params: IDmsDocumentExportParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = DmsDocumentExportActionTypes.CreateSuccess;

  constructor(public fileExport: DmsDocumentExport) {
  }
}

export class CreateFail implements Action {
  readonly type = DmsDocumentExportActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = DmsDocumentExportActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = DmsDocumentExportActionTypes.LoadOneSuccess;

  constructor(public fileExport: DmsDocumentExport) {
  }
}

export class LoadOneFail implements Action {
  readonly type = DmsDocumentExportActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = DmsDocumentExportActionTypes.Remove;

  constructor(public processId: string, public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = DmsDocumentExportActionTypes.RemoveSuccess;

  constructor(public fileExport: DmsDocumentExport) {
  }
}

export class RemoveFail implements Action {
  readonly type = DmsDocumentExportActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export type DmsDocumentExportActions =
  | Create
  | CreateSuccess
  | CreateFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Remove
  | RemoveSuccess
  | RemoveFail;
