import {Action} from '@ngrx/store';
import {ITemplateCategoryParams, WorkflowTemplateCategory} from "./workflow-template-category";

export enum WorkflowTemplateCategoryActionTypes {
  Create = '[WorkflowTemplateCategory] Create',
  CreateSuccess = '[WorkflowTemplateCategory] Create Success',
  CreateFail = '[WorkflowTemplateCategory] Create Fail',
  Delete = '[WorkflowTemplateCategory] Delete',
  DeleteSuccess = '[WorkflowTemplateCategory] Delete Success',
  DeleteFail = '[WorkflowTemplateCategory] Delete Fail',
  LoadAll = '[WorkflowTemplateCategory] Load All',
  LoadAllSuccess = '[WorkflowTemplateCategory] Load All Success',
  LoadAllFail = '[WorkflowTemplateCategory] Load All Fail',
  LoadOne = '[WorkflowTemplateCategory] Load One',
  LoadOneSuccess = '[WorkflowTemplateCategory] Load One Success',
  LoadOneFail = '[WorkflowTemplateCategory] Load One Fail',
  Update = '[WorkflowTemplateCategory] Update',
  UpdateSuccess = '[WorkflowTemplateCategory] Update Success',
  UpdateFail = '[WorkflowTemplateCategory] Update Fail',
}

export class Create implements Action {
  readonly type = WorkflowTemplateCategoryActionTypes.Create;

  constructor(public payload: ITemplateCategoryParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = WorkflowTemplateCategoryActionTypes.CreateSuccess;

  constructor(public category: WorkflowTemplateCategory) {
  }
}

export class CreateFail implements Action {
  readonly type = WorkflowTemplateCategoryActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Delete implements Action {
  readonly type = WorkflowTemplateCategoryActionTypes.Delete;

  constructor(public id: string) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = WorkflowTemplateCategoryActionTypes.DeleteSuccess;

  constructor(public category: WorkflowTemplateCategory) {
  }
}

export class DeleteFail implements Action {
  readonly type = WorkflowTemplateCategoryActionTypes.DeleteFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = WorkflowTemplateCategoryActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = WorkflowTemplateCategoryActionTypes.LoadAllSuccess;

  constructor(public categories: WorkflowTemplateCategory[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = WorkflowTemplateCategoryActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {

  readonly type = WorkflowTemplateCategoryActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = WorkflowTemplateCategoryActionTypes.LoadOneSuccess;

  constructor(public category: WorkflowTemplateCategory) {
  }
}

export class LoadOneFail implements Action {
  readonly type = WorkflowTemplateCategoryActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Update implements Action {

  readonly type = WorkflowTemplateCategoryActionTypes.Update;

  constructor(public id: string, public payload: ITemplateCategoryParams) {
  }
}

export class UpdateSuccess implements Action {
  readonly type = WorkflowTemplateCategoryActionTypes.UpdateSuccess;

  constructor(public category: WorkflowTemplateCategory) {
  }
}

export class UpdateFail implements Action {
  readonly type = WorkflowTemplateCategoryActionTypes.UpdateFail;

  constructor(public payload: any) {
  }
}


export type WorkflowTemplateCategoryActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Update
  | UpdateSuccess
  | UpdateFail;
