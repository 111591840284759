import {Injectable} from '@angular/core';
import {catchError, map, mapTo, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {CppApiService} from 'app/services/cpp-api.service';
import {
  AddFurtherAddress,
  AddFurtherAddressSuccess,
  AddFurtherEmail,
  AddFurtherEmailSuccess,
  AddFurtherPhoneNumber,
  AddFurtherPhoneNumberSuccess,
  LoadMyFail,
  LoadMySuccess,
  NaturalPersonActionTypes,
  RemoveFurtherAddress,
  RemoveFurtherAddressSuccess,
  RemoveFurtherEmail,
  RemoveFurtherEmailSuccess,
  RemoveFurtherPhoneNumber,
  RemoveFurtherPhoneNumberSuccess,
  UpdateMy,
  UpdateMySuccess
} from './natural-person.actions';
import {naturalPersonfromJsonAPI, NaturalPersonResponse} from '../../models/natural-person.model';
import {
  SimpleAddress,
  SimpleEmailAddress,
  SimplePhoneNumber,
  SimpleSingleAddressResponse,
  SimpleSingleEmailAddressResponse
} from '../../modules/contacts/models/contact.interface';
import {NotificationActions} from '../notification';
import {HttpErrorResponse} from '@angular/common/http';
import {Store} from '@ngrx/store';
import * as JsonAPI from '../../models/json-api.model'
import {SyncApiProfileService} from 'app/shared/modules/api/services/sync-api-profile.service';

@Injectable()
export class NaturalPersonEffects {
  @Effect()
  loadMyNP = this.actions.pipe(
    ofType(NaturalPersonActionTypes.LoadMy),
    switchMap(() => {
      return this.cppApiService.get<JsonAPI.Root<NaturalPersonResponse>>('person').pipe(
        map(res => new LoadMySuccess(naturalPersonfromJsonAPI(res.data, res.included))),
        catchError(err => of(new LoadMyFail(err)))
      )
    })
  );

  @Effect()
  UpdateMy = this.actions.pipe(
    ofType(NaturalPersonActionTypes.UpdateMy),
    switchMap((action: UpdateMy) => {
      return this.cppApiService.put<JsonAPI.Root<NaturalPersonResponse>>(`person/${action.payload.id}/profile`, {
        title_id: String(action.payload.title),
        name: action.payload.lastName + ', ' + action.payload.firstName,
        first_name: action.payload.firstName,
        last_name: action.payload.lastName,
        main_email_address: action.payload.mainEmailAddress && action.payload.mainEmailAddress.toCppRequest(),
        main_email_id: action.payload.mainEmailAddress.id,
        main_address: action.payload.mainAddress && action.payload.mainAddress.toCppRequest(),
        main_address_id: action.payload.mainAddress ? action.payload.mainAddress.id : '',
        main_phone: action.payload.mainPhoneNumber && action.payload.mainPhoneNumber.toCppRequest(),
        main_phone_id: action.payload.mainPhoneNumber ? action.payload.mainPhoneNumber.id : '',
        honorific: action.payload.honorific,
      }).pipe(
        map(my => new UpdateMySuccess(naturalPersonfromJsonAPI(my.data, my.included)))
      )
    })
  );

  // Synchronizes the CPP user profile with the proxy object of the API.
  @Effect({dispatch: false})
  SyncApiProfile = this.actions.pipe(
    ofType(NaturalPersonActionTypes.UpdateMy),
    switchMap((action: UpdateMy) => {
      return this.syncApiProfileSvc.update({
        title_id: action.payload.title,
        first_name: action.payload.firstName,
        last_name: action.payload.lastName,
        honorific: action.payload.honorific
      })
    })
  );

  @Effect()
  addFurtherEmail = this.actions.pipe(
    ofType(NaturalPersonActionTypes.AddFurtherEMail),
    switchMap((action: AddFurtherEmail) => {
      return this.cppApiService.post<SimpleSingleEmailAddressResponse>(`person/${action.payload.personId}/email`, action.payload.emailAddress.toCppRequest()).pipe(
        map((res: SimpleSingleEmailAddressResponse) => {
          const emailAddress = new SimpleEmailAddress();
          emailAddress.id = res.data.id;
          emailAddress.emailAddress = res.data.attributes.email_address;
          emailAddress.locationOrType = res.data.attributes.address_type_id;
          return new AddFurtherEmailSuccess({
            personId: action.payload.personId,
            emailAddress
          })
        }),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  );

  @Effect()
  removeFurtherEmail = this.actions.pipe(
    ofType(NaturalPersonActionTypes.RemoveFurtherEMail),
    switchMap((action: RemoveFurtherEmail) => {
      return this.cppApiService.delete<any>(`person/${action.payload.personId}/email/${action.payload.emailAddressId}`).pipe(
        map(() => new RemoveFurtherEmailSuccess(action.payload.personId, action.payload.emailAddressId)),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  );

  @Effect()
  addFurtherAddress = this.actions.pipe(
    ofType(NaturalPersonActionTypes.AddFurtherAddress),
    switchMap((action: AddFurtherAddress) => {
      return this.cppApiService.post<SimpleSingleAddressResponse>(`person/${action.payload.personId}/address`, action.payload.address.toCppRequest()).pipe(
        map((res: SimpleSingleAddressResponse) => {
          const address = new SimpleAddress()
          address.id = res.data.id;
          address.city = res.data.attributes.city;
          address.countryName = res.data.attributes.country;
          address.locationOrType = res.data.attributes.address_type_id;
          address.street = res.data.attributes.street;
          address.streetNo = res.data.attributes.street_number;
          address.zip = res.data.attributes.post_code;

          return new AddFurtherAddressSuccess({
            personId: action.payload.personId,
            address
          });
        }),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  );

  @Effect()
  removeFurtherAddress = this.actions.pipe(
    ofType(NaturalPersonActionTypes.RemoveFurtherAddress),
    switchMap((action: RemoveFurtherAddress) => {
      return this.cppApiService.delete<any>(`person/${action.payload.personId}/address/${action.payload.addressId}`).pipe(
        map(() => new RemoveFurtherAddressSuccess(action.payload.personId, action.payload.addressId)),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  );

  @Effect()
  addFurtherPhoneNumber = this.actions.pipe(
    ofType(NaturalPersonActionTypes.AddFurtherPhoneNumber),
    switchMap((action: AddFurtherPhoneNumber) => {
      return this.cppApiService.post<any>(`person/${action.payload.personId}/phone`, action.payload.phoneNumber.toCppRequest()).pipe(
        map(res => {
          const phoneNumber = new SimplePhoneNumber();
          phoneNumber.id = res.data.id;
          phoneNumber.countryCode = res.data.attributes.country_code;
          phoneNumber.phoneNumber = res.data.attributes.phone_number;
          phoneNumber.locationOrType = res.data.attributes.address_type_id;

          return new AddFurtherPhoneNumberSuccess({
            personId: action.payload.personId,
            phoneNumber
          })
        }),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  );

  @Effect()
  removeFurtherPhoneNumber = this.actions.pipe(
    ofType(NaturalPersonActionTypes.RemoveFurtherPhoneNumber),
    switchMap((action: RemoveFurtherPhoneNumber) => {
      return this.cppApiService.delete<any>(`person/${action.payload.personId}/phone/${action.payload.phoneNumberId}`).pipe(
        mapTo(new RemoveFurtherPhoneNumberSuccess(action.payload.personId, action.payload.phoneNumberId)),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  );

  constructor(private actions: Actions,
              private store: Store<any>,
              private cppApiService: CppApiService,
              private syncApiProfileSvc: SyncApiProfileService
  ) {
  }
}
