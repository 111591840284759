import {Action} from '@ngrx/store';
import {UserAvatar} from './user-avatar';

export enum UserAvatarActionTypes {
  LoadOne = '[UserAvatar] Load One',
  LoadOneByHttp = '[UserAvatar] Load One By Http',
  LoadOneSuccess = '[UserAvatar] Load One Success',
  LoadOneFail = '[UserAvatar] Load One Fail',
  LoadMany = '[UserAvatar] Load Many',
  LoadManySuccess = '[UserAvatar] Load Many Success',
  LoadManyFail = '[UserAvatar] Load Many Fail',
  Updated = '[UserAvatar] Update',
  UpdatedSuccess = '[UserAvatar] Update Success',
  UpdatedFail = '[UserAvatar] Update Fail',
}

export class LoadOne implements Action {
  readonly type = UserAvatarActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneByHttp implements Action {
  readonly type = UserAvatarActionTypes.LoadOneByHttp;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = UserAvatarActionTypes.LoadOneSuccess;

  constructor(public avatar: UserAvatar) {
  }
}

export class LoadOneFail implements Action {
  readonly type = UserAvatarActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class LoadMany implements Action {
  readonly type = UserAvatarActionTypes.LoadMany;

  constructor(public ids: string[]) {
  }
}

export class LoadManySuccess implements Action {
  readonly type = UserAvatarActionTypes.LoadManySuccess;

  constructor(public avatar: UserAvatar[]) {
  }
}

export class LoadManyFail implements Action {
  readonly type = UserAvatarActionTypes.LoadManyFail;

  constructor(public payload: any) {
  }
}


export class Updated implements Action {
  readonly type = UserAvatarActionTypes.Updated;

  constructor(public avatar: UserAvatar) {
  }
}

export class UpdatedSuccess implements Action {
  readonly type = UserAvatarActionTypes.UpdatedSuccess;

  constructor(public avatar: UserAvatar) {
  }
}

export class UpdatedFail implements Action {
  readonly type = UserAvatarActionTypes.UpdatedFail;

  constructor(public avatar: any) {
  }
}


export type UserAvatarActions =
  | LoadOne
  | LoadOneByHttp
  | LoadOneSuccess
  | LoadOneFail
  | LoadMany
  | LoadManySuccess
  | LoadManyFail
  | Updated
  | UpdatedSuccess
  | UpdatedFail;
