import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Organization} from 'app/models/organization.model';
import { MatDialog } from '@angular/material/dialog';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {OrganizationSelectors} from 'app/+store';
import {LicenceUpgradeDialogComponent} from 'app/shared/components/dialogs/licence-upgrade-dialog/licence-upgrade-dialog.component';
import {CreateOrganizationDialogContainerComponent} from 'app/modules/address-book/modules/address-book-table/containers/create-organization-dialog-container/create-organization-dialog-container.component';
import {Router} from '@angular/router';

@Component({
    selector: 'dvtx-quickshare-quickstarter',
    templateUrl: './quickshare-quickstarter.component.html',
    styleUrls: ['./quickshare-quickstarter.component.scss']
})
export class QuickshareQuickstarterComponent implements OnInit {
    organizations: Observable<Organization[]>;
    organizationsCount: number;

    constructor(public dialog: MatDialog, private _store: Store<AppState>, private _translateSvc: TranslateService, private router: Router) {
  }

  ngOnInit() {
    this.organizations = this._store.select(OrganizationSelectors.getAllOrganizations);
    this.organizations.subscribe( (organizations) => {
      this.organizationsCount = organizations.length;
    } )
  }

  redirectToLink(link) {
    if (this.organizationsCount === 0) {
      this.dialog.closeAll();
      this.dialog.open(LicenceUpgradeDialogComponent, {
        data: {
          title: 'REGISTRATION.ORGANIZATION_IS_NEEDED_TITLE',
          message: 'REGISTRATION.ORGANIZATION_IS_NEEDED_MESSAGE',
          onSubmitAction: () => {
            this.dialog.open(CreateOrganizationDialogContainerComponent);
          },
            submitButtonTitle: this._translateSvc.instant('ORGANIZATION.CREATION.CREATE')
        },
        width: '400px',
      });
    } else {
      this.router.navigate(link);
    }

  }
}
