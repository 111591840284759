import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {CmsArticle} from './cms-article';
import {CmsArticleBuilder} from './cms-article.builder';

@Injectable()
export class CmsArticleService {
  readonly BASE_PATH = 'api/v1/cms/articles';

  constructor(private _http: ApiResourceService) {}

  getAll(): Observable<CmsArticle[]> {
    const builder = new CmsArticleBuilder();
    return <Observable<CmsArticle[]>>this._http.get<CmsArticleBuilder, CmsArticle>(builder, `${this.BASE_PATH}`);
  }

  getOne(id: string): Observable<CmsArticle> {
    const builder = new CmsArticleBuilder();
    return <Observable<CmsArticle>>this._http.get<CmsArticleBuilder, CmsArticle>(builder, `${this.BASE_PATH}/${id}`);
  }

  create(article: CmsArticle): Observable<CmsArticle> {
    const builder = new CmsArticleBuilder();
    const payload = builder.toRequest(article);
    return <Observable<CmsArticle>>this._http.post<CmsArticleBuilder, CmsArticle>(builder, `${this.BASE_PATH}`, payload);
  }

  update(article: CmsArticle): Observable<CmsArticle> {
    const builder = new CmsArticleBuilder();
    const payload = builder.toRequest(article);
    return <Observable<CmsArticle>>this._http.put<CmsArticleBuilder, CmsArticle>(builder, `${this.BASE_PATH}/${article.id}`, payload);
  }

  destroy(articleId: string): Observable<CmsArticle> {
    const builder = new CmsArticleBuilder();
    return <Observable<CmsArticle>>this._http.del<CmsArticleBuilder, CmsArticle>(builder, `${this.BASE_PATH}/${articleId}`);
  }
}
