import { PaymentOption } from '../models/payment-option';

export class PaymentOptionBuilder {
  public static toRequest(paymentOption: PaymentOption) {
    return {
      data: {
        attributes: {
          first_name: paymentOption.firstName,
          last_name: paymentOption.lastName,
          iban: paymentOption.iban,
          bic: paymentOption.bic,
          confirmed: paymentOption.confirmed
        }
      }
    }
  }

  public static fromResponse(data): PaymentOption {
    return new PaymentOption(
      data.id,
      data.attributes.first_name,
      data.attributes.last_name,
      data.attributes.iban,
      data.attributes.bic,
      data.attributes.confirmed_at
    )
  }
}
