import {NgModule} from '@angular/core';
import {QuickstartDirective} from './directives/quickStart.directive';
import {SharedModule} from '../../shared/shared.module';
import {QuickstartBarComponent} from './containers/quickstart-bar/quickstart-bar.component';
import {QuickUploadDialogContainerComponent} from './containers/quick-upload-dialog/quick-upload-dialog.component';
import {QuickUploadSuccessDialogContainerComponent} from './containers/quick-upload-success/quick-upload-success-dialog.component';
import {QuickShareDialogContainerComponent} from './containers/quick-share-dialog/quick-share-dialog.component';
import {UploadIconComponent} from './components/upload-icon/upload-icon.component';
import {PersonContactComponent} from './components/person-contact/person-contact.component';
import {QuickUploadComponent} from './components/quick-upload/quick-upload.component';
import {QuickContactDialogComponent} from './containers/quick-contact-dialog/quick-contact-dialog.component';
import {UploadFileListingComponent} from './components/upload-file-listing/upload-file-listing.component';
import {UploadFileItemComponent} from './components/upload-file-item/upload-file-item.component';
import {UploadFileDetailComponent} from './components/upload-file-detail/upload-file-detail.component';
import {QuickUploadSuccessDialogContentComponent} from './components/quick-upload-success-dialog/quick-upload-dialog-success-content.component';
import {ShareIconComponent} from './components/quick-share-icon/quick-share.component';
import {QuickShareFormComponent} from './components/quick-share-form/quick-share-form.component';
import {QuickShareChooseDialogComponent} from './components/quick-share-file-choose/quick-share-file-choose-dialog.component';
import {QuickShareSuccessDialogComponent} from './components/quick-share-success/quick-share-success-dialog.component';
import {QuickShareFileControlComponent} from './components/quick-share-file-control/quick-share-file-control.component';
import {SendMessageComponent} from './components/quick-communication-icon/quick-communication-icon.component';
import {QuickCommunicationDialogComponent} from './containers/quick-communication-dialog/quick-communication-dialog.component';
import {QuickCommunicationFormComponent} from './components/quick-communication-form/quick-communication-form.component';
import {ContactsModule} from '../contacts/contacts.module';
import {QcQuickstarterComponent} from './containers/qc-quickstarter/qc-quickstarter.component';
import {QuickshareQuickstarterComponent} from './containers/quickshare-quickstarter/quickshare-quickstarter.component';
import {FilePickerModule} from '../file-picker/file-picker.module';
import {CollectorQuickstarterComponent} from './containers/collector-quickstarter/collector-quickstarter.component';
import {ProjectQuickstarterComponent} from './containers/project-quickstarter/project-quickstarter.component';
import {DocumentSignaturTypeSelectionModule} from "../document-signature/modules/document-signature-type-selection/document-signatur-type-selection.module";
import {FibuQuickstarterComponent} from './containers/fibu-quickstarter/fibu-quickstarter.component';
import {ProjectQuickstarterFromTemplateComponent} from "./containers/project-quickstarter-from-template/project-quickstarter-from-template.component";

@NgModule({
    imports: [
        SharedModule,
        ContactsModule,
        FilePickerModule,
        DocumentSignaturTypeSelectionModule
    ],
    declarations: [
        QuickstartDirective,
        QcQuickstarterComponent,
        UploadIconComponent,
        QuickstartBarComponent,
        PersonContactComponent,
        SendMessageComponent,
        QuickUploadDialogContainerComponent,
        QuickUploadSuccessDialogContainerComponent,
        QuickUploadComponent,
        QuickContactDialogComponent,
        UploadFileListingComponent,
        UploadFileItemComponent,
        UploadFileDetailComponent,
        QuickUploadSuccessDialogContentComponent,
        QuickShareDialogContainerComponent,
        ShareIconComponent,
        QuickShareFormComponent,
        QuickShareChooseDialogComponent,
        QuickShareSuccessDialogComponent,
        QuickShareFileControlComponent,
        QuickCommunicationDialogComponent,
        QuickCommunicationFormComponent,
        QuickshareQuickstarterComponent,
        CollectorQuickstarterComponent,
        ProjectQuickstarterComponent,
        FibuQuickstarterComponent,
        ProjectQuickstarterFromTemplateComponent
    ],
    providers: [],
    exports: [
        QuickstartBarComponent,
        QuickstartDirective
    ]
})
export class QuickstartModule {
}
