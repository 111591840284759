import {ContactImportItem} from '../models/contact-import-item';
import {AppendixBuilder, SpecificAppendixBuilder} from './appendix.builder';
import {SpecializedAppendix} from '../models/appendix';

export namespace ContactImportItemBuilder {
  export function fromResponse(response, catalog = null): ContactImportItem {
    const attrs = response.attributes;

    return new ContactImportItem(
      attrs.id,
      attrs.identifier,
      attrs.abbreviation,
      attrs.organization_name,
      attrs.country_code,
      attrs.first_name,
      attrs.last_name,
      attrs.role,
      attrs.email,
      attrs.phone,
      attrs.mobile,
      attrs.verified_at,
      attrs.deleted_at,
      attrs.verified_by_id,
      attrs.due_date,
      attrs.notification_language,
      attrs.modified_by_tbp_at,
      attrs.modified_by_tbp_name,
      attrs.reviewer_first_name,
      attrs.reviewer_last_name,
      attrs.reviewer_confirmed,
      SpecificAppendixBuilder.fromResponseList(response.appendices),
      response.appendices_ids,
      attrs.created_by_email,
      attrs.created_by_first_name,
      attrs.created_by_last_name,
      attrs.created_by_external_at,
      attrs.last_updated_by_email,
      attrs.last_updated_by_first_name,
      attrs.last_updated_by_last_name,
      attrs.comment,
      attrs.created_at,
      attrs.updated_at
    );
  }

  export function fromSimpleResponse(response, catalog = null): ContactImportItem {
    return new ContactImportItem(
      response.id,
      response.identifier,
      response.abbreviation,
      response.organization_name,
      response.country_code,
      response.first_name,
      response.last_name,
      response.role,
      response.email,
      response.phone,
      response.mobile,
      response.verified_at,
      response.deleted_at,
      response.verified_by_id,
      response.due_date,
      response.notification_language,
      response.modified_by_tbp_at,
      response.modified_by_tbp_name,
      response.reviewer_first_name,
      response.reviewer_last_name,
      response.reviewer_confirmed,
      SpecificAppendixBuilder.fromResponseList(response.appendices),
      response.appendices_ids,
      response.created_by_email,
      response.created_by_first_name,
      response.created_by_last_name,
      response.created_by_external_at,
      response.last_updated_by_email,
      response.last_updated_by_first_name,
      response.last_updated_by_last_name,
      response.comment,
      response.created_at,
      response.updated_at
    );
  }

  export function fromResponseList(dataArray): ContactImportItem[] {
    const ret: ContactImportItem[] = [];
    for (const element of dataArray) {
      ret.push(ContactImportItemBuilder.fromSimpleResponse(element))
    }
    return ret;
  }

  export function toRequest(record: ContactImportItem) {
    return {
      data: {
        id: record.id,
        type: record.type,
        attributes: {
          id: record.id,
          identifier: record.identifier,
          abbreviation: record.abbreviation,
          organization_name: record.organizationName,
          country_code: record.countryCode,
          first_name: record.firstName,
          last_name: record.lastName,
          role: record.role,
          email: record.email,
          phone: record.phone,
          mobile: record.mobile,
          verified_at: record.verifiedAt,
          deleted_at: record.deletedAt,
          verified_by_id: record.verifiedById,
          due_date: record.dueDate,
          reviewer_confirmed: record.reviewerConfirmed,
          notification_language: record.notificationLanguage,
          modified_by_tbp_at: record.modifiedByTbpAt,
          modified_by_tbp_name: record.modifiedByTbpName
        }
      }
    };
  }

  export function toDependentRequestList(list: ContactImportItem[]) {
    return list.map(x => ContactImportItemBuilder.toDependentRequest(x));
  }

  export function toDependentRequest(record: ContactImportItem) {
    return {
      id: record.id,
      identifier: record.identifier,
      abbreviation: record.abbreviation,
      organization_name: record.organizationName,
      country_code: record.countryCode,
      first_name: record.firstName,
      last_name: record.lastName,
      role: record.role,
      email: record.email,
      country_phone_code: '+49',
      phone: record.phone,
      country_mobile_code: '+49',
      mobile: record.mobile,
      verified_at: record.verifiedAt,
      notification_language: record.notificationLanguage || 'de',
      appendices: record.appendices.map(x => SpecificAppendixBuilder.toRequest(x)),
      appendices_ids: []
    }
  }
}
