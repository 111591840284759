<div *ngIf="!isModal">
  <button color="gray" mat-icon-button cdk-overlay-origin
          (click)="showDropdown = !showDropdown"
          #dropdown="cdkOverlayOrigin"
          [disableRipple]='true'
          [matTooltip]="'NOTIFICATION.NOTIFICATIONS' | translate">
    <mat-icon>notifications</mat-icon>
  </button>
  <ng-template cdk-connected-overlay
               [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
               [cdkConnectedOverlayOrigin]="dropdown"
               [cdkConnectedOverlayHasBackdrop]="true"
               [cdkConnectedOverlayOpen]="showDropdown"
               (backdropClick)="showDropdown = false">
    <ng-container [ngTemplateOutlet]="contentView"></ng-container>
  </ng-template>
</div>

<div *ngIf="isModal">
  <ng-container [ngTemplateOutlet]="contentView"></ng-container>
</div>

<ng-template #contentView>
  <div class="mat-elevation-z8 list--container pt-1 px-4 pb-1 d-flex align-items-start"
       style="flex-direction: column; background-color: white;">
    <h3 class="font-weight-bold dvtx-fg-color dvtx-bg-fivef-blue-400">{{ 'NOTIFICATION.NOTIFICATION_SETTINGS' | translate }}</h3>

    <!-- Show the global info only if notifications are globally set to off -->
    <ng-container *ngIf="globalSettings && !globalSettings.byEmail && globalSettings.byInbox">
      <dvtx-table-text-and-info style="margin-left: -10px; width: 345px; font-size: 12px;"
                                [alignmentPosition]="'start'" [h4]="false"
                                [position]="'before'"
                                [title]="'NOTIFICATION.GLOBALLY_OFF_INFO_INBOX'">
      </dvtx-table-text-and-info>

      <h5 class="mt-1 mb-0 font-weight-bold dvtx-fg-color dvtx-bg-fivef-blue-400">
        {{ 'NOTIFICATION.OVERRIDE_GLOBALLY_OFF_Q' | translate }}
      </h5>
    </ng-container>

    <ng-container *ngIf="globalSettings && !globalSettings.byEmail && !globalSettings.byInbox">
      <dvtx-table-text-and-info style="margin-left: -10px; width: 345px; font-size: 12px;"
                                [alignmentPosition]="'start'" [h4]="false"
                                [position]="'before'"
                                [title]="'NOTIFICATION.GLOBALLY_OFF_INFO_EMAIL'">
      </dvtx-table-text-and-info>

      <h5 class="mt-1 mb-0 font-weight-bold dvtx-fg-color dvtx-bg-fivef-blue-400">
        {{ 'NOTIFICATION.OVERRIDE_GLOBALLY_OFF_Q' | translate }}
      </h5>
    </ng-container>

    <mat-checkbox (change)="onOverrideGlobalSettings($event)"
                  [checked]="overrideGlobalSettings">
      <dvtx-table-text-and-info [alignmentPosition]="'start'"
                                [h4]="false"
                                [position]="'after'"
                                [title]="'NOTIFICATION.OVERRIDE_GLOBAL_OFF'"
                                [tooltip]="'NOTIFICATION.OVERRIDE_GLOBAL_OFF_INFO'">
      </dvtx-table-text-and-info>
    </mat-checkbox>

    <mat-slide-toggle *ngIf="resourceSettings"
                      class="owner-role-toggle mt-1"
                      color="primary"
                      [(ngModel)]="resourceSettings.byInbox"
                      [disabled]="disableInboxNotification"
                      [labelPosition]="'after'"
                      [checked]="resourceSettings?.byInbox"
                      (change)="updateSettings('byInbox', $event)">
      <h5 class="m-0">{{'NOTIFICATION.RECEIVE_INBOX_NOTIFICATION' | translate}}</h5>
    </mat-slide-toggle>

    <h5 class="my-1 font-weight-bold dvtx-fg-color dvtx-bg-fivef-blue-400">{{'NOTIFICATION.GLOBAL_NOTIFICATIONS_TYPES_INFO_2' | translate}}</h5>

    <div class="d-flex align-items-center">
      <dvtx-filter-list-selection-colored style="width: 200px;"
                                          [disabled]="!resourceSettings?.byInbox"
                                          [showFilterCounter]="false"
                                          [options]="activityOptions"
                                          [selectedOptions]="selectedActivities"
                                          (onChangeSelection)="updateSelectedActivities($event)">
      </dvtx-filter-list-selection-colored>
    </div>
    <br>
    <mat-slide-toggle *ngIf="resourceSettings"
                      class="owner-role-toggle"
                      color="primary"
                      [disabled]="disableEmailNotification"
                      [(ngModel)]="resourceSettings.byEmail"
                      [labelPosition]="'after'"
                      [checked]="resourceSettings?.byEmail"
                      (change)="updateSettings('byEmail', $event)">
      <h5 class="m-0">{{'NOTIFICATION.RECEIVE_EMAIL_NOTIFICATION' | translate}}</h5>
    </mat-slide-toggle>

    <button mat-button type="button" color="gray" class="mt-1"
            (click)="applySettingsToSubworkflow()">
      {{'PARTICIPANTOVERVIEW.APPLY_SETTINGS_TO_SUB' | translate}}
    </button>
  </div>
</ng-template>
