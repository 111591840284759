import {IResource, IRole} from '../../../models';
import {AbstractControl, ValidatorFn} from '@angular/forms';

export interface IContact extends IResource {
  contactId?: number;
  name: string;
  icon: string;
  address?: IContactAddress;
  addresses: IContactAddress[];
  emailAddress?: IContactEmailAddress;
  emailAddresses: IContactEmailAddress[];
  phoneNumber?: IContactPhoneNumber;
  phoneNumbers: IContactPhoneNumber[];
  visibility: string;
  note: string;
  roles?: IRole[];
}

export interface IContactEmailAddress extends IResource {
  mainAddress: boolean;
  locationOrType: string;
  emailAddress: string;
  order: number;
}

export interface IContactAddress extends IResource {
  mainAddress: boolean;
  locationOrType: string;
  full: string;
  street: string;
  streetNo: string;
  city: string;
  zip: string;
  countryName: string;
  geometry: any;
  order: number;
}

export class SimpleAddress {
  public id: string | undefined;
  public locationOrType: string;
  public street: string;
  public streetNo: string;
  public city: string;
  public zip: string;
  public countryName: string;

  constructor() {
  }

  toCppRequest(): any {
    const ret = {
      address_type_id: String(this.locationOrType),
      street: this.street,
      street_number: this.streetNo,
      post_code: this.zip,
      city: this.city,
      country: this.countryName
    };
    return JSON.stringify(ret) === JSON.stringify({}) ? undefined : ret;
  }
}

export interface SimpleSingleAddressResponse {
  data: SimpleAddressResponse
}

export interface SimpleAddressResponse {
  type: string,
  id: string,
  attributes: {
    address_type_id: string,
    street: string,
    street_number: string,
    post_code: string,
    city: string,
    country: string
  }
}

export interface IContactPhoneNumber extends IResource {
  mainPhoneNumber: boolean;
  locationOrType: string;
  countryCode: string;
  phoneNumber: string;
  order: number;
}

export class SimplePhoneNumber {
  public locationOrType: string;
  public countryCode: string;
  public phoneNumber: string;
  public id: string | undefined;
  public isValid?: Boolean | boolean | undefined;
  constructor(

  ) {
  }

  toCppRequest(): any {
    const ret = {
      address_type_id: String(this.locationOrType),
      country_code: this.countryCode,
      phone_number: this.phoneNumber
    };
    return JSON.stringify(ret) === JSON.stringify({}) ? undefined : ret;
  }
}

export interface SimplePhoneNumberResponse {
  type: string,
  id: string,
  attributes: {
    address_type_id: string,
    country_code: string,
    phone_number: string,
  }
}

export class SimpleEmailAddress {
  public locationOrType: string;
  public emailAddress: string;
  public id: string | undefined;

  constructor() {
  }

  toCppRequest(): any {
    const ret = {
      address_type_id: String(this.locationOrType),
      email_address: this.emailAddress
    };
    return JSON.stringify(ret) === JSON.stringify({}) ? undefined : ret;
  }
}

export interface SimpleSingleEmailAddressResponse {
  data: SimpleEmailAddressResponse
}

export interface SimpleEmailAddressResponse {
  type: string,
  id: string,
  attributes: {
    address_type_id: string,
    email_address: string,
  }
}

export function SimpleEmailAddressRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const input = control.value as SimpleEmailAddress;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = re.test(String(input.emailAddress).toLowerCase());
    if (!valid) {
      return {'InvalidEmailAddress': 'InvalidEmailAddress'}
    } else {
      return null;
    }
  };
}
