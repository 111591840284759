import {Router} from '@angular/router';
import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {
  ClientNavigationCommand,
  FiveFStatusApiMap,
  FiveFStatusesMap, FlatProcess,
  NoopCommand,
  Process,
  ProcessListingItemV3,
  ProcessListingV3Participant,
  ProcessListingV3Statistics,
  ProcessStatistics,
  ProcessStatus, ProcessViewSettings
} from './process';
import {IFiveFStatus, IProcessCommand} from './process.interface';
import {ProcessParticipant} from 'app/+store/process-participant/process-participant';
import {ProcessParticipantBuilder} from 'app/+store/process-participant/process-participant.builder';
import {ProcessRole} from '../process-role/process-role';
import {ClientBuilder} from '../client/client.builder';
import {Client} from '../client/client';

export class ProcessBuilder implements IApiResourceBuilder<Process> {
  participants: {
    [id: string]: ProcessParticipant
  } = {};

  externalParticipants: {
    [id: string]: ProcessParticipant
  } = {};

  clients: {
    [id: string]: Client
  } = {};

  total = 1;
  perPage = 1;
  records = 1;

  failed: number = 0;
  completed: number = 0;

  constructor(private _router: Router) {}

  fromResponse(data): Process {
    if (!data) {
      return null
    }
    const attr = data.attributes;
    const process = new Process(
      data.id,
      attr.title,
      attr.subtitle,
      attr.description,
      attr.due_date,
      attr.created_at,
      attr.process_type,
      attr.updated_at);
    process.clientId = attr.client_uuid;

    // Assessment period
    process.year = attr.year;
    process.month = attr.month;

    process.startedAt = attr.started_at;
    process.completedAt = attr.completed_at;
    process.startsAt = attr.starts_at;
    process.endsAt = attr.ends_at;
    if (attr.status) {
      process.status = new ProcessStatus(attr.status.code, attr.status.message, attr.status.info_level, attr.status.icon);
    }
    process.color = attr.color;
    process.parentId = attr.parent_id;
    process.dmsFolderId = attr.dms_folder_id;
    process.creatorEmail = attr.creator_email;
    process.creatorName = attr.creator_name;
    process.ownerEmail = attr.owner_email;
    process.ownerName = attr.owner_name;
    process.orgName = attr.organization_name;
    process.organizationId = attr.organization_uuid;
    process.identifier = attr.identifier;
    process.sharingInfo = attr.sharing_info;
    process.syncDmsFolder = attr.sync_folder;
    process.participants = [];
    process.canCreateTask = attr.can_create_task;
    process.lastUpdatedByEmail = attr.last_updated_by_email;
    process.bigFilesEnabledAt = attr.big_files_enabled_at;
    process.documentRevisionEnabled = attr.document_revision_enabled;

    // TODO: Check is this used? Use IAM if possible and remove this property
    process.canBeReopened = attr.can_be_reopened;

    // ATTENTION: (a.baier@5fsoftware.de)
    // This is experimental, not extensively tested and lastUpdatedByName could be null.
    // Prefer lastUpdatedByEmail for now.
    process.lastUpdatedByName = attr.last_updated_by_name;

    process.auditProofUntil = attr.audit_proof_until;

    process.profile = attr.profile;

    if (attr.dms_account_type) {
      process.dmsAccountType = attr.dms_account_type;
    }

    if (attr.roles && attr.roles.length) {
      process.roles = attr.roles;
    }

    if (attr.organization_membership) {
      process.roles.push(ProcessRole.MemberOfProcessOrganization)
    }

    try {
      let clientId = null;
      if (data.relationships && data.relationships.client && data.relationships.client.data) {
        try {
          clientId = data.relationships.client.data.id;
          process.client = this.clients[clientId];

          if (process.client) {
            process.clientName = process.client.getFullName();
          }
        } catch (err) {
          console.error('ERROR: Add included client section failed', err);
        }
      }
    } catch (err) {
      console.error('ERROR: Add included section failed', err);
    }

    try {
      if (data.relationships && data.relationships.participants && data.relationships.participants.data) {
        data.relationships.participants.data.map( participant => {
          process.participants.push(this.participants[participant.id]);
        });
      }

      if (data.relationships && data.relationships.external_participants && data.relationships.external_participants.data) {
        data.relationships.external_participants.data.map(participant => {
          process.participants.push(this.externalParticipants[participant.id]);
        });
      }
    } catch (err) {
      console.error('ERROR: Add included section failed', err);
    }

    process.total = this.total;
    process.perPage = this.perPage;
    process.records = this.records;

    process.completed = this.completed;
    process.failed = this.failed;

    this.buildCommandsFrom(process, attr.commands);
    return process;
  }

  toRequest(_: Process) {
    return null;
  }

  /**
   * Builds API driven commands for the execution of certain predefined events
   * in the process control flow.
   *
   * @param {IProcessContext} context
   * @param commands
   */
  buildCommandsFrom(context: Process, commands): void {
    if (!commands) return;

    // Create commands for each queue based on the event queue's name.
    for (const command of commands) {
      // console.log(`ProcessContextBuilder: Creating command: ${command}.`);
      context.addCommand(command.event_queue, this._createCommand(command));
    }

    // For testing purpose:
    // Should return one No op on the command line
    // context.addCommand('after_validation', new NoopCommand(false));
    // context.addCommand('after_validation', new NoopCommand(true));
  }

  addIncludedSection(includes) {
    if (!includes || includes.length === 0) {
      return;
    }
    const builder: ProcessParticipantBuilder = new ProcessParticipantBuilder(null);
    const cbuilder = new ClientBuilder();

    includes.map( (include) => {
      if (include.type === 'workflow_engine_process_participants') {
        const _participant = builder.fromResponse(include);
        this.participants[include.id] = _participant;
      }

      if (include.type === 'workflow_engine_process_external_participants') {
        const _eParticipant = builder.fromResponse(include);
        this.externalParticipants[include.id] = _eParticipant;
      }

      if (include.type === 'client_people' || include.type === 'client_organizations') {
        const _client = cbuilder.fromResponse(include);
        this.clients[include.id] = _client;
      }
    });
  }

  addMetaSection(meta: any) {
    if (!meta) return;
    this.total = meta.total;
    this.perPage = meta.per_page;
    this.records = meta.records;
    this.failed = meta.failed;
    this.completed = meta.completed;
  }

  /*
   * Helper method to create the concrete commands from command classes.
   */
  private _createCommand(command): IProcessCommand {
    switch (command.context) {
      case 'API':
        return new NoopCommand(`ProcessContextBuilder: Not implemented, yet.`);

      case 'web_client':
        return this._createClientCommand(command);

      default:
        return new NoopCommand(`ProcessContextBuilder: Unknown command context: ${command.context}.`);
    }
  }

  /*
   * Helper method for the creation of client specific commands, e.g. navigation using the
   * Angular router.
   */
  private _createClientCommand(command): IProcessCommand {
    switch (command.method) {
      case 'router.navigate':
        return new ClientNavigationCommand(command, this._router);

      default:
        return new NoopCommand(`ProcessContextBuilder: Unknown client command: ${command.method}`);
    }
  }
}

export class ProcessStatisticsBuilder implements IApiResourceBuilder<ProcessStatistics> {
  constructor(public id) {
  }

  fromResponse(data): ProcessStatistics {
    return new ProcessStatistics(
      this.id,
      data.attributes.processes.all,
      data.attributes.processes.open,
      data.attributes.processes.closed,
      data.attributes.tasks.all,
      data.attributes.tasks.open,
      data.attributes.tasks.closed,
      data.attributes.artifacts.all);
  }

  toRequest(_: ProcessStatistics) {
    return null;
  }
}

export class ProcessListingV3StatisticsBuilder implements IApiResourceBuilder<ProcessListingV3Statistics> {
  constructor(public id) {
  }

  fromResponse(data): ProcessListingV3Statistics {
    return new ProcessListingV3Statistics(
      this.id,
      data.attributes.processes.all,
      data.attributes.processes.open,
      data.attributes.processes.closed,
      data.attributes.processes.draft);
  }

  toRequest(_: ProcessListingV3Statistics) {
    return null;
  }
}

export class ProcessListingV3ParticipantBuilder implements IApiResourceBuilder<ProcessListingV3Participant> {
  fromResponse(data): ProcessListingV3Participant {
    return new ProcessListingV3Participant(
      data.id,
      data.attributes.first_name,
      data.attributes.last_name);
  }

  toRequest(_: ProcessListingV3Participant) {
    return null;
  }
}

export class ProcessListingItemV3Builder implements IApiResourceBuilder<ProcessListingItemV3> {
  participants: {
    [id: string]: ProcessParticipant
  } = {};

  externalParticipants: {
    [id: string]: ProcessParticipant
  } = {};

  clients: {
    [id: string]: Client
  } = {};

  total = 1;
  perPage = 1;
  records = 1;

  constructor() {
  }

  fromResponse(data): ProcessListingItemV3 {
    const attr = data.attributes;
    const process = new ProcessListingItemV3(
      data.id,
      attr.title,
      attr.description,
      attr.identifier,
      attr.process_type,
      <IFiveFStatus>FiveFStatusApiMap[attr.status] || FiveFStatusesMap.Open,
      attr.organization_uuid,
      attr.client_uuid,
      attr.due_date,
      attr.color,
      attr.creator_name,
      attr.creator_email,
      attr.trashed_at,
      attr.created_at,
      attr.updated_at);

    process.total = this.total;
    process.perPage = this.perPage;
    process.records = this.records;

    process.clientName = attr.client_name;
    process.clientNo = attr.client_id;

    process.ownerName = attr.creator_name;
    process.ownerEmail = attr.creator_email;

    process.year = attr.year;
    process.month = attr.month;

    return process;
  }

  toRequest(_: ProcessListingItemV3) {
    return null;
  }

  addMetaSection(meta: any) {
    if (!meta) return;
    this.total = meta.total;
    this.perPage = meta.per_page;
    this.records = meta.records;
  }
}

export class FlatProcessBuilder implements IApiResourceBuilder<FlatProcess> {
  fromResponse(data): FlatProcess {
    const attr = data.attributes;
    return new FlatProcess(
      data.id,
      attr.title,
      attr.color,
      attr.process_type,
      attr.created_at,
      attr.updated_at);
  }

  toRequest(_: FlatProcess) {
    return null;
  }
}

export class ProcessViewSettingsBuilder implements IApiResourceBuilder<ProcessViewSettings> {
  constructor(public identifier) {
  }

  fromResponse(data): ProcessViewSettings {
    const attr = data.attributes;
    return new ProcessViewSettings(
      this.identifier,
      attr.config,
      attr.created_at,
      attr.updated_at);
  }

  toRequest(_: ProcessViewSettings) {
    return null;
  }
}


