<dvtx-user-profile-settings-header [routes]="routes" [activeLink]="activeLink"
  [title]="'SIGNATURE.ACCOUNT.ACCOUNT_DATA'" [subtitle]="'SIGNATURE.ACCOUNT.DEFAULT_SIGNATURE'">
</dvtx-user-profile-settings-header>
<div class="five-f-settings-container">
  <div class="row w-100 mx-0 pb-3" *ngIf="currentSignature">
    <div
      class="m-0 p-0 col-12 d-flex align-items-center user--profile--avatar--input mb-3">
      <div class="w-100 d-flex pb-3">
        <div>
          <!-- <div class="mt-2 mb-2">
            <dvtx-alert [icon]="'info'" [color]="'info'" [text]="'SIGNATURE.ACCOUNT.ACCOUNT_DEFAULT_SIGNATURE_INFO' | translate"></dvtx-alert>
          </div>-->

          <!-- NORMAL STANDARD SIGNATURE -->

          <div class="d-flex mb-1">
            <div class="h2">{{ 'SIGNATURE.ACCOUNT.MY_DEFAULT_SIGNATURE' | translate }}</div>
          </div>

          <p [innerHTML]="'SIGNATURE.ACCOUNT.MY_SIGNATURE_SIZE_INFO' | translate"></p>

          <div class="d-flex align-items-center mt-1 mb-2">
            <div>
              <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
            </div>
            <p [innerHTML]="'SIGNATURE.ACCOUNT.MY_SIGNATURE_SIZE_INFO_2' | translate" class="mb-0"></p>
          </div>

          <dvtx-document-signature-profile-preview [currentSignature]="currentSignature"
            [skipMetaInfoSupported]="skipMetaInfoSupported">
          </dvtx-document-signature-profile-preview>

          <!-- <div class="d-flex align-items-center mb-2">
            <a download="5F Signature Example.png" target="_blank" href="https://obs-prod-01.obs.eu-de.otc.t-systems.com/templates/5fsoftware_sample_signature.png" >{{'SIGNATURE.ACCOUNT.SAMPLE_DOWNLOAD' | translate}}</a>
          </div>-->

          <!-- STAMP UPLOAD AREA -->

          <div class="d-flex mt-3 mt-1 mb-1">
            <div class="h2">{{ 'SIGNATURE.ACCOUNT.MY_DEFAULT_STAMP' | translate }} (optional)</div>
          </div>

          <p [innerHTML]="'SIGNATURE.ACCOUNT.MY_STAMP_SIZE_INFO' | translate"></p>

          <div class="d-flex align-items-center mt-1 mb-2">
            <div>
              <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
            </div>
            <p [innerHTML]="'SIGNATURE.ACCOUNT.MY_STAMP_SIZE_INFO_2' | translate" class="mb-0"></p>
          </div>

          <dvtx-document-signature-profile-preview [currentSignature]="currentSignatureStamp"
            [skipMetaInfoSupported]="true" [enableSkipMetaInfoSupport]="false" [enableEdit]="false"
            [enableHandWriting]="false" [format]="'stamp'">
          </dvtx-document-signature-profile-preview>

          <div class="d-flex align-items-center mt-1 mb-2">
            <a download="5F Stamp Example.png" target="_blank"
              href="https://obs-prod-01.obs.eu-de.otc.t-systems.com/templates/5fsoftware_sample_stamp.png">{{'SIGNATURE.ACCOUNT.SAMPLE_DOWNLOAD'
              | translate}}</a>
          </div>

          <!-- SIGNET UPLOAD AREA -->

          <div class="d-flex mt-3 mb-1">
            <div class="h2">{{ 'SIGNATURE.ACCOUNT.MY_DEFAULT_SIGNET' | translate }} (optional)</div>
          </div>

          <p [innerHTML]="'SIGNATURE.ACCOUNT.MY_SIGNET_SIZE_INFO' | translate"></p>

          <div class="d-flex align-items-center mt-1 mb-2">
            <div>
              <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
            </div>
            <p [innerHTML]="'SIGNATURE.ACCOUNT.MY_SIGNET_SIZE_INFO_2' | translate" class="mb-0"></p>
          </div>

          <dvtx-document-signature-profile-preview [currentSignature]="currentSignatureSignet"
            [skipMetaInfoSupported]="true" [enableSkipMetaInfoSupport]="false" [enableEdit]="false"
            [enableHandWriting]="false" [format]="'signet'">
          </dvtx-document-signature-profile-preview>

          <div class="d-flex align-items-center mt-1 mb-2">
            <a download="5F Signet Example.png" target="_blank"
              href="https://obs-prod-01.obs.eu-de.otc.t-systems.com/templates/5fsoftware_sample_signet.png">{{'SIGNATURE.ACCOUNT.SAMPLE_DOWNLOAD'
              | translate}}</a>
          </div>

          <!-- Organizational Preview Stamp. Only present if set by 5F Operator -->
          <div class="mt-3 dvtx-organizational-visual" *ngIf="(organization$ | async) && visual?.content">
            <div class="mb-2">
              <div class="h2">{{ 'SIGNATURE.SETTINGS.ORGANIZATIONAL_SIGNATURE' | translate }}</div>
            </div>

            <div class="mb-2">
                <div class="d-flex align-items-center mt-1 mb-2">
                  <div>
                    <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
                  </div>
                  <p [innerHTML]="'SIGNATURE.SETTINGS.ORGANIZATIONAL_VISUAL_INFO' | translate" class="mb-0"></p>
                </div>
            </div>

            <div class="mb-0">
              <div class="h3">{{ 'SIGNATURE.SETTINGS.SELECTED_ORGANIZATION' | translate }}</div>
            </div>

            <div class="mb-2">
              <div class="h2 d-flex align-item-center">
                <mat-icon class="mr-2 dvtx-fg-color dvtx-fg-fivef-blue-400">domain</mat-icon>
                {{ (organization$ | async)?.name }}
              </div>
            </div>

            <ng-container *ngIf="visual?.content">
              <h2 class="mt-3 mb-1">{{'GENERAL.PREVIEW' | translate}}</h2>

              <div class="px-3 dvtx-organizational-visual__content__preview">
                <img [src]="visual.content" />
              </div>

              <h2 class="mt-3 mb-1">{{'SIGNATURE.SETTINGS.SETTINGS' | translate}}</h2>

              <div class="d-flex flex-column dvtx-mat-input-form-override" [formGroup]="form">
                <div class="mb-2 d-flex justify-content-end">
                  <button mat-raised-button color="primary" [disabled]="form.invalid || form.pristine || submitOngoing"
                    (click)="updateOrganizationalSignature()">
                    {{ 'GENERAL.SAVE_ACTION' | translate }}
                  </button>
                </div>

                <div class="d-flex mb-1">
                  <mat-form-field>
                    <input matInput formControlName="name"
                      [placeholder]="'SIGNATURE.SETTINGS.CUSTOM_NAME' | translate" />
                  </mat-form-field>

                  <mat-form-field style="max-width: 60px" class="ml-2">
                    <input matInput formControlName="fontSize" type="number" max="144" min="60"
                      [placeholder]="'SIGNATURE.SETTINGS.FONT_SIZE' | translate" />
                  </mat-form-field>
                </div>
                <mat-checkbox class="mb-1" formControlName="enableProfession">{{'SIGNATURE.SETTINGS.ENABLE_PROFESSION' |
                  translate}}</mat-checkbox>
                <mat-form-field class="mb-0">
                  <input matInput formControlName="profession" maxlength="80"
                    [placeholder]="'SIGNATURE.SETTINGS.PROFESSION' | translate" />
                </mat-form-field>

                <p class="mb-1 mt-0">{{'SIGNATURE.SETTINGS.INFRASTRUCTURE_INFO' | translate}}</p>

                <mat-checkbox formControlName="enableDate">{{'SIGNATURE.SETTINGS.ENABLE_DATE' |
                  translate}}</mat-checkbox>
                <mat-checkbox formControlName="enableTime">{{'SIGNATURE.SETTINGS.ENABLE_TIME' |
                  translate}}</mat-checkbox>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>