import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {CollectorAttachment} from './collector-attachment';

export class CollectorAttachmentBuilder implements IApiResourceBuilder<CollectorAttachment> {
  fromResponse(data): CollectorAttachment {
    return new CollectorAttachment(
      data.id,
      data.attributes.collector_id,
      data.attributes.element_id,
      data.attributes.display_name,
      data.attributes.filename,
      data.attributes.description,
      data.attributes.uploaded_by,
      data.attributes.revision,
      data.attributes.fingerprint,
      data.attributes.size,
      data.attributes.mime_type,
      data.attributes.created_at,
      data.attributes.updated_at,
      data.attributes.content);
  }

  toRequest(_: CollectorAttachment) {
    return null;
  }
}
