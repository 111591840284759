import { FibuBookmanAccountActions, FibuBookmanAccountActionTypes } from './fibu-bookman-account.actions';
import { adapter, initialState, State } from './fibu-bookman-account.state';

export function reducer(state = initialState, action: FibuBookmanAccountActions): State {

  switch (action.type) {
    case FibuBookmanAccountActionTypes.SignInToFibuBookmanAccount:
      return {
        ...state,
        error: false,
        loading: true
      };
    case FibuBookmanAccountActionTypes.SignInToFibuBookmanAccountSuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        signedIn: true,
        error: false,
        loading: false,
      });
    case FibuBookmanAccountActionTypes.SignInToFibuBookmanAccountFail:
      return {
        ...state,
        signedIn: false,
        error: true,
        loading: false,
      };

    case FibuBookmanAccountActionTypes.SignOutFromFibuBookmanAccount:
      return {
        ...state,
        error: false,
        loading: true
      };
    case FibuBookmanAccountActionTypes.SignOutFromFibuBookmanAccountSuccess:
      return adapter.removeOne(action.payload.id, {
        ...state,
        signedIn: false,
        error: false,
        loading: false
      })
    case FibuBookmanAccountActionTypes.SignOutFromFibuBookmanAccountFail:
      return {
        ...state,
        signedIn: true,
        error: true,
        loading: false,
      };

    case FibuBookmanAccountActionTypes.GetFibuBookmanAccount:
      return {
        ...state,
        error: false,
        loading: true
      };
    case FibuBookmanAccountActionTypes.GetFibuBookmanAccountSuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        error: false,
        loading: false,
      });
    case FibuBookmanAccountActionTypes.GetFibuBookmanAccountFail:
      return {
        ...state,
        error: false,
        loading: false,
      };

  }
  return state;
}
