import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ProjectStatistics } from './project-statistics';

export interface State extends EntityState<ProjectStatistics> {
  loading: boolean;
}

export const adapter = createEntityAdapter<ProjectStatistics>();

export const initialState: State = adapter.getInitialState({
  loading: false,
});
