import {MembershipActions, MembershipActionTypes} from './membership.actions';
import {adapter, initialState, State} from './membership.state';

export function reducer(state = initialState, action: MembershipActions): State {
  switch (action.type) {
    case MembershipActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case MembershipActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case MembershipActionTypes.LoadOne:
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: false,
      });
    case MembershipActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };
    case MembershipActionTypes.RemoveOne:
      return {
        ...state,
        loading: true
      };
    case MembershipActionTypes.RemoveOneSuccess:
      // BUG (by Andreas Baier): Not the membership id is returned from the API
      //     but the natural person id so this does not work.
      // FIXED: by adapting selector and effect.
      return adapter.removeOne(action.id, {
        ...state,
        loading: false,
      });
    case MembershipActionTypes.LoadMy:
      return {
        ...state,
        loading: true
      };
    case MembershipActionTypes.LoadMySuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        my: action.payload.id,
        loading: false,
      });
    case MembershipActionTypes.LoadMyFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}
