import { IApiResourceBuilder } from 'app/shared/modules/api-resource/models/api.interface';
import { ItemLabels } from './item-labels';
import { IItemLabelsParams } from './item-labels.interface';
import { Label } from 'app/+store/label/label';
import { LabelBuilder } from 'app/+store/label/label.builder';

export class ItemLabelsBuilder implements IApiResourceBuilder<ItemLabels> {
  labels: {
    [id: string]: Label
  } = {};

  fromResponse(data): ItemLabels {
    const itemLabels = new ItemLabels(
      data.id,
      data.attributes.reference_id,
      [],
      data.attributes.item_type,
      data.attributes.process_id
    );

    // try {
    //   if (data.relationships && data.relationships.labels && data.relationships.labels.data) {
    //     let labels = [];
    //     try {
    //       data.relationships.labels.data.forEach(label => {
    //         itemLabels.labelIds.push(label.id);
    //         labels.push(this.labels[label.id]);
    //       });
    //       itemLabels.labels = labels;
    //     } catch (err) {
    //       console.error('ERROR: Add included labels section failed', err);
    //     }
    //   }
    // } catch (err) {
    //   console.error('ERROR: Add included section failed', err);
    // }
    if (data.relationships && data.relationships.labels && data.relationships.labels.data) {
      itemLabels.labelIds = data.relationships.labels.data.map(l => l.id);
    }
    return itemLabels;
  }

  addIncludedSection(includes) {
    if (!includes || includes.length === 0) {
      return;
    }
    // const cbuilder = new LabelBuilder();

    // includes.map( (include) => {
    //   if (include.type === 'label') {
    //     const _label = cbuilder.fromResponse(include);
    //     this.labels[include.id] = _label;
    //   }
    // });
  }

  toRequest(params: IItemLabelsParams) {
    return {
      data: {
        type: 'label',
        attributes: {
          reference_id: params.itemId,
          label_id: params.labelId,
          item_type: params.itemType,
          process_id: params.processId
        }
      }
    };
  }
}
