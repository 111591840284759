import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './datev-client.state';

export const stateKey = 'datev_client';
const getClientState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getClientEntities,
  selectAll: getAllClients,
} = adapter.getSelectors(getClientState);

export const loading = createSelector(getClientState, state => state.loading);
export const syncing = createSelector(getClientState, state => state.syncing);

export const getAll = createSelector(
  getAllClients,
  (entities) => entities
);
