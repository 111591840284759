import {TenantUserActions, TenantUserActionTypes} from './user.actions';
import {adapter, initialState, State} from './user.state';

export function reducer(state = initialState, action: TenantUserActions): State {
  const current = state.selected;
  switch (action.type) {
    case TenantUserActionTypes.LoadAll:
      if (action.params.reset) {
        return adapter.removeAll({
          ...state,
          selected: null,
          loading: false,
        });
      }
      return {
        ...state,
        selected: null,
        loading: true
      };
    case TenantUserActionTypes.Create:
    case TenantUserActionTypes.LoadOne:
    case TenantUserActionTypes.Save:
    case TenantUserActionTypes.LockUserAccount:
    case TenantUserActionTypes.ToggleLicense:
      return {
        ...state,
        loading: true
      };
    case TenantUserActionTypes.LoadOneSuccess:
      return adapter.addOne(action.user, {
        ...state,
        selected: action.user,
        loading: false,
      });
    case TenantUserActionTypes.LoadAllSuccess:
      if (action.reset) {
        return adapter.setAll(action.rawUsers, {
            ...state,
            selected: null,
            loading: false,
        });
      }
      return adapter.upsertMany(action.users, {
        ...state,
        selected: null,
        loading: false,
      });
    case TenantUserActionTypes.CreateSuccess:
    case TenantUserActionTypes.SaveSuccess:
    case TenantUserActionTypes.LockUserAccountSuccess:
    case TenantUserActionTypes.ToggleLicenseSuccess:
      return adapter.upsertOne(action.user, {
        ...state,
        selected: action.user,
        loading: false,
      });
    case TenantUserActionTypes.CreateFail:
    case TenantUserActionTypes.LoadAllFail:
    case TenantUserActionTypes.LoadOneFail:
    case TenantUserActionTypes.SaveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
  }
  return state;
}
