import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { WorkflowIconComponent } from "./component/workflow-icon.component";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule
  ],
  declarations: [
    WorkflowIconComponent
  ],
  exports: [
    WorkflowIconComponent
  ]
})
export class WorkflowIconModule {
}
