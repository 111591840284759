<div class="d-flex flex-column h-100">
  <div mat-dialog-content>
    <div class="folder-actions">
      <div class="left-side-actions">
        <div class="action folder-actions__new-folder">
          <button (click)="openCreateFolder()" [disabled]="!selectedFolder">
            <mat-icon>create_new_folder</mat-icon>
            <span>{{ 'GENERAL.CREATE_ACTION' | translate }}</span>
          </button>
        </div>
      </div>
      <div class="right-side-actions">
        <div class="action folder-actions__edit-folder">
          <button (click)="openEditFolder()" [disabled]="!selectedFolder || !selectedFolder.editable">
            <mat-icon>edit</mat-icon>
            <span>{{ 'GENERAL.EDIT_ACTION' | translate }}</span>
          </button>
        </div>
        <div class="action folder-actions__delete-folder">
          <button (click)="openDeleteFolder()"
                  [disabled]="!selectedFolder || !selectedFolder.deletable || selectedFolder?.children?.length > 0">
            <mat-icon>delete</mat-icon>
            <span>{{ 'GENERAL.DELETE_ACTION' | translate }}</span>
          </button>
        </div>
        <!--        <div class="action folder-actions__setting-folder">-->
        <!--          <button (click)="folderSettings()" [disabled]="!selectedFolder">-->
        <!--            <mat-icon>settings</mat-icon>-->
        <!--            <span>SETTINGS</span>-->
        <!--          </button>-->
        <!--        </div>-->
      </div>

    </div>
    <div *ngIf="actionsFormEnabled" class="folder-actions-form">
      <div *ngIf="['create', 'edit'].includes(folderAction)" class="d-flex flex-column create-folder-action">
        <mat-form-field>
          <input [(ngModel)]="folderNameField" [ngModelOptions]="{standalone: true}" matInput
                 placeholder="{{ 'DMS.FOLDER_NAME' | translate }}">
        </mat-form-field>
        <div class="align-self-end">
          <div class="d-flex">
            <button (click)="cancelAction()" class="ml-2" mat-button type="button">
              {{ 'GENERAL.CANCEL_ACTION' | translate }}
            </button>
            <button (click)="createFolder()" *ngIf="folderAction === 'create'" class="ml-2" color="primary"
                    mat-raised-button
                    type="button">
              {{ 'GENERAL.CREATE_ACTION' | translate }}
            </button>
            <button (click)="editFolder()" *ngIf="folderAction === 'edit'" class="ml-2" color="primary"
                    mat-raised-button
                    type="button">
              {{ 'GENERAL.EDIT_ACTION' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="folderAction === 'delete'" class="d-flex flex-column delete-folder-action">
        <h2 class="mb-2">
          {{ 'DMS.DELETE_FOLDER_ACTION' | translate }}
        </h2>
        <div class="d-flex justify-content-end">
          <button (click)="cancelAction()" mat-button type="button">
            {{ 'GENERAL.CANCEL_ACTION' | translate }}
          </button>
          <button (click)="deleteFolder()" class="ml-2"
                  color="warn"
                  mat-raised-button>{{ 'GENERAL.CONFIRM_ACTION' | translate }}</button>
        </div>
      </div>
    </div>
    <div (click)="cancelAction()" *ngIf="actionsFormEnabled" class="folder-actions-form-backdrop"></div>
    <div class="folders-tree">
      <dvtx-folder-tree (onDblClick)="dblClickSelection($event)"
                        (onNodeSelection)="folderSelect($event)"
                        [folders]="folders$ | async"
                        [preSelectedNodeId]="selectedId"></dvtx-folder-tree>
    </div>

  </div>
  <div class="d-flex justify-content-end" mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
    <button (click)="select()"
            [disabled]="!selectedFolder"
            color="primary"
            mat-raised-button>
      {{ 'GENERAL.SELECT_ACTION' | translate }}
    </button>
  </div>
</div>
