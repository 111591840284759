import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Project} from './project';
import * as dayjs from 'dayjs';

export interface State extends EntityState<Project> {
  selected?: Project;
  loading: boolean;
}

export const adapter = createEntityAdapter<Project>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
