<div class="five-f-color-picker-dropdown-container" [class.five-f-menu-opened]="showColorPicker">
  <dvtx-tooltip [tooltipText]="tooltip | translate" [position]="tooltipPosition">
    <button [disabled]="disabled" type="button" mat-icon-button [disableRipple]="true" [matMenuTriggerFor]="menu"
            (click)="showColorPicker = !showColorPicker" #buttonToggler *ngIf="buttonType === 'icon'">
      <mat-icon [style.color]="selected">
        invert_colors
      </mat-icon>
    </button>
    <button [disabled]="disabled" type="button" mat-stroked-button [disableRipple]="true" [matMenuTriggerFor]="menu"
            (click)="showColorPicker = !showColorPicker" #buttonToggler *ngIf="buttonType === 'button'"
            [style.background]="selected">
      <mat-icon [style.color]="colorsLib.LightOrDark(selected) === 'light' ? '#3c3c3c' : '#ffffff'">
        invert_colors
      </mat-icon>
    </button>
  </dvtx-tooltip>
  <mat-menu
    #menu="matMenu"
    class="five-f-card five-f-color-picker-dropdown"
    [xPosition]="xPosition"
    [yPosition]="yPosition"
    (closed)="showColorPicker = false">
    <ul class="mb-0 five-f-color-picker-dropdown-inner-content">
      <ng-container *ngFor="let color of colors">
        <li class="list-inline-item">
          <span [style.background]="color" (click)="selectColor(color)"></span>
        </li>
      </ng-container>
      <li class="list-inline-item" (click)="$event.stopPropagation();">
        <dvtx-tooltip [tooltipText]="'GENERAL.SHOW_MORE' | translate" [position]="'top right'">
          <button type="button" mat-icon-button [disableRipple]="true" (click)="moreColorsPicker.click()">
            <mat-icon>
              palette
            </mat-icon>
          </button>
        </dvtx-tooltip>
        <input type="color" class="customColorPicker" (change)="selectCustomColor($event)" #moreColorsPicker/>
      </li>
    </ul>
  </mat-menu>
</div>
