import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {CollectorExport} from './collector-export';

export interface State extends EntityState<CollectorExport> {
  loading: boolean
}

export const adapter = createEntityAdapter<CollectorExport>();
export const initialState: State = adapter.getInitialState({
  loading: false
});
