import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Milestone} from './milestone';

export interface State extends EntityState<Milestone> {
  loading: boolean;
}

export const adapter = createEntityAdapter<Milestone>()
// Sorted by API by now
// {
//   sortComparer: (l, r) => -(dayjs(r.dueDate).diff(dayjs(l.dueDate)))
// });

export const initialState: State = adapter.getInitialState({
  loading: false
});
