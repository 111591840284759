import {Component, forwardRef, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {
  SimpleAddress,
  SimpleEmailAddress,
  SimpleEmailAddressRequiredValidator,
  SimplePhoneNumber
} from '../../../../../contacts/models/contact.interface';
import {takeUntil} from 'rxjs/operators';
import {DvtxControlValueAccessor} from '../../../../../../shared/modules/base-form-elements/components/1_control-value-accessor-components/DvtxControlValueAccessor';
import {Subject} from 'rxjs';
import {ContactOrganizationBaseForm, newOrganizationBaseForm} from '../../../../../organization/models/contact-organization-base-form';
import {ContactPerson} from '../../../../../../models/contact-person.model';
import {ContactOrganization} from '../../../../../../models/contact-organization.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../reducers';
import {ContactActions} from '../../../../../../+store/contact';

@Component({
  selector: 'dvtx-create-organization-dialog',
  templateUrl: './create-organization-dialog.component.html',
  styleUrls: ['./create-organization-dialog.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreateOrganizationDialogComponent),
      multi: true,
    }
  ]
})
export class CreateOrganizationDialogComponent extends DvtxControlValueAccessor implements OnInit, OnDestroy, OnChanges {
  createOrgaFrom: UntypedFormGroup;
  private onDestroy = new Subject();

  @Input()
  headingTranslateKey: string;
  @Input() displayFurtherDataFields: boolean = false;
  contactOrganization: ContactOrganization;

  constructor(
    fb: UntypedFormBuilder,
    protected injector: Injector,
    public store: Store<AppState>
  ) {
    super();
    this.createOrgaFrom = fb.group({
      Rechtsform: [undefined, Validators.required],
      Name: [undefined, Validators.required],
      PhoneNumber: new SimplePhoneNumber(),
      EmailAddress: [new SimpleEmailAddress(), SimpleEmailAddressRequiredValidator()],
      Address: new SimpleAddress()
    });

    this.createOrgaFrom.valueChanges.pipe(
      takeUntil(this.onDestroy)
    ).subscribe(((value: FormValue) => {
      const ret = newOrganizationBaseForm();
      const notification = {
        ...ret,
        contactOrganization: {
          name: value.Name,
          mainAddress: value.Address,
          mainPhoneNumber: value.PhoneNumber,
          mainEmailAddress: value.EmailAddress,
          legalFormId: value.Rechtsform,
        },
        isValid: this.createOrgaFrom.valid
      };
      this.notifyOnChange(notification);
    }))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDisabled']) {
      if (this.isDisabled) {
        this.createOrgaFrom.disable();
      } else {
        this.createOrgaFrom.enable();
      }
    }
  }

  writeValue(obj: ContactOrganizationBaseForm): void {
    this.contactOrganization = obj ? obj.contactOrganization : null;

    if (obj) {
      this.createOrgaFrom.patchValue({
        Rechtsform: obj.contactOrganization.legalFormId,
        Name: obj.contactOrganization.name,
        PhoneNumber: obj.contactOrganization.mainPhoneNumber ? obj.contactOrganization.mainPhoneNumber : new SimplePhoneNumber(),
        Address: obj.contactOrganization.mainAddress ? obj.contactOrganization.mainAddress : new SimpleAddress(),
        EmailAddress: obj.contactOrganization.mainEmailAddress ? obj.contactOrganization.mainEmailAddress : new SimpleEmailAddress(),
      })
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnInit() {
  }

  addFurtherPhoneNumber(phoneNumber: SimplePhoneNumber) {
    this.store.dispatch(new ContactActions.AddFurtherPhoneNumber({
      contactId: this.contactOrganization.id,
      phoneNumber,
    }))
  }

  removeFurtherPhoneNumber(phoneNumberId: string) {
    this.store.dispatch(new ContactActions.RemoveFurtherPhoneNumber({
      contactId: this.contactOrganization.id,
      phoneNumberId
    }))
  }

  addFurtherEmail(emailAddress: SimpleEmailAddress) {
    this.store.dispatch(new ContactActions.AddFurtherEmail({
      contactId: this.contactOrganization.id,
      emailAddress,
    }))
  }

  removeFurtherEmail(emailAddressId: string) {
    this.store.dispatch(new ContactActions.RemoveFurtherEmail({
      contactId: this.contactOrganization.id,
      emailAddressId
    }))
  }

  addFurtherAddress(address: SimpleAddress) {
    this.store.dispatch(new ContactActions.AddFurtherAddress({
      contactId: this.contactOrganization.id,
      address,
    }))
  }

  removeFurtherAddress(addressId: string) {
    this.store.dispatch(new ContactActions.RemoveFurtherAddress({
      contactId: this.contactOrganization.id,
      addressId
    }))
  }
}


interface FormValue {
  Rechtsform: { id: string, name: string },
  Name: string,
  PhoneNumber: SimplePhoneNumber
  EmailAddress: SimpleEmailAddress,
  Address: SimpleAddress
}
