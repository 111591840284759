import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Observable, Subject} from 'rxjs';
import {PageSidebarSelectors} from 'app/+store/page-sidebar';
import {PageSidebar} from 'app/+store/page-sidebar/page-sidebar';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'dvtx-sidebar-toggle',
  templateUrl: './sidebar-toggle.component.html',
  styleUrls: ['./sidebar-toggle.component.scss']
})
export class SidebarToggleComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  @Output()
  private onToggle = new EventEmitter();

  @Input() icon = ''

  private sidebar$: Observable<PageSidebar>;
  public sidebar: PageSidebar;

  constructor(private _store: Store<AppState>,
              private _cdr: ChangeDetectorRef) {
    this.sidebar$ = _store.select(PageSidebarSelectors.getSidebar);
  }

  ngOnInit() {
    this.sidebar$.pipe(takeUntil(this.onDestroy)).subscribe(sidebar => {
      this.sidebar = sidebar;
      this._cdr.detectChanges();
    })
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public toggle() {
    this.onToggle.emit();
  }
}
