import {IResource} from "app/models";

export interface ILabel extends IResource {
  icon: string;
  color: string;
  title: string;
  description: string;
  isGlobal: boolean;
  scope: LabelScope;
  origin: LabelOrigin;
  usageCount: number;
  isSVGIcon: boolean;
  createdBy: string;
}

export interface ILabelParams {
  icon?: string;
  color: string;
  title: string;
  description?: string;
  scope?: LabelScope;
  origin?: LabelOrigin;
  isGlobal?: boolean;
  referenceId?: string;
}

export enum LabelScope {
  GLOBAL = 'global',
  ORGANIZATIONAL = 'organizational',
  PERSONAL = 'personal'
}

export enum LabelOrigin {
  TASK_PRIORITY = 'task_priority',
  TASK_STATUS = 'task_status',
  APPROVAL_TASK_PRIORITY = 'approval_task_priority',
  APPROVAL_TASK_STATUS = 'approval_task_status',
  COLLECTOR_PRIORITY = 'collector_priority',
  COLLECTOR_STATUS = 'collector_status',
  DOCUMENTS_UNSORTED = 'documents_unsorted',
  NO_ORIGIN = "no_origin",
  COLLECTO_GROUP = "collecto_group"
}
