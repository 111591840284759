import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {DmsFolderEffects} from './dms-folder.effects';
import {DmsFolderService} from './dms-folder.service';
import {reducer} from './dms-folder.reducer';
import {stateKey} from './dms-folder.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([DmsFolderEffects])
  ],
  providers: [
    DmsFolderEffects,
    DmsFolderService
  ]
})
export class DmsFolderStoreModule {
}
