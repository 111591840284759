import {Component, forwardRef, Injector, Input} from '@angular/core';
import {DvtxControlValueAccessor} from '../../DvtxControlValueAccessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'dvtx-radio-cva',
  templateUrl: './radio-cva.component.html',
  styleUrls: ['./radio-cva.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioCvaComponent),
      multi: true,
    }
  ]
})
export class RadioCvaComponent extends DvtxControlValueAccessor {
  @Input() tooltip: string = null;
  @Input() radioChoices: any[] = [];
  @Input() displayPath = '';
  @Input() position = 'after';

  selectedModelObject: any;

  constructor(protected injector: Injector) {
    super();
  }

  writeValue(obj: any): void {
    this.selectedModelObject = obj;
  }

  changeRadio() {
    this.notifyOnChange(this.selectedModelObject);
    this.notifyOnTouch();
  }
}
