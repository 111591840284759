
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FolderTreeBuilder } from '../builders/folder-tree.builder';
import { ApiErrorBuilder } from '../builders/api-error.builder';
import createFolderTreeFrom = FolderTreeBuilder.createFolderTreeFrom;
import createFolder = FolderTreeBuilder.createFolder;
import {HttpErrorResponse, HttpClient} from '@angular/common/http';
import { environment } from 'environments/environment';

/**
 * Service to fetch the users folder from DMS API endpoint.
 */
@Injectable()
export class FolderService {
  readonly BASE_PATH = 'api/v1/folders';
  basePath;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  getAll() {
    return this._http
      .get(`${this.basePath}`).pipe(
      map((res: any) => createFolderTreeFrom(res.data)),
      catchError(this.handleError),);
  }

  create(name, parentId?) {
    const body = { data: { attributes: { name: name } } };
    if (parentId) {
      body['data']['attributes']['parent_id'] = parentId;
    }
    return this._http
      .post(`${this.basePath}`, body).pipe(
      map((res: any) => createFolder(res.data)),
      catchError(this.handleError),);
  }

  delete(id) {
    return this._http
      .delete(`${this.basePath}/${id}`).pipe(
      map((res: any) => createFolder(res.data)),
      catchError(this.handleError),);
  }

  private handleError(error: HttpErrorResponse) {
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}
