import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SalesService} from './sales.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    SalesService
  ]
})
export class SalesStoreModule {
}
