import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  templateUrl: './confirm-dialog-v2.component.html',
  styleUrls: ['./confirm-dialog-v2.component.scss'],
  selector: 'dvtx-confirm-dialog-v2',
})
export class ConfirmDialogV2Component {
  @Input() color = '#007aff';
  @Input() icon;
  @Input() title;
  @Input() body;
  @Input() disableCancelButton = false;
  @Input() cancelButtonTitle = 'GENERAL.CANCEL_ACTION';
  @Input() confirmButtonTitle = 'GENERAL.CONFIRM_ACTION';
  @Input() confirmButtonColor = 'primary'
  @Input() dynamicWidth = false;
  @Input() disableClose = false;
  @Input() noPadding = false;
  @Input() disableConfirmButton = false;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogV2Component>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    [
      this.color,
      this.icon,
      this.title,
      this.body,
      this.confirmButtonTitle,
      this.confirmButtonColor,
      this.cancelButtonTitle,
      this.dynamicWidth,
      this.noPadding,
      this.disableCancelButton,
      this.disableConfirmButton,
    ] = [
      data.color || this.color,
      data.icon,
      data.title,
      data.body,
      data.confirmButtonTitle || this.confirmButtonTitle,
      data.confirmButtonColor || this.confirmButtonColor,
      data.cancelButtonTitle || this.cancelButtonTitle,
      data.dynamicWidth || this.dynamicWidth,
      data.noPadding,
      data.disableCancelButton || false,
      data.disableConfirmButton || false,
    ];
  }
}
