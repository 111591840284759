<ng-container *ngIf="isAuthorized">
  <button mat-mini-fab color="primary" [matMenuTriggerFor]="quickStartMenu"
          [matTooltip]="'GENERAL.CREATE_WORKFLOW_ACTION' | translate" [matTooltipPosition]="'above'">
    <mat-icon>add</mat-icon>
  </button>
  <mat-menu #quickStartMenu="matMenu" yPosition="below" xPosition="before" class="quick-start-menu">
    <!-- TODO : clean up of unused upload icon component  -->
    <!-- <dvtx-upload-icon *ngIf="(featureSet$ | async)?.hasMyStorage"></dvtx-upload-icon> -->
    <dvtx-project-quickstarter></dvtx-project-quickstarter>
    <dvtx-project-quickstarter-from-template></dvtx-project-quickstarter-from-template>
    <!-- INFO: Fibu-Project is disabled because of business logic change. -->
    <!--       Entry kept for documentation how to enable profile and feature toggle based projects with own icon and test -->
    <!-- <dvtx-project-quickstarter *ngIf="(featureSet$ | async)?.hasBookman"-->
    <!--                            [title]="'FIBU.FIBU_WORKFLOWS_PROJECT'"-->
    <!--                            [subTitle]="'FIBU.FIBU_WORKFLOWS_PROJECT_SUBTITLE'"-->
    <!--                            [link]="'/projects/new'"-->
    <!--                            [icon]="'fibu_workflow'"-->
    <!--                            [profile]="ProcessProfile.Fibu"></dvtx-project-quickstarter>-->
    <dvtx-quick-communication></dvtx-quick-communication>
    <dvtx-quickshare-quickstarter></dvtx-quickshare-quickstarter>
    <dvtx-document-signature-selection-menu [action]="DocumentSignatureSelectionMenuViewType.Quickstart"></dvtx-document-signature-selection-menu>
    <dvtx-collector-quickstarter></dvtx-collector-quickstarter>
<!--    <dvtx-fibu-quickstarter *ngIf="(featureSet$ | async)?.hasBookman"></dvtx-fibu-quickstarter>-->
  </mat-menu>
</ng-container>
