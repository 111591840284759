import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {AuditContractee} from './audit-contractee';

export class AuditContracteeBuilder implements IApiResourceBuilder<AuditContractee> {
  processId: string;

  constructor(processId: string) {
    this.processId = processId;
  }

  fromResponse(data): AuditContractee {
    return new AuditContractee(
      data.id,
      this.processId,
      data.attributes.name,
      data.attributes.legal_form_id
      );
  }

  toRequest(_: AuditContractee) {
    return null;
  }
}
