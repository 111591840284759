import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Membership} from '../../models/membership.model';

export interface State extends EntityState<Membership> {
  my?: string
  loading: boolean
}

export const adapter = createEntityAdapter<Membership>();

export const initialState: State = adapter.getInitialState({
  my: undefined,
  loading: false,
});
