import {Action} from '@ngrx/store';
import {IKanbanBoardParams, IKanbanBoardTypes} from './kanban-board.interface';
import {KanbanBoard} from './kanban-board';

export enum KanbanBoardActionTypes {
  Create = '[KanbanBoard] Create',
  CreateSuccess = '[KanbanBoard] Create Success',
  CreateFail = '[KanbanBoard] Create Fail',
  Edit = '[KanbanBoard] Update',
  EditSuccess = '[KanbanBoard] Edit Success',
  EditFail = '[KanbanBoard] Edit Fail',
  LoadAll = '[KanbanBoard] Load All',
  LoadAllSuccess = '[KanbanBoard] Load All Success',
  LoadAllFail = '[KanbanBoard] Load All Fail',
  LoadOne = '[KanbanBoard] Load One',
  LoadOneSuccess = '[KanbanBoard] Load One Success',
  LoadOneFail = '[KanbanBoard] Load One Fail',
  Remove = '[KanbanBoard] Remove',
  RemoveSuccess = '[KanbanBoard] Remove Success',
  RemoveFail = '[KanbanBoard] Remove Fail',
  Select = '[KanbanBoard] Select',
  SelectSuccess = '[KanbanBoard] Select Success',
  Reset = '[KanbanBoard] Reset'
}

export class Create implements Action {
  readonly type = KanbanBoardActionTypes.Create;

  constructor(public params: IKanbanBoardParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = KanbanBoardActionTypes.CreateSuccess;

  constructor(public kanbanBoard: KanbanBoard) {
  }
}

export class CreateFail implements Action {
  readonly type = KanbanBoardActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Edit implements Action {
  readonly type = KanbanBoardActionTypes.Edit;

  constructor(public params: IKanbanBoardParams) {
  }
}

export class EditSuccess implements Action {
  readonly type = KanbanBoardActionTypes.EditSuccess;

  constructor(public kanbanBoard: KanbanBoard) {
  }
}

export class EditFail implements Action {
  readonly type = KanbanBoardActionTypes.EditFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = KanbanBoardActionTypes.LoadAll;

  constructor(public processId: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = KanbanBoardActionTypes.LoadAllSuccess;

  constructor(public kanbanBoards: KanbanBoard[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = KanbanBoardActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = KanbanBoardActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = KanbanBoardActionTypes.LoadOneSuccess;

  constructor(public kanbanBoard: KanbanBoard) {
  }
}

export class LoadOneFail implements Action {
  readonly type = KanbanBoardActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = KanbanBoardActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = KanbanBoardActionTypes.RemoveSuccess;

  constructor(public id: string) {
  }
}

export class RemoveFail implements Action {
  readonly type = KanbanBoardActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Select implements Action {
  readonly type = KanbanBoardActionTypes.Select;

  constructor(public board: string, public processId: string = null, public boardType: IKanbanBoardTypes) {
  }
}

export class SelectSuccess implements Action {
  readonly type = KanbanBoardActionTypes.SelectSuccess;

  constructor(public board: string) {
  }
}


export class Reset implements Action {
  readonly type = KanbanBoardActionTypes.Reset;

  constructor() {
  }
}


export type KanbanBoardActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Edit
  | EditSuccess
  | EditFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Select
  | SelectSuccess
  | Reset;
