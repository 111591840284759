
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILabelParams, LabelScope, LabelOrigin } from 'app/+store/label/label.interface';
import { LabelActions } from 'app/+store';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { Label } from 'app/+store/label/label';

@Component({
  selector: 'dvtx-create-label-dialog',
  templateUrl: './create-label-dialog.component.html',
  styleUrls: ['./create-label-dialog.component.scss'],
})
export class CreateLabelDialogComponent implements OnInit {
  label: Label;
  editingMode = false;
  scope: LabelScope = LabelScope.PERSONAL;
  origin: LabelOrigin = LabelOrigin.NO_ORIGIN;

  constructor(
    private _store: Store<AppState>,
    public dialogRef: MatDialogRef<CreateLabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.label) {
      this.label = data.label;
      this.editingMode = true;
    }
    if (data.scope) {
      this.scope = data.scope;
    }
  }

  ngOnInit(): void {
  }

  submitCreateLabel(event: ILabelParams) {
    const labelParams = event;
    if (this.editingMode) {
      this._store.dispatch(new LabelActions.Edit(this.data.label.id, labelParams));
    } else {
      this._store.dispatch(new LabelActions.Create(labelParams));
    }
    this.dialogRef.close();
  }
}
