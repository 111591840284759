import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {of} from 'rxjs';
import {ProcessTreeNode} from 'app/+store/process-tree/process-tree';
import {ProcessTreeNodeType} from 'app/+store/process-tree/process-tree.interface';
import {TreeData} from '../../../../../process-tree/common/process-tree-data';
import {TranslateService} from '@ngx-translate/core';

/**
 * @Input nodes: ProcessTreeNode[] - Takes nodes of type ProcessTreeNode.
 * @Output onChange: ProcessTreeNode . Emits the selected node of type ProcessTreeNode;
 */
@Component({
  selector: 'dvtx-process-tree-single-select',
  templateUrl: './process-tree-single-select.component.html',
  styleUrls: ['./process-tree-single-select.component.scss']
})
export class ProcessTreeSingleSelectComponent implements OnInit, AfterViewInit {
  ProcessTreeNodeType = ProcessTreeNodeType;
  allnodes: TreeData[] = [];
  @ViewChild('tree', { static: true }) tree; // obtain reference to treeControl.
  nestedDataSource: TreeData[];
  nestedTreeControl: NestedTreeControl<TreeData>;
  noTitlePlaceholder: string;
  private initialized = false;

  @Input() selectedNodeId = null;

  // tslint:disable-next-line:no-input-rename
  @Input('onFolderClickCb') onFolderClickCb: (nodeId) => void;
  // call it: this.onFolderClickCb()
  // tslint:disable-next-line:no-input-rename
  @Input('initialExpandAll') initialExpand: boolean = false;
  // tslint:disable-next-line:no-input-rename
  @Input('initialExpandRoot') initialExpandRoot: boolean = false;

  // tslint:disable-next-line:no-input-rename
  @Input() set nodes(treeNodes: ProcessTreeNode[]) {
    // this.processNodes = treeNodes;
    // ProcessTreeNodeType.Task || ProcessTreeNodeType.Process
    // console.log('input() triggered')
    const nodes = this.encodeNodes('Input() ', treeNodes);
    if (nodes) {
      this.nestedDataSource = nodes[0].children;
      this.nestedTreeControl.dataNodes = nodes[0].children; // this is workaround to enable expandall

      if (this.initialExpand || treeNodes.length && treeNodes.length <= 4) {
        this.treeControlExpandAll();
      } else if (this.initialExpandRoot) {
        this.treeControlExpandRoot();
      }
    }
  }

  // tslint:disable-next-line:no-input-rename
  @Output() onChange = new EventEmitter<ProcessTreeNode>();

  constructor(private _translateSvc: TranslateService, private _cdr: ChangeDetectorRef) {
    this.nestedTreeControl = new NestedTreeControl(this.getChildren);
      this.noTitlePlaceholder = this._translateSvc.instant('GENERAL.NO_TITLE');
  }

  ngOnInit() {
    this.initialized = true;
  }

  getChildren = (node: TreeData) => of(node.children);

  ngAfterViewInit() {
    if (this.initialExpand || this.allnodes.length && this.allnodes.length <= 4) {
      this.treeControlExpandAll()
    } else if (this.initialExpandRoot) {
      this.treeControlExpandRoot()
    }
  }

  change(node: ProcessTreeNode) {
    this.onChange.emit(node);
    // try function

  }

  public isExpanded(node) {
    return this.nestedTreeControl.isExpanded(node);
  }

  public expanderIcon(node) {
    return this.isExpanded(node) ? 'expand_more' : 'chevron_right';
  }

  onToggleNodeExpanded(node: TreeData) {
    // console.log('ProcessTreeSingleSelectComponent#onToggleNodeExpanded clicked()', node);
    try {
      if (node.type === ProcessTreeNodeType.Folder)
        this.onFolderClickCb(node.id);
    } catch (err) {
      console.error('ProcessTreeSingleSelectComponent#onToggleNodeExpanded', err)
    }
  }

  /**
   * This method is invoked by single select button entry
   * @param id
   */
  selectNode(node: TreeData) {
    // console.log(`ProcessTreeSingleSelectComponent#selectNode clicked! id:${node.id}`)
    this.allnodes.filter(n => n.ngClass !== '').forEach(n => n.ngClass = '')
    this.allnodes.filter(n => n.id === node.id).forEach(n => {
      n.ngClass = 'dvtx-selected-node';
      // console.log('ProcessTreeSingleSelectComponent#selectNode updated!');
      this.change(new ProcessTreeNode(n.id, ProcessTreeNodeType.Process, n.parentid, n.name, null))
      this.onToggleNodeExpanded(node)
    })
  }


  hasChild(_: number, node: TreeData) {
    return node.children != null && node.children.length > 0;
  }

  private encodeNodes(cause: string, processNodes: ProcessTreeNode[]): TreeData[] {
    this.allnodes = [];

    const rootnode: TreeData = {
      name: 'Workflow Process Tree',
      iconname: 'stars',
      children: [],
      ngClass: '',
      type: ProcessTreeNodeType.Default
    }

    if (!processNodes || typeof processNodes === 'undefined') {
      console.log('Process nodes empty!');
      return null;
    } else {
      console.log(`=> proc nodes present!  ${processNodes.length} `)
    }

    processNodes.filter(n => n.type === ProcessTreeNodeType.Process || n.type === ProcessTreeNodeType.Folder || n.type === ProcessTreeNodeType.CollectoElement || n.type === ProcessTreeNodeType.Root).forEach(n => {
      const thisNode: TreeData = {
        id: n.id,
        parentid: n.parentId,
        name: n.title,
        iconname: this.getIconName(n),
        children: [],
        ngClass: n.active ? 'active' : '',
        active: n.active,
        icon: n.icon,
        isSvgIcon: n.isSvgIcon,
        closed: n.closed,
        type: n.type
      };

      this.allnodes.push(thisNode);
    });

    this.allnodes.forEach(n => {
      if (n.parentid == null) {
        rootnode.children.push(n);
      } else {
        this.allnodes.filter(o => o.id === n.parentid).forEach(node => {
          node.children.push(n);
        })
      }
    });


    // attaching task nodes
    processNodes.filter(n => n.type === ProcessTreeNodeType.Task || n.type === ProcessTreeNodeType.Document).forEach(n => {

      const thisNode: TreeData = {
        id: n.id,
        parentid: n.parentId,
        name: n.title,
        iconname: this.getIconName(n),
        children: [],
        ngClass: n.active ? 'active' : '',
        active: n.active,
        closed: n.closed,
        icon: n.icon,
        isSvgIcon: n.isSvgIcon,
        type: n.type
      };

      this.allnodes.push(thisNode);

      const taskParent = this.allnodes.filter(o => o.id === n.parentId);

      if (taskParent.length > 0) {
        taskParent.forEach(node => {
          node.children.push(thisNode);
        })
      } else rootnode.children.push(thisNode); // attaching orphaned tasks into root.
    });
    return [rootnode];
  }

  private getIconName(node: ProcessTreeNode) {
    switch (node.type) {
      case(ProcessTreeNodeType.Folder):
        return 'folder';
      case(ProcessTreeNodeType.Document):
        return 'file_copy';
      case(ProcessTreeNodeType.Process):
        return 'trending_up';
      case(ProcessTreeNodeType.Task):
        return 'play_circle_outline';
      case(ProcessTreeNodeType.CollectoElement):
        return 'playlist_add_check';
      default:
        return 'star';
    }
  }

  /**
   * Expand all nodes
   * ====================================================================
   * Working around issue that initially expanding all nodes did not work.
   * ====================================================================
   * For the expandAll functionality to work,
   * make sure the dataNodes variable of the TreeControl must be set to all root level data nodes of the tree
   *
   * Notice the treeControl.dataNodes = nodes; before calling expandAll()
   *
   * following test tells do we having any expanded nodes
   * expect(treeControl.expansionModel.selected.length).toBe(0, `Expect no expanded nodes`);
   */
  private treeControlExpandAll() {
    try {
      this.nestedTreeControl.expandAll();
    } catch (error) {
      console.log(`Exception: treeControlExpandAll() ${(<Error>error).message}`)
    }

    if (this.initialized) {
      this._cdr.detectChanges();
    }
  }

  private treeControlExpandRoot() {
    const nodeMap = {};
    let activeNode = null;
    try {
      this.nestedTreeControl.dataNodes.forEach(node => {
        nodeMap[node.id] = node;
        if (node.active) {
          activeNode = node;
        }
      });
      this.nestedTreeControl.dataNodes.forEach(
        node => {
          if (!node.parentid) {
            this.nestedTreeControl.expand(node);
          }

          if (activeNode) {
            this._expandRecursive(nodeMap, activeNode);
          }
          // this.nestedTreeControl.expand(node)
          // if (node.children)
          //   node.children.forEach(
          //     node2 => this.nestedTreeControl.expand(node2)
          //   )
        })
      if (this.initialized) {
        this._cdr.detectChanges();
      }
    } catch (error) {
      console.log(`Exception: treeControlExpandRoot() ${(<Error>error).message}`)
    }
  }

  private _expandRecursive(nodeMap, activeNode) {
    if (activeNode.parentId && nodeMap[activeNode.parentId]) {
      const node = nodeMap[activeNode.parentId];
      this.nestedTreeControl.expand(node);
      if (node.parentId && nodeMap[node.parentId]) {
        this._expandRecursive(nodeMap, node);
      }
    }
  }
}
