import {Component, Inject, OnInit, Input} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dvtx-licence-upgrade-contact-admin-dialog',
  templateUrl: './licence-upgrade-contact-admin-dialog.component.html',
  styleUrls: ['./licence-upgrade-contact-admin-dialog.component.scss']
})
export class LicenceUpgradeContactAdminDialogComponent {

  submitButtonTitle = 'GENERAL.CONFIRM_ACTION';
  cancelButtonTitle = 'GENERAL.CANCEL_ACTION';

  constructor(
    public dialogRef: MatDialogRef<LicenceUpgradeContactAdminDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LicenceUpgradeContactAdminDialogData
  ) {
    if (this.data.submitButtonTitle) {
      this.submitButtonTitle = this.data.submitButtonTitle;
    }

    if (this.data.cancelButtonTitle) {
      this.cancelButtonTitle = this.data.cancelButtonTitle;
    }
  }

  onSubmitClick(): void {
    this.data.onSubmitAction();
    this.dialogRef.close();
  }

  onNoClick(): void {
    if (this.data.onCancelAction) {
      this.data.onCancelAction();
    }
    this.dialogRef.close();
  }
}

export interface LicenceUpgradeContactAdminDialogData {
  title: string;
  message: string;
  allowComment?: boolean;
  onSubmitAction: () => void;
  onCancelAction?: () => void;
  submitButtonTitle?: string;
  cancelButtonTitle?: string;
}
