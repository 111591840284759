import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FoxdoxAccountService} from './foxdox-account.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    FoxdoxAccountService
  ]
})
export class FoxdoxAccountStoreModule {
}
