import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {CommentBuilder, CommentStatisticsBuilder} from './comment.builder';
import {Comment, CommentProcessStatistics} from './comment';
import {CommentReactionType, CommentType} from './comment.interface';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {EnvService} from 'app/shared/modules/api-resource/services/env.service';

@Injectable()
export class CommentService {
  readonly BASE_PATH = 'api/v1/comments';
  readonly WFE_BASE_PATH = 'api/v1/workflow_engine/processes';
  readonly ARTIFACT_COMMENT_BASE_PATH = 'api/v1/artifacts/artifacts';
  apiUrl: any;

  constructor(private _http: ApiResourceService, private _httpClient: HttpClient, private env: EnvService) {
    this.apiUrl = env.apiBase();
  }

  sendComment(comment: Comment): Observable<Comment> {
    const builder = new CommentBuilder();
    const payload = builder.toRequest(comment);
    return <Observable<Comment>>this._http.post<CommentBuilder, Comment>(builder, `${this.BASE_PATH}/drafts/${comment.id}/send`, payload);
  }

  createProcessComment(processId: string, comment: Comment): Observable<Comment> {
    const builder = new CommentBuilder(CommentType.Comment);
    const payload = builder.toRequest(comment);
    return <Observable<Comment>>this._http.post<CommentBuilder, Comment>(builder, `${this.WFE_BASE_PATH}/${processId}/comments`, payload);
  }

  getAllProcessComments(processId: string, includeChildren = false, resourceId: string = null): Observable<Comment[]> {
    const _queryParams = [];
    if (includeChildren) {
      _queryParams.push('include_children=true');
    }
    if (resourceId) {
      _queryParams.push(`resource_id=${resourceId}`);
    }
    const builder = new CommentBuilder(CommentType.Comment);
    const queryParams = _queryParams.length > 0 ? `?${_queryParams.join('&')}` : '';
    return <Observable<Comment[]>>this._http.get<CommentBuilder, Comment>(builder, `${this.WFE_BASE_PATH}/${processId}/comments${queryParams}`);
  }

  getOneProcessComment(processId: string, commentId: string): Observable<Comment> {
    const builder = new CommentBuilder(CommentType.Comment);
    return <Observable<Comment>>this._http.get<CommentBuilder, Comment>(builder, `${this.WFE_BASE_PATH}/${processId}/comments/${commentId}`);
  }

  getCommentProcessStatistics(processId: string, recursive = false): Observable<CommentProcessStatistics> {
    const builder = new CommentStatisticsBuilder(processId);
    return <Observable<CommentProcessStatistics>>this._http.get<CommentStatisticsBuilder, CommentProcessStatistics>(builder, `${this.WFE_BASE_PATH}/${processId}/comments/statistics?recursive=${recursive}`);
  }

  markProcessCommentRead(processId: string, commentId: string): Observable<Comment> {
    const builder = new CommentBuilder(CommentType.Comment);
    return <Observable<Comment>>this._http.post<CommentBuilder, Comment>(builder, `${this.WFE_BASE_PATH}/${processId}/comments/${commentId}/read`, {});
  }

  doReact(processId: string, commentId: string, reactionType: CommentReactionType): Observable<Comment> {
    const builder = new CommentBuilder(CommentType.Comment);
    const payload = { data: { attributes: { reaction_type: reactionType } } }
    return <Observable<Comment>>this._http.post<CommentBuilder, Comment>(builder, `${this.WFE_BASE_PATH}/${processId}/comments/${commentId}/reaction`, payload);
  }

  getAllArtifactComments(artifactId: string): Observable<Comment[]> {
    const builder = new CommentBuilder(CommentType.Comment);
    return <Observable<Comment[]>>this._http.get<CommentBuilder, Comment>(builder, `${this.ARTIFACT_COMMENT_BASE_PATH}/${artifactId}/comments`);
  }

  getOneArtifactComment(artifactId: string, commentId: string): Observable<Comment> {
    const builder = new CommentBuilder(CommentType.Comment);
    return <Observable<Comment>>this._http.get<CommentBuilder, Comment>(builder, `${this.ARTIFACT_COMMENT_BASE_PATH}/${artifactId}/comments/${commentId}`);
  }

  updateArtifactComment(artifactId: string, commentId: string, content: string): Observable<Comment> {
    const builder = new CommentBuilder(CommentType.Comment);
    const payload = {
      data: {
        attributes: {
          content: content
        }
      }
    }
    return <Observable<Comment>>this._http.put<CommentBuilder, Comment>(builder, `${this.ARTIFACT_COMMENT_BASE_PATH}/${artifactId}/comments/${commentId}`, payload);
  }

  deleteArtifactComment(artifactId: string, commentId: string): Observable<Comment> {
    const builder = new CommentBuilder(CommentType.Comment);
    return <Observable<Comment>>this._http.del<CommentBuilder, Comment>(builder, `${this.ARTIFACT_COMMENT_BASE_PATH}/${artifactId}/comments/${commentId}`);
  }

  markArtifactCommentRead(artifactId: string, commentId: string): Observable<Comment> {
    const builder = new CommentBuilder(CommentType.Comment);
    return <Observable<Comment>>this._http.post<CommentBuilder, Comment>(builder, `${this.ARTIFACT_COMMENT_BASE_PATH}/${artifactId}/comments/${commentId}/read`, {});
  }

  doReactOnArtifactComment(artifactId: string, commentId: string, reactionType: CommentReactionType): Observable<Comment> {
    const builder = new CommentBuilder(CommentType.Comment);
    const payload = { data: { attributes: { reaction_type: reactionType } } }
    return <Observable<Comment>>this._http.post<CommentBuilder, Comment>(builder, `${this.ARTIFACT_COMMENT_BASE_PATH}/${artifactId}/comments/${commentId}/reaction`, payload);
  }

  sendArtifactComment(artifactId: string, content: string): Observable<Comment> {
    const builder = new CommentBuilder(CommentType.Comment);
    const payload = {
      data: {
        attributes: {
          content: content
        }
      }
    }
    return <Observable<Comment>>this._http.post<CommentBuilder, Comment>(builder, `${this.ARTIFACT_COMMENT_BASE_PATH}/${artifactId}/comments`, payload);
  }

  downloadArtifactComments(artifactId, ids: string[]) {
    const payload = {
      data: {
        attributes: {
          ids: ids
        }
      }
    }
    return this._httpClient.post(`${this.apiUrl}/${this.ARTIFACT_COMMENT_BASE_PATH}/${artifactId}/comments/download`, payload)
      .pipe(map(res => {
        return {
          content: res['data']['attributes']['content']
        };
      }))
  }
}
