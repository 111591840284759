import {
  ThirdPartyConfirmationLeasing,
  ThirdPartyConfirmationLeasingRow
} from './leasing';

export namespace ThirdPartyConfirmationLeasingBuilder {
  export function fromResponse(response): ThirdPartyConfirmationLeasing {
    const thirdParty = new ThirdPartyConfirmationLeasing(
      response.id,
      response.type,
      response.attributes.parent_process_id,
      response.attributes.referenced_at,
      response.attributes.due_date,
      response.attributes.color,
      response.attributes.language || 'de',
      response.attributes.rows.map((row) => {
        return new ThirdPartyConfirmationLeasingRow(
          row.id,
          row.color || response.attributes.color,
          row.lfd,
          row.referenced_at || response.attributes.referenced_at,
          row.due_date || response.attributes.due_date,
          row.comment || '',
          row.language || response.attributes.language || 'de',
          row.vertragspartner_name,
          row.vertragspartner_ansprechpartner_name,
          row.vertragspartner_ansprechpartner_email,
          row.vertragspartner_ansprechpartner_handy)
      })
    );
    thirdParty.emailAttachmentFilename = response.attributes.email_attachment_filename;
    thirdParty.state = response.attributes.state;

    return thirdParty;
  }

  export function toRequest(resource: ThirdPartyConfirmationLeasing) {
    return {
      id: resource.id,
      type: resource.type,
      attributes: {
        parent_process_id: resource.parent_process_id,
        referenced_at: resource.referenced_at,
        due_date: resource.due_date,
        language: resource.language,
        rows: resource.rows.map((row) => {
          return {
            id: row.id,
            color: row.color,
            lfd: row.lfd,
            referenced_at: row.referenced_at,
            due_date: row.due_date,
            comment: row.comment,
            language: row.language,
            vertragspartner_name: row.vertragspartner_name,
            vertragspartner_ansprechpartner_name: row.vertragspartner_ansprechpartner_name,
            vertragspartner_ansprechpartner_email: row.vertragspartner_ansprechpartner_email,
            vertragspartner_ansprechpartner_handy: row.vertragspartner_ansprechpartner_handy
          }
        })
      }
    };
  }
}
