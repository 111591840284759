import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {CmsBlog} from './cms-blog';

export interface State extends EntityState<CmsBlog> {
  selected?: CmsBlog;
  loading: boolean;
}

export const adapter = createEntityAdapter<CmsBlog>();

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
