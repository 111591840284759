<div>
  <div class="md-form" *ngIf="step === 7">
    <hr>
    <dvtx-two-factor-verification
      [form]="checkDataForm"
      [error]="codeMissMatch"
    ></dvtx-two-factor-verification>
    <div class="deactivate">
      <button type="reset" mat-button color="warn" (click)="cancelAction()"
              class="wide">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
      <button mat-raised-button (click)="removeAuthentication();" color="primary"
              [disabled]="
          checkDataForm.controls.firstInput.invalid
          || checkDataForm.controls.secondInput.invalid
          || checkDataForm.controls.thirdInput.invalid
          || checkDataForm.controls.fourthInput.invalid
          || checkDataForm.controls.fifthInput.invalid
          || checkDataForm.controls.sixthInput.invalid">
        {{'AUTH.DEACTIVATION_2FA' | translate}}
      </button>
    </div>
  </div>


  <div style="padding-left: 0px;" *ngIf="step === 1">
    <div class="main-div">
      <div class="first-div">
        <h2>{{'AUTH.PHONE_NUMBER_HEADLINE' | translate}}</h2>
        <p>{{'AUTH.PHONE_NUMBER_SUBTITLE' | translate}}</p>
        <div class="col-12" [formGroup]="checkDataForm">
          <dvtx-telephone-input
            #phoneNumber
            id="phoneNumber"
            [formControlName]="'phone'"
            [isType]="false"
            name="phone"
            [options]="options"
            (autoCompleteFun)="autoCompleteFunction($event)"
          >
          </dvtx-telephone-input>
        </div>
      </div>
      <div class="second-div">
        <div>
          <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200">info</mat-icon>
        </div>
        <div>
          <li> {{'AUTH.PHONE_NUMBER_INFO' | translate}} </li>
        </div>
      </div>
    </div>
    <div class="action-card">
      <button type="reset" mat-button color="warn" (click)="cancelAction()"
              class="wide">{{ 'GENERAL.RESET_ACTION' | translate }}</button>
      <button mat-raised-button (click)="enableSMSAuthentication()" color="primary"
              [disabled]="!checkDataForm.controls['phone'].value">{{'AUTH.SEND_CONFIRMATION' | translate}}</button>
    </div>
  </div>


  <div style="padding-left: 0px;" *ngIf="step === 2">
    <div class="main-div">
      <div class="first-div">
        <h2>{{'AUTH.PHONE_NUMBER_HEADLINE' | translate}}</h2>
        <!-- <p>{{'AUTH.PHONE_NUMBER_CONFIRM' | translate}}</p> -->
        <div class="inline">
          <p> {{'AUTH.CONFIRM_MSG_SENT' | translate}}
            {{this.PhoneNumberValue}}.
            {{'AUTH.PHONE_IS_WRONG' | translate}}
            <a  (click)="editPhoneNumber();" [matTooltip]="'AUTH.CLICK_HERE_TO_EDIT' | translate"> {{ 'AUTH.CLICK_HERE_TO_EDIT' | translate}} </a>
            {{ 'GENERAL.OR' | translate}}
            <a (click)="enableSMSAuthentication();" [matTooltip]="'AUTH.SEND_AGAIN' | translate"> {{'AUTH.SEND_AGAIN' | translate}} </a>.
          </p>
        </div>
        <mat-form-field [formGroup]="checkDataForm">
            <input matInput [placeholder]="'AUTH.SMS_CONFIRMATION' | translate" [formControlName]="'SMSConfirmation'"
                   name="SMSPin" [maxlength]="6" [minlength]="6" required>
          </mat-form-field>
      </div>
    </div>
    <div class="action-card">
      <button type="reset" mat-button color="warn" (click)="cancelAction()"
              class="wide">{{ 'GENERAL.RESET_ACTION' | translate }}</button>
      <button mat-raised-button (click)="verifySMSAuthentication()" color="primary"
              [disabled]="checkDataForm.controls.SMSConfirmation.invalid || checkDataForm.controls.SMSConfirmation.value.length !== 6">{{'AUTH.ACTIVATE' | translate}}</button>
    </div>
  </div>

  <div style="padding-left: 0px;" *ngIf="step === 3">
    <div class="main-div">
      <div class="first-div">
        <h2>{{'AUTH.PHONE_NUMBER_ACTIVE' | translate}}</h2>
        <p>{{'AUTH.PHONE_NUMBER_ACTIVE_SUBTITLE' | translate}}</p>
      </div>
    </div>
    <div class="action-card">
      <button type="reset" mat-button color="warn" (click)="cancelAction()"
              class="wide">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
      <button mat-raised-button color="primary" (click)="done()">{{ 'AUTH.SAVE_FINISH' | translate }}</button>
      <button mat-raised-button (click)="enableAuthentication(5);"
              color="primary">{{'AUTH.SAVE_AND_CONTINUE' | translate}}</button>
    </div>
  </div>

  <div style="padding-left: 0px;" *ngIf="step === 4">
    <div class="main-div">
      <div class="first-div">
      </div>
      <div class="second-div">
        <div>
          <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200">info</mat-icon>
        </div>
      </div>
    </div>
    <div class="action-card">
      <button type="reset" mat-button color="warn" (click)="cancelAction()"
              class="wide">{{ 'GENERAL.RESET_ACTION' | translate }}</button>
      <button mat-raised-button (click)="verifyPhoneNumber()" color="primary">{{'GENERAL.NEXT' | translate}}</button>
    </div>
  </div>

  <div style="padding-left: 0px;" *ngIf="step === 5">
    <div class="main-div">
      <div class="first-div">
        <h2>{{'AUTH.APP_HEADLINE' | translate}}</h2>
        <p>{{'AUTH.APP_SUBTITLE' | translate}}</p>
        <mat-form-field [formGroup]="checkDataForm">
          <input matInput [placeholder]="'AUTH.SMS_CONFIRMATION' | translate" [formControlName]="'MobileConfirmation'"
                 name="MobileConfirmation" maxlength="6" minlength="6">
        </mat-form-field>
      </div>
      <div class="second-div">
        <div>
          <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200">info</mat-icon>
        </div>
        <div>
          <li> {{'AUTH.APP_INFO' | translate}} </li>
        </div>
      </div>
    </div>
    <div class="action-card">
      <button type="reset" mat-button color="warn" (click)="cancelAction()"
              class="wide">{{ 'GENERAL.RESET_ACTION' | translate }}</button>
      <button mat-raised-button (click)="enablMobileAuthentication()" color="primary"
              [disabled]="checkDataForm.controls.MobileConfirmation.invalid || checkDataForm.controls.MobileConfirmation.value.length !== 6">{{'AUTH.ACTIVATE' | translate}}</button>
    </div>
  </div>

  <div style="padding-left: 0px;" *ngIf="step === 6">
    <div class="main-div">
      <div class="first-div">
        <h2>{{'AUTH.APP_HEADLINE' | translate}}</h2>
        <p>{{'AUTH.APP_SCAN_QR' | translate}}</p>
        <dvtx-qr-confirmation-dialog
          class="text"
          [QRCode]="(authenticationStatus.mobile)?.mobileImage"
        ></dvtx-qr-confirmation-dialog>
      </div>
      <!-- <div class="second-div">
        <div>
          <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200">info</mat-icon>
        </div>
        <div>
          <li> {{'AUTH.APP_SCAN_QR_INFO' | translate}}  </li>
        </div>
      </div> -->
    </div>
    <div class="action-card">
      <button mat-raised-button (click)="done()" color="primary">{{'AUTH.ACTIVATE' | translate}}</button>
    </div>
  </div>
</div>
