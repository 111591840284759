import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Sizes } from '../../models/avatar';
import { MatDialog } from '@angular/material/dialog';
import { TooltipLikeDialogComponent } from 'app/shared/components/dialogs/tooltip-like-dialog/tooltip-like-dialog.component';
import { AvatarService } from '../avatar/avatar.service';
import { Profile } from '../../models/profile';
import { Sorters } from 'app/lib/sorter/sorters';
import { AppState } from 'app/reducers';
import { Store } from '@ngrx/store';
import { EmailToNameSelectors, NaturalPersonSelectors } from 'app/+store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dvtx-avatars-group',
  templateUrl: './avatars-group.component.html',
  styleUrls: ['./avatars-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AvatarsGroupComponent implements OnChanges {
  public avatarSizeNumber: number;
  public avatarSizeString: string;
  public avatarShowTitle: boolean = false;
  public containerClass;
  public itemsClass;
  private _profiles: Profile[];
  @Input() set profiles(profiles: Profile[]) {
    if (profiles && profiles.length) {
      this._profiles = profiles.filter(p => !!p && p.email).sort((l, r) => Sorters.sort(l.email, r.email));
      this._profiles.forEach(profile => {
        if (profile.lastName && profile.firstName) {
          profile.name = profile.lastName + ', ' + profile.firstName;
        } else {
          this._store.select(EmailToNameSelectors.getByEmail(profile.email)).pipe(first()).subscribe((x) => {
            if (x) {
              profile.firstName = x.firstName;
              profile.lastName = x.lastName;
              if (x.firstName || x.lastName) {
                profile.name = x.lastName + ', ' + x.firstName;
              } else {
                profile.name = null;
              }
            }
          });
        }
      })
    } else {
      this._profiles = [];
    }
  }
  get profiles() {
    return this._profiles;
  }
  @Input() public scrollableDynamicTooltip = false;
  public contentTooltipAvatar;
  @Input() maxToShow: number = 5;
  @Input() removable: boolean = false;
  @Input() showMore = true;
  @Input() set listStyle(listStyle) {
    if (listStyle === 'inline') {
      this.containerClass = listStyle + ' mb-0 d-flex align-items-top';
      this.itemsClass = 'list-inline-item';
    }
  }
  @Input() set extraClasses(extraClasses) {
    this.containerClass += extraClasses;
  };
  @Input() set size(size: any) {
    this.avatarSizeNumber = +Sizes[size];
    this.avatarSizeString = size;
  };
  @Input() set showTitle(showTitle: boolean) {
    this.avatarShowTitle = showTitle;
  };
  @Output() onItemRemoved: EventEmitter<any> = new EventEmitter<any>(null);
  @ViewChild('ShowTooltipButton') showTooltipButton: ElementRef;

  constructor(private elementRef: ElementRef,
    private dialog: MatDialog,
    private _store: Store<AppState>,
    private avatarService: AvatarService,
    private _cdr: ChangeDetectorRef,
    private sanitized: DomSanitizer) { }

  showAllListTooltip() {
    let content = `<mat-list role="list">`;

    this.profiles.slice(this.maxToShow, this.profiles.length).map((profile) => {
      const avatar = this.avatarService.get(profile, 'sm', null, true);
      content += `<mat-list-item role="listitem" class="d-flex w-100 my-1">${avatar.innerHTML}</mat-list-item>`;
    });

    content += `</mat-list>`;

    if (this.scrollableDynamicTooltip) {
      this.contentTooltipAvatar = this.sanitized.bypassSecurityTrustHtml(content);
    } else {
      const elementWidth = this.showTooltipButton.nativeElement.getBoundingClientRect().width;
      const elementHeight = this.showTooltipButton.nativeElement.getBoundingClientRect().height;
      const elementLeftPosition = this.showTooltipButton.nativeElement.getBoundingClientRect().left;
      const elementTopPosition = this.showTooltipButton.nativeElement.getBoundingClientRect().top;

      const tooltipPosition = {
        top: elementTopPosition + elementHeight,
        left: (elementLeftPosition + (elementWidth / 2)) - (260 / 2)
      }

      // this.dialog.closeAll();
      const dialogRef = this.dialog.open(TooltipLikeDialogComponent, {
        data: {
          content: content
        },
        panelClass: 'tootltip-like-dialog',
        width: '260px',
        minWidth: '260px',
        maxWidth: '260px',
        backdropClass: 'dialog--backdrop--transparent',
        position: {
          top: tooltipPosition.top ? tooltipPosition.top + 'px' : null,
          left: tooltipPosition.left ? tooltipPosition.left + 'px' : null
        }
      });
    }
  }

  ngOnChanges(changes) {
    // console.log(changes)
    if (changes.profiles && changes.profiles.currentValue) {
      this.profiles = changes.profiles.currentValue;
    }
  }

  getProfile(email, profile) {
    const _profile = this.avatarService.getProfile(email);
    this._cdr.markForCheck();
    return _profile;
  }

  remove(item) {
    this.onItemRemoved.emit(item);
  }
}
