import {Injectable} from '@angular/core';
import * as ActionCable from 'actioncable';
import {environment} from 'environments/environment';
import {User} from '../models/user';
import Cable = ActionCable.Cable;
import Channel = ActionCable.Channel;

// ws://localhost:3000/cable/?access-token=ZigtvvcKK7B7rsF_20bGHg&client=TccPxBrirWOO9k5fK4l_NA&uid=client1@example.com

@Injectable()
export class WebsocketService {
  private _websocketServer: string = environment.websocketServer;
  private _websocketPath: string = environment.websocketPath;
  public websocketUrl: string = this._websocketServer + this._websocketPath;

  private _cable: Cable = null;
  private _user: User;

  constructor() {
  }

  subscribe(user, channel, method, data, callback): Channel {
    if (!user || !user.uid) {
      console.error(`Cable setup: User unset. Cannot connect to channel ${channel}. Exit...`);
      return null;
    }

    this.connect(user, channel);

    if (!this._cable) {
      console.error(`Cable setup incomplete. Cannot connect to channel ${channel}. Exit...`);
      return null;
    }

    const subscription = this._cable.subscriptions.create(channel, {
      connected: () => {
        setTimeout(() => {
          const msg = `channel ${channel} with method ${method}.`;
          if (!environment.production) {
            console.log(`[Websocket] Connected. Connecting to ${msg}`);
            console.log(`[Websocket] Data received: `, data);
          }
          subscription.perform(method, data);
        }, 1000);
      },
      disconnected: () => {
        if (!environment.production) {
          console.log('[Websocket] Disconnected.');
        }
      },
      received: (res) => {
        if (!environment.production) {
          console.log('[Websocket] Received', res);
        }
        callback(res);
      }
    });
    return subscription;
  }

  connect(user: User, channel: string = '') {
    // New user: disconnect consumer.
    if (this._user && this._user.uid !== user.uid && this._cable) {
      try {
        if (!environment.production) {
          console.log('[Websocket] Disconnecting cable consumer');
        }
        this._cable.disconnect();
      } catch (err) {
        console.error('Error on disconnecting cable.');
      }
    }

    // Consumer is reused as long as user is the same.
    if (this._user && this._user.uid === user.uid && this._cable) {
      if (!environment.production) {
        console.log('[Websocket] Reusing cable consumer');
      }
      return;
    }

    this._user = user;

    const uid = encodeURIComponent(user.uid);
    const _channel = encodeURIComponent(channel);
    const url = `${this.websocketUrl}/?access-token=${user.accessToken}&client=${user.client}&uid=${uid}&channel=${_channel}`;

    this._cable = ActionCable.createConsumer(url);

    if (!environment.production) {
      console.log('[Websocket] Create consumer', this._cable);
    }
  }

  disconnect() {
    if (this._cable) {
      try {
        if (!environment.production) {
          console.log('[Websocket] Disconnecting cable consumer');
        }
        this._cable.disconnect();
      } catch (err) {
        console.error('Error on disconnecting cable.');
      }
    }
  }
}
