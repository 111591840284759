import {IResource} from 'app/shared/modules/api/models/resource.interface';

export enum MilestoneType {
  CollectorNode = 'CollectorNode',
  Task = 'TASK',
  Process = 'PROCESS',
  Document = 'DOCUMENT',
  Appendix = 'APPENDIX'
}

export interface IMilestone extends IResource {
  parentId: string;
  processId: string;
  title: string;
  icon: string;
  preDueDate: Date;
  dueDate: Date;
  dueAt: string;
  todayDiff: number;
  formatedDueDate: string;
  startedAt: Date;
  completedAt: Date;
  createdAt: Date;
  updatedAt: Date;

  // TODO: Kept just for companility. Check!
  assignees;
}
