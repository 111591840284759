import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';

export interface IPublishParams {
  dms_document_id: string;
  externally_available: boolean;
  documents_externally_available_until: Date;
  password: string;
}

@Injectable()
export class PublishDocumentService {
  readonly BASE_PATH = 'api/v1/foxdox/publish';
  basePath;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  publish(params: IPublishParams) {
    const payload = {
      data: {
        attributes: {
          dms_document_id: params.dms_document_id,
          public_available: params.externally_available,
          public_available_until: params.documents_externally_available_until,
          public_password: params.password
        }
      }
    };
    return this._http.post(this.basePath, payload);
  }
}
