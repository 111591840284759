import {CacAppendix} from '../cac/cac-appendix';
import {BasicContact} from './basic-contact';
import {ISociety} from './society.interface';

export class Society implements ISociety {
  readonly type = 'society';
  contacts: BasicContact[] = [];
  appendices: CacAppendix[] = [];
  defaultAppendixReferenceMap: { [id: string]: CacAppendix } = {};
  color: string;
  checked: boolean;
  attachmentCount = 0;
  instructionAttachmentCount = 0;
  processId: string;
  partnerNo: string;

  total = 1;
  perPage = 1;
  records = 1;

  constructor(public id: string, public name: string, public countryCode: string, public abbreviation,
              public componentMateriality, public toleranceMateriality, public toleranceLimit,
              public currency, public comment) {
    if (countryCode) {
      this.countryCode = countryCode.toLowerCase();
    }
  }

  static sortByCountry = (self: Society, other: Society) => {
    if (!self || !other) return -1;
    if (self.countryCode < other.countryCode) { return -1; }
    if (self.countryCode > other.countryCode) { return 1; }
    return Society.sortByPartnerNo(self, other);
  };

  static sortByPartnerNo = (self: Society, other: Society) => {
    if (!self || !other) return -1;
    if (self.partnerNo < other.partnerNo) { return -1; }
    if (self.partnerNo > other.partnerNo) { return 1; }
    return Society.sortBySociety(self, other);
  };

  static sortBySociety = (self: Society, other: Society) => {
    if (!self || !other) return -1;
    if (self.name < other.name) { return -1; }
    if (self.name > other.name) { return 1; }
    return 0;
  };

  static toKey(society: Society) {
    const name = society.name.trim();
    return `${society.countryCode}|${society.abbreviation}|${name}|${society.partnerNo}`.toLowerCase();
  }
}
