<ng-template #callToActionRef>
  <button color="primary" dvtxContact mat-raised-button type="button">
    <mat-icon aria-label="back" matAffix>person_add</mat-icon>
    {{ 'ADDRESSBOOK.CREATE_CONTACT'| translate }}
  </button>
</ng-template>

<ng-template #globalActionRef>
  <div class="d-flex align-items-center">
    <ng-container [formGroup]="filterForm">
      <!--<dvtx-tooltip *ngIf="(filters?.options?.length + filters?.clients?.length) > 0" [tooltipText]="'FILTER.RESET_FILTER' | translate" [position]="'bottom left'">
        <button mat-icon-button color="gray" (click)="onClearAllFilters()" class="mat-icon-button-with-text">
          <mat-icon svgIcon="filter_disable"></mat-icon>
        </button>
      </dvtx-tooltip>-->
      <dvtx-filters-container [showTitle]="false" [selectedCount]="filters?.options?.length + filters?.clients?.length"
                              class="ml-2">
        <div class="row filters-header">
          <div class="col-9">
            <dvtx-api-autocompleted-input-select
              [placeholder]="'GENERAL.FILTER_SEARCH' | translate"
              [prefixIcon]="'search'"
              [form]="searchForm"
              [controlName]="'searchTerm'">
            </dvtx-api-autocompleted-input-select>
          </div>
          <div class="col-3 mb-2 d-inline-flex align-items-center justify-content-end">
            <button *ngIf="(filters?.options?.length + filters?.clients?.length) > 0" [matTooltip]="'FILTER.RESET_FILTER' | translate"
                    mat-icon-button color="gray" (click)="onClearAllFilters()">
              <mat-icon svgIcon="filter_disable"></mat-icon>
            </button>
          </div>
        </div>
        <div class="row align-items-start filters-container mt-1">
          <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 mb-2">
            <dvtx-filter-list-selection-clients
              [title]="'CLIENT.CLIENT' | translate"
              [options]="clients$ | async"
              [selectedOptions]="filters.clients"
              [search]="searchForm.get('searchTerm').value"
              [isAddressBook]="true"
              (onChangeSelection)="onFilterByClients($event)">
            </dvtx-filter-list-selection-clients>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 mb-2">
            <dvtx-filter-list-selection
              [title]="''"
              [options]="filterOptions"
              [selectedOptions]="filters.options"
              [search]="searchForm.get('searchTerm').value"
              (onChangeSelection)="onFilterByOptions($event)">
            </dvtx-filter-list-selection>
          </div>
        </div>
      </dvtx-filters-container>
    </ng-container>

    <dvtx-global-action-menu #globalMenu *ngIf="isAdmin" [openerIcon]="'more_vert'">
      <button (click)="importContacts(); globalMenu.close()"
              class="d-flex align-items-center dvtx-global-menu-item">
        <mat-icon>unarchive</mat-icon>
        {{ 'ADDRESSBOOK.IMPORT_CONTACTS'| translate }}
      </button>

      <ng-container *ngIf="isAdmin">
        <button (click)="exportAddressBook(); globalMenu.close()"
                class="d-flex align-items-center dvtx-global-menu-item">
          <mat-icon svgIcon="excel_download" style="margin-left: 0.2rem;"></mat-icon>
          {{ 'CONTACTS.ADDRESS_BOOK_EXPORT_ACTION'| translate }}
        </button>
      </ng-container>
    </dvtx-global-action-menu>
  </div>
</ng-template>

<dvtx-settings-header #settingsHeaderRef
                      [routes]="routes"
                      [activeLink]="activeLink"
                      [toOverview]="true"
                      [callToActionPortal]="callToActionPortal"
                      [globalActionPortal]="globalActionPortal"
                      (onSearch)="search($event)"
                      [enableSearch]="true"
                      [title]="'ORGANIZATION.ADDRESS_BOOK'"></dvtx-settings-header>

<dvtx-address-book class="five-f-settings-container"
                   [searchTerm]="searchTerm"
                   [contactType]="contactType"
                   [filterByClients]="filterByClients"
                   [clearAllFilters]="clearAllFilters"
                   [enableToolbar]="false"></dvtx-address-book>
