export enum RequestType {
  BalanceClosed = 'balance-closed',
  BalanceOpen = 'balance-open',
  BalanceOptional = 'balance-optional',
  Invoice = 'invoice',
  InvoiceOptional = 'invoice-optional',
  Bank = 'bank',
  Insurance = 'insurance',
  Expert = 'expert',
  Safekeeping = 'safekeeping',
  Leasing = 'leasing'
}
