import {Component, Input} from '@angular/core';
import {Browser} from 'browser-support';

@Component({
  selector: 'dvtx-unsupported-browser',
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.scss']
})
export class UnsupportedBrowserComponent {
  isUnsupportedBrowser: boolean;
  isUnsupportedVersion: boolean;

  @Input() info = 'SYSTEM.BROWSER.UNSUPPORTED_BROWSER_INFO';
  @Input() alertMsg = null;

  constructor() {
    const validator = new Browser.SupportValidator();
    this.isUnsupportedBrowser = validator.isUnsupportedBrowser;
  }
}
