import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {NaturalPersonEffects} from './natural-person.effects';
import {stateKey} from './natural-person.selectors';
import {reducer} from './natural-person.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([NaturalPersonEffects])
  ],
  providers: [NaturalPersonEffects]
})
export class NaturalPersonStoreModule {
}
