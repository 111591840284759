<div class="dvtx-tree-icon" >
  <ng-template [ngIf]="iNode.type === NodeType.Folder" [ngIfElse]="fileIcon">
    <ng-template [ngIf]="iNode.auditProof" [ngIfElse]="defaultFolderIcon">
      <span *ngIf="iNode?.auditProof"><mat-icon style="font-size: 20px">security</mat-icon></span>
    </ng-template>

    <ng-template #defaultFolderIcon>
      <span *ngIf="iNode?.shared"><mat-icon style="font-size: 20px">folder_shared</mat-icon></span>
      <span *ngIf="!iNode?.shared"><mat-icon style="font-size: 20px">folder</mat-icon></span>
    </ng-template>
  </ng-template>

  <ng-template #fileIcon>
    <ng-template [ngIf]="iNode.type === NodeType.File">
      <span><mat-icon style="font-size: 20px">insert_drive_file</mat-icon></span>
    </ng-template>

    <ng-template [ngIf]="iNode.type === NodeType.Error">
      <span><mat-icon style="font-size: 20px">error</mat-icon></span>
    </ng-template>
  </ng-template>
</div>
