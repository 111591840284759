import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {TaskAssignmentStatus} from './task-assignee.interface';
import {TaskAssignee} from './task-assignee';

export class TaskAssigneeBuilder implements IApiResourceBuilder<TaskAssignee> {
  fromResponse(data): TaskAssignee {
    const assignee = new TaskAssignee(
      data.id,
      data.attributes.email,
      data.attributes.first_name,
      data.attributes.last_name,
      data.attributes.task_id,
      data.attributes.created_at,
      data.attributes.updated_at,
      data.attributes.approved,
      data.attributes.approved_at,
      data.attributes.comment,
      data.attributes.accepted_at,
      data.attributes.creator_email);
    if (data.attributes.approved === false) {
      assignee.status = TaskAssignmentStatus.Denied;
    } else if (data.attributes.approved === true) {
      assignee.status = TaskAssignmentStatus.Approved;
    } else {
      switch (data.attributes.status) {
        case 'accepted':
          assignee.status = TaskAssignmentStatus.Accepted;
          break;
        case 'rejected':
          assignee.status = TaskAssignmentStatus.Rejected;
          break;
        case 'pending':
          assignee.status = TaskAssignmentStatus.Pending;
          break;
      }
    }
    return assignee;
  }

  toRequest(assignee: TaskAssignee) {
    return {
      data: {
        id: assignee.id,
        type: 'task_assignees',
        attributes: {
          email: assignee.email,
          first_name: assignee.firstName,
          last_name: assignee.lastName,
          approved: assignee.approved,
          status: assignee.status,
          task_id: assignee.taskId
        }
      }
    };
  }
}
