import {Action} from '@ngrx/store';
import {ProcessTreeNode} from './process-tree';
import {Update} from '@ngrx/entity';

export enum ProcessTreeActionTypes {
  LoadAll = '[ProcessTree] Load All',
  LoadAllSuccess = '[ProcessTree] Load All Success',
  LoadAllFail = '[ProcessTree] Load All Fail',
  Reset = '[ProcessTree] Reset'
}

export class Reset implements Action {
  readonly type = ProcessTreeActionTypes.Reset;

  constructor(public payload: any = null) {
  }
}

export class LoadAll implements Action {
  readonly type = ProcessTreeActionTypes.LoadAll;

  constructor(public activeProcessId: string = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ProcessTreeActionTypes.LoadAllSuccess;

  constructor(public nodes: ProcessTreeNode[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ProcessTreeActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export type ProcessTreeActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Reset;
