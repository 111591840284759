import {RequestType} from './request-type.enum';
import {ThirdPartyConfirmationSafekeeping, ThirdPartyConfirmationSafekeepingRow} from './safekeeping';

export namespace ThirdPartyConfirmationSafekeepingBuilder {
  export function fromResponse(response): ThirdPartyConfirmationSafekeeping {
    const keyToGroup: {[key: string]: any[]} = {};
    const groups: any[][] = [];

    for (const row of response.attributes.rows) {
      const key = [
        row.gegenstand_aufbewahrer_name,
        row.gegenstand_ansprechpartner_name,
        row.gegenstand_ansprechpartner_email,
        row.gegenstand_ansprechpartner_handy
      ].join('|');

      if (keyToGroup.hasOwnProperty(key)) {
        keyToGroup[key].push(row);
      } else {
        const newGroup = [row];
        keyToGroup[key] = newGroup;
        groups.push(newGroup);
      }
    }

    const thirdParty = new ThirdPartyConfirmationSafekeeping(
      response.id,
      response.type,
      response.attributes.parent_process_id,
      response.attributes.referenced_at,
      response.attributes.due_date,
      response.attributes.color,
      response.attributes.language || 'de',
      response.attributes.request_type || RequestType.Safekeeping,
      groups.map((group) => {
        const firstInGroup = group[0];

        return new ThirdPartyConfirmationSafekeepingRow(
          firstInGroup.id,
          firstInGroup.color || response.attributes.color,
          firstInGroup.lfd,
          firstInGroup.referenced_at || response.attributes.referenced_at,
          firstInGroup.due_date || response.attributes.due_date,
          firstInGroup.comment || '',
          firstInGroup.language || response.attributes.language || 'de',
          firstInGroup.request_type || response.attributes.request_type || RequestType.Safekeeping,
          firstInGroup.gegenstand_aufbewahrer_name,

          group.map((row) => {
            return {
              id: row.id,
              lfd: row.lfd,
              menge: row.menge,
              gegenstand_art: row.gegenstand_art,
              gegenstand_zustand: row.gegenstand_zustand,
              gegenstand_aufbewahrer_lagerungart: row.gegenstand_aufbewahrer_lagerungart
            };
          }),

          firstInGroup.gegenstand_ansprechpartner_name,
          firstInGroup.gegenstand_ansprechpartner_email,
          firstInGroup.gegenstand_ansprechpartner_handy,
        )
      })
    );
    thirdParty.emailAttachmentFilename = response.attributes.email_attachment_filename;
    thirdParty.state = response.attributes.state;

    return thirdParty;
  }

  export function toRequest(resource: ThirdPartyConfirmationSafekeeping) {
    const rows = [];

    for (const row of resource.rows) {
      rows.push(...row.safekeepingRows.map((safekeepingRow) => {
        return {
          id: safekeepingRow.id,
          color: row.color,
          lfd: safekeepingRow.lfd,
          referenced_at: row.referenced_at,
          due_date: row.due_date,
          comment: row.comment,
          language: row.language,
          request_type: row.request_type,
          quantity: safekeepingRow.menge,
          name: safekeepingRow.gegenstand_art,
          condition: safekeepingRow.gegenstand_zustand,
          storage: safekeepingRow.gegenstand_aufbewahrer_lagerungart,
          gegenstand_ansprechpartner_name: row.gegenstand_aufbewahrer_name,
          gegenstand_ansprechpartner_email: row.gegenstand_ansprechpartner_email,
          gegenstand_ansprechpartner_handy: row.gegenstand_ansprechpartner_handy
        }
      }))
    }

    return {
      id: resource.id,
      type: resource.type,
      attributes: {
        parent_process_id: resource.parent_process_id,
        referenced_at: resource.referenced_at,
        due_date: resource.due_date,
        language: resource.language,
        request_type: resource.request_type,
        rows
      }
    };
  }
}
