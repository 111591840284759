import {IDatevClient} from './datev-client.interface';

export class DatevClient implements IDatevClient {
  readonly type = 'datev_client';

  constructor(public id: string,
              public clientNumber: string,
              public consultantNumber: string,
              public name: string,
              public synced: boolean,
              public services = [],
              public documentTypes = [],
              public workerEmail,
              public workerName) {
  }
}
