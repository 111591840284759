import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {ProcessActionBuilder} from './process-action.builder';
import {ProcessAction} from './process-action';
import {HttpClient} from '@angular/common/http';
import {Process} from '../process/process';
import {ProcessBuilder} from '../process/process.builder';
import {tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ProcessActions} from '../process';
import {ProcessService} from "../process/process.service";
import {AngularTokenService} from "angular-token";

export interface ICloseParam {
  process_id: string;
  removeParticipants?: boolean;
}

export interface IReopenParam {
  process_id: string;
  include_children?: boolean;
}

export interface IChangeOwnerParam {
  process_id: string;
  email: string;
  include_children?: boolean;
}

@Injectable()
export class ProcessActionService {
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';

  constructor(private _http: ApiResourceService,
              private _httpClient: HttpClient,
              private _tokenSvc: AngularTokenService,
              private _processSvc: ProcessService,
              private _store: Store<AppState>,
              private _router: Router) {
  }

  getAll(id: string): Observable<ProcessAction[]> {
    const builder = new ProcessActionBuilder(id);
    return <Observable<ProcessAction[]>>this._http.get<ProcessActionBuilder, ProcessAction>(builder, `${this.BASE_PATH}/${id}/process_actions`);
  }

  close(params: ICloseParam) {
    const builder = new ProcessBuilder(this._router);
    const payload = {
      data: {
        attributes: {
          remove_participants: params.removeParticipants
        }
      }
    };
    return <Observable<Process>>this._http.post<ProcessBuilder, Process>(builder, `${this.BASE_PATH}/${params.process_id}/close`, payload)
      .pipe(
        tap((process: Process) => {
          this._store.dispatch(new ProcessActions.LoadOneSuccess(process));
          const uid = this._tokenSvc.currentAuthData.uid;
          this._processSvc.loadIamPolicy(uid, process.id);
        }));
  }
}
