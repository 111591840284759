<div class="stats-container" *ngIf="chartData && chartData.length && chartData.length > 0">
  <button *ngIf="showReset" (click)="resetFilter()" class="reset-filter-button"
          [matTooltip]=" 'GENERAL.SHOW_ALL' | translate"></button>
  <ngx-charts-advanced-pie-chart
    [view]="view"
    [scheme]="colorScheme"
    [results]="chartData"
    [gradient]="gradient"
    (select)="onSelect($event)">
  </ngx-charts-advanced-pie-chart>
</div>
