import {IResource} from 'app/shared/modules/api/models/resource.interface';

export const REQUEST_TYPE_MAP = {
  'balance-open': 'THIRD_PARTY.STATUS.BALANCE_REQUEST_OPEN',
  'balance-closed': 'THIRD_PARTY.STATUS.BALANCE_REQUEST_CLOSED',
  'balance-optional': 'THIRD_PARTY.STATUS.BALANCE_REQUEST_NEGATIVE'
}

export class ThirdPartyDetails implements IResource {
  constructor(public id: string,
              public type: string,
              public title: string,
              public requestType: Date,
              public businessYearStartsAt: Date,
              public businessYearEndsAt: Date,
              public referencedAt: Date,
              public dueDate: Date,
              public externalPreambleDe: string = null,
              public externalPreambleEn: string = null,
              public emailSenderName: string = null,
              public emailSubject: string = null,
              public emailBody: string = null,
              public returnAddress: string = null,
              public skipGlobalEMailSignature,
              public readonly: boolean = true) {
  }
}

export class ThirdPartyConfirmationLineItem {
  public searchKey: string;

  constructor(public id: number,
              public type: string | 'third_party_invoice' | 'third_party_balance',
              public uuid: string,
              public identifier: string,
              public deviation: number,
              public recipientOrganizationNo: string,
              public recipientOrganizationName: string,
              public recipientEmail: string,
              public recipientTitle: string,
              public recipientName: string,
              public reportedValue: number,
              public unitOfValue: string,
              public auditedValue: number,
              public confirmedValue: number,
              public valueConfirmed: boolean,
              public providedComment: string,
              public contributedComment: string,
              public thirdPartyFirstname: string,
              public thirdPartyLastname: string,
              public thirdPartyEmail: string,
              public thirdPartyFunction: string,
              public thirdPartyCompanyName: string,
              public thirdPartyPlace: string,
              public thirdPartyDate: Date,
              public state: string,
              public createdAt: Date,
              public updatedAt: Date,
              public account: string,
              public invoiceNo: string,
              public invoiceDate: Date,
              public requestType: string) {
  }

  public search(term) {
    if (!this.searchKey) {
      const searchKey = `${this.account}|${this.invoiceNo}|${this.reportedValue}|${this.identifier}|${this.recipientOrganizationNo}|${this.recipientOrganizationName}|${this.recipientEmail}|${this.recipientName}|${this.thirdPartyFirstname}|${this.thirdPartyLastname}|${this.thirdPartyFunction}|${this.thirdPartyCompanyName}`;
      this.searchKey = searchKey.toLowerCase();
    }

    const q = !!term && typeof term === 'string' ? term.toLowerCase() : '';

    if (q) {
      return this.searchKey.indexOf(q) >= 0;
    } else {
      return true;
    }
  }
  public isApproved(): boolean {
    return this.deviation == 0 && (this.auditedValue !== null || this.confirmedValue !== null);
  }

  public isNotClarified(): boolean {
    return this.auditedValue === null && this.confirmedValue === null;
  }
}

export class ThirdPartyConfirmationRequest {
  readonly type = 'third_party_request';

  public requestType: string;
  public lineItems: ThirdPartyConfirmationLineItem[];
  public recipientOrganizationNo: string;
  public recipientOrganizationName: string;
  public recipientEmail: string;
  public recipientTitle: string;
  public recipientName: string;
  public searchKey: string;

  constructor(public id: string,
              public title: string,
              public color: string,
              public state: string,
              public dueDate: Date,
              public receivedAt: Date,
              public updatedAt: Date) {
    this.lineItems = [];
  }

  public thirdPartyDifference() {
    return this.lineItems.reduce((acc, lineItem) => acc + Math.abs(lineItem.deviation), 0);
  }

  public search(term) {
    if (!this.searchKey) {
      const searchKey = `${this.title}|${this.recipientOrganizationNo}|${this.recipientOrganizationNo}|${this.recipientOrganizationName}|${this.recipientEmail}|${this.recipientName}`;
      this.searchKey = searchKey.toLowerCase();
    }

    const q = !!term && typeof term === 'string' ? term.toLowerCase() : '';

    if (q) {
      return this.searchKey.indexOf(q) >= 0;
    } else {
      return true;
    }
  }
}

/**
 * {
 * 	"data": {
 * 		"id": "63eccfba-4517-4286-abc1-37c33032b735",
 * 		"type": "workflow_engine_processes",
 * 		"attributes": {
 * 			"title": "Invoices with diff units",
 * 			"color": "#bb3354",
 * 			"identifier": null,
 * 			"created_at": "2022-05-17T20:24:57.205+02:00",
 * 			"client_name": "5FSoftware GmbH",
 * 			"client_id": null,
 * 			"client_uuid": "77a952cf-66d3-4d34-b9fa-046e2235a907",
 * 			"parent_title": "Vorbereitung für Bestätigung Dritter",
 * 			"parent_id": "f44704bc-c634-4abd-9e04-fa203f9f10c7",
 * 			"state": "sent",
 * 			"process_type": "third_party_request",
 * 			"third_party_type": "claim_invoice",
 * 			"item_type": "invoice",
 * 			"request_type": "open",
 * 			"referenced_at": "2022-05-01T00:00:00.000+02:00",
 * 			"business_year_starts_at": "2021-01-01T00:00:00.000+01:00",
 * 			"business_year_ends_at": "2021-12-31T23:59:59.999+01:00",
 * 			"requests": [
 * 				{
 * 					"uuid": "a94962c6-87ac-46b7-9e41-ce7aa67eb199",
 * 					"color": "#bb3354",
 * 					"state": "sent",
 * 					"due_date": "2022-05-31T00:00:00.000+02:00",
 * 					"received_at": null,
 * 					"line_items": [
 * 						{
 * 							"id": 2550,
 * 							"identifier": "28",
 * 							"deviation": 0.0,
 * 							"recipient_organization_no": "3467",
 * 							"recipient_organization_name": "Friedmann, Dittmar und Bauschke",
 * 							"recipient_email": "daren_mcdermott@abbottgottlieb.co",
 * 							"recipient_title": "Herr",
 * 							"recipient_name": "Mario Busemann",
 * 							"reported_value": "123454.0",
 * 							"unit_of_value": "USD",
 * 							"audited_value": null,
 * 							"confirmed_value": null,
 * 							"value_confirmed": null,
 * 							"provided_comment": "",
 * 							"contributed_comment": null,
 * 							"third_party_firstname": null,
 * 							"third_party_lastname": null,
 * 							"third_party_function": null,
 * 							"third_party_company_name": null,
 * 							"third_party_place": null,
 * 							"third_party_date": null,
 * 							"state": null,
 * 							"created_at": "2022-05-17T20:24:57.224+02:00",
 * 							"updated_at": "2022-05-17T20:25:55.447+02:00",
 * 							"account": "23555",
 * 							"invoice_no": "346543",
 * 							"invoice_date": "01.04.2017"
 * 						}
 * 					]
 * 				},
 * 			...
 * 		}
 * 	}
 */
export class ThirdPartyConfirmationV2 implements IResource {
  constructor(public id: string,
              public type: string,
              public title: string,
              public color: string,
              public identifier: null,
              public clientName: string,
              public clientId: string,
              public clientUuid: string,
              public parentTitle: string,
              public parentId: string,
              public state: string,
              public thirdPartyType: 'claim_invoice' | 'claim_balance',
              public itemType: 'invoice' | 'balance',
              public referencedAt: Date,
              public businessYearStartsAt: Date,
              public businessYearEndsAt: Date,
              public createdAt: Date,
              public requests: ThirdPartyConfirmationRequest[]) {
  }
}

/**
 * {
 * 			"id": "458122b4-9b8a-4786-a36a-5b2aa5527924",
 * 			"type": "third_party_audit_actions",
 * 			"attributes": {
 * 				"action_type": "simple_approval",
 * 			  "commit_message": "Text",
 * 				"audited_value": "2.34",
 * 				"previous_audited_value": null,
 * 				"reported_value": "12312.0",
 * 				"confirmed_value": "12312.0",
 * 				"previous_difference": "0.0",
 * 				"created_at": "2022-05-19T19:00:49.781+02:00",
 * 				"performer_email": "a.baier@5fsoftware.de",
 * 				"performer_first_name": "Andreas",
 * 				"performer_last_name": "Baier"
 * 			}
 * 		},
 */
export class ThirdPartyAuditAction implements IResource {
  readonly type = 'third_party_audit_actions';
  constructor(public id: string,
              public actionType: string,
              public commitMessage: string,
              public auditedValue: number,
              public previousAuditedValue: number = null,
              public reportedValue: number = null,
              public confirmedValue: number = null,
              public previousDeviation: number = null,
              public currentDeviation: number = null,
              public performerEmail: string = null,
              public performerFirstName: string = null,
              public performerLastName: string = null,
              public createdAt: Date = null) {
  }
}
