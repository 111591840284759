/**
 * Created by User on 18.09.2017.
 */

import * as titleActions from '../actions/title.actions';

export interface TitleState {
  title: string;
}

export const initialState: TitleState = {
  title: '5F | Communication'
};

export function titleReducer(state: TitleState = initialState, action: titleActions.Actions): TitleState {

  switch (action.type) {

    case titleActions.SET_TITLE:
          return { title: action.payload };
    default: {
      return state;
    }

  }

};
