import {Component, Input, OnInit} from '@angular/core';
import {FoxdoxService} from '../../services/foxdox.service';
import {FoxdoxAccountService} from 'app/+store/foxdox-account/foxdox-account.service';
import {FoxdoxStatus} from 'app/shared/modules/api/models/foxdoxStatus';
import {GetStatus} from 'app/actions/foxdox-status.actions';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {first} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'dvtx-foxdox-status',
  templateUrl: './foxdox-status.component.html',
  styleUrls: ['./foxdox-status.component.scss']
})
export class FoxdoxStatusComponent implements OnInit {

  color = 'primary';
  mode = 'determinate';
  _foxdoxStatusData: FoxdoxStatus;
  result: BehaviorSubject<any> = new BehaviorSubject(null);

  @Input() set foxdoxStatusData(value: FoxdoxStatus) {
    this._foxdoxStatusData = value;
  }

  get foxdoxStatusData() {
    return this._foxdoxStatusData;
  }

  constructor(private foxdoxStatusCredentials: FoxdoxService,
              private foxdoxAccountSvc: FoxdoxAccountService,
              private _store: Store<AppState>) {
  }

  submitFoxdoxCredentials(credentials) {
    // console.log('%c foxdoxStatusCredentials',  'color: #03a9f4' , credentials );
    return this.foxdoxAccountSvc.signInFoxdoxAccount(credentials)
      .pipe(first())
      .subscribe((result) => {
        console.log(result);
        // console.log('%c foxdoxStatusCredentials result',  'color: #03a9f4' , result);
        this._store.dispatch(new GetStatus());
        this.result.next({error: false, result: result});
      }, (error) => {
        console.error('Error authenticating against Foxdox.', error);
        this.result.next({error: true, result: error});
      });
  }

  ngOnInit() {
    console.log(this.foxdoxStatusData)
  }
}
