import {ChangeDetectionStrategy, Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'dvtx-loading-panel',
  templateUrl: './loading-panel.component.html',
  styleUrls: ['./loading-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingPanelComponent implements OnInit {
  @Input() isCoveringArea = false;
  @Input() loadingText = 'GENERAL.LOAD_ITEMS';

  constructor() { }

  ngOnInit() {
  }
}
