export enum FiveFMatButtonStyle {
  MenuItem = 'MenuItem',
  Button = 'Button',
  PrimaryButton = 'PrimaryButton',
  RaisedButton = 'RaisedButton',
  RaisedPrimaryButton = 'RaisedPrimaryButton',
  IconButton = 'IconButton',
  IconPrimaryButton = 'IconPrimaryButton',
  InlineButton = 'InlineButton',
}
