import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';

/**
 * Licence selection of checkout wizard. First step of wizard.
 */
@Component({
  selector: 'dvtx-licence-selection',
  templateUrl: './licence-selection.component.html',
  styleUrls: ['./licence-selection.component.scss']
})
export class LicenceSelectionComponent implements OnInit {
  @Input()
  form: UntypedFormGroup;

  totalCost = 0;
  totalAdditionalMembersCost = 0;
  licence_amount;
  selectedPlan;

  constructor(
    private _notService: NotificationService
  ) {
  }

  ngOnInit() {
    this.selectedPlan = {
      title: this.form.controls['selectedPlanAttributes'].value.title,
      userLicencesCount: this.form.controls['selectedPlanAttributes'].value.userLicencesCount,
      price: this.form.controls['paymentInterval'].value === 'Monthly' ? this.form.controls['selectedPlanAttributes'].value.price.monthly : this.form.controls['selectedPlanAttributes'].value.price.yearly * 12,
      additionalMemberCost: this.form.controls['paymentInterval'].value === 'Monthly' ? this.form.controls['selectedPlanAttributes'].value.additionalMemberCost.monthly : Number(this.form.controls['selectedPlanAttributes'].value.additionalMemberCost.yearly) * 12,
      paymentInterval: this.form.controls['paymentInterval'].value,
      validUntilDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).setDate(new Date().getDate() - 1)
    }

    if (this.form.controls['selectedPlanAttributes'].value.validUntil) {
      this.selectedPlan.validUntilDate = this.form.controls['selectedPlanAttributes'].value.validUntil;
    }

    const licence_amount_min = Number(this.form.controls['selectedPlanAttributes'].value.userLicencesCount) > 0 ? Number(this.form.controls['selectedPlanAttributes'].value.userLicencesCount) : 1;

    this.form.addControl('licence_amount', new UntypedFormControl(
              licence_amount_min,
              this.minValueValidator(licence_amount_min, 'Pro Einkauf beträgt die Mindestabnahme zero, die Maximalabnahme hundert Lizenzen.')
            ));

    this.CalcualteTotalCost();
  }

  minValueValidator(min: number, errormessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value,
        isNotNumber = isNaN(input),
        isValid = input < min;
      if ((isValid || isNotNumber)) {
        this._notService.error(errormessage);
        control.setValue(min);
      } else {
        return null;
      }
    };
  }

  ChangesInput(controllerName): any {
    this.CalcualteTotalCost();
  }

  CalcualteTotalCost() {
    this.totalAdditionalMembersCost = Number(this.form.controls['licence_amount'].value) * Number(this.selectedPlan.additionalMemberCost);
    this.totalCost = Number(this.selectedPlan.price) + Number(this.totalAdditionalMembersCost);
  }
}
