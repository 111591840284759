export interface ContactListDto {
  clients?: any[];
  id?: string;
  naturalProfileId?: string;
  membershipId?: string;
  // Key can be used for partial rendering by trackBy.
  // It is set to "ID|timestamp" by effect actions.
  // This is a workaround because the CPP service does not export
  // a value like updated at (as in core API).
  key?: string;
  name: string;
  telephone?: string;

  email: string;

  /**
   * Normalized precalculated email for fast
   * matchings with contacts. Expected to be filled up by
   * StringUtils.normalizeString(incomingEmail)
   */
  normalizedEmail?: string;

  type?: contactListDtoType;
  belongsToOrganizationId?: string;
  hasAccount?: boolean;
  assignedPeople?: number;
  contactVisibility?: string;
  legalForm?: string;
  firstName?: string;
  lastName?: string;
  createdBy?: string;
  isVerified?: boolean;
  isMember?: boolean;
  isRegisteredUser?: boolean;
  partnerLinkId?: string;
  processCount?: number;
}

export interface PersonContactListDto extends ContactListDto {
  firstName: string;
  lastName: string;
}

export interface ConstactListDtoResponseElement {
  type: string,
  id: string,
  attributes: {
    name: string,
    telephone: string,
    email: string,
    type: string,
    has_account: boolean,
    assigned_people: number,
    related_orgas: {name: string, legal_form_id: string}[]
    contact_visibility: string,
    legal_form_id: string,
    created_by: string
  }
}

export interface ImportContactRow {
  title: string,
  first_name: string,
  last_name: string,
  street_business: string,
  city_business: string,
  zip_business: string,
  country_business: string,
  street_private: string,
  city_private: string,
  zip_private: string,
  country_private: string,
  fax_business: string,
  phone_business: string,
  phone_private: string,
  phone_mobile: string,
  phone_main: string,
  email: string
}

export interface ContactListDtoReponse {
  data: ConstactListDtoResponseElement[]
}

export interface ContactVisibility {
  type: visibilityTypes,
  visibleFor: string
}

export enum visibilityTypes {
  visiblePrivate = 'visiblePrivate',
  visiblePublic = 'visiblePublic',
}

export enum contactListDtoType {
  organizationContact = 'organizationContact',
  naturalPersonContact = 'naturalPersonContact',
  Membership = 'Membership',
}
