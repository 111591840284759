import {first} from 'rxjs/operators';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';

import {InvoiceAddress} from 'app/shared/modules/api/models/invoice-address';
import { MatSelect } from '@angular/material/select';
import {InvoiceAddressService} from 'app/+store/licence/invoice-address.service';

/**
 * Invoice address selection.
 */
@Component({
  selector: 'dvtx-invoice-address',
  templateUrl: './invoice-address.component.html',
  styleUrls: ['./invoice-address.component.scss']
})
export class InvoiceAddressComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  invoiceAdresses$: Observable<InvoiceAddress[]>;

  @ViewChild('selectInvoiceAddress', { static: true }) selectInvoiceAddress: MatSelect;

  constructor( private _invoiceService: InvoiceAddressService ) {
  }

  ngOnInit() {
    this.form.addControl('id', new UntypedFormControl(null));
    this.form.addControl('firstName', new UntypedFormControl('', Validators.required));
    this.form.addControl('lastName', new UntypedFormControl('', Validators.required));
    this.form.addControl('organizationName', new UntypedFormControl(''));
    this.form.addControl('street', new UntypedFormControl('', Validators.required));
    this.form.addControl('streetNo', new UntypedFormControl(''));
    this.form.addControl('zip', new UntypedFormControl('', Validators.required));
    this.form.addControl('city', new UntypedFormControl('', Validators.required));
    this.form.addControl('country', new UntypedFormControl('', Validators.required));
    this.form.addControl('ustId', new UntypedFormControl(''));

    this.invoiceAdresses$ = this._invoiceService.getAll().pipe(first());
  }

  fillForm(address) {
    this.form.patchValue(address.value);
  }

  resetForm() {
    this.selectInvoiceAddress.value = null;
    this.form.reset();
    this.form.enable();
    this.form.updateValueAndValidity();
  }
}
