import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {BookmanServiceAccount} from './bookman-service-account';
import {
  BookmanServiceAccountActionTypes,
  GetBookmanServiceAccount,
  GetBookmanServiceAccountFail,
  GetBookmanServiceAccountSuccess,
  SignInToBookmanServiceAccount,
  SignInToBookmanServiceAccountFail,
  SignInToBookmanServiceAccountSuccess,
  SignOutFromBookmanServiceAccount,
  SignOutFromBookmanServiceAccountFail,
  SignOutFromBookmanServiceAccountSuccess
} from './bookman-service-account.actions';
import {BookmanServiceAccountService} from './bookman-service-account.service';

@Injectable()
export class BookmanServiceAccountEffects {
  @Effect()
  SignInToBookmanServiceAccount$ = this.actions.pipe(
    ofType(BookmanServiceAccountActionTypes.SignInToBookmanServiceAccount),
    switchMap((action: SignInToBookmanServiceAccount) => {
      return this._svc.signInBookmanServiceAccount(action.payload).pipe(
        first(),
        concatMap((bookmanServiceAccount: BookmanServiceAccount) => {
          return [new SignInToBookmanServiceAccountSuccess(bookmanServiceAccount)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SignInToBookmanServiceAccountFail(err));
        }));
    })
  );

  @Effect()
  SignOutFromBookmanServiceAccount$ = this.actions.pipe(
    ofType(BookmanServiceAccountActionTypes.SignOutFromBookmanServiceAccount),
    switchMap((action: SignOutFromBookmanServiceAccount) => {
      return this._svc.signOutBookmanServiceAccount().pipe(
        first(),
        concatMap((bookmanServiceAccount: BookmanServiceAccount) => {
          return [new SignOutFromBookmanServiceAccountSuccess(bookmanServiceAccount)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SignOutFromBookmanServiceAccountFail(err));
        }));
    })
  );

  @Effect()
  GetBookmanServiceAccount$ = this.actions.pipe(
    ofType(BookmanServiceAccountActionTypes.GetBookmanServiceAccount),
    switchMap((action: GetBookmanServiceAccount) => {
      return this._svc.getBookmanServiceAccount().pipe(
        first(),
        concatMap((bookmanServiceAccount: BookmanServiceAccount) => {
          return [new GetBookmanServiceAccountSuccess(bookmanServiceAccount)];
        }),
        catchError(err => {
          console.error(err);
          return of(new GetBookmanServiceAccountFail(err));
        }));
    })
  );

  constructor(
    private actions: Actions,
    private _svc: BookmanServiceAccountService
  ) {
  }
}
