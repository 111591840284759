import { User } from '../models/user';
import * as currentUserActions from '../actions/current-user-actions';
import * as userActions from '../actions/user-actions';

export type CurrentUserState = User;

// initial user state
export const initialState: CurrentUserState = null;

export function currentUserReducer(state: CurrentUserState = initialState, action: currentUserActions.Actions): CurrentUserState {

  switch (action.type) {
    case currentUserActions.GET_CURRENT_USER_SUCCESS:
      return action.payload;

    case currentUserActions.SET_CURRENT_USER:
      return action.payload;

    case currentUserActions.RESET_CURRENT_USER:
    case userActions.USER_LOGOUT_ERROR:
    case userActions.USER_LOGOUT_SUCCESS:
      return initialState;

    case currentUserActions.USER_HINT_TRIAL:
      state.trialEndWasHinted = true;
      return state;

    default: {
      return state;
    }
  }
};

