import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {IProcessTreeSettings, ProcessTreeNode} from './process-tree';
import {ProcessTreeBuilder, ProcessTreeSettingsBuilder} from './process-tree.builder';
import {first, takeUntil, tap} from 'rxjs/operators';
import {ProcessTreeActions, ProcessTreeSelectors} from './index';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Net} from 'app/lib/net/uuid';
import {Subject} from 'rxjs/internal/Subject';

@Injectable()
export class ProcessTreeService implements OnDestroy {
  readonly BASE_PATH = 'api/v1/workflow_engine';

  /**
   * Current settings. Fetched once at service singleton instantiation. Maintained during livecycle with updates.
   */
  public currentSettings$: BehaviorSubject<IProcessTreeSettings> = new BehaviorSubject<IProcessTreeSettings>(null);
  private userUid = null;

  private processesNodes$ = new BehaviorSubject<ProcessTreeNode[]>([]);
  /**
   * Current active process nodes.
   */
  public processesNodes = this.processesNodes$.asObservable();

  private onDetach: Subject<void>;
  private processId = null;

  constructor(private _http: ApiResourceService,
              private _store: Store<AppState>,
              private _ngZone: NgZone) {
    this.refresh();
  }

  ngOnDestroy() {
    this.processesNodes$.complete();
  }

  /**
   * Needs to be invoked if the service is started by view initialization.
   * Currently this is only done inside the ProcessStructureComponent aka
   * Project Structure tree at the sidebar.
   *
   * ATTENTION: Run detach on destroy.
   * ATTENTION: !!This method is not thread safe. Run it only once in the view!!
   * @param id
   */
  public init(id) {
    // Reset the nodes on init to not show a former cached project structure
    // during project changes.
    if (this.processId !== id) {
      this.processesNodes$.next([]);
    }

    this.onDetach = new Subject();
    // TODO: Revise next code if still valid or better alternative.
    //       Adapt ProcessStructureComponent if better alternative is found.
    // Update the local node context only if the 'active node' context is correct according
    // the current ID.
    // (Prevents a race condition with concurrent tree node calls and incorrect shown active node).
    const nodes$ = this._store.select(ProcessTreeSelectors.getAllProcesssTreeNodes);
    nodes$
      .pipe(takeUntil(this.onDetach))
      .subscribe((nodes) => {
        if (Net.validUUID(id)) {
          const found = nodes.find(node => node.active);
          if (found && found.id === id) {
            this.processesNodes$.next(nodes);
          }
        }
      });
  }

  public detach() {
    this.onDetach.next();
    this.onDetach.complete();
  }

  public refreshProjectStructure(id) {
    if (!id) {
      this.processesNodes$.next([]);
    }

    // Global state is a problem, here. It can be different from current loading
    // context (e.g. laggy background loading with call responses in wrong order)
    // so we dispatch by service, here, and keep a local copy bound to the
    // current id of the component. In this way the call count was also reduced.
    // OLD: this._store.dispatch(new ProcessTreeActions.LoadAll(id));
    this._ngZone.runOutsideAngular(_ => {
      this.getAll(id)
        .pipe(first())
        .subscribe(nodes => {
          this._store.dispatch(new ProcessTreeActions.LoadAllSuccess(nodes));

          this._ngZone.run(_ => {
            this.processesNodes$.next(nodes);
          });
        }, err => {
          console.error(err);
        });
    });
  }

  /**
   * Returns a minimal tree focused process listing.
   * Depends on current Project structure settings.
   * See below.
   * @param activeProcessId
   */
  getAll(activeProcessId: string = null): Observable<ProcessTreeNode[]> {
    const builder = new ProcessTreeBuilder();
    const path = activeProcessId ? `${this.BASE_PATH}/processes/${activeProcessId}/process_trees` : `${this.BASE_PATH}/process_trees`;
    return <Observable<ProcessTreeNode[]>>this._http.get<ProcessTreeBuilder, ProcessTreeNode>(builder, path);
  }

  public refresh(userUid = null) {
    // If refresh is based on user account UID and this.userUid and userUid is
    // the same then settings fetching can be skipped.
    if (userUid || !this.currentSettings$.value) {
      if (userUid === this.userUid) {
        return;
      }
      this.userUid = userUid;
    }

    this.getSettings()
      .pipe(first())
      .subscribe(settings => {
        this.currentSettings$.next(settings);
      }, err => {
        console.error(err);
      });
  }

  private getSettings(): Observable<IProcessTreeSettings> {
    const builder = new ProcessTreeSettingsBuilder();
    const path = `${this.BASE_PATH}/process_trees/settings`;
    return <Observable<IProcessTreeSettings>>this._http.get<ProcessTreeSettingsBuilder, IProcessTreeSettings>(builder, path);
  }

  /**
   * Updates the Project Structure view settings.
   * Depending on these settings the API is sending presorted and prefiltered results.
   * @param settings
   */
  updateSettings(settings: IProcessTreeSettings): Observable<IProcessTreeSettings> {
    const builder = new ProcessTreeSettingsBuilder();
    const payload = builder.toRequest(settings)
    const path = `${this.BASE_PATH}/process_trees/settings`;
    return <Observable<IProcessTreeSettings>>this._http.post<ProcessTreeSettingsBuilder, IProcessTreeSettings>(builder, path, payload)
      .pipe(tap(settings => {
        this.currentSettings$.next(settings);
      }));
  }
}
