<div class="p-3">

  <div *ngIf="!user" class="card-content align-center">
    <p>{{ 'REGISTRATION.VERIFICATION_LINK_CHECK' | translate }}</p>
    <mat-spinner [diameter]="40" [strokeWidth]="4" aria-label="loading..."></mat-spinner>
  </div>

  <div class="mb-2" *ngIf="form">
    <div class="row mx-0 mt-1">
      <div class="col-12">
        <h2 class="">{{ 'REGISTRATION.SET_YOUR_PASSWORD' | translate }}</h2>
      </div>
    </div>

    <div class="row mx-0 mt-1">
      <div class="col-12">
        <p class="label">Email</p>
        <p>{{user.email}}</p>
      </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="submit()" novalidate>

      <div class="row mx-0 mt-1">
        <div class="col-12 col-sm-6">
          <mat-form-field>
            <input matInput required placeholder="{{ 'CONTACTS.FIRST_NAME' | translate }}" [formControlName]="'first_name'" autocomplete="new-first-name" (focus)="hidePassword(); hidePasswordSecond();">
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6">
          <mat-form-field>
            <input matInput required placeholder="{{ 'CONTACTS.LAST_NAME' | translate }}" [formControlName]="'last_name'" autocomplete="new-last-name" (focus)="hidePassword(); hidePasswordSecond();">
          </mat-form-field>
        </div>
      </div>

      <div class="row mx-0 mt-1 align-items-center">
        <div class="col-10 col-lg-11 password--field">
          <mat-form-field class="password-input">
            <input matInput [type]="textType" required [placeholder]="'AUTH.PASSWORD' | translate"
                   formControlName="password" autocomplete="new-password"
                   #password (keyup)="verifyPassword(password.value)" (focusout)="hidePassword()" (focus)="hidePasswordSecond()">
            <mat-icon *ngIf="textType === fieldType.Password" (click)="showPassword()" [matTooltip]="'PASSWORD.VISIBLE' | translate"  class="vis-icon five-f-icon-color-dark">visibility</mat-icon>
            <mat-icon *ngIf="textType === fieldType.Text" (click)="hidePassword()" [matTooltip]="'PASSWORD.VISIBILITY_OFF' | translate"  class="vis-icon five-f-icon-color-dark">visibility_off</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-2 col-lg-1 d-flex justify-content-end">
          <mat-icon matTooltip="{{ 'PASSWORD.PASSWORD_POLICY' | translate }}" class="info five-f-icon-color-dark">info</mat-icon>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-12 col-sm-6">
          <span *ngIf="verifyPasswordResponse && verifyPasswordResponse.level"
                [class]="verifyPasswordResponse.level">
              {{ verifyPasswordResponse.message | translate }}
          </span>
        </div>
        <div class="col-12 col-sm-6">
          <span *ngIf="passwordform.errors?.StrongPassword" class="label">mind. {{PASSWORD_MIN_LENGTH}} Zeichen</span>
          <span *ngIf="passwordform.errors?.WeakPassword" class="label">mind. {{PASSWORD_MIN_LENGTH}} Zeichen</span>
          <span *ngIf="passwordform.errors?.minlength" class="label">mind. {{PASSWORD_MIN_LENGTH}} Zeichen</span>
        </div>
      </div>

      <div class="row mx-0 mt-1 align-items-center">
        <div class="col-10 col-lg-11 password--field password--field--confirmation">
          <mat-form-field class="password-input">
            <input matInput [type]="textTypeFirst" required placeholder="{{ 'PASSWORD.CONFIRM_PASSWORD' | translate }}"
                    formControlName="passwordConfirm" autocomplete="new-password-confirmation" (focusout)="hidePasswordSecond()" (focus)="hidePassword()">
            <mat-icon *ngIf="textTypeFirst === fieldType.Password" (click)="ShowPasswordSecond()" [matTooltip]="'PASSWORD.VISIBLE' | translate" class="vis-icon five-f-icon-color-dark">visibility</mat-icon>
            <mat-icon *ngIf="textTypeFirst === fieldType.Text" (click)="hidePasswordSecond()" [matTooltip]="'PASSWORD.VISIBILITY_OFF' | translate"  class="vis-icon five-f-icon-color-dark">visibility_off</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-2 col-lg-1 d-flex justify-content-end">
          <mat-icon *ngIf="!form.controls.passwordConfirm.errors?.MatchPassword" class="check">check</mat-icon>
        </div>
      </div>

      <div class="row mx-0 mt-2">
        <div class="col-2 col-lg-1 terms--acceptance--field terms">
          <mat-checkbox (change)="readTerms($event)" class="terms-checkbox" color="primary" formControlName="terms" required></mat-checkbox>
        </div>
        <div class="col-10 col-lg-11 terms--acceptance--field terms"
             [innerHTML]="'REGISTRATION.TERMS_ACCEPT' | translate">
        </div>
      </div>


      <div class="row mx-0 mt-2">
        <div class="col-12 d-flex justify-content-end">
          <button mat-raised-button color="primary" [disabled]="form.invalid || passwordform.errors?.minlength || passwordform.errors?.WeakPassword || passwordform.errors?.MatchPassword" type="submit">
<!--          <button mat-raised-button color="primary" type="submit">-->
            {{ 'GENERAL.NEXT_ACTION' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
