import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import * as errorActions from '../../../../actions/api-error.actions';

@Injectable()
export class ApiErrorService {
  constructor(private _store: Store<AppState>) {}

  addError(error) {
    this._store.dispatch(new errorActions.AddApiError(error));
  }
}
