import {adapter, initialState, State} from './tasks-stats.state';
import {TasksStatsActions, TasksStatsActionTypes} from './tasks-stats.actions';

export function reducer(state = initialState, action: TasksStatsActions): State {
  switch (action.type) {
    case TasksStatsActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case TasksStatsActionTypes.LoadAllSuccess:
      return adapter.setAll([action.tasksStats], {
        ...state,
        loading: false,
      });

    case TasksStatsActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };

  }
  return state;
}
