import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {ProjectOverviewComponent} from './containers/project-overview/project-overview.component';
import {InViewportModule} from 'ng-in-viewport';
import {ProcessTableModule} from '../workflow-engine/modules/process-table/process-table.module';
import {FiltersContainerModule} from '../../five-f/filters/filters-container.module';
import {TooltipModule} from '../../five-f/tooltip/tooltip.module';
import {OrganizationCardModule} from '../../five-f/organization-card/organization-card.module';
import {StatusSelectorModule} from 'app/shared/modules/status-selector/status-selector.module';
import {ProjectOverviewStatsComponent} from './components/project-overview-stats/project-overview-stats.component';
import {PieChartModule} from '@swimlane/ngx-charts';
import {ProcessToolbarModule} from '../process-toolbar/process-toolbar.module';
import {ProcessListingModule} from '../../five-f/process-listing/process-listing.module';
import {InfoBlockModule} from 'app/five-f/info-block/info-block.module';

@NgModule({
  imports: [
    SharedModule,
    InViewportModule,
    ProcessTableModule,
    FiltersContainerModule,
    TooltipModule,
    OrganizationCardModule,
    StatusSelectorModule,
    PieChartModule,
    ProcessToolbarModule,
    ProcessListingModule,
    InfoBlockModule
  ],
  declarations: [
    ProjectOverviewComponent,
    ProjectOverviewStatsComponent
  ],
  exports: [
    ProjectOverviewComponent
  ]
})
export class ProjectOverviewModule {
}
