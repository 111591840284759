import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import { AlertComponent } from './components/alert/alert.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    AlertComponent
  ],
  exports: [
    AlertComponent
  ]
})
export class AlertsModule {
}
