import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PartnerLinkEffects} from './partner-link.effects';
import {PartnerLinkService} from './partner-link.service';
import {reducer} from './partner-link.reducer';
import {stateKey} from './partner-link.selectors';
import {PartnerLinkMgmtService} from './partner-link-mgmt.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([PartnerLinkEffects])
  ],
  providers: [
    PartnerLinkEffects,
    PartnerLinkService,
    PartnerLinkMgmtService
  ]
})
export class PartnerLinkStoreModule {
}
