import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {stateKey} from './excel-export.selectors';
import {reducer} from './excel-export.reducer';
import {ExcelExportEffects} from './excel-export.effects';
import {ExcelExportService} from './excel-export.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ExcelExportEffects])
  ],
  providers: [
    ExcelExportEffects,
    ExcelExportService
  ]
})
export class ExcelExportStoreModule {
}
