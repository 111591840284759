import {Action} from '@ngrx/store';
import {CmsBlog} from './cms-blog';

export enum CmsBlogActionTypes {
  Create = '[CmsBlog] Create',
  CreateSuccess = '[CmsBlog] Create Success',
  CreateFail = '[CmsBlog] Create Fail',
  Remove = '[CmsBlog] Remove',
  RemoveSuccess = '[CmsBlog] Remove Success',
  RemoveFail = '[CmsBlog] Remove Fail',
  Save = '[CmsBlog] Save',
  SaveSuccess = '[CmsBlog] Save Success',
  SaveFail = '[CmsBlog] Save Fail',
  Status = '[CmsBlog] Status',
  LoadOne = '[CmsBlog] Load All',
  LoadOneSuccess = '[CmsBlog] Load All Success',
  LoadOneFail = '[CmsBlog] Load All Fail'
}

export class Create implements Action {
  readonly type = CmsBlogActionTypes.Create;

  constructor(public cmsBlog: CmsBlog) {
  }
}

export class CreateSuccess implements Action {
  readonly type = CmsBlogActionTypes.CreateSuccess;

  constructor(public cmsBlog: CmsBlog) {
  }
}

export class CreateFail implements Action {
  readonly type = CmsBlogActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = CmsBlogActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = CmsBlogActionTypes.RemoveSuccess;

  constructor(public cmsBlog: CmsBlog) {
  }
}

export class RemoveFail implements Action {
  readonly type = CmsBlogActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = CmsBlogActionTypes.Save;

  constructor(public cmsBlog: CmsBlog) {
  }
}

export class SaveSuccess implements Action {
  readonly type = CmsBlogActionTypes.SaveSuccess;

  constructor(public cmsBlog: CmsBlog) {
  }
}

export class SaveFail implements Action {
  readonly type = CmsBlogActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = CmsBlogActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = CmsBlogActionTypes.LoadOneSuccess;

  constructor(public cmsBlog: CmsBlog) {
  }
}

export class LoadOneFail implements Action {
  readonly type = CmsBlogActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type CmsBlogActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
