<div [formGroup]="createPersonFrom">

  <div class="icon-content-layout">
    <mat-icon>account_box</mat-icon>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-5">
        <dvtx-api-select-cva
          formControlName="title"
          class="type-or-location"
          listingPath="name"
          listingType="gender"
          [elementName]="'CONTACTS.GENDER' | translate"
        ></dvtx-api-select-cva>
      </div>
      <div class="col-12 col-sm-6 col-md-5">
        <dvtx-text-input-cva formControlName="honorific" class="comp-name"
                             [elementName]="'CONTACTS.TITLE' | translate"></dvtx-text-input-cva>
      </div>
    </div>
  </div>

  <div class="icon-content-layout">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-5">
        <dvtx-text-input-cva formControlName="firstName" class="comp-name" [requiredField]="true"
                             [elementName]="'CONTACTS.FIRST_NAME' | translate"></dvtx-text-input-cva>
      </div>
      <div class="col-12 col-sm-6 col-md-5">
        <dvtx-text-input-cva formControlName="lastName" class="comp-name" [requiredField]="true"
                             [elementName]="'CONTACTS.LAST_NAME' | translate"></dvtx-text-input-cva>
      </div>
    </div>
  </div>

  <div class="icon-content-layout">
    <mat-icon>email</mat-icon>
    <div class="icon-content-layout--content">
      <div class="row">
        <div class="col-12 col-md-10">
          <dvtx-email-address-input
            formControlName="mainEmailAddress"
            [requiredField]="true"
            [elementName]="'CONTACTS.EMAIL' | translate"
            [errorMapper]="[{error:'InvalidEmailAddress', message: 'CONTACTS.ENTER_VALID_EMAIL' | translate }]"
          ></dvtx-email-address-input>
        </div>
        <div class="col-12">
          <dvtx-email-address-group-cva
            *ngIf="displayFurtherDataFields === true && contactPerson"
            [emailAddresses]="$any(contactPerson).emailAddresses"
            (addEmail)="addFurtherEmail($event)"
            (removeEmail)="removeFurtherEmail($event)"
            [disabled]="isDisabled"></dvtx-email-address-group-cva>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 my-1 d-flex align-items-center" *ngIf="newContactMode && (administrationRights | async).hasAdministrationRights && (featureSet$ | async).hasPartnerLinks">
    <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200">link</mat-icon>
    <div class="w-100 ml-3">
      <mat-checkbox
        [labelPosition]="'before'"
        formControlName="activatePartnerLinks">
        {{ 'PARTNER_LINK.ACTIVATE_PARTNERLINKS' | translate }}
      </mat-checkbox>
    </div>
  </div>

  <div class="icon-content-layout">
    <mat-icon>phone</mat-icon>
    <div class="icon-content-layout--content">
      <div class="row">
        <div class="col-12 col-md-10">
          <dvtx-telephone-input [formControlName]="'mainPhoneNumber'" [isType]="true"></dvtx-telephone-input>
        </div>
        <div class="col-12">
          <dvtx-telephone-group-cva *ngIf="displayFurtherDataFields && contactPerson"
                                    [telephonenumbers]="$any(contactPerson).phoneNumbers"
                                    (addPhone)="addFurtherPhoneNumber($event)"
                                    (removePhone)="removeFurtherPhoneNumber($event)"
                                    [disabled]="isDisabled">
          </dvtx-telephone-group-cva>
        </div>
      </div>
    </div>
  </div>

  <div class="icon-content-layout">
    <mat-icon>place</mat-icon>
    <div class="icon-content-layout--content">
      <div class="row">
        <div class="col-12 col-md-10">
          <dvtx-address-input-cva formControlName="mainAddress" [elementName]="'CONTACTS.ADDRESS' | translate"
                                  [isDisabled]="isDisabled"></dvtx-address-input-cva>
        </div>
        <div class="col-12">
          <dvtx-address-input-group-cva
            *ngIf="displayFurtherDataFields && contactPerson"
            [enableLocationOrType]="true"
            [addresses]="$any(contactPerson).addresses"
            (addAddress)="addFurtherAddress($event)"
            (removeAddress)="removeFurtherAddress($event)"
            [disabled]="isDisabled"
          ></dvtx-address-input-group-cva>
        </div>
      </div>
    </div>
  </div>
</div>

