
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ResourceBuilder} from 'app/shared/modules/api/builders/resource.builder';
import {ApiErrorBuilder} from 'app/shared/modules/api/builders/api-error.builder';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {IAuditOrder} from 'app/shared/modules/api/models/audit-order.interface';


@Injectable()
export class AuditOrderService {
  readonly BASE_PATH = 'api/v1/audit/auditings';
  basePath;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  getAll() {
    return this._http.get(`${this.basePath}`).pipe(
      map((res: any) => ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  get(id) {
    return this._http.get(`${this.basePath}/${id}`).pipe(
      map((res: any) => ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  create(order) {
    return this._http.post(`${this.basePath}`, ResourceBuilder.toRequest(order)).pipe(
      map((res: any) => {
        return <IAuditOrder>ResourceBuilder.fromResponse(res);
      }),
      catchError(this.handleError),);
  }

  private handleError(error) {
    if (error.json) {
      const errors = ApiErrorBuilder.fromResponse(error);
      return observableThrowError(errors);
    }
    return observableThrowError(error);
  }
}
