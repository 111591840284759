import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { MatDialog } from '@angular/material/dialog';
import { Invitation } from 'app/+store/invitation/invitation.model';

import {
  OrganizationSelectors
} from 'app/+store';

import { CreateInvitation } from 'app/+store/invitation/invitation.actions';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';
import { ConfirmationDialogSimpleComponent } from 'app/shared/components/dialogs/confirmation-dialog-simple/confirmation-dialog-simple.component';
import { contactListDtoType } from '../../../../../models/contact-list-dto.model';

import { LicenceControlService } from 'app/shared/services/licence-control.service';
import { EmailValidator } from 'app/lib/validator';

@Component({
  selector: 'dvtx-membership-invitation',
  templateUrl: './membership-invitation.component.html',
  styleUrls: ['./membership-invitation.component.scss']
})
export class MembershipInvitationComponent implements OnDestroy {
  onDestroy: Subject<void> = new Subject<void>();
  invitations: Invitation[];
  pendingInviteesEmails = [];
  accpentedInviteesEmails = [];
  employeesEmails = [];
  inviteeEmail: string = '';
  contactListDtoType = contactListDtoType;
  invs;

  public currentUser = {
    hasRequiredLicence: false,
    hasAdministrationRights: false,
    isOrganizationalOwner: false,
    currentLicence: null,
    attributes: {},
    licencedOrganizations: []
  }
  // in-viewport-rendering setup
  isInViewPort: string[] = [];

  constructor(private store: Store<AppState>,
    private _translateService: TranslateService,
    private _notifyService: NotificationService,
    private _dialog: MatDialog,
    private licenceControl: LicenceControlService
  ) {
    this.currentUser = this.licenceControl._currentUser;
  }

  openMemberInvitationDialog() {
    const inviteesEmail = this.inviteeEmail.trim().toLowerCase();
    if (this.employeesEmails.includes(inviteesEmail)) {
      this._dialog.open(ConfirmationDialogSimpleComponent, {
        data: {
          title: 'INVITATIONS.USER_IS_MEMBER_TITLE',
          message: 'INVITATIONS.USER_IS_MEMBER_CONTENT',
          submitButtonTitle: 'GENERAL.OK_ACTION',
          cancelButtonTitle: 'GENERAL.CLOSE_ACTION',
          onSubmitAction: () => {
          },
        }
      });
      return;
    } else if (this.pendingInviteesEmails.includes(inviteesEmail)) {
      this._dialog.open(ConfirmationDialogSimpleComponent, {
        data: {
          title: 'INVITATIONS.RESEND_DIALOG_TITLE',
          message: 'INVITATIONS.RESEND_DIALOG_CONTENT',
          submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
          cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
          onSubmitAction: () => {
            this.store.select(OrganizationSelectors.getSelectedId).pipe(
              first()
            ).subscribe((orgaId: string) => {
              this.store.dispatch(new CreateInvitation({ orgaId: orgaId, inviteeEmail: inviteesEmail }));
              this._translateService.get('INVITATIONS.INVITATION_SENT').pipe(first()).subscribe(
                (message: string) => this._notifyService.info(message)
              );
            });
          },
        }
      });
      return;
    }
    this._dialog.open(ConfirmationDialogSimpleComponent, {
      data: {
        title: 'INVITATIONS.INVITE_MEMBER_DIALOG_TITLE',
        message: 'INVITATIONS.INVITE_MEMBER_DIALOG_CONTENT',
        submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        onSubmitAction: () => {
          this.sendMemberInvitation();
        },
      }
    });
  }

  sendMemberInvitation() {
    this.store.select(OrganizationSelectors.getSelectedId).pipe(
      first()
    ).subscribe((orgaId: string) => {
      if (this.inviteeEmail !== '') {
        this.store.dispatch(new CreateInvitation({
          orgaId: orgaId,
          inviteeEmail: this.inviteeEmail.trim().toLowerCase()
        }));
        this.inviteeEmail = '';
        this._dialog.closeAll();
      }
    });
  }
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
  public isInviteeEmailValid() {
    if (!this.inviteeEmail || !this.inviteeEmail.length)
      return false;
    return EmailValidator.emailValid(this.inviteeEmail);
  }
}
