import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Process} from 'app/+store/process/process';

@Component({
  selector: 'dvtx-process-icon',
  templateUrl: './process-icon.component.html',
  styleUrls: ['./process-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessIconComponent implements OnInit {

  @Input()
  public size: 'xs' | 'sm' | 'md' = 'md';

  _iconConfig: { icon: string, isSvgIcon: boolean };
  @Input() set process(p) {
    this._iconConfig = p;
  }

  @Input() set workflowType(workflowType) {
    this._iconConfig = Process.iconForType(workflowType)
  }

  constructor() { }

  ngOnInit() {
  }

}
