import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CollectorTemplateEffects} from './collector-template.effects';
import {CollectorTemplateService} from './collector-template.service';
import {reducer} from './collector-template.reducer';
import {stateKey} from './collector-template.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([CollectorTemplateEffects])
  ],
  providers: [
    CollectorTemplateEffects,
    CollectorTemplateService
  ]
})
export class CollectorTemplateStoreModule {
}
