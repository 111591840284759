import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {OrganizationLogo} from 'app/+store/organization-logo/organization-logo';

export interface State extends EntityState<OrganizationLogo> {
  current?: OrganizationLogo
  loading: boolean
}

export const adapter = createEntityAdapter<OrganizationLogo>();

export const initialState: State = adapter.getInitialState({
  current: null,
  loading: false,
});
