import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import { DocumentSignatureSelectionMenuComponent } from './containers/document-signature-selection-menu/document-signature-selection-menu.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    DocumentSignatureSelectionMenuComponent
  ],
  exports: [
    DocumentSignatureSelectionMenuComponent
  ]
})
export class DocumentSignaturTypeSelectionModule {
}
