<div class="mat-dialog-inner-wrap" [style.borderColor]="color" [class.mat-dialog-dynamic-width]="dynamicWidth">

  <h3 *ngIf="title" class="mat-dialog-header">
    {{ title | translate }}
  </h3>

  <div mat-dialog-content [class.p-0]="noPadding">
    <h4 class="mb-1 dvtx-loading-indicator">{{ headline | translate }}<span>.</span><span>.</span><span>.</span></h4>

    <div class="w-100 d-flex">
      <div class="w-100 d-flex align-items-center pr-1">
        <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>
