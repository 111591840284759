import {IResource} from 'app/shared/modules/api/models/resource.interface';

export class OrganizationProxy implements IResource {
  readonly type = 'organization_records';

  constructor(public id: string,
              public name,
              public abbreviation: string,
              public legalFormId: string,
              public countryId: string | number,
              public countryCode: string,
              public consultantId: string,
              public grant_clients_folder_to_members: boolean = null,
              public disable_private_dms: boolean = null,
              public disable_business_partner_dms_contacts: boolean = null,
              public contact_cleanup_at: Date = null,
              public show_client_to_business_partner: boolean = null,
              public show_client_in_email: boolean = null,
              public enforce_two_factor_auth: boolean = null,
              public createdAt: Date = null,
              public updatedAt: Date = null) {}
}
