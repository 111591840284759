import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {IamProcessActivityEffects} from './iam-process-activity.effects';
import {reducer} from './iam-process-activity.reducer';
import {stateKey} from './iam-process-activity.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([IamProcessActivityEffects])
  ],
  providers: [IamProcessActivityEffects]
})
export class IamProcessActivityStoreModule {
}
