import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {OperatorSmsRecordEffects} from './sms-record.effects';
import {reducer} from './sms-record.reducer';
import {stateKey} from './sms-record.selectors';
import {OperatorSmsRecordService} from './sms-record.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([OperatorSmsRecordEffects])
  ],
  providers: [
    OperatorSmsRecordEffects,
    OperatorSmsRecordService
  ]
})
export class OperatorSmsRecordStoreModule {
}
