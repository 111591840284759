import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {TaskAssignee} from './task-assignee';
import * as dayjs from 'dayjs';

export interface State extends EntityState<TaskAssignee> {
  selected?: TaskAssignee;
  loading: boolean;
}

export const adapter = createEntityAdapter<TaskAssignee>({
  sortComparer: (l, r) => -(dayjs(l.updatedAt).diff(dayjs(r.updatedAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
