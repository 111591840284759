import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ProjectRoom} from './project-room';

export interface State extends EntityState<ProjectRoom> {
  selected: ProjectRoom;
  loading: boolean;
}

export const adapter = createEntityAdapter<ProjectRoom>();

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false
});
