<div class="mat-dialog-inner-wrap">
  <div class="mat-dialog-header dvtx-bg-color dvtx-bg-cool-grey-25">
    <h2 mat-dialog-title>{{ 'QUICKSHARE.SHARE_DOCUMENTS_TOOLTIP' | translate }}</h2>
    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="mat-dialog-container">
    <dvtx-quick-upload-success-dialog-content></dvtx-quick-upload-success-dialog-content>
  </div>
</div>
