/**
 * Lightweight interface for a folder element.
 */
export interface File {
  id: string;
  name: string;
}

export class IFolder {
  id: string;
  name: string;
  parentId: string | null;
  children: (IFolder | File)[];
}

/**
 * Implementation of a folder in our DMS.
 */
export class DMSFolder implements IFolder {
  constructor(public id: string,
              public name: string,
              public parentId: string = null,
              public children: IFolder[] = []) {}
}

/**
 * Singleton to create a folder structure.
 */
export namespace FolderTreeBuilder {

  /**
   * Creates a tree structure of folder Objects.
   * Returns a root folder with all its subfolders as children.
   *
   * Expects an array of API folder definitions.
   *
   * @param response
   * @returns {DMSFolder}
   */
  /* Algorithm.
   * 1. Iterate over the folder array of the response.
   *    For each entry create a DMSFolder object.
   *    If you find the root folder (parent_id == null)
   *    remember it it will be returned.
   *    Create a index of all folder ids to create the recursive structure afterwards.
   *    Remember all created folders in a catalog for the second iteration.
   * 2. Create the tree structure by iteration over the folder catalog.
   *    if a parentId is present, then add the current folder to the parent
   *    by searching it over the index.
   * 3. Return the root node.
   */
  export function createFolderTreeFrom(response): IFolder {
    let root = null;
    const folderIndex = {};
    const folderCatalog = [];

    response.forEach((responseFolderDefinition) => {
      const folder = FolderTreeBuilder.createFolder(responseFolderDefinition);

      if (!folder.parentId) {
        root = folder;
      }
      folderIndex[folder.id] = folder;
      folderCatalog.push(folder);
    });

    folderCatalog.forEach((folder) => {
      if (folder.parentId) {
        folderIndex[folder.parentId].children.push(folder);
      }
    });
    return root;
  }

  export function createFolder(folder): IFolder {
    return new DMSFolder(folder.id, folder.attributes.name, folder.attributes.parent_id);
  }
}
