import {Action} from '@ngrx/store';
import * as model from './member';
import * as modelInterface from './member.interface';
import * as organizationModel from './../organization/organization';

export enum TenantMemberActionTypes {
  Create = '[Tenant.Member] Create',
  CreateSuccess = '[Tenant.Member] Create Success',
  CreateFail = '[Tenant.Member] Create Fail',
  Remove = '[Tenant.Member] Remove',
  RemoveSuccess = '[Tenant.Member] Remove Success',
  RemoveFail = '[Tenant.Member] Remove Fail',
  Save = '[Tenant.Member] Save',
  SaveSuccess = '[Tenant.Member] Save Success',
  SaveFail = '[Tenant.Member] Save Fail',
  Status = '[Tenant.Member] Status',
  LoadAll = '[Tenant.Member] Load All',
  LoadAllSuccess = '[Tenant.Member] Load All Success',
  LoadAllFail = '[Tenant.Member] Load All Fail',
  LoadOne = '[Tenant.Member] Load One',
  LoadOneSuccess = '[Tenant.Member] Load One Success',
  LoadOneFail = '[Tenant.Member] Load One Fail',
  Reset = '[Tenant.Member] Reset All'
}

export class Create implements Action {
  readonly type = TenantMemberActionTypes.Create;

  constructor(public organizationId: string, public params: modelInterface.Tenant.IMemberParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = TenantMemberActionTypes.CreateSuccess;

  constructor(public member: model.Tenant.Member) {
  }
}

export class CreateFail implements Action {
  readonly type = TenantMemberActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = TenantMemberActionTypes.Remove;

  constructor(public organization: organizationModel.Tenant.Organization, public membership: model.Tenant.Member) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = TenantMemberActionTypes.RemoveSuccess;

  constructor(public member: model.Tenant.Member) {
  }
}

export class RemoveFail implements Action {
  readonly type = TenantMemberActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = TenantMemberActionTypes.Save;

  constructor(public member: model.Tenant.Member) {
  }
}

export class SaveSuccess implements Action {
  readonly type = TenantMemberActionTypes.SaveSuccess;

  constructor(public member: model.Tenant.Member) {
  }
}

export class SaveFail implements Action {
  readonly type = TenantMemberActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = TenantMemberActionTypes.LoadAll;

  constructor(public userId: number = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = TenantMemberActionTypes.LoadAllSuccess;

  constructor(public members: model.Tenant.Member[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = TenantMemberActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = TenantMemberActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = TenantMemberActionTypes.LoadOneSuccess;

  constructor(public member: model.Tenant.Member) {
  }
}

export class LoadOneFail implements Action {
  readonly type = TenantMemberActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}
export class Reset implements Action {
  readonly type = TenantMemberActionTypes.Reset;

  constructor() {
  }
}

export type TenantMemberActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Reset;
