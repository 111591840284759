import {Action} from '@ngrx/store';
import {Society} from './society';

export enum SocietyActionTypes {
  LoadAll = '[Society] LoadAll One',
  LoadAllSuccess = '[Society] LoadAll One Success',
  LoadAllFail = '[Society] LoadAll One Fail',
  Load = '[Society] Load One',
  LoadSuccess = '[Society] Load One Success',
  LoadFail = '[Society] Load One Fail',
}

export class LoadAll implements Action {
  readonly type = SocietyActionTypes.LoadAll;

  constructor(public processId: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = SocietyActionTypes.LoadAllSuccess;

  constructor(public societies: Society[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = SocietyActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Load implements Action {
  readonly type = SocietyActionTypes.Load;

  constructor(public id: string) {
  }
}

export class LoadSuccess implements Action {
  readonly type = SocietyActionTypes.LoadSuccess;

  constructor(public society: Society) {
  }
}

export class LoadFail implements Action {
  readonly type = SocietyActionTypes.LoadFail;

  constructor(public payload: any) {
  }
}

export type SocietyActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Load
  | LoadSuccess
  | LoadFail;
