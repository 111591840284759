import {IProcessFolder} from './process-folder.interface';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';

export class ProcessFolder implements IProcessFolder {
  [x: string]: any;
  readonly type = 'process_folders';
  children: ProcessFolder[] = [];

  constructor(public id: string,
              public dmsFolderId: string,
              public name: string,
              public path: string,
              public admin: boolean,
              public ownedBy: string,
              public sharedBy: DmsAccountType,
              public ownedByMe: boolean,
              public parentId: string,
              public folderCount: number,
              public processCount: number,
              public documentCount: number,
              public shared: boolean,
              public auditProof: boolean,
              public folderSyncAuthorized: boolean,
              public createdAt: Date,
              public updatedAt: Date = null) {}
}
