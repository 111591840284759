import {LineItem} from '../models/line-item';

/**
 * Create LineItems - the respresentation of articles in
 * the shopping cart.
 */
export class LineItemBuilder {
  public static toRequest(lineItem: LineItem) {
    return {
      data: {
        attributes: {
          quantity: lineItem.quantity
        }
      }
    }
  }

  public static fromResponse(data): LineItem {
    return new LineItem(
      data.id,
      data.attributes.title,
      data.attributes.subtitle,
      data.attributes.description,
      data.attributes.quantity,
      data.attributes.price,
      data.attributes.created_at
    )
  }
}
