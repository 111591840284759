import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './task-status.state';
import {ITaskStatus} from './task-status.interface';

export const stateKey = 'task-status';
const getTaskStatusState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getTaskStatusEntities,
  selectAll: getAllTaskStatuses,
} = adapter.getSelectors(getTaskStatusState);

export const getStatusesOfScheme = (schemeId: string) =>  createSelector(
  getAllTaskStatuses,
  (entities) => entities.filter(assignee => {
    return assignee.statusScheme === schemeId;
  })
);
