import { User } from '../models/user';
import * as userActions from '../actions/user-actions';

// the interface that describes user part of the app state
export interface UserState {
  user: User;
  error: any;
  isFetching: boolean;
}

// initial user state
export const initialState: UserState = {
  user: null,
  error: null,
  isFetching: false
};

export function userReducer(state: UserState = initialState, action: userActions.Actions): UserState {

  switch (action.type) {

    case userActions.LOGIN_USER_REQUEST:
      return Object.assign({}, state, { error: null, isFetching: true, user: null });

    case userActions.LOGIN_USER_SUCCESS:
      return Object.assign({}, state, { user: action.payload, error: null, isFetching: false });

    case userActions.LOGIN_USER_ERROR:
      return Object.assign({}, state, { user: null, error: action.payload, isFetching: false  });

    case userActions.USER_REGISTER_REQUEST:
      return Object.assign({}, state, { error: null, isFetching: true });

    case userActions.USER_REGISTER_SUCCESS:
      return Object.assign({}, state, { user: action.payload, error: null, isFetching: false });

    case userActions.USER_REGISTER_ERROR:
      return Object.assign({}, state, { user: null, error: action.payload, isFetching: false });

    case userActions.USER_LOGOUT_REQUEST:
      return Object.assign({}, state, { user: null, error: null, isFetching: true });

    case userActions.USER_LOGOUT_SUCCESS:
      return Object.assign({}, state, { user: null, error: null, isFetching: false });

    case userActions.USER_LOGOUT_ERROR:
      return Object.assign({}, state, { error: action.payload, isFetching: false });

    default: {
      return state;
    }

  }

};

