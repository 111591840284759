import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {ProcessAttribute} from './process-attribute';

export class ProcessAttributeBuilder implements IApiResourceBuilder<ProcessAttribute> {
  constructor() {}

  fromResponse(data): ProcessAttribute {
    return new ProcessAttribute(
      data.id,
      data.attributes.resource_id,
      data.attributes.name,
      data.attributes.value,
      data.attributes.description,
      data.attributes.value_type,
      data.attributes.xml_tag,
      data.attributes.order,
      data.attributes.created_by,
      data.attributes.updated_by,
      data.attributes.created_at,
      data.attributes.updated_at
      );
  }

  toRequest(_: ProcessAttribute) {
    return null;
  }
}
