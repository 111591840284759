import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {MessageEffects} from './message.effects';
import {MessageService} from './message.service';
import {reducer} from './message.reducer';
import {stateKey} from './message.selectors';
import {MessageResourceStatsService} from './message-resource-stats.service';
import {ProjectInvitationCountService} from './project-invitation-count.service';
import {NewsTickerService} from './news-ticker.service';
import {MsgApiResourceService} from './msg-api-resource.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([MessageEffects])
  ],
  providers: [
    MessageEffects,
    MsgApiResourceService,
    MessageService,
    MessageResourceStatsService,
    ProjectInvitationCountService,
    NewsTickerService
  ]
})
export class MessageStoreModule {
}
