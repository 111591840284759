import { IApiResourceBuilder } from 'app/shared/modules/api-resource/models/api.interface';
import { FibuBookmanAccount } from './fibu-bookman-account';

export class FibuBookmanAccountBuilder implements IApiResourceBuilder<FibuBookmanAccount> {

  fromResponse(data): FibuBookmanAccount {
    const fibuBookmanAccount = new FibuBookmanAccount(
      data.id,
      data.attributes.email,
      data.attributes.created_at
    );
    return fibuBookmanAccount;
  }

  toRequest(_: FibuBookmanAccount) {
    return null;
  }
}
