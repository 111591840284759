import {IamProcessActivityActions, IamProcessActivityActionTypes} from './iam-process-activity.actions';
import {adapter, initialState, State} from './iam-process-activity.state';

export function reducer(state = initialState, action: IamProcessActivityActions): State {
  switch (action.type) {
    case IamProcessActivityActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    // case IamProcessActivityActionTypes.LoadAllSuccess:
    //   return adapter.upsertMany(action.payload, {
    //     ...state,
    //     loading: false,
    //   });
    case IamProcessActivityActionTypes.LoadAllSuccess:
      return adapter.setAll(action.payload, {
          ...state,
          loading: false,
      });
    case IamProcessActivityActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

