import * as _ from 'lodash';
import { ICommand } from './commands/icommand';
import { IContact} from '../../../../models';
import { ContactRequestBuilder } from '../../../../modules/contacts/builders/contact-request.builder';
import { IProcessContext, IProcessStatus, CommandQueue } from './process-context.interface';

export class ProcessStatus implements IProcessStatus {
  set code(code) {
    this._code = code;
  }

  get code() {
    return this._code;
  }

  constructor(private _code: string = 'open',
              public message: string = '',
              public infoLevel = '',
              public icon: string = 'fa fa-play-circle') {
  }

  isClosed() {
    return this._code === 'closed' || this._code === 'canceled';
  }

  isOpen() {
    return !this.isClosed();
  }
}

export abstract class AbstractProcessContext implements IProcessContext {

  /**
   * @inheritDoc
   */
  abstract values: any;

  /**
   * @inheritDoc
   */
  abstract config: any;

  /**
   * @inheritDoc
   */
  abstract parentId: any;

  /**
   * @inheritDoc
   */
  abstract workflow: any;

  /**
   * @inheritDoc
   */
  abstract revision: any;

  /**
   * @inheritDoc
   */
  abstract id: string;

  /**
   * @inheritDoc
   */
  abstract type: string;

  /**
   * @inheritDoc
   */
  abstract title: string;

  /**
   * @inheritDoc
   */
  abstract subtitle: string;

  abstract description: string;

  abstract dueDate: string;

  abstract createdAt: string;

  abstract updatedAt: string;

  abstract status: IProcessStatus;

  abstract assignee: IContact;

  /**
   * @inheritDoc
   */
  abstract artifacts: any;

  abstract getValue(code: string): any;

  /**
   * @inheritDoc
   */
  abstract addCommand(hook: CommandQueue.CommandQueueName, command): IProcessContext;

  /**
   * @inheritDoc
   */
  abstract runCommands(hook: CommandQueue.CommandQueueName): Array<ICommand>;

  /**
   * @inheritDoc
   */
  abstract setQueue(hook: CommandQueue.CommandQueueName, queue: Array<ICommand>): void;

  /**
   * @inheritDoc
   */
  abstract getQueue(hook: CommandQueue.CommandQueueName): Array<ICommand>;

  /**
   * @inheritDoc
   */
  abstract toJSON(): any;

  abstract toForm();

  /**
   * @inheritDoc
   */
  abstract clone(): AbstractProcessContext;
}

export class ProcessContext extends AbstractProcessContext {
  public children?: any;

  private _commandQueues = {};

  // Variable binding: Map that binds a symbol of a process variable to a value.
  private _values = <any>{};

  private _parentId: string = null;

  private _config: any;

  private _assignee: IContact;

  dueDate: string;

  createdAt: string;

  updatedAt: string;

  artifacts: any;

  type: string;

  set values(values) {
    this._values = values;
  }

  get values() {
    return this._values;
  }

  set config(config) {
    this._config = config;
  }

  get config() {
    return this._config;
  }

  set parentId(parentId: string) {
    this._parentId = parentId;
  }

  get parentId(): string {
    return this._parentId;
  }

  set assignee(assignee: IContact) {
    this._assignee = assignee;
    this.values.assignee = assignee;
  }

  get assignee(): IContact {
    return this._assignee;
  }

  constructor(public workflow = '',
              public revision = '',
              public id: string = null,
              public title: string = '',
              public subtitle: string = '',
              public description: string = '',
              public status: IProcessStatus = new ProcessStatus()) {
    super();

    // Initialize the command queues under each hook name.
    CommandQueue.defaultQueues().forEach((hookName) => {
      this._commandQueues[hookName] = [];
    });

    this.dueDate = '';

    this.createdAt = '';

    this.updatedAt = '';

    this.artifacts = [];
  }

  /**
   * @inheritDoc
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @param command
   * @returns {IProcessContext}
   */
  addCommand(hook: CommandQueue.CommandQueueName, command): IProcessContext {
    this._commandQueues[hook].push(command);
    return this;
  }

  /**
   * @inheritDoc
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @param {Array<ICommand>} queue
   */
  setQueue(hook: CommandQueue.CommandQueueName, queue): void {
    this._commandQueues[hook] = queue;
  }

  /**
   * @inheritDoc
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @returns {Array<ICommand>}
   */
  getQueue(hook: CommandQueue.CommandQueueName): Array<ICommand> {
    return this._commandQueues[hook];
  }

  /**
   * @inheritDoc
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @returns {Array<ICommand>}
   */
  runCommands(hook: CommandQueue.CommandQueueName): Array<ICommand> {
    if (!_.includes(CommandQueue.defaultQueues(), hook)) {
      console.log(`ProcessContext: queue ${hook} is unknown.`);
      return [];

    } else {

      if (this._commandQueues[hook].length === 0) {
        console.log(`ProcessContext: Command queue ${hook} is empty.`);
        return [];

      } else {
        console.log(`ProcessContext: Running commands from queue ${hook}.`);

        let i = 0;
        let command = this._commandQueues[hook][i];
        while (command && command.execute()) {
          i += 1;
          command = this._commandQueues[hook][i];
        }
        return this._commandQueues[hook].slice(i + 1);
      }
    }
  }

  /**
   * @inheritDoc
   *
   * @returns {ProcessContext}
   */
  clone(): ProcessContext {
    const context = new ProcessContext(this.workflow, this.revision);
    CommandQueue.defaultQueues().forEach((hookName) => {
      this._commandQueues[hookName].forEach((cmd) => {
        context.addCommand(hookName, cmd);
      });
    });
    context.values = _.cloneDeep(this.values);
    context.parentId = this.parentId;
    return context;
  }

  isClosed(): boolean {
    return this.status.isClosed();
  }

  getValue(code): any {
    return this.values[code];
  }

  toForm() {
    throw new Error('Not implemented, yet.');
  }

  /**
   * @inheritDoc
   *
   * @returns {{workflow: string; revision: string; binding: {}}}
   */
  toJSON() {
    let assignee = null;
    try {
      if (this.assignee) {
        assignee = Object.assign({}, ContactRequestBuilder.generateDataForApi(this.assignee));
      }
      this.values.assignee = ContactRequestBuilder.generateDataForApi(assignee);
    } catch (e) {}

    return {
      workflow: this.workflow,
      revision: this.revision,
      parentId: this.parentId,
      values: this.values
    };
  }
}
