import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatSidenav } from '@angular/material/sidenav';
import {
  FeatureSelectors,
  InvitationActions,
  NaturalPersonActions,
  OrganizationActions,
  OrganizationSelectors,
  PageSidebarActions,
  PageSidebarSelectors
} from 'app/+store';
import { combineLatest as observableCombineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, map, takeUntil } from 'rxjs/operators';
import { SystemService } from 'app/services/system.service';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { AngularTokenService } from 'angular-token';
import * as storeCache from 'store';
import { PageSidebarComponent } from 'app/shared/modules/page-sidebar/containers/page-sidebar/page-sidebar.component';
import { SidebarRoute } from 'app/shared/modules/page-sidebar/containers/sidebar-navigation/sidebar-navigation.component';
import { Feature } from 'app/+store/feature/feature';
import { CmsBlog } from 'app/+store/cms-blog/cms-blog';
import { AppState } from 'app/reducers';
import { PageSidebar } from 'app/+store/page-sidebar/page-sidebar';
import { GetCurrentUser } from 'app/actions/current-user-actions';
import { CmsBlogSelectors } from 'app/+store/cms-blog';
import { User } from 'app/models/user';
import * as foxdoxStatus from 'app/actions/foxdox-status.actions';
import * as tenantModel from 'app/+store/tenant/tenant/tenant';

declare var $;

@Component({
  selector: 'dvtx-main-app',
  templateUrl: './main-app.component.html',
  styleUrls: ['./main-app.component.scss']
})
export class MainAppComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();
  @ViewChild('sidebar', { static: true }) public sidebar: MatSidenav;
  @ViewChild('pageSideBarComponent') public pageSideBarComponent: PageSidebarComponent;
  public error;
  public showQuickstarter = false;
  public loading: boolean = false;
  public featureSet$: Observable<Feature>;
  public sidebarMainRoutes: SidebarRoute[] = [];
  private blog$: Observable<CmsBlog>;
  public sidebarSettingsRoutes: SidebarRoute[] = [];
  public sidebarOtherRoutes: SidebarRoute[] = [];
  public currentOrganization$: Observable<any>;
  private componentBeforeNavigation: any = null;
  public sidebarCollapsed = true;
  private smallScreen: boolean;

  constructor(private _store: Store<AppState>,
    public _authTokenService: AngularTokenService,
    private _systemSvc: SystemService,
    private _router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    @Inject(DOCUMENT) document,
    private _cdr: ChangeDetectorRef) {
    this._store.dispatch(new PageSidebarActions.Open(new PageSidebar()));
    const self = this;

    // resize sidebar feature
    $(document).ready(function () {
      const resizer = document.querySelector('.dvtx-sidebar-resizer');
      const sidebar = document.querySelector('.dvtx-sidebar-nav-container');
      const appMainContainer = document.querySelector('.dvtx-app-main-container');
      const navigationWidth: any = document.querySelector('.dvtx-sidebar-container');

      if (resizer) {
        resizer.addEventListener('mousedown', (event) => {
          document.addEventListener('mousemove', resize, false);
          document.addEventListener('mouseup', () => {
            if (appMainContainer) {
              appMainContainer.style['user-select'] = 'unset'
            }

            document.removeEventListener('mousemove', resize, false);
          }, false);
        });
      }

      function resize(e) {
        if (document.querySelector('.dvtx-sidebar-resizer-remove')) {
          return;
        }
        const size = `${e.x}px`;
        if (appMainContainer) {
          appMainContainer.style['user-select'] = 'none'
        }
        if (e.x < 300 && sidebar && navigationWidth) {
          sidebar.style.flexBasis = '300px';
          sidebar.style['min-width'] = '300px';
          navigationWidth.style['width'] = `${300 - 70}px`
        } else if (e.x > 800 && sidebar && navigationWidth) {
          sidebar.style.flexBasis = '800px';
          sidebar.style['min-width'] = '800px';
          navigationWidth.style['width'] = `${800 - 70}px`
        } else if (sidebar && navigationWidth) {
          sidebar.style.flexBasis = size;
          sidebar.style['min-width'] = size;
          navigationWidth.style['width'] = `${e.x - 70}px`
          storeCache.set('sidebar-resize', e.x);
        }
      }
    });
    this._router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

  ngOnInit(): void {
    // Keep it for debugging: Print routes.
    // for (let i = 0; i < this._router.config.length; i++) {
    //   const path: string = this._router.config[i].path;
    //   console.log(`Route: ${path}`, this._router.config[i]);
    // }

    this.currentOrganization$ = this._store.select(OrganizationSelectors.getSelected).pipe(map(x => x));
    this._store.dispatch(new GetCurrentUser(false));
    // 2019-02-12: Userlance is currently disabled.
    // if (environment.production) {
    //   Userlane('init', 31843);
    // }
    this._store.select('currentUser').pipe(
      filter(u => { return !!u }),
      distinctUntilKeyChanged('uid'),
      takeUntil(this.onDestroy)
    ).subscribe((user: User) => {
      this._store.dispatch(new NaturalPersonActions.LoadMy());
      this._store.dispatch(new foxdoxStatus.GetStatus());
      this._store.dispatch(new InvitationActions.LoadMy());
    });

    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
    this.blog$ = this._store.select(CmsBlogSelectors.getCmsBlogOfSelectedOrg);
    this._activateQuickstarterToolbar();
    this.sidebar.open();
    this.sidebarMainRoutes.push(new SidebarRoute('BANNER.LINK.DASHBOARD_TITLE', 'home', `/dashboard`));
    this.sidebarMainRoutes.push(new SidebarRoute('BANNER.LINK.PROJECT_ROOMS_TITLE', 'projectroom', `/workflows/my-workflows`));
    this.sidebarMainRoutes.push(new SidebarRoute('TASK.TITLE', 'tasks', `/tasks/my`));

    observableCombineLatest(this.featureSet$, this.blog$, this.currentOrganization$)
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(([featureSet, blog, currentOrganization]) => {
        this.sidebarSettingsRoutes = [];
        this.sidebarSettingsRoutes.push(new SidebarRoute('BANNER.LINK.FAQ_TITLE', 'help', `/faq`));
        this.sidebarOtherRoutes = [];
        if (featureSet && featureSet.hasFastdocs && currentOrganization) {
          this.sidebarOtherRoutes.push(new SidebarRoute('FASTDOCS.DASHBOARD_STARTER_TITLE', 'lohn', `/fastdocs/container`));
        }
        if (featureSet && currentOrganization && blog && featureSet.hasCms && blog.publishedAt) {
          this.sidebarOtherRoutes.push(new SidebarRoute(blog.linkTitle, 'article', `/cms/content/${currentOrganization?.id}/articles`));
        }
        if (featureSet && featureSet.hasLabels) {
          this.sidebarOtherRoutes.push(new SidebarRoute('LABELS.LABELS_OVERVIEW', 'labels', `/label-items`));
        }

        // TODO: Grant Thornton purplehub link
        //       - Authorize by tenant membership and GT domain.
        // if (tenantModel.Tenant.Tenant.isGrantThornton()) {
        //   this.sidebarOtherRoutes.push(new SidebarRoute('Purplehub', 'gt', `/`));
        // }
      })
    if (this.pageSideBarComponent) {
      this.pageSideBarComponent.configureSideNav();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  // Shows and hides the loading spinner during RouterEvent changes
  private navigationInterceptor(event: RouterEvent): void {
    const path = this._location.path().split('?')[0];
    // Prevent scrollup on tasks and Collectos
    //  if (/tasks/.test(path) || /collecto/.test(path)) {
    //    this.loading = false;
    //    return;
    //  }
    let loading = JSON.parse(JSON.stringify(this.loading));
    const previousLoadingState = this.loading;
    if (event instanceof NavigationStart) {
      // load spinner if navigation is to a new component
      if (!event.url.includes(path)) {
        loading = true;
      }
    }
    if (event instanceof NavigationEnd) {
      loading = false
      // scroll up if navigation is to a new component
      let currentRoute = this.route;
      while (currentRoute.firstChild) currentRoute = currentRoute.firstChild;
      if (this.componentBeforeNavigation !== currentRoute.component) {
        this.scrollUp();
      }
      this.componentBeforeNavigation = currentRoute.component;
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      loading = false
    }
    if (event instanceof NavigationError) {
      loading = false
    }
    this.loading = loading;

    if (previousLoadingState !== loading) {
      this._cdr.detectChanges();
    }
  }

  private scrollUp() {
    setTimeout(() => {
      const documentSideNavContent = document.querySelector('mat-sidenav-content');
      if (documentSideNavContent) {
        documentSideNavContent.scrollTo(0, 0);
      }
    }, 100);
  }

  public setSidebarCollapsedState($event) {
    this.sidebarCollapsed = !!$event;
    this._cdr.detectChanges();
  }

  /**
   * Shows the quickstart buttons only if the route equals the dashboard.
   * Implementation uses the location service and router events to listen on the route because
   * - The app component is always present.
   * - The quickstart buttons are currently embedded inside the main layout.
   */
  private _activateQuickstarterToolbar() {
    this.checkQuickstarterToolbar();
    this._router.events
      .pipe(takeUntil(this.onDestroy))
      .subscribe(_ => {
        this.checkQuickstarterToolbar();

      });
  }

  checkQuickstarterToolbar() {
    const path = this._location.path();
    this.showQuickstarter = /^\/dashboard/.test(path) && !(/\/overdue/.test(path));
  }

  openSidebar($event) {
    if (!this.sidebar) return;
    if ($event) {
      this.sidebar.open();
    } else {
      this.sidebar.close();
    }
  }

  checkSidebarToggleIcon() {
    this.smallScreen = window.innerWidth < 800 ? true : false
    if (this.smallScreen) {
      if (this.sidebar.opened) {
        return 'navigate_before';
      } else {
        return 'navigate_next'
      }
    } else {
      if (this.sidebarCollapsed) {
        return 'navigate_next'
      } else {
        return 'navigate_before';
      }
    }
  }

  // private _activateSidaBarListener() {
  //   this._store.select(PageSidebarSelectors.isOpen).pipe(
  //     takeUntil(this.onDestroy)
  //   ).subscribe(open => {
  //     if (this.sidebar) {
  //       open ? this.sidebar.open() : this.sidebar.close()
  //     }
  //   });
  // }
}
