import {IResource} from './resource.interface';

export class PaymentOption implements IResource {
  readonly type = 'sales_sepa';

  public confirmed = false;

  get displayName() {
    return `${this.firstName} ${this.lastName} ${this.iban}`;
  }

  constructor(
    public id: number,
    public firstName: string,
    public lastName: string,
    public iban: string,
    public bic: string,
    public confirmedAt: Date
  ) {
    this.confirmed = !!confirmedAt;
  }
}
