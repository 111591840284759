<form class="tfa-verification" [formGroup]="form">
  <div class="header-text">
    {{ 'AUTH.TWO_FACTOR_COMP_TITLE' | translate }}
    <p>{{'AUTH.VERIFY_COMPONENT_SUBTITLE' | translate}} <a (click)="sendSMS()"> {{ 'AUTH.VERIFY_COMPONENT_SEND' | translate}} </a> </p>
  </div>
  <div class="verification-component">
    <mat-form-field class="paddright font-size-big">
      <input matInput #firstInput formControlName="firstInput" maxlength="1" class="textcenter" autocomplete="off" (click)="setCaretPosition($event)" autocapitalize="none" />
    </mat-form-field>
    <mat-form-field class="paddleft paddright font-size-big">
      <input matInput #secondInput formControlName="secondInput" maxlength="1" class="textcenter" autocomplete="off" (keydown.backspace)="onBackspace($event, 2)" (click)="setCaretPosition($event)" autocapitalize="none" />
    </mat-form-field>
    <mat-form-field class="paddleft paddright font-size-big">
      <input matInput #thirdInput formControlName="thirdInput" maxlength="1" class="textcenter" autocomplete="off" (keydown.backspace)="onBackspace($event, 3)" (click)="setCaretPosition($event)" autocapitalize="none" />
    </mat-form-field>
    <mat-form-field class="paddleft font-size-big">
      <input matInput #fourthInput formControlName="fourthInput" maxlength="1" class="textcenter" autocomplete="off" (keydown.backspace)="onBackspace($event, 4)" (click)="setCaretPosition($event)" autocapitalize="none" />
    </mat-form-field>
    <mat-form-field class="paddleft font-size-big">
      <input matInput #fifthInput formControlName="fifthInput" maxlength="1" class="textcenter" autocomplete="off" (keydown.backspace)="onBackspace($event, 5)" (click)="setCaretPosition($event)" autocapitalize="none" />
    </mat-form-field>
    <mat-form-field class="paddleft font-size-big">
      <input matInput #sixthInput formControlName="sixthInput" maxlength="1" class="textcenter" autocomplete="off" (keydown.backspace)="onBackspace($event, 6)" (click)="setCaretPosition($event)" autocapitalize="none" />
    </mat-form-field>
    <!-- <div class="button-container">
      <button mat-raised-button color="primary" class="fullwidth" mat-button type="button" >
        {{ 'AUTH.SEND_BY_SMS_BUTTON' | translate }}
      </button>
    </div> -->
  </div>
  <mat-error *ngIf="error?.length > 0">{{error | translate}}</mat-error>
</form>
