import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {ProcessAction} from './process-action';

export enum ProcessActionActionTypes {
  LoadAll = '[ProcessAction] Load All Actions',
  LoadAllSuccess = '[ProcessAction] Load All Actions Success',
  LoadAllFail = '[ProcessAction] Load All Actions Fail',
  Run = '[ProcessAction] Run Action',
  RunSuccess = '[ProcessAction] Run Action Success',
  RunFail = '[ProcessAction] Run Action Fail',
}

export class LoadAll implements Action {
  readonly type = ProcessActionActionTypes.LoadAll;

  constructor(public id: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ProcessActionActionTypes.LoadAllSuccess;

  constructor(public payload: ProcessAction[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ProcessActionActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Run implements Action {
  readonly type = ProcessActionActionTypes.Run;

  constructor(public command: any) {
  }
}

export class RunSuccess implements Action {
  readonly type = ProcessActionActionTypes.RunSuccess;

  constructor(public payload: any) {
  }
}

export class RunFail implements Action {
  readonly type = ProcessActionActionTypes.RunFail;

  constructor(public payload: any) {
  }
}

export type ProcessActionActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Run
  | RunSuccess
  | RunFail;
