<ng-container *ngIf="nodes$ | async as filteredNodes">
  <div class="dvtx-dms-file-picker">
    <div class="dvtx-dms-file-picker-toolbar mb-1">
      <div class="d-flex" *ngIf="!isCreateInput && !isRenameInput && !isSearch">
        <button mat-icon-button (click)="isSearch = true" matTooltip="{{ 'GENERAL.SEARCH' | translate }}" [disabled]="loading">
          <mat-icon>search</mat-icon>
        </button>
        <button mat-icon-button (click)="refresh()" matTooltip="{{ 'GENERAL.REFRESH_ACTION' | translate }}" [disabled]="loading">
          <mat-icon>refresh</mat-icon>
        </button>
        <button mat-icon-button (click)="toggleAdd()" class="ml-2" matTooltip="{{ 'DMS.CREATE_FOLDER_ACTION' | translate }}" [disabled]="!selectedNode || loading || selectedNode?.data?.writePerm !== true">
          <mat-icon>create_new_folder</mat-icon>
        </button>
        <button mat-icon-button (click)="toggleRename()" matTooltip="{{ 'GENERAL.RENAME_ACTION' | translate }}" [disabled]="!selectedNode || loading || selectedNode?.data?.renamePerm !== true">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="deleteFolder()" [disabled]="!selectedNode || loading || selectedNode?.data?.deletePerm !== true" matTooltip="{{ 'DMS.DELETE_FOLDER_ACTION' | translate }}">
          <mat-icon>delete</mat-icon>
        </button>

        <dvtx-dms-folder-info *ngIf="selectedNode?.data" [folder]="selectedNode?.data" [buttonColor]="'primary'" [action]="FolderInfoAction.Button"></dvtx-dms-folder-info>
      </div>

      <div *ngIf="isSearch" class="d-flex dvtx-folder-picker-create-folder">
        <form class="d-flex w-100">
          <mat-form-field class="five-f-search" (keydown.enter)="$event.preventDefault()">
            <input type="text" [placeholder]="'GENERAL.SEARCH' | translate" (keyup)="search(searchFieldRef.value)"
              matInput #searchFieldRef>
            <mat-icon matPrefix>search</mat-icon>
            <button mat-button *ngIf="searchFieldRef?.value" matSuffix mat-icon-button color="gray" [disableRipple]="true"
              aria-label="Clear" (click)="search(''); searchFieldRef.value = ''; $event.stopPropagation()">
              <mat-icon class="mb-1">close</mat-icon>
            </button>
          </mat-form-field>
          <div class="align-self-center ml-1">
            <div class="d-flex">
              <button type="button" class="ml-1" mat-button
                (click)="search(''); isSearch = false; searchFieldRef.value = ''; $event.stopPropagation(); ">{{
                'GENERAL.CANCEL_ACTION' | translate }}</button>
            </div>
          </div>
        </form>
      </div>

      <div *ngIf="isRenameInput" class="d-flex dvtx-folder-picker-create-folder">
        <form class="d-flex w-100">
          <mat-form-field>
            <input #renameFolderName matInput placeholder="{{ 'DMS.FOLDER_NAME' | translate }}" [value]="selectedNode?.data?.name" (keyup.enter)="renameFolder(renameFolderName.value)">
          </mat-form-field>
          <div class="align-self-center ml-1">
            <div class="d-flex">
              <button type="button" mat-raised-button color="primary" [disabled]="createDisabled || (renameFolderName.value.length === 0)" (click)="renameFolder(renameFolderName.value)">{{ 'GENERAL.RENAME_ACTION' | translate }}</button>
              <button type="button" class="ml-1" mat-button (click)="$event.stopPropagation(); isRenameInput = false">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
            </div>
          </div>
        </form>
      </div>

      <div *ngIf="isCreateInput" class="d-flex dvtx-folder-picker-create-folder">
        <form class="d-flex w-100">
          <mat-form-field>
            <input #folderName matInput placeholder="{{ 'DMS.FOLDER_NAME' | translate }}" (keyup.enter)="createFolder(folderName.value)">
          </mat-form-field>
          <div class="align-self-center ml-1">
            <ng-template [ngIf]="createInputButtonsType === 'normal'">
              <div class="d-flex">
                <button type="button" mat-raised-button color="primary" [disabled]="createDisabled || (folderName.value.length === 0)" (click)="createFolder(folderName.value)">
                  {{ 'GENERAL.CREATE_ACTION' | translate }}
                </button>
                <button type="button" class="ml-1" mat-button (click)="$event.stopPropagation(); isCreateInput = false">
                  {{ 'GENERAL.CANCEL_ACTION' | translate }}
                </button>
              </div>
            </ng-template>
            <ng-template [ngIf]="createInputButtonsType === 'icons'">
              <div class="d-flex">
                <button mat-icon-button color="primary" [disabled]="createDisabled || (folderName.value.length === 0)" (click)="createFolder(folderName.value)" [matTooltip]="'GENERAL.CREATE_ACTION' | translate">
                  <mat-icon>check</mat-icon>
                </button>
                <button class="ml-1" mat-icon-button (click)="$event.stopPropagation(); isCreateInput = false" [matTooltip]="'GENERAL.CANCEL_ACTION' | translate">
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
            </ng-template>

          </div>
        </form>
      </div>

      <ng-template [ngIf]="reloading && !(filteredNodes?.length > 0)">
        <div style="padding: 14px; height: 100%; width: 100%" class="dvtx-loading-indicator">
          <div class="d-flex align-items-center">
            <mat-spinner [diameter]="20" style="margin-right: 15px;"></mat-spinner>
            {{ 'GENERAL.LOAD_ITEMS' | translate }}<span>.</span><span>.</span><span>.</span>
          </div>
        </div>
      </ng-template>
    </div>

    <div class="dvtx-dms-file-picker-tree dvtx-dms-folder-picker-tree"
         *ngIf="(filteredNodes?.length > 0) || !reloading">
      <tree-root [nodes]="filteredNodes" [options]="options" #treeComponent>
        <ng-template #treeNodeTemplate let-node let-index="index">
          <div class="dvtx-tree-node-wrapper" [ngClass]="{'dvtx-root-node': node?.data?.root }">
            <div [class.dvtx-root-node-private]="node?.data?.accountType === DmsAccountType.Private"
                 [class.dvtx-root-node-organization]="node?.data?.accountType === DmsAccountType.Organization" [class.dvtx-root-node-bookman]="node?.data?.bookmanCockpitEnabled">
              <dvtx-folder-tree-icon [iNode]="node?.data"></dvtx-folder-tree-icon>
            </div>

            <ng-template [ngIf]="node.data.type === NodeType.Error" [ngIfElse]="nodeName">
              <span>{{ 'FILE_PICKER.SERVICE_UNAVAILABLE' | translate }}</span>
            </ng-template>

            <ng-template #nodeName>
              <span>{{ node.data.name }}</span>
            </ng-template>
          </div>
        </ng-template>
      </tree-root>
    </div>
  </div>
</ng-container>
