import {Action} from '@ngrx/store';
import {CollectorLookupItem} from './collector-item-lookup';

export enum CollectorItemLookupActionTypes {
  LoadOne = '[CollectorItemLookup] Load One',
  LoadOneSuccess = '[CollectorItemLookup] Load One Success',
  LoadOneFail = '[CollectorItemLookup] Load One Fail',
  FetchOne = '[CollectorItemLookup] Fetch One',
  SkipFetching = '[CollectorItemLookup] Skip fetching',
  ResetOne = '[CollectorItemLookup] Resets one item from the cache',
  ResetAll = '[CollectorItemLookup] Reset the lookup item cache'
}

/**
 * Requests a Collecto lookup item fetch action.
 */
export class LoadOne implements Action {
  readonly type = CollectorItemLookupActionTypes.LoadOne;

  constructor(public processId: string, public itemId: string) {
  }
}

/**
 * Real API fetching intermediate action.
 */
export class FetchOne implements Action {
  readonly type = CollectorItemLookupActionTypes.FetchOne;

  constructor(public processId: string, public itemId: string) {
  }
}

/**
 * Resets the item cache for item with ID. E.g. if group or element title was changed.
 */
export class ResetOne implements Action {
  readonly type = CollectorItemLookupActionTypes.ResetOne;

  constructor(public itemId: string) {
  }
}

/**
 * Resets the item cache. E.g. if a group was changed or items were reordered.
 */
export class ResetAll implements Action {
  readonly type = CollectorItemLookupActionTypes.ResetAll;

  constructor(public payload: any = null) {
  }
}

/**
 * Action for successful item fetching.
 */
export class LoadOneSuccess implements Action {
  readonly type = CollectorItemLookupActionTypes.LoadOneSuccess;

  constructor(public item: CollectorLookupItem) {
  }
}

/**
 * Action for failed item fetching.
 */
export class LoadOneFail implements Action {
  readonly type = CollectorItemLookupActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

/**
 * Action if item is already fetched or in fetching state.
 */
export class SkipFetching implements Action {
  readonly type = CollectorItemLookupActionTypes.SkipFetching;

  constructor(public payload: any = null) {
  }
}

export type CollectorItemLookupActions =
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | FetchOne
  | ResetOne
  | ResetAll
  | SkipFetching;
