import {combineLatest, first, takeUntil} from 'rxjs/operators';
import {
  AfterViewInit, ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {SplitViewDialogComponent} from 'app/shared/modules/page-container/split-view-dialog/split-view-dialog.component';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Net} from 'app/lib/net/uuid';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {AngularTokenService} from 'angular-token';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {ProcessParticipantService} from 'app/+store/process-participant/process-participant.service';
import {ProcessParticipantActions} from 'app/+store/process-participant';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ProcessParticipant} from '../../../../../../+store/process-participant/process-participant';
import {ContactSelectors} from 'app/+store/contact';
import {PersonContactListDto} from 'app/models/contact-list-dto.model';

export enum AddOrInviteActionType {
  Button = 'ParticipantRole',
  MenuItem = 'MenuItem'
}

export enum AddOrInviteParticipantType {
  Participant = 'Participant',
  ExternalParticipant = 'ExternalParticipant'
}

@Component({
  selector: 'dvtx-add-and-invite',
  templateUrl: './add-and-invite.component.html',
  styleUrls: ['./add-and-invite.component.scss']
})
export class AddAndInviteComponent implements AfterViewInit, OnDestroy, OnInit {
  AddOrInviteActionType = AddOrInviteActionType;
  AddOrInviteParticipantType = AddOrInviteParticipantType;

  @Input() action: AddOrInviteActionType;
  @Input() participantType = AddOrInviteParticipantType.Participant;

  _processId;
  @Input() set processId(id: string) {
    this._processId = id;
    // if (Net.validUUID(id)) {}
  }

  private _participant: ProcessParticipant;
  @Input() set participant(p: ProcessParticipant) {
    this._participant = p;
    if (p) {
      this.form.patchValue({
        email: p.email
      });
      this.form.updateValueAndValidity();
      this.form.markAsPristine();
    }
    this.participant$.next(p);
  }

  @Output() onOpenDialog = new EventEmitter();
  @Output() onSelect = new EventEmitter<any>();
  onDestroy = new Subject();

  @ViewChild('buttonsToolbar', {static: true}) buttonsToolbar: TemplateRef<any>;
  @ViewChild('context', {static: true}) context: TemplateRef<any>;
  @ViewChild('titleContext', {static: true}) titleRef: TemplateRef<any>;
  buttonsToolbarPortal: Portal<any>;
  contextPortal: Portal<any>;
  titlePortal: Portal<any>;
  dialogRef: MatDialogRef<SplitViewDialogComponent>;
  form: UntypedFormGroup;
  newParticipant = new UntypedFormControl(null);
  dialogTitle = 'PROJECT_ROOM.ADD_AND_INVITE_TITLE';
  submitOnGoing = false;
  participant$ = new BehaviorSubject<ProcessParticipant>(null);

  constructor(private _dialog: MatDialog,
              private router: Router,
              private _store: Store<AppState>,
              private _viewContainerRef: ViewContainerRef,
              private _fb: UntypedFormBuilder,
              private _tokenSvc: AngularTokenService,
              private _participantSvc: ProcessParticipantService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService,
              private avatarService: AvatarService,
              private _cdr: ChangeDetectorRef) {
    if (!this.form) this.form = this._initForm();
  }

  ngOnInit() {
    const contacts$ = this._store.select(ContactSelectors.getMembersAndContactPersonsOfSelectedOrg())

    this.participant$.pipe(combineLatest(contacts$))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([participant, contacts]) => {
        if (participant && contacts && contacts.length) {
          const found: PersonContactListDto = <PersonContactListDto>contacts.find(c => c.email === participant.email);
          if (found) {
            this.form.patchValue({
              first_name: found.firstName,
              last_name: found.lastName
            });
            this.form.updateValueAndValidity();
            this.form.markAsPristine();
            try {
              this._cdr.detectChanges();
            } catch (err) {
              console.error(err);
            }
          }
        }
      });
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this._initContextPortal();
      this._initTitlePortal();
      this._initButtonToolbarPortal();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  addParticipant() {
    if (this.form.invalid) {
      console.error('Form is invalid. Exiting...');
      return;
    }

    this.submitOnGoing = true;
    const values = this.form.value;
    if (this._participant && this.participantType === AddOrInviteParticipantType.ExternalParticipant) {
      if (!this._processId) {
        console.error('Process ID is missing. Exiting...');
        return;
      }
      this._participantSvc.inviteExternalToProjectRoom(this._processId, this._participant.id, values.email, values.first_name, values.last_name)
        .pipe(first())
        .subscribe(participant => {
          this._store.dispatch(new ProcessParticipantActions.CreateSuccess(participant));
          this.submitOnGoing = false;
          if (!this._participant) {
            this._reset();
          }
          this.closeDialog();
        }, err => {
          console.error(err);
          this.submitOnGoing = false;
          this.closeDialog();
        });
    } else if (this.participantType === AddOrInviteParticipantType.Participant) {
      this.onSelect.emit(values);
      this.submitOnGoing = false;
      this._reset();
      this.closeDialog();
    }
  }

  openDialog() {
    this._initContextPortal();
    this._initTitlePortal();
    this._initButtonToolbarPortal();

    this.onOpenDialog.emit(this._participant);

    if (!this._participant) {
      this._reset();
    }

    this.dialogRef = this._dialog.open(SplitViewDialogComponent, {
      data: {
        color: '#233246',
        icon: null,
        title: this.titlePortal,
        context: this.contextPortal,
        buttonsToolbar: this.buttonsToolbarPortal
      }
    });
  }

  closeDialog() {
    if (!this._participant) {
      this._reset();
    }
    this.dialogRef.close();
  }

  private _initContextPortal() {
    this.contextPortal = this._createPortal(this.contextPortal, this.context);
  }

  private _initTitlePortal() {
    this.titlePortal = this._createPortal(this.titlePortal, this.titleRef);
  }

  private _initButtonToolbarPortal() {
    this.buttonsToolbarPortal = this._createPortal(this.buttonsToolbarPortal, this.buttonsToolbar);
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }

  private _initForm() {
    return this._fb.group({
      email: [null, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required]
    });
  }

  private _reset() {
    if (this._participant) return;

    this.form.reset();
    this.form.updateValueAndValidity();
    this.form.markAsPristine();
  }
}
