import {IResource} from 'app/shared/modules/api/models/resource.interface';
import {IRole} from 'app/models';

export interface IProcessParticipantRole extends IRole {
  symbol: string;
  name: string;
}

export enum ProcessParticipantType {
  Member = 'MEMBER',
  External = 'EXTERNAL'
}

export interface IProcessParticipant extends IResource {
  id: string;
  processId: string;
  name: string;
  email: string;
  gender: string;
  title: string;
  firstName: string;
  lastName: string;
  contactId;
  roles: IProcessParticipantRole[];
  isMember?: boolean;
  isAdmin: boolean;
  isCreator: boolean;
  isOwner: boolean;
  recursive: boolean;
  createdAt?: Date;

  getName(): string;
}
