import {ICmsFaqCategory, ICmsFaqSection, ICmsFaqItem} from './cms-faq.interface';

export class CmsFaqItem implements ICmsFaqItem {
  readonly type = 'cms_faq_item';
  categoryId: string;
  sectionId: string;

  constructor(public id,
              public title_de,
              public title_en,
              public document_title_de,
              public document_title_en,
              public url_de,
              public url_en,
              public video_url_de,
              public video_url_en,
              public order,
              public published_at,
              public licenced_only,
              public createdAt = null,
              public updatedAt = null) {
  }
}

export class CmsFaqSection implements ICmsFaqSection {
  readonly type = 'cms_faq_section';
  items: CmsFaqItem[] = [];
  categoryId: string;

  constructor(public id,
              public title_de,
              public title_en,
              public order,
              public published_at,
              public createdAt = null,
              public updatedAt = null) {
  }
}

export class CmsFaqCategory implements ICmsFaqCategory {
  readonly type = 'cms_faq_category';
  sections: CmsFaqSection[] = [];

  constructor(public id,
              public title_de,
              public title_en,
              public order,
              public published_at,
              public createdAt = null,
              public updatedAt = null) {
  }
}
