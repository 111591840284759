import { ITaskResource, TaskResourceType } from './task-resource.interface';

export class TaskResource implements ITaskResource {
  readonly type = 'task_resources';

  constructor(public id: string = null,
              public title: string = null,
              public description: string = null,
              public creatorEmail: string = null,
              public resourceId: string = null,
              public resourceType: TaskResourceType = null,
              public createdAt: Date = null,
              public updatedAt: Date = null,
              public role: string = null,
              public revision: string = null) {}
}
