import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {DashboardStatsComponent} from './containers/dashboard-stats/dashboard-stats.component';
import {TasksStatsComponent} from './components/tasks-stats/tasks-stats.component';
import {MyTasksStatsComponent} from './components/my-tasks-stats/my-tasks-stats.component';
import {TooltipModule} from 'app/five-f/tooltip/tooltip.module';
import {TasksStatisticsComponent} from './components/statistics/statistics.component';
import {TasksDueDateStatisticsComponent} from './components/due-date-statistics/due-date-statistics.component';
import {BarChartModule, NumberCardModule, PieChartModule} from "@swimlane/ngx-charts";

@NgModule({
  imports: [
    SharedModule,
    TooltipModule,
    BarChartModule,
    NumberCardModule,
    PieChartModule
  ],
  declarations: [
    TasksStatsComponent,
    DashboardStatsComponent,
    MyTasksStatsComponent,
    TasksStatisticsComponent,
    TasksDueDateStatisticsComponent
  ],
  exports: [
    DashboardStatsComponent,
    TasksStatisticsComponent,
    TasksDueDateStatisticsComponent
  ]
})
export class DashboardStatsModule {
}
