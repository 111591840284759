import {NgModule} from '@angular/core';
import {ParticipantModule} from './modules/participant/participant.module';
import {DataVizualizationModule} from './modules/data-vizualization/data-vizualization.module';
import {ProcessAttributeModule} from './modules/process-attribute/process-attribute.module';
import {FolderModule} from './modules/folder/folder.module';
import {ProcessDialogModule} from './modules/process-dialog/process-dialog.module';
import {ProcessNavigationModule} from './modules/process-navigation/process-navigation.module';
import { ScrollingModule } from '@angular/cdk-experimental/scrolling';

@NgModule({
  imports: [
    ProcessDialogModule,
    DataVizualizationModule,
    ParticipantModule,
    ProcessAttributeModule,
    FolderModule,
    ProcessNavigationModule,
    ScrollingModule
  ],
  declarations: [],
  exports: [
    ProcessDialogModule,
    DataVizualizationModule,
    ParticipantModule,
    ProcessAttributeModule,
    FolderModule,
    ProcessNavigationModule
  ]
})
export class WorkflowEngineModule {
}
