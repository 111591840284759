import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

export enum StatusColor {
  Info = 'info'
}

@Component({
  selector: 'dvtx-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBlockComponent {
  StatusColor = StatusColor;

  @Input() icon = 'info';
  @Input() color: 'info' | 'warn' | null = null;
  @Input() text;
}
