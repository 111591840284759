import {Component, OnDestroy} from '@angular/core';
import {ConfirmationDialogSimpleComponent} from 'app/shared/components/dialogs/confirmation-dialog-simple/confirmation-dialog-simple.component';
import { MatDialog } from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {EmailFooterActions, EmailFooterSelectors} from 'app/+store/email-footer';
import {EmailFooter} from 'app/+store/email-footer/email-footer';
import {Observable, Subject, Subscription} from 'rxjs';
import {OrganizationSelectors, NaturalPersonSelectors} from 'app/+store';
import {takeUntil} from 'rxjs/operators';
import {FooterType} from 'app/+store/email-footer/email-footer.interface';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'dvtx-email-footer-container',
  styleUrls: ['./email-footer-container.scss'],
  templateUrl: './email-footer-container.html',
})
export class EmailFooterContainerComponent implements OnDestroy {
  onDestroy = new Subject();
  emailFooter: EmailFooter[];
  naturalPerson$: Observable<string>;
  emailFooterInfo: { createdAt: string, updatedAt: string };
  actionCreate: boolean;
  footerSubscription: Subscription;
  FooterType = FooterType;
  returnUrl = null;

  constructor(private _dialog: MatDialog,
              private _store: Store<AppState>,
              private route: ActivatedRoute,
              private router: Router) {

              this.emailFooterInfo = null;
              this.emailFooter = null;
              this.footerSubscription = null;
              this.loadFooter();
  }

  loadFooter() {
    this._store.select(OrganizationSelectors.getSelected).pipe(
    takeUntil(this.onDestroy))
    .subscribe((x) => {
      if (x) {
        this._store.dispatch(new EmailFooterActions.LoadAll);
        if (this.footerSubscription) {
          this.footerSubscription.unsubscribe();
        }
        this.selectFooter();
      }
    });

  }

  selectFooter() {
    this.footerSubscription = this._store.select(EmailFooterSelectors.getAllEmailFooters)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((emailFooter: EmailFooter[]) => {
        this.emailFooter = emailFooter;
        if (emailFooter.length > 0) {
          this.naturalPerson$ = this._store.select(NaturalPersonSelectors.getNameByEmail(this.emailFooter[0].creatorEmail));
          this.emailFooterInfo = {
            createdAt: emailFooter[0].createdAt,
            updatedAt: emailFooter[0].updatedAt
          }
          this.actionCreate = false;
        } else {
          this.actionCreate = true;
        }
      });
  }

  submit(footer) {
    if (!this.actionCreate) {
      this._store.dispatch(new EmailFooterActions.Updated(footer));
    } else {
      this._store.dispatch(new EmailFooterActions.Create(footer));
      this.selectFooter();
    }

    if (this.route.snapshot.queryParams['returnUrl']) {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl']
      this.router.navigateByUrl(this.returnUrl + '?origin=emailFooter&status=1');
    }
  }

  formReset(footer) {
    this._dialog.open(ConfirmationDialogSimpleComponent, {
      data: {
        title: 'FOOTER.DELETE_TITLE',
        message: 'FOOTER.DELETE_CONTENT',
        onSubmitAction: () => {
          this._store.dispatch(new EmailFooterActions.Delete(footer.id));
          this.emailFooter = [];
          this.emailFooterInfo = {
            createdAt: null,
            updatedAt: null
          };
          this.actionCreate = true;
        }
      }
    })
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
