import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
  selector: 'dvtx-simple-confirm',
  templateUrl: './simple-confirm.component.html',
  styleUrls: ['./simple-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleConfirmComponent implements OnInit {
  @Input() confirmButtonText = 'GENERAL.DELETE_ACTION';
  @Input() color = 'warn';
  @Input() overlapTrigger = false;
  @Input() xPosition = 'before';
  @Input() disabled = false;
  @Output() onConfirm = new EventEmitter();
  @Input() closeOnConfirm = false;
  @ViewChild('clickMenuTrigger', {static: false}) clickMenuTrigger: MatMenuTrigger;

  constructor() {
  }

  ngOnInit() {
  }

  closeMenu() {
    if (this.closeOnConfirm && this.clickMenuTrigger)
    this.clickMenuTrigger.closeMenu();
  }
}
