<div class="dvtx-address-input-form">
  <div class="d-flex">
    <mat-form-field style="flex: 0 0 79%;">
      <input matInput [disabled]="isDisabled" [(ngModel)]="address.street"
        placeholder="{{ 'CONTACTS.STREET' | translate }}">
    </mat-form-field>
    <mat-form-field style="padding-left: 25px;">
      <input matInput [disabled]="isDisabled" [(ngModel)]="address.streetNo"
        placeholder="{{ 'CONTACTS.STREET_NO' | translate }}">
    </mat-form-field>
  </div>
  <div class="d-flex">
    <mat-form-field style="flex: 0 0 79%;">
      <input matInput [disabled]="isDisabled" [(ngModel)]="address.city" placeholder="{{ 'CONTACTS.CITY' | translate }}">
    </mat-form-field>
    <mat-form-field style="padding-left: 25px;">
      <input matInput [disabled]="isDisabled" [(ngModel)]="address.zip" placeholder="{{ 'CONTACTS.ZIP' | translate }}">
    </mat-form-field>
  </div>
</div>
