import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  DocumentPreviewActionTypes,
  RequestPreviews,
  RequestPreviewsFail,
  RequestPreviewsSuccess,
} from './document-preview.actions';
import {DocumentPreview} from './document-preview';
import {DocumentPreviewService} from './document-preview.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class DocumentPreviewEffects {
  @Effect()
  request$ = this.actions.pipe(
    ofType(DocumentPreviewActionTypes.RequestPreviews),
    switchMap((action: RequestPreviews) => {
      return of([
        new DocumentPreview(1, 1, 'https://testurl'),
        new DocumentPreview(2, 1, 'https://testurl2')
      ])
        // return this._svc.requestPreviews(action.ids)
        .pipe(
          first(),
          concatMap((res: DocumentPreview[]) => {
            return [new RequestPreviewsSuccess(res)];
          }),
          catchError(err => {
            console.error(err);
            return of(new RequestPreviewsFail(err));
          }));
    })
  );

  constructor(private actions: Actions,
              private _svc: DocumentPreviewService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
