import {Action} from '@ngrx/store';
import {Motd} from './motd';

export enum MotdActionTypes {
  Create = '[Motd] Create',
  CreateSuccess = '[Motd] Create Success',
  CreateFail = '[Motd] Create Fail',
  Remove = '[Motd] Remove',
  RemoveSuccess = '[Motd] Remove Success',
  RemoveFail = '[Motd] Remove Fail',
  Save = '[Motd] Save',
  SaveSuccess = '[Motd] Save Success',
  SaveFail = '[Motd] Save Fail',
  Status = '[Motd] Status',
  LoadOne = '[Motd] Load All',
  LoadOneSuccess = '[Motd] Load All Success',
  LoadOneFail = '[Motd] Load All Fail'
}

export class Create implements Action {
  readonly type = MotdActionTypes.Create;

  constructor(public motd: Motd) {
  }
}

export class CreateSuccess implements Action {
  readonly type = MotdActionTypes.CreateSuccess;

  constructor(public motd: Motd) {
  }
}

export class CreateFail implements Action {
  readonly type = MotdActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = MotdActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = MotdActionTypes.RemoveSuccess;

  constructor(public motd: Motd) {
  }
}

export class RemoveFail implements Action {
  readonly type = MotdActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = MotdActionTypes.Save;

  constructor(public motd: Motd) {
  }
}

export class SaveSuccess implements Action {
  readonly type = MotdActionTypes.SaveSuccess;

  constructor(public motd: Motd) {
  }
}

export class SaveFail implements Action {
  readonly type = MotdActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = MotdActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = MotdActionTypes.LoadOneSuccess;

  constructor(public motd: Motd) {
  }
}

export class LoadOneFail implements Action {
  readonly type = MotdActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type MotdActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
