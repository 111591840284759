import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';

@Component({
  selector: 'dvtx-datev-duo-indicator',
  templateUrl: './datev-duo-indicator.component.html',
  styleUrls: ['./datev-duo-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatevDuoIndicatorComponent implements OnInit {

  @Input() textClass = '';

  @Input() onlyIcon = false;

  constructor(
    private _store: Store<AppState>,
  ) {
  }

  ngOnInit() {
  }

}
