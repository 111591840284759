<div class="row mx-1" style="padding: 0.5rem;">
  <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-3 col-xxxl-2 five-f-preview-overview__preview five-f-preview-overview__preview__thumbnail-container" *ngFor="let doc of documents">
<!--  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style="padding: 0rem 0.5rem 1rem 0.5rem;" *ngFor="let doc of documents">-->
    <div class="five-f-preview-overview__preview__description-overlay" (click)="onImageClick(doc)">
      <div class="w-100 five-f-preview-overview__preview__description-overlay__title"
           [matTooltip]="(artifact$(doc.id) | async)?.title">
        <div class="d-flex align-items-center justify-content-center">
          <p style="font-size: 1rem" class="text-truncate">{{ (artifact$(doc.id) | async)?.title }}</p>
        </div>
        <ng-container *ngIf="artifact$(doc.id) | async as document">
          <div class="d-flex align-items-center text-truncate" style="padding-bottom: 5px;" matTooltip="{{ 'DMS.UPLOADED_BY' | translate }}: {{ document.uploaderEmail }}">
            <dvtx-avatar [size]="'xs'" [email]="document?.uploaderEmail"></dvtx-avatar>
            <div class="text-truncate">
              {{ document.uploaderName }}
            </div>
          </div>
        </ng-container>
        <span style="color: #d3d6da">{{ 'DMS.SIZE' | translate }}: {{ (artifact$(doc.id) | async)?.size | dvtxFileSize }}</span><br>
        <span style="color: #d3d6da">{{ 'GENERAL.UPLOADED_AT' | translate }}: {{ (artifact$(doc.id) | async)?.createdAt | date: 'shortDate' }}</span>
      </div>
    </div>

    <div
      style="position: relative;"
      class="w-90 dvtx-pointer"
      [style.paddingTop.%]="calculateImageHeightPercent(doc)"
      (click)="onImageClick(doc)"
    >

      <div
        *ngIf="isNotFoundDocument(doc)"
        class="d-block w-100 h-100 imagelist-inner-position"
      >
        <mat-card style="padding: 0; background-color: transparent; color: #FFFFFF;">
          <mat-card-title style="background-color: transparent; color: #FFFFFF; padding: 1rem;">
<!--            {{ 'PREVIEW_BROWSER.DOCUMENT_NOT_FOUND' | translate }}-->
          </mat-card-title>

          <mat-card-content>
            <p>
              {{ 'PREVIEW_BROWSER.PREVIEWS_NOT_AVAILBLE' | translate }}
            </p>
          </mat-card-content>
        </mat-card>
      </div>

      <div *ngIf="!isNotFoundDocument(doc)"
        class="d-block w-100 h-100 imagelist-inner-position mat-elevation-z2">
        <ng-container *ngIf="doc.pages > 0 && doc.previews.length > 0">
          <img
            class="p-0 m-0 w-100 h-100 dvtx-document-preview-image"
            inViewport
            [inViewportOptions]="{ threshold: 0.0001 }"
            [dvtxLazyPreview]="doc.previews[0]"
            #preview="dvtxLazyPreview"
            src=""
          />
          <dvtx-lazy-preview-loading-skeleton *ngIf="preview.loading"></dvtx-lazy-preview-loading-skeleton>
          <ng-container *ngIf="preview.loaded && !preview.isComplete">
            <mat-card style="padding: 0; background-color: transparent; color: #FFFFFF;">
              <mat-card-title style="background-color: transparent; color: #FFFFFF; padding: 1rem;">
                {{ 'PREVIEW_BROWSER.PREVIEWS_NOT_AVAILBLE' | translate }}
              </mat-card-title>
              <mat-card-content>
                <p>
                  {{ 'PREVIEW_BROWSER.PREVIEW_NOT_AVAILBLE_OR_DAMAGED' | translate }}
                </p>
              </mat-card-content>
            </mat-card>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="doc.pages === 0 || doc.previews.length === 0">
          <mat-card style="padding: 0;">
            <mat-card-title style="background-color: #FF8800; color: #FFFFFF; padding: 1rem;">
              {{ 'PREVIEW_BROWSER.PREVIEWS_NOT_AVAILBLE' | translate }}
            </mat-card-title>
            <mat-card-content>
              <p>
                {{ 'PREVIEW_BROWSER.PREVIEW_NOT_AVAILBLE_OR_DAMAGED' | translate }}
              </p>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </div>
    </div>
  </div>
</div>
