import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TooltipModule} from 'app/five-f/tooltip/tooltip.module';
import {ColorPickerDropdownComponent} from './components/color-picker-dropdown/color-picker-dropdown.component';
import {TranslateModule} from '@ngx-translate/core';
import {CategoryColorPickerComponent} from './components/category-color-picker/category-color-picker.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    TooltipModule
  ],
  declarations: [
    ColorPickerDropdownComponent,
    CategoryColorPickerComponent
  ],
  exports: [
    ColorPickerDropdownComponent,
    CategoryColorPickerComponent
  ]
})
export class ColorPickerDropdownModule {
}
