import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {DvtxControlValueAccessor} from 'app/shared/modules/base-form-elements/components/1_control-value-accessor-components/DvtxControlValueAccessor';
import {EmojiService} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {Subject} from 'rxjs/internal/Subject';
import { environment } from 'environments/environment';

declare let $: any; // The Froala instance will be attached to the $ variable

@Component({
  selector: 'dvtx-message-editor',
  templateUrl: './message-editor.component.html',
  styleUrls: ['./message-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MessageEditorComponent),
      multi: true,
    }
  ]
})
export class MessageEditorComponent extends DvtxControlValueAccessor implements OnDestroy, OnInit {
  protected onDestroy: Subject<any> = new Subject();
  private _message: string = '';
  private _froalaInstance: any;
  dasError: boolean = false;
  fileError: boolean = false;
  showEmojiMenu = false;
  DEFAULT_OPTIONS;

  _options;
  @Input() optimizeToolbar = false;

  get options() {
    return Object.assign({}, this.DEFAULT_OPTIONS, {
      placeholderText: this.editor.nativeElement.placeholder,
      heightMax: this.heightMax
    }, this._options);
  }

  @ViewChild('editor', { static: true }) editor: ElementRef;

  @Input() height = 100;
  @Input() heightMax = 100;

  private _messagePrefixExists = false;
  private _messageInitialized = false;

  @Input() set message(msg: string) {
    // Check if previously a prefix was set by @Input: Do not override a prefix if it was set first:
    if (this._messagePrefixExists && this._message && (!msg || msg.length === 0)) {
      this._message = `${this._message}${msg}`;
      this._messagePrefixExists = false;
      this._messageInitialized = true;
    } else {
      this._message = msg;
      // Mark for eventually setting a prefix, that messsage @Input was already set. Everything's fine.
      this._messageInitialized = true;
    }
    this.notifyOnChange(msg);
    this.notifyOnTouch();
  }

  get message(): string {
    return this._message;
  }

  @Input() placeholder = 'MESSAGING.PLACEHOLDER';

  @Output() onSend: EventEmitter<any> = new EventEmitter<any>();

  @Input() set commentPrefix(prefix) {
    if (prefix) {
      this._message = `${prefix}<br>`;
      // @Input() message came first if this._messageInitialized
      this._messagePrefixExists = !this._messageInitialized;
      this.notifyOnChange(this._message);
      this.notifyOnTouch();
    }
  }

  constructor(private _emojiSvc: EmojiService, private _cdr: ChangeDetectorRef) {
    super();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnInit() {
    this.initOptions();
  }

  writeValue(value): void {
    if (value !== undefined) {
      this.message = value;
      this.notifyOnChange(this.message);
    }
  }

  toggleEmojiMenu() {
    this.showEmojiMenu = !this.showEmojiMenu;
    if (this.showEmojiMenu) {
      this.editor.nativeElement.blur();
    }
  }

  insertEmoji($event) {
    const styles = this._emojiSvc.emojiSpriteStyles($event.emoji.sheet);
    const el = document.createElement('span');
    Object.assign(el.style, styles);
    this.insertAtMarker($event.emoji.native);
  }

  initializeLink(controls) {
    controls.initialize();
    this._froalaInstance = controls.getEditor();
  }

  insertAtMarker(value: string) {
    const html: string = this._froalaInstance.html.get(true);
    const index = html.search(/<span class=.fr-marker.*?>.*?<\/span>/)
    const newHtml = index === -1 ? `${html}${value}` : [html.slice(0, index), value, html.slice(index)].join('');
    this._froalaInstance.html.set(newHtml);
    this.message = newHtml;
  }

  send() {
    this.onSend.emit(this.message.replace(/\n/g, '<br>'));
    this.message = '';
  }

  initOptions() {
    const optimizedToolbar = [['undo', 'redo', 'bold', 'italic', 'underline']];
    const self = this;
    this.DEFAULT_OPTIONS = {
      key: environment.froalaKey,
      attribution: false,
      toolbarButtons: this.optimizeToolbar ? optimizedToolbar : [['undo', 'redo', '|', 'fontSize', 'fontFamily', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'outdent', 'indent', 'clearFormatting', 'insertTable', 'html']], // 'insertVideo',
      toolbarButtonsXS: [['undo', 'redo', '-', 'bold', 'italic', 'underline']],
      // videoResponsive: true,
      // videoInsertButtons: ['videoByURL', 'videoEmbed'],
      charCounterCount: false,
      quickInsertTags: null,
      fontSizeDefaultSelection: '14',
      heightMin: this.height,
      heightMax: this.heightMax,
      zIndex: 9999,
      enter: $.FE?.ENTER_BR,
      imageMaxSize: 200 * 1024,
      fileAllowedTypes: ['*'],
      fileMaxSize: 20 * 1024 * 1024,
      fileUpload: true,
      draggable: true,
      events : {
        'image.beforeUpload' : function(files) {
          self.fileError = false;
          const editor = this;
          if (files.length) {
            const reader = new FileReader();
            reader.onload = function (event: any) {
              const result = event.target.result;
              editor.image.insert(result, null, null, editor.image.get());
            };
            let dasFiles = files[0];
            // if image is copy pasted convert blob to file
            if (dasFiles && dasFiles.type && !dasFiles.name) {
              // A Blob() is almost a File() - it's just missing the two properties below which we will add
              // Cast to a File() type
              dasFiles = new File([dasFiles], 'name', {type: dasFiles.type.replace(';base64', '')});
            }
            const imageSize = dasFiles.size;
            if (imageSize > 100000) {  // around 100KB
              self.dasError = true;
              editor.image.remove();
            } else {
              reader.readAsDataURL(dasFiles);
              self.dasError = false;
            }
          }
          editor.popups.hideAll();
          self._cdr.detectChanges();
          return false;
        },
        'file.beforeUpload' : function(files) {
          const editor = this;
          self.fileError = true;
          self.dasError = false;
          editor.popups.hideAll();
          self._cdr.detectChanges();
          return false;
          let dasFiles = files[0];
          if (files.length) {
            const reader = new FileReader();
            reader.onload = function (event: any) {
              const result = event.target.result;
              editor.file.insert(result, dasFiles.name , {result: result});
            };
            // if image is copy pasted convert blob to file
            if (dasFiles && dasFiles.type && !dasFiles.name) {
              // A Blob() is almost a File() - it's just missing the two properties below which we will add
              // Cast to a File() type
              dasFiles = new File([dasFiles], 'name', {type: dasFiles.type.replace(';base64', '')});
            }
            const imageSize = dasFiles.size;
            if (imageSize > 2000000) {  // around 20MB
              self.dasError = true;
              editor.file.remove();
            } else {
              reader.readAsDataURL(dasFiles);
              self.dasError = false;
            }
          }
          editor.popups.hideAll();
          return false;
        },
        'froalaEditor.initialized': (_e, editor) => {
          this._froalaInstance = editor;
        },
        'blur': () => {
          this._froalaInstance.selection.save();
        },
        'focus': () => {
          // This is done to remove any invisible space when focusing in an empty editor
          if (this._froalaInstance.html.get(true) === '') {
            this._froalaInstance.html.set('');
          }
        }
      }
    };
  }
}
