import * as mainHeaderActions from '../actions/main-header.actions';

// the interface that describes user part of the app state
export interface MainHeaderState {
  height: number;
}

// initial user state
export const initialState: MainHeaderState = {
  height: null
};

export function mainHeaderReducer(state: MainHeaderState = initialState, action: mainHeaderActions.Actions): MainHeaderState {

  switch (action.type) {

    case mainHeaderActions.GET_MAIN_HEADER_HEIGHT:
        return { ...state, height: action.payload };

    default: {
      return state;
    }

  }

};
