import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {VerifiedUser} from './verified-user';

export interface State extends EntityState<VerifiedUser> {
  current?: VerifiedUser
  loading: boolean
}

export const adapter = createEntityAdapter<VerifiedUser>();

export const initialState: State = adapter.getInitialState({
  current: null,
  loading: false,
});
