import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './contact-person.state';

export const stateKey = 'contact-persons';
const getContactPersonsState = createFeatureSelector<State>(stateKey)

export const {
  selectEntities: getPersonContactEntities,
  selectAll: getAllPersonContacts,
} = adapter.getSelectors(getContactPersonsState);

export const getContactsLoading = createSelector(
  getContactPersonsState,
  state => state.loading
);

export const getPersonContactById = (contactId: string) => createSelector(
  getPersonContactEntities,
  (entities) => entities[contactId]
);

/**
 * @deprecated Use a selector from `contact.selector`
 * @param contactId
 */
export const getAssignedOrganizations = (contactId: string ) => createSelector(
  getPersonContactEntities,
  (entities) => {
      return entities[contactId].assignedOrganizations
  }
);
