import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { ApiModule } from 'app/shared/modules/api/api.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { StatusSelectorComponent } from './component/status-selector.component';
import { TooltipModule } from 'app/five-f/tooltip/tooltip.module';

@NgModule({
    imports: [
        ApiModule,
        OverlayModule,
        CommonModule,
        MatButtonModule,
        MatIconModule,
        TooltipModule
    ],
    declarations: [
        StatusSelectorComponent
    ],
    providers: [],
    exports: [
        StatusSelectorComponent
    ]
})
export class StatusSelectorModule {
}
