import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {MinimalProcess} from './process-lookup';
import {Sorters} from 'app/lib/sorter/sorters';

export interface State extends EntityState<MinimalProcess> {
  loading: boolean;
  sortComparer: any;
}

export const adapter = createEntityAdapter<MinimalProcess>({
  sortComparer: (l, r) => initialState.sortComparer(l, r)
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  sortComparer: (l: MinimalProcess, r: MinimalProcess) => Sorters.sortByDates(l.createdAt, r.createdAt, false)
});
