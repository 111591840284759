import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ExternalAccess} from './external-access';

export interface State extends EntityState<ExternalAccess> {
  selected?: ExternalAccess;
  loading: boolean;
}

export const adapter = createEntityAdapter<ExternalAccess>();

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
