import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {BookmanServiceAccount} from './bookman-service-account';

export class BookmanServiceAccountBuilder implements IApiResourceBuilder<BookmanServiceAccount> {

  fromResponse(data): BookmanServiceAccount {
    return new BookmanServiceAccount(
      data.id,
      data.attributes.email,
      data.attributes.created_at
    );
  }

  toRequest(_: BookmanServiceAccount) {
    return null;
  }
}
