import {IResource} from 'app/shared/modules/api/models/resource.interface';
import {ProcessParticipant} from 'app/+store/process-participant/process-participant';
import {Client} from '../client/client';

export interface IRedirectUrl {
  type: string;
  url: string;
}

export enum ProcessType {
  Process = 'workflow_engine_processes'
}

export interface IProcessPropertyRequest {
  title?: string;
  subtitle?: string;
  description?: string;
  due_date?: string;
  color?: string;
  client_uuid?: string;
}

export interface IProcessStatistics extends IResource {
  all: number;
  open: number;
  closed: number;
  allTaskCount: number;
  openTaskCount: number;
  closedTaskCount: number;
}

/**
 * Command interface for API driven process control flows.
 */
export interface IProcessCommand {
  /**
   * Executes the given actions in the context of a command.
   *
   * Returns false, if the control flow should stop after executions,
   * true, if the control flow should continue.
   *
   * @returns {boolean} true, if control flow is allowed to continue, false otherwise.
   */
  execute(): boolean;
}

export enum ProcessProfile {
  StandardProfile = 'standard_profile',
  AuditingProfile = 'auditing_profile',
  ThirdPartyClaimsBalance = 'third_party_claim_balance',
  ThirdPartyClaimsInvoice = 'third_party_claim_invoice',
  ThirdPartyLiabilityBalance = 'third_party_liability_balance',
  ThirdPartyLiabilityInvoice = 'third_party_liability_invoice',
  Fibu = 'fibu',
  DatevDuo = 'datev_duo'
}

/**
 * Static type and utility information for the handling of command
 * queues providing hooks for API driven control flows.
 */
export namespace CommandQueue {
  /**
   * Contains all event hook names delivered by the API and
   * used as names in the command queues.
   */
  export type CommandQueueName = 'on_init'
    | 'after_validation'
    | 'on_click'
    | 'on_create'
    | 'on_go_back'
    | 'on_submit'
    | 'on_update'
    | 'after_submit';

  /**
   * Returns the command queue hook names as iterable list.
   *
   * @returns {Array<CommandQueue.CommandQueueName>}
   */
  export function defaultQueues(): Array<CommandQueue.CommandQueueName> {
    return ['on_init', 'after_validation', 'on_click', 'on_create',
      'on_go_back', 'on_submit', 'on_update', 'after_submit'];
  }
}

export interface IProcessStatus {
  code: string;
  infoLevel: string;
  icon: string;
  message: string;

  isClosed(): boolean;

  isOpen(): boolean;
}

export interface IFiveFStatus {
  id: string;
  title: string;
  icon?: string;
  color?: string;
  isSVGIcon?: boolean;
}

export interface IMatIconSupport {
  isSvgIcon: boolean;
  icon: string;
}

export interface IUploadProcessConfiguration {
  supportedFileTypes: string[];
  maxFileSize: number;
  uploadInformation: string;
  uploadInfoTooltip: boolean;
  enableDmsFilePicker: boolean;
}

export interface IProcess extends IResource {
  title: string;
  subtitle: string;
  description: string;
  dueDate: Date;
  startedAt: Date;
  completedAt: Date;
  startsAt: Date;
  endsAt: Date;
  createdAt: Date;
  updatedAt: Date;
  color: string;
  processType: string;
  status: IProcessStatus;
  parentId: string;
  orgName?: string;
  organizationId: string;
  favorite: boolean;
  client: Client;
  roles: string[];
  participants?: ProcessParticipant[];
  dmsFolderId?: string;
  dmsAccountType?: string;
  syncDmsFolder: boolean;
  profile: ProcessProfile;
  auditProofUntil: Date;
  lastUpdatedByEmail?: string;
  // ATTENTION: (a.baier@5fsoftware.de)
  // This is experimental, not extensively tested and lastUpdatedByName could be null.
  // Prefer lastUpdatedByEmail for now.
  lastUpdatedByName?: string;

  // Pagination support
  total?: number;
  perPage?: number;
  records?: number;

  /**
   * Adds a command to the event queue identified by <tt>hook</tt>.
   * Returns the altered process context.
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @param {IProcessCommand} command
   * @returns {IProcess}
   */
  addCommand(hook: CommandQueue.CommandQueueName, command: IProcessCommand): IProcess;

  /**
   * Runs the commands in the event queue identified by <tt>hook</tt>.
   * The iterated calling stops if a command returns false.
   *
   * Returns the remaining commands in queue.
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @returns {Array<IProcessCommand>}
   */
  runCommands(hook: CommandQueue.CommandQueueName): Array<IProcessCommand>;

  /**
   * Sets a new event queue for commands of the type <tt>hook</tt>.
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @param {Array<IProcessCommand>} queue
   */
  setQueue(hook: CommandQueue.CommandQueueName, queue: Array<IProcessCommand>): void;

  /**
   * Returns the commands in the event queue identified by <tt>hook</tt>.
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @returns {Array<IProcessCommand>}
   */
  getQueue(hook: CommandQueue.CommandQueueName): Array<IProcessCommand>;
}

export interface IProcessSortingBy {
  title: string,
  comparer: any
}

export interface IProcessV3Query {
  workflows?: string[];
  participants?: string[];
  client?: string;
  clients?: string[];
  organizations?: string[];
  status?: string[];
  search?: string;
  parent_id?: string;

  /**
   * Request processes with overridden notification settings.
   */
  ovns?: boolean;
}
