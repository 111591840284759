import {Action} from '@ngrx/store';
import {FibuBookmanAccount} from './fibu-bookman-account';

export enum FibuBookmanAccountActionTypes {
  SignInToFibuBookmanAccount = '[FibuAccount] Sign In To Fibu Bookman Account ',
  SignInToFibuBookmanAccountSuccess = '[FibuAccount] Sign In To Fibu Bookman Account  Success',
  SignInToFibuBookmanAccountFail = '[FibuAccount] Sign In To Fibu Bookman Account  Fail',
  SignOutFromFibuBookmanAccount = '[FibuAccount] Sign Out From Fibu Bookman Account ',
  SignOutFromFibuBookmanAccountSuccess = '[FibuAccount] Sign Out From Fibu Bookman Account  Success',
  SignOutFromFibuBookmanAccountFail = '[FibuAccount] Sign Out From Fibu Bookman Account  Fail',
  GetFibuBookmanAccount = '[FibuAccount] Get Fibu Bookman Account ',
  GetFibuBookmanAccountSuccess = '[FibuAccount] Get Fibu Bookman Account  Success',
  GetFibuBookmanAccountFail = '[FibuAccount] Get Fibu Bookman Account  Fail'
}

export class SignInToFibuBookmanAccount implements Action {
  readonly type = FibuBookmanAccountActionTypes.SignInToFibuBookmanAccount;

  constructor(public payload: any) { }
}

export class SignInToFibuBookmanAccountSuccess implements Action {
  readonly type = FibuBookmanAccountActionTypes.SignInToFibuBookmanAccountSuccess;

  constructor(public payload: FibuBookmanAccount) {
  }
}

export class SignInToFibuBookmanAccountFail implements Action {
  readonly type = FibuBookmanAccountActionTypes.SignInToFibuBookmanAccountFail;

  constructor(public payload: any = null) { }
}

export class SignOutFromFibuBookmanAccount implements Action {
  readonly type = FibuBookmanAccountActionTypes.SignOutFromFibuBookmanAccount;

  constructor() { }
}

export class SignOutFromFibuBookmanAccountSuccess implements Action {
  readonly type = FibuBookmanAccountActionTypes.SignOutFromFibuBookmanAccountSuccess;

  constructor(public payload: FibuBookmanAccount) { }
}

export class SignOutFromFibuBookmanAccountFail implements Action {
  readonly type = FibuBookmanAccountActionTypes.SignOutFromFibuBookmanAccountFail;

  constructor(public payload: any = null) { }
}

export class GetFibuBookmanAccount implements Action {
  readonly type = FibuBookmanAccountActionTypes.GetFibuBookmanAccount;

  constructor() { }
}

export class GetFibuBookmanAccountSuccess implements Action {
  readonly type = FibuBookmanAccountActionTypes.GetFibuBookmanAccountSuccess;

  constructor(public payload: FibuBookmanAccount) {
  }
}

export class GetFibuBookmanAccountFail implements Action {
  readonly type = FibuBookmanAccountActionTypes.GetFibuBookmanAccountFail;

  constructor(public payload: any = null) { }
}

export type FibuBookmanAccountActions =
  GetFibuBookmanAccount
  | GetFibuBookmanAccountSuccess
  | GetFibuBookmanAccountFail
  | SignInToFibuBookmanAccount
  | SignInToFibuBookmanAccountSuccess
  | SignInToFibuBookmanAccountFail
  | SignOutFromFibuBookmanAccount
  | SignOutFromFibuBookmanAccountSuccess
  | SignOutFromFibuBookmanAccountFail;
