import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Label} from './label';

export interface State extends EntityState<Label> {
  selected?: Label;
  loading: boolean;
}

export const adapter = createEntityAdapter<Label>({});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
