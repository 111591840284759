import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {AngularTokenService} from 'angular-token';
import {filter, first, map} from 'rxjs/operators';
import {MembershipActions, MembershipSelectors} from 'app/+store/membership';
import {OrganizationSelectors} from 'app/+store/organization';

@Injectable()
export class CanActivateAdminRights implements CanActivate {
  constructor(private _store: Store<AppState>,
              private _authTokenService: AngularTokenService,
              private _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Routes related to administrational actions always require an organization, so we must wait here until it is present.
    // Users without organization would not get to this route by link.
    this._store.select(OrganizationSelectors.getSelectedId)
      .pipe(filter(o => !!o), first())
      .subscribe(orgId => {
        this._store.dispatch(new MembershipActions.LoadAll(orgId))
      });
    // We must filter here or we get logout scenarios if the organization or membership was just not loaded, yet e.g. on refresh.
    return this._store.select(MembershipSelectors.getMyMembership)
      .pipe(filter(m => !!m), first(), map(ms => !!(ms && ms.hasAdministrationRights)))
  }
}

