import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './natural-person.state';
import * as MembershipSelectors from '../membership/membership.selectors'
import {OrganizationSelectors} from '../organization'
import {LicenceAssignmentSelectors} from '../licence-assignment'
import {ContactSelectors} from '../contact';
import {contactListDtoType} from '../../models/contact-list-dto.model';
import { ProcessParticipantSelectors } from '../process-participant';
import {StringUtils} from '../../lib/string_utils/functions';
import { EmailToNameSelectors } from '../email-to-name';

export const stateKey = 'naturalPerson';
export const getNaturalPersonState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getNaturalPersonEntities,
  selectAll: getAllNaturalPersons,
} = adapter.getSelectors(getNaturalPersonState);

export const getNaturalPersonsLoading = createSelector(
  getNaturalPersonState,
  state => state.loading
);

export const getUsersNaturalPersonId = createSelector(
  getNaturalPersonState,
  state => state.my
);

export const getUsersNaturalPersonObject = createSelector(
  getNaturalPersonState,
  state => state.myNaturalPerson
);

export const getUsersNaturalPerson = createSelector(
  getAllNaturalPersons,
  getUsersNaturalPersonId,
  getUsersNaturalPersonObject,
  (entities, id, _person) => {
    return entities.find(entity => entity.id === id)
  }
);

export const getNaturalPersonsOfSelectedOrganization = createSelector(
  MembershipSelectors.getMembershipsOfSelectedOrg,
  getNaturalPersonEntities,
  (memberships, entities) => memberships.map(m => {
    const member = entities[m.naturalProfileId];
    if (member) {
      member['membershipId'] = m.id;
    }
    return member;
  }).filter(x => x !== undefined)
)

export const getMembershipsOfSelectedOrgIncludingNatPersons = createSelector(
  OrganizationSelectors.getSelectedId,
  MembershipSelectors.getMembershipsOfSelectedOrg,
  LicenceAssignmentSelectors.getAllLicenceAssignments,
  getNaturalPersonEntities,
  (id, memberships, assignments, entities) => memberships.map(m => {
    const res = {
      oid: id,
      membership: m,
      natPerson: entities[m.naturalProfileId],

      hasKapLicence: false,
      kapAssignment: null,
      hasJapLicence: false,
      japAssignment: null,
      hasTeamLicence: false,
      teamAssignment: null,
      hasProfLicence: false,
      profAssignment: null
    };
    const licenceAssignments = assignments.filter(a => a.membershipId === m.id);
    for (const assignment of licenceAssignments) {
      if (assignment.hasKapLicence) {
        res.hasKapLicence = assignment.hasKapLicence;
        res.kapAssignment = assignment;
      }

      if (assignment.hasJapLicence) {
        res.hasJapLicence = assignment.hasJapLicence;
        res.japAssignment = assignment;
      }

      if (assignment.hasProfLicence) {
        res.hasProfLicence = assignment.hasProfLicence;
        res.profAssignment = assignment;
      }

      if (assignment.hasTeamLicence) {
        res.hasTeamLicence = assignment.hasTeamLicence;
        res.teamAssignment = assignment;
      }
    }
    return res;
  }).filter(x => x.natPerson !== undefined)
)

export const getById = (id: string) => createSelector(
  getNaturalPersonEntities,
  entities => entities[id]
)

export const getNameByEmailMap = createSelector(
  getAllNaturalPersons,
  ContactSelectors.getAllContacts,
  EmailToNameSelectors.getAllEmailToName,
  (people, contacts, emailToNames) => {
    const nameByEmailMap = {}

    emailToNames.forEach(emailToName => {
      const email = StringUtils.normalizeString(emailToName.email);
      if (!nameByEmailMap[email] && emailToName.firstName && emailToName.lastName) {
        nameByEmailMap[email] = `${emailToName.firstName} ${emailToName.lastName}`;
      }
    })

    people.forEach(p => {
      const email = StringUtils.normalizeString(p.mainEmailAddress.emailAddress)
      if (!nameByEmailMap[email] && p.firstName && p.lastName) {
        nameByEmailMap[email] = `${p.firstName} ${p.lastName}`;
      }
    });

    contacts.forEach(c => {
      if (c.type === contactListDtoType.Membership || c.type === contactListDtoType.naturalPersonContact) {
        const email = StringUtils.normalizeString(c.email);
        if (!nameByEmailMap[email]) {
          const [lastName, firstName] = c.name.split(', ');
          if (lastName && firstName) {
            nameByEmailMap[email] = `${firstName} ${lastName}`;
          }
        }
      }
    });
    return nameByEmailMap;
  }
)

export const getNameByEmail = (email: string) => createSelector(
  getNameByEmailMap,
  (nameByEmailMap) => nameByEmailMap[email] || email
)

export const getByEmail = (email: string) => createSelector(
  getAllNaturalPersons,
  ContactSelectors.getAllContacts,
  (people, contacts) => {

    const person = people.find(p => p.mainEmailAddress && matchingEmails(p.mainEmailAddress.emailAddress, email));
    console.log(person);
    if (person) {
      return person
    } else {
      const contact: any = contacts.find(c => matchingEmails(c.email, email) && (c.type === contactListDtoType.naturalPersonContact));
      if (contact) {
        contact.hasAccount = contactListDtoType.Membership;
        return contact
      } else {
        return {
          email: email
        };
      }
    }
  }
);

export const getPersonByEmail = (email: string) => createSelector(
  getAllNaturalPersons,
  ContactSelectors.getContactsOfSelectedOrg,
  (people, contacts) => {
    const contact: any = contacts.find(c => matchingEmails(c.email, email) && (c.type === contactListDtoType.Membership || c.type === contactListDtoType.naturalPersonContact));
    if (contact) {
      const person: any = people.find(p => p.mainEmailAddress && matchingEmails(p.mainEmailAddress.emailAddress, contact.email));
      if (person) {
        if (contact.hasAccount) {
          person.hasAccount = contact.hasAccount;
        }
        person.isMember = true;
        return person;
      } else {
        return {
          email: email
        };
      }
    } else {
      return {
        email: email
      }
    }
  }
);

const matchingEmails = (email, other): boolean => {
  return StringUtils.simpleCompare(email, other);
};
