import { IApiResourceBuilder } from 'app/shared/modules/api-resource/models/api.interface';
import { TaskStatusScheme } from './task-status-scheme';
import { TaskStatus } from 'app/+store/task-status/task-status';

export class TaskStatusSchemeBuilder implements IApiResourceBuilder<TaskStatusScheme> {
  schemeStatuses: { [id: string]: TaskStatus[] } = {};

  addIncludedSection(includes) {
    const taskStatusSchemeBuilder = new TaskStatusSchemeBuilder();
    if (includes && includes.length) {
      includes.forEach(include => {
        this.createIncludeModel(include.type, include);
      });
    }
  }

  createIncludeModel(type: string, include) {
    const attrs = include.attributes;
    switch (type) {
      case 'task_statuses':
        const status = new TaskStatus(include.id, attrs.title, attrs.color, attrs.order,
          attrs.status_scheme, attrs.created_at, attrs.updated_at);

        if (!this.schemeStatuses[attrs.status_scheme]) {
          this.schemeStatuses[attrs.status_scheme] = [];
        }
        this.schemeStatuses[attrs.status_scheme].push(status);
        break;
    }
  }

  fromResponse(data): TaskStatusScheme {
    const status = new TaskStatusScheme(
      data.id,
      data.attributes.title,
      data.attributes.description,
      data.attributes.task_type,
      this.schemeStatuses[data.id] || [],
      data.attributes.default,
      data.attributes.published,
      data.attributes.creator_email,
      data.attributes.modified_by_email,
      data.attributes.published_at,
      data.attributes.created_at,
      data.attributes.updated_at);
    return status;
  }

  toRequest(scheme: TaskStatusScheme) {
    return {
      data: {
        id: scheme.id,
        type: 'task_status_schemes',
        attributes: {
          default: scheme.isDefault,
          published: scheme.isPublished,
          description: scheme.description,
          title: scheme.title,
          task_type: scheme.taskType
        }
      }
    }
  }
}
