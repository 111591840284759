import {Injectable} from '@angular/core';
import {EnvService} from 'app/shared/modules/api-resource/services/env.service';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '../../shared/modules/notification/services/notification.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {first} from 'rxjs/operators';
import {DatevClient} from '../datev/datev-client/datev-client';
import {DatevClientService} from '../datev/datev-client/datev-client.service';
import {Observable} from "rxjs";


export interface DatevUserInfo {
  sub: string;
  name?: string;
  given_name?: string;
  family_name?: string;
  email?: string;
  email_verified?: boolean;
}

export enum DatevScope {
  Default = 'default',
  Client = 'client',
}

export enum DatevTokenType {
  StandardToken = 'standard_token',
  LongRunningClientBasedToken = 'long_running_client_based_token',
}

@Injectable({
  providedIn: 'root'
})
export class DatevOauthService {
  private basePath;

  private loading$ = new BehaviorSubject<boolean>(false);
  public loading = this.loading$.asObservable();

  private userInfo$ = new BehaviorSubject<DatevUserInfo>(null);
  public userInfo = this.userInfo$.asObservable();

  private signedIn$ = new BehaviorSubject<boolean>(false);
  public signedIn = this.signedIn$.asObservable();

  private currentClient$ = new BehaviorSubject<DatevClient>(null);
  public currentClient = this.currentClient$.asObservable();

  private clientLoading$ = new BehaviorSubject<boolean>(false);
  public clientLoading = this.clientLoading$.asObservable();

  constructor(private env: EnvService,
              private http: HttpClient,
              private datevClientSvc: DatevClientService,
              private notifyService: NotificationService) {
    this.basePath = env.apiBase() + '/api/v1/datev';
  }

  public login(redirectUrl: string, tokenType: DatevTokenType = DatevTokenType.StandardToken, clientId = null): void {
    this.loading$.next(true);

    const params = {
      data: {
        attributes: {
          token_type: tokenType,
          client_id: clientId,
          redirect_url: redirectUrl
        }
      }
    };

    this.http.post(`${this.basePath}/login`, params)
      .pipe(first())
      .subscribe((res: any) => {
        window.open(res.data.attributes.redirect_uri, '_self');
      }, err => {
        console.error('An error occurred: ', err);
        this.resetStatus();
      });
  }

  public me(scope: DatevScope = DatevScope.Default, clientId: string = null): void {
    this.resetStatus();
    this.loading$.next(true);

    let url = `${this.basePath}/me?scope=${scope}`;
    if (scope === DatevScope.Client) {
      url = `${url}&client_id=${clientId}`;
    }
    this.http.get(url)
      .pipe(first())
      .subscribe(res => {
        if (res) {
          this.signedIn$.next(true);
          this.userInfo$.next(res as DatevUserInfo);
          this.loading$.next(false);

          if (clientId) {
            this.fetchAuthorizedClient(clientId);
          }
        }
      }, err => {
        this.loading$.next(false);
      });
  }

  fetchAuthorizedClient(clientId: string): void {
    this.currentClient$.next(null);
    this.clientLoading$.next(true);

    this.datevClientSvc.getClientInfo(clientId)
      .pipe(first())
      .subscribe(client => {
        this.clientLoading$.next(false);
        this.currentClient$.next(client);
      }, err => {
        this.clientLoading$.next(false);
      });
  }

  /**
   * TODO: This must only reset the according token or alternatively all tokens of the user.
   *       Affects client and general tokens.
   */
  public logout(): void {
    this.loading$.next(true);

    this.http.delete(`${this.basePath}/logout`)
      .pipe(first())
      .subscribe(res => {
        if (res) {
          this.resetStatus();
        }
      });
  }

  public logoutClient(clientId = null) {
    this.loading$.next(true);
    this.clientLoading$.next(true);

    let params = '';
    if (clientId) {
      params = `?client_id=${clientId}`;
    }

    return this.http.delete(`${this.basePath}/logout${params}`)
      .pipe(first())
      .subscribe(res => {
        this.clientLoading$.next(false);
        this.fetchAuthorizedClient(clientId);
      }, err => {
        this.clientLoading$.next(false);
        this.fetchAuthorizedClient(clientId);
      });
  }

  private resetStatus(): void {
    this.signedIn$.next(false);
    this.userInfo$.next(null);
    this.loading$.next(false);
    this.clientLoading$.next(false)
  }
}
