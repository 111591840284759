import {IQuickshareRecipient, RecipientType} from './quickshare-recipient.interface';
import {ProcessParticipantType} from '../process-participant/process-participant.interface';
import { SimplePhoneNumber } from 'app/modules/contacts/models/contact.interface';

export class QuickshareRecipient implements IQuickshareRecipient {
  readonly type = 'workflow_engine_quickshares';
  processParticipantType: ProcessParticipantType;
  isMember = false;
  isVerified = false;
  firstName: string;
  lastName: string;

  constructor(public id: string,
              public recipientType: RecipientType,
              public email: string,
              public quickshareId: string,
              public notifyBySms: boolean,
              public phoneNumber: string,
              public passwordEnabled: boolean,
              public createdAt: Date = null,
              public updatedAt: Date = null,
              public simplePhoneNumber?: SimplePhoneNumber) {
  }
}
