import { NgModule } from '@angular/core';
import { CartOverviewComponent } from './components/cart-overview/cart-overview.component';
import { SharedModule } from 'app/shared/shared.module';
import { LicenceSelectionComponent } from './components/licence-selection/licence-selection.component';
import { LicenseBuyingComponent } from './containers/license-buying/license-buying.component';
import { PaymentSelectionComponent } from './components/payment-selection/payment-selection.component';
import { InvoiceAddressComponent } from './components/invoice-address/invoice-address.component';
import { CartDetailsComponent } from './components/cart-details/cart-details.component';
import { LicenseAssignmentTableComponent } from './components/license-assignment-table/license-assignment-table.component';

/**
 * Sales und shopping cart related components.
 */
@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        CartOverviewComponent,
        InvoiceAddressComponent,
        LicenceSelectionComponent,
        LicenseBuyingComponent,
        PaymentSelectionComponent,
        CartDetailsComponent,
        LicenseAssignmentTableComponent
    ],
    exports: [
        LicenseBuyingComponent,
        LicenseAssignmentTableComponent
    ]
})
export class SalesModule { }
