import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Society} from './society';

export interface State extends EntityState<Society> {
  loading: boolean;
}

export const adapter = createEntityAdapter<Society>({
  sortComparer: (l, r) => Society.sortByCountry(l, r)
});

export const initialState: State = adapter.getInitialState({
  loading: false
});
