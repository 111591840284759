<ng-container *ngIf="isButton; else link">
  <button
    mat-icon-button
    cdk-overlay-origin
    (click)="showDropdownDialog()"
    #dropdown="cdkOverlayOrigin"
    [disabled]="buttonDisabled"
    [disableRipple]='true'>
    <ng-container [ngSwitch]="selected">
      <ng-container *ngSwitchCase="null">
        <span class="button--content" [style.color]="'black'">{{'GENERAL.STATUS' | translate}}</span>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngIf="multipeSelection">
          <ng-container *ngIf="!selected || !selected?.length">
            {{'GENERAL.STATUS' | translate}}
          </ng-container>
          <ng-container *ngFor="let selectedItem of selected">
            <dvtx-tooltip [tooltipText]="selected?.length < 3 ? '' : (selectedItem?.title | translate)" [position]="'top left'" class="w-100">
              <span class="button--content" [style.background]="'#' + selectedItem?.color">
                <ng-container *ngIf="selected?.length < 3">
                  <mat-icon style="color: white !important;" *ngIf="selectedItem?.icon" class="mr-1">{{selected?.icon}}</mat-icon>
                  <ng-container *ngIf="!iconOnly">{{selectedItem?.title | translate}}</ng-container>
                </ng-container>
              </span>
            </dvtx-tooltip>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!multipeSelection">
          <span class="button--content" [style.background]="'#' + selected?.color">
            <mat-icon style="color: white !important;" *ngIf="selected?.icon" [class.mr-1]="!iconOnly" [class.m-0]="iconOnly">{{selected?.icon}}</mat-icon>
            <ng-container *ngIf="!iconOnly">{{selected?.title | translate}}</ng-container>
          </span>
        </ng-container>
      </ng-container>
    </ng-container>
  </button>
  <ng-template cdk-connected-overlay
               [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
               [cdkConnectedOverlayOrigin]="dropdown"
               [cdkConnectedOverlayHasBackdrop]="true"
               [cdkConnectedOverlayOpen]="showDropdown && !disabled"
               (backdropClick)="showDropdown = false">
    <div class="mat-elevation-z8 list--container p-1">
      <ul class="m-0">
        <li class="status--block p-1" *ngFor="let status of statuses" (click)="selectStatus(status)">
          <dvtx-tooltip [tooltipText]="status?.title | translate" [position]="'top left'" class="w-100">
            <span [style.background]="'#' + status?.color" [class.active]="isStatusSelected(status)">
              <mat-icon style="color: white !important;" *ngIf="selected?.icon" class="mr-1">{{status?.icon}}</mat-icon>
              {{(status?.title | translate).length > 22 ? (((status?.title | translate) | slice:0:22) + '..') : (status?.title | translate)}}
            </span>
          </dvtx-tooltip>
        </li>
      </ul>
    </div>
  </ng-template>
</ng-container>

<ng-template #link>
  <span
    class="link--content"
    cdk-overlay-origin
    (click)="showDropdownDialog()"
    #dropdown="cdkOverlayOrigin"
    [style.background]="'#' + selected?.color"
    [style.border-color]="'#' + selected?.color">
    <ng-container [ngSwitch]="selected">
      <ng-container *ngSwitchCase="null">
        <span [style.color]="'black'">{{'GENERAL.STATUS' | translate}}</span>
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{selected?.title | translate}}
      </ng-container>
    </ng-container>
  </span>
  <ng-template cdk-connected-overlay
               [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
               [cdkConnectedOverlayOrigin]="dropdown"
               [cdkConnectedOverlayHasBackdrop]="true"
               [cdkConnectedOverlayOpen]="showDropdown && !disabled"
               (backdropClick)="showDropdown = false">
    <div class="mat-elevation-z8 list--container p-1">
      <ul class="m-0">
        <li class="status--block p-1" *ngFor="let status of statuses" (click)="selectStatus(status)">
          <dvtx-tooltip [tooltipText]="status?.title | translate" [position]="'top left'" class="w-100">
            <span [style.background]="'#' + status?.color" [class.active]="isStatusSelected(status)">
              {{(status?.title | translate).length > 22 ? (((status?.title | translate) | slice:0:22) + '..') : (status?.title | translate)}}
            </span>
          </dvtx-tooltip>
        </li>
      </ul>
    </div>
  </ng-template>
</ng-template>
