import {ILoginSettings, IOrganizationLogo} from './organization-logo.interface';

export class OrganizationLogo implements IOrganizationLogo {
  readonly type = 'artifact_organization_logos';
  temporary = false;

  constructor(public id,
              public url: string,
              public current = false,
              public title: string,
              public organizationId: string,
              public inviterLogo = false) {}
}

export class LoginSettings implements ILoginSettings {
  readonly type = 'auth_login_settings';

  constructor(public id,
              public imprintUrl: string,
              public loginHeader: string,
              public loginFooter: string,
              public loginHeaderEN: string,
              public loginFooterEN: string) {}
}
