import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {DocumentTableComponent} from './containers/document-table/document-table.component';
import {DocumentPreviewComponent} from './containers/document-preview/document-preview.component';
import {DocumentDetailsComponent} from './containers/document-details/document-details.component';
import {DeleteDocumentComponent} from './containers/delete-document/delete-document.component';
import {DmsDocumentTableComponent} from './containers/dms-document-table/dms-document-table.component';
import {DmsDocumentDetailsComponent} from './containers/dms-document-details/dms-document-details.component';
import {DmsDocumentAdminTableComponent} from './containers/dms-document-admin-table/dms-document-admin-table.component';
import {DeleteDocumentAsAdminComponent} from './components/delete-document-as-admin/delete-document-as-admin.component';
import {BatchDmsDocumentDeletionDialogComponent} from './components/batch-dms-document-deletion-dialog/batch-dms-document-deletion-dialog.component';
import {DocumentOptionsComponent} from './components/document-options/document-options.component';
import {DocumentPreviewDialogComponent} from './containers/document-preview-dialog/document-preview-dialog.component';
import {PreviewBrowserModule} from '../../../document-preview/modules/preview-browser/preview-browser.module';
import {ArtifactsTableComponent} from './containers/artifacts-table/artifacts-table.component';
import {TooltipModule} from "../../../../five-f/tooltip/tooltip.module";

@NgModule({
    imports: [
        SharedModule,
        PreviewBrowserModule,
        TooltipModule,
    ],
    declarations: [
        DmsDocumentTableComponent,
        DocumentOptionsComponent,
        BatchDmsDocumentDeletionDialogComponent,
        DmsDocumentAdminTableComponent,
        DmsDocumentDetailsComponent,
        DocumentTableComponent,
        DocumentPreviewComponent,
        DocumentDetailsComponent,
        DeleteDocumentComponent,
        DeleteDocumentAsAdminComponent,
        DeleteDocumentAsAdminComponent,
        DocumentPreviewDialogComponent,
        ArtifactsTableComponent
    ],
    exports: [
        DocumentTableComponent,
        DmsDocumentTableComponent,
        DmsDocumentAdminTableComponent,
        DeleteDocumentComponent,
        DeleteDocumentAsAdminComponent,
        DocumentPreviewDialogComponent,
        ArtifactsTableComponent,
        DocumentPreviewComponent
    ]
})
export class DocumentModule {
}
