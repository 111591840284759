import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {EmailFooter} from './email-footer';
import * as dayjs from 'dayjs';


export interface State extends EntityState<EmailFooter> {
  current?: EmailFooter
  loading: boolean
}

export const adapter = createEntityAdapter<EmailFooter>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  current: null,
  loading: false,
});
