import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Comment} from './comment';
import * as dayjs from 'dayjs';

export interface State extends EntityState<Comment> {
  selected?: Comment;
  unread: number;
  stats: object;
  loading: boolean;
  creating: boolean;
  updating: boolean;
}

export const adapter = createEntityAdapter<Comment>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  unread: null,
  stats: {},
  loading: false,
  creating: false,
  updating: false
});
