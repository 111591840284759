<div class="mat-dialog-inner-wrap">
  <div class="mat-dialog-header">
    <button class="mat-dialog-close" mat-mini-fab mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
    <h2 mat-dialog-title>
      {{ 'TASK.CREATE_TASK' | translate }}
    </h2>
  </div>

  <div mat-dialog-content *ngIf="form">
    <dvtx-task-settings [(form)]="form" [(task)]="task"></dvtx-task-settings>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-end">
    <button type="button" mat-button mat-dialog-close>
      {{ 'GENERAL.CANCEL_ACTION' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)='saveChanges()' [disabled]="form.invalid">
      {{'GENERAL.SAVE_ACTION' | translate}}
    </button>
  </div>
</div>
