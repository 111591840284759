import {ProcessLookupActions, ProcessLookupActionTypes} from './process-lookup.actions';
import {adapter, initialState, State} from './process-lookup.state';

export function reducer(state = initialState, action: ProcessLookupActions): State {
  switch (action.type) {
    case ProcessLookupActionTypes.LoadSelection:
      return {
        ...state,
        loading: true
      };
    case ProcessLookupActionTypes.LoadSelectionSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false
      });
    case ProcessLookupActionTypes.LoadSelectionFail:
      return {
        ...state,
        loading: false
      };
  }
  return state;
}
