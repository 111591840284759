import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {TaskComment} from './task-comment';
import {TaskCommentBuilder} from './task-comment.builder';
import {CommentReactionType, CommentType} from "../comment/comment.interface";
import {Comment} from "../comment/comment";
import {CommentBuilder} from "../comment/comment.builder";

@Injectable()
export class TaskCommentService {
  readonly BASE_PATH = 'api/v1/tasks/tasks';

  constructor(private _http: ApiResourceService) {
  }

  loadAll(taskId) {
    const builder = new TaskCommentBuilder();
    return <Observable<TaskComment[]>>this._http.get<TaskCommentBuilder, TaskComment>(builder, `${this.BASE_PATH}/${taskId}/comments`);
  }

  sendComment(taskId, comment: TaskComment): Observable<TaskComment> {
    const builder = new TaskCommentBuilder();
    const payload = builder.toRequest(comment);
    return <Observable<TaskComment>>this._http.post<TaskCommentBuilder, TaskComment>(builder, `${this.BASE_PATH}/${taskId}/comments`, payload);
  }

  updateComment(taskId, comment: TaskComment): Observable<TaskComment> {
    const builder = new TaskCommentBuilder();
    const payload = builder.toRequest(comment);
    return <Observable<TaskComment>>this._http.put<TaskCommentBuilder, TaskComment>(builder, `${this.BASE_PATH}/${taskId}/comments/${comment.id}`, payload);
  }

  deleteComment(taskId, comment: TaskComment): Observable<TaskComment> {
    const builder = new TaskCommentBuilder();
    return <Observable<TaskComment>>this._http.del<TaskCommentBuilder, TaskComment>(builder, `${this.BASE_PATH}/${taskId}/comments/${comment.id}`);
  }

  read(taskId, comment: TaskComment): Observable<TaskComment> {
    const builder = new TaskCommentBuilder();

    return <Observable<TaskComment>>this._http.post<TaskCommentBuilder, TaskComment>(builder, `${this.BASE_PATH}/${taskId}/comments/${comment.id}/read`, {});
  }

  doReact(taskId: string, commentId: string, reactionType: CommentReactionType): Observable<TaskComment> {
    const builder = new TaskCommentBuilder();
    const payload = { data: { attributes: { reaction_type: reactionType } } }
    return <Observable<TaskComment>>this._http.post<TaskCommentBuilder, TaskComment>(builder, `${this.BASE_PATH}/${taskId}/comments/${commentId}/reaction`, payload);
  }
}
