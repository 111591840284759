<div class="mat-dialog-inner-wrap dialog-confirmation">

  <div class="mat-dialog-header dvtx-bg-color dvtx-bg-cool-grey-25">
    <h1 mat-dialog-title>
      {{ title | translate }}
    </h1>
  </div>

  <div class="folder-dialog-content folder-dialog-errors mat-dialog-content" mat-dialog-content>
    <div class="folder-dialog-error-message" *ngFor="let error of errors">
      {{ error.details }}
    </div>
  </div>

  <div class="mat-dialog-footer d-flex justify-content-end">
    <mat-dialog-actions class="d-flex justify-content-center">
      <button mat-raised-button color="warn" [mat-dialog-close]="'ok'">{{ 'GENERAL.CLOSE_ACTION' | translate }}</button>
    </mat-dialog-actions>
  </div>
</div>
