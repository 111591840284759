import {Action} from '@ngrx/store';
import * as model from './user';
import * as modelInterface from './user.interface';

export enum TenantUserActionTypes {
  Create = '[Tenant.User] Create',
  CreateSuccess = '[Tenant.User] Create Success',
  CreateFail = '[Tenant.User] Create Fail',
  Save = '[Tenant.User] Save',
  SaveSuccess = '[Tenant.User] Save Success',
  SaveFail = '[Tenant.User] Save Fail',
  Status = '[Tenant.User] Status',
  LoadAll = '[Tenant.User] Load All',
  LoadAllSuccess = '[Tenant.User] Load All Success',
  LoadAllFail = '[Tenant.User] Load All Fail',
  LoadOne = '[Tenant.User] Load One',
  LoadOneSuccess = '[Tenant.User] Load One Success',
  LoadOneFail = '[Tenant.User] Load One Fail',
  ToggleLicense = '[Tenant.User] ToggleLicense',
  ToggleLicenseSuccess = '[Tenant.User] ToggleLicense Success',
  ToggleLicenseFail = '[Tenant.User] ToggleLicense Fail',
  LockUserAccount = '[Tenant.User] LockUserAccount',
  LockUserAccountSuccess = '[Tenant.User] LockUserAccount Success',
  LockUserAccountFail = '[Tenant.User] LockUserAccount Fail'
}

export class Create implements Action {
  readonly type = TenantUserActionTypes.Create;

  constructor(public tenantId: string, public user: model.Tenant.User) {
  }
}

export class CreateSuccess implements Action {
  readonly type = TenantUserActionTypes.CreateSuccess;

  constructor(public user: model.Tenant.User) {
  }
}

export class CreateFail implements Action {
  readonly type = TenantUserActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class ToggleLicense implements Action {
  readonly type = TenantUserActionTypes.ToggleLicense;

  constructor(public tenantId: string, public userId: string, public enable: boolean) {
  }
}

export class ToggleLicenseSuccess implements Action {
  readonly type = TenantUserActionTypes.ToggleLicenseSuccess;

  constructor(public user: model.Tenant.User) {
  }
}

export class ToggleLicenseFail implements Action {
  readonly type = TenantUserActionTypes.ToggleLicenseFail;

  constructor(public payload: any) {
  }
}

export class LockUserAccount implements Action {
  readonly type = TenantUserActionTypes.LockUserAccount;

  constructor(public tenantId: string, public userId: string, public enable: boolean) {
  }
}

export class LockUserAccountSuccess implements Action {
  readonly type = TenantUserActionTypes.LockUserAccountSuccess;

  constructor(public user: model.Tenant.User) {
  }
}

export class LockUserAccountFail implements Action {
  readonly type = TenantUserActionTypes.LockUserAccountFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = TenantUserActionTypes.Save;

  constructor(public user: model.Tenant.User) {
  }
}

export class SaveSuccess implements Action {
  readonly type = TenantUserActionTypes.SaveSuccess;

  constructor(public user: model.Tenant.User) {
  }
}

export class SaveFail implements Action {
  readonly type = TenantUserActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = TenantUserActionTypes.LoadAll;

  constructor(public tenantId: string, public params: modelInterface.Tenant.IUserGetAllParams) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = TenantUserActionTypes.LoadAllSuccess;

  constructor(public users: model.Tenant.User[], public rawUsers: model.Tenant.User[] = [], public reset = false) {
  }
}

export class LoadAllFail implements Action {
  readonly type = TenantUserActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = TenantUserActionTypes.LoadOne;

  constructor(public tenantId: string, public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = TenantUserActionTypes.LoadOneSuccess;

  constructor(public user: model.Tenant.User) {
  }
}

export class LoadOneFail implements Action {
  readonly type = TenantUserActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type TenantUserActions =
  | Create
  | CreateSuccess
  | CreateFail
  | ToggleLicense
  | ToggleLicenseSuccess
  | ToggleLicenseFail
  | LockUserAccount
  | LockUserAccountSuccess
  | LockUserAccountFail
  | Save
  | SaveSuccess
  | SaveFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
