export class ArtifactKeyword {
  readonly type = 'artifact_keywords';

  constructor(public id,
              public organizationId: string,
              public name: string,
              public description: string,
              public createdAt: Date) {
  }
}
