<mat-form-field floatLabel="never"
                class="dvtx-autocompleted-input-select">
  <!-- <mat-label>{{autocompleteValue ? '' : placeholder}}</mat-label>-->
  <mat-icon *ngIf="prefixIcon" matPrefix>{{prefixIcon}}</mat-icon>
  <input class="inputFieldCustom" matInput type="text"
         [placeholder]="autocompleteValue ? '' : placeholder"
         [matTooltip]="autocompleteValue"
         [matAutocomplete]="auto"
         [matAutocompleteDisabled]="disabled"
         [(ngModel)]="autocompleteValue"
         (keydown.enter)="onEnter($event)"
         (keyup)="onAutocompleteKeyEnter(autocompleteValue)"
         (focusout)="onBlur($event)"
         [disabled]="disabled">
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
  <mat-option *ngFor="let item of _filteredAutocomplete | async"
              [value]="item"
              (onSelectionChange)="onSelect($event, item)">
    {{ item }}
  </mat-option>
</mat-autocomplete>
