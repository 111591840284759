
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserProfile} from '../models/userProfile';
import {ProfileBuilder} from '../builders/profile.builder.';
import {
  IContactPhoneNumber,
  SimpleAddress,
  SimplePhoneNumber
} from '../../../../modules/contacts/models/contact.interface';
import {
  SimplePhoneNumberBuilder
} from '../../../../modules/contacts/builders/contact-phone-number.builder';
import {SimpleAddressBuilder} from '../../../../modules/contacts/builders/contact-address.builder';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProfileService {

  constructor(
    private _tokenService: HttpClient
  ) {
  }

  getProfile(): Observable<UserProfile> {
    return this._tokenService.get('/profile').pipe(map((response: any) => {
      return ProfileBuilder.fromResponse(response['data']['attributes']);
    }))
  }

  getPhones(): Observable<SimplePhoneNumber[]> {
    return this._tokenService.get('profile/phone_numbers').pipe(map((response: any) => {
      return SimplePhoneNumberBuilder.fromResponseList(response);
    }));
  }

  getEmails(): Observable<IContactPhoneNumber[]> {
    throw Error('not implemented')
  }

  getAddresses(): Observable<SimpleAddress[]> {
    return this._tokenService.get('profile/addresses').pipe(map((response: any) => {
      return SimpleAddressBuilder.fromResponseList(response);
    }));
  }

  saveAddress(val: SimpleAddress): Observable<any> {
    return this._tokenService.post('profile/addresses', SimpleAddressBuilder.toRequest(val));
  }
  savePhone(val: SimplePhoneNumber): Observable<any> {
    return this._tokenService.post('profile/phone_numbers', SimplePhoneNumberBuilder.toRequest(val));
  }
  saveProfile(val: UserProfile): Observable<any> {
    return this._tokenService.post('/profile', ProfileBuilder.toRequest(val));
  }

  delAddress(val: SimpleAddress): Observable<any> {
    return this._tokenService.delete('profile/addresses/' + val.id);
  }
  delPhone(val: SimplePhoneNumber): Observable<any> {
    return this._tokenService.delete('profile/phone_numbers/' + val.id);
  }
}
