import { Action } from '@ngrx/store';

export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_FAILURE = 'GET_STATUS_FAILURE';

export class GetStatusSuccess implements Action {
  readonly type: string = GET_STATUS_SUCCESS;

  constructor(public payload: any) { }
}

export class GetStatusFailure implements Action {
  readonly type: string = GET_STATUS_FAILURE;

  constructor(public payload: any) { }
}

export const GET_STATUS = 'GET_STATUS';

export class GetStatus implements Action {
  readonly type: string = GET_STATUS;

  constructor(public payload: any = null) { }
}


export type Actions = 
  GetStatusSuccess 
| GetStatus
| GetStatusFailure;
