import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'dvtx-subarea-verifier-request-contact-form',
  templateUrl: './request-contact-form.component.html',
})
export class SubareaVerifierRequestContactFormComponent implements OnInit {
  _form: UntypedFormGroup;
  @Input() set form(form: UntypedFormGroup) {
    this._form = form;
    if(this.readOnly) {
      this._form.disable();
    }
  }
  get form() {
    return this._form;
  }

  _readOnly: boolean;
  @Input() set readOnly(readOnly: boolean){
    this._readOnly = readOnly;
    if (readOnly) {
      this._form.disable();
    }
  }
  get readOnly() {
    return this._readOnly;
  }

  constructor() {}

  ngOnInit() {}

}
