import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, first, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DropdownContentComponent} from 'app/five-f/dropdown-content/component/dropdown-content.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Label} from 'app/+store/label/label';
import {StringValidator} from 'app/lib/validator';
import {GLOBAL_LABELS} from 'app/five-f/labels/configs';
import {ILabelParams, LabelOrigin, LabelScope} from 'app/+store/label/label.interface';
import {Sorters} from 'app/lib/sorter/sorters';
import {OrganizationSelectors} from 'app/+store';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dvtx-labels-selector',
  templateUrl: './labels-selector.component.html',
  styleUrls: ['./labels-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelsSelectorComponent implements OnInit, OnDestroy {
  protected onDestroy = new Subject();
  public scope = LabelScope;
  public origin = LabelOrigin;

  public globalLabels = GLOBAL_LABELS;
  @ViewChild('listCreationDropdownMenu') listCreationDropdownMenu: DropdownContentComponent;
  @ViewChild('createListIcon', {static: true}) createListIcon: TemplateRef<any>;
  @ViewChild('createListContent', {static: true}) createListContent: TemplateRef<any>;
  createListIconPortal: Portal<any>;
  createListContentPortal: Portal<any>;
  showNewListItemForm = false;

  createListForm: UntypedFormGroup;
  searchForm: UntypedFormGroup;

  _filteredLabels: Label[];
  _labels: Label[];
  @Input() set labels(labels: Label[]) {
    if (!labels) return;
    this._labels = labels.filter(label => label.origin === LabelOrigin.NO_ORIGIN && label.title !== 'KANBAN.UNSORTED_GROUP').map(label => {
      const globalLabel = GLOBAL_LABELS.find(_label => _label.title === label.title);

      if (globalLabel) {
        globalLabel.id = label.id;
        return globalLabel;
      }

      return label;
    });

    this.globalLabels = this.globalLabels.filter(label => !this._labels.find(l => l.title === label.title));
    this._filteredLabels = [...this.globalLabels, ...this._labels].sort((l, r) => Sorters.sort(l.scope, r.scope)).sort((l, r) => Sorters.sort(l.title, r.title));
    this._filteredLabels = this._filteredLabels.sort((a, b) => (this._translateSvc.instant(a.title).trim().toLowerCase() < this._translateSvc.instant(b.title).trim().toLowerCase() ? -1 : 1));
  }

  @Input() selected: string[] = [];
  @Input() isTextButton = true;
  @Input() labelIcon = true;
  @Input() hiddenCount: number = 0;

  @Output() onSelection: EventEmitter<any> = new EventEmitter();

  constructor(private _store: Store<AppState>,
              private _viewContainerRef: ViewContainerRef,
              private _fb: UntypedFormBuilder,
              private _router: Router,
              private _dialog: MatDialog,
              private _translateSvc: TranslateService,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.initCreateForm();
    this.createSearchForm();
    this.initCreateListDropdown();

    this._translateSvc.onLangChange
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this._filteredLabels) {
          this._filteredLabels = this._filteredLabels.sort((a, b) => (this._translateSvc.instant(a.title).trim().toLowerCase() < this._translateSvc.instant(b.title).trim().toLowerCase() ? -1 : 1));
          this._cdr.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  initCreateForm() {
    this.createListForm = this._fb.group({
      'title': ['', [Validators.required]],
      'color': ['#000000', [Validators.required]]
    });

    this.createListForm.get('title').setValidators((control) => StringValidator.notExistedIn(control.value, this.labels ? this.labels.map(l => l.title) : []));
  }

  createSearchForm() {
    this.searchForm = this._fb.group({
      searchLabelsTerm: ['Search']
    });

    this.searchForm.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.onDestroy)
    ).subscribe( () => {
      this._filteredLabels = [...this.globalLabels, ...this._labels].sort((l, r) => Sorters.sort(l.scope, r.scope)).sort((l, r) => Sorters.sort(l.title, r.title));
      const q = this.searchForm.value.searchLabelsTerm;
      this._filteredLabels = this._filteredLabels.sort((a, b) => (this._translateSvc.instant(a.title).trim().toLowerCase() < this._translateSvc.instant(b.title).trim().toLowerCase() ? -1 : 1));
      if ((!q || q.length === 0)) return;
      this._filteredLabels = this._filteredLabels.filter(item => item.title.toLowerCase().search(q.toLowerCase()) > -1);
      this._filteredLabels = this._filteredLabels.sort((a, b) => (this._translateSvc.instant(a.title).trim().toLowerCase() < this._translateSvc.instant(b.title).trim().toLowerCase() ? -1 : 1));
    })

    setTimeout(_ => {
      this.searchForm.patchValue({
        searchLabelsTerm: ''
      })
    }, 1);
  }

  initCreateListDropdown() {
    this.createListIconPortal = new TemplatePortal(
      this.createListIcon,
      this._viewContainerRef
    );

    this.createListContentPortal = new TemplatePortal(
      this.createListContent,
      this._viewContainerRef
    );
  }


  check(label: Label, $event) {
    if (!!this._labels && !!this._labels.find(_label => _label.id === label.id)) {
      this.onSelection.emit({ event: $event, label: label });
    } else {
      this.submitCreateGlobalLabel(label);
    }
  }

  createLabelChangeColor(color) {
    this.createListForm.patchValue({
      color: color
    });
  }

  submitCreateLabel(event: ILabelParams) {
    this.onSelection.emit({ $event: null, label: event })
    this.createListForm.reset();
    this.showNewListItemForm = false;
  }

  submitCreateGlobalLabel(label: Label) {
    const labelParams: ILabelParams = {
      icon: label.icon,
      color: label.color,
      title: label.title,
      description: label.description,
      scope: this.scope.GLOBAL,
      isGlobal: true,
      origin: this.origin.NO_ORIGIN
    };
    this.onSelection.emit({ $event: null, label: labelParams })
  }

  goToManageLabelsPage() {
    this.listCreationDropdownMenu.toggleDropdown();
    this._store.select(OrganizationSelectors.getSelected).pipe(
      filter(organization => !!organization),
      distinctUntilKeyChanged('id'),
      takeUntil(this.onDestroy)
    ).subscribe(org => {

      this._dialog.closeAll();
      this._dialog.afterAllClosed.pipe(first()).subscribe(_ => this._router.navigate([`/organization/${org.id}/labels-settings`], {queryParams: {'returnUrl': this._router.url}}))
    })
  }

  public isSelected(labelId: string) {
    if (!labelId) return false;
    return this.selected && this.selected.indexOf(labelId) > -1;
  }
}
