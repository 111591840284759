import {FibuActions, FibuActionTypes} from './fibu.actions';
import {adapter, initialState, State} from './fibu.state';

export function reducer(state = initialState, action: FibuActions): State {
  const current = state.selected;
  switch (action.type) {
    case FibuActionTypes.LoadByClient:
    case FibuActionTypes.Update:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case FibuActionTypes.LoadByClientFail:
    case FibuActionTypes.UpdateFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
    case FibuActionTypes.LoadByClientSuccess:
    case FibuActionTypes.UpdateSuccess:
      return adapter.upsertOne(action.fibu, {
        ...state,
        selected: action.fibu,
        loading: false,
      });
  }
  return state;
}
