import * as model from './organization.interface';
import {IResource} from 'app/shared/modules/api/models/resource.interface';

/// <reference path="./organization.interface.ts" />
export namespace Operator {
  export class Organization implements model.Operator.IOrganization {
    readonly type = 'organization_records';

    public cmsEnabledAt: Date;

    /**
     * Reports feature, e.g. the participant excel export.
     */
    public reportsEnabledAt: Date;

    /**
     * ELO DMS feature support.
     */
    public eloEnabledAt: Date;

    public motdEnabledAt: Date;
    public partnerLinksEnabledAt: Date;
    public auditProofDmsEnabledAt: Date;
    public processManagementEnabledAt: Date;
    public bookmanEnabledAt: Date;
    public fastdocsEnabledAt: Date;
    public digitalbarEnabledAt: Date;
    public fivefAgentEnabledAt: Date;
    public datevEnabledAt: Date;
    public fivefDesktopEnabledAt: Date;
    public exportMarkerEnabledAt: Date;
    public mobileEnabledAt: Date;
    public datevDmsEnabledAt: Date;
    public japEnabledAt: Date;
    public kapEnabledAt: Date;
    public labelsEnabledAt: Date;
    public kanbanEnabledAt: Date;
    public bigFileSupportEnabledAt: Date;
    public documentRevisionEnabledAt: Date;
    public processCount: number;
    public licence: string;
    public edition: number;
    public namedUserLicences: number;
    public freeNamedUserLicences: number;
    public memberships: model.Operator.IOrganizationMembership[];

    public dmsSid: string;
    public dmsUsername: string;
    public dmsEmail: string;
    public dmsHumanSize: string;
    public dmsUsedInPercent: string;
    public usedInHumanSize: string;
    public dmsDiskSpace: number;
    public dmsFree: number;
    public dmsDocumentCount: number;
    public dmsFolderCount: number;
    public status: model.Operator.OrganizationStatus;
    public contractStartedAt: Date;
    public tenantId: string;
    public tenantName: string;
    public tenantDomain: string;
    public customerName: string;
    public customerNumber: string;
    public note: string;

    // String of Microsoft Active Directory department entries.
    // The value is a comma separated list of department entries.
    public adDepartments: string;

    constructor(public id,
                public name: string,
                public countryCode,
                public createdAt,
                public updatedAt) {
    }
  }

  export class OrganizationStats {
    readonly id = 'organization_stats';
    readonly type = 'organization_stats';

    constructor(public trialPhase: number,
                public bookedLicencePlan: number,
                public overdue: number) {
    }
  }

  export class EmailFooter implements model.Operator.IEmailFooter {
    readonly type = model.Operator.FooterType.Orgnizational;

    constructor(public id,
                public title: string,
                public content: string,
                public creatorEmail: string,
                public publishedAt: string,
                public updatedAt: string,
                public createdAt: string,
                public published: boolean,
    ) {}
  }

  export class OrganizationLogo implements model.Operator.IOrganizationLogo {
    readonly type = 'artifact_organization_logos';

    constructor(public id,
                public url: string,
                public current = false,
                public title: string,
                public organizationId: string,
                public inviterLogo = false,
                public createdAt: Date) {
    }
  }

  export class VisualSignatureBackground implements IResource {
    readonly type = 'organization_visual_signature_background';

    constructor(public id,
                public content: string | ArrayBuffer,
                public fontColor: string = 'black',
                public updatedAt: Date = null,
                public createdAt: Date = null) {
    }
  }

  /**
   * id: 15,
   * uuid: "aee67264-ef21-49cb-a7f2-be205d712afc",
   * operator_id: 1253,
   * owner_id: 166,
   * organization_id: 1743,
   * organization_name: "WKGT localhost",
   * organization_email: "wkgt.localhost@org.5fsoftware.de",
   * licence_count: 50,
   * licence_type: "sales_licence_professional",
   * licence_interval: "Yearly",
   * encrypted_default_password: "fcHYkh7p8E1OtNaqxkh9elOcqqN8Mnk0\n",
   * encrypted_default_password_iv: "VfqfKfcnSyXUojfh\n",
   * created_at: Tue, 07 Sep 2021 09:10:25 CEST +02:00,
   * updated_at: Tue, 07 Sep 2021 09:10:52 CEST +02:00
   */
  export class OrganizationOnboarding implements IResource {
    readonly type = 'organization_onboardings';
    licenceType: string;

    constructor(public id,
                public organization_id: string,
                public organization_name: string,
                public operator_email: string,
                public operator_first_name: string,
                public operator_last_name: string,
                public licence_count: string,
                public licence_type: string,
                public updatedAt: Date = null,
                public createdAt: Date = null) {
      switch (licence_type) {
        case 'sales_licence_standard':
          this.licenceType = 'Standard';
          break;
        case 'sales_licence_professional':
          this.licenceType = 'Professional';
          break;
        case 'sales_licence_enterprise':
          this.licenceType = 'Enterprise';
          break;
        default:
          this.licenceType = 'undefined';
      }
    }
  }
}
