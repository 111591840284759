import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {DateUtil} from 'app/lib/date/date-util';
import {Fastdocs, FastdocsSubscription} from './fastdocs';
import {
  IFastdocsDates,
  IFastdocsDmsFolder,
  IFastdocsIndicators,
  IFastdocsPerson,
  IFastdocsProcess,
  IFastdocsStatus,
  IFiveFStatus,
  IIsFastdocs
} from './fastdocs.interface';
import {FastdocsStatus, FastdocsStatusTitle, FiveFStatusApiMap, FiveFStatusesMap} from './fastdocs.var';

export class FastdocsBuilder implements IApiResourceBuilder<Fastdocs> {
  fromResponse(data): Fastdocs {
    const attrs = Object.assign({}, data.attributes);
    const fastdocs = new Fastdocs(
      data.id,
      attrs.title,
      attrs.color,
      <IFastdocsProcess>{
        client: {
          id: attrs.client_id,
          name: attrs.client_name
        }
      },
      <IFastdocsDmsFolder>{
        path: attrs.dms_folder_path,
        id: attrs.dms_folder_id
      },
      <IFiveFStatus>FiveFStatusApiMap[attrs.state] || FiveFStatusesMap.Open,
      <{[id: string]: IFastdocsStatus}>{
        Open: {
          id: FastdocsStatus.Open,
          title: FastdocsStatusTitle.Open,
          completed: true
        },
        ClientCompleted: {
          id: FastdocsStatus.ClientCompleted,
          title: FastdocsStatusTitle.ClientCompleted,
          completed: false
        },
        OfficeCompleted: {
          id: FastdocsStatus.OfficeCompleted,
          title: FastdocsStatusTitle.OfficeCompleted,
          completed: false
        }
      },
      <IFastdocsPerson[]>[],
      <IFastdocsDates>{},
      0, // duration
      <IFastdocsIndicators>{
        notificationsCount: attrs.notification_count,
        documentsCount: attrs.document_count,
        templatesCount: attrs.template_count
      },
      <IIsFastdocs>{},
      <IFastdocsPerson>{
        email: attrs.owner_email,
        name: attrs.owner_name
      },
      <IFastdocsPerson>{
        email: attrs.creator_email,
        name: attrs.creator_name
      },
      attrs.description,
      attrs.template_count,
      attrs.document_count,
      attrs.notification_count,
      attrs.created_at,
      attrs.starts_at,
    );

    if (attrs.participants && attrs.participants.length > 0) {
      attrs.participants.forEach(participant => {
        fastdocs.participants.push({
          id: participant.id,
          email: participant.email,
          name: participant.first_name + ' ' + participant.last_name
        });
      });
    }

    fastdocs.dates = {
      dueDate: attrs.due_date,
      startedAt: attrs.started_at,
      completedAt: attrs.completed_at,
      startsAt: attrs.starts_at,
      endsAt: attrs.ends_at,
      createdAt: attrs.created_at,
      updatedAt: attrs.updated_at
    }

    if (attrs.created_at) {
      const endDate = attrs.completed_at || new Date();
      fastdocs.duration = DateUtil.daysDiff(endDate, attrs.created_at);
    }

    if (attrs.fastdocs_workflow_state !== FastdocsStatus.Open) {
      fastdocs.fastdocsStatuses.ClientCompleted.completed = (attrs.fastdocs_workflow_state === FastdocsStatus.ClientCompleted) || (attrs.fastdocs_workflow_state === FastdocsStatus.Completed);
      fastdocs.fastdocsStatuses.OfficeCompleted.completed = (attrs.fastdocs_workflow_state === FastdocsStatus.OfficeCompleted) || (attrs.fastdocs_workflow_state === FastdocsStatus.Completed);
    }

    return fastdocs;
  }

  toRequest(fastdocs: Fastdocs) {
    return {
      data: {
        id: fastdocs.id,
        type: 'fastdocs',
        attributes: {}
      }
    };
  }

  isOverdue(date) {
    const today = new Date().toString();
    return DateUtil.daysDiff(date, today) <= 0;
  }

  overdueDuration(date) {
    const today = new Date().toString();
    return DateUtil.daysDiff(date, today) * -1;
  }
}

export class FastdocsSubscriptionBuilder implements IApiResourceBuilder<FastdocsSubscription> {
  fromResponse(data): FastdocsSubscription {
    const attrs = Object.assign({}, data.attributes);
    return new FastdocsSubscription(data.id, attrs.email, attrs.created_at);
  }

  toRequest(fastdocs: FastdocsSubscription) {
    return null;
  }
}
