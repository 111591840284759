import {TaskActions, TaskActionTypes} from './task.actions';
import {adapter, initialState, State} from './task.state';

export function reducer(state = initialState, action: TaskActions): State {
  switch (action.type) {
    case TaskActionTypes.LoadAll:
    case TaskActionTypes.LoadMy:
    case TaskActionTypes.LoadAllAndAppend:
    case TaskActionTypes.LoadAllUserTasks:
    case TaskActionTypes.LoadAllOrganizationTasks:
      return {
        ...state,
        loading: true
      };
    case TaskActionTypes.LoadAllSuccess:
    case TaskActionTypes.LoadMySuccess:
    case TaskActionTypes.LoadAllOrganizationTasksSuccess:
      return adapter.setAll(action.tasks, {
          ...state,
          selected: null,
          loading: false
      });

    case TaskActionTypes.LoadAllAndAppendSuccess:
      return adapter.upsertMany(action.tasks, {
        ...state,
        selected: null,
        loading: false
      });

    case TaskActionTypes.LoadAllFail:
    case TaskActionTypes.LoadMyFail:
    case TaskActionTypes.LoadAllAndAppendFail:
      return {
        ...state,
        loading: false
      };
    case TaskActionTypes.LoadAllWithoutPaging:
      return {
        ...state,
        loading: true
      };
    case TaskActionTypes.LoadAllWithoutPagingSuccess:
        return adapter.setAll(action.tasks, {
            ...state,
            selected: null,
            loading: false
        });

    case TaskActionTypes.LoadAllWithoutPagingFail:
      return {
        ...state,
        loading: false
      };
    case TaskActionTypes.LoadOne:
      return {
        ...state,
        loading: false
      };

    case TaskActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.task, {
        ...state,
        selected: action.task,
        loading: false
      });

    case TaskActionTypes.LoadOneFail:
      return {
        ...state,
        loading: false
      };

    case TaskActionTypes.Delete:
      return {
        ...state,
        deleting: true
      };

    case TaskActionTypes.DeleteSuccess:
      return adapter.removeOne(action.task.id, {
        ...state,
        deleting: false
      });

    case TaskActionTypes.Edit:
    case TaskActionTypes.AddAttachmentFromDMS:
    case TaskActionTypes.RemoveAttachment:
    case TaskActionTypes.Reorder:
    case TaskActionTypes.Close:
    case TaskActionTypes.Open:
      return {
        ...state,
        updating: true
      };

    case TaskActionTypes.EditSuccess:
    case TaskActionTypes.AddAttachmentFromDMSSuccess:
    case TaskActionTypes.RemoveAttachmentSuccess:
    case TaskActionTypes.CloseSuccess:
    case TaskActionTypes.OpenSuccess:
      return adapter.upsertOne(action.task, {
        ...state,
        updating: false
      });

    case TaskActionTypes.EditFail:
    case TaskActionTypes.AddAttachmentFromDMSFail:
    case TaskActionTypes.RemoveAttachmentFail:
    case TaskActionTypes.CloseFail:
    case TaskActionTypes.OpenFail:
    case TaskActionTypes.ReorderFail:
    case TaskActionTypes.AddResponsibleFail:
      return {
        ...state,
        updating: false,
        loading: false
      };

    case TaskActionTypes.ReorderSuccess:
      return adapter.upsertMany(action.tasks, {
        ...state,
        updating: false
      });

    case TaskActionTypes.AddResponsible:
      return {
        ...state,
        updating: true
      };

    case TaskActionTypes.AddResponsibleSuccess:
      return adapter.upsertOne(action.task, {
        ...state,
        updating: false
      });

    case TaskActionTypes.Create:
      return {
        ...state,
        creating: true
      };

    case TaskActionTypes.CreateSuccess:
      return adapter.addOne(action.task, {
        ...state,
        creating: false
      });

    case TaskActionTypes.LoadQuery:
      return {
        ...state,
        loading: true
      };

    case TaskActionTypes.LoadQuerySuccess:
        return adapter.setAll(action.payload, {
            ...state,
            selected: null,
            loading: false
        });

    case TaskActionTypes.LoadMore:
      return {
        ...state,
        loading: true
      };

    case TaskActionTypes.LoadMoreSuccess:
      return adapter.upsertMany(action.tasks, {
        ...state,
        loading: false
      });

    case TaskActionTypes.Reset:
      return adapter.removeAll(state);
  }
  return state;
}
