import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './slim-folder.state';
import {DmsAccountType} from './slim-folder.interface';
import {SlimFolderBuilder} from "./slim-folder.builder";
import {OrganizationSelectors} from "../organization";

export const stateKey = 'slim-folder';
const getSlimFolderState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getSlimFolderEntities,
  selectAll: getAllSlimFolders,
} = adapter.getSelectors(getSlimFolderState);

export const getSelected = createSelector(
  getSlimFolderState,
  (state) => state.selected
)

export const getOne = (id: string) => createSelector(
  getSlimFolderEntities,
  (entities) => entities[id]
);

export const getall = createSelector(
  getAllSlimFolders,
  (entities) => entities
);

export const getPrivateFolders = createSelector(
  getAllSlimFolders,
  (entities) => entities.filter(f => f.dmsAccountType === DmsAccountType.Private)
);

export const getOrganizationFolders = (oid: string) => createSelector(
  getAllSlimFolders,
  (entities) => entities.filter(f => f.dmsAccountType === DmsAccountType.Organization && f.sourceId === oid)
);

export const getOrganizationFoldersTree = (oid: string) => createSelector(
  OrganizationSelectors.getById(oid),
  getAllSlimFolders,
  (organization, slimFolders) => {
    const folders = slimFolders.filter(f => f.dmsAccountType === DmsAccountType.Organization && f.sourceId === oid).map(folder => {
      // Folder is no root folder, return.
      if (folder.parentId) return folder;

      // Folder is root folder (upmost level)
      // Attention: Shared folder contexts can have multiple roots:
      // (Multi) root naming convention. If admin with full root access, the main folder name is empty
      // So just print the organization name instead.
      // Example:
      // My Company
      // +- Clients
      // +- Office
      // Second case: Normal user with shared folder permissions. Now, it can happen, that the user has multiple
      // shared folders: All of them are own root folders.
      // Print the organization name - folder name.
      // My Company - First shared root folder name
      // +- First subfolder
      // +- Second subfolder
      // My Company - Second shared root folder name
      // +- First subfolder
      // ...
      // otherwise all folders would have the organization name.
      const folderName = folder.name ? ` - ${folder.name}` : ''
      folder.name = `${organization.name}${folderName}`;
      return folder;
    });
    return SlimFolderBuilder.generateFolderTreeFrom(folders);
  }
);

export const getFolderPath = (folderId: string) => createSelector(
  getAllSlimFolders,
  (folders) => {
    const selectedFolder = folders.find(f => f.id === folderId);
    if (!selectedFolder) return;
    let parentFolder = folders.find(f => f.id === selectedFolder.parentId);
    const folderAncestors = [parentFolder, selectedFolder];

    while (parentFolder) {
      parentFolder = folders.find(f => f.id === parentFolder.parentId);
      if (parentFolder) folderAncestors.unshift(parentFolder);
    }
    return folderAncestors.filter(f => !!f).map(f => f.name).join('/')
  }
);

export const loadingState = createSelector(
  getSlimFolderState,
  (state) => state.loading
);
