
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ResourceBuilder} from '../builders/resource.builder';
import {ApiErrorBuilder} from '../builders/api-error.builder';
import {environment} from 'environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ILocale} from 'app/shared/modules/api/models/locale.interface';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class I18nService {

  basePath;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/api/v1`;
  }

  getAvailableLocales() {
    return this._http.get(`${this.basePath}/settings/locales`).pipe(
      map((res: any) => ResourceBuilder.fromResponse(res)),
      catchError(_ => {
        return of([{name: 'Deutsch', code: 'de'}, {name: 'English', code: 'en'}])
      }));
  }

  getUserLocale() {
    return this._http.get(`${this.basePath}/settings/locale`).pipe(
      map((res: any) => <ILocale>ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  setUserLocale(locale) {
    const payload = {data: {attributes: {locale: locale}}};
    return this._http.post(`${this.basePath}/settings/locale`, payload).pipe(
      map((res: any) => ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  private handleError(error: HttpErrorResponse) {
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}
