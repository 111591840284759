import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  AcceptAssignment,
  AcceptAssignmentFail,
  AcceptAssignmentSuccess,
  AddAssignment,
  AddAssignmentFail,
  AddAssignmentSuccess,
  Delete,
  DeleteFail,
  DeleteSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadAssignment,
  LoadAssignmentFail,
  LoadAssignmentSuccess,
  RejectAssignment,
  RejectAssignmentFail,
  RejectAssignmentSuccess,
  TaskAssigneeActionTypes,
  UpdateAssignment
} from './task-assignee.actions';
import {TaskAssigneeService} from './task-assignee.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {TaskAssignee} from './task-assignee';
import * as TaskActions from 'app/+store/task/task.actions';
import * as TasksStatsActions from 'app/+store/tasks-stats/tasks-stats.actions';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';

@Injectable()
export class TaskAssigneeEffects {
  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(TaskAssigneeActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        concatMap((res: TaskAssignee[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.LOAD_ASSIGNEES_ERROR')
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  accept$ = this.actions.pipe(
    ofType(TaskAssigneeActionTypes.AcceptAssignment),
    switchMap((action: AcceptAssignment) => {
      return this._svc.accept(action.id).pipe(
        first(),
        concatMap((res: TaskAssignee) => {
          this._store.dispatch(new TasksStatsActions.LoadAll);
          return [new AcceptAssignmentSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.ACCEPT_ASSIGNMENT_ERROR')
          return of(new AcceptAssignmentFail(err));
        }));
    })
  );

  @Effect()
  reject$ = this.actions.pipe(
    ofType(TaskAssigneeActionTypes.RejectAssignment),
    switchMap((action: RejectAssignment) => {
      return this._svc.reject(action.id).pipe(
        first(),
        concatMap((res: TaskAssignee) => {
          this._store.dispatch(new TasksStatsActions.LoadAll);
          return [new RejectAssignmentSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.REJECT_ASSIGNMENT_ERROR')
          return of(new RejectAssignmentFail(err));
        }));
    })
  );

  @Effect()
  add$ = this.actions.pipe(
    ofType(TaskAssigneeActionTypes.AddAssignment),
    concatMap((action: AddAssignment) => {
      return this._svc.add(action.taskId, action.assigneeEmail).pipe(
        first(),
        concatMap((res: TaskAssignee) => {
          return [new AddAssignmentSuccess(res), new TaskActions.LoadOne(action.taskId)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.ADD_ASSIGNEE_ERROR')
          return of(new AddAssignmentFail(err));
        }));
    })
  );

  @Effect()
  LoadOne$ = this.actions.pipe(
    ofType(TaskAssigneeActionTypes.LoadAssignment),
    switchMap((action: LoadAssignment) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((res: TaskAssignee[]) => {

          return [new LoadAssignmentSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.LOAD_ASSIGNEE_ERROR')
          return of(new LoadAssignmentFail(err));
        }));
    })
  );

  @Effect()
  delete$ = this.actions.pipe(
    ofType(TaskAssigneeActionTypes.Delete),
    switchMap((action: Delete) => {
      return this._svc.remove(action.taskId, action.assigneeId).pipe(
        first(),
        switchMap((res: TaskAssignee) => {
          this._notifyService.success('TASK.DELETED_ASSIGNEE_SUCCESSFULLY')

          return [new DeleteSuccess(res), new TaskActions.LoadOne(action.taskId)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.DELETED_ASSIGNEE_ERROR')
          return of(new DeleteFail(err))
        }));
    })
  );

  @Effect()
  update$ = this.actions.pipe(
    ofType(TaskAssigneeActionTypes.UpdateAssignment),
    switchMap((action: UpdateAssignment) => {
      return this._svc.update(action.assignment).pipe(
        first(),
        concatMap((res: TaskAssignee) => {
          return [new AddAssignmentSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.UPDATE_ASSIGNEE_ERROR')
          return of(new AddAssignmentFail(err));
        }));
    })
  );

  constructor(private _store: Store<AppState>,
              private actions: Actions,
              private _svc: TaskAssigneeService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
