<div class="row mx-0 w-100 purchase--address--form align-items-center">
  <div class="col-12">
    <p class="h3">Angaben zur Rechnungsadresse</p>
  </div>
  <div class="col-8">
    <mat-form-field>
      <mat-select placeholder="Adresse wählen oder erstellen" disableRipple (selectionChange)="fillForm($event)" #selectInvoiceAddress>
        <mat-option *ngFor="let address of invoiceAdresses$ | async" [value]="address">{{ address?.full }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-4 text-right">
    <button mat-flat-button color="warn" (click)="resetForm()">{{ 'GENERAL.RESET_ACTION' | translate }}</button>
  </div>
</div>

<div class="row mx-0 w-100 purchase--address--form"  [formGroup]="form">
  <div class="col-4">
    <mat-form-field>
      <input matInput placeholder="Vorname" formControlName="firstName" required>
    </mat-form-field>
  </div>
  <div class="col-4">
    <mat-form-field>
      <input matInput placeholder="Nachname" formControlName="lastName" required>
    </mat-form-field>
  </div>
</div>

<div class="row mx-0 w-100 purchase--address--form"  [formGroup]="form">
  <div class="col-8">
    <mat-form-field>
      <input matInput placeholder="Unternehmen" formControlName="organizationName">
    </mat-form-field>
  </div>
</div>

<div class="row mx-0 w-100 purchase--address--form"  [formGroup]="form">
  <div class="col-4">
    <mat-form-field>
      <input matInput placeholder="Straße" formControlName="street" required>
    </mat-form-field>
  </div>
  <div class="col-4">
    <mat-form-field>
      <input matInput placeholder="Haus-Nr." formControlName="streetNo">
    </mat-form-field>
  </div>
</div>

<div class="row mx-0 w-100 purchase--address--form"  [formGroup]="form">
  <div class="col-4">
    <mat-form-field>
      <input matInput placeholder="PLZ" formControlName="zip" required>
    </mat-form-field>
  </div>
  <div class="col-4">
    <mat-form-field>
      <input matInput placeholder="Stadt" formControlName="city" required>
    </mat-form-field>
  </div>
</div>

<div class="row mx-0 w-100 purchase--address--form"  [formGroup]="form">
  <div class="col-4">
    <mat-form-field>
      <input matInput placeholder="Land" formControlName="country" required>
    </mat-form-field>
  </div>
  <div class="col-4">
    <mat-form-field>
      <input matInput placeholder="Ust Id" formControlName="ustId">
    </mat-form-field>
  </div>
</div>
