import { Routes, RouterModule } from '@angular/router';
import {RoutingComponent} from './components/routing.component';
import {DatevOauthRedirectComponent} from './components/datev-oauth-redirect/datev-oauth-redirect.component';

const routes: Routes = [{
  path: 'integrations/dms/return',
  component: RoutingComponent,
}, {
  path: 'integrations/datev/return',
  component: DatevOauthRedirectComponent,
}];

export const customRoute = RouterModule.forChild(routes);
