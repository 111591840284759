import { IFoxdoxStatus } from './foxdoxStatus.interface';
import { IResourceError } from './resource-error.interface';

export class FoxdoxStatus implements IFoxdoxStatus {
  readonly type = 'dms_foxdox_model_storage_infos';

  public errors: IResourceError [] = null;

  get diskSpaceInMB(): string {
      const diskSpace =  this.convertToMB (this.disk_space);
      return diskSpace;
  }

  get UsedDiskSpaceInMB(): string {
      const diskSpace =  this.convertToMB (this.used);
      return diskSpace;
  }

  get FreeDiskSpaceInMB(): string {
      const diskSpace =  this.convertToMB (this.free);
      return diskSpace;
  }


  convertToMB(value): string {
    try {
      return (value).toFixed(2) + ' MB';
    } catch (error) {
      console.error('Diskspace unset. Returning 0...')
      return '0 MB';
    }
  }

  get diskSpaceInGB(): string {
    const diskSpace =  this.convertToGB (this.disk_space);
    return diskSpace;
  }

  get UsedDiskSpaceInGB(): string {
      const diskSpace =  this.convertToGB (this.used);
      return diskSpace;
  }

  convertToGB(value): string {
    try {
      return (value / ( 1024 * 1024 * 1024 )).toFixed(2)  + ' GB';
    } catch (error) {
      console.error('Diskspace unset. Returning 0...')
      return '0 GB';
    }
  }

  get usedDiskSpacePercent(): string {
    const usedPercent = (this.used / this.disk_space) / 100;
    return usedPercent.toFixed(2) + '%';
  }


  get valid() {
    return !this.inValid ;
  };

  get inValid() {
    return this.errors && this.errors.length > 0 ;
  };

  constructor(
    public id: number = null,
    public disk_space: number = 0,
    public free: number = 0,
    public used: number = 0,
    public document_count: number = 0,
    public folder_count: number = 0,
    public used_in_human_size = 0
  ) {
    this.errors = [];
  }

}
