<div class="mat-dialog-inner-wrap">
  <ng-container [ngSwitch]="lang">
    <ng-template [ngSwitchCase]="'en'">
      <div class="mat-dialog-header">
        <h2 mat-dialog-title>Welcome to 5F</h2>
      </div>

      <div mat-dialog-content>
        <p class="align-center check-align">
          <mat-icon class="material-icons success">check</mat-icon>&nbsp;&nbsp;<span>Your registration has been successful.</span>
        </p>


        <p class="align-center">
          We will gladly provide you with all basic features for 14 days. You can upgrade and purchase a license at any time.
          <br>
          If you have been invited by a office/business partner, all free features will still be available to you after the trial period. You can also use the free client access immediately.
          <br><br>
          An overview of the features and prices can be found <a (click)="goToPrices()">here.</a>
          <br><br>
          If you have any questions, please contact our support team.
        </p>

        <p class="align-center">
          <b>
            Upon request, we will gladly provide you with an order for data processing.
          </b>
        </p>
      </div>
    </ng-template>

    <ng-template [ngSwitchCase]="'de'">
      <div class="mat-dialog-header">
        <h2 mat-dialog-title>Herzlich Willkommen bei 5F</h2>
      </div>

      <div mat-dialog-content>
        <p class="align-center check-align">
          <mat-icon class="material-icons success">check</mat-icon>&nbsp;&nbsp;<span>Ihre Registrierung war erfolgreich!</span>
        </p>


        <p class="align-center">
          Gerne stellen wir Ihnen 14 Tage lang alle Grundfunktionalitäten der 5F Kommunikationslösung zur Verfügung.
          Sie können jederzeit ein Upgrade durchführen und eine Lizenz erwerben.
          Nach der Testphase stehen Ihnen alle kostenlosen Funktionalitäten weiter zur Verfügung.
          <br><br>
          Eine Übersicht der Funktionalitäten und Preise finden Sie <a (click)="goToPrices()">hier.</a>
          <br><br>
          Bei Fragen wenden Sie sich gerne an unser Support Team.
        </p>

        <p class="align-center">
          <b>
            Gerne stellen wir Ihnen auf Wunsch einen Auftrag zur Datenverarbeitung bereit.
          </b>
        </p>
      </div>
    </ng-template>
  </ng-container>

  <div mat-dialog-actions class="d-flex justify-content-end">

    <button (click)="continueTestPeriod()" mat-raised-button type="button">
      {{ 'REGISTRATION.USE_TEST_VERSION' | translate }} ({{validilty}} {{ 'REGISTRATION.DAYS_REMAINING' | translate }})
    </button>

    <button class="fullwidth" mat-raised-button color="primary" type="button" (click)="goToPrices()">
      {{ 'REGISTRATION.BUY_LICENCE' | translate }}
    </button>

    <button *ngIf="currentUser?.attributes?.invitationType === 'business_partner'" class="fullwidth" mat-raised-button color="primary" type="button" (click)="endTrialVersion()">
      {{ 'REGISTRATION.USE_FREE_CLIENT_ACCOUNT' | translate }}
    </button>

  </div>
</div>
