import {FibuProcess} from './fibu-process';

export class FibuProcessBuilder {

  fromResponse(data): FibuProcess {
    return new FibuProcess(
      data.id,
      data.attributes.client_id,
      data.attributes.dms_folder_id,
      data.attributes.dms_path,
      data.attributes.dms_account_type,
    );
  }

  toRequest(_: FibuProcess) {
    return null;
  }
}
