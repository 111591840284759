import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {OrganizationSelectors} from 'app/+store/organization';
import {Observable, Subject, BehaviorSubject} from 'rxjs';
import {Organization} from 'app/models/organization.model';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {TaskStatusScheme} from 'app/+store/task-status-scheme/task-status-scheme';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {TaskStatusActions, TaskStatusSchemeActions, TaskStatusSchemeSelectors} from 'app/+store';
import {TaskColors, TaskType, TaskTypeTitle} from 'app/+store/task/task.interface';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TaskStatus} from 'app/+store/task-status/task-status';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {StringValidator} from 'app/lib/validator';
import {ITabNavRoute} from 'app/five-f/organization-card/models/tab-nav-route.interface';
import {ActivatedRoute} from '@angular/router';
import {Portal, TemplatePortal} from "@angular/cdk/portal";

@Component({
  selector: 'dvtx-tasks-settings-standard-frame',
  templateUrl: './tasks-settings-standard-frame.component.html',
  styleUrls: ['./tasks-settings-standard-frame.component.scss']
})
export class TasksSettingsStandardFrameComponent implements AfterViewInit, OnInit, OnDestroy {
  private onDestroy = new Subject();
  readonly TASK_TYPE = TaskType.Standard;
  selectdNewColor;

  taskTypes = TaskType;
  taskTypeTitle = TaskTypeTitle;
  taskColors = TaskColors;


  public organization$: Observable<Organization>;
  public statusesSchemas$: Observable<TaskStatusScheme[]>;
  public defaultStatusesScheme$: Observable<TaskStatusScheme>;
  public statusesSchemas: { [id: string]: TaskStatusScheme } = {};
  public execludedColors: { [id: string]: string[] } = {};
  public execludedTitles: { [id: string]: string[] } = {};

  public forms: { [id: string]: UntypedFormGroup } = {};
  public statusForms: { [id: string]: UntypedFormGroup } = {};

  public orgId;
  public routes: ITabNavRoute[] = [];
  public activeLink: string;

  @ViewChild('callToActionRef', { static: true }) callToActionRef: TemplateRef<any>;
  callToActionPortal: Portal<any>;

  constructor(private store: Store<AppState>,
              public fb: UntypedFormBuilder,
              public dialog: MatDialog,
              private _route: ActivatedRoute,
              private _viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.organization$ = this.store.select(OrganizationSelectors.getSelected)
      .pipe(filter(organization => !!organization), distinctUntilChanged());
    this.organization$.pipe(takeUntil(this.onDestroy)).subscribe((organization) => {
      if (organization && organization.id) {

        this.store.dispatch(new TaskStatusSchemeActions.LoadAll(true));
        this.store.dispatch(new TaskStatusSchemeActions.LoadDefault);

        this.defaultStatusesScheme$ = this.store.select(TaskStatusSchemeSelectors.getDefaultStatusSchemes());
        this.statusesSchemas$ = this.store.select(TaskStatusSchemeSelectors.getAllTaskStatusSchemes);
        this.statusesSchemas$.pipe(takeUntil(this.onDestroy)).subscribe((schemes) => {
          this.execludedColors = {};
          this.execludedTitles = {};

          const forms: { [id: string]: UntypedFormGroup } = {};
          Object.keys(this.taskTypes).forEach((key) => {
            forms[this.taskTypes[key]] = this.fb.group({
              title: [null, [Validators.required, StringValidator.noWhitespaceValidator]],
              taskType: [this.taskTypes[key], Validators.required],
              description: [null],
              isDefault: [true],
              isPublished: [true]
            });
            this.statusForms[this.taskTypes[key]] = this.fb.group({
              title: [
                null, [
                  Validators.required,
                  StringValidator.noWhitespaceValidator,
                  (control) => StringValidator.notExistedIn(control.value, this.execludedTitles[this.taskTypes[key]])
                ]
              ],
              color: [null, Validators.required],
              statusScheme: [null, Validators.required]
            });
          });

          schemes.map((scheme: TaskStatusScheme) => {
            if (forms[scheme.taskType]) {
              this.statusesSchemas[scheme.taskType] = scheme;
              forms[scheme.taskType] = this.fb.group({
                title: [
                  scheme.title, [
                    Validators.required,
                    (control) => StringValidator.notExistedIn(control.value, this.execludedTitles[scheme.taskType])
                  ]
                ],
                taskType: [scheme.taskType, Validators.required],
                description: [scheme.description],
                isDefault: [true],
                isPublished: [true]
              });

              this.statusForms[scheme.taskType].get('statusScheme').setValue(scheme.id);

              scheme.statuses.forEach(status => {
                if (!this.execludedColors[scheme.taskType]) {
                  this.execludedColors[scheme.taskType] = [];
                }
                if (!this.execludedTitles[scheme.taskType]) {
                  this.execludedTitles[scheme.taskType] = [];
                }
                this.execludedColors[scheme.taskType].push(status.color);
                this.execludedTitles[scheme.taskType].push(status.title);
              });
            }
          });

          this.forms = forms;
        });
      }
    })

    this.orgId = this._route.snapshot.params.id;
    this.routes = [{
      title: 'TASK.TASK_TYPES.STANDARD',
      route: `/organization/${this.orgId}/tasks-settings/standard`
    }, {
      title: 'TASK.TASK_TYPES.INVOICE_APPROVAL',
      route: `/organization/${this.orgId}/tasks-settings/approval`,
    }];
    this.activeLink = this.routes[0].title;
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.callToActionPortal = this._createPortal(this.callToActionPortal, this.callToActionRef);
    });
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }

  saveScheme(form: UntypedFormGroup, scheme: TaskStatusScheme = null) {
    if (!scheme) {
      this.store.dispatch(new TaskStatusSchemeActions.Create(form.value));
      return;
    }
    const control = new UntypedFormControl(scheme.id);
    form.addControl('id', control);
    this.store.dispatch(new TaskStatusSchemeActions.Edit(form.value));
  }

  addStatus(form: UntypedFormGroup) {
    this.store.dispatch(new TaskStatusActions.Create(form.value));
    form.get('title').reset();
    form.get('color').reset();
    this.selectdNewColor = null;
    setTimeout(_ => {
      this.store.dispatch(new TaskStatusSchemeActions.LoadOne(form.get('statusScheme').value));
    }, 500);
  }

  deleteStatus(status: TaskStatus) {
    const body = 'TASK.DELETE_STATUS';
    const dialogRef = this.dialog.open(DeleteStatusDialogComponent, {
      panelClass: 'dialog-sm',
      data: {title: 'TASK.DELETE_STATUS', body: body}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new TaskStatusActions.Delete(status));
        setTimeout(_ => {
          this.store.dispatch(new TaskStatusSchemeActions.LoadOne(status.statusScheme));
        }, 500);
      }
    });
  }

  editStatus(status: TaskStatus, scheme) {
    const dialogRef = this.dialog.open(EditStatusDialogComponent, {
      data: {
        status: status,
        execludedColors: this.execludedColors[scheme],
        execludedTitles: this.execludedTitles[scheme],
        onSaveChanges: (form: UntypedFormGroup) => {
          this.store.dispatch(new TaskStatusActions.Edit(form.value));
          setTimeout(_ => {
            this.store.dispatch(new TaskStatusSchemeActions.LoadOne(form.get('statusScheme').value));
          }, 500);
          dialogRef.close();
        }
      }
    })
  }

  selectNewColor(color, form: UntypedFormGroup) {
    this.selectdNewColor = color;
    form.get('color').setValue(color);
  }
}


@Component({
  selector: 'dvtx-edit-status-dialog',
  template: `
    <div class="mat-dialog-inner-wrap">
      <div class="mat-dialog-header">
        <button class="mat-dialog-close" mat-mini-fab mat-dialog-close>
          <mat-icon aria-label="close dialog">clear</mat-icon>
        </button>
        <h2 mat-dialog-title>
          {{ 'TASK.EDIT_STATUS' | translate }}
        </h2>
      </div>

      <div mat-dialog-content *ngIf="form">
        <div class="row mb-2 justify-content-center align-items-center" [formGroup]="form">
          <div class="col-12 col-sm-10 d-flex align-items-center">
            <mat-form-field>
              <input class="m-0 p-0" matInput formControlName="title">
              <mat-error
                *ngIf="form.get('title').errors?.existed">{{ 'ORGANIZATION_NAVIGATON.STATUS_ALREADY_EXISTS_ERROR' | translate}}</mat-error>
            </mat-form-field>
            <dvtx-color-picker
              [colors]="taskColors | enumValues"
              [execluded]="execludedColors | async"
              [selected]="selectdColor"
              (onSelection)="selectColor($event)">
            </dvtx-color-picker>
          </div>
        </div>
      </div>

      <div mat-dialog-actions class="d-flex justify-content-end">
        <button type="button" mat-button mat-dialog-close>
          {{ 'GENERAL.CANCEL_ACTION' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="saveChanges()"
          [disabled]="form.invalid"
        >{{ 'GENERAL.SAVE_ACTION' | translate }}</button>
      </div>
    </div>
  `,
  styleUrls: ['./tasks-settings-standard-frame.component.scss']
})
export class EditStatusDialogComponent {
  form: UntypedFormGroup;
  taskColors = TaskColors;
  execludedColors: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  execludedTitles: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  selectdColor;

  constructor(
    public dialogRef: MatDialogRef<EditStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder
  ) {
    if (data.status) {
      this.form = this.fb.group({
        id: [data.status.id],
        title: [
          data.status.title, [
            Validators.required,
            StringValidator.noWhitespaceValidator,
            (control) => StringValidator.notExistedIn(control.value, data.execludedTitles.filter(t => t !== data.status.title))
          ]
        ],
        color: [data.status.color, Validators.required],
        statusScheme: [data.status.statusScheme, Validators.required]
      });
      this.selectdColor = this.form.get('color').value;
    }
    if (data.execludedColors) {
      this.execludedColors.next(data.execludedColors);
    }
    if (data.execludedTitles) {
      this.execludedTitles.next(data.execludedTitles);
    }
  }

  saveChanges() {
    this.data.onSaveChanges(this.form);
  }

  selectColor(color) {
    this.selectdColor = color;
    this.form.get('color').setValue(color);
  }
}

@Component({
  selector: 'dvtx-delete-status-dialog',
  template: `
    <div class="mat-dialog-inner-wrap">
      <div mat-dialog-content>
        <div class="d-flex justify-content-center mb-1">
          <mat-icon aria-hidden="true">warning</mat-icon>
        </div>
        <span class="p-1" [innerHTML]="data?.body | translate"></span>
      </div>

      <div mat-dialog-actions class="d-flex justify-content-end">
        <button type="button" mat-button mat-dialog-close>
          {{ 'GENERAL.CANCEL_ACTION' | translate }}
        </button>
        <button mat-raised-button color="warn" [mat-dialog-close]="true">
          {{'GENERAL.DELETE_ACTION' | translate}}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./tasks-settings-standard-frame.component.scss']
})
export class DeleteStatusDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DeleteStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
}
