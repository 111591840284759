import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import * as processContextActions from '../../../../actions/process-context.actions';

@Component({
  template: '<h3>Autorisiere Zugriff...</h3>'
})
export class AuthenticatedAccessDispatcherComponent implements OnInit {
  private _context$;

  constructor(private _store: Store<AppState>,
              private _route: ActivatedRoute) {
    this._context$ = _store.select('processContext');
  }

  ngOnInit(): void {
    this._validateAccess();
  }

  _validateAccess = () => {
    this._route
      .params
      .subscribe(params => {
        const token = params['token'];
        if (token && token.length > 0) {
          this._store.dispatch(new processContextActions.ValidateAccess(token));
        }
      });
  }
}
