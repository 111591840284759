import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, combineLatest, Subject, BehaviorSubject} from 'rxjs';
import {ContactListDto, contactListDtoType} from 'app/models/contact-list-dto.model';
import {filter, first, map, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ContactSelectors} from 'app/+store/contact';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {LoadAll} from 'app/+store/contact/contact.actions';
import {OrganizationSelectors} from 'app/+store/organization';
import {DvtxControlValueAccessor} from 'app/shared/modules/base-form-elements/components/1_control-value-accessor-components/DvtxControlValueAccessor';
import {AngularTokenService} from 'angular-token';
import {DmsContact} from 'app/+store/dms-contact/dms-contact';
import {DmsFolder} from 'app/+store/dms-folder/dms-folder';
import {DmsContactService} from 'app/+store/dms-contact/dms-contact.service';

@Component({
  selector: 'dvtx-person-autocomplete',
  templateUrl: './person-autocomplete.component.html',
  styleUrls: ['./person-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonAutocompleteComponent),
      multi: true,
    }
  ]
})
export class PersonAutocompleteComponent extends DvtxControlValueAccessor implements OnInit, OnChanges, OnDestroy {
  onDestroy = new Subject();

  myControl = new UntypedFormControl();
  filteredOptions: Observable<DmsContact[]>;
  contacts$: BehaviorSubject<DmsContact[]> = new BehaviorSubject([]);

  @Input() set dmsFolder(f: DmsFolder) {
    if (f) {
      this._contactSvc.getAll(f.dmsAccountType)
        .pipe(first())
        .subscribe(contacts => {
          const cs = contacts.filter(contact => contact && contact.email);
          this.contacts$.next(cs);
        }, (err) => console.error(err));
    }
  }

  @Input()
  excludedIds: string[] = [];

  @Input() set value(val: string) {
    this.writeValue(val);
  }

  @Output()
  onSelect = new EventEmitter();

  constructor(
    protected injector: Injector,
    private store: Store<AppState>,
    private _tokenSvc: AngularTokenService,
    private _contactSvc: DmsContactService
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnChanges(changes) {
    if (changes.excludedIds) {
      this.filteredOptions =
        combineLatest(
          this.myControl.valueChanges,
          this.contacts$,
        ).pipe(
          map(([value, contacts]: [string, DmsContact[]]) => {
            const filteredContacts = this._filter(value, contacts);
            return filteredContacts.filter(contact => (
                contact.email !== this._tokenSvc.currentAuthData.uid && !this.excludedIds.includes(contact.email)
              )
            );
          })
        );
    }
  }

  private _filter(value: string, contacts: DmsContact[]): DmsContact[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return contacts.filter((entry: DmsContact) => {
        const email = entry.email || '';
        const name = `${entry.firstName} ${entry.lastName}` || '';
        const userName = entry.userName || '';
        return userName.toLowerCase().indexOf(filterValue) >= 0
          || name.toLowerCase().indexOf(filterValue) >= 0
          || email.toLowerCase().indexOf(filterValue) >= 0
      }).slice(0, 5);
    } else {
      return [];
    }
  }

  writeValue(obj: any): void {
    this.myControl.setValue(obj);
  }


  contactSelected($event: MatAutocompleteSelectedEvent) {
    this.notifyOnChange($event.option.value);
    this.onSelect.emit($event.option.value);
    this.writeValue(null);
  }

  // Used to display the text in the input field.
  displayFn(contact: DmsContact): string {
    if (!contact) return '';
    return contact ? contact.email : contact.userName;
  }

  onKeyEnter() {
    this.onSelect.emit(this.myControl.value);
    // if (typeof this.myControl.value !== 'string' && !this.valueIsEmail) {
    //   this.onSelect.emit(this.myControl.value);
    // } else if (this.valueIsEmail) {
    //   const value = (this.myControl.value && this.myControl.value.email) ? this.myControl.value.email : this.myControl.value;
    //   this.onSelect.emit(value);
    // }
    // this.writeValue(null);
  }

  onBlurEventHandler() {
    // if (typeof this.myControl.value !== 'string' && !this.valueIsEmail) {
    //   this.notifyOnChange(this.myControl.value);
    // } else if (this.valueIsEmail) {
    //   const value = (this.myControl.value && this.myControl.value.email) ? this.myControl.value.email : this.myControl.value;
    //   this.notifyOnChange(value);
    // }
  }
}
