import {FileCardFile} from '../models/file-card-file';
import {environment} from 'environments/environment';

export class FileCardFileBuilder {
  public static fromResponseList(response): FileCardFile[] {
    const ret: FileCardFile[] = [];
    for (const item of response.data) {
      ret.push(FileCardFileBuilder.fromResponse(item));
    }
    return ret;
  }

  public static fromResponse(data): FileCardFile {
    const ret = new FileCardFile();
    ret.id = data['id'];
    ret.size = parseInt(data['attributes']['file_file_size'], 10);
    ret.filename = data['attributes']['file_file_name'];
    ret.lastChangeDate = data['attributes']['file_updated_at'];
    ret.url = environment.token_service_config.apiBase + data['attributes']['link'];
    return ret;
  }
}
