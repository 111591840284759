import {of as observableOf} from 'rxjs';

import {startWith} from 'rxjs/operators';

import {share} from 'rxjs/operators';

import {catchError} from 'rxjs/operators';

import {shareReplay} from 'rxjs/operators';

import {switchMap} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

import {AuditThirdPartyConfirmationService} from 'app/+store/audit-third-party-confirmation/audit-third-party-confirmation.service';
import {Observable} from 'rxjs';
import {ThirdPartyConfirmationContribution} from 'app/modules/third-party-confirmation/models/contribution';
import {Subscription} from 'rxjs';
import {ProcessArtifact} from 'app/+store/process-artifact/process-artifact';
import {Subject} from 'rxjs';
import {combineLatest} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'dvtx-request-confirmation',
  templateUrl: './third-party-request-confirmation.component.html',
  styleUrls: ['./third-party-request-confirmation.component.scss']
})
export class ThirdPartyRequestConfirmationComponent implements OnInit {
  public thirdPartyContribution$: Observable<ThirdPartyConfirmationContribution>;
  public allFiles: Observable<ProcessArtifact[]>;
  public uploadManualReload = new Subject<void>();
  public submitToken: Observable<string>;
  public accessDenied = false;

  contactForm: UntypedFormGroup;
  token: string = null;
  contactConfirmed = false;
  sendOngoing = false;
  success = false;

  constructor(private route: ActivatedRoute,
              private thirdPartyConfirmationService: AuditThirdPartyConfirmationService,
              private _fb: UntypedFormBuilder) {
    this.contactForm = this._fb.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      email: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.thirdPartyContribution$ = this.route.params.pipe(
      switchMap((params) => {
        this.token = params.token;
        return this.thirdPartyConfirmationService.getThirdPartyConfirmationContribution(params.token).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 404 || err.status === 401) {
              this.accessDenied = true;
            } else {
              console.error(err);
            }
            return observableOf(null);
          }));
      }),
      first(),
      shareReplay(1),);
    this.allFiles = combineLatest(
      this.route.params,
      this.uploadManualReload.pipe(startWith(undefined)),
      (params) => params
    ).pipe(
      switchMap((params) => {
        return this.thirdPartyConfirmationService.getExternalDownloads(params.token);
      }), share(),);

    this.submitToken = combineLatest(this.thirdPartyContribution$, this.route.params)
      .pipe(map(([thirdPartyContribution, params]): string | undefined => {
        return !thirdPartyContribution.third_party_company_name ? params.token : undefined;
      }));
  }

  onSubmit(thirdPartyContribution) {
    this.sendOngoing = true;
    this.thirdPartyConfirmationService.updateThirdPartyConfirmationContribution(this.route.snapshot.params.token, thirdPartyContribution).pipe(
      first())
      .subscribe(thirdParty => {
        this.success = true;
        this.sendOngoing = false;
      }, err => {
        this.sendOngoing = false;
      });
  }
}

export function bindFormAndAttributes(formBuilder: UntypedFormBuilder, keys: string[], attributes: { [key: string]: any }, validators = undefined): [UntypedFormGroup, Subscription] {
  const formTemplate = {};
  for (const key of keys) {
    if (validators && validators[key]) {
      formTemplate[key] = [attributes[key], validators[key]];
    } else {
      formTemplate[key] = [attributes[key]];
    }
  }

  const form = formBuilder.group(formTemplate);

  const subscription = form.valueChanges.subscribe((value) => {
    for (const key of keys) {
      attributes[key] = value[key];
    }
  });

  return [form, subscription];
}

type NestedRows = { [key: string]: string }[];

export class BindFormAndAttributesAsArray {
  public form: UntypedFormGroup;
  public subscriptions: Subscription[] = [];

  constructor(private formBuilder: UntypedFormBuilder, private arrayKey: string, private formControlName: string, private keys: string[], private data: { [key: string]: string | any[] }) {
    if (!data[arrayKey]) {
      data[arrayKey] = [];
    }

    this.form = this.formBuilder.group({
      [formControlName]: this.formBuilder.array(
        (this.data[this.arrayKey] as NestedRows).map(this.getNewFormGroup)
      ),
    });
  }

  public addNewRow() {
    const newRow = {};
    (this.data[this.arrayKey] as NestedRows).push(newRow);
    (this.form.get(this.formControlName) as UntypedFormArray).push(this.getNewFormGroup(newRow))
  }

  public deleteRow(idx: number) {
    (this.data[this.arrayKey] as NestedRows).splice(idx, 1);
    (this.form.get(this.formControlName) as UntypedFormArray).removeAt(idx);
  }

  private getNewFormGroup = (row): UntypedFormGroup => {
    const [formGroup, subscription] = bindFormAndAttributes(this.formBuilder,
      this.keys, row);

    this.subscriptions.push(subscription);
    return formGroup;
  };

  public unsubscribe() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
