/**
 * Service to show the notification snackbar.
 *
 * Author: Andreas Baier <andreas.baier@paperbird.org>
 * Usage:
 *    import { NotificationService } from 'app/shared/modules/notification/services/notification.service';
 *    constructor(...private _notificationSvc: NotificationService) {
 *    ...
 *    setTimeout(() => this._notificationSvc.info('Meldungs-Text'), 2000);
 */
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarMessageComponent } from '../containers/snackbar-message/snackbar-message.component';

@Injectable()
export class NotificationService {
  autoHide = 5000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public snackBar: MatSnackBar, private _translateSvc: TranslateService) {}

  success(message, object = null, autoHide = 5000, verticalPosition: MatSnackBarVerticalPosition = 'top', horizontalPosition: MatSnackBarHorizontalPosition = 'right') {
    message =  this._translateSvc.instant(message, object);
    this.horizontalPosition = horizontalPosition;
    this.verticalPosition = verticalPosition;
    const config = this._config(message, autoHide);
    config['panelClass'] = ['dvtx-snackbar', 'dvtx-snackbar-success'];
    this.snackBar.openFromComponent(SnackbarMessageComponent, config);
  }

  info(message, object = null, autoHide = 5000, verticalPosition: MatSnackBarVerticalPosition = 'top', horizontalPosition: MatSnackBarHorizontalPosition = 'right') {
    message =  this._translateSvc.instant(message, object);
    this.horizontalPosition = horizontalPosition;
    this.verticalPosition = verticalPosition;
    const config = this._config(message, autoHide);
    config['panelClass'] = ['dvtx-snackbar', 'dvtx-snackbar-info'];
    this.snackBar.openFromComponent(SnackbarMessageComponent, config);
  }

  warning(message, object = null, autoHide = 5000, verticalPosition: MatSnackBarVerticalPosition = 'top', horizontalPosition: MatSnackBarHorizontalPosition = 'right') {
    message =  this._translateSvc.instant(message, object);
    this.horizontalPosition = horizontalPosition;
    this.verticalPosition = verticalPosition;
    const config = this._config(message, autoHide);
    config['panelClass'] = ['dvtx-snackbar', 'dvtx-snackbar-warning'];
    this.snackBar.openFromComponent(SnackbarMessageComponent, config);
  }

  error(message, object = null, autoHide = 5000, verticalPosition: MatSnackBarVerticalPosition = 'top', horizontalPosition: MatSnackBarHorizontalPosition = 'right') {
    message =  this._translateSvc.instant(message, object);
    this.horizontalPosition = horizontalPosition;
    this.verticalPosition = verticalPosition;
    const config = this._config(message, autoHide);
    config['panelClass'] = ['dvtx-snackbar', 'dvtx-snackbar-error'];
    this.snackBar.openFromComponent(SnackbarMessageComponent, config);
  }

  default(message, object = null, autoHide = 5000, verticalPosition: MatSnackBarVerticalPosition = 'top', horizontalPosition: MatSnackBarHorizontalPosition = 'right') {
    message =  this._translateSvc.instant(message, object);
    this.horizontalPosition = horizontalPosition;
    this.verticalPosition = verticalPosition;
    const config = this._config(message, autoHide);
    config['panelClass'] = ['dvtx-snackbar', 'dvtx-snackbar-default'];
    this.snackBar.openFromComponent(SnackbarMessageComponent, config);
  }

  private _config(message, autoHide = 5000): MatSnackBarConfig {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    if (autoHide) {
      config.duration = autoHide;
    } else {
      config.duration = this.autoHide;
    }
    config.data = { message: message };
    return config;
  }
}
