import {Action} from '@ngrx/store';
import {DocumentPreviewPreview} from './document-preview-preview';

export enum DocumentPreviewPreviewActionTypes {
  RequestPreview = '[DocumentPreviewPreview] RequestPreview',
  RequestPreviewSuccess = '[DocumentPreviewPreview] RequestPreview Success',
  RequestPreviewFail = '[DocumentPreviewPreview] RequestPreview Fail'
}

export class RequestPreview implements Action {
  readonly type = DocumentPreviewPreviewActionTypes.RequestPreview;

  constructor(public document_id: string, public preview_id: string) {
  }
}

export class RequestPreviewSuccess implements Action {
  readonly type = DocumentPreviewPreviewActionTypes.RequestPreviewSuccess;

  constructor(public preview: DocumentPreviewPreview) {
  }
}

export class RequestPreviewFail implements Action {
  readonly type = DocumentPreviewPreviewActionTypes.RequestPreviewFail;

  constructor(public payload: any = null) {
  }
}

export type DocumentPreviewPreviewActions =
  | RequestPreview
  | RequestPreviewSuccess
  | RequestPreviewFail;
