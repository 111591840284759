import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {MembershipEffects} from './membership.effects';
import {reducer} from './membership.reducer';
import {stateKey} from './membership.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([MembershipEffects])
  ],
  providers: [MembershipEffects]
})
export class MembershipStoreModule {
}
