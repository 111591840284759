import {TaskStatusSchemeActions, TaskStatusSchemeActionTypes} from './task-status-scheme.actions';
import {adapter, initialState, State} from './task-status-scheme.state';
import {TaskStatusScheme} from "./task-status-scheme";

export function reducer(state = initialState, action: TaskStatusSchemeActions): State {
  const current = state.selected;
  switch (action.type) {
    case TaskStatusSchemeActionTypes.LoadAllFail:
    case TaskStatusSchemeActionTypes.LoadOneFail:
    case TaskStatusSchemeActionTypes.LoadDefaultFail:
    case TaskStatusSchemeActionTypes.CreateFail:
    case TaskStatusSchemeActionTypes.EditFail:
    case TaskStatusSchemeActionTypes.DeleteFail:
      return {
        ...state,
        loading: false
      };
    case TaskStatusSchemeActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case TaskStatusSchemeActionTypes.LoadAllSuccess:
      if (action.reset) {
        let found: TaskStatusScheme = null;
        if (current) {
          found = action.statusSchemes.find(scheme => scheme.id === current.id);
        }
        return adapter.setAll(action.statusSchemes, {
            ...state,
            selected: found,
            loading: false,
        });
      } else {
        return adapter.upsertMany(action.payload, {
          ...state,
          selected: current ? state.entities[current.id] : null,
          loading: false,
        });
      }

    case TaskStatusSchemeActionTypes.LoadOne:
    case TaskStatusSchemeActionTypes.LoadDefault:
      return {
        ...state,
        loading: true
      };
    case TaskStatusSchemeActionTypes.LoadOneSuccess:
    case TaskStatusSchemeActionTypes.LoadDefaultSuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: false,
      });

    case TaskStatusSchemeActionTypes.Create:
      return {
        ...state,
        loading: true,
      };

    case TaskStatusSchemeActionTypes.CreateSuccess:
    return adapter.upsertOne(action.scheme , {
      ...state,
      loading: false,
    });

    case TaskStatusSchemeActionTypes.Edit:
      return {
        ...state,
        loading: true,
      };

    case TaskStatusSchemeActionTypes.EditSuccess:
    return adapter.upsertOne(action.scheme, {
      ...state,
      loading: false,
    });

    case TaskStatusSchemeActionTypes.Delete:
      return {
        ...state,
        loading: true,
      };

    case TaskStatusSchemeActionTypes.DeleteSuccess:
    return adapter.removeOne(action.scheme.id , {
      ...state,
      loading: false,
    });
  }
  return state;
}
