import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './organization.state';

export const stateKey = 'organization';
const getOrganizationState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getOrganizationEntities,
  selectAll: getAllOrganizations,
} = adapter.getSelectors(getOrganizationState);

export const getSelectedId = createSelector(
  getOrganizationState,
  state => state.selected
);

export const getSelected = createSelector(
  getOrganizationEntities,
  getSelectedId,
  (entities, id) => entities[id]
);

export const getById = (id: string) => createSelector(
  getOrganizationEntities,
  (entities) => entities[id]
);

export const loadingState = createSelector(
  getOrganizationState,
  state => state.loading
);

