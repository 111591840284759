import {ICollectorExport} from './collector-export.interface';

export class CollectorExport implements ICollectorExport {
  readonly type = 'collector_export';

  constructor(
    public id = null,
    public attributes) {
  }
}
