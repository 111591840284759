import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { stateKey } from './item-labels.selectors';
import { reducer } from './item-labels.reducer';
import { ItemLabelsEffects } from './item-labels.effects';
import { ItemLabelsService } from './item-labels.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ItemLabelsEffects])
  ],
  providers: [
    ItemLabelsEffects,
    ItemLabelsService
  ]
})
export class ItemLabelsStoreModule {
}
