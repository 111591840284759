import { ICommand } from './icommand';

/**
 * 'No'-opereation. Command for testing purpose.
 * Does nothing.
 */
export class NoopCommand implements ICommand {
  constructor(private _message = 'NoopCommand#execute: No Op.',
              private _continueControlFlow: boolean = false) {}

  execute() {
    console.log(this._message);
    return this._continueControlFlow;
  }
}
