import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {DvtxControlValueAccessor} from '../../DvtxControlValueAccessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'dvtx-number-input-cva',
  templateUrl: './number-input-cva.component.html',
  styleUrls: ['./number-input-cva.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputCvaComponent),
      multi: true,
    }
  ]
})
export class NumberInputCvaComponent extends DvtxControlValueAccessor implements OnInit {

  @Input()
  disabled = false;

  @Input()
  innerValue: number;

  @Input()
  unit = 'Stück';

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {
  }

  writeValue(obj: any): void {
    this.innerValue = obj;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
