<div class="five-f-sidebar-dialog-container" *ngVar="(client$ | async) as client">
  <div class="mat-dialog-inner-wrap">
    <div mat-dialog-content #dialogContent>
      <mat-button-toggle-group class="five-f-mat-button-toggle-group" #group="matButtonToggleGroup"
                               [(ngModel)]="selectedTab" (ngModelChange)="selectedTabChanged($event)">
        <mat-button-toggle [value]="0" [disableRipple]="true">
          <span>{{'SETTINGS.SETTINGS' | translate}}</span>
        </mat-button-toggle>
        <mat-button-toggle [value]="1" [disableRipple]="true">
          <span *ngIf="isContact">{{'CONTACTS.ASSOCIATED_CLIENTS' | translate}}</span>
          <span *ngIf="isClient">{{'CONTACTS.ASSOCIATED_CONTACTS' | translate}}</span>
        </mat-button-toggle>
        <mat-button-toggle [value]="2" [disableRipple]="true" *ngIf="showFibu && isClient">
          <span>{{'FIBU.CLIENT_SETTINGS' | translate}}</span>
        </mat-button-toggle>
        <mat-button-toggle [value]="3" [disableRipple]="true" *ngIf="(featureSet$ | async)?.hasDatev && isClient">
          <span>{{'DATEV.AUTHORIZE_CLIENT_TITLE' | translate}}</span>
        </mat-button-toggle>
        <mat-button-toggle [value]="4" [disableRipple]="true" *ngIf="showMobileActivation() | async">
          <span>{{'ADDRESSBOOK.MOBILE_ACTIVATION.TITLE' | translate}}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <ng-container [ngSwitch]="selectedTab">
        <ng-template [ngSwitchCase]="0">
          <dvtx-client-selection
            *ngIf="isClient"
            [position]="'start'"
            [readonly]="!client?.canEditClient"
            (onEditSuccess)="editSuccess($event)"
            [action]="'Form'"
            [selectedClient]="client"
            (onFormUpdate)="updateActionButtons($event)"
            #clientSelectionComponent></dvtx-client-selection>

          <dvtx-edit-contact-organization-dialog
            *ngIf="contact && isContact && contact?.type === contactListDtoType.organizationContact"
            [hasAccount]="contact?.hasAccount"
            [contactId]="contact?.id"
            [createdBy]="contact?.createdBy">
          </dvtx-edit-contact-organization-dialog>

          <dvtx-edit-contact-person-dialog
            *ngIf="contact && isContact && contact?.type === contactListDtoType.naturalPersonContact"
            [contactId]="contact?.id"
            [createdBy]="contact?.createdBy"
            (onSave)="closeSidebar($event)"
          ></dvtx-edit-contact-person-dialog>
        </ng-template>

        <ng-template [ngSwitchCase]="1">
          <dvtx-participant-client-link [selectedClient]="client" [selectedContact]="contact" [isContact]="isContact"
                                        [isClient]="isClient"
                                        (updateMapping)="updateMapping($event)"></dvtx-participant-client-link>
        </ng-template>

        <ng-template [ngSwitchCase]="2" *ngIf="showFibu && isClient">
          <dvtx-client-bookman (onSave)="updateBookmanSettings($event)" [id]="client?.id"></dvtx-client-bookman>
        </ng-template>

        <ng-template [ngSwitchCase]="3" *ngIf="isClient && showDatevLogin">
          <dvtx-client-datev-login *ngIf="client$ | async" [client]="client$ | async"></dvtx-client-datev-login>
        </ng-template>

        <ng-template [ngSwitchCase]="4" *ngIf="showMobileActivation() | async">
          <dvtx-mobile-activation-panel [userEmail]="contact?.email"></dvtx-mobile-activation-panel>
        </ng-template>
      </ng-container>
    </div>

    <div mat-dialog-actions class="d-flex justify-content-end">
      <button *ngIf="isClient && selectedTab === 0" mat-raised-button
              color="primary"
              [disabled]="form?.pristine || form?.invalid"
              (click)="saveClient()">
        <mat-icon mataffix>save</mat-icon>
        {{ 'GENERAL.SAVE_ACTION' | translate }}
      </button>

      <button *ngIf="!(isContact && selectedTab === 0)" type="button" mat-button mat-dialog-close>
        {{ 'GENERAL.CLOSE_ACTION' | translate }}
      </button>
    </div>
  </div>
</div>
