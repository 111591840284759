import {createEntityAdapter, EntityState} from '@ngrx/entity';
import * as dayjs from 'dayjs';
import {ProcessEvent} from '../process-event/process-event';

export interface State extends EntityState<ProcessEvent> {
  loading: boolean;
}

export const adapter = createEntityAdapter<ProcessEvent>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  loading: false,
});
