import { ILocale } from '../models/locale.interface';

export namespace LocaleBuilder {
  export function fromResponse(response, _catalog = null): ILocale {
    const attrs = response.attributes;
    return { id: response.id, type: response.type, code: attrs.code, name: attrs.name };
  }

  export function toRequest(_locale) {
    return null;
  }
}
