import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ProcessRoleService} from './process-role.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ProcessRoleService
  ]
})
export class ProcessRoleStoreModule {
}
