import {Action} from '@ngrx/store';
import * as model from './tenant';
import * as modelInterface from './tenant.interface';

export enum OperatorTenantActionTypes {
  Create = '[Operator.Tenant] Create',
  CreateSuccess = '[Operator.Tenant] Create Success',
  CreateFail = '[Operator.Tenant] Create Fail',
  Remove = '[Operator.Tenant] Remove',
  RemoveSuccess = '[Operator.Tenant] Remove Success',
  RemoveFail = '[Operator.Tenant] Remove Fail',
  Save = '[Operator.Tenant] Save',
  SaveSuccess = '[Operator.Tenant] Save Success',
  SaveFail = '[Operator.Tenant] Save Fail',
  LoadAll = '[Operator.Tenant] Load All',
  LoadAllSuccess = '[Operator.Tenant] Load All Success',
  LoadAllFail = '[Operator.Tenant] Load All Fail',
  LoadOne = '[Operator.Tenant] Load One',
  LoadOneSuccess = '[Operator.Tenant] Load One Success',
  LoadOneFail = '[Operator.Tenant] Load One Fail'
}

export class Create implements Action {
  readonly type = OperatorTenantActionTypes.Create;

  constructor(public params: modelInterface.Operator.ITenantParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = OperatorTenantActionTypes.CreateSuccess;

  constructor(public tenant: model.Operator.Tenant) {
  }
}

export class CreateFail implements Action {
  readonly type = OperatorTenantActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = OperatorTenantActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = OperatorTenantActionTypes.RemoveSuccess;

  constructor(public tenant: model.Operator.Tenant) {
  }
}

export class RemoveFail implements Action {
  readonly type = OperatorTenantActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = OperatorTenantActionTypes.Save;

  constructor(public tenant: model.Operator.Tenant) {
  }
}

export class SaveSuccess implements Action {
  readonly type = OperatorTenantActionTypes.SaveSuccess;

  constructor(public tenant: model.Operator.Tenant) {
  }
}

export class SaveFail implements Action {
  readonly type = OperatorTenantActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = OperatorTenantActionTypes.LoadAll;

  constructor(public userId: number = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = OperatorTenantActionTypes.LoadAllSuccess;

  constructor(public tenants: model.Operator.Tenant[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = OperatorTenantActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = OperatorTenantActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = OperatorTenantActionTypes.LoadOneSuccess;

  constructor(public tenant: model.Operator.Tenant) {
  }
}

export class LoadOneFail implements Action {
  readonly type = OperatorTenantActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type OperatorTenantActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
