import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {ProcessEvent} from '../process-event/process-event';
import {ProcessEventBuilder} from '../process-event/process-event.builder';
import {AuditTrailBuilder} from './audit-trail.builder';
import {AuditTrail} from './audit-trail';
import {AuditTrailDownloadFormat} from './audit-trail.interface';

@Injectable()
export class AuditTrailService {
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';

  constructor(private _http: ApiResourceService) {}

  getAll(processId: string): Observable<ProcessEvent[]> {
    const builder = new ProcessEventBuilder(processId);
    return <Observable<ProcessEvent[]>>this._http.get<ProcessEventBuilder, ProcessEvent>(builder, `${this.BASE_PATH}/${processId}/audit_trails`);
  }

  getEventsFor(processId, page) {
    const builder = new ProcessEventBuilder(processId);
    return <Observable<ProcessEvent[]>>this._http.get<ProcessEventBuilder, ProcessEvent>(builder, `${this.BASE_PATH}/${processId}/audit_trails?page=${page}`);
  }

  download(processId: string, format: AuditTrailDownloadFormat) {
    switch (format) {
      case AuditTrailDownloadFormat.PDF:
        return this.downloadPDF(processId);
      case AuditTrailDownloadFormat.TXT:
        return this.downloadTXT(processId);
    }
    throw Error('Unknown download format');
  }

  downloadTXT(processId: string) {
    const builder = new AuditTrailBuilder();
    return <Observable<AuditTrail>>this._http.get<AuditTrailBuilder, AuditTrail>(builder, `${this.BASE_PATH}/${processId}/audit_trail/to_txt`);
  }

  downloadPDF(processId: string) {
    const builder = new AuditTrailBuilder();
    return <Observable<AuditTrail>>this._http.get<AuditTrailBuilder, AuditTrail>(builder, `${this.BASE_PATH}/${processId}/audit_trail/to_pdf`);
  }
}
