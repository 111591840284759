import {of, throwError as observableThrowError} from 'rxjs';
import {ApiError} from '../models/api-error';
import {HttpErrorResponse} from '@angular/common/http';

export namespace ApiErrorBuilder {

  export function fromResponse(response: HttpErrorResponse, status = null): ApiError[] {
    const errors: ApiError[] = [];
    if (response.error) {
      if (response.error.errors) {
        response.error.errors.forEach((e) => {
          const apiError = new ApiError(e.code, e.title, e.details, e.source);
          apiError.status = status;
          errors.push(apiError);
        });
      }
    }
    return errors;
  }

  export function handleArrayError(error) {
    switch (error.status) {
      case 401:
        console.error('Unauthorized');
        return of([]);
      case 404:
        console.error('Not found');
        return of([]);
      default:
        return ApiErrorBuilder.parseError(error);
    }
  }

  export function handleError(error) {
    switch (error.status) {
      case 401:
        console.error('Unauthorized');
        return of(null);
      case 404:
        console.error('Not found');
        return of(null);
      default:
        return ApiErrorBuilder.parseError(error);
    }
  }

  export function parseError(error) {
    if (error.json) {
      const errors = ApiErrorBuilder.fromResponse(error.json());
      return observableThrowError(errors);
    }
    return observableThrowError(error);
  }
}
