import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {PartnerLinkParticipation} from './partner-link-participation';

export interface State extends EntityState<PartnerLinkParticipation> {
  selected?: PartnerLinkParticipation;
  loading: boolean;
}

export const adapter = createEntityAdapter<PartnerLinkParticipation>();

export const initialState: State = adapter.getInitialState({
  loading: false,
});
