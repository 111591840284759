import {
  PartnerLinkParticipationActions,
  PartnerLinkParticipationActionTypes
} from './partner-link-participation.actions';
import {adapter, initialState, State} from './partner-link-participation.state';

export function reducer(state = initialState, action: PartnerLinkParticipationActions): State {
  switch (action.type) {
    case PartnerLinkParticipationActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case PartnerLinkParticipationActionTypes.Create:
    case PartnerLinkParticipationActionTypes.Remove:
      return {
        ...state,
        loading: true
      };
    case PartnerLinkParticipationActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.partners, {
        ...state,
        loading: false,
      });
    case PartnerLinkParticipationActionTypes.CreateSuccess:
      return adapter.upsertOne(action.partner, {
        ...state,
        loading: false,
      });
    case PartnerLinkParticipationActionTypes.CreateFail:
    case PartnerLinkParticipationActionTypes.LoadAllFail:
    case PartnerLinkParticipationActionTypes.RemoveFail:
      return {
        ...state,
        loading: false,
      };
    case PartnerLinkParticipationActionTypes.RemoveSuccess:
      return adapter.removeOne(action.partner.id, {
        ...state,
        loading: false,
      });
  }
  return state;
}

