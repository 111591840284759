<div class="intl-tel-input allow-dropdown" *ngIf="selectedCountry?.flagClass && phoneNumber">
  <div class="flag-container" >
    <a class="selected-flag dropdown-toggle" data-toggle="dropdown" *ngIf="isDisabled; else dropdownToggle">
      <h2 class="m-0">
        <span class="flag-icon flag-icon-squared" [ngClass]="'flag-icon-'+selectedCountry.flagClass"></span>
      </h2>
      <div class="iti-arrow"></div>
    </a>
    <ng-template #dropdownToggle>
      <a class="selected-flag dropdown-toggle" data-toggle="dropdown">
        <h2 class="m-0">
          <span class="flag-icon flag-icon-squared" [ngClass]="'flag-icon-'+selectedCountry.flagClass"></span>
        </h2>
        <div class="iti-arrow"></div>
      </a>
    </ng-template>
    <ul class="country-list dropdown-menu">
      <li class="country" *ngFor="let country of preferredCountriesInDropDown" (click)="onCountrySelect(country, focusable)">
        <div class="flag-box">
          <h2 class="m-0">
            <span class="flag-icon flag-icon-squared" [ngClass]="'flag-icon-'+country.flagClass"></span>
          </h2>
        </div>
        <span class="country-name">{{country.name}}</span>
        <span class="dial-code">+{{country.dialCode}}</span>
      </li>
      <li class="divider" *ngIf="preferredCountriesInDropDown?.length"></li>
      <li class="country" *ngFor="let country of allCountries" (click)="onCountrySelect(country, focusable)">
        <div class="flag-box">
          <h2 class="m-0">
            <span class="flag-icon flag-icon-squared" [ngClass]="'flag-icon-'+country.flagClass"></span>
          </h2>
        </div>
        <span class="country-name">{{country.name}}</span>
        <span class="dial-code">+{{country.dialCode}}</span>
      </li>
    </ul>
  </div>
  <mat-form-field class="phone-number">
    <input
      matInput type="tel" #focusable
      [(ngModel)]="phoneNumber.phoneNumber"
      (keypress)="onInputKeyPress($event)"
      (input)="onPhoneNumberChange()"
      (ngModelChange)="onPhoneNumberChange()"
      [placeholder]="('ORGANIZATION.CREATION.NUMBER' | translate) + ': ' + selectedCountry.placeHolder"
      [disabled]="isDisabled"
      [errorStateMatcher]="this"
      [matAutocomplete]="auto"
      (blur)="onBlur.emit()"
    >
  </mat-form-field>


  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="autoCompleteFunction($event);onPhoneNumberChange()">
      <mat-option *ngFor="let option of options"  [value]="option.value.phoneNumber">
        {{option.value.phoneNumber}}
      </mat-option>
    </mat-autocomplete>
</div>
