import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateEmployeeDialogComponent } from '../organization-view/create-employee-dialog/create-employee-dialog.component';

@Directive({
  selector: '[dvtxEmployee]'
})
export class EmployeeDirective implements OnInit {
  @Input() selectedIndexTab = null;

  constructor(public dialog: MatDialog) {
  }
  @HostListener('click')
  onClick() {
    this.openDialog();
  }
  openDialog() {
    this.dialog.open(CreateEmployeeDialogComponent, {
        data: {
          selectedIndexTab: this.selectedIndexTab
        }
      });
  }
  ngOnInit(): void {
  }
}
