import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CollectorAttachmentEffects} from './collector-attachment.effects';
import {CollectorAttachmentService} from './collector-attachment.service';
import {reducer} from './collector-attachment.reducer';
import {stateKey} from './collector-attachment.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([CollectorAttachmentEffects])
  ],
  providers: [
    CollectorAttachmentEffects,
    CollectorAttachmentService
  ]
})
export class CollectorAttachmentStoreModule {
}
