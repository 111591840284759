import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './process-tree.state';
import {getAllProcesss} from '../process/process.selectors';
import {ProcessTreeNode} from './process-tree';
import {ProcessTreeNodeType} from './process-tree.interface';
import {Process} from '../process/process';
import {getAllTasks} from '../task/task.selectors';
import {Task} from '../task/task';
import {getAllDmsFolders} from '../dms-folder/dms-folder.selectors';
import {getAllDmsDocuments} from '../dms-document/dms-document.selectors';
import {DmsDocument} from '../dms-document/dms-document';
import {DmsFolder} from '../dms-folder/dms-folder';

export const stateKey = 'process-tree';
const getProcessTreeState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getProcessTreeNodesEntities,
  selectAll: getAllProcesssTreeNodes,
} = adapter.getSelectors(getProcessTreeState);

export const getProcessNodes = createSelector(
  getAllProcesss,
  processes => processes.map((p: Process) => {
    const node = new ProcessTreeNode(p.id, ProcessTreeNodeType.Process, p.parentId, p.title, p.updatedAt, p.status, p.canCreateTask);
    const config = Process.iconForType(p.processType);
    node.icon = config.icon;
    node.isSvgIcon = config.isSvgIcon;
    return node;
  })
);

export const getAllProcessNodes = createSelector(
  getAllProcesssTreeNodes,
  getAllProcesss,
  (processesNodes, processes) => {
    return processesNodes.map((p: ProcessTreeNode) => {
      const process = processes.find( process => process.id === p.id);
      if (process && process.status) {
        return new ProcessTreeNode(p.id, ProcessTreeNodeType.Process, p.parentId, p.title, p.updatedAt, process.status, p.canCreateTask);
      }
      return new ProcessTreeNode(p.id, ProcessTreeNodeType.Process, p.parentId, p.title, p.updatedAt, null, p.canCreateTask);
    })
  }
);

export const getProcessNodesAndTaskNodes = createSelector(
  getAllProcesss,
  getAllTasks,
  (processes: Process[], tasks: Task[]) => {
    const result = processes.map((p: Process) => new ProcessTreeNode(p.id, ProcessTreeNodeType.Process, p.parentId, p.title, p.updatedAt, p.status, p.canCreateTask));
    tasks.forEach(task => {
      result.push(new ProcessTreeNode(task.id, ProcessTreeNodeType.Task, task.process.id, task.title, null));
    });
    return result;
  }
);

export const getOpenProcessNodes = createSelector(
  getAllProcesss,
  (processes: Process[]) => {
    const result = processes
    .filter(p => ['closed', 'draft', 'initiated'].indexOf(p.status.code) < 0 && p.processType !== 'third_party_request')
    .map( (p: Process) => new ProcessTreeNode(p.id, ProcessTreeNodeType.Process, p.parentId ? p.parentId : 'open-processes-parent', p.title, p.updatedAt))
    return result;
  }
);

export const getClosedProcessNodes = createSelector(
  getAllProcesss,
  (processes: Process[]) => {
    const result = processes
    .filter(p => p.status.code === 'closed')
    .map( (p: Process) => new ProcessTreeNode(p.id, ProcessTreeNodeType.Process, p.parentId ? p.parentId : 'closed-processes-parent', p.title, p.updatedAt))
    return result;
  }
);

export const getDraftsProcessNodes = createSelector(
  getAllProcesss,
  (processes: Process[]) => {
    const result = processes
    .filter(p => p.status.code === 'draft')
    .map( (p: Process) => new ProcessTreeNode(p.id, ProcessTreeNodeType.Process, p.parentId ? p.parentId : 'drafts-processes-parent', p.title, p.updatedAt))
    return result;
  }
);

export const getFolderAndDocumentTreeviewNodes = createSelector(
  getAllDmsFolders,
  getAllDmsDocuments,
  (folders: DmsFolder[], files) => {
    const result = folders.map((f: DmsFolder) => new ProcessTreeNode(f.id, ProcessTreeNodeType.Folder, f.parentId, f.name, f.updatedAt));
    files.forEach(file => {
      result.push(new ProcessTreeNode(file.id, ProcessTreeNodeType.Document, file.folderId, file.name, null));
    });

    return result;
  }
);

export const getProcessNodesByParent = (id) => createSelector(
  getProcessNodes,
  processes => processes.filter(process => process.parentId === id || process.id == id)
);

export const getProcessPathMap = createSelector(
  getProcessTreeNodesEntities,
  getProcessNodes,
  (processEntities, nodes) => {
    const processPathMap = {};
    nodes.forEach(node => {
      let currentNode = node;
      let nodePath = [currentNode];
      let hasParent = !!currentNode.parentId;

      const parentPath = processPathMap[currentNode.parentId];
      if (hasParent && parentPath) {
        nodePath = [parentPath, ...nodePath];
      } else {
        while (hasParent && currentNode.id !== currentNode.parentId) {
          currentNode = processEntities[currentNode.parentId];
          hasParent = !!currentNode;
          if (hasParent) {
            nodePath = [currentNode, ...nodePath];
          }
        }
      }
      processPathMap[node.id] = nodePath;
    });
    return processPathMap;
  }
);

export const getProcessPath = (id) => createSelector(
  getProcessPathMap,
  processPathMap => {
    const nodePath = processPathMap[id];
    return nodePath ? nodePath : [];
  }
);
