
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiErrorBuilder } from '../builders/api-error.builder';
import { environment } from 'environments/environment';
import { FoxdoxStatusBuilder } from '../builders/foxdox-status.builder';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FoxdoxStatusService {

  readonly BASE_PATH = 'foxdox/status';
  basePath;

  constructor( private _router: Router , private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.BASE_PATH}`;
  }

  getFoxStatus() {
    return this._http.get(`${this.basePath}`).pipe(
    map((res: any) => FoxdoxStatusBuilder.fromResponse(res.data)),
     // return data; // this only used if the response is multi lines
    catchError(this.handleError),);
  }


  private handleError(error) {
    const errors = ApiErrorBuilder.fromResponse(error , error.status);
    return observableThrowError(errors);
  }
}
