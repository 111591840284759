
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiErrorBuilder } from '../builders/api-error.builder';
import {TimelineCommentsBuilder} from '../builders/timeline-comments.builder';
import createTimelinecommentFrom = TimelineCommentsBuilder.createTimelinecommentFrom;
import {HttpErrorResponse, HttpClient} from '@angular/common/http';

@Injectable()

export class TimelineCommentsService {

  readonly BASE_PATH = 'workflow_engine/timelines';

  constructor(private _http: HttpClient) {}

  addComment(timelineId: number, panelId: number, body) {
    return this._http.post(`${this.BASE_PATH}/${timelineId}/panel/${panelId}/comments`, body).pipe(
      map((res: any) => createTimelinecommentFrom(res.data)),
      catchError(this.handleError),);
  }


  updateComment(timelineId: number, panelId: number, commentId: number, body) {
    return this._http.put(`${this.BASE_PATH}/${timelineId}/panel/${panelId}/comments/${commentId}`, body).pipe(
      map((res: any) => createTimelinecommentFrom(res.data)),
      catchError(this.handleError),);
  }

  private handleError(error: HttpErrorResponse) {
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}
