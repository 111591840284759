import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ITabNavRoute } from 'app/five-f/organization-card/models/tab-nav-route.interface';

@Component({
  selector: 'dvtx-two-factor-auth-container',
  templateUrl: './two-factor-auth-container.component.html',
  styleUrls: ['./two-factor-auth-container.component.scss']
})
export class TwoFactorAuthContainerComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();
  public routes: ITabNavRoute[];
  public activeLink: string;

  constructor() {}

  ngOnInit() {
    this.routes = [{
      title: 'AUTH.TITLE',
      route: '/user-profile/security/two-factor-authentication'
    }, {
      title: 'AUTH.PASSWORD_RESET',
      route: '/user-profile/security/password'
    }, {
      title: 'AUTH.AUTO_LOGOUT',
      route: '/user-profile/security/auto-logout'
    }];
    this.activeLink = this.routes[0].title
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
