import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DocumentPreviewDocument} from './document-preview-document';
import {DocumentPreviewDocumentBuilder} from './document-preview-document.builder';
import {PvmsApiResourceService} from '../document-preview/pvms-api-resource.service';

@Injectable()
export class DocumentPreviewDocumentService {
  readonly BASE_PATH = 'api/v1/previews';

  constructor(private _http: PvmsApiResourceService) {}

  requestPreviews(ids: string[]): Observable<DocumentPreviewDocument[]> {
    const builder = new DocumentPreviewDocumentBuilder();
    const payload = {
      data: {
        attributes: {
          ids: ids
        }
      }
    };
    return <Observable<DocumentPreviewDocument[]>>this._http.postAll<DocumentPreviewDocumentBuilder, DocumentPreviewDocument>(builder, `${this.BASE_PATH}`, payload);
  }
}
