import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {distinctUntilChanged, filter, first, map, switchMap, takeUntil} from 'rxjs/operators';
import {ArtifactKeywordActions, ArtifactKeywordSelectors, ProcessArtifactActions} from 'app/+store';
import {ProcessArtifactService} from 'app/+store/process-artifact/process-artifact.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ProcessArtifact} from 'app/+store/process-artifact/process-artifact';
import {UntypedFormControl} from '@angular/forms';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {ArtifactKeyword} from 'app/+store/artifact-keyword/artifact-keyword';
import {Observable} from 'rxjs/internal/Observable';

/**
 * Dropdown menu for keywords.
 */
@Component({
  selector: 'dvtx-artifact-keyword-dropdown',
  templateUrl: './artifact-keyword-dropdown.component.html',
  styleUrls: ['./artifact-keyword-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArtifactKeywordDropdownComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  public _artifact: ProcessArtifact;
  private loadedKeywords$ = new BehaviorSubject<string[]>([]);
  private organizationId$ = new BehaviorSubject<string>(null);
  private filteredKeywordList$: Observable<ArtifactKeyword[]>;
  private searchTerm$ = new BehaviorSubject<string>(null);

  public formCtrl = new UntypedFormControl();

  public keywordMap = {};
  public keywordList: ArtifactKeyword[] = [];

  /**
   * Typical embedment layout option for sidebar and dialog:
   * Layout includes leading hr and h4 title.
   */
  @Input()
  public sectionLayout = false;

  public processId: string;

  @Input()
  private set artifact(artifact: ProcessArtifact) {
    this._artifact = artifact;

    if (artifact) {
      this.formCtrl.patchValue(artifact.keywords);
      this.organizationId$.next(artifact.organizationId);
    }

    if (artifact && artifact.keywords && artifact.keywords.length) {
      this.loadedKeywords$.next(artifact.keywords);
    } else {
      this.loadedKeywords$.next([]);
    }

    // Note: the processId of an artifact is not necessarily reflecting the process
    //       by which the user gained access to the artifact, but mostly.
    //       Reasons: The original process could already have been deleted, the
    //                artifact could have been relinked to some other process.
    if (artifact && artifact.processIds && artifact.processIds.includes(artifact.processId)) {
      this.processId = artifact.processId;
    } else if (artifact) {
      this.processId = artifact.processIds ? artifact.processIds[0] : null;
    } else {
      this.processId = null;
    }
  }

  constructor(private store: Store<AppState>,
              private artifactSvc: ProcessArtifactService,
              private cdr: ChangeDetectorRef,
              private notifyService: NotificationService) {
  }

  ngOnInit(): void {
    const organizationId$ = this.organizationId$.pipe(filter(oid => !!oid), distinctUntilChanged(), takeUntil(this.onDestroy));

    const keywords$ = organizationId$.pipe(switchMap(oid => this.store.select(ArtifactKeywordSelectors.getArtifactKeywordsOfOrg(oid))));
    keywords$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(keywords => {
        this.keywordList = keywords;
        this.keywordList.forEach(k => {
          this.keywordMap[k.id] = k;
        });
        this.cdr.detectChanges();
      });
    this.filteredKeywordList$ = combineLatest(keywords$, this.searchTerm$)
      .pipe(
        map(([keywords, query]: [ArtifactKeyword[], string]) => {
          if (query && typeof query === 'string') {
            const q = query.toLowerCase();
            return keywords.filter(k => {
              const name = k && k.name ? k.name.toLowerCase() : '';
              return name.indexOf(q) > -1;
            });
          }
          return keywords;
        }))

    organizationId$.subscribe(oid => {
      setTimeout(_ => this.store.dispatch(new ArtifactKeywordActions.LoadAll(oid)));
    })

    combineLatest(keywords$, this.loadedKeywords$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([keywords, assigned]: [ArtifactKeyword[], string[]]) => {
        if (keywords && keywords.length && assigned && assigned.length) {
          const keywordIds = [];
          assigned.forEach(a => {
            const c = keywords.find(k => k.name === a);
            if (c) {
              keywordIds.push(c.id);
            }
          })
          this.formCtrl.patchValue(keywordIds);
          this.formCtrl.markAsPristine();
          this.formCtrl.updateValueAndValidity();
          this.cdr.detectChanges();
        }
      })
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.loadedKeywords$.complete();
    this.organizationId$.complete();
    this.searchTerm$.complete();
  }

  public search($event) {
    this.searchTerm$.next($event);
  }

  public update(keywords): void {
    const processId = this.processId;
    const artifactId = this._artifact.id;

    // Create a keyword list out of the target keyword names by ID name mapping.
    let keywordList = null;
    if (keywords && keywords.length) {
      keywordList = []
      keywords.map(did => {
        const d = this.keywordMap[did];
        if (d) {
          keywordList.push(d.name);
        }
      })
    }

    this.artifactSvc.update(processId, artifactId, 'keywords', keywordList)
      .pipe(first())
      .subscribe(artifact => {
        this.store.dispatch(new ProcessArtifactActions.UpdateOneSuccess(artifact))
        this.notifyService.success('PROJECT_ROOM_SETTINGS.CHANGES_APPLIED');
      }, err => {
        this.notifyService.error('PROJECT_ROOM_SETTINGS.CHANGES_FAILED');
        console.error(err);
      })
  }

  public removeKeyword($event: any, item: any, keywords: string[]) {
    $event.stopPropagation();
    const newKeywords = keywords.filter((k) => k !== item.id)
    this.update(newKeywords);
  }
}
