import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {QuickUploadDialogContainerComponent} from '../containers/quick-upload-dialog/quick-upload-dialog.component';
import {QuickUploadSuccessDialogContainerComponent} from '../containers/quick-upload-success/quick-upload-success-dialog.component';
import {QuickShareDialogContainerComponent} from '../containers/quick-share-dialog/quick-share-dialog.component';
import {QuickShareChooseDialogComponent} from '../components/quick-share-file-choose/quick-share-file-choose-dialog.component';
import {QuickShareSuccessDialogComponent} from '../components/quick-share-success/quick-share-success-dialog.component';
import {LicenceControlService} from 'app/shared/services/licence-control.service';
import {Observable} from 'rxjs';
import {Organization} from 'app/models/organization.model';
import {AppState} from 'app/reducers';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {OrganizationSelectors} from 'app/+store';
import {CreateOrganizationDialogContainerComponent} from 'app/modules/address-book/modules/address-book-table/containers/create-organization-dialog-container/create-organization-dialog-container.component';
import {LicenceUpgradeDialogComponent} from 'app/shared/components/dialogs/licence-upgrade-dialog/licence-upgrade-dialog.component';
import {Router} from '@angular/router';
import {ProcessProfile} from 'app/+store/process/process.interface';

@Directive({
    selector: '[dvtxQuickStart]'
})
export class QuickstartDirective implements OnInit {

    @Input() parent = null;
    @Input() profile: ProcessProfile = ProcessProfile.StandardProfile;
    @Input() modalClass: string;
  @Input() size: string;
  @Input() dmsPathChooser = true;
  @Input() processId = null;
  @Output() onModalCall = new EventEmitter<any>();

  currentModalComponent;
  modalSizeClass;

  organizations: Observable<Organization[]>;
  organizationsCount: number;

  constructor(private el: ElementRef, private _dialog: MatDialog, private licenceControl: LicenceControlService, private _store: Store<AppState>,
  private _translateSvc: TranslateService, private _router: Router) {
    this.organizations = this._store.select(OrganizationSelectors.getAllOrganizations);
    this.organizations.subscribe( (organizations) => {
      this.organizationsCount = organizations.length;
    } )
  }

  ngOnInit(): void {
    this.licenceControl.elementRef = this.el;
    // For development. TODO: Remove when finished.
    // this.currentModalComponent = QuickShareDialogContainerComponent;
    // setTimeout(_ => this.openDialog(), 100);
  }

  @HostListener('click')
  onClick() {
    this.chooseComponent(this.modalClass);
  }

  openDialog() {
    console.log(this);
    setTimeout(_ => {
      this._dialog.open(this.currentModalComponent,
        {
          panelClass: this.modalSizeClass,
          data: {
            options: {
              parent: this.parent,
              dmsPathChooser: this.dmsPathChooser,
              processId: this.processId
            }
          }
        });
    }, 0);
  }

  chooseComponent(type, size = 'md') {
    this.modalSizeClass = `dialog-${size}`;
    switch (type) {
      case 'QuickUploadDialog':
        this.currentModalComponent = QuickUploadDialogContainerComponent;
        this.openDialog();
        break;
      case 'QuickShareDialog':
        this.currentModalComponent = QuickShareDialogContainerComponent;
        this.openDialog();
        break;
      case 'SendMessageDialog':
        const licenceChecker = this.licenceControl.hasRequiredLicence('Standard');
        if (licenceChecker) {
          if (this.organizationsCount === 0) {
            this._dialog.closeAll();
            this._dialog.open(LicenceUpgradeDialogComponent, {
              data: {
                title: 'REGISTRATION.ORGANIZATION_IS_NEEDED_TITLE',
                message: 'REGISTRATION.ORGANIZATION_IS_NEEDED_MESSAGE',
                onSubmitAction: () => {
                  this._dialog.open(CreateOrganizationDialogContainerComponent);
                },
                  submitButtonTitle: this._translateSvc.instant('ORGANIZATION.CREATION.CREATE')
              },
              width: '400px',
            });
          } else {
            const queryParams = { parent: this.parent, profile: this.profile || ProcessProfile.StandardProfile }
            this._router.navigate([`project-room/new`], { queryParams: queryParams });
            // this.currentModalComponent = QuickCommunicationDialogComponent;
            // this.openDialog();
          }

        }
        break;

      case 'QuickShareChooseDialog':
        this.currentModalComponent = QuickShareChooseDialogComponent;
        this.openDialog();
        break;
      case 'QuickUploadSuccessDialog':
        this.currentModalComponent = QuickUploadSuccessDialogContainerComponent;
        this.openDialog();
        break;
      case 'QuickShareSuccessDialog':
        this.currentModalComponent = QuickShareSuccessDialogComponent;
        this.openDialog();
        break;
      default:
        throw new Error('Unknown dialog component.');
    }
  }
}
