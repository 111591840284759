import {Component, OnDestroy, OnInit} from '@angular/core';
import {startWith, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'dvtx-access-token-not-found',
  templateUrl: './access-token-not-found.component.html',
  styleUrls: ['./access-token-not-found.component.scss']
})
export class AccessTokenNotFoundComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject();
  public lang = 'de';

  constructor(private _i18nSvc: TranslateService) {
    this._i18nSvc.onLangChange.pipe(startWith({lang: this._i18nSvc.currentLang}),
      takeUntil(this.onDestroy)).subscribe(lang => this.lang = lang.lang)
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
