import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TenantOrganizationEffects} from './organization.effects';
import {reducer} from './organization.reducer';
import {stateKey} from './organization.selectors';
import {TenantOrganizationService} from './organization.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([TenantOrganizationEffects])
  ],
  providers: [
    TenantOrganizationEffects,
    TenantOrganizationService
  ]
})
export class TenantOrganizationStoreModule {
}
