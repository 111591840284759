import {AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import {CollectorItemLookupActions} from 'app/+store';

/**
 * Lazy loading directive for comments and Collecto item references.
 * Loads the simplified CollectoLookupItem +store.
 * Uses the obsection observer to recognize if the current DOM element is inside the
 * viewport.
 *
 * The directive tries to prevent repeated refetching requests (itemFetched).
 */
@Directive({
  selector: '[dvtxFetchCollectorItem]',
})
export class FetchCollectorItemDirective implements OnDestroy, OnInit, AfterViewInit {
  private itemFetched = false;

  @Input('fetchCollectorItemId') itemId: string = null;
  @Input('fetchCollectorItemProcessId') processId = null;

  private observer: IntersectionObserver | undefined;

  constructor(private elRef: ElementRef,
              private store: Store<AppState>,
              private ngZone: NgZone) {
  }

  ngOnInit() {
    this.createObserver();
  }

  ngAfterViewInit() {
    this.startObservingElements();
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }
  }

  private createObserver() {
    const options = {
      threshold: 0.5,
    };
    const isIntersecting = (entry: IntersectionObserverEntry) => entry.isIntersecting || entry.intersectionRatio > 0.5;
    this.observer = new IntersectionObserver((entries, _observer) => {
      entries.forEach(entry => {
        if (isIntersecting(entry)) {
          this.fetchItem()
        }
      });
    }, options);
  }

  private startObservingElements() {
    if (!this.observer) return;
    this.observer.observe(this.elRef.nativeElement);
  }

  private fetchItem() {
    if (!this.processId || !this.itemId) return;
    if (this.itemFetched) return;

    // Don't refetch!
    this.itemFetched = true;
    const pid = this.processId;
    const itemId = this.itemId;
    this.ngZone.runOutsideAngular(_ => this.store.dispatch(new CollectorItemLookupActions.LoadOne(pid, itemId)));
  }
}
