import {adapter, initialState, State} from './item-labels.state';
import {ItemLabelsActions, ItemLabelsActionTypes} from './item-labels.actions';

export function reducer(state = initialState, action: ItemLabelsActions): State {
  switch (action.type) {
    case ItemLabelsActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };

    case ItemLabelsActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.labelItems, {
        ...state,
        selected: null,
        loading: false,
      });

    case ItemLabelsActionTypes.LoadByProcessIdAndScopeSuccess:
      return adapter.upsertMany(action.labelItems, {
        ...state,
        selected: null,
        loading: false,
      });

    case ItemLabelsActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case ItemLabelsActionTypes.LoadAllByLabels:
      return {
        ...state,
        selected: null,
        loading: false
      };
    case ItemLabelsActionTypes.LoadAllByLabelsSuccess:
      return adapter.setAll(action.labelItems, {
          ...state,
          selected: null,
          loading: false,
      });

    case ItemLabelsActionTypes.Create:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case ItemLabelsActionTypes.CreateSuccess:
      return adapter.upsertOne(action.labelItem, {
        ...state,
        selected: null,
        loading: false
      });

    case ItemLabelsActionTypes.CreateFail:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case ItemLabelsActionTypes.Remove:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case ItemLabelsActionTypes.RemoveSuccess:
      return adapter.upsertOne(action.labelItem, {
        ...state,
        selected: null,
        loading: false
      });

    case ItemLabelsActionTypes.RemoveFail:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case ItemLabelsActionTypes.Reset:
      return adapter.removeAll({
        ...state,
        selected: null,
        loading: false
      });
  }
  return state;
}
