<div class="steps-bar">
  <div class="step-wrap">
    <ng-container *ngFor="let wizard of wizards">
      <div class="single-step mb-1" *ngIf="wizard" (click)="setCurrentWizard(wizard)">
        <div class="step-item w-100 h-100" [ngClass]="{'active': wizard.isFilled && wizard.id !== currentWizard.id, 'process': wizard.id === currentWizard.id}">
          <span class="num font-weight-bold">{{ wizard.numberToShow || (wizard.id + 1) }}</span>
          <span class="text hidden-md-down">{{ wizard.title }}</span>
        </div>
      </div>
    </ng-container>
    <!--<div class="col-sm-6 col-lg-3 d-flex mb-1">
      <div class="step-item d-flex">
        <span class="num d-flex align-items-center font-weight-bold">4</span>
        <span class="text d-flex align-items-center font-weight-bold text-uppercase">Start</span>
      </div>
    </div>-->
  </div>
</div>
