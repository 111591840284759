import {Component, Inject, Input} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dvtx-split-view-dialog',
  templateUrl: './split-view-dialog.component.html',
  styleUrls: ['./split-view-dialog.component.scss']
})
export class SplitViewDialogComponent {
  @Input() color = 'white';
  @Input() icon;
  @Input() title;
  @Input() context;
  @Input() buttonsToolbar;
  @Input() dynamicWidth = false;
  @Input() disableClose = false;
  @Input() noPadding = false;
  @Input() classNames = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    [this.color, this.icon, this.context, this.buttonsToolbar, this.title, this.dynamicWidth, this.noPadding, this.classNames] = [data.color, data.icon, data.context, data.buttonsToolbar, data.title, data.dynamicWidth || this.dynamicWidth, data.noPadding, data.classNames];
  }
}
