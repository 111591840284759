
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ResourceBuilder} from '../../../shared/modules/api/builders/resource.builder';
import { ApiErrorBuilder } from '../../../shared/modules/api/builders/api-error.builder';
import { environment } from 'environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';


@Injectable()
export class MemberAddressService {

  basePath;
  readonly BASE_PATH = 'contacts';

  constructor(private _tokenService: HttpClient,
              private _http: HttpClient) {
    this.basePath = `${environment.contactApiBase}/api/v1/members`;
  }

  create(contact, address) {
    return this._http.post(`${this.basePath}/${contact.id}/addresses/`, ResourceBuilder.toRequest(address)).pipe(
      map((res: any) => ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  delete(contact, addressId) {
    return this._http.delete(`${this.basePath}/${contact.id}/addresses/${addressId}`).pipe(
      map((res: any) => ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  update(contact, address) {
    return this._tokenService
      .put(`${this.BASE_PATH}/${contact.id}/addresses/${address.id}`, JSON.stringify(ResourceBuilder.toRequest(address))).pipe(
      map((res: any) => ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }


  private handleError(error: HttpErrorResponse) {
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}
