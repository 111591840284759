import {ICmsBlog} from './cms-blog.interface';

export class CmsBlog implements ICmsBlog {
  readonly type = 'cms_ blogs';

  constructor(public id,
              public linkTitle,
              public description = null,
              public publishedAt = null,
              public createdAt = null,
              public updatedAt = null) {
  }
}
