import {Component, OnInit} from '@angular/core';
import {Browser} from 'browser-support';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'dvtx-cav-browser-support',
  templateUrl: './cav-browser-support.component.html',
  styleUrls: ['./cav-browser-support.component.scss']
})
export class CavBrowserSupportComponent implements OnInit {
  redirectToSystemUnsupportedBrowser = false;

  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute) {
    const validator = new Browser.SupportValidator();
    this.redirectToSystemUnsupportedBrowser = validator.isUnsupportedBrowser;
  }

  ngOnInit() {
    const token = this._activatedRoute.snapshot.params['token'];
    if (this.redirectToSystemUnsupportedBrowser) {
      this._router.navigate(['system', 'unsupported-browser']);
    } else {
      this._router.navigate(['workflows', 'audit', 'kap', '_verification', token], { queryParamsHandling: 'preserve' });
    }
  }
}
