<div *ngIf="_user">
  <div class="mt-1 mb-2">
    <div class="d-flex mt-1 mb-2">
      <div>
        <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
      </div>
      <p [innerHTML]="'AUTH.AUTO_LOGOUT_INFO' | translate" class="mb-0"></p>
    </div>
  </div>

  <div class="my-2">
    <p class="h3">{{ 'AUTH.AUTO_LOGOUT_TIME' | translate }}</p>
    <p class="h5">{{ 'AUTH.AUTO_LOGOUT_TIME_SUBSCRIPT' | translate }}</p>
  </div>

  <div class="d-flex w-100 justify-content-between align-items-center">
    <div style="max-width: 300px;" [formGroup]="form">
      <mat-form-field>
        <input matInput type="number" min="5" max="1440" formControlName="autoLogout" />
      </mat-form-field>
      <div class="d-flex align-self-center">
        <p class="h4">{{ 'DATETIME.MINUTES' | translate }}</p>
      </div>
    </div>
    <button mat-raised-button color="primary"
    [disabled]="form.pristine || form.invalid || submitOngoing"
    (click)="saveAutoLogoutTimer()">{{ 'GENERAL.SAVE_ACTION' | translate }}</button>
  </div>
</div>