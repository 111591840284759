import {IDmsContact} from './dms-contact.interface';

export class DmsContact implements IDmsContact {
  readonly type = 'dms_model_contact';
  isMember: boolean;
  isVerified: boolean;

  constructor(
    public id,
    public userName,
    public firstName,
    public lastName,
    public email) {
  }
}
