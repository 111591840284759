import {Action} from '@ngrx/store';
import {TaskStatus} from './task-status';

export enum TaskStatusActionTypes {
  LoadAll = '[TaskStatus] Load All',
  LoadAllSuccess = '[TaskStatus] Load All Success',
  LoadAllFail = '[TaskStatus] Load All Fail',
  Create = '[TaskStatus] Create Status',
  CreateSuccess = '[TaskStatus] Create Status Success',
  CreateFail = '[TaskStatus] Create Status Fail',
  Edit = '[TaskStatus] Edit',
  EditSuccess = '[TaskStatus] Edit Success',
  EditFail = '[TaskStatus] Edit Fail',
  Delete = '[TaskStatus] Delete Status',
  DeleteSuccess = '[TaskStatus] Delete Status Success',
  DeleteFail = '[TaskStatus] Delete Status Fail'
}

export class LoadAll implements Action {
  readonly type = TaskStatusActionTypes.LoadAll;

  constructor(public schemeId: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = TaskStatusActionTypes.LoadAllSuccess;

  constructor(public payload: TaskStatus[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = TaskStatusActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Create implements Action {
  readonly type = TaskStatusActionTypes.Create;

  constructor(public status: TaskStatus) {
  }
}

export class CreateSuccess implements Action {
  readonly type = TaskStatusActionTypes.CreateSuccess;

  constructor(public status: TaskStatus) {
  }
}

export class CreateFail implements Action {
  readonly type = TaskStatusActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Delete implements Action {
  readonly type = TaskStatusActionTypes.Delete;

  constructor(public status: TaskStatus) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = TaskStatusActionTypes.DeleteSuccess;

  constructor(public status: TaskStatus) {
  }
}

export class DeleteFail implements Action {
  readonly type = TaskStatusActionTypes.DeleteFail;

  constructor(public payload: any) {
  }
}

export class Edit implements Action {
  readonly type = TaskStatusActionTypes.Edit;

  constructor(public status: TaskStatus) {
  }
}

export class EditSuccess implements Action {
  readonly type = TaskStatusActionTypes.EditSuccess;

  constructor(public status: TaskStatus) {
  }
}

export class EditFail implements Action {
  readonly type = TaskStatusActionTypes.EditFail;

  constructor(public payload: any) {
  }
}

export type TaskStatusActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Create
  | CreateSuccess
  | CreateFail
  | Edit
  | EditSuccess
  | EditFail
  | Delete
  | DeleteSuccess
  | DeleteFail;
