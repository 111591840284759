import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {DocumentPreviewDocument} from './document-preview-document';

export interface State extends EntityState<DocumentPreviewDocument> {
  selected?: DocumentPreviewDocument;
  loading: boolean;
}

export const adapter = createEntityAdapter<DocumentPreviewDocument>();

export const initialState: State = adapter.getInitialState({
  loading: false,
});
