import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CollectorItemLookupEffects} from './collector-item-lookup.effects';
import {CollectorItemLookupService} from './collector-item-lookup.service';
import {reducer} from './collector-item-lookup.reducer';
import {stateKey} from './collector-item-lookup.selectors';

/**
 * Cache for Collecto comment references for the Project's and Project Room's activity protocol.
 */
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([CollectorItemLookupEffects])
  ],
  providers: [
    CollectorItemLookupEffects,
    CollectorItemLookupService
  ]
})
export class CollectorItemLookupStoreModule {
}
