import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'dvtx-foxdox-connect',
  templateUrl: './foxdox-connect.component.html',
  styleUrls: ['./foxdox-connect.component.scss']
})
export class FoxdoxConnectComponent implements OnInit {
  @Output() onSubmit = new EventEmitter();
  @Input() result: Observable<any> = new Observable();

  form: UntypedFormGroup;
  error = null;
  buttonIsClicked = false;

  constructor(private _formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.form = this._formBuilder.group({
      foxdoxUsername: ['', Validators.required],
      foxdoxPassword: ['', Validators.required]
    });
  }

  submit() {
    if (this.form.valid) {
      this.buttonIsClicked = true;
      this.result.subscribe( res => {
        console.log(res);
        if(res && res.result) {
          this.buttonIsClicked = false;
        }
      })
      this.onSubmit.emit(this.form.value);
    }    
  }
}
