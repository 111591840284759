import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {DocumentTemplateEffects} from './document-template.effects';
import {DocumentTemplateService} from './document-template.service';
import {reducer} from './document-template.reducer';
import {stateKey} from './document-template.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([DocumentTemplateEffects])
  ],
  providers: [
    DocumentTemplateEffects,
    DocumentTemplateService
  ]
})
export class DocumentTemplateStoreModule {
}
