import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {AngularTokenService} from 'angular-token';
import {User} from 'app/models/user';

@Injectable()
export class DmsPdfExportService {
  readonly apiPath = '/api/v1/foxdox/pdf';
  basePath;

  constructor(private _tokenService: AngularTokenService) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.apiPath}`;
  }

  getPdf(dmsDocumentId) {
    const url = `${this.basePath}/${dmsDocumentId}?${this._authQueryParams()}`;
    console.log(url);
    window.open(url);
  }

  private _authQueryParams() {
    const auth = this._tokenService.currentAuthData;
    const uid = User.userUidToParam(auth.uid);
    return `access_token=${auth.accessToken}&uid=${uid}&client=${auth.client}`;
  }
}
