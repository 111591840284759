import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {TemplateRef, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {contactListDtoType} from 'app/models/contact-list-dto.model';
import {MatDialog} from '@angular/material/dialog';

/**
 * Small, functional or trivial helpers for the Address Book Table.
 */
export class AddressBookTableHelper {
  constructor(private _viewContainerRef: ViewContainerRef,
              private _router: Router,
              private _dialog: MatDialog) {
  }

  createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }

  /**
   * Sort by contact Natural Person first, then organization, then members.
   * @param a
   * @param b
   */
  static sortByType = (a, b) => {
    // Sort contact persons to the beginning
    if (a.type === contactListDtoType.naturalPersonContact && b.type !== contactListDtoType.naturalPersonContact) { return -1 }
    if (a.type !== contactListDtoType.naturalPersonContact && b.type === contactListDtoType.naturalPersonContact) { return 1 }
    if (a.type === contactListDtoType.naturalPersonContact && b.type === contactListDtoType.naturalPersonContact) { return 0 }
    // Sort contact organizations to the middle
    if (a.type === contactListDtoType.organizationContact && b.type !== contactListDtoType.organizationContact) { return -1 }
    if (a.type !== contactListDtoType.organizationContact && b.type === contactListDtoType.organizationContact) { return 1 }
    if (a.type === contactListDtoType.organizationContact && b.type === contactListDtoType.organizationContact) { return 0 }

    if (a.type === contactListDtoType.Membership && b.type !== contactListDtoType.Membership) { return -1 }
    if (a.type !== contactListDtoType.Membership && b.type === contactListDtoType.Membership) { return 1 }
    return 0
  }

  /**
   * Sort by name and case insensitive.
   */
  static sortByString = (a, b) => {
    if (!a || !b) {
      if (!a && b) {
        return 1;
      }
      if (a && !b) {
        return -1;
      }
      return 0;
    }
    const typePrecedence = AddressBookTableHelper.sortByType(a, b);
    if (typePrecedence === 0) {
      return a.name.localeCompare(b.name);
    }
    return typePrecedence;
  }

  /**
   * Sort by name and case insensitive.
   */
  static sortByFirstNameLastName = (a, b) => {
    if (!a || !b) {
      if (!a && b) {
        return 1;
      }
      if (a && !b) {
        return -1;
      }
      return 0;
    }
    const firstLevelSorting = AddressBookTableHelper.sortByName(a.firstName, b.firstName);
    if (firstLevelSorting === 0) {
      return AddressBookTableHelper.sortByName(a.lastName, b.lastName);
    }
    return firstLevelSorting;
  }

  /**
   * Sort by name and case insensitive.
   */
  static sortByLastNameFirstName = (a, b) => {
    if (!a || !b) {
      if (!a && b) {
        return 1;
      }
      if (a && !b) {
        return -1;
      }
      return 0;
    }
    const firstLevelSorting = AddressBookTableHelper.sortByName(a.lastName, b.lastName);
    if (firstLevelSorting === 0) {
      return AddressBookTableHelper.sortByName(a.firstName, b.firstName);
    }
    return firstLevelSorting;
  }

  /**
   * Sort by name and case insensitive.
   */
  static sortByName = (a, b) => {
    if (!a || !b) {
      if (!a && b) {
        return 1;
      }
      if (a && !b) {
        return -1;
      }
      return 0;
    }
    return a.localeCompare(b);
  }

  /**
   * Jumps to the Project Room listing filtered by participant (identified by email).
   * Closes all open dialogs.
   */
  public toMemberProjectRooms(organization, email) {
    this._dialog.closeAll();
    const uid = encodeURIComponent(email);
    this._router.navigate(['process_management', 'admin', organization.id, 'participations', uid]);
  }

  /**
   * Jumps to the Project Room listing filtered by participant (identified by email).
   * Action button call.
   */
  public navigateToProjectRooms($event, organization, member) {
    $event.stopPropagation();
    const uid = encodeURIComponent(member.email);
    this._router.navigate(['process_management', 'admin', organization.id, 'participations', uid]);
  }

  /**
   * Helper to check if contact can be deleted or has attached project rooms.
   * @param contactToBeDeleted
   * @param processParticipationMap
   * @param contactPersonEmailCountMap
   */
  public projectRoomDeletionGuard(contactToBeDeleted, processParticipationMap, contactPersonEmailCountMap) {
    if (!contactToBeDeleted || !contactToBeDeleted.email) {
      return false;
    }
    const email = contactToBeDeleted.email.toLowerCase();
    const hasProjectRoomAttached = !!processParticipationMap[email];
    const lastContactWithEmail = contactPersonEmailCountMap[email] === 1;
    return hasProjectRoomAttached && lastContactWithEmail;
  }
}
