import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ProcessEffects} from './process.effects';
import {ProcessService} from './process.service';
import {reducer} from './process.reducer';
import {stateKey} from './process.selectors';
import {QuickstartService} from './quickstart.service';
import {ProcessOrganizationSelectorService} from './process-organization-selector.service';
import {ProcessListingV3Service} from './process-listing-v3.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ProcessEffects])
  ],
  providers: [
    ProcessEffects,
    ProcessService,
    ProcessListingV3Service,
    ProcessOrganizationSelectorService,
    QuickstartService
  ]
})
export class ProcessStoreModule {
}
