<div class="dvtx-list-choice-select">
  <div class="d-flex">
    <mat-radio-group (change)="onSelect($event?.value)"
                     [value]="choiceValue"
                     [disabled]="disabled"
      class="justify-content-between align-content-center">
      <ng-container *ngFor="let item of choiceList; let i = index;">
        <mat-radio-button [value]="i" [disableRipple]="true">
          <span>{{item | translate}}</span>
        </mat-radio-button>
      </ng-container>

      <ng-container *ngIf="editable">
        <ng-template [ngIf]="!choiceList || choiceList?.length === 0" [ngIfElse]="editIconButtonRef">
          <button mat-button color="gray"
                  class="ml-2"
                  (click)="onChangeRequest.emit()">
            <mat-icon>add</mat-icon>
            {{ 'GENERAL.EDIT_CHOICE' | translate }}
          </button>
        </ng-template>

        <ng-template #editIconButtonRef>
          <div class="dvtx-list-choice-select--edit-button">
            <button mat-icon-button color="lightgray"
                    (click)="onChangeRequest.emit()">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </ng-template>
      </ng-container>
    </mat-radio-group>
  </div>
</div>
