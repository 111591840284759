import {Component, Input} from '@angular/core';
import {ParsedCsv} from '../../services/csv-import.service';

@Component({
  selector: 'dvtx-csv-import-error-box',
  templateUrl: './csv-import-error-box.component.html',
  styleUrls: ['./csv-import-error-box.component.scss']
})
export class CsvImportErrorBoxComponent {
  @Input() public fileName: string;

  @Input() public numberOfErrors: number;
}
