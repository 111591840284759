import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { MessagePanelDetailsDropdownComponent } from "./components/message-panel-details-dropdown/message-panel-details-dropdown.component";
import { MessagePanelComponent } from "./container/message-panel/message-panel.component";
import { InViewportModule } from "ng-in-viewport";
import { TooltipModule } from "app/five-f/tooltip/tooltip.module";
import {InstantMessagingModule} from "../../../inbox/modules/instant-messaging/instant-messaging.module";


@NgModule({
    imports: [
        SharedModule,
        InViewportModule,
        TooltipModule,
        InstantMessagingModule
    ],
    declarations: [
        MessagePanelComponent,
        MessagePanelDetailsDropdownComponent
    ],
    exports: [
        MessagePanelComponent
    ]
})
export class MessagePanelModule {
}
