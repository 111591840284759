import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ProcessActionEffects} from './process-action.effects';
import {ProcessActionService} from './process-action.service';
import {reducer} from './process-action.reducer';
import {stateKey} from './process-action.selectors';
import {ApiCommandService} from './api-command.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ProcessActionEffects])
  ],
  providers: [
    ProcessActionEffects,
    ProcessActionService,
    ApiCommandService
  ]
})
export class ProcessActionStoreModule {
}
