/* tslint:disable:member-ordering */
import { Action } from '@ngrx/store';

// action types
export const SCREEN_RESIZE = 'SCREEN_RESIZE';

// get main header height action creator
export class ScreenResize implements Action {
  readonly type: string = SCREEN_RESIZE;

  constructor(public payload: number) {

  }
}

export type Actions = ScreenResize;

