import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CollectorItemEffects} from './collector-item.effects';
import {CollectorItemService} from './collector-item.service';
import {reducer} from './collector-item.reducer';
import {stateKey} from './collector-item.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([CollectorItemEffects])
  ],
  providers: [
    CollectorItemEffects,
    CollectorItemService
  ]
})
export class CollectorItemStoreModule {
}
