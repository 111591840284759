import { ProcessGroup } from '../models/process-group';

export namespace ProcessGroupBuilder {

  export function fromResponse(response): ProcessGroup {
    return new ProcessGroup(response.id, response.attributes.title, response.attributes.size, response.attributes.info_level, response.attributes.children);
  }

  export function toRequest(group: ProcessGroup) {
    return new ProcessGroup(-1, '', 0, 'default', []);
  }
}
