import {TwoFactorSessionActions, TwoFactorSessionActionTypes} from './two-factor-session.actions';
import {adapter, initialState, State} from './two-factor-session.state';

export function reducer(state = initialState, action: TwoFactorSessionActions): State {
  switch (action.type) {
    case TwoFactorSessionActionTypes.Load:
      return {
        ...state,
        loading: true
      };
    case TwoFactorSessionActionTypes.LoadSuccess:
      return adapter.upsertOne(action.session, {
        ...state,
        loading: false,
      });
    case TwoFactorSessionActionTypes.LoadFail:
      return {
        ...state,
        loading: false,
      };
    case TwoFactorSessionActionTypes.OpenDialog:
      return {
        ...state,
        openDialog: true,
        loading: false,
      };
    case TwoFactorSessionActionTypes.Reset:
      return adapter.removeAll({
        ...state,
        openDialog: false,
        loading: false,
      });
    case TwoFactorSessionActionTypes.ResetDialogOpenState:
      return {
        ...state,
        openDialog: false,
        loading: false,
      };
  }
  return state;
}

