import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {FolderSelectionDialogComponent} from '../folder-selection-dialog/folder-selection-dialog.component';
import {SlimFolder} from 'app/+store/slim-folder/slim-folder';
import {SlimFolderSelectors} from 'app/+store/slim-folder';
import {AppState} from '../../../../../../reducers';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs/internal/Observable';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'dvtx-folder-selector',
  templateUrl: './folder-selector.component.html',
  styleUrls: ['./folder-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderSelectorComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Input() isFibuSelector = false;
  @Output() onFolderSelection = new EventEmitter<SlimFolder>();

  private _selectedId: string;
  selectedFolderPath$: Observable<string>;
  selectedFolder: SlimFolder;
  private folderId$ = new BehaviorSubject<string>(null)

  @Input() set selectedId(id: string) {
    this._selectedId = id;
    this.folderId$.next(id);
  }

  constructor(private _store: Store<AppState>,
              private _dialog: MatDialog,
              private _zone: NgZone) {
  }

  ngOnInit(): void {
    this.selectedFolderPath$ = this.folderId$.pipe(switchMap(id => this._store.select(SlimFolderSelectors.getFolderPath(id))));
  }

  ngOnDestroy() {
    this.folderId$.complete();
  }

  openFolderSelector() {
    this._zone.run(() => {
      this._dialog.open(FolderSelectionDialogComponent, {
        panelClass: 'dvtx-folder-selection-container',
        autoFocus: true,
        data: {
          selectedId: this._selectedId,
          onFolderSelect: ($event) => {
            this.selectedFolder = $event;
            this.onFolderSelection.emit($event);
          }
        }
      })
    })
  }
}
