import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {TwoFactorMemberStatus, TwoFactorSession} from './two-factor-session';
import {TwoFactorStatus} from './two-factor-session.interface';

export class TwoFactorSessionBuilder implements IApiResourceBuilder<TwoFactorSession> {
  fromResponse(data): TwoFactorSession {
    const session = new TwoFactorSession(
      data.id,
      data.attributes.expires_at);
    session.status = TwoFactorStatus.Success;
    return session;
  }

  toRequest(session: TwoFactorSession) {
    return {
      data: {
        id: session.id,
        type: session.type,
        attributes: {
          code: session.code
        }
      }
    };
  }
}

export class TwoFactorMemberStatusBuilder implements IApiResourceBuilder<TwoFactorMemberStatus> {
  fromResponse(data): TwoFactorMemberStatus {
    return new TwoFactorMemberStatus(data.id, data.attributes.otp_enabled);
  }

  toRequest(session: TwoFactorMemberStatus) {
    return null;
  }
}
