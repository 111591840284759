import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './task-comment.state';

export const stateKey = 'taskComment';
const getTaskCommentState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getTaskCommentEntities,
  selectAll: getAllTaskComments,
} = adapter.getSelectors(getTaskCommentState);

export const getLoadingState = createSelector(
  getTaskCommentState,
  state => state.loading
);

export const getCreatingState = createSelector(
  getTaskCommentState,
  state => state.creating
);

export const getUpdatingState = createSelector(
  getTaskCommentState,
  state => state.updating
);
