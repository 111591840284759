import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CmsFaqService} from './cms-faq.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    CmsFaqService
  ]
})
export class CmsFaqStoreModule {
}
