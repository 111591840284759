import {IThirdPartyConfirmation, IThirdPartyConfirmationRow} from './thirdPartyConfirmation';
import {RequestType} from './request-type.enum';
import {
  RequestCreation
} from './request-creation.interface';
import {ThirdPartyConfirmationClaimBalanceRow} from './claimBalance';

export class ThirdPartyConfirmationExpertRow implements IThirdPartyConfirmationRow {
  constructor(public id: number,
              public color: string,
              public lfd: string,
              public referenced_at: string,
              public due_date: string,
              public comment: string,
              public language: string,
              public request_type: RequestType,
              public sachverstaendiger_name,
              public sachverstaendiger_ansprechpartner_name: string,
              public sachverstaendiger_ansprechpartner_email: string,
              public sachverstaendiger_ansprechpartner_handy: string
  ) {

  }

  toRequestCreation(): RequestCreation {
    return {
      companyName: this.sachverstaendiger_name,
      id: this.id,
      color: this.color,
      sequenceNo: this.lfd,
      requestDate: this.referenced_at,
      dueDate: this.due_date,
      fileAttachments: undefined,
      contactPerson: {
        name: this.sachverstaendiger_ansprechpartner_name,
        email: this.sachverstaendiger_ansprechpartner_email,
        phone: this.sachverstaendiger_ansprechpartner_handy
      },
      requestType: this.request_type,
      language: this.language,
      comment: this.comment
    }
  }
}

export class ThirdPartyConfirmationExpert implements IThirdPartyConfirmation {
  emailAttachmentFilename: string;
  state: string;

  constructor(public id: number,
              public type: string,
              public parent_process_id: number,
              public referenced_at: string,
              public due_date: string,
              public color: string,
              public language: string,
              public request_type: RequestType,
              public rows: ThirdPartyConfirmationExpertRow[]) {

  }

  toForm() {
    return undefined;
  }
}
