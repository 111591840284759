import {IClient} from './client.interface';

export class ClientContact {
  readonly type = 'client_contact';
  constructor(public id: string, public clientId: string, public contactId: string, public roleName) {
  }
}

export class Client implements IClient {
  readonly type = 'client_record';

  legalForm;
  street: string;
  streetNo: string;
  zip;
  city: string;
  country: string;
  creatorName: string;
  creatorEmail: string;
  phone: string;
  datevClientNo: string;
  datevConsultantNo: string;
  bookmanClientId: number;
  bookmanClientName: string;
  referenceId: string;
  processCount: number;
  canEditClient = false;
  canDeleteClient = false;
  fullName = null;

  // Pagination support
  total: number;
  perPage: number;
  records: number;
  contacts: any[];

  constructor(public id,
              public contactType,
              public name,
              public email,
              public gender,
              public title,
              public firstName,
              public lastName,
              public clientId,
              public organizationId = null,
              public contactId = null,
              public createdAt = null,
              public updatedAt = null) {
  }

  getFullName() {
    if (this.fullName) return this.fullName;

    const name = this.name ? `${this.name} ` : '';
    const person = this.firstName ? `${this.firstName} ${this.lastName}` : '';
    let res = '';
    if (name) {
      if (person) {
        res = `${name} (${person})`;
      } else {
        res = name;
      }
    } else {
      res = `${person}`;
    }
    this.fullName = res;
    return this.fullName;
  }
}
