<div class="dvtx-message-editor column w-100">
  <div *ngIf="dasError" class="error-container red flex">
    <mat-icon>warning</mat-icon>
    <p> {{'GENERAL.BIG_IMAGE' | translate}} </p>
  </div>
  <div *ngIf="fileError" class="error-container red flex">
    <mat-icon>warning</mat-icon>
    <p> {{'GENERAL.FILE_ERROR' | translate}} </p>
  </div>
  <div>
    <textarea #editor
              (froalaInit)="initializeLink($event)"
              [froalaEditor]="options"
              [(froalaModel)]="message"
              [(ngModel)]="message"
              [placeholder]="placeholder | translate"></textarea>
  </div>

  <div class="action-buttons row m-0 message-panel-footer d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center mr-2">
      <dvtx-emoji-menu (onSelection)="insertEmoji($event)"></dvtx-emoji-menu>
    </div>
    <div class="d-flex align-items-center">
      <button mat-icon-button
              [disabled]="!message || message.length === 0"
              color="primary">
        <mat-icon (click)="send()">send</mat-icon>
      </button>
    </div>
  </div>
</div>
