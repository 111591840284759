import 'froala-editor/js/plugins.pkgd.min.js'
import { NgModule } from '@angular/core';
import { ApiModule } from '../api/api.module';
import { MessageEditorComponent } from './component/message-editor.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { EmojiMenuModule } from 'app/shared/modules/emoji-menu/emoji-menu.module';

@NgModule({
  imports: [
    ApiModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatIconModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    EmojiMenuModule
  ],
  declarations: [
    MessageEditorComponent
  ],
  exports: [
    MessageEditorComponent
  ]
})
export class MessageEditorModule { }
