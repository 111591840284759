import {Action} from '@ngrx/store';
import * as model from './organization';
import * as modelInterface from './organization.interface';

export enum OperatorOrganizationActionTypes {
  Create = '[Operator.Organization] Create',
  CreateSuccess = '[Operator.Organization] Create Success',
  CreateFail = '[Operator.Organization] Create Fail',
  Remove = '[Operator.Organization] Remove',
  RemoveSuccess = '[Operator.Organization] Remove Success',
  RemoveFail = '[Operator.Organization] Remove Fail',
  Save = '[Operator.Organization] Save',
  SaveSuccess = '[Operator.Organization] Save Success',
  SaveFail = '[Operator.Organization] Save Fail',
  Status = '[Operator.Organization] Status',
  LoadAll = '[Operator.Organization] Load All',
  LoadAllSuccess = '[Operator.Organization] Load All Success',
  LoadAllFail = '[Operator.Organization] Load All Fail',
  LoadOne = '[Operator.Organization] Load One',
  LoadOneSuccess = '[Operator.Organization] Load One Success',
  LoadOneFail = '[Operator.Organization] Load One Fail',
  UpdateStatus = '[Operator.Organization] Update Status',
  UpdateStatusSuccess = '[Operator.Organization] Update Status Success',
  UpdateStatusFail = '[Operator.Organization] Update Status Fail'
}

export class Create implements Action {
  readonly type = OperatorOrganizationActionTypes.Create;

  constructor(public params: modelInterface.Operator.IOrganizationParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = OperatorOrganizationActionTypes.CreateSuccess;

  constructor(public organization: model.Operator.Organization) {
  }
}

export class CreateFail implements Action {
  readonly type = OperatorOrganizationActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = OperatorOrganizationActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = OperatorOrganizationActionTypes.RemoveSuccess;

  constructor(public organization: model.Operator.Organization) {
  }
}

export class RemoveFail implements Action {
  readonly type = OperatorOrganizationActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = OperatorOrganizationActionTypes.Save;

  constructor(public organization: model.Operator.Organization) {
  }
}

export class SaveSuccess implements Action {
  readonly type = OperatorOrganizationActionTypes.SaveSuccess;

  constructor(public organization: model.Operator.Organization) {
  }
}

export class SaveFail implements Action {
  readonly type = OperatorOrganizationActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = OperatorOrganizationActionTypes.LoadAll;

  constructor(public userId: number = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = OperatorOrganizationActionTypes.LoadAllSuccess;

  constructor(public organizations: model.Operator.Organization[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = OperatorOrganizationActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = OperatorOrganizationActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = OperatorOrganizationActionTypes.LoadOneSuccess;

  constructor(public organization: model.Operator.Organization) {
  }
}

export class LoadOneFail implements Action {
  readonly type = OperatorOrganizationActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class UpdateStatus implements Action {
  readonly type = OperatorOrganizationActionTypes.UpdateStatus;

  constructor(public id: string, public status: modelInterface.Operator.OrganizationStatus) {
  }
}

export class UpdateStatusSuccess implements Action {
  readonly type = OperatorOrganizationActionTypes.UpdateStatusSuccess;

  constructor(public organization: model.Operator.Organization) {
  }
}

export class UpdateStatusFail implements Action {
  readonly type = OperatorOrganizationActionTypes.UpdateStatusFail;

  constructor(public payload: any) {
  }
}

export type OperatorOrganizationActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | UpdateStatus
  | UpdateStatusSuccess
  | UpdateStatusFail;
