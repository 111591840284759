import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {ProcessAttributeBuilder} from './process-attribute.builder';
import {ProcessAttribute} from './process-attribute';
import {IAttributeParams} from './process-attribute.interface';

@Injectable()
export class ProcessAttributeService {
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';

  constructor(private _http: ApiResourceService) {
  }

  getAll(id: string): Observable<ProcessAttribute[]> {
    const builder = new ProcessAttributeBuilder();
    return <Observable<ProcessAttribute[]>>this._http.get<ProcessAttributeBuilder, ProcessAttribute>(builder, `${this.BASE_PATH}/${id}/attributes`);
  }

  getOne(processId: string, id: string): Observable<ProcessAttribute> {
    const builder = new ProcessAttributeBuilder();
    return <Observable<ProcessAttribute>>this._http.get<ProcessAttributeBuilder, ProcessAttribute>(builder, `${this.BASE_PATH}/${processId}/attributes/${id}`);
  }

  create(processId: string, params: IAttributeParams): Observable<ProcessAttribute> {
    const builder = new ProcessAttributeBuilder();
    const payload = {
      data: {
        attributes: {
          name: params.name,
          value: params.value,
          xml_tag: params.xmlTag
        }
      }
    };
    return <Observable<ProcessAttribute>>this._http.post<ProcessAttributeBuilder, ProcessAttribute>(builder, `${this.BASE_PATH}/${processId}/attributes`, payload);
  }

  update(processId: string, id: string, params: IAttributeParams): Observable<ProcessAttribute> {
    const builder = new ProcessAttributeBuilder();
    const payload = {
      data: {
        attributes: {
          name: params.name,
          value: params.value,
          xml_tag: params.xmlTag
        }
      }
    };
    return <Observable<ProcessAttribute>>this._http.put<ProcessAttributeBuilder, ProcessAttribute>(builder, `${this.BASE_PATH}/${processId}/attributes/${id}`, payload);
  }

  destroy(processId: string, id: string): Observable<ProcessAttribute> {
    const builder = new ProcessAttributeBuilder();
    return <Observable<ProcessAttribute>>this._http.del<ProcessAttributeBuilder, ProcessAttribute>(builder, `${this.BASE_PATH}/${processId}/attributes/${id}`);
  }
}
