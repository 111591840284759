import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Task} from './task';

export interface State extends EntityState<Task> {
  selected?: Task
  loading: boolean,
  reloading: boolean,
  updating: boolean,
  creating: boolean,
  deleting: boolean
}

// export const adapter = createEntityAdapter<Task>();
export const adapter = createEntityAdapter<Task>({
  // sortComparer: (l, r) => (dayjs(l.createdAt).diff(dayjs(r.createdAt)))
  sortComparer: Task.sortByOrder});


export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
  reloading: false,
  updating: false,
  creating: false,
  deleting: false
});
