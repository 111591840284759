import { ITimeline, IPanel } from '../../../../models';

export class PanelComment {

  id: number;
  // Id of the comments' author
  author_id: number;

  // Name of the comments' author
  author_name: string;

  // true if unread.
  unread: boolean;

  // Created at.
  created_at: Date;

  // Last modification
  updated_at: Date;

  // Text of the comment.
  content: string;

  constructor(id: number, authorId: number, authorName: string, unread: boolean, createdAt: Date, updatedAt: Date, content: string) {
    this.id = Number(id);
    this.author_id = authorId;
    this.author_name = authorName;
    this.unread = unread;
    this.created_at = createdAt;
    this.updated_at = updatedAt;
    this.content = content;
  }

  get isUnread(): boolean {
    return this.unread;
  }

  isEditable(userId) {
    return userId === this.author_id;
  }

  isEdited(): boolean {
    return this.created_at !== this.updated_at;
  }
}

export namespace TimelineCommentsBuilder {

  export function createTimelinecommentFrom(response) {
    const comment = response.attributes;
    comment['id'] = response.id;
    return TimelineCommentsBuilder.createComment(comment);
  }

  export function createComment(comment): PanelComment {
    return new PanelComment(comment.id, comment.author_id, comment.author_name, true, comment.created_at, comment.updated_at, comment.content);
  }
}
