<!-- CREATE LIST -->
<dvtx-dropdown-content [xPosition]="'before'" [yPosition]="'above'" [icon]="createListIconPortal" [content]="createListContentPortal" [tooltip]="'LABELS.ADD_LABEL' | translate" (onCloseDropdown)="showNewListItemForm = false" #listCreationDropdownMenu [iconButtonColor]="isTextButton ? 'primary' : 'gray'"></dvtx-dropdown-content>

<!-- CREATE GROUP TEMPLATES -->
<ng-template cdkPortal #createListIcon>
  <ng-container *ngIf="isTextButton">
    <span class="d-flex align-items-center">
      <mat-icon>add</mat-icon>
      {{'LABELS.ADD_LABEL' | translate}}
      <span class="hidden-count" *ngIf="hiddenCount > 0">{{hiddenCount}}</span>
    </span>
  </ng-container>
  <ng-container *ngIf="!isTextButton">
    <span class="d-flex align-items-center addLabel-Btn">
      <span class="addLabel-text" *ngIf="hiddenCount == 0">{{'LABELS.ADD_LABEL' | translate}}</span>
      <mat-icon class="addLabel-icon" svgIcon="add_label">
        <span class="hidden-count" [hidden]="hiddenCount == 0">{{hiddenCount}}</span>
      </mat-icon>
    </span>
  </ng-container>
</ng-template>

<ng-template cdkPortal #createListContent>
  <div class="labels-creation-form px-3 py-1" [class.d-none]="showNewListItemForm">
    <p>{{ 'LABELS.Select_Labels' | translate }}</p>

    <dvtx-api-autocompleted-input-select
      [placeholder]="'GENERAL.SEARCH_1' | translate"
      [prefixIcon]="'search'"
      [form]="searchForm"
      [controlName]="'searchLabelsTerm'">
    </dvtx-api-autocompleted-input-select>

    <ul class="labels-menu" *ngIf="_filteredLabels">
      <li class="labels-menu-item" *ngFor="let label of _filteredLabels">
        <mat-checkbox class="label-checkbox" [disableRipple]="true"
                      [checked]="isSelected(label?.id)"
                      (change)="check(label, $event)">
          <!--          <span class="labels-menu-item-color" [style.background-color]="label?.color"></span> <span class="text-truncate">{{label?.title | translate}}</span>-->
          <div class="mx-2">
            <dvtx-label [label]="label" [preventClicking]="true" [readonly]="true"></dvtx-label>
          </div>
        </mat-checkbox>
      </li>
    </ul>
    <!-- <div class="d-flex align-items-center justify-content-around">
      <button
        *ngIf="(myMembership | async)?.hasAdministrationRights"
        class="mat-icon-button-with-text mr-3"
        mat-icon-button
        color="primary"
        [disableRipple]="true"
        (click)="showNewListItemForm = true">
        {{'LABELS.CREATE_LABEL' | translate}}
        <mat-icon>add</mat-icon>
      </button>
      <button
        class="mat-icon-button-with-text"
        mat-icon-button
        color="primary"
        [disableRipple]="true"
        (click)="goToManageLabelsPage()">
        {{'LABELS.MANAGE_LABELS' | translate}}
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div> -->
  </div>
  <!-- <dvtx-create-label-tabs-form *ngIf="showNewListItemForm" (onSubmitCreation)="submitCreateLabel($event)"
                               (onCancelCreation)="showNewListItemForm = false"></dvtx-create-label-tabs-form> -->
</ng-template>
<!-- CREATE GROUP TEMPLATES / END -->
