import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './iam-process-activity.state';

export const stateKey = 'iamProcessActivities';
const getIamProcessActivitiesState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getIamProcessActivityEntities,
  selectAll: getAllIamProcessActivities,
} = adapter.getSelectors(getIamProcessActivitiesState);

