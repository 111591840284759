import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {QuickshareRecipientEffects} from './quickshare-recipient.effects';
import {QuickshareRecipientService} from './quickshare-recipient.service';
import {reducer} from './quickshare-recipient.reducer';
import {stateKey} from './quickshare-recipient.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([QuickshareRecipientEffects])
  ],
  providers: [
    QuickshareRecipientEffects,
    QuickshareRecipientService
  ]
})
export class QuickshareRecipientStoreModule {
}
