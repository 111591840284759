import {Injectable} from '@angular/core';
import {IKanbanBoardParams} from './kanban-board.interface';
import {KanbanBoard} from './kanban-board';
import {KanbanBoardBuilder} from './kanban-board.builder';
import {HttpParams} from '@angular/common/http';
import {KanbanApiResourceService} from "app/shared/modules/api-resource/services/kanban-api-resource.service";
import {Observable} from 'rxjs';

@Injectable()
export class KanbanBoardService {
  readonly BASE_PATH = 'api/v1/kanban/configurations';

  constructor(private _http: KanbanApiResourceService) {
  }

  create(params: IKanbanBoardParams): Observable<KanbanBoard> {
    const builder = new KanbanBoardBuilder();
    const payload = builder.toRequest(params);
    return <Observable<KanbanBoard>>this._http.post<KanbanBoardBuilder, KanbanBoard>(builder, `${this.BASE_PATH}`, payload);
  }

  update(boardId, params: IKanbanBoardParams): Observable<KanbanBoard> {
    const builder = new KanbanBoardBuilder();
    const payload = builder.toRequest(params);
    return <Observable<KanbanBoard>>this._http.put<KanbanBoardBuilder, KanbanBoard>(builder, `${this.BASE_PATH}/${boardId}`, payload);
  }

  getAll(processId: string): Observable<KanbanBoard[]> {
    const builder = new KanbanBoardBuilder();
    const params = new HttpParams({fromObject: {process_id: processId}});
    return <Observable<KanbanBoard[]>>this._http.get<KanbanBoardBuilder, KanbanBoard>(builder, `${this.BASE_PATH}`, params);
  }

  getOne(id: string): Observable<KanbanBoard> {
    const builder = new KanbanBoardBuilder();
    return <Observable<KanbanBoard>>this._http.get<KanbanBoardBuilder, KanbanBoard>(builder, `${this.BASE_PATH}/${id}`);
  }

  remove(id: string): Observable<KanbanBoard> {
    const builder = new KanbanBoardBuilder();
    return <Observable<KanbanBoard>>this._http.del<KanbanBoardBuilder, KanbanBoard>(builder, `${this.BASE_PATH}/${id}`);
  }

}
