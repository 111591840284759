<!-- using hidden visibilty and not ngif for the truncatedHandler to work  -->
<div
  [class.hideTruncatedDiv]="(editCollectoDescription && !hasTruncated) || (editCollectoDescription && hasTruncated && truncationDisabled)"
  #truncatedContentContainer
  [innerHTML]="value"
  [line-truncation]="numberOfLines"
  (hasTruncated)="truncatedHandler($event)"
  [disabled]="truncationDisabled"></div>

  <div *ngIf="editCollectoDescription">
    <div *ngIf="!hasTruncated || (hasTruncated && truncationDisabled)">
      <div
      *ngIf="disabled"
      [innerHTML]="value"
      [disabled]="disabled"></div>
      <div *ngIf="!disabled">
        <dvtx-wysiwyg-editor-cva (onBlur)="saveNewDescription($event, 'description')" [isDisabled]="disabled"
         [value]="value" [height]="'auto'" [heightMax]="'auto'"></dvtx-wysiwyg-editor-cva>
      </div>
    </div>
  </div>

<ng-container *ngIf="hasTruncated">
  <span class="show--more--link" *ngIf="!truncationDisabled">
    <span class="link--shadow"></span>
    <span class="link--content" (click)="showMore()">
      <span class="link--content--text">{{'GENERAL.SHOW_MORE' | translate}}</span>
      <mat-icon>expand_more</mat-icon>
    </span>
  </span>
  <span class="show--less--link" *ngIf="truncationDisabled">
    <span class="link--content" (click)="showLess()">
      <span class="link--content--text">{{'GENERAL.SHOW_LESS' | translate}}</span>
      <mat-icon>expand_less</mat-icon>
    </span>
  </span>
</ng-container>
