import {IFile} from './files.interface';

export class DMSFile implements IFile {
  constructor(public id: number = null,
              public type: string,
              public folder_id: string,
              public revision: number,
              public name: string,
              public filename: string,
              public readable: boolean,
              public writable: boolean,
              public mime_type: string,
              public created_at: string,
              public updated_at: string,
              public locked_at: string) {
  }
}
