import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {SlimFolderEffects} from './slim-folder.effects';
import {SlimFolderService} from './slim-folder.service';
import {reducer} from './slim-folder.reducer';
import {stateKey} from './slim-folder.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([SlimFolderEffects])
  ],
  providers: [
    SlimFolderEffects,
    SlimFolderService
  ]
})
export class SlimFolderStoreModule {
}
