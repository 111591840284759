<div class="mat-dialog-inner-wrap">
  <div class="mat-dialog-header">
    <h2 mat-dialog-title>Lizenz kaufen</h2>
    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <mat-horizontal-stepper linear [selectedIndex]="0" #stepper>

      <!-- Product selection step -->
      <mat-step [editable]="!orderCompleted" [stepControl]="licenceSelectionFormGroup">
        <hr>
        <ng-template matStepLabel>Auswahl</ng-template>
        <dvtx-licence-selection [form]="licenceSelectionFormGroup"></dvtx-licence-selection>
      </mat-step>

      <!-- Invoice address step -->
      <mat-step [editable]="!orderCompleted" [stepControl]="invoiceSelectionFormGroup">
        <hr>
        <ng-template matStepLabel>Rechnungsadresse</ng-template>
        <dvtx-invoice-address [form]="invoiceSelectionFormGroup"></dvtx-invoice-address>
      </mat-step>

      <!-- Payment configuration step -->
      <mat-step [editable]="!orderCompleted" [completed]="paymentCompleted">
        <hr>
        <ng-template matStepLabel>Bankverbindung</ng-template>
        <dvtx-payment-selection [form]="paymentSelectionFormGroup" (onPaymentCompleted)="setPaymentCompleted($event)"></dvtx-payment-selection>
      </mat-step>

      <!-- Overview -->
      <mat-step>
        <hr>
        <ng-template matStepLabel>Übersicht</ng-template>
        <div class="step-content">
          <br>
          <mat-spinner *ngIf="loading"></mat-spinner>
          <div class="row purchase--basket--overview" *ngIf="!loading && stepper.selectedIndex === 3">
            <div class="col-7">
              <dvtx-cart-details [cart]="cart" style="width: 100%"></dvtx-cart-details>
            </div>
            <div class="col-5">
              <dvtx-cart-overview [form]="licenceSelectionFormGroup"></dvtx-cart-overview>
            </div>
          </div>
        </div>
      </mat-step>

      <!-- Confirmation -->
      <mat-step>
        <hr>
        <ng-template matStepLabel>Bestätigung</ng-template>
        <div class="step-content">
          <br>
          <div class="success-container">
            <div class="success-column success-icon-container" *ngIf="loading">
              <mat-spinner [diameter]="60"></mat-spinner>
            </div>
            <div class="success-column success-icon-container" *ngIf="!loading">
              <mat-icon>check_circle</mat-icon>
            </div>
            <div class="success-column">
              <h3>Vielen Dank.</h3>
              <p>Eine Bestätigungsemail wird Ihnen zugesandt.</p>
              <p>Sie können die Lizenzen in den <button mat-button (click)="goToLicenceAssignment()">Einstellungen</button> Ihren Mitarbeitern zuweisen.</p>
            </div>
          </div>
          <br>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-end">

    <div [ngSwitch]="stepper?.selectedIndex">

      <!-- product > invoice address -->
      <div *ngSwitchCase="'0'">
        <button mat-raised-button mat-dialog-close>
          <mat-icon matPrefix aria-label="abort">clear</mat-icon>
          Abbrechen
        </button>
        <button mat-raised-button color="primary"
                [disabled]="licenceSelectionFormGroup.invalid"
                (click)="addProductToCart()">
          <mat-icon matPrefix aria-label="next">check</mat-icon>
          Rechnungsdaten überprüfen
        </button>
      </div>
      <!-- Invoice Address > Payment -->
      <div *ngSwitchCase="'1'">
        <button mat-raised-button (click)="previousStep()" color="primary">
          <mat-icon matPrefix aria-label="abort">arrow_back</mat-icon>
          Zurück
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="invoiceSelectionFormGroup.invalid"
          (click)="saveInvoiceAddress()">
          <mat-icon matPrefix aria-label="buy licenses">payment</mat-icon>
          Weiter
        </button>
      </div>

      <!-- Invoice Address > Payment -->
      <div *ngSwitchCase="'2'">
        <button mat-raised-button (click)="previousStep()" color="primary">
          <mat-icon matPrefix aria-label="abort">arrow_back</mat-icon>
          Zurück
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="paymentSelectionFormGroup.invalid || invoiceSelectionFormGroup.invalid"
          (click)="savePaymentOption()">
          <mat-icon matPrefix aria-label="buy licenses">shopping_cart</mat-icon>
          Zur Bestellübersicht
        </button>
      </div>

      <!-- Overview / Checkout > Confirmation -->
      <div *ngSwitchCase="'3'">
        <button mat-raised-button (click)="previousStep()" color="primary">
          <mat-icon matPrefix aria-label="abort">arrow_back</mat-icon>
          Zurück
        </button>
        <button mat-raised-button color="primary" (click)="createOrder()">
          <mat-icon matPrefix aria-label="abort">check</mat-icon>
          Jetzt kostenpflichtig kaufen
        </button>
      </div>

      <!-- Confirmation -->
      <div *ngSwitchCase="'4'">
        <button mat-raised-button color="primary" (click)="goToLicenceAssignment()">
          <mat-icon matPrefix aria-label="abort">clear</mat-icon>
          Schließen
        </button>
      </div>
    </div>

  </div>
</div>
