import {throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {PartnerBuilder} from '../builders/partner.builder';
import {ApiErrorBuilder} from '../builders/api-error.builder';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class PartnerService {

  constructor(private tokenService: HttpClient) {
  }

  getServiceTypes() {
    return this.tokenService.get('listings/service-types').pipe(
      map((res: any) => PartnerBuilder.buildServiceTypes(res)),
      catchError(this.handleError),);
  }


  private handleError(error: HttpErrorResponse) {
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}
