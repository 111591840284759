import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {CollectorLookupItem} from './collector-item-lookup';
import {CollectorLookupItemBuilder} from './collector-item-lookup.builder';

@Injectable()
export class CollectorItemLookupService {
  readonly BASE_PATH = 'api/v1/clr';

  constructor(private _http: ApiResourceService) {}

  /**
   * Returns a minimal CollectorItem with category for quick displaying it at comments.
   * @param processId
   * @param id
   */
  lookup(processId: string, id: string): Observable<CollectorLookupItem> {
    const builder = new CollectorLookupItemBuilder(processId);
    return <Observable<CollectorLookupItem>>this._http.get<CollectorLookupItemBuilder, CollectorLookupItem>(builder, `${this.BASE_PATH}/checklists/${processId}/items/${id}/lookup`);
  }
}
