export interface IProcessQueryParams {
  perPage?: number;
  workflows?: string[];
  clients?: string[];
  uid?: string;
  search?: string;
  status?: string[];
  folderId?: string;
  trashed_only?:boolean
}

export enum ActionInvocationContext {
  ProjectRoomAdministrator = 'ProjectRoomAdministrator',
  Participant = 'Participant'
}

/**
 * Leaf level of the DATEV DMS folder taxonomy.
 * Registers are contained in folders (see IDatevDmsFolder).
 * Not each folder has registers, therefore optional.
 */
export interface IDatevDmsRegister {
  id: string;
  name: string;
}

/**
 * Middle level of the DATEV folder taxonomy.
 * A folder is contained in domains. Folders can have 'registers' as children folder
 * elements (see IDatevDmsRegister).
 */
export interface IDatevDmsFolder extends IDatevDmsRegister {
  registers: IDatevDmsRegister[];
}

/**
 * Upmost and madatory level of the DATEV folder hierarchy.
 * Domains can have folders as children elements.
 * A DATEV DMS transfer must define a domain. Example Domain with ID 1 is 'Mandanten' (clients).
 */
export interface IDatevDmsDomain extends IDatevDmsRegister {
  folders: IDatevDmsFolder[];
}
