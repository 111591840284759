import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {LoadAll, LoadAllFail, LoadAllSuccess, OperatorSmsRecordActionTypes} from './sms-record.actions';
import * as model from './sms-record';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {OperatorSmsRecordService} from './sms-record.service';

@Injectable()
export class OperatorSmsRecordEffects {
  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(OperatorSmsRecordActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.params).pipe(
        first(),
        concatMap((res: model.Operator.SmsRecord[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: OperatorSmsRecordService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
