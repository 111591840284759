import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {Client, ClientContact} from './client';
import {ClientContactType} from './client.interface';

export class ClientBuilder implements IApiResourceBuilder<Client> {
  total = 1;
  perPage = 1;
  records = 1;

  fromResponse(data): Client {
    const contactType = data.attributes.contact_type === 'organization' ? ClientContactType.Organization : ClientContactType.Person;
    const client = new Client(
      data.id,
      contactType,
      data.attributes.name,
      data.attributes.email,
      data.attributes.gender,
      data.attributes.title,
      data.attributes.first_name,
      data.attributes.last_name,
      data.attributes.client_id,
      data.attributes.organization_uuid,
      data.attributes.contact_uuid,
      data.attributes.created_at,
      data.attributes.updated_at);
    client.city = data.attributes.city;
    client.street = data.attributes.street;
    client.zip = data.attributes.zip;
    client.streetNo = data.attributes.streetNo;
    client.phone = data.attributes.phone;
    client.datevClientNo = data.attributes.datev_client_no;
    client.datevConsultantNo = data.attributes.datev_consultant_no;
    client.bookmanClientId = data.attributes.bookman_client_id;
    client.bookmanClientName = data.attributes.bookman_client_name;
    client.processCount = data.attributes.process_count;
    client.canDeleteClient = data.attributes.can_delete_client;
    client.canEditClient = data.attributes.can_edit_client;

    client.total = this.total;
    client.perPage = this.perPage;
    client.records = this.records;

    return client;
  }

  toRequest(client: Client) {
    return {
      data: {
        id: client.id,
        type: client.type,
        attributes: {
          contact_type: client.contactType,
          contact_uuid: client.contactId,
          gender: client.gender,
          title: client.title,
          first_name: client.firstName,
          last_name:  client.lastName,
          name: client.name,
          legal_form: client.legalForm,
          street: client.street,
          street_no: client.streetNo,
          city: client.city,
          zip: client.zip,
          phone: client.phone,
          email: client.email,
          client_id: client.clientId,
          reference_id: client.referenceId,
          datev_consultant_no: client.datevConsultantNo,
          datev_client_no: client.datevClientNo,
          bookman_client_id: client.bookmanClientId,
          bookman_client_name: client.bookmanClientName
        }
      }
    };
  }

  addMetaSection(meta: any) {
    if (!meta) return;
    this.total = meta.total;
    this.perPage = meta.per_page;
    this.records = meta.records;
  }
}

export class ClientContactBuilder implements IApiResourceBuilder<ClientContact> {
  constructor(public clientId = null) {
  }

  fromResponse(data): ClientContact {
    return new ClientContact(
      data.id,
      this.clientId,
      data.attributes.contact_id,
      data.attributes.role_name);
  }

  toRequest(client: ClientContact) {
    return {
      data: {
        id: client.id,
        type: client.type,
        attributes: {

        }
      }
    };
  }
}
