import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {EmailFooterBuilder} from './email-footer.builder';
import {EmailFooter} from './email-footer';

@Injectable()
export class EmailFooterService {
  readonly basePath = 'api/v1/organizations/email_footers';
  builder: EmailFooterBuilder;

  constructor(private _http: ApiResourceService) {
    this.builder = new EmailFooterBuilder();
  }

  getAll() {
    return <Observable<EmailFooter[]>>this._http.get<EmailFooterBuilder, EmailFooter>(this.builder, `${this.basePath}`);
  }

  create(emailFooter: EmailFooter): Observable<EmailFooter> {
    const payload = this.builder.toRequest(emailFooter);
    return this._http.post<EmailFooterBuilder, EmailFooter>(this.builder, `${this.basePath}` , payload);
  }

  sendTestMail(emailFooter: EmailFooter): Observable<EmailFooter> {
    const payload = this.builder.toRequest(emailFooter);
    return this._http.post<EmailFooterBuilder, EmailFooter>(this.builder, `${this.basePath}/test_email` , payload);
  }

  update(emailFooter: EmailFooter): Observable<EmailFooter> {
    const payload = this.builder.toRequest(emailFooter);
    return this._http.put<EmailFooterBuilder, EmailFooter>(this.builder, `${this.basePath}/${emailFooter.id}`, payload);
  }

  remove(id: string): Observable<EmailFooter> {
    return this._http.del<EmailFooterBuilder, EmailFooter>(this.builder, `${this.basePath}/${id}`);
  }

}

