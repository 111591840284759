import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { stateKey } from './kanban-group.selectors';
import { reducer } from './kanban-group.reducer';
import { KanbanGroupEffects } from './kanban-group.effects';
import { KanbanGroupService } from './kanban-group.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([KanbanGroupEffects])
  ],
  providers: [
    KanbanGroupEffects,
    KanbanGroupService
  ]
})
export class KanbanGroupStoreModule {
}
