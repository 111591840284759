import {Injectable} from '@angular/core';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';

/**
 * Collecto group/category specific actions or service calls.
 *
 * Note: All former API calls are migrated to the new generic
 *       BomDataNodeService.
 */
@Injectable()
export class CollectorCategoryService {
  readonly BASE_PATH = 'api/v1/clr';

  constructor(private _http: ApiResourceService) {}
}
