import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {
  GrantThorntonSyncSettingsDialogComponent
} from './containers/grant-thornton-sync-settings-dialog/grant-thornton-sync-settings-dialog.component';
import {
  GrantThorntonSyncSettingsComponent
} from './containers/grant-thornton-sync-settings/grant-thornton-sync-settings.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
  GrantThorntonSyncActionMenuComponent
} from './containers/grant-thornton-sync-action-menu/grant-thornton-sync-action-menu.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {
  GrantThorntonSyncExportDialogComponent
} from './containers/grant-thornton-sync-export-dialog/grant-thornton-sync-export-dialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import {InfoBlockModule} from '../../five-f/info-block/info-block.module';
import {DocumentModule} from '../workflow-engine/modules/document/document.module';

@NgModule({
  declarations: [
    GrantThorntonSyncSettingsDialogComponent,
    GrantThorntonSyncSettingsComponent,
    GrantThorntonSyncActionMenuComponent,
    GrantThorntonSyncExportDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatStepperModule,
    InfoBlockModule,
    DocumentModule
  ],
  exports: [
    GrantThorntonSyncSettingsDialogComponent,
    GrantThorntonSyncSettingsComponent,
    GrantThorntonSyncActionMenuComponent,
    GrantThorntonSyncExportDialogComponent
  ]
})
export class GrantThorntonModule {
}
