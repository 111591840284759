import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {TaskStatusSchemeBuilder} from './task-status-scheme.builder';
import {TaskStatusScheme} from './task-status-scheme';

@Injectable()
export class TaskStatusSchemeService {
  readonly base_path = 'api/v1/tasks/status_schemes';

  builder: TaskStatusSchemeBuilder;

  constructor(private _http: ApiResourceService) {
  }

  getAll(): Observable<TaskStatusScheme[]> {
    const builder = new TaskStatusSchemeBuilder();
    return <Observable<TaskStatusScheme[]>>this._http.get<TaskStatusSchemeBuilder, TaskStatusScheme>(builder, `${this.base_path}`);
  }

  getDefault(): Observable<TaskStatusScheme> {
    const builder = new TaskStatusSchemeBuilder();
    return <Observable<TaskStatusScheme>>this._http.get<TaskStatusSchemeBuilder, TaskStatusScheme>(builder, `api/v1/tasks/default_scheme`);
  }

  getOne(schemeId: string): Observable<TaskStatusScheme> {
    const builder = new TaskStatusSchemeBuilder();
    return <Observable<TaskStatusScheme>>this._http.get<TaskStatusSchemeBuilder, TaskStatusScheme>(builder, `${this.base_path}/${schemeId}`);
  }

  create(scheme: TaskStatusScheme): Observable<TaskStatusScheme> {
    const builder = new TaskStatusSchemeBuilder();
    const payload = builder.toRequest(scheme);
    return <Observable<TaskStatusScheme>>this._http.post<TaskStatusSchemeBuilder, TaskStatusScheme>(builder, `${this.base_path}`, payload);
  }

  update(scheme: TaskStatusScheme): Observable<TaskStatusScheme> {
    const builder = new TaskStatusSchemeBuilder();
    const payload = builder.toRequest(scheme);
    return <Observable<TaskStatusScheme>>this._http.put<TaskStatusSchemeBuilder, TaskStatusScheme>(builder, `${this.base_path}/${scheme.id}`, payload);
  }

  remove(schemeId): Observable<TaskStatusScheme> {
    const builder = new TaskStatusSchemeBuilder();
    return <Observable<TaskStatusScheme>>this._http.del<TaskStatusSchemeBuilder, TaskStatusScheme>(builder, `${this.base_path}/${schemeId}`);
  }
}
