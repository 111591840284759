<div class="five-f-icon-picker-dropdown-container" [class.five-f-menu-opened]="showIconPicker">
  <dvtx-tooltip [tooltipText]="'LABELS.SELECT_ICON' | translate" [position]="tooltipPosition">
    <button [disabled]="disabled" type="button" mat-icon-button [disableRipple]="true" [matMenuTriggerFor]="menu" (click)="showIconPicker = !showIconPicker" #buttonToggler *ngIf="buttonType === 'icon'">
      <mat-icon>
        {{selected ? selected : 'insert_emoticon'}}
      </mat-icon>
    </button>
    <button [disabled]="disabled" type="button" mat-stroked-button [disableRipple]="true" [matMenuTriggerFor]="menu" (click)="showIconPicker = !showIconPicker" #buttonToggler *ngIf="buttonType === 'button'" [style.background]="selected">
      <mat-icon>
        {{selected ? selected : 'insert_emoticon'}}
      </mat-icon>
      {{'LABELS.SELECT_ICON' | translate}}
    </button>
  </dvtx-tooltip>
  <mat-menu
    #menu="matMenu"
    class="five-f-card five-f-icon-picker-dropdown"
    [xPosition]="xPosition"
    [yPosition]="yPosition"
    (closed)="showIconPicker = false">
    <ul class="mb-0 five-f-icon-picker-dropdown-inner-content">
      <ng-container *ngFor="let icon of icons">
        <li class="list-inline-item" (click)="selectIcon(icon)">
          <button type="button" mat-icon-button [color]="selected === icon ? 'primary' : 'grey'">
            <mat-icon>
              {{icon}}
            </mat-icon>
          </button>
        </li>
      </ng-container>
    </ul>
  </mat-menu>
</div>
