
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiErrorBuilder } from '../builders/api-error.builder';
import {HttpErrorResponse, HttpClient} from '@angular/common/http';
import { environment } from 'environments/environment';
import { ResourceBuilder } from '../builders/resource.builder';
import { FilesBuilder } from '../builders/files.builder';

/**
 * Service to fetch the users folder from DMS API endpoint.
 */
@Injectable()
export class FilesService {

  readonly BASE_PATH = 'files';
  basePath;

  constructor(private tokenService: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/files/documents`;

  }

  getAll() {
    return this.tokenService
      .get(`${this.basePath}`).pipe(
      map((res: any) => {
        console.log('Get all fromResonse' , FilesBuilder.fromResponse(res))
        return FilesBuilder.fromResponse(res);
      }),
      catchError(this.handleError),);
  }

  // create(name, parentId?) {
  //   const body = {};
  //   body['name'] = name;
  //   if (parentId) {
  //     body['parent_folder_id'] = parentId;
  //   }
  //   return this.tokenService
  //     .post(`${this.basePath}`, body)
  //     .map((res: any) => ResourceBuilder.fromResponse(res))
  //     .catch(this.handleError);
  // }

  // delete(id) {
  //   return this.tokenService
  //     .delete(`${this.basePath}/${id}`)
  //     .map((res: any) => ResourceBuilder.fromResponse(res))
  //     .catch(this.handleError);
  // }

  private handleError(error: HttpErrorResponse) {
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}
