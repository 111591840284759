<div class="d-flex w-100">
  <form class="w-100">
    <mat-form-field>
      <input *ngIf="readOnly"
      type="text"
      [placeholder]="placeholder | translate"
      matInput
      [value]="myControl?.value?.name"
      [disabled]="readOnly"
      #inputElementRef>
      <input *ngIf="!readOnly"
             type="text"
             [placeholder]="placeholder | translate"
             matInput
             [required]="required"
             [formControl]="myControl"
             [matAutocomplete]="auto"
             (blur)="onBlurEventHandler()"
             [readonly]="readOnly"
             #inputElementRef>

      <button mat-button *ngIf="myControl.value && !readOnly" matSuffix mat-icon-button
              aria-label="Clear" (click)="reset(); $event.stopPropagation()" class="clear-button">
        <mat-icon>close</mat-icon>
      </button>

      <!--<button mat-icon-button matSuffix-->
              <!--*ngIf="selected && selected.id"-->
              <!--matTooltip="{{ 'CLIENT.EDIT_CLIENT' | translate }}">-->
        <!--<mat-icon>edit</mat-icon>-->
      <!--</button>-->

      <!--<button mat-icon-button matSuffix matTooltip="{{ 'CLIENT.CREATE_CLIENT' | translate }}">-->
        <!--<mat-icon>add</mat-icon>-->
      <!--</button>-->

      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="clientSelected($event)" class="dvtx-client-selection-cva-autocomplete">
        <cdk-virtual-scroll-viewport *ngIf="(filteredOptions | async)?.length > 0" style="height: 240px;" [itemSize]="30">
        <mat-option *cdkVirtualFor="let option of filteredOptions | async" [value]="option">
          <p class="d-flex align-items-center my-1">
            <ng-container [ngSwitch]="option.contactType">
              <ng-container *ngSwitchCase="ClientContactType.Person">
                <!--<dvtx-avatar [size]="'xs'" [email]="option" matTooltip="{{option?.email}}" [showTitle]="false"></dvtx-avatar>-->
                {{ ( option?.name && option?.name?.length > 20 ) ? ( option?.name | slice:0:20 ) + '..' : ( option?.name ) }} {{ (!option?.name) ? option?.firstName + ' ' + option?.lastName : ''}}
              </ng-container>

              <ng-container *ngSwitchCase="ClientContactType.Organization">
                {{ ( option?.name?.length > 20 ) ? ( option?.name | slice:0:20 ) + '..' : ( option?.name ) }}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="option?.email && option?.contactType === ClientContactType.Organization">
              ({{option?.email}})
            </ng-container>
            <ng-container *ngIf="option?.clientId">&nbsp;({{option.clientId}})</ng-container>
          </p>
        </mat-option>
        </cdk-virtual-scroll-viewport>
      </mat-autocomplete>
    </mat-form-field>
  </form>

  <div class="align-self-center d-flex" matTooltip="{{ 'CLIENT.CREATE_CLIENT' | translate }}" *ngIf="!readOnly && showCreateButton">
    <dvtx-client-selection [action]="action"
                           (onClientChange)="setClient($event)"></dvtx-client-selection>
    <!--<div style="width: 28px;">-->
      <!--<ng-container *ngIf="selected && selected.id">-->
        <!--<dvtx-client-selection [action]="ClientActionType.EditButton"-->
                               <!--[selectedClient]="selected"></dvtx-client-selection>-->
      <!--</ng-container>-->
    <!--</div>-->
  </div>
</div>
