import { IShoppingCart } from './shopping-cart.interface';
import { ShoppingCartService } from '../../../../+store/licence/shopping-cart.service';
import { LineItem } from './line-item';
import { BehaviorSubject ,  of } from 'rxjs';
import { catchError, finalize} from 'rxjs/operators';
import { InvoiceAddress } from './invoice-address';
import { PaymentOption } from './payment-option';

/**
 * DAO for shopping cart access.
 */
export class ShoppingCart implements IShoppingCart {
  readonly type = 'sales_carts';
  readonly id: number = null;

  public lineItems: LineItem[] = [];
  public invoiceAddress: InvoiceAddress;
  public paymentOption: PaymentOption;
  protected lineItemsSubject = new BehaviorSubject<LineItem[]>([]);
  public loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  get sum() {
    if (this.lineItems && this.lineItems.length) {
      let sum = 0;
      this.lineItems.forEach(item => sum += +item.price * +item.quantity);
      return sum;
    }
    return 0;
  }

  get sumWith2places() {
    return this.sum.toFixed(2);
  }

  get sumWithUst() {
    const ust = this.ust + 1.0;
    return (this.sum * ust).toFixed(2);
  }

  get sumUst() {
    const ust = this.ust + 1.0;
    return ((this.sum * ust) - this.sum).toFixed(2);
  }

  get ust() {
    return 0.19;
  }

  constructor(protected _cartSvc: ShoppingCartService = null) {}

  refresh() {
    if (!this._cartSvc) {
      return null;
    }
    this.loadingSubject.next(true);

    this._cartSvc.getDetails().pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe((cart: IShoppingCart) => {
      this.lineItems = cart.lineItems;
      this.invoiceAddress = cart.invoiceAddress;
      this.paymentOption = cart.paymentOption;
      this.lineItemsSubject.next(this.lineItems)
    });
  }
}
