import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './partner-link-participation.state';
import {OrganizationSelectors} from 'app/+store/organization';
import {IPartnerLinkParticipationMap} from './partner-link-participation.interface';

export const stateKey = 'partner-link-participation';
const getPartnerLinkState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getPartnerLinkEntities,
  selectAll: getAllPartnerLinks,
} = adapter.getSelectors(getPartnerLinkState);

export const getPartnerLinksOfSelectedOrg = createSelector(
  OrganizationSelectors.getSelectedId,
  getAllPartnerLinks,
  (orgId, partnerLinks) => partnerLinks.filter(c => orgId && c.organizationId === orgId)
);

export const loadingState = createSelector(
  getPartnerLinkState,
  (state) => state.loading
);

export const getPartnerLinkMapOfSelectedOrg = createSelector(
  OrganizationSelectors.getSelectedId,
  getAllPartnerLinks,
  (orgId, partnerLinks) => {
    const partnerLinkMap: IPartnerLinkParticipationMap = {};
    partnerLinks
      .filter(p => p.organizationId === orgId)
      .forEach(p => {
        partnerLinkMap[p.email.toLocaleLowerCase()] = p.id;
      });
    return partnerLinkMap;
  }
);
