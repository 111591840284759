<div class="mat-dialog-inner-wrap"  [style.borderColor]="color">
  <div class="mat-dialog-header">
    <h4 mat-dialog-title>{{data.title | translate}}</h4>
  </div>
  <div mat-dialog-content>
    <h3 class="mb-2">{{data.message | translate}}</h3>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button (click)="onNoClick()">{{ cancelButtonTitle | translate }}</button>
    <button *ngIf="showActionButton" mat-raised-button color="primary" (click)="onActionClick()">{{ actionButtonTitle | translate }}</button>
    <button mat-raised-button color="primary" (click)="onSubmitClick()">{{ submitButtonTitle | translate }}</button>
  </div>
</div>
