import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "app/reducers";
import {FlatTreeControl} from "@angular/cdk/tree";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {takeUntil} from "rxjs/operators";
import {DmsAccountType} from 'app/+store/slim-folder/slim-folder.interface';
import {SlimFolder} from 'app/+store/slim-folder/slim-folder';
import {SlimFolderService} from 'app/+store/slim-folder/slim-folder.service';
import {BehaviorSubject, Subject} from "rxjs";

class FlatNode {
  constructor(
    public expandable: boolean, public id: string, public level: number, public folder: SlimFolder) {
  }
}

@Component({
  selector: 'dvtx-folder-tree',
  templateUrl: './folder-tree.component.html',
  styleUrls: ['./folder-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderTreeComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject();

  readonly DmsAccountType = DmsAccountType;
  treeControl: FlatTreeControl<FlatNode>;
  treeFlattener: MatTreeFlattener<SlimFolder, FlatNode>;
  dataSource: MatTreeFlatDataSource<SlimFolder, FlatNode>;
  selectedNode: SlimFolder;
  folderTree$ = new BehaviorSubject<SlimFolder[]>([]);
  preSelectedFolderId: string;

  @Input() set folders(folders: SlimFolder[]) {
    this.folderTree$.next(folders)
  }

  @Input() set preSelectedNodeId(id: string) {
    this.preSelectedFolderId = id;
    this.expandPreSelectedFolder();
  }

  @Input() enableScrollToSelected = true;
  @Output() onNodeSelection = new EventEmitter<SlimFolder>();
  @Output() onDblClick = new EventEmitter<boolean>(false);

  constructor(
    private _store: Store<AppState>,
    private _folderSvc: SlimFolderService,
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this._getLevel,
      this._isExpandable,
      this._getChildren
    );
    this.treeControl = new FlatTreeControl<FlatNode>(this._getLevel, this._isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnInit() {
    this.folderTree$.pipe(takeUntil(this.onDestroy)).subscribe(tree => {
      this.dataSource.data = tree;
      if (this.preSelectedFolderId) {
        this.expandPreSelectedFolder();
      } else {
        this.treeControl.expand(this.treeControl.dataNodes[0])
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.dataSource.disconnect();
  }

  transformer = (node: SlimFolder, level: number) => {
    const isExpandable = !!(node.children && node.children.length);
    return new FlatNode(isExpandable, node.id, level, node);
  }
  hasChild = (_: number, _nodeData: FlatNode) => _nodeData.expandable;

  private _getChildren = (node: SlimFolder) => node.children;
  private _getLevel = (node: FlatNode) => node.level;
  private _isExpandable = (node: FlatNode) => node.expandable;

  createFolderTreeFrom(folders: SlimFolder[]): SlimFolder {
    let root = null;
    const folderIndex = {};
    const folderCatalog = [];

    folders.forEach((folder) => {

      if (!folder.parentId) {
        root = folder;
      }
      folderIndex[folder.id] = folder;
      folderCatalog.push(folder);
    });

    folderCatalog.forEach((folder) => {
      if (folder.parentId) {
        folderIndex[folder.parentId].children.push(folder);
      }
    });
    return root;
  }

  selectNode(node, dblClick = false) {
    this.selectedNode = node;
    this.onNodeSelection.emit(node);
    console.log(node)
    if (dblClick) this.onDblClick.emit(dblClick);
  }

  private expandPreSelectedFolder() {
    // @ts-ignore
    const preSelectedNode = this.dataSource._flattenedData.value.find(node => node.id === this.preSelectedFolderId);
    if (!preSelectedNode) return;

    // @ts-ignore
    let parentNode = this.dataSource._flattenedData.value.find(node => node.id === preSelectedNode.folder.parentId);
    const nodeAncestors = [parentNode];

    while (parentNode) {

      // @ts-ignore
      parentNode = this.dataSource._flattenedData.value.find(node => node.id === parentNode.folder.parentId);
      if (parentNode) nodeAncestors.unshift(parentNode);
    }
    nodeAncestors.forEach(node => this.treeControl.expand(node));
    this.selectNode(preSelectedNode.folder);
    if (this.enableScrollToSelected) {
      setTimeout(() => {
        this.scrollSelectedFolderIntoView();
      }, 120)
    }
  }

  scrollSelectedFolderIntoView(position: 'start' | 'center' | 'end' | 'nearest' = 'center') {
    const folderTreeElement = document.querySelector('dvtx-folder-tree');
    if (folderTreeElement) {
      const selectedFolderElement = folderTreeElement.querySelector<HTMLElement>('.tree-node.tree-node--selected');
      if (selectedFolderElement) {
        selectedFolderElement.scrollIntoView({behavior: 'auto', block: position});
      }
    }
  }
}
