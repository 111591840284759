import {IApiRequest, IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {
  DocumentPreview,
  DocumentSignature, DocumentSignatureRequest, OrganizationalVisual, SignatureAccount,
  SignatureJob, SignatureJobPositionFrame,
  SignatureSettings,
  VisualSignature
} from './document-signature';

export class DocumentSignatureBuilder implements IApiResourceBuilder<DocumentSignature> {
  fromResponse(data): DocumentSignature {
    return new DocumentSignature(
      data.id,
      data.attributes.updated_at);
  }

  toRequest(signature: DocumentSignature) {
    return null;
  }
}

export class VisualSignatureBuilder implements IApiResourceBuilder<VisualSignature> {
  fromResponse(data): VisualSignature {
    return new VisualSignature(
      data.id,
      data.attributes.content,
      data.attributes.skip_meta_info,
      data.attributes.created_at,
      data.attributes.updated_at);
  }

  toRequest(signature: VisualSignature) {
    return null;
  }
}

export class DocumentPreviewBuilder implements IApiResourceBuilder<DocumentPreview> {
  fromResponse(data): DocumentPreview {
    return new DocumentPreview(
      data.id,
      data.attributes.title,
      data.attributes.content);
  }

  toRequest(signature: DocumentPreview) {
    return null;
  }
}

export class SignatureSettingsBuilder implements IApiResourceBuilder<SignatureSettings> {
  fromResponse(data): SignatureSettings {
    return new SignatureSettings(
      data.id,
      data.attributes.name,
      data.attributes.account_present,
      data.attributes.fp_sign_account_account_present,
      data.attributes.description,
      data.attributes.authentication,
      data.attributes.exchange_type,
      data.attributes.fill_out_pdf,
      data.attributes.signature_type);
  }

  toRequest(settings: SignatureSettings) {
    return null;
  }
}

export class SignatureAccountBuilder implements IApiResourceBuilder<SignatureAccount> {
  fromResponse(data): SignatureAccount {
    return new SignatureAccount(
      data.id,
      data.attributes.created_at);
  }

  toRequest(settings: SignatureAccount) {
    return null;
  }
}

export class SignatureJobBuilder implements IApiResourceBuilder<SignatureJob> {
  fromResponse(data): SignatureJob {
    const job = new SignatureJob(
      data.id,
      data.attributes.identifier,
      data.attributes.email,
      data.attributes.first_name,
      data.attributes.last_name,
      data.attributes.signature_settings_id,
      data.attributes.comment,
      data.attributes.status);
    job.projectRoom = data.attributes.project_room
    job.signedAt = data.attributes.signed_at;

    if (data.attributes.signature_frame_id) {
      job.signatureFrameId = data.attributes.signature_frame_id;
    }

    if (data.attributes.signature_frames) {
      job.signatureFrames = data.attributes.signature_frames.map(f => {
        return new SignatureJobPositionFrame(f.id, f.type, f.skip_meta_info, f.format, f.page, f.width, f.height, f.offset_left,
          f.offset_top, f.color, f.reviewed_at, f.image);
      });
    }
    return job;
  }

  toRequest(settings: SignatureJob) {
    return {
      data: {
        id: settings.id,
        type: settings.type,
        attributes: {
          signature_settings_id: settings.settingsId,
          comment: settings.comment,
          email: settings.email,
          identifier: settings.identifier,
          first_name: settings.firstName,
          last_name: settings.lastName,
          mobile: settings.mobile,
          data: settings.data,
          project_room: settings.projectRoom
        }
      }
    };
  }
}

export class DocumentSignatureRequestBuilder implements IApiResourceBuilder<DocumentSignatureRequest> {
  fromResponse(data): DocumentSignatureRequest {
    const signatureRequest = new DocumentSignatureRequest(
      data.id,
      data.attributes.process_id,
      data.attributes.parent_id,
      data.attributes.organization_id,
      data.attributes.originator,
      data.attributes.document_id,
      data.attributes.dms_folder_id,
      data.attributes.dms_account_type,
      data.attributes.title,
      data.attributes.description,
      data.attributes.due_date,
      data.attributes.client_uuid,
      data.attributes.color,
      data.attributes.request_profile,
      data.attributes.signature_processing_state,
      data.attributes.signed_document_uuid,
      data.attributes.audit_log_uuid,
      data.attributes.verification_report_uuid,
      data.attributes.send_documents_by_email,
      data.attributes.created_at,
      data.attributes.updated_at);
    signatureRequest.qes = data.attributes.qes;
    signatureRequest.state = data.attributes.state;
    return signatureRequest;
  }

  toRequest(signatureRequest: DocumentSignatureRequest) {
    const payload = {
      data: {
        id: signatureRequest.id,
        type: signatureRequest.type,
        attributes: {
          process_id: signatureRequest.processId,
          document_id: signatureRequest.documentId,
          client_uuid: signatureRequest.clientId,
          dms_account_type: signatureRequest.dmsAccountType,
          dms_folder_id: signatureRequest.dmsFolderId,
          title: signatureRequest.title,
          description: signatureRequest.description,
          due_date: signatureRequest.dueDate,
          color: signatureRequest.color,
          request_profile: signatureRequest.profile,
          send_documents_by_email: signatureRequest.sendDocumentsByEmail,
          qes: signatureRequest.qes
        }
      }
    };
    if (signatureRequest.originatorSignature) {
      payload['data']['attributes']['originator_signature_page'] = signatureRequest.originatorSignature.page;
      payload['data']['attributes']['originator_signature_width'] = signatureRequest.originatorSignature.w;
      payload['data']['attributes']['originator_signature_height'] = signatureRequest.originatorSignature.h;
      payload['data']['attributes']['originator_signature_offset_left'] = signatureRequest.originatorSignature.x;
      payload['data']['attributes']['originator_signature_offset_top'] = signatureRequest.originatorSignature.y;
      payload['data']['attributes']['originator_signature_content'] = signatureRequest.originatorSignature.content;
      payload['data']['attributes']['document_container_height'] = signatureRequest.originatorSignature.ch;
      payload['data']['attributes']['document_container_width'] = signatureRequest.originatorSignature.cw;
      payload['data']['attributes']['skip_meta_info'] = signatureRequest.originatorSignature.skipMetaInfo;
    }
    return payload;
  }
}

export class OrganizationalVisualBuilder implements IApiResourceBuilder<OrganizationalVisual> {
  fromResponse(data): OrganizationalVisual {
    return new OrganizationalVisual(
      data.id,
      data.attributes.content,
      data.attributes.name,
      data.attributes.font_size,
      data.attributes.enable_profession,
      data.attributes.profession,
      data.attributes.enable_date,
      data.attributes.enable_time,
      data.attributes.updated_at,
      data.attributes.created_at
    );
  }

  toRequest(visual: OrganizationalVisual) {
    return {
      data: {
        id: visual.id,
        type: visual.type,
        attributes: {
          name: visual.name,
          font_size: visual.fontSize,
          enable_profession: visual.enableProfession,
          profession: visual.profession,
          enable_date: visual.enableDate,
          enable_time: visual.enableTime
        }
      }
    }
  }
}
