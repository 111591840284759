import {NgModule} from '@angular/core';
import {ActivityLogItemComponent} from './components/activity-log-item/activity-log-item.component';
import {ActivityLogComponent} from './containers/activity-log/activity-log.component';
import {SharedModule} from 'app/shared/shared.module';
import {ReferencedArtifactsComponent} from './containers/referenced-artifacts/referenced-artifacts.component';
import {FileContainerModule} from '../file-container/file-container.module';
import {
  ThirdPartyAuditActionTimelineEventComponent
} from './containers/third-party-audit-action-timeline-event/third-party-audit-action-timeline-event.component';
import {
  ThirdPartyDetailsTimelineEventComponent
} from './containers/third-party-details-timeline-event/third-party-details-timeline-event.component';
import {LabelsModule} from "../labels/labels.module";


@NgModule({
  declarations: [
    ActivityLogItemComponent,
    ActivityLogComponent,
    ReferencedArtifactsComponent,
    ThirdPartyAuditActionTimelineEventComponent,
    ThirdPartyDetailsTimelineEventComponent
  ],
  imports: [
    SharedModule,
    FileContainerModule,
    LabelsModule,
  ],
  exports: [
    SharedModule,
    ActivityLogComponent,
    FileContainerModule,
    ReferencedArtifactsComponent,
    ThirdPartyAuditActionTimelineEventComponent,
    ThirdPartyDetailsTimelineEventComponent
  ]
})
export class ActivityLogModule {
}
