import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {
  ThirdPartyAuditAction,
  ThirdPartyConfirmationLineItem,
  ThirdPartyConfirmationRequest,
  ThirdPartyConfirmationV2,
  ThirdPartyDetails
} from './audit-third-party';

export class ThirdPartyDetailsBuilder implements IApiResourceBuilder<ThirdPartyDetails> {
  /**
   * {
   *  "data": {
   *    "id": "abae10e0-24a3-4a1b-94f8-2745eec3db55",
   *    "type": "third_party",
   *    "attributes": {
   *      "business_year_starts_at": "2020-01-01T00:00:00.000+01:00",
   *      "business_year_ends_at": "2020-12-31T23:59:59.999+01:00",
   *      "request_type": "open",
   *      "referenced_at": "2021-12-31T00:00:00.000+01:00",
   *      "title": "02 Rechnungen",
   *      "due_date": "2021-12-31T00:00:00.000+01:00",
   *      "type": "liability_invoice",
   *      "external_preamble": null
   *    }
   *  }
   *}
   */
  fromResponse(data): ThirdPartyDetails {
    return new ThirdPartyDetails(
      data.id,
      data.attributes.type,
      data.attributes.title,
      data.attributes.request_type,
      data.attributes.business_year_starts_at,
      data.attributes.business_year_ends_at,
      data.attributes.referenced_at,
      data.attributes.due_date,
      data.attributes.external_preamble_de || data.attributes.default_preamble_de,
      data.attributes.external_preamble_en || data.attributes.default_preamble_en,
      data.attributes.sender_name,
      data.attributes.email_subject,
      data.attributes.email_body || data.attributes.default_email,
      data.attributes.return_address,
      data.attributes.skip_global_email_signature,
      data.attributes.readonly);
  }

  toRequest(thirdParty: ThirdPartyDetails) {
    return {
      data: {
        id: thirdParty.id,
        type: thirdParty.type,
        attributes: {
          title: thirdParty.title,
          business_year_starts_at: thirdParty.businessYearStartsAt,
          business_year_ends_at: thirdParty.businessYearEndsAt,
          referenced_at: thirdParty.referencedAt,
          due_date: thirdParty.dueDate,
          sender_name: thirdParty.emailSenderName,
          email_subject: thirdParty.emailSubject,
          email_body: thirdParty.emailBody,
          return_address: thirdParty.returnAddress,
          external_preamble_de: thirdParty.externalPreambleDe,
          external_preamble_en: thirdParty.externalPreambleEn,
          skip_global_email_signature: thirdParty.skipGlobalEMailSignature
        }
      }
    };
  }
}

export class ThirdPartyConfirmationV2Builder implements IApiResourceBuilder<ThirdPartyConfirmationV2> {
  fromResponse(data): ThirdPartyConfirmationV2 {
    const attrs = data.attributes;
    // const thirdPartyType = attrs.third_party_type;
    const lineItemType = attrs.item_type;
    const requests = attrs.requests.map(req => {
      const request = new ThirdPartyConfirmationRequest(req.id, req.title, req.color,
        req.state, req.due_date, req.received_at, req.updated_at);
      req.line_items.forEach(item => {
        const lineItem = new ThirdPartyConfirmationLineItem(item.id,
          `third_party_${lineItemType}`,
          item.uuid,
          item.identifier,
          item.deviation,
          item.recipient_organization_no,
          item.recipient_organization_name,
          item.recipient_email,
          item.recipient_title,
          item.recipient_name,
          item.reported_value,
          item.unit_of_value,
          item.audited_value,
          item.confirmed_value,
          item.value_confirmed,
          item.provided_comment,
          item.contributed_comment,
          item.third_party_firstname,
          item.third_party_lastname,
          item.third_party_email,
          item.third_party_function,
          item.third_party_company_name,
          item.third_party_place,
          item.third_party_date,
          item.state,
          item.created_at,
          item.updated_at,
          item.account,
          item.invoice_no,
          item.invoice_date,
          item.request_type,
        );
        request.lineItems.push(lineItem);
      });
      const firstItem = request.lineItems[0];
      if (firstItem) {
        request.recipientOrganizationNo = firstItem.recipientOrganizationNo;
        request.recipientOrganizationName = firstItem.recipientOrganizationName;
        request.recipientEmail = firstItem.recipientEmail;
        request.recipientTitle = firstItem.recipientTitle;
        request.recipientName = firstItem.recipientName;
        request.requestType = firstItem.requestType;
      }

      return request;
    });

    return new ThirdPartyConfirmationV2(
      data.id,
      attrs.type,
      attrs.title,
      attrs.color,
      attrs.identifier,
      attrs.client_name,
      attrs.client_id,
      attrs.client_uuid,
      attrs.parent_title,
      attrs.parent_id,
      attrs.state,
      attrs.third_party_type,
      attrs.item_type,
      attrs.referenced_at,
      attrs.business_year_starts_at,
      attrs.business_year_ends_at,
      attrs.created_at,
      requests
      );
  }

  toRequest(thirdParty: ThirdPartyConfirmationV2) {
    return null;
  }
}

/**
 * {
 * 			"id": "458122b4-9b8a-4786-a36a-5b2aa5527924",
 * 			"type": "third_party_audit_actions",
 * 			"attributes": {
 * 				"action_type": "simple_approval",
 * 				"audited_value": "2.34",
 * 				"previous_audited_value": null,
 * 				"reported_value": "12312.0",
 * 				"confirmed_value": "12312.0",
 * 				"previous_difference": "0.0",
 * 				"created_at": "2022-05-19T19:00:49.781+02:00",
 * 				"performer_email": "a.baier@5fsoftware.de",
 * 				"performer_first_name": "Andreas",
 * 				"performer_last_name": "Baier"
 * 			}
 * 		},
 */
export class ThirdPartyAuditActionBuilder implements IApiResourceBuilder<ThirdPartyAuditAction> {
  fromResponse(data): ThirdPartyAuditAction {
    const attrs = data.attributes;
    return new ThirdPartyAuditAction(
      attrs.id,
      attrs.action_type,
      attrs.commit_message,
      attrs.audited_value,
      attrs.previous_audited_value,
      attrs.reported_value,
      attrs.confirmed_value,
      attrs.previous_difference,
      attrs.current_difference,
      attrs.performer_email,
      attrs.performer_first_name,
      attrs.performer_last_name,
      attrs.created_at);
  }

  /**
   * {
   * 	"data": {
   * 		"attributes": {
   * 			"commit_message": "Hello World",
   * 			"action_type": "simple_approved",
   * 			"audited_value": 2.34
   * 		}
   * 	}
   * }
   * @param auditAction
   */
  toRequest(auditAction: ThirdPartyAuditAction) {
    return {
      data: {
        type: auditAction.type,
        attributes: {
          commit_message: auditAction.commitMessage,
          action_type: auditAction.actionType,
          audited_value: auditAction.auditedValue,
        }
      }
    };
  }
}
