import {IResource} from 'app/shared/modules/api/models/resource.interface';

export namespace Operator {
  export interface IOrganization extends IResource {
    id: string;
    name: string;
    countryCode: string;
    cmsEnabledAt: Date;
    motdEnabledAt: Date;
    bookmanEnabledAt: Date;
    fastdocsEnabledAt: Date;
    labelsEnabledAt: Date;
    processManagementEnabledAt: Date;
    dmsSid: string;
    dmsUsername: string;
    dmsEmail: string;
    dmsHumanSize: string;
    dmsUsedInPercent: string;
    dmsDiskSpace: number;
    dmsFree: number;
    dmsDocumentCount: number;
    dmsFolderCount: number;
    createdAt: Date;
    updatedAt: Date;
  }

  export type ValidLicence = 'sales_licence_standard' | 'sales_licence_professional' | 'sales_licence_enterprise';

  export interface ILicencePlanParams {
    licence_plan: ValidLicence;
    owner_id: number;            // User account ID
    user_licence_count?: number; // positive count defaults to 1
    payment_interval?: string;   // Optional: default to 'Yearly'
  }

  export interface IOrganizationMembership {
    id: number;
    name: string;
    email: string;
    membershipId: string;
    fastdocsEnabledAt?: Date;
    bigFilesEnabledAt?: Date;
  }

  export interface IOrganizationParams {
    email: string;
    name: string;
    owner_uid: string;
  }

  export enum OrganizationStatus {
    TrialPhase= 'trial_phase',
    BookedLicencePlan = 'booked_licence_plan',
    Testing = 'testing',
    Support = 'support',
    Hidden = 'hidden',
    Overdue = 'overdue',
    MarkedForDeletion = 'marked_for_deletion'
  }

  export interface IEmailFooter extends IResource {
    title: string,
    content: string,
    creatorEmail: string,
    publishedAt: string,
    published: boolean,
    updatedAt: string,
    createdAt: string,
  }

  export enum FooterType {
    User = 'user-signature',
    Orgnizational = 'orgnization-signature'
  }

  export interface IOrganizationLogo extends IResource {
    title: string;
    url: string;
    current: boolean;
    organizationId: string;
    inviterLogo?: boolean;
  }

  export interface IOrganizationQueryParams {
    includeStorageInfo: boolean
  }
}
