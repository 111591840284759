import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {QuickCollector} from './quickcollector';
import * as dayjs from 'dayjs';

export interface State extends EntityState<QuickCollector> {
  selected?: QuickCollector;
  loading: boolean;
  creating: boolean;
  updating: boolean;
  removing: boolean;
}

export const adapter = createEntityAdapter<QuickCollector>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
  creating: false,
  updating: false,
  removing: false
});
