import {Invitation, InvitationStatus, InvitationType} from './invitation.model';
import {MessageResourceType} from 'app/+store/message/message.interface';
import {Message} from 'app/+store/message/message';

export class InvitationBuilder {
  constructor(private _invitationType = InvitationType.ContactPerson) {}

  fromResponse(data): Invitation {
    let currentState = undefined;
    switch (data.attributes.status) {
      case 'accepted':
      case 'Accepted':
        currentState = InvitationStatus.Accepted;
        break;
      case 'declined':
      case 'Declined':
        currentState = InvitationStatus.Declined;
        break;
      case 'pending':
      case 'Pending':
        currentState = InvitationStatus.Pending;
        break;
      case 'deleted':
      case 'Deleted':
        currentState = InvitationStatus.Deleted;
        break;
      default:
        currentState = InvitationStatus.Undefined;
    }
    const inv = new Invitation(
      data.id,
      data.attributes.invitee_email,
      data.attributes.inviter_name,
      data.attributes.inviter_email,
      data.attributes.inviter_first_name,
      data.attributes.inviter_last_name,
      currentState,
      data.attributes.created_at,
      this._invitationType
    );
    inv.email =  data.attributes.invitee_email;
    inv.belongsToOrganizationId = data.attributes.inviter_organization_uuid;
    return inv;
  }

  toRequest(_: Invitation) {
    return null;
  }

  static invitationToNotification(inv: Invitation): Message {
    const msg = new Message(
      inv.id,
      inv.inviter_name,
      {
        email: inv.inviter_email,
        firstName: inv.inviter_first_name,
        lastName: inv.inviter_last_name
      },
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      inv.current_status !== InvitationStatus.Pending ? inv.created_at : null,
      inv.created_at,
      inv.created_at);
    msg.resourceId = inv.id;
    msg.payload = inv;
    if (inv.type === InvitationType.Member) {
      msg.resourceType = MessageResourceType.MemberInvitation;
      msg.subject = 'INVITATIONS.CONFIRM_MEMBER_REQUEST';
      msg.icon = 'domain';
      msg.unreadIcon = 'domain';
      msg.color = '#233246';
    } else {
      msg.resourceType = MessageResourceType.BusinessInvitation;
      msg.subject = 'INVITATIONS.CONFIRM_CONTACT_REQUEST';
      msg.icon = 'person';
      msg.unreadIcon = 'person';
      msg.color = '#00ca5c';
    }
    return msg;
  }
}
