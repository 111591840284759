<!-- Error Dialog -->
<div class="mat-dialog-inner-wrap" *ngIf="!!errorState">
  <div class="mat-dialog-header dvtx-bg-color dvtx-bg-cool-grey-25">
    <h1 mat-dialog-title>
      {{ 'ERROR.GENERAL.CONNECTION_ERROR' | translate }}
    </h1>
    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>

  <div class="folder-dialog-content folder-dialog-errors mat-dialog-content text-center"
       mat-dialog-content>
    <mat-icon class="folder-dialog-error-info-icon status-color-info">error</mat-icon>
    <div class="folder-dialog-error-message" *ngFor="let error of errors">
      {{ error.title }}
    </div>
    <div class="folder-dialog-error-message" *ngIf="fatalError">
      {{ fatalError }}
    </div>
  </div>

  <div class="mat-dialog-footer">
    <mat-dialog-actions class="d-flex justify-content-end mt-1">
      <button type="button" mat-raised-button mat-dialog-close color="primary">
        {{ 'GENERAL.CLOSE_ACTION' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
<!-- End Error Dialog -->

<!-- Folder Dialog -->
<div class="mat-dialog-inner-wrap" *ngIf="!errorState">
  <div class="mat-dialog-header dvtx-bg-color dvtx-bg-cool-grey-25">
    <h1 mat-dialog-title>
      {{ 'DMS.FOLDER_OVERVIEW' | translate }}
    </h1>
    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>

  <div class="folder-dialog-content mat-dialog-content" mat-dialog-content>
    <div class="folder-toolbar">
      <button  mat-button (click)="toggleAdd()" title="{{ 'DMS.CREATE_FOLDER_ACTION' | translate }}">
        <i class="fa fa-plus" aria-hidden="true"></i> {{ 'GENERAL.CREATE_ACTION' | translate }}
      </button>
      <button  mat-button color="warn" (click)="deleteFolder()" [disabled]="!selectedNode || isRootFolder()" title="Ordner löschen">
        <i class="fa fa-trash-o" aria-hidden="true"></i> {{ 'GENERAL.DELETE_ACTION' | translate }}
      </button>
      <button mat-button (click)="refreshTree()" title="{{ 'GENERAL.REFRESH_ACTION' | translate }}">
        <i class="fa fa-refresh" aria-hidden="true"></i>
        {{ 'GENERAL.REFRESH_ACTION' | translate }}
      </button>
    </div>

    <div *ngIf="isCreateInput" class="added-folder">
      <mat-form-field>
        <input #folderName matInput placeholder="Name des Ordners">
      </mat-form-field>
      <button type="button"
              mat-raised-button
              [disabled]="createDisabled || (folderName.value.length === 0)"
              (click)="createFolder(folderName.value)">{{ 'DMS.CREATE_FOLDER_ACTION' | translate }}</button>
    </div>

    <div>
      <!-- TODO: refactor style css  -->
      <mat-spinner *ngIf="loading && (!nodes || nodes.length == 0)" [diameter]="20" style="margin: 60px auto; height: 40px;"></mat-spinner>
    </div>

    <tree-root #treeComponent
               [nodes]="nodes"
               (activate)="activateChanged($event)"
               (initialized)="onTreeInit($event)">
    </tree-root>
  </div>

  <div class="mat-dialog-footer">
    <mat-dialog-actions class="d-flex justify-content-end mt-1">
      <button type="button" mat-raised-button mat-dialog-close>{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
      <button type="button" mat-raised-button color="primary" *ngIf="!errorState" (click)="selectFolder()">{{ 'GENERAL.SELECT_ACTION' | translate }}</button>
    </mat-dialog-actions>
  </div>
</div>
