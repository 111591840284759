import {startWith, switchMap} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ListingService} from '../../../api/services/listing.service';

@Component({
  selector: 'dvtx-api-autocompleted-input-select',
  templateUrl: './api-autocompleted-input-select.component.html',
  styleUrls: ['./api-autocompleted-input-select.component.scss']
})
export class ApiAutocompletedInputSelectComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() controlName;
  @Input() prefixIcon?;
  @Input() checkedOnChoose?;
  @Input() placeholder = '';
  @Input() disabled = false;

  listing: any;
  listing$: any;
  showCheckIcon = false;

  @Input()
  set fetchListingType(listingType) {
    if (listingType) {
      this.listing$ = this._listingService.fetchListing(listingType);

      this.listing = this.form.controls[this.controlName].valueChanges.pipe(startWith(null), switchMap(
        (term: string) => {
          return term ? this.filterItem(term) : this.listing$;
        }
      ),);
    }
  }

  constructor(private _listingService: ListingService) {
  }

  ngOnInit(): void {
    this.form.controls[this.controlName].valueChanges.subscribe(value => {
      value ? this.showCheckIcon = true : this.showCheckIcon = false;
    });
  }

  filterItem(term: string) {
    return this.listing$.map(elem => elem.filter(item => item.toLowerCase().indexOf(term.toLowerCase()) === 0));
  }

  // Used to display the text in the input field.
  displayFn(item): string {
    return item;
  }

  fn(e) {
    console.log(e);
  }
}
