import { Component, Inject, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dvtx-quick-share-success-dialog-container',
  templateUrl: './quick-share-success-dialog.component.html',
  styleUrls: ['./quick-share-success-dialog.component.scss']
})
export class QuickShareSuccessDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<QuickShareSuccessDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }
}
