<div class="address-control">
  <div class="address-header-line">
    <dvtx-api-select-cva
      *ngIf="enableLocationOrType"
      class="type-or-location"
      elementName="Ort oder Typ"
      listingType="typed_email-type"
      listingPath="name"
      [(ngModel)]="address.locationOrType"
      [isDisabled]="isDisabled"
    >
    </dvtx-api-select-cva>
    <div [formGroup]="form">
      <mat-form-field>
        <input matInput
               #autocomplete
               formControlName="full"
               [placeholder]="elementName"
               (focus)="onFocus($event)"
        >
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="
  address
  &&
  (autocomplete.value.length>0
  || (address.street && address.street !== '')
  || (address.zip && address.zip !== '')
  || (address.city && address.city !== '')
  || (address.streetNo && address.streetNo !== '')
  )">
    <div class="address-container row">
      <div class="col-12 col-md-8 m-0">
        <dvtx-text-input-cva
          class="long-item"
          [elementName]="'CONTACTS.STREET' | translate"
          [(ngModel)]="address.street"
          [isDisabled]="isDisabled"

        ></dvtx-text-input-cva>
      </div>
      <div class="col-12 col-md-4 m-0">
        <dvtx-text-input-cva
          class="short-item"
          [elementName]="'CONTACTS.STREET_NO' | translate"
          [(ngModel)]="address.streetNo"
          [isDisabled]="isDisabled"
        ></dvtx-text-input-cva>
      </div>
      <div class="col-12 col-md-8 m-0">
        <dvtx-text-input-cva
          class="long-item"
          [elementName]="'CONTACTS.CITY' | translate"
          [(ngModel)]="address.city"
          [isDisabled]="isDisabled"
        ></dvtx-text-input-cva>
      </div>
      <div class="col-12 col-md-4 m-0">
        <dvtx-text-input-cva
          class="short-item"
          [elementName]="'CONTACTS.ZIP' | translate"
          [(ngModel)]="address.zip"
          [isDisabled]="isDisabled"
        ></dvtx-text-input-cva>
      </div>
    </div>
  </div>
</div>
