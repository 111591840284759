import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MembershipActions } from 'app/+store/membership';
import { OperatorMemberService } from 'app/+store/operator/member/member.service';
import { getSelected } from 'app/+store/organization/organization.selectors';
import { EmailValidator } from 'app/lib/validator/email_valid';
import { SimpleEmailAddress, SimpleEmailAddressRequiredValidator } from 'app/modules/contacts/models/contact.interface';
import { fieldType } from 'app/modules/registration/components/password-form/password-form.component';
import { RegistrationService } from 'app/modules/registration/services/registration.service';
import { AppState } from 'app/reducers';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';
import { first } from 'rxjs/internal/operators/first';
import { MembershipInvitationComponent } from '../membership-invitation/membership-invitation.component';

@Component({
  selector: 'dvtx-create-employee-dialog',
  templateUrl: './create-employee-dialog.component.html',
  styleUrls: ['./create-employee-dialog.component.scss']
})
export class CreateEmployeeDialogComponent implements OnInit {
  orgaTypeId: string = '';
  createEmployeeForm: UntypedFormGroup;
  color = '#007aff';
  fieldType = fieldType;
  showFirstPass: string = fieldType.Password;
  showResetPassword = false;
  passwordResetValue: string;
  passwordChangeOngoing = false;
  selectedIndexTab: any = '1'

  // true when member is being created, false else.
  // During member creation a DMS is created. This can take some time and
  // a progress bar is shown.
  // In this case we hiding the form by CSS not disabling it (ngIf), otherwise this creates issues with
  // the viewchild below.
  public employeeCreationOngoing = false;

  @ViewChild('membershipInvitation') membershipInvitationComponent: MembershipInvitationComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mdr: MatDialogRef<CreateEmployeeDialogComponent>,
    private store: Store<AppState>,
    private _memberService: OperatorMemberService,
    private _translateSvc: TranslateService,
    private _notifyService: NotificationService,
    private _cdr: ChangeDetectorRef,
    fb: UntypedFormBuilder
  ) {
    this.createEmployeeForm = fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      note: [''],
      notifyUser: [false],
      password: ['', Validators.required],
      email: [new SimpleEmailAddress(), SimpleEmailAddressRequiredValidator()],
    });
    if (data && data.color) {
      this.color = data.color;
    }
    if (data && data.selectedIndexTab) {
      this.selectedIndexTab = data.selectedIndexTab;
    }
  }

  ngOnInit() {
  }

  showFirstPassword() {
    this.showFirstPass = fieldType.Text;
  }

  hideFirstPassword() {
    this.showFirstPass = fieldType.Password;
  }

  verifyPasswordStrength(password) {
    return RegistrationService.verifyPasswordStrength(password)
  }

  isPasswordInvalid(password) {
    const result = RegistrationService.verifyPasswordStrength(password);
    return !result || result && result.level !== 'success';
  }

  createEmployee() {
    this.employeeCreationOngoing = true;
    const employee: any = {}
    employee.email = this.createEmployeeForm.controls['email'].value;
    employee.password = this.createEmployeeForm.controls['password'].value;
    employee.firstName = this.createEmployeeForm.controls['firstName'].value;
    employee.lastName = this.createEmployeeForm.controls['lastName'].value;
    employee.note = this.createEmployeeForm.controls['note'].value;
    employee.notifyUser = this.createEmployeeForm.controls['notifyUser'].value;

    // Switch view to progress mode before creation is ongoing.
    this._cdr.detectChanges();

    // Give the action another run inside the event queue, so the progress bar can be shown.
    setTimeout(_ => {
      this.store.select(getSelected).pipe(
        first()
      ).subscribe((org) => {
          this._memberService.addEmployee(employee, org.id).pipe(first()).subscribe((res) => {
            this.employeeCreationOngoing = false;
            this.store.dispatch(new MembershipActions.LoadAll(org.id));
            this._notifyService.success('CONTACTS.EMPLOYEE_ADDED_SUCCESSFULLY')
            this.mdr.close(employee);
            this.employeeCreationOngoing = false;
            return;
          }, err => {
            console.error(err);
            this.employeeCreationOngoing = false;
          });
      });
      }, 10);
  }

  closeDialog(result?) {
    this.mdr.close(result);
  }

  public isInviteeEmailValid() {
    if (this.membershipInvitationComponent) {
      if (!this.membershipInvitationComponent.inviteeEmail
        || !this.membershipInvitationComponent.inviteeEmail.length)
        return false;
      return EmailValidator.emailValid(this.membershipInvitationComponent.inviteeEmail);
    } else {
      return false;
    }
  }

  openMemberInvitationDialog() {
    if (this.membershipInvitationComponent) {
      this.membershipInvitationComponent.openMemberInvitationDialog();
    }
  }

  resetForms() {
    // didnt use form reset since email control cannot reset because its a new SimpleEmailAddress object
    if (this.createEmployeeForm) {
      this.createEmployeeForm.controls.password.reset();
      this.createEmployeeForm.controls.firstName.reset();
      this.createEmployeeForm.controls.lastName.reset();
      this.createEmployeeForm.controls.note.reset();
      this.createEmployeeForm.controls.notifyUser.reset();
      this.createEmployeeForm.controls.email.patchValue(new SimpleEmailAddress());
    }
    if (this.membershipInvitationComponent) {
      this.membershipInvitationComponent.inviteeEmail = '';
    }
    this._cdr.detectChanges();
  }
}
