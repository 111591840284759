import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {environment} from 'environments/environment';
import {DvtxControlValueAccessor} from './../1_control-value-accessor-components/DvtxControlValueAccessor';
import {Subject} from 'rxjs/internal/Subject';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
declare let $: any; // The Froala instance will be attached to the $ variable

@Component({
  selector: 'dvtx-wysiwyg-editor-cva',
  templateUrl: './wysiwyg-editor-cva.component.html',
  styleUrls: ['./wysiwyg-editor-cva.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WysiwygEditorCvaComponent),
      multi: true,
    }
  ]
})
export class WysiwygEditorCvaComponent extends DvtxControlValueAccessor implements OnDestroy, OnInit {
  readonly DEFAULT_TOOLBAR = [['undo', 'redo', '|', 'fontSize', 'fontFamily', 'color', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript',
    'outdent', 'indent', 'clearFormatting', 'insertTable', 'html']]; // 'insertVideo', 'imageReplace', 'imageAlign', 'imageRemove', '|', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'
  onDestroy = new Subject();
  dasError: boolean = false;
  fileError: boolean = false;
  bodyHTML: string;

  DEFAULT_OPTIONS;
  @Input() preview?: boolean;
  @Input() placeholder = 'PROJECT_ROOM.ADD_DESCRIPTION_2';

  @Input() height: number | string = 300;
  @Input() heightMax: number | string = 300;
  @Input() toolbar = null;

  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>(null);
  @Input() isDisabled = false;
  _options;
  editable: boolean = false;
  @Input() editableFeature = true;
  private initialValue = '';
  @Input() set options(options) {
    this._options = options;
  }

  @Input() set value(value) {
    this.body = value
  }

  get options() {
    return Object.assign({}, this.DEFAULT_OPTIONS, {
      placeholderText: this._translate.instant(this.placeholder),
      heightMax: this.heightMax,
      heightMin: this.height,
      height: this.height
    }, this._options);
  }

  _body: string;
  get body(): string {
    return this._body;
  }

  set body(value: string) {
    this._body = value;
    this.notifyOnChange(value);
    this.notifyOnTouch();
  }

  constructor(protected injector: Injector, private _translate: TranslateService, private _cdr: ChangeDetectorRef,
    ) {
    super();

  }

  ngOnInit() {
    this.initOptions();
    this.initialValue = this.body;
  }

  initOptions() {
    const self = this;
    this.DEFAULT_OPTIONS = {
      key: environment.froalaKey,
      attribution: false,
      toolbarButtons: this.toolbar || this.DEFAULT_TOOLBAR,
      toolbarButtonsXS: [['undo', 'redo', '-', 'bold', 'italic', 'underline']], // 'insertVideo'
      // videoResponsive: true,
      // videoInsertButtons: ['videoByURL', 'videoEmbed'],
      charCounterCount: false,
      quickInsertTags: null,
      fontSizeDefaultSelection: '16',
      height: this.height,
      heightMin: this.height,
      heightMax: this.heightMax,
      zIndex: 9999,
      enter: $.FE?.ENTER_BR,
      imageMaxSize: 200 * 1024,
      fileAllowedTypes: ['*'],
      fileMaxSize: 20 * 1024 * 1024,
      fileUpload: true,
      draggable: true,
      events: {
        'image.beforeUpload' : function(files) {
          self.fileError = false;
          const editor = this;
          if (files.length) {
            const reader = new FileReader();
            reader.onload = function(event: any) {
              const result = event.target.result;
              editor.image.insert(result, null, null, editor.image.get());
            };
            let dasFiles = files[0];
            // if image is copy pasted convert blob to file
            if (dasFiles && dasFiles.type && !dasFiles.name) {
              // A Blob() is almost a File() - it's just missing the two properties below which we will add
              // Cast to a File() type
              dasFiles = new File([dasFiles], 'name', {type: dasFiles.type.replace(';base64', '')});
            }
            const imageSize = dasFiles.size;
            if (imageSize > 100000) {  // around 100KB
              self.dasError = true;
              editor.image.remove();
            } else {
              reader.readAsDataURL(dasFiles);
              self.dasError = false;
            }
          }
          editor.popups.hideAll();
          self._cdr.detectChanges();
          return false;
        },
        'file.beforeUpload' : function(files) {
          const editor = this;
          self.fileError = true;
          self.dasError = false;
          editor.popups.hideAll();
          self._cdr.detectChanges();
          return false;
          let dasFiles = files[0];
          if (files.length) {
            const reader = new FileReader();
            reader.onload = function (event: any) {
              const result = event.target.result;
              editor.file.insert(result, dasFiles.name , {result: result});
            };
            // if image is copy pasted convert blob to file
            if (dasFiles && dasFiles.type && !dasFiles.name) {
              // A Blob() is almost a File() - it's just missing the two properties below which we will add
              // Cast to a File() type
              dasFiles = new File([dasFiles], 'name', {type: dasFiles.type.replace(';base64', '')});
            }
            const imageSize = dasFiles.size;
            if (imageSize > 2000000) {  // around 20MB
              self.dasError = true;
              editor.file.remove();
            } else {
              reader.readAsDataURL(dasFiles);
              self.dasError = false;
            }
          }
          editor.popups.hideAll();
          return false;
        },
        'blur': () => {
          if (this.onBlur) {
            if (this.initialValue != this.body) {
              this.onBlur.emit(this.body);
              this.initialValue = this.body;
            }
            this.editable = false;
            this._cdr.detectChanges();
          }
        }
      }
    };

     if(this.editableFeature) {
      if (this.DEFAULT_OPTIONS) {
        this.DEFAULT_OPTIONS.autofocus = true;
      }
     }
  }


  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  writeValue(value): void {
    if (value !== undefined) {
      this.body = value;
      this.notifyOnChange(this.body);
    }
  }

  public focusOnInput() {
    const element = <HTMLElement>document.getElementsByClassName('fr-element fr-view')[0];
    setTimeout(_ => {
      element.focus();
    }, 10);
  }

  /**
   * Changes the view of the editor into the editing mode with toolbar.
   * @param $event
   */
  public changeEditable($event) {
    // Don't change into edit mode if the event is of type link.
    // Reason: The triggered event was made by a click on a link, that should just open it (Usability).

    // NOTE (ab): I used target, here. Needs to be discussed if srcElement could be a better option.
    if ($event && $event.target && $event.target.nodeName === 'A') {
      $event.stopPropagation();
      return;
    }

    if (this.isDisabled) {
      return;

    } else {
      // NOTE (ab): Not fully understood: Seems to cause the need of a double click because of delayed change detection.
      // setTimeout(() => {
      //   this.editable = true;
      // }, 500);
      // Changed to:
      this.editable = true;
      this._cdr.detectChanges();
    }
  }
}
