<div [formGroup]="form">
  <div class="number-wrap">
    <mat-form-field [matTooltip]="placeholder">
      <input type="number" matInput [placeholder]="placeholder"
             [formControlName]="controlName" onkeydown="return event.keyCode != 190"
             [name]="controlName" [id]="controlName" autocomplete="new-{{controlName}}" [required]="required">
    </mat-form-field>
    <mat-placeholder class="qualifier" *ngIf="text">{{text}}</mat-placeholder>
  </div>

  <ng-template [ngIf]="hint">
    <mat-hint align="start">
      <span class="dvtx-fg-color dvtx-fg-warning" style="font-size: 0.9rem;">
        {{ 'INPUT.NUMBER_INPUT_HINT' | translate }}
      </span>
    </mat-hint>
  </ng-template>
</div>
