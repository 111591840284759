import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  MarkHidden,
  MarkHiddenFail,
  MarkHiddenSuccess,
  MarkVisible,
  MarkVisibleFail,
  MarkVisibleSuccess,
  PartnerLinkActionTypes,
  SaveSilent,
  SaveSilentFail,
  SaveSilentSuccess,
} from './partner-link.actions';
import {PartnerLink} from './partner-link';
import {PartnerLinkService} from './partner-link.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {PartnerLinkVisibility} from './partner-link.interface';
import {PartnerLinkMgmtService} from './partner-link-mgmt.service';

@Injectable()
export class PartnerLinkEffects {
  @Effect()
  update$ = this.actions.pipe(
    ofType(PartnerLinkActionTypes.SaveSilent),
    switchMap((action: SaveSilent) => {
      return this._svc.update(action.partnerLink).pipe(
        first(),
        switchMap((partnerLink: PartnerLink) => {
          return [new SaveSilentSuccess(partnerLink)];
        }),
        catchError(err => {
          console.error(err);
          this._notifyService.error('HTTP_ERROR.TRY_AGAIN')
          return of(new SaveSilentFail(err));
        }));
    })
  );

  @Effect()
  loadOne$ = this.actions.pipe(
    ofType(PartnerLinkActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((partnerLink: PartnerLink) => {
          return [new LoadOneSuccess(partnerLink)];
        }),
        catchError(err => {
          console.error(err);
          this._notifyService.error('HTTP_ERROR.TRY_AGAIN')
          return of(new LoadOneFail(err));
        }));
    })
  );

  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(PartnerLinkActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        concatMap((res: PartnerLink[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          this._notifyService.error('HTTP_ERROR.TRY_AGAIN')
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  markVisible$ = this.actions.pipe(
    ofType(PartnerLinkActionTypes.MarkVisible),
    switchMap((action: MarkVisible) => {
      return this._svc.markVisible(action.partnerLink.id).pipe(
        first(),
        concatMap((item: PartnerLink) => {
          item.visibility = PartnerLinkVisibility.Visible;
          return [new MarkVisibleSuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          this._notifyService.error('HTTP_ERROR.TRY_AGAIN')
          return of(new MarkVisibleFail(err));
        }));
    })
  );

  @Effect()
  markHidden$ = this.actions.pipe(
    ofType(PartnerLinkActionTypes.MarkHidden),
    switchMap((action: MarkHidden) => {
      return this._svc.markHidden(action.partnerLink.id).pipe(
        first(),
        concatMap((item: PartnerLink) => {
          item.visibility = PartnerLinkVisibility.Hidden;
          return [new MarkHiddenSuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          this._notifyService.error('HTTP_ERROR.TRY_AGAIN')
          return of(new MarkHiddenFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: PartnerLinkService,
              private _mgmtSvc: PartnerLinkMgmtService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
