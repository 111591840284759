import {NgModule} from '@angular/core';
import {RegistrationRoutes} from './registration.routes';
import {RegistrationComponent} from './registration.component';
import {FilesUploadModule} from 'app/shared/modules/file-upload/file-upload.module';
import {ApiErrorService} from 'app/shared/modules/api/services/api-error.service';
import {RegistrationService} from './services/registration.service';
import {EmailExistsComponent} from './components/email-exists/email-exists';
import {RegistrationSetupComponent} from './components/registration-setup/registration-setup.component';
import {FoxdoxModule} from 'app/modules/foxdox/foxdox.module';
import {OrganizationModule} from '../organization/organization.module';
import {PasswordRegistrationFormV2Component} from './components/password-form-v2/password-form-v2.component';
import {StatusChecklistComponent} from './components/status-checklist/status-checklist.component';
import {BrowserSupportModule} from '../browser-support/browser-support.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {XTenantInterceptor} from '../../services/x-tenant.interceptor';
import {PasswordRegistrationFormComponent} from './components/password-form/password-form.component';

@NgModule({
  imports: [
    RegistrationRoutes,
    BrowserSupportModule,
    FoxdoxModule,
    FilesUploadModule,
    OrganizationModule
  ],
  declarations: [
    RegistrationComponent,
    RegistrationSetupComponent,
    EmailExistsComponent,
    PasswordRegistrationFormComponent,
    PasswordRegistrationFormV2Component,
    StatusChecklistComponent,
  ],
  providers: [
    ApiErrorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XTenantInterceptor,
      multi: true,
    },
    RegistrationService
  ]
})
export class RegistrationModule {
}
