import {adapter, initialState, State} from './fibu-process.state';
import {FibuProcessActions, FibuProcessActionTypes} from './fibu-process.actions';

export function reducer(state = initialState, action: FibuProcessActions): State {
  const current = state.selected;
  switch (action.type) {
    case FibuProcessActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case FibuProcessActionTypes.LoadAllSuccess:
      if (action.refresh) {
        return adapter.setAll(action.fibuProcesses, {
            ...state,
            loading: false,
        });
      }
      return adapter.upsertMany(action.updatedProcesses, {
        ...state,
        loading: false,
      });
    case FibuProcessActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}
