import {Action} from '@ngrx/store';
import {Licence} from 'app/shared/modules/api/models/licence';

export enum LicenceActionTypes {
  LoadAll = '[Licence] Load All',
  LoadAllSuccess = '[Licence] Load All Success',
  LoadAllFail = '[Licence] Load All Fail',
}

export class LoadAll implements Action {
  readonly type = LicenceActionTypes.LoadAll;

  constructor(public payload: any) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = LicenceActionTypes.LoadAllSuccess;

  constructor(public payload: Licence[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = LicenceActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export type LicenceActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail;
