import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './external-access.state';

export const stateKey = 'external-access';
const getExternalAccessState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getExternalAccessEntities,
  selectAll: getAllExternalAccesss,
} = adapter.getSelectors(getExternalAccessState);

export const loadingState = createSelector(
  getExternalAccessState,
  (state) => state.loading
);

export const getExternalAccessByProcessId = (id: string) => createSelector(
  getAllExternalAccesss,
  (externalAccesses) => externalAccesses.filter(ea => ea.processId === id)
);

export const getExternalAccessById = (id: string) => createSelector(
  getExternalAccessEntities,
  (externalAccesses) => externalAccesses[id]
);

export const getExternalAccessDocumentCount = (id: string) => createSelector(
  getExternalAccessEntities,
  (externalAccesses) => {
    const ea = externalAccesses[id];
    if (ea) {
      return ea.documentIds.length;
    } else {
      return 0;
    }
  }
);
