import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ProcessParticipant} from './process-participant';

export interface State extends EntityState<ProcessParticipant> {
  loading: boolean;
}

export const adapter = createEntityAdapter<ProcessParticipant>();

export const initialState: State = adapter.getInitialState({
  loading: false
});
