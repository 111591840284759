import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {LineTruncationLibModule} from 'ngx-line-truncation';
import {TruncatedDescriptionComponent} from './containers/truncated-description/truncated-description.component';
import {ApiModule} from 'app/shared/modules/api/api.module';
import { BaseFormElementsModule } from '../base-form-elements/base-form-elements.module';

const EXPORTED_COMPONENTS = [
  TruncatedDescriptionComponent
];

@NgModule({
  imports: [
    ApiModule,
    CommonModule,
    MatIconModule,
    LineTruncationLibModule,
    BaseFormElementsModule
  ],
  declarations: [
    ...EXPORTED_COMPONENTS
  ],
  exports: [
    ...EXPORTED_COMPONENTS
  ]
})
export class TruncatedContentModule {
}
