import {Injectable} from '@angular/core';
import {catchError, concatMap, first, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  FastdocsActionTypes,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess
} from './fastdocs.actions';
import {FastdocsService} from './fastdocs.service';
import {Fastdocs} from './fastdocs';

@Injectable()
export class FastdocsEffects {
  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(FastdocsActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        map((res: Fastdocs[]) => {
          return new LoadAllSuccess(res);
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err))
        })
      );
    })
  );

  @Effect()
  loadOne$ = this.actions.pipe(
    ofType(FastdocsActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((task: Fastdocs) => {
          return [new LoadOneSuccess(task)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err))
        })
      );
    })
  );

  constructor(
    private actions: Actions,
    private _svc: FastdocsService) {
  }

}
