<div class="card">
  <div class="card-content">
    <div>
      <img class="foxdox-logo" src="/assets/img/foxdox.png">
      <form [formGroup]="form" novalidate>
        <mat-grid-list cols="5" rowHeight="64px">
          <mat-grid-tile [colspan]="5">
            <mat-form-field>
              <input matInput placeholder="{{ 'AUTH.USERNAME' | translate }}" formControlName="foxdoxUsername" required>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="5">
            <mat-form-field>
              <input matInput type="password" placeholder="{{ 'AUTH.PASSWORD' | translate }}" formControlName="foxdoxPassword" required>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="5" *ngIf="error">
            <span class="error">{{ error }}</span>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="5">
            <div style="width: 100%;">
              <button mat-raised-button color="primary" [disabled]="form.invalid || buttonIsClicked" (click)="submit()">
                {{ 'FOXDOX.CONNECT_ACCOUNTS_BUTTON' | translate }}
              </button>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </form>
    </div>
  </div>
</div>
