import {Action} from '@ngrx/store';
import {CmsArticle} from './cms-article';

export enum CmsArticleActionTypes {
  Create = '[CmsArticle] Create',
  CreateSuccess = '[CmsArticle] Create Success',
  CreateFail = '[CmsArticle] Create Fail',
  Remove = '[CmsArticle] Remove',
  RemoveSuccess = '[CmsArticle] Remove Success',
  RemoveFail = '[CmsArticle] Remove Fail',
  Save = '[CmsArticle] Save',
  SaveSuccess = '[CmsArticle] Save Success',
  SaveFail = '[CmsArticle] Save Fail',
  Status = '[CmsArticle] Status',
  LoadAll = '[CmsArticle] Load All',
  LoadAllSuccess = '[CmsArticle] Load All Success',
  LoadAllFail = '[CmsArticle] Load All Fail',
  LoadOne = '[CmsArticle] Load One',
  LoadOneSuccess = '[CmsArticle] Load One Success',
  LoadOneFail = '[CmsArticle] Load One Fail'
}

export class Create implements Action {
  readonly type = CmsArticleActionTypes.Create;

  constructor(public article: CmsArticle) {
  }
}

export class CreateSuccess implements Action {
  readonly type = CmsArticleActionTypes.CreateSuccess;

  constructor(public article: CmsArticle) {
  }
}

export class CreateFail implements Action {
  readonly type = CmsArticleActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = CmsArticleActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = CmsArticleActionTypes.RemoveSuccess;

  constructor(public article: CmsArticle) {
  }
}

export class RemoveFail implements Action {
  readonly type = CmsArticleActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = CmsArticleActionTypes.Save;

  constructor(public article: CmsArticle) {
  }
}

export class SaveSuccess implements Action {
  readonly type = CmsArticleActionTypes.SaveSuccess;

  constructor(public article: CmsArticle) {
  }
}

export class SaveFail implements Action {
  readonly type = CmsArticleActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = CmsArticleActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = CmsArticleActionTypes.LoadAllSuccess;

  constructor(public articles: CmsArticle[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = CmsArticleActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = CmsArticleActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = CmsArticleActionTypes.LoadOneSuccess;

  constructor(public article: CmsArticle) {
  }
}

export class LoadOneFail implements Action {
  readonly type = CmsArticleActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type CmsArticleActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
