import {NgModule} from '@angular/core';
import {ClientOverviewComponent} from './containers/client-overview/client-overview.component';
import {ClientContainerComponent} from './containers/client-container/client-container.component';
import {clientRoutes} from './client.routes';
import {ClientDetailsComponent} from './containers/client-details/client-details.component';
import {ClientWorkflowsComponent} from './containers/client-workflows/client-workflows.component';
import {ClientNavBarComponent} from './components/client-nav-bar/client-nav-bar.component';
import {ProcessTableModule} from '../workflow-engine/modules/process-table/process-table.module';
import {ClientBookmanComponent} from './containers/client-bookman/client-bookman.component';
import {FibuAccoutNoteComponent} from './components/fibu-accout-note/fibu-accout-note.component';
import {DMSSelectorComponent} from './components/dms-selector/dms-selector.component';
import {FolderModule} from '../workflow-engine/modules/folder/folder.module';
import {DropdownContentModule} from 'app/five-f/dropdown-content/dropdown-content.module';
import {AuditTrailModalComponent} from './components/audit-trail-modal/audit-trail-modal.component';
import {AuditTrailComponent} from './components/audit-trail/audit-trail.component';
import {InViewportModule} from 'ng-in-viewport';
import {ProjectOverviewModule} from '../project-overview/project-overview.module';
import {ProcessDialogModule} from '../workflow-engine/modules/process-dialog/process-dialog.module';
import {SharedModule} from 'app/shared/shared.module';
import {PersonSelectorAutocompleteModule} from 'app/shared/modules/person-selector-autocomplete/person-selector-autocomplete.module';
import {ClientTableComponent} from './modules/client-table/components/client-table/client-table.component';
import {EditClientDialogComponent} from './modules/client-table/components/edit-client-dialog/edit-client-dialog.component';
import {TooltipModule} from 'app/five-f/tooltip/tooltip.module';
import {ParticipantClientLinkComponent} from './modules/client-table/components/participant-client-link/participant-client-link.component';
import {FiltersContainerModule} from 'app/five-f/filters/filters-container.module';
import {AddressBookTableModule} from '../address-book/modules/address-book-table/address-book-table.module';
import {ParticipantModule} from '../workflow-engine/modules/participant/participant.module';
import {ClientDatevLoginComponent} from './containers/client-datev-login/client-datev-login.component';
import {MobileActivationPanelComponent} from './modules/client-table/components/mobile-activation-panel/mobile-activation-panel.component';

@NgModule({
  imports: [
    clientRoutes,
    ProcessTableModule,
    FolderModule,
    DropdownContentModule,
    InViewportModule,
    ProjectOverviewModule,
    ProcessDialogModule,
    SharedModule,
    TooltipModule,
    InViewportModule,
    PersonSelectorAutocompleteModule,
    FiltersContainerModule,
    AddressBookTableModule,
    ParticipantModule
  ],
  declarations: [
    ClientOverviewComponent,
    ClientContainerComponent,
    ClientDetailsComponent,
    ClientWorkflowsComponent,
    ClientNavBarComponent,
    ClientBookmanComponent,
    ClientDatevLoginComponent,
    FibuAccoutNoteComponent,
    DMSSelectorComponent,
    AuditTrailModalComponent,
    AuditTrailComponent,
    ClientTableComponent,
    EditClientDialogComponent,
    ParticipantClientLinkComponent,
    MobileActivationPanelComponent
  ],
  exports: [
    ClientOverviewComponent,
    SharedModule,
    ProcessDialogModule,
    AddressBookTableModule,
    ClientTableComponent,
    ParticipantModule
  ]
})
export class ClientModule {
}
