import {distinctUntilKeyChanged, filter, takeUntil, first, switchMap} from 'rxjs/operators';
import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {SplitViewDialogComponent} from 'app/shared/modules/page-container/split-view-dialog/split-view-dialog.component';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ContactSelectors} from 'app/+store/contact';
import {ProcessParticipant} from 'app/+store/process-participant/process-participant';
import {ProcessParticipantActions, ProcessParticipantSelectors} from 'app/+store/process-participant';
import {AngularTokenService} from 'angular-token';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {Router} from '@angular/router';
import {AddOrInviteActionType} from '../add-and-invite/add-and-invite.component';
import {Organization} from 'app/models/organization.model';
import {OrganizationSelectors} from 'app/+store/organization';
import {environment} from '../../../../../../../environments/environment';
import {ProcessParticipantService} from 'app/+store/process-participant/process-participant.service';
import {ConfirmationDialogParticipantComponent} from 'app/shared/components/dialogs/confirmation-dialog-participant/confirmation-dialog-participant.component';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {AddressBookTableHelper} from '../../../../../address-book/modules/address-book-table/containers/address-book-table/address-book-table.helper';

export enum ParticipantActionType {
  ParticipantRole = 'ParticipantRole',
  ParticipantRoleBarButton = 'ParticipantRoleBarButton',
  ParticipantRoleMenuItem = 'ParticipantRoleMenuItem',
  ParticipantRoleStandAlone = 'ParticipantRoleStandAlone',
}

@Component({
  selector: 'dvtx-participant-role-dialog',
  templateUrl: './participant-role-dialog.component.html',
  styleUrls: ['./participant-role-dialog.component.scss']
})
export class ParticipantRoleDialogComponent implements AfterViewInit, OnDestroy, OnInit {
  ParticipantActionType = ParticipantActionType;
  AddOrInviteActionType = AddOrInviteActionType;

  @ViewChild('buttonsToolbar', {static: true}) buttonsToolbar: TemplateRef<any>;
  @ViewChild('context', {static: true}) context: TemplateRef<any>;
  public buttonsToolbarPortal: Portal<any>;
  public contextPortal: Portal<any>;
  private dialogRef: MatDialogRef<SplitViewDialogComponent>;

  // For the autocomplete to exclude current participants.
  public excludedIds = [];
  public newParticipant = new UntypedFormControl(null);
  public currentParticipant: ProcessParticipant;
  public remainingParticipants: ProcessParticipant[] = [];
  public showEmail = false;

  private onDestroy = new Subject();

  private processParticipants$: Observable<ProcessParticipant[]>;
  private processId$ = new BehaviorSubject<string>(null);

  @Input() action: ParticipantActionType;
  @Input() showTitle = true;
  @Input() showSaveButtons = true;

  organization: Organization;

  _processId;

  _opened = false;
  @Input() set processId(id: string) {
    this._processId = id;
    this.processId$.next(id);
  }

  get processId(): string {
    return this._processId;
  }

  constructor(private _dialog: MatDialog,
              private router: Router,
              private _store: Store<AppState>,
              private _viewContainerRef: ViewContainerRef,
              private _fb: UntypedFormBuilder,
              private _tokenSvc: AngularTokenService,
              private _cdr: ChangeDetectorRef,
              private _participantSvc: ProcessParticipantService,
              private avatarService: AvatarService) {
  }

  ngOnInit() {
    this._store.select(OrganizationSelectors.getSelected)
      .pipe(filter(o => !!o), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy))
      .subscribe(org => {
        this.organization = org;
        this.showEmail = true;
        this._cdr.detectChanges();
      });

    this.processParticipants$ = this.processId$.pipe(takeUntil(this.onDestroy), switchMap(id => {
      return this._store.select(ProcessParticipantSelectors.getProcessParticipantsOfProcess(id));
    }))

    this.processParticipants$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(participants => {
        const remainingParticipants = [];
        const excludedIds = [];
        participants.forEach(p => {
          if (this._isCurrentUser(p.email)) {
            this.currentParticipant = p;
          } else {
            remainingParticipants.push(p);
          }
          excludedIds.push(p.email);
        });
        this.remainingParticipants = remainingParticipants.sort(AddressBookTableHelper.sortByFirstNameLastName);
        this.excludedIds = excludedIds;
        this._cdr.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this._initContextPortal();
    });
    // setTimeout(_ => this.openParticipantRoleDialog(), 100);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.processId$.complete();
  }

  public addParticipant($event) {
    if (!$event || !$event.email) return;

    const participant = new ProcessParticipant(null, this.processId, null, $event.email, null, null,
      null, null, $event.recursive)
    this._store.dispatch(new ProcessParticipantActions.Create(this.processId, participant,
      $event.admin, $event.recursive,
      'GENERAL.EDIT_PARTICIPANT_SUCCESSFULLY', 'GENERAL.EDIT_PARTICIPANT_FAIL'));
  }

  private _initContextPortal() {
    if (this.contextPortal) {
      return;
    }
    this.contextPortal = new TemplatePortal(
      this.context,
      this._viewContainerRef
    );
  }

  openParticipantRoleDialog() {
    if (this.processId) {
      this._refresh(this.processId);
    }
    this._initContextPortal();

    this.buttonsToolbarPortal = new TemplatePortal(
      this.buttonsToolbar,
      this._viewContainerRef
    );

    this.dialogRef = this._dialog.open(SplitViewDialogComponent, {
      data: {
        color: '#233246',
        icon: null,
        context: this.contextPortal,
        buttonsToolbar: this.buttonsToolbarPortal
      },
      autoFocus: false
    });
  }

  public inviteAndAddParticipant($event) {
    this._participantSvc.inviteAndAdd(this._processId, $event.email, $event.first_name, $event.last_name)
      .pipe(first())
      .subscribe((participant: ProcessParticipant) => {
        this._store.dispatch(new ProcessParticipantActions.CreateSuccess(participant));
      }, err => {
        console.error(err);
      });
  }

  public openModeratorConfirmationDialog($event: MatSlideToggleChange, participant: ProcessParticipant) {
    if (!participant || !participant.email) return;
    const message = $event.checked ? 'GENERAL.ADD_PARTICIPANT_Moderator' : 'GENERAL.REMOVE_PARTICIPANT_Moderator';

    this._dialog.open(ConfirmationDialogParticipantComponent, {
      data: {
        title: 'GENERAL.EDIT_PARTICIPANT',
        message: message,
        submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        person: this.avatarService.getProfile(participant.email),
        onSubmitAction: () => {
          this._store.dispatch(new ProcessParticipantActions.Create(this.processId, participant,
            $event.checked, participant.recursive,
            'GENERAL.EDIT_PARTICIPANT_SUCCESSFULLY', 'GENERAL.EDIT_PARTICIPANT_FAIL'));
        },
        onCancelAction: () => {
          this.remainingParticipants = [...this.remainingParticipants];
          this._refresh(this.processId);
        }
      }
    });
  }

  public applyToSubWorkflowsConfirmationDialog($event: MatSlideToggleChange, participant: ProcessParticipant) {
    if (!participant || !participant.email) return;
    const message = $event.checked ? 'GENERAL.ADD_PARTICIPANT_SUB_PROJECT_ROOMS' : 'GENERAL.REMOVE_PARTICIPANT_SUB_PROJECT_ROOMS';

    this._dialog.open(ConfirmationDialogParticipantComponent, {
      data: {
        title: 'GENERAL.EDIT_PARTICIPANT',
        message: message,
        submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        person: this.avatarService.getProfile(participant.email),
        onSubmitAction: () => {
          this._store.dispatch(new ProcessParticipantActions.Create(this.processId, participant,
            participant.isAdmin, $event.checked,
            'GENERAL.EDIT_PARTICIPANT_SUCCESSFULLY', 'GENERAL.EDIT_PARTICIPANT_FAIL'));
        },
        onCancelAction: () => {
          this.remainingParticipants = [...this.remainingParticipants];
          this._refresh(this.processId);
        }
      }
    });
  }

  public openRemoveParticipantConfirmDialog(participant: ProcessParticipant) {
    if (!participant || !participant.email) return;

    this._dialog.open(ConfirmationDialogParticipantComponent, {
      data: {
        title: 'GENERAL.REMOVE_PARTICIPANT',
        message: 'GENERAL.REMOVE_PARTICIPANT_PROJECT_ROOM',
        submitButtonTitle: 'GENERAL.REMOVE_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        color: '#db0000',
        remove: true,
        person: this.avatarService.getProfile(participant.email),
        onSubmitAction: () => {
          this._store.dispatch(new ProcessParticipantActions.Delete(participant));
        },
        onCancelAction: () => {
        }
      }
    });
  }

  private _refresh(id: string) {
    if (!id) return;
    if (!environment.production) {
      console.error('Participants Loaded by ParticipantRoleDialogComponent');
    }
    this._store.dispatch(new ProcessParticipantActions.LoadAllRefresh(id));
  }

  private _isCurrentUser(email) {
    return this._tokenSvc.currentAuthData.uid === email;
  }

  public goToPermissionView() {
    this.closeDialog();
    this.router.navigate([`/workflow/${this.processId}/permissions`]);
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
