<ng-container *ngIf="_artifact && keywordList?.length && processId">
  <ng-container *ngIf="sectionLayout">
    <hr>

    <h4 class="font-weight-bold">
      {{ 'GENERAL.KEYWORD_ASSIGNMENT' | translate }}
      <span *ngIf="_artifact?.keywords?.length">({{_artifact?.keywords?.length || ''}})</span>
    </h4>
  </ng-container>

  <div class="w-100 five-f-mat-input">
    <mat-form-field class="w-100">
      <mat-select [formControl]="formCtrl"
                  (selectionChange)="update($event.value)"
                  multiple>
        <div class="five-f-mat-input five-f-search">
          <mat-form-field class="mx-1 my-0 w-100" [floatLabel]="'never'">
            <input matInput (keyup)="search($event.target?.value)"
                   [placeholder]="'GENERAL.SEARCH' | translate" />
          </mat-form-field>
        </div>

        <mat-select-trigger>
          <ul *ngIf="formCtrl?.value?.length">
            <li *ngFor="let keywordId of formCtrl?.value">
              <ng-container *ngIf="keywordMap[keywordId] as keyword">
                <div class="list-item-container text-truncate">
                  <div class="text-truncate" [matTooltip]="keyword?.name">{{keyword?.name}}</div>
                  <div (click)="removeKeyword($event, keyword, formCtrl?.value)">
                    <mat-icon>close</mat-icon>
                  </div>
                </div>
              </ng-container>
            </li>
          </ul>
        </mat-select-trigger>
        <mat-option *ngFor="let keyword of filteredKeywordList$ | async" [value]="keyword.id">
          {{keyword.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>
