import { Component, OnInit } from '@angular/core';
import { AppState } from '../../../../reducers';
import { Store } from '@ngrx/store';
import { GetStatus } from '../../../../actions/foxdox-status.actions';
import { ITabNavRoute } from 'app/five-f/organization-card/models/tab-nav-route.interface';

@Component({
  selector: 'dvtx-foxdox-container-status',
  templateUrl: './foxdox-container-status.component.html',
  styleUrls: ['./foxdox-container-status.component.scss']
})
export class FoxdoxStatusContainerComponent implements OnInit {

  public foxdoxStatus$;
  public routes: ITabNavRoute[];
  public activeLink: string;
  constructor(private _store: Store<AppState>) { }

  ngOnInit() {
    this.routes = [{
      title: 'USER.DMS_STATUS',
      route: '/user-profile/dms/dms-settings'
    }];
    this.activeLink = this.routes[0].title
    this._store.dispatch(new GetStatus());
    this.foxdoxStatus$ = this._store.select('foxdoxStatus');
  }
}
