import {ContactImportItem} from './contact-import-item';
import {Appendix} from './appendix';
import {ProcessArtifact} from '../../../../+store/process-artifact/process-artifact';

export class AuditAuditorContributions {
  id: string | undefined;
  contactEntries: ContactImportItem[];
  artifacts: ProcessArtifact[];

  constructor() {
    this.contactEntries = [];
    this.artifacts = [];
  }
}

