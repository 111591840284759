import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Organization} from 'app/models/organization.model';
import {MatDialog} from '@angular/material/dialog';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {LicenceUpgradeDialogComponent} from 'app/shared/components/dialogs/licence-upgrade-dialog/licence-upgrade-dialog.component';
import {CreateOrganizationDialogContainerComponent} from 'app/modules/address-book/modules/address-book-table/containers/create-organization-dialog-container/create-organization-dialog-container.component';
import {Router} from '@angular/router';
import {OrganizationSelectors} from 'app/+store/organization';
import {ProcessProfile} from 'app/+store/process/process.interface';

/**
 * Project Workflow quickstarter.
 *
 * See quickstart bar for a usage example.
 * Advanced usage example:
 * <dvtx-project-quickstarter *ngIf="(featureSet$ | async)?.hasBookman"
 *                            [title]="'FIBU.FIBU_WORKFLOWS_PROJECT'"
 *                            [subTitle]="'FIBU.FIBU_WORKFLOWS_PROJECT_SUBTITLE'"
 *                            [link]="'/projects/new'"
 *                            [icon]="'fibu_workflow'"
 *                            [profile]="ProcessProfile.Fibu"></dvtx-project-quickstarter>
 */
@Component({
  selector: 'dvtx-project-quickstarter-from-template',
  templateUrl: './project-quickstarter-from-template.component.html',
  styleUrls: ['./project-quickstarter-from-template.component.scss']
})
export class ProjectQuickstarterFromTemplateComponent implements OnInit {
  organizations: Observable<Organization[]>;
  organizationsCount: number;
  ProcessProfile = ProcessProfile;

  @Input() title = 'PROJECT.NEW_PROJECT_FROM_TEMPLATE';
  @Input() subTitle = 'PROJECT.NEW_PROJECT_FROM_TEMPLATE_SUBTITLE';
  @Input() link = '/projects/new';
  @Input() profile: ProcessProfile = ProcessProfile.StandardProfile;
  @Input() icon = 'templates';

  constructor(
    public dialog: MatDialog,
    private _store: Store<AppState>,
    private _translateSvc: TranslateService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.organizations = this._store.select(
      OrganizationSelectors.getAllOrganizations
    );
    this.organizations.subscribe(organizations => {
      this.organizationsCount = organizations.length;
    });
  }

  redirectToLink(link) {
    if (this.organizationsCount === 0) {
      this.dialog.closeAll();
      this.dialog.open(LicenceUpgradeDialogComponent, {
        data: {
          title: 'REGISTRATION.ORGANIZATION_IS_NEEDED_TITLE',
          message: 'REGISTRATION.ORGANIZATION_IS_NEEDED_MESSAGE',
          onSubmitAction: () => {
            this.dialog.open(CreateOrganizationDialogContainerComponent);
          },
          submitButtonTitle: this._translateSvc.instant(
            'ORGANIZATION.CREATION.CREATE'
          )
        },
        width: '400px'
      });
    } else {
      this.router.navigate(link, {queryParams: {profile: this.profile, from_template: true}});
    }
  }
}
