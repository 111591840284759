import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {GrantThorntonApiResourceService} from './grant-thornton-api-resource.service';
import * as model from './grant-thornton';
import * as builders from './grant-thornton.builder';

/**
 * Service calls to the 5F Grant Thornton API and the GT 5F integration service
 * connecting Sharepoint (code name Grant Thornton Purplehub) and the 5F Grant Thornton PAPI.
 */
@Injectable()
export class GrantThorntonService {
  readonly GT_BASE_PATH = 'api/v3/customer/grant_thornton';

  constructor(private http: GrantThorntonApiResourceService) {
  }


  /**
   * Returns all connected channels of client.
   *
   * @param clientNo client ID (mostly DATEV)
   */
  getTeamsChannels(clientNo = '11_036_397'): Observable<model.GrantThornton.TeamsChannel[]> {
    const builder = new builders.GrantThornton.TeamsChannelBuilder();
    const clientId = btoa(clientNo);
    return <Observable<model.GrantThornton.TeamsChannel[]>>this.http.get<builders.GrantThornton.TeamsChannelBuilder, model.GrantThornton.TeamsChannel>(builder, `${this.GT_BASE_PATH}/clients/${clientId}/channels`);
  }

  getSharepointFoldersFromChannel(clientNo: string, channel: model.GrantThornton.TeamsChannel): Observable<model.GrantThornton.SharepointFolder[]> {
    const builder = new builders.GrantThornton.SharepointFolderBuilder();
    // The channel ID can contain prblematic characters, therefore we encode it.
    const channelId = btoa(channel.id);
    const clientId = btoa(clientNo);
    return <Observable<model.GrantThornton.SharepointFolder[]>>this.http.get<builders.GrantThornton.SharepointFolderBuilder, model.GrantThornton.SharepointFolder>(builder, `${this.GT_BASE_PATH}/clients/${clientId}/teams/${channel.team_id}/channels/${channelId}/folders`);
  }

  getChannelSyncSettings(processId: string): Observable<model.GrantThornton.ChannelSyncSettings> {
    const builder = new builders.GrantThornton.ChannelSyncSettingsBuilder();
    return <Observable<model.GrantThornton.ChannelSyncSettings>>this.http.get<builders.GrantThornton.ChannelSyncSettingsBuilder, model.GrantThornton.ChannelSyncSettings>(builder, `${this.GT_BASE_PATH}/processes/${processId}/sync_settings`);
  }

  updateChannelSyncSettings(processId: string, params: model.GrantThornton.ChannelSyncSettings): Observable<model.GrantThornton.ChannelSyncSettings> {
    const builder = new builders.GrantThornton.ChannelSyncSettingsBuilder();
    const payload = builder.toRequest(params);
    return <Observable<model.GrantThornton.ChannelSyncSettings>>this.http.put<builders.GrantThornton.ChannelSyncSettingsBuilder, model.GrantThornton.ChannelSyncSettings>(builder, `${this.GT_BASE_PATH}/processes/${processId}/sync_settings`, payload);
  }
}
