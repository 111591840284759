import {createEntityAdapter, EntityState} from '@ngrx/entity';
import * as model from './organization';
import * as dayjs from 'dayjs';

export interface State extends EntityState<model.Operator.Organization> {
  selected?: model.Operator.Organization;
  loading: boolean;
}

export const adapter = createEntityAdapter<model.Operator.Organization>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
