import {Injectable} from '@angular/core';
import {catchError, first, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {CppApiService} from '../../services/cpp-api.service';
import {Store} from '@ngrx/store';
import {
  ContactPersonActionTypes,
  EditContact,
  EditContactSuccess,
  EditEmail,
  EditFirstName,
  EditLastName,
  EditPhoneNumber,
  LoadAssignedOrganizations,
  LoadAssignedOrganizationsSuccess,
  LoadOne,
  LoadOneSuccess
} from './contact-person.actions';
import {ContactPersonResponse, createContactPersonfromResponse} from '../../models/contact-person.model';
import {OrganizationSelectors} from '../organization'
import {Organization} from '../../models/organization.model';
import {LoadAllSuccess, LoadOne as LoadOneContact} from '../contact/contact.actions';
import * as NotificationActions from '../notification/notification.actions';
import {ConstactListDtoResponseElement, ContactListDtoReponse} from '../../models/contact-list-dto.model';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ContactPersonEffects {
  @Effect()
  loadOne = this.actions.pipe(
    ofType(ContactPersonActionTypes.LoadOne),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected).pipe(map(x => x))),
    switchMap(([action, orga]: [LoadOne, Organization]) => {
        return this.cppApiService.get<ContactPersonResponse>('organization/' + orga.id + '/addressbook/' + orga.addressbookId + '/personcontacts/' + action.contactId).pipe(
          map((x: ContactPersonResponse) => {
            const loadedContact = createContactPersonfromResponse(x);
            return new LoadOneSuccess(loadedContact);
          }),
          catchError((err) => {
            return of(new NotificationActions.ShowHttpError(err))
          })
        )
      }
    )
  );

  @Effect()
  editContact = this.actions.pipe(
    ofType(ContactPersonActionTypes.EditContact),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected).pipe(map(x => x))),
    switchMap(([action, orga]: [EditContact, Organization]) => {
      const body = {
        natural_person_profile: {
          first_name: action.contactToEdit.firstName,
          last_name: action.contactToEdit.lastName,
          honorific: action.contactToEdit.honorific,
          title_id: String(action.contactToEdit.title),
        },
        email: action.contactToEdit.mainEmailAddress.toCppRequest(),
          address: action.contactToEdit.mainAddress.toCppRequest(),
          phone: action.contactToEdit.mainPhoneNumber.toCppRequest(),
          contact_visibility: action.contactToEdit.contactVisibility.type,
          visible_for_id: action.contactToEdit.contactVisibility.visibleFor
        };

        return this.cppApiService.put<ContactPersonResponse>('organization/' + orga.id + '/addressbook/' + orga.addressbookId + '/personcontacts/' + action.contactToEdit.id, body).pipe(
          switchMap((res: ContactPersonResponse) => {
            const loadedContact = createContactPersonfromResponse(res);
            const timestamp = new Date().toDateString();
            this._notifyService.success('CONTACTS.CONTACT_UPDATED')
            return [
              new EditContactSuccess(loadedContact),
              new LoadAllSuccess([
                {
                  id: loadedContact.id,
                  key: `${loadedContact.id}|${timestamp}`,
                  email: loadedContact.mainEmailAddress.emailAddress,
                  telephone: loadedContact.mainPhoneNumber ? loadedContact.mainPhoneNumber.countryCode + loadedContact.mainPhoneNumber.phoneNumber : '',
                  name: loadedContact.lastName + ', ' + loadedContact.firstName,
                  firstName: loadedContact.firstName,
                  lastName: loadedContact.lastName
                }
              ])
            ];
          }),
          catchError((err) => {
            return of(new NotificationActions.ShowHttpError(err))
          })
        )
      }
    )
  );

  /**
   * @deprecated This should not be used, we have the `contact-person-organization-association` for that.
   * If this endpoint makes sense, the reducer needs to be changed to fill the state in the association store slice.
   */
  @Effect()
  loadAssignedOrganizations = this.actions.pipe(
    ofType(ContactPersonActionTypes.LoadAssignedOrganizations),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected).pipe(map(x => x))),
    mergeMap(([action, orga]: [LoadAssignedOrganizations, Organization]) => {
      return this.cppApiService.get<ContactListDtoReponse>('organization/' + orga.id + '/addressbook/' + orga.addressbookId + '/personcontacts/' + action.contactId + '/organizations').pipe(
        map((res: ContactListDtoReponse) => {
          const dtos: string[] = res.data.map((item: ConstactListDtoResponseElement) => {

            return item.attributes.name
          });

          return new LoadAssignedOrganizationsSuccess({id: action.contactId, assigns: dtos})
        }),
        catchError((err) => {
          return of(new NotificationActions.ShowHttpError(err))
        })
      )
      }
    )
  );
  @Effect()
  editFirstName = this.actions.pipe(
    ofType(ContactPersonActionTypes.EditFirstName),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [EditFirstName, Organization]) => {
      const payload = {
        'first_name' : action.firstName
      }
      return this.cppApiService.put<ContactPersonResponse>(`organization/${org.id}/addressbook/${org.addressbookId}/personcontacts/${action.contactId}/first_name`, payload).pipe(
        map((x: ContactPersonResponse) => {
          const loadedContact = createContactPersonfromResponse(x);
            this._notifyService.success('CONTACTS.EDIT_FIRST_NAME');
          return new LoadOneSuccess(loadedContact);
        }),
        catchError((err) => {
            this._notifyService.error('CONTACTS.EDIT_FIRST_NAME_FAIL');
          return of(new NotificationActions.ShowHttpError(err))
        })
        )
    })
  );
  @Effect()
  editLastName = this.actions.pipe(
    ofType(ContactPersonActionTypes.EditLastName),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [EditLastName, Organization]) => {
      const payload = {
        'last_name' : action.lastName
      }
      return this.cppApiService.put<any>(`organization/${org.id}/addressbook/${org.addressbookId}/personcontacts/${action.contactId}/last_name`, payload).pipe(
        map((x: ContactPersonResponse) => {
          const loadedContact = createContactPersonfromResponse(x);
            this._notifyService.success('CONTACTS.EDIT_LAST_NAME')
          return new LoadOneSuccess(loadedContact);
        }),
        catchError((err) => {
            this._notifyService.error('CONTACTS.EDIT_LAST_NAME_FAIL')
          return of(new NotificationActions.ShowHttpError(err))
        })
        )
    })
  );
  @Effect()
  editEmail = this.actions.pipe(
    ofType(ContactPersonActionTypes.EditEmail),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [EditEmail, Organization]) => {
      const payload = {
        'email' : action.email
      }
      return this.cppApiService.put<any>(`organization/${org.id}/addressbook/${org.addressbookId}/personcontacts/${action.contactId}/email`, payload).pipe(
        map((x: ContactPersonResponse) => {
          const loadedContact = createContactPersonfromResponse(x);
            this._notifyService.success('CONTACTS.EDIT_EMAIL')
          return new LoadOneSuccess(loadedContact);
        }),
        catchError((err) => {
            this._notifyService.error('CONTACTS.EDIT_EMAIL_FAIL')
          return of(new NotificationActions.ShowHttpError(err))
        })
        )
    })
  );
  @Effect()
  editPhoneNumber = this.actions.pipe(
    ofType(ContactPersonActionTypes.EditPhoneNumber),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [EditPhoneNumber, Organization]) => {
      const payload = {
        'phone_number' : action.phoneNumber
      }
      return this.cppApiService.put<any>(`organization/${org.id}/addressbook/${org.addressbookId}/personcontacts/${action.contactId}/phone_number`, payload).pipe(
        switchMap((x: ContactPersonResponse) => {
          const loadedContact = createContactPersonfromResponse(x);
          if (action.phoneNumber === '') {
              this._notifyService.success('CONTACTS.REMOVE_PHONE')
          } else {
            this._notifyService.success('CONTACTS.EDIT_PHONE')
          }
          return [new LoadOneSuccess(loadedContact), new LoadOneContact(action.contactId)];
        }),
        catchError((err) => {
          if (action.phoneNumber === '') {
              this._notifyService.error('CONTACTS.REMOVE_PHONE_FAIL')
          } else {
              this._notifyService.error('CONTACTS.EDIT_PHONE_FAIL')
          }
          return of(new NotificationActions.ShowHttpError(err))
        })
        )
    })
  );

  constructor(private actions: Actions,
              private cppApiService: CppApiService,
              private _notifyService: NotificationService,
              private _translateSvc: TranslateService,
              private store: Store<any>
  ) {
  }
}
