<ng-container *ngIf="isButton; else link">
  <button
    mat-icon-button
    cdk-overlay-origin
    (click)="showDropdownDialog()"
    #dropdown="cdkOverlayOrigin"
    [disabled]="disabled"
    [disableRipple]='true'>
    <ng-container [ngSwitch]="selected">
      <ng-container *ngSwitchCase="null">
        <span class="button--content">
          {{'GENERAL.PRIORITY' | translate}}
        </span>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngIf="multipeSelection">
          <ng-container *ngIf="!selected || !selected.length">
            {{'GENERAL.PRIORITY' | translate}}
          </ng-container>
          <ng-container *ngFor="let selectedItem of selected">
            <dvtx-tooltip [tooltipText]="selected.length < 3 ? '' : (selectedItem.title | translate)" [position]="'top left'">
              <span class="button--content" [style.border-color]="selectedItem.color" [style.color]="selectedItem.color">
                <mat-icon>{{selectedItem.icon}}</mat-icon>
                <ng-container *ngIf="selected.length < 3">{{selectedItem.title | translate}}</ng-container>
              </span>
            </dvtx-tooltip>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!multipeSelection">
          <span class="button--content" [style.border-color]="selected.color" [style.color]="selected.color">
            <mat-icon>{{selected.icon}}</mat-icon>
            {{selected.title | translate}}
          </span>
        </ng-container>
      </ng-container>
    </ng-container>
  </button>
  <ng-template cdk-connected-overlay
               [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
               [cdkConnectedOverlayOrigin]="dropdown"
               [cdkConnectedOverlayHasBackdrop]="true"
               [cdkConnectedOverlayOpen]="showDropdown && !disabled"
               (backdropClick)="showDropdown = false">
    <div class="mat-elevation-z8 list--container p-1">
      <ul class="m-0">
        <li class="priority--block p-1" *ngFor="let priority of priorities" (click)="selectPriority(priority)">
          <span [style.border-color]="priority.color" [style.color]="priority.color" [class.active]="isPrioritySelected(priority)">
            <mat-icon>{{priority.icon}}</mat-icon>
            {{priority.title | translate}}
          </span>
        </li>
      </ul>
    </div>
  </ng-template>
</ng-container>

<ng-template #link>
  <span
    class="link--content"
    cdk-overlay-origin
    (click)="showDropdownDialog()"
    #dropdown="cdkOverlayOrigin">
    <ng-container [ngSwitch]="selected">
      <ng-container *ngSwitchCase="null">
        <span>
          {{'GENERAL.PRIORITY' | translate}}
        </span>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <span class="d-flex align-items-center" [style.border-color]="selected.color" [style.color]="selected.color">
          <mat-icon>{{selected.icon}}</mat-icon>
          {{selected.title | translate}}
        </span>
      </ng-container>
    </ng-container>
  </span>
  <ng-template cdk-connected-overlay
               [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
               [cdkConnectedOverlayOrigin]="dropdown"
               [cdkConnectedOverlayHasBackdrop]="true"
               [cdkConnectedOverlayOpen]="showDropdown && !disabled"
               (backdropClick)="showDropdown = false">
    <div class="mat-elevation-z8 list--container p-1">
      <ul class="m-0">
        <li class="priority--block p-1" *ngFor="let priority of priorities" (click)="selectPriority(priority)">
          <span [style.border-color]="priority.color" [style.color]="priority.color" [class.active]="priority.id === selected?.id">
            <mat-icon>{{priority.icon}}</mat-icon>
            {{priority.title | translate}}
          </span>
        </li>
      </ul>
    </div>
  </ng-template>
</ng-template>
