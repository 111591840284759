import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {DatevClient} from './datev-client';

export interface State extends EntityState<DatevClient> {
  selected?: DatevClient;
  loading: boolean;
  syncing: boolean;
}

export const adapter = createEntityAdapter<DatevClient>({
  sortComparer: (l, r) => l.name.localeCompare(r.name)
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
  syncing: false,
});
