import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {User} from 'app/models/user';
import {Subject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AutoLogoutService} from 'app/services/auto-logout.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {GetCurrentUser} from 'app/actions/current-user-actions';

@Component({
  selector: 'dvtx-auto-logout-timer',
  templateUrl: './auto-logout-timer.component.html',
  styleUrls: ['./auto-logout-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoLogoutTimerComponent implements OnInit, OnDestroy {
  onDestroy = new Subject();

  _user: User;
  @Input() set user(u: User) {
    this._user = u;
    if (u && u.autoLogout) {
      this.form.patchValue({ autoLogout: u.autoLogout });
      this.form.markAsPristine();
    }
  }

  form: UntypedFormGroup;
  submitOngoing = false;
  currentUser$: Observable<User>;

  constructor( private _store: Store<AppState>,
               private _fb: UntypedFormBuilder,
              private _autoLogoutSvc: AutoLogoutService) {
    this.form = this._fb.group({ autoLogout: [30, Validators.required] });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  saveAutoLogoutTimer() {
    if (this.form.invalid) {
      return;
    }
    this._autoLogoutSvc.update(this.form.value.autoLogout).pipe(first()).subscribe(user => {
      console.error(user);
      this._store.dispatch(new GetCurrentUser());
    });
  }
}
