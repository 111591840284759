import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './audit-trail.state';
import {ProcessSelectors} from '../process';
import {ProcessEvent} from '../process-event/process-event';

export const stateKey = 'audit-trail';
const getAuditTrailState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getAuditTrailEntities,
  selectAll: getAllAuditTrails,
} = adapter.getSelectors(getAuditTrailState);

export const loadingState = createSelector(
  getAuditTrailState,
  (state) => state.loading
);

export const getAuditTrailEventsOfSelectedProcess = createSelector(
  getAllAuditTrails,
  ProcessSelectors.getSelectedProcess,
  (events, process) => {
    if (process) {
      return events.filter(r => r.processId === process.id || r.parentId === process.id)
    }
    return [];
  });
