<div class="example-container workflow--table--container">
  <div class="overlay-loading-shade" *ngIf="loading || _loading">
    <mat-spinner [diameter]="'20'"></mat-spinner>
  </div>

  <ng-container *ngIf="!loading && !_loading && filteredData">
    <div class="row mx-0 d-flex justify-content-end">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <mat-form-field class="w-100">
          <input type="text" placeholder="Filter" aria-label="suchen"
                 (keyup)="applyFilter($event.target.value)" matInput>
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <mat-table #table multiTemplateDataRows matSort [dataSource]="dataSource">

      <!-- Id Column -->
      <!-- <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef (click)="sortBy('id')"  mat-sort-header > Id</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
      </ng-container> -->

      <!-- Workflow Column -->
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef (click)="sortBy('title')"  mat-sort-header>
          {{ 'GENERAL.TITLE' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" > {{element.title ? element.title : ('GENERAL.NO_TITLE' | translate)}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ident">
        <mat-header-cell *matHeaderCellDef (click)="sortBy('id')"  mat-sort-header style="max-width: 150px;">
          {{ 'GENERAL.IDENT' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" style="max-width: 150px;"> {{element?.identifier }} </mat-cell>
      </ng-container>

      <!-- letztes update Column -->
      <ng-container matColumnDef="letztes_update">
        <mat-header-cell *matHeaderCellDef (click)="sortBy('letztes_update')" mat-sort-header>
          {{ 'GENERAL.LAST_UPDATED' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.updatedAt | date: 'medium' }} </mat-cell>
      </ng-container>

      <!-- Type Column  no filter for this column bc it needs mapping from the backend-->
      <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef >Workflow</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element?.humanReadableProcessType | translate }}</mat-cell>
        </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef (click)="sortBy('Status')" mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <p [class]="'status-color-'+element.status.infoLevel"> {{element.status.message}} <i class="fa {{element.status.icon}} "></i>  </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" class="mat-cell-arrow">
        <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
          <div class="d-flex justify-content-end pr-2">
            <button mat-icon-button type="button" (click)="click(element)">
              <mat-icon class="projectroom-icon" svgIcon="projectroom"></mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>


      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="expanded--table--element--detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <ng-template [ngIf]="element == expandedElement">
              <hr class="my-1"/>
              <div class="row">
                <div class="col-12 col-md-8">
                  <p [innerHtml]="element.description"></p>
                </div>
                <div class="col-12 col-md-4">
                  <table class="process--details--table">
                    <tr>
                      <td style="text-align: right; padding-right: 15px">{{ 'GENERAL.AUTHOR' | translate }}</td>
                      <td><strong>{{ element?.creatorName }}</strong></td>
                    </tr>
                    <tr>
                      <td style="text-align: right; padding-right: 15px"></td>
                      <td>{{ element?.creatorEmail }}</td>
                    </tr>
                    <tr>
                      <td style="text-align: right; padding-right: 15px">{{ 'CONTACTS.ORG_NAME' | translate }}</td>
                      <td><strong>{{ element?.orgName }}</strong></td>
                    </tr>
                    <tr>
                      <td style="text-align: right; padding-right: 15px">{{ 'GENERAL.CREATED_AT' | translate }}</td>
                      <td><strong>{{ element?.createdAt | date }}</strong></td>
                    </tr>
                    <tr>
                      <td style="text-align: right; padding-right: 15px">{{ 'GENERAL.LAST_UPDATED' | translate }}</td>
                      <td><strong>{{ element?.updatedAt | date }}</strong></td>
                    </tr>
                    <!--<tr>-->
                      <!--<td style="text-align: right; padding-right: 15px">{{ 'PROJECT_ROOM.MEMBER' | translate }}</td>-->
                      <!--<td class="d-flex align-items-center">-->
                        <!--&lt;!&ndash;<dvtx-avatars-group mat-list-icon class="ml-1" size="sm" listStyle="inline" maxToShow="1" [profiles]="element.participants"></dvtx-avatars-group>&ndash;&gt;-->
                        <!--&lt;!&ndash; <dvtx-participant-role-dialog [processId]="element.id" [action]="ParticipantActionType.ParticipantRole" class="ml-2"></dvtx-participant-role-dialog> &ndash;&gt;-->
                      <!--</td>-->
                    <!--</tr>-->
                  </table>
                </div>
              </div>
            </ng-template>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row class="dvtx-bg-color dvtx-bg-cool-grey-25" *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns;"
          class="expanded--table--element--row"
          [style.border-left-color]="element?.color"
          [style.border-left-style]="element?.color? 'solid' : 'none'"
          [style.border-left-width]="element?.color? '10px' : '0px'"
          [class.expanded]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element; getProcessDetails($event, element)">
      </mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail']"
      [style.border-left-color]="row?.color"
      [style.border-left-style]="row?.color? 'solid' : 'none'"
      [style.border-left-width]="row?.color? '10px' : '0px'"
      class="expanded--table--detail--row"></mat-row>
    </mat-table>
  </ng-container>
</div>
