
import {combineLatest as observableCombineLatest,  Observable ,  Subject ,  BehaviorSubject } from 'rxjs';
import { Component, Directive, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { QuickshareRecipient } from 'app/+store/quickshare-recipient/quickshare-recipient';
import { AppState } from 'app/reducers';
import { Store } from '@ngrx/store';
import { ContactSelectors, QuickshareRecipientActions } from 'app/+store';
import { Process } from 'app/+store/process/process';
import { contactListDtoType } from 'app/models/contact-list-dto.model';
import { AbstractControl, NG_VALIDATORS, NgModel, Validator, ValidatorFn } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { SimplePhoneNumber } from 'app/modules/contacts/models/contact.interface';
import { AvatarService } from 'app/shared/modules/user-account/components/avatar/avatar.service';
import { StringUtils } from 'app/lib/string_utils';
import { RecipientAutocompleteComponent } from '../../components/recipient-autocomplete/recipient-autocomplete.component';

export function validPhoneNumber(phoneNumber: string) {
  const regex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, 'i');
  return !!phoneNumber && !!regex.test(phoneNumber);
}

export function phoneNumberRequiredValidator(recipient: QuickshareRecipient): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const regex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, 'i');
    const match = regex.test(control.value);
    const ok = !recipient.notifyBySms || control.value && control.value.length > 6 && match;
    return ok ? null : { 'phoneNumberRequired': { value: control.value } };
  };
}

@Directive({
  selector: '[dvtxPhoneNumberRequired]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ForbiddenValidatorDirective, multi: true }]
})
export class ForbiddenValidatorDirective implements Validator {
  @Input('dvtxPhoneNumberRequired') phoneNumberRequired: QuickshareRecipient;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.phoneNumberRequired ? phoneNumberRequiredValidator(this.phoneNumberRequired)(control) : null;
  }
}

@Component({
  selector: 'dvtx-recipient-selection',
  templateUrl: './recipient-selection.component.html',
  styleUrls: ['./recipient-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecipientSelectionComponent implements OnInit, OnDestroy {
  protected onDestroy = new Subject();

  @ViewChild('recipientAutocompleteComponent') public recipientAutocompleteComponent: RecipientAutocompleteComponent;

  removable = true;
  recipient: any;
  id: string;
  contacts$ = new BehaviorSubject([]);
  recipients$ = new BehaviorSubject<QuickshareRecipient[]>([]);
  quickshareRecipients$;

  @Input() passwordEnabled = false;
  @Input() notifyBySms = false;
  @Input() notifyBySmsOnly = false;

  @Output() onSelectSettings = new EventEmitter<boolean>();
  @Output() onSMSOptionClick: EventEmitter<any> = new EventEmitter();

  @Input() set process(p: Process) {
    if (p) {
      this.id = p.id;
      this._cdr.detectChanges();
    }
  }
  @Input() set recipients(recipients: QuickshareRecipient[]) {
    this.recipients$.next(recipients);
    this._cdr.detectChanges();
  }

  constructor(private _store: Store<AppState>, private avatarService: AvatarService, private _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this._store.select(ContactSelectors.getMembersAndContactPersonsOfSelectedOrg()).pipe(
      takeUntil(this.onDestroy)
    ).subscribe(contacts => this.contacts$.next(contacts));

    this.quickshareRecipients$ = observableCombineLatest(this.contacts$, this.recipients$).pipe(
      map(([contacts, recipients]) => {
        return recipients.map(recipient => {
          const found = contacts.find(contact => StringUtils.simpleCompare(contact.email, recipient.email));
          if (found) {
            recipient.isMember = found.type === contactListDtoType.Membership;
            recipient.isVerified = found.hasAccount;
            const [lastName, firstName] = found.name.split(', ');
            recipient.firstName = firstName;
            recipient.lastName = lastName;
          }
          if (recipient.phoneNumber) {
            recipient.simplePhoneNumber = new SimplePhoneNumber();
            recipient.simplePhoneNumber.phoneNumber = recipient.phoneNumber;
          }
          return recipient;
        })
      })
    );
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  add(value): void {
    if (value) {
      const recipient = new QuickshareRecipient(null, null, value, this.id, this.notifyBySms, null, this.passwordEnabled);
      this._store.dispatch(new QuickshareRecipientActions.Create(this.id, recipient));
      this.recipient = null;
    }
  }

  getContact(email) {
    return this.contacts$.value.find(contact => {
      return contact.email === email;
    })
  }

  remove(recipient: QuickshareRecipient): void {
    this._store.dispatch(new QuickshareRecipientActions.Remove(this.id, recipient.id));
  }

  updateNotifyBySms($event, recipient: QuickshareRecipient, phoneNumber: NgModel = null) {
    this.onSMSOptionClick.emit();
    this.update(recipient, phoneNumber);
  }

  update(recipient: QuickshareRecipient, phoneNumber: NgModel = null) {
    recipient.phoneNumber = recipient.simplePhoneNumber && recipient.simplePhoneNumber['number'] && recipient.simplePhoneNumber.isValid ? recipient.simplePhoneNumber['number'] : null;
    recipient.notifyBySms = validPhoneNumber(recipient.phoneNumber);

    if (phoneNumber && phoneNumber.control) {
      if (recipient.notifyBySms) {
        phoneNumber.control.markAsTouched();
        phoneNumber.control.updateValueAndValidity();
      }
    }

    const match = validPhoneNumber(recipient.phoneNumber);
    if (recipient.notifyBySms && (!recipient.phoneNumber || !match || recipient.phoneNumber.length <= 6)) {
      return;
    }
    this._store.dispatch(new QuickshareRecipientActions.Save(this.id, recipient));
  }

}
