import {Action} from '@ngrx/store';
import {VerifiedUser} from './verified-user';

export enum VerifiedUserActionTypes {
  LoadAll = '[VerifiedUser] Load All',
  LoadAllSuccess = '[VerifiedUser] Load All Success',
  LoadAllFail = '[VerifiedUser] Load All Fail',
  LoadCurrent = '[VerifiedUser] Load Current',
  LoadCurrentSuccess = '[VerifiedUser] Load Current Success',
  LoadCurrentFail = '[VerifiedUser] Load Current Fail',
  Remove = '[VerifiedUser] Remove Logo',
  RemoveSuccess = '[VerifiedUser] Remove Logo Success',
  RemoveFail = '[VerifiedUser] Remove Logo Fail'
}

export class LoadAll implements Action {
  readonly type = VerifiedUserActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = VerifiedUserActionTypes.LoadAllSuccess;

  constructor(public payload: VerifiedUser[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = VerifiedUserActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export type VerifiedUserActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail;
