import {AbstractControl, UntypedFormControl, ValidatorFn} from "@angular/forms";

export namespace StringValidator {
  export function noWhitespaceValidator(control: UntypedFormControl | AbstractControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  export function notExistedIn(value: string, list: string[]) {
    if (!value || !list || list.length === 0) return null;
    const isIn = list.find(item => item && item.trim().toLowerCase() === value.trim().toLowerCase());
    const isValid = !isIn;
    return isValid ? null : { 'existed': true };
  }

  export function notInList(list: string[]): ValidatorFn {
    return (control: UntypedFormControl): {[key: string]: boolean} | null => {
      if (!control.value) return null;
      const isIn = list.find(item => item.trim().toLowerCase() === control.value.trim().toLowerCase());
      const isValid = !isIn;
      return isValid ? null : { 'existed': true };
    };
  }
}
