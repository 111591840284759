import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';

import {
  DvtxControlValueAccessor
} from 'app/shared/modules/base-form-elements/components/1_control-value-accessor-components/DvtxControlValueAccessor';

import { ContactPerson } from 'app/models/contact-person.model';

import {
  SimpleAddress,
  SimplePhoneNumber,
  SimpleEmailAddress,
  SimpleEmailAddressRequiredValidator
} from '../../../contacts/models/contact.interface';

import {
  newContactBaseForm,
  ContactPersonBaseForm
} from '../../../organization/models/person-contact-base-form';

import {
  ContactActions,
  NaturalPersonActions
} from 'app/+store';

import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import {
  take,
  takeUntil,
  first
} from 'rxjs/operators';
import { Subject } from 'rxjs';


/**
 * This component is used for creating new contacts as well as editing existing contacts.
 * The component name is misleading.
 */
@Component({
  selector: 'dvtx-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserProfileFormComponent),
      multi: true,
    }
  ]
})

export class UserProfileFormComponent extends DvtxControlValueAccessor implements OnDestroy, OnChanges {


  private onDestroy = new Subject();
  @Input() title: string = 'CONTACTS.NEW_TITLE';
  @Input() isDisabled: boolean = true;

  displayFurtherDataFields: boolean = false;
  myContactDetails: ContactPerson;
  userProfileForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>) {
    super();
    this.userProfileForm = fb.group({
      firstName: [undefined, Validators.required],
      lastName: [undefined, Validators.required],
      title: [undefined],
      honorific: [undefined],
      mainAddress: [new SimpleAddress()],
      mainPhoneNumber: [new SimplePhoneNumber()],
      mainEmailAddress: [new SimpleEmailAddress(), SimpleEmailAddressRequiredValidator()],
    });

    this.userProfileForm.valueChanges.pipe(
      takeUntil(this.onDestroy)
    ).subscribe(((value: ContactPerson) => {
      const ret = newContactBaseForm();
      this.notifyOnChange({
        ...ret,
        contactPerson: {
          firstName: value.firstName,
          lastName: value.lastName,
          title: value.title,
          honorific: value.honorific,
          mainAddress: value.mainAddress,
          mainPhoneNumber: value.mainPhoneNumber,
          mainEmailAddress: value.mainEmailAddress,
          valid: this.userProfileForm.valid
        },
        isValid: this.userProfileForm.valid
      });

    }))

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDisabled']) {
      this.isDisabled = changes.isDisabled.currentValue;
      if (this.isDisabled) {
        this.userProfileForm.disable();
      } else {
        this.userProfileForm.enable();
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  writeValue(obj: ContactPersonBaseForm): void {
    this.myContactDetails = obj ? obj.contactPerson : null;
    if (this.myContactDetails) {
      this.userProfileForm.patchValue({
        firstName: obj.contactPerson.firstName,
        lastName: obj.contactPerson.lastName,
        title: obj.contactPerson.title,
        honorific: obj.contactPerson.honorific,
        mainAddress: obj.contactPerson.mainAddress || new SimpleAddress(),
        mainPhoneNumber: obj.contactPerson.mainPhoneNumber || new SimplePhoneNumber(),
        mainEmailAddress: obj.contactPerson.mainEmailAddress || new SimpleEmailAddress(),
        valid: this.userProfileForm.valid
      });
    }
  }

  addPhoneNumber(phoneNumber: SimplePhoneNumber) {
    this.store.dispatch(new ContactActions.AddFurtherPhoneNumber({
      contactId: this.myContactDetails.id,
      phoneNumber
    }))
  }

  removePhoneNumber(phoneNumberId: string) {
    this.store.dispatch(new ContactActions.RemoveFurtherPhoneNumber({
      contactId: this.myContactDetails.id,
      phoneNumberId
    }))
  }

  addEmail(emailAddress: SimpleEmailAddress) {
    this.store.dispatch(new ContactActions.AddFurtherEmail({
      contactId: this.myContactDetails.id,
      emailAddress,
    }))
  }

  removeEmail(emailAddressId: string) {
    this.store.dispatch(new ContactActions.RemoveFurtherEmail({
      contactId: this.myContactDetails.id,
      emailAddressId
    }))
  }

  addAddress(address: SimpleAddress) {
    this.store.dispatch(new ContactActions.AddFurtherAddress({
      contactId: this.myContactDetails.id,
      address,
    }))
  }

  removeAddress(addressId: string) {
    this.store.dispatch(new ContactActions.RemoveFurtherAddress({
      contactId: this.myContactDetails.id,
      addressId
    }))
  }

  addFurtherPhoneNumber(phoneNumber: SimplePhoneNumber) {
    this.store.dispatch(new NaturalPersonActions.AddFurtherPhoneNumber({
      personId: this.myContactDetails.id,
      phoneNumber,
    }))
  }

  removeFurtherPhoneNumber(phoneNumberId: string) {
    this.store.dispatch(new NaturalPersonActions.RemoveFurtherPhoneNumber({
      personId: this.myContactDetails.id,
      phoneNumberId
    }))
  }

  addFurtherEmail(emailAddress: SimpleEmailAddress) {
    this.store.dispatch(new NaturalPersonActions.AddFurtherEmail({
      personId: this.myContactDetails.id,
      emailAddress,
    }))
  }

  removeFurtherEmail(emailAddressId: string) {
    this.store.dispatch(new NaturalPersonActions.RemoveFurtherEmail({
      personId: this.myContactDetails.id,
      emailAddressId
    }))
  }

  addFurtherAddress(address: SimpleAddress) {
    this.store.dispatch(new NaturalPersonActions.AddFurtherAddress({
      personId: this.myContactDetails.id,
      address,
    }))
  }

  removeFurtherAddress(addressId: string) {
    this.store.dispatch(new NaturalPersonActions.RemoveFurtherAddress({
      personId: this.myContactDetails.id,
      addressId
    }))
  }

}
