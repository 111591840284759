import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {UserAvatarEffects} from './user-avatar.effects';
import {UserAvatarService} from './user-avatar.service';
import {reducer} from './user-avatar.reducer';
import {stateKey} from './user-avatar.selectors';
import {UpmsApiResourceService} from './upms-api-resource.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([UserAvatarEffects])
  ],
  providers: [
    UserAvatarEffects,
    UpmsApiResourceService,
    UserAvatarService
  ]
})
export class UserAvatarStoreModule {
}
