import {NgModule} from '@angular/core';
import {TwoFactorVerificationComponent} from './containers/two-factor-verification/two-factor-verification.component';
import {SharedModule} from 'app/shared/shared.module';
import {OnlyNumberDirective} from './directives/only-number.directive';
import { TwoFactorVerificationDialogComponent } from './containers/two-factor-verification-dialog/two-factor-verification-dialog.component';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        TwoFactorVerificationComponent,
        OnlyNumberDirective,
        TwoFactorVerificationDialogComponent
    ],
    exports: [
        SharedModule,
        OnlyNumberDirective,
        TwoFactorVerificationComponent,
        TwoFactorVerificationDialogComponent
    ]
})
export class TwoFactorVerificationModule {
}
