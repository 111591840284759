<div class="container-fluid p-2">
  <div class="d-flex justify-content-end p-2">
    <button mat-button (click)="downloadSample()">
      <mat-icon>file_download</mat-icon>
      <span>{{'GENERAL.STANDARD_TEMPLATE' | translate}}</span>
    </button>

    <button mat-raised-button color="primary"
            (click)="openUploadDialog()"
            class="ml-2">
      <mat-icon matPrefix aria-label="Upload template">file_upload</mat-icon>
      {{'CUSTOMIZATION.UPLOAD_TEMPLATE' | translate}}
    </button>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="d-flex">
        <div class="d-flex align-items-center">
          <mat-icon>info</mat-icon>
        </div>
        <p class="ml-2">
          (Sys-) Admins können hier Dokumentvorlagen hochladen und veröffentlichen.
          <br/>
          Hinterlegte Vorlagen werden als Master für generierte Dokumente in Workflows verwendet.
          <br/>
          Du kannst die Vorlage über das Play-Symbol (<span style="vertical-align: -webkit-baseline-middle"><mat-icon>play_circle_outline</mat-icon></span>)
          testen, bevor Du sie für die Verwendung in Workflows veröffentlichst.
        </p>
        <!--truncate filename + tooltip-->
      </div>
    </div>
  </div>

  <div class="row" *ngIf="dataSource?.data?.length">
    <div class="col-12">
      <div class="d-flex">
        <h3>
          {{ 'CUSTOMIZATION.AVAILABLE_TEMPLATES' | translate }}
        </h3>
      </div>

      <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.TITLE' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let template">
            {{ template?.displayName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="filename">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.FILENAME' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let template">
            {{ template.fileName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="size">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.SIZE' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let template">
            {{ fileSize(template) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="published">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.PUBLISHED' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let template">
            <mat-slide-toggle color="primary" [checked]="template?.published" (change)="publish(template, $event)">
              {{ 'GENERAL.PUBLISHED' | translate }}
            </mat-slide-toggle>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.ACTION_TITLE' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let template">
            <button mat-icon-button color="gray"
                    (click)="remove(template)"
                    matTooltip="{{ 'GENERAL.REMOVE_ACTION' | translate }}">
              <mat-icon>delete_outline</mat-icon>
            </button>

            <button mat-icon-button color="gray"
                    (click)="download(template)"
                    matTooltip="Download">
              <mat-icon>file_download</mat-icon>
            </button>

            <button mat-icon-button color="gray"
                    (click)="testTemplate(template)"
                    matTooltip="Test it!">
              <mat-icon>play_circle_outline</mat-icon>
            </button>

            <!--<br />-->
            <!--<button mat-button>-->
            <!--<mat-icon>edit</mat-icon>-->
            <!--{{ 'GENERAL.RENAME_ACTION' | translate }}-->
            <!--</button>-->
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let rowData; columns: columnsToDisplay"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
