import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RegistrationService} from '../../services/registration.service';
import {RegistrationUser} from '../../models/registration-user.interface';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'dvtx-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss']
})

export class PasswordRegistrationFormComponent implements OnInit, OnChanges {
  @Input() user: RegistrationUser;
  @Output() next = new EventEmitter();

  PASSWORD_MIN_LENGTH = 8;
  form: UntypedFormGroup;
  verifyPasswordResponse: any;
  loading: Boolean = false;

  fieldType = fieldType;
  textType: string;
  textTypeFirst: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private registrationService: RegistrationService) {

      this.textType = fieldType.Password;
      this.textTypeFirst = fieldType.Password;

  }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (changes.user.currentValue) {
      this.createForm();
    }
  }

  createForm() {
    const group = {
      first_name: [this.user.firstName, Validators.required],
      last_name: [this.user.lastName, Validators.required],
      password: [this.user.password, [Validators.required, Validators.minLength(this.PASSWORD_MIN_LENGTH)]],
      passwordConfirm: [this.user.passwordConfirm, [Validators.required, Validators.minLength(this.PASSWORD_MIN_LENGTH)]],
      terms: [this.user.terms, Validators.required]
    };

    this.form = this.formBuilder.group(
      group,
      {
      validator: this.MatchPassword
    });

  }

  MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value; // to get value in input tag
    const passwordConfirm = AC.get('passwordConfirm').value; // to get value in input tag
    if (password !== passwordConfirm || !password) {
      AC.get('passwordConfirm').setErrors({MatchPassword: true});
    } else {
      return null;
    }
  }

  get passwordform() {
    return this.form.get('password');
  }

  showPassword() {
    this.textType = fieldType.Text;
  }

  hidePassword() {
    this.textType = fieldType.Password;
  }

  hidePasswordSecond() {
    this.textTypeFirst = fieldType.Password;
  }

  ShowPasswordSecond() {
    this.textTypeFirst = fieldType.Text;
  }

  verifyPassword(password: string) {
    this.registrationService.verifyPassword(password).subscribe(response => {
      this.verifyPasswordResponse = response;
    });
  }

  submit(formData): void {
    const updatedUser = <RegistrationUser>formData;
    updatedUser.email = this.user.email;
    updatedUser.firstName = formData.first_name;
    updatedUser.lastName = formData.last_name;
    updatedUser.confirmationToken = this.user.confirmationToken;
    this.next.emit(updatedUser);
  }

  public readTerms(event: MatCheckboxChange): void {
    this.form.controls.terms.setValue(event.checked);
    this.form.controls.terms.updateValueAndValidity();
  }
}

export enum fieldType {
  Text = 'text',
  Password = 'password',
}
