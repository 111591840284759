import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ResourceBuilder} from 'app/shared/modules/api/builders/resource.builder';
import {Observable} from 'rxjs';
import {PaymentOption} from 'app/shared/modules/api/models/payment-option';
import {PaymentOptionBuilder} from 'app/shared/modules/api/builders/payment-option.builder';
import {ApiErrorBuilder} from 'app/shared/modules/api/builders/api-error.builder';
import {IResource} from 'app/shared/modules/api/models/resource.interface';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';

export interface IValidatedBank extends IResource {
  name: string;
}

export class ValidatedBankBuilder {
  public static fromResponse(data): IValidatedBank {
    return { id: data.id, type: 'list_mapping_ibans', name: data.attributes.name };
  }

  public static toRequest(obj: IValidatedBank): {} {
    throw new Error('Not implemented.')
  }
}

@Injectable()
export class PaymentOptionService {
  readonly BASE_PATH = 'sales/payment_options';
  basePath;
  constructor(private _tokenService: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.BASE_PATH}`;

  }

  getAll(): Observable<PaymentOption[]> {
    return this._tokenService.get(this.basePath).pipe(
      map(res => <PaymentOption[]>ResourceBuilder.fromResponse(res)),
      catchError(ApiErrorBuilder.handleArrayError),);
  }

  show(id): Observable<PaymentOption> {
    return this._tokenService.get(`${this.basePath}/${id}`).pipe(
      map(res => <PaymentOption>ResourceBuilder.fromResponse(res)),
      catchError(ApiErrorBuilder.handleError),);
  }

  create(invoiceAddress: PaymentOption): Observable<PaymentOption> {
    const request = PaymentOptionBuilder.toRequest(invoiceAddress);
    return this._tokenService.post(this.basePath, request).pipe(
      map(res => <PaymentOption>ResourceBuilder.fromResponse(res)),
      catchError(ApiErrorBuilder.handleError),);
  }

  update(id, invoiceAddress: PaymentOption): Observable<PaymentOption> {
    const request = PaymentOptionBuilder.toRequest(invoiceAddress);
    return this._tokenService.put(`${this.basePath}/${id}`, request).pipe(
      map(res => <PaymentOption>ResourceBuilder.fromResponse(res)),
      catchError(ApiErrorBuilder.handleError),);
  }

  destroy(id): Observable<PaymentOption> {
    return this._tokenService.delete(`${this.basePath}/${id}`).pipe(
      map(res => <PaymentOption>ResourceBuilder.fromResponse(res)),
      catchError(ApiErrorBuilder.handleError),);
  }

  verifyIban(iban: string): Observable<IValidatedBank> {
    const payload = { data: { attributes: { iban: iban } } }
    return this._tokenService.post(`${this.basePath}`, payload).pipe(
      map(res => ValidatedBankBuilder.fromResponse(res)),
      catchError(ApiErrorBuilder.handleError),);
  }
}
