import {ContactTypes} from './address-book-table.model';
import {DEFAULT_FILTER_OPTIONS} from './address-book-table.constants';
import {Subject, Observable} from 'rxjs';
import {BatchActionViewType} from '../../../batch-dialog/shared/batch-action-dialog-base';
import {AddressBookTableHelper} from './address-book-table.helper';
import {Router} from '@angular/router';
import {ViewContainerRef} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ContactListDto, contactListDtoType} from 'app/models/contact-list-dto.model';
import {Membership} from 'app/models/membership.model';
import {Organization} from 'app/models/organization.model';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {PartnerLinkParticipationActions} from 'app/+store/partner-link-participation';
import {NaturalPersonSelectors} from 'app/+store/natural-person';
import {map} from 'rxjs/operators';

export abstract class AddressBookTableBase {
  protected onDestroy = new Subject();

  readonly REMOVE_FROM_PROCESS_DEFAULT = false;

  ContactTypes = ContactTypes;
  BatchActionViewType = BatchActionViewType;

  filterOptions = DEFAULT_FILTER_OPTIONS;

  protected helper: AddressBookTableHelper;

  protected contacts$: Observable<ContactListDto[]>;
  contactsList = [];
  assignedOrgaNames: Observable<string[]>;
  contactListDtoType = contactListDtoType;

  currentUser: any;
  organization: Organization;
  ownId$: Observable<any>;
  isAdmin = false;
  administrationRights$: Observable<Membership>;
  partnerLinksEnabled = false;
  memberShip$;
  projectAdminMap = {};
  isOwner$: Observable<boolean>;
  currentUserUID: string;

  protected constructor(protected store: Store<AppState>,
                        protected _dialog: MatDialog,
                        protected _router: Router,
                        protected _viewContainerRef: ViewContainerRef) {
    this.helper = new AddressBookTableHelper(_viewContainerRef, _router, _dialog);
  }

  /**
   * Authorize contact for Partner Links.
   */
  public authorizePartner($event, person) {
    if (!person) return;

    if ($event.checked) {
      this.store.dispatch(new PartnerLinkParticipationActions.Create(person.email));
    } else {
      this.store.dispatch(new PartnerLinkParticipationActions.Remove(person.partnerLinkId));
    }
  }

  public navigateToProjectRooms($event, member) {
    this.helper.navigateToProjectRooms($event, this.organization, member);
  }

  public toMemberProjectRooms(email) {
    this.helper.toMemberProjectRooms(this.organization, email);
  }

  protected createMembershipEmailMap(): Observable<{ [email: string]: any }> {
    return this.store.select(NaturalPersonSelectors.getNaturalPersonsOfSelectedOrganization)
      .pipe(map(members => {
        const membershipMap = {}
        members.forEach(member => {
          membershipMap[member.mainEmailAddress.emailAddress] = member;
        })
        return membershipMap;
      }));
  }
}
