import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Iam} from 'app/shared/modules/api/models/iam/activity';

export interface State extends EntityState<Iam.Activity> {
  my?: string
  loading: boolean
}

export const adapter = createEntityAdapter<Iam.Activity>();

export const initialState: State = adapter.getInitialState({
  my: undefined,
  loading: false,
});
