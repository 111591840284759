
import {of as observableOf, Observable, BehaviorSubject} from 'rxjs';

import {delay} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';

import {IContactRequest, IVerifierCountryRequest} from '../../modules/workflows/workflows/audit/kap/workflow-variations/models/subarea-verifier-request/step1/subarea-verifier-request-check.interface';
import {RequestCreateMockData} from '../../modules/workflows/workflows/audit/kap/workflow-variations/models/subarea-verifier-request/step1/subarea-verifier-request-check.mock';



@Injectable()

export class SubareaVerifierRequestCreateService {

  itemsObservable: BehaviorSubject<IVerifierCountryRequest[]>;
  originalItems: IVerifierCountryRequest[] = [];
  items: IVerifierCountryRequest[] = [];

  constructor(private formBuilder: UntypedFormBuilder) {
    this.originalItems = RequestCreateMockData;
    this.items = this.originalItems;

    this.itemsObservable = new BehaviorSubject<IVerifierCountryRequest[]>(this.items);
  }

  getGroupEntityList(id: String) {
    return this.itemsObservable.asObservable();
  }

  updateGlobalCountryLanguage(language) {
    this.items = this.items.map(item => {
      const newItem = Object.assign(<IVerifierCountryRequest>{}, item);
      newItem.language = language;
      return newItem;
    });
    this.updateSubject();
  }

  updateCountryLanguage(language, country) {
    this.items = this.items.map(item => {
      if (item.country === country.country) {
        const newItem = Object.assign(<IVerifierCountryRequest>{}, item);
        newItem.language = language;
        return newItem;
      } else {
        return item;
      }
    });
    this.updateSubject();
  }

  updateSubject() {
    this.itemsObservable.next(this.items);
  }

  submitSubareaVerifierRequests(requests): Observable<any> {
    console.log('requests', requests);
    return observableOf(true).pipe(delay(1000));
  }

  verifyContactManually(contact: IContactRequest) {
    console.log('verifyContactManually', contact);
  }

  addContactToAddressBook(contact: IContactRequest) {
    console.log('addContactToAddressBook', contact);
  }

  deleteContact(contact: IContactRequest) {
    console.log('deleteContact', contact);
  }

  getContactFormGroup(contact) {
    const form = this.formBuilder.group({
      firstName: [contact.firstName, Validators.required],
      lastName: [contact.lastName, Validators.required],
      authorization: [contact.authorization, Validators.required],
      email: [contact.email, Validators.required],
      mobile: [contact.mobile, Validators.required],
      phone: [contact.phone, Validators.required]
    });

    return form;
  };

}
