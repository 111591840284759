import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TwoFactorSessionEffects} from './two-factor-session.effects';
import {TwoFactorSessionService} from './two-factor-session.service';
import {reducer} from './two-factor-session.reducer';
import {stateKey} from './two-factor-session.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([TwoFactorSessionEffects])
  ],
  providers: [
    TwoFactorSessionEffects,
    TwoFactorSessionService
  ]
})
export class TwoFactorSessionStoreModule {
}
