import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './email-footer.state';

export const stateKey = 'email-footer';
const getEmailFooterState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getEmailFooterEntities,
  selectAll: getAllEmailFooters,
} = adapter.getSelectors(getEmailFooterState);

export const getAll = createSelector(
  getAllEmailFooters,
  (footers) => footers
);
