<div class="dvtx-info-icon-message-block" [class.align-info-horizontally]="horizontal">
  <ng-container [ngSwitch]="icon">
    <mat-icon *ngSwitchCase="'third_party'" svgIcon="third_party" class="dvtx-info-icon-message-block__icon-{{size}}"></mat-icon>
    <mat-icon *ngSwitchCase="'process'" svgIcon="process" class="dvtx-info-icon-message-block__icon-{{size}}"></mat-icon>
    <mat-icon *ngSwitchCase="'tasks'" svgIcon="tasks" class="dvtx-info-icon-message-block__icon-{{size}}"></mat-icon>
    <mat-icon *ngSwitchCase="'hide_image'" svgIcon="hide_image" class="dvtx-info-icon-message-block__icon-{{size}}"></mat-icon>
    <mat-icon *ngSwitchCase="'engineering'" svgIcon="engineering" class="dvtx-info-icon-message-block__icon-{{size}}"></mat-icon>
    <mat-icon *ngSwitchCase="'recycling'" svgIcon="recycling" class="dvtx-info-icon-message-block__icon-{{size}}"></mat-icon>
    <mat-icon *ngSwitchDefault class="dvtx-info-icon-message-block__icon-{{size}}">{{icon}}</mat-icon>
  </ng-container>

  <h3>{{message | translate}}</h3>
</div>
