import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {SplitViewDialogComponent} from 'app/shared/modules/page-container/split-view-dialog/split-view-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {Subject} from 'rxjs';
import {Fibu} from 'app/+store/fibu/fibu';

@Component({
  selector: 'dvtx-audit-trail-modal',
  templateUrl: './audit-trail-modal.component.html',
  styleUrls: ['./audit-trail-modal.component.scss']
})
export class AuditTrailModalComponent implements AfterViewInit, OnDestroy, OnInit {
  onDestroy = new Subject();

  dialogTitle = 'MY_DIALOG_TITLE';
  submitOnGoing = false;

  @ViewChild('context', { static: true }) context: TemplateRef<any>;
  @ViewChild('title', { static: true }) titleRef: TemplateRef<any>;
  buttonsToolbarPortal: Portal<any>;
  contextPortal: Portal<any>;
  titlePortal: Portal<any>;
  dialogRef: MatDialogRef<SplitViewDialogComponent>;

  @Input() process: Fibu;

  constructor(private _store: Store<AppState>,
              private _dialog: MatDialog,
              private _viewContainerRef: ViewContainerRef,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService,
              private avatarService: AvatarService) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this._initContextPortal();
          this._initTitlePortal();

    });
  }

  openDialog() {
    this._initContextPortal();
    this._initTitlePortal();

    this.dialogRef = this._dialog.open(SplitViewDialogComponent, {
      data: {
        color: '#233246',
        icon: null,
        title: this.titlePortal,
        context: this.contextPortal,
        noPadding: true
      }
    });
  }

  closeDialog(_event) {
    this.dialogRef.close();
  }

  private _initContextPortal() {
    this.contextPortal = this._createPortal(this.contextPortal, this.context);
  }

  private _initTitlePortal() {
    this.titlePortal = this._createPortal(this.titlePortal, this.titleRef);
  }


  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }
}
