import {ProcessArtifact} from '../../../../+store/process-artifact/process-artifact';

export class Appendix {
  public id: string;
  public title: string;
  public dueDate: string;
  public applicable: string;
  public globalComment: string;
  public comment: string;
  public relativeDmsPath: string;
  public currency: string;
  public componentMateriality: number;
  public toleranceMateriality: number;
  public toleranceLimit: number;
  public auditComponentAuditorCommunicationsId: number;
  public auditComponentAuditorCommunicationProcessId: number;
  public appendixUploadUrl: number;
  public artifacts: ProcessArtifact[];
  public artifactContributions: ProcessArtifact[];
  public createdAt: Date;
  public updatedAt: Date;
  public referenceId: string;

  constructor() {
    this.artifacts = [];
  }

  toSpecialAppendix(): SpecializedAppendix {
    const ret = new SpecializedAppendix();
    ret.id = this.id;
    ret.title = this.title;
    ret.dueDate = this.dueDate;
    ret.applicable = this.applicable;
    ret.globalComment = this.globalComment;
    ret.comment = this.comment;
    ret.relativeDmsPath = this.relativeDmsPath;
    ret.currency = this.currency;
    ret.componentMateriality = this.componentMateriality;
    ret.toleranceMateriality = this.toleranceMateriality;
    ret.toleranceLimit = this.toleranceLimit;
    ret.auditComponentAuditorCommunicationsId = this.auditComponentAuditorCommunicationsId;
    ret.auditComponentAuditorCommunicationProcessId = this.auditComponentAuditorCommunicationProcessId;
    ret.artifacts = this.artifacts;
    ret.createdAt = this.createdAt;
    ret.updatedAt = this.updatedAt;
    ret.referenceId = this.referenceId;
    return ret;
  }
}

export class SpecializedAppendix extends Appendix {
  public contactId: number;
  public appendixId: string;
  public processId: string;
  public status: string;
}
