<div>
  <mat-form-field>
    <div class="input-unit-row">
      <input
        matInput
        type="number"
        [(ngModel)]="innerValue"
        (change)="notifyOnChange($event.srcElement.value)"
        (click)="notifyOnTouch()"
        [placeholder]="elementName"
        [disabled]="disabled"
      >
      <div class="suffix-column">
        <span matSuffix>{{unit}}</span>
      </div>
    </div>
  </mat-form-field>
</div>
