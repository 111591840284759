<div *ngFor="let emailAddress of emailAddresses" class="email">
  <div class="row w-100 m-0">
    <div class="col-12 col-md-9 p-0">
      <dvtx-email-address-input
        [innerValue]="emailAddress"
        [isDisabled]="emailAddress.id || disabled"
      ></dvtx-email-address-input>
    </div>
    <div class="col-12 col-md-3 d-flex align-items-end" *ngIf="!disabled">
      <button mat-icon-button [disabled]="disabled" (click)="saveEmail(emailAddress)" *ngIf="!emailAddress.id">
        <mat-icon>done</mat-icon>
      </button>
      <button mat-icon-button [disabled]="disabled" (click)="deleteEmail(emailAddress)"><mat-icon>clear</mat-icon></button>
    </div>
  </div>
</div>
<div *ngIf="!disabled">
  <button mat-icon-button [disabled]="disabled" color="primary" type="button" (click)="addNewEmailField()" class="add-button" [disableRipple]="true">
    <mat-icon>add_circle_outline</mat-icon>
    {{ 'CONTACTS.ADD_EMAIL' | translate }}
  </button>
</div>
