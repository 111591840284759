import * as model from './tenant.interface';

/// <reference path="./tenant.interface.ts" />
export namespace Tenant {
  export class Tenant implements model.Tenant.ITenant {
    readonly type = 'tenant_records';

    constructor(public id,
                public name: string,
                public sender_name: string,
                public domain: string,
                public email_domain: string,
                public favicon_url: string,
                public login_header: string,
                public login_header_en: string,
                public login_footer: string,
                public login_footer_en: string,
                public imprint_url: string,
                public adDepartmentOnboardingActivated: boolean,
                public adDepartmentOffboardingActivated: boolean,
                public adDepartmentOffboardingPerformerId: boolean,
                public autoLicenseUpgrade: boolean,
                public whitelisted_user_domains: string[],
                public createdAt: Date,
                public updatedAt: Date) {
    }

    static isGrantThornton() {
      return !!(window.location.hostname.includes('grantthornton.5fsoftware.org') || window.location.hostname.includes('portal.grantthornton.de') || window.location.hostname.includes('grantthornton.local'));
    }
  }

  export class TenantDepartment {
    readonly type = 'tenant_departments';

    constructor(public id,
                public name: string,
                public description: string,
                public createdAt: Date) {
    }
  }

  export class LicenceStats {
    readonly id: 'tenant_user_stats';
    readonly type: 'tenant_user_stats';

    constructor(public all: number,
                public available: number,
                public assigned: number,
                public memberships: any[],
                public uploads: any[]) {}
  }

  export class AdminLogItem {
    readonly type: 'tenant_admin_log_items';

    total: number;
    perPage: number;
    records: number;

    constructor(public id: string,
                public performerEmail: string,
                public performerName: string,
                public category: string,
                public action: string,
                public infoLevel: string,
                public message: string,
                public createdAt: Date) {}
  }

  export class TenantAuthorizedDomain {
    readonly type: 'tenant_authorized_domains';

    constructor(public id: string, public domain: string, public defaultDomain: boolean) {}
  }

  export class OffbordingDryRun {
    readonly type: 'offboarding_dry_run';

    constructor(public id: string, public total: number, public considered: number, public removed, public inactive) {
    }
  }
}
