import {Component, Input, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MobileActivationService} from 'app/+store/mobile-activation/mobile-activation.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {takeUntil} from 'rxjs/operators';
import {MobileActivation} from 'app/+store/mobile-activation/mobile-activation';

@Component({
  selector: 'dvtx-mobile-activation-panel',
  templateUrl: './mobile-activation-panel.component.html',
  styleUrls: ['./mobile-activation-panel.component.scss']
})
export class MobileActivationPanelComponent implements OnDestroy, OnInit {

  onDestroy = new Subject();
  isLoading$ = new BehaviorSubject<boolean>(false);
  isUserVerified$ = new BehaviorSubject<boolean>(true);
  mobileActivation$ = new BehaviorSubject<MobileActivation>(null);


  @Input() userEmail: string;

  constructor(private _mobileActivationSvc: MobileActivationService,
              private _store: Store<AppState>,
              private _translateSvc: TranslateService,
              private _viewContainerRef: ViewContainerRef) {
  }

  ngOnInit() {
    this.isLoading$.next(true);
    this._mobileActivationSvc.status(this.userEmail)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((mobileActivation) => {
        this.isLoading$.next(false);
        this.isUserVerified$.next(true);
        this.mobileActivation$.next(mobileActivation);
      }, () => {
        this.isLoading$.next(false);
        this.isUserVerified$.next(false);
        this.mobileActivation$.next(null);
      });
  }

  ngOnDestroy(): void {
    this.mobileActivation$.complete();
    this.isUserVerified$.complete();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  activateMobile() {
    this.isLoading$.next(true);
    this._mobileActivationSvc.activate(this.userEmail)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((mobileActivation) => {
        this.isLoading$.next(false);
        this.isUserVerified$.next(true);
        this.mobileActivation$.next(mobileActivation);
      }, () => {
        this.isLoading$.next(false);
        this.isUserVerified$.next(false);
        this.mobileActivation$.next(null);
      });
  }
}
