import {NgModule} from '@angular/core';
import {ApiModule} from '../api/api.module';
import {BaseFormElementsModule} from '../base-form-elements/base-form-elements.module';
import {FileUploadDirective} from './directives/file-upload.directive';
import {FileUploadModalComponent} from './containers/file-upload-modal/file-upload-modal.component';
import {FileDialogModule} from '../file-dialog/file-dialog.module';
import {ListingModule} from '../listing/listing.module';

@NgModule({
    imports: [
        ApiModule,
        ListingModule,
        BaseFormElementsModule,
        FileDialogModule
    ],
    declarations: [
        FileUploadDirective,
        FileUploadModalComponent,
    ],
    exports: [
        FileUploadDirective,
        FileUploadModalComponent
    ]
})
export class FilesUploadModule {}
