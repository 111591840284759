import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './contact-organization.state';

export const stateKey = 'contactOrganizations';
const getContactOrganizationsState = createFeatureSelector<State>(stateKey)

export const {
  selectEntities: getOrganizationContactEntities,
  selectAll: getAllOrganizationContacts,
} = adapter.getSelectors(getContactOrganizationsState);

export const getContactsLoading = createSelector(
  getContactOrganizationsState,
  state => state.loading
);


export const getOrganizationContactById = (contactId: string) => createSelector(
  getOrganizationContactEntities,
  (entities) => entities[contactId]
);
