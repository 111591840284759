import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { BookmanServiceAccount } from './bookman-service-account';

export interface State extends EntityState<BookmanServiceAccount> {
  signedIn: boolean;
  error: boolean;
  loading: boolean;
}

export const adapter = createEntityAdapter<BookmanServiceAccount>();

export const initialState: State = adapter.getInitialState({
  signedIn: false,
  error: false,
  loading: false,
});
