import {IDocumentPreviewPreview, DocumentPreviewPreviewState} from './document-preview-preview.interface';

export class DocumentPreviewPreview implements IDocumentPreviewPreview {
  readonly type = 'document_preview_preview';
  constructor(public id,
              public page: number,
              public state: DocumentPreviewPreviewState,
              public content: string = null,
              public url: string = null,
              public mimeType: string = null,
              public width: number = null,
              public height: number = null,
              public documentId: string = null
              ) {
  }
}
