import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {DocumentTemplate} from './document-template';

export class DocumentTemplateBuilder implements IApiResourceBuilder<DocumentTemplate> {
  fromResponse(data): DocumentTemplate {
    return new DocumentTemplate(
      data.id,
      data.attributes.display_name,
      data.attributes.description,
      data.attributes.revision,
      data.attributes.file_name,
      data.attributes.file_size,
      data.attributes.file_type,
      data.attributes.organization_uuid,
      data.attributes.published,
      data.attributes.content
    );
  }

  toRequest(_: DocumentTemplate) {
    return null
  }
}
