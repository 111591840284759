import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'dvtx-autocompleted-input-select',
  templateUrl: './autocompleted-input-select.component.html',
  styleUrls: ['./autocompleted-input-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompletedInputSelectComponent implements OnInit {
  @Input() prefixIcon?;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() autocompleteValue = '';
  @Output() onBlurChange: EventEmitter<any> = new EventEmitter<any>(null);
  @Output() onSelectChange: EventEmitter<any> = new EventEmitter<any>(null);
  public _filteredAutocomplete = new BehaviorSubject([]);
  private _autocompleteList = [];
  private selectedAlready = false;
  @Input() set autocompleteList(value) {
    this._autocompleteList = value;
    this.onAutocompleteKeyEnter(this.autocompleteValue);
  }

  public onAutocompleteKeyEnter(value) {
    this._filteredAutocomplete.next(this._filterAutocomplete(this._autocompleteList, value));
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  private _filterAutocomplete(list, query: string) {
    const search = query && query.toLowerCase ? query.toLowerCase() : '';
    return list.filter(option => option.toLowerCase().includes(search));
  }

  public onBlur($event) {
    if ($event) $event.stopPropagation();

    setTimeout(() => {
      if (!this.selectedAlready && this.onBlurChange) {
        this.onBlurChange.emit(this.autocompleteValue);
      } else {
        this.selectedAlready = false;
      }
    }, 500);
  }

  onEnter($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.onBlurChange.emit(this.autocompleteValue);
  }

  public onSelect($event, content) {
    $event.stopPropagation();

    this.selectedAlready = true;
    if (this.onSelect) {
      this.onSelectChange.emit(content);
    }
  }

  // Used to display the text in the input field.
  public displayFn(item): string {
    return item;
  }
}
