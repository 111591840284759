import {Action} from '@ngrx/store';
import {CollectorAttachment} from './collector-attachment';

export enum CollectorAttachmentActionTypes {
  LoadAll = '[CollectorAttachment] Load All',
  LoadAllSuccess = '[CollectorAttachment] Load All Success',
  LoadAllFail = '[CollectorAttachment] Load All Fail',
  LoadOneSuccess = '[CollectorAttachment] Load One Fail',
  Remove = '[CollectorAttachment] Remove',
  RemoveSuccess = '[CollectorAttachment] Remove Success',
  RemoveFail = '[CollectorAttachment] Remove Fail',
  Download = '[CollectorAttachment] Download',
  DownloadSuccess = '[CollectorAttachment] Download Success',
  DownloadFail = '[CollectorAttachment] Download Fail',
  SaveDownload = '[CollectorAttachment] Save Download',
  PickFileFromDms = '[CollectorAttachment] PickFileFromDms',
  PickFileFromDmsSuccess = '[CollectorAttachment] PickFileFromDms Success',
  PickFileFromDmsFail = '[CollectorAttachment] PickFileFromDms Fail',
  Reset = '[CollectorAttachment] Reset'
}

export class Reset implements Action {
  readonly type = CollectorAttachmentActionTypes.Reset;

  constructor(public payload: any = null) {
  }
}

export class LoadAll implements Action {
  readonly type = CollectorAttachmentActionTypes.LoadAll;

  constructor(public collectorId: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = CollectorAttachmentActionTypes.LoadAllSuccess;

  constructor(public attachments: CollectorAttachment[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = CollectorAttachmentActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = CollectorAttachmentActionTypes.LoadOneSuccess;

  constructor(public attachment: CollectorAttachment) {
  }
}

export class Remove implements Action {
  readonly type = CollectorAttachmentActionTypes.Remove;

  constructor(public collectorId: string, public attachmentId: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = CollectorAttachmentActionTypes.RemoveSuccess;

  constructor(public attachment: CollectorAttachment) {
  }
}

export class RemoveFail implements Action {
  readonly type = CollectorAttachmentActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Download implements Action {
  readonly type = CollectorAttachmentActionTypes.Download;

  constructor(public collectorId: string, public id: string) {
  }
}

export class DownloadSuccess implements Action {
  readonly type = CollectorAttachmentActionTypes.DownloadSuccess;

  constructor(public attachment) {
  }
}

export class DownloadFail implements Action {
  readonly type = CollectorAttachmentActionTypes.DownloadFail;

  constructor(public payload: any = null) {
  }
}

export class SaveDownload implements Action {
  readonly type = CollectorAttachmentActionTypes.SaveDownload;

  constructor(public filename: string, public content: string, public mimeType: string) {
  }
}

export class PickFileFromDms implements Action {
  readonly type = CollectorAttachmentActionTypes.PickFileFromDms;

  constructor(public collectorId: string, public nodeId: string, public dmsDocumentId: string, public dmsAccountType) {
  }
}

export class PickFileFromDmsSuccess implements Action {
  readonly type = CollectorAttachmentActionTypes.PickFileFromDmsSuccess;

  constructor(public attachment) {
  }
}

export class PickFileFromDmsFail implements Action {
  readonly type = CollectorAttachmentActionTypes.PickFileFromDmsFail;

  constructor(public payload: any = null) {
  }
}

export type CollectorAttachmentActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOneSuccess
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Download
  | DownloadSuccess
  | DownloadFail
  | PickFileFromDms
  | PickFileFromDmsSuccess
  | PickFileFromDmsFail
  | SaveDownload
  | Reset;
