import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {ProcessAction} from './process-action';

export class ProcessActionBuilder implements IApiResourceBuilder<ProcessAction> {
  constructor(private _processId: string) {}

  fromResponse(data): ProcessAction {
    const attrs = data.attributes;
    return new ProcessAction(
      data.id,
      data.type,
      this._processId,
      attrs.title,
      attrs.description,
      attrs.icon,
      attrs.command
    );
  }

  toRequest(_: ProcessAction) {
    return null;
  }
}
