import {NgModule} from '@angular/core';
import {ProcessAttributeEditorComponent} from './containers/process-attribute-editor/process-attribute-editor.component';
import {SharedModule} from 'app/shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ProcessAttributeEditorComponent
  ],
  exports: [
    ProcessAttributeEditorComponent
  ]
})
export class ProcessAttributeModule {
}
