import {catchError, first, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {CppApiService} from 'app/services/cpp-api.service';
import {
  Add,
  AddFail, AddSuccess,
  ChangeOwner,
  ChangeOwnerFail,
  ChangeOwnerSuccess,
  Create,
  CreateFail,
  CreateSuccess,
  Delete,
  DeleteFail,
  DeleteSuccess,
  LoadAllFail,
  LoadAllSuccess,
  Remove,
  RemoveFail, RemoveSuccess,
  RoleActionTypes
} from './role.actions';
import {Role, RolesResponse} from 'app/models/roles.model';
import {Store} from '@ngrx/store';
import {OrganizationSelectors} from '../organization'
import {LoadAll} from '../membership/membership.actions';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {MembershipActions} from 'app/+store/membership';

@Injectable()
export class RoleEffects {
  @Effect()
  loadAll = this.actions.pipe(
    ofType(RoleActionTypes.LoadAll),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelectedId)),
    switchMap(([action, orgId]) => {
      return this.cppApiService.get<RolesResponse>('organization/' + orgId + '/membershiprole').pipe(
        map((res: RolesResponse) => {
            const roles: Role[] = res.data.map(role => ({
                id: role.id,
                current_roletype: role.attributes.current_roletype,
                role_name: role.attributes.role_name || `ROLE.${role.attributes.current_roletype.toUpperCase()}`

              })
            )
            return new LoadAllSuccess(roles)
          }
        ),
        catchError(err => of(new LoadAllFail(err)))
      )
    })
  );

  @Effect()
  addRole = this.actions.pipe(
    ofType(RoleActionTypes.Add),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelectedId)),
    switchMap(([action, orgId]: [Add, string]) => {
      return this.cppApiService.post<any>(`organization/${orgId}/membershiprole/${action.payload.roleId}/membership/${action.payload.membershipId}`).pipe(
        map((ret) => {

          // Change this
          const roleRel = ret.included.find((x) => {
            return x.id === ret.data.relationships.roles.data[0].id
          });

          const role: Role = {
            id: ret.data.id,
            current_roletype: roleRel.attributes.current_roletype,
            role_name: roleRel.attributes.role_name
          };
          this._notifyService.success('AUTH.USER_UPDATED')
          return new AddSuccess(role);
        }),
        catchError(err => of(new AddFail(err)))
      )
    })
  );

  @Effect()
  createRole = this.actions.pipe(
    ofType(RoleActionTypes.Create),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelectedId)),
    switchMap(([action, orgId]: [Create, string]) => {
      return this.cppApiService.post<any>(`organization/${orgId}/membershiprole`, {
        role_name: action.roleName
      }).pipe(
        map(ret => {
          const role: Role = {
            id: ret.data.id,
            current_roletype: ret.data.attributes.current_roletype,
            role_name: ret.data.attributes.role_name
          }
          return new CreateSuccess(role)
        }),
        catchError(err => of(new CreateFail(err)))
      )
    })
  );

  @Effect()
  changeOwner = this.actions.pipe(
    ofType(RoleActionTypes.ChangeOwner),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelectedId)),
    switchMap(([action, orgId]: [ChangeOwner, string]) => {
      return this.cppApiService.put<any>(`organization/${orgId}/owner`, {
        id: action.personId
      }).pipe(
        switchMap(_ => {
          this._notifyService.success('AUTH.USER_UPDATED')
          return [
            new MembershipActions.LoadAll(orgId),
            new MembershipActions.LoadMy(orgId),
            new LoadAll(orgId)];
        }),
        catchError(err => {
          console.error(err);
          return of(new ChangeOwnerFail(err));
        })
      )
    })
  );

  @Effect()
  changeOwnerSuccess = this.actions.pipe(
    ofType(RoleActionTypes.ChangeOwnerSuccess),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelectedId)),
    map(([action, orgId]: [ChangeOwnerSuccess, string]) => {
      this._notifyService.success('AUTH.USER_UPDATED')
      return [
        new MembershipActions.LoadAll(orgId),
        new MembershipActions.LoadMy(orgId),
        new LoadAll(orgId)]
    })
  );

  @Effect()
  removeRole = this.actions.pipe(
    ofType(RoleActionTypes.Remove),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelectedId)),
    switchMap(([action, orgId]: [Remove, string]) => {
      return this.cppApiService.delete<any>(`organization/${orgId}/membershiprole/${action.payload.roleId}/membership/${action.payload.membershipId}`).pipe(
        map(() => {
          return new RemoveSuccess(action.payload.roleId)
        }),
        catchError(err => of(new RemoveFail(err)))
      )
    })
  );

  @Effect()
  deleteRole = this.actions.pipe(
    ofType(RoleActionTypes.Delete),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelectedId)),
    switchMap(([action, orgId]: [Delete, string]) => {
      return this.cppApiService.delete<any>(`organization/${orgId}/membershiprole/${action.roleId}`).pipe(
        map(() => new DeleteSuccess(action.roleId)),
        catchError(err => of(new DeleteFail(err)))
      )
    })
  );

  constructor(private actions: Actions,
              private cppApiService: CppApiService,
              private store: Store<any>,
              private _notifyService: NotificationService,
              private _translateSvc: TranslateService
  ) {
  }
}
