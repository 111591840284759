import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './document-signature.state';

export const stateKey = 'document-signature';
const getDocumentSignatureState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getDocumentSignatureEntities,
  selectAll: getAllDocumentSignatures,
} = adapter.getSelectors(getDocumentSignatureState);

export const loadingState = createSelector(
  getDocumentSignatureState,
  (state) => state.loading
);
