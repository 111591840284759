import { ITask } from './task.interface';

export class Task implements ITask {
  readonly type = 'task_records';
  actions = [];
  constructor(public id, public processId, public title, public description,
              public dueDate, public completedAt, public status, public priority,
              public createddAt, public updatedAt) {}

  toForm() {
    return {
      id: this.id,
      process_id: this.processId,
      title: this.title,
      description: this.description,
      due_date: this.dueDate,
      status: this.status,
      priority: this.priority,
      completed_at: this.completedAt,
    }
  }

  static fromForm(values) {
    return new Task(values.id, values.process_id, values.title, values.description, values.due_date,
      values.completed_at, values.status, values.priority, null, null);
  }

  get readableStatus() {
    switch (this.status) {
      case 'initiated':
        return 'Erstellt';
      case 'overdued':
        return 'Überfällig';
      case 'in_progress':
        return 'In Bearbeitung';
      case 'completed':
        return 'Abgeschlossen';
      case 'rejected':
        return 'Abgelehnt';
      case 'canceled':
        return 'Abgebrochen';
      case 'delegated':
        return 'Delegiert';
      default:
        return 'Erstellt';
    }
  }

  get icon() {
    switch (this.status) {
      case 'initiated':
        return 'play_arrow';
      case 'overdued':
        return 'cancel';
      case 'in_progress':
        return 'timelapse';
      case 'completed':
        return 'check';
      case 'rejected':
        return 'cancel';
      case 'canceled':
        return 'cancel';
      case 'delegated':
        return 'send';
      default:
        return 'check';
    }
  }

  get infoLevel() {
    switch (this.status) {
      case 'initiated':
        return 'default';
      case 'overdued':
        return 'error';
      case 'in_progress':
        return 'default';
      case 'completed':
        return 'success';
      case 'rejected':
        return 'error';
      case 'canceled':
        return 'error';
      case 'delegated':
        return 'info';
      default:
        return 'default';
    }
  }
}
