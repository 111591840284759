import {Action} from '@ngrx/store';
import {QuickshareRecipient} from './quickshare-recipient';

export enum QuickshareRecipientActionTypes {
  Create = '[QuickshareRecipient] Create',
  CreateSuccess = '[QuickshareRecipient] Create Success',
  CreateFail = '[QuickshareRecipient] Create Fail',
  LoadAll = '[QuickshareRecipient] Load All',
  LoadAllSuccess = '[QuickshareRecipient] Load All Success',
  LoadAllFail = '[QuickshareRecipient] Load All Fail',
  Remove = '[QuickshareRecipient] Remove One',
  RemoveSuccess = '[QuickshareRecipient] Remove One Success',
  RemoveFail = '[QuickshareRecipient] Remove One Fail',
  Save = '[QuickshareRecipient] Save',
  SaveSuccess = '[QuickshareRecipient] Save Success',
  SaveFail = '[QuickshareRecipient] Save Fail'
}

export class Create implements Action {
  readonly type = QuickshareRecipientActionTypes.Create;

  constructor(public id: string, public recipient: QuickshareRecipient) {
  }
}

export class CreateSuccess implements Action {
  readonly type = QuickshareRecipientActionTypes.CreateSuccess;

  constructor(public recipient: QuickshareRecipient) {
  }
}

export class CreateFail implements Action {
  readonly type = QuickshareRecipientActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = QuickshareRecipientActionTypes.LoadAll;

  constructor(public id: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = QuickshareRecipientActionTypes.LoadAllSuccess;

  constructor(public recipients: QuickshareRecipient[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = QuickshareRecipientActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = QuickshareRecipientActionTypes.Remove;

  constructor(public id: string, public recipientId: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = QuickshareRecipientActionTypes.RemoveSuccess;

  constructor(public recipient: QuickshareRecipient) {
  }
}

export class RemoveFail implements Action {
  readonly type = QuickshareRecipientActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = QuickshareRecipientActionTypes.Save;

  constructor(public id: string, public recipient: QuickshareRecipient) {
  }
}

export class SaveSuccess implements Action {
  readonly type = QuickshareRecipientActionTypes.SaveSuccess;

  constructor(public recipient: QuickshareRecipient) {
  }
}

export class SaveFail implements Action {
  readonly type = QuickshareRecipientActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export type QuickshareRecipientActions =
  | Create
  | CreateSuccess
  | CreateFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail;
