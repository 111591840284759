import {UserAvatarActions, UserAvatarActionTypes} from './user-avatar.actions';
import {VerifiedUserActions, VerifiedUserActionTypes} from 'app/+store/verified-user/verified-user.actions';
import {adapter, initialState, State} from './user-avatar.state';
import {UserAvatar} from './user-avatar';

export function reducer(state = initialState, action: UserAvatarActions | VerifiedUserActions): State {
  switch (action.type) {
    case UserAvatarActionTypes.LoadOne:
    case UserAvatarActionTypes.LoadMany:
    case UserAvatarActionTypes.Updated:
      return {
        ...state,
        loading: true
      };

    case UserAvatarActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.avatar, {
        ...state,
        loading: false,
      });

    case UserAvatarActionTypes.LoadManySuccess:
      return adapter.upsertMany(action.avatar, {
        ...state,
        loading: false,
      });

    case VerifiedUserActionTypes.LoadAllSuccess:
      const ids = [];
      action.payload.forEach( user => {
        if (user && user.avatar) {
          ids.push(new UserAvatar(user.id, user.avatar, null, null, null, null));
        }
      });
      return adapter.upsertMany( ids, {
        ...state,
        loading: false,
      });

    case UserAvatarActionTypes.UpdatedSuccess:
      return adapter.upsertOne(action.avatar , {
        ...state,
        loading: false,
      });

    case UserAvatarActionTypes.LoadOneFail:
    case UserAvatarActionTypes.LoadManyFail:
    case UserAvatarActionTypes.UpdatedFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}
