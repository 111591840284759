import {TaskCommentActions, TaskCommentActionTypes} from './task-comment.actions';
import {adapter, initialState, State} from './task-comment.state';

export function reducer(state = initialState, action: TaskCommentActions): State {
  switch (action.type) {
    case TaskCommentActionTypes.LoadAll:
      return {
        ...state,
        loading: true,
        creating: false,
        updating: false
      };
    case TaskCommentActionTypes.LoadAllSuccess:
      return adapter.setAll(action.payload, {
          ...state,
          loading: false,
          creating: false,
          updating: false
      });

    case TaskCommentActionTypes.SendComment:
      return {
        ...state,
        loading: false,
        creating: true,
        updating: false
      };
    case TaskCommentActionTypes.SendCommentSuccess:
      return adapter.addOne(action.comment, {
        ...state,
        selected: (state.selected && state.selected.id === action.comment.id) ? null : state.selected,
        loading: false,
        creating: false,
        updating: false
      });

    case TaskCommentActionTypes.UpdateComment:
      return {
        ...state,
        loading: false,
        creating: false,
        updating: true
      };
    case TaskCommentActionTypes.UpdateCommentSuccess:
      return adapter.upsertOne(action.comment, {
        ...state,
        selected: (state.selected && state.selected.id === action.comment.id) ? null : state.selected,
        loading: false,
        creating: false,
        updating: false
      });


    case TaskCommentActionTypes.DeleteComment:
      return {
        ...state,
        loading: false,
        creating: false,
        updating: true
      };
    case TaskCommentActionTypes.DeleteCommentSuccess:
      return adapter.removeOne(action.comment.id, {
        ...state,
        selected: (state.selected && state.selected.id === action.comment.id) ? null : state.selected,
        loading: false,
        creating: false,
        updating: false
      });

    case TaskCommentActionTypes.Read:
      return {
        ...state,
        loading: false,
        creating: false,
        updating: true
      };
    case TaskCommentActionTypes.ReadSuccess:
      return adapter.upsertOne(action.comment, {
        ...state,
        loading: false,
        creating: false,
        updating: false
      });

    case TaskCommentActionTypes.ReadFail:
    case TaskCommentActionTypes.LoadAllFail:
    case TaskCommentActionTypes.SendCommentFail:
    case TaskCommentActionTypes.DeleteCommentFail:
    case TaskCommentActionTypes.UpdateCommentFail:
      return {
        ...state,
        loading: false
      };
  }
  return state;
}
