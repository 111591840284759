import {Action} from '@ngrx/store';
import {UserSignature} from './user-signature';

export enum UserSignatureActionTypes {
  LoadAll = '[UserSignature] Load All',
  LoadAllSuccess = '[UserSignature] Load All Success',
  LoadAllFail = '[UserSignature] Load All Fail',
  Create = '[UserSignature] Create',
  CreateSuccess = '[UserSignature] Create Success',
  CreateFail = '[UserSignature] Create Fail',
  Updated = '[UserSignature] Update',
  UpdatedSuccess = '[UserSignature] Update Success',
  UpdatedFail = '[UserSignature] Update Fail',
  Delete = '[UserSignature] Delete',
  DeleteSuccess = '[UserSignature] Delete Success',
  DeleteFail = '[UserSignature] Delete Fail',
}

export class Create implements Action {
  readonly type = UserSignatureActionTypes.Create;

  constructor(public signature: UserSignature) {
  }
}

export class CreateSuccess implements Action {
  readonly type = UserSignatureActionTypes.CreateSuccess;

  constructor(public signature: UserSignature) {
  }
}

export class CreateFail implements Action {
  readonly type = UserSignatureActionTypes.CreateFail;

  constructor(public signature: UserSignature) {
  }
}

export class LoadAll implements Action {
  readonly type = UserSignatureActionTypes.LoadAll;
  constructor(public signature: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = UserSignatureActionTypes.LoadAllSuccess;

  constructor(public signature: UserSignature[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = UserSignatureActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Updated implements Action {
  readonly type = UserSignatureActionTypes.Updated;

  constructor(public signature: UserSignature) {
  }
}

export class UpdatedSuccess implements Action {
  readonly type = UserSignatureActionTypes.UpdatedSuccess;

  constructor(public signature: UserSignature) {
  }
}

export class UpdatedFail implements Action {
  readonly type = UserSignatureActionTypes.UpdatedFail;

  constructor(public signature: any) {
  }
}

export class Delete implements Action {
  readonly type = UserSignatureActionTypes.Delete;

  constructor(public id:  string) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = UserSignatureActionTypes.DeleteSuccess;

  constructor(public signature: any ) {
  }
}

export class DeleteFail implements Action {
  readonly type = UserSignatureActionTypes.DeleteFail;

  constructor(public signature: any) {
  }
}



export type UserSignatureActions =
  | Create
  | CreateSuccess
  | CreateFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Updated
  | UpdatedSuccess
  | UpdatedFail
  | Delete
  | DeleteSuccess
  | DeleteFail;



