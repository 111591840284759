import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {
  ArtifactKeywordDropdownComponent
} from './containers/artifact-keyword-dropdown/artifact-keyword-dropdown.component';


@NgModule({
  declarations: [
    ArtifactKeywordDropdownComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    ArtifactKeywordDropdownComponent
  ]
})
export class ArtifactPropertiesModule {
}
