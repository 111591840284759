import {Component, EventEmitter, Input, Output} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {CreateContactDialogComponent} from 'app/modules/address-book/modules/address-book-table/components/create-contact-dialog/create-contact-dialog.component';

@Component({
  selector: 'dvtx-quick-share-form',
  templateUrl: './quick-share-form.component.html',
  styleUrls: ['./quick-share-form.component.scss']
})
export class QuickShareFormComponent {

  @Input() contactType: 'contact_people' | 'contact_organizations' = 'contact_people';
  @Input() contactToolbar = true;
  @Input() lockContactType = true;
  @Input() parent = null;

  @Input() contacts;
  file;

  @Input() form: UntypedFormGroup;
  @Output() onContactChanges = new EventEmitter();

  public newContact;

  constructor(private _dialog: MatDialog) {}

  openNewContactDialog() {
    const dialogRef = this._dialog.open(CreateContactDialogComponent,
      {
        data: {
          options: {
            contactType: this.contactType,
            lockContactType: this.lockContactType,
            autosave: false,
            contactToolbar: this.contactToolbar,
            parent: this.parent
          }
        }
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.onNewContact.emit(ContactRequestBuilder.generateDataForApi(result));
        console.log(result);
      }
    });
  }

  changes($event) {
    this.onContactChanges.emit($event)
  }
}
