import {NgModule} from '@angular/core';
import {ParticipantRoleDialogComponent} from './containers/participant-role-dialog/participant-role-dialog.component';
import {SharedModule} from 'app/shared/shared.module';
import {RecipientAutocompleteComponent} from './components/recipient-autocomplete/recipient-autocomplete.component';
import { AddAndInviteComponent } from './containers/add-and-invite/add-and-invite.component';
import {AlertsModule} from '../../../alerts/alerts.module';

@NgModule({
    imports: [
        AlertsModule,
        SharedModule,
    ],
    declarations: [
        ParticipantRoleDialogComponent,
        RecipientAutocompleteComponent,
        AddAndInviteComponent
    ],
    exports: [
        SharedModule,
        ParticipantRoleDialogComponent,
        AddAndInviteComponent,
        RecipientAutocompleteComponent
    ]
})
export class ParticipantModule {
}
