import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ProcessParticipantEffects} from './process-participant.effects';
import {ProcessParticipantService} from './process-participant.service';
import {reducer} from './process-participant.reducer';
import {stateKey} from './process-participant.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ProcessParticipantEffects])
  ],
  providers: [
    ProcessParticipantEffects,
    ProcessParticipantService
  ]
})
export class ProcessParticipantStoreModule {
}
