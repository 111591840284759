import {createEntityAdapter, EntityState} from '@ngrx/entity'
import {ContactListDto} from '../../models/contact-list-dto.model';

export interface State extends EntityState<ContactListDto> {
  loading: boolean
}

export const adapter = createEntityAdapter<ContactListDto>()

export const initialState: State = adapter.getInitialState({
  loading: false,
});

