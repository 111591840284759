import {RequestType} from './request-type.enum';
import {ThirdPartyConfirmationInsurance, ThirdPartyConfirmationInsuranceRow} from './insurance';

export namespace ThirdPartyConfirmationInsuranceBuilder {
  export function fromResponse(response): ThirdPartyConfirmationInsurance {
    const thirdParty = new ThirdPartyConfirmationInsurance(
      response.id,
      response.type,
      response.attributes.parent_process_id,
      response.attributes.referenced_at,
      response.attributes.due_date,
      response.attributes.color,
      response.attributes.language || 'de',
      response.attributes.request_type || RequestType.Insurance,
      response.attributes.rows.map((row) => {
        return new ThirdPartyConfirmationInsuranceRow(
          row.id,
          row.color || response.attributes.color,
          row.lfd,
          row.referenced_at || response.attributes.referenced_at,
          row.due_date || response.attributes.due_date,
          row.comment || '',
          row.language || response.attributes.language || 'de',
          row.request_type || response.attributes.request_type || RequestType.Insurance,
          row.versicherung_name,
          row.versicherung_ansprechpartner_name,
          row.versicherung_ansprechpartner_email,
          row.versicherung_ansprechpartner_handy,
        )
      })
    );
    thirdParty.emailAttachmentFilename = response.attributes.email_attachment_filename;
    thirdParty.state = response.attributes.state;

    return thirdParty;
  }

  export function toRequest(resource: ThirdPartyConfirmationInsurance) {
    return {
      id: resource.id,
      type: resource.type,
      attributes: {
        parent_process_id: resource.parent_process_id,
        referenced_at: resource.referenced_at,
        due_date: resource.due_date,
        language: resource.language,
        request_type: resource.request_type,
        rows: resource.rows.map((row) => {
          return {
            id: row.id,
            color: row.color,
            lfd: row.lfd,
            referenced_at: row.referenced_at,
            due_date: row.due_date,
            comment: row.comment,
            language: row.language,
            request_type: row.request_type,
            versicherung_name: row.versicherung_name,
            versicherung_ansprechpartner_name: row.versicherung_ansprechpartner_name,
            versicherung_ansprechpartner_email: row.versicherung_ansprechpartner_email,
            versicherung_ansprechpartner_handy: row.versicherung_ansprechpartner_handy
          }
        })
      }
    };
  }
}
