import * as processContextActions from '../actions/process-context.actions';
import { ProcessContext } from '../shared/modules/api/models/process-context';

export type ProcessContextStates = ProcessContext[];
export const initialState: ProcessContextStates = [];

/**
 * Reducer for the process context.
 * The process context contains information how to proceed in a process in which way (by command
 * objects), who has access as well as other meta information around process management.
 *
 * @param {ProcessContextStates} states
 * @param {Actions} action
 * @returns {ProcessContextStates}
 * @constructor
 */
export function processContextsReducer(states: ProcessContextStates = initialState,
                                      action: processContextActions.Actions): ProcessContextStates {

  switch (action.type) {

    case processContextActions.GET_PROCESS_CONTEXTS_SUCCESS:
      return action.payload;

    default: {
      return states;
    }
  }
};
