<ng-container *ngIf="processesNodes$ && (processesNodes$ | async)?.length && (processesNodes$ | async)?.length > 1">
  <h3></h3>&nbsp;
  <ng-container [ngSwitch]="(process$ | async)?.processType">

    <ng-container *ngSwitchCase="'project'">
      {{ 'DOCUMENTS.DOCUMENT_STRUCTURE_PROJECT_ROOM' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'quickcollector'">
      {{ 'DOCUMENTS.DOCUMENT_STRUCTURE' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'quickstart'">
      {{ 'DOCUMENTS.DOCUMENT_STRUCTURE_PROJECT_ROOM' | translate }}
    </ng-container>

    <ng-container *ngSwitchDefault>
      {{ 'DOCUMENTS.DOCUMENT_STRUCTURE' | translate }}
    </ng-container>

  </ng-container>
  <div style="position: relative;padding: 10px;">
    <dvtx-process-tree-single-select [nodes]="processesNodes$ | async"
                                     (onChange)="selectItem($event)"
                                     [onFolderClickCb]="onExpandItem"
                                     [initialExpandRoot]="true"></dvtx-process-tree-single-select>
  </div>
</ng-container>
