import {Injectable} from '@angular/core';
import {catchError, concatMap, first} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {FibuProcessActionTypes, LoadAll, LoadAllFail, LoadAllSuccess} from './fibu-process.actions';
import {FibuProcess} from './fibu-process';
import {FibuProcessService} from './fibu-process.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class FibuProcessEffects {

  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(FibuProcessActionTypes.LoadAll),
    concatMap((action: LoadAll) => {
      const call = action.ids ? this._svc.loadAll(action.ids) : this._svc.getAll()
      return call.pipe(
        first(),
        concatMap((fibuProcesses: FibuProcess[]) => {
          return [new LoadAllSuccess(fibuProcesses, [], !action.refresh)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: FibuProcessService,
              private _router: Router,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
