import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {DocumentSignatureEffects} from './document-signature.effects';
import {DocumentSignatureService} from './document-signature.service';
import {reducer} from './document-signature.reducer';
import {stateKey} from './document-signature.selectors';
import {SignApiResourceService} from './sign-api-resource.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([DocumentSignatureEffects])
  ],
  providers: [
    DocumentSignatureEffects,
    SignApiResourceService,
    DocumentSignatureService
  ]
})
export class DocumentSignatureStoreModule {
}
