import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {OrganizationSelectors} from 'app/+store/organization';
import {Organization} from 'app/models/organization.model';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ITabNavRoute} from 'app/five-f/organization-card/models/tab-nav-route.interface';
import {ActivatedRoute} from '@angular/router';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {Observable} from 'rxjs/internal/Observable';
import {LogoUploadContainerComponent} from '../../../../customization/containers/logo-upload-container/logo-upload-container.component';

@Component({
  selector: 'dvtx-customization-logo-frame',
  templateUrl: './customization-logo-frame.component.html',
  styleUrls: ['./customization-logo-frame.component.scss']
})
export class CustomizationLogoFrameComponent implements AfterViewInit, OnInit {
  public organization$: Observable<Organization>;
  public orgId;
  public routes: ITabNavRoute[] = [];
  public activeLink: string;

  @ViewChild('callToActionRef', { static: true }) callToActionRef: TemplateRef<any>;
  callToActionPortal: Portal<any>;

  @ViewChild('logoUploadContainerRef', { static: true }) logoUploadContainerRef: LogoUploadContainerComponent;

  constructor(private store: Store<AppState>,
              private _route: ActivatedRoute,
              private _viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.organization$ = this.store.select(OrganizationSelectors.getSelected);
    this.orgId = this._route.snapshot.params.id;

    this.routes = [{
      title: 'ORGANIZATION.LOGO',
      route: `/organization/${this.orgId}/customization/logo`
    }, {
      title: 'FOOTER.TITLE',
      route: `/organization/${this.orgId}/customization/email`,
    }];
    this.activeLink = this.routes[0].title;
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.callToActionPortal = this._createPortal(this.callToActionPortal, this.callToActionRef);
    });
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }

  public openLogoDialog() {
    this.logoUploadContainerRef.openLogoDialog();
  }
}
