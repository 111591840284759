<button
  mat-icon-button
  cdk-overlay-origin
  (click)="showDropdown = !showDropdown"
  #dropdown="cdkOverlayOrigin"
  [disableRipple]='true'>
  <mat-icon color="{{showDropdown ?  'primary' : '' }}">sentiment_very_satisfied</mat-icon>
</button>

<ng-template cdk-connected-overlay
             [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
             [cdkConnectedOverlayOrigin]="dropdown"
             [cdkConnectedOverlayHasBackdrop]="true"
             [cdkConnectedOverlayOpen]="showDropdown"
             (backdropClick)="showDropdown = false">
   <div class="emoji-menu-wrapper">
     <div class="emoji-menu"
          [ngClass]="{'show-emoji-menu': showDropdown, 'hide-emoji-menu': !showDropdown }">
       <emoji-mart
         (emojiClick)="insertEmoji($event)"
         [showPreview]="false"
         [emojiTooltip]="false"
         [autoFocus]="true"
         isNative="true"
         color="#333"
         title="Pick your emoji…" emoji="point_up">
       </emoji-mart>
     </div>
   </div>
</ng-template>
