<!-- Action button configurations -->
<ng-template [ngIf]="buttonStyle === FiveFMatButtonStyle.MenuItem">
  <button class="d-flex align-items-center" mat-menu-item
          [disabled]="!canDeleteProcess"
          (click)="openProjectRoomDeletionDialog()">
    <mat-icon>delete</mat-icon>
    <div class="d-flex flex-column">
      <span>{{ 'PROCESS_MANAGEMENT.DELETE_PROJECT_ROOM' | translate }}</span>
      <sub>{{ 'PROCESS_MANAGEMENT.DELETE_PROJECT_ROOM_SUBTITLE' | translate }}</sub>
    </div>
  </button>
</ng-template>

<ng-template [ngIf]="buttonStyle === FiveFMatButtonStyle.PrimaryButton">
  <button mat-button
          [matTooltip]="'PROCESS_MANAGEMENT.DELETE_PROJECT_ROOM' | translate"
          [color]="'warn'"
          [disabled]="process?.status?.isOpen()"
          (click)="openProjectRoomDeletionDialog()">
    <mat-icon mataffix="">delete</mat-icon>
    {{ 'PROCESS_MANAGEMENT.DELETE_PROJECT_ROOM' | translate }}
  </button>
</ng-template>

<ng-template [ngIf]="buttonStyle === FiveFMatButtonStyle.IconButton">
  <button mat-icon-button
          color="gray"
          [matTooltip]="'PROCESS_MANAGEMENT.DELETE_PROJECT_ROOM' | translate"
          (click)="openProjectRoomDeletionDialog($event)">
    <mat-icon>delete</mat-icon>
  </button>
</ng-template>

<!-- Dialog configuration -->
<ng-template cdkPortal #projectRoomDeletionTitleContext>
  <h2 mat-dialog-title>{{ 'PROCESS_MANAGEMENT.DELETE_PROJECT_ROOM' | translate }}</h2>
  <button class="mat-dialog-close" mat-mini-fab color="warn" (click)="closeProjectRoomDeletionDialog()">
    <mat-icon>clear</mat-icon>
  </button>
</ng-template>

<ng-template cdkPortal #projectRoomDeletionContext>
  <div class="five-f-process-management-process-details__delete-project-room-dialog" style="max-width: 650px">
    <div class="d-flex w-100 justify-content-center flex-column">
      <div class="p-1 h4 my-2" [innerHTML]="'PROJECT_ROOM.DELETE_PROJECT_ROOM_ARE_YOU_SURE' | translate"></div>

      <ul>
        <li class="m-1" style="position: relative; padding-left: 30px;">
          <span style="position: absolute; top: -3px; left: 0; color: #00ca5c;"><mat-icon>check_circle</mat-icon></span>
          {{'PROJECT_ROOM.DELETE_PROJECT_ROOM_SUB_PROJECT_ROOMS_DELETED_NOTE' | translate}}
        </li>
        <li class="m-1" style="position: relative; padding-left: 30px;">
          <span style="position: absolute; top: -3px; left: 0; color: #00ca5c;"><mat-icon>check_circle</mat-icon></span>
          {{'PROJECT_ROOM.DELETE_PROJECT_ROOM_TASK_DELETED_NOTE' | translate}}
        </li>
        <li class="m-1" style="position: relative; padding-left: 30px;">
          <span style="position: absolute; top: -3px; left: 0; color: #00ca5c;"><mat-icon>check_circle</mat-icon></span>
          {{'PROJECT_ROOM.DELETE_PROJECT_ROOM_TASK_DOCUMENTS_DELETED_NOTE' | translate}}
        </li>
      </ul>
      <div class="w-100 d-flex justify-content-center">
        <div class="h4 mt-2 mb-1" [innerHTML]="'PROCESS_MANAGEMENT.CONFIRM_DELETE_INFO' | translate:{ code: code }"></div>
      </div>
      <div class="w-100 d-flex justify-content-center" [formGroup]="form">
        <div style="max-width: 300px;">
          <mat-form-field [appearance]="'outline'" color="warn">
            <input matInput formControlName="code" [placeholder]="'Code'" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template cdkPortal #projectRoomDeletionButtonToolbar>
  <div class="d-flex justify-content-end">
    <div>
      <button mat-button (click)="closeProjectRoomDeletionDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
      <button mat-raised-button color="warn" [disabled]="submitOnGoing || form.invalid || form.pristine" (click)="destroyProcess()">
        {{ 'PROCESS_MANAGEMENT.DELETE_PROJECT_ROOM' | translate }}
      </button>
    </div>
  </div>
</ng-template>
