import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './fibu-process.state';

export const stateKey = 'fibu-process';
const getFibuState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getFibuEntities,
  selectAll: getAllFibuProcesses,
} = adapter.getSelectors(getFibuState);

export const getAll = createSelector(
  getAllFibuProcesses,
  (entities) => entities
);

export const getByProcessId = (processId: string) => createSelector(
  getAllFibuProcesses,
  (entities) => entities.find(p => p.id === processId)
);
export const getByClientId = (clientId: string) => createSelector(
  getAllFibuProcesses,
  (entities) => entities.find(p => p.clientId === clientId)
);

export const loadingState = createSelector(
  getFibuState,
  (state) => state.loading
);
