
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from 'environments/environment';

export enum GrantType {
  Password = 'password',
  Azure = 'azure'
}

export class ApiSignInMethod {
  constructor(public uid, public grantType: GrantType, public redirectUrl: string = null) {
  }
}

@Injectable()
export class AuthPasswordService {

  constructor(private http: HttpClient) {
  }

  public getSignInMethod(email: string, redirectUrl: string) {
    return this.http.post(
      `${environment.token_service_config.apiBase}/api/v1/auth/auth_method`,
      {
        data: {
          attributes: {
            uid: email,
            redirect_url: redirectUrl
          }
        }
      },
    ).pipe(map((res: any) => {
      const attrs = res.data.attributes;
      return new ApiSignInMethod(attrs.uid, attrs.grant_type, attrs.redirect_url)
    }));
  }

  public forgotPassword(userName: string, locale: string) {
    return this.http.post(
      `${environment.token_service_config.apiBase}/api/v1/auth/password_forgotten`,
      {
        data: {
          attributes: {
            email: userName,
            locale
          }
        }
      },
    ).pipe(catchError((err: HttpErrorResponse) => {
      return observableThrowError(err);
    }));
  }

  public verifyToken(token: string): Observable<VerifyTokenResponse> {
    return this.http.get(
      `${environment.token_service_config.apiBase}/api/v1/auth/verify_password_reset/` + token,
    ).pipe(map((response: VerifyTokenResponseApi) => {
      return {hastTFA: response.data.attributes.otp, email: response.data.attributes.email}
    }),catchError((err) => {
      return observableThrowError(err);
    }),);
  }

  public changePassword(password: string, passwordConfirmation: string, currentPassword: string, otp: string) {
    return this.http.post(
      `${environment.token_service_config.apiBase}/api/v1/auth/change_password`,
      {
        data: {
          attributes: {
            password,
            password_confirmation: passwordConfirmation,
            current_password: currentPassword,
            otp
          }
        }
      }
    ).pipe(catchError((err) => {
      return observableThrowError(err);
    }));
  }

  public changePasswordWithToken(email: string, password: string, passwordConfirmation: string, token: string, otp: string) {
    return this.http.post(
      `${environment.token_service_config.apiBase}/api/v1/auth/reset_password/${token}`,
      {
        data: {
          attributes: {
            password,
            email,
            password_confirmation: passwordConfirmation,
            otp_code: otp
          }
        }
      },
    ).pipe(catchError((err) => {
      return observableThrowError(err);
    }));
  }

}

interface VerifyTokenResponseApi {
  data: {
    id: string,
    type: string,
    attributes: {
      email: string,
      otp: boolean | null
    }
  }
}

export interface VerifyTokenResponse {
  email: string,
  hastTFA: boolean
}
