import {DatevClientActions, DatevClientActionTypes} from './datev-client.actions';
import {adapter, initialState, State} from './datev-client.state';

export function reducer(state = initialState, action: DatevClientActions): State {
  const current = state.selected;
  switch (action.type) {
    case DatevClientActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true,
        syncing: false,
      };
    case DatevClientActionTypes.SyncOne:
      return {
        ...state,
        selected: null,
        loading: false,
        syncing: true,
      };

    case DatevClientActionTypes.SyncAll:
      return {
        ...state,
        selected: null,
        loading: true,
        syncing: true,
      };

    case DatevClientActionTypes.LoadAllSuccess:
    case DatevClientActionTypes.SyncAllSuccess:
      return adapter.setAll(action.clients, {
        ...state,
        selected: null,
        loading: false,
        syncing: false,
      });

    case DatevClientActionTypes.SyncOneSuccess:
      return adapter.upsertOne(action.client, {
        ...state,
        selected: null,
        loading: false,
        syncing: false,
      });

    case DatevClientActionTypes.LoadAllFail:
    case DatevClientActionTypes.SyncAllFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
        syncing: false,
      };
  }
  return state;
}

