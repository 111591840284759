import {Action} from '@ngrx/store';
import * as model from './sms-record';
import * as modelInterface from './sms-record.interface';

export enum OperatorSmsRecordActionTypes {
  LoadAll = '[Operator.SmsRecord] Load All',
  LoadAllSuccess = '[Operator.SmsRecord] Load All Success',
  LoadAllFail = '[Operator.SmsRecord] Load All Fail'
}

export class LoadAll implements Action {
  readonly type = OperatorSmsRecordActionTypes.LoadAll;

  constructor(public params: modelInterface.Operator.ISmsRecordParams) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = OperatorSmsRecordActionTypes.LoadAllSuccess;

  constructor(public sms: model.Operator.SmsRecord[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = OperatorSmsRecordActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export type OperatorSmsRecordActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail;
