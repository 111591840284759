import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  Download,
  DownloadFail,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  PickFileFromDms,
  PickFileFromDmsFail,
  PickFileFromDmsSuccess,
  QuickshareAttachmentActionTypes,
  Remove,
  RemoveFail,
  RemoveSuccess,
  SaveDownload
} from './quickshare-attachment.actions';
import {QuickshareAttachment} from './quickshare-attachment';
import {QuickshareAttachmentService} from './quickshare-attachment.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {FileUtils} from 'app/lib/file_utils';

@Injectable()
export class QuickshareAttachmentEffects {
  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(QuickshareAttachmentActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.quickshareId).pipe(
        first(),
        concatMap((res: QuickshareAttachment[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
this._notifyService.error('NOTIFICATION.LOAD_SETTINGS_ERROR');
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  remove$ = this.actions.pipe(
    ofType(QuickshareAttachmentActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.remove(action.quickshareId, action.attachmentId).pipe(
        first(),
        concatMap((attachment: QuickshareAttachment) => {
          return [new RemoveSuccess(attachment)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  );

  @Effect()
  download$ = this.actions.pipe(
    ofType(QuickshareAttachmentActionTypes.Download),
    switchMap((action: Download) => {
      return this._svc.download(action.quickshareId, action.id).pipe(
        first(),
        switchMap((attachment: QuickshareAttachment) => {
          return [new SaveDownload(attachment.filename, attachment.content, attachment.mimeType)];
        }),
        catchError(err => {
          this._notifyService.error('CUSTOMIZATION.DOWNLOAD_TEMPLATE_FAILURE')
          return of(new DownloadFail(err));
        }));
    })
  );

  @Effect()
  pickFileFromDms$ = this.actions.pipe(
    ofType(QuickshareAttachmentActionTypes.PickFileFromDms),
    concatMap((action: PickFileFromDms) => {
      return this._svc.pickFileFromDms(action.quickshareId, action.dmsDocumentId, action.dmsAccountType).pipe(
        first(),
        concatMap((attachment: QuickshareAttachment) => {
          return [new PickFileFromDmsSuccess(attachment)];
        }),
        catchError(err => {
          console.error(err);
          return of(new PickFileFromDmsFail(err));
        }));
    })
  );

  @Effect({dispatch: false})
  saveTemplate$ = this.actions.pipe(
    ofType(QuickshareAttachmentActionTypes.SaveDownload),
    switchMap((action: SaveDownload) => {
      FileUtils.saveAsFile(action.filename, action.content, action.mimeType);
      return of(null);
    })
  );

  constructor(private actions: Actions,
              private _svc: QuickshareAttachmentService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




