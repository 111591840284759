import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {ProcessFolderBuilder} from './process-folder.builder';
import {ProcessFolder} from './process-folder';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';

@Injectable()
export class ProcessFolderService {
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';

  constructor(private _http: ApiResourceService) {
  }

  getOne(id: string, preventPrivateDmsResult = false): Observable<ProcessFolder> {
    const builder = new ProcessFolderBuilder(id);
    return <Observable<ProcessFolder>>this._http.get<ProcessFolderBuilder, ProcessFolder>(builder, `${this.BASE_PATH}/${id}/folder?verify=${preventPrivateDmsResult}`);
  }

  syncFolder(processId: string, sync_folder: boolean, dmsDocumentIds: string[]): Observable<ProcessFolder> {
    const builder = new ProcessFolderBuilder(processId);
    const payload = {
      data: {
        attributes: {
          sync_folder: sync_folder,
          dms_document_ids: dmsDocumentIds
        }
      }
    };
    return <Observable<ProcessFolder>>this._http.post<ProcessFolderBuilder, ProcessFolder>(builder, `${this.BASE_PATH}/${processId}/sync_folder`, payload);
  }

  copyDocuments(processId: string, targetFolderId: string, accountType: DmsAccountType, dmsDocumentIds: string[]): Observable<ProcessFolder> {
    const builder = new ProcessFolderBuilder(processId);
    const dmsAccountType = accountType === DmsAccountType.Organization ? 'organization' : 'private';
    const payload = {
      data: {
        attributes: {
          dms_folder_id: targetFolderId,
          dms_account_type: dmsAccountType,
          dms_document_ids: dmsDocumentIds
        }
      }
    };
    return <Observable<ProcessFolder>>this._http.post<ProcessFolderBuilder, ProcessFolder>(builder, `${this.BASE_PATH}/${processId}/copy`, payload);
  }
}
