import { Licence } from '../models/licence';

/**
 * Create LineItems - the respresentation of articles in
 * the shopping cart.
 */
export class LicenceBuilder {
  public static toRequest(_: Licence) {
    throw new Error('Not implemented');
  }

  public static fromResponse(response, _catalog = null): Licence {
    const attrs = response.attributes;
    return new Licence(response.id, response.type,
      attrs.title, attrs.subtitle, attrs.description,
      attrs.licence_type, attrs.valid_until);
  }
}
