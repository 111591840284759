
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, Input, Output, ViewEncapsulation, EventEmitter} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ListingService} from '../../../api/services/listing.service';


@Component({
  selector: 'dvtx-api-listing-dropdown',
  templateUrl: './api-listing-dropdown.component.html',
  styleUrls: ['./api-listing-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApiListingDropdownComponent {
  private _listing = [];

  @Input() form: UntypedFormGroup;
  @Input() controlName;
  @Input() placeholder = '';
  @Input() hint?: string;
  @Input() multiple?: boolean;
  @Input() prefixIcon?: string;
  @Input() isDisabled?: boolean;

  @Input()
  set listing(listing) {
    this._listing = listing;
  }

  @Input()
  set fetchListingType(listingType: string) {
    if (this._localListing(listingType)) {
      this._listingService.fetchListing(listingType).subscribe(list => {
        this._listing = list;
      });
      return;
    }
    const storageListing = JSON.parse(localStorage.getItem(listingType));
    if (!storageListing) {
      observableForkJoin([this._listingService.getLastUpdated(listingType), this._listingService.fetchListing(listingType)]).subscribe(results => {
        this.setListing(listingType, results[0].attributes.updated_at, results[1]);
      });
    } else {
      this._listingService.getLastUpdated(listingType).subscribe(res => {
        if (storageListing['last_updated'] < res.attributes.updated_at) {
          this._listingService.fetchListing(listingType).subscribe(list => {
            this.setListing(listingType, res.attributes.updated_at, list);
          });
        } else {
          this._listing = storageListing['listing'];
        }
      });
    }
  }

  @Output() onChange: EventEmitter<string> = new EventEmitter();

  setListing(listingType, lastUpdated, listing) {
    const data = {};
    data['last_updated'] = lastUpdated;
    data['listing'] = listing;
    this._listing = listing;
    localStorage.setItem(listingType, JSON.stringify(data));
  }

  changeEvent($event) {
    this.onChange.emit($event.value);
  }

  get listing() {
    return this._listing;
  }

  constructor(private _listingService: ListingService) {
  }

  private _localListing(type) {
    return type === 'gender' || type === 'priority';
  }
}


