import {
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortalDirective, ComponentPortal } from '@angular/cdk/portal';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import {DvtxControlValueAccessor} from 'app/shared/modules/base-form-elements/components/1_control-value-accessor-components/DvtxControlValueAccessor';
import { environment } from 'environments/environment';
declare let $: any; // The Froala instance will be attached to the $ variable

@Component({
  selector: 'dvtx-emoji-input',
  templateUrl: './emoji-input.component.html',
  styleUrls: ['./emoji-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmojiInputComponent),
      multi: true,
    }
  ]
})
export class EmojiInputComponent extends DvtxControlValueAccessor implements OnDestroy {
  protected onDestroy = new Subject();
  private _message: string = '';
  private _froalaInstance: any;

  @Input() height = 100;
  @Input() heightMax = 500;
  DEFAULT_OPTIONS;
  _options;
  dasError: boolean = false;
  fileError: boolean = false;
  get options() {
    return Object.assign({}, this.DEFAULT_OPTIONS, {placeholderText: this.editor.nativeElement.placeholder, heightMax: this.heightMax}, this._options);
  }

  caretPos: number = 0;
  showEmojiMenu = false;

  @ViewChild('editor', { static: true }) editor: ElementRef;

  @Input() debug = false;
  @Input() set message(msg: string) {
    this._message = msg;
    this.notifyOnChange(msg);
    this.notifyOnTouch();
  }

  @Input() set value(value) {
    this.message = value
  }

  get message(): string {
    return this._message;
  }

  @Input() placeholder = 'MESSAGING.PLACEHOLDER';

  @Output() onSend = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  @Input() editMode = false;
  @Input() disableEditButton = false;
  @Input() enableCancelButton = false;

  constructor(protected injector: Injector, private _overlay: Overlay, private _cdr: ChangeDetectorRef) {
    super();
    this.initOptions();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  writeValue(value): void {
    if (value !== undefined) {
      this.message = value;
      this.notifyOnChange(this.message);
    }
  }

  getCaretPos($event) {
    if ($event.selectionStart || String($event.selectionStart) === '0') {
      this.caretPos = $event.selectionStart;
    }
  }

  toggleEmojiMenu() {
    this.showEmojiMenu = !this.showEmojiMenu;
  }

  insertEmoji($event) {
    // const styles = this._emojiSvc.emojiSpriteStyles($event.emoji.sheet);
    // const el = document.createElement('span');
    // Object.assign(el.style, styles);
    this.insertAtMarker($event.emoji.native);
  }

  initializeLink(controls) {
    controls.initialize();
    this._froalaInstance = controls.getEditor();
  }

  insertAtMarker(value: string) {
    const html: string = this._froalaInstance.html.get(true);
    const index = html.search(/<span class=.fr-marker.*?>.*?<\/span>/)
    const newHtml = index === -1 ? `${html}${value}` : [html.slice(0, index), value, html.slice(index)].join('');
    this._froalaInstance.html.set(newHtml);
    this.message = newHtml;
  }

  initOptions() {
    const self = this;
    this.DEFAULT_OPTIONS = {
      key: environment.froalaKey,
      attribution: false,
      toolbarButtons: [['undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'outdent', 'indent', 'clearFormatting', 'insertTable', 'html']], // 'insertVideo',
      // 'imageReplace', 'imageAlign', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'
      toolbarButtonsXS: [['undo', 'redo', '-', 'bold', 'italic', 'underline']],
      // videoResponsive: true,
      // videoInsertButtons: ['videoByURL', 'videoEmbed'],
      charCounterCount: false,
      quickInsertTags: null,
      fontSizeDefaultSelection: '14',
      heightMin: this.height,
      heightMax: this.heightMax,
      zIndex: 9999,
      enter: $.FE?.ENTER_BR,
      imageMaxSize: 200 * 1024,
      fileAllowedTypes: ['*'],
      fileMaxSize: 20 * 1024 * 1024,
      fileUpload: true,
      draggable: true,
      events : {
        'image.beforeUpload' : function(files) {
          const editor = this;
          self.fileError = false;
          if (files.length) {
            const reader = new FileReader();
            reader.onload = function (event: any) {
              const result = event.target.result;
              editor.image.insert(result, null, null, editor.image.get());
            };
            let dasFiles = files[0];
            // if image is copy pasted convert blob to file
            if (dasFiles && dasFiles.type && !dasFiles.name) {
              // A Blob() is almost a File() - it's just missing the two properties below which we will add
              // Cast to a File() type
              dasFiles = new File([dasFiles], 'name', {type: dasFiles.type.replace(';base64', '')});
            }
            const imageSize = dasFiles.size;
            if (imageSize > 100000) {  // around 100KB
              self.dasError = true;
              editor.image.remove();
            } else {
              reader.readAsDataURL(dasFiles);
              self.dasError = false;
            }
          }
          editor.popups.hideAll();
          self._cdr.detectChanges();
          return false;
        },
        'file.beforeUpload' : function(files) {
          const editor = this;
          self.fileError = true;
          self.dasError = false;
          editor.popups.hideAll();
          self._cdr.detectChanges();
          return false;
          let dasFiles = files[0];
          if (files.length) {
            const reader = new FileReader();
            reader.onload = function (event: any) {
              const result = event.target.result;
              editor.file.insert(result, dasFiles.name , {result: result});
            };
            // if image is copy pasted convert blob to file
            if (dasFiles && dasFiles.type && !dasFiles.name) {
              // A Blob() is almost a File() - it's just missing the two properties below which we will add
              // Cast to a File() type
              dasFiles = new File([dasFiles], 'name', {type: dasFiles.type.replace(';base64', '')});
            }
            const imageSize = dasFiles.size;
            if (imageSize > 2000000) {  // around 20MB
              self.fileError = true;
              editor.file.remove();
            } else {
              reader.readAsDataURL(dasFiles);
              self.dasError = false;
            }
          }
          editor.popups.hideAll();
          return false;
        },
        'froalaEditor.initialized': (_e, editor) => {
          this._froalaInstance = editor;
        },
        'blur': () => {
          this._froalaInstance.selection.save();
        },
        'focus': () => {
          // This is done to remove any invisible space when focusing in an empty editor
          if (this._froalaInstance.html.get(true) === '') {
            this._froalaInstance.html.set('');
          }
        }
      }
    };
  }
}
