<!-- HEADING -->
<div class="button-row mb-2">
  <dvtx-backlink [title]="'GENERAL.BACK_ACTION' | translate"></dvtx-backlink>
</div>
<div class="row mb-3 mt-2">
  <div class="col-12 col-md-6">
    <h2 class="m-0">
      {{ title | translate }}
    </h2>

    <h5 class="mt-1" *ngIf="subTitle">
      {{ subTitlePrefix | translate }} {{ subTitle | translate }}
    </h5>
  </div>

  <div class="col-12 col-md-6 d-inline-flex justify-content-end">
    <dvtx-project-overview-stats [stats]="chartData$ | async"
                                 (onResetFilter)="showAll()"
                                 (onSelectFilter)="selectFilterByStats($event)"></dvtx-project-overview-stats>
  </div>
</div>

<div class="w-100 d-flex justify-content-end">
  <div class="five-f-mat-input five-f-search">
    <mat-form-field class="five-f-search"
                    style="margin-bottom: -2rem;"
                    (keydown.enter)="$event.preventDefault()">
      <input type="text" [placeholder]="'GENERAL.SEARCH' | translate"
             [(ngModel)]="searchTerm"
             (keyup)="applySearch(searchFieldRef.value)" matInput #searchFieldRef>
      <mat-icon matPrefix>search</mat-icon>

      <button mat-button *ngIf="searchFieldRef?.value"
              matSuffix mat-icon-button
              color="gray"
              [disableRipple]="true"
              aria-label="Clear" (click)="applySearch(''); searchFieldRef.value = ''; $event.stopPropagation()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <dvtx-tooltip [tooltipText]="'FILTER.RESET_FILTER' | translate" [position]="'top right'">
    <button mat-icon-button color="gray" [disableRipple]="true" (click)="onClearAllFilters()"
            *ngIf="showReset" class="mat-icon-button-with-text">
      <mat-icon svgIcon="filter_disable"></mat-icon>
    </button>
  </dvtx-tooltip>

  <dvtx-filters-container class="ml-2" [selectedCount]="selectedWorkflowTypes.length + selectedClients.length + selectedStatusSelectorTypes.length + selectedOrganizations.length + selectedParticipants.length" #filtersContainerComponent>
    <div class="row filters-header">
      <div class="col-9 mb-1">
        <dvtx-api-autocompleted-input-select
          [placeholder]="'GENERAL.FILTER_SEARCH' | translate"
          [prefixIcon]="'search'"
          [form]="searchForm"
          [controlName]="'searchTerm'">
        </dvtx-api-autocompleted-input-select>
      </div>
      <div class="col-3 mb-1 d-inline-flex align-items-center justify-content-end">
        <dvtx-tooltip [tooltipText]="'FILTER.RESET_FILTER' | translate" [position]="'top right'" *ngIf="showReset">
          <button mat-icon-button color="gray" (click)="onClearAllFilters()">
            <mat-icon svgIcon="filter_disable"></mat-icon>
          </button>
        </dvtx-tooltip>
        <button mat-raised-button color="primary" class="ml-2" (click)="onSaveFilters()" *ngIf="(featureSet$ | async)?.hasFiltersSaving">
          {{'FILTER.SAVE_FILTER' | translate}}
        </button>
      </div>
    </div>
    <div class="row align-items-start filters-container mt-1">
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 mb-2" *ngIf="enableClientFilter && !disableClientFilter && allClients?.length > 0">
        <dvtx-filter-list-selection-clients
          [title]="'CLIENT.CLIENT' | translate"
          [options]="clients$ | async"
          [selectedOptions]="selectedClients"
          [search]="searchForm.get('searchTerm').value"
          (onChangeSelection)="selectClient($event)">
        </dvtx-filter-list-selection-clients>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 mb-2" *ngIf="allParticipants?.length > 0">
        <dvtx-filter-list-selection-profiles
          [title]="'TASK.PERSONS' | translate"
          [options]="participants$ | async"
          [selectedOptions]="selectedParticipants"
          [search]="searchForm.get('searchTerm').value"
          (onChangeSelection)="selectParticipant($event)">
        </dvtx-filter-list-selection-profiles>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 mb-2" *ngIf="workflowsSelectorTypes && workflowsSelectorTypes.length">
        <dvtx-filter-list-selection
          [title]="'GENERAL.TYPE' | translate"
          [options]="workflowsSelectorTypes"
          [selectedOptions]="selectedWorkflowTypes"
          [search]="searchForm.get('searchTerm').value"
          (onChangeSelection)="selectWorkflow($event)">
        </dvtx-filter-list-selection>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 mb-2" *ngIf="(organizations$ | async)?.length > 1 && enableOrganizationFilter">
        <dvtx-filter-list-selection-organizations
          [title]="'ORGANIZATION.ORGANIZATION' | translate"
          [options]="organizations$ | async"
          [selectedOptions]="selectedOrganizations"
          [search]="searchForm.get('searchTerm').value"
          (onChangeSelection)="selectOrganization($event)">
        </dvtx-filter-list-selection-organizations>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 mb-2" *ngIf="statusSelectorTypes && statusSelectorTypes.length">
        <dvtx-filter-list-selection-colored
          [title]="'GENERAL.STATUS' | translate"
          [options]="statusSelectorTypes"
          [selectedOptions]="selectedStatusSelectorTypes"
          [search]="searchForm.get('searchTerm').value"
          (onChangeSelection)="selectStatus($event)">
        </dvtx-filter-list-selection-colored>
      </div>
    </div>
  </dvtx-filters-container>
</div>

<div class="mt-1">
  <dvtx-process-listing [data]="data$ | async"
                        [displayedColumns]="displayedColumns"
                        (onNavigateTo)="navigateTo($event)"
                        (onRemoveDraft)="removeDraft($event)"></dvtx-process-listing>

  <ng-container *ngIf="loading$ | async; else more">
    <div class="w-100" style="height: 2px">
      <div class="dvtx-fg-color dvtx-fg-fivef-blue-300 dvtx-loading-indicator">
        {{ 'GENERAL.LOAD_ITEMS' | translate }}<span>.</span><span>.</span><span>.</span>
      </div>
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  </ng-container>

  <ng-template #more>
    <div class="w-100 d-flex justify-content-center">
      <ng-container *ngIf="page < pages">
        <div class="infinite-scroll-zone"
             inViewport [inViewportOptions]="{ threshold: 0 }"
             (inViewportAction)="loadMore($event)">&#160;</div>
      </ng-container>
    </div>
  </ng-template>
</div>

<div *ngIf="!(loading$ | async) && (data$ | async)?.length === 0"
     class="w-100 d-flex justify-content-center">
  <dvtx-info-icon-message-block [size]="'md'"
                                [icon]="'process'"
                                [message]="'PROJECT_ROOM.NO_ITEMS_MATCH_SEARCH'"></dvtx-info-icon-message-block>
</div>
