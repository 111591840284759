import {Component, Input, OnInit} from '@angular/core';
import {ITabNavRoute} from '../../models/tab-nav-route.interface';
import {FiveFCardProfile} from '../../containers/organization-card/organization-card.component';

@Component({
  selector: 'dvtx-organization-card-nav-bar-light',
  templateUrl: './organization-card-nav-bar-light.component.html',
  styleUrls: ['./organization-card-nav-bar-light.component.scss']
})
export class OrganizationCardNavBarLightComponent implements OnInit {
  FiveFCardProfile = FiveFCardProfile;
  @Input() routes: ITabNavRoute[];
  @Input() activeLink: string;

  constructor() { }

  ngOnInit() {
  }

}
