import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ResumableUploadService} from './resumable-upload.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ResumableUploadService
  ]
})
export class ResumableUploadStoreModule {
}
