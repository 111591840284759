import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { OganizationFormComponent } from './components/oganization-form/oganization-form.component';
import { PersonFormComponent } from './components/person-form/person-form.component';
import { ContactEditDirective } from './directives/contact-edit.directive';
import { ContactEditModalComponent } from './components/contact-edit-modal/contact-edit-modal.component';
import { ContactModalComponent } from './containers/contact-modal/contact-modal.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactRemoveMenuComponent } from './components/contact-remove-menu/contact-remove-menu.component';
import { ContactEditContainerComponent } from './containers/contact-edit-container/contact-edit-container.component';

@NgModule({
    imports: [
        CommonModule,
        // StoreModule.forRoot(reducer.reducers, { initialState: reducer.initialState }),
        // EffectsModule.forRoot([ContactsEffects]),
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
        // contactRoutes
    ],
    declarations: [
        ContactModalComponent,
        OganizationFormComponent,
        PersonFormComponent,
        ContactEditDirective,
        ContactEditModalComponent,
        ContactFormComponent,
        ContactRemoveMenuComponent,
        ContactEditContainerComponent,
    ],
    exports: [
        ContactEditDirective,
    ]
})
export class ContactsModule { }
