import {createFeatureSelector} from '@ngrx/store';
import {adapter, State} from './collector-export.state';

export const stateKey = 'collector-export';
const getCollectorExportState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getCollectorExportEntities,
  selectAll: getAllCollectorExports,
} = adapter.getSelectors(getCollectorExportState);
