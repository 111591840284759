import {combineLatest as observableCombineLatest} from 'rxjs';

import {startWith, takeUntil} from 'rxjs/operators';
import {Component, Input, OnInit} from "@angular/core";
import {FilterListSelectionComponent} from "../filter-list-selection/filter-list-selection.component";

@Component({
  selector: 'dvtx-filter-list-selection-clients',
  templateUrl: './filter-list-selection-clients.component.html',
  styleUrls: ['./filter-list-selection-clients.component.scss']
})
export class FilterListSelectionClientComponent extends FilterListSelectionComponent implements OnInit {
  @Input() isAddressBook: boolean = false;

  ngOnInit(): void {
    observableCombineLatest(
      this.options$.pipe(startWith([])),
      this.search$.pipe(startWith('')),
      (options, search) => {
        if (!search || search.length === 0) return this.filterdOptions$.next(options);
        options = options.filter(option =>
          (option && option.name && option.name.toLowerCase().search(search.toLowerCase()) > -1) ||
          (option && option.firstName && option.firstName.toLowerCase().search(search.toLowerCase()) > -1) ||
          (option && option.lastName && option.lastName.toLowerCase().search(search.toLowerCase()) > -1) ||
          (option && option.email && option.email.toLowerCase().search(search.toLowerCase()) > -1)
        );
        this.filterdOptions$.next(options);
      }
    ).pipe(
      takeUntil(this.onDestroy)
    ).subscribe()
  }
}
