import {ProcessParticipant} from "../process-participant/process-participant";
import FavoriteProcess from "./favorite-process";
import {ProcessStatus} from "../process/process";

export default class FavoriteProcessBuilder {

  total = 1;
  perPage = 1;
  records = 1;
  timestamp = null;

  fromResponse(data): FavoriteProcess {
    if (!data) {
      return null
    }
    const attr = data.attributes;
    const process = new FavoriteProcess(
      data.id,
      attr.title,
      attr.subtitle,
      attr.process_type,
      attr.due_date,
      attr.created_at,
      attr.updated_at,
      attr.favorite_id,
      attr.favorite_confirmed_at);
    if (attr.status) {
      process.status = new ProcessStatus(attr.status);
    }
    process.color = attr.color;
    process.creatorEmail = attr.creator_email;
    process.creatorName = attr.creator_name;
    process.canCreateTask = attr.can_create_task;
    process.lastUpdatedByEmail = attr.last_updated_by_email;
    process.lastUpdatedByName = attr.last_updated_by_name;

    process.favoriteId = attr.favorite_id;
    process.favoriteConfirmedAt = attr.favorite_confirmed_at;

    process.profile = attr.profile;
    process.clientName = attr.client_name;

    process.total = this.total;
    process.perPage = this.perPage;
    process.records = this.records;
    process.timestamp = this.timestamp;

    if (attr.participants.length) {
      process.participants = attr.participants.map(par => new ProcessParticipant(
        par.id,
        process.id,
        par.name,
        par.email,
        null,
        null,
        par.first_name,
        par.last_name,
        undefined
      ))
    }
    return process;
  }

  toRequest(_: FavoriteProcess) {
    return null;
  }

  addMetaSection(meta: any) {
    if (!meta) return;
    this.total = meta.total;
    this.perPage = meta.per_page;
    this.records = meta.records;
    this.timestamp = meta.timestamp;
  }
}
