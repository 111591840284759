import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './dms-document.state';
import {ProcessSelectors} from '../process';

export const stateKey = 'dms-document';
const getDmsDocumentState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getDmsDocumentEntities,
  selectAll: getAllDmsDocuments,
} = adapter.getSelectors(getDmsDocumentState);

export const getSelected = createSelector(
  getDmsDocumentState,
  (state) => state.selected
)

export const getOne = (id: string) => createSelector(
  getDmsDocumentEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllDmsDocuments,
  (entities) => entities
);

export const getAllOfFolder = (folderId) => createSelector(
  getAllDmsDocuments,
  (documents) => documents.filter(document => document.folderId === folderId)
);

export const getAllOfProcessById = (processId: string ) => createSelector(
  ProcessSelectors.getProcessById(processId),
  getAllDmsDocuments,
  (process, documents) => {
    if (!process || !process.dmsFolderId) {
      return [];
    }
    return documents.filter(document => document.folderId === process.dmsFolderId)
  }
);

export const getAllOfSelectedProcess = createSelector(
  ProcessSelectors.getSelectedProcess,
  getAllDmsDocuments,
  (process, documents) => {
    if (!process || !process.dmsFolderId) {
      return [];
    }
    return documents.filter(document => document.folderId === process.dmsFolderId)
  }
);

export const loadingState = createSelector(
  getDmsDocumentState,
  (state) => state.loading
)
