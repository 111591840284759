import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Invitation, InvitationStatus, InvitationType} from 'app/+store/invitation/invitation.model';
import {
  InvitationSelectors,
  LicenceAssignmentActions,
  MembershipActions,
  NaturalPersonSelectors,
  OrganizationSelectors
} from 'app/+store';
import {CreateInvitation, Destroy, LoadOfOrganization} from 'app/+store/invitation/invitation.actions';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {Subject} from 'rxjs/internal/Subject';
import {filter, first, map, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {
  ConfirmationDialogSimpleComponent
} from 'app/shared/components/dialogs/confirmation-dialog-simple/confirmation-dialog-simple.component';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {CppApiService} from 'app/services/cpp-api.service';
import {contactListDtoType} from '../../../../../models/contact-list-dto.model';
import {InviteService} from '../invite.service';

import {LoadMy} from 'app/+store/membership/membership.actions';
import {LicenceControlService} from 'app/shared/services/licence-control.service';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {Router} from '@angular/router';
import {InViewportMetadata} from 'ng-in-viewport';
import {
  ConfirmDialogV2Component
} from "../../../../../five-f/confirm-dialog/components/confirm-dialog/confirm-dialog-v2.component";

@Component({
  selector: 'dvtx-invitations-view',
  templateUrl: './invitations-view.component.html',
  styleUrls: ['./invitations-view.component.scss']
})
export class InvitationsViewComponent implements OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();

  filterType$ = new BehaviorSubject<InvitationType>(undefined);
  invitations: Invitation[];

  @Output() changeUsedUserLicencesCount = new EventEmitter();

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public dataSource: MatTableDataSource<Invitation> = new MatTableDataSource([]);
  public columnsToDisplay = ['avatar', 'email', 'createdAt', 'status', 'resend', 'actions']; // 'invited_by'

  InvitationStatus = InvitationStatus;
  filterOptions = [{title: InvitationStatus.Accepted, id: '1'}, {
    title: InvitationStatus.Pending,
    id: '2'
  }, {title: InvitationStatus.Declined, id: '3'}];
  InvitationType = InvitationType;
  pendingInviteesEmails = [];
  accpentedInviteesEmails = [];
  employeesEmails = [];
  organizationId;
  inviteeEmail: string = '';
  contactListDtoType = contactListDtoType;

  loading: boolean = true;
  invs;

  public currentUser = {
    hasRequiredLicence: false,
    hasAdministrationRights: false,
    isOrganizationalOwner: false,
    currentLicence: null,
    attributes: {},
    licencedOrganizations: []
  }

  // in-viewport-rendering setup
  public isInViewPort: string[] = [];

  @Input() set filterType(invitationType: InvitationType) {
    this.filterType$.next(invitationType);
  }

  constructor(private store: Store<AppState>,
              private _translateService: TranslateService,
              private _notifyService: NotificationService,
              private _dialog: MatDialog,
              private httpClient: HttpClient,
              private inviteService: InviteService,
              private cppApiService: CppApiService,
              private licenceControl: LicenceControlService,
              private avatarService: AvatarService,
              private _router: Router) {

    this.dataSource.sortingDataAccessor = (item, property): string | number => {
      switch (property) {
        case 'createdAt':
          return new Date(item.created_at).getTime();
        case 'status':
          return item.current_status;
        default:
          return item[property];
      }
    };

    this.currentUser = this.licenceControl._currentUser;
    this.store.select(OrganizationSelectors.getSelectedId).pipe(
      takeUntil(this.onDestroy),
      filter(x => !!x)
    ).subscribe((org: string) => {
      this.organizationId = org;
      this.store.dispatch(new LoadMy(org));
      this.store.dispatch(new LicenceAssignmentActions.LoadAll(org));
      this.refresh();
    });

    this.invs = store.select(InvitationSelectors.getAllInvitations);

    combineLatest(store.select(InvitationSelectors.selectOrgasInvitations), this.filterType$)
      .pipe(
        map(([invitations, filterType]: [Invitation[], InvitationType]) => {
          if (!invitations || !filterType) {
            return invitations;
          }

          return invitations.filter((invitation) => invitation.type === filterType);
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe(invis => {
        this.invitations = invis;
        invis.forEach(inv => {
          if (inv.current_status === InvitationStatus.Accepted && inv.invitee_email && this.accpentedInviteesEmails.includes(inv.invitee_email) === false) {
            this.accpentedInviteesEmails.push(inv.invitee_email.trim().toLowerCase());
          } else if (inv.current_status === InvitationStatus.Pending && inv.invitee_email && this.pendingInviteesEmails.includes(inv.invitee_email) === false) {
            this.pendingInviteesEmails.push(inv.invitee_email.trim().toLowerCase());
          }
        });
        this.dataSource.data = invis;
        this.dataSource.sort = this.sort;
      });
    this.store.select(NaturalPersonSelectors.getNaturalPersonsOfSelectedOrganization)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(persons => {
        persons.forEach(person => {
          this.employeesEmails.push(person.mainEmailAddress.emailAddress);
        });
      });
    this.loading = false;
  }

  public showItem(event, element) {
    const {[InViewportMetadata]: {entry}, target, visible} = event;
    if (!visible) {
      const index = this.isInViewPort.indexOf(element, 0);
      if (index > -1) {
        this.isInViewPort.splice(index, 1);
      }

      return;
    }
    this.isInViewPort.push(element);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  refresh() {
    if (this.organizationId) {
      this.store.dispatch(new MembershipActions.LoadAll(this.organizationId));
      this.store.dispatch(new LoadOfOrganization(this.organizationId));
    }
  }

  public onFilterSelect(value) {
    let filteredData = Object.assign([], this.invitations);
    if (value && value.length > 0) {
      const listStatus = value.map(a => a.title);
      filteredData = filteredData.filter(data => listStatus.includes(data.current_status)).filter(item => item);
    }
    this.dataSource = new MatTableDataSource(filteredData);
    this.dataSource.sort = this.sort;
  }

  public openResendDialog($event) {
    const member = $event;
    this._dialog.open(ConfirmDialogV2Component, {
      data: {
        title: 'INVITATIONS.RESEND_DIALOG_TITLE',
        body: 'INVITATIONS.RESEND_DIALOG_CONTENT',
        submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        onSubmitAction: () => {
          this.resendInvitation(member);
        }
      }
    });

    // this._dialog.open(ConfirmationDialogSimpleComponent, {
    //   data: {
    //     title: 'INVITATIONS.RESEND_DIALOG_TITLE',
    //     message: 'INVITATIONS.RESEND_DIALOG_CONTENT',
    //     submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
    //     cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
    //     onSubmitAction: () => {
    //       this.resendInvitation(member);
    //     },
    //   }
    // });
  }

  private resendInvitation(member): void {
    if (member.type === InvitationType.ContactPerson) {
      this.httpClient.post(environment.token_service_config.apiBase + '/callbacks/registrations/invite', {
        data: {
          attributes: {
            invitation_type: 'business_partner',
            email: member.invitee_email
          }
        }
      }, {headers: this.cppApiService.setHeaders()}).pipe(first()).subscribe(() => {
      });
    } else {
      this.store.select(OrganizationSelectors.getSelectedId).pipe(
        first()
      ).subscribe((orgaId: string) => {
        if (member.invitee_email !== '') {
          this.store.dispatch(new CreateInvitation({orgaId: orgaId, inviteeEmail: member.invitee_email}));
          this._translateService.get('INVITATIONS.INVITATION_SENT').pipe(
            first()).subscribe((message: string) => this._notifyService.info(message));
        }
      });
    }
  }

  public openDestroyInvitationDialog($event): void {
    const member = $event;
    this._dialog.open(ConfirmDialogV2Component, {
      data: {
        title: 'INVITATIONS.DELETE_INVITATION_CONFIRMATION_TITLE',
        body: 'INVITATIONS.DELETE_INVITATION_CONFIRMATION',
        submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        onSubmitAction: () => {
          this.destroyInvitation(member);
        }
      }
    });
  }

  private destroyInvitation(member): void {
    this.store.select(OrganizationSelectors.getSelectedId).pipe(
      first()
    ).subscribe((orgaId: string) => {
      this.store.dispatch(new Destroy(member.id, InvitationType.Member, orgaId));
      this.currentUser = this.licenceControl._currentUser;
    });
  }
}
