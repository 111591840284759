import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {PartnerLink} from './partner-link';

export class PartnerLinkBuilder implements IApiResourceBuilder<PartnerLink> {
  fromResponse(data): PartnerLink {
    const partnerLink = new PartnerLink(
      data.id,
      data.attributes.organization_id,
      data.attributes.title,
      data.attributes.description,
      data.attributes.url,
      data.attributes.fg_color,
      data.attributes.bg_color,
      data.attributes.icon_link,
      data.attributes.visibility,
      data.attributes.published_at,
      data.attributes.created_at,
      data.attributes.updated_at);
    partnerLink.assignees = data.attributes.assignees;
    return partnerLink;
  }

  toRequest(partnerLink: PartnerLink) {
    return {
      data: {
        id: partnerLink.id,
        type: partnerLink.type,
        attributes: {
          title: partnerLink.title,
          url: partnerLink.url,
          fg_color: partnerLink.fgColor,
          bg_color: partnerLink.bgColor,
          visibility: partnerLink.visibility
        }
      }
    };
  }
}
