import {NgModule} from '@angular/core';
import {FolderDialogComponent} from './components/folder-dialog/folder-dialog.component';
import {DeleteFolderDialogComponent} from './components/delete-folder-dialog/delete-folder-dialog.component';
import {FolderSelectContainerComponent} from './containers/folder-select-container/folder-select-container.component';
import {ApiModule} from '../api/api.module';
import {BaseFormElementsModule} from '../base-form-elements/base-form-elements.module';
import {TreeModule} from '@circlon/angular-tree-component';
import {ErrorDialogComponent} from './components/error-dialog/error-dialog.component';
import {FolderSelectorDirective} from './directives/folder-selector.directive';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';

@NgModule({
    imports: [
        ApiModule,
        BaseFormElementsModule,
        TreeModule
    ],
    declarations: [
        FolderDialogComponent,
        DeleteFolderDialogComponent,
        FolderSelectContainerComponent,
        ErrorDialogComponent,
        FolderSelectorDirective,
    ],
    exports: [
        TreeModule,
        FolderSelectContainerComponent,
        FolderSelectorDirective,
        CdkTableModule,
        CdkTreeModule,
    ]
})
export class FileDialogModule {
}



