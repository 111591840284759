import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './document-preview-document.state';
import {DocumentPreviewDocument} from './document-preview-document';

export const stateKey = 'document-preview-document';
const getDocumentPreviewDocumentState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getDocumentPreviewDocumentEntities,
  selectAll: getAllDocumentPreviewDocuments,
} = adapter.getSelectors(getDocumentPreviewDocumentState);

export const loadingState = createSelector(
  getDocumentPreviewDocumentState,
  (state) => state.loading
);

export const documentWithId = (id: string) => createSelector(
  getDocumentPreviewDocumentEntities,
  (documentEntities) => documentEntities[id]
);

export const documentsByIds = (ids: string[]) => createSelector(
  getAllDocumentPreviewDocuments,
  (documents) => documents.filter(doc => ids.includes(doc.id))
);
