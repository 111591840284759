import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {ApiErrorBuilder} from '../../../shared/modules/api/builders/api-error.builder';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import * as store from 'store';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';

export class DownloadItem {
  constructor(public id: string, public title: string, public ownerName, public url, public uploadedAt: Date, public revision: string, public role: string) {}
}

@Injectable()
export class QuickshareService {
  readonly BASE_PATH = 'api/v1/access/external';
  basePath;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  getExternalAccess(token: string, preview = false) {
    const headers = QuickshareService.unlockHeaders(token);
    const query = preview ? '?preview=true' : '';
    return this._http.get(`${this.basePath}/quickshare/validate/${token}${query}`, {headers})
      .pipe(map((res: any) => res.data.attributes))
  }

  authorizeByPassword(token: string, passphrase: string) {
    const payload = {
      data: {
        attributes: {
          passphrase: passphrase
        }
      }
    };
    return this._http.post(`${this.basePath}/quickshare/authorize/${token}`, payload)
      .pipe(map((res: any) => res.data.attributes))
  }

  getExternalDownloads(token: string) {
    const headers = QuickshareService.unlockHeaders(token);
    return this._http.get(`${this.basePath}/artifacts?access_token=${token}`, {headers})
      .pipe(map((res: any) => res.data
        .map(doc => {
          const attrs = doc.attributes;
          return new DownloadItem(doc.id, attrs.title, attrs.owner_name, attrs.url, attrs.created_at, attrs.revision, attrs.role)
        })
      ), catchError(QuickshareService.handleError))
  }

  getExternalDownload(token: string, id: string) {
    const headers = QuickshareService.unlockHeaders(token);
    return this._http.get(`${this.basePath}/artifacts/${id}?access_token=${token}`, {headers})
      .pipe(map((res: any) => {
        const attrs = res.data.attributes;
        return new DownloadItem(res.data.id, attrs.title, attrs.owner_name, attrs.url, attrs.created_at, attrs.revision, attrs.role)
      }));
  }

  sendComment(token: string, comment: string) {
    const headers = QuickshareService.unlockHeaders(token);
    const payload = {data: {attributes: {comment: comment}}};
    return this._http.post(`${this.basePath}/quickshare/comment/${token}`, payload, {headers})
      .pipe(map((res: any) => null), catchError(QuickshareService.handleError))
  }

  private static handleError(error: HttpErrorResponse) {
    console.error('handleError', error);
    if (error) {
      const errors = ApiErrorBuilder.fromResponse(error);
      return Observable.throw(errors);
    }
    Observable.throw(error);
  }

  private static unlockHeaders(token) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    let unlock = null;
    try {
      unlock = store.get(token);
    } catch (e) {
      console.error(e);
    }

    if (unlock) {
      headers['X-UNLOCKTOKEN'] = unlock;
    }
    return headers;
  }
}
