import {createFeatureSelector} from '@ngrx/store';
import {adapter, State} from './excel-export.state';

export const stateKey = 'excel-export';
const getExcelExportState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getExcelExportEntities,
  selectAll: getAllExcelExports,
} = adapter.getSelectors(getExcelExportState);
