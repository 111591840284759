<p class="filter-selection-title d-flex align-items-center justify-content-between">
  {{title}}
  <span
    *ngIf="selectedOptions?.length"
    class="d-inline-flex align-items-center justify-items-center"
    [matBadge]="selectedOptions?.length"
    matBadgeOverlap="false"
    color="primary">
  </span>
</p>
<div class="list-group">
  <cdk-virtual-scroll-viewport *ngIf="(filterdOptions$ | async)?.length" style="height: 280px;" [itemSize]="20"
    autosize>
    <div *cdkVirtualFor="let option of filterdOptions$ | async">
      <dvtx-tooltip style="display: block !important;"
        *ngIf="option.id && (option.name || option.firstName || option.email)" [position]="'bottom left'"
        [tooltipText]="option.name || (option.firstName + ' ' + option.lastName) || option.email">
        <button type="button" class="list-group-item list-group-item-action" [class.active]="isSelected(option)"
          (click)="changeSelection(option)" [disabled]="loading">
          <span>{{ option.name || (option.firstName + ' ' + option.lastName) || option.email }}</span>
        </button>
      </dvtx-tooltip>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
