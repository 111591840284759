import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {CreateWorkflowComponent} from './containers/create-workflow/create-workflow.component';
import {QuickstartModule} from 'app/modules/quickstart/quickstart.module';
import {ClientSelectionComponent} from './containers/client-selection/client-selection.component';
import { ClientSelectionCvaComponent } from './containers/client-selection-cva/client-selection-cva.component';
import {AlertsModule} from '../../../alerts/alerts.module';
import { ScrollingModule } from '@angular/cdk-experimental/scrolling';

@NgModule({
  imports: [
    SharedModule,
    AlertsModule,
    QuickstartModule,
    ScrollingModule
  ],
  declarations: [
    ClientSelectionComponent,
    CreateWorkflowComponent,
    ClientSelectionCvaComponent
  ],
  exports: [
    AlertsModule,
    ClientSelectionComponent,
    CreateWorkflowComponent,
    ClientSelectionCvaComponent
  ]
})
export class ProcessDialogModule {
}
