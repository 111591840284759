import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ProcessEvent} from './process-event';
import * as dayjs from 'dayjs';

export interface State extends EntityState<ProcessEvent> {
  selected: ProcessEvent;
  loading: boolean;
}

export const adapter = createEntityAdapter<ProcessEvent>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false
});
