import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UploadConfiguration} from 'app/shared/modules/file-upload/models/upload-configuration.interface';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Subject, Observable} from 'rxjs';
import {takeUntil, filter, distinctUntilKeyChanged} from 'rxjs/operators';
import {User} from '../../../../models/user';
import {DocumentSignatureSelectionMenuViewType} from '../../../document-signature/modules/document-signature-type-selection/containers/document-signature-selection-menu/document-signature-selection-menu.component';
import {Feature} from 'app/+store/feature/feature';
import {FeatureSelectors} from 'app/+store/feature';
import {ProcessProfile} from 'app/+store/process/process.interface';

@Component({
  selector: 'dvtx-quickstart-bar',
  templateUrl: './quickstart-bar.component.html',
  styleUrls: ['./quickstart-bar.component.scss']
})
export class QuickstartBarComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();
  DocumentSignatureSelectionMenuViewType = DocumentSignatureSelectionMenuViewType;
  @Input() successUploadText: string;
  isAuthorized;
  featureSet$: Observable<Feature>;

  constructor(private _store: Store<AppState>) {
  }

  ngOnInit() {
    this._store.select('currentUser')
      .pipe(filter(u => !!u), distinctUntilKeyChanged('uid'), takeUntil(this.onDestroy))
      .subscribe((user: User) => {
        this.isAuthorized = !!user;
      });

    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
