import {Action} from '@ngrx/store';
import {Fibu} from './fibu';
import {IFibuParams} from "./fibu.interface";

export enum FibuActionTypes {
  LoadByClient = '[Fibu] LoadByClient',
  LoadByClientSuccess = '[Fibu] LoadByClient Success',
  LoadByClientFail = '[Fibu] LoadByClient Fail',
  Update = '[Fibu] Update',
  UpdateSuccess = '[Fibu] Update Success',
  UpdateFail = '[Fibu] Update Fail',
}


export class LoadByClient implements Action {
  readonly type = FibuActionTypes.LoadByClient;

  constructor(public clientId: string) {
  }
}

export class LoadByClientSuccess implements Action {
  readonly type = FibuActionTypes.LoadByClientSuccess;

  constructor(public fibu: Fibu) {
  }
}

export class LoadByClientFail implements Action {
  readonly type = FibuActionTypes.LoadByClientFail;

  constructor(public payload: any) {
  }
}

export class Update implements Action {
  readonly type = FibuActionTypes.Update;

  constructor(public processId: string, public params: IFibuParams) {
  }
}

export class UpdateSuccess implements Action {
  readonly type = FibuActionTypes.UpdateSuccess;

  constructor(public fibu: Fibu) {
  }
}

export class UpdateFail implements Action {
  readonly type = FibuActionTypes.UpdateFail;

  constructor(public payload: any) {
  }
}

export type FibuActions =
  | LoadByClient
  | LoadByClientSuccess
  | LoadByClientFail
  | Update
  | UpdateSuccess
  | UpdateFail;
