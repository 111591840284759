<ul class="assignment-status-bar" *ngIf="statuses">
  <ng-container *ngFor="let status of statuses; let i = index">
    <dvtx-tooltip [tooltipText]="status.title != activeStatus?.title ? (status.title | translate) : ''" [position]="'bottom left'">
      <li
        [attr.data-ph]="
        status.title != activeStatus?.title
        ?
          (
            status.title.length > 6
            ?
              (
                ( (status.title | translate) | slice:0:6 ) + '..'
              )
            :
              (status.title | translate)
          )
        :
          (status.title | translate)
        "
        [style.background]="'#'+status.color"
        [class.active]="status.title == activeStatus?.title"
        [class.last]="(i + 1) === statuses.length">{{ i + 1 }}
      </li>
    </dvtx-tooltip>

    <li
      class="assignment-status-bar-line"
      [class.before-active]="(i + 1) !== statuses.length && statuses[i + 1]?.title === activeStatus?.title"
      [class.after-active]="i !== 0 && statuses[i - 1]?.title === activeStatus?.title"
      *ngIf="(i + 1) !== statuses.length"></li>
  </ng-container>
</ul>
