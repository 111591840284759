import {IResource} from './resource.interface';
import {ProcessProfile} from 'app/+store/process/process.interface';
import {IApiResourceBuilder} from '../../api-resource/models/api.interface';

/**
 * Minimal CAV contact item to be displayed at dashboard at the
 * Your action is required section.
 */
export class SimpleContactImportItem implements IResource {
  readonly type = 'audit_contact_import_items';
  constructor(public id,
              public token,
              public client_name: string,
              public office_name,
              public creator_first_name,
              public creator_last_name,
              public business_year_end,
              public identifier: string,
              public abbreviation: string,
              public organization_name: string,
              public country_code: string,
              public due_date: string,
              public comment: string,
              public created_at: Date) {
  }
}

export class SimpleContactImportItemBuilder implements IApiResourceBuilder<SimpleContactImportItem> {
  fromResponse(data): SimpleContactImportItem {
    const attrs = data.attributes;
    return new SimpleContactImportItem(
      data.id,
      attrs.token,
      attrs.client_name,
      attrs.office_name,
      attrs.creator_first_name,
      attrs.creator_last_name,
      attrs.business_year_end,
      attrs.identifier,
      attrs.abbreviation,
      attrs.organization_name,
      attrs.country_code,
      attrs.due_date,
      attrs.comment,
      attrs.created_at);
  }

  toRequest(thirdParty: SimpleContactImportItem) { return null; }
}

export class AuditContactImports implements IResource {
  readonly type = 'audit_contact_imports';
  clientId: string;
  startsAt: Date;
  endsAt: Date;
  title: string;
  clientName: string;
  profile: ProcessProfile;

  constructor(public id, public dueDate: string,
              public parentProcessId: number, public rows: AuditContactImportRow[]) {

  }

  toForm() {
    return undefined;
  }
}

export class AuditContactImportRow {
  perCountryComment: string;

  constructor(
    public id: number,
    public identifier: string,
    public abbreviation: string,
    public organizationName: string,
    public countryCode: string,
    public firstName: string,
    public lastName: string,
    public role: string,
    public email: string,
    public phone: string,
    public mobile: string,
    public verifiedAt: string,
    public verifiedById: number,
    public dueDate: Date,
    public notificationLanguage: string,
    public comment: string,
  ) {

  }
}
