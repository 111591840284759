import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { KanbanItem } from './kanban-item';

export interface State extends EntityState<KanbanItem> {
  selected?: KanbanItem;
  loading: boolean;
}

export const adapter = createEntityAdapter<KanbanItem>({});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
