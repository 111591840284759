import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'dvtx-task-status-bar',
  templateUrl: './task-status-bar.component.html',
  styleUrls: ['./task-status-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskStatusBarComponent {
  _statuses;
  _activeStatus;

  @Input() set statuses(statuses) {
    if (statuses) {
      this._statuses = statuses;
    }
  };
  get statuses() {
    return this._statuses;
  }

  @Input() set activeStatus(activeStatus) {
    if (activeStatus) {
      this._activeStatus = activeStatus;
    }
  }
  get activeStatus() {
    return this._activeStatus;
  }

  constructor() {
  }
}
