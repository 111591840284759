import {AuditAuditorCommunications} from '../models/auditAuditorCommunications';
import {ContactImportItemBuilder} from './contact-import-item.builder';
import {Appendix} from '../models/appendix';
import {AppendixBuilder} from './appendix.builder';
import {ProcessArtifactBuilder} from '../../../../+store/process-artifact/process-artifact.builder';

export class AuditAuditorCommunicationsBuilder {
  public static fromResponse(data): AuditAuditorCommunications {
    const obj =  new AuditAuditorCommunications();
    obj.id = data['id'];
    obj.parentProcessId = data['attributes']['parent_process_id'];
    obj.instructionUploadUrl = data['attributes']['instruction_upload_url'];
    obj.artifacts = (data['attributes']['artifacts'] || []).map((artifact) => new ProcessArtifactBuilder(obj.id).fromResponse(artifact.data));

    obj.contactEntries = ContactImportItemBuilder.fromResponseList(data['attributes']['contact_entries']);
    obj.appendices = AppendixBuilder.fromResponseList(data['attributes']['appendices']);

    if (data['attributes']['contractee'] !== null) {
      obj.contracteeName = data['attributes']['contractee']['name'];
    }

    return obj;
  }

  public static toRequest(entry: AuditAuditorCommunications, clientId = null): {} {
    return {
      data: {
        type: 'audit_component_auditor_communications',
        id: entry.parentProcessId,
        attributes: {
          client_id: clientId,
          ends_at: entry.endsAt,
          starts_at: entry.startsAt,
          due_date: entry.dueDate,
          title: entry.title,
          contact_entries: ContactImportItemBuilder.toDependentRequestList(entry.contactEntries),
          appendices: entry.appendices.map((x: Appendix) => {return AppendixBuilder.toRequest(x)}),
          parent_process_id: entry.id,
          instruction_upload_url: entry.instructionUploadUrl
        }
      }
    }
  }
}

