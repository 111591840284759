import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {authRoutes} from './auth.routes';

import {AuthComponent} from './auth.component';

import {SignInPageComponent} from './containers/sign-in/sign-in.component';
import {SignUpPageComponent} from './containers/sign-up/sign-up.component';
import {ResetPasswordPageComponent} from './containers/reset-password/reset-password.component';
import {SignUpAterInvitePageComponent} from './containers/sign-up-invite/sign-up-invite.component';

import {SignInFormComponent} from './components/signin-form/signin-form.component';
import {SignUpFormComponent} from './components/signup-form/signup-form.component';
import {PasswordChangeComponent} from './components/password-change-form/password-change-form.component';
import {SignUpInviteFormComponent} from './components/signup-invitation-form/signup-invite-form.component';
import {SignInTfaComponent} from './components/sign-in-tfa/sign-in-tfa.component';
import {DashboardModule} from '../two-factor-auth/dashboard.module';
import {PasswordResetFormComponent} from './components/password-reset-form/password-reset-form.component';
import {RegistrationModule} from '../registration/registration.module';
import {LoginRouterDirective} from './directive/login-router.directive';
import {FoxdoxModule} from '../foxdox/foxdox.module';
import {BrowserSupportModule} from '../browser-support/browser-support.module';
import {TruncatedContentModule} from 'app/shared/modules/truncated-content/truncated-content.module';
import {InfoBlockModule} from '../../five-f/info-block/info-block.module';
import { TwoFactorAuthenticationComponent } from './components/two-factor-authentication/two-factor-authentication.component';

@NgModule({
    imports: [
        ReactiveFormsModule,
        DashboardModule,
        FoxdoxModule,
        BrowserSupportModule,
        RegistrationModule,
        TruncatedContentModule,
        InfoBlockModule
    ],
  declarations: [
    AuthComponent,
    SignInPageComponent,
    SignUpPageComponent,
    ResetPasswordPageComponent,
    SignInFormComponent,
    SignUpFormComponent,
    PasswordChangeComponent,
    SignUpAterInvitePageComponent,
    SignUpInviteFormComponent,
    SignInTfaComponent,
    PasswordResetFormComponent,
    LoginRouterDirective,
    TwoFactorAuthenticationComponent
  ],
  exports: []
})
export class AuthModule {

}

