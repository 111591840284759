<div *ngFor="let number of telephonenumbers" class="phone">
  <div class="row w-100 m-0">
    <div class="col-12 col-md-9 p-0">
      <dvtx-telephone-input
        [innerValue]="number"
        [isDisabled]="number.id || disabled"
        [isType]="true"
      ></dvtx-telephone-input>
    </div>

    <div class="col-12 col-md-3 d-flex align-items-end" *ngIf="!disabled">
      <button mat-icon-button (click)="add(number)" [disabled]="disabled || !number.isValid || !number.phoneNumber" *ngIf="!number.id">
        <mat-icon>done</mat-icon>
      </button>
      <button mat-icon-button (click)="remove(number)" [disabled]="disabled"><mat-icon>clear</mat-icon></button>
    </div>
  </div>
</div>
<div *ngIf="!disabled">
  <button mat-icon-button color="gray" type="button" [disabled]="disabled" (click)="addNew()" class="add-button" [disableRipple]="true">
    <mat-icon>add_circle_outline</mat-icon>
    {{ 'CONTACTS.ADD_PHONE' | translate }}
  </button>
</div>
