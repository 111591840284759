import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  LoadSelection,
  LoadSelectionFail,
  LoadSelectionSuccess, ProcessLookupActionTypes,
} from './process-lookup.actions';
import {ProcessLookupService} from './process-lookup.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {MinimalProcess} from './process-lookup';


@Injectable()
export class ProcessLookupEffects {
  @Effect()
  loadSelection$ = this.actions.pipe(
    ofType(ProcessLookupActionTypes.LoadSelection),
    concatMap((action: LoadSelection) => {
      return this._svc.getSelection(action.params.processIds, action.params).pipe(
        first(),
        concatMap((res: MinimalProcess[]) => {
          return [new LoadSelectionSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          // TODO: Add translation
          // this._notifyService.error('NOTIFICATION.LOAD_SETTINGS_ERROR')
          return of(new LoadSelectionFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: ProcessLookupService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
