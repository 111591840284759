import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  Destroy,
  DestroyFail,
  DestroySuccess,
  ExternalAccessActionTypes,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
} from './external-access.actions';
import {ExternalAccess} from './external-access';
import {ExternalAccessService} from './external-access.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class ExternalAccessEffects {
  @Effect()
  create$ = this.actions.pipe(
    ofType(ExternalAccessActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.processId, action.externalAccess).pipe(
        first(),
        concatMap((motd: ExternalAccess) => {
          return [new CreateSuccess(motd)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  );

  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(ExternalAccessActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.processId).pipe(
        first(),
        concatMap((externalAccesses: ExternalAccess[]) => {
          return [new LoadAllSuccess(externalAccesses)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  remove$ = this.actions.pipe(
    ofType(ExternalAccessActionTypes.Destroy),
    switchMap((action: Destroy) => {
      return this._svc.destroy(action.processId, action.externalAccessId).pipe(
        first(),
        concatMap((item: ExternalAccess) => {
          this._notifyService.success('EXTERNAL_ACCESS.PUBLIC_LINK_DELETED')
          return [new DestroySuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new DestroyFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: ExternalAccessService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
