import { IOrganization } from '../models/organization.interface';
import { Organization } from '../models/organization';

export namespace OrganizationBuilder {
  export function fromResponse(response, catalog = null): IOrganization {
    const attrs = response.attributes;
    return new Organization(response.id, attrs.name);
  }

  export function toRequest(record: IOrganization) {
    return {
      data: {
        id: record.id,
        type: record.type,
        attributes: {
          name: record.name
        }
      }
    };
  }
}
