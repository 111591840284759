import {MotdActions, MotdActionTypes} from './motd.actions';
import {adapter, initialState, State} from './motd.state';

export function reducer(state = initialState, action: MotdActions): State {
  const current = state.selected;
  switch (action.type) {
    case MotdActionTypes.LoadOne:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case MotdActionTypes.Create:
    case MotdActionTypes.Remove:
    case MotdActionTypes.Save:
      return {
        ...state,
        loading: true
      };
    case MotdActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.motd, {
        ...state,
        selected: action.motd,
        loading: false,
      });
    case MotdActionTypes.CreateSuccess:
    case MotdActionTypes.SaveSuccess:
      return adapter.upsertOne(action.motd, {
        ...state,
        selected: action.motd,
        loading: false,
      });
    case MotdActionTypes.CreateFail:
    case MotdActionTypes.LoadOneFail:
    case MotdActionTypes.RemoveFail:
    case MotdActionTypes.SaveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
    case MotdActionTypes.RemoveSuccess:
      return adapter.removeOne(action.motd.id, {
        ...state,
        selected: (current && current.id === action.motd.id) ?  null : current,
        loading: false,
      });
  }
  return state;
}

