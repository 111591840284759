import {IProcessParticipantAcl, ROLES} from './process-participant-acl.interface';

export class ProcessParticipantAcl implements IProcessParticipantAcl {
  readonly type = 'iam_acl_process_participants';
  children: ProcessParticipantAcl[] = [];

  constructor(
    public id,
    public processId,
    public email,
    public sid,
    public participantId,
    public name,
    public contactType,
    public processContactType,
    public accessType,
    public organizationAdmin,
    public processOwner,
    public processAdmin,
    public read,
    public write,
    public closeProcess,
    public addParticipant,
    public removeParticipant,
    public alterSettings,
    public startSubProcess,
    public uploadDocument,
    public downloadDocument,
    public deleteDocument,
    public auditTrail,
    public syncFolder,
    public readByApp,
    public writeByApp,
    public readBySyncClient,
    public writeBySyncClient,
    public grantProcessAdmin,
    public grantRead,
    public grantWrite,
    public canCreateTask,
    public canBeParticipantOfTask,
    public canAccessMyTasks,
    public canAccessAllTasks,
    public canSetCompletedTask,
    public canDeleteTask
  ) {}

  static toPermissionRowModel(acl: ProcessParticipantAcl) {
    return {
      id: acl.id,
      sid: acl.sid,
      email: acl.email,
      participantId: acl.participantId,
      name: acl.name,
      role: ProcessParticipantAcl.getAclRole(acl),
      processOwner: acl.processOwner,
      organizationAdmin: acl.organizationAdmin,
      processAdmin: acl.processAdmin,
      grant: acl.grantRead,
      folderPermissions: [{
        sid: acl.sid,
        read: {
          value: acl.read,
          sections: {
            accessDocumentsFromMenu: {
              value: acl.read
            },
            accessApp: {
              value: acl.readByApp
            },
            accessSyncedClient: {
              value: acl.readBySyncClient
            }
          }
        },
        write: {
          value: acl.write,
          sections: {
            downloadAndUploadOnDocuemtnsFromMenu: {
              value: acl.write
            },
            downloadAndUploadOnApp: {
              value: acl.writeByApp
            },
            downloadAndUploadOnSyncedClient: {
              value: acl.writeBySyncClient
            },
            exportToXML: {
              value: false
            }
          }
        }
      }],
      projectRoomPermissions: [{
        participantId: acl.participantId,
        close: {
          value: acl.closeProcess
        },
        addParticipants: {
          value: acl.addParticipant
        },
        removeParticipants: {
          value: acl.removeParticipant
        },
        changeSettings: {
          value: acl.alterSettings
        },
        startSubWorkflow: {
          value: acl.startSubProcess
        },
        uploadConnectedDocs: {
          value: acl.uploadDocument
        },
        downloadConnectedDocs: {
          value: acl.downloadDocument
        },
        deleteConnectedDocs: {
          value: acl.deleteDocument
        },
        enteringTrailView: {
          value: acl.auditTrail
        }
      }],
      tasksPermissions: [{
        participantId: acl.participantId,
        canCreateTask: {
          value: acl.canCreateTask || false
        },
        canBeParticipantOfTask: {
          value: acl.canBeParticipantOfTask || false
        },
        canAccessMyTasks: {
          value: acl.canAccessMyTasks || false
        },
        canAccessAllTasks: {
          value: acl.canAccessAllTasks || false
        },
        canSetCompletedTask: {
          value: acl.canSetCompletedTask || false
        },
        canDeleteTask: {
          value: acl.canDeleteTask || false
        }
      }]
    }
  }

  static getAclRole(acl: ProcessParticipantAcl) {
    if (acl.processOwner)
      return ROLES.PROJECT_ROOM_OWNER;
    if (acl.organizationAdmin)
      return ROLES.ORGANIZATION_ADMIN;
    if (acl.processAdmin)
      return ROLES.PROJECT_ROOM_ADMIN;
    if (acl.accessType === 'project_room' && acl.processContactType === 'member')
      return ROLES.PROJECT_ROOM_MEMBER_PARTICIPANT;
    if (acl.accessType === 'project_room')
      return ROLES.PROJECT_ROOM_CLIENT_PARTICIPANT;
    if (acl.accessType === 'external_access')
      return ROLES.PROJECT_ROOM_EXTERNAL_PARTICIPANT;
    if (acl.email)
      return ROLES.CONTACT;
    return ROLES.FOXDOX_ACCOUNT;
  }
}
