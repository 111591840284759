import {OperatorTenantActions, OperatorTenantActionTypes} from './tenant.actions';
import {adapter, initialState, State} from './tenant.state';

export function reducer(state = initialState, action: OperatorTenantActions): State {
  const current = state.selected;
  switch (action.type) {
    case OperatorTenantActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case OperatorTenantActionTypes.Create:
    case OperatorTenantActionTypes.LoadOne:
    case OperatorTenantActionTypes.Remove:
    case OperatorTenantActionTypes.Save:
      return {
        ...state,
        loading: true
      };
    case OperatorTenantActionTypes.LoadOneSuccess:
      return adapter.addOne(action.tenant, {
        ...state,
        selected: action.tenant,
        loading: false,
      });
    case OperatorTenantActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.tenants, {
        ...state,
        loading: false,
      });
    case OperatorTenantActionTypes.CreateSuccess:
    case OperatorTenantActionTypes.SaveSuccess:
      return adapter.upsertOne(action.tenant, {
        ...state,
        selected: action.tenant,
        loading: false,
      });
    case OperatorTenantActionTypes.CreateFail:
    case OperatorTenantActionTypes.LoadAllFail:
    case OperatorTenantActionTypes.LoadOneFail:
    case OperatorTenantActionTypes.RemoveFail:
    case OperatorTenantActionTypes.SaveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
    case OperatorTenantActionTypes.RemoveSuccess:
      return adapter.removeOne(action.tenant.id, {
        ...state,
        selected: (current && current.id === action.tenant.id) ? null : current,
        loading: false,
      });
  }
  return state;
}
