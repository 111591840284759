<div class="dvtx-instance-messenger column w-100">
  <div *ngIf="dasError" class="error-container red flex">
    <mat-icon>warning</mat-icon>
    <p> {{'GENERAL.BIG_IMAGE' | translate}} </p>
  </div>
  <div *ngIf="fileError" class="error-container red flex">
    <mat-icon>warning</mat-icon>
    <p> {{'GENERAL.FILE_ERROR' | translate}} </p>
  </div>
  <div class="editor-text-reply row m-0">
    <textarea #editor
              (froalaInit)="initializeLink($event)"
              [froalaEditor]="options"
              [(froalaModel)]="message"
              [placeholder]="placeholder"
              (click)="getCaretPos(editor)"
              (keyup)="getCaretPos(editor)"
              class="col-12"></textarea>
  </div>
  <div class="action-buttons row m-0 message-panel-footer d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center mr-2">
      <button mat-icon-button cdk-overlay-origin #trigger="cdkOverlayOrigin" (click)="showEmojiMenu = !showEmojiMenu" matSuffix>
        <mat-icon>sentiment_very_satisfied</mat-icon>
      </button>
      <ng-template cdk-connected-overlay
                   [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
                   [cdkConnectedOverlayOrigin]="trigger"
                   [cdkConnectedOverlayHeight]="400"
                   [cdkConnectedOverlayWidth]="360"
                   [cdkConnectedOverlayHasBackdrop]="true"
                   [cdkConnectedOverlayOpen]="showEmojiMenu"
                   (backdropClick)="showEmojiMenu = false">
        <div class="emoji-menu-wrapper">
          <div class="emoji-menu showEmojiMenu" style="width: 360px;">
            <emoji-mart
              (emojiClick)="insertEmoji($event)"
              [showPreview]="false"
              [emojiTooltip]="false"
              [autoFocus]="true"
              color="#333"
              title="Pick your emoji…" emoji="point_up">
            </emoji-mart>
          </div>
        </div>
      </ng-template>
    </div>

    <div class="d-flex">
      <div *ngIf="!editMode" class="d-flex align-items-center">
        <button [disabled]="message?.length === 0" mat-icon-button color="primary"
                (click)="onSend.emit($event)">
          <mat-icon>send</mat-icon>
        </button>
      </div>
      <div class="d-flex align-items-center ml-1" *ngIf="enableCancelButton">
        <button mat-icon-button color="gray"
                (click)="onCancel.emit($event)" matTooltip="{{'GENERAL.CANCEL_ACTION' | translate}}">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div *ngIf="editMode" class="d-flex align-items-center">
        <span class="input-group-btn ml-2">
          <button [disabled]="message?.length === 0 || disableEditButton"
                  [matTooltip]="'GENERAL.SAVE_ACTION' | translate"
                  mat-icon-button color="primary"
                  (click)="onSave.emit($event)">
            <mat-icon>check</mat-icon>
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
