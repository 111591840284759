<div class="dvtx-orgaization-master-data-settings" *ngIf="(orga)">
  <h3>{{ 'ORGANIZATION.MASTER_DATA_TITLE' | translate}}</h3>
  <div class="inline pl-2">
    <div class="d-flex">
      <div class="inline">
        <mat-icon>domain</mat-icon>
        <dvtx-text-input-cva style="width: 296px !important;" [elementName]="'CONTACTS.ORG_NAME' | translate"
          [(ngModel)]="orga.name" [isDisabled]="readonly || !editmode"></dvtx-text-input-cva>
      </div>
      <dvtx-api-select-cva style="padding-left: 30px;" class="w-100" [elementName]="'CONTACTS.LEGAL_FORM' | translate"
        listingType="legal-form" listingPath="name" [isDisabled]="readonly || !editmode" [(ngModel)]="orga.legalFormId">
      </dvtx-api-select-cva>
    </div>
  </div>
  <div>
    <div class="inline  pl-2">
      <mat-icon>email</mat-icon>
      <dvtx-email-address-input [(ngModel)]="orga.email" [isDisabled]="readonly || !editmode">
      </dvtx-email-address-input>
    </div>
  </div>
  <div class="inline  pl-2">
    <mat-icon>phone</mat-icon>
    <dvtx-telephone-input style="width: 463px;" [(ngModel)]="orga.telephone" [isDisabled]="readonly || !editmode"
      [isType]="true">
    </dvtx-telephone-input>
  </div>

  <div style="width: 508px;">
    <div class="inline  pl-2">
      <mat-icon>place</mat-icon>
        <dvtx-address-input-form [address]="orga.address" [isDisabled]="readonly || !editmode"></dvtx-address-input-form>
    </div>
  </div>
  <hr>
  <h3>{{ 'CLIENT.DATEV_CONSULTANT_TITLE' | translate }}</h3>
  <div>
    <div class="inline  pl-2" [formGroup]="organizationProxyForm">
      <mat-icon svgIcon="datev"></mat-icon>
      <dvtx-text-input-cva [elementName]="'CLIENT.DATEV_CONSULTANT_ID' | translate" formControlName="consultantId"
        [isDisabled]="readonly || !editmode"></dvtx-text-input-cva>
    </div>
  </div>
</div>
