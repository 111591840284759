import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {PartnerLinkParticipation} from './partner-link-participation';
import {PartnerLinkParticipationBuilder} from './partner-link-participation.builder';

@Injectable()
export class PartnerLinkParticipationService {
  readonly BASE_PATH = 'api/v1/cms/partner_link_participations';

  constructor(private _http: ApiResourceService) {}

  getAll(): Observable<PartnerLinkParticipation[]> {
    const builder = new PartnerLinkParticipationBuilder();
    return <Observable<PartnerLinkParticipation[]>>this._http.get<PartnerLinkParticipationBuilder, PartnerLinkParticipation>(builder, `${this.BASE_PATH}`);
  }

  create(email: string): Observable<PartnerLinkParticipation> {
    const builder = new PartnerLinkParticipationBuilder();
    const payload = {
      data: {
        attributes: {
          email: email
        }
      }
    };
    return <Observable<PartnerLinkParticipation>>this._http.post<PartnerLinkParticipationBuilder, PartnerLinkParticipation>(builder, `${this.BASE_PATH}/`, payload);
  }

  destroy(partnerLinkParticipationId: string): Observable<PartnerLinkParticipation> {
    const builder = new PartnerLinkParticipationBuilder();
    return <Observable<PartnerLinkParticipation>>this._http.del<PartnerLinkParticipationBuilder, PartnerLinkParticipation>(builder, `${this.BASE_PATH}/${partnerLinkParticipationId}`);
  }
}
