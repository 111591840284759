import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, State } from './kanban-group.state';

export const stateKey = 'kanban-group';
const getKanbanGroupState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getKanbanGroupEntities,
  selectAll: getAllKanbanGroups,
} = adapter.getSelectors(getKanbanGroupState);

export const getSelected = createSelector(
  getKanbanGroupState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getKanbanGroupEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllKanbanGroups,
  (entities) => entities
);

export const getAllLabelsIds = createSelector(
  getAllKanbanGroups,
  (entities) => entities.map(entity => entity.labelId)
);

export const loadingState = createSelector(
  getKanbanGroupState,
  (state) => state.loading
);
