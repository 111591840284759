import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Sizes} from '../../models/avatar';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Subject, BehaviorSubject, Observable} from 'rxjs';
import {UserAvatar} from 'app/+store/user-avatar/user-avatar';
import {takeUntil, distinctUntilKeyChanged, filter} from 'rxjs/operators';

@Component({
  selector: 'dvtx-simple-avatar',
  templateUrl: './simple-avatar.component.html',
  styleUrls: ['./simple-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleAvatarComponent implements OnDestroy, OnInit {
  protected onDestroy = new Subject();

  avatarSizeNumber: number;
  avatarSizeString: string;
  avatarShowEmail: boolean = false;
  _profile: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  avatarProfile;
  avatarShowTitle: boolean = false;
  avatarButtonCallback: any = null;
  userStatusIsMember = false;
  userStatusIsVerified = false;
  image$: Observable<UserAvatar>;

  @Input() isVerified: boolean = false;
  @Input() isMember: boolean = false;
  @Input() showTick: boolean = true;

  @Input() userAvatar: UserAvatar;

  currentEmail: string = null;

  refresh$ = new BehaviorSubject(null);

  @Input() set profile(profile) {

    if (!profile) return;

    if (!profile.fgColor) {
      profile.fgColor = '#fff';
    }
    if (!profile.bgColor) {
      profile.bgColor = '#0090cf';
    }

    this.refresh$.next(profile);
  };

  @Input() set size(size: any) {
    this.avatarSizeNumber = +Sizes[size];
    this.avatarSizeString = size;
  };

  @Input() set showTitle(showTitle: boolean) {
    this.avatarShowTitle = showTitle;
  };

  @Input() set showEmail(showEmail: boolean) {
    this.avatarShowEmail = showEmail;
  };

  @Input() set callbackFn(callbackFn: any) {
    this.avatarButtonCallback = callbackFn;
  };

  constructor(private _store: Store<AppState>,
              private _cdr: ChangeDetectorRef,
              private _ngZone: NgZone) {
  }

  ngOnInit() {
    this.refresh$.pipe(
      filter(profile => !!profile),
      distinctUntilKeyChanged('email'),
      takeUntil(this.onDestroy)
    ).subscribe(profile => {
      this.avatarProfile = profile;
      // this._cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.refresh$.complete();
    this._profile.complete();
  }
}
