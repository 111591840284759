import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import * as model from './user';

export namespace Operator {
  export class UserStatsBuilder implements IApiResourceBuilder<model.Operator.UserStats> {
    fromResponse(data): model.Operator.UserStats {
      return new model.Operator.UserStats(
        data.attributes.all,
        data.attributes.member_only,
        data.attributes.bp_only,
        data.attributes.cav_only
      );
    }

    toRequest(_: model.Operator.UserStats) {
      return null;
    }
  }

  export class UserBuilder implements IApiResourceBuilder<model.Operator.User> {
    total = 1;
    perPage = 1;
    records = 1;

    fromResponse(data): model.Operator.User {
      const user = new model.Operator.User(
        data.id,
        data.attributes.email,
        data.attributes.first_name,
        data.attributes.last_name,
        data.attributes.basic_account,
        data.attributes.created_at,
        data.attributes.confirmed_at,
        data.attributes.locked_at,
      );
      user.testAccount = data.attributes.test_account;
      user.lastSignInAt = data.attributes.last_sign_in_at;

      user.roles = data.attributes.roles;

      user.creatorEmail = data.attributes.creator_email;
      user.creatorName = data.attributes.creator_name;
      user.invitationType = data.attributes.invitation_type;

      user.total = this.total;
      user.perPage = this.perPage;
      user.records = this.records;

      user.trialDate = data.attributes.trial_date;

      user.dmsSid = data.attributes.dms_sid;
      user.dmsUsername = data.attributes.dms_username;

      user.confirmationLink = data.attributes.confirmation_link;
      user.hasOtp = data.attributes.otp_enabled;

      user.authProvider = data.attributes.auth_provider;

      if (data.attributes.dms_folder_info) {
        user.dmsHumanSize = data.attributes.dms_folder_info.human_size;
        user.dmsUsedInPercent = data.attributes.dms_folder_info.used_in_percent;
        user.dmsDiskSpace = data.attributes.dms_folder_info.disk_space;
        user.dmsFree = data.attributes.dms_folder_info.free;
        user.dmsDocumentCount = data.attributes.dms_folder_info.document_count;
        user.dmsFolderCount = data.attributes.dms_folder_info.folder_count;
      }

      return user;
    }

    toRequest(_: model.Operator.User) {
      return null;
    }

    addMetaSection(meta: any) {
      if (!meta) return;
      this.total = meta.total;
      this.perPage = meta.per_page;
      this.records = meta.records;
    }
  }
}
