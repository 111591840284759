import {adapter, initialState, State} from './contact-person-organization-association.state';
import {
  ContactPersonOrganizationAssociationActions,
  ContactPersonOrganizationAssociationActionTypes
} from './contact-person-organization-association.actions';

export function reducer(state = initialState, action: ContactPersonOrganizationAssociationActions): State {
  switch (action.type) {
    case ContactPersonOrganizationAssociationActionTypes.LoadContactPersonOrganizationAssocitions:
      return {
        ...state,
        loading: true
      };
    case ContactPersonOrganizationAssociationActionTypes.LoadContactPersonOrganizationAssociationsSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case ContactPersonOrganizationAssociationActionTypes.AssignContactPerson:
      return {
        ...state,
        loading: false
      };
    case ContactPersonOrganizationAssociationActionTypes.AssignContactPersonSuccess: {
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: false,
      });
    }
    case ContactPersonOrganizationAssociationActionTypes.UnassignContactPersonSuccess: {
      const relId = action.relId;
      const entity = state.entities[relId];
      return adapter.removeOne(entity.id, state)
    }
  }
  return state
}
