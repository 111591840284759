import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './user-avatar.state';

export const stateKey = 'user-signature';
const getUserAvatarState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getUserAvatarEntities,
  selectAll: getAllUserAvatar,
} = adapter.getSelectors(getUserAvatarState);


export const getOne = (id: string) => createSelector(
  getUserAvatarEntities,
  (entities) => entities[id]
);
