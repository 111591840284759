import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TasksStatsEffects } from './tasks-stats.effects';
import { TasksStatsService } from './tasks-stats.service';
import { stateKey } from './tasks-stats.selectors';
import { reducer } from './tasks-stats.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([TasksStatsEffects])
  ],
  providers: [
    TasksStatsEffects,
    TasksStatsService
  ]
})
export class TasksStatsStoreModule {
}
