import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
  name: 'dvtxSafeNumber'
})
export class SafeNumberPipe extends DecimalPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let transformed = value;
    try {
      transformed = super.transform(value, args);
    } catch (err) {
      console.error('SafeNumberPipe: ', err);
    }
    return transformed;
  }

}
