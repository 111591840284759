import {IResource} from './resource.interface';

/**
 * Representation of an line item of a shopping cart
 * or order.
 */
export class LineItem implements IResource {
  readonly type = 'sales_line_items';

  get sum() {
    const quantity = parseFloat(this.quantity);
    const price = parseFloat(this.price);
    return (quantity * price).toFixed(2);
  }

  get priceWithPrecision() {
    const price = parseFloat(this.price);
    return price.toFixed(2);
  }

  constructor(
    public id: number,
    public title: string,
    public subtitle: string,
    public description: string,
    public quantity: string,
    public price: string,
    public createdAt: Date
  ) {}
}
