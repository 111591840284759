import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {ContactOrganization} from '../../models/contact-organization.model';

export enum ContactOrganizationActionTypes {
  LoadOne = '[contactOrganization] Load one',
  LoadOneSuccess = '[contactOrganization] Load one Success',
  LoadOneFail = '[contactOrganization] Load one Fail',
  EditContact = '[contactOrganization] Edit Contact',
  EditContactSuccess = '[contactOrganization] Edit Contact Success',
  EditContactFail = '[contactOrganization] Edit Contact Fail',
  SetPrimaryContact = '[contactOrganization] Set primary contact',
  SetPrimaryContactSuccess = '[contactOrganization] Set primary contact success',
  RemovePrimaryContact = '[contactOrganization] remove primary contact',
  RemovePrimaryContactSuccess = '[contactOrganization] remove primary contact success',
  SetParentOrganization = '[contactOrganization] set parent organization',
  SetParentOrganizationSuccess = '[contactOrganization] set parent organization success',
  RemoveParentOrganization = '[contactOrganization] remove parent organization',
  RemoveParentOrganizationSuccess = '[contactOrganization] remove parent organization success',
}

export class LoadOne implements Action {
  readonly type = ContactOrganizationActionTypes.LoadOne;

  constructor(public contactId: string) {

  }
}

export class LoadOneSuccess implements Action {
  readonly type = ContactOrganizationActionTypes.LoadOneSuccess;

  constructor(public payload: ContactOrganization) {
  }
}

export class LoadOneFail implements Action {
  readonly type = ContactOrganizationActionTypes.LoadOneFail;

  constructor(public payload: HttpErrorResponse) {
  }
}

export class EditContact implements Action {
  readonly type = ContactOrganizationActionTypes.EditContact;

  constructor(public contactToEdit: ContactOrganization) {

  }
}

export class EditContactSuccess implements Action {
  readonly type = ContactOrganizationActionTypes.EditContactSuccess;

  constructor(public payload: ContactOrganization) {
  }
}

export class EditContactFail implements Action {
  readonly type = ContactOrganizationActionTypes.EditContactFail;

  constructor(public payload: HttpErrorResponse) {
  }
}

export class SetPrimaryContact implements Action {
  readonly type = ContactOrganizationActionTypes.SetPrimaryContact;

  constructor(public payload: {
    orgContactId: string,
    primaryContactId: string
  }) {

  }
}

export class SetPrimaryContactSuccess implements Action {
  readonly type = ContactOrganizationActionTypes.SetPrimaryContactSuccess;

  constructor(public payload: {
    orgContactId: string,
    primaryContactId: string
  }) {
  }
}

export class RemovePrimaryContact implements Action {
  readonly type = ContactOrganizationActionTypes.RemovePrimaryContact;

  constructor(public payload: {
    orgContactId: string,
    primaryContactId: string
  }) {
  }
}

export class RemovePrimaryContactSuccess implements Action {
  readonly type = ContactOrganizationActionTypes.RemovePrimaryContactSuccess;

  constructor(public orgContactId: string) {
  }
}

export class SetParentOrganization implements Action {
  readonly type = ContactOrganizationActionTypes.SetParentOrganization;

  constructor(public payload: {
    orgContactId: string,
    parentOrgId: string
  }) {
  }
}

export class SetParentOrganizationSuccess implements Action {
  readonly type = ContactOrganizationActionTypes.SetParentOrganizationSuccess;

  constructor(public payload: {
    orgContactId: string,
    parentOrgId: string
  }) {
  }
}

export class RemoveParentOrganization implements Action {
  readonly type = ContactOrganizationActionTypes.RemoveParentOrganization;

  constructor(public payload: {
    orgContactId: string,
    parentOrgId: string
  }) {
  }
}

export class RemoveParentOrganizationSuccess implements Action {
  readonly type = ContactOrganizationActionTypes.RemoveParentOrganizationSuccess;

  constructor(public orgContactId: string) {
  }
}

export type ContactOrganizationActions =
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | EditContact
  | EditContactSuccess
  | EditContactFail
  | SetPrimaryContact
  | SetPrimaryContactSuccess
  | RemovePrimaryContact
  | RemovePrimaryContactSuccess
  | SetParentOrganization
  | SetParentOrganizationSuccess
  | RemoveParentOrganization
  | RemoveParentOrganizationSuccess
