import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'dvtx-list-choice-select',
  templateUrl: './list-choice-select.component.html',
  styleUrls: ['./list-choice-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListChoiceSelectComponent implements OnInit {
  @Input()
  disabled = false;

  @Input()
  choiceValue = '';

  @Input()
  choiceList = [];

  @Input()
  editable = false;

  @Output()
  onSelectChange: EventEmitter<any> = new EventEmitter<any>(null);

  @Output()
  onChangeRequest: EventEmitter<any> = new EventEmitter<any>(null);

  constructor() {
  }

  ngOnInit(): void {
  }

  public onSelect(content) {
    if (this.onSelect) {
      this.onSelectChange.emit(content);
    }
  }

  // Used to display the text in the input field.
  public displayFn(item): string {
    return item;
  }
}
