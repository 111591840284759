import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {ContactPersonBaseForm, newContactBaseForm} from '../../../../../organization/models/person-contact-base-form';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {AppState} from '../../../../../../reducers';
import {Store} from '@ngrx/store';
import {ContactVisibility, visibilityTypes} from '../../../../../../models/contact-list-dto.model';
import {NewContact} from '../../../../../../+store/contact/contact.actions';
import {first} from 'rxjs/operators';
import {Organization} from '../../../../../../models/organization.model';
import {getSelected} from '../../../../../../+store/organization/organization.selectors';
import {InviteService} from '../../../../../organization/components/organization-view/invite.service';
import {LoadOfOrganization} from 'app/+store/invitation/invitation.actions';
import {PartnerLinkParticipationActions} from 'app/+store/partner-link-participation';

@Component({
  selector: 'dvtx-create-contact-dialog',
  templateUrl: './create-contact-dialog.component.html',
  styleUrls: ['./create-contact-dialog.component.scss']
})
export class CreateContactDialogComponent implements OnInit {
  types = [
    {label: 'Person', value: contactTypes.naturalPerson, icon: 'person', tooltip: 'Kontaktperson anlegen.'}
  ];
  color = '#007aff';
  contactType: contactTypes = contactTypes.organization;
  contactTypes = contactTypes;
  contactVisibility: ContactVisibility = {visibleFor: '', type: visibilityTypes.visiblePublic};
  lockContactType = false;

  orgaTypeId: string = '';

  createContactForm: ContactPersonBaseForm = newContactBaseForm();

  // TODO: Reset the form after a contact is created.
  furtherContact: boolean = false;

  public hideInviteButton = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mdr: MatDialogRef<CreateContactDialogComponent>,
    private store: Store<AppState>,
    private inviteService: InviteService,
    private _ngZone: NgZone
  ) {
    if (data && data.options && data.options.contactType === 'contact_people') {
      this.contactType = contactTypes.naturalPerson;
    }

    if (data && data.options && data.options.contactType === 'contact_people' && data.contactPerson) {
      this.createContactForm.contactPerson = data.contactPerson;
    }

    if (data && data.options && data.options.hideInviteButton) {
      this.hideInviteButton = data.options.hideInviteButton;
    }

    if (data && data.options && data.options.lockContactType) {
      this.lockContactType = data.options.lockContactType;
    }
    if (data && data.color) {
      this.color = data.color;
    }
  }

  ngOnInit() {
  }

  createContact(shouldInvite = false) {
    this.store.select(getSelected).pipe(
      first()
    ).subscribe((orga: Organization) => {
        // Person Contact creation
        const email = this.createContactForm.contactPerson.mainEmailAddress.emailAddress;
        this.store.dispatch(new NewContact(orga, this.createContactForm, this.contactVisibility));

        const createPartnerLinkAccess = this.createContactForm.contactPerson.activatePartnerLinks && email;
        const sanitizedEmail = email.toLowerCase();

        if (shouldInvite) {
          this.inviteService.inviteBusinessPartner(email).subscribe(() => {
            setTimeout(_ => {
              this._ngZone.runOutsideAngular(_ => {
                if (createPartnerLinkAccess) {
                  this.store.dispatch(new PartnerLinkParticipationActions.Create(sanitizedEmail));
                }
                this.store.dispatch(new LoadOfOrganization(orga.id));
              });
            });
          }, err => {
            console.error(err);
            this.store.dispatch(new LoadOfOrganization(orga.id));
          });
        } else if (createPartnerLinkAccess) {
          this.store.dispatch(new PartnerLinkParticipationActions.Create(sanitizedEmail));
          this.store.dispatch(new LoadOfOrganization(orga.id));
        }

        if (!this.furtherContact) {
          this.closeDialog();
        }
    });
  }

  closeDialog(result?) {
    this.mdr.close(result);
  }

}


enum contactTypes {
  naturalPerson,
  organization
}
