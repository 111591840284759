import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {DavContact} from './dav-contact';

export class DavContactBuilder implements IApiResourceBuilder<DavContact> {
  fromResponse(data): DavContact {
    return new DavContact(data.id, data.attributes.email, data.attributes.name, data.attributes.first_name, data.attributes.last_name);
  }

  toRequest(_: DavContact) {
    return null;
  }
}
