/**
 * Notification module for local and global message handling.
 *
 * Author: Andreas Baier <andreas.baier@paperbird.org>
 */
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ApiModule } from '../api/api.module';
import { BaseFormElementsModule } from '../base-form-elements/base-form-elements.module';
import { SnackbarMessageContentComponent } from './components/snackbar-message-content/snackbar-message-content.component';
import { SnackbarMessageComponent } from './containers/snackbar-message/snackbar-message.component';
import { NotificationService } from './services/notification.service';

@NgModule({
    imports: [
        MatSnackBarModule,
        ApiModule,
        BaseFormElementsModule
    ],
    declarations: [
        SnackbarMessageComponent,
        SnackbarMessageContentComponent
    ],
    exports: [
        MatSnackBarModule
    ],
    providers: [
        NotificationService
    ]
})
export class NotificationModule { }
