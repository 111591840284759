import {Action} from '@ngrx/store';
import {Milestone} from './milestone';

export enum MilestoneActionTypes {
  LoadAll = '[Milestone] Load All Milestones',
  LoadAllSuccess = '[Milestone] Load All Milestones Success',
  LoadAllFail = '[Milestone] Load All Milestones Fail'
}

export class LoadAll implements Action {
  readonly type = MilestoneActionTypes.LoadAll;

  constructor(public id: string = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = MilestoneActionTypes.LoadAllSuccess;

  constructor(public payload: Milestone[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = MilestoneActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export type MilestoneActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail;
