import {Action} from '@ngrx/store';
import * as model from './user';
import * as modelInterface from './user.interface';

export enum OperatorUserActionTypes {
  Create = '[Operator.User] Create',
  CreateSuccess = '[Operator.User] Create Success',
  CreateFail = '[Operator.User] Create Fail',
  Remove = '[Operator.User] Remove',
  RemoveSuccess = '[Operator.User] Remove Success',
  RemoveFail = '[Operator.User] Remove Fail',
  Save = '[Operator.User] Save',
  SaveSuccess = '[Operator.User] Save Success',
  SaveFail = '[Operator.User] Save Fail',
  Status = '[Operator.User] Status',
  LoadAll = '[Operator.User] Load All',
  LoadAllSuccess = '[Operator.User] Load All Success',
  LoadAllFail = '[Operator.User] Load All Fail',
  LoadOne = '[Operator.User] Load One',
  LoadOneSuccess = '[Operator.User] Load One Success',
  LoadOneFail = '[Operator.User] Load One Fail'
}

export class Create implements Action {
  readonly type = OperatorUserActionTypes.Create;

  constructor(public params: modelInterface.Operator.IUserParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = OperatorUserActionTypes.CreateSuccess;

  constructor(public user: model.Operator.User) {
  }
}

export class CreateFail implements Action {
  readonly type = OperatorUserActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = OperatorUserActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = OperatorUserActionTypes.RemoveSuccess;

  constructor(public user: model.Operator.User) {
  }
}

export class RemoveFail implements Action {
  readonly type = OperatorUserActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = OperatorUserActionTypes.Save;

  constructor(public user: model.Operator.User) {
  }
}

export class SaveSuccess implements Action {
  readonly type = OperatorUserActionTypes.SaveSuccess;

  constructor(public user: model.Operator.User) {
  }
}

export class SaveFail implements Action {
  readonly type = OperatorUserActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = OperatorUserActionTypes.LoadAll;

  constructor(public params: modelInterface.Operator.IUserGetAllParams) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = OperatorUserActionTypes.LoadAllSuccess;

  constructor(public users: model.Operator.User[], public rawUsers: model.Operator.User[] = [], public reset = false) {
  }
}

export class LoadAllFail implements Action {
  readonly type = OperatorUserActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = OperatorUserActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = OperatorUserActionTypes.LoadOneSuccess;

  constructor(public user: model.Operator.User) {
  }
}

export class LoadOneFail implements Action {
  readonly type = OperatorUserActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type OperatorUserActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
