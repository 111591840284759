import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './process-action.state';
import {ProcessSelectors} from '../process';

export const stateKey = 'process-action';
const getProcessActionState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getProcessActionEntities,
  selectAll: getAllProcessActions,
} = adapter.getSelectors(getProcessActionState);

export const getLoadingState = createSelector(
  getProcessActionState,
  state => state.loading
);

export const getProcessActionsOfProcess = (id: string) => createSelector(
  getAllProcessActions,
  actions => actions.filter(action => action.processId === id)
);

export const getProcessCloseActionsEvent = (id: string) => createSelector(
  getAllProcessActions,
  actions => actions.filter(action => action.processId === id && action.command.type === 'close_process')
);

export const getProcessActionsOfSelectedProcess = createSelector(
  getAllProcessActions,
  ProcessSelectors.getSelectedProcess,
  (actions, process) => {
    if (process) {
      return actions.filter(a => a.processId === process.id)
    }
    return [];
  });
