import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {Invitation, InvitationType} from 'app/+store/invitation/invitation.model';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {InvitationBuilder} from './invitation.builder';

@Injectable()
export class MembershipInvitationService {
  readonly BASE_PATH = 'api/v1/organizations/membership_invitations';
  basePath;
  builder: InvitationBuilder;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
    this.builder = new InvitationBuilder(InvitationType.Member);
  }

  getAll(): Observable<Invitation[]> {
    return this._http.get(this.basePath).pipe(map((response: { data: any }) => response.data),
      map(data => data.map(item => this.builder.fromResponse(item))),);
  }

  accept(id): Observable<Invitation> {
    const payload = { data: { attributes: { accepted: true } } };
    return this._http.post(`${this.basePath}/${id}/accept`, payload).pipe(
      map((response: { data: any }) => response.data),
      map(item => this.builder.fromResponse(item)),);
  }

  decline(id): Observable<Invitation> {
    const payload = { data: { attributes: { declined: true } } };
    return this._http.post(`${this.basePath}/${id}/decline`, payload).pipe(
      map((response: { data: any }) => response.data),
      map(item => this.builder.fromResponse(item)),);
  }

  create(email): Observable<Invitation> {
    const payload = { data: { attributes: { email: email } } };
    return this._http.post(`${this.basePath}`, payload).pipe(
      map((response: { data: any }) => response.data),
      map(item => this.builder.fromResponse(item)),);
  }

  destroy(id): Observable<Invitation> {
    return this._http.delete(`${this.basePath}/${id}`).pipe(
      map((response: { data: any }) => response.data),
      map(item => this.builder.fromResponse(item)),);
  }
}
