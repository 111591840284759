import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {
  TwoFactorVerificationModule
} from '../../modules/two-factor-auth/modules/two-factor-verification/two-factor-verification.module';
import {PageSidebarModule} from '../../shared/modules/page-sidebar/page-sidebar.module';
import {PageHeaderModule} from '../../shared/modules/page-header/page-header.module';
import {MainAppComponent} from './containers/main-app/main-app.component';
import {MainExternalComponent} from './containers/main-external/main-external.component';
import {MainSessionComponent} from './containers/main-session/main-session.component';
import {QuickstartModule} from '../quickstart/quickstart.module';
import {InfoBlockModule} from "../../five-f/info-block/info-block.module";


@NgModule({
  declarations: [
    MainAppComponent,
    MainExternalComponent,
    MainSessionComponent
  ],
    imports: [
        SharedModule,
        PageHeaderModule,
        PageSidebarModule,
        TwoFactorVerificationModule,
        QuickstartModule,
        InfoBlockModule
    ],
  exports: [
    MainAppComponent,
    MainExternalComponent,
    MainSessionComponent
  ]
})
export class MainAppModule {
}
