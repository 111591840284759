import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './comment.state';

export const stateKey = 'comment';
const getCommentState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getCommentEntities,
  selectAll: getAllComments,
} = adapter.getSelectors(getCommentState);

export const getLoadingState = createSelector(
  getCommentState,
  state => state.loading
);
export const getCreatingState = createSelector(
  getCommentState,
  state => state.creating
);

export const getState = createSelector(
  getCommentState,
  state => state
);

export const getUpdatingState = createSelector(
  getCommentState,
  state => state.updating
);

export const getCommentByBacktrackId = (backtrackID: string) => createSelector(
  getAllComments,
  (comments) => {
    if (!backtrackID) {
      return [];
    }
    return comments.filter(c => c.backtrackId === backtrackID)
  }
);

export const getCommentStatsByProcessId = (processId: string) => createSelector(
  getCommentState,
  (state) => state.stats[processId] || {}
);

export const getCommentCountByNodeId = (processId: string, nodeId: string) => createSelector(
  getCommentStatsByProcessId(processId),
  (stats) => stats[nodeId] || 0
);

export const getCommentCountByBacktrackId = (backtrackID: string) => createSelector(
  getCommentByBacktrackId(backtrackID),
  comments => comments.length
);


export const getCommentsProcessId = (processId: string) => createSelector(
  getAllComments,
  comments => comments.filter(c => c.processId && c.processId === processId)
);

export const getCommentCountByProcessId = (processId: string) => createSelector(
  getCommentsProcessId(processId),
  comments => comments.length
);
