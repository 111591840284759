import {Action} from '@ngrx/store';
import {PartnerLink} from './partner-link';

export enum PartnerLinkActionTypes {
  Create = '[PartnerLink] Create',
  CreateSuccess = '[PartnerLink] Create Success',
  CreateFail = '[PartnerLink] Create Fail',
  Remove = '[PartnerLink] Remove',
  RemoveSuccess = '[PartnerLink] Remove Success',
  RemoveFail = '[PartnerLink] Remove Fail',
  Save = '[PartnerLink] Save',
  SaveSuccess = '[PartnerLink] Save Success',
  SaveFail = '[PartnerLink] Save Fail',
  SaveSilent = '[PartnerLink] SaveSilent',
  SaveSilentSuccess = '[PartnerLink] SaveSilent Success',
  SaveSilentFail = '[PartnerLink] SaveSilent Fail',
  LoadAll = '[PartnerLink] Load All',
  LoadAllSuccess = '[PartnerLink] Load All Success',
  LoadAllFail = '[PartnerLink] Load All Fail',
  LoadOne = '[PartnerLink] Load One',
  LoadOneSuccess = '[PartnerLink] Load One Success',
  LoadOneFail = '[PartnerLink] Load One Fail',
  MarkVisible = '[PartnerLink] MarkVisible',
  MarkVisibleSuccess = '[PartnerLink] MarkVisible Success',
  MarkVisibleFail = '[PartnerLink] MarkVisible Fail',
  MarkHidden = '[PartnerLink] MarkHidden',
  MarkHiddenSuccess = '[PartnerLink] MarkHidden Success',
  MarkHiddenFail = '[PartnerLink] MarkHidden Fail',
}

export class Create implements Action {
  readonly type = PartnerLinkActionTypes.Create;

  constructor(public partnerLink: PartnerLink) {
  }
}

export class CreateSuccess implements Action {
  readonly type = PartnerLinkActionTypes.CreateSuccess;

  constructor(public partnerLink: PartnerLink) {
  }
}

export class CreateFail implements Action {
  readonly type = PartnerLinkActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = PartnerLinkActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = PartnerLinkActionTypes.RemoveSuccess;

  constructor(public partnerLink: PartnerLink) {
  }
}

export class RemoveFail implements Action {
  readonly type = PartnerLinkActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = PartnerLinkActionTypes.Save;

  constructor(public partnerLink: PartnerLink) {
  }
}

export class SaveSuccess implements Action {
  readonly type = PartnerLinkActionTypes.SaveSuccess;

  constructor(public partnerLink: PartnerLink) {
  }
}

export class SaveFail implements Action {
  readonly type = PartnerLinkActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class SaveSilent implements Action {
  readonly type = PartnerLinkActionTypes.SaveSilent;

  constructor(public partnerLink: PartnerLink) {
  }
}

export class SaveSilentSuccess implements Action {
  readonly type = PartnerLinkActionTypes.SaveSilentSuccess;

  constructor(public partnerLink: PartnerLink) {
  }
}

export class SaveSilentFail implements Action {
  readonly type = PartnerLinkActionTypes.SaveSilentFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = PartnerLinkActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = PartnerLinkActionTypes.LoadAllSuccess;

  constructor(public partnerLinks: PartnerLink[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = PartnerLinkActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = PartnerLinkActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = PartnerLinkActionTypes.LoadOneSuccess;

  constructor(public partnerLink: PartnerLink) {
  }
}

export class LoadOneFail implements Action {
  readonly type = PartnerLinkActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class MarkVisible implements Action {
  readonly type = PartnerLinkActionTypes.MarkVisible;

  constructor(public partnerLink: PartnerLink) {
  }
}

export class MarkVisibleSuccess implements Action {
  readonly type = PartnerLinkActionTypes.MarkVisibleSuccess;

  constructor(public partnerLink: PartnerLink) {
  }
}

export class MarkVisibleFail implements Action {
  readonly type = PartnerLinkActionTypes.MarkVisibleFail;

  constructor(public payload: any) {
  }
}

export class MarkHidden implements Action {
  readonly type = PartnerLinkActionTypes.MarkHidden;

  constructor(public partnerLink: PartnerLink) {
  }
}

export class MarkHiddenSuccess implements Action {
  readonly type = PartnerLinkActionTypes.MarkHiddenSuccess;

  constructor(public partnerLink: PartnerLink) {
  }
}

export class MarkHiddenFail implements Action {
  readonly type = PartnerLinkActionTypes.MarkHiddenFail;

  constructor(public payload: any) {
  }
}

export type PartnerLinkActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail
  | SaveSilent
  | SaveSilentSuccess
  | SaveSilentFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | MarkVisible
  | MarkVisibleSuccess
  | MarkVisibleFail
  | MarkHidden
  | MarkHiddenSuccess
  | MarkHiddenFail;
