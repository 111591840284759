<ng-template cdkPortal #twoFaAuthTitleContext>
  <h2 mat-dialog-title>{{ 'AUTH.TWO_FACTOR_DIALOG_TITLE' | translate }}</h2>
  <button class="mat-dialog-close" mat-mini-fab color="warn" (click)="close()">
    <mat-icon>clear</mat-icon>
  </button>
</ng-template>

<ng-template cdkPortal #twoFaAuthContext>
  <div mat-dialog-content>
    <dvtx-two-factor-verification [form]="form"
                                  [error]="error$ | async"></dvtx-two-factor-verification>
  </div>
</ng-template>

<ng-template cdkPortal #twoFaAuthButtonToolbar>
  <div class="d-flex justify-content-end">
    <div class="mr-2">
      <button mat-button (click)="cancel()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
    </div>
    <div>
      <button mat-raised-button color="primary"
              (click)="confirm()" [disabled]="submitOngoing || form.invalid || form.pristine">
        {{ 'GENERAL.CONFIRM_ACTION' | translate }}
      </button>
    </div>
  </div>
</ng-template>
