<mat-card>
  <mat-card-content class="p-0">
    <div mat-dialog-content class="p-0">
      <h3 class="mb-2 status-color-danger font-italic font-weight-light">{{'LICENCES.CONTACT_ADMIN_TITLE' | translate}}</h3>
      <h2 class="font-weight-light" [innerHTML]="'LICENCES.CONTACT_ADMIN_DESCRIPTION' | translate"></h2>
    </div>
  </mat-card-content>

  <mat-card-content class="p-0" *ngIf="data.allowComment">
    <div mat-dialog-content class="p-0">
      <mat-form-field>
        <textarea matInput matTextareaAutosize [placeholder]="'LICENCES.CONTACT_ADMIN_INPUT_TITLE' | translate"></textarea>
      </mat-form-field>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <div class="d-flex justify-content-center">
      <button mat-raised-button color="primary" (click)="onSubmitClick()">{{ submitButtonTitle | translate }}</button>
    </div>
  </mat-card-actions>
</mat-card>
