import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BookmanServiceAccountEffects } from './bookman-service-account.effects';
import { BookmanServiceAccountService } from './bookman-service-account.service';
import { reducer } from './bookman-service-account.reducer';
import { stateKey } from './bookman-service-account.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([BookmanServiceAccountEffects])
  ],
  providers: [
    BookmanServiceAccountEffects,
    BookmanServiceAccountService
  ]
})
export class BookmanServiceAccountStoreModule {
}
