import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {ConfirmDialogV2Component} from './components/confirm-dialog/confirm-dialog-v2.component';
import {MatButtonModule} from '@angular/material/button';
import {WaitingDialogComponent} from './components/waiting-dialog/waiting-dialog.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
    declarations: [
        ConfirmDialogV2Component,
        WaitingDialogComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressBarModule
    ],
    exports: [
        ConfirmDialogV2Component
    ]
})
export class ConfirmDialogModule { }
