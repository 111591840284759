import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {DmsFolder} from './dms-folder';
import {DmsFolderBuilder} from './dms-folder.builder';
import {DmsAccountType} from './dms-folder.interface';

@Injectable()
export class DmsFolderService {
  readonly BASE_PATH = 'api/v1/folders';

  constructor(private _http: ApiResourceService) {}

  /**
   *
   * @param accountType: DmsAccountType
   * @param loadAll
   * @param fileContext Run call for later fetched documents not folders.
   *                    This option returns the private DMS folders nevetheless private DMS is disabled on
   *                    workflow creation.
   */
  getAll(accountType: DmsAccountType, loadAll = false, fileContext = false): Observable<DmsFolder[]> {
    const builder = new DmsFolderBuilder(accountType);
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    const loadAllParam = accountType === DmsAccountType.Organization && loadAll ? `${param}&load_all=true` : param;
    return <Observable<DmsFolder[]>>this._http.get<DmsFolderBuilder, DmsFolder>(builder, `${this.BASE_PATH}?account_type=${loadAllParam}&file_context=${fileContext}`);
  }

  getShared(sid: string, accountType: DmsAccountType): Observable<DmsFolder[]> {
    const builder = new DmsFolderBuilder(accountType);
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<DmsFolder[]>>this._http.get<DmsFolderBuilder, DmsFolder>(builder, `${this.BASE_PATH}?account_type=${param}&sid=${sid}`);
  }

  getOne(id: string, accountType: DmsAccountType = DmsAccountType.Private): Observable<DmsFolder> {
    const builder = new DmsFolderBuilder(accountType);
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<DmsFolder>>this._http.get<DmsFolderBuilder, DmsFolder>(builder, `${this.BASE_PATH}/${id}?account_type=${param}`);
  }

  details(id: string, accountType: DmsAccountType = DmsAccountType.Private): Observable<DmsFolder> {
    const builder = new DmsFolderBuilder(accountType);
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<DmsFolder>>this._http.get<DmsFolderBuilder, DmsFolder>(builder, `${this.BASE_PATH}/${id}/details?account_type=${param}`);
  }

  chmod(id: string, sid: string, read: boolean, write: boolean, accountType: DmsAccountType = DmsAccountType.Private): Observable<DmsFolder> {
    const builder = new DmsFolderBuilder(accountType);
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    const payload = {
      data: {
        attributes: {
          sid: sid,
          read: read,
          write: write
        }
      }
    };
    return <Observable<DmsFolder>>this._http.post<DmsFolderBuilder, DmsFolder>(builder, `${this.BASE_PATH}/${id}/chmod?account_type=${param}`, payload);
  }

  getProcessFolder(id: string, accountType): Observable<DmsFolder> {
    const builder = new DmsFolderBuilder(accountType);
    return <Observable<DmsFolder>>this._http.get<DmsFolderBuilder, DmsFolder>(builder, `api/v1/workflow_engine/processes/${id}/folder`);
  }

  create(name, parentId?, accountType = DmsAccountType.Private) {
    const body = { data: { attributes: { name: name } } };
    if (parentId) {
      body['data']['attributes']['parent_id'] = parentId;
    }
    const builder = new DmsFolderBuilder(accountType);
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<DmsFolder>>this._http.post<DmsFolderBuilder, DmsFolder>(builder, `${this.BASE_PATH}?account_type=${param}`, body);
  }

  moveFolder(sourceId: string, targetId: string) {
    const builder = new DmsFolderBuilder(DmsAccountType.Organization);
    const body = {
      data: {
        attributes: {
          target_folder_id: targetId
        }
      }
    }
    return <Observable<DmsFolder>>this._http.post<DmsFolderBuilder, DmsFolder>(builder, `${this.BASE_PATH}/${sourceId}/move`, body);
  }

  rename(id: string, name: string, accountType = DmsAccountType.Private) {
    const body = { data: { attributes: { name: name } } };
    const builder = new DmsFolderBuilder(accountType);
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<DmsFolder>>this._http.post<DmsFolderBuilder, DmsFolder>(builder, `${this.BASE_PATH}/${id}/rename?account_type=${param}`, body);
  }

  lockFolder(id: string, lockUntil: string, accountType = DmsAccountType.Organization) {
    const body = { data: { attributes: { lock_until: lockUntil } } };
    const builder = new DmsFolderBuilder(accountType);
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<DmsFolder>>this._http.post<DmsFolderBuilder, DmsFolder>(builder, `${this.BASE_PATH}/${id}/lock?account_type=${param}`, body);
  }

  delete(id, accountType = DmsAccountType.Private) {
    const builder = new DmsFolderBuilder(accountType);
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<DmsFolder>>this._http.del<DmsFolderBuilder, DmsFolder>(builder, `${this.BASE_PATH}/${id}?account_type=${param}`);
  }

  // TODO: Creation and Deletion of folders. Taken from old Folder service
  // create(name, parentId?) {
  //   const body = { data: { attributes: { name: name } } };
  //   if (parentId) {
  //     body['data']['attributes']['parent_id'] = parentId;
  //   }
  //   return this._http
  //     .post(`${this.basePath}`, body)
  //     .map((res: any) => createFolder(res.data))
  //     .catch(this.handleError);
  // }
  //
  // delete(id) {
  //   return this._http
  //     .delete(`${this.basePath}/${id}`)
  //     .map((res: any) => createFolder(res.data))
  //     .catch(this.handleError);
  // }
}
