
import {combineLatest as observableCombineLatest, of, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {visibilityTypes} from '../../models/contact-list-dto.model';
import {getSelected} from '../organization/organization.selectors';
import {Organization} from '../../models/organization.model';
import {NewContact} from '../contact/contact.actions';
import {LoadOfOrganization} from '../invitation/invitation.actions';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import {catchError, first, map} from 'rxjs/operators';
import {InviteService} from '../../modules/organization/components/organization-view/invite.service';
import {ContactPersonBaseForm, newContactBaseForm} from '../../modules/organization/models/person-contact-base-form';
import * as organizationFormUtil from '../../modules/organization/models/contact-organization-base-form';
import {SimplePhoneNumber} from '../../modules/contacts/models/contact.interface';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ContactOrganizationBaseForm} from '../../modules/organization/models/contact-organization-base-form';
import {ContactItemResponse} from '../../models/contact-item';
import {CppApiService} from '../../services/cpp-api.service';
import {Client} from '../client/client';
import {ClientContactType} from '../client/client.interface';
import {OrganizationSelectors} from '../organization';
import {ContactSelectors} from '../contact';
import {environment} from 'environments/environment';

export interface IBasicContact {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  mobile?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContactPersonFactoryService {
  createContactForm: ContactPersonBaseForm = newContactBaseForm();

  constructor(private _store: Store<AppState>,
              private inviteService: InviteService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService,
              private _cppApiService: CppApiService) {
  }

  create(contact: IBasicContact,
         invite = false,
         contactVisibility = {
           visibleFor: '',
           type: visibilityTypes.visiblePublic
         }) {
    this.createContactForm = newContactBaseForm();

    this.createContactForm.contactPerson.firstName = contact.firstName;
    this.createContactForm.contactPerson.lastName = contact.lastName;
    this.createContactForm.contactPerson.mainEmailAddress.emailAddress = contact.email;
    this.createContactForm.contactPerson.mainPhoneNumber.phoneNumber = contact.mobile;
    if (contact.phone) {
      const phoneNumber = new SimplePhoneNumber();
      phoneNumber.phoneNumber = contact.phone;
      this.createContactForm.contactPerson.phoneNumbers.push(phoneNumber);
    }

    this._store.select(getSelected)
      .pipe(first())
      .subscribe((orga: Organization) => {
        this._store.dispatch(new NewContact(orga, this.createContactForm, contactVisibility));
          this._notifyService.success('CONTACTS.CONTACT_TRANSFERED_TO_ADDRESSBOOK');
        if (invite) {
          this.inviteAsBusinessPartner(orga, this.createContactForm.contactPerson.mainEmailAddress.emailAddress);
        }
      });
  }

  addVerifiedContactToAddressBook(contact: IBasicContact, invite = true) {
    const organization$ = this._store.select(OrganizationSelectors.getSelected);
    const contactExist$ = this._store.select(ContactSelectors.contactExists(contact.email));
    const isVerified$ = this._store.select(ContactSelectors.isVerified(contact.email));
    observableCombineLatest(organization$, contactExist$, isVerified$)
      .pipe(first())
      .subscribe(([organization, exists, verified]) => {
        if (!environment.production) {
          console.log('DEBUG: ContactPersonFactoryService#addVerifiedContactToAddressBook: ', organization, exists, verified);
        }

        if (!organization) return;

        if (!exists) {
          this.create(contact, true);
          return;
        } else {
            this._notifyService.error('ADDRESSBOOK.ENTRY_WITH_EMAIL_EXISTS');
        }

        if (!verified) {
          this.inviteAsBusinessPartner(organization, contact.email);
        }
      }, err => {
        console.error(err);
      });
  }

  inviteAsBusinessPartner(orga, email, disableTrial = true) {
    this.inviteService.inviteBusinessPartner(email, disableTrial)
      .pipe(first())
      .subscribe(() => {
        this._store.dispatch(new LoadOfOrganization(orga.id));
      }, (err) => console.error(err));
  }

  createFromClient(org, client: Client,
          contactVisibility = {
           visibleFor: '',
           type: visibilityTypes.visiblePublic
         }) {
    let createContactForm;
    if (client.contactType === ClientContactType.Organization) {
      createContactForm = organizationFormUtil.newOrganizationBaseForm();
      createContactForm.contactOrganization.legalFormId = '6';
      createContactForm.contactOrganization.name = client.name;
      createContactForm.contactOrganization.mainEmailAddress.emailAddress = client.email;
      createContactForm.contactOrganization.mainPhoneNumber.phoneNumber = client.phone;
      if (client.phone) {
        const phoneNumber = new SimplePhoneNumber();
        phoneNumber.phoneNumber = client.phone;
        createContactForm.contactOrganization.phoneNumbers.push(phoneNumber);
      }
    } else {
      createContactForm = newContactBaseForm();
      createContactForm.contactPerson.firstName = client.firstName;
      createContactForm.contactPerson.lastName = client.lastName;
      createContactForm.contactPerson.mainEmailAddress.emailAddress = client.email;
      createContactForm.contactPerson.mainPhoneNumber.phoneNumber = client.phone;
      if (client.phone) {
        const phoneNumber = new SimplePhoneNumber();
        phoneNumber.phoneNumber = client.phone;
        createContactForm.contactPerson.phoneNumbers.push(phoneNumber);
      }
    }

    return this._createContact(org, createContactForm, contactVisibility);
  }

  _createContact(organization, contactToCreate, visibility) {
    let endpoint: string;
    let body: any;

    if (contactToCreate.discriminator === 'ContactOrganizationBaseForm') {
      endpoint = 'organizationcontacts';
      const contact = <ContactOrganizationBaseForm>contactToCreate;

      body = {
        name: contact.contactOrganization.name,
        email: contact.contactOrganization.mainEmailAddress.toCppRequest(),
        address: contact.contactOrganization.mainAddress.toCppRequest(),
        phone: contact.contactOrganization.mainPhoneNumber.toCppRequest(),
        legal_form_id: String(contact.contactOrganization.legalFormId),
        contact_visibility: visibility.type,
        visible_for_id: visibility.visibleFor,
        organization_type_id: String(contact.contactOrganization.organization_type_id) || '',
      }

    } else if (contactToCreate.discriminator === 'ContactPersonBaseForm') {
      endpoint = 'personcontacts';
      const contact = <ContactPersonBaseForm>contactToCreate;

      body = {
        natural_person_profile: {
          first_name: contact.contactPerson.firstName,
          last_name: contact.contactPerson.lastName,
          title_id: String(contact.contactPerson.title),
        },
        email: contact.contactPerson.mainEmailAddress.toCppRequest(),
        address: contact.contactPerson.mainAddress.toCppRequest(),
        phone: contact.contactPerson.mainPhoneNumber.toCppRequest(),
        contact_visibility: visibility.type,
        visible_for_id: visibility.visibleFor
      }
    }
    return this._cppApiService.post<ContactItemResponse>('organization/' + organization.id + '/addressbook/' + organization.addressbookId + '/' + endpoint, body)
      .pipe(map((res: ContactItemResponse) => res),
            catchError((err) => of(null))
    )
  }
}
