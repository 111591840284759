import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import * as api from 'app/+store/iam/process-policy.interface';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs/internal/Observable';

/**
 * Namespace for Identity and access management (IAM).
 * NOTE: The main source is always the backend, not the frontend implementation.
 */
export namespace Iam {
  /**
   * The authorization context is responsible to retrieve the correct and contextualInstance
   * permission manager. It must be specialized according all contextual values needed to
   * authorize an object, e.g. user, organization, process.
   */
  export abstract class AuthorizationContext implements api.Iam.IAuthorizationContext {
    public constructor() {}

    /**
     * Returns the (specialized) PermissionManager related to this context.
     */
    public abstract getAuthorizationManager(
      parent: api.Iam.IPermissionManager
    ): api.Iam.IPermissionManager;
  }

  /**
   * Minimal user context.
   */
  export class UserContext extends AuthorizationContext {
    public constructor(public email: string) {
      super();
    }

    getAuthorizationManager(
      parent: api.Iam.IPermissionManager
    ): UserPermissionManager {
      return UserPermissionManager.getInstance(parent, this);
    }
  }

  /**
   * Minimal user context.
   */
  export class ProcessContext extends AuthorizationContext {
    public constructor(public email: string, public pid: string) {
      super();
    }

    getAuthorizationManager(
      parent: api.Iam.IPermissionManager
    ): ProcessPermissionManager {
      return ProcessPermissionManager.getInstance(parent, this);
    }
  }

  /**
   * The base permission manager.
   * This class implements the singleton pattern.
   * As such the constructor is protected for decorating children.
   *
   * The PermissionManager returns specialized permission managers as
   * Decorators by authorization contexts given to getInstance.
   */
  export class PermissionManager implements api.Iam.IPermissionManager {
    private static instance: PermissionManager;

    // Current authorization/permission 'world' knowledge.
    private currentPolicyState = new BehaviorSubject<api.Iam.IBasePolicy>(
      api.Iam.DEFAULT_POLICY
    );

    public readonly policy$ = this.currentPolicyState.asObservable();

    /**
     * Returns the current state as immutable copy.
     */
    public get currentPolicy() {
      return Object.freeze(Object.assign({}, this.currentPolicyState.value));
    }

    protected set currentPolicy(newState) {
      const previousState = this.currentPolicy;
      const state = Object.assign({}, previousState, newState);
      this.currentPolicyState.next(state);
    }

    // Singleton: Hide the contructor. Use getInstance to get the current manager.
    private constructor() {}

    static getInstance(
      context: AuthorizationContext = null
    ): api.Iam.IPermissionManager {
      // Note this is referencing the Class itself! Here same as PermissionManager.instance
      if (!this.instance) {
        this.instance = new this();
      }

      if (context) {
        const parent = this.instance;
        return context.getAuthorizationManager(parent);
      }

      return this.instance;
    }

    public reset(): void {
      const state = Object.assign({}, api.Iam.DEFAULT_POLICY);
      this.currentPolicyState.next(state);
    }

    static reset() {
      this.instance.reset();
    }

    dispatch(newPolicy: api.Iam.IBasePolicy): void {
      this.currentPolicy = newPolicy;
    }

    load(newPolicy: api.Iam.IBasePolicy): Observable<any> {
      this.currentPolicy = newPolicy;
      return of(newPolicy);
    }
  }

  /**
   * Specialized user permission manager.
   * Each user identified by email has one singleton permission manager,
   * that can be retrieved by according context objects.
   */
  export class UserPermissionManager implements api.Iam.IPermissionManager {
    public readonly policy$;

    // Instance permission policy, overriding/extending the base policy.
    private userPermissionState: BehaviorSubject<api.Iam.IUserPolicy>;

    // Instance dictionary: Each user has one singleton permission manager.
    private static contextualInstances: {
      [email: string]: UserPermissionManager;
    } = {};

    private _currentPolicy: api.Iam.IUserPolicy;
    /**
     * Current policy snapshot;
     */
    public get currentPolicy(): api.Iam.IUserPolicy {
      return Object.freeze(Object.assign({}, this._currentPolicy));
    }

    private constructor(
      private parent: api.Iam.IPermissionManager,
      private context: UserContext
    ) {
      this._currentPolicy = parent.currentPolicy;
      this.userPermissionState = new BehaviorSubject<api.Iam.IUserPolicy>(
        api.Iam.DEFAULT_USER_POLICY
      );

      // Merge the policy with the main policy.
      // User permissions override global permissions.
      this.policy$ = combineLatest(
        parent.policy$,
        this.userPermissionState
      ).pipe(
        map(([parentPolicy, userPolicy]) => {
          const newPolicy = Object.assign({}, parentPolicy, userPolicy);
          this._currentPolicy = newPolicy;
          return newPolicy;
        })
      );
    }

    // Returns only one instance (Singleton) per user (email)
    static getInstance(
      parent: api.Iam.IPermissionManager,
      context: UserContext
    ): UserPermissionManager {
      if (!context) {
        throw new Error('User context is missing.');
      }

      if (!parent) {
        throw new Error('Parent reference is missing.');
      }

      if (!this.contextualInstances[context.email]) {
        this.contextualInstances[context.email] = new this(parent, context);
      }

      return this.contextualInstances[context.email];
    }

    /**
     * Resets local state and parent state.
     */
    reset() {
      this.userPermissionState.next(api.Iam.DEFAULT_USER_POLICY);
      this.parent.reset();
    }

    /**
     * Reset all instances.
     */
    static reset() {
      for (const i in this.contextualInstances) {
        this.contextualInstances[i].reset();
      }
    }

    /**
     * Update the state according to contextual needs.
     */
    dispatch(newPolicy: api.Iam.IUserPolicy): void {
      this.userPermissionState.next(newPolicy);
    }

    /**
     * Loads and returns the policy.
     */
    load(newPolicy: api.Iam.IUserPolicy): Observable<any> {
      this.userPermissionState.next(newPolicy);
      return of(newPolicy);
    }
  }

  /**
   * Specialized user permission manager.
   * Each user identified by email has one singleton permission manager,
   * that can be retrieved by according context objects.
   */
  export class ProcessPermissionManager implements api.Iam.IPermissionManager {
    public readonly policy$;

    // Instance permission policy, overriding/extending the base policy.
    private processPermissionState: BehaviorSubject<api.Iam.IProcessPolicy>;

    // Instance dictionary: Each user has one singleton permission manager.
    private static contextualInstances: {
      [emailPid: string]: ProcessPermissionManager;
    } = {};

    private _currentPolicy: api.Iam.IProcessPolicy;
    /**
     * Current policy snapshot;
     */
    public get currentPolicy(): api.Iam.IProcessPolicy {
      return Object.freeze(Object.assign({}, this._currentPolicy));
    }

    private constructor(
      private parent: api.Iam.IPermissionManager,
      private context: ProcessContext
    ) {
      this._currentPolicy = parent.currentPolicy;
      this.processPermissionState = new BehaviorSubject<api.Iam.IProcessPolicy>(api.Iam.DEFAULT_PROCESS_POLICY);

      // Merge the policy with the main policy.
      // User permissions override global permissions.
      this.policy$ = combineLatest(
        parent.policy$,
        this.processPermissionState
      ).pipe(
        map(([parentPolicy, processPolicy]) => {
          const newPolicy = Object.assign({}, parentPolicy, processPolicy);
          this._currentPolicy = newPolicy;
          return newPolicy;
        })
      );
    }

    static getKey(context) {
      return `${context.email}|${context.pid}`;
    }

    // Returns only one instance (Singleton) per user (email)
    static getInstance(
      parent: api.Iam.IPermissionManager,
      context: ProcessContext
    ): ProcessPermissionManager {
      if (!context) {
        throw new Error('Process context is missing.');
      }

      if (!parent) {
        throw new Error('Parent reference is missing.');
      }

      const key = ProcessPermissionManager.getKey(context);

      if (!this.contextualInstances[key]) {
        this.contextualInstances[key] = new this(parent, context);
      }

      return this.contextualInstances[key];
    }

    /**
     * Resets local state and parent state.
     */
    reset() {
      this.processPermissionState.next(api.Iam.DEFAULT_PROCESS_POLICY);
      this.parent.reset();
    }

    /**
     * Reset all instances.
     */
    static reset() {
      for (const i in this.contextualInstances) {
        this.contextualInstances[i].reset();
      }
    }

    /**
     * Update the state according to contextual needs.
     */
    dispatch(newPolicy: api.Iam.IProcessPolicy): void {
      this.processPermissionState.next(newPolicy);
    }

    /**
     * Loads and returns the policy.
     */
    load(newPolicy: api.Iam.IProcessPolicy): Observable<any> {
      this.processPermissionState.next(newPolicy);
      return of(newPolicy);
    }
  }
}
