import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {UserAvatar} from './user-avatar';

export class UserAvatarBuilder implements IApiResourceBuilder<UserAvatar> {
  fromResponse(data): UserAvatar {
    return new UserAvatar(
      data.id,
      data.attributes.avatar,
      data.attributes.first_name,
      data.attributes.last_name,
      data.attributes.gender,
      data.attributes.title
    );
  }

  toRequest (avatar: UserAvatar) {
    return {
      data: {
        id: avatar.id,
        type: 'user_profiles',
        attributes: {
          avatar: avatar.avatar,
          first_name: avatar.firstName,
          last_name: avatar.lastName,
          gender: avatar.gender,
          title: avatar.title
        }
      }
    };
  }
}
