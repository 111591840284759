import {ICollectorConfirmation} from './collector-confirmation.interface';

export class CollectorConfirmation implements ICollectorConfirmation {
  readonly type = 'collector_confirmations';
  public color: string;

  constructor(public id: string,
              public collectorId: string,
              public email: string,
              public createdAt: Date) {}
}
