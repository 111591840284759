import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {FastdocsEffects} from './fastdocs.effects';
import {FastdocsService} from './fastdocs.service';
import {reducer} from './fastdocs.reducer';
import {stateKey} from './fastdocs.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([FastdocsEffects])
  ],
  providers: [
    FastdocsEffects,
    FastdocsService
  ]
})
export class FastdocsStoreModule {
}
