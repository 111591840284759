export class AddressBookColumnBuilder {
  static updateColumns(addressBook: any) {
    let columns = [];

    if (addressBook.isAdmin && addressBook.enableSelectColumn) {
      columns.push('select');
    }

    columns.push('avatar');

    if (!addressBook.disableClients) {
      columns.push('contactClients');
    }

    if (!addressBook.disableVisibility) {
      columns.push('visibility');
    }

    columns = [...columns, ...['name', 'email']];

    if (!addressBook.disablePhoneColumn) {
      columns.push('phoneNumber');
    }

    if (addressBook.partnerLinksEnabled && !addressBook.partnerLinkColumnTitle) {
      columns.push('partnerLink');
    }

    // Support for a longer column title for partnerlinks
    if (addressBook.enablePartnerLinksColumn && addressBook.partnerLinksEnabled && addressBook.partnerLinkColumnTitle) {
      columns.push('partnerLinksAlternative');
    }

    if (addressBook.enableContactColumn) {
      columns.push('contact')
    }

    if (addressBook.enableMemberColumn) {
      columns.push('member')
    }

    if (addressBook.enableProjectRoomColumn && addressBook.isAdmin) {
      columns.push('projectRooms');
    }

    if (addressBook.enableTwoFaColumn && addressBook.isAdmin) {
      columns.push('two-fa');
    }

    if (addressBook.enableAddressbookActionsColumn) {
      columns.push('actions');
    }

    if (addressBook.enableParticipationActionsColumn) {
      columns.push('participationActions');
    }
    return columns;
  }
}
