import {NaturalPersonActions, NaturalPersonActionTypes} from './natural-person.actions';
import {adapter, initialState, State} from './natural-person.state';
import {NaturalPerson} from 'app/models/natural-person.model';

export function reducer(state = initialState, action: NaturalPersonActions): State {
  switch (action.type) {
    case NaturalPersonActionTypes.LoadMy:
      return {
        ...state,
        loading: true
      };
    case NaturalPersonActionTypes.LoadMySuccess:
      return adapter.addOne(action.payload, {
        ...state,
        my: action.payload.id,
        loading: false,
        myNaturalPerson: action.payload
      });
    case NaturalPersonActionTypes.LoadMy:
      return {
        ...state,
        loading: true,
        myNaturalPerson: null
      };
    case NaturalPersonActionTypes.LoadMyFail:
      return {
        ...state,
        loading: false,
      };
    case NaturalPersonActionTypes.UpdateMySuccess: {
      return adapter.addOne(action.payload, {
        ...state,
        my: action.payload.id,
        loading: false,
        myNaturalPerson: action.payload
      });

    }
    case NaturalPersonActionTypes.LoadSuccess: {
      const updates: NaturalPerson[] = action.payload.map(p => (p))
      return adapter.upsertMany(updates, state)
    }
    case NaturalPersonActionTypes.AddFurtherAddressSuccess: {
      const id = action.payload.personId
      const entity = state.entities[id]
      const addresses = [...entity.addresses, action.payload.address]
      return adapter.updateOne({id, changes: {addresses}}, state)
    }
    case NaturalPersonActionTypes.RemoveFurtherAddressSuccess: {
      const personId = action.personId;
      const entity = state.entities[personId]
      const addresses = entity.addresses.filter((address) => address.id !== action.addressId);
      return adapter.updateOne({id: personId, changes: {addresses}}, state);
    }
    case NaturalPersonActionTypes.AddFurtherPhoneNumberSuccess: {
      const id = action.payload.personId
      const entity = state.entities[id]
      const phoneNumbers = [...entity.phoneNumbers, action.payload.phoneNumber]
      return adapter.updateOne({id, changes: {phoneNumbers}}, state)
    }
    case NaturalPersonActionTypes.RemoveFurtherPhoneNumberSuccess: {
      const personId = action.personId;
      const entity = state.entities[personId]
      const phoneNumbers = entity.phoneNumbers.filter((phoneNumber) => phoneNumber.id !== action.phoneNumberId);
      return adapter.updateOne({id: personId, changes: {phoneNumbers}}, state);
    }
    case NaturalPersonActionTypes.AddFurtherEMailSuccess: {
      const id = action.payload.personId;
      const entity = state.entities[id]
      const emailAddresses = [...entity.emailAddresses, action.payload.emailAddress];
      return adapter.updateOne({id, changes: {emailAddresses}}, state);
    }
    case NaturalPersonActionTypes.RemoveFurtherEMailSuccess: {
      const personId = action.personId;
      const entity = state.entities[personId]
      const emailAddresses = entity.emailAddresses.filter((emailAddress) => emailAddress.id !== action.emailAddressId);
      return adapter.updateOne({id: personId, changes: {emailAddresses}}, state);
    }
  }
  return state;
}
