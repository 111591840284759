import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'dvtx-file-listing',
  templateUrl: './upload-file-listing.component.html',
  styleUrls: ['./upload-file-listing.component.scss']
})
export class UploadFileListingComponent {

  @Input() form: UntypedFormGroup;
  @Input() uploader;
  @Output() onRemove = new EventEmitter();
}
