import { IApiResourceBuilder } from "../../api-resource/models/api.interface";
import { UserTwoFactorWhitelist } from "../models/userTwoFactorWhitelist";


export class UserTwoFactorWhitelistBuilder implements IApiResourceBuilder<UserTwoFactorWhitelist> {
  fromResponse(data): UserTwoFactorWhitelist {
    return new UserTwoFactorWhitelist(
      data.id,
      data.attributes.first_name,
      data.attributes.last_name,
      data.attributes.created_at,
      data.attributes.email
      );
  }

  toRequest(user: UserTwoFactorWhitelist) {
    return null;
  }
}
