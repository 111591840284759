
import {combineLatest as observableCombineLatest, Observable, Subject, BehaviorSubject} from 'rxjs';
/**
 * CVA autocomplete component to display contacts as chips and allow to add or remove them.
 *
 * NOTE: ng6 has chip autocompletion build in so this component could be rebuilt after
 *       an upgrade.
 *
 * Reference:
 * - Discussion about a material chip autocomplete component:
 *   https://github.com/angular/material2/issues/3273
 *
 * Author: Andreas Baier <andreas.baier@paperbird.org>
 */
import {
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input, OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {DvtxControlValueAccessor} from '../../DvtxControlValueAccessor';
import {UntypedFormControl} from '@angular/forms';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import {startWith, map} from 'rxjs/operators';

@Component({
  selector: 'dvtx-contact-chips-cva',
  templateUrl: './contact-chips-cva.component.html',
  styleUrls: ['./contact-chips-cva.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactChipsCvaComponent),
      multi: true,
    }
  ]
})
export class ContactChipsCvaComponent extends DvtxControlValueAccessor implements OnDestroy {
  onDestroy = new Subject();
  contacts$ = new BehaviorSubject([]);
  filteredContacts$;
  selectedContacts = [];

  @Input() placeholder = 'PROJECT_ROOM.ADD_MEMBER';

  @Input() set contacts(contacts) {
    if (contacts && contacts.length) {
      this.contacts$.next(contacts.filter(contact => contact && contact.email))
    }
  }

  @ViewChild('contactInput', { read: MatAutocompleteTrigger })
  private autoCompleteTrigger: MatAutocompleteTrigger;
  autoCompleteContactList: UntypedFormControl = new UntypedFormControl();

  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;

  constructor(protected injector: Injector) {
    super();

    const search = this.autoCompleteContactList.valueChanges;
    this.filteredContacts$ = observableCombineLatest(this.contacts$, search)
      .pipe(
        map(([contacts, query]) => {
          return contacts.filter(contact => {
            if (!contact || !contact.email) {
              return false;
            }
            return this._matchName(contact, query)
              && !this.selectedContacts.find(c => c && c.email === contact.email);
          });
        })
      );
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  writeValue(value): void {
    if (value !== undefined) {
      this.autoCompleteContactList.patchValue(value);
      this.selectedContacts = value;
      this.notifyOnChange(this.selectedContacts);
    }
  }

  focusInput() {
    setTimeout(() => {
      if (!this.autoCompleteTrigger.panelOpen) {
        this.autoCompleteTrigger.openPanel();
      }
    }, 100);
  }

  add(event: MatAutocompleteSelectedEvent, input: any): void {
    const selected = event.option.value;
    const duplicateCheck: boolean = !!this.selectedContacts.find(y => y && y.id === selected.id) || false;
    if (!duplicateCheck) {
      this.selectedContacts.push(selected);
    }
    if (input) {
      input.value = '';
    }
    this.notifyOnChange(this.selectedContacts);
  }

  remove(contact: any): void {
    const index = this.selectedContacts.indexOf(contact);
    if (index >= 0) {
      this.selectedContacts.splice(index, 1);
      this.notifyOnChange(this.selectedContacts);
    }
  }

  private _matchName(contact, query: string) {
    return contact.name.toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
  }
}
