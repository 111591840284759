import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EnvService} from 'app/shared/modules/api-resource/services/env.service';
import {Observable} from 'rxjs';
import {ExcelExport, ExcelExportResponse} from './excel-export';

@Injectable()
export class ExcelExportService {
  readonly EXPORT_PATH = '/api/v1/';
  apiUrl: string;

  constructor(private _http: HttpClient, private _env: EnvService) {
    this.apiUrl = _env.collectoExportApi()
  }

  generateExcel(excelExport: ExcelExport): Observable<ExcelExportResponse> {
    const payload = {
      data: {
        type: excelExport.type,
        attributes: {
          export: excelExport.export
        }
      }
    };
    return <Observable<ExcelExportResponse>>this._http.post(`${this.apiUrl}${this.EXPORT_PATH}/generator/excel`, payload);
  }
}
