import {createEntityAdapter, EntityState} from '@ngrx/entity';
import * as dayjs from 'dayjs';
import { TaskComment } from './task-comment';

export interface State extends EntityState<TaskComment> {
  selected?: TaskComment;
  loading: boolean;
  creating: boolean;
  updating: boolean;
}

export const adapter = createEntityAdapter<TaskComment>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
  creating: false,
  updating: false
});
