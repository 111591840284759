import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ProcessParticipantAclEffects} from './process-participant-acl.effects';
import {ProcessParticipantAclService} from './process-participant-acl.service';
import {reducer} from './process-participant-acl.reducer';
import {stateKey} from './process-participant-acl.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ProcessParticipantAclEffects])
  ],
  providers: [
    ProcessParticipantAclEffects,
    ProcessParticipantAclService
  ]
})
export class ProcessParticipantAclStoreModule {
}
