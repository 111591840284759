import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {ProcessRole, ProjectRoomAdmin} from './process-role';

export class ProcessRoleBuilder implements IApiResourceBuilder<ProcessRole> {
  constructor(private _processId: string) {}

  fromResponse(data): ProcessRole {
    return new ProcessRole(data.attributes.name, this._processId);
  }

  toRequest(_: ProcessRole) {
    return null;
  }
}

export class ProjectRoomAdminRoleBuilder implements IApiResourceBuilder<ProjectRoomAdmin> {
  fromResponse(data): ProjectRoomAdmin {
    return new ProjectRoomAdmin(data.id, data.attributes.email);
  }

  toRequest(_: ProjectRoomAdmin) {
    return null;
  }
}
