import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  of as observableOf,
  Subject,
  throwError as observableThrowError
} from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ITreeOptions, KEYS, TREE_ACTIONS, TreeComponent, TreeNode} from '@circlon/angular-tree-component';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  flatMap,
  map,
  take,
  takeUntil
} from 'rxjs/operators';
import {DmsAccountType} from 'app/+store/dms-folder/dms-folder.interface';
import {OrganizationSelectors} from 'app/+store/organization';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {NaturalPersonSelectors} from 'app/+store/natural-person';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ErrorDialogComponent} from 'app/shared/modules/file-dialog/components/error-dialog/error-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {
  DeleteFolderDialogComponent
} from 'app/shared/modules/file-dialog/components/delete-folder-dialog/delete-folder-dialog.component';
import {NodeType} from 'app/+store/dms-folder/node-type';
import {DmsINodeStoreRepository} from 'app/+store/dms-folder/dms-inode-repository';
import {INode} from 'app/+store/dms-folder/node.interface';
import {NodeBuilder} from 'app/+store/dms-folder/node.builder';
import {AngularTokenService} from 'angular-token';
import {DmsFolderActions, DmsFolderSelectors} from 'app/+store';
import {DmsFolderService} from 'app/+store/dms-folder/dms-folder.service';
import {FolderInfoAction} from '../dms-folder-info/dms-folder-info.component';

@Component({
  selector: 'dvtx-dms-folder-picker-v5',
  templateUrl: './dms-folder-picker-v5.component.html',
  styleUrls: [
    '../../../../../file-picker/containers/dms-file-picker/dms-file-picker.component.scss',
    './dms-folder-picker-v5.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DmsFolderPickerV5Component implements OnInit, OnDestroy {
  private onDestroy = new Subject();

  public FolderInfoAction = FolderInfoAction;
  public NodeType = NodeType;
  public DmsAccountType = DmsAccountType;
  public loading = false;
  public fatalError = null;

  private initialized = false;

  public isSearch = false;

  @ViewChild(TreeComponent) private treeComponent: TreeComponent;
  @ViewChild('folderName') private folderName: ElementRef;
  @ViewChild('renameFolderName') private renameFolderName: ElementRef;

  @Output() onSelect = new EventEmitter<{ id: string, accountType: DmsAccountType, path: string }>();

  /*
   * * Tree default action mapping:
   *
   * defaultActionMapping: IActionMapping = {
   *   mouse: {
   *     click: TREE_ACTIONS.TOGGLE_ACTIVE,
   *     dblClick: null,
   *     contextMenu: null,
   *     expanderClick: TREE_ACTIONS.TOGGLE_EXPANDED,
   *     checkboxClick: TREE_ACTIONS.TOGGLE_SELECTED,
   *     drop: TREE_ACTIONS.MOVE_NODE
   *   },
   *   keys: {
   *     [KEYS.RIGHT]: TREE_ACTIONS.DRILL_DOWN,
   *     [KEYS.LEFT]: TREE_ACTIONS.DRILL_UP,
   *     [KEYS.DOWN]: TREE_ACTIONS.NEXT_NODE,
   *     [KEYS.UP]: TREE_ACTIONS.PREVIOUS_NODE,
   *     [KEYS.SPACE]: TREE_ACTIONS.TOGGLE_ACTIVE,
   *     [KEYS.ENTER]: TREE_ACTIONS.TOGGLE_ACTIVE
   *   }
   * };
   *
   * * Further Options:
   *
   * options: ITreeOptions = {
   *   getChildren: (node: TreeNode) => {
   *     console.error('Fetching');
   *     return new Promise((resolve, reject) => {
   *       this.testData('leaf').subscribe(data => {
   *         resolve(data);
   *       }, error => null);
   *     });
   *   },
   *   displayField: 'name',
   *   isExpandedField: 'expanded',
   *   idField: 'id',
   *   hasChildrenField: 'hasChildren',
   *   nodeHeight: 23,
   *   allowDrag: (node) => {
   *     return true;
   *   },
   *   allowDrop: (node) => {
   *     return true;
   *   },
   *   allowDragoverStyling: true,
   *   levelPadding: 10,
   *   useVirtualScroll: true,
   *   animateExpand: true,
   *   scrollOnActivate: true,
   *   animateSpeed: 30,
   *   animateAcceleration: 1.2,
   *   scrollContainer: document.documentElement // HTML
   * };
   */
  options: ITreeOptions = {
    getChildren: this.getChildren.bind(this),
    useCheckbox: true,
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          if (!node || !node.data) {
            return;
          }
          if (node.data.type === NodeType.Folder && node.data.id && node.data.id !== '00000000-0000-0000-0000-000000000000') { // && !node.isRoot)) {
            this.select(node);
            TREE_ACTIONS.ACTIVATE(tree, node, $event);
          } else {
            TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
          }
        }
      },
      keys: {
        [KEYS.ENTER]: (tree, node, $event) => {
          if (!node || !node.data) {
            return;
          }
          if (node.data.type === NodeType.Folder
            && node.data.id && node.data.id && node.data.id !== '00000000-0000-0000-0000-000000000000') { // && !node.isRoot)) {
            this.select(node);
            TREE_ACTIONS.ACTIVATE(tree, node, $event);
          } else {
            TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
          }
        },
        [KEYS.SPACE]: (tree, node, $event) => {
          if (!node || !node.data) {
            return;
          }
          if (node.data.type === NodeType.Folder
            && node.data.id && node.data.id && node.data.id !== '00000000-0000-0000-0000-000000000000') { // && !node.isRoot)) {
            this.select(node);
            TREE_ACTIONS.ACTIVATE(tree, node, $event);
          } else {
            TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
          }
        }
      }
    },
  };

  public nodes$ = new BehaviorSubject([]);
  private search$ = new BehaviorSubject('');

  privRepository: DmsINodeStoreRepository;
  orgRepository: DmsINodeStoreRepository;
  selectedNode: TreeNode;

  organizationId: string;

  isCreateInput = false;
  isRenameInput = false;
  createDisabled = false;
  reloading = true;
  private nodesInitialized = false;

  @Input() createInputButtonsType = 'normal';
  _disabledPrivateFolder: boolean;
  @Input() set disabledPrivateFolder(disabledPrivateFolder: boolean) {
    if (disabledPrivateFolder === this._disabledPrivateFolder) return;

    this._disabledPrivateFolder = disabledPrivateFolder;
    this._initFolderTree();
  }

  @Input() set selectedNodeId(selectedNodeId) {
    this.selectNode(selectedNodeId);
  }

  constructor(private _store: Store<AppState>,
              private _dmsFolderSvc: DmsFolderService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService,
              private _dialog: MatDialog,
              private _tokenSvc: AngularTokenService,
              private _zone: NgZone,
              private _cd: ChangeDetectorRef) {
    this.privRepository = new DmsINodeStoreRepository(this._tokenSvc.currentAuthData.uid, DmsAccountType.Private, _store);
  }

  ngOnInit() {
    if (this._disabledPrivateFolder === undefined) {
      this.disabledPrivateFolder = false;
    }
    this.initialized = true;
    this.search$
      .pipe(debounceTime(300), takeUntil(this.onDestroy))
      .subscribe(term => {
        if (this.treeComponent && this.treeComponent.treeModel) {
          if (!this.nodesInitialized) {
            this.treeComponent.treeModel.calculateExpandedNodes();
            this.nodesInitialized = true;
          }
          this.treeComponent.treeModel.filterNodes(term, true);
        }
      })
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.initialized = false;
    this.nodes$.complete();
    this.search$.complete();

    if (this.orgRepository) {
      this.orgRepository.destroy();
    }

    if (this.privRepository) {
      this.privRepository.destroy();
    }
  }

  public search(term) {
    this.search$.next(term);
  }

  getChildren(node: TreeNode) {
    return new Promise((resolve, reject) => {
      if (node.data.accountType === DmsAccountType.Private) {
        this.privRepository.getChildrenNodes(node.id, true)
          .pipe(take(1))
          .subscribe(_nodes => resolve(_nodes));
      } else {
        this.orgRepository.getChildrenNodes(node.id, true)
          .pipe(take(1))
          .subscribe(_nodes => resolve(_nodes));
      }
    });
  }

  select(node: TreeNode) {
    const data = node.data as INode;
    const res = {path: data.path === '' ? '/' : data.path, ...data};
    this.selectedNode = node;
    this.onSelect.emit(res);
  }

  selectNode(selectedNodeId) {
    if (selectedNodeId) {
      setTimeout(_ => {
        try {
          if (this.treeComponent && this.treeComponent.treeModel && this.treeComponent.treeModel.getNodeById(selectedNodeId)) {
            const node: TreeNode = this.treeComponent.treeModel.getNodeById(selectedNodeId);
            TREE_ACTIONS.ACTIVATE(this.treeComponent.treeModel, node, null);
            this.select(node)
          } else {
            this.selectNode(selectedNodeId)
          }
        } catch (err) {
          console.error(err);
        }
    }, 100);
    }
  }

  toggleAdd() {
    this.isCreateInput = !this.isCreateInput;
  }

  toggleRename() {
    this.isRenameInput = !this.isRenameInput;
  }

  renameFolder(name) {
    this.createDisabled = true;
    if (this.selectedNode && this.selectedNode.data) {
      this._store.dispatch(new DmsFolderActions.Rename(this.selectedNode.id, name, this.selectedNode.data.accountType));
    }
    this.renameFolderName.nativeElement.value = '';
    this.isRenameInput = false;
    this.createDisabled = false;
  }

  getCurrentFolderName() {
    if (this.selectedNode && this.selectedNode.data) {
      return this.selectedNode.data.name;
    }
    return '';
  }

  createFolder(name) {
    this.createDisabled = true;
    if (this.selectedNode && this.selectedNode.data && this.selectedNode.parent && this.selectedNode.realParent) {
      // this.loading = true;
      const selectedNode = this.selectedNode;
      this._dmsFolderSvc.create(name, selectedNode.id, selectedNode.data.accountType).pipe(
        first(),
        catchError(error => this._handleFatalError(error)))
        .subscribe(res => {
          this.createDisabled = false;
          if (res && res.id) {
            this.loading = false;
            const newNode = new NodeBuilder(selectedNode.data.accountType).create(res);
            // const selected = this.searchTree(this.nodes[0], this.selectedNode.id);
            // selected.children.push(res);
            newNode.path = `${selectedNode.data.path}/${newNode.name}`;
            if (selectedNode && selectedNode.data && selectedNode.data.children) {
              selectedNode.data.children.push(newNode);

            } else if (selectedNode.data) {
              selectedNode.data['children'] = [].concat(newNode);
              // selectedNode.data.children.push(newNode);
            }
            if (selectedNode.data.ref) {
              const folder = selectedNode.data.ref;
              ++folder.folderCount;
              selectedNode.data.hasChildren = true;
            }
            selectedNode.expand();
            this.selectedNode = null;
            this.treeComponent.treeModel.update();
            this._store.dispatch(new DmsFolderActions.CreateSuccess(res));
            // this._store.dispatch(new DmsFolderActions.LoadOne(selectedNode.data.id));
          }
        }, (error) => {
          console.error(error);
          if (error.error && error.error.errors) {
            this.openErrorDialog(error.error.errors)
          }
          this.createDisabled = false;
          this.isCreateInput = false;
          this.selectedNode = null;
        });
    } else if (this.selectedNode && this.selectedNode.data) {
      // this.loading = true;
      const selectedNode = this.selectedNode;
      this._dmsFolderSvc.create(name, selectedNode.id, selectedNode.data.accountType).pipe(
        first(),
        catchError(error => this._handleFatalError(error)))
        .subscribe(res => {
          this.createDisabled = false;
          if (res && res.id) {
            this.loading = false;
            const newNode = new NodeBuilder(selectedNode.data.accountType).create(res);
            // const selected = this.searchTree(this.nodes[0], this.selectedNode.id);
            // selected.children.push(res);
            newNode.path = `${selectedNode.data.path}/${newNode.name}`;
            if (selectedNode && selectedNode.data && selectedNode.data.children) {
              selectedNode.data.children.push(newNode);

            } else if (selectedNode.data) {
              selectedNode.data['children'] = [].concat(newNode);
              // selectedNode.data.children.push(newNode);
            }
            selectedNode.expand();
            this.selectedNode = null;
            if (selectedNode.data.ref) {
              const folder = selectedNode.data.ref;
              ++folder.folderCount;
              selectedNode.data.hasChildren = true;
            }
            this.treeComponent.treeModel.update();
            this._store.dispatch(new DmsFolderActions.CreateSuccess(res));
            // this._store.dispatch(new DmsFolderActions.LoadOne(selectedNode.data.id));
          }
        }, (error) => {
          console.error(error);
          if (error.error && error.error.errors) {
            this.openErrorDialog(error.error.errors)
          }
          this.createDisabled = false;
          this.isCreateInput = false;
          this.selectedNode = null;
        });
    } else {
      this._dmsFolderSvc.create(name).pipe(
        first(),
        catchError(error => this._handleFatalError(error)))
        .subscribe(res => {
          this.loading = this.createDisabled = false;
          // this.nodes[0].children.push(res);
          this.treeComponent.treeModel.update();
          this._store.dispatch(new DmsFolderActions.CreateSuccess(res));
          this.createDisabled = false;
          this.isCreateInput = false;
        }, (error) => {
          console.error(error);
          if (error.error && error.error.errors) {
            this.openErrorDialog(error.error.errors)
          }
          this.createDisabled = false;
          this.isCreateInput = false;
          this.selectedNode = null;
        });
    }
    this.folderName.nativeElement.value = '';
    this.isCreateInput = false;
    this.createDisabled = false;
  }

  openInfoDialog() {
  }

  deleteFolder() {
    const dialogRef = this._dialog.open(DeleteFolderDialogComponent, {
      panelClass: 'dialog-sm',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        const selectedNode = this.selectedNode;
        if (selectedNode && selectedNode.data) {
          let parent = null;
          if (selectedNode.data.parent) {
            try {
              // Try to find a reference to the parent node if present.
              parent = this.treeComponent.treeModel.getNodeById(selectedNode.data.parent);
            } catch (err) {
              console.error(err);
            }
          }
          this._dmsFolderSvc.delete(selectedNode.id, selectedNode.data.accountType)
            .pipe(first())
            .subscribe(folder => {
              try {
                // If deletion was successful update the folder count of the parent
                // to remove the folder expander and to enable actions on the parent.
                if (parent && parent.data && parent.data.ref) {
                  const iNodeRef = parent.data.ref;
                  if (iNodeRef && iNodeRef.folderCount && iNodeRef.folderCount > 0) {
                    --iNodeRef.folderCount;
                  }
                  this._store.dispatch(new DmsFolderActions.DeleteSuccess(folder));
                }
              } catch (err) {
                console.error(err);
              }
            }, (err) => this._handleFatalError(err));
        }
        this.selectedNode = null;
      }
    });
  }

  openErrorDialog(errors) {
    this.loading = this.createDisabled = false;
    const title = (errors.length === 1) ? 'GENERAL.AN_ERROR_OCCURRED' : 'GENERAL.ERRORS_OCCURRED';
    const attrError = new RegExp('^(api.dms.error.folder|api.resource.error)');
    const attributesErrors = errors.filter(error => attrError.test(error.code));

    this._dialog.open(ErrorDialogComponent, {
      panelClass: 'dialog-sm',
      data: {title: title, errors: attributesErrors}
    });
  }

  isRootFolder(): boolean {
    return this.selectedNode && this.selectedNode.isRoot;
  }

  refresh() {
    this._store.dispatch(new DmsFolderActions.Refresh(DmsAccountType.Private));
    if (this.organizationId) {
      this._store.dispatch(new DmsFolderActions.Refresh(DmsAccountType.Organization));
    }
    this.createDisabled = false;
    this.isCreateInput = false;
    this.isRenameInput = false;
  }

  private _handleFatalError(error) {
    this.loading = false;
    this.fatalError = true;
    console.error('FolderPickerV2#handleError', error);
    if (error && error.error && error.error.errors && error.error.errors.length > 0) {
      this._notifyService.error(error.error.errors[0].title);
      this.fatalError = error.error.errors[0].title;
    }
    return observableThrowError(error);
  }

  private _fetchUserName() {
    return this._store
      .select(NaturalPersonSelectors.getUsersNaturalPerson)
      .pipe(
        filter(x => !!x),
        map(person => person ? `${person.firstName} ${person.lastName}` : null)
      );
  }

  private _initFolderTree() {
    this.loading = true;

    this._store.select(DmsFolderSelectors.loadingState).pipe(
        distinctUntilChanged(),
        takeUntil(this.onDestroy)
      ).subscribe(loading => {
        this.reloading = loading;
        if (this.initialized) {
          this._cd.detectChanges();
        }
      });

    // TODO: Double check with Andi, the below changes are done by Abdallah
    // The below change from "null" to "Observable.of({name: null})" because usually the OnInit starts before
    // the @Input(), but it a weird case the OnInit was getting executed after, and if the _disabledPrivateFolder is true and
    // then the OnInit gets executed, the Observable.combineLatest(org, username$, root$) return []. The OnInit has been
    // chnaged for this case where the OnInit gets executed after the @input(), if the @Input gets called before OnInit with
    // true value for the _disabledPrivateFolder, then we have no folder is being displayed.
    const root$ = !this._disabledPrivateFolder ? this.privRepository.getRootNode() : observableOf({name: null});
    // // const orgRoot$ = this.orgRepository.getRootNode();
    const org = this._store.select(OrganizationSelectors.getSelected).pipe(filter(o => !!o));
    // if (!this._disabledPrivateFolder) {
    //   this._store.dispatch(new DmsFolderActions.LoadAll(DmsAccountType.Private));
    // }
    // org.pipe(
    //   distinctUntilChanged(),
    //   takeUntil(this.onDestroy)
    // ).subscribe(_org => {
    //     this._store.dispatch(new DmsFolderActions.LoadAllOrganization());
    //   });
    const username$ = this._fetchUserName();

    observableCombineLatest(org, username$, root$).pipe(
      distinctUntilChanged(),
      flatMap(([organization, username, root]) => {
        if (!root) {
          return observableOf([]);
        }
        if (username) {
          root.name = username;
        }
        if (organization && organization.id) {
          this.organizationId = organization.id;
          this.orgRepository = new DmsINodeStoreRepository(organization.id, DmsAccountType.Organization, this._store);
          const orgRoot$ = this.orgRepository.getRootNode();
          return orgRoot$.pipe(
            // first(),
            map(orgRoot => {
              const roots = [];
              if (!this._disabledPrivateFolder && root['type'] !== NodeType.DisabledFolder) {
                roots.push(root);
              }
              if (!!orgRoot && !!organization) {
                orgRoot.name = organization.name;
                roots.push(orgRoot);
              }
              return roots;
            }));
        } else {
          return observableOf([root]);
        }
      }),
      catchError(error => {
        console.error(error);
        this.loading = false;
        return observableOf([]);
      }),
      takeUntil(this.onDestroy)
    ).subscribe(roots => {
      this.nodes$.next(roots);
      this.loading = false;
    });

    this.nodes$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.onDestroy))
      .subscribe(x => {
        this._updateTree();
      });
  }

  private _updateTree() {
    setTimeout(_ => {
      try {
        if (this.treeComponent && this.treeComponent.treeModel) {
          this.treeComponent.treeModel.update();
          if (this.initialized) {
            this._cd.detectChanges();
          }
        }
      } catch (err) {
        console.error(err);
      }
    }, 1);
  }
}
