import {first, takeUntil} from 'rxjs/operators';
import {Component, Output, EventEmitter, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {UploadDialogComponent} from '../../components/upload-dialog/upload-dialog.component';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers/index';
import {OrganizationSelectors, OrganizationLogoActions, OrganizationLogoSelectors} from 'app/+store/index';
import {IOrganizationLogo} from 'app/+store/organization-logo/organization-logo.interface';
import {ConfirmationDialogSimpleComponent} from 'app/shared/components/dialogs/confirmation-dialog-simple/confirmation-dialog-simple.component';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {environment} from 'environments/environment';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';

@Component({
  selector: 'dvtx-logo-upload-container',
  styleUrls: ['./logo-upload-container.component.scss'],
  templateUrl: './logo-upload-container.component.html',
})
export class LogoUploadContainerComponent implements OnInit, OnDestroy {
  SUPPORTED_FILE_TYPES: string[] = ['jpeg', 'jpg', 'gif', 'png'];
  MAX_FILE_SIZE = 500;
  UPLOAD_URL = `${environment.token_service_config.apiBase}/api/v1/organizations/logos`;

  onDestroy: Subject<void> = new Subject<void>();
  @ViewChild(MatSort) sort: MatSort;
  @Output() onRemove = new EventEmitter();

  logo$: Observable<IOrganizationLogo>;
  logos$: Observable<IOrganizationLogo[]>;
  columnsToDisplay = ['icon', 'title', 'current', 'preview', 'actions'];
  dataSource: MatTableDataSource<IOrganizationLogo>;

  constructor(private _dialog: MatDialog,
              private _store: Store<AppState>,
              private _notificationSvc: NotificationService) {
  }

  ngOnInit() {
    this.logo$ = this._store.select(OrganizationLogoSelectors.getCurrentLogo);
    this.logos$ = this._store.select(OrganizationLogoSelectors.getAllLogosOfOrganization);

    this._store
      .select(OrganizationSelectors.getSelected).pipe(
      takeUntil(this.onDestroy))
      .subscribe(_ => {
        this.refresh();
      });

    this.logos$.pipe(
      takeUntil(this.onDestroy)
    ).subscribe(logos => {
      this.dataSource = new MatTableDataSource(logos.filter(x => x && x.url));
      this.dataSource.sort = this.sort;
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  refresh() {
    this._store.dispatch(new OrganizationLogoActions.LoadAll());
  }

  public openLogoDialog() {
    this._store.select(OrganizationSelectors.getSelectedId).pipe(
      first())
      .subscribe(id => {
        this._dialog.open(UploadDialogComponent,
          {
            data: {
              options: {
                dialogTitle: 'CUSTOMIZATION.SELECT_AND_UPLOAD_TITLE',
                submitButtonTitle: 'CUSTOMIZATION.UPLOAD_LOGO',
                supportedFileTypes: this.SUPPORTED_FILE_TYPES,
                maxFileSize: this.MAX_FILE_SIZE,
                uploadUrl: this.UPLOAD_URL,
                organizationUUID: id,
                onSuccess: this.onSuccess,
                onItemSuccess: this.onItemSuccess,
                alert: {
                  color: 'info',
                  icon: 'info',
                  text: 'CUSTOMIZATION.UPLOADED_LOGO_RECOMMENDATION',
                  dismissible: false
                }
              }
            }
          });
      });
  }

  publish(element, $event) {
    this._store.dispatch(new OrganizationLogoActions.Publish(element.id, $event.checked));
  }

  remove(logo) {
    this._dialog.open(ConfirmationDialogSimpleComponent, {
      data: {
        title: 'CUSTOMIZATION.REMOVE_LOGO_TITLE',
        message: 'CUSTOMIZATION.REMOVE_LOGO_CONFIRM',
        onSubmitAction: () => {
          this._store.dispatch(new OrganizationLogoActions.Remove(logo));
        }
      }
    });
  }

  onSuccess = () => {
    this._store.dispatch(new OrganizationLogoActions.LoadCurrent());
    this._store.dispatch(new OrganizationLogoActions.LoadAll());
  }

  onItemSuccess = (item) => {
    this._notificationSvc.success(`Logo "${item.file.name}" successfully uploaded.`);
  }
}
