import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {ProcessStructureComponent} from './containers/process-structure/process-structure.component';
import {ProcessTreeSingleSelectComponent} from './components/process-tree-single-select/process-tree-single-select.component';
import { MatTreeModule } from '@angular/material/tree';
import {ProcessTreeNodeComponent} from './components/process-tree-node/process-tree-node.component';
import {ProcessStructureStandaloneTreeComponent} from './containers/process-structure-standalone-tree/process-structure-standalone-tree.component';

@NgModule({
  imports: [
    SharedModule,
    MatTreeModule
  ],
  declarations: [
    ProcessStructureComponent,
    ProcessTreeSingleSelectComponent,
    ProcessTreeNodeComponent,
    ProcessStructureStandaloneTreeComponent
  ],
  exports: [
    ProcessStructureComponent,
    ProcessStructureStandaloneTreeComponent
  ]
})
export class ProcessNavigationModule {
}
