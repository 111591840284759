import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dvtx-lazy-preview-loading-skeleton',
  templateUrl: './lazy-preview-loading-skeleton.component.html',
  styleUrls: ['./lazy-preview-loading-skeleton.component.scss']
})

export class LazyPreviewLoadingSkeletonComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {

  }
}
