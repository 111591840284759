import { ITaskComment } from './task-comment.interface';
import {CommentReaction, CommentReactionType} from '../comment/comment.interface';

export class TaskComment implements ITaskComment {
  readonly type = 'comment_records';
  reactionMap = {}
  reactionList = [];

  constructor(public id: string,
              public authorEmail: string,
              public content: string,
              public createdAt: Date,
              public updatedAt: Date,
              public seenBy: any[] = [],
              public reactions: CommentReaction[] = []) {}
}
