import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {DvtxControlValueAccessor} from '../../DvtxControlValueAccessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {TextInputCvaComponent} from '../text-input-cva/text-input-cva.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'dvtx-checkbox-cva',
  templateUrl: './checkbox-cva.component.html',
  styleUrls: ['./checkbox-cva.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxCvaComponent),
      multi: true,
    }
  ]
})
export class CheckboxCvaComponent extends DvtxControlValueAccessor implements OnInit {

  @Input()
  isChecked: boolean;

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {
  }

  writeValue(obj: boolean): void {
    this.isChecked = obj;
  }

  changeCheckbox($event: MatCheckboxChange) {
    this.isChecked = $event.checked;
    this.notifyOnChange(this.isChecked);
    this.notifyOnTouch();
  }
}
