import {Routes, RouterModule} from '@angular/router';
import {RegistrationComponent} from './registration.component';
import {RegistrationSetupComponent} from './components/registration-setup/registration-setup.component';
import {EmailExistsComponent} from './components/email-exists/email-exists';
import {NgModule} from '@angular/core';

const routes: Routes = [{
  path: '',
  component: RegistrationComponent,
  children: [
    {path: 'confirmation/:token', component: RegistrationSetupComponent},
    {path: 'exists', component: EmailExistsComponent}
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegistrationRoutes {
}
