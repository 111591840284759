<!-- <ul #lngSw id="lngSwitch">
  <li dvtxLocaleSw *ngFor="let locale of locales$ | async" [attr.data-lang]="locale.code">
    <a (click)="selectLanguage(locale.code)">
      <span class="flag-icon flag-icon-{{locale.code === 'de' ? 'de' : 'gb'}}"></span>
      {{ locale.code }}
    </a>
  </li>
</ul> -->

<!-- NOTE: The locale switcher is also used inline at 3rd party external view
           Here, the bottom border effect needs to be disabled and flags should be shown for usability.
           See variable showUnderlineOnHover and showText (default in page header).
           -->
<li class="nav-item nav-item-locale-switcher dropdown"
    [class.nav-item]="showUnderlineOnHover">
  <a class="nav-link dropdown-toggle account-drop-down"
     [class.dvtx-show-bottom-line-on-hover]="showUnderlineOnHover"
     style="padding-left: 6px"
     type="button"
     id="navbarDropdownMenuLink"
     data-toggle="dropdown"
     aria-haspopup="true"
     aria-expanded="true">
    <ng-template [ngIf]="showFlag" [ngIfElse]="showText">
      <span class="flag-icon flag-icon-{{selectedLang === 'de' ? 'de' : 'gb'}}"></span>
    </ng-template>
    <ng-template #showText>
      {{ selectedLang  | uppercase }}
    </ng-template>
  </a>

  <ul class="dropdown-menu dropdown-menu-right text-capitalize" aria-labelledby="navbarDropdownMenuLink"
    data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
    <li *ngFor="let locale of locales$ | async" class="dropdown-item-wrapper">
      <a (click)="selectLanguage(locale.code)" class="dropdown-item">
        <span class="flag-icon flag-icon-{{locale.code === 'de' ? 'de' : 'gb'}}"></span>
        &nbsp;<span style="text-transform: uppercase">{{ locale.code }}</span>
      </a>
    </li>

    <!--  Organization creation menu entry: NOTE: Removed. We only create organizations on manual onboardings by Sales -->
    <!--    <li class="dropdown-item-wrapper">-->
    <!--      <mat-icon>add_circle</mat-icon>-->
    <!--      <a class="dropdown-item" (click)="openCreateOrganizationDialog()">{{ 'BANNER.LINK.ORGANIZATION_CREATION_TITLE' | translate }}</a>-->
    <!--    </li>-->
  </ul>
</li>
