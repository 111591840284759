import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';
import {IContactRequestConfirm} from '../../modules/workflows/workflows/audit/kap/workflow-variations/models/subarea-verifier-request/step4/request-confirm-contact-interface';
import {RequestConfirmMockData} from '../../modules/workflows/workflows/audit/kap/workflow-variations/models/subarea-verifier-request/step4/request-confirm-contact.mock';


@Injectable()

export class SubareaVerifierRequestConfirmService {

  itemsObservable: BehaviorSubject<IContactRequestConfirm>;
  originalItems: IContactRequestConfirm = null;
  items: IContactRequestConfirm = null;

  constructor() {
    this.originalItems = RequestConfirmMockData;
    this.items = this.originalItems;

    this.itemsObservable = new BehaviorSubject<IContactRequestConfirm>(this.items);
  }

  updateSubject() {
    this.itemsObservable.next(this.items);
  }

  getSubareaVerifierRequest(id: String) {
    return this.itemsObservable.asObservable();
  }

  submitConfirmation(data) {
    console.log(data);
  }

}
