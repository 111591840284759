import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DatevClientEffects } from './datev-client.effects';
import { DatevClientService } from './datev-client.service';
import { reducer } from './datev-client.reducer';
import { stateKey } from './datev-client.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([DatevClientEffects])
  ],
  providers: [
    DatevClientEffects,
    DatevClientService
  ]
})
export class DatevClientStoreModule {
}
