import {Component, Input, Inject, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dvtx-split-view-container-dialog',
  templateUrl: './split-view-container-dialog.component.html',
  styleUrls: ['./split-view-container-dialog.component.scss']
})
export class SplitViewContainerDialogComponent {
  @Input() context;
  @Input() dynamicWidth = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    [this.context, this.dynamicWidth] = [data.context, data.dynamicWidth || this.dynamicWidth];
  }
}
