import * as dayjs from 'dayjs';

export interface IDueDatable {
  dueDate: Date;
  completedAt: Date;
}

export namespace DateUtil {
  export function inTime(elem: IDueDatable) {
    if (!elem.dueDate) {
      return true;
    }

    if (elem.completedAt) {
      return (dayjs(elem.completedAt).diff(dayjs(elem.dueDate))) < 0;
    }

    const now = dayjs();
    return (now.diff(dayjs(elem.dueDate))) < 0;
  }

  /**
   * [daysDiff description]
   * @method daysDiff
   * @param  dateA    [description]
   * @param  dateB    [description]
   * @return          [description]
   */
  export function daysDiff(dateA, dateB): number | null {
    if (!dateA || !dateB) return null;
    try {
      dateA = new Date(new Date(dateA).setHours(0, 0, 0)).getTime();
      dateB = new Date(new Date(dateB).setHours(0, 0, 0)).getTime();

      return Math.round((dateA - dateB) / (1000 * 60 * 60 * 24));;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * Returns true if d2 lays in the range of today or in future.
   * @param d1
   * @param d2
   */
  export function inFutureOrToday(d1: Date, d2: Date): boolean {
    if (!d1 || !d2) {
      return false;
    }
    if (d1 < d2) {
      return true;
    }
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  /**
   * Returns true if d2 lays in the range of today or in future.
   * @param d1
   * @param d2
   */
  export function todayOrInFuture(d1: Date, d2: Date): boolean {
    if (!d1 || !d2) {
      return false;
    }
    if (d1 < d2) {
      return true;
    }
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDay() === d2.getDay();
  }

  /**
   * Returns true if d2 lays in the range of today or past.
   * @param d1
   * @param d2
   */
  export function inPastOrToday(date1: Date, date2: Date): boolean {
    let d1 = date1;
    let d2 = date2;

    if (!d1 || !d2) {
      return false;
    }

    try {
      if (typeof d1 === 'string') {
        d1 = new Date(date1);
      }

      if (typeof d2 === 'string') {
        d2 = new Date(date2);
      }
    } catch (err) {
      console.error(err);
      return false;
    }

    if (!d1 || !d2 || !d1.getFullYear || !d2.getFullYear) {
      return false;
    }

    if (d1 > d2) {
      return true;
    }
    try {
      const res = d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
      return res;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
}
