import {Component, Input} from '@angular/core';
import {CsvColumn, CsvRow, GroupedCsv} from '../../services/csv-import.service';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'dvtx-csv-import-display',
  templateUrl: './csv-import-display.component.html',
  styleUrls: ['./csv-import-display.component.scss']
})
export class CsvImportDisplayComponent {
  @Input() public title: string;
  @Input() public children: CsvRow[] | GroupedCsv[];
  @Input() public columns: CsvColumn[];
  @Input() public allowHidingChildren = false;
  @Input() public errorForm: UntypedFormGroup;

  public isExpanded = true;

  public toggleExpanded() {
    if (this.allowHidingChildren) {
      this.isExpanded = !this.isExpanded;
    }
  }
}
