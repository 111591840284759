import {AppendixStatusEnum, ApplicableState} from './cac.interface';
import {ProcessArtifact} from '../process-artifact/process-artifact';
import {FileAttachment} from './cac';
import {IResource} from 'app/shared/modules/api/models/resource.interface';
import {IBomDataNode} from '../bom/bom-data-node.interface';

export class CacAppendix implements IBomDataNode {
  readonly type = 'audit_cac_appendix';
  attachments: FileAttachment[];
  closed = false;
  applicable: ApplicableState = ApplicableState.None;
  societyId: string = null;
  status: AppendixStatusEnum;
  processId: string;
  overdued: boolean;
  inTime = false;
  attachmentCount = 0;
  startedAt: Date;
  deliveredAt: Date;

  _contributedDocuments: ProcessArtifact[] = [];
  set contributedDocuments(c: ProcessArtifact[]) {
    this._contributedDocuments = c || [] ;
    if (c && c.length > 0) {
      this.setReceived();
    }
  }

  get contributedDocuments() {
    return this._contributedDocuments;
  }

  constructor(public id: string, public title: string, public dueDate: Date,
              public description: string = '', public checked: boolean = true,
              public reference: string = null, public createdAt = null, public updatedAt = null) {}

  clone(): CacAppendix {
    return new CacAppendix(null, this.title, this.dueDate, this.description, this.checked, this.reference);
  }

  setReceived() {
    if (this.status === AppendixStatusEnum.completed) {
      return;
    }
    this.status = AppendixStatusEnum.received;
  }

  static groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  static toApiStatus(status: AppendixStatusEnum) {
    switch (status) {
      case AppendixStatusEnum.sent:
        return 'published';
      case AppendixStatusEnum.in_progress:
        return 'in_progress';
      case AppendixStatusEnum.completed:
        return 'completed';
      case AppendixStatusEnum.closed:
        return 'closed';
    }
  }

  static toApiApplicability(applicability: ApplicableState) {
    switch (applicability) {
      case ApplicableState.Applicable:
        return true;
      case ApplicableState.NotApplicable:
        return false;
      default:
        return null;
    }
  }

  static initStatusMap() {
    return {
      sent: 0,
      completed: 0,
      not_applicable: 0,
      overdued: 0,
      closed: 0
    }
  }

  static calcStatusMap(appendix, statusMap) {
    const status = appendix.status;
    ++statusMap['sent'];

    if (status === AppendixStatusEnum.closed) {
      ++statusMap['closed'];
    } else if (appendix.applicable === ApplicableState.NotApplicable) {
      ++statusMap['not_applicable'];
    } else if (status === AppendixStatusEnum.completed || status === AppendixStatusEnum.in_progress) {
      ++statusMap['completed'];
      if (appendix.overdued) ++statusMap['overdued'];
    } else {
      if (appendix.overdued) ++statusMap['overdued'];
    }
  }
}

export class CacStatistics implements IResource {
  readonly type = 'cac_request_statistics';

  constructor(public id: string,
              public societies: number,
              public sent: number,
              public overdue: number,
              public notApplicable: number,
              public inProgress: number,
              public closed: number) {
  }
}
