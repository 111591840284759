import {Action} from '@ngrx/store';
import {TemplateLoadingScope, TemplateType, WorkflowTemplate} from './workflow-template';

export enum WorkflowTemplateActionTypes {
  Create = '[WorkflowTemplate] Create',
  CreateSuccess = '[WorkflowTemplate] Create Success',
  CreateFail = '[WorkflowTemplate] Create Fail',
  Delete = '[WorkflowTemplate] Delete',
  DeleteSuccess = '[WorkflowTemplate] Delete Success',
  DeleteFail = '[WorkflowTemplate] Delete Fail',
  LoadAll = '[WorkflowTemplate] Load All',
  LoadAllSuccess = '[WorkflowTemplate] Load All Success',
  LoadAllFail = '[WorkflowTemplate] Load All Fail',
  LoadOne = '[WorkflowTemplate] Load One',
  LoadOneSuccess = '[WorkflowTemplate] Load One Success',
  LoadOneFail = '[WorkflowTemplate] Load One Fail',
  Update = '[WorkflowTemplate] Update',
  UpdateSuccess = '[WorkflowTemplate] Update Success',
  UpdateFail = '[WorkflowTemplate] Update Fail',
  Apply = '[WorkflowTemplate] Apply',
}

export class Create implements Action {
  readonly type = WorkflowTemplateActionTypes.Create;

  constructor(public payload: any, public scope = '') {
  }
}

export class CreateSuccess implements Action {
  readonly type = WorkflowTemplateActionTypes.CreateSuccess;

  constructor(public template: WorkflowTemplate) {
  }
}

export class CreateFail implements Action {
  readonly type = WorkflowTemplateActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Delete implements Action {
  readonly type = WorkflowTemplateActionTypes.Delete;

  constructor(public id: string, public scope = '') {
  }
}

export class DeleteSuccess implements Action {
  readonly type = WorkflowTemplateActionTypes.DeleteSuccess;

  constructor(public template: WorkflowTemplate) {
  }
}

export class DeleteFail implements Action {
  readonly type = WorkflowTemplateActionTypes.DeleteFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = WorkflowTemplateActionTypes.LoadAll;

  constructor(public scope: TemplateLoadingScope, public templateType?: TemplateType) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = WorkflowTemplateActionTypes.LoadAllSuccess;

  constructor(public templates: WorkflowTemplate[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = WorkflowTemplateActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {

  readonly type = WorkflowTemplateActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = WorkflowTemplateActionTypes.LoadOneSuccess;

  constructor(public template: WorkflowTemplate) {
  }
}

export class LoadOneFail implements Action {
  readonly type = WorkflowTemplateActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Update implements Action {

  readonly type = WorkflowTemplateActionTypes.Update;

  constructor(public id: string, public payload: any) {
  }
}

export class UpdateSuccess implements Action {
  readonly type = WorkflowTemplateActionTypes.UpdateSuccess;

  constructor(public template: WorkflowTemplate) {
  }
}

export class UpdateFail implements Action {
  readonly type = WorkflowTemplateActionTypes.UpdateFail;

  constructor(public payload: any) {
  }
}

export class Apply implements Action {
  readonly type = WorkflowTemplateActionTypes.Apply;

  constructor(public id: string) {
  }
}


export type WorkflowTemplateActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Apply;
