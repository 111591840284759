<ng-template [ngIf]="!logo?.url" [ngIfElse]="brandedLogo">
  <a [routerLink]="homeLink" class="navbar-brand">
    <img [src]="defaultLogoUrl" class="d-block" alt="5F Logo">
  </a>
</ng-template>

<ng-template #brandedLogo>
  <a [routerLink]="homeLink" class="navbar-brand">
    <img [attr.src]="logo?.url" class="d-block" alt="5F">
  </a>
</ng-template>
