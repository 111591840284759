import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Process} from './process';
import {Sorters} from 'app/lib/sorter/sorters';

export interface State extends EntityState<Process> {
  selected: Process;
  loading: boolean;
  authorized: boolean;
  sortComparer: any;
  paginationEnd: number;
  selectedLoading: boolean;
}

export const adapter = createEntityAdapter<Process>({
  sortComparer: (l, r) => initialState.sortComparer(l, r)
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
  authorized: true,
  sortComparer: (l: Process, r: Process) => Sorters.sortByDates(l.createdAt, r.createdAt, false),
  paginationEnd: 9,
  selectedLoading: false
});
