import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RegistrationUser} from '../../models/registration-user.interface';

@Component({
  selector: 'dvtx-status-checklist',
  templateUrl: './status-checklist.component.html',
  styleUrls: ['./status-checklist.component.scss']
})
export class StatusChecklistComponent implements OnInit {
  @Input() user: RegistrationUser;
  @Input() dmsAccountPresent = false;
  @Input() accountCompleted = false;
  @Output() onNext = new EventEmitter();
  @Output() onBack = new EventEmitter();

  constructor() { }

  ngOnInit() {

  }
}
