import {Component, Inject, OnInit} from '@angular/core';
import {ProjectRoomAdmin} from "app/+store/process-role/process-role";
import {OrganizationProxyService} from "app/+store/organization/organization-proxy.service";
import {NotificationService} from "app/shared/modules/notification/services/notification.service";
import {AppState} from "app/reducers";
import {TranslateService} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {ProcessRoleService} from 'app/+store/process-role/process-role.service';
import {ProcessParticipantService} from 'app/+store/process-participant/process-participant.service';
import {first, map} from "rxjs/operators";
import {NaturalPerson} from "app/models/natural-person.model";
import {UntypedFormControl} from "@angular/forms";
import {MembershipActions, NaturalPersonSelectors} from "app/+store";
import {BehaviorSubject, combineLatest, Observable, of} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'dvtx-member-deletion-dialog',
  templateUrl: './member-deletion-dialog.component.html',
  styleUrls: ['./member-deletion-dialog.component.scss']
})
export class MemberDeletionDialogComponent implements OnInit {
  currentUserUID;
  projectAdminMap = {};
  selectedMemberForRemoval;
  selectedMemberShipForRemoval;
  selectedMemberEmailForRemoval;
  selectedOwner: NaturalPerson;
  ownerFilter$ = new BehaviorSubject(null);
  ownerSearchField: UntypedFormControl;
  organization;

  constructor(
    public dialogRef: MatDialogRef<MemberDeletionDialogComponent>,
    protected store: Store<AppState>,
    private _orgProxySvc: OrganizationProxyService,
    private _translateSvc: TranslateService,
    private _notifyService: NotificationService,
    private _participantSvc: ProcessParticipantService,
    private _processRoleSvc: ProcessRoleService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.organization = data.organization
    this.selectedMemberForRemoval= data.selectedMemberForRemoval
    this.selectedMemberShipForRemoval= data.selectedMemberShipForRemoval
    this.selectedMemberEmailForRemoval= data.selectedMemberEmailForRemoval
    this.currentUserUID= data.currentUserUID
  }

  ngOnInit() {
        this.ownerSearchField = new UntypedFormControl();
    this._loadProjectRoomAdmins();
  }

  public removeMember(): void {
    if (!this.selectedOwner || !this.selectedMemberForRemoval || !this.selectedMemberShipForRemoval || !this.selectedMemberEmailForRemoval) return;
    this.store.dispatch(new MembershipActions.RemoveOne(
      this.organization,
      this.selectedMemberForRemoval,
      this.selectedMemberShipForRemoval,
      this.selectedMemberEmailForRemoval,
      this.selectedOwner.mainEmailAddress.emailAddress
    ));
    this.dialogRef.close();
  }

  private _loadProjectRoomAdmins(): void {
    this._processRoleSvc.getProjectRoomAdmins()
      .pipe(first())
      .subscribe((admins: ProjectRoomAdmin[]) => {
        const projectAdminMap = {};
        admins.forEach(admin => {
          projectAdminMap[admin.email] = admin;
        });
        this.projectAdminMap = projectAdminMap;
      });
  }

  public selectNewOwner($event: MatSelectChange) {
    this.selectedOwner = $event.value;
    this.resetPersonSearch();
  }

  public resetPersonSearch() {
    this.ownerFilter$.next(null);
    this.ownerSearchField.reset();
  }

  public ownerSelectionChanged($event) {
    if (!$event) {
      this.ownerFilter$.next(null);
      this.ownerSearchField.reset();
    }
  }

  public handleKeydown(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }
  }

  public onInputChange($event) {
    this.ownerFilter$.next($event);
  }

  getMembersWithoutSelected(email): Observable<NaturalPerson[]> {
    if (!email) return of([])

    const members$ = this.store.select(NaturalPersonSelectors.getNaturalPersonsOfSelectedOrganization)
      .pipe(
        map((members: NaturalPerson[]): NaturalPerson[] => {
          if (!members) return [];
          return members.filter((member: NaturalPerson) => !!member && member.mainEmailAddress.emailAddress !== email);
        }));
    return combineLatest(this.ownerFilter$, members$)
      .pipe(
        map(([query, members]: [string, NaturalPerson[]]): NaturalPerson[] => {
          if (!query) return members;

          const _query = query.toLowerCase();
          return members.filter(member => {
            const _email = member.mainEmailAddress.emailAddress.toLocaleLowerCase()
            if (_email.indexOf(_query) >= 0) return true;
            const name = member.name.toLocaleLowerCase()
            return (name.indexOf(_query) >= 0);
          });
        })
      );
  }
}
