import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './task-assignee.state';
import {TaskAssignmentStatus} from './task-assignee.interface';

export const stateKey = 'task-assignee';
const getTaskAssigneeState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getTaskAssigneeEntities,
  selectAll: getAllTaskAssignees,
} = adapter.getSelectors(getTaskAssigneeState);

export const getPendingAssignments = createSelector(
  getAllTaskAssignees,
  (entities) => entities.filter(assignee => {
    return assignee.status === TaskAssignmentStatus.Pending;
  })
);

export const getAssignmentById = (id: string) => createSelector(
  getTaskAssigneeEntities,
  (entities) => entities[id]
);

export const getAssignments = (taskId: string) =>  createSelector(
  getAllTaskAssignees,
  (entities) => entities.filter(assignee => {
    return assignee.taskId === taskId;
  })
);

export const getLoadingState = createSelector(
  getTaskAssigneeState,
  state => state.loading
);
