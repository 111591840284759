import {CommentActions, CommentActionTypes} from './comment.actions';
import {adapter, initialState, State} from './comment.state';

export function reducer(state = initialState, action: CommentActions): State {
  switch (action.type) {
    case CommentActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case CommentActionTypes.LoadAllSuccess:
      return adapter.setAll(action.comments, {
          ...state,
          selected: null,
          loading: false,
      });
    case CommentActionTypes.SaveComment:
      return {
        ...state,
        loading: true
      };
    case CommentActionTypes.SaveCommentSuccess:
      return adapter.upsertOne(action.comment, {
        ...state,
        selected: (state.selected && state.selected.id === action.comment.id) ? action.comment : state.selected,
        loading: false,
      });
    case CommentActionTypes.SaveCommentFail:
      return {
        ...state,
        loading: false,
      };
    case CommentActionTypes.LoadOne:
      return {
        ...state,
        loading: false
      };
    case CommentActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.comment, {
        ...state,
        selected: (state.selected && state.selected.id === action.comment.id) ? action.comment : state.selected,
        loading: false,
      });
    case CommentActionTypes.LoadOneFail:
      return {
        ...state,
        loading: false,
      };
    case CommentActionTypes.SendComment:
      return {
        ...state,
        loading: false,
        creating: true
      };
    case CommentActionTypes.SendCommentSuccess:
      return adapter.addOne(action.comment, {
        ...state,
        selected: (state.selected && state.selected.id === action.comment.id) ? null : state.selected,
        loading: false,
        creating: false
      });
    case CommentActionTypes.SendCommentFail:
      return {
        ...state,
        loading: false,
        creating: false
      };
    case CommentActionTypes.DeleteComment:
      return {
        ...state,
        loading: true
      };
    case CommentActionTypes.DeleteCommentSuccess:
      return adapter.removeOne(action.commentId, {
        ...state,
        selected: (state.selected && state.selected.id === action.commentId) ? null : state.selected,
        loading: false,
      });

    case CommentActionTypes.Read:
      return {
        ...state,
        loading: false,
        creating: false,
        updating: true
      };
    case CommentActionTypes.ReadSuccess:
      return adapter.upsertOne(action.comment, {
        ...state,
        loading: false,
        creating: false,
        updating: false
      });
    case CommentActionTypes.LoadProcessStatistics:
      return {
        ...state
      };
    case CommentActionTypes.LoadProcessStatisticsSuccess:
      const newStats = Object.assign({}, state.stats);
      newStats[action.stats.id] = action.stats.commentCounts;
      return {
        ...state,
        stats: newStats
      };
    case CommentActionTypes.LoadProcessStatisticsFail:
      return {
        ...state
      };
  }
  return state;
}
