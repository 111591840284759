import {map, switchMap} from 'rxjs/operators';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import * as contactsActions from '../actions/contacts.actions';
import {ContactService} from '../services/contact.service';
import {IContact} from '../models/contact.interface';


@Injectable()
export class ContactsEffects {
  @Effect()
  getContacts$: Observable<Action> = this.actions$
    .pipe(
      ofType(contactsActions.GET_CONTACTS),
      switchMap((action: contactsActions.GetContacts) => {
        return this.contactService.getAll();
      }),
      map((contacts: IContact[]) => {
        return new contactsActions.GetContactsSuccess(contacts);
      }),);

  @Effect()
  createContact$: Observable<Action> = this.actions$
    .pipe(
      ofType(contactsActions.CREATE_CONTACT),
      switchMap((action: contactsActions.CreateContact) => {
        return this.contactService.create(action.payload);
      }),
      map((contact: IContact) => {
        return new contactsActions.CreateContactSuccess(contact);
      }),);

  @Effect()
  getContact$: Observable<Action> = this.actions$
    .pipe(
      ofType(contactsActions.GET_CONTACT),
      switchMap((action: contactsActions.GetContact) => {
        return this.contactService.get(action.payload);
      }),
      map((contact: IContact) => {
        return new contactsActions.GetContactSuccess(contact);
      }),);

  @Effect()
  deleteContact$: Observable<Action> = this.actions$
    .pipe(
      ofType(contactsActions.DELETE_CONTACT),
      switchMap((action: contactsActions.DeleteContact) => {
        return this.contactService.delete(action.payload);
      }),
      map((contact: IContact) => {
        return new contactsActions.DeleteContactSuccess(contact);
      }),);

  @Effect()
  updateContact$: Observable<Action> = this.actions$
    .pipe(
      ofType(contactsActions.UPDATE_CONTACT),
      switchMap((action: contactsActions.UpdateContact) => {
        return this.contactService.update(action.payload);
      }),
      map((contact: IContact) => {
        return new contactsActions.UpdateContactSuccess(contact);
      }),);

  constructor(private contactService: ContactService,
              private actions$: Actions) {
  }
}
