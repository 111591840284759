<div class="w-100 dvtx-client-overview">
  <div class="w-100 pb-2" *ngIf="enableToolbar">
    <form>
      <div class="d-flex justify-content-between align-items-center">
        <div class="five-f-mat-input five-f-search">
          <mat-form-field class="five-f-search" (keydown.enter)="$event.preventDefault()">
            <input type="text" [placeholder]="'GENERAL.SEARCH' | translate"
                   (keyup)="applySearch()" matInput #searchField>
            <mat-icon matPrefix>search</mat-icon>
            <button mat-button *ngIf="searchField?.value" matSuffix mat-icon-button  color="gray" [disableRipple]="true"
                    aria-label="Clear" (click)="resetSearch(); $event.stopPropagation()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div>
          <dvtx-client-selection [action]="ClientActionType.CreateRaisedButton"
                                 [readonly]="false"
                                 (onCreateSuccess)="createSuccess($event)"></dvtx-client-selection>
        </div>
      </div>
    </form>
  </div>

  <dvtx-client-table [clients]="data$ | async"
                     [featureSet]="featureSet$ | async"
                     [organization]="organization"
                     (onDelete)="removeClient($event)"
                     (onEditSuccess)="editSuccess($event)"></dvtx-client-table>

  <ng-container *ngIf="loading$ | async; else more">
    <div class="w-100">
      <div class="dvtx-fg-color dvtx-fg-fivef-blue-300 dvtx-loading-indicator">
        {{ 'GENERAL.LOAD_ITEMS' | translate }}<span>.</span><span>.</span><span>.</span>
      </div>
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  </ng-container>

  <ng-template #more>
    <div class="w-100 d-flex justify-content-end">
      <ng-container *ngIf="page < pages">
        <div class="infinite-scroll-zone"
             inViewport [inViewportOptions]="{ threshold: 0 }"
             (inViewportAction)="loadMore($event)">&#160;</div>
      </ng-container>
    </div>
  </ng-template>
</div>

<div *ngIf="!(loading$ | async) && (data$ | async)?.length === 0" class="w-100 dvtx-no-items">
  <div class="no-items-found">
    <mat-icon svgIcon="clients"></mat-icon>
    <h3>{{'PROJECT_ROOM.NO_ITEMS_MATCH_SEARCH' | translate}}</h3>
  </div>
</div>
