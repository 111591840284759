import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Invitation} from './invitation.model';

export interface State extends EntityState<Invitation> {
  loading: boolean;
}

export const adapter = createEntityAdapter<Invitation>();

export const initialState: State = adapter.getInitialState({
  loading: false
});
