<div class="mat-dialog-inner-wrap">
  <div class="mat-dialog-header">
    <h2 mat-dialog-title>
      {{ 'UPLOAD.UPLOADER_DIALOG_TITLE' | translate }}
    </h2>
    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <dvtx-quick-upload [uploader]="uploader" [form]="form" [dmsPathChooser]="dmsPathChooser"
                       (onRemove)="removeFile($event)" (onSubmit)="submit()"></dvtx-quick-upload>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-end">
    <button type="reset" mat-button mat-dialog-close>
      {{ 'GENERAL.CANCEL_ACTION' | translate }}
    </button>
    <button type="submit" [disabled]="form.pristine || !uploader.getNotUploadedItems().length || getInvalidFiles().length > 0 || !form.valid" mat-raised-button color="primary" (click)="submit()">
      {{ 'UPLOAD.UPLOAD_BUTTON' | translate }}
    </button>
  </div>
</div>
