import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {MilestoneEffects} from './milestone.effects';
import {MilestoneService} from './milestone.service';
import {reducer} from './milestone.reducer';
import {stateKey} from './milestone.selectors';
import {MilestoneApiResourceService} from './milestone-api-resource.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([MilestoneEffects])
  ],
  providers: [
    MilestoneEffects,
    MilestoneApiResourceService,
    MilestoneService
  ]
})
export class MilestoneStoreModule {
}
