import {DocumentPreviewActions, DocumentPreviewActionTypes} from './document-preview.actions';
import {adapter, initialState, State} from './document-preview.state';

export function reducer(state = initialState, action: DocumentPreviewActions): State {
  switch (action.type) {
    case DocumentPreviewActionTypes.RequestPreviews:
      return {
        ...state,
        loading: true
      };
    case DocumentPreviewActionTypes.RequestPreviewsSuccess:
      return adapter.upsertMany(action.previews, {
        ...state,
        loading: false,
      });
    case DocumentPreviewActionTypes.RequestPreviewsFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

