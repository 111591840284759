import {Action} from '@ngrx/store'
import {Organization} from '../../models/organization.model';
import {ContactOrganizationBaseForm} from '../../modules/organization/models/contact-organization-base-form';

export enum OrganizationActionTypes {
  Create = '[Organization] Create',
  LoadAll = '[Organization] Load All',
  LoadAllSuccess = '[Organization] Load All Success',
  LoadAllFail = '[Organization] Load All Fail',
  UpdateOrga = '[Organization] Update All',
  UpdateOrgaSuccess = '[Organization] Update Success',
  UpdateOrgaFail = '[Organization] Update Fail',
  LoadDetailed = '[Organization] Load Detailed',
  LoadDetailedSuccess = '[Organization] Load Detailed Success',
  LoadDetailedFail = '[Organization] Load Detailed Fail',
  Select = '[Organization] Select',
  SelectSuccess = '[Organization] Select Success',
  SelectAddressBook = '[Organization] Select AddressBook',
  SelectAddressBookSuccess = '[Organization] Select AddressBook Success'
}

export class Create implements Action {
  readonly type = OrganizationActionTypes.Create

  constructor(public payload: ContactOrganizationBaseForm) {
  }
}


export class LoadAll implements Action {
  readonly type = OrganizationActionTypes.LoadAll
}

export class LoadAllSuccess implements Action {
  readonly type = OrganizationActionTypes.LoadAllSuccess;

  constructor(public payload: Organization[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = OrganizationActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class UpdateOrga implements Action {
  readonly type = OrganizationActionTypes.UpdateOrga;

  constructor(public payload: Organization) {
  }
}

export class UpdateOrgaSuccess implements Action {
  readonly type = OrganizationActionTypes.UpdateOrgaSuccess;

  constructor(public payload: Organization) {
  }
}

export class UpdateOrgaFail implements Action {
  readonly type = OrganizationActionTypes.UpdateOrgaFail;

  constructor(public payload: any) {
  }
}

export class LoadDetailed implements Action {
  readonly type = OrganizationActionTypes.LoadDetailed;

  constructor(public orgaId: string) {
  }
}

export class LoadDetailedSuccess implements Action {
  readonly type = OrganizationActionTypes.LoadDetailedSuccess;

  constructor(public payload: Organization) {
  }
}

export class LoadDetailedFail implements Action {
  readonly type = OrganizationActionTypes.LoadDetailedFail;

  constructor(public payload: any) {
  }
}

export class Select implements Action {
  readonly type = OrganizationActionTypes.Select;

  constructor(public id: string) {
  }
}

export class SelectSuccess implements Action {
  readonly type = OrganizationActionTypes.SelectSuccess;

  constructor(public id: string) {
  }
}

export class SelectAddressBook implements Action {
  readonly type = OrganizationActionTypes.SelectAddressBook;

  constructor(public id: string) {
  }
}

export class SelectAddressBookSuccess implements Action {
  readonly type = OrganizationActionTypes.SelectAddressBookSuccess;

  constructor(public id: string) {
  }
}

export type OrganizationActions =
  | Create
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Select
  | SelectSuccess
  | LoadDetailed
  | LoadDetailedSuccess
  | LoadDetailedFail
  | UpdateOrga
  | UpdateOrgaFail
  | UpdateOrgaSuccess
  | SelectAddressBook
  | SelectAddressBookSuccess;
