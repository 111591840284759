import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'dvtx-remaining-days-counter',
  templateUrl: './remaining-days-counter.component.html',
  styleUrls: ['./remaining-days-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RemainingDaysCounterComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();
  private startDate$ = new BehaviorSubject<Date>(null);
  private retentionDays$ = new BehaviorSubject<number>(null);

  public remainingDays: number = null;

  @Input()
  private set startDate(s: Date) {
    this.startDate$.next(s);
  }

  @Input()
  private set retentionDays(r: number) {
    this.retentionDays$.next(r);
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    combineLatest(this.startDate$, this.retentionDays$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([maybeStartDate, retention]) => {
        if (maybeStartDate && retention) {
          const start = new Date(maybeStartDate);
          const deletionDate = new Date(start.getTime() + (1000 * 60 * 60 * 24) * retention);
          const diff = Math.abs(deletionDate.getTime() - new Date().getTime());
          this.remainingDays = Math.ceil(diff / (1000 * 3600 * 24));
          this.cdr.detectChanges();
        }
      })
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.startDate$.complete();
    this.retentionDays$.complete();
  }
}
