import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './collector-template.state';

export const stateKey = 'collector-template';
const getCollectorTemplateState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getCollectorTemplateEntities,
  selectAll: getAllCollectorTemplates,
} = adapter.getSelectors(getCollectorTemplateState);

export const getSelected = createSelector(
  getCollectorTemplateState,
  (state) => state.selected
);

export const getAll = createSelector(
  getAllCollectorTemplates,
  (entities) => entities
);

export const loadingState = createSelector(
  getCollectorTemplateState,
  (state) => state.loading
);
