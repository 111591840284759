import { Injectable } from '@angular/core';
import { ApiResourceService } from 'app/shared/modules/api-resource/services/api-resource.service';
import { Observable } from 'rxjs';
import { FibuBookmanAccountBuilder } from './fibu-bookman-account.builder';
import { FibuBookmanAccount } from './fibu-bookman-account';

@Injectable()
export class FibuBookmanAccountService {
  readonly basePath = 'api/v1/fibu/accounts';

  constructor(private _http: ApiResourceService) {
  }

  signInFibuBookmanAccount(credentials) {
    const builder = new FibuBookmanAccountBuilder();
    const payload = {
      data: {
        attributes: {
          email: credentials.email,
          password: credentials.password
        }
      }
    };
    return <Observable<FibuBookmanAccount>>this._http.post<FibuBookmanAccountBuilder, FibuBookmanAccount>(builder, `${this.basePath}`, payload);
  }

  signOutFibuBookmanAccount() {
    const builder = new FibuBookmanAccountBuilder();
    return <Observable<FibuBookmanAccount>>this._http.del<FibuBookmanAccountBuilder, FibuBookmanAccount>(builder, `${this.basePath}`);
  }


  getFibuBookmanAccount() {
    const builder = new FibuBookmanAccountBuilder();
    return <Observable<FibuBookmanAccount>>this._http.get<FibuBookmanAccountBuilder, FibuBookmanAccount>(builder, `${this.basePath}`);
  }

}
