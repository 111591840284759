import {CollectorCategoryActions, CollectorCategoryActionTypes} from './collector-category.actions';
import {adapter, initialState, State} from './collector-category.state';
import {Update} from '@ngrx/entity';
import {CollectorCategory} from './collector-category';

export function reducer(state = initialState, action: CollectorCategoryActions): State {
  const current = state.selected;
  switch (action.type) {
    /*
      LOAD ALL REDUCERS
    */
    case CollectorCategoryActionTypes.LoadAllSuccess:
      return adapter.setAll(action.categories, {
        ...state,
        selected: null,
        loading: false,
      });
    case CollectorCategoryActionTypes.UpsertAllSuccess:
      return adapter.upsertMany(action.categories, {
        ...state,
        selected: null,
        loading: false,
      });

    /*
      CREATE REDUCERS
    */
    case CollectorCategoryActionTypes.Create:
      return {
        ...state,
        creating: true
      };
    case CollectorCategoryActionTypes.CreateSuccess:
      return adapter.upsertOne(action.category, {
        ...state,
        selected: action.category,
        creating: false,
      });
    case CollectorCategoryActionTypes.CreateFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        creating: false,
      };

    /*
      SAVE REDUCERS
    */
    case CollectorCategoryActionTypes.Save:
      return {
        ...state,
        updating: true
      };
    case CollectorCategoryActionTypes.SaveSuccess:
      return adapter.upsertOne(action.category, {
        ...state,
        selected: action.category,
        updating: false,
      });
    case CollectorCategoryActionTypes.SaveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        updating: false,
      };

    /*
      REMOVE REDUCERS
    */
    case CollectorCategoryActionTypes.Remove:
      return {
        ...state,
        removing: true
      };
    case CollectorCategoryActionTypes.RemoveSuccess:
      return adapter.removeOne(action.category.id, {
        ...state,
        selected: (current && current.id === action.category.id) ?  null : current,
        removing: false,
      });
    case CollectorCategoryActionTypes.RemoveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        removing: false,
      };

    /*
      STATUS REDUCERS
    */
    case CollectorCategoryActionTypes.Status:
      return {
        ...state,
        updating: true
      };
    case CollectorCategoryActionTypes.StatusSuccess:
      return adapter.upsertOne(action.category, {
        ...state,
        selected: action.category,
        updating: false,
      });
    case CollectorCategoryActionTypes.StatusFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        updating: false,
      };
    /*
      COPY REDUCERS
    */
    case CollectorCategoryActionTypes.Copy:
      return {
        ...state,
        selected: null,
        loading: false
      };
    case CollectorCategoryActionTypes.CopySuccess:
      return adapter.addOne(action.category, {
        ...state,
        selected: null,
        loading: false,
      });
    case CollectorCategoryActionTypes.CopyFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
    case CollectorCategoryActionTypes.Reset:
      return Object.assign({}, initialState);
  }
  return state;
}
