import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Fastdocs} from './fastdocs';

export interface State extends EntityState<Fastdocs> {
  selected?: Fastdocs
  loading: boolean
}

export const adapter = createEntityAdapter<Fastdocs>({
  sortComparer: Fastdocs.sortByOrder
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false
});
