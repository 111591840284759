<div style="border-left: 10px solid #db0000">
  <div class="mat-dialog-header">
    <h2 mat-dialog-title>{{ 'ORGANIZATION.REMOVE_MEMBER' | translate }}</h2>
  </div>
  <div mat-dialog-content>
    <div class="d-flex flex-column" style="max-width: 700px;">

      <div class="d-flex justify-content-center mb-1">
        <mat-icon aria-hidden="true" class="dialog-top-icon" style="color: #db0000">warning</mat-icon>
      </div>
      <div class="w-100 d-flex flex-column dvtx-organization-employee-removal-dialog">
        <div class="d-flex">
          <h2>{{ 'ORGANIZATION.REMOVE_MEMBER_DIALOG_TITLE' | translate }}</h2>
        </div>
        <div class="mb-2 d-flex">
          <h4>{{ 'ORGANIZATION.REMOVE_MEMBER_DIALOG_SUB_TITLE' | translate }}</h4>
        </div>

        <div class="d-flex flex-column mb-2">
          <div class="d-flex align-items-center">
            <div>
              <mat-icon class="status-color-success dvtx-inline-valign mr-2">check_circle</mat-icon>
            </div>
            <div>{{ 'ORGANIZATION.MEMBER_DELETION_INFO_1' | translate }}</div>
          </div>

          <div class="d-flex align-items-center">
            <div>
              <mat-icon class="status-color-success dvtx-inline-valign mr-2">check_circle</mat-icon>
            </div>
            <div>{{ 'ORGANIZATION.MEMBER_DELETION_INFO_2' | translate }}</div>
          </div>

          <div class="d-flex align-items-center">
            <div>
              <mat-icon class="status-color-success dvtx-inline-valign mr-2">check_circle</mat-icon>
            </div>
            <div>{{ 'ORGANIZATION.MEMBER_DELETION_INFO_3' | translate }}</div>
          </div>
        </div>

        <div *ngIf="projectAdminMap[currentUserUID]" class="d-flex mb-2">
          <button (click)="data.toMemberProjectRooms(selectedMemberEmailForRemoval)" color="primary"
                  mat-button>
            {{ 'PROJECT_ROOM.TO_PROJECT_ROOMS' | translate }}
            <mat-icon mataffix>arrow_forward</mat-icon>
          </button>
        </div>

        <div class="d-flex mb-1">
          <h4>{{ 'ORGANIZATION.SELECT_NEW_PROCESS_OWNER' | translate }}</h4>
        </div>
        <div class="w-100 d-flex dvtx-organization-employee-removal-dialog__owner-selection">
          <mat-form-field style="width: 100%; max-width: 400px;">
            <mat-icon matPrefix>person</mat-icon>
            <mat-select (openedChange)="ownerSelectionChanged($event)"
                        (selectionChange)="selectNewOwner($event)"
                        [value]="selectedOwner"
                        class="persons--filter"
                        placeholder="{{ 'ORGANIZATION.SELECT_NEW_OWNER' | translate }}">
              <mat-select-trigger *ngIf="selectedOwner" class="persons--filter--selected">
                {{ selectedOwner?.name }} <span
                class="dvtx-fg-color dvtx-fg-fivef-blue-300">({{ selectedOwner?.mainEmailAddress?.emailAddress }}
                )</span>
              </mat-select-trigger>

              <div class="persons-filter-search d-flex px-3 align-items-center">
                <mat-icon matPrefix>search</mat-icon>
                <input #searchSelectInput
                       (keydown)="handleKeydown($event)"
                       (keyup)="onInputChange($event.target.value)"
                       [formControl]="ownerSearchField"
                       [placeholder]="'GENERAL.SEARCH' | translate"
                       autocomplete="new-search" class="mat-select-search-input"
                       matInput
                       name="search"/>
                <mat-icon (click)="resetPersonSearch()"
                          *ngIf="searchSelectInput?.value"
                          matSuffix>close
                </mat-icon>
              </div>
              <hr class="m-0"/>
              <mat-option
                *ngFor="let member; let i = index; of (getMembersWithoutSelected(selectedMemberEmailForRemoval) | async)"
                [value]="member"
                class="persons-filter-option" style="line-height: 1em !important; height: 3em !important;">
                {{ member.name }}
                <br>
                <span
                  class="dvtx-fg-color dvtx-fg-fivef-blue-300"><small>{{ member.mainEmailAddress.emailAddress }}</small></span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-end">
    <div class="mr-2">
      <button mat-button mat-dialog-close>
        {{ 'GENERAL.CANCEL_ACTION' | translate }}
      </button>
    </div>

    <div>
      <dvtx-simple-confirm (onConfirm)="removeMember()"
                           [confirmButtonText]="'ADDRESSBOOK.CONFIRM_DELETION'"
                           [disabled]="!selectedOwner">
        <button [disabled]="!selectedOwner" color="warn" mat-raised-button>
          {{ 'GENERAL.CONFIRM_ACTION' | translate }}
        </button>
      </dvtx-simple-confirm>
    </div>
  </div>
</div>
