import { Component, Input } from '@angular/core';
import { AvatarService } from 'app/shared/modules/user-account/components/avatar/avatar.service';
import { Observable ,  BehaviorSubject } from 'rxjs';

@Component({
  selector: 'dvtx-message-panel-info-dropdown',
  templateUrl: './message-panel-info-dropdown.component.html',
  styleUrls: ['./message-panel-info-dropdown.component.scss']
})
export class MessagePanelInfoDropdownComponent {
  _persons: BehaviorSubject<any[]> = new BehaviorSubject([]);
  showDropdown = false;

  @Input() set persons(persons) {
    // NOTE: Array check as guard and for backwards compability
    let res = [];
    if (Array.isArray(persons)) {
      res = persons.filter(p => !!p && p.email)
    } else {
      const tmp = [persons];
      res = tmp.filter(p => !!p && p.email)
    }

    this._persons.next(res);
  };
  @Input() showAvatarTitle;
  @Input() showTitle = false;
  @Input() title;
  @Input() icon;


  constructor(private avatarService: AvatarService) {
  }
}
