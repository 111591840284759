import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';




import { IPartner, IAudit, IAuditPerson } from '../../modules/workflows/workflows/audit/kap/workflow-variations/models/audit/audit.model';
import {
  auditorContactList, clientContactList, teamContactList, auditorFromBookContactList, auditorFromBookContactTeamList, auditorFromBookContactClientList
} from '../../modules/workflows/workflows/audit/kap/workflow-variations/models/audit/audit.mock';
import {RequestAuditList, RequestClientList, partnerList} from '../../modules/workflows/workflows/audit/kap/workflow-variations/models/audit/audit.mock';


@Injectable()

export class AuditCreateSelectClientService {

  public itemsObservable$: BehaviorSubject<IAudit[]>;
  private originalItems: IAudit[] = [];
  private items: IAudit[] = [];

  public clientItemsObservable$: BehaviorSubject<IAudit[]>;
  private clientItems: IAudit[] = [];

  private filterSource$: BehaviorSubject<object>;
  public filter$: Observable<object>;

  private partnerList: IPartner[] = partnerList;

  private auditorContactList: IAuditPerson[] = [];
  public auditorContactListObservable$: BehaviorSubject<IAuditPerson[]>;
  private clientContactList: IAuditPerson[] = [];
  public clientContactListObservable$: BehaviorSubject<IAuditPerson[]>;
  private teamContactList: IAuditPerson[] = [];
  public teamContactListObservable$: BehaviorSubject<IAuditPerson[]>;

  private auditorFromBookContactList = auditorFromBookContactList;
  private auditorFromBookContactListTeam = auditorFromBookContactTeamList;
  private auditorFromBookContactListClient = auditorFromBookContactClientList;

  constructor() {
    this.originalItems = RequestAuditList;
    this.items = this.originalItems;
    this.itemsObservable$ = new BehaviorSubject<IAudit[]>(this.items);

    this.filterSource$ = new BehaviorSubject([]);
    this.filter$ = this.filterSource$.asObservable();

    this.clientItems = RequestClientList;
    this.clientItemsObservable$ = new BehaviorSubject<IAudit[]>(this.clientItems);

    this.auditorContactList = auditorContactList;
    this.clientContactList = clientContactList;
    this.teamContactList = teamContactList;
    this.auditorContactListObservable$ = new BehaviorSubject<IAuditPerson[]>(this.auditorContactList);
    this.clientContactListObservable$ = new BehaviorSubject<IAuditPerson[]>(this.clientContactList);
    this.teamContactListObservable$ = new BehaviorSubject<IAuditPerson[]>(this.teamContactList);
  }

  setFilterData(obj) {
    this.filterSource$.next(obj);
    this.runFilter();
  }

  runFilter() {
    const arrayOfFilters: any = this.filterSource$.value;
    if (arrayOfFilters.length > 0) {
      this.items = this.originalItems.filter(
        (item) => {
          let flag = false;
          for ( const x of arrayOfFilters) {
            flag = flag || item.companyName.toLowerCase().indexOf(x.name.toLowerCase()) > -1 || item.requestDate.indexOf(x.name) > -1
          }
          return flag
        }
      );
    } else {
      this.items = this.originalItems
    }
    this.updateSubject();
  }

  updateSubject() {
    this.itemsObservable$.next(this.items);
  }

  // popups CreateForm
  getAuditPartnerList() {
    return this.partnerList;
  }

  getAvailableAuditorContactList() {
    return this.auditorContactList;
  }

  getAvailableTeamContactList() {
    return this.teamContactList;
  }

  getAvailableClientContactList() {
    return this.clientContactList;
  }

  getContactsFromAddressBook(type) {
    switch (type) {
      case 'auditor':
        return this.auditorFromBookContactList;
      case 'team':
        return this.auditorFromBookContactListTeam;
      case 'client-contact':
        return this.auditorFromBookContactListClient;
    }
  }

  submitNewAuditContact(item, type) {
    switch (type) {
      case 'auditor':
        this.auditorContactList.push({
          name: item.firstName + ' ' + item.lastName,
          value: item.email
        });
        this.auditorContactListObservable$.next(this.auditorContactList);
        break;
      case 'team':
        this.teamContactList.push({
          name: item.firstName + ' ' + item.lastName,
          value: item.email
        });
        this.teamContactListObservable$.next(this.teamContactList);
        break;
      case 'client-contact':
        this.clientContactList.push({
          name: item.firstName + ' ' + item.lastName,
          value: item.email
        });
        this.clientContactListObservable$.next(this.clientContactList);
        break;
      default:
        console.error('unknown type of audit');
    }
  }

  submitNewAudit(item) {
    console.log(item);
  }
}
