import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { ProcessDialogModule } from "app/modules/workflow-engine/modules/process-dialog/process-dialog.module";
import { FilePickerModule } from "app/modules/file-picker/file-picker.module";
import { TaskUploadsComponent } from "./component/task-uploads.component";
import { TooltipModule } from "app/five-f/tooltip/tooltip.module";

@NgModule({
    imports: [
        SharedModule,
        ProcessDialogModule,
        FilePickerModule,
        TooltipModule
    ],
    declarations: [
        TaskUploadsComponent
    ],
    exports: [
        TaskUploadsComponent
    ]
})
export class TasksUploadsModule {
}
