import {ArtifactKeywordActions, ArtifactKeywordActionTypes} from './artifact-keyword.actions';
import {adapter, initialState, State} from './artifact-keyword.state';

export function reducer(state = initialState, action: ArtifactKeywordActions): State {
  const current = state.selected;
  switch (action.type) {
    case ArtifactKeywordActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case ArtifactKeywordActionTypes.Create:
    case ArtifactKeywordActionTypes.LoadOne:
    case ArtifactKeywordActionTypes.Remove:
    case ArtifactKeywordActionTypes.Save:
      return {
        ...state,
        loading: true
      };
    case ArtifactKeywordActionTypes.LoadOneSuccess:
      return adapter.addOne(action.keyword, {
        ...state,
        selected: null,
        loading: false,
      });
    case ArtifactKeywordActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.keywords, {
        ...state,
        selected: null,
        loading: false,
      });
    case ArtifactKeywordActionTypes.CreateSuccess:
    case ArtifactKeywordActionTypes.SaveSuccess:
      return adapter.upsertOne(action.keyword, {
        ...state,
        selected: action.keyword,
        loading: false,
      });
    case ArtifactKeywordActionTypes.CreateFail:
    case ArtifactKeywordActionTypes.LoadAllFail:
    case ArtifactKeywordActionTypes.LoadOneFail:
    case ArtifactKeywordActionTypes.RemoveFail:
    case ArtifactKeywordActionTypes.SaveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
    case ArtifactKeywordActionTypes.RemoveSuccess:
      return adapter.removeOne(action.keyword.id, {
        ...state,
        selected: (current && current.id === action.keyword.id) ?  null : current,
        loading: false,
      });
  }
  return state;
}

