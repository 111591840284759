import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'dvtx-priority-selector',
  templateUrl: './priority-selector.component.html',
  styleUrls: ['./priority-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrioritySelectorComponent implements OnInit {
  showDropdown = false;
  priorities = PRIORITIES;
  _selected;

  @Input() set selected(selected) {
    if (this.multipeSelection) {
      this._selected = selected;
    } else {
      this._selected = PRIORITIES_MAP[selected];
    }
  }
  get selected() {
    return this._selected;
  }

  @Input() disabled = false;
  @Input() isButton = true;
  @Input() multipeSelection = false;

  @Output() onSelection: EventEmitter<any> = new EventEmitter<any>(null);

  constructor(public _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  selectPriority($event) {
    this.onSelection.emit($event);
    this.showDropdown = false;
    this._cdr.detectChanges();
  }

  isPrioritySelected(priority) {
    if (!this.selected) {
      return false;
    }
    if (!this.multipeSelection && (this.selected.id === priority.id)) {
      return true;
    }
    if (this.multipeSelection && this.selected.find(selectedProirity => selectedProirity.id === priority.id)) {
      return true;
    }
    return false;
  }

  showDropdownDialog() {
    if (this.disabled) return;
    this.showDropdown = !this.showDropdown;
    this._cdr.detectChanges();
  }
}

export const PRIORITIES_COLORS = {
  highest: '#db0000',
  high: '#ff8800',
  medium: '#0090cf',
  low: '#00ca5c',
  lowest: '#007834',
}

export const MONTH_I18N_KEYMAP = {
  1: 'DATE.JAN',
  2: 'DATE.FEB',
  3: 'DATE.MAR',
  4: 'DATE.APR',
  5: 'DATE.MAI',
  6: 'DATE.JUN',
  7: 'DATE.JUL',
  8: 'DATE.AUG',
  9: 'DATE.SEP',
  10: 'DATE.OCT',
  11: 'DATE.NOV',
  12: 'DATE.DEC',
}

export interface IPriority {
  id?: string;
  title?: string;
  color?: string;
  icon?: string;
}

export const PRIORITIES_MAP: {[id: string]: IPriority} = {
  highest: {
    id: 'highest',
    title: 'TASK.PRIORITY.HIGHEST',
    color: PRIORITIES_COLORS.highest,
    icon: 'arrow_upward'
  },
  high: {
    id: 'high',
    title: 'TASK.PRIORITY.HIGH',
    color: PRIORITIES_COLORS.high,
    icon: 'call_made'
  },
  medium: {
    id: 'medium',
    title: 'TASK.PRIORITY.MEDIUM',
    color: PRIORITIES_COLORS.medium,
    icon: 'arrow_forward'
  },
  low: {
    id: 'low',
    title: 'TASK.PRIORITY.LOW',
    color: PRIORITIES_COLORS.low,
    icon: 'call_received'
  },
  lowest: {
    id: 'lowest',
    title: 'TASK.PRIORITY.LOWEST',
    color: PRIORITIES_COLORS.lowest,
    icon: 'arrow_downward'
  }
}

export const PRIORITIES: IPriority[] = [
  PRIORITIES_MAP.highest,
  PRIORITIES_MAP.high,
  PRIORITIES_MAP.medium,
  PRIORITIES_MAP.low,
  PRIORITIES_MAP.lowest
];
