import {Action} from '@ngrx/store';
import {EmailFooter} from './email-footer';

export enum EmailFooterActionTypes {
  LoadAll = '[EmailFooter] Load All',
  LoadAllSuccess = '[EmailFooter] Load All Success',
  LoadAllFail = '[EmailFooter] Load All Fail',
  Create = '[EmailFooter] Create',
  CreateSuccess = '[EmailFooter] Create Success',
  CreateFail = '[EmailFooter] Create Fail',
  Updated = '[EmailFooter] Update',
  UpdatedSuccess = '[EmailFooter] Update Success',
  UpdatedFail = '[EmailFooter] Update Fail',
  Delete = '[EmailFooter] Delete',
  DeleteSuccess = '[EmailFooter] Delete Success',
  DeleteFail = '[EmailFooter] Delete Fail',
}

export class Create implements Action {
  readonly type = EmailFooterActionTypes.Create;

  constructor(public footer: EmailFooter) {
  }
}

export class CreateSuccess implements Action {
  readonly type = EmailFooterActionTypes.CreateSuccess;

  constructor(public footer: EmailFooter) {
  }
}

export class CreateFail implements Action {
  readonly type = EmailFooterActionTypes.CreateFail;

  constructor(public footer: EmailFooter) {
  }
}

export class LoadAll implements Action {
  readonly type = EmailFooterActionTypes.LoadAll;

  constructor(public footer: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = EmailFooterActionTypes.LoadAllSuccess;

  constructor(public footer: EmailFooter[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = EmailFooterActionTypes.LoadAllFail;

  constructor(public footer: any) {
  }
}

export class Updated implements Action {
  readonly type = EmailFooterActionTypes.Updated;

  constructor(public footer: EmailFooter) {
  }
}

export class UpdatedSuccess implements Action {
  readonly type = EmailFooterActionTypes.UpdatedSuccess;

  constructor(public footer: EmailFooter) {
  }
}

export class UpdatedFail implements Action {
  readonly type = EmailFooterActionTypes.UpdatedFail;

  constructor(public footer: any) {
  }
}

export class Delete implements Action {
  readonly type = EmailFooterActionTypes.Delete;

  constructor(public id:  string) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = EmailFooterActionTypes.DeleteSuccess;

  constructor(public footer: any ) {
  }
}

export class DeleteFail implements Action {
  readonly type = EmailFooterActionTypes.DeleteFail;

  constructor(public footer: any) {
  }
}



export type EmailFooterActions =
  | Create
  | CreateSuccess
  | CreateFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Updated
  | UpdatedSuccess
  | UpdatedFail
  | Delete
  | DeleteSuccess
  | DeleteFail;



