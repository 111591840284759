import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {SmallBoxComponent} from './components/small-box/small-box.component';
import {MediumBoxComponent} from './components/medium-box/medium-box.component';
import {ListingBoxComponent} from './components/listing-box/listing-box.component';
import {BoxComponent} from './components/box/box.component';
import {GraphBoxComponent} from './components/graph-box/graph-box.component';
import {ProcessListingBoxComponent} from './components/process-listing-box/process-listing-box.component';
import {TaskListingBoxComponent} from './components/task-listing-box/task-listing-box.component';
import {SimpleDashboardPanelComponent} from './components/simple-dashboard-panel/simple-dashboard-panel.component';
import {ProcessBoxComponent} from './components/process-box/process-box.component';
import {ProcessBoxFavoriteComponent} from './components/process-box-favorite/process-box-favorite.component';
import {BarChartModule, PieChartModule} from "@swimlane/ngx-charts";

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    BarChartModule,
    PieChartModule
  ],
  declarations: [
    SmallBoxComponent,
    MediumBoxComponent,
    ListingBoxComponent,
    ProcessBoxComponent,
    ProcessBoxFavoriteComponent,
    ProcessListingBoxComponent,
    BoxComponent,
    GraphBoxComponent,
    TaskListingBoxComponent,
    SimpleDashboardPanelComponent,
  ],
  exports: [
    BoxComponent,
    SmallBoxComponent,
    MediumBoxComponent,
    ListingBoxComponent,
    ProcessBoxComponent,
    ProcessBoxFavoriteComponent,
    ProcessListingBoxComponent,
    GraphBoxComponent,
    TaskListingBoxComponent,
    SimpleDashboardPanelComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class DashboardPanelsModule {
}
