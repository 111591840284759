import { IFibuBookmanAccount } from './fibu-bookman-account.interface';

export class FibuBookmanAccount implements IFibuBookmanAccount {
  readonly type = 'fibu_bookman_account';

  constructor(
    public id: number = null,
    public email: string = null,
    public createdAt: string = null
  ) {
  }
}
