import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'dvtx-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() required: boolean = false;
  @Input() text: string;
  @Input() hint = true;

  constructor() { }

  ngOnInit() {
  }

}
