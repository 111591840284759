import {Component, OnDestroy} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {Router} from '@angular/router';
import {LicenceUpgradeContactAdminDialogComponent} from 'app/shared/components/dialogs/licence-upgrade-contact-admin-dialog/licence-upgrade-contact-admin-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {Invitation, InvitationStatus, InvitationType} from 'app/+store/invitation/invitation.model';
import {LicenceControlService} from 'app/shared/services/licence-control.service';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {contactListDtoType} from '../../../../models/contact-list-dto.model';
import {first, map, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {
    ContactActions,
    InvitationSelectors,
    MembershipActions,
    MembershipSelectors,
    NaturalPersonSelectors,
    OrganizationSelectors
} from 'app/+store';
import {CreateInvitation, Destroy, LoadOfOrganization} from 'app/+store/invitation/invitation.actions';
import {LicenseBuyingComponent} from 'app/modules/sales/containers/license-buying/license-buying.component';
import {CartActionTypes} from 'app/+store/licence/shopping-cart.service';
import {ConfirmationDialogSimpleComponent} from 'app/shared/components/dialogs/confirmation-dialog-simple/confirmation-dialog-simple.component';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';

@Component({
  selector: 'dvtx-license-assignment',
  templateUrl: './license-assignment.component.html',
  styleUrls: ['./license-assignment.component.scss']
})
export class LicenseAssignmentComponent implements OnDestroy {
  onDestroy: Subject<void> = new Subject<void>();
  filterType$ = new BehaviorSubject<InvitationType>(undefined);
  public usedUserLicences = [];
  public currentUser = {
    hasRequiredLicence: false,
    hasAdministrationRights: false,
    isOrganizationalOwner: false,
    currentLicence: null,
    attributes: {},
    licencedOrganizations: []
  }

  columnsToDisplay = ['avatar', 'email', 'createdAt', 'status', 'actions'];

  dataSource: MatTableDataSource<any | undefined>;
  InvitationStatus = InvitationStatus;
  InvitationType = InvitationType;
  organizationId;

  contactListDtoType = contactListDtoType;

  loading: boolean = true;

  invitations = [];
  members = [];

  inviteeEmail: string = '';

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private router: Router,
    private _translateSvc: TranslateService,
    private licenceControl: LicenceControlService,
    private avatarService: AvatarService) {
    this.filterType$.next(InvitationType.Member);
    this.currentUser = this.licenceControl._currentUser;

    this.store.select(OrganizationSelectors.getSelected)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((org) => {
        if (org) {
          this.organizationId = org.id;
          this.store.dispatch(new ContactActions.LoadAll(org));
          this.refresh();
        }
      });

    combineLatest(store.select(InvitationSelectors.selectOrgasInvitations), this.filterType$)
      .pipe(
        map(([invitations, filterType]: [Invitation[], InvitationType]) => {
          return invitations.filter((invitation) => invitation.type === filterType && invitation.current_status === InvitationStatus.Pending);
        }),
        takeUntil(this.onDestroy)
      ).subscribe((invitations) => {
        this.invitations = [];
        invitations.map(invitation => {
          this.invitations.push({
            id: invitation.id,
            email: invitation.invitee_email,
            created_at: invitation.created_at,
            status: invitation.current_status
          });
        });
        this.dataSource = new MatTableDataSource(this.members.concat(this.invitations));
      });

    store.select(NaturalPersonSelectors.getNaturalPersonsOfSelectedOrganization)
      .pipe(
        takeUntil(this.onDestroy)
      ).subscribe(persons => {
        this.members = [];
        persons.map(person => {
          this.store.select(MembershipSelectors.getMembershipOfSelectedOrgByNatPersonId(person.id))
            .pipe(first()).subscribe((membershipItem) => {
              const isMemeberOfLicence = this.currentUser.currentLicence && this.currentUser.currentLicence.attributes.licencedMemberships.indexOf(membershipItem.id) > -1;
              const isMe = this.currentUser.attributes && this.currentUser.attributes['uid'] === person.mainEmailAddress.emailAddress;
              this.members.push({
                id: person.id,
                email: person.mainEmailAddress.emailAddress,
                created_at: null,
                status: isMemeberOfLicence || isMe ? null : 'Pending'
              });
            });
        });
        this.dataSource = new MatTableDataSource(this.members.concat(this.invitations));
      });

  }

  refresh() {
    if (this.organizationId) {
      this.store.dispatch(new MembershipActions.LoadAll(this.organizationId));
      this.store.dispatch(new LoadOfOrganization(this.organizationId));
    }
  }

  upgradePlan() {
    if (!this.currentUser.isOrganizationalOwner) {
      this.showAdministrationContactDialog();
      return false;
    }
    this.router.navigate([`/prices`]);
  }

  buyUserLicence() {
    if (!this.currentUser.isOrganizationalOwner) {
      this.showAdministrationContactDialog();
      return false;
    }
    if (!this.currentUser.currentLicence || !this.currentUser.currentLicence.attributes) {
      return;
    }
    const plan = { ...this.currentUser.currentLicence.attributes };
    console.error('PLAN', this.currentUser, plan);
    plan.userLicencesCount = 0;
    plan.price.monthly = '0';
    plan.price.yearly = '0';
    const dialogRef = this.dialog.open(LicenseBuyingComponent, {
      data: {
        actionType: CartActionTypes.BUY_USER_LICENCE,
        selectedPlan: plan,
        paymentInterval: plan.paymentInterval,
        options: {
          autosave: false,
        }
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this.currentUser.currentLicence.attribute = result;
    });
  }

  openMemberInvitationDialog() {
    this.dialog.open(ConfirmationDialogSimpleComponent, {
      data: {
        title: 'INVITATIONS.INVITE_MEMBER_DIALOG_TITLE',
        message: 'INVITATIONS.INVITE_MEMBER_DIALOG_CONTENT',
        submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        onSubmitAction: () => {
          this.sendMemberInvitation();
        },
      }
    });
  }

  sendMemberInvitation() {
    this.store.select(OrganizationSelectors.getSelectedId).pipe(
      first()
    ).subscribe((orgaId: string) => {
      if (this.inviteeEmail !== '') {
        this.store.dispatch(new CreateInvitation({ orgaId: orgaId, inviteeEmail: this.inviteeEmail }));
        this.inviteeEmail = '';
        this.refresh();
      }
    });
  }

  cancelInvitationDialog(member) {
    this.dialog.open(ConfirmationDialogSimpleComponent, {
      data: {
        title: 'INVITATIONS.DELETE_INVITATION_CONFIRMATION_TITLE',
        message: 'INVITATIONS.DELETE_INVITATION_CONFIRMATION',
        submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        onSubmitAction: () => {
          this.cancelMemberInvitation(member);
        },
      }
    });
  }

  cancelMemberInvitation(member) {
    this.store.select(OrganizationSelectors.getSelectedId).pipe(
      first()
    ).subscribe((orgaId: string) => {
      this.store.dispatch(new Destroy(member.id, InvitationType.Member, orgaId));
      this.currentUser = this.licenceControl._currentUser;
    });
  }

  showAdministrationContactDialog() {
    this.dialog.closeAll();
    this.dialog.open(LicenceUpgradeContactAdminDialogComponent, {
      data: {
        allowComment: false,
        onSubmitAction: () => {
        },
          submitButtonTitle: this._translateSvc.instant('LICENCES.CONTACT_ADMIN_BUTTON')
      },
      width: '500px'
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
