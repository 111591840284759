import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TaskAssigneeEffects } from './task-assignee.effects';
import { TaskAssigneeService } from './task-assignee.service';
import { reducer } from './task-assignee.reducer';
import { stateKey } from './task-assignee.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([TaskAssigneeEffects])
  ],
  providers: [
    TaskAssigneeEffects,
    TaskAssigneeService
  ]
})
export class TaskAssigneeStoreModule {
}
