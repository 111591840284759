<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="folder-tree">
  <mat-tree-node class="tree-node" *matTreeNodeDef="let node" matTreeNodePadding [matTreeNodePaddingIndent]="20"
                 (click)="selectNode(node.folder)" (dblclick)="selectNode(node.folder, true)"
                 [class.tree-node--selected]="selectedNode?.id === node.id">
    <div class="tree-node-heading">
        <span class="tree-node-folder-icon" [class.tree-node-folder-icon--fibu]="node.folder.bookmanCockpitEnabled">
          <mat-icon *ngIf="node.folder.dmsAccountType === DmsAccountType.Organization">folder_shared</mat-icon>
          <mat-icon *ngIf="node.folder.dmsAccountType === DmsAccountType.Private">folder</mat-icon>
        </span>
      <span class="tree-node-heading__folder-name">{{node.folder.name}}</span>
    </div>
  </mat-tree-node>

  <mat-tree-node class="tree-node" *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding
                 [matTreeNodePaddingIndent]="20"
                 (click)="selectNode(node.folder)" (dblclick)="selectNode(node.folder, true)"
                 [class.tree-node--selected]="selectedNode?.id === node.id">
    <div class="tree-node-heading tree-node-heading--with-icon">
      <button mat-icon-button matTreeNodeToggle [disableRipple]="true"
              class="expansion-toggle-button">
        <mat-icon class="expansion-toggle-icon">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <span class="tree-node-folder-icon" [class.tree-node-folder-icon--fibu]="node.folder.bookmanCockpitEnabled">
          <mat-icon *ngIf="node.folder.dmsAccountType === DmsAccountType.Organization">folder_shared</mat-icon>
          <mat-icon *ngIf="node.folder.dmsAccountType === DmsAccountType.Private">folder</mat-icon>
        </span>
      <span class="tree-node-heading__folder-name">{{node.folder.name}}</span>
    </div>
  </mat-tree-node>
</mat-tree>
