import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TasksStats } from './tasks-stats';

export enum TasksStatsActionTypes {
  LoadAll = '[TasksStats] Load All',
  LoadAllSuccess = '[TasksStats] Load All Success',
  LoadAllFail = '[TasksStats] Load All Fail'
}

export class LoadAll implements Action {
  readonly type = TasksStatsActionTypes.LoadAll;

  constructor() {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = TasksStatsActionTypes.LoadAllSuccess;

  constructor(public tasksStats: TasksStats) {
  }
}

export class LoadAllFail implements Action {
  readonly type = TasksStatsActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}


export type TasksStatsActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail;
