<ng-template cdkPortal #commentTitleDialogContext>
  <h2 mat-dialog-title>{{ 'PROJECT_ROOM.EXPORT_COMMENTS' | translate }}</h2>
</ng-template>

<ng-template cdkPortal #commentDialogContext>
  <div class="mb-2 dvtx-comment-export-dialog">

    <ng-container *ngIf="commentsLoading">
      <div class="w-100 my-3 d-flex justify-content-center">
        <dvtx-loading-panel [loadingText]="'PROJECT_ROOM.LOADING_COMMENTS'"></dvtx-loading-panel>
      </div>
    </ng-container>

    <ng-container *ngIf="!commentsLoading && (comments$ | async)?.length === 0">
      <div class="w-100 d-flex justify-content-center">
        <dvtx-no-element-found>
          <mat-icon>chat_bubble_outline</mat-icon>
          <h3>{{'PROJECT_ROOM.NO_COMMENTS' | translate}}</h3>
        </dvtx-no-element-found>
      </div>
    </ng-container>

    <div class="mb-2 dvtx-comment-export-dialog__select-all-container"
      *ngIf="!commentsLoading && (comments$ | async)?.length">
      <dvtx-alert class="w-100" [text]="'PROJECT_ROOM.EXPORT_COMMENT_INFO'" [icon]="'info'"
        [color]="'info'"></dvtx-alert>
      <div class="mt-2">
        <mat-checkbox [checked]="selectAllComments" (change)="toggleAllComments($event)">{{ 'GENERAL.SELECT_ALL' |
          translate }}</mat-checkbox>
      </div>
      <hr>
    </div>

    <ul class="timeline-panel" *ngIf="id">
      <li class="list-item user-item" *ngFor="let event of (comments$ | async)">
        <div *ngIf="showDate(event.id) | async" class="grouping--date--headline">
          <hr class="first-line">
          <span>{{event.createdAt | date: 'dd.MM.yyyy'}}</span>
          <hr>
        </div>
        <ng-template [ngIf]="id && id !== event.processId && !currentProcessArtifact">
          <ng-container *ngIf="(getProcessTitleById(event.processId) | async) as title">
            <div style="width: 100%; padding: 0 auto; margin-left: 4rem;" *ngIf="title">
              <span class="dvtx-label">{{ 'PROJECT_ROOM.TITLE' | translate }}:</span>&nbsp;&nbsp;<a
                class="dvtx-panel-resource-link" (click)="navigateToWorkflow(event.processId)">{{ title }}</a>
            </div>
          </ng-container>
        </ng-template>

        <ng-container [ngSwitch]="event.type">
          <div *ngSwitchCase="ProcessEventType.InstantMessage">
            <div class="d-flex">
              <div style="width: 40px">
                <mat-checkbox [checked]="selectedComments[event.id]"
                  (change)="selectComment($event, event)"></mat-checkbox>
              </div>
              <dvtx-message-panel [process]="process$ | async" [message]="event" [readonly]="true"
                class="w-100"></dvtx-message-panel>
            </div>
          </div>

          <div *ngSwitchCase="ProcessEventType.Comment">
            <div class="d-flex">
              <div style="width: 40px">
                <mat-checkbox [checked]="selectedComments[event.id]"
                  (change)="selectComment($event, event)"></mat-checkbox>
              </div>
              <dvtx-message-panel [message]="event" [readonly]="true" class="w-100"></dvtx-message-panel>
            </div>
          </div>

          <div *ngSwitchCase="ProcessEventType.ExternalMessage">
            <div class="d-flex">
              <div style="width: 40px">
                <mat-checkbox [checked]="selectedComments[event.id]"
                  (change)="selectComment($event, event)"></mat-checkbox>
              </div>
              <dvtx-message-panel [message]="event" [readonly]="true" class="w-100"></dvtx-message-panel>
            </div>
          </div>
        </ng-container>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template cdkPortal #commentDialogButtonsToolbarContext>
  <div class="d-flex justify-content-end">
    <div class="mr-2">
      <button mat-button (click)="closeCommentDialog()">
        <mat-icon matAffix>close</mat-icon>
        {{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
    </div>

    <div>
      <button mat-raised-button color="primary" (click)="generateCommentExport()"
        [disabled]="creatingCommentExport || commentsLoading || !(comments$ | async)?.length">
        <mat-icon matAffix>file_download</mat-icon>
        {{ 'GENERAL.CREATE_ACTION' | translate }}</button>
    </div>
  </div>
</ng-template>