import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'dvtx-info-icon-message-block',
  templateUrl: './info-icon-message-block.component.html',
  styleUrls: ['./info-icon-message-block.component.scss']
})
export class InfoIconMessageBlockComponent implements OnInit {
  @Input() icon;
  @Input() message = '';
  @Input() size: 'md' | 'lg' = 'md'
  @Input() horizontal = false;

  constructor() {
  }

  ngOnInit(): void {
  }
}
