import {ITaskStatus} from './task-status.interface';

export class TaskStatus implements ITaskStatus {
  readonly type = 'task_status';
  organizationId: string;

  constructor(public id: string,
              public title: string,
              public color: string,
              public order: number,
              public statusScheme: string,
              public createdAt: Date,
              public updatedAt: Date = null) {}
}
