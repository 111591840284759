import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: Object) {
    const array = [];
    Object.keys(data).forEach( (key) => {
      array[key] = data[key];
    });
    return array;
  }
}

@Pipe({
  name: 'enumKeys'
})
export class EnumKeysPipe implements PipeTransform {
  transform(data: Object) {
    if (!data) return [];
    return Object.keys(data);
  }
}

@Pipe({
  name: 'enumValues'
})
export class EnumValuesPipe implements PipeTransform {
  transform(data: Object) {
    const values = [];
    Object.keys(data).forEach( (key) => {
      values.push(data[key]);
    });
    return values;
  }
}
