<ng-container>
  <dvtx-tooltip [position]="'top left'">
    <span (click)="disableLabel ? $event.stopPropagation() : clickOnLabel(label);"
          [style.background-color]="label.color"
          [style.color]="colors.LightOrDark(label.color) === 'light' ? '#3c3c3c' : '#ffffff'" [class.disableLabel]="disableLabel"
          class="five-f-labels-item">

      <mat-icon *ngIf="label.icon && !label.isSVGIcon" class="mr-1">{{label.icon}}</mat-icon>
      <mat-icon *ngIf="label.icon && label.isSVGIcon" [svgIcon]="label.icon" class="mr-1"></mat-icon>
      <span [matTooltip]="(label.description | translate)">
        {{((label?.title | translate)?.length > 22) ? (label?.title | translate | slice:0:22) : (label?.title | translate)}}
        {{((label?.title | translate)?.length > 22) ? '...' : ''}}
      </span>
      <span *ngIf="!readonly && label.origin === LabelOrigin.NO_ORIGIN" class="label-remove">
        <mat-icon (click)="removeLabel($event)" [matTooltip]="'GENERAL.REMOVE_ACTION' | translate"
                  class="delete-icon">clear</mat-icon>
      </span>
    </span>
  </dvtx-tooltip>
</ng-container>
<!-- <ng-template #shortText>
  <span (click)="disableLabel ? $event.stopPropagation() : clickOnLabel(label);" [style.background-color]="label.color"
        [style.color]="colors.LightOrDark(label.color) === 'light' ? '#3c3c3c' : '#ffffff'" [class.disableLabel]="disableLabel"
        class="five-f-labels-item">
    <mat-icon *ngIf="label.icon && !label.isSVGIcon" class="mr-1">{{label.icon}}</mat-icon>
    <mat-icon *ngIf="label.icon && label.isSVGIcon" [svgIcon]="label.icon" class="mr-1"></mat-icon>
    {{label.title | translate}}
    <span class="label-remove" *ngIf="!readonly && label.origin === LabelOrigin.NO_ORIGIN">
      <mat-icon class="delete-icon" [matTooltip]="'GENERAL.REMOVE_ACTION' | translate"
                (click)="removeLabel($event)">clear</mat-icon>
    </span>
  </span>
</ng-template> -->
