import { Injectable } from "@angular/core";
import { MessageResourceStat } from "app/+store/message/message";

@Injectable()
export class ListingTasksService {
  _unreadMessagesMap;

  constructor() {
  }

  public set unreadMessagesMap(stats: MessageResourceStat[]) {
    this._unreadMessagesMap = {};
    if (stats && stats.length) {
      stats.forEach((stat: MessageResourceStat) => {
        this._unreadMessagesMap[stat.id] = stat.unread;
      })
    }
  }

  public get unreadMessagesMap() {
    return this._unreadMessagesMap;
  }

}
