<p class="filter-selection-title d-flex align-items-center justify-content-between">
  {{title}}
  <span
    *ngIf="selectedOptions?.length"
    class="d-inline-flex align-items-center justify-items-center"
    [matBadge]="selectedOptions?.length"
    matBadgeOverlap="false"
    color="primary">
  </span>
</p>
<div class="list-group">
  <ng-container *ngFor="let option of filterdOptions$ | async">
      <button
      *ngIf="option?.id && option?.name"
       [matTooltip]="((option?.name | translate)?.length > 22) ? (option.name | translate) : null"
        type="button"
        class="list-group-item list-group-item-action"
        [class.active]="isSelected(option)"
        (click)="changeSelection(option)">
        <span class="text-truncate">{{ option.name }}</span>
      </button>
  </ng-container>
</div>
