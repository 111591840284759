<div class="signature-box">
  <div class="signature-box__toolbar d-flex align-items-center justify-content-between mb-1">
    <div class="pen-width d-flex align-items-center mr-2">
      <span class="mr-2 brush-label">{{'GENERAL.BRUSH_SIZE' | translate }}: </span>
      <mat-button-toggle-group class="five-f-mat-button-toggle-group" (valueChange)="redraw()" [(value)]="lineWidth">
        <mat-button-toggle [value]="2"><span class="pathWidth pathWidth-1"></span></mat-button-toggle>
        <mat-button-toggle [value]="3"><span class="pathWidth pathWidth-2"></span></mat-button-toggle>
        <mat-button-toggle [value]="4"><span class="pathWidth pathWidth-3"></span></mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <button mat-icon-button color="gray" [matTooltip]="'GENERAL.DELETE_ACTION' | translate" (click)="clearCanvas()">
      <mat-icon>delete</mat-icon>
    </button>

  </div>
  <canvas [class.hovering]="painting"
          [width]="boxWidth"
          [height]="boxHeight"
          (mousedown)="mouseDown($event)"
          (mousemove)="mouseMove($event)"
          (mouseleave)="stopPainting()"
          (mouseup)="stopPainting()"
          (touchstart)="touchStart($event)"
          (touchmove)="touchMove($event)"
          (touchend)="stopPainting()"
          (touchcancel)="stopPainting()"
          #canvasRef></canvas>

</div>
