import {Action} from '@ngrx/store';
import {ExternalAccess} from './external-access';

export enum ExternalAccessActionTypes {
  Create = '[ExternalAccess] Create',
  CreateSuccess = '[ExternalAccess] Create Success',
  CreateFail = '[ExternalAccess] Create Fail',
  Destroy = '[ExternalAccess] Destroy',
  DestroySuccess = '[ExternalAccess] Destroy Success',
  DestroyFail = '[ExternalAccess] Destroy Fail',
  LoadAll = '[ExternalAccess] Load All',
  LoadAllSuccess = '[ExternalAccess] Load All Success',
  LoadAllFail = '[ExternalAccess] Load All Fail'
}

export class Create implements Action {
  readonly type = ExternalAccessActionTypes.Create;

  constructor(public processId: string, public externalAccess: ExternalAccess) {
  }
}

export class CreateSuccess implements Action {
  readonly type = ExternalAccessActionTypes.CreateSuccess;

  constructor(public externalAccess: ExternalAccess) {
  }
}

export class CreateFail implements Action {
  readonly type = ExternalAccessActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Destroy implements Action {
  readonly type = ExternalAccessActionTypes.Destroy;

  constructor(public processId: string, public externalAccessId: string) {
  }
}

export class DestroySuccess implements Action {
  readonly type = ExternalAccessActionTypes.DestroySuccess;

  constructor(public externalAccess: ExternalAccess) {
  }
}

export class DestroyFail implements Action {
  readonly type = ExternalAccessActionTypes.DestroyFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = ExternalAccessActionTypes.LoadAll;

  constructor(public processId: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ExternalAccessActionTypes.LoadAllSuccess;

  constructor(public externalAccesses: ExternalAccess[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ExternalAccessActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export type ExternalAccessActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Destroy
  | DestroySuccess
  | DestroyFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail;
