import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import {DvtxControlValueAccessor} from '../../DvtxControlValueAccessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  DisabledToggleDirective,
  resolve
} from 'app/shared/modules/base-form-elements/directives/disabled-toggle.directive';
import {TranslateService} from '@ngx-translate/core';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {DateUtil} from 'app/lib/date/date-util';
import {Subject} from 'rxjs';
import {DateAdapter} from '@angular/material/core';

@Component({
  selector: 'dvtx-datepicker-cva',
  templateUrl: './datepicker-cva.component.html',
  styleUrls: ['./datepicker-cva.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerCvaComponent),
      multi: true,
    }
  ]
})
export class DatepickerCvaComponent extends DvtxControlValueAccessor {
  onDestroy = new Subject();

  @ViewChild('inputElementRef', {static: true}) inputElementRef: ElementRef<any>;
  @Input() representedDate: Date = new Date();

  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() placeholder?: string;
  @Output() onChange = new EventEmitter();

  @Input() set value(val) {
    this.writeValue(val);
  }

  private today = new Date();

  /**
   * Mark date red on past or today.
   */
  _enableAlertOnPast = false;
  @Input() set enableAlertOnPast(alertOnPast: boolean) {
    this._enableAlertOnPast = alertOnPast;
    if (alertOnPast && this.representedDate) {
      let date: Date = this.representedDate;
      if (date && typeof (date) === 'string') {
        date = new Date(date);
      }
      this.alertOnPast = DateUtil.inPastOrToday(this.today, date);
    }
  }

  public alertOnPast = false;

  get enableAlertOnPast(): boolean {
    return this._enableAlertOnPast;
  }

  private disabledDirective: DisabledToggleDirective;

  get disabled() {
    return this.disabledDirective.disabled;
  }

  constructor(protected injector: Injector,
              changeDetector: ChangeDetectorRef,
              @Optional() optDisabled: DisabledToggleDirective,
              private _translate: TranslateService,
              private dateAdapter: DateAdapter<Date>) {
    super();
    this.disabledDirective = resolve(optDisabled);
    this.disabledDirective.onChange(null, _ => changeDetector.markForCheck());

    const currentLang = this._translate.currentLang;
    this.dateAdapter.setLocale(currentLang);

    this._translate.onLangChange.pipe(
      distinctUntilChanged(),
      takeUntil(this.onDestroy)
    ).subscribe(lang => {
      this.dateAdapter.setLocale(lang.lang);
      changeDetector.detectChanges();
    })
  }

  writeValue(obj: any): void {
    this.representedDate = obj;

    // Mark date red on past or today
    if (this._enableAlertOnPast && obj) {
      let date = obj;
      if (typeof (date) === 'string') {
        date = new Date(obj);
      }
      this.alertOnPast = DateUtil.inPastOrToday(this.today, date);
    }
  }

  valueChanges(event) {
    this.notifyOnChange(this.representedDate);
    this.notifyOnTouch();

    // Mark date red on past or today
    if (this._enableAlertOnPast) {
      this.alertOnPast = DateUtil.inPastOrToday(this.today, event.value);
    }
    this.onChange.emit(event.value);
  }

  public focusOnInput() {
    this.inputElementRef.nativeElement.focus();
  }
}
