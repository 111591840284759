import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {QuickshareAttachment} from './quickshare-attachment';
import {QuickshareAttachmentBuilder} from './quickshare-attachment.builder';

@Injectable()
export class QuickshareAttachmentService {
  readonly BASE_PATH = 'api/v1/quickshare/processes';

  constructor(private _http: ApiResourceService) {}

  getAll(quickshareId: string): Observable<QuickshareAttachment[]> {
    const builder = new QuickshareAttachmentBuilder();
    return <Observable<QuickshareAttachment[]>>this._http.get<QuickshareAttachmentBuilder, QuickshareAttachment>(builder, `${this.BASE_PATH}/${quickshareId}/attachments`);
  }

  remove(quickshareId: string, attachmentId: string): Observable<QuickshareAttachment> {
    const builder = new QuickshareAttachmentBuilder();
    return <Observable<QuickshareAttachment>>this._http.del<QuickshareAttachmentBuilder, QuickshareAttachment>(builder, `${this.BASE_PATH}/${quickshareId}/attachments/${attachmentId}`);
  }

  download(quickshareId: string, attachmentId: string) {
    const builder = new QuickshareAttachmentBuilder();
    return <Observable<QuickshareAttachment>>this._http.get<QuickshareAttachmentBuilder, QuickshareAttachment>(builder, `${this.BASE_PATH}/${quickshareId}/attachments/${attachmentId}`);
  }

  pickFileFromDms(processId: string, dmsDocumentId: string, accountType): Observable<QuickshareAttachment> {
    const builder = new QuickshareAttachmentBuilder();
    const payload = {
      data: {
        attributes: {
          dms_document_id: dmsDocumentId,
          account_type: accountType
        }
      }
    };
    return <Observable<QuickshareAttachment>>this._http.post<QuickshareAttachmentBuilder, QuickshareAttachment>(builder, `api/v1/quickshare/processes/${processId}/attach_file_from_dms`, payload);
  }
}
