<p class="filter-selection-title d-flex align-items-center justify-content-between">
  {{title}}
  <span
    *ngIf="selectedOptions?.length"
    class="d-inline-flex align-items-center justify-items-center"
    [matBadge]="selectedOptions?.length"
    matBadgeOverlap="false"
    color="primary">
  </span>
</p>
<div class="list-group">
  <ng-container *ngFor="let option of filterdOptions$ | async">
      <button
        [matTooltip]="((option?.title | translate)?.length > 22) ? (option.title | translate) : null"
        type="button"
        class="list-group-item list-group-item-action"
        [class.active]="isSelected(option)"
        (click)="changeSelection(option)"
        [disabled]="loading">
        <mat-icon *ngIf="option?.icon && (!option?.isSVGIcon && !option?.isSvgIcon)" class="mr-1">{{option?.icon}}</mat-icon>
        <mat-icon *ngIf="option?.icon && (option?.isSVGIcon || option?.isSvgIcon)" [svgIcon]="option?.icon" class="mr-1"></mat-icon>
        <span class="text-truncate">
          {{option.title | translate}}
        </span>
      </button>
  </ng-container>
</div>
