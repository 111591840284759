import {Component, OnInit} from '@angular/core';
import {ITabNavRoute} from '../../../organization/modules/organization-card/models/tab-nav-route.interface';
import {DocumentSignatureUserAccountDialogMenuViewType} from '../../../document-signature/modules/document-signature-user-account/containers/document-signature-user-account-dialog/document-signature-user-account-dialog.component';

@Component({
  selector: 'dvtx-document-signature-account-settings',
  templateUrl: './document-signature-account-settings.component.html',
  styleUrls: ['./document-signature-account-settings.component.scss']
})
export class DocumentSignatureAccountSettingsComponent implements OnInit {
  public DocumentSignatureUserAccountDialogMenuViewType = DocumentSignatureUserAccountDialogMenuViewType;
  public routes: ITabNavRoute[];
  public activeLink: string;

  constructor() {
  }

  ngOnInit() {
    this.routes = [{
      title: 'SIGNATURE.ACCOUNT.ACCOUNT_DATA',
      route: '/user-profile/signature/account'
    }, {
      title: 'SIGNATURE.ACCOUNT.ACCOUNT_SETTINGS',
      route: '/user-profile/signature/defaults'
    }, {
      title: 'SIGNATURE.ACCOUNT.FP_SIGN_SETTINGS',
      route: '/user-profile/signature/fp-sign-settings'
    }];
    this.activeLink = this.routes[0].title
  }

}
