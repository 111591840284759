import {Action} from '@ngrx/store';
import {ArtifactKeyword} from './artifact-keyword';

export enum ArtifactKeywordActionTypes {
  Create = '[ArtifactKeyword] Create',
  CreateSuccess = '[ArtifactKeyword] Create Success',
  CreateFail = '[ArtifactKeyword] Create Fail',
  Remove = '[ArtifactKeyword] Remove',
  RemoveSuccess = '[ArtifactKeyword] Remove Success',
  RemoveFail = '[ArtifactKeyword] Remove Fail',
  Save = '[ArtifactKeyword] Save',
  SaveSuccess = '[ArtifactKeyword] Save Success',
  SaveFail = '[ArtifactKeyword] Save Fail',
  LoadAll = '[ArtifactKeyword] Load All',
  LoadAllSuccess = '[ArtifactKeyword] Load All Success',
  LoadAllFail = '[ArtifactKeyword] Load All Fail',
  LoadOne = '[ArtifactKeyword] Load One',
  LoadOneSuccess = '[ArtifactKeyword] Load One Success',
  LoadOneFail = '[ArtifactKeyword] Load One Fail'
}

export class Create implements Action {
  readonly type = ArtifactKeywordActionTypes.Create;

  constructor(public organizationId: string, public keyword: ArtifactKeyword) {
  }
}

export class CreateSuccess implements Action {
  readonly type = ArtifactKeywordActionTypes.CreateSuccess;

  constructor(public keyword: ArtifactKeyword) {
  }
}

export class CreateFail implements Action {
  readonly type = ArtifactKeywordActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = ArtifactKeywordActionTypes.Remove;

  constructor(public organizationId: string, public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = ArtifactKeywordActionTypes.RemoveSuccess;

  constructor(public keyword: ArtifactKeyword) {
  }
}

export class RemoveFail implements Action {
  readonly type = ArtifactKeywordActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = ArtifactKeywordActionTypes.Save;

  constructor(public organizationId: string, public keyword: ArtifactKeyword) {
  }
}

export class SaveSuccess implements Action {
  readonly type = ArtifactKeywordActionTypes.SaveSuccess;

  constructor(public keyword: ArtifactKeyword) {
  }
}

export class SaveFail implements Action {
  readonly type = ArtifactKeywordActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = ArtifactKeywordActionTypes.LoadAll;

  constructor(public organizationId: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ArtifactKeywordActionTypes.LoadAllSuccess;

  constructor(public keywords: ArtifactKeyword[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ArtifactKeywordActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = ArtifactKeywordActionTypes.LoadOne;

  constructor(public organizationId: string, public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = ArtifactKeywordActionTypes.LoadOneSuccess;

  constructor(public keyword: ArtifactKeyword) {
  }
}

export class LoadOneFail implements Action {
  readonly type = ArtifactKeywordActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type ArtifactKeywordActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
