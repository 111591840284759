import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {AuditContractee} from './audit-contractee';

export interface State extends EntityState<AuditContractee> {
  loading: boolean
}

export const adapter = createEntityAdapter<AuditContractee>();

export const initialState: State = adapter.getInitialState({
  loading: false,
});
