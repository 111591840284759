import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {AddAuthentificationDialogComponent} from './containers/add-authentification-dialog/add-authentification-dialog.component';
import {QrConfirmationDialogComponent} from './components/add-authentification-dialog/confirmation-dialogs/qr-confirmation-dialog/qr-confirmation-dialog.component';
import {SmsConfirmationDialogComponent} from './components/add-authentification-dialog/confirmation-dialogs/sms-confirmation-dialog/sms-confirmation-dialog.component';
import {CheckDataComponentComponent} from './components/add-authentification-dialog/check-data-component/check-data-component.component';
import {AddAuthentificationComponent} from './containers/add-authentification/add-authentification.component';
import {TwoFactorVerificationModule} from './modules/two-factor-verification/two-factor-verification.module';

@NgModule({
    imports: [
        HttpClientModule,
        TwoFactorVerificationModule,
    ],
    providers: [],
    declarations: [
        AddAuthentificationDialogComponent,
        AddAuthentificationComponent,
        QrConfirmationDialogComponent,
        SmsConfirmationDialogComponent,
        CheckDataComponentComponent
    ],
    exports: [
        TwoFactorVerificationModule,
        AddAuthentificationComponent
    ]
})
export class DashboardModule {
}
