import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import { PersonsFilterComponent } from './components/persons-filter/persons-filter.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    PersonsFilterComponent
  ],
  exports: [
    PersonsFilterComponent
  ]
})
export class PersonsFilterModule {
}
