import {Action} from '@ngrx/store';
import {Message, UnreadResource} from './message';
import {IMessageParams, IMessageStat} from './message.interface';

export enum MessageActionTypes {
  LoadMessageStats = '[Message] Load Message Stats',
  LoadMessageStatsSuccess = '[Message] Load Message Stats Success',
  LoadMessageStatsFail = '[Message] Load Message Stats Fail',
  ToggleArchive = '[Message] Archive Message',
  ToggleArchiveSuccess = '[Message] Archive Message Success',
  ToggleArchiveFail = '[Message] Archive Message Fail',
  ToggleRead = '[Message] Toggle Read Message',
  ToggleReadSuccess = '[Message] Toggle Read Message Success',
  ToggleReadFail = '[Message] Toggle Read Message Fail',
  MarkReadForResource = '[Message] Mark Read For Resource',
  MarkReadForResourceSuccess = '[Message] Mark Read For Resource Success',
  MarkReadForResourceFail = '[Message] Mark Read For Resource Fail',
  MarkReadForResourceDocuments = '[Message] Mark Read For Resource',
  MarkReadForResourceDocumentsSuccess = '[Message] Mark Read For Resource Success',
  MarkReadForResourceDocumentsFail = '[Message] Mark Read For Resource Fail',
  ToggleFavorite = '[Message] Toggle Favorite',
  ToggleFavoriteSuccess = '[Message] Toggle Favorite Success',
  ToggleFavoriteFail = '[Message] Toggle Favorite Fail',
  DeleteMessage = '[Message] Delete Message',
  DeleteMessageSuccess = '[Message] Delete Message Success',
  DeleteMessageFail = '[Message] Delete Message Fail',
  DeleteDraft = '[Message] Delete Draft',
  DeleteDraftSuccess = '[Message] Delete Draft Success',
  DeleteDraftFail = '[Message] Delete Draft Fail',
  LoadAll = '[Message] Load All',
  LoadAllSuccess = '[Message] Load All Success',
  LoadAllFail = '[Message] Load All Fail',
  LoadOne = '[Message] Load One',
  LoadOneSuccess = '[Message] Load One Success',
  LoadOneFail = '[Message] Load One Fail',
  LoadSent = '[Message] Load Sent',
  LoadSentSuccess = '[Message] Load Sent Success',
  LoadSentFail = '[Message] Load Sent Fail',
  Reset = '[Message] Reset',
  LoadDrafts = '[Message] Load Drafts',
  LoadDraftsSuccess = '[Message] Load Drafts Success',
  LoadDraftsFail = '[Message] Load Drafts Fail',
  SelectMessage = '[Message] Select Message',
  SendMessage = '[Message] Send Message',
  SendMessageSuccess = '[Message] Send Message Success',
  SendMessageFail = '[Message] Send Message Fail',
  SaveMessage = '[Message] Save Message',
  SaveMessageSuccess = '[Message] Save Message Success',
  SaveMessageFail = '[Message] Save Message Fail'
}

export class ToggleArchive implements Action {
  readonly type = MessageActionTypes.ToggleArchive;

  constructor(public message: Message) {
  }
}

export class ToggleArchiveSuccess implements Action {
  readonly type = MessageActionTypes.ToggleArchiveSuccess;

  constructor(public message: Message) {
  }
}

export class ToggleArchiveFail implements Action {
  readonly type = MessageActionTypes.ToggleArchiveFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = MessageActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = MessageActionTypes.LoadOneSuccess;

  constructor(public message: Message) {
  }
}

export class LoadOneFail implements Action {
  readonly type = MessageActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class ToggleFavorite implements Action {
  readonly type = MessageActionTypes.ToggleFavorite;

  constructor(public message: Message) {
  }
}

export class ToggleFavoriteSuccess implements Action {
  readonly type = MessageActionTypes.ToggleFavoriteSuccess;

  constructor(public message: Message) {
  }
}

export class ToggleFavoriteFail implements Action {
  readonly type = MessageActionTypes.ToggleFavoriteFail;

  constructor(public payload: any) {
  }
}

export class ToggleRead implements Action {
  readonly type = MessageActionTypes.ToggleRead;

  constructor(public message: Message) {
  }
}

export class ToggleReadSuccess implements Action {
  readonly type = MessageActionTypes.ToggleReadSuccess;

  constructor(public message: Message) {
  }
}

export class ToggleReadFail implements Action {
  readonly type = MessageActionTypes.ToggleReadFail;

  constructor(public payload: any) {
  }
}


export class MarkReadForResource implements Action {
  readonly type = MessageActionTypes.MarkReadForResource;

  constructor(public id: string, public findResourceBy = 'resource_id') {
  }
}

export class MarkReadForResourceSuccess implements Action {
  readonly type = MessageActionTypes.MarkReadForResourceSuccess;

  constructor(public unreadResource: UnreadResource) {
  }
}

export class MarkReadForResourceFail implements Action {
  readonly type = MessageActionTypes.MarkReadForResourceFail;

  constructor(public payload: any) {
  }
}

export class MarkReadForResourceDocuments implements Action {
  readonly type = MessageActionTypes.MarkReadForResourceDocuments;

  constructor(public id: string, public findResourceBy = 'resource_id') {
  }
}

export class MarkReadForResourceDocumentsSuccess implements Action {
  readonly type = MessageActionTypes.MarkReadForResourceDocumentsSuccess;

  constructor(public payload: any) {
  }
}

export class MarkReadForResourceDocumentsFail implements Action {
  readonly type = MessageActionTypes.MarkReadForResourceDocumentsFail;

  constructor(public payload: any) {
  }
}

export class DeleteMessage implements Action {
  readonly type = MessageActionTypes.DeleteMessage;

  constructor(public message: Message) {
  }
}

export class DeleteMessageSuccess implements Action {
  readonly type = MessageActionTypes.DeleteMessageSuccess;

  constructor(public message: Message) {
  }
}

export class DeleteMessageFail implements Action {
  readonly type = MessageActionTypes.DeleteMessageFail;

  constructor(public payload: any) {
  }
}

export class SaveMessage implements Action {
  readonly type = MessageActionTypes.SaveMessage;

  constructor(public message: Message) {
  }
}

export class SaveMessageSuccess implements Action {
  readonly type = MessageActionTypes.SaveMessageSuccess;

  constructor(public message: Message) {
  }
}

export class SaveMessageFail implements Action {
  readonly type = MessageActionTypes.SaveMessageFail;

  constructor(public payload: any) {
  }
}

export class SendMessage implements Action {
  readonly type = MessageActionTypes.SendMessage;

  constructor(public message: Message) {
  }
}

export class SendMessageSuccess implements Action {
  readonly type = MessageActionTypes.SendMessageSuccess;

  constructor(public message: Message) {
  }
}

export class SendMessageFail implements Action {
  readonly type = MessageActionTypes.SendMessageFail;

  constructor(public payload: any) {
  }
}

export class DeleteDraft implements Action {
  readonly type = MessageActionTypes.DeleteDraft;

  constructor(public message: Message) {
  }
}

export class DeleteDraftSuccess implements Action {
  readonly type = MessageActionTypes.DeleteDraftSuccess;

  constructor(public message: Message) {
  }
}

export class DeleteDraftFail implements Action {
  readonly type = MessageActionTypes.DeleteDraftFail;

  constructor(public payload: any) {
  }
}

export class Reset implements Action {
  readonly type = MessageActionTypes.Reset;

  constructor(public payload: any = null) {
  }
}

export class LoadAll implements Action {
  readonly type = MessageActionTypes.LoadAll;

  constructor(public params: IMessageParams) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = MessageActionTypes.LoadAllSuccess;

  constructor(public payload: Message[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = MessageActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadDrafts implements Action {
  readonly type = MessageActionTypes.LoadDrafts;

  constructor(public params: IMessageParams) {
  }
}

export class LoadDraftsSuccess implements Action {
  readonly type = MessageActionTypes.LoadDraftsSuccess;

  constructor(public payload: Message[]) {
  }
}

export class LoadDraftsFail implements Action {
  readonly type = MessageActionTypes.LoadDraftsFail;

  constructor(public payload: any) {
  }
}

export class LoadSent implements Action {
  readonly type = MessageActionTypes.LoadSent;

  constructor(public params: IMessageParams) {
  }
}

export class LoadSentSuccess implements Action {
  readonly type = MessageActionTypes.LoadSentSuccess;

  constructor(public payload: Message[]) {
  }
}

export class LoadSentFail implements Action {
  readonly type = MessageActionTypes.LoadSentFail;

  constructor(public payload: any) {
  }
}

export class LoadMessageStats implements Action {
  readonly type = MessageActionTypes.LoadMessageStats;

  constructor(public payload = null) {
  }
}

export class LoadMessageStatsSuccess implements Action {
  readonly type = MessageActionTypes.LoadMessageStatsSuccess;

  constructor(public stat: IMessageStat) {
  }
}

export class LoadMessageStatsFail implements Action {
  readonly type = MessageActionTypes.LoadMessageStatsFail;

  constructor(public payload: any) {
  }
}

export class SelectMessage implements Action {
  readonly type = MessageActionTypes.SelectMessage;

  constructor(public message: Message) {
  }
}

export type MessageActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | ToggleArchive
  | ToggleArchiveSuccess
  | ToggleArchiveFail
  | ToggleFavorite
  | ToggleFavoriteSuccess
  | ToggleFavoriteFail
  | ToggleRead
  | ToggleReadSuccess
  | ToggleReadFail
  | MarkReadForResource
  | MarkReadForResourceSuccess
  | MarkReadForResourceFail
  | MarkReadForResourceDocuments
  | MarkReadForResourceDocumentsSuccess
  | MarkReadForResourceDocumentsFail
  | DeleteMessage
  | DeleteMessageSuccess
  | DeleteMessageFail
  | DeleteDraft
  | DeleteDraftSuccess
  | DeleteDraftFail
  | SaveMessage
  | SaveMessageSuccess
  | SaveMessageFail
  | SendMessage
  | SendMessageSuccess
  | SendMessageFail
  | LoadDrafts
  | LoadDraftsSuccess
  | LoadDraftsFail
  | LoadSent
  | LoadSentSuccess
  | LoadSentFail
  | LoadMessageStats
  | LoadMessageStatsSuccess
  | LoadMessageStatsFail
  | Reset
  | SelectMessage;
