import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from "@angular/core";
import { GLOBAL_LABELS, GLOBAL_LABELS_RESERVED_TITLES } from "app/five-f/labels/configs";
import { Subject } from "rxjs";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { StringValidator } from "app/lib/validator";
import { Store } from "@ngrx/store";
import { AppState } from "app/reducers";
import { LabelSelectors } from "app/+store";
import { filter, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { Label } from "app/+store/label/label";
import { ILabelParams, LabelScope, LabelOrigin } from "app/+store/label/label.interface";

@Component({
  selector: 'dvtx-create-label-form',
  templateUrl: './create-label-form.component.html',
  styleUrls: ['./create-label-form.component.scss']
})
export class CreateLabelComponent implements OnInit, OnDestroy {
  protected onDestroy = new Subject();

  public gloablLabels = GLOBAL_LABELS;
  public gloablLabelsReservedTitles = GLOBAL_LABELS_RESERVED_TITLES;
  createLabelForm: UntypedFormGroup;

  LabelScopeRef = LabelScope;
  @Input() scope: LabelScope;
  @Input() origin: LabelOrigin;
  @Output() onSubmitCreation: EventEmitter<any> = new EventEmitter();
  @Output() onCancelCreation: EventEmitter<any> = new EventEmitter();

  descriptionFieldOptions = {
    toolbarButtons: [['fontSize', 'color', '|', 'bold', 'italic', 'underline', 'strikeThrough']],
    toolbarButtonsXS: [['fontSize', 'color', '|', 'bold', 'italic', 'underline', 'strikeThrough']]
  };

  constructor(
    private _store: Store<AppState>,
    private _fb: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initCreateForm();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  initCreateForm() {
    this.createLabelForm = this._fb.group({
      'title': ['', [Validators.required]],
      'color': ['#000000', [Validators.required]],
      'icon': [],
      'description': []
    });

    this.createLabelForm.get('title').setValidators((control) => StringValidator.notExistedIn(control.value, this.gloablLabelsReservedTitles));
    this.createLabelForm.get('title').updateValueAndValidity();
    this._store.select(LabelSelectors.getAll).pipe(
      filter(labels => !!labels),
      distinctUntilChanged(),
      takeUntil(this.onDestroy)
    ).subscribe((labels: Label[]) => {
      const blacklistedLabels = [...this.gloablLabelsReservedTitles, ...labels.map(l => l.title)];
      this.createLabelForm.get('title').setValidators((control) => StringValidator.notExistedIn(control.value, blacklistedLabels));
      this.createLabelForm.get('title').updateValueAndValidity();
    })

  }

  createLabelChangeColor(color) {
    this.createLabelForm.patchValue({
      color: color
    });
  }

  createLabelChangeIcon(icon) {
    this.createLabelForm.patchValue({
      icon: icon
    });
  }

  submitCreateLabel() {
    const labelParams: ILabelParams = {
      color: this.createLabelForm.value.color,
      title: this.createLabelForm.value.title,
      icon: this.createLabelForm.value.icon,
      description: this.createLabelForm.value.description,
      scope: this.scope,
      origin: this.origin
    };
    this.onSubmitCreation.emit(labelParams);
  }
}
