import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Colors} from "app/lib/color/hsl";
import {Label} from "app/+store/label/label";
import {Router} from "@angular/router";
import {NotificationService} from "app/shared/modules/notification/services/notification.service";
import {TranslateService} from "@ngx-translate/core";
import {LabelOrigin} from "../../../../+store/label/label.interface";

@Component({
  selector: 'dvtx-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
  colors = Colors;
  LabelOrigin = LabelOrigin;
  @Input() label: Label;
  @Input() readonly: boolean = false;
  @Input() preventClicking: boolean = false;

  @Output() onClick: EventEmitter<Label> = new EventEmitter();
  @Output() onRemove: EventEmitter<Label> = new EventEmitter();
  @Input() disableLabel: boolean = false;


  constructor(private _router: Router, private _notificationSvc: NotificationService, private _translateService: TranslateService) {
  }

   ngOnInit() {
  //   if (this._router.url.split('?')[0]  === '/label-items') {
  //     this.disableLabel = true;
  //  }
  }

  clickOnLabel(label: Label) {
    // if (!label || this.preventClicking) return;
    // if (label.id !== label.title) {
    //      if (this._router.url.split('?')[0]  === '/label-items') {
    //       this._router.navigate([`/label-items`], {queryParams: {label: label.id }});
    //     } else {
    //       this._router.navigate([`/label-items`], {queryParams: {label: label.id , 'returnUrl': this._router.url}});
    //     }
    // } else {
    //   this._notificationSvc.info(`${this._translateService.get(label.title)['value']}`);
    // }
    this.onClick.emit(label);
  }

  removeLabel($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.onRemove.emit(this.label)
  }
}
