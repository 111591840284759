
import {of as observableOf} from 'rxjs';

import {catchError} from 'rxjs/operators';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UserService {
  userType: String;
  basePath;

  constructor(private _http: HttpClient) {
    // this.userType = 'business';
    this.userType = 'tax-firm';
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}`;
  }

  getUserType() {
    return this.userType;
  }

  completeUserSetup() {
    const apiResponse = { success: true, message: 'Einrichtungsassistent erfolgreich abgeschlossen.' };
    const apiFailure = { success: false, message: 'Der Einrichtungsassistent konnte nicht abgeschlossen werden.' };
    return this._http.post('/auth/complete_user_setup', {}).pipe(
      map((_res: any) => apiResponse),
      catchError((_error: any) => observableOf(apiFailure)),);
  }
}
