import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {OrganizationEffects} from './organization.effects';
import {stateKey} from './organization.selectors';
import {reducer} from './organization.reducer';
import {OrganizationProxyService} from './organization-proxy.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([OrganizationEffects])
  ],
  providers: [
    OrganizationEffects,
    OrganizationProxyService
  ]
})
export class OrganizationStoreModule {
}
