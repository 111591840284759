import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Motd} from './motd';

export interface State extends EntityState<Motd> {
  selected?: Motd;
  loading: boolean;
}

export const adapter = createEntityAdapter<Motd>();

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
