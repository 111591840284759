export interface IListingElement {
  id: number;
  name: string;
  value: number;
}

/**
 * Singleton to create a listing structure for dropdowns.
 */
export namespace ListingBuilder {

  /**
   * Builder function to create a listing element structure.
   * @param response
   * @returns {{id, name, value}}
   */
  export function createListingElementFrom(response): IListingElement {
    return {
      id: response.id,
      name: response.attributes.name,
      value: response.attributes.value,
    };
  }
}
