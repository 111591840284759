import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProcessSelectionBuilder} from './process-lookup.builder';
import {MinimalProcess} from './process-lookup';
import {IProcessQuery} from '../process-event/process-event.interface';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';

@Injectable()
export class ProcessLookupService {
  readonly BASE_V3_PATH = 'api/v3/workflow_engine/processes';

  constructor(private _http: ApiResourceService) {
  }

  /**
   * Returns selected processes with a reduced data set.
   * @param ids Restrict processes fetched to those included in +ids+ (comma separated list of process UUIDs)
   * @param query
   */
  getSelection(pids: string[], query: IProcessQuery = null): Observable<MinimalProcess[]> {
    // if (query && query.debug) {
    //   console.error('DEBUG: ', query.debug);
    //   console.error('DEBUG: ids', ids);
    //   console.error('DEBUG: query', query);
    // }
    let ids = pids;
    if (!ids && query) {
      ids = query.processIds;
    }
    let payload;
    payload = {
      data: {
        attributes: {
          selected_ids: ids
        }
      }
    };
    const builder = new ProcessSelectionBuilder();
    return <Observable<MinimalProcess[]>>this._http.postAll<ProcessSelectionBuilder, MinimalProcess>(builder, `${this.BASE_V3_PATH}/selection`, payload);
  }
}
