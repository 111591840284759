import {Action} from '@ngrx/store'
import {HttpErrorResponse} from '@angular/common/http';

export enum NotificationActionTypes {
  ShowHttpError = '[Notification] Show HTTP Error',
}

/**
 * HttpError models error responsed coming from a backend with an predefined error code.
 * The error code is translated via the translation module.
 * If no error code is set, it defaults to a generic message
 * The error code must be set in in the top level body of the response
 * ```json
 * {
 *    "code": "ERROR.CPP.USER_NOT_FOUND"
 * }
 * ```
 *
 * The actual string representation is irrelevant, as long as there is a matching translation.
 */
export class ShowHttpError implements Action {
  readonly type = NotificationActionTypes.ShowHttpError

  constructor(public error: HttpErrorResponse) {
  }
}

export type NotificationActions =
  | ShowHttpError
