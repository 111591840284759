import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {first} from 'rxjs/operators';
import {ProcessEvent} from 'app/+store/process-event/process-event';
import {CommentActions, ProcessArtifactActions, ProcessEventActions} from 'app/+store';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {AngularTokenService} from 'angular-token';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {ProcessEventService} from 'app/+store/process-event/process-event.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {CommentService} from 'app/+store/comment/comment.service';
import {Comment} from 'app/+store/comment/comment';
import {CommentType} from 'app/+store/comment/comment.interface';
import {SendCommentSuccess} from 'app/+store/comment/comment.actions';
import {MessageFromType} from 'app/shared/directives/mark-read.directive';

@Component({
  selector: 'dvtx-sidebar-comment-panel',
  templateUrl: './sidebar-comment-panel.component.html',
  styleUrls: ['./sidebar-comment-panel.component.scss']
})
export class SidebarCommentPanelComponent implements OnInit {
  public editMode = false;
  private uid: string; // Email aka uid of current user.
  form: UntypedFormGroup;

  @Input() message;
  @Input() process: { id: string };
  @Input() backtrackId: string;
  @Input() isClosed = true;
  @Input() isArtifact = false;
  @Input() resourceId: string;
  @Input() commentType: MessageFromType = null;
  @Input() enableReply = true;

  @Output() onNewComment = new EventEmitter()
  @Output() onMarkedRead = new EventEmitter();

  public showInputComment = false;
  public comment = '';

  constructor(private _store: Store<AppState>,
              private _token: AngularTokenService,
              public avatarService: AvatarService,
              private _eventSvc: ProcessEventService,
              private _commentSvc: CommentService,
              private _translateSvc: TranslateService,
              private _fb: UntypedFormBuilder,
              private _cdr: ChangeDetectorRef,
              private _notifyService: NotificationService) {
    this.uid = _token.currentUserData.uid;
    this.form = this._fb.group({content: ''});
  }

  ngOnInit(): void {
  }

  public isFromCurrentUser(): boolean {
    const message = this.message;
    return this.uid === message.performer;
  }

  public enableEditMode() {
    const message = this.message;
    this.form.patchValue({content: message.message});
    this.form.updateValueAndValidity();
    this.editMode = true;
    this._cdr.detectChanges();
  }

  public editComment() {
    const message = this.message;
    const content = this.form.value.content;
    this._eventSvc.editComment(message.processId, message.id, content)
      .pipe(first())
      .subscribe((ev: ProcessEvent) => {
        message.message = content;
        message.updatedAt = new Date();
        this.form.patchValue({content: ''});
        this.form.updateValueAndValidity();
        this.editMode = false;
        this._cdr.detectChanges();
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._store.dispatch(new CommentActions.SaveCommentSuccess(message));
      }, err => {
        console.error(err);
        this._notifyService.error('INBOX.GENERAL_SEND_ERROR')
        this._cdr.detectChanges();
      });
  }

  public deleteComment() {
    const message = this.message;
    this._eventSvc.deleteComment(message.processId, message.id)
      .pipe(first())
      .subscribe((ev: ProcessEvent) => {
        this.editMode = false;
        this._cdr.detectChanges();
        this._notifyService.success('PROJECT_ROOM.COMMENT_DELETED')
        this._store.dispatch(new ProcessEventActions.DeleteSuccess(message.id));
        this._store.dispatch(new CommentActions.DeleteCommentSuccess(message.id));
      }, err => {
        console.error(err);
        this._notifyService.error('INBOX.GENERAL_SEND_ERROR')
        this._cdr.detectChanges();
      });
  }

  public sendComment() {
    if (!this.comment) return;
    const content = this.comment;

    const comment = new Comment(null, CommentType.Comment, null, null, content, null, null);
    comment.backtrackId = this.backtrackId;
    comment.replyToId = this.message.id;
    const call = this.isArtifact ? this._commentSvc.sendArtifactComment(this.resourceId, content) : this._commentSvc.createProcessComment(this.process.id, comment);
    call.pipe(first()).subscribe((cmt: Comment) => {
      this._store.dispatch(new SendCommentSuccess(cmt));
      this.showInputComment = false;
      if (this.resourceId) {
        this._store.dispatch(new CommentActions.LoadOne(this.resourceId, cmt.id, true));
        this.onNewComment.emit({processId: this.process.id, comment: comment})
      } else {
        this._store.dispatch(new CommentActions.LoadOne(this.process.id, cmt.id));
        this.onNewComment.emit({processId: this.process.id, comment: comment})
      }
      this._cdr.detectChanges();
    }, err => {
      console.error(err);
      this.comment = comment.message;
      this._notifyService.error('INBOX.GENERAL_SEND_ERROR')
    });
  }

  public cancelEdit() {
    this.form.patchValue({content: ''});
    this.form.updateValueAndValidity();
    this.editMode = false;
    this._cdr.detectChanges();
  }

  public react($event) {
    const call = this.isArtifact ? this._commentSvc.doReactOnArtifactComment(this.resourceId, this.message.id, $event) :
    this._commentSvc.doReact(this.message.processId, this.message.id, $event);
    call.pipe(first())
      .subscribe((comment) => {
        this.message = comment;
        this._store.dispatch(new CommentActions.SaveCommentSuccess(comment));
        this._cdr.detectChanges();
      }, err => {
        console.error(err);
        this._notifyService.error('INBOX.GENERAL_SEND_ERROR')
        this._cdr.detectChanges();
      });
  }

  public editArtifactComment() {
    const message = this.message;
    const content = this.form.value.content;
    this._commentSvc.updateArtifactComment(this.resourceId, message.id, content).pipe(first())
      .subscribe((ev: Comment) => {
        message.message = content;
        message.updatedAt = new Date();
        this.form.patchValue({content: ''});
        this.form.updateValueAndValidity();
        this.editMode = false;
        this._cdr.detectChanges();
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._store.dispatch(new CommentActions.SaveCommentSuccess(message));
      }, err => {
        console.error(err);
        this._notifyService.error('INBOX.GENERAL_SEND_ERROR')
        this._cdr.detectChanges();
      });
  }

  public deleteArtifactComment() {
    const message = this.message;
    this._commentSvc.deleteArtifactComment(this.resourceId, message.id).pipe(first())
      .subscribe((ev: Comment) => {
        this.editMode = false;
        this._cdr.detectChanges();
        this._notifyService.success('PROJECT_ROOM.COMMENT_DELETED')
        this._store.dispatch(new ProcessEventActions.DeleteSuccess(message.id));
        this._store.dispatch(new CommentActions.DeleteCommentSuccess(message.id));
        this._store.dispatch(new ProcessArtifactActions.LoadOneById(message.backtrackId));
      }, err => {
        console.error(err);
        this._notifyService.error('INBOX.GENERAL_SEND_ERROR')
        this._cdr.detectChanges();
      });
  }

  public toggleComment() {
    this.showInputComment = !this.showInputComment;
  }
}
