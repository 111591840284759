import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';

@Component({
  template: '<router-outlet></router-outlet>'
})

export class UserProfileComponent {
  constructor(private _store: Store<AppState>) {
  }
}
