<dvtx-user-profile-settings-header [routes]="routes"
                                  [activeLink]="activeLink"
                                  [title]="'REGISTRATION.DATA_PRIVACY'"
                                  [subtitle]="'REGISTRATION.DATA_PRIVACY_SUBTITLE'">
</dvtx-user-profile-settings-header>

<div class="five-f-settings-container">
  <h2 class="mb-2">{{'REGISTRATION.DATA_PRIVACY' | translate}}</h2>

  <div [innerHTML]="'REGISTRATION.DATA_PRIVACY_INFO' | translate"></div>
</div>
