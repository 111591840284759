import {Action} from '@ngrx/store';
import {ICreateTaskAssigneeParams} from './task-assignee.interface';
import {TaskAssignee} from './task-assignee';

export enum TaskAssigneeActionTypes {
  AcceptAssignment = '[TaskAssignee] Accept Assignment',
  AcceptAssignmentSuccess = '[TaskAssignee] Accept Assignment Success',
  AcceptAssignmentFail = '[TaskAssignee] Accept Assignment Fail',
  Create = '[TaskAssignee] Create Assignee',
  CreateSuccess = '[TaskAssignee] Create Assignee Success',
  CreateFail = '[TaskAssignee] Create Assignee Fail',
  Delete = '[TaskAssignee] Delete Assignee',
  DeleteSuccess = '[TaskAssignee] Delete Assignee Success',
  DeleteFail = '[TaskAssignee] Delete Assignee Fail',
  LoadAll = '[TaskAssignee] Load All',
  LoadAllSuccess = '[TaskAssignee] Load All Success',
  LoadAllFail = '[TaskAssignee] Load All Fail',
  LoadAssignment = '[TaskAssignee] Load Assignment',
  LoadAssignmentSuccess = '[TaskAssignee] Load Assignment Success',
  LoadAssignmentFail = '[TaskAssignee] Load Assignment Fail',
  RejectAssignment = '[TaskAssignee] Reject Assignment',
  RejectAssignmentSuccess = '[TaskAssignee] Reject Assignment Success',
  RejectAssignmentFail = '[TaskAssignee] Reject Assignment Fail',
  AddAssignment = '[TaskAssignee] Add Assignment',
  AddAssignmentSuccess = '[TaskAssignee] Add Assignment Success',
  AddAssignmentFail = '[TaskAssignee] Add Assignment Fail',
  UpdateAssignment = '[TaskAssignee] Update Assignment',
  UpdateAssignmentSuccess = '[TaskAssignee] Update Assignment Success',
  UpdateAssignmentFail = '[TaskAssignee] Update Assignment Fail',
}

export class AcceptAssignment implements Action {
  readonly type = TaskAssigneeActionTypes.AcceptAssignment;

  constructor(public id: string) {
  }
}

export class AcceptAssignmentSuccess implements Action {
  readonly type = TaskAssigneeActionTypes.AcceptAssignmentSuccess;

  constructor(public assignee: TaskAssignee) {
  }
}

export class AcceptAssignmentFail implements Action {
  readonly type = TaskAssigneeActionTypes.AcceptAssignmentFail;

  constructor(public payload: any) {
  }
}

export class Create implements Action {
  readonly type = TaskAssigneeActionTypes.Create;

  constructor(public params: ICreateTaskAssigneeParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = TaskAssigneeActionTypes.CreateSuccess;

  constructor(public assignee: TaskAssignee) {
  }
}

export class CreateFail implements Action {
  readonly type = TaskAssigneeActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Delete implements Action {
  readonly type = TaskAssigneeActionTypes.Delete;

  constructor(public taskId: string, public assigneeId: string) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = TaskAssigneeActionTypes.DeleteSuccess;

  constructor(public assignee: TaskAssignee) {
  }
}

export class DeleteFail implements Action {
  readonly type = TaskAssigneeActionTypes.DeleteFail;

  constructor(public payload: any) {
  }
}

/**
 * Return pending assignments.
 */
export class LoadAll implements Action {
  readonly type = TaskAssigneeActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = TaskAssigneeActionTypes.LoadAllSuccess;

  constructor(public payload: TaskAssignee[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = TaskAssigneeActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

/**
 * Retuns assignments of a specific task.
 */
export class LoadAssignment implements Action {
  readonly type = TaskAssigneeActionTypes.LoadAssignment;

  constructor(public id: string) {
  }
}

export class LoadAssignmentSuccess implements Action {
  readonly type = TaskAssigneeActionTypes.LoadAssignmentSuccess;

  constructor(public payload: TaskAssignee[]) {
  }
}

export class LoadAssignmentFail implements Action {
  readonly type = TaskAssigneeActionTypes.LoadAssignmentFail;

  constructor(public payload: any) {
  }
}

export class RejectAssignment implements Action {
  readonly type = TaskAssigneeActionTypes.RejectAssignment;

  constructor(public id: string) {
  }
}

export class RejectAssignmentSuccess implements Action {
  readonly type = TaskAssigneeActionTypes.RejectAssignmentSuccess;

  constructor(public assignee: TaskAssignee) {
  }
}

export class RejectAssignmentFail implements Action {
  readonly type = TaskAssigneeActionTypes.RejectAssignmentFail;

  constructor(public payload: any) {
  }
}

export class AddAssignment implements Action {
  readonly type = TaskAssigneeActionTypes.AddAssignment;

  constructor(public taskId: string, public assigneeEmail: string) {
  }
}

export class AddAssignmentSuccess implements Action {
  readonly type = TaskAssigneeActionTypes.AddAssignmentSuccess;

  constructor(public assignee: TaskAssignee) {
  }
}

export class AddAssignmentFail implements Action {
  readonly type = TaskAssigneeActionTypes.AddAssignmentFail;

  constructor(public payload: any) {
  }
}


export class UpdateAssignment implements Action {
  readonly type = TaskAssigneeActionTypes.UpdateAssignment;

  constructor(public assignment: TaskAssignee) {
  }
}

export class UpdateAssignmentSuccess implements Action {
  readonly type = TaskAssigneeActionTypes.UpdateAssignmentSuccess;

  constructor(public assignment: TaskAssignee) {
  }
}

export class UpdateAssignmentFail implements Action {
  readonly type = TaskAssigneeActionTypes.UpdateAssignmentFail;

  constructor(public payload: any) {
  }
}

export type TaskAssigneeActions =
  | AcceptAssignment
  | AcceptAssignmentSuccess
  | AcceptAssignmentFail
  | Create
  | CreateSuccess
  | CreateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadAssignment
  | LoadAssignmentSuccess
  | LoadAssignmentFail
  | RejectAssignment
  | RejectAssignmentSuccess
  | RejectAssignmentFail
  | AddAssignment
  | AddAssignmentSuccess
  | AddAssignmentFail
  | UpdateAssignment
  | UpdateAssignmentSuccess
  | UpdateAssignmentFail;
