import {CollectorConfirmationActions, CollectorConfirmationActionTypes} from './collector-confirmation.actions';
import {adapter, initialState, State} from './collector-confirmation.state';

export function reducer(state = initialState, action: CollectorConfirmationActions): State {
  switch (action.type) {
    case CollectorConfirmationActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case CollectorConfirmationActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.confirmations, {
        ...state,
        loading: false,
      });
    case CollectorConfirmationActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };
    case CollectorConfirmationActionTypes.Confirm:
      return {
        ...state,
        loading: true
      };
    case CollectorConfirmationActionTypes.ConfirmSuccess:
      return adapter.upsertOne(action.confirmation, {
        ...state,
        loading: false,
      });
    case CollectorConfirmationActionTypes.ConfirmFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

