import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'dvtx-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() isDisabled?: boolean;
  @Input() value?: string;
  @Input() type?: string;

  @Output() fousingEvent: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  focusing(event) {
    this.fousingEvent.emit(event);
  }

}
