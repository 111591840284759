<a (click)="$event.preventDefault(); selectNode(_node)"
   [matTooltip]="nodeName">
  <ng-template [ngIf]="_node?.closed" [ngIfElse]="workflowIcon">
    <div class="d-flex align-self-center">
              <span style="color: #00BD00; width: 18px; height: 18px; margin-right: 5px;">
                <mat-icon style="font-size: 18px;">lock</mat-icon>
              </span>
    </div>
  </ng-template>

  <ng-template #workflowIcon>
    <div class="d-flex align-self-center">
      <ng-template [ngIf]="_node?.isSvgIcon" [ngIfElse]="defaultIconType">
                <span style="width: 18px; height: 18px; margin-right: 5px;">
                <mat-icon style="height: 18px; width: 18px;" [svgIcon]="_node?.icon"></mat-icon>
              </span>
      </ng-template>
      <ng-template #defaultIconType>
                <span style="width: 18px; height: 18px; margin-right: 5px;">
                  <mat-icon style="font-size: 18px;">{{ _node?.icon }}</mat-icon>
                </span>
      </ng-template>
    </div>
  </ng-template>

  <span [ngClass]="{'active' : _node?.active}">
    <ng-template [ngIf]="nodeName" [ngIfElse]="nameMissing">
      <ng-container *ngIf="nodeName.length">
        <ng-template [ngIf]="isRoot" [ngIfElse]="innernode">
          {{ nodeName }}
        </ng-template>
        <ng-template #innernode>
          <!--{{ _node?.name }}-->
          {{ nodeName?.length ? (nodeName | slice:0:18) : nodeName }}
          {{ nodeName?.length > 18 ? '...' : ''}}
        </ng-template>
      </ng-container>
    </ng-template>
    <ng-template #nameMissing>
      <em class="font-style-italic">{{ noTitlePlaceholder }}</em>
    </ng-template>
  </span>
</a>
