import {IThirdPartyConfirmation, IThirdPartyConfirmationRow} from './thirdPartyConfirmation';
import {RequestCreation} from './request-creation.interface';
import {RequestType} from './request-type.enum';

export class ThirdPartyConfirmationLeasingRow implements IThirdPartyConfirmationRow {
  constructor(public id: number,
              public color: string,
              public lfd: string,
              public referenced_at: string,
              public due_date: string,
              public comment: string,
              public language: string,
              public vertragspartner_name: string,
              public vertragspartner_ansprechpartner_name: string,
              public vertragspartner_ansprechpartner_email: string,
              public vertragspartner_ansprechpartner_handy: string
  ) {

  }

  toRequestCreation(): RequestCreation {
    return {
      companyName: this.vertragspartner_name,
      id: this.id,
      color: this.color,
      sequenceNo: this.lfd,
      requestDate: this.referenced_at,
      dueDate: this.due_date,
      fileAttachments: undefined,
      contactPerson: {
        name: this.vertragspartner_ansprechpartner_name,
        email: this.vertragspartner_ansprechpartner_email,
        phone: this.vertragspartner_ansprechpartner_handy
      },
      requestType: RequestType.Leasing,
      language: this.language,
      comment: this.comment
    };
  }
}

export class ThirdPartyConfirmationLeasing implements IThirdPartyConfirmation {
  emailAttachmentFilename: string;
  state: string;

  constructor(public id: number,
              public type: string,
              public parent_process_id: number,
              public referenced_at: string,
              public due_date: string,
              public color: string,
              public language: string,
              public rows: ThirdPartyConfirmationLeasingRow[]) {

  }

  toForm() {
    return undefined;
  }
}
