import {Component, Input, OnInit} from '@angular/core';

export interface ClientRoute {
  title: string;
  route: string;
}

@Component({
  selector: 'dvtx-client-nav-bar',
  templateUrl: './client-nav-bar.component.html',
  styleUrls: ['./client-nav-bar.component.scss']
})
export class ClientNavBarComponent implements OnInit {
  @Input() routes: ClientRoute[];
  @Input() activeLink: string;

  constructor() { }

  ngOnInit() {
  }

}
