import { catchError, first } from 'rxjs/operators';
import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { SplitViewDialogComponent } from 'app/shared/modules/page-container/split-view-dialog/split-view-dialog.component';
import { Portal, TemplatePortal } from '@angular/cdk/portal';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { ProcessParticipant } from 'app/+store/process-participant/process-participant';
import { AvatarService } from 'app/shared/modules/user-account/components/avatar/avatar.service';
import { ConfirmationDialogParticipantComponent } from 'app/shared/components/dialogs/confirmation-dialog-participant/confirmation-dialog-participant.component';
import { Subject } from 'rxjs/internal/Subject';
import { TwoFactorUserWhitelistService } from 'app/modules/organization/services/two-factor-user-whitelist.service';
import { UserTwoFactorWhitelist } from 'app/shared/modules/api/models/userTwoFactorWhitelist';
import { AddOrInviteActionType } from 'app/modules/workflow-engine/modules/participant/containers/add-and-invite/add-and-invite.component';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'dvtx-user-whitelist-two-factor-dialog',
  templateUrl: './user-whitelist-two-factor-dialog.component.html',
  styleUrls: ['./user-whitelist-two-factor-dialog.component.scss']
})
export class UserWhitelistTwoFactorDialogComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('buttonsToolbar', { static: true }) buttonsToolbar: TemplateRef<any>;
  @ViewChild('context', { static: true }) context: TemplateRef<any>;
  public buttonsToolbarPortal: Portal<any>;
  public contextPortal: Portal<any>;
  private dialogRef: MatDialogRef<SplitViewDialogComponent>;
  // For the autocomplete to exclude current participants.
  public excludedIds = [];
  public newParticipant = new UntypedFormControl(null);
  public usersWhiteListed: UserTwoFactorWhitelist[] = [];
  private onDestroy = new Subject();
  public AddOrInviteActionType = AddOrInviteActionType;
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _dialog: MatDialog,
    private _notifyService: NotificationService,
    private _viewContainerRef: ViewContainerRef,
    private _whitelistSvc: TwoFactorUserWhitelistService,
    private _cdr: ChangeDetectorRef,
    private avatarService: AvatarService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this._initContextPortal();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.loading$.complete();
  }

  private getAllWhitelistedUsers() {
    this.loading$.next(true);
    this._whitelistSvc.getAll().pipe(first()).subscribe((whitelisted: UserTwoFactorWhitelist[]) => {
      this.usersWhiteListed = [];
      this.excludedIds = [];
      this.usersWhiteListed = whitelisted;
      if (whitelisted && whitelisted.length > 0) {
        this.excludedIds = whitelisted.map(a => a.email);
      }
      this.loading$.next(false);
      this._cdr.detectChanges();
    }, error => {
      console.error(error);
      this._notifyService.error('HTTP_ERROR.DEFAULT')
    });
  }

  public addUserToWhitelist(participant) {
    if (!participant) return;

    this._dialog.open(ConfirmationDialogParticipantComponent, {
      data: {
        title: 'AUTH.EXCLUDE_USER_TWO_FACTOR',
        message: 'GENERAL.ADD_PARTICIPANT_WHITELIST',
        submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        person: this.avatarService.getProfile(participant),
        onSubmitAction: () => {
          this._whitelistSvc.create(participant.email).pipe(first()).subscribe((user: UserTwoFactorWhitelist) => {
            this.getAllWhitelistedUsers();
            this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
            this._cdr.detectChanges();
          }, error => {
            console.error(error);
            this._notifyService.error('HTTP_ERROR.DEFAULT')
          })
        },
        onCancelAction: () => {
        }
      }
    });
  }

  private _initContextPortal() {
    if (this.contextPortal) {
      return;
    }
    this.contextPortal = new TemplatePortal(
      this.context,
      this._viewContainerRef
    );
  }

  public openWhiteListTwoFactorAuthDialog() {
    this.getAllWhitelistedUsers();
    this._initContextPortal();
    this.buttonsToolbarPortal = new TemplatePortal(
      this.buttonsToolbar,
      this._viewContainerRef
    );
    this.dialogRef = this._dialog.open(SplitViewDialogComponent, {
      data: {
        color: '#233246',
        icon: null,
        context: this.contextPortal,
        buttonsToolbar: this.buttonsToolbarPortal,
        classNames: 'mat-dialog-content-height-unset'
      },
      autoFocus: false
    });
  }

  public openRemoveUserTwoFactorDialog(participant: ProcessParticipant) {
    if (!participant || !participant.email) return;

    this._dialog.open(ConfirmationDialogParticipantComponent, {
      data: {
        title: 'AUTH.EXCLUDE_USER_TWO_FACTOR',
        message: 'GENERAL.REMOVE_PARTICIPANT_WHITELIST',
        submitButtonTitle: 'GENERAL.REMOVE_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        color: '#db0000',
        remove: true,
        person: this.avatarService.getProfile(participant.email),
        onSubmitAction: () => {
          this._whitelistSvc.remove(participant.email).pipe(first()).subscribe((user: UserTwoFactorWhitelist) => {
            this.getAllWhitelistedUsers();
            this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
            this._cdr.detectChanges();
          }, error => {
            console.error(error);
            this._notifyService.error('HTTP_ERROR.DEFAULT')
          });
        },
        onCancelAction: () => {

        }
      }
    });
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
