import { BookmanServiceAccountActions, BookmanServiceAccountActionTypes } from './bookman-service-account.actions';
import { adapter, initialState, State } from './bookman-service-account.state';

export function reducer(state = initialState, action: BookmanServiceAccountActions): State {

  switch (action.type) {
    case BookmanServiceAccountActionTypes.SignInToBookmanServiceAccount:
      return {
        ...state,
        error: false,
        loading: true
      };
    case BookmanServiceAccountActionTypes.SignInToBookmanServiceAccountSuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        signedIn: true,
        error: false,
        loading: false,
      });
    case BookmanServiceAccountActionTypes.SignInToBookmanServiceAccountFail:
      return {
        ...state,
        signedIn: false,
        error: true,
        loading: false,
      };

    case BookmanServiceAccountActionTypes.SignOutFromBookmanServiceAccount:
      return {
        ...state,
        error: false,
        loading: true
      };
    case BookmanServiceAccountActionTypes.SignOutFromBookmanServiceAccountSuccess:
      return adapter.removeOne(action.payload.id, {
        ...state,
        signedIn: false,
        error: false,
        loading: false
      })
    case BookmanServiceAccountActionTypes.SignOutFromBookmanServiceAccountFail:
      return {
        ...state,
        signedIn: true,
        error: true,
        loading: false,
      };

    case BookmanServiceAccountActionTypes.GetBookmanServiceAccount:
      return {
        ...state,
        error: false,
        loading: true
      };
    case BookmanServiceAccountActionTypes.GetBookmanServiceAccountSuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        error: false,
        loading: false,
      });
    case BookmanServiceAccountActionTypes.GetBookmanServiceAccountFail:
      return {
        ...state,
        error: false,
        loading: false,
      };

  }
  return state;
}
