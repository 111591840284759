import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {AuditTrailEffects} from './audit-trail.effects';
import {AuditTrailService} from './audit-trail.service';
import {reducer} from './audit-trail.reducer';
import {stateKey} from './audit-trail.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([AuditTrailEffects])
  ],
  providers: [
    AuditTrailEffects,
    AuditTrailService
  ]
})
export class AuditTrailStoreModule {
}
