import {OrganizationLogoActions, OrganizationLogoActionTypes} from './organization-logo.actions';
import {adapter, initialState, State} from './organization-logo.state';

export function reducer(state = initialState, action: OrganizationLogoActions): State {
  switch (action.type) {
    case OrganizationLogoActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case OrganizationLogoActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case OrganizationLogoActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };
    case OrganizationLogoActionTypes.LoadCurrent:
      return {
        ...state,
        loading: true
      };
    case OrganizationLogoActionTypes.LoadCurrentSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        current: action.payload,
        loading: false,
      });
    case OrganizationLogoActionTypes.RemoveSuccess:
      return adapter.removeOne(action.payload.id, state);
    case OrganizationLogoActionTypes.Reset:
      return adapter.removeAll({
        ...state,
        current: null,
        loading: false,
      });
  }
  return state;
}

