import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TaskEffects} from './task.effects';
import {TaskService} from './task.service';
import {reducer} from './task.reducer';
import {stateKey} from './task.selectors';
import {TaskRouterService} from './task-router.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([TaskEffects])
  ],
  providers: [
    TaskEffects,
    TaskService,
    TaskRouterService
  ]
})
export class TaskStoreModule {
}
