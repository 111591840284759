import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {DocumentModule} from '../document/document.module';
import {ProcessFolderSharingInfoComponent} from './containers/process-folder-sharing-info/process-folder-sharing-info.component';
import {FolderInfoComponent} from './components/folder-info/folder-info.component';
import {DmsFolderPickerV5Component} from './containers/dms-folder-picker-v5/dms-folder-picker-v5.component';
import {DmsFolderInfoComponent} from './containers/dms-folder-info/dms-folder-info.component';
import {PersonAutocompleteComponent} from './components/person-autocomplete/person-autocomplete.component';
import {ProcessTableModule} from '../process-table/process-table.module';
import {ProcessFolderExportComponent} from './containers/process-folder-export/process-folder-export.component';
import {ZipFileDownloadComponent} from './containers/zip-file-download/zip-file-download.component';
import {FolderTreeIconComponent} from './components/folder-tree-icon/folder-tree-icon.component';
import {BatchDeletionDialogComponent} from './containers/batch-deletion-dialog/batch-deletion-dialog.component';
import {DocumentStructureComponent} from './containers/documents-structure/document-structure.component';
import {ProcessTreeNodeComponent} from './components/process-tree-node/process-tree-node.component';
import {ProcessTreeSingleSelectComponent} from './components/process-tree-single-select/process-tree-single-select.component';
import {MatTreeModule} from '@angular/material/tree';
import {AlertsModule} from '../../../alerts/alerts.module';
import {FolderSelectionDialogComponent} from './components/folder-selection-dialog/folder-selection-dialog.component';
import {FolderSelectorComponent} from './components/folder-selector/folder-selector.component';
import {FolderTreeComponent} from './containers/folder-tree/folder-tree.component';
import {InfoBlockModule} from '../../../../five-f/info-block/info-block.module';

@NgModule({
    imports: [
        SharedModule,
        ProcessTableModule,
        DocumentModule,
        MatTreeModule,
        AlertsModule,
        InfoBlockModule
    ],
    declarations: [
        BatchDeletionDialogComponent,
        ProcessFolderSharingInfoComponent,
        FolderInfoComponent,
        DmsFolderPickerV5Component,
        DmsFolderInfoComponent,
        PersonAutocompleteComponent,
        ProcessFolderExportComponent,
        ZipFileDownloadComponent,
        FolderTreeIconComponent,
        ProcessTreeNodeComponent,
        ProcessTreeSingleSelectComponent,
        DocumentStructureComponent,
        FolderSelectionDialogComponent,
        FolderSelectorComponent,
        FolderTreeComponent
    ],
    exports: [
        BatchDeletionDialogComponent,
        ProcessFolderSharingInfoComponent,
        DmsFolderPickerV5Component,
        ProcessFolderExportComponent,
        ZipFileDownloadComponent,
        DmsFolderInfoComponent,
        DocumentStructureComponent,
        FolderSelectionDialogComponent,
        FolderSelectorComponent
    ]
})
export class FolderModule {
}
