import {CollectorAttachmentActions, CollectorAttachmentActionTypes} from './collector-attachment.actions';
import {adapter, initialState, State} from './collector-attachment.state';

export function reducer(state = initialState, action: CollectorAttachmentActions): State {
  const current = state.selected;
  switch (action.type) {
    case CollectorAttachmentActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case CollectorAttachmentActionTypes.PickFileFromDms:
      return {
        ...state,
        transfering: true,
        loading: true
      };
    case CollectorAttachmentActionTypes.LoadAllSuccess:
      return adapter.setAll(action.attachments, {
          ...state,
          selected: current ? state.entities[current.id] : null,
          loading: false,
      });
    case CollectorAttachmentActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.attachment, {
        ...state,
        selected: state.entities[action.attachment.id],
        loading: false,
      });
    case CollectorAttachmentActionTypes.PickFileFromDmsSuccess:
      return adapter.upsertOne(action.attachment, {
        ...state,
        transfering: false,
        loading: false,
      });
    case CollectorAttachmentActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
    case CollectorAttachmentActionTypes.Remove:
      return {
        ...state,
        loading: true
      };
    case CollectorAttachmentActionTypes.RemoveSuccess:
      return adapter.removeOne(action.attachment.id, {
        ...state,
        selected: current && current.id === action.attachment.id ? null : current,
        loading: false,
      });
    case CollectorAttachmentActionTypes.RemoveFail:
    case CollectorAttachmentActionTypes.PickFileFromDmsFail:
      return {
        ...state,
        transfering: false,
        loading: false,
      };
    case CollectorAttachmentActionTypes.Reset:
      return Object.assign({}, initialState);
  }
  return state;
}

