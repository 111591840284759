import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './milestone.state';
import {ProcessSelectors} from '../process';

export const stateKey = 'milestone';
const getMilestoneState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getMilestoneEntities,
  selectAll: getAllMilestones,
} = adapter.getSelectors(getMilestoneState);

export const getLoadingState = createSelector(
  getMilestoneState,
  state => state.loading
);

export const getMilestonesOfProcess = (id: string) => createSelector(
  getAllMilestones,
  events => events.filter(event => event.processId === id)
);

export const getMilestonesOfSelectedProcess = createSelector(
  getAllMilestones,
  ProcessSelectors.getSelectedProcess,
  (milestones, process) => {
    if (process) {
      return milestones.filter(m => m.processId === process.id)
    }
    return [];
  });
