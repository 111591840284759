import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {AuditContracteeBuilder} from './audit-contractee.builder';
import {AuditContractee} from './audit-contractee';

@Injectable()
export class AuditContracteeService {
  readonly BASE_PATH = 'api/v1/audit/auditings';

  constructor(private _http: ApiResourceService) {}

  getAll(id: string): Observable<AuditContractee[]> {
    const builder = new AuditContracteeBuilder(id);
    return <Observable<AuditContractee[]>>this._http.get<AuditContracteeBuilder, AuditContractee>(builder, `${this.BASE_PATH}/${id}/contractees`);
  }
}
