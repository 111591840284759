import {ITwoFactorMemberStatus, ITwoFactorSession, TwoFactorStatus} from './two-factor-session.interface';

export class TwoFactorSession implements ITwoFactorSession {
  readonly type = 'two_factor_session';

  status: TwoFactorStatus;
  code?: string;

  constructor(public id, public expiresAt: Date) {
  }
}

export class TwoFactorMemberStatus implements ITwoFactorMemberStatus {
  readonly type = 'two_factor_member_status';

  constructor(public id, public otpEnabled: boolean) {
  }
}
