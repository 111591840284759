import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FibuProcess} from "../../../+store/fibu-process/fibu-process";
import {FibuProcessSelectors} from "app/+store/fibu-process";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {AppState} from 'app/reducers';

@Component({
  selector: 'dvtx-fibu-process-indicator',
  templateUrl: './fibu-process-indicator.component.html',
  styleUrls: ['./fibu-process-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FibuProcessIndicatorComponent implements OnInit {
  fibuProcess$: Observable<FibuProcess>;

  @Input() set processId(id: string) {
    if (id) {
      this.fibuProcess$ = this._store.select(FibuProcessSelectors.getByProcessId(id))
    }
  }
  @Input() textClass = '';

  @Input() onlyIcon  = false;

  constructor(
    private _store: Store<AppState>,
  ) {
  }

  ngOnInit() {
  }

}
