import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ITemplateParams, TemplateLoadingScope, TemplateVisibility, WorkflowTemplate} from './workflow-template';
import {WorkflowTemplateBuilder} from './workflow-template.builder';
import {WtResourceService} from './wt-api-resource.service';

@Injectable()
export class WorkflowTemplateService {
  readonly BASE_PATH = 'api/v1';

  constructor(private _http: WtResourceService) {
  }

  getAll(scope: TemplateLoadingScope, params?: string): Observable<WorkflowTemplate[]> {
    const builder = new WorkflowTemplateBuilder();
    return <Observable<WorkflowTemplate[]>>this._http.get<WorkflowTemplateBuilder, WorkflowTemplate>(builder, `${this.BASE_PATH}/workflow_templates?scope=${scope}&${params}`);
  }

  getOne(id: string, scope = TemplateLoadingScope.Personal): Observable<WorkflowTemplate> {
    const builder = new WorkflowTemplateBuilder();
    return <Observable<WorkflowTemplate>>this._http.get<WorkflowTemplateBuilder, WorkflowTemplate>(builder, `${this.BASE_PATH}/workflow_templates/${id}?scope=${scope}`);
  }

  delete(id: string, scope = TemplateLoadingScope.Personal): Observable<WorkflowTemplate> {
    const builder = new WorkflowTemplateBuilder();
    return <Observable<WorkflowTemplate>>this._http.del<WorkflowTemplateBuilder, WorkflowTemplate>(builder, `${this.BASE_PATH}/workflow_templates/${id}?scope=${scope}`);
  }

  create(params: ITemplateParams): Observable<WorkflowTemplate> {
    const builder = new WorkflowTemplateBuilder();
    const visibilities = {
      [TemplateVisibility.Hidden]: 'hidden',
      [TemplateVisibility.Global]: 'global',
      [TemplateVisibility.Personal]: 'personal',
    }
    const payload = {
      data: {
        attributes: {
          template_type: params.templateType,
          title: params.title,
          description: params.description,
          visibility: visibilities[params.visibility],
          creator_id: params.creator,
          color: params.color,
          client_id: params.clients,
          template_data: params.data,
          category_id: params.categoryId,
        }
      }
    };
    return <Observable<WorkflowTemplate>>this._http.post<WorkflowTemplateBuilder, WorkflowTemplate>(builder, `${this.BASE_PATH}/workflow_templates`, payload);
  }

  update(id: string, params: ITemplateParams): Observable<WorkflowTemplate> {
    const builder = new WorkflowTemplateBuilder();
    const payload = {data: {attributes: {}}};
    const visibilities = {
      [TemplateVisibility.Hidden]: 'hidden',
      [TemplateVisibility.Global]: 'global',
      [TemplateVisibility.Personal]: 'personal',
    }
    if (params.title) payload.data.attributes['title'] = params.title;
    if (params.description) payload.data.attributes['description'] = params.description;
    if (typeof params.visibility !== 'undefined') payload.data.attributes['visibility'] = visibilities[params.visibility];
    if (params.data) payload.data.attributes['template_data'] = params.data;
    if (params.clients) payload.data.attributes['client_id'] = params.clients;
    if (params.categoryId) payload.data.attributes['category_id'] = params.categoryId;
    if (params.color) payload.data.attributes['color'] = params.color;
    if ('published' in params) payload.data.attributes['blocked'] = !params.published;
    return <Observable<WorkflowTemplate>>this._http.put<WorkflowTemplateBuilder, WorkflowTemplate>(builder, `${this.BASE_PATH}/workflow_templates/${id}`, payload);
  }

  applyTemplate(id: string): Observable<WorkflowTemplate> {
    const builder = new WorkflowTemplateBuilder();
    const payload = {data: {attributes: {apply: true}}};
    return <Observable<WorkflowTemplate>>this._http.put<WorkflowTemplateBuilder, WorkflowTemplate>(builder, `${this.BASE_PATH}/workflow_templates/${id}`, payload);
  }
}
