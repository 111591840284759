import {IDmsDocument} from './dms-document.interface';
import {IDocumentExportedMarker} from '../process-artifact/process-artifact.interface';
import {ProcessArtifact} from '../process-artifact/process-artifact';

export class DmsDocument implements IDmsDocument {
  readonly type = 'dms_foxdox_model_documents';
  selected = false;
  url = null;
  uploadedBy: string;
  owner: string;
  artifactPresent = true;
  exports: IDocumentExportedMarker[] = [];
  artifact: ProcessArtifact;
  auditProof: boolean;
  referenceId: string;

  // Role usable in project rooms regarding Artifact roles.
  // Example Collector: A document can have the roles 'default' or 'template'
  // when provided by the sender.
  role: string;

  constructor(public id: string,
              public name: string,
              public filename: string,
              public revision: number,
              public folderId: string,
              public readable: boolean,
              public writeable: boolean,
              public shared: boolean,
              public protectedDocument: boolean,
              public publiclyAvailable: boolean,
              public mimeType: string,
              public size: number,
              public pages: number,
              public lockedUntil: Date,
              public previewUrl: string,
              public createdAt: Date,
              public updatedAt: Date) {}
}
