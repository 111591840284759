<div class="mat-dialog-inner-wrap">
  <div class="mat-dialog-header">
    <h2 mat-dialog-title>{{ dialogTitle | translate}}</h2>
    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>

  <div mat-dialog-content *ngIf="form">
    <ng-container *ngIf="alert">
      <div style="max-width: 800px" class="mb-2">
        <dvtx-alert [color]="alert.color" [icon]="alert?.icon" [text]="alert.text" [dismissible]="alert.dismissible"></dvtx-alert>
      </div>
    </ng-container>

    <div [formGroup]="form">
      <mat-form-field>
        <input matInput formControlName="display_name" placeholder="{{ 'GENERAL.TITLE' | translate }}" required autofocus>
      </mat-form-field>
    </div>

    <div class="row py-1">
      <div *ngIf="uploader.queue.length == 0" class="col-12 mb-2">
        <div class="drag-n-drop"
             ng2FileDrop
             [uploader]="uploader"
             [ngClass]="{'file-over': hasBaseDropZoneOver}"
             (fileOver)="fileOverBase($event)">
          <div class="drag-n-drop__icon">
            <mat-icon aria-label="upload">file_upload</mat-icon>
          </div>
          <div class="drag-n-drop__button">
            <label class="file-upload-label btn-outline waves-effect" for="files-uploader">
              <input type="file"
                     class="outside"
                     ng2FileSelect
                     [uploader]="uploader"
                     multiple
                     id="files-uploader"/>{{ 'UPLOAD.DROP_ZONE_BUTTON' | translate }}</label>
            <p>
              {{ 'UPLOAD.SUPPORTED_TYPES' | translate }} jpeg, jpg, gif, png max {{ maxFileSize }}KB
              <!--<br />-->
              <!--<mat-icon [matTooltip]="'Supported file types: ' + supportedFileTypes.join(', ')">info</mat-icon>-->
            </p>
          </div>
        </div>
      </div>
      <ul class="col-12">
        <li class="import-error" *ngFor="let file of getInvalidFiles()">
          <mat-icon aria-label="import error" class="import-error-sign">priority_high</mat-icon>
          <!--<p class="text-mutted">File can not be uploaded:</p>-->
          <p><strong>{{ file.name }}<small>({{ file.reason }})</small></strong></p>
        </li>
      </ul>
    </div>

    <h3 *ngIf="uploader.queue.length > 0">{{ 'GENERAL.SELECTED' | translate }}</h3>
    <div class="row" *ngFor="let fileItem of uploader.queue; let x = index">
      <div class="col-12 uploaded-file-icon">
        <mat-icon style="vertical-align: middle">image</mat-icon>
        <mat-placeholder>{{ fileItem.file.name}}</mat-placeholder>
        &nbsp;&nbsp;&nbsp;
        <button mat-button (click)="uploader.queue[x].remove()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

  </div>

  <div mat-dialog-actions class="d-flex justify-content-end">
    <button type="reset" mat-button mat-dialog-close>{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
    <button type="submit" (click)="submit()"
            [disabled]="form.pristine || !uploader.getNotUploadedItems().length || getInvalidFiles().length > 0 || !form.valid"
            mat-raised-button color="primary">{{ submitButtonTitle | translate }}</button>
  </div>
</div>
