import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {EmailFooterEffects} from './email-footer.effects';
import {EmailFooterService} from './email-footer.service';
import {reducer} from './email-footer.reducer';
import {stateKey} from './email-footer.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([EmailFooterEffects])
  ],
  providers: [
    EmailFooterEffects,
    EmailFooterService
  ]
})
export class EmailFooterStoreModule {
}
