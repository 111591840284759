import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './feature.state';

export const stateKey = 'feature';
const getFeatureState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getFeatureEntities,
  selectAll: getAllFeatures,
} = adapter.getSelectors(getFeatureState);

export const getCurrentFeatureSet = createSelector(
  getFeatureState,
  (state) => state.selected
);

export const loadingState = createSelector(
  getFeatureState,
  (state) => state.loading
);
