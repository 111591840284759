import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {SplitViewDialogComponent} from 'app/shared/modules/page-container/split-view-dialog/split-view-dialog.component';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Subject, Observable, BehaviorSubject} from 'rxjs';
import {AngularTokenService} from 'angular-token';
import {Net} from 'app/lib/net/uuid';
import {Router} from '@angular/router';
import {Feature} from 'app/+store/feature/feature';
import {FeatureSelectors} from 'app/+store/feature';
import {Process} from 'app/+store/process/process';
import {IResource} from 'app/shared/modules/api/models/resource.interface';
import {ProcessArtifact} from 'app/+store/process-artifact/process-artifact';
import {
  DocumentPreview, DocumentSignatureRequestProfileType,
  SignatureAccount,
  SignatureSettings
} from 'app/+store/document-signature/document-signature';
import {first} from 'rxjs/operators';
import {ProcessService} from 'app/+store/process/process.service';
import {DocumentSignatureService} from 'app/+store/document-signature/document-signature.service';
import {DmsPdfExportService} from 'app/shared/modules/api/services/dms-pdf-export.service';

export enum DocumentSignatureSelectionMenuViewType {
  Embedded = 'Embedded',
  Button = 'Button',
  Custom = 'Custom',
  MenuItem = 'MenuItem',
  IconButton = 'IconButton',
  ListItem = 'ListItem',
  Quickstart = 'Quickstart'
}

@Component({
  selector: 'dvtx-document-signature-selection-menu',
  templateUrl: './document-signature-selection-menu.component.html',
  styleUrls: ['./document-signature-selection-menu.component.scss']
})
export class DocumentSignatureSelectionMenuComponent implements AfterViewInit, OnDestroy, OnInit {
  DocumentSignatureSelectionMenuViewType = DocumentSignatureSelectionMenuViewType;
  DocumentSignatureRequestProfileType = DocumentSignatureRequestProfileType;

  onDestroy = new Subject();

  @ViewChild('buttonsToolbar', { static: true }) buttonsToolbar: TemplateRef<any>;
  @ViewChild('context', { static: true }) context: TemplateRef<any>;
  buttonsToolbarPortal: Portal<any>;
  contextPortal: Portal<any>;
  dialogRef: MatDialogRef<SplitViewDialogComponent>;
  workflowFlowIsLoading = false;

  @Input() menuItemButtonText = 'SIGNATURE.START_WORKFLOW_DIALOG.CREATE_SIGNATURE';
  @Input() menuItemButtonDisabled = false;

  @Input() action = null;

  _process: Process;
  createStandaloneSignatureProcess = true;
  @Input() set process(process: Process) {
    this._process = process;
    if (process && process.id) {
      this._processId = process.id;
    }
    this.createStandaloneSignatureProcess = !this._processId;
  }

  private _processId = null;
  @Input() set processId(processId: string) {
    this._processId = processId;
    this.createStandaloneSignatureProcess = !processId;
  }

  get processId(): string {
    if (this._processId) return this._processId;
    if (!this._process) return null;
    return this._process.id;
  }

  supported = false;

  provideUpload = true;
  _document: ProcessArtifact;
  @Input() set document(document: IResource) {
    this._document = <ProcessArtifact>document;
    this.provideUpload = !this._document;

    if (this._document) {
      this.supported = DocumentSignatureSelectionMenuComponent.supportedDocument(<ProcessArtifact>this._document)
    }
  }

  @Input() disabled = true;

  featureSet$: Observable<Feature>;

  process$ = new BehaviorSubject<Process>(null);
  processLoading = true;

  document$ = new BehaviorSubject<DocumentPreview>(null);
  documentLoading = true;

  account$ = new BehaviorSubject<SignatureAccount>(null);
  accountLoading = true;
  settingsLoading = true;
  settingsLoadingError = false;
  accountLoadingError = false;

  pdfLoading = true;
  pdfIsAvailable = false;

  pdf$ = new BehaviorSubject<DocumentPreview>(null);

  mySettings$ = new BehaviorSubject<SignatureSettings[]>([]);

  profile: DocumentSignatureRequestProfileType = null;

  creatingWorkflowRequest = false;

  constructor(private _dialog: MatDialog,
              private _store: Store<AppState>,
              private _viewContainerRef: ViewContainerRef,
              private _processSvc: ProcessService,
              private _signSvc: DocumentSignatureService,
              private _pdfSvc: DmsPdfExportService,
              private _router: Router,
              private _tokenSvc: AngularTokenService,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this._initContextPortal();
    });
  }

  private _initContextPortal() {
    if (this.contextPortal) {
      return;
    }
    this.contextPortal = new TemplatePortal(
      this.context,
      this._viewContainerRef
    );
  }

  private _resetDialog() {
    this.account$.next(null);
    this.accountLoading = true;
    this.settingsLoading = true;
    this.pdf$.next(null);
    this.document$.next(null);
    this.pdfLoading = true;
    this.pdfIsAvailable = false;
    this.mySettings$.next(null);
    this.process$.next(null);

    this.workflowFlowIsLoading = false;
  }

  openWorkflowDialog() {
    if (!this.supported && !this.provideUpload) return;

    this._resetDialog();

    this.creatingWorkflowRequest = false;

    this._initContextPortal();

    this.buttonsToolbarPortal = new TemplatePortal(
      this.buttonsToolbar,
      this._viewContainerRef
    );

    this.dialogRef = this._dialog.open(SplitViewDialogComponent, {
      data: {
        color: '#007aff',
        icon: null,
        context: this.contextPortal,
        buttonsToolbar: this.buttonsToolbarPortal
      },
      width: '400px'
    });
  }

  startSignature(profile) {
    this.workflowFlowIsLoading = true;
    this.profile = profile;

    if (this.processId && Net.validUUID(this.processId)) {
      this.loadProcess(this.processId);
    }

    if (this._document) {
      this.loadDocument(this._document.id);
    }

    this.loadMyAccount();
  }

  closeDialog() {
    try {
      this.dialogRef.close();
    } catch (e) {
      console.error('Could not close the signature menu dialog. Exit...', e)
    }
  }

  static supportedDocument(artifact: ProcessArtifact) {
    if (artifact) {
      const fileName = artifact.fileName;
      const title = artifact.title;
      let ext: string = '';
      if (fileName && fileName.includes('.') && fileName.substr(fileName.lastIndexOf('.') + 1)) {
        ext = fileName.substr(fileName.lastIndexOf('.') + 1);
      } else if (title && title.includes('.') && title.substr(title.lastIndexOf('.') + 1)) {
        ext = title.substr(title.lastIndexOf('.') + 1);
      }
      if (ext) {
        const _ext = ext.toLocaleLowerCase();
        switch (_ext) {
          case 'pdf':
          case 'txt':
          case 'rtf':
          case 'fodt':
          case 'doc':
          case 'docx':
          case 'odt':
          case 'xls':
          case 'xlsx':
          case 'ods':
          case 'ppt':
          case 'pptx':
          case 'odp':
            return true;
        }
      }
    }
    return false;
  }

  private loadProcess(processId: string) {
    if (Net.validUUID(processId)) {
      this._processSvc.getOne(processId)
        .pipe(first())
        .subscribe(process => {
          this.processLoading = false;
          this.process$.next(process);

          this.maybeCreateRequestWorkflow();
        });
    }
  }

  private loadDocument(documentId: string) {
    if (Net.validUUID(documentId)) {
      this._signSvc.checkDocumentAccess(documentId)
        .pipe(first())
        .subscribe(document => {
          this.documentLoading = false;
          this.document$.next(document);
          this.loadPDF(documentId);
        });
    }
  }

  private loadPDF(documentId) {
    if (Net.validUUID(documentId)) {
      this._signSvc.getDocumentPreview(documentId)
        .pipe(first())
        .subscribe(document => {
          this.pdfIsAvailable = true;
          this.pdfLoading = false;
          this.pdf$.next(document);

          this.maybeCreateRequestWorkflow();
        }, _err => {
          this.pdfIsAvailable = false;
          this.pdfLoading = false;
        });
    }
  }

  private loadMyAccount() {
    this.accountLoadingError = false;
    this._signSvc.getMyAccount()
      .pipe(first())
      .subscribe(account => {
        this.accountLoading = false;
        this.account$.next(account);
        this.loadSettings();
      }, err => {
        this.accountLoading = false;
        this.accountLoadingError = true;
      });
  }

  private loadSettings() {
    this.settingsLoadingError = false;
    this._signSvc.getMySignatureSettings()
      .pipe(first())
      .subscribe(settings => {
        this.mySettings$.next(settings);
        if (settings.length === 0) {
          this.settingsLoadingError = true;
        }
        this.settingsLoading = false;

        if (!this.settingsLoadingError) {
          this.maybeCreateRequestWorkflow();
        }
      }, err => {
        this.settingsLoadingError = true;
      });
  }

  /**
   * True if critieria to start the workflow are unmet.
   */
  private unmetCriteriaForWorkflowCreation(): boolean {
    return this.creatingWorkflowRequest
      || this.accountLoading
      || this.settingsLoading
      || !this.createStandaloneSignatureProcess && this.processLoading
      || !this.provideUpload && (this.documentLoading || this.pdfLoading || !this.pdfIsAvailable);
  }

  /*
   * Create signature workflow if all criteria are met.
   */
  private maybeCreateRequestWorkflow() {
    if (this.unmetCriteriaForWorkflowCreation()) { return; }

    this.createRequestWorkflow();
  }

  /**
   * Creates the siganture process and navigates to the wizard form.
   */
  public createRequestWorkflow(): void {
    this.creatingWorkflowRequest = true;
    this.workflowFlowIsLoading = true;
    // Loader does show up without change detection here.
    this._cdr.detectChanges();

    const dialogRef = this._dialog;

    const documentId = this._document ? this._document.id : null;
    const processId = this.processId;

    this._signSvc.createWorkflowRequest(processId, documentId, this.profile)
      .pipe(first())
      .subscribe(workflowRequest => {
        console.error(workflowRequest);
        this._router.navigate(['signature', 'request', 'new', workflowRequest.id]);

        // Close all outer start dialogs, e.g. also the document preview browser.
        setTimeout(_ => { dialogRef.closeAll(); }, 2000);
      }, err => {
        console.error(err);
        this.creatingWorkflowRequest = false;
      });
  }

  navigateToSettings() {
    this.closeDialog();
    this._router.navigate(['user-profile', 'signature', 'fp-sign-settings']);
  }
}
