import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {Motd} from './motd';
import {MotdBuilder} from './motd.builder';

@Injectable()
export class MotdService {
  readonly BASE_PATH = 'api/v1/cms/motd';

  constructor(private _http: ApiResourceService) {}

  getOne(organizationId): Observable<Motd> {
    const builder = new MotdBuilder();
    return <Observable<Motd>>this._http.get<MotdBuilder, Motd>(builder, `${this.BASE_PATH}/${organizationId}`);
  }

  create(motd: Motd): Observable<Motd> {
    const builder = new MotdBuilder();
    const payload = builder.toRequest(motd);
    return <Observable<Motd>>this._http.post<MotdBuilder, Motd>(builder, `${this.BASE_PATH}`, payload);
  }

  update(motd: Motd): Observable<Motd> {
    const builder = new MotdBuilder();
    const payload = builder.toRequest(motd);
    return <Observable<Motd>>this._http.put<MotdBuilder, Motd>(builder, `${this.BASE_PATH}/${motd.id}`, payload);
  }

  destroy(motdId: string): Observable<Motd> {
    const builder = new MotdBuilder();
    return <Observable<Motd>>this._http.del<MotdBuilder, Motd>(builder, `${this.BASE_PATH}/${motdId}`);
  }
}
