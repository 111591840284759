<div>
  <mat-spinner *ngIf="!loading"
               [diameter]="70" [strokeWidth]="4"
               aria-label="loading...">
  </mat-spinner>

  <div *ngIf="allRoles && allRoles.length>0">
    <h4 class="mb-1">{{ 'ORGANIZATION.ORGANIZATION_PRIVILEDGES' | translate }}</h4>

    <div *ngFor="let role of allRoles" class="mb-1">
      <div [ngSwitch]="role.type">
        <div *ngSwitchCase="'owner'">
          <div *ngIf="isOwner">
            <h4 class="mb-1" *ngIf="isEditable(role)">{{ 'AUTH.TRANSFER_OWNERSHIP_INFO' | translate }}</h4>

            <mat-slide-toggle class="owner-role-toggle"
                              color="primary"
                              [labelPosition]="'after'"
                              [checked]="isOwner && isSelectedRoles(role)"
                              [disabled]="!isEditable(role)"
                              (change)="onChangeOwner(role, $event)">
              <dvtx-table-text-and-info [alignmentPosition]="'start'"
                                        [h4]="true" [position]="'after'"
                                        [title]="role.roleName"
                                        [tooltip]="'AUTH.OWNER_DESC'">
              </dvtx-table-text-and-info>
            </mat-slide-toggle>
          </div>
        </div>

        <div *ngSwitchCase="'administrator'">
          <mat-slide-toggle class="owner-role-toggle"
                            color="primary"
                            [checked]="isSelectedRoles(role)"
                            [labelPosition]="'after'"
                            [disabled]="!isEditable(role)"
                            (change)="onChange($event, role, isSelectedRoles(role))">
            <dvtx-table-text-and-info [alignmentPosition]="'start'"
                                      [h4]="true" [position]="'after'"
                                      [title]="role.roleName"
                                      [tooltip]="'AUTH.ADMIN_DESC'"></dvtx-table-text-and-info>
          </mat-slide-toggle>
        </div>

        <div *ngSwitchCase="'sysadmin'"></div>

        <div *ngSwitchDefault>
          <mat-icon class="role-info-icon" matTooltip="{{ 'AUTH.GENERAL_ROLE_INFO' | translate }}">info_outline</mat-icon>
          <mat-slide-toggle
            class="default-role-toggle"
            color="primary"
            [checked]="isSelectedRoles(role)"
            [disabled]="!isEditable(role)"
            [labelPosition]="'after'"
            (change)="onChange(role,isSelectedRoles(role))"
            [matTooltipDisabled]="isEditable(role)"
            matTooltip="{{ 'AUTH.GRANT_ROLE_DISABLED' | translate }}">
            {{role.roleName}}
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!allRoles">
    <p>{{ 'DASHBOARD.NO_ROLES_AVAILABLE_NOW' |translate}}</p>
  </div>
</div>
