import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {customRoute} from './routing.routes';
import {RoutingComponent} from './components/routing.component';
import {RoutingService} from './routing.service';
import {DatevOauthRedirectComponent} from './components/datev-oauth-redirect/datev-oauth-redirect.component';

@NgModule({
  imports: [
    CommonModule,
    customRoute
  ],
  declarations: [
    RoutingComponent,
    DatevOauthRedirectComponent
  ],
  providers: [RoutingService]
})
export class RoutingModule {
}
