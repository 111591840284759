import {Injectable} from '@angular/core';
import {catchError, first, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  Delete,
  DeleteFail,
  DeleteSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  Updated,
  UpdatedFail,
  UpdatedSuccess,
  UserSignatureActionTypes
} from './user-signature.actions';
import {UserSignatureService} from './user-signature.service';
import {UserSignature} from './user-signature';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class UserSignatureEffects {
  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(UserSignatureActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        map((res: UserSignature[]) => {
            return new LoadAllSuccess(res);
          }
        ),
        catchError(err => of(new LoadAllFail(err))));
    })
  );

  @Effect()
  create$ = this.actions.pipe(
    ofType(UserSignatureActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.signature).pipe(
        first(),
        switchMap((res: UserSignature) => {
          this._notifyService.success('CUSTOMIZATION.CREATED_SIGNATURE_SUCCESS')
          return [new CreateSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('CUSTOMIZATION.CREATED_SIGNATURE_FAILURE')
          return of(new CreateFail(err))
        }));
    })
  );

  @Effect()
  edit$ = this.actions.pipe(
    ofType(UserSignatureActionTypes.Updated),
    switchMap((action: Updated) => {
      return this._svc.update(action.signature).pipe(
        first(),
        switchMap((res: UserSignature) => {
          this._notifyService.success('CUSTOMIZATION.EDITED_SIGNATURE_SUCCESS')
          return [new UpdatedSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('CUSTOMIZATION.EDITED_SIGNATURE_FAILURE')
          return of(new UpdatedFail(err))
        }));
    })
  );

  @Effect()
  delete$ = this.actions.pipe(
    ofType(UserSignatureActionTypes.Delete),
    switchMap((action: Delete) => {
      return this._svc.remove(action.id).pipe(
        first(),
        switchMap((res: UserSignature) => {
          this._notifyService.success('CUSTOMIZATION.DELETED_SIGNATURE_SUCCESS')
          return [
            new DeleteSuccess(res),
          ];
        }),
        catchError(err => {
          this._notifyService.error('CUSTOMIZATION.REMOVE_SIGNATURE_FAILURE')
          return of(new DeleteFail(err))
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: UserSignatureService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
