import {Action} from '@ngrx/store';
import {SlimFolder} from './slim-folder';
import {DmsAccountType} from './slim-folder.interface';

export enum SlimFolderActionTypes {
  LoadAll = '[SlimFolder] Load All',
  LoadAllSuccess = '[SlimFolder] Load All Success',
  LoadAllFail = '[SlimFolder] Load All Fail',
  LoadAllOrganization = '[SlimFolder] Load All',
  LoadOne = '[SlimFolder] Load One',
  LoadOneSuccess = '[SlimFolder] Load One Success',
  LoadOneFail = '[SlimFolder] Load One Fail',
  Create = '[SlimFolder] Create',
  CreateSuccess = '[SlimFolder] Create Success',
  CreateFail = '[SlimFolder] Create Fail',
  Delete = '[SlimFolder] Delete',
  DeleteSuccess = '[SlimFolder] Delete Success',
  DeleteFail = '[SlimFolder] Delete Fail',
  Refresh = '[SlimFolder] Refresh',
  RefreshSuccess = '[SlimFolder] Refresh Success',
  RefreshFail = '[SlimFolder] Refresh Fail',
  Rename = '[SlimFolder] Rename',
  RenameSuccess = '[SlimFolder] Rename Success',
  RenameFail = '[SlimFolder] Rename Fail',
  LockFolder = '[SlimFolder] LockFolder',
  LockFolderSuccess = '[SlimFolder] LockFolder Success',
  LockFolderFail = '[SlimFolder] LockFolder Fail',
}

export class LoadAll implements Action {
  readonly type = SlimFolderActionTypes.LoadAll;

  constructor(public dmsAccountType: DmsAccountType = DmsAccountType.Private) {
  }
}

export class LoadAllOrganization implements Action {
  readonly type = SlimFolderActionTypes.LoadAllOrganization;

  constructor(public dmsAccountType: DmsAccountType = DmsAccountType.Organization) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = SlimFolderActionTypes.LoadAllSuccess;

  constructor(public payload: SlimFolder[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = SlimFolderActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Refresh implements Action {
  readonly type = SlimFolderActionTypes.Refresh;

  constructor(public dmsAccountType: DmsAccountType = DmsAccountType.Private) {
  }
}

export class RefreshSuccess implements Action {
  readonly type = SlimFolderActionTypes.RefreshSuccess;

  constructor(public payload: SlimFolder[]) {
  }
}

export class RefreshFail implements Action {
  readonly type = SlimFolderActionTypes.RefreshFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = SlimFolderActionTypes.LoadOne;

  constructor(public id: string, public accountType = DmsAccountType.Private) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = SlimFolderActionTypes.LoadOneSuccess;

  constructor(public folder: SlimFolder) {
  }
}

export class LoadOneFail implements Action {
  readonly type = SlimFolderActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Create implements Action {
  readonly type = SlimFolderActionTypes.Create;

  constructor(public name: string, public parent: string, public dmsAccountType: DmsAccountType) {
  }
}

export class CreateSuccess implements Action {
  readonly type = SlimFolderActionTypes.CreateSuccess;

  constructor(public folder: SlimFolder) {
  }
}

export class CreateFail implements Action {
  readonly type = SlimFolderActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Rename implements Action {
  readonly type = SlimFolderActionTypes.Rename;

  constructor(public id: string, public name: string, public dmsAccountType: DmsAccountType) {
  }
}

export class RenameSuccess implements Action {
  readonly type = SlimFolderActionTypes.RenameSuccess;

  constructor(public folder: SlimFolder) {
  }
}

export class RenameFail implements Action {
  readonly type = SlimFolderActionTypes.RenameFail;

  constructor(public payload: any) {
  }
}

export class Delete implements Action {
  readonly type = SlimFolderActionTypes.Delete;

  constructor(public id: string, public dmsAccountType: DmsAccountType) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = SlimFolderActionTypes.DeleteSuccess;

  constructor(public folder: SlimFolder) {
  }
}

export class DeleteFail implements Action {
  readonly type = SlimFolderActionTypes.DeleteFail;

  constructor(public payload: any) {
  }
}

export class LockFolder implements Action {
  readonly type = SlimFolderActionTypes.LockFolder;

  constructor(public id: string, public lockUntil: string, public dmsAccountType: DmsAccountType = DmsAccountType.Organization) {
  }
}

export class LockFolderSuccess implements Action {
  readonly type = SlimFolderActionTypes.LockFolderSuccess;

  constructor(public folder: SlimFolder) {
  }
}

export class LockFolderFail implements Action {
  readonly type = SlimFolderActionTypes.LockFolderFail;

  constructor(public payload: any) {
  }
}

export type SlimFolderActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadAllOrganization
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Create
  | CreateSuccess
  | CreateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | Refresh
  | RefreshSuccess
  | RefreshFail
  | Rename
  | RenameSuccess
  | RenameFail
  | LockFolder
  | LockFolderSuccess
  | LockFolderFail;
