import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './process-participant-acl.state';
import {ProcessSelectors} from '../process';

export const stateKey = 'process-participant-acl';
const getProcessParticipantAclState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getProcessParticipantAclEntities,
  selectAll: getAllProcessParticipantAcls,
} = adapter.getSelectors(getProcessParticipantAclState);

export const getLoadingState = createSelector(
  getProcessParticipantAclState,
  state => state.loading
);

export const getProcessParticipantAclsOfSelectedProcess = createSelector(
  ProcessSelectors.getSelectedProcess,
  getAllProcessParticipantAcls,
  (process, acls) => acls.filter(acl => process && acl.processId === process.id));
