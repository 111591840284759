
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Licence } from '../models/licence';
import { ResourceBuilder } from '../builders/resource.builder';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class LicenceService {
  basePath;
  readonly subpath: string = 'sales/licences';

  constructor(private _tokenService: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.subpath}`;
  }

  getAll(): Observable<Licence[]> {
    return this._tokenService.get(this.basePath).pipe(
      map(res => <Licence[]>ResourceBuilder.fromResponse(res)),
      catchError(_ => observableOf([])),);
  }
}

