import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { KanbanBoard } from './kanban-board';

export interface State extends EntityState<KanbanBoard> {
  selected?: string;
  loading: boolean;
}

export const adapter = createEntityAdapter<KanbanBoard>({});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
