import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationBuilder } from '../builders/navigation.builder';

@Injectable()
export class NavigationMenuCommandFactory {
  constructor(private _router: Router) {
  }

  createMenu(entries, parentId = 0) {
    entries = entries.children ? entries.children : (entries instanceof Array ? entries : []);
    return NavigationBuilder.createNavigationFrom(entries, this._router, parentId);
  }
}
