import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EnvService} from 'app/shared/modules/api-resource/services/env.service';
import {Observable} from 'rxjs';
import {CollectorExport} from './collector-export';
import {CollectorExportBuilder} from './collector-export.builder';

@Injectable()
export class CollectorExportService {
  readonly EXPORT_PATH = '/api/v1/clr/export/excel';
  apiUrl: string;

  constructor(private _http: HttpClient, private _env: EnvService) {
    this.apiUrl = _env.collectoExportApi()
  }

  download(collector: CollectorExport): Observable<CollectorExport> {
    const builder = new CollectorExportBuilder();
    const payload = builder.toRequest(collector);
    return <Observable<CollectorExport>>this._http.post(this.apiUrl + this.EXPORT_PATH, payload);
  }
}
