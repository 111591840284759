<section class="fivef-stepper">
  <header class="fivef-stepper__header">
    <ol>
      <ng-container *ngFor="let step of steps; let i = index;">
        <li [class.active-step]="selectedIndex === i">
          <button (click)="selectedIndex = i">
            <ng-container *ngIf="step.stepLabel; else showLabelText" [ngTemplateOutlet]="step.stepLabel.template">
            </ng-container>
            <ng-template #showLabelText>
              {{ step.label }}
            </ng-template>
          </button>
        </li>
        <li class="horizontal-line" *ngIf="i < steps.length - 1" aria-hidden="true">
          <div class="step-horizontal-line"></div>
        </li>
      </ng-container>
    </ol>
    <div class="step-label--mobile">
      <ng-container *ngIf="selected.stepLabel; else showLabelText" [ngTemplateOutlet]="selected.stepLabel.template">
      </ng-container>
      <ng-template #showLabelText>
        {{ selected.label }
      </ng-template>
    </div>
  </header>

  <div class="fivef-stepper__body">
    <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
  </div>
</section>
