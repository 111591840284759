import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipModule } from 'app/five-f/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconPickerDropdownComponent } from './component/icon-picker-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    TooltipModule
  ],
  declarations: [
    IconPickerDropdownComponent
  ],
  exports: [
    IconPickerDropdownComponent
  ]
})
export class IconPickerDropdownModule {
}
