import {AvatarComponent} from '../components/avatar/avatar.component';

export enum Sizes {
  xs = 22,
  sm = 32,
  md = 42,
  lg = 56,
  xl = 128
}

export interface Avatars {
  xs: AvatarComponent;
  sm: AvatarComponent;
  md: AvatarComponent;
  lg: AvatarComponent;
  xl: AvatarComponent;
}
