export interface ContactPersonOrganizationAssociation {
  id: string,
  personContactId: string,
  organizationContactId: string,
}

export function newCoPeOrAsfromResponse(resp: ContactPersonOrganizationAssociationReponse): ContactPersonOrganizationAssociation {
  return {
    id: resp.data.id,
    organizationContactId: resp.data.relationships.organization.data.id,
    personContactId: resp.data.relationships.person.data.id,
  }
}

export function newCoPeOrAsfromResponseList(resp: ContactPersonOrganizationAssociationListReponse): ContactPersonOrganizationAssociation[] {
  return resp.data.map((x: ContactPersonOrganizationAssociationResponseElement) => {
    return {
      id: x.id,
      organizationContactId: x.relationships.organization.data.id,
      personContactId: x.relationships.person.data.id,
    }
  })
}

export interface ContactPersonOrganizationAssociationResponseElement {
  type: string,
  id: string,
  relationships: {
    organization: {
      data: {
        id: string
      }
    },
    person: {
      data: {
        id: string
      }
    }
  }
}

export interface ContactPersonOrganizationAssociationReponse {
  data: ContactPersonOrganizationAssociationResponseElement
}

export interface ContactPersonOrganizationAssociationListReponse {
  data: ContactPersonOrganizationAssociationResponseElement[]
}
