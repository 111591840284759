import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {KanbanApiResourceService} from 'app/shared/modules/api-resource/services/kanban-api-resource.service';
import {ItemBuilder} from "./item.builder";
import {Item} from './item';
import { LabelStatsBuilder } from './label-stats.builder';
import { LabelStats } from './label-stats';

@Injectable()
export class ItemService {
  readonly BASE_PATH = 'api/v1/labels/items';

  constructor(private _http: KanbanApiResourceService) {
  }

  getAllLabelStats(): Observable<LabelStats[]> {
    const builder = new LabelStatsBuilder();
    return <Observable<LabelStats[]>>this._http.get<LabelStatsBuilder, LabelStats>(builder, `api/v1/labels/stats`);
  }
  getAll(query: any): Observable<Item[]> {
    const builder = new ItemBuilder();
    return <Observable<Item[]>>this._http.get<ItemBuilder, Item>(builder, `${this.BASE_PATH}${query}`);
  }
}
