import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {LoginSettings, OrganizationLogo} from './organization-logo';

export class OrganizationLogoBuilder implements IApiResourceBuilder<OrganizationLogo> {
  fromResponse(data): OrganizationLogo {
    return new OrganizationLogo(
      data.id,
      data.attributes.link,
      data.attributes['default'],
      data.attributes.title,
      data.attributes.organization_uuid,
      data.attributes.inviter_logo
    );
  }

  toRequest(_: OrganizationLogo) {
    return null
  }
}

export class LoginSettingsBuilder implements IApiResourceBuilder<LoginSettings> {
  fromResponse(data): LoginSettings {
    return new LoginSettings(
      data.id,
      data.attributes.impressum_url,
      data.attributes.login_header,
      data.attributes.login_footer,
      data.attributes.login_header_en,
      data.attributes.login_footer_en
    );
  }

  toRequest(_: LoginSettings) {
    return null
  }
}
