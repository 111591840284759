import { ICommand } from './icommand';

/**
 * Navigation command. Uses the Angular router, a client route to navigate to a client address.
 * The third parameter of the constructor is used to preserve query parameters.
 *
 * Routing in the client disrupts the control flow (execute() -> false).
 */
export class ClientNavigationCommand implements ICommand {

  /**
   * Creates a new <tt>ClientNavigationCommand</tt>.
   * @param _command Client command.
   * @param _router Angular router.
   */
  constructor(private _command, private _router) {
  }

  /**
   * Route to the given client route. Returns false to disrupt the control flow.
   * @returns {boolean}
   */
  execute() {
    const params = {};
    let preserveQueryParams = true;
    if (this._command.preserve_query_params != null) {
      preserveQueryParams = this._command.preserve_query_params;
    }
    
    if (preserveQueryParams) {
      params['queryParamsHandling'] = 'preserve';
    }

    if (this._command.replace_url) {
      params['replaceUrl'] = this._command.replace_url;
    }
    this._router.navigate([this._command.client_route], params);
    return false;
  }
}
