import {TaskEventActions, TaskEventActionTypes} from './task-event.actions';
import {adapter, initialState, State} from './task-event.state';

export function reducer(state = initialState, action: TaskEventActions): State {
  switch (action.type) {
    case TaskEventActionTypes.LoadAllForTask:
      return {
        ...state,
        loading: true
      };
    case TaskEventActionTypes.LoadAllForTaskSuccess:
      return adapter.setAll(action.payload, {
          ...state,
          loading: false,
      });
    case TaskEventActionTypes.LoadAllForTaskFail:
      return {
        ...state,
        loading: false
      };
  }
  return state;
}
