import { Action } from '@ngrx/store';

export const GET_EXTERNAL_PARTICIPANTS = 'GET_EXTERNAL_PARTICIPANTS';
export const GET_EXTERNAL_PARTICIPANTS_SUCCESS = 'GET_EXTERNAL_PARTICIPANTS_SUCCESS';
export const DELETE_EXTERNAL_PARTICIPANT = 'DELETE_EXTERNAL_PARTICIPANT';
export const DELETE_EXTERNAL_PARTICIPANT_SUCCESS = 'DELETE_EXTERNAL_PARTICIPANT_SUCCESS';
export const RESET_EXTERNAL_PARTICIPANTS = 'RESET_EXTERNAL_PARTICIPANTS';

export class GetExternalParticipants implements Action {
  readonly type: string = GET_EXTERNAL_PARTICIPANTS;

  constructor(public payload: any = null) { }
}

export class GetExternalParticipantsSuccess implements Action {
  readonly type: string = GET_EXTERNAL_PARTICIPANTS_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteExternalParticipant implements Action {
  readonly type: string = DELETE_EXTERNAL_PARTICIPANT;

  constructor(public payload: any) {}
}

export class DeleteExternalParticipantSuccess  implements Action {
  readonly type: string = DELETE_EXTERNAL_PARTICIPANT_SUCCESS;

  constructor(public payload: any) {}
}

export class ResetExternalParticipants implements Action {
  readonly type: string = RESET_EXTERNAL_PARTICIPANTS;

  constructor(public payload: any = null) { }
}


export type Actions = GetExternalParticipants
  | GetExternalParticipantsSuccess
  | DeleteExternalParticipant
  | DeleteExternalParticipantSuccess
  | ResetExternalParticipants;
