import {ClientActions, ClientActionTypes} from './client.actions';
import {adapter, initialState, State} from './client.state';

export function reducer(state = initialState, action: ClientActions): State {
  const current = state.selected;
  switch (action.type) {
    case ClientActionTypes.LoadAll:
    case ClientActionTypes.LoadOne:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case ClientActionTypes.Create:
    case ClientActionTypes.Remove:
    case ClientActionTypes.Save:
      return {
        ...state,
        loading: true
      };
    case ClientActionTypes.LoadAllSuccess:
      return adapter.setAll(action.clients, {
        ...state,
        selected: null,
        loading: false,
      });
    case ClientActionTypes.CreateSuccess:
    case ClientActionTypes.SaveSuccess:
    case ClientActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.client, {
        ...state,
        selected: action.client,
        loading: false,
      });
    case ClientActionTypes.CreateFail:
    case ClientActionTypes.LoadAllFail:
    case ClientActionTypes.RemoveFail:
    case ClientActionTypes.SaveFail:
    case ClientActionTypes.LoadOneFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
    case ClientActionTypes.RemoveSuccess:
      return adapter.removeOne(action.client.id, {
        ...state,
        selected: (current && current.id === action.client.id) ?  null : current,
        loading: false,
      });
  }
  return state;
}

