import {PageSidebarActions, PageSidebarActionTypes} from './page-sidebar.actions';
import {adapter, initialState, State} from './page-sidebar.state';

export function reducer(state = initialState, action: PageSidebarActions): State {
  switch (action.type) {
    case PageSidebarActionTypes.Open:
      return {
        ...state,
        selected: action.sidebar,
        open: action.sidebar.open
      };
    case PageSidebarActionTypes.Close:
      return {
        ...state,
        open: false
      };
    case PageSidebarActionTypes.Toggle: {
      return {
        ...state,
        collapsed: !!action.state
      };
    }
    case PageSidebarActionTypes.Reset:
      return {
        ...state,
        selected: null,
        open: false
      };
  }
  return state;
}
