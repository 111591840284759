<div class="w-100 d-flex flex-column">
  <div class="d-flex flex-column">
    <h2 class="mb-2">{{ 'ADDRESSBOOK.MOBILE_ACTIVATION.TITLE' | translate }}</h2>
    <p class="mb-2">{{ 'ADDRESSBOOK.MOBILE_ACTIVATION.DESCRIPTION' | translate }}</p>
  </div>

  <div class="d-flex align-items-center w-100 ">
    <button mat-raised-button
            class="ml-2 mobile-activation__button"
            color="primary"
            [disableRipple]="true"
            (click)="activateMobile()"
            [disabled]="!(isUserVerified$ | async) || (mobileActivation$ | async)?.enabled || (isLoading$ | async)">
      <ng-container *ngIf="!(isLoading$ | async); else spinner">
        {{ 'ADDRESSBOOK.MOBILE_ACTIVATION.ACTIVATE_ACTION' | translate }}
      </ng-container>
      <ng-template #spinner>
        <div class=" d-flex align-items-center">
          <mat-spinner diameter="16"></mat-spinner>
          <span class="mobile-activation--loading">{{ 'GENERAL.LOADING' | translate }}...</span>
        </div>
      </ng-template>
    </button>
    <ng-container *ngIf="!(isUserVerified$ | async)">
      <div class="p-2 mobile-activation--disabled d-flex align-items-center">
        <mat-icon class="mr-1">error</mat-icon>
        {{ 'ADDRESSBOOK.MOBILE_ACTIVATION.NOT_VERIFIED' | translate }}
      </div>
    </ng-container>
    <ng-container *ngIf="(mobileActivation$ | async)?.enabled">
      <div class="p-2 mobile-activation--success d-flex align-items-center">
        <mat-icon class="mr-1">check_circle</mat-icon>
        {{ 'ADDRESSBOOK.MOBILE_ACTIVATION.ACTIVATED' | translate }}
      </div>
    </ng-container>
  </div>
</div>
