import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {NgModule} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@swimlane/ngx-charts';
import { BaseFormElementsModule } from '../base-form-elements/base-form-elements.module';
import { SidebarOptionsComponent } from './container/sidebar-options/sidebar-options.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BaseFormElementsModule,
    MatBadgeModule,
    TranslateModule,
    PortalModule,
    TooltipModule,
  ],
  declarations: [
    SidebarOptionsComponent,
  ],
  exports: [
    SidebarOptionsComponent,
  ]
})
export class SidebarDetailsModule {
}
