import {Injectable} from '@angular/core';
import * as papaparse from 'papaparse';
import {ParseError, ParseResult} from 'papaparse';
import {endsWith, groupBy} from 'lodash';
import * as chardet from 'chardet';

export interface CsvColumn {
  title: string;
  key: string;
  editType?: 'number' | 'string' | 'email' | 'date';
  isRequired?: boolean;
  isNumber?: boolean;
  isEmail?: boolean;
  isGrouped?: true;
  groupOrder?: number;
  isHidden?: boolean;
}

export enum KnownCsvError {
  QUOTE_MISMATCH,
  ONE_OR_MORE_REQUIRED_CELLS_MISSING,
  TOO_FEW_ITEMS,
  TOO_MANY_ITEMS,
  ONE_OR_MORE_EMAILS_INVALID
}

export type CsvError = KnownCsvError | string;

export interface CsvRow {
  id?: number;
  value?: { [p: string]: any };
  data: string[];
  errors: CsvError[];
}

export interface ParsedFile {
  data: string[][];
  errors: any[];
}

export interface ParsedCsv {
  children: GroupedCsv[] | CsvRow[];
  errors: string[];
  filename: string;
  totalNumberOfErrors: number;
  value: any;
}

export interface GroupedCsv {
  title: string;
  children: GroupedCsv[] | CsvRow[];
}

export type CsvValue = {[key: string]: string}[];

@Injectable()
export class CsvImportService {
  public templateCsv(columns: CsvColumn[]) {
    const titles = columns.map((column) => column.title);
    return papaparse.unparse([titles])
  }

  public parse(file: File, columns: CsvColumn[]): Promise<ParsedCsv> {
    let parsedFilePromise: Promise<ParsedFile> = null;

    if (endsWith(file.name, 'csv')) {
      parsedFilePromise = CsvImportService.parseCsv(file);
    } else if (endsWith(file.name, 'xlsx') || endsWith(file.name, 'xls')) {
      parsedFilePromise = CsvImportService.parseXlsx(file);
    }

    if (!parsedFilePromise) {
      throw new Error(`No parser for file: ${file.name}`);
    }

    return parsedFilePromise.then<ParsedCsv>((parsedFile) => {
      const papaRowErrorsByRowId: {[rowId: number]: ParseError[]} = groupBy<ParseError>(parsedFile.errors, (error) => error.row);

      const rows = parsedFile.data
        .map((data: string[], rowId) => {
          return {
            data,
            errors: CsvImportService.getErrorsInDataRow(data, columns, papaRowErrorsByRowId[rowId])
          }
        })
        .slice(1) // Ignore header row
        .filter((row) => {
          for (const datum of row.data) {
            if (datum && datum.trim() && datum.trim().length > 0) {
              return true;
            }
          }

          return false;
        }); // Ignore empty children

      const errors = [];

      const totalNumberOfErrors = CsvImportService.getTotalNumberOfErrors(errors, rows);

      return {
        errors,
        children: CsvImportService.groupRows(rows, columns),
        filename: file.name,
        totalNumberOfErrors,
        value: totalNumberOfErrors ? null : CsvImportService.valueFromParsedCsv(rows, columns)
      }
    });
  }

  private static parseCsv(file: File): Promise<ParsedFile> {
    return new Promise<string>(((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (readFile: any) => {
        const encoding = chardet.detect(readFile.target.result);
        resolve(encoding);
      };

      reader.onerror = (e) => {
        reject(e);
      };

      reader.onabort = (e) => {
        reject(e);
      };

      reader.readAsBinaryString(file);
    })).then((encoding) => {
      return new Promise<ParseResult>((resolve, reject) => {
        papaparse.parse(file, {
          encoding: encoding,
          complete: function(results) {
            resolve(results);
          }, error: function(error) {
            reject(error);
          }
        });
      });
    })
  }

  private static parseXlsx(file: File): Promise<ParsedFile> {
    return new Promise<string>(((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (readFile: any) => {
        resolve(readFile.target.result);
      };

      reader.onerror = (e) => {
        reject(e);
      };

      reader.onabort = (e) => {
        reject(e);
      };

      reader.readAsBinaryString(file);
    }))
      .then((readFile): Promise<ParsedFile> => {
        return import(/* webpackChunkName: 'xlsx' */'xlsx').then((xlsx): ParsedFile => {
          const ws = xlsx.read(readFile, {type: 'binary'});

          const data: any[][] = xlsx.utils.sheet_to_json(ws.Sheets[ws.SheetNames[0]], {header: 1});

          return {
            data: data.map((row) => {
              return row.map((v) => '' + v)
            }),
            errors: []
          };
        });
      });
  }

  private static getErrorsInDataRow(dataRow: string[], columns: CsvColumn[], papaparseErrors: ParseError[]): CsvError[] {
    let errors: CsvError[] = [];
    if (papaparseErrors) {
      errors = papaparseErrors.map((papaparseError) => {
        if (papaparseError.type === 'Quotes' && papaparseError.code === 'InvalidQuotes') {
          return KnownCsvError.QUOTE_MISMATCH;
        }

        return papaparseError.message;
      });
    }

    if (dataRow.length < columns.length) {
      errors.push(KnownCsvError.TOO_FEW_ITEMS);
    }

    // New logic because of CAV update
    // if (dataRow.length > columns.length) {
    //   errors.push(KnownCsvError.TOO_MANY_ITEMS);
    // }

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].editType) {
        // This can be edited in the frontend, so we will show the exception next to the input
        continue;
      }

      const cellContainsValue = (dataRow[i] || '').trim();
      if (columns[i].isRequired && !cellContainsValue) {
        errors.push(KnownCsvError.ONE_OR_MORE_REQUIRED_CELLS_MISSING);
        break;
      }

      if (columns[i].isEmail && !/^.+@.+\..+$/.test((dataRow[i] || '').trim())) {
        errors.push(KnownCsvError.ONE_OR_MORE_EMAILS_INVALID);
        break;
      }
    }

    return errors;
  }

  private static groupRows(rows: CsvRow[], columns: CsvColumn[]): GroupedCsv[] | CsvRow[] {
    const allGroupedByColumns = CsvImportService.allGroupedByColumns(columns);

    return CsvImportService.groupByRecursion(rows, allGroupedByColumns);
  }

  private static groupByRecursion(rows: CsvRow[], columnsToGroupBy: { column: CsvColumn, index: number }[]): GroupedCsv[] | CsvRow[] {
    if (columnsToGroupBy.length === 0) {
      return rows;
    }
    const currentColumnToGroupBy = columnsToGroupBy[0];
    const remainingColumnsToGroupBy = columnsToGroupBy.slice(1);

    // Done manually as to keep the iteration order
    type TitleAndRows = {title: string, rows: CsvRow[]}; // tslint:disable-line
    const titleToGroupedCsv: {[title: string]: TitleAndRows} = {};

    const groups: TitleAndRows[] = [];

    for (const row of rows) {
      const title = row.data[currentColumnToGroupBy.index];

      if (titleToGroupedCsv.hasOwnProperty(title)) {
        titleToGroupedCsv[title].rows.push(row);
      } else {
        const newGroup = {title, rows: [row]};
        titleToGroupedCsv[title] = newGroup;
        groups.push(newGroup);
      }
    }

    return groups.map(({title, rows}) => { // tslint:disable-line
      return {
        title,
        children: CsvImportService.groupByRecursion(rows, remainingColumnsToGroupBy)
      };
    });
  }

  private static allGroupedByColumns(columns: CsvColumn[]): { column: CsvColumn, index: number }[] {
    return columns
      .map((column, index) => {
        return {column, index};
      })
      .filter(({column}) => {
        return column.isGrouped;
      })
      .sort((a, b) => {
        if (a.column.groupOrder !== b.column.groupOrder) {
          return a.column.groupOrder - b.column.groupOrder;
        }

        // Stable sort
        return a.index - b.index;
      });
  }

  private static getTotalNumberOfErrors(errors: CsvError[], rows: CsvRow[]) {
    return errors.length + rows.reduce<number>((numberOfErrors, row) => {
      return numberOfErrors + row.errors.length;
    }, 0);
  }

  private static valueFromParsedCsv(rows: CsvRow[], columns: CsvColumn[]): CsvValue | null {
    const value: CsvValue = [];

    for (const row of rows) {
      if (row.errors.length > 0) {
        return null;
      }

      const rowValue: {[key: string]: string} = {};

      for (let i = 0; i < columns.length; i++) {
        rowValue[columns[i].key] = row.data[i];
      }

      value.push(rowValue);
      row.value = rowValue;
    }

    return value;
  }
}
