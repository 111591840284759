import { Component, Inject, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dvtx-quick-upload-success-dialog-container',
  templateUrl: './quick-upload-success-dialog.component.html',
})
export class QuickUploadSuccessDialogContainerComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<QuickUploadSuccessDialogContainerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }
}
