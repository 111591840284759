import {
  AfterViewInit,
  ChangeDetectorRef,
  ContentChild,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Optional,
  Renderer2
} from '@angular/core';
import {InViewportDirective} from 'ng-in-viewport';
import {BehaviorSubject, interval, Subject, Subscription} from 'rxjs';
import {delay, filter, first, take, takeUntil, tap} from 'rxjs/operators';
import {DocumentPreviewPreview} from 'app/+store/document-preview-preview/document-preview-preview';
import {DocumentPreviewPreviewService} from 'app/+store/document-preview-preview/document-preview-preview.service';
import {DocumentPreviewPreviewState} from 'app/+store/document-preview-preview/document-preview-preview.interface';

@Directive({
  selector: '[dvtxLazyPreview]',
  exportAs: 'dvtxLazyPreview'
})
export class DvtxLazyPreviewDirective implements AfterViewInit, OnDestroy {
  onDestroy = new Subject<void>();
  onResize = new Subject<void>();
  @Input('dvtxLazyPreview') private preview: DocumentPreviewPreview;

  private static LOADING_CLASS_NAME = 'dvtx-lazy-preview-loading';
  private static LOADED_CLASS_NAME = 'dvtx-lazy-preview-loaded';
  private static INCOMPLETE_CLASS_NAME = 'dvtx-lazy-preview-incomplete';
  private static ERROR_CLASS_NAME = 'dvtx-lazy-preview-error';

  private loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isComplete$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private hasError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly subscription: Subscription = new Subscription();

  public get isComplete(): boolean {
    return this.isComplete$.getValue();
  }

  public set isComplete(value: boolean) {
    this.isComplete$.next(value);
    if (value) {
      this.renderer.removeClass(this.elementRef.nativeElement, DvtxLazyPreviewDirective.INCOMPLETE_CLASS_NAME);
    } else {
      this.renderer.addClass(this.elementRef.nativeElement, DvtxLazyPreviewDirective.INCOMPLETE_CLASS_NAME);
    }
    this._cdr.markForCheck();
  }

  public get loading(): boolean {
    return this.loading$.getValue();
  }

  public set loading(value: boolean) {
    this.loading$.next(value);
    if (value) {
      this.renderer.addClass(this.elementRef.nativeElement, DvtxLazyPreviewDirective.LOADING_CLASS_NAME);
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, DvtxLazyPreviewDirective.LOADING_CLASS_NAME);
    }
    this._cdr.markForCheck();
  }

  public get loaded(): boolean {
    return this.loaded$.getValue();
  }

  public set loaded(value: boolean) {
    this.loaded$.next(value);
    if (value) {
      this.renderer.addClass(this.elementRef.nativeElement, DvtxLazyPreviewDirective.LOADED_CLASS_NAME);
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, DvtxLazyPreviewDirective.LOADED_CLASS_NAME);
    }
    this._cdr.markForCheck();
  }

  public get hasError(): boolean {
    return this.hasError$.getValue();
  }

  public set hasError(value: boolean) {
    this.hasError$.next(value);
    if (value) {
      this.renderer.addClass(this.elementRef.nativeElement, DvtxLazyPreviewDirective.ERROR_CLASS_NAME);
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, DvtxLazyPreviewDirective.ERROR_CLASS_NAME);
    }
    this._cdr.markForCheck();
  }


  // @ContentChild(InViewportDirective) private inViewport: InViewportDirective;

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
              private previewService: DocumentPreviewPreviewService,
              private _cdr: ChangeDetectorRef,
              @Optional() private readonly inViewport: InViewportDirective
              ) { }

  public ngAfterViewInit(): void {
    // TODO: Fake it till you make it: Worst hack to trigger ngInViewPort to reevaluate the visibility in viewport:
    // Case: Component is invoked inside OnPush parent. First application is fine, next invocations return inViewportAction->visible false
    // in changedetection.Default environments this does not happen.
    // Resize event on Browser triggers a reevaluation, so this hack.
    setTimeout(_ => {
      interval(500).pipe(takeUntil(this.onDestroy), takeUntil(this.onResize)).subscribe(_ => {
        window.dispatchEvent(new Event('resize'));
        // Not working on OnPush
        // this._cdr.detectChanges();
      });
    });

    if (this.inViewport) {
      this.subscription.add(
        this.inViewport.inViewportAction
          .pipe(
            filter(({ visible }) => visible),
            take(1),
            takeUntil(this.onDestroy)
          )
          .subscribe(() => {
            this.load();
            this.onResize.next();
          })
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.onDestroy.next();
    this.onDestroy.complete();
    this.onResize.next();
    this.onResize.complete();
    this.loading$.complete();
    this.loaded$.complete();
    this.isComplete$.complete();
    this.hasError$.complete();
  }

  public load(): void {
    this.loading = true;
    this.subscription.add(
      this.previewService.getOneByUrl(this.preview.url)
        .pipe(
          first(),
          tap((data: DocumentPreviewPreview) => {
            if (data.state === DocumentPreviewPreviewState.Completed) {
              this.renderer.setAttribute(this.elementRef.nativeElement, 'src', data.content);
            }
          }),
          delay(10)
        )
        .subscribe(
          (data: DocumentPreviewPreview) => {
            this.isComplete = data.state === DocumentPreviewPreviewState.Completed;
            this.loading = false;
            this.loaded = true;
            this.hasError = false;
          },
          (error) => {
            this.isComplete = false;
            this.loading = false;
            this.loaded = true;
            this.hasError = true;
            // this.snackBar.open(error, undefined, { duration: 3000, panelClass: 'error-snackbar' });
          }
        )
    );
  }
}
