import {DmsDocumentActions, DmsDocumentActionTypes} from './dms-document.actions';
import {adapter, initialState, State} from './dms-document.state';

export function reducer(state = initialState, action: DmsDocumentActions): State {
  const current = state.selected;
  switch (action.type) {
    case DmsDocumentActionTypes.LoadAll:
    case DmsDocumentActionTypes.LoadAllOfProcessFolder:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case DmsDocumentActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.documents, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case DmsDocumentActionTypes.LoadAllOfProcessFolderSuccess:
      return adapter.setAll(action.documents, {
          ...state,
          selected: current ? state.entities[current.id] : null,
          loading: false,
      });
    case DmsDocumentActionTypes.LoadAllFail:
    case DmsDocumentActionTypes.LoadAllOfProcessFolderFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
    case DmsDocumentActionTypes.LoadOne:
    case DmsDocumentActionTypes.Remove:
    case DmsDocumentActionTypes.Destroy:
      return {
        ...state,
        selected: null,
        loading: false
      };
    case DmsDocumentActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.document, {
        ...state,
        selected: action.document,
        loading: false,
      });
    case DmsDocumentActionTypes.RemoveSuccess:
    case DmsDocumentActionTypes.DestroySuccess:
      return adapter.removeOne(action.id, {
        ...state,
        selected: null,
        loading: false,
      });
    case DmsDocumentActionTypes.LoadOneFail:
    case DmsDocumentActionTypes.RemoveFail:
    case DmsDocumentActionTypes.DestroyFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
    case DmsDocumentActionTypes.Reset:
      return Object.assign({}, initialState);
  }
  return state;
}

