import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../reducers';
import {UserLogoutRequest} from '../../actions/user-actions';
import {AngularTokenService} from 'angular-token';

@Component({
  template: `
    <div class="d-flex justify-content-center">
      <router-outlet></router-outlet>
    </div>`,
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent {
  constructor(private _store: Store<AppState>,
              private _authTokenService: AngularTokenService) {
    if (this._authTokenService.userSignedIn()) {
      this._store.dispatch(new UserLogoutRequest());
    }
  }
}
