import {LicenceAssignment} from '../models/licence';

/**
 * Create LineItems - the respresentation of articles in
 * the shopping cart.
 */
export class LicenceAssignmentBuilder {
  public static toRequest(_: LicenceAssignment) {
    throw new Error('Not implemented');
  }

  public static fromResponse(response, _catalog = null): LicenceAssignment {
    const attrs = response;
    // const id = response.id;
    const membershipId = attrs.membership_uuid;

    switch (attrs.type) {
      // case 'sales_licence_professionals':
      //   return new LicenceAssignment(id, attrs.type, membershipId,
      //   null, null, null, licenceId.toString(), null, null, null);
      //
      // case 'sales_licence_teams':
      //   return new LicenceAssignment(id, attrs.type, membershipId,
      //     null, null, licenceId.toString(), null, null, null, null);
      //
      // case 'sales_licence_annual_auditings':
      //   return new LicenceAssignment(id, attrs.type, membershipId,
      //     null, licenceId.toString(), null, null, null, null, null);
      //
      // case 'sales_licence_group_auditings':
      //   return new LicenceAssignment(id, attrs.type, membershipId,
      //     licenceId.toString(), null, null, null, null, null, null);

      case 'sales_licence_standard':
        return new LicenceAssignment(attrs.type, 'sales_licence_standard', membershipId,
          null, null, null, null, attrs.type.toString(), null, null, attrs)

      case 'sales_licence_professional':
      // case 'sales_licence_bases':
        return new LicenceAssignment(attrs.type, 'sales_licence_professional', membershipId,
          null, null, null, null, null, attrs.type.toString(), null, attrs)

      case 'sales_licence_enterprise':
        return new LicenceAssignment(attrs.type, 'sales_licence_enterprise', membershipId,
          null, null, null, null, null, null, attrs.type.toString(), attrs)

      default:
        throw new Error();
    }
  }
}
