<dvtx-user-profile-settings-header [routes]="routes"
                                   [activeLink]="activeLink"
                                   [title]="'SIGNATURE.ACCOUNT.ACCOUNT_DATA'"
                                   [subtitle]="'SIGNATURE.ACCOUNT.ACCOUNT_DATA'">
</dvtx-user-profile-settings-header>

<div class="five-f-settings-container">
  <div class="mt-2 d-flex">
    <img src="./assets/img/fp_sign.png" style="width: 180px" />
  </div>

  <!--  <div class="d-flex align-items-center mt-1 mb-2">-->
  <!--    <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>-->
  <!--    <div [innerHTML]="'SIGNATURE.ACCOUNT.ACCOUNT_SETTINGS_INFO_LONG' | translate" class="mb-0"></div>-->
  <!--  </div>-->

  <dvtx-document-signature-user-account-dialog [action]="DocumentSignatureUserAccountDialogMenuViewType.Embedded">
  </dvtx-document-signature-user-account-dialog>
</div>
