import {Component, Output, EventEmitter, Input, OnInit, OnChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl} from '@angular/forms';
import {RegistrationService} from '../../services/registration.service';
import {RegistrationUser} from '../../models/registration-user.interface';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'dvtx-password-form-v2',
  templateUrl: './password-form-v2.component.html',
  styleUrls: ['./password-form-v2.component.scss']
})

export class PasswordRegistrationFormV2Component implements OnInit, OnChanges {
  @Input() user: RegistrationUser;
  @Output() onNext = new EventEmitter();

  PASSWORD_MIN_LENGTH = 8;
  form: UntypedFormGroup;
  verifyPasswordResponse: any;
  @Input() loading = false;

  fieldType = fieldType;
  textType: string;
  textTypeFirst: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private _registrationSvc: RegistrationService) {

    this.textType = fieldType.Password;
    this.textTypeFirst = fieldType.Password;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (changes.user.currentValue) {
      this.createForm();
    }
  }

  createForm() {
    const noWhiteSpace = new RegExp(/^\S*$/);
    const group = {
      first_name: [this.user.firstName, Validators.required],
      last_name: [this.user.lastName, Validators.required],
      password: [this.user.password, [
        Validators.required,
        Validators.minLength(this.PASSWORD_MIN_LENGTH),
        Validators.pattern(this._registrationSvc.passwordPatternExpr),
        Validators.pattern(noWhiteSpace)
      ]],
      passwordConfirm: [this.user.passwordConfirm, [Validators.required, Validators.minLength(this.PASSWORD_MIN_LENGTH)]],
      terms: [this.user.terms, Validators.required]
    };

    this.form = this.formBuilder.group(
      group,
      {
        validator: this.MatchPassword
      });

  }

  MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value; // to get value in input tag
    const passwordConfirm = AC.get('passwordConfirm').value; // to get value in input tag
    if (password !== passwordConfirm || !password) {
      AC.get('passwordConfirm').setErrors({MatchPassword: true});
    } else {
      return null;
    }
  }

  get passwordform() {
    return this.form.get('password');
  }

  showPassword() {
    this.textType = fieldType.Text;
  }

  hidePassword() {
    this.textType = fieldType.Password;
  }

  hidePasswordSecond() {
    this.textTypeFirst = fieldType.Password;
  }

  ShowPasswordSecond() {
    this.textTypeFirst = fieldType.Text;
  }

  verifyPassword(password: string) {
    this._registrationSvc.verifyPassword(password).subscribe(response => {
      this.verifyPasswordResponse = response;
    });
  }

  submit(): void {
    // if (this.form.invalid) return;
    const values = this.form.value;
    const updatedUser = <RegistrationUser>values;
    console.error(values);
    console.error(updatedUser);

    updatedUser.email = this.user.email;
    updatedUser.firstName = values.first_name;
    updatedUser.lastName = values.last_name;
    updatedUser.confirmationToken = this.user.confirmationToken;
    this.onNext.emit(updatedUser);
  }

  public readTerms(event: MatCheckboxChange): void {
    this.form.controls.terms.setValue(event.checked);
    this.form.controls.terms.updateValueAndValidity();
  }
}

export enum fieldType {
  Text = 'text',
  Password = 'password',
}
