import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogV2Component} from 'app/five-f/confirm-dialog/components/confirm-dialog/confirm-dialog-v2.component';

@Component({
  selector: 'dvtx-message-panel-action-dropdown',
  templateUrl: './message-panel-action-dropdown.component.html',
  styleUrls: ['./message-panel-action-dropdown.component.scss']
})
export class MessagePanelActionDropdownComponent implements OnInit, AfterViewInit {

  @Output() onEditMode = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onReply = new EventEmitter();
  @Input() enableReplyButton = false;
  @Input() isAuthor = false;
  @Input() isClosed = true;
  @Input() enableReply = true;

  constructor(private _store: Store<AppState>,
              private _dialog: MatDialog) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  public deleteComment($event) {
    const dialogRef = this._dialog.open(ConfirmDialogV2Component, {
      data: {
        title: 'GENERAL.DELETE_COMMENT',
        body: 'GENERAL.DELETE_COMMENT_QUESTION'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.onDelete.emit($event);
      }
    });
  }
}
