import { LicenceProduct, LicenceProductDescription } from '../models/licence';

export class LicenceProductBuilder {
  public static fromResponseList(dataArray): LicenceProduct[] {
    const ret: LicenceProduct[] = [];
    for (const element of dataArray) {
      ret.push(LicenceProductBuilder.fromResponse(element))
    }
    return ret;
  }

  public static fromResponse(data): LicenceProduct {
    const attrs = data.attributes;
    return new LicenceProduct(
      data.id,
      attrs.title,
      attrs.price,
      new LicenceProductDescription(
        attrs.title,
        attrs.description,
        attrs.footer,
        attrs.properties.feature_set,
        attrs.display_order
      )
    )
  }
}
