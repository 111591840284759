import {Action} from '@ngrx/store';
import {CollectorExport} from './collector-export';

export enum CollectorExportActionTypes {
  Download = '[CollectorExport] Download',
  DownloadSuccess = '[CollectorExport] Download Success',
  DownloadFail = '[CollectorExport] Download Fail',
}

export class Download implements Action {
  readonly type = CollectorExportActionTypes.Download;

  constructor(public collector: CollectorExport) {
  }
}

export class DownloadSuccess implements Action {
  readonly type = CollectorExportActionTypes.DownloadSuccess;

  constructor(public payload: any) {
  }
}

export class DownloadFail implements Action {
  readonly type = CollectorExportActionTypes.DownloadFail;

  constructor(public payload: any) {
  }
}

export type CollectorExportActions =
  | Download
  | DownloadSuccess
  | DownloadFail
