<dvtx-user-profile-settings-header [routes]="routes" [activeLink]="activeLink"
                                   [title]="'SIGNATURE.ACCOUNT.ACCOUNT_DATA'" [subtitle]="'SIGNATURE.ACCOUNT.FP_SIGN_SETTINGS'">
</dvtx-user-profile-settings-header>

<div class="five-f-settings-container">
  <div class="row w-100 mx-0 mb-2">
    <div class="col-12 p-0">
        <div>
          <div class="mb-1 d-flex">
            <img src="./assets/img/fp_sign.png" style="width: 180px" />
          </div>
          <div class="mb-2 d-flex">
            <h3>{{ 'SIGNATURE.ACCOUNT.FP_SIGN_SETTINGS_INFO' | translate }}</h3>
          </div>
          <div class="mb-1 d-flex">
            <h4>{{ 'SIGNATURE.ACCOUNT.FP_SIGN_SETTINGS_LINK_INFO' | translate }}</h4>
          </div>
          <div class="d-flex">
            <a class="p-0" [href]="fpSignLoginLink" mat-button color="primary" target="_blank">{{
              'SIGNATURE.ACCOUNT.TO_FP_SIGN_LOGIN' | translate }}</a>
          </div>
        </div>
    </div>
  </div>
</div>
