import {QuickshareActions, QuickshareActionTypes} from './quickshare.actions';
import {adapter, initialState, State} from './quickshare.state';

export function reducer(state = initialState, action: QuickshareActions): State {
  const current = state.selected;
  switch (action.type) {
    case QuickshareActionTypes.LoadAll:
    case QuickshareActionTypes.Create:
    case QuickshareActionTypes.LoadOne:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case QuickshareActionTypes.SaveDraft:
    case QuickshareActionTypes.Publish:
    case QuickshareActionTypes.UpdateRecipients:
      return {
        ...state,
        loading: true
      };
    case QuickshareActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.quickshares, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case QuickshareActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
    case QuickshareActionTypes.LoadOneSuccess:
    case QuickshareActionTypes.CreateSuccess:
    case QuickshareActionTypes.SaveDraftSuccess:
    case QuickshareActionTypes.PublishSuccess:
    case QuickshareActionTypes.UpdateRecipientsSuccess:
      return adapter.upsertOne(action.quickshare, {
        ...state,
        selected: action.quickshare,
        loading: false,
      });
    case QuickshareActionTypes.LoadOneFail:
    case QuickshareActionTypes.SaveDraftFail:
    case QuickshareActionTypes.UpdateRecipientsFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
    case QuickshareActionTypes.PublishFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

