export namespace PartnerBuilder {
  export function buildServiceTypes(response) {
    return response.data.map(elem => {
      return {
        id: elem.id,
        type: elem.type,
        name: elem.attributes.name,
        order: elem.attributes.order,
        value: elem.attributes.value
      };
    });
  }

}
