import {
  IFastdocsDates,
  IFastdocsDmsFolder,
  IFastdocsExtended,
  IFastdocsIndicators,
  IFastdocsPerson,
  IFastdocsProcess,
  IFastdocsStatus,
  IFiveFStatus,
  IIsFastdocs
} from './fastdocs.interface';
import {IResource} from 'app/shared/modules/api/models/resource.interface';

export class Fastdocs implements IFastdocsExtended {
  readonly type = 'fastdocs';

  constructor(
    public id: string,
    public title: string,
    public color: string,
    public process: IFastdocsProcess,
    public dmsFolder: IFastdocsDmsFolder,
    public fiveFStatus: IFiveFStatus,
    public fastdocsStatuses: {[id: string]: IFastdocsStatus},
    public participants: IFastdocsPerson[],
    public dates: IFastdocsDates,
    public duration: number,
    public indicators: IFastdocsIndicators,
    public is: IIsFastdocs,
    public owner: IFastdocsPerson,
    public creator: IFastdocsPerson,
    public description: string = null,
    public templateCount: number,
    public documentCount: number,
    public notificationCount: number,
    public createdAt: Date,
    public startsAt: Date,
    public amIParticipant: boolean = false,
  ) {
  }

  static sortByOrder(l, r) {
    if (l.order === r.order) return 0;
    if (!l.order) return -1;
    if (!r.order) return 1;
    return l.order < r.order ? -1 : 1;
  }
}

export class FastdocsSubscription implements IResource {
  readonly type = 'fastdocs_status';

  constructor(public id: string, public email: string, public created_at: Date) {
  }
}
