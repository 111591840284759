import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './society.state';
import {CommentSelectors} from '../comment';
import {ProcessArtifactSelectors} from '../process-artifact';
import {BasicContact} from './basic-contact';

export const stateKey = 'society';
const getSocietyState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getSocietyEntities,
  selectAll: getAllSocieties,
} = adapter.getSelectors(getSocietyState);

export const getLoadingState = createSelector(
  getSocietyState,
  state => state.loading
);

export const getSocietyById = (id: string) => createSelector(
  getSocietyEntities,
  (entities) => entities[id]
);

export const getSocietyDataSheet = (processId, id: string) => createSelector(
  getSocietyById(id),
  ProcessArtifactSelectors.getAssignedContributedByProcessCount(processId),
  CommentSelectors.getCommentCountByProcessId(processId),
  (society, contributedCount, commentCount) => {
    let appendicesCount = 0;
    let onHold = false;
    let validMateriality = false;
    let contactReachable = false;
    let countedContacts = 0;

    if (society) {
      appendicesCount = society.appendices.filter(a => a.checked).length || 0;
      onHold = society && !!society.contacts.find((c: BasicContact) => !c.verifiedAt || !c.hasRegisteredAccount);
      contactReachable = society && society.contacts && !!society.contacts.find((c: BasicContact) => !!c.verifiedAt && !!c.hasRegisteredAccount);
      validMateriality = !!society.toleranceMateriality || !!society.componentMateriality || !!society.toleranceLimit;
      if (contactReachable) {
        countedContacts = society.contacts.filter((c: BasicContact) => !!c.verifiedAt || !!c.hasRegisteredAccount).length;
      } else {
        countedContacts = society.contacts ? society.contacts.length : 0;
      }
      // Grau sobald einer verifiziert und account
      // default orange
      // All wenn keiner verifziert
      // Count: anzahl die grauen

      if (society.id === '28501ba6-7a84-42f3-9e91-b96ee04d69a7') {
        console.error('validMateriality', validMateriality)
      }
    }
    return {
      society: society,
      artifactCount: contributedCount || 0,
      commentCount: commentCount || 0,
      appendicesCount: appendicesCount,
      onHold: onHold,
      contactReachable: contactReachable,
      validMateriality: validMateriality,
      countedContacts: countedContacts
    }
  }
);
