import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {reducer} from './contact.reducer';
import {stateKey} from './contact.selectors';
import {ContactEffects} from './contact.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ContactEffects])
  ],
  providers: [ContactEffects]
})
export class ContactsStoreModule {
}
