import { Action } from '@ngrx/store';

export const GET_AUDIT_ORDER = 'GET_AUDIT_ORDER';
export const GET_AUDIT_ORDER_SUCCESS = 'GET_AUDIT_ORDER_SUCCESS';
export const ADD_AUDIT_ORDER = 'ADD_AUDIT_ORDER';
export const GET_AUDIT_ORDERS = 'GET_AUDIT_ORDERS';
export const GET_AUDIT_ORDERS_SUCCESS = 'GET_AUDIT_ORDERS_SUCCESS';
export const RESET_AUDIT_ORDERS = 'RESET_AUDIT_ORDERS';

export class AddAuditOrder implements Action {
  readonly type: string = ADD_AUDIT_ORDER;

  constructor(public payload: any) { }
}

export class GetAuditOrder implements Action {
  readonly type: string = GET_AUDIT_ORDER;

  constructor(public payload: any = null) { }
}

export class GetAuditOrderSuccess implements Action {
  readonly type: string = GET_AUDIT_ORDER_SUCCESS;

  constructor(public payload: any) {}
}

export class GetAuditOrders implements Action {
  readonly type: string = GET_AUDIT_ORDERS;

  constructor(public payload: any = null) { }
}

export class GetAuditOrdersSuccess implements Action {
  readonly type: string = GET_AUDIT_ORDERS_SUCCESS;

  constructor(public payload: any) {}
}

export class ResetAuditOrders implements Action {
  readonly type: string = RESET_AUDIT_ORDERS;

  constructor(public payload: any = null) { }
}

export type Actions = AddAuditOrder
  | GetAuditOrder
  | GetAuditOrderSuccess
  | GetAuditOrders
  | GetAuditOrdersSuccess
  | ResetAuditOrders;
