import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, Input,
  NgZone,
  OnDestroy,
  OnInit, TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {map, filter, first, distinctUntilKeyChanged, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {
  MembershipActions,
  NaturalPersonSelectors,
  FeatureSelectors, MembershipSelectors,
} from 'app/+store';
import {DialogData, EditRolesComponent} from '../../../edit-roles/edit-roles.component';
import {NaturalPerson} from '../../../../../models/natural-person.model';
import {OrganizationSelectors} from 'app/+store';
import {AppState} from '../../../../../reducers';
import {Subject, Observable, Subscription, of, BehaviorSubject, combineLatest} from 'rxjs';
import {Organization} from 'app/models/organization.model';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {TwoFactorSessionService} from 'app/+store/two-factor-session/two-factor-session.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ProcessRoleService} from 'app/+store/process-role/process-role.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {SplitViewDialogComponent} from 'app/shared/modules/page-container/split-view-dialog/split-view-dialog.component';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {UntypedFormControl} from '@angular/forms';
import {AngularTokenService} from 'angular-token';
import {fieldType} from 'app/modules/registration/components/password-form/password-form.component';
import {OperatorMemberService} from 'app/+store/operator/member/member.service';
import {RegistrationService} from 'app/modules/registration/services/registration.service';
import {Member, OrganizationEmployeeRepository} from './organization-employee.repository';

@Component({
  selector: 'dvtx-organization-employee',
  templateUrl: './organization-employee.component.html',
  styleUrls: ['./organization-employee.component.scss']
})
export class OrganizationEmployeeComponent implements AfterViewInit, OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();
  private repository: OrganizationEmployeeRepository;
  public members$: BehaviorSubject<Member[]>;
  public selectedMember: Member = null;
  public member2FAStatusMapLoading$: Observable<boolean>;
  public amIProjectRoomAdmin$: Observable<boolean>;

  // columnsToDisplay = ['avatar', 'name', 'email', 'roles', 'actions']; // actions coloumn was removed because we wasnt to change the dialog into slid toggles
  columnsToDisplay = ['avatar', 'name', 'email', 'roles', 'process-management', '2-fa', 'actions'];
  // data : EditRoleToggleComponent
  organization: Organization;
  ownId: Observable<any>;
  membershipsSubscription: Subscription;

  featureSet$;
  memberShip$;

  dialogTitle = 'ORGANIZATION.REMOVE_MEMBER';
  submitOnGoing = false;
  fieldType = fieldType;
  showFirstPass: string = fieldType.Password;
  showResetPassword = false;
  @ViewChild('buttonsToolbar', {static: true}) buttonsToolbar: TemplateRef<any>;
  @ViewChild('context', {static: true}) context: TemplateRef<any>;
  @ViewChild('title', {static: true}) titleRef: TemplateRef<any>;
  @ViewChild('headerShowMore', {static: true}) headerShowMore: TemplateRef<any>;
  @ViewChild('bodyShowMore', {static: true}) bodyShowMore: TemplateRef<any>;
  @ViewChild('footerShowMore', {static: true}) footerShowMore: TemplateRef<any>;
  buttonsToolbarPortal: Portal<any>;
  contextPortal: Portal<any>;
  titlePortal: Portal<any>;
  headerShowMorePortal: Portal<any>;
  bodyShowMorePortal: Portal<any>;
  footerShowMorePortal: Portal<any>;
  dialogRef: MatDialogRef<SplitViewDialogComponent>;

  selectedMemberForRemoval;
  selectedMemberShipForRemoval;
  selectedMemberEmailForRemoval;
  selectedOwner: NaturalPerson;
  ownerFilter$ = new BehaviorSubject(null);
  ownerSearchField: UntypedFormControl;
  isOwner$: Observable<boolean>;
  currentUserUID: string;
  passwordResetValue: string;
  memberShipLoading$: Observable<boolean>;

  private query$ = new BehaviorSubject<string>('');
  public filterAdmin$ = new BehaviorSubject<any>([]);

  persons: NaturalPerson[];

  selectedWorkflow: string = 'workflow';

  showWorkflows = false;
  passwordChangeOngoing = false;

  fastdocsToggleState = false;

  digitalbarToggleState = false;

  fivefAgentToggleState = false;

  fivefDesktopToggleState = false;

  datevToggleState = false;

  bookmanToggleState = false;

  @Input() set search(searchTerm: string) {
    this.repository.search(searchTerm);
  }

  @Input() set filter(value: any) {
    this.repository.filterAdmin(value);
  }

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private _store: Store<AppState>,
              private registrationService: RegistrationService,
              private _router: Router,
              private avatarService: AvatarService,
              private _memberSvc: OperatorMemberService,
              private _twoFactorSessionSvc: TwoFactorSessionService,
              private _processRoleSvc: ProcessRoleService,
              private _dialog: MatDialog,
              private _viewContainerRef: ViewContainerRef,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService,
              private _tokenSvc: AngularTokenService,
              private _cdr: ChangeDetectorRef,
              private _ngZone: NgZone) {
    this.repository = new OrganizationEmployeeRepository(
      _store,
      _tokenSvc,
      _memberSvc,
      _twoFactorSessionSvc,
      _processRoleSvc,
      _translateSvc,
      _notifyService,
      _ngZone
    );
    this.members$ = this.repository.data;
    this.member2FAStatusMapLoading$ = this.repository.get2FaLoadingState();
    this.amIProjectRoomAdmin$ = this.repository.amIProjectRoomAdmin();

    this.ownerSearchField = new UntypedFormControl();

    this._store.select(OrganizationSelectors.getSelected).pipe(
      filter(org => !!org),
      distinctUntilKeyChanged('id'),
      first(),
    ).subscribe((org) => {
      if (org) {
        this.organization = org;
        this.ownId = this._store.select('currentUser');
        this.repository.fetch(org);
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.query$.next(filterValue);
  }

  ngOnInit() {
    this.currentUserUID = this._tokenSvc.currentAuthData.uid;
    this.memberShip$ = this._store.select(MembershipSelectors.getMyMembership);
    this.isOwner$ = this._store.select(MembershipSelectors.isOwner);
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
    this.memberShipLoading$ = this._store.select(MembershipSelectors.getMembershipsLoading);

    this.repository.selected$().pipe(takeUntil(this.onDestroy)).subscribe(member => {
      this.selectedMember = member;
      this._cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.repository.destroy();
  }

  showResetPass() {
    this.showResetPassword = true;
    this.passwordResetValue = '';
  }

  showFirstPassword() {
    this.showFirstPass = fieldType.Text;
  }

  hideFirstPassword() {
    this.showFirstPass = fieldType.Password;
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this._initContextPortal();
      this._initTitlePortal();
      this._initButtonToolbarPortal();
      this._initheaderShowMorePortal();
      this._initbodyShowMorePortal();
      this._initfooterShowMorePortal();
    });
  }

  getMemberShip(member) {
    if (!member) return of(null);
    return this._store.select(MembershipSelectors.getMembershipById(member.membershipId));
  }

  handleManageEmployeeClick(natPersonId: string) {
    const data: DialogData = {natPersonId}
    this._dialog.open(EditRolesComponent, {
      panelClass: 'dialog-xl',
      data
    });
  }

  toggleProcessManagementAdmin($event: MatCheckboxChange, email: string, projectRoomAdminRoleId: string) {
    const selectedMember = this.selectedMember;
    if (!selectedMember) {
      console.error('Selected member unset');
      return;
    }

    if ($event.checked) {
      this._processRoleSvc.grantProjectRoomAdminRole(email)
        .pipe(first())
        .subscribe(admin => {
          this.repository.updatePermissions(selectedMember.membershipId);
          this._notifyService.success('GENERAL.UPDATE_ACTION_DONE')
        }, err => {
          console.error(err);
          this.repository.updatePermissions(selectedMember.membershipId);
          this._notifyService.error('HTTP_ERROR.DEFAULT')
        });
    } else {
      if (projectRoomAdminRoleId) {
        this._processRoleSvc.revokeProjectRoomAdminRole(projectRoomAdminRoleId)
          .pipe(first())
          .subscribe(admin => {
            this.repository.updatePermissions(selectedMember.membershipId);
            this._notifyService.success('GENERAL.UPDATE_ACTION_DONE')
          }, err => {
            console.error(err);
            this.repository.updatePermissions(selectedMember.membershipId);
            this._notifyService.error('HTTP_ERROR.DEFAULT')
          });
      }
    }
  }

  openRemovalDialog(memberEmail, memberId, membershipId): void {
    this._initContextPortal();
    this._initTitlePortal();
    this._initButtonToolbarPortal();

    this.selectedMemberEmailForRemoval = memberEmail;
    this.selectedMemberForRemoval = memberId;
    this.selectedMemberShipForRemoval = membershipId;
    this.selectedOwner = null;
    this.resetPersonSearch();

    this.dialogRef = this._dialog.open(SplitViewDialogComponent, {
      data: {
        color: '#db0000',
        icon: 'warning',
        title: this.titlePortal,
        context: this.contextPortal,
        buttonsToolbar: this.buttonsToolbarPortal
      }
    });
  }

  openShowMoreDialog(member: Member) {
    this._initbodyShowMorePortal();
    this._initheaderShowMorePortal();
    this._initfooterShowMorePortal();

    this.selectedMember = member;
    this.fastdocsToggleState = member.isFastdocsEnabled;
    this.digitalbarToggleState = member.isDigitalbarEnabled;
    this.fivefAgentToggleState = member.isFivefAgentEnabled;
    this.fivefDesktopToggleState = member.isFivefDesktopEnabled;
    this.datevToggleState = member.isDatevEnabled;
    this.bookmanToggleState = member.isBookmanEnabled;

    const selectedSubscription = this.repository.getSelectedMember(member)
      .pipe(filter(m => !!m), first())
      .subscribe((updatedMember) => {
        if (!updatedMember) {
          this._notifyService.error('HTTP_ERROR.DEFAULT')
          return;
        }

        this.selectedMember = updatedMember;
        if (updatedMember.isWorkflowCreationEnabled) {
          this.selectedWorkflow = 'workflow';
        } else if (updatedMember.isSubWorkflowCreationEnabled && !updatedMember.isWorkflowCreationEnabled) {
          this.selectedWorkflow = 'subworkflow';
        }

        this.fastdocsToggleState = updatedMember.isFastdocsEnabled;
        this.digitalbarToggleState = updatedMember.isDigitalbarEnabled;
        this.fivefAgentToggleState = updatedMember.isFivefAgentEnabled;
        this.fivefDesktopToggleState = updatedMember.isFivefDesktopEnabled;
        this.datevToggleState = updatedMember.isDatevEnabled;
        this.bookmanToggleState = updatedMember.isBookmanEnabled;

        this.selectedWorkflow = 'workflow';
        if (member.isWorkflowCreationEnabled) {
          this.selectedWorkflow = 'workflow';
        } else if (member.isSubWorkflowCreationEnabled && !member.isWorkflowCreationEnabled) {
          this.selectedWorkflow = 'subworkflow';
        }

        this.dialogRef = this._dialog.open(SplitViewDialogComponent, {
          panelClass: 'five-f-sidebar-dialog',
          data: {
            icon: '',
            title: this.headerShowMorePortal,
            context: this.bodyShowMorePortal,
            buttonsToolbar: this.footerShowMorePortal,
            classNames: 'five-f-sidebar-dialog-container mat-dialog-content-height'
          }
        });

        this.dialogRef.afterClosed().subscribe(_ => {
          this.showResetPassword = false;
          selectedSubscription.unsubscribe();
        });

        this.dialogRef.afterOpened().subscribe(_ => {
          setTimeout(() => {
            (document.activeElement as HTMLElement).blur();
          }, 50);
        });

        this._cdr.detectChanges();
      });
  }

  public removeMember(): void {
    const selectedMemberForRemoval = this.selectedMemberForRemoval;
    const selectedMemberShipForRemoval = this.selectedMemberShipForRemoval;
    const selectedMemberEmailForRemoval = this.selectedMemberEmailForRemoval;
    this.selectedMemberForRemoval = null;
    this.selectedMemberShipForRemoval = null;
    this.selectedMemberEmailForRemoval = null;

    if (!this.selectedOwner || !selectedMemberForRemoval || !selectedMemberShipForRemoval || !selectedMemberEmailForRemoval) return;

    this.closeDialog();
    this._store.dispatch(new MembershipActions.RemoveOne(this.organization, selectedMemberForRemoval, selectedMemberShipForRemoval, selectedMemberEmailForRemoval, this.selectedOwner.mainEmailAddress.emailAddress));
  }

  public closeDialog(_event = null): void {
    this.resetRemovalDialog();
    this._dialog.closeAll();
  }

  public closeRemovalDialog() {
    this.resetRemovalDialog();
    this.dialogRef.close();
  }

  public resetRemovalDialog() {
    this.selectedMemberForRemoval = null;
    this.selectedMemberShipForRemoval = null;
    this.selectedMemberEmailForRemoval = null;
  }

  private _initContextPortal() {
    this.contextPortal = this._createPortal(this.contextPortal, this.context);
  }

  private _initTitlePortal() {
    this.titlePortal = this._createPortal(this.titlePortal, this.titleRef);
  }

  private _initButtonToolbarPortal() {
    this.buttonsToolbarPortal = this._createPortal(this.buttonsToolbarPortal, this.buttonsToolbar);
  }

  private _initheaderShowMorePortal() {
    this.headerShowMorePortal = this._createPortal(this.headerShowMorePortal, this.headerShowMore);
  }

  private _initbodyShowMorePortal() {
    this.bodyShowMorePortal = this._createPortal(this.bodyShowMorePortal, this.bodyShowMore);
  }

  private _initfooterShowMorePortal() {
    this.footerShowMorePortal = this._createPortal(this.footerShowMorePortal, this.footerShowMore);
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }

  getMembersWithoutSelected(email): Observable<NaturalPerson[]> {
    if (!email) return of([])

    const members$ = this._store.select(NaturalPersonSelectors.getNaturalPersonsOfSelectedOrganization)
      .pipe(
        map((members: NaturalPerson[]): NaturalPerson[] => {
          if (!members) return [];
          return members.filter((member: NaturalPerson) => !!member && member.mainEmailAddress.emailAddress !== email);
        }));
    return combineLatest(this.ownerFilter$, members$)
      .pipe(
        map(([query, members]: [string, NaturalPerson[]]): NaturalPerson[] => {
          if (!query) return members;

          const _query = query.toLowerCase();
          return members.filter(member => {
            const _email = member.mainEmailAddress.emailAddress.toLocaleLowerCase()
            if (_email.indexOf(_query) >= 0) return true;
            const name = member.name.toLocaleLowerCase()
            return (name.indexOf(_query) >= 0);
          });
        })
      );
  }

  /**
   * Handles the key down event with MatSelect.
   * Allows e.g. selecting with enter key, navigation with arrow keys, etc.
   * @param {KeyboardEvent} event
   * @private
   */
  public handleKeydown(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }
  }

  public onInputChange($event) {
    this.ownerFilter$.next($event);
  }

  public selectNewOwner($event: MatSelectChange) {
    this.selectedOwner = $event.value;
    this.resetPersonSearch();
  }

  public resetPersonSearch() {
    this.ownerFilter$.next(null);
    this.ownerSearchField.reset();
  }

  public ownerSelectionChanged($event) {
    if (!$event) {
      this.ownerFilter$.next(null);
      this.ownerSearchField.reset();
    }
  }

  public toMemberProjectRooms(email) {
    this._dialog.closeAll();
    const uid = encodeURIComponent(email);
    this._router.navigate(['process_management', 'admin', this.organization.id, 'participations', uid]);
  }

  public identifyByKey(index, item) {
    return item.id;
  }

  toggleSalaryAccess($event: MatCheckboxChange, member: Member) {
    if ($event.checked) {
      this._memberSvc.enableSalaryAccess(this.organization.id, member.membershipId)
        .pipe(first()).subscribe(members => {
        this.repository.updatePermissions(member.membershipId);
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._cdr.detectChanges();
      }, err => {
        setTimeout(() => {
          this.repository.updatePermissions(member.membershipId);
          this._cdr.detectChanges();
        }, 200);
        this._notifyService.error('HTTP_ERROR.DEFAULT');
        console.error(err);
      });
    } else {
      this._memberSvc.disableSalaryAccess(this.organization.id, member.membershipId)
        .pipe(first()).subscribe(members => {
        this.repository.updatePermissions(member.membershipId);
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._cdr.detectChanges();
      }, err => {
        setTimeout(() => {
          this.repository.updatePermissions(member.membershipId);
          this._cdr.detectChanges();
        }, 200);
        this._notifyService.error('HTTP_ERROR.DEFAULT');
        console.error(err);
      });
    }

  }

  toggleDigitalbar($event: MatCheckboxChange, member: Member) {
    if ($event.checked) {
      this._memberSvc.enableDigitalbar(this.organization.id, member.membershipId)
        .pipe(first()).subscribe(members => {
        this.repository.updatePermissions(member.membershipId);
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._cdr.detectChanges();
      }, err => {
        setTimeout(() => {
          this.repository.updatePermissions(member.membershipId);
          this._cdr.detectChanges();
        }, 200);
        this._notifyService.error('HTTP_ERROR.DEFAULT');
        console.error(err);
      });
    } else {
      this._memberSvc.disableDigitalbar(this.organization.id, member.membershipId)
        .pipe(first()).subscribe(members => {
        this.repository.updatePermissions(member.membershipId);
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._cdr.detectChanges();
      }, err => {
        setTimeout(() => {
          this.repository.updatePermissions(member.membershipId);
          this._cdr.detectChanges();
        }, 200);
        this._notifyService.error('HTTP_ERROR.DEFAULT');
        console.error(err);
      });
    }

  }

  toggleFivefDesktop($event: MatCheckboxChange, member: Member) {
    if ($event.checked) {
      this._memberSvc.enableFivefDesktop(this.organization.id, member.membershipId)
        .pipe(first()).subscribe(members => {
        this.repository.updatePermissions(member.membershipId);
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._cdr.detectChanges();
      }, err => {
        setTimeout(() => {
          this.repository.updatePermissions(member.membershipId);
          this._cdr.detectChanges();
        }, 200);
        this._notifyService.error('HTTP_ERROR.DEFAULT');
        console.error(err);
      });
    } else {
      this._memberSvc.disableFivefDesktop(this.organization.id, member.membershipId)
        .pipe(first()).subscribe(members => {
        this.repository.updatePermissions(member.membershipId);
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._cdr.detectChanges();
      }, err => {
        setTimeout(() => {
          this.repository.updatePermissions(member.membershipId);
          this._cdr.detectChanges();
        }, 200);
        this._notifyService.error('HTTP_ERROR.DEFAULT');
        console.error(err);
      });
    }
  }

  toggleFivefAgent($event: MatCheckboxChange, member: Member) {
    if ($event.checked) {
      this._memberSvc.enableFivefAgent(this.organization.id, member.membershipId)
        .pipe(first()).subscribe(members => {
        this.repository.updatePermissions(member.membershipId);
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._cdr.detectChanges();
      }, err => {
        setTimeout(() => {
          this.repository.updatePermissions(member.membershipId);
          this._cdr.detectChanges();
        }, 200);
        this._notifyService.error('HTTP_ERROR.DEFAULT');
        console.error(err);
      });
    } else {
      this._memberSvc.disableFivefAgent(this.organization.id, member.membershipId)
        .pipe(first()).subscribe(members => {
        this.repository.updatePermissions(member.membershipId);
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._cdr.detectChanges();
      }, err => {
        setTimeout(() => {
          this.repository.updatePermissions(member.membershipId);
          this._cdr.detectChanges();
        }, 200);
        this._notifyService.error('HTTP_ERROR.DEFAULT');
        console.error(err);
      });
    }
  }

  toggleDatev($event: MatCheckboxChange, member: Member) {
    if ($event.checked) {
      this._memberSvc.enableDatev(this.organization.id, member.membershipId)
        .pipe(first()).subscribe(members => {
        this.repository.updatePermissions(member.membershipId);
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._cdr.detectChanges();
      }, err => {
        setTimeout(() => {
          this.repository.updatePermissions(member.membershipId);
          this._cdr.detectChanges();
        }, 200);
        this._notifyService.error('HTTP_ERROR.DEFAULT');
        console.error(err);
      });
    } else {
      this._memberSvc.disableDatev(this.organization.id, member.membershipId)
        .pipe(first()).subscribe(members => {
        this.repository.updatePermissions(member.membershipId);
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._cdr.detectChanges();
      }, err => {
        setTimeout(() => {
          this.repository.updatePermissions(member.membershipId);
          this._cdr.detectChanges();
        }, 200);
        this._notifyService.error('HTTP_ERROR.DEFAULT');
        console.error(err);
      });
    }
  }

  toggleBookman($event: MatCheckboxChange, member: Member) {
    if ($event.checked) {
      this._memberSvc.enableBookman(this.organization.id, member.membershipId)
        .pipe(first()).subscribe(members => {
        this.repository.updatePermissions(member.membershipId);
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._cdr.detectChanges();
      }, err => {
        setTimeout(() => {
          this.repository.updatePermissions(member.membershipId);
          this._cdr.detectChanges();
        }, 200);
        this._notifyService.error('HTTP_ERROR.DEFAULT');
        console.error(err);
      });
    } else {
      this._memberSvc.disableBookman(this.organization.id, member.membershipId)
        .pipe(first()).subscribe(members => {
        this.repository.updatePermissions(member.membershipId);
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._cdr.detectChanges();
      }, err => {
        setTimeout(() => {
          this.repository.updatePermissions(member.membershipId);
          this._cdr.detectChanges();
        }, 200);
        this._notifyService.error('HTTP_ERROR.DEFAULT');
        console.error(err);
      });
    }
  }

  updateWorkflows($event = null, membershipId: string) {
    let payload = {};
    if ($event && !$event.checked) {
      payload = {
        data: {
          attributes: {
            'enable_workflow': false,
            'enable_sub_workflow': false
          }
        }
      }
    } else {
      if (this.selectedWorkflow) {
        if (this.selectedWorkflow === 'workflow') {
          payload = {
            data: {
              attributes: {
                'enable_workflow': true,
                'enable_sub_workflow': true
              }
            }
          }
        } else {
          payload = {
            data: {
              attributes: {
                'enable_workflow': false,
                'enable_sub_workflow': true
              }
            }
          }
        }
      } else {
        payload = {
          data: {
            attributes: {
              'enable_workflow': false,
              'enable_sub_workflow': false
            }
          }
        }
      }
    }

    this._memberSvc.updateWorkflowCreation(this.organization.id, this.selectedMember.membershipId, payload)
      .pipe(first())
      .subscribe(members => {
        this.repository.updatePermissions(membershipId);
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
        this._cdr.detectChanges();
      }, err => {
        this._notifyService.error('HTTP_ERROR.DEFAULT');
        console.error(err);
      });
  }

  changePassword() {
    this.passwordChangeOngoing = true;
    this.registrationService.verifyPassword(this.passwordResetValue).subscribe(response => {
      if (response && response.level === 'success') {
        this._memberSvc.passwordReset(this.organization.id, this.selectedMember.membershipId, this.passwordResetValue)
          .pipe(first()).subscribe(members => {
          this.showResetPassword = false;
          this.passwordChangeOngoing = false;
          this._notifyService.success('GENERAL.UPDATE_ACTION_DONE')
          this._cdr.detectChanges();
        }, err => {
          this.passwordChangeOngoing = false;
          this._notifyService.error('HTTP_ERROR.DEFAULT')
          console.error(err);
          this._cdr.detectChanges();
        });
      } else {
        this.passwordChangeOngoing = false;
        this._notifyService.error(response.message)
      }
    });
  }

  verifyPasswordStrength(password) {
    return RegistrationService.verifyPasswordStrength(password)
  }

  isPasswordInvalid(password) {
    const result = RegistrationService.verifyPasswordStrength(password);
    return !result || result && result.level !== 'success';
  }

}
