import {Subject, throwError as observableThrowError} from 'rxjs';

import {catchError, first, takeUntil} from 'rxjs/operators';
import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {TreeComponent, TreeNode} from '@circlon/angular-tree-component';
import {DeleteFolderDialogComponent} from '../delete-folder-dialog/delete-folder-dialog.component';
import {FolderService} from '../../../api/services/folder.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers/index';
import {ClearApiErrors} from 'app/actions/api-error.actions';
import {ApiError} from 'app/models';
import {ErrorDialogComponent} from '../error-dialog/error-dialog.component';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import * as _ from 'lodash';

@Component({
  selector: 'dvtx-folder-dialog',
  templateUrl: './folder-dialog.component.html',
  styleUrls: ['./folder-dialog.component.scss']
})
export class FolderDialogComponent implements OnInit, OnDestroy {

  @ViewChild(TreeComponent)
  private treeComponent: TreeComponent;
  @ViewChild('folderName')
  private folderName: ElementRef;

  selectedNode: TreeNode;
  nodes;
  createDisabled = false;
  isCreateInput = false;
  loading = false;

  errors: ApiError[] = [];

  onDestroy = new Subject();
  fatalError = null;

  get errorState() {
    return this.errors.length > 0 || this.fatalError;
  }

  constructor(public dialogRef: MatDialogRef<FolderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private folderService: FolderService,
              private _notificationSvc: NotificationService,
              public dialog: MatDialog,
              private _store: Store<AppState>) {
    _store.dispatch(new ClearApiErrors());
    _store.select('apiErrors').pipe(
      takeUntil(this.onDestroy))
      .subscribe((_errors: ApiError[]) => {
        if (_errors && _errors.length) {
          const fatal = new RegExp('^api\.dms\.error');
          this.errors = _errors.filter(error => fatal.test(error.code));
        }
      });
  }

  ngOnInit(): void {
    this.getNodes();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  getNode(id) {
    return this.treeComponent.treeModel.getNodeById(id);
  }

  getNodes() {
    this.loading = true;
    this.folderService.getAll().pipe(
      first(),
      catchError(error => this._handleFatalError(error)),)
      .subscribe(res => {
        this.loading = false;
        if (this.data.parentFolderId) {
          const parent = this.searchTree(res, this.data.parentFolderId);
          if (parent) {
            this.nodes = [].concat(parent);
          } else {
            this.nodes = [].concat(res);
          }
        } else {
          this.nodes = [].concat(res);
        }
        if (this.data.dmsFolderId) {
          setTimeout(() => {
            const node: TreeNode = this.getNode(this.data.dmsFolderId);
            if (node) {
              node.setActiveAndVisible();
            }
          }, 0);
        }
      }, (error) => console.error('FolderDialogComponent#getNodes', error));
  }

  selectFolder(): void {
    if (this.selectedNode) {
      const path = this.getSelectedNodePath();
      this.dialogRef.close({dmsFolderId: this.selectedNode.id, folderPath: path});
    } else {
      this.dialogRef.close();
    }
  }

  activateChanged(event) {
    this.selectedNode = event.node;
  }

  onTreeInit(event) {
  }

  collectParents(node: TreeNode) {
    const parents = [];
    while (node.realParent) {
      parents.push(node.realParent);
      node = node.realParent;
    }
    return parents.reverse();
  }


  searchTree(element, id) {
    if (element.id === id) {
      return element;
    } else if (element.children != null) {
      let i;
      let result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = this.searchTree(element.children[i], id);
      }
      return result;
    }
    return null;
  }

  createFolder(name) {
    this.createDisabled = true;
    if (this.selectedNode && this.selectedNode.realParent) {
      this.loading = true;
      this.folderService.create(name, this.selectedNode.id).pipe(
        first(),
        catchError(error => this._handleFatalError(error)),)
        .subscribe(res => {
          this.createDisabled = false;
          if (res) {
            this.loading = false;
            const selected = this.searchTree(this.nodes[0], this.selectedNode.id);
            selected.children.push(res);
            this.treeComponent.treeModel.update();
          }
        }, (errors) => this.openErrorDialog(errors));
    } else {
      this.folderService.create(name).pipe(
        first(),
        catchError(error => this._handleFatalError(error)),)
        .subscribe(res => {
          this.loading = this.createDisabled = false;
          this.nodes[0].children.push(res);
          this.treeComponent.treeModel.update();
        }, (errors) => this.openErrorDialog(errors));
    }
    this.folderName.nativeElement.value = '';
    this.isCreateInput = false;
  }

  openErrorDialog(errors) {
    this.loading = this.createDisabled = false;
    const title = (errors.length === 1) ? 'Ein Fehler ist aufgetreten' : 'Fehler sind aufgetreten';
    const attrError = new RegExp('^(api.dms.error.folder|api.resource.error)');
    const attributesErrors = errors.filter(error => attrError.test(error.code));

    this.dialog.open(ErrorDialogComponent, {
      panelClass: 'dialog-sm',
      data: {title: title, errors: attributesErrors}
    });
  }

  deleteFolder() {
    const dialogRef = this.dialog.open(DeleteFolderDialogComponent, {
      panelClass: 'dialog-sm',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.folderService.delete(this.selectedNode.id).pipe(
          first(),
          catchError(error => this._handleFatalError(error)),)
          .subscribe(res => {
            _.remove(this.selectedNode.parent.data.children, this.selectedNode.data);
            this.selectedNode = null;
            this.treeComponent.treeModel.update();
          });
      }
    });
  }

  getSelectedNodePath(): string {
    if (!this.selectedNode) {
      return null;
    }
    const parents: TreeNode[] = this.collectParents(this.selectedNode);
    parents.push(this.selectedNode);
    return parents.map(node => node.data.name).join('/');
  }

  refreshTree() {
    this.loading = true;
    this.nodes = [];
    this.treeComponent.treeModel.update();
    this.getNodes();
  }

  toggleAdd() {
    this.isCreateInput = !this.isCreateInput;
  }

  isRootFolder(): boolean {
    if (!this.nodes[0]) {
      return true;
    }
    return this.selectedNode.id === this.nodes[0].id;
  }

  _handleFatalError(error) {
    this.loading = false;
    this.fatalError = true;
    console.error('FolderDialogComponent#getNodes', error);
    this._notificationSvc.error(error[0].title);
    this.fatalError = error[0].title;
    return observableThrowError(error);
  }
}
