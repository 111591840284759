import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  CollectorConfirmationActionTypes,
  Confirm,
  ConfirmFail,
  ConfirmSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess
} from './collector-confirmation.actions';
import {CollectorConfirmation} from './collector-confirmation';
import {CollectorConfirmationService} from './collector-confirmation.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ProjectRoomActions} from '../project-room';
import {QuickCollectorActions} from '../quickcollector';

@Injectable()
export class CollectorConfirmationEffects {
  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(CollectorConfirmationActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.collectorId).pipe(
        first(),
        concatMap((res: CollectorConfirmation[]) => {
          return [
            new LoadAllSuccess(res)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  remove$ = this.actions.pipe(
    ofType(CollectorConfirmationActionTypes.Confirm),
    switchMap((action: Confirm) => {
      return this._svc.confirm(action.collectorId).pipe(
        first(),
        concatMap((confirmation: CollectorConfirmation) => {
          return [
            new ConfirmSuccess(confirmation),
            new QuickCollectorActions.LoadOne(action.collectorId),
            new QuickCollectorActions.LoadDataNodes(action.collectorId),
            new ProjectRoomActions.LoadOne(action.collectorId)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new ConfirmFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: CollectorConfirmationService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




