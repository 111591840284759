import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ProcessParticipantAcl} from './process-participant-acl';

export interface State extends EntityState<ProcessParticipantAcl> {
  loading: boolean;
}

export const adapter = createEntityAdapter<ProcessParticipantAcl>();

export const initialState: State = adapter.getInitialState({
  loading: false
});
