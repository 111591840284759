import {TaskResourceActions, TaskResourceActionTypes} from './task-resource.actions';
import {adapter, initialState, State} from './task-resource.state';

export function reducer(state = initialState, action: TaskResourceActions): State {
  const current = state.selected;
  switch (action.type) {

    case TaskResourceActionTypes.CreateResource:
      return {
        ...state,
        loading: true
      };
    case TaskResourceActionTypes.CreateResourceSuccess:
      return adapter.addOne(action.resource, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });

    case TaskResourceActionTypes.UpdateResource:
      return {
        ...state,
        loading: true
      };
    case TaskResourceActionTypes.UpdateResourceSuccess:
      return adapter.upsertOne(action.resource, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });

    case TaskResourceActionTypes.Delete:
      return {
        ...state,
        loading: true,
      };

    case TaskResourceActionTypes.DeleteSuccess:
    return adapter.removeOne(action.resource.id , {
      ...state,
      loading: false,
    });
  }
  return state;
}
