import {Action} from '@ngrx/store'
import {Invitation, InvitationType} from './invitation.model'

export enum InvitationsActionTypes {
  LoadMy = '[Invitations] LoadMy',
  LoadOfOrga = '[Invitations] Load of Orga',
  LoadFail = '[Invitations] Load Fail',
  LoadSuccess = '[Invitations] Load Success',
  CreateInvitation = '[Invitations] CreateInvitation',
  CreateInvitationFail = '[Invitations] CreateInvitation Fail',
  CreateInvitationSuccess = '[Invitations] CreateInvitation Success',
  Accept = '[Invitations] Accept',
  AcceptFail = '[Invitations] Accept Fail',
  AcceptSuccess = '[Invitations] Accept Success',
  Decline = '[Invitations] Decline',
  DeclineFail = '[Invitations] Decline Fail',
  DeclineSuccess = '[Invitations] Decline Success',
  Destroy = '[Invitations] Destroy',
  DestroyFail = '[Invitations] Destroy Fail',
  DestroySuccess = '[Invitations] Destroy Success'
}

export class LoadMy implements Action {
  readonly type = InvitationsActionTypes.LoadMy;
}

export class LoadOfOrganization implements Action {
  readonly type = InvitationsActionTypes.LoadOfOrga;

  constructor(public orgaId: string) {

  }
}

export class LoadFail implements Action {
  readonly type = InvitationsActionTypes.LoadFail;

  constructor(public payload: any) {
  }
}

export class LoadSuccess implements Action {
  readonly type = InvitationsActionTypes.LoadSuccess;

  constructor(public payload: Invitation[]) {
  }
}

export class CreateInvitation implements Action {
  readonly type = InvitationsActionTypes.CreateInvitation;

  constructor(public invitationData: { orgaId: string, inviteeEmail: string }) {

  }
}

export class CreateInvitationFail implements Action {
  readonly type = InvitationsActionTypes.CreateInvitationFail;

  constructor(public payload: any) {
  }
}

export class CreateInvitationSuccess implements Action {
  readonly type = InvitationsActionTypes.CreateInvitationSuccess;

  constructor(public payload: Invitation[]) {
  }
}

export class Accept implements Action {
  readonly type = InvitationsActionTypes.Accept;

  constructor(public id: string, public invitationType: InvitationType) {
  }
}

export class AcceptFail implements Action {
  readonly type = InvitationsActionTypes.AcceptFail;

  constructor(public payload: any) {
  }
}

export class AcceptSuccess implements Action {
  readonly type = InvitationsActionTypes.AcceptSuccess;

  constructor(public id: string) {
  }
}

export class Decline implements Action {
  readonly type = InvitationsActionTypes.Decline;

  constructor(public id: string, public invitationType: InvitationType) {
  }
}

export class DeclineFail implements Action {
  readonly type = InvitationsActionTypes.DeclineFail;

  constructor(public payload: any) {
  }
}

export class DeclineSuccess implements Action {
  readonly type = InvitationsActionTypes.DeclineSuccess;

  constructor(public id: string) {
  }
}


export class Destroy implements Action {
  readonly type = InvitationsActionTypes.Destroy;

  constructor(public id: string, public invitationType: InvitationType, public organizationId: string = null) {
  }
}

export class DestroyFail implements Action {
  readonly type = InvitationsActionTypes.DestroyFail;

  constructor(public payload: any) {
  }
}

export class DestroySuccess implements Action {
  readonly type = InvitationsActionTypes.DestroySuccess;

  constructor(public id: string) {
  }
}

export type InvitationActions =
  | CreateInvitation
  | CreateInvitationFail
  | CreateInvitationSuccess
  | LoadMy
  | LoadOfOrganization
  | LoadFail
  | LoadSuccess
  | Accept
  | AcceptFail
  | AcceptSuccess
  | Decline
  | DeclineFail
  | DeclineSuccess
  | Destroy
  | DestroyFail
  | DestroySuccess
