import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import { FiveFStatusApiMap, FiveFStatusesMap } from '../process/process';
import {IFiveFStatus} from '../process/process.interface';
import {MinimalProcess} from './process-lookup';

export class ProcessSelectionBuilder implements IApiResourceBuilder<MinimalProcess> {
  fromResponse(data): MinimalProcess {
    const attr = data.attributes;
    return new MinimalProcess(
      data.id,
      attr.title,
      attr.identifier,
      attr.process_type,
      <IFiveFStatus>FiveFStatusApiMap[attr.status] || FiveFStatusesMap.Open,
      attr.parent_id,
      attr.organization_uuid,
      attr.client_uuid,
      attr.client_name,
      attr.datev_client_no,
      attr.color,
      attr.created_at,
      attr.updated_at);
  }

  toRequest(_: MinimalProcess) {
    return null;
  }
}
