import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { ClientsFilterComponent } from './components/clients-filter/clients-filter.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ClientsFilterComponent
  ],
  exports: [
    ClientsFilterComponent
  ]
})
export class ClientsFilterModule {
}
