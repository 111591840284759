<div [formGroup]="form" class="row" *ngIf="form">
  <!--<pre>{{form.value | json}}</pre>-->
  <div class="uploaded-docs-title mb-1" *ngIf="form.controls.files.controls.length > 0">
    {{ 'UPLOAD.MARKED_AS_UPLOADED' | translate }}
  </div>
  <div class="col-12" formArrayName="files">
    <div class="document-item" *ngFor="let fileForm of form.controls.files.controls; let x = index" [formGroupName]="x">
      <!--<pre>{{fileForm.value | json}}</pre>-->
      <dvtx-upload-file-item [file]="uploader.queue[x].file" (onRemove)="onRemove.emit(x)"></dvtx-upload-file-item>
      <dvtx-upload-file-detail [form]="fileForm"></dvtx-upload-file-detail>

      <div *ngIf="uploader.queue[x].isError">
        {{ 'UPLOAD.UPLOAD_FAILURE' | translate }}
      </div>

      <div class="progress" style="margin-bottom: 10px;" *ngIf="uploader.queue[x].progress > 0">
        <!--<div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.queue[x].progress + '%' }"></div>-->
        <mat-progress-bar
          color="primary"
          [mode]="'determinate'"
          [value]="uploader.queue[x].progress">
        </mat-progress-bar>
        <span>{{ uploader.queue[x].progress + '%' }}</span>
      </div>
    </div>
  </div>
</div>
