import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {reducer} from './page-sidebar.reducer';
import {stateKey} from './page-sidebar.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer)
  ],
  providers: [
  ]
})
export class PageSidebarStoreModule {
}
