import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  OperatorMemberActionTypes,
  Remove,
  RemoveFail,
  RemoveSuccess
} from './member.actions';
import * as model from './member';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {OperatorMemberService} from './member.service';

@Injectable()
export class OperatorMemberEffects {
  @Effect()
  create$ = this.actions.pipe(
    ofType(OperatorMemberActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.organizationId, action.params).pipe(
        first(),
        concatMap((member: model.Operator.Member[]) => {
          return [new LoadAllSuccess(member)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  );

  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(OperatorMemberActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.userId).pipe(
        first(),
        concatMap((res: model.Operator.Member[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  remove$ = this.actions.pipe(
    ofType(OperatorMemberActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.destroy(action.organization, action.membership).pipe(
        first(),
        concatMap((item: model.Operator.Member[]) => {
          return [new RemoveSuccess(item[0])];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: OperatorMemberService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
