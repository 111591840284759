import {QuickshareRecipientActions, QuickshareRecipientActionTypes} from './quickshare-recipient.actions';
import {adapter, initialState, State} from './quickshare-recipient.state';

export function reducer(state = initialState, action: QuickshareRecipientActions): State {
  const current = state.selected;
  switch (action.type) {
    case QuickshareRecipientActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case QuickshareRecipientActionTypes.Create:
    case QuickshareRecipientActionTypes.Save:
    case QuickshareRecipientActionTypes.Remove:
      return {
        ...state,
        loading: true
      };
    case QuickshareRecipientActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.recipients, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case QuickshareRecipientActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
    case QuickshareRecipientActionTypes.CreateSuccess:
    case QuickshareRecipientActionTypes.SaveSuccess:
      return adapter.upsertOne(action.recipient, {
        ...state,
        selected: action.recipient,
        loading: false,
      });
    case QuickshareRecipientActionTypes.RemoveSuccess:
      return adapter.removeOne(action.recipient.id, {
        ...state,
        selected: current && action.recipient.id === current.id ? null : current,
        loading: false,
      });
    case QuickshareRecipientActionTypes.CreateFail:
    case QuickshareRecipientActionTypes.RemoveFail:
    case QuickshareRecipientActionTypes.SaveFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

