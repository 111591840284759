import {IResource} from 'app/shared/modules/api/models/resource.interface';

export interface IUserAvatar extends IResource {
  avatar: string,
  firstName?: string,
  lastName?: string,
  gender?: any,
  title?: any
  loading?: boolean;
}

export const AvatarType = 'user_profiles';
