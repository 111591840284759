import {IApiRequest, IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import { LabelStats } from './label-stats';

export class LabelStatsBuilder implements IApiResourceBuilder<LabelStats> {
  toRequest(model: LabelStats): IApiRequest {
    return null;
  }
  addIncludedSection?(includes: any): void {
  }
  addMetaSection?(meta: any): void {
  }
  fromResponse(data): LabelStats {
    const item = new LabelStats();
    item.title = data.attributes.title;
    item.color = data.attributes.color;
    item.count = data.attributes.usage_count;
    item.id = data.id;
    return item;
  }
}
