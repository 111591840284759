import {NgModule} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {AlertBoxSuccessComponent} from './components/alert-box-success/alert-box-success.component';
import {AlertBoxComponent} from './components/alert-box/alert-box.component';
import {ActionsPanelComponent} from './components/actions-panel/actions-panel.component';
import {ApiModule} from 'app/shared/modules/api/api.module';
import {BaseFormElementsModule} from 'app/shared/modules/base-form-elements/base-form-elements.module';
import {ContentEditableModule} from './modules/content-editable/content-editable.module';
import {DashboardPanelsModule} from './modules/dashboard-panels/dashboard-panels.module';
import {ListingModule} from 'app/shared/modules/listing/listing.module';
import {FilesUploadModule} from 'app/shared/modules/file-upload/file-upload.module';
import {SuccessWorkflowComponent} from './components/success-workflow-modal/success-workflow-modal.component';
import {WorkflowModalComponent} from './components/workflow-modal/workflow-modal.component';
import {DeleteFolderDialogComponent} from './components/delete-folder-dialog/delete-folder-dialog.component';
import {FileDialogModule} from './modules/file-dialog/file-dialog.module';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {BackLinkComponent} from './components/backlink/backlink.component';
import {NotificationModule} from './modules/notification/notification.module';
import {AdditionalInformationDialogComponent} from './components/additional-information-dialog/additional-information-dialog.component';
import {ConfirmationDialogComponent} from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogSimpleComponent} from './components/dialogs/confirmation-dialog-simple/confirmation-dialog-simple.component';
import {SafeHtmlPipe} from './pipes/safe-html-pipe';
import {ComingSoonComponent} from './components/coming-soon/coming-soon.component';
import {LocalizationHelperService} from './services/localization-helper/localization-helper.service';
import {LocaleSwitchDirective} from './directives/locale-switch.directive';
import {FeatureToggleDirective} from './directives/feature-toggle.directive';
import {CustomMatPaginatorIntl} from 'app/shared/services/custom-mat-paginator-intl.service';
import {RoleToggleDirective} from './directives/roleToggle.directive';
import {AuthToggleDirective} from './directives/authToggle.directive';
import {ApiErrorComponent} from './components/api-error/api-error.component';
import {CreateContactPersonDialogComponent} from './components/create-contact-person-dialog/create-contact-person-dialog.component';
import {ApiResourceModule} from 'app/shared/modules/api-resource/api-resource.module';
import {ActioncableComponent} from 'app/shared/containers/actioncable/actioncable.component';
import {PageHeaderModule} from './modules/page-header/page-header.module';
import {WorkflowDelegationModalComponent} from './components/workflow-modal-task-delegation/workflow-modal-task-delegation.component';
import {PageContainerModule} from './modules/page-container/page-container.module';
import {PageSidebarModule} from './modules/page-sidebar/page-sidebar.module';
import {WizardComponent} from './components/wizard-bar/wizard-bar.component';
import {WizardCopyComponent} from 'app/shared/components//wizard-bar-copy/wizard-bar-copy.component';
import {LegalFormNameByIdPipe} from './pipes/legal-form-name-by-id.pipe';
import {SimpleConfirmComponent} from './components/simple-confirm/simple-confirm.component';
import {SafeNumberPipe} from './pipes/safe-number.pipe';
import {LicenceUpgradeDialogDirective} from './directives/licence-upgrade-dialog.directive';
import {LicenceUpgradeDialogComponent} from './components/dialogs/licence-upgrade-dialog/licence-upgrade-dialog.component';
import {LicenceUpgradeContactAdminDialogComponent} from './components/dialogs/licence-upgrade-contact-admin-dialog/licence-upgrade-contact-admin-dialog.component';
import {RoutingModule} from './modules/routing/routing.module';
import {FileSizePipe} from './pipes/file-size.pipe';
import {TooltipLikeDialogComponent} from 'app/shared/components/dialogs/tooltip-like-dialog/tooltip-like-dialog.component';
import {LicenceControlService} from './services/licence-control.service';
import {VersionComponent} from './modules/version/version.component';
import {SystemMaintenanceComponent} from 'app/shared/components/system-maintenance/system-maintenance.component';
import {LicenceTrialRenewDialogComponent} from 'app/shared/components/dialogs/licence-trial-renew-dialog/licence-trial-renew-dialog.component';
import {EnumKeysPipe, EnumToArrayPipe, EnumValuesPipe} from './pipes/enum-to-array';
import {FilterPipe} from './pipes/filter.pipe';
import {AddAccessTokenPipe} from './pipes/add-access-token.pipe';
import {LoadingPanelComponent} from './components/loading-panel/loading-panel.component';
import {FeatureAuthorizationDirective} from './directives/feature-authorization.directive';
import {TableUtilsModule} from './modules/table-utils/table-utils.module';
import {VarDirective} from './directives/ng-var.directive';
import {FibuProcessIndicatorComponent} from './components/fibu-process-indicator/fibu-process-indicator.component';
import {MarkReadDirective} from "./directives/mark-read.directive";
import {FivefStepper} from "./components/fivef-stepper/fivef-stepper";
import {FroalaViewModule} from "angular-froala-wysiwyg";
import {ConfirmationDialogParticipantComponent} from './components/dialogs/confirmation-dialog-participant/confirmation-dialog-participant.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {CreateEmployeeDialogComponent} from 'app/modules/organization/components/organization-view/create-employee-dialog/create-employee-dialog.component';
import {MembershipInvitationComponent} from 'app/modules/organization/components/organization-view/membership-invitation/membership-invitation.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {DatevDuoIndicatorComponent} from './components/datev-duo-indicator/datev-duo-indicator.component';
import { SidebarDetailsModule } from './modules/sidebar-details/sidebar-details.module';
import { PortalModule } from '@angular/cdk/portal';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';

const materialMods = [
  MatCardModule,
  MatStepperModule,
  MatTableModule,
  MatPaginatorModule
];

const components = [
  AlertBoxSuccessComponent,
  AlertBoxComponent,
  ActionsPanelComponent,
  WizardComponent,
  WizardCopyComponent,
  SuccessWorkflowComponent,
  WorkflowModalComponent,
  WorkflowDelegationModalComponent,
  DeleteFolderDialogComponent,
  PageNotFoundComponent,
  SystemMaintenanceComponent,
  BackLinkComponent,
  AdditionalInformationDialogComponent,
  ConfirmationDialogComponent,
  ConfirmationDialogSimpleComponent,
  ConfirmationDialogParticipantComponent,
  AddAccessTokenPipe,
  SafeHtmlPipe,
  FileSizePipe,
  FilterPipe,
  EnumToArrayPipe,
  EnumKeysPipe,
  EnumValuesPipe,
  ComingSoonComponent,
  LocaleSwitchDirective,
  FeatureToggleDirective,
  CreateContactPersonDialogComponent,
  CreateEmployeeDialogComponent,
  MembershipInvitationComponent,
  RoleToggleDirective,
  AuthToggleDirective,
  ApiErrorComponent,
  ActioncableComponent,
  LegalFormNameByIdPipe,
  SimpleConfirmComponent,
  SafeNumberPipe,
  LicenceUpgradeDialogDirective,
  LicenceUpgradeDialogComponent,
  LicenceUpgradeContactAdminDialogComponent,
  LicenceTrialRenewDialogComponent,
  TooltipLikeDialogComponent,
  VersionComponent,
  LoadingPanelComponent,
  FeatureAuthorizationDirective,
  VarDirective,
  FibuProcessIndicatorComponent,
  MarkReadDirective,
  FivefStepper,
  DatevDuoIndicatorComponent
];

const sharedModules = [
  FroalaViewModule,
  ApiModule,
  ApiResourceModule,
  BaseFormElementsModule,
  ContentEditableModule,
  NotificationModule,
  ListingModule,
  DashboardPanelsModule,
  FileDialogModule,
  FilesUploadModule,
  PageContainerModule,
  // PageHeaderModule,
  SidebarDetailsModule,
  // UserAccountModule,
  RouterModule,
  MatBadgeModule,
  PortalModule,
  RoutingModule,
  TableUtilsModule
];

@NgModule({
    imports: [
        ...materialMods,
        ...sharedModules,
        MatCheckboxModule,
        MatProgressBarModule
    ],
    declarations: components,
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl
        },
        LocalizationHelperService,
        LicenceControlService
    ],
    exports: [
        ...materialMods,
        ...sharedModules,
        ...components
    ]
})
export class SharedModule {
}
