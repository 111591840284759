import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dvtx-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatepickerComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject();

  @Input() form: UntypedFormGroup;
  @Input() controlName;
  @Input() placeholder = '';
  @Input() startAt = new Date();
  @Input() disabled = false;
  @Input() required = false;
  @Output() onSelectedDate: EventEmitter<any> = new EventEmitter();

  constructor(changeDetector: ChangeDetectorRef, private _translate: TranslateService,
    private dateAdapter: DateAdapter<Date>) {
    const currentLang = this._translate.currentLang;
    this.dateAdapter.setLocale(currentLang);
    this._translate.onLangChange.pipe(
      distinctUntilChanged(),
      takeUntil(this.onDestroy)
    ).subscribe(lang => {
      this.dateAdapter.setLocale(lang.lang);
      changeDetector.detectChanges();
    })
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public clearDueDate() {
    if (this.disabled || this.required) {
      return;
    }

    if (this.form && this.controlName && this.form.controls[this.controlName]) {
      this.form.controls[this.controlName].setValue(null);
    }
    this.onSelectedDate.emit(null);
  }
}
