<ng-template #callToActionRef>
  <dvtx-add-employee-dropdown [disabled]="!(availableUserLicences > 0)"></dvtx-add-employee-dropdown>
</ng-template>

<ng-template #globalActionRef>
  <div class="d-flex align-items-center">
    <dvtx-filters-container [showTitle]="false" [selectedCount]="selectedFilter?.length" class="ml-2">
      <div class="row filters-header">
        <div class="col-9">
        </div>
        <div class="col-3 d-inline-flex align-items-center justify-content-end">
          <button [matTooltip]="'FILTER.RESET_FILTER' | translate" mat-icon-button color="gray"
            (click)="onClearAllFilters()">
            <mat-icon svgIcon="filter_disable"></mat-icon>
          </button>
        </div>
      </div>
      <div class="row align-items-start filters-container">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 mb-2">
          <dvtx-filter-list-selection [options]="filterOptions" [selectedOptions]="selectedFilter"
            (onChangeSelection)="onFilterSelect($event)">
          </dvtx-filter-list-selection>
        </div>
      </div>
    </dvtx-filters-container>
  </div>
</ng-template>

<dvtx-settings-header [routes]="routes"
                      [activeLink]="activeLink"
                      [toOverview]="true"
                      (onSearch)="search($event)"
                      [enableSearch]="true"
                      [callToActionPortal]="callToActionPortal"
                      [globalActionPortal]="globalActionPortal"
  [title]="'ORGANIZATION.EMPLOYEES'"></dvtx-settings-header>

<dvtx-organization-employee class="five-f-settings-container five-f-block" [search]="searchTerm"
  [filter]="selectedFilter"></dvtx-organization-employee>
