import {Action} from '@ngrx/store';
import {ProcessEvent} from '../process-event/process-event';
import {AuditTrail} from './audit-trail';
import {AuditTrailDownloadFormat} from './audit-trail.interface';

export enum AuditTrailActionTypes {
  LoadAll = '[AuditTrail] Load All',
  LoadAllSuccess = '[AuditTrail] Load All Success',
  LoadAllFail = '[AuditTrail] Load All Fail',
  Download = '[AuditTrail] Download',
  DownloadSuccess = '[AuditTrail] Download Success',
  DownloadFail = '[AuditTrail] Download Fail',
  SaveDownload = '[AuditTrail] Save Download',
}

export class LoadAll implements Action {
  readonly type = AuditTrailActionTypes.LoadAll;

  constructor(public processId: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = AuditTrailActionTypes.LoadAllSuccess;

  constructor(public attachments: ProcessEvent[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = AuditTrailActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Download implements Action {
  readonly type = AuditTrailActionTypes.Download;

  constructor(public processId: string, public format: AuditTrailDownloadFormat) {
  }
}

export class DownloadSuccess implements Action {
  readonly type = AuditTrailActionTypes.DownloadSuccess;

  constructor(public auditTrail: AuditTrail) {
  }
}

export class DownloadFail implements Action {
  readonly type = AuditTrailActionTypes.DownloadFail;

  constructor(public payload: any = null) {
  }
}

export class SaveDownload implements Action {
  readonly type = AuditTrailActionTypes.SaveDownload;

  constructor(public filename: string, public content: string, public mimeType: string) {
  }
}

export type AuditTrailActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Download
  | DownloadSuccess
  | DownloadFail
  | SaveDownload;
