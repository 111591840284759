import {Component, OnInit} from '@angular/core';
import {ITabNavRoute} from '../../../organization/modules/organization-card/models/tab-nav-route.interface';
import {FiveFCardProfile} from '../../../../five-f/organization-card/containers/organization-card/organization-card.component';
import {DocumentSignatureUserAccountDialogMenuViewType} from '../../../document-signature/modules/document-signature-user-account/containers/document-signature-user-account-dialog/document-signature-user-account-dialog.component';
import {environment} from 'environments/environment'

@Component({
  selector: 'dvtx-document-signature-fp-sign-account',
  templateUrl: './document-signature-fp-sign-account.component.html',
  styleUrls: ['./document-signature-fp-sign-account.component.scss']
})
export class DocumentSignatureFpSignAccountComponent implements OnInit {
  public DocumentSignatureUserAccountDialogMenuViewType = DocumentSignatureUserAccountDialogMenuViewType;
  public routes: ITabNavRoute[];
  public activeLink: string;
  public fpSignLoginLink = environment.fpSignLoginPage;

  constructor() {
  }

  ngOnInit() {
    this.routes = [{
      title: 'SIGNATURE.ACCOUNT.ACCOUNT_DATA',
      route: '/user-profile/signature/account'
    }, {
      title: 'SIGNATURE.ACCOUNT.ACCOUNT_SETTINGS',
      route: '/user-profile/signature/defaults'
    }, {
      title: 'SIGNATURE.ACCOUNT.FP_SIGN_SETTINGS',
      route: '/user-profile/signature/fp-sign-settings'
    }];
    this.activeLink = this.routes[2].title
  }
}
