import {throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ApiErrorBuilder} from '../builders/api-error.builder';
import {environment} from 'environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';


@Injectable()
export class MessageService {

  basePath;
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';

  constructor(private _tokenService: HttpClient,
              private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  create(processId, message) {
    const payload = {
      data: {
        attributes: {
          message: message
        }
      }
    };
    return this._http.post(`${this.basePath}/${processId}/messages`, payload).pipe(
      map((res: any) => res),
      catchError(this.handleError),);
  }

  private handleError(error: HttpErrorResponse) {
    console.log('ContactService#handleError', error);
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}
