import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ItemService} from './item.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ItemService
  ]
})
export class ItemStoreModule {
}
