import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'dvtx-upload-file-item',
  templateUrl: './upload-file-item.component.html',
  styleUrls: ['./upload-file-item.component.scss']
})
export class UploadFileItemComponent {

  @Input() file;
  @Output() onRemove = new EventEmitter();

  remove() {
    this.onRemove.emit(this.file);
  }
}
