import {ICmsArticle} from './cms-article.interface';

export class CmsArticle implements ICmsArticle {
  readonly type = 'cms_articles';
  sticky = false;
  constructor(public id,
              public cmsBlogId: string,
              public title,
              public subtitle,
              public content,
              public authorEmail,
              public publishedAt = null,
              public createdAt = null,
              public updatedAt = null) {
  }

  toForm() {
    return {
      id: this.id,
      title: this.title,
      subtitle: this.subtitle,
      content: this.content,
      published: !!this.publishedAt,
      sticky: !!this.sticky
    }
  }
}
