import {first, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {isValidBIC, isValidIBAN} from 'ibantools'
import {Observable, Subject} from 'rxjs';
import {PaymentOption} from 'app/shared/modules/api/models/payment-option';
import {PaymentOptionService} from 'app/+store/licence/payment-option.service';

/**
 * Payment selection of wizard.
 */
@Component({
  selector: 'dvtx-payment-selection',
  templateUrl: './payment-selection.component.html',
  styleUrls: ['./payment-selection.component.scss']
})
export class PaymentSelectionComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Output() onPaymentCompleted = new EventEmitter();
  @ViewChild('selectPaymentOption', { static: true }) selectPaymentOption;

  ibanFormControl: UntypedFormControl;
  bicFormControl: UntypedFormControl;
  bank: string;

  paymentoptions$: Observable<PaymentOption[]>;

  private alreadyConfirmed = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor( private _paymentOptionSvc: PaymentOptionService ) {
  }

  ngOnInit() {
    this.ibanFormControl = new UntypedFormControl('', {
      validators: [
        Validators.required,
        this.ibanValidator(),
      ],
      updateOn: 'blur'
    });
    this.bicFormControl = new UntypedFormControl('', {
      validators: [
        Validators.required,
        this.bicValidator(),
      ],
      updateOn: 'blur'
    });
    this.form.addControl('id', new UntypedFormControl(null));
    this.form.addControl('firstName', new UntypedFormControl('', Validators.required));
    this.form.addControl('lastName', new UntypedFormControl('', Validators.required));
    this.form.addControl('iban', this.ibanFormControl);
    this.form.addControl('bic', this.bicFormControl);
    this.form.addControl('confirmed', new UntypedFormControl(null, Validators.requiredTrue));

    this.paymentoptions$ = this._paymentOptionSvc.getAll().pipe(first());

    this.form.valueChanges.pipe(
      takeUntil(this.destroy$))
      .subscribe(_ => {
        this.onPaymentCompleted.emit(this.form.valid);
        if (this.form.valid) {
          this.paymentoptions$ = this._paymentOptionSvc.getAll().pipe(first());
        }
      });
  }

  resetForm() {
    this.selectPaymentOption.value = null;
    this.form.reset();
    this.form.enable();
    this.form.updateValueAndValidity();
  }

  fillForm(paymentOption) {
    this.alreadyConfirmed = paymentOption.value.confirmed;
    this.form.patchValue(paymentOption.value);
    this.form.disable();
    this.onPaymentCompleted.emit(true);
  }

  isConfirmed() {
    return this.alreadyConfirmed;
  }

  ibanValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const alreadyConfirmed = this.isConfirmed();
      const validIban = control.value ? isValidIBAN(control.value.replace(/\s/g, '')) || control.value.length === 0 : true;
      if (validIban) {
        this._paymentOptionSvc.verifyIban(control.value).pipe(first()).subscribe(bank => this.bank = bank.name, _ => this.bank = null);
      } else {
        this.bank = null;
      }
      if (!validIban && !alreadyConfirmed) {
        return {'invalidIban': 'invalidIban'}
      } else {
        return null;
      }
    };
  }

  bicValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const alreadyConfirmed = this.isConfirmed();
      const validIban = control.value ? isValidBIC(control.value.replace(/\s/g, '')) || control.value.length === 0 : true;
      if (!validIban && !alreadyConfirmed) {
        return {'invalidBic': 'invalidBic'}
      } else {
        return null;
      }
    };
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
