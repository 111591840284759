import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DmsAccountType} from 'app/+store/dms-folder/dms-folder.interface';

export interface ISelectedFolder {
  id: string;
  accountType: DmsAccountType;
  path: string;
}

@Component({
  selector: 'dvtx-dms-folder-picker-dialog',
  templateUrl: './dms-folder-picker-dialog.component.html',
  styleUrls: ['./dms-folder-picker-dialog.component.scss']
})
export class DmsFolderPickerDialogComponent implements OnInit {
  selection: ISelectedFolder = null;

  constructor(private _dialogRef: MatDialogRef<DmsFolderPickerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ISelectedFolder = null) {
  }

  ngOnInit() {
  }

  onSelect($event) {
    if ($event && $event.id) {
      this.selection = $event;
    }
  }

  selectAndClose() {
    if (!this.selection) {
      return;
    }
    this._dialogRef.close({dmsFolderId: this.selection.id, dmsAccountType: this.selection.accountType, dmsPath: this.selection.path});
  }
}
