<div class="d-flex flex-column" *ngIf="(client$ | async) as client">
  <div class="text-truncate"
       [matTooltip]="client?.name?.length > 16 ? client?.name : ''">
    {{ client?.name }}
  </div>

  <small class="text-truncate">
    {{ client?.clientId }}
  </small>
</div>
