import {OperatorUserActions, OperatorUserActionTypes} from './user.actions';
import {adapter, initialState, State} from './user.state';

export function reducer(state = initialState, action: OperatorUserActions): State {
  const current = state.selected;
  switch (action.type) {
    case OperatorUserActionTypes.LoadAll:
      if (action.params.reset) {
        return adapter.removeAll({
          ...state,
          selected: null,
          loading: false,
        });
      }
      return {
        ...state,
        selected: null,
        loading: true
      };
    case OperatorUserActionTypes.Create:
    case OperatorUserActionTypes.LoadOne:
    case OperatorUserActionTypes.Remove:
    case OperatorUserActionTypes.Save:
      return {
        ...state,
        loading: true
      };
    case OperatorUserActionTypes.LoadOneSuccess:
      return adapter.addOne(action.user, {
        ...state,
        selected: action.user,
        loading: false,
      });
    case OperatorUserActionTypes.LoadAllSuccess:
      if (action.reset) {
        return adapter.setAll(action.rawUsers, {
            ...state,
            selected: null,
            loading: false,
        });
      }
      return adapter.upsertMany(action.users, {
        ...state,
        selected: null,
        loading: false,
      });
    case OperatorUserActionTypes.CreateSuccess:
    case OperatorUserActionTypes.SaveSuccess:
      return adapter.upsertOne(action.user, {
        ...state,
        selected: action.user,
        loading: false,
      });
    case OperatorUserActionTypes.CreateFail:
    case OperatorUserActionTypes.LoadAllFail:
    case OperatorUserActionTypes.LoadOneFail:
    case OperatorUserActionTypes.RemoveFail:
    case OperatorUserActionTypes.SaveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
    case OperatorUserActionTypes.RemoveSuccess:
      return adapter.removeOne(action.user.id, {
        ...state,
        selected: (current && current.id === action.user.id) ? null : current,
        loading: false,
      });
  }
  return state;
}
