import {IResource} from '../../shared/modules/api/models/resource.interface';

export enum ClientContactType {
  Person = 'person',
  Organization = 'organization'
}

export interface IClient extends IResource {
  contactType: ClientContactType;
  name: string;
  email: string;
  gender: string;
  title: string;
  firstName: string;
  lastName: string;
  clientId: string;
  bookmanClientId: number;
  bookmanClientName: string;
  processCount: number;
  organizationId: string;
  contactId: string;
  datevClientNo: string;
  datevConsultantNo: string;
  phone: string;
  legalForm?: string;
  street: string;
  streetNo: string;
  zip;
  city: string;
  country: string;
  creatorName: string;
  creatorEmail: string;
  referenceId: string;
  canEditClient: boolean;
  canDeleteClient: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface IClientV3Query {
  search?: string;
}
