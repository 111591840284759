import {
    WorkflowTemplateCategoryActions,
    WorkflowTemplateCategoryActionTypes
} from "./workflow-template-category.actions";
import {adapter, initialState, State} from "./workflow-template-category.state";

export function reducer(state = initialState, action: WorkflowTemplateCategoryActions): State {
  const current = state.selected;
  switch (action.type) {
    case WorkflowTemplateCategoryActionTypes.Create:
    case WorkflowTemplateCategoryActionTypes.Delete:
    case WorkflowTemplateCategoryActionTypes.LoadAll:
    case WorkflowTemplateCategoryActionTypes.LoadOne:
    case WorkflowTemplateCategoryActionTypes.Update:
      return {
        ...state,
        loading: true
      };
    case WorkflowTemplateCategoryActionTypes.CreateSuccess:
      return adapter.upsertOne(action.category, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case WorkflowTemplateCategoryActionTypes.UpdateSuccess:
      return adapter.upsertOne(action.category, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case WorkflowTemplateCategoryActionTypes.LoadAllSuccess:
        return adapter.setAll(action.categories, {
            ...state,
            loading: false,
        });
    case WorkflowTemplateCategoryActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.category, {
        ...state,
        loading: false,
      });
    case WorkflowTemplateCategoryActionTypes.DeleteSuccess:
      return adapter.removeOne(action.category.id, {
        ...state,
        loading: false,
      });
    case WorkflowTemplateCategoryActionTypes.CreateFail:
    case WorkflowTemplateCategoryActionTypes.DeleteFail:
    case WorkflowTemplateCategoryActionTypes.LoadAllFail:
    case WorkflowTemplateCategoryActionTypes.LoadOneFail:
    case WorkflowTemplateCategoryActionTypes.UpdateFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

