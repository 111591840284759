import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ProjectEffects} from './project.effects';
import {ProjectService} from './project.service';
import {reducer} from './project.reducer';
import {stateKey} from './project.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ProjectEffects])
  ],
  providers: [
    ProjectEffects,
    ProjectService
  ]
})
export class ProjectStoreModule {
}
