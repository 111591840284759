import { Component, OnInit } from '@angular/core';
import { ITabNavRoute } from 'app/five-f/organization-card/models/tab-nav-route.interface';

@Component({
  selector: 'dvtx-password-reset-container',
  templateUrl: './password-reset-container.component.html',
  styleUrls: ['./password-reset-container.component.scss']
})
export class PasswordResetContainerComponent implements OnInit {
  public routes: ITabNavRoute[];
  public activeLink: string;
  
  constructor() {}

  ngOnInit() {
    this.routes = [{
      title: 'AUTH.TITLE',
      route: '/user-profile/security/two-factor-authentication'
    }, {
      title: 'AUTH.PASSWORD_RESET',
      route: '/user-profile/security/password'
    }, {
      title: 'AUTH.AUTO_LOGOUT',
      route: '/user-profile/security/auto-logout'
    }];
    this.activeLink = this.routes[1].title
  }
}
