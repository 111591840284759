import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {EmailFooterComponent} from './components/email-footer/email-footer.component';
import {EmailFooterContainerComponent} from './containers/email-footer-container/email-footer-container';
import {UserSignatureContainerComponent} from './containers/signature-container/signature-container';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        EmailFooterComponent,
        EmailFooterContainerComponent,
        UserSignatureContainerComponent,
    ],
    exports: [
        EmailFooterComponent,
        EmailFooterContainerComponent,
        UserSignatureContainerComponent,
    ]
})
export class SignatureModule {
}
