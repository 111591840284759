import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {state, style, trigger} from '@angular/animations';
import {Process} from 'app/+store/process/process';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Subject} from 'rxjs';
import {ParticipantActionType} from 'app/modules/workflow-engine/modules/participant/containers/participant-role-dialog/participant-role-dialog.component';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {Page} from 'app/models/page.class';
import {LicenceControlService} from 'app/shared/services/licence-control.service';

@Component({
  selector: 'dvtx-simple-process-table',
  templateUrl: './simple-process-table.component.html',
  styleUrls: ['./simple-process-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'}))
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleProcessTableComponent implements OnDestroy {
  ParticipantActionType = ParticipantActionType;
  protected onDestroy = new Subject();
  process$;
  processRoles$;
  page: Page;
  processTypes = {};
  query: URLSearchParams = new URLSearchParams();
  dataSource;
  displayedColumns = ['title', 'ident', 'letztes_update', 'type', 'status', 'actions'];
  expandedElement: MatTableDataSource<Element>;
  _processData: any[];
  direction: string = 'desc';
  filteredData;
  _loading = true;

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() highlight: boolean;
  @Input() status?: string;
  @Input() loading: boolean = false;
  @Input() set processData(data: any[]) {
    this._loading = true;

    if (data && data.length) {
      data.forEach(p => {
        p.humanReadableProcessType = this.getProcessType(p);
      });

      this.filteredData = this._processData = data;
      this.dataSource = new MatTableDataSource<Element>(this.filteredData);
      // this.allSharedWith.next(participants);
      this._loading = false;
    }
    // this.setDataSourceAttributes();

    setTimeout(() => this.setDataSourceAttributes());
  }

  @Output() onRefresh = new EventEmitter();
  @Output() onClick: EventEmitter<Process> = new EventEmitter();

  currentUser;

  constructor(private _store: Store<AppState>, private avatarService: AvatarService, private licenceControlService: LicenceControlService) {
    this.currentUser = this.licenceControlService._currentUser.attributes;
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  setDataSourceAttributes() {
    if (!this.dataSource) {
      return;
    }
    this.dataSource.sort = this.sort;
  }

  getProcessDetails($event, process: Process) {
    // $event.stopPropagation();
    // this.process$ = this._store.select(ProcessSelectors.getProcessById(process.id));
    // this.processRoles$ = this._store.select(ProcessRoleSelectors.getProcessParticipantsOfSelectedProcess);
  }

  click(process: Process) {
    this.onClick.emit(process);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  sortBy(sort_key) {
    this.setDataSourceAttributes();
  }

  getProcessType(process: Process) {
    return Process.humanProcessType(process);
  }
}
