import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './bookman-service-account.state';

export const stateKey = 'bookman-service-account';
const getBookmanServiceAccountState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getBookmanServiceAccountEntities,
  selectAll: getAllBookmanServiceAccounts,
} = adapter.getSelectors(getBookmanServiceAccountState);

export const isSignedIn = createSelector(
  getBookmanServiceAccountState,
  (state) => state.signedIn
);

export const getAccount = createSelector(
  getAllBookmanServiceAccounts,
  (entities) => entities[0]
);

export const loadingState = createSelector(
  getBookmanServiceAccountState,
  (state) => state.loading
);

export const errorState = createSelector(
  getBookmanServiceAccountState,
  (state) => state.error
);
