import {IUserSignature} from './user-signature.interface';
import {FooterType} from '../email-footer/email-footer.interface';

export class UserSignature implements IUserSignature {
  readonly type = FooterType.User;

  constructor(public id,
              public title: string,
              public content: string,
              public creatorEmail: string,
              public publishedAt: string,
              public updatedAt: string,
              public createdAt: string,
              public published: boolean,
              ) {}
}

