
<mat-list class="flex-column p-0">
  <mat-list-item class="flex-row">
    <label>{{ selectedPlan.paymentInterval }} {{selectedPlan.title | translate}} Plan:</label>
    <strong>
      &euro;{{ selectedPlan.price }}
    </strong>
  </mat-list-item>

  <mat-list-item class="flex-row">
    <label>{{ 'SHOPPING_CART.USER_LICENCES' | translate }}:</label>
    <strong>{{ form.get("licence_amount").value | number}}</strong>
  </mat-list-item>

  <mat-list-item class="flex-row">
    <label>{{ 'SHOPPING_CART.USER_LICENCES_COST' | translate }}:</label>
    <strong>&euro;{{totalAdditionalMembersCost | number}}</strong>
  </mat-list-item>

  <mat-list-item class="flex-row">
    <label>{{ 'SHOPPING_CART.TOTAL_COST' | translate }}:</label>
    <strong>&euro;{{totalCost | number}}</strong>
  </mat-list-item>

  <mat-list-item class="flex-row" *ngIf="form.controls['creditBalance'].value > 0">
    <label>{{ 'SHOPPING_CART.CREDIT_BALANCE' | translate }}:</label>
    <strong class="text-success">-&euro;{{form.controls['creditBalance'].value | number}}</strong>
  </mat-list-item>

  <mat-list-item class="flex-row" *ngIf="form.controls['creditBalance'].value > 0">
    <label>{{ 'SHOPPING_CART.TOTAL_COST_AFTER_DEDUCTION' | translate }}:</label>
    <strong>&euro;{{totalCost - form.controls['creditBalance'].value | number}}</strong>
  </mat-list-item>

  <mat-list-item>
    <p>{{ 'SHOPPING_CART.NEW_LICENCE_VALID_TO' | translate }}</p>
  </mat-list-item>

  <mat-list-item class="flex-row">
    <label>{{ 'SHOPPING_CART.NEW_LICENCE_VALID_UNTIL' | translate }}:</label>
    <strong>{{selectedPlan.validUntilDate | date: 'd.M.y'}}</strong>
  </mat-list-item>

</mat-list>
