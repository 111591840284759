import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './process-attribute.state';
import {ProcessSelectors} from '../process';

export const stateKey = 'process-attribute';
const getProcessAttributeState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getProcessAttributeEntities,
  selectAll: getAllProcessAttributes,
} = adapter.getSelectors(getProcessAttributeState);

export const getLoadingState = createSelector(
  getProcessAttributeState,
  state => state.loading
);

export const getProcessAttributesOfProcess = (id: string) => createSelector(
  getAllProcessAttributes,
  events => events.filter(event => event.resourceId === id)
);

export const getProcessAttributesOfSelectedProcess = createSelector(
  getAllProcessAttributes,
  ProcessSelectors.getSelectedProcess,
  (attributes, process) => {
    if (process) {
      return attributes.filter(a => a.resourceId === process.id)
    }
    return [];
  });
