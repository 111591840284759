import {createEntityAdapter, EntityState} from '@ngrx/entity';
import * as dayjs from 'dayjs';
import {WorkflowTemplateCategory} from "./workflow-template-category";

export interface State extends EntityState<WorkflowTemplateCategory> {
  selected?: WorkflowTemplateCategory;
  loading: boolean;
}

export const adapter = createEntityAdapter<WorkflowTemplateCategory>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
