import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './fastdocs.state';

export const stateKey = 'fastdocs';
const getFastdocsState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getFastdocsEntities,
  selectAll: getAllFastdocsModels,
} = adapter.getSelectors(getFastdocsState);

// Get item
export const getFastdocsItem = (id: string) => createSelector(
  getFastdocsEntities,
  (fastdocsItems) => fastdocsItems[id]
);

// Get all items
export const getAllFastdocsItems = createSelector(
  getAllFastdocsModels,
  (fastdocsItems) => fastdocsItems
);

export const getSelected = createSelector(
  getFastdocsState,
  (state) => state.selected
)

export const loadingState = createSelector(
  getFastdocsState,
  (state) => state.loading
)
