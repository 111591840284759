import {IProjectStatistics, ProcessesStatsObj, TasksStatsObj, CollectorsStatsObj, DocumentsStatsObj} from './project-statistics.interface';
import {Process} from '../process/process';

export class ProjectStatistics implements IProjectStatistics {
  readonly type = 'process_stat_views';

  constructor(
    public id: string,
    public processId: string = null,
    public processes: ProcessesStatsObj = null,
    public tasks: TasksStatsObj = null,
    public collectors: CollectorsStatsObj = null,
    public documents: DocumentsStatsObj = null
  ) {}
}
