import { IResource } from './resource.interface';
import { LineItem } from './line-item';

export class SalesOrder implements IResource {
  readonly type = 'sales_orders';

  public lineItems: LineItem[] = [];

  constructor(public id, public status: string, public firstName: string, public lastName: string,
              public organizationName: string, public street: string, public streetNo: string,
              public zip: string, public city: string, public countryName: string,
              public ustId: string, public dueDate: Date, public createdAt: Date, public updatedAt: Date) {}
}
