import {IProcessAction} from './process-action.interface';

export class ProcessAction implements IProcessAction {
  constructor(public id,
              public type,
              public processId: string,
              public title: string,
              public description,
              public icon,
              public command) {}
}

