import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './process-lookup.state';
import {MinimalProcess} from './process-lookup';

export const stateKey = 'process-lookup';
const getProcessLookupState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getProcessLookupEntities,
  selectAll: getAllProcessLookupModels,
} = adapter.getSelectors(getProcessLookupState);

export const getLoadingState = createSelector(
  getProcessLookupState,
  state => state.loading
);

export const getProcessById = (id: string) => createSelector(
  getProcessLookupEntities,
  entities => entities[id]
);
