import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { PersonSelectorAutocompleteModule } from "app/shared/modules/person-selector-autocomplete/person-selector-autocomplete.module";
import { StatusSelectorModule } from "app/shared/modules/status-selector/status-selector.module";
import { PrioritySelectorModule } from "app/shared/modules/priority-selector/priority-selector.module";
import { MessageEditorModule } from "app/shared/modules/message-editor/message-editor.module";
import { AlertsModule } from "app/modules/alerts/alerts.module";
import { FilePickerModule } from "app/modules/file-picker/file-picker.module";
import { TasksUploadsModule } from "app/modules/tasks/modules/task-uploads/uploads.module";
import { MessagePanelModule } from "app/modules/tasks/modules/message-panel/message-panel.module";
import { TaskViewerComponent } from "./container/task-viewer/task-viewer.component";
import { TaskStatusComponent } from "./components/tabs/task-status/task-status.component";
import { TaskSettingsComponent } from "./components/tabs/task-settings/task-settings.component";
import { TaskCommentsComponent } from "./components/tabs/task-comments/task-comments.component";
import { TaskActivityLogComponent } from "./components/tabs/task-activity-log/task-activity-log.component";
import { TaskResourcesComponent } from "./components/task-resources/task-resources.component";
import { TaskAssignmentResponseComponent } from "./components/task-assignment-response/task-assignment-response.component";
import { TaskAssignmentsComponent } from "./components/task-assignments/task-assignments.component";
import { TaskAssignmentStatusBarComponent } from "./components/task-assignment-status-bar/task-assignment-status-bar.component";
import { TaskStatusBarComponent } from "./components/task-status-bar/task-status-bar.component";
import { TaskActivityItemComponent } from "./components/task-activity-item/task-activity-item.component";
import { TooltipModule } from "app/five-f/tooltip/tooltip.module";
import { LabelsModule } from "app/five-f/labels/labels.module";

@NgModule({
    imports: [
        SharedModule,
        PersonSelectorAutocompleteModule,
        StatusSelectorModule,
        PrioritySelectorModule,
        MessageEditorModule,
        AlertsModule,
        FilePickerModule,
        TasksUploadsModule,
        MessagePanelModule,
        TooltipModule,
        LabelsModule
    ],
    declarations: [
        TaskViewerComponent,
        TaskStatusComponent,
        TaskSettingsComponent,
        TaskCommentsComponent,
        TaskActivityLogComponent,
        TaskResourcesComponent,
        TaskAssignmentResponseComponent,
        TaskAssignmentsComponent,
        TaskAssignmentStatusBarComponent,
        TaskStatusBarComponent,
        TaskActivityItemComponent
    ],
    exports: [
        TaskViewerComponent,
        TaskStatusComponent,
        TaskSettingsComponent
    ]
})
export class TasksViewerModule {
}
