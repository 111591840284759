<div class="mat-dialog-inner-wrap">
  <div class="mat-dialog-header">
    <h2 mat-dialog-title>{{'GENERAL.CREATE_SIGNATURE' | translate}}</h2>
    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <dvtx-signature-box (window:resize)="onResize()" [boxWidth]="signatureBoxWidth" #signatureBox></dvtx-signature-box>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-end">
    <button type="reset" mat-button mat-dialog-close>{{'GENERAL.CANCEL_ACTION' | translate}}</button>
    <button mat-raised-button color="primary"
            (click)="saveSignature()">{{'GENERAL.SAVE_ACTION' | translate}}</button>
  </div>
</div>
