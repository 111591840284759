import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import * as modelInterface from './organization.interface';
import * as memberInterface from './../member/member.interface';
import * as model from './organization';
import * as build from './organization.builder';

@Injectable()
export class OperatorOrganizationService {
  readonly BASE_PATH = 'operator/api/v1/organization/entities';
  readonly BASE_ONBOARDINGS_PATH = 'operator/api/v1/organization/onboardings';

  constructor(private _http: ApiResourceService) {
  }

  getAll(userId = null): Observable<model.Operator.Organization[]> {
    if (userId) {
      return this.getAllOrganizationsOfUser(userId);
    }
    const builder = new build.Operator.OrganizationBuilder();
    return <Observable<model.Operator.Organization[]>>this._http.get<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, `${this.BASE_PATH}`);
  }

  getAllOrganizationsOfUser(userId: number): Observable<model.Operator.Organization[]> {
    const builder = new build.Operator.OrganizationBuilder();
    return <Observable<model.Operator.Organization[]>>this._http.get<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, `operator/api/v1/user/accounts/${userId}/entities`);
  }

  updateStatus(id: string, status: modelInterface.Operator.OrganizationStatus): Observable<model.Operator.Organization> {
    const builder = new build.Operator.OrganizationBuilder();
    const payload = {
      data: {
        attributes: {
          status: status
        }
      }
    };
    return <Observable<model.Operator.Organization>>this._http.post<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, `${this.BASE_PATH}/${id}/status`, payload);
  }

  getOne(id: string, params: modelInterface.Operator.IOrganizationQueryParams = null): Observable<model.Operator.Organization> {
    let query = ''
    if (params && params.includeStorageInfo) {
      query = '?include_storage_info=true'
    }
    const builder = new build.Operator.OrganizationBuilder();
    return <Observable<model.Operator.Organization>>this._http.get<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, `${this.BASE_PATH}/${id}${query}`);
  }

  createLicencePlan(id: string, licenceParams: modelInterface.Operator.ILicencePlanParams): Observable<model.Operator.Organization> {
    const builder = new build.Operator.OrganizationBuilder();
    const payload = {
      data: {
        attributes: licenceParams
      }
    };
    return <Observable<model.Operator.Organization>>this._http.post<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, `${this.BASE_PATH}/${id}/licences`, payload);
  }

  create(params: modelInterface.Operator.IOrganizationParams): Observable<model.Operator.Organization> {
    const builder = new build.Operator.OrganizationBuilder();
    const payload = {
      data: {
        attributes: params
      }
    };
    return <Observable<model.Operator.Organization>>this._http.post<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, this.BASE_PATH, payload);
  }

  addLicence(id: string): Observable<model.Operator.Organization> {
    const builder = new build.Operator.OrganizationBuilder();
    return <Observable<model.Operator.Organization>>this._http.post<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, `${this.BASE_PATH}/${id}/increment`, {});
  }

  removeLicence(id: string): Observable<model.Operator.Organization> {
    const builder = new build.Operator.OrganizationBuilder();
    return <Observable<model.Operator.Organization>>this._http.post<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, `${this.BASE_PATH}/${id}/decrement`, {});
  }

  update(organization: model.Operator.Organization): Observable<model.Operator.Organization> {
    const builder = new build.Operator.OrganizationBuilder();
    const payload = builder.toRequest(organization);
    return <Observable<model.Operator.Organization>>this._http.put<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, `${this.BASE_PATH}/${organization.id}`, payload);
  }

  updateCustomerName(organization: model.Operator.Organization, value: string): Observable<model.Operator.Organization> {
    return this.updateSalesAttribute(organization.id, 'customer_name', value)
  }

  updateCustomerNumber(organization: model.Operator.Organization, value: string): Observable<model.Operator.Organization> {
    return this.updateSalesAttribute(organization.id, 'customer_number', value)
  }

  updateEdition(organization: model.Operator.Organization, value: number): Observable<model.Operator.Organization> {
    return this.updateSalesAttribute(organization.id, 'edition', value)
  }

  updateCustomerNote(organization: model.Operator.Organization, value: string): Observable<model.Operator.Organization> {
    return this.updateSalesAttribute(organization.id, 'note', value)
  }

  updateSalesAttribute(organizationId: string, attribute: string, value: any): Observable<model.Operator.Organization> {
    const builder = new build.Operator.OrganizationBuilder();
    const payload = { data: { attributes: {} } };
    payload['data']['attributes'][attribute] = value;
    return <Observable<model.Operator.Organization>>this._http.post<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, `${this.BASE_PATH}/${organizationId}/${attribute}`, payload);
  }

  setContractStartedAt(organization: model.Operator.Organization, contractStartedAt: Date): Observable<model.Operator.Organization> {
    const builder = new build.Operator.OrganizationBuilder();
    const payload = {
      data: {
        attributes: {
          contract_started_at: contractStartedAt
        }
      }
    };
    return <Observable<model.Operator.Organization>>this._http.post<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, `${this.BASE_PATH}/${organization.id}/contract_start`, payload);
  }

  destroy(organizationId: string): Observable<model.Operator.Organization> {
    const builder = new build.Operator.OrganizationBuilder();
    return <Observable<model.Operator.Organization>>this._http.del<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, `${this.BASE_PATH}/${organizationId}`);
  }

  getStats(startAt: Date = null): Observable<model.Operator.OrganizationStats> {
    const builder = new build.Operator.OrganizationStatsBuilder();
    const payload = { data: { attributes: { start_at: startAt } } };
    return <Observable<model.Operator.OrganizationStats>>this._http.post<build.Operator.OrganizationStatsBuilder, model.Operator.OrganizationStats>(builder, 'operator/api/v1/organization/stats', payload);
  }

  toggleFastdocsForMember(organization: model.Operator.Organization, member: modelInterface.Operator.IOrganizationMembership | memberInterface.Operator.IMember): Observable<model.Operator.Organization> {
    const builder = new build.Operator.OrganizationBuilder();
    const payload = {
      data: {
        attributes: {
          fastdocs_enabled: !!member.fastdocsEnabledAt,
          big_files_enabled: !!member.bigFilesEnabledAt
        }
      }
    }
    return <Observable<model.Operator.Organization>>this._http.put<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, `${this.BASE_PATH}/${organization.id}/memberships/${member.membershipId}`, payload);
  }

  toggleBigFileForMember(organization: model.Operator.Organization, member: modelInterface.Operator.IOrganizationMembership | memberInterface.Operator.IMember): Observable<model.Operator.Organization> {
    const builder = new build.Operator.OrganizationBuilder();
    const payload = {
      data: {
        attributes: {
          fastdocs_enabled: !!member.fastdocsEnabledAt,
          big_files_enabled: !!member.bigFilesEnabledAt
        }
      }
    }
    return <Observable<model.Operator.Organization>>this._http.put<build.Operator.OrganizationBuilder, model.Operator.Organization>(builder, `${this.BASE_PATH}/${organization.id}/memberships/${member.membershipId}`, payload);
  }

  getAllEmailFooters(organization: model.Operator.Organization): Observable<model.Operator.EmailFooter[]> {
    const builder = new build.Operator.EmailFooterBuilder();
    return <Observable<model.Operator.EmailFooter[]>>this._http.get<build.Operator.EmailFooterBuilder, model.Operator.EmailFooter>(builder, `${this.BASE_PATH}/${organization.id}/email_footers`);
  }

  createEmailFooter(organization: model.Operator.Organization, emailFooter: model.Operator.EmailFooter): Observable<model.Operator.EmailFooter> {
    const builder = new build.Operator.EmailFooterBuilder();
    const payload = builder.toRequest(emailFooter);
    return this._http.post<build.Operator.EmailFooterBuilder, model.Operator.EmailFooter>(builder, `${this.BASE_PATH}/${organization.id}/email_footers` , payload);
  }

  sendTestEmail(organization: model.Operator.Organization, emailFooter: model.Operator.EmailFooter): Observable<model.Operator.EmailFooter> {
    const builder = new build.Operator.EmailFooterBuilder();
    const payload = builder.toRequest(emailFooter);
    return this._http.post<build.Operator.EmailFooterBuilder, model.Operator.EmailFooter>(builder, `${this.BASE_PATH}/${organization.id}/email_footers/test_email` , payload);
  }

  updateEmailFooter(organization: model.Operator.Organization, emailFooter: model.Operator.EmailFooter): Observable<model.Operator.EmailFooter> {
    const builder = new build.Operator.EmailFooterBuilder();
    const payload = builder.toRequest(emailFooter);
    return this._http.put<build.Operator.EmailFooterBuilder, model.Operator.EmailFooter>(builder, `${this.BASE_PATH}/${organization.id}/email_footers/${emailFooter.id}`, payload);
  }

  removeEmailFooter(organization: model.Operator.Organization, id: string): Observable<model.Operator.EmailFooter> {
    const builder = new build.Operator.EmailFooterBuilder();
    return this._http.del<build.Operator.EmailFooterBuilder, model.Operator.EmailFooter>(builder, `${this.BASE_PATH}/${organization.id}/email_footers/${id}`);
  }

  getAllLogos(organization: model.Operator.Organization): Observable<model.Operator.OrganizationLogo[]> {
    const builder = new build.Operator.OrganizationLogoBuilder();
    return this._http.getAll<build.Operator.OrganizationLogoBuilder, model.Operator.OrganizationLogo>(builder, `${this.BASE_PATH}/${organization.id}/logos`);
  }

  updateLogo(organization: model.Operator.Organization, logo: model.Operator.OrganizationLogo, publish): Observable<model.Operator.OrganizationLogo> {
    const builder = new build.Operator.OrganizationLogoBuilder();
    const payload = {
      data: {
        attributes: {
          publish: publish
        }
      }
    };
    return this._http.put<build.Operator.OrganizationLogoBuilder, model.Operator.OrganizationLogo>(builder, `${this.BASE_PATH}/${organization.id}/logos/${logo.id}`, payload);
  }

  removeLogo(organization: model.Operator.Organization, logo: model.Operator.OrganizationLogo): Observable<model.Operator.OrganizationLogo> {
    const builder = new build.Operator.OrganizationLogoBuilder();
    return this._http.del<build.Operator.OrganizationLogoBuilder, model.Operator.OrganizationLogo>(builder, `${this.BASE_PATH}/${organization.id}/logos/${logo.id}`);
  }

  getVisualSignatureBackground(organization: model.Operator.Organization): Observable<model.Operator.VisualSignatureBackground> {
    const builder = new build.Operator.VisualSignatureBackgroundBuilder();
    return <Observable<model.Operator.VisualSignatureBackground>>this._http.get<build.Operator.VisualSignatureBackgroundBuilder, model.Operator.VisualSignatureBackground>(builder, `${this.BASE_PATH}/${organization.id}/visual_signature_backgrounds`);
  }

  updateSignatureBackground(organization: model.Operator.Organization, visual: model.Operator.VisualSignatureBackground): Observable<model.Operator.VisualSignatureBackground> {
    const builder = new build.Operator.VisualSignatureBackgroundBuilder();
    const payload = builder.toRequest(visual);
    return this._http.put<build.Operator.VisualSignatureBackgroundBuilder, model.Operator.VisualSignatureBackground>(builder, `${this.BASE_PATH}/${organization.id}/visual_signature_backgrounds`, payload);
  }

  removeSignatureBackground(organization: model.Operator.Organization): Observable<model.Operator.VisualSignatureBackground> {
    const builder = new build.Operator.VisualSignatureBackgroundBuilder();
    return this._http.del<build.Operator.VisualSignatureBackgroundBuilder, model.Operator.VisualSignatureBackground>(builder, `${this.BASE_PATH}/${organization.id}/visual_signature_backgrounds`);
  }

  /**
   * Onboarding Interface.
   */
  getAllOnboardings(): Observable<model.Operator.OrganizationOnboarding[]> {
    const builder = new build.Operator.OrganizationOnboardingBuilder();
    return <Observable<model.Operator.OrganizationOnboarding[]>>this._http.get<build.Operator.OrganizationOnboardingBuilder, model.Operator.OrganizationOnboarding>(builder, `${this.BASE_ONBOARDINGS_PATH}`);
  }
}
