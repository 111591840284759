import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {TaskEventBuilder} from './task-event.builder';
import {TaskEvent} from './task-event';

@Injectable()
export class TaskEventService {
  readonly BASE_PATH = 'api/v1/tasks/tasks';
  builder: TaskEventBuilder;

  constructor(private _http: ApiResourceService) {
    this.builder = new TaskEventBuilder();
  }

  getAll(id: string): Observable<TaskEvent[]> {
    return <Observable<TaskEvent[]>>this._http.get<TaskEventBuilder, TaskEvent>(this.builder, `${this.BASE_PATH}/${id}/events`);
  }
}
