import {Action} from '@ngrx/store';
import {DocumentTemplate} from './document-template';

export enum DocumentTemplateActionTypes {
  LoadAll = '[DocumentTemplate] Load All',
  LoadAllSuccess = '[DocumentTemplate] Load All Success',
  LoadAllFail = '[DocumentTemplate] Load All Fail',
  Remove = '[DocumentTemplate] Remove Template',
  RemoveSuccess = '[DocumentTemplate] Remove Template Success',
  RemoveFail = '[DocumentTemplate] Remove Template Fail',
  Publish = '[DocumentTemplate] Publish Template',
  PublishSuccess = '[DocumentTemplate] Publish Template Success',
  PublishFail = '[DocumentTemplate] Publish Template Fail',
  Download = '[DocumentTemplate] Download Template',
  DownloadSuccess = '[DocumentTemplate] Download Template Success',
  DownloadFail = '[DocumentTemplate] Download Template Fail',
  DownloadSample = '[DocumentTemplate] Download Sample',
  DownloadSampleSuccess = '[DocumentTemplate] Download Sample Success',
  DownloadSampleFail = '[DocumentTemplate] Download Sample Fail',
  SaveDownload = '[DocumentTemplate] Saving template',
  TestTemplate = '[DocumentTemplate] Test Template',
  TestTemplateSuccess = '[DocumentTemplate] Test Template Success',
  TestTemplateFail = '[DocumentTemplate] Test Template Fail',
}

export class LoadAll implements Action {
  readonly type = DocumentTemplateActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = DocumentTemplateActionTypes.LoadAllSuccess;

  constructor(public payload: DocumentTemplate[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = DocumentTemplateActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = DocumentTemplateActionTypes.Remove;

  constructor(public template: DocumentTemplate) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = DocumentTemplateActionTypes.RemoveSuccess;

  constructor(public payload: DocumentTemplate) {
  }
}

export class RemoveFail implements Action {
  readonly type = DocumentTemplateActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Publish implements Action {
  readonly type = DocumentTemplateActionTypes.Publish;

  constructor(public id: any, public published: boolean) {
  }
}

export class PublishSuccess implements Action {
  readonly type = DocumentTemplateActionTypes.PublishSuccess;

  constructor(public payload: DocumentTemplate) {
  }
}

export class PublishFail implements Action {
  readonly type = DocumentTemplateActionTypes.PublishFail;

  constructor(public payload: any) {
  }
}

export class Download implements Action {
  readonly type = DocumentTemplateActionTypes.Download;

  constructor(public id: string) {
  }
}

export class DownloadSuccess implements Action {
  readonly type = DocumentTemplateActionTypes.DownloadSuccess;

  constructor(public template: DocumentTemplate) {
  }
}

export class DownloadFail implements Action {
  readonly type = DocumentTemplateActionTypes.DownloadFail;

  constructor(public payload: any) {
  }
}

export class DownloadSample implements Action {
  readonly type = DocumentTemplateActionTypes.DownloadSample;

  constructor(public payload: any = null) {
  }
}

export class DownloadSampleSuccess implements Action {
  readonly type = DocumentTemplateActionTypes.DownloadSampleSuccess;

  constructor(public template: DocumentTemplate = null) {
  }
}

export class DownloadSampleFail implements Action {
  readonly type = DocumentTemplateActionTypes.DownloadSampleFail;

  constructor(public payload: any) {
  }
}

export class SaveDownload implements Action {
  readonly type = DocumentTemplateActionTypes.SaveDownload;

  constructor(public template: DocumentTemplate) {
  }
}

export class TestTemplate implements Action {
  readonly type = DocumentTemplateActionTypes.TestTemplate;

  constructor(public template: any = null) {
  }
}

export class TestTemplateSuccess implements Action {
  readonly type = DocumentTemplateActionTypes.TestTemplateSuccess;

  constructor(public template: DocumentTemplate = null) {
  }
}

export class TestTemplateFail implements Action {
  readonly type = DocumentTemplateActionTypes.TestTemplateFail;

  constructor(public payload: any) {
  }
}

export type DocumentTemplateActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Publish
  | PublishSuccess
  | PublishFail
  | Download
  | DownloadSuccess
  | DownloadFail
  | DownloadSample
  | DownloadSampleSuccess
  | DownloadSampleFail
  | SaveDownload
  | TestTemplate
  | TestTemplateSuccess
  | TestTemplateFail;
