import {IResource} from '../../shared/modules/api/models/resource.interface';
import { IFiveFStatus } from '../process/process.interface';
import {FiveFStatusesMap, Process} from '../process/process';

export class MinimalProcess implements IResource {
  readonly type = 'workflow_engine_minimal_process';
  humanReadableProcessType: string;
  public closed: boolean = false;

  constructor(public id: string,
              public title: string,
              public identifier: string,
              public processType: string,
              public state: IFiveFStatus,
              public parentId: string,
              public organizationId: string,
              public clientId: string,
              public clientName: string,
              public clientDatevNo: string,
              public color: string,
              public createdAt: Date,
              public updatedAt: Date) {
    this.humanReadableProcessType = Process.humanProcessTypeFromString(this.processType);
    this.closed = state === FiveFStatusesMap.Closed;
  }
}
