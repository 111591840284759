import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AuditOrderService} from './audit-order.service';
import {EffectsModule} from '@ngrx/effects';
import {AuditOrderEffects} from './audit-order.effects';
import {AuditCreateSelectClientService} from './audit-create-select-client.service';
import {SubareaVerifierRequestCreateService} from './subarea-verifier-request-create.service';
import {SubareaVerifierRequestOverviewService} from './subarea-verifier-request-overview.service';
import {SubareaVerifierRequestConfirmService} from './subarea-verifier-request-confirm.service';
import {AppendicesRequestCreateService} from './appendices-request-create.service';
import {AppendicesRequestRespondService} from './appendices-request-respond.service';
import {AuditWorkflowService} from '../../modules/workflows/workflows/audit/kap/workflow-variations/components/workflow-list/services/audit-workflow.service';
import {AppendicesRequestOverviewService} from './appendices-request-overview.service';
import {AuditContactImportsService} from './audit-contact-imports.service';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([AuditOrderEffects])
  ],
  providers: [
    AuditOrderService,
    AuditCreateSelectClientService,
    SubareaVerifierRequestCreateService,
    SubareaVerifierRequestOverviewService,
    SubareaVerifierRequestConfirmService,
    AppendicesRequestCreateService,
    AppendicesRequestRespondService,
    AuditWorkflowService,
    AppendicesRequestOverviewService,
    AuditContactImportsService
  ]
})
export class AuditOrderStoreModule {
}
