import {Injectable} from '@angular/core';
import {catchError, concatMap, first} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  Edit,
  EditFail,
  EditSuccess,
  LabelActionTypes,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Remove,
  RemoveFail,
  RemoveSuccess
} from './label.actions';
import {Label} from './label';
import {LabelService} from './label.service';

@Injectable()
export class LabelEffects {
  @Effect()
  create$ = this.actions.pipe(
    ofType(LabelActionTypes.Create),
    concatMap((action: Create) => {
      return this._svc.create(action.params).pipe(
        first(),
        concatMap((label: Label) => {
          return [new CreateSuccess(label)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  );

  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(LabelActionTypes.LoadAll),
    concatMap((action: LoadAll) => {
      return this._svc.getAll(action.boardId).pipe(
        first(),
        concatMap((res: Label[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  loadOne$ = this.actions.pipe(
    ofType(LabelActionTypes.LoadOne),
    concatMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((label: Label) => {
          return [new LoadOneSuccess(label)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  );

  @Effect()
  remove$ = this.actions.pipe(
    ofType(LabelActionTypes.Remove),
    concatMap((action: Remove) => {
      return this._svc.remove(action.id).pipe(
        first(),
        concatMap((label: Label) => {
          return [new RemoveSuccess(label.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  );

  @Effect()
  edit$ = this.actions.pipe(
    ofType(LabelActionTypes.Edit),
    concatMap((action: Edit) => {
      return this._svc.edit(action.id, action.params).pipe(
        first(),
        concatMap((label: Label) => {
          return [new EditSuccess(label)];
        }),
        catchError(err => {
          console.error(err);
          return of(new EditFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
    private _svc: LabelService
  ) {
  }
}
