import {Component, Inject, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {Task} from 'app/+store/task/task';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {TaskType} from 'app/+store/task/task.interface';
import {takeUntil, filter, distinctUntilKeyChanged} from 'rxjs/operators';
import {PRIORITIES_MAP} from 'app/shared/modules/priority-selector/component/priority-selector.component';

@Component({
  selector: 'dvtx-create-task-dialog',
  templateUrl: './create-task-dialog.component.html',
  styleUrls: ['./create-task-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTaskDialogComponent implements OnDestroy {
  protected onDestroy = new Subject();

  task: Task;
  form: UntypedFormGroup;
  selectedTab = 0;
  currentUser;
  taskTypes = TaskType;

  constructor(public dialogRef: MatDialogRef<CreateTaskDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _fb: UntypedFormBuilder,
              private _store: Store<AppState>) {
    this._store.select('currentUser').pipe(
      filter(user => !!user),
      distinctUntilKeyChanged('uid'),
      takeUntil(this.onDestroy)
    ).subscribe((user) => {
      this.currentUser = user;
    });

    this.task = new Task();
    if (data.task) {
      this.task = data.task;
    }
    if (data.processId) {
      this.task.process = {id: data.processId};
    }
    this._initForm();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  _initForm() {
    this.form = this._fb.group({
      taskType: [this.task.taskType || this.taskTypes.Standard, Validators.required],
      title: [this.task.title, Validators.required],
      description: [this.task.description],
      dueDate: [this.task.dates && this.task.dates.dueDate ? this.task.dates.dueDate : null],
      priority: [this.task.priority && this.task.priority.id ? this.task.priority.id : 'medium'],
      responsibleEmail: [this.task.responsible && this.task.responsible.email ? this.task.responsible.email : this.currentUser.email],
      assignees: [this.task.assignees || []]
    });

    // Alternative logic for the due date tp prevent an invalid form: set Due date only if in future.
    // New: Currently the due date constraint is totally removed by setting start_at to null inside
    // task-settings.component.html
    // if (this.task.dates && this.task.dates.dueDate) {
    //   try {
    //     const today = new Date().toString();
    //     const dueDate = this.task.dates.dueDate;
    //     const duration = DateUtil.daysDiff(dueDate, today);
    //     if (duration >= 1) {
    //       this.form.patchValue({dueDate: this.task.dates.dueDate});
    //     }
    //   } catch (err) {
    //     console.error(err);
    //   }
    // }
  }

  saveChanges() {
    this.task.title = this.form.get('title').value;
    this.task.description = this.form.get('description').value;
    this.task.dates.dueDate = this.form.get('dueDate').value;
    this.task.priority = PRIORITIES_MAP[this.form.get('priority').value];
    this.task.responsible.email = this.form.get('responsibleEmail').value;
    this.task.assignees = this.form.get('assignees').value;
    this.task.taskType = this.form.get('taskType').value;
    this.data.onSaveChanges(this.task);
  }

  onSelectPredefinedTemplate(task) {
    this.task = task;
    this.form.patchValue({
      title: task.title,
      description: task.description,
      priority: task.priority
    });
  }
}
