/**
 * Actions for the manipulating of the current process context/state.
 */
/* tslint:disable:member-ordering */
import { Action } from '@ngrx/store';

// action types
export const CREATE_PROCESS_CONTEXT = 'CREATE_PROCESS_CONTEXT';
export const CREATE_PROCESS_CONTEXT_SUCCESS = 'CREATE_PROCESS_CONTEXT_SUCCESS';
export const GET_PROCESS_CONTEXT = 'GET_PROCESS_CONTEXT';
export const GET_PROCESS_CONTEXTS = 'GET_PROCESS_CONTEXTS';
export const GET_PROCESS_CONTEXT_SUCCESS = 'GET_PROCESS_CONTEXT_SUCCESS';
export const GET_PROCESS_CONTEXTS_SUCCESS = 'GET_PROCESS_CONTEXTS_SUCCESS';
export const GET_EXTERNAL_PROCESS_CONTEXT = 'GET_EXTERNAL_PROCESS_CONTEXT';
export const GET_EXTERNAL_PROCESS_CONTEXT_SUCCESS = 'GET_EXTERNAL_PROCESS_CONTEXT_SUCCESS';
export const NEW_PROCESS_CONTEXT = 'NEW_PROCESS_CONTEXT';
export const RESET_PROCESS_CONTEXT = 'RESET_PROCESS_CONTEXT';
export const RUN_COMMAND = 'RUN_COMMAND';
export const RUN_COMMAND_SUCCESS = 'RUN_COMMAND_SUCCESS';
export const SET_PROCESS_CONTEXT = 'SET_PROCESS_CONTEXT';
export const SET_PROCESS_CONTEXT_SUCCESS = 'SET_PROCESS_CONTEXT_SUCCESS';
export const UPDATE_PROCESS_CONTEXT = 'UPDATE_PROCESS_CONTEXT';
export const UPDATE_PROCESS_CONTEXT_SUCCESS = 'UPDATE_PROCESS_CONTEXT_SUCCESS';
export const UPDATE_EXTERNAL_PROCESS_CONTEXT = 'UPDATE_EXTERNAL_PROCESS_CONTEXT';
export const UPDATE_EXTERNAL_PROCESS_CONTEXT_SUCCESS = 'UPDATE_EXTERNAL_PROCESS_CONTEXT_SUCCESS';
export const VALIDATE_ACCESS = 'VALIDATE_ACCESS';
export const GET_PROCESS_CONTEXTS_DRAFTS_ONLY = 'GET_PROCESS_CONTEXTS_DRAFTS_ONLY';
export const GET_PROCESS_CONTEXTS_DRAFTS_ONLY_SUCCESS = 'GET_PROCESS_CONTEXTS_DRAFTS_ONLY_SUCCESS';
export const VALIDATE_ACCESS_SUCCESS = 'VALIDATE_ACCESS_SUCCESS';

export class CreateProcessContext implements Action {
  readonly type: string = CREATE_PROCESS_CONTEXT;

  constructor(public payload: any) {}
}

export class CreateProcessContextSuccess implements Action {
  readonly type: string = CREATE_PROCESS_CONTEXT_SUCCESS;

  constructor(public payload: any) {}
}

export class GetProcessContexts implements Action {
  readonly type: string = GET_PROCESS_CONTEXTS;

  constructor(public payload: any) {}
}

export class GetProcessContext implements Action {
  readonly type: string = GET_PROCESS_CONTEXT;

  constructor(public payload: any) {}
}

export class GetProcessContextDraftsOnly implements Action {
  readonly type: string = GET_PROCESS_CONTEXTS_DRAFTS_ONLY;

  constructor(public payload: any) {}
}

export class GetProcessContextDraftsOnlySuccess implements Action {
  readonly type: string = GET_PROCESS_CONTEXTS_DRAFTS_ONLY_SUCCESS;

  constructor(public payload: any) {}
}

export class GetProcessContextSuccess implements Action {
  readonly type: string = GET_PROCESS_CONTEXT_SUCCESS;

  constructor(public payload: any) {}
}

export class GetProcessContextsSuccess implements Action {
  readonly type: string = GET_PROCESS_CONTEXTS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetExternalProcessContext implements Action {
  readonly type: string = GET_EXTERNAL_PROCESS_CONTEXT;

  constructor(public payload: any) {}
}

export class GetExternalProcessContextSuccess implements Action {
  readonly type: string = GET_EXTERNAL_PROCESS_CONTEXT_SUCCESS;

  constructor(public payload: any) {}
}

export class NewProcessContext implements Action {
  readonly type: string = NEW_PROCESS_CONTEXT;

  constructor(public payload: any) {}
}

export class ResetProcessContext implements Action {
  readonly type: string = RESET_PROCESS_CONTEXT;

  constructor(public payload: any = null) {}
}

export class RunCommand implements Action {
  readonly type: string = RUN_COMMAND;

  constructor(public payload: any) {}
}

export class RunCommandSuccess implements Action {
  readonly type: string = RUN_COMMAND_SUCCESS;

  constructor(public payload: any) {}
}

export class SetProcessContext implements Action {
  readonly type: string = SET_PROCESS_CONTEXT;

  constructor(public payload: any) {}
}

export class SetProcessContextSuccess implements Action {
  readonly type: string = SET_PROCESS_CONTEXT_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateProcessContext implements Action {
  readonly type: string = UPDATE_PROCESS_CONTEXT;

  constructor(public payload: any) {}
}

export class UpdateProcessContextSuccess implements Action {
  readonly type: string = UPDATE_PROCESS_CONTEXT_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateExternalProcessContext implements Action {
  readonly type: string = UPDATE_EXTERNAL_PROCESS_CONTEXT;

  constructor(public payload: any) {}
}

export class UpdateExternalProcessContextSuccess implements Action {
  readonly type: string = UPDATE_EXTERNAL_PROCESS_CONTEXT_SUCCESS;

  constructor(public payload: any) {}
}

export class ValidateAccess implements Action {
  readonly type: string = VALIDATE_ACCESS;

  constructor(public payload: any) {}
}

export class ValidateAccessSuccess implements Action {
  readonly type: string = VALIDATE_ACCESS_SUCCESS;

  constructor(public payload: any) {}
}

export type Actions = CreateProcessContext
  | CreateProcessContextSuccess
  | GetProcessContext
  | GetProcessContexts
  | GetProcessContextsSuccess
  | GetProcessContextSuccess
  | GetExternalProcessContext
  | GetExternalProcessContextSuccess
  | NewProcessContext
  | ResetProcessContext
  | RunCommand
  | RunCommandSuccess
  | SetProcessContext
  | SetProcessContextSuccess
  | UpdateProcessContext
  | UpdateProcessContextSuccess
  | UpdateExternalProcessContext
  | UpdateExternalProcessContextSuccess
  | ValidateAccess
  | ValidateAccessSuccess
  | GetProcessContextDraftsOnly
  | GetProcessContextDraftsOnlySuccess;
