import {LicenceActions, LicenceActionTypes} from './licence.actions';
import {adapter, initialState, State} from './licence.state';

export function reducer(state = initialState, action: LicenceActions): State {
  switch (action.type) {
    case LicenceActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case LicenceActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case LicenceActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

