import {Action} from '@ngrx/store';
import {ProcessAttribute} from './process-attribute';
import {IAttributeParams} from './process-attribute.interface';

export enum ProcessAttributeActionTypes {
  Create = '[ProcessAttribute] Create Attributes',
  CreateSuccess = '[ProcessAttribute] Create Attributes Success',
  CreateFail = '[ProcessAttribute] Create Attributes Fail',
  Destroy = '[ProcessAttribute] Destroy Attributes',
  DestroySuccess = '[ProcessAttribute] Destroy Attributes Success',
  DestroyFail = '[ProcessAttribute] Destroy Attributes Fail',
  UpdateAttribute = '[ProcessAttribute] UpdateAttribute Attributes',
  UpdateAttributeSuccess = '[ProcessAttribute] UpdateAttribute Attributes Success',
  UpdateAttributeFail = '[ProcessAttribute] UpdateAttribute Attributes Fail',
  LoadAll = '[ProcessAttribute] Load All Attributes',
  LoadAllSuccess = '[ProcessAttribute] Load All Attributes Success',
  LoadAllFail = '[ProcessAttribute] Load All Attributes Fail',
  LoadOne = '[ProcessAttribute] Load One Attributes',
  LoadOneSuccess = '[ProcessAttribute] Load One Attributes Success',
  LoadOneFail = '[ProcessAttribute] Load One Attributes Fail'
}

export class LoadAll implements Action {
  readonly type = ProcessAttributeActionTypes.LoadAll;

  constructor(public id: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ProcessAttributeActionTypes.LoadAllSuccess;

  constructor(public payload: ProcessAttribute[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ProcessAttributeActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Create implements Action {
  readonly type = ProcessAttributeActionTypes.Create;

  constructor(public id: string, public params: IAttributeParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = ProcessAttributeActionTypes.CreateSuccess;

  constructor(public attribute: ProcessAttribute) {
  }
}

export class CreateFail implements Action {
  readonly type = ProcessAttributeActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class UpdateAttribute implements Action {
  readonly type = ProcessAttributeActionTypes.UpdateAttribute;

  constructor(public processId: string, public id: string, public params: IAttributeParams) {
  }
}

export class UpdateAttributeSuccess implements Action {
  readonly type = ProcessAttributeActionTypes.UpdateAttributeSuccess;

  constructor(public attribute: ProcessAttribute) {
  }
}

export class UpdateAttributeFail implements Action {
  readonly type = ProcessAttributeActionTypes.UpdateAttributeFail;

  constructor(public payload: any) {
  }
}

export class Destroy implements Action {
  readonly type = ProcessAttributeActionTypes.Destroy;

  constructor(public processId: string, public id: string) {
  }
}

export class DestroySuccess implements Action {
  readonly type = ProcessAttributeActionTypes.DestroySuccess;

  constructor(public attribute: ProcessAttribute) {
  }
}

export class DestroyFail implements Action {
  readonly type = ProcessAttributeActionTypes.DestroyFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = ProcessAttributeActionTypes.LoadOne;

  constructor(public processId: string, public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = ProcessAttributeActionTypes.LoadOneSuccess;

  constructor(public attribute: ProcessAttribute) {
  }
}

export class LoadOneFail implements Action {
  readonly type = ProcessAttributeActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type ProcessAttributeActions =
  | Create
  | CreateSuccess
  | CreateFail
  | UpdateAttribute
  | UpdateAttributeSuccess
  | UpdateAttributeFail
  | Destroy
  | DestroySuccess
  | DestroyFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
