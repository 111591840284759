import { Component, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {startWith, takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SystemService } from 'app/services/system.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'dvtx-system-maintenance',
  templateUrl: './system-maintenance.component.html',
  styleUrls: ['./system-maintenance.component.scss']
})
export class SystemMaintenanceComponent implements OnDestroy {
  private onDestroy = new Subject();
  lang = 'de';
  error;
  constructor(
    private _i18nSvc: TranslateService,
    private _systemSvc: SystemService,
    private route: ActivatedRoute,
    private router: Router) {
    this._i18nSvc.onLangChange.pipe(startWith({lang: this._i18nSvc.currentLang}),
      takeUntil(this.onDestroy)).subscribe(lang => this.lang = lang.lang);

    this._systemSvc.getStatus().pipe(takeUntil(this.onDestroy)).subscribe( (status) => {
      if (this.route.snapshot.queryParams['returnUrl']) {
        this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl']);
      } else {
        this.router.navigate(['dashboard']);
      }
    }, (error) => {
      if (error.status === 503) {
        this.error = error;
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  refresh() {
    this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl']);
  }
}
