<div class="{{sidebarNavigationClassStyle}} {{classList}}">
  <mat-list>
    <mat-accordion>
      <div>
        <h3 style="padding-top: 10px;" *ngIf="title">&nbsp;{{title | translate}}</h3>

        <dvtx-workflow-info *ngIf="process"
                            [process]="process"
                            [hasAccessibleParent]="hasAccessibleParent"></dvtx-workflow-info>

        <div *ngIf="process && (this.sidebar$ | async)?.externalPortal">
          <ng-template [cdkPortalOutlet]="(this.sidebar$ | async)?.externalPortal"></ng-template>
          <br>
        </div>

        <nav>
          <ul class="five-f-menu-sidebar">
            <ng-container *ngFor="let route of routes">
              <li class="active">
                <dvtx-navigation-link [icon]="route?.icon"
                                      [count]="route?.icon === 'tasks' ? tasksRouteCount : route?.count"
                                      [title]="route?.title"
                                      [infoLevel]="route?.infoLevel"
                                      [path]="route?.path"
                                      [callback]="route?.callback"></dvtx-navigation-link>
              </li>
            </ng-container>
          </ul>
        </nav>
      </div>
    </mat-accordion>
  </mat-list>
</div>
