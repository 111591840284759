<div class="w-100 d-flex justify-content-center m-0 p-0">
  <div style="flex-grow: 1; max-width: 600px; min-width: 300px;">
    <dvtx-unsupported-browser></dvtx-unsupported-browser>
  </div>
</div>

<div class="d-flex justify-content-center mb-3" *ngIf="cavRedirect">
  <dvtx-alert [color]="'success'" [icon]="'check_circle'" [text]="'<strong>Thank you for your support!</strong> <br><br>You can now complete your registration for further communication.'"></dvtx-alert>
</div>

<mat-card class="five-f-card five-f-elevation-z-3 five-f-card-blue-header five-f-registration-setup">
  <mat-card-header>
    <mat-card-title>
      <h1>{{ 'REGISTRATION.TITLE' | translate }}</h1>
    </mat-card-title>

    <mat-card-subtitle>
      <h5>{{ 'REGISTRATION.COMPLETE_REGISTRATION_TITLE' | translate }}</h5>
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <ng-container [ngSwitch]="state">
      <dvtx-password-form-v2 [user]="user" *ngSwitchCase="RegistrationState.Password" (onNext)="toSetupPage($event)"></dvtx-password-form-v2>
      <dvtx-status-checklist [user]="user" *ngSwitchCase="RegistrationState.Setup"
                             (onBack)="toPasswordPage()"
                             (onNext)="completeSetup()"
                             [accountCompleted]="accountCompleted$ | async"
                             [dmsAccountPresent]="dmsAccountPresent$ | async"></dvtx-status-checklist>
      <ng-container *ngSwitchCase="RegistrationState.InvalidToken">
        <div class="p-3 my-3 d-flex justify-content-center">
          <div class="d-flex flex-column">
            <h2>{{ 'PASSWORD.CONFIRMATION_TOKEN_INVALID' | translate }}</h2>
            <p class="h4">{{ 'PASSWORD.ALREADY_REGISTERED_QUESTION' | translate }}&nbsp; <a routerLink="/session/sign-in" class="anchor-text">{{ 'AUTH.LOGIN_TITLE' | translate }}</a></p>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </mat-card-content>
</mat-card>
