import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ProjectRoomEffects} from './project-room.effects';
import {reducer} from './project-room.reducer';
import {stateKey} from './project-room.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ProjectRoomEffects])
  ],
  providers: [
    ProjectRoomEffects
  ]
})
export class ProjectRoomStoreModule {
}
