import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TreeData} from '../../../../../process-tree/common/process-tree-data';

@Component({
  selector: 'dvtx-process-tree-node',
  templateUrl: './process-tree-node.component.html',
  styleUrls: ['./process-tree-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessTreeNodeComponent implements OnInit {
  @Input() node: TreeData;
  @Input() noTitlePlaceholder;
  @Output() onSelect = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  selectNode(node) {
    if (!node) return;

    this.onSelect.emit(node);
  }
}
