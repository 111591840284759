import { Router } from '@angular/router';

declare var $;

/**
 * Singleton to create a navigation menu structure
 * expected by the sidebar.
 */
export namespace NavigationBuilder {

  /**
   * Creates a navigation menu from the navigation structure
   * send by the API endpoint.
   *
   * @param entries - Array of categories with child element or direct entries.
   * @param router - Angular Router to create the navigationTo entry.
   * @returns {Array} - Sidebar conform menu structure. (TODO: Create interface).
   */
  export function createNavigationFrom(entries: any[], router: Router, parentId = 0) {
    const menu = [];

    for (const entry of entries) {
      switch (entry.type) {
        case 'menu-category':
          menu.push(NavigationBuilder.createMenuCategory(entry, router, parentId));
          break;
        case 'menu-entry-workflow':
        case 'menu-entry':
          menu.push(NavigationBuilder.createMenuEntry(entry, router, parentId));
          break;
      }
    }
    return menu;
  }

  /**
   * Navigation category. Holds linkeable entries as children.
   *
   * @param category - Category, currently presented as MDB collapsible.
   * @param router - Angular Router to create the navigationTo entry.
   * @returns {{title: any, amount: any, children: Array}}
   */
  export function createMenuCategory(category, router, parentId) {
    const title = category.title;
    const amount = category.amount;
    const children = [];

    for (const child of category.children) {
      children.push(NavigationBuilder.createMenuEntry(child, router, parentId));
    }

    return {
      title: title,
      amount: amount,
      children: children
    };
  }

  /**
   * Navigation entry. Holds the link to the subpage. Linking is done
   * by a click event handler using the Angular Router method
   * navigate.
   *
   * @param entry - Linking element.
   * @param router - Angular Router to create the navigationTo entry.
   * @returns {{title, amount, highlight, clickCallback: any}}
   */
  export function createMenuEntry(entry: any, router: Router, parentId) {
    const command = entry.command;
    let callback: any = (_: any) => console.log(entry);
    const _router = router;
    const route = command.client_route;
    let params = command.query_params;

    if (parentId > 0) {
      params = Object.assign({}, params, { parentId: parentId });
    }

    switch (command.type) {
      case 'click':
        callback = (any) => {
          _router.navigate([route], { queryParams: params });
          try {
            $('#sidenav-overlay').remove();
          } catch (e) {
            console.log('Closing sidenav-overlay failed.');
          }
        };
        break;
    }

    return {
      id: entry.id,
      title: entry.title,
      subtitle: entry.subtitle,
      description: entry.description,
      amount: entry.amount,
      highlight: entry.highlight,
      onClick: callback
    };
  }
}
