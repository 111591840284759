import {IAuditOrder} from './audit-order.interface';
import {IOrganization} from './organization.interface';
import {DmsAccountType} from 'app/+store/dms-folder/dms-folder.interface';

export abstract class AbstractAuditOrder implements IAuditOrder {
  process;
  dmsFolderId: string;
  dmsPath: string;
  dmsAccountType: DmsAccountType;

  constructor(public id: string,
              public processId,
              public type: string,
              public identifier,
              public typeOfAuditing,
              public businessYearStart,
              public businessYearEnd,
              public contractee: IOrganization = null,
              public contractor: IOrganization = null) {
  }

  abstract toForm();

  get title() {
    return `${this.identifier} - ${this.contractee.name}`;
  }

  get client() {
    return this.contractee.name;
  }

  get status() {
    if (this.process) {
      return this.process.status.message;
    } else {
      return null;
    }
  }
}

export class AnnualAudit extends AbstractAuditOrder {
  readonly type = 'audit_annual_auditings';

  constructor(public id, public processId,
              public identifier, public typeOfAuditing,
              public businessYearStart, public businessYearEnd,
              public contractee: IOrganization = null, public contractor: IOrganization = null) {
    super(id, processId, 'audit_annual_auditings', identifier, typeOfAuditing,
      businessYearStart, businessYearEnd, contractee, contractor);
  }

  toForm() {
    return {
      id: this.id,
      type: this.type,
      identifier: this.identifier,
      type_of_auditing: this.typeOfAuditing,
      business_year_start: this.businessYearStart,
      business_year_end: this.businessYearEnd
    };
  }

  static fromForm(values) {
    const contractee: IOrganization = {
      type: 'organization_records',
      id: values.business_partner_uuid,
      name: values.business_partner_name
    };
    const order = new AnnualAudit(values.id, values.process_id,
      values.identifier, values.type_of_auditing,
      values.business_year_start, values.business_year_end, contractee);
    order.dmsPath = values.dms_path;
    order.dmsFolderId = values.dms_folder_id;
    order.dmsAccountType = values.dms_account_type;
    return order;
  }
}

export class GroupAudit extends AbstractAuditOrder {
  readonly type = 'audit_group_auditings';

  constructor(public id, public processId,
              public identifier, public typeOfAuditing,
              public businessYearStart, public businessYearEnd,
              public contractee: IOrganization = null, public contractor: IOrganization = null) {
    super(id, processId, 'audit_group_auditings', identifier, typeOfAuditing,
      businessYearStart, businessYearEnd, contractee, contractor);
  }

  toForm() {
    return {
      id: this.id,
      type: this.type,
      identifier: this.identifier,
      type_of_auditing: this.typeOfAuditing,
      business_year_start: this.businessYearStart,
      business_year_end: this.businessYearEnd
    };
  }

  static fromForm(values) {
    const contractee: IOrganization = {
      type: 'organization_records',
      id: values.business_partner_uuid,
      name: values.business_partner_name
    };
    const order = new GroupAudit(values.id, values.process_id,
      values.identifier, values.type_of_auditing,
      values.business_year_start, values.business_year_end, contractee);
    order.dmsPath = values.dms_path;
    order.dmsFolderId = values.dms_folder_id;
    order.dmsAccountType = values.dms_account_type;
    return order;
  }
}
