import { IBookmanServiceAccount } from './bookman-service-account.interface';

export class BookmanServiceAccount implements IBookmanServiceAccount {
  readonly type = 'bookman_service_account';

  constructor(
    public id: number = null,
    public email: string = null,
    public createdAt: string = null
  ) {
  }
}
