import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Favorite} from './favorite';

export interface State extends EntityState<Favorite> {
  current?: Favorite
  loading: boolean
}

export const adapter = createEntityAdapter<Favorite>();

export const initialState: State = adapter.getInitialState({
  loading: false,
});
