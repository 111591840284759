import {Injectable} from '@angular/core';
import {catchError, concatMap, first, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Router} from '@angular/router';
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';
import {
  AddCategoryResponsible,
  AddItemResponsible,
  Create,
  CreateFail,
  CreateSuccess, Duplicate, DuplicateFail, DuplicateSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadDataNodes, LoadDataNodesSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  LockCategoryUpload,
  LockItemUpload,
  NavigateToDashboard,
  OrderItems,
  OrderItemsFail, PrefetchAttachments, PrefetchAttachmentsFail, PrefetchAttachmentsSuccess,
  Publish,
  PublishFail,
  PublishSuccess,
  QuickCollectorActionTypes,
  RemoveCategoryResponsible,
  RemoveItemResponsible,
  SaveDraft,
  SaveDraftFail,
  SaveDraftSuccess,
  UnlockCategoryUpload,
  UnlockItemUpload,
  UpdateRecipients,
  UpdateRecipientsFail,
  UpdateRecipientsSuccess,
  UpdateWorkers,
  UpdateWorkersFail,
  UpdateWorkersSuccess, UpsertDataNodes, UpsertDataNodesSuccess
} from './quickcollector.actions';
import {QuickCollector} from './quickcollector';
import {QuickCollectorService} from './quickcollector.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ProcessParticipantActions} from 'app/+store/process-participant';
import {CollectorItem} from '../collector-item/collector-item';
import {CollectorItemActions} from '../collector-item';
import * as itemLookupActions from '../collector-item-lookup/collector-item-lookup.actions';
import {BomDataNodeService} from '../bom/bom-data-node.service';
import {CollectorCategoryActions} from '../collector-category';
import {CollectorElementType} from '../collector/collector.interface';
import {CollectorCategory} from '../collector-category/collector-category';
import {QuickCollectorActions} from './index';

@Injectable()
export class QuickCollectorEffects {
  @Effect()
  create$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.params).pipe(
        first(),
        concatMap((quickcollector: QuickCollector) => {
          return [new CreateSuccess(quickcollector)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  );

  @Effect()
  publish$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.Publish),
    switchMap((action: Publish) => {
      return this._svc.publish(action.id).pipe(
        first(),
        concatMap((quickcollector: QuickCollector) => {
          return [new PublishSuccess(quickcollector), new NavigateToDashboard(quickcollector.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new PublishFail(err));
        }));
    })
  );

  @Effect({dispatch: false})
  navigateToDashboard$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.NavigateToDashboard),
    switchMap((action: NavigateToDashboard) => {
      try {
        this._router.navigate([`/collecto/run/${action.id}/dashboard`], {replaceUrl: true})
      } catch (error) {
        console.error(error)
      }
      return of(null);
    })
  );

  @Effect()
  updateRecipients$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.UpdateRecipients),
    switchMap((action: UpdateRecipients) => {
      return this._svc.updateRecipients(action.id, action.recipients).pipe(
        first(),
        concatMap((quickcollector: QuickCollector) => {
          return [new UpdateRecipientsSuccess(quickcollector), new ProcessParticipantActions.LoadAllRefresh(action.id), new LoadOne(action.id, false)];
        }),
        catchError(err => {
          console.error(err);
          return of(new UpdateRecipientsFail(err));
        }));
    })
  );

  @Effect()
  updateWorkers$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.UpdateWorkers),
    switchMap((action: UpdateWorkers) => {
      return this._svc.updateWorkers(action.id, action.workers).pipe(
        first(),
        concatMap((quickcollector: QuickCollector) => {
          return [new UpdateWorkersSuccess(quickcollector), new ProcessParticipantActions.LoadAllRefresh(action.id), new LoadOne(action.id, false)];
        }),
        catchError(err => {
          console.error(err);
          return of(new UpdateWorkersFail(err));
        }));
    })
  );

  @Effect()
  saveDraft$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.SaveDraft),
    switchMap((action: SaveDraft) => {
      return this._svc.saveDraft(action.id, action.params).pipe(
        first(),
        concatMap((quickcollector: QuickCollector) => {
          return [new SaveDraftSuccess(quickcollector)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveDraftFail(err));
        }));
    })
  );

  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        concatMap((res: QuickCollector[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  loadOne$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((quickcollector: QuickCollector) => {
          return [new LoadOneSuccess(quickcollector)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  );

  @Effect()
  reorder$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.OrderItems),
    switchMap((action: OrderItems) => {
      return this._svc.reorder(action.id, action.items).pipe(
        first(),
        concatMap((res: any) => {
          // Reset the item lookup cache to not show wrong item paths at comments.
          return [new itemLookupActions.ResetAll()];
        }),
        catchError(err => {
          console.error(err);
          return of(new OrderItemsFail(err))
        }));
    })
  );

  @Effect()
  addItemResponsible$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.AddItemResponsible),
    switchMap((action: AddItemResponsible) => {
      return this.dataNodeSvc.addResponsible(action.collectorId, action.itemId, action.participantId).pipe(
        first(),
        concatMap((node: CollectorItem) => {
          this._notifyService.success('COLLECTOR.ADD_RESPONSIBLE_SUCCESS')
          return [new CollectorItemActions.SaveSuccess(node)];
        }),
        catchError(err => {
          this._notifyService.error('COLLECTOR.ERRORS.ADD_RESPONSIBLE_FAILURE')
          console.error(err);
          return of(new UpdateRecipientsFail(err));
        }));
    })
  );

  @Effect()
  removeItemResponsible$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.RemoveItemResponsible),
    switchMap((action: RemoveItemResponsible) => {
      return this.dataNodeSvc.removeResponsible(action.collectorId, action.itemId).pipe(
        first(),
        concatMap((node: CollectorItem) => {
          this._notifyService.success('COLLECTOR.ADD_RESPONSIBLE_SUCCESS')
          return [new CollectorItemActions.SaveSuccess(node)];
        }),
        catchError(err => {
          this._notifyService.error('COLLECTOR.ERRORS.ADD_RESPONSIBLE_FAILURE')
          console.error(err);
          return of(new UpdateRecipientsFail(err));
        }));
    })
  );

  @Effect()
  addCategoryResponsible$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.AddCategoryResponsible),
    switchMap((action: AddCategoryResponsible) => {
      return this.dataNodeSvc.addResponsible(action.collectorId, action.categoryId, action.participantId).pipe(
        first(),
        concatMap((_node) => {
          this._notifyService.success('COLLECTOR.ADD_RESPONSIBLE_SUCCESS')
          return [new QuickCollectorActions.UpsertDataNodes(action.collectorId, action.categoryId)];
        }),
        catchError(err => {
          this._notifyService.error('COLLECTOR.ERRORS.ADD_RESPONSIBLE_FAILURE')
          console.error(err);
          return of(new UpdateRecipientsFail(err));
        }));
    })
  );

  @Effect()
  removeCategoryResponsible$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.RemoveCategoryResponsible),
    switchMap((action: RemoveCategoryResponsible) => {
      return this.dataNodeSvc.removeResponsible(action.collectorId, action.categoryId).pipe(
        first(),
        concatMap((_node) => {
          this._notifyService.success('COLLECTOR.ADD_RESPONSIBLE_SUCCESS');
          return [new QuickCollectorActions.UpsertDataNodes(action.collectorId, action.categoryId)];
        }),
        catchError(err => {
          this._notifyService.error('COLLECTOR.ERRORS.ADD_RESPONSIBLE_FAILURE')
          console.error(err);
          return of(new UpdateRecipientsFail(err));
        }));
    })
  );

  @Effect()
  lockItemUpload$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.LockItemUpload),
    switchMap((action: LockItemUpload) => {
      return this.dataNodeSvc.lockDataNode(action.collectorId, action.itemId).pipe(
        first(),
        concatMap((item: CollectorItem) => {
          this._notifyService.success('UPLOAD.ELEMENT_LOCK_SUCCESSFULLY');
          return [new CollectorItemActions.SaveSuccess(item)];
        }),
        catchError(err => {
          this._notifyService.error('UPLOAD.ELEMENT_LOCK_FAIL');
          console.error(err);
          return of(new UpdateRecipientsFail(err));
        }));
    })
  );

  @Effect()
  unlockItemUpload$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.UnlockItemUpload),
    switchMap((action: UnlockItemUpload) => {
      return this.dataNodeSvc.unlockDataNode(action.collectorId, action.itemId).pipe(
        first(),
        concatMap((item: CollectorItem) => {
          this._notifyService.success('UPLOAD.ELEMENT_UNLOCK_SUCCESSFULLY')
          return [new CollectorItemActions.SaveSuccess(item)];
        }),
        catchError(err => {
          this._notifyService.error('UPLOAD.ELEMENT_UNLOCK_FAIL')
          console.error(err);
          return of(new UpdateRecipientsFail(err));
        }));
    })
  );

  @Effect()
  loadNodes$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.LoadDataNodes),
    switchMap((action: LoadDataNodes) => {
      return this.dataNodeSvc.getAll(action.processId, action.parentNodeId).pipe(
        first(),
        concatMap((nodes) => {
          // this._notifyService.success('UPLOAD.ELEMENT_UNLOCK_SUCCESSFULLY')
          return [new QuickCollectorActions.LoadDataNodesSuccess(nodes)];
        }),
        catchError(err => {
          this._notifyService.error('UPLOAD.ELEMENT_UNLOCK_FAIL')
          console.error(err);
          return of(new UpdateRecipientsFail(err));
        }));
    })
  );

  @Effect()
  loadNodesSuccess$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.LoadDataNodesSuccess),
    switchMap((action: LoadDataNodesSuccess) => {
      const nodes = action.nodes;
      const categories = [];
      const items = [];
      nodes.forEach(node => {
        if (node.type === CollectorElementType.Category) {
          categories.push(node);
        }

        if (node.type === CollectorElementType.Item) {
          items.push(node);
        }
      });
      return [
        new CollectorCategoryActions.LoadAllSuccess(categories),
        new CollectorItemActions.LoadAllSuccess(items)
      ];
    }),
    catchError(err => {
      this._notifyService.error('UPLOAD.ELEMENT_UNLOCK_FAIL')
      console.error(err);
      return of(new UpdateRecipientsFail(err));
    })
  );

  @Effect()
  upsertNodes$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.UpsertDataNodes),
    switchMap((action: UpsertDataNodes) => {
      return this.dataNodeSvc.getAll(action.processId, action.parentNodeId).pipe(
        first(),
        concatMap((nodes) => {
          // this._notifyService.success('UPLOAD.ELEMENT_UNLOCK_SUCCESSFULLY')
          return [new QuickCollectorActions.UpsertDataNodesSuccess(nodes)];
        }),
        catchError(err => {
          this._notifyService.error('UPLOAD.ELEMENT_UNLOCK_FAIL')
          console.error(err);
          return of(new UpdateRecipientsFail(err));
        }));
    })
  );

  @Effect()
  upsertNodesSuccess$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.UpsertDataNodesSuccess),
    switchMap((action: UpsertDataNodesSuccess) => {
      const categories: CollectorCategory[] = [];
      const items: CollectorItem[] = [];
      const nodes = action.nodes;
      nodes.forEach(node => {
        if (node.type === CollectorElementType.Category) {
          categories.push(<CollectorCategory>node);
        }

        if (node.type === CollectorElementType.Item) {
          items.push(<CollectorItem>node);
        }
      });
      return [
        new CollectorCategoryActions.UpsertAllSuccess(categories),
        new CollectorItemActions.UpsertAllSuccess(items)
      ];
    }),
    catchError(err => {
      this._notifyService.error('UPLOAD.ELEMENT_UNLOCK_FAIL')
      console.error(err);
      return of(new UpdateRecipientsFail(err));
    })
  );


  @Effect()
  lockCategoryUpload$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.LockCategoryUpload),
    switchMap((action: LockCategoryUpload) => {
      return this.dataNodeSvc.lockDataNode(action.collectorId, action.categoryId).pipe(
        first(),
        concatMap((_node) => {
          this._notifyService.success('UPLOAD.GROUP_LOCK_SUCCESSFULLY');
          return [
            new QuickCollectorActions.UpsertDataNodes(action.collectorId, action.categoryId)
          ];
        }),
        catchError(err => {
          this._notifyService.error('UPLOAD.GROUP_LOCK_FAIL')
          console.error(err);
          return of(new UpdateRecipientsFail(err));
        }));
    })
  );

  @Effect()
  unlockCategoryUpload$ = this.actions.pipe(
    ofType(QuickCollectorActionTypes.UnlockCategoryUpload),
    switchMap((action: UnlockCategoryUpload) => {
      return this.dataNodeSvc.unlockDataNode(action.collectorId, action.categoryId).pipe(
        first(),
        concatMap((_node) => {
          this._notifyService.success('UPLOAD.GROUP_UNLOCK_SUCCESSFULLY')
          return [new QuickCollectorActions.UpsertDataNodes(action.collectorId, action.categoryId)];
        }),
        catchError(err => {
          this._notifyService.error('UPLOAD.GROUP_UNLOCK_FAIL')
          console.error(err);
          return of(new UpdateRecipientsFail(err));
        }));
    })
  );


  duplicate$ = createEffect(() => this.actions.pipe(
    ofType(QuickCollectorActionTypes.Duplicate),
    switchMap((action: Duplicate) => {
      return this._svc.duplicate(action.id, action.includeTemplates, action.includeDueDates).pipe(
        first(),
        concatMap((quickcollector: QuickCollector) => {
          return [new DuplicateSuccess(quickcollector)];
        }),
        catchError(err => {
          console.error(err);
          return of(new DuplicateFail(err));
        }));
    }))
  );


  prefetch_attachments$ = createEffect(() => this.actions.pipe(
    ofType(QuickCollectorActionTypes.PrefetchAttachments),
    switchMap((action: PrefetchAttachments) => {
      return this._svc.prefetch_attachments(action.id).pipe(
        first(),
        concatMap(() => {
          return [new PrefetchAttachmentsSuccess()];
        }),
        catchError(err => {
          console.error(err);
          return of(new PrefetchAttachmentsFail());
        }));
    }))
  );

  constructor(private actions: Actions,
              private _svc: QuickCollectorService,
              private dataNodeSvc: BomDataNodeService,
              private _router: Router,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
