import {Injectable} from '@angular/core';
import {catchError, first, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {LoadAll, LoadAllFail, LoadAllSuccess, ProjectStatisticsActionTypes} from './project-statistics.actions';
import {ProjectStatistics} from './project-statistics';
import {ProjectStatisticsService} from './project-statistics.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class ProjectStatisticsEffects {
  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(ProjectStatisticsActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.processId).pipe(
        first(),
        map((res: ProjectStatistics) => {
          if (res && res.id) {
            return new LoadAllSuccess(res);
          }
        }),
        catchError(err => {
          console.log(err);
          return of(new LoadAllFail(err));
        }));
    })
  );



  constructor(private actions: Actions,
    private _svc: ProjectStatisticsService,
    private _router: Router,
    private _translateSvc: TranslateService,
    private _notifyService: NotificationService) {
  }
}
