import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from "./workflow-template-category.state";

export const stateKey = 'workflow-template-category';
const getWorkflowTemplateCategoryState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getWorkflowTemplateCategoryEntities,
  selectAll: getAllWorkflowTemplateCategories,
} = adapter.getSelectors(getWorkflowTemplateCategoryState);

export const getSelected = createSelector(
  getWorkflowTemplateCategoryState,
  (state) => state.selected
);

export const getAll = createSelector(
  getAllWorkflowTemplateCategories,
  (categories) => categories
);
export const getCategoryById = (id: string) => createSelector(
  getAllWorkflowTemplateCategories,
  (categories) => categories.find(cat => cat.id === id)
);


export const loadingState = createSelector(
  getWorkflowTemplateCategoryState,
  (state) => state.loading
);


