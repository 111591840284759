import {NgModule} from '@angular/core';
import {TruncatePipe} from './pipes/truncate.pipe';
import {ApiModule} from 'app/shared/modules/api/api.module';
import {BaseFormElementsModule} from 'app/shared/modules/base-form-elements/base-form-elements.module';

@NgModule({
  imports: [
    ApiModule,
    BaseFormElementsModule
  ],
  declarations: [
    TruncatePipe,
  ],
  exports: [
    TruncatePipe
  ]
})
export class ListingModule { }
