import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {AlertsModule} from '../../../alerts/alerts.module';
import {BatchContactDeletionDialogComponent} from './containers/batch-contact-deletion-dialog/batch-contact-deletion-dialog.component';
import {BatchPartnerLinkActivationDialogComponent} from './containers/batch-partner-link-activation-dialog/batch-partner-link-activation-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    AlertsModule,
  ],
  declarations: [
    BatchContactDeletionDialogComponent,
    BatchPartnerLinkActivationDialogComponent
  ],
  exports: [
    SharedModule,
    BatchContactDeletionDialogComponent,
    BatchPartnerLinkActivationDialogComponent
  ]
})
export class BatchDialogModule {
}
