import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ExcelExport} from './excel-export';

export interface State extends EntityState<ExcelExport> {
  loading: boolean
}

export const adapter = createEntityAdapter<ExcelExport>();
export const initialState: State = adapter.getInitialState({
  loading: false
});
