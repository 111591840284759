import { Action } from '@ngrx/store';

export const FILTER_CONTACTS = 'FILTER_CONTACTS';
export const RESET_CONTACT_FILTER = 'RESET_CONTACTS';


export class FilterContacts implements Action {
  readonly type: string = FILTER_CONTACTS;

  constructor(public payload: any) { }
}

export class ResetContactFilter implements Action {
  readonly type: string = RESET_CONTACT_FILTER;

  constructor(public payload: any = null) { }
}


export type Actions = FilterContacts
  | ResetContactFilter;
