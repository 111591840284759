import {Injectable} from '@angular/core';
import {catchError, first, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {CppApiService} from '../../services/cpp-api.service';
import {
  LoadAll,
  LoadAllSuccess,
  LoadMy,
  LoadMyFail,
  LoadMySuccess,
  MembershipActionTypes,
  RemoveOne,
  RemoveOneSuccess
} from './membership.actions';
import {Membership, MembershipResponse} from '../../models/membership.model';
import {NotificationActions} from '../notification'
import {NaturalPersonActions} from '../natural-person'
import {naturalPersonfromJsonAPI} from '../../models/natural-person.model';
import {Root} from '../../models/json-api.model';
import {LicenceAssignmentActions} from '../licence-assignment';
import * as contactActions from 'app/+store/contact/contact.actions';
import {OrganizationProxyService} from '../organization/organization-proxy.service';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class MembershipEffects {
  @Effect()
  loadMyNP = this.actions.pipe(
    ofType(MembershipActionTypes.LoadMy),
    switchMap((action: LoadMy) => {
      return this.cppApiService.get<Root<MembershipResponse>>('organization/' + action.orgaId + '/membership/my').pipe(
        map((res: Root<MembershipResponse>) => {
            const membership: Membership = {
              id: res.data.id,
              naturalProfileId: res.data.relationships.person.data.id,
              organizationId: res.data.relationships.organization.data.id,
              roles: res.data.relationships.roles.data.map(r => r.id),
              hasAdministrationRights: hasAdministrationRights(res),
              isOrganizationalOwner: isOrganizationalOwner(res),
                isSysadmin: isOrganizationalOwner(res),
                createdAt: res.data.attributes.created_at
              }
              return new LoadMySuccess(membership)
            }
          ),
          catchError(err => of(new LoadMyFail(err)))
        )
      }
    )
  );

  @Effect()
  loadMemberships = this.actions.pipe(
    ofType(MembershipActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this.cppApiService.get<Root<MembershipResponse[]>>('organization/' + action.orgId + '/membership').pipe(
        mergeMap((res: Root<MembershipResponse[]>) => {
          const memberships: Membership[] = res.data.map((d: MembershipResponse) => {
            const membership: Membership = {
              id: d.id,
              naturalProfileId: d.relationships.person.data.id,
              organizationId: action.orgId,
              hasAdministrationRights: hasAdministrationRights({
                included: res.included,
                data: d
              }),
              isOrganizationalOwner: isOrganizationalOwner({
                included: res.included,
                data: d
              }),
              isSysadmin: isSysadmin({
                included: res.included,
                data: d
              }),
              roles: d.relationships.roles.data.map(r => r.id),
              createdAt: d.attributes.created_at,
            };
            return membership
          });

          const naturalPersons = res.included.filter(i => i.type === 'natural_persons').map(p => naturalPersonfromJsonAPI(p as any, res.included))

          return [
            new LoadAllSuccess(memberships),
            new NaturalPersonActions.LoadSuccess(naturalPersons)
          ]
        }),
        catchError((err) => {
            return of(new LoadMyFail(err))
          })
        )
      }
    )
  );

  @Effect()
  removeMember = this.actions.pipe(
    ofType(MembershipActionTypes.RemoveOne),
    switchMap((action: RemoveOne) => {
      return this.cppApiService.delete('organization/' + action.organization.id + '/membership/' + action.memberId).pipe(
        switchMap(() => {
            this.orgProxySvc
              .pruneEmployee(action.memberEmail, action.ownerEmail)
              .pipe(first())
              .subscribe(() => {
              }, err => console.error(err));
              this._notifyService.success('ADDRESSBOOK.MEMBER_DELETED')

            return [
              new RemoveOneSuccess(action.membershipId),
              new contactActions.Reload(action.organization),
              new LicenceAssignmentActions.Remove(action.organization.id, action.membershipId)
            ];
          }
        ),
        catchError(err => of(new NotificationActions.ShowHttpError(err)))
      )
      }
    )
  );

  constructor(private actions: Actions,
              private cppApiService: CppApiService,
              private orgProxySvc: OrganizationProxyService,
              private _notifyService: NotificationService,
              private _translateSvc: TranslateService) {
  }
}

function hasAdministrationRights(m: Root<MembershipResponse>): boolean {
  return m.data.relationships.roles.data.reduce((prevVal, currentvalue) => {
    const role: any = m.included.find((x) => {
      return x.id === currentvalue.id
    });
    return prevVal || role.attributes.current_roletype === 'owner' || role.attributes.current_roletype === 'administrator'
  }, false);
}

function isOrganizationalOwner(m: Root<MembershipResponse>): boolean {
  return hasRole(m, 'owner');
}

function isSysadmin(m: Root<MembershipResponse>): boolean {
  return m.data.relationships.roles.data.reduce((prevVal, currentvalue) => {
    const role: any = m.included.find((x) => {
      return x.id === currentvalue.id
    });
    return prevVal
      || role.attributes.current_roletype === 'owner'
      || role.attributes.current_roletype === 'administrator'
      || role.attributes.current_roletype === 'sysadmin'
  }, false);
}

function hasRole(m, roleName) {
  return m.data.relationships.roles.data.reduce((prevVal, currentvalue) => {
    const role: any = m.included.find((x) => {
      return x.id === currentvalue.id
    });
    return prevVal || role.attributes.current_roletype === roleName
  }, false);
}
