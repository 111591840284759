import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  template: ``
})
export class RoutingComponent implements OnInit {

  route;

  constructor(private _router: Router) {
    this.route = localStorage.getItem('route');
  }

  ngOnInit(): void {
    if (!this.route) {
      this.route = '/dashboard';
    }
    this._router.navigateByUrl(this.route).then(redirected => console.log(redirected));
  }
}
