import {Component, Inject, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {SignatureBoxComponent} from "../signature-box/signature-box.component";

@Component({
  selector: 'dvtx-create-signature-dialog',
  templateUrl: './create-signature-dialog.component.html',
  styleUrls: ['./create-signature-dialog.component.scss']
})
export class CreateSignatureDialogComponent {
  signatureAsBase64: string;
  signatureBoxWidth = 640;
  @ViewChild('signatureBox', { static: true }) signatureBox: SignatureBoxComponent;

  constructor(
    public dialogRef: MatDialogRef<CreateSignatureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.onResize();
  }

  saveSignature() {
    this.signatureAsBase64 = this.signatureBox.confirmSignature();
    this.dialogRef.close(this.signatureAsBase64);
  }

  onResize() {
    if (window.innerWidth > 800) {
      this.signatureBoxWidth = 640;
    }
    if (window.innerWidth <= 800) {
      this.signatureBoxWidth = 540;
    }
    if (window.innerWidth <= 700) {
      this.signatureBoxWidth = 440;
    }
    if (window.innerWidth <= 600) {
      this.signatureBoxWidth = 340;
    }
    if (window.innerWidth <= 500) {
      this.signatureBoxWidth = 270;
    }
    if (window.innerWidth <= 350) {
      this.signatureBoxWidth = 240;
    }
  }
}
