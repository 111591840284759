import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';

@Injectable()
export class SyncApiProfileService {
  readonly BASE_PATH = 'api/v1/settings/profile';
  basePath;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  update(params) {
    const payload = { data: { attributes: params } };
    return this._http.put(this.basePath, payload)
  }
}
