<div class="mat-dialog-inner-wrap {{classNames}}" [style.borderColor]="color" [class.mat-dialog-dynamic-width]="dynamicWidth">

  <div *ngIf="title" class="mat-dialog-header">
    <ng-template [cdkPortalOutlet]="title"></ng-template>
  </div>

  <div mat-dialog-content [class.p-0]="noPadding">
    <div class="d-flex justify-content-center mb-1" *ngIf="icon">
      <mat-icon aria-hidden="true" class="dialog-top-icon" [style.color]="color">{{icon}}</mat-icon>
    </div>

    <ng-template [cdkPortalOutlet]="context"></ng-template>
  </div>

  <div *ngIf="buttonsToolbar" mat-dialog-actions class="d-flex justify-content-end">
    <ng-template [cdkPortalOutlet]="buttonsToolbar"></ng-template>
  </div>
</div>
