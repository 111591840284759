import {Employee} from '../models/employee';

export class EmployeeBuilder {
  static toRequest(mem: Employee) {
    return {
      data: {
        email: mem.email,
        gender: mem.gender,
        first_name: mem.firstName,
        last_name: mem.lastName,
        title: mem.title,
      }
    }
  }

  public static fromResponseList(response): Employee[] {
    const ret: Employee[] = [];
    for (const item of response.data) {
      ret.push(EmployeeBuilder.fromResponse(item));
    }
    return ret;
  }

  static fromResponse(data): Employee {
    const ret = new Employee();
    ret.id = data['id'];
    ret.firstName = data['attributes']['first_name'];
    ret.lastName = data['attributes']['last_name'];
    ret.gender = data['attributes']['gender'];
    ret.title = data['attributes']['title'];
    ret.email = data['attributes']['email'];
    return ret;
  }
}
