import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {CollectorCategory} from './collector-category';
import {ApiStatusToItemStatusMapping} from 'app/+store/collector/collector.interface';

export class CollectorCategoryBuilder implements IApiResourceBuilder<CollectorCategory> {
  constructor(public processId: string) {}

  fromResponse(data): CollectorCategory {
    const category = new CollectorCategory(
      data.id,
      this.processId,
      data.attributes.title,
      data.attributes.description,
      data.attributes.color,
      data.attributes.pre_due_date,
      data.attributes.due_date,
      data.attributes.started_at,
      data.attributes.completed_at,
      data.attributes.created_at,
      data.attributes.updated_at,
      false,
      null,
      data.attributes.starts_at,
      data.attributes.ends_at,
      data.attributes.order);
    category.status = ApiStatusToItemStatusMapping[data.attributes.status];
    category.priority = data.attributes.priority == 'undefined' ? 'medium' : data.attributes.priority;
    category.isOverdued = category.dueDate && this.isOverdued(category.dueDate);
    return category;
  }

  toRequest(_: CollectorCategory) {
    return null;
  }

  isOverdued(date) {
    const currentDate = new Date(new Date().setHours(24, 0, 0));
    const catDueDate = new Date(new Date(date).setHours(24, 0, 0));
    return Math.round((catDueDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)) < 0;
  }
}
