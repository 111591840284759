import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { PersonSelectorAutocompleteModule } from "app/shared/modules/person-selector-autocomplete/person-selector-autocomplete.module";
import { StatusSelectorModule } from "app/shared/modules/status-selector/status-selector.module";
import { PrioritySelectorModule } from "app/shared/modules/priority-selector/priority-selector.module";
import { InViewportModule } from "ng-in-viewport";
import { ListingTasksComponent } from "./container/listing-tasks/listing-tasks.component";
import { TaskTypeIconComponent } from "./components/task-type-icon/task-type-icon.component";
import { ListingTasksService } from "./services/listing-tasks.service";
import { TaskIndicatorsComponent } from "./components/task-indicators/task-indicators.component";
import { TaskActionsService } from "./services/task-actions.service";
import { ListingItemDatepickerComponent } from "./components/listig-item-datepicker/listig-item-datepicker.component";
import { DeleteTaskDialogComponent } from "./components/delete-task-dialog/delete-task-dialog.component";
import { TooltipModule } from "app/five-f/tooltip/tooltip.module";
import { TaskActionsComponent } from "./components/task-actions/task-actions.component";
import { LabelsModule } from "app/five-f/labels/labels.module";

@NgModule({
    imports: [
        SharedModule,
        PersonSelectorAutocompleteModule,
        StatusSelectorModule,
        PrioritySelectorModule,
        InViewportModule,
        TooltipModule,
        LabelsModule
    ],
    declarations: [
        ListingTasksComponent,
        TaskTypeIconComponent,
        TaskIndicatorsComponent,
        ListingItemDatepickerComponent,
        DeleteTaskDialogComponent,
        TaskActionsComponent
    ],
    exports: [
        ListingTasksComponent,
        DeleteTaskDialogComponent
    ],
    providers: [
        ListingTasksService,
        TaskActionsService
    ]
})
export class ListingTasksModule {
}
