<ng-container *ngIf="(_selectedProfiles | async)?.length">
  <dvtx-person-selected-dropdown [avatarSize]="avatarSize" [maxToShow]="maxToShow" [selectedProfiles]="_selectedProfiles | async"></dvtx-person-selected-dropdown>
</ng-container>


<ng-container *ngIf="(_selectedProfile | async) as profile">
  <dvtx-tooltip [tooltipText]="(profile?.firstName && profile.lastName) ? profile?.name : profile?.email" [position]="'top left'">
    <dvtx-avatar [size]="avatarSize" [email]="profile?.email"></dvtx-avatar>
  </dvtx-tooltip>
</ng-container>


<ng-container *ngIf="!disabled">
    <button
     [matTooltip]="title"
      mat-icon-button
      color="gray"
      cdk-overlay-origin
      (click)="openSelector()"
      #dropdown="cdkOverlayOrigin"
      [disableRipple]='true'>
      <ng-container [ngSwitch]="multipeSelection">
        <ng-container *ngSwitchCase="true">
          <ng-container [ngSwitch]="icon">
            <ng-container *ngSwitchCase="'supervised_user_circle'">
              <mat-icon svgIcon="supervised_user_circle"></mat-icon>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <mat-icon>{{icon}}</mat-icon>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <ng-container *ngIf="_selectedProfile | async">
            <mat-icon class="custom--edit--icon">edit</mat-icon>
          </ng-container>
          <ng-container *ngIf="!(_selectedProfile | async)">
            <ng-container [ngSwitch]="icon">
              <ng-container *ngSwitchCase="'supervised_user_circle'">
                <mat-icon svgIcon="supervised_user_circle"></mat-icon>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <mat-icon>{{icon}}</mat-icon>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </button>


  <ng-template
    cdk-connected-overlay
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayOrigin]="dropdown"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="showDropdown"
    (backdropClick)="showDropdown = false">
    <div class="mat-elevation-z8 p-0 list--container">
      <mat-form-field class="mat-select-search-input">
        <input
          matInput
          [placeholder]="acceptTypedEmail ? 'Type and email or search' : 'GENERAL.SEARCH' | translate"
          name="search"
          autocomplete="new-search"
          #searchSelectInput
          (keydown)="_handleKeydown($event)"
          (keyup)="onInputChange($event.target.value)"/>
        <span matPrefix><mat-icon matPrefix>search</mat-icon></span>
      </mat-form-field>
      <dvtx-add-and-invite *ngIf="showInviteParticipant" (onSelect)="inviteAndAddParticipant.emit($event)" [action]="AddOrInviteActionType.Button"></dvtx-add-and-invite>

      <cdk-virtual-scroll-viewport [itemSize]="36" style="height: 300px; min-width: 400px; overflow-x: hidden">
      <div class="px-3 py-1">
        <ul class="m-0">
          <ng-container [ngSwitch]="multipeSelection">
            <ng-container *ngSwitchCase="true">
              <li #firstLi tabindex="-1" class="mb-1" *cdkVirtualFor="let person of (filteredPersons | async)">
                <mat-checkbox [checked]="selectedEmailMap[person?.email?.trim()?.toLocaleLowerCase()]"
                              (change)="selectPerson(person, $event)"
                              (click)="openConfirmationSelectPerson(person, $event)">
                  <dvtx-avatar mat-list-icon [size]="'xs'" [email]="person?.email"
                               [showTitle]="true"
                               [showEmail]="true" ></dvtx-avatar>
                </mat-checkbox>
              </li>
              <li *ngIf="searchTerm !== '' && !(filteredPersons | async).length && acceptTypedEmail">
                <mat-checkbox (change)="selectPerson({email: searchTerm}, $event)" [disabled]="!this.searchTermIsEmail">
                  <dvtx-avatar mat-list-icon [size]="'xs'" [email]="searchTerm"
                               [showTitle]="true"
                               [showEmail]="true"></dvtx-avatar>
                </mat-checkbox>
              </li>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <li #firstLi tabindex="-1" class="mb-1" *cdkVirtualFor="let person of (filteredPersons | async)"  (click)="selectPerson(person)">
                <dvtx-avatar mat-list-icon [size]="'xs'" [email]="person.email"
                             [showTitle]="true"
                             [showEmail]="true"></dvtx-avatar>
              </li>
              <li
                *ngIf="searchTerm !== '' && !(filteredPersons | async).length && acceptTypedEmail"
                [class.disabled]="!this.searchTermIsEmail"
                (click)="selectPerson({email: searchTerm})">
                <dvtx-avatar mat-list-icon [size]="'xs'" [email]="searchTerm"
                             [showTitle]="true"
                             [showEmail]="true"></dvtx-avatar>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </cdk-virtual-scroll-viewport>
    </div>
  </ng-template>
</ng-container>
