import {Injectable} from '@angular/core';

import {PartnerType} from '../models/partner-types/partner-type.interface';
import {PartnerTypes} from '../models/partner-types/partner-types.mock';
import {BehaviorSubject} from 'rxjs';


@Injectable()
export class PartnerTypeService {
  items: PartnerType [];
  typesSubject: BehaviorSubject<PartnerType[]>;

  constructor() {
    this.items = PartnerTypes;
    this.typesSubject = new BehaviorSubject<PartnerType[]>(this.items);
  }

  getPartnerTypes() {
    return this.typesSubject.asObservable();
  }

  create(newType: PartnerType) {
    if (!!newType && this.items.indexOf(newType) === -1) {
      this.items.push(newType);
      this.updateSubject();
    }
  }

  remove(partnerType) {
    // TODO find partner type and remove
  }

  updateSubject() {
    this.typesSubject.next(this.items);
  }

}

