import {NgModule} from '@angular/core';
import {MessageSettingsComponent} from './containers/message-settings/message-settings.component';
import {LayoutModule} from '@angular/cdk/layout';
import {InstantMessagingModule} from '../instant-messaging/instant-messaging.module';
import {SignatureModule} from '../../../signature/signature.module';
import {OrganizationModule} from 'app/modules/organization/organization.module';
import {ProcessTableModule} from '../../../workflow-engine/modules/process-table/process-table.module';
import {ActivityLogModule} from 'app/five-f/activity-log/activity-log.module';
import {PageSidebarModule} from 'app/shared/modules/page-sidebar/page-sidebar.module';
import {UserAccountModule} from 'app/shared/modules/user-account/user-account.module';
import {TooltipModule} from 'app/five-f/tooltip/tooltip.module';
import {FiltersContainerModule} from 'app/five-f/filters/filters-container.module';
import {InboxModule} from '../../inbox.module';

@NgModule({
  imports: [
    LayoutModule,
    InstantMessagingModule,
    SignatureModule,
    OrganizationModule,
    ProcessTableModule,
    ActivityLogModule,
    PageSidebarModule,
    UserAccountModule,
    TooltipModule,
    FiltersContainerModule
  ],
  declarations: [
    MessageSettingsComponent,
  ],
  exports: [
    InstantMessagingModule,
    MessageSettingsComponent
  ]
})
export class NotificationSettingsModule {
}
