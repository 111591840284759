import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './member.state';
import {
  AddressBookTableHelper
} from '../../../modules/address-book/modules/address-book-table/containers/address-book-table/address-book-table.helper';

export const stateKey = 'operator-member';
const getMemberState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getMemberEntities,
  selectAll: getAllMembers,
} = adapter.getSelectors(getMemberState);

export const loadingState = createSelector(
  getMemberState,
  (_state) => _state.loading
);

export const getMemberByOrganizationId = (oid: string) => createSelector(
  getAllMembers,
  (members) => {
    members.filter(m => m.organizationId === oid).sort(AddressBookTableHelper.sortByLastNameFirstName)
  }
);
