import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Organization} from 'app/models/organization.model';
import {DatevOauthService, DatevScope, DatevTokenType, DatevUserInfo} from 'app/+store/datev-oauth/datev-oauth.service';
import {DatevClient} from 'app/+store/datev/datev-client/datev-client';
import {Client} from 'app/+store/client/client';
import {distinctUntilKeyChanged, filter, first, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'dvtx-client-datev-login',
  templateUrl: './client-datev-login.component.html',
  styleUrls: ['./client-datev-login.component.scss']
})
export class ClientDatevLoginComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  private client$ = new BehaviorSubject<Client>(null);
  private _client: Client;

  public userLoading$: Observable<boolean>;
  public clientLoading$: Observable<boolean>;
  public datevUserInfo$: Observable<DatevUserInfo>;
  public isSignedIn$: Observable<boolean>;

  organization: Organization;
  public datevClient$: Observable<DatevClient>;

  @Input() private set client(c: Client) {
    this._client = c;
    this.client$.next(c);
  }

  constructor(private _store: Store<AppState>,
              private _dialog: MatDialog,
              private _viewContainerRef: ViewContainerRef,
              private _cdr: ChangeDetectorRef,
              private _router: Router,
              private _route: ActivatedRoute,
              private datevOauthService: DatevOauthService) {
  }

  ngOnInit() {
    this.userLoading$ = this.datevOauthService.loading;
    this.datevUserInfo$ = this.datevOauthService.userInfo;
    this.isSignedIn$ = this.datevOauthService.signedIn;
    this.clientLoading$ = this.datevOauthService.clientLoading;
    this.datevClient$ = this.datevOauthService.currentClient;

    // const organizationId = this._route.snapshot.params.orgId;
    this.client$
      .pipe(filter(c => !!c), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy))
      .subscribe(client => {
        this._initDatevClient(client);
      });
  }

  private async _initDatevClient(client) {
    if (!client) {
      console.error('DATEV: Client unset. Skipping...');
      return;
    }

    if (!client.clientId) {
      console.error('DATEV: Client ID unset. Skipping...');
      return;
    }

    // this.datevOauthService.me(DatevScope.Client, client.id);
    this.datevOauthService.fetchAuthorizedClient(client.id);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.client$.complete();
  }

  login() {
    if (!this._client || !this._client.clientId) {
      console.error('Client not set or invalid: ', this._client);
      return;
    }
    const redirectUrl = window.location.href;
    this.datevOauthService.login(redirectUrl, DatevTokenType.LongRunningClientBasedToken, this._client.id);
  }

  logout() {
    const clientId = this._client.id;
    this.datevOauthService.logoutClient(clientId);
  }
}
