import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './message.state';
import {MessageResourceType} from 'app/+store/message/message.interface';
import {Invitation, InvitationStatus} from 'app/+store/invitation/invitation.model';

export const stateKey = 'message';
const getMessageState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getMessageEntities,
  selectAll: getAllMessages,
} = adapter.getSelectors(getMessageState);

export const getInboxMessages = createSelector(
  getAllMessages,
  (entities) => entities.filter(message => {
    return !message.archivedAt && (message.resourceType === MessageResourceType.Message)
  })
);

export const getNotifications = createSelector(
  getAllMessages,
  (entities) => entities.filter(message => {
    return !message.archivedAt && notificationMatch(message);
  })
);

export const getUnreadMessages = createSelector(
  getAllMessages,
  (messages) => messages.filter(message => !message.readAt)
);

export const getNotificationsFromResource = (id: string) => createSelector(
  getNotifications,
  (notifications) => notifications.filter(notification => notification.resourceId === id)
);

const notificationMatch = (message) => {
  return message.resourceType === MessageResourceType.AuditProofFolder
    || message.resourceType === MessageResourceType.CollectorPublished
    || message.resourceType === MessageResourceType.CollectorUpdatedAttributes
    || message.resourceType === MessageResourceType.CollectorGroupCreated
    || message.resourceType === MessageResourceType.CollectorGroupDeleted
    || message.resourceType === MessageResourceType.CollectorItemCreated
    || message.resourceType === MessageResourceType.CollectorItemDeleted
    || message.resourceType === MessageResourceType.CollectorItemAssigned
    || message.resourceType === MessageResourceType.CollectorGroupAssigned
    || message.resourceType === MessageResourceType.CacStatusUpdate
    || message.resourceType === MessageResourceType.Comment
    || message.resourceType === MessageResourceType.Description
    || message.resourceType === MessageResourceType.DueDateChanged
    || message.resourceType === MessageResourceType.ParticipantRemoved
    || message.resourceType === MessageResourceType.DocumentUploaded
    || message.resourceType === MessageResourceType.ExternalMessage
    || message.resourceType === MessageResourceType.ExternalAccessReceived
    || message.resourceType === MessageResourceType.InstantMessage
    || message.resourceType === MessageResourceType.ParticipantAdded
    || message.resourceType === MessageResourceType.TaskUpdated
    || message.resourceType === MessageResourceType.TaskDocumentAdded
    || message.resourceType === MessageResourceType.TaskDocumentRemoved
    || message.resourceType === MessageResourceType.TaskResponsibleAssignment
    || message.resourceType === MessageResourceType.Task
    || message.resourceType === MessageResourceType.TaskAssignment
    || message.resourceType === MessageResourceType.TaskAssignmentAccepted
    || message.resourceType === MessageResourceType.TaskAssignmentRejected
    || message.resourceType === MessageResourceType.TaskApproved
    || message.resourceType === MessageResourceType.TaskDeclined
    || message.resourceType === MessageResourceType.TaskClosed
    || message.resourceType === MessageResourceType.TaskCommented
    || message.resourceType === MessageResourceType.TaskReopened
    || message.resourceType === MessageResourceType.Process
    || message.resourceType === MessageResourceType.ProcessStarted
    || message.resourceType === MessageResourceType.ProcessClosed
    || message.resourceType === MessageResourceType.ProcessOwnerChanged
    || message.resourceType === MessageResourceType.Reminded
    || message.resourceType === MessageResourceType.RequestReceived
    || message.resourceType === MessageResourceType.ThirdPartyDetails
    || message.resourceType === MessageResourceType.ContactVerified
    || message.resourceType === MessageResourceType.AcceptedMemberInvitation
    || message.resourceType === MessageResourceType.DeclinedMemberInvitation
    || message.resourceType === MessageResourceType.AcceptedBusinessPartnerInvitation
    || message.resourceType === MessageResourceType.DeclinedBusinessPartnerInvitation
};

export const inboxUnreadCount = createSelector(
  getMessageState,
  state => {
    const unread = state.unread - state.unreadUpdates;
    return unread > 0 ? unread : 0;
  }
);

export const getNotificationUnreadCount = createSelector(
  getMessageState,
  (state) => state.unreadUpdates
);

export const getInvitations = createSelector(
  getAllMessages,
  (entities) => entities.filter(message => {
    return message.resourceType === MessageResourceType.BusinessInvitation || message.resourceType === MessageResourceType.MemberInvitation
  })
);

export const getArchivedMessages = createSelector(
  getAllMessages,
  (entities) => entities.filter(message => !!message.archivedAt)
);

export const getDrafts = createSelector(
  getAllMessages,
  (entities) => entities.filter(message => message.resourceType === MessageResourceType.MessageDraft)
);

export const getSent = createSelector(
  getAllMessages,
  (entities) => entities.filter(message => message.resourceType === MessageResourceType.SentMessage)
);

export const getFavorites = createSelector(
  getAllMessages,
  (entities) => entities.filter(message => message.favorite)
);

export const getLoadingState = createSelector(
  getMessageState,
  state => state.loading
);

export const selectedMessage = createSelector(
  getMessageState,
  state => state.selected
);

export const unreadCount = createSelector(
  getMessageState,
  state => state.unread
);

export const invitationCount = createSelector(
  getInvitations,
  invitations => {
    return invitations.filter(inv => {
      const invitation = inv.payload as Invitation;
      return invitation.current_status === InvitationStatus.Pending;
    }).length
  }
);

export const getPendingInvitations = createSelector(
  getInvitations,
  (invitations) => {
    return invitations.filter(inv => {
      const invitation = inv.payload as Invitation;
      return invitation.current_status === InvitationStatus.Pending;
    })
  }
);
