import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {DmsFolder} from './dms-folder';

export interface State extends EntityState<DmsFolder> {
  selected?: DmsFolder;
  unread: number;
  loading: boolean;
  refresh: boolean;
}

export const adapter = createEntityAdapter<DmsFolder>({
  sortComparer: (l, r) => DmsFolder.sortByPath(l, r)
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  unread: null,
  loading: false,
  refresh: false
});
