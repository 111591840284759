import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {EmailValidator} from 'app/lib/validator';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {StringUtils} from 'app/lib/string_utils';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {Sorters} from 'app/lib/sorter/sorters';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogParticipantComponent } from 'app/shared/components/dialogs/confirmation-dialog-participant/confirmation-dialog-participant.component';
import { ProcessParticipant } from 'app/+store/process-participant/process-participant';

@Component({
  selector: 'dvtx-selector-autocomplete',
  templateUrl: './selector-autocomplete.component.html',
  styleUrls: ['./selector-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorAutocompleteComponent implements OnDestroy {
  private _onDestroy = new Subject<void>();
  @ViewChild('dropdown') public dropdown: CdkOverlayOrigin;

  public searchValue: string;
  public allData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public filteredPersons: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  showDropdown = false;
  showSelectedDropdown = false;
  _selectedProfile: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _selectedProfiles: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  _selectedProfilesEmails: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  searchTerm = '';
  searchTermIsEmail = false;
  @Input() maxToShow = 3;
  @Input() acceptTypedEmail = false;
  @Input() avatarSize = 'sm';
  @Input() responsibleCollectoRemove = false;
  @Input() contacts = true;
  _selectedProfilesIds: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  selectedData: any[];
  @Input() clients = false;
  @Input() set data(persons) {
    if (persons) {
      this.allData.next(persons);
      // this.filteredPersons.next(persons); 
      this.loadSelected();
    }
  };
  @Input() icon = 'add_circle';
  @Input() set selectedProfile(selectedProfile) {
    if (selectedProfile) {
      this._selectedProfile.next(selectedProfile);
      const combinedContacts = this.conbineContactsWithExternals([selectedProfile]);
      this.allData.next(combinedContacts);
    }
  };

  selectedIdMap = {};

  @Input() set selectedProfiles(selectedProfiles) {
    if (selectedProfiles) {
      this._selectedProfiles.next(selectedProfiles);
      let selectedProfileIds = null;
      if (this.contacts) {
        selectedProfileIds = selectedProfiles.map(a => a.contactId);
      } else if (this.clients) {
        selectedProfileIds = selectedProfiles.map(a => a.clientId);
      } else {
        selectedProfileIds = selectedProfiles.map(a => a.id);
      }
      if (this.allData.value) {
        this.selectedData = this.allData.value.filter(a => selectedProfileIds.includes(a.id));
      }
    }
    this._cdr.detectChanges();
  };

  @Input() title;
  @Input() multipeSelection = false;
  @Output() onSelection: EventEmitter<any> = new EventEmitter<any>(null);
  @Output() onUnSelection: EventEmitter<any> = new EventEmitter<any>(null);

  get selectedProfile() {
    return this._selectedProfile.value;
  }

  get selectedProfiles() {
    return this._selectedProfiles.value;
  }

  @Input() disabled = false;
  @Input() enableConfirmation = false;
  @Input() enableRecursive = true;
  @Input() enableAdmin = true;

  @ViewChild('searchSelectInput', {read: ElementRef}) searchSelectInput: ElementRef;
  @ViewChild('firstLi') firstLi: ElementRef;

  constructor(private avatarService: AvatarService, public _cdr: ChangeDetectorRef,
    private dialog: MatDialog) {
  }
  
  loadSelected() {
    if (this.selectedProfiles) {
      let selectedProfileIds = null;
      if (this.contacts) {
        selectedProfileIds = this.selectedProfiles.map(a => a.contactId);
      } else if (this.clients) {
        selectedProfileIds = this.selectedProfiles.map(a => a.clientId);
      } else {
        selectedProfileIds = this.selectedProfiles.map(a => a.id);
      }
      if (this.allData.value) {
        this.selectedData = this.allData.value.filter(a => selectedProfileIds.includes(a.id));
      }
    }
    this._cdr.detectChanges();
  }

  openConfirmationSelectPerson(person, $event) {
    if (!person || !person.id) return;

    if (this.disabled) {
      return false;
    }

    const id = person.id;

    // Use selectPerson instead.
    if (!this.enableConfirmation) {
      return;
    }

    $event.preventDefault();

    if (!this.selectedIdMap[id]) {
      this.dialog.open(ConfirmationDialogParticipantComponent, {
        disableClose: true,
        data: {
          title: 'GENERAL.ADD_PARTICIPANT',
          message: 'GENERAL.ADD_PARTICIPANT_CONFIRMATION',
          submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
          cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
          person: person,
          adminOption: this.enableAdmin,
          recursiveOption: this.enableRecursive,
          onSubmitAction: (recursive, admin) => {
            const matChecked = { checked: true };
            this.confirmPerson(person, matChecked, recursive, admin);
          },
          onCancelAction: () => {
          }
        }
      });
    } else {
      this.dialog.open(ConfirmationDialogParticipantComponent, {
        data: {
          title: 'GENERAL.REMOVE_PARTICIPANT',
          message: 'GENERAL.REMOVE_PERSON_PARTICIPANT',
          submitButtonTitle: 'GENERAL.REMOVE_ACTION',
          cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
          color: '#db0000',
          remove: true,
          person: person,
          onSubmitAction: () => {
            const matChecked = { checked: false };
            this.confirmPerson(person, matChecked);
          },
          onCancelAction: () => {
          }
        }
      });
    }
  }

  selectPerson(person, $event: any = null) {
    if (!person || !person.id) return;

    if (this.disabled) {
      return false;
    }

    // Use confirmPerson/openConfirmationSelectPerson instead.
    if (this.enableConfirmation) {
      return;
    }

    const id = person.id;
    const foundedPerson = this.allData.value.find(p => person && p.id === id);
    if (this.searchTerm && !this.searchTermIsEmail && !foundedPerson) {
      return false;
    }
      this.showDropdown = false;
      this.onSelection.emit(person);  
  }

  private confirmPerson(person, $event: any = null, recursive = false, admin = false) {
    if (!person || !person.id) return;

    if (this.disabled) {
      return false;
    }
    const id = person.id;
    const foundedPerson = this.allData.value.find(p => person && p.id === id);
    if (this.searchTerm && !foundedPerson) {
      return false;
    }
    this.showDropdown = false;
    this.onSelection.emit({id: id, recursive: recursive, admin: admin});
  }

  /**
   * Handles the key down event with MatSelect.
   * Allows e.g. selecting with enter key, navigation with arrow keys, etc.
   * @param {KeyboardEvent} event
   * @private
   */
  _handleKeydown(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }
  }

  onInputChange(value) {
    // reset search on empty text
    // if (!value) return;
    this.filteredPersons.next(this.allData.value.filter(p => !!p && p.id));
    this.searchValue = value.trim ? value.trim().toLowerCase() : '';
    this.searchTerm = this.searchValue;
    if (!this.searchTerm || !this.allData) {
      return;
    }

    this.filteredPersons.next(
      this.filteredPersons.value.filter(person => {
        if (!person) return false;

        const firstNameMatch = person && person.name && person.name.toLowerCase().indexOf(this.searchTerm) > -1;
        return firstNameMatch;
      })
    )
  }

  onDropdownOpen() {
    if (this.selectedData && this.selectedData.length > 0) {
      const ids = this.selectedData.map(a => a.id);
      this.filteredPersons.next(
        this.allData.value.filter((s) => !!s && !ids.includes(s.id))
      );
    } else {
      this.filteredPersons.next(this.allData.value.filter(p => !!p && p.id));
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.allData.complete();
    this.filteredPersons.complete();
    this._selectedProfile.complete();
    this._selectedProfiles.complete();
    this._selectedProfilesIds.complete();
  }

  conbineContactsWithExternals(selectedProfiles) {
    const combinedContacts = this.allData.value.concat(selectedProfiles);
    for (let i = 0; i < combinedContacts.length; ++i) {
      for (let j = i + 1; j < combinedContacts.length; ++j) {
        if (combinedContacts[i].id === combinedContacts[j].id)
          combinedContacts.splice(j--, 1);
      }
    }

    return combinedContacts;
  }

  public openSelector() {
    this.showDropdown = true;
    this.onDropdownOpen();
    this._cdr.detectChanges();
    if (this.firstLi && this.firstLi.nativeElement) {
      setTimeout(() => {
        this.firstLi.nativeElement.focus();
      }, 200);
    }
  }
}
