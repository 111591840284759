import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CollectAndDelegateWorkflowModule} from './modules/collect-and-delegate-workflow/collect-and-delegate-workflow.module';
import {DocumentTemplateContainerComponent} from './containers/document-template-container/document-template-container.component';
import {LogoUploadContainerComponent} from './containers/logo-upload-container/logo-upload-container.component';
import {UploadDialogComponent} from './components/upload-dialog/upload-dialog.component';
import { AlertsModule } from 'app/modules/alerts/alerts.module';

@NgModule({
    imports: [
        CommonModule,
        CollectAndDelegateWorkflowModule,
        AlertsModule
    ],
    declarations: [
        DocumentTemplateContainerComponent,
        LogoUploadContainerComponent,
        UploadDialogComponent
    ],
    exports: [
        CollectAndDelegateWorkflowModule,
        DocumentTemplateContainerComponent,
        LogoUploadContainerComponent
    ]
})
export class CustomizationModule {
}
