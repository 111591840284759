  <div class="d-flex w-100 align-items-center justify-content-between" style="max-width: 500px;">
    <p class="h2">{{ 'PASSWORD.CHANGE_PASSWORD' | translate }}</p>
    <button *ngIf="editDisabled" mat-raised-button color="primary" (click)="editEnableDiable()">{{'GENERAL.EDIT_ACTION' | translate}}</button>
  </div>
<div class="flex" *ngIf="!editDisabled">
    <form [formGroup]="form">
      <div class="flex password">
        <mat-form-field>
          <input matInput [formControlName]="'currentPassword'" #currentPassword [placeholder]="'PASSWORD.ACTUAL' | translate"  [type]="currentPasswordVisbile" (focus)="passwordConfirmatioShow(false);passwordShow(false);" required>
          <mat-icon *ngIf="currentPasswordVisbile === fieldType.Password" (click)="currentPasswordShow(true)" [matTooltip]="'PASSWORD.VISIBLE' | translate"  class="vis-icon">visibility</mat-icon>
          <mat-icon *ngIf="currentPasswordVisbile === fieldType.Text" (click)="currentPasswordShow(false)" [matTooltip]="'PASSWORD.VISIBILITY_OFF' | translate"  class="vis-icon">visibility_off</mat-icon>
        </mat-form-field>
      </div>
      <mat-error *ngIf="wrongPasswordError">{{wrongPasswordError}}</mat-error>

      <div class="flex password">
        <mat-form-field>
          <input matInput [formControlName]="'newPassword'" (keyup)="verifyPassword()" [placeholder]="'PASSWORD.NEW' | translate" [type]="passwordVisbile" (focus)="passwordConfirmatioShow(false);currentPasswordShow(false);" required>
          <mat-icon (click)="passwordShow(true)" *ngIf="passwordVisbile === fieldType.Password" [matTooltip]="'PASSWORD.VISIBLE' | translate"  class="vis-icon">visibility</mat-icon>
          <mat-icon (click)="passwordShow(false)" *ngIf="passwordVisbile === fieldType.Text" [matTooltip]="'PASSWORD.VISIBILITY_OFF' | translate"  class="vis-icon">visibility_off</mat-icon>
        </mat-form-field>
      </div>

      <mat-error class="text-danger" *ngIf="form.get('newPassword').errors?.required && form.get('newPassword').touched">
        {{'PASSWORD.ENTER_PASSWORD' | translate}}
      </mat-error>

      <span *ngIf="verifyPasswordResponse && verifyPasswordResponse.level"
        [class]="verifyPasswordResponse.level">
          {{ verifyPasswordResponse.message | translate }}
      </span>

      <div class="flex password">
        <mat-form-field>
          <input matInput [formControlName]="'newPasswordConfirmation'" [placeholder]="'PASSWORD.NEW_CONFIRMATION' | translate" [type]="passwordConfirmationVisbile" (focus)="passwordShow(false);currentPasswordShow(false);" required>
          <mat-icon *ngIf="!form.errors?.passwordsNoMatch && form.get('newPasswordConfirmation').touched" class="check">
              check
            </mat-icon>
          <mat-icon  *ngIf="passwordConfirmationVisbile === fieldType.Password" (click)="passwordConfirmatioShow(true)" matTooltip="{{ 'PASSWORD.VISIBLE' | translate }}"  class="vis-icon">visibility</mat-icon>
          <mat-icon  *ngIf="passwordConfirmationVisbile === fieldType.Text" (click)="passwordConfirmatioShow(false)" matTooltip="{{ 'PASSWORD.VISIBILITY_OFF' | translate }}"  class="vis-icon">visibility_off</mat-icon>
        </mat-form-field>
      </div>

      <mat-error class="text-danger" *ngIf="form.errors?.passwordsNoMatch">
        {{'PASSWORD.PASSWORDS_DONT_MATCH' | translate}}
      </mat-error>

      <dvtx-two-factor-verification
        *ngIf="twoFactorAuthEnabled | async"
        [form]="form"
        [error]="wrongCodeError"
        >
      </dvtx-two-factor-verification>
      <div class="d-flex w-100 justify-content-end">
        <div>
          <button type="reset" mat-button color="warn" (click)="editEnableDiable()" class="wide">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
        </div>
        <div>
          <button mat-raised-button color="primary" (click)="submit()" [disabled]="form.invalid">{{ 'PASSWORD.CHANGE_PASSWORD' | translate }}</button>
        </div>
      </div>
  </form>

  <div class="info">
    <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200">info</mat-icon>
    <p>{{'PASSWORD.STRONG_PASSWORD_POLICY' | translate}}</p>
  </div>
</div>

<div *ngIf="editDisabled">
    <mat-form-field>
        <input matInput [placeholder]="'PASSWORD.ACTUAL' | translate"  type="password" disabled value="1345645" type="password" >
      </mat-form-field>
    <mat-form-field>
      <input matInput [placeholder]="'PASSWORD.NEW' | translate"  value="1345645" type="password" disabled >
    </mat-form-field>
    <mat-form-field>
      <input matInput [placeholder]="'PASSWORD.NEW_CONFIRMATION' | translate"  value="1345645" type="password" disabled >
    </mat-form-field>
</div>

