<div class="mat-dialog-inner-wrap dialog-confirmation">
  <div class="mat-dialog-header dvtx-bg-color dvtx-bg-cool-grey-25">
    <h1 mat-dialog-title>
      {{ 'DMS.DELETE_FOLDER_ACTION' | translate }}
    </h1>
  </div>

  <div class="mat-dialog-footer d-flex justify-content-end">
      <button mat-button mat-dialog-close class="mr-1">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
      <button mat-raised-button color="warn" mat-dialog-close="ok">{{ 'GENERAL.CONFIRM_ACTION' | translate }}</button>
  </div>
</div>
