import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './verified-user.state';

export const stateKey = 'verifiedUser';
const getVerifiedUserState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getVerifiedUserEntities,
  selectAll: getAllVerifiedUsers,
} = adapter.getSelectors(getVerifiedUserState);

export const getTwoFactorStatusOfUser = (email) => createSelector(
  getVerifiedUserEntities,
  (users) => {
    if (!email) return false;
    return users[email] && users[email].hasTwoFactorEnabled
  });
