import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ProcessAttributeEffects} from './process-attribute.effects';
import {ProcessAttributeService} from './process-attribute.service';
import {reducer} from './process-attribute.reducer';
import {stateKey} from './process-attribute.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ProcessAttributeEffects])
  ],
  providers: [
    ProcessAttributeEffects,
    ProcessAttributeService
  ]
})
export class ProcessAttributeStoreModule {
}
