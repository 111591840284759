import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {TaskStatus} from './task-status';
import * as dayjs from 'dayjs';

export interface State extends EntityState<TaskStatus> {
  selected?: TaskStatus;
  loading: boolean;
}

export const adapter = createEntityAdapter<TaskStatus>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
