import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TaskResourceEffects } from './task-resource.effects';
import { TaskResourceService } from './task-resource.service';
import { reducer } from './task-resource.reducer';
import { stateKey } from './task-resource.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([TaskResourceEffects])
  ],
  providers: [
    TaskResourceEffects,
    TaskResourceService
  ]
})
export class TaskResourceStoreModule {
}
