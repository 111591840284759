import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Favorite} from 'app/+store/favorite/favorite';
import {FavoriteActions, FavoriteSelectors} from 'app/+store/favorite';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {switchMap} from 'rxjs/operators';
import {ProcessActions} from 'app/+store/process';

/**
 * Favorite star component to be used inside process tables.
 */
@Component({
  selector: 'dvtx-favorite-toggle',
  templateUrl: './favorite-toggle.component.html',
  styleUrls: ['./favorite-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteToggleComponent implements OnInit, OnDestroy {
  private _id: string = null;

  private id$ = new BehaviorSubject<string>(null);

  public favorite$: Observable<Favorite>;
  public loading$: Observable<boolean>;

  @Input() set id(id: string) {
    if (this._id !== id) {
      this._id = id;
      this.id$.next(id);
    }
  }

  constructor(private _store: Store<AppState>,
              private _cdr: ChangeDetectorRef,
              private _zone: NgZone) { }

  ngOnInit() {
    this.loading$ = this._store.select(FavoriteSelectors.getLoadingState);
    this.favorite$ = this.id$.pipe(switchMap(id => {
      return this._store.select(FavoriteSelectors.favoriteByResourceId(id));
    }));
  }

  ngOnDestroy() {
    this.id$.complete();
  }


  public remove($event, favorite: Favorite): void {
    $event.stopPropagation();
    if (!favorite || !favorite.id) {
      return;
    }
    this._store.dispatch(new FavoriteActions.Remove(favorite.id))
  }

  public add($event): void {
    $event.stopPropagation();

    if (!this._id) {
      return;
    }
    this._store.dispatch(new ProcessActions.AddFavorite(null, this._id));
  }
}
