import * as autoSaverActions from '../actions/autosaver.actions';

export type AutoSaverState = boolean;
export const initialState = false;

export function autosaverReducer(state: boolean = initialState, action: autoSaverActions.Actions): boolean {
  switch (action.type) {
    case autoSaverActions.AUTO_SAVING:
      return true;
    case autoSaverActions.AUTO_SAVED:
      return initialState;

    default: {
      return state;
    }
  }
};

