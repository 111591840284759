
import {CollectorItemActions, CollectorItemActionTypes} from './collector-item.actions';
import {adapter, initialState, State} from './collector-item.state';
import {CollectorItem} from './collector-item';
import {Update} from '@ngrx/entity';

export function reducer(state = initialState, action: CollectorItemActions): State {
  const current = state.selected;
  switch (action.type) {
    /*
      LOAD ALL REDUCERS
    */
    case CollectorItemActionTypes.LoadAllSuccess:
      return adapter.setAll(action.items, {
        ...state,
        selected: null,
        loading: false,
      });
    case CollectorItemActionTypes.UpsertAllSuccess:
      return adapter.upsertMany(action.items, {
        ...state,
        selected: null,
        loading: false,
      });

    /*
      CREATE REDUCERS
    */
    case CollectorItemActionTypes.Create:
      return {
        ...state,
        creating: true
      };
    case CollectorItemActionTypes.CreateSuccess:
      return adapter.upsertOne(action.item, {
        ...state,
        selected: action.item,
        creating: false,
      });
    case CollectorItemActionTypes.CreateFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        creating: false,
      };

    /*
      SAVE REDUCERS
    */
    case CollectorItemActionTypes.Save:
      return {
        ...state,
        updating: true
      };
    case CollectorItemActionTypes.SaveSuccess:
    case CollectorItemActionTypes.LoadOneSuccess:
    case CollectorItemActionTypes.StatusSuccess:
      return adapter.upsertOne(action.item, {
        ...state,
        selected: action.item,
        updating: false,
      });
    case CollectorItemActionTypes.SaveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        updating: false,
      };

    /*
      REMOVE REDUCERS
    */
    case CollectorItemActionTypes.Remove:
      return {
        ...state,
        removing: true
      };
    case CollectorItemActionTypes.RemoveSuccess:
      return adapter.removeOne(action.item.id, {
        ...state,
        selected: (current && current.id === action.item.id) ?  null : current,
        removing: false,
      });
    case CollectorItemActionTypes.RemoveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        removing: false,
      };

    /*
      STATUS REDUCERS
    */
    case CollectorItemActionTypes.Status:
      return {
        ...state,
        updating: true
      };
    case CollectorItemActionTypes.StatusFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        updating: false,
      };

    /*
      SELECT REDUCERS
    */
    case CollectorItemActionTypes.Select:
      return {
        ...state,
        selected: state.entities[action.id]
      };

    case CollectorItemActionTypes.Copy:
      return {
        ...state,
        selected: null,
        loading: false
      };
    case CollectorItemActionTypes.CopySuccess:
      return adapter.addOne(action.item, {
        ...state,
        selected: null,
        loading: false,
      });
    case CollectorItemActionTypes.CopyFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };

    case CollectorItemActionTypes.Reset:
      return Object.assign({}, initialState);

    case CollectorItemActionTypes.UpdateAllSuccess:
      const updates: Update<CollectorItem>[] = action.items.map(item => {
        return {id: item.id, changes: item};
      });
      return adapter.updateMany(updates, {
        ...state,
        selected: null,
        loading: false,
      });

    /**
     * Updates status or due date by category.
     */
    case CollectorItemActionTypes.UpdateByCategory:
      const category = action.category;
      const updated = [];
      action.items.forEach(i => {
        const item: CollectorItem = Object.assign({}, i) as CollectorItem;
        let updatedItem: Update<CollectorItem>;
        switch (action.attribute) {
          case 'due_date':
            item.dueDate = category.dueDate;
            item.isOverdued = category.isOverdued;
            item.updatedAt = category.updatedAt;
            CollectorItem.updateTrackById(item);
            updatedItem = {id: item.id, changes: item};
            updated.push(updatedItem);
            break;
          case 'status':
            item.status = category.status;
            item.updatedAt = category.updatedAt;
            CollectorItem.updateTrackById(item);
            updatedItem = {id: item.id, changes: item};
            updated.push(updatedItem);
            break;
            case 'archived_at':
              item.archivedAt = category.archivedAt;
              item.updatedAt = category.updatedAt;
              CollectorItem.updateTrackById(item);
              updatedItem = {id: item.id, changes: item};
              updated.push(updatedItem);
              break;
        }
      });
      return adapter.updateMany(updated, {
        ...state,
        selected: null,
        loading: false,
      });
  }
  return state;
}
