import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {UserSignature} from './user-signature';
import * as dayjs from 'dayjs';


export interface State extends EntityState<UserSignature> {
  current?: UserSignature
  loading: boolean
}

export const adapter = createEntityAdapter<UserSignature>({
  sortComparer: (l, r) => -(dayjs(r.createdAt).diff(dayjs(l.createdAt)))
});


export const initialState: State = adapter.getInitialState({
  current: null,
  loading: false,
});
