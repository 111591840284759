import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {TaskEvent} from './task-event';
import {TaskActivityType} from './task-event.interface';

export class TaskEventBuilder implements IApiResourceBuilder<TaskEvent> {
  fromResponse(data): TaskEvent {
    const event = new TaskEvent(
      data.id,
      data.attributes.task_id,
      data.attributes.performer,
      data.attributes.performer_email,
      data.attributes.changeset,
      data.attributes.resource,
      data.attributes.created_at);
    event.activity = this._setActivity(data.attributes.activity);
    return event;
  }

  toRequest(_: TaskEvent) {
    return null;
  }

  private _setActivity(activity) {
    switch (activity) {
      case 'created':
        return TaskActivityType.Created;
      case 'approved':
        return TaskActivityType.Approved;
      case 'declined':
        return TaskActivityType.Declined;
      case 'document_added':
        return TaskActivityType.DocumentAdded;
      case 'document_removed':
        return TaskActivityType.DocumentRemoved;
      case 'attributes_updated':
        return TaskActivityType.Updated;
      case 'deleted':
        return TaskActivityType.Deleted;
      case 'completed':
        return TaskActivityType.Completed;
      case 'archived':
        return TaskActivityType.Archived;
      case 'canceled':
        return TaskActivityType.Canceled;
      case 'due_date_changed':
        return TaskActivityType.DueDateChanged;
      case 'priority_changed':
        return TaskActivityType.PriorityChanged;
      case 'status_changed':
        return TaskActivityType.StatusChanged;
      case 'create_assignee':
        return TaskActivityType.CreateAssignee;
      case 'destroy_assignee':
        return TaskActivityType.DestroyAssignee;
      case 'accept_assignment':
        return TaskActivityType.AcceptAssignment;
      case 'reject_assignment':
        return TaskActivityType.RejectAssignment;
      case 'commented':
        return TaskActivityType.Commented;
      case 'label_added':
        return TaskActivityType.LabelAdded
      case 'label_removed':
        return TaskActivityType.LabelRemoved;
      default:
        console.error('TaskEventBuilder: SETTING DEFAULT ACTIVITY TYPE', activity);
        return TaskActivityType.Updated;
    }
  }
}
