import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {
  MessageBuilder,
  MessageDraftBuilder,
  MessageResourceStatBuilder,
  MessageStatBuilder,
  UnreadResourceBuilder
} from './message.builder';
import {Message, MessageResourceStat, UnreadResource} from './message';
import {IMessageParams, IMessageStat, MESSAGE_PAGE_SIZE, MessageType, Update} from './message.interface';
import {MsgApiResourceService} from './msg-api-resource.service';

@Injectable()
export class MessageService {
  readonly BASE_PATH = 'api/v1/messages';

  constructor(private _http: MsgApiResourceService) {}

  getAll(params: IMessageParams): Observable<Message[]> {
    const queryParams = this._queryParams(params);
    const builder = new MessageBuilder();
    return <Observable<Message[]>>this._http.get<MessageBuilder, Message>(builder, `${this.BASE_PATH}/messages${queryParams}`);
  }

  getOne(id: string): Observable<Message> {
    const builder = new MessageBuilder();
    return <Observable<Message>>this._http.get<MessageBuilder, Message>(builder, `${this.BASE_PATH}/messages/${id}`);
  }

  getStats() {
    const builder = new MessageStatBuilder();
    return <Observable<IMessageStat>>this._http.get<MessageStatBuilder, IMessageStat>(builder, `${this.BASE_PATH}/stats`);
  }

  deleteMessage(message): Observable<Message> {
    const builder = new MessageBuilder();
    return <Observable<Message>>this._http.delete<MessageBuilder, Message>(builder, `${this.BASE_PATH}/messages`, message);
  }

  deleteDraft(message): Observable<Message> {
    const builder = new MessageBuilder();
    return <Observable<Message>>this._http.delete<MessageBuilder, Message>(builder, `${this.BASE_PATH}/drafts`, message);
  }

  saveMessage(message: Message): Observable<Message> {
    const builder = new MessageDraftBuilder();
    if (message.id) {
      return <Observable<Message>>this._http.update<MessageDraftBuilder, Message>(builder, `${this.BASE_PATH}/drafts`, message);
    } else {
      return <Observable<Message>>this._http.create<MessageDraftBuilder, Message>(builder, `${this.BASE_PATH}/drafts`, message);
    }
  }

  sendMessage(message: Message): Observable<Message> {
    const builder = new MessageDraftBuilder();
    const payload = builder.toRequest(message);
    return <Observable<Message>>this._http.post<MessageDraftBuilder, Message>(builder, `${this.BASE_PATH}/drafts/${message.id}/send`, payload);
  }

  toggleArchive(message: Message): Observable<Message> {
    const builder = new MessageBuilder();
    return <Observable<Message>>this._http.post<MessageBuilder, Message>(builder, `${this.BASE_PATH}/messages/${message.id}/archive`, {});
  }

  toggleFavorite(message: Message): Observable<Message> {
    const builder = new MessageBuilder();
    return <Observable<Message>>this._http.post<MessageBuilder, Message>(builder, `${this.BASE_PATH}/messages/${message.id}/favorite`, {});
  }

  toggleRead(message: Message | Update): Observable<Message> {
    const builder = new MessageBuilder();
    return <Observable<Message>>this._http.post<MessageBuilder, Message>(builder, `${this.BASE_PATH}/messages/${message.id}/read`, {});
  }

  toggleCollectoElementRead(id: string): Observable<Message> {
    const builder = new MessageBuilder();
    return <Observable<Message>>this._http.post<MessageBuilder, Message>(builder, `${this.BASE_PATH}/messages/${id}/read`, {});
  }

  getDrafts(params: IMessageParams): Observable<Message[]> {
    const builder = new MessageDraftBuilder();
    const queryParams = this._queryParams(params);
    return <Observable<Message[]>>this._http.get<MessageDraftBuilder, Message>(builder, `${this.BASE_PATH}/drafts${queryParams}`);
  }

  getSent(params: IMessageParams): Observable<Message[]> {
    const builder = new MessageDraftBuilder();
    const queryParams = this._queryParams(params);
    return <Observable<Message[]>>this._http.get<MessageDraftBuilder, Message>(builder, `${this.BASE_PATH}/send${queryParams}`);
  }

  private _queryParams(params: IMessageParams) {
    const perPage = params['per_page'] || MESSAGE_PAGE_SIZE;
    let queryParams = `?per_page=${perPage}`;
    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key) && !!params[key]) {
          queryParams = `${queryParams}&${key}=${params[key]}`;
        }
      }
    }
    return queryParams;
  }

  getUnseenDocuments(): Observable<UnreadResource[]> {
    const builder = new UnreadResourceBuilder();
    return <Observable<UnreadResource[]>>this._http.get<UnreadResourceBuilder, UnreadResource>(builder, `${this.BASE_PATH}/resources?documents_only=true`);
  }

  getUnreadComments(): Observable<UnreadResource[]> {
    const builder = new UnreadResourceBuilder();
    return <Observable<UnreadResource[]>>this._http.get<UnreadResourceBuilder, UnreadResource>(builder, `${this.BASE_PATH}/resources?comments_only=true`);
  }

  getUnreadCounts(grouped_by = 'resource_id'): Observable<UnreadResource[]> {
    const builder = new UnreadResourceBuilder();
    return <Observable<UnreadResource[]>>this._http.get<UnreadResourceBuilder, UnreadResource>(builder, `${this.BASE_PATH}/messages/unread_counts?grouped_by=${grouped_by}`);
  }

  /**
   * Returns notification counts for the given setup.
   * Used in Collecto dashboard, tasks or CAV/CAC to show activity icons for new messages.
   *
   * @param resourceId UUID of process or static value 'tasks'. Restricts the payload to 'tasks' related resource stats or a specific process.
   * @param grouped_by 'resource_id' or 'follow_up_id': Resource id returns the payload by according main resource, 'follow_up_id' returns the payload
   *                   by follow up/secondary resource e.g. Collecto items (follow up) of a Collecto (main resource).
   * @param recursive Needed in recursive dashboards like CAC or CAV to show changes of sub workflows.
   */
  getMessageResourceStats(resourceId: string, grouped_by = 'resource_id', recursive = false): Observable<MessageResourceStat[]> {
    const builder = new MessageResourceStatBuilder();
    return <Observable<MessageResourceStat[]>>this._http.get<MessageResourceStatBuilder, MessageResourceStat>(builder, `${this.BASE_PATH}/resources?grouped_by=${grouped_by}&resource_id=${resourceId}&recursive=${recursive}`);
  }

  readMessagesForResource(id, findResourceBy = 'resource_id'): Observable<UnreadResource> {
    const builder = new UnreadResourceBuilder();
    return <Observable<UnreadResource>>this._http.post<UnreadResourceBuilder, UnreadResource>(builder, `${this.BASE_PATH}/resources/${id}/read?resource_id_type=${findResourceBy}`, {});
  }

  readMessagesForResourceDocuments(id, findResourceBy = 'resource_id'): Observable<UnreadResource> {
    const builder = new UnreadResourceBuilder();
    return <Observable<UnreadResource>>this._http.post<UnreadResourceBuilder, UnreadResource>(builder, `${this.BASE_PATH}/resources/${id}/read?documents_only=true`, {});
  }

  readMessagesForResourceComments(id, findResourceBy = 'resource_id'): Observable<UnreadResource> {
    const builder = new UnreadResourceBuilder();
    return <Observable<UnreadResource>>this._http.post<UnreadResourceBuilder, UnreadResource>(builder, `${this.BASE_PATH}/resources/${id}/read?comments_only=true`, {});
  }

  markAllRead(type: MessageType): Observable<Message[]> {
    const builder =  new MessageBuilder();
    return <Observable<Message[]>>this._http.postAll<MessageBuilder, Message>(builder, `${this.BASE_PATH}/mark_all_read?type=${type}`, {});
  }

  markAllFilterMatchesRead(params: IMessageParams): Observable<Message[]> {
    const queryParams = this._queryParams(params);
    const builder =  new MessageBuilder();
    return <Observable<Message[]>>this._http.postAll<MessageBuilder, Message>(builder, `${this.BASE_PATH}/mark_all_read${queryParams}`, {});
  }

  markAllArchivedMatchesRead(params: IMessageParams): Observable<Message[]> {
    const queryParams = this._queryParams(params);
    const builder =  new MessageBuilder();
    return <Observable<Message[]>>this._http.postAll<MessageBuilder, Message>(builder, `${this.BASE_PATH}/mark_all_archive${queryParams}`, {});
  }
}
