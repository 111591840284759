import {ContactTypes} from './address-book-table.model';

export const DEFAULT_FILTER_OPTIONS = [{
  value: ContactTypes.All,
  title: 'ADDRESSBOOK.FILTER_ALL',
  id: 0
}, {
  value: ContactTypes.Person,
  title: 'ADDRESSBOOK.FILTER_PERSON',
  id: 1
}, {
  value: ContactTypes.Member,
  title: 'ADDRESSBOOK.FILTER_MEMBER',
  id: 2
}];

export const PERSON_FILTER_OPTIONS = [{
  value: ContactTypes.All,
  title: 'ADDRESSBOOK.FILTER_ALL',
  id: 0
}, {
  value: ContactTypes.Person,
  title: 'ADDRESSBOOK.FILTER_PERSON',
  id: 1
}, {
  value: ContactTypes.Member,
  title: 'ADDRESSBOOK.FILTER_MEMBER',
  id: 2
}];
