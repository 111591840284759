import {RoleActions, RoleActionTypes} from './role.actions'
import {adapter, initialState, State} from './role.state'

export function reducer(state = initialState, action: RoleActions): State {
  switch (action.type) {
    case RoleActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      }
    case RoleActionTypes.LoadAllSuccess:
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
      })
    case RoleActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false
      }
    // case RoleActionTypes.AddSuccess:
    //   return adapter.addOne(action.role, {
    //     ...state,
    //     loading: false
    //   })
    case RoleActionTypes.Create:
      return {
        ...state,
        loading: true
      }
    case RoleActionTypes.CreateSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
      })
    case RoleActionTypes.Delete:
      return {
        ...state,
        loading: true
      }
    case RoleActionTypes.DeleteSuccess:
      return adapter.removeOne(action.roleId, {
        ...state,
        loading: false
      })
    // case RoleActionTypes.RemoveSuccess:
    //   return adapter.removeOne(action.roleId, {
    //     ...state,
    //     loading: false
    //   })
  }
  return state
}
