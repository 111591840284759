import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {DocumentPreview} from './document-preview';

export class DocumentPreviewBuilder implements IApiResourceBuilder<DocumentPreview> {
  fromResponse(data): DocumentPreview {
    return new DocumentPreview(
      data.id,
      data.attributes.page_number,
      data.attributes.url);
  }

  toRequest(cmsArticle: DocumentPreview) {
    return null;
  }
}

export class DocumentPreviewMetaObjectBuilder implements IApiResourceBuilder<DocumentPreview> {
  fromResponse(data): DocumentPreview {
    return new DocumentPreview(
      data.id,
      data.attributes.page_number,
      data.attributes.url);
  }

  toRequest(cmsArticle: DocumentPreview) {
    return null;
  }
}
