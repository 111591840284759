import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {IResource} from 'app/shared/modules/api/models/resource.interface';
import * as model from '../operator/user/user';

export class BasicAccountRequest implements IResource {
  readonly type = 'sales_basic_account_requests';
  constructor(public id: string,
              public inviterName: string,
              public message: string,
              public createdAt: Date,
              public updatedAt: Date) {}
}

export class SalesUser implements IResource {
  readonly type = 'user_accounts';
  testAccount = false;
  lastSignInAt: Date;
  hasJap = false;
  hasKap = false;
  hasPc = false;
  hasSignature = false;
  authProvider: model.Operator.UserAuthProviderType = 'credentials';

  _roles = [];
  hasOtp = false;
  set roles(roles: string[]) {
    this._roles = roles || [];
    this.hasJap = this.hasRole('jap');
    this.hasKap = this.hasRole('kap');
    this.hasPc = this.hasRole('pc');
    this.hasSignature = this.hasRole('signature');
  }

  get roles() {
    return this._roles;
  }

  constructor(public id: string,
              public email: string,
              public firstName: string,
              public lastName: string,
              public basicAccount,
              public createdAt: Date,
              public confirmedAt: Date,
              public lockedAt: Date) {}

  hasRole(role) {
    return !!this.roles.find(_role => _role === role);
  }
}

export class SalesUserBuilder implements IApiResourceBuilder<SalesUser> {
  fromResponse(data): SalesUser {
    const user = new SalesUser(
      data.id,
      data.attributes.email,
      data.attributes.first_name,
      data.attributes.last_name,
      data.attributes.basic_account,
      data.attributes.created_at,
      data.attributes.confirmed_at,
      data.attributes.locked_at,
    );
    user.testAccount = data.attributes.test_account;
    user.lastSignInAt = data.attributes.last_sign_in_at;
    user.roles = data.attributes.roles;
    user.hasOtp = data.attributes.otp_enabled;
    user.authProvider = data.attributes.auth_provider;
    return user;
  }

  toRequest(_: SalesUser) {
    return null;
  }
}

export class BasicAccountRequestBuilder implements IApiResourceBuilder<BasicAccountRequest> {
  fromResponse(data): BasicAccountRequest {
    return new BasicAccountRequest(
      data.id,
      data.attributes.inviter_name,
      data.attributes.message,
      data.attributes.created_at,
      data.attributes.updated_at);
  }

  toRequest(request: BasicAccountRequest) {
    return {
      data: {
        id: null,
        type: request.type,
        attributes: {
          inviter_name: request.inviterName,
          message: request.message
        }
      }
    };
  }
}
