import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import * as modelInterface from './tenant.interface';
import * as model from './tenant';
import * as organizationModel from '../organization/organization';
import * as build from './tenant.builder';
import * as organizationBuild from '../organization/organization.builder';
import {FileApiResourceService} from '../../process-artifact/file-api-resource.service';
import {EnvService} from 'app/shared/modules/api-resource/services/env.service';
import {AngularTokenService} from 'angular-token';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class OperatorTenantService {
  readonly BASE_PATH = 'operator/api/v1/tenant/entities';
  readonly BASE_PATH_AZURE_SSO = 'operator/api/v1/tenant/azure_sso_configs';
  readonly BASE_PATH_AZURE_SSO_DOMAIN_MAPPINGS = 'operator/api/v1/tenant/azure_sso_domain_mappings';

  constructor(private _http: ApiResourceService,
              private fhttp: FileApiResourceService,
              private env: EnvService,
              private tokenSvc: AngularTokenService,
              private httpClient: HttpClient) {
  }

  getAll(): Observable<model.Operator.Tenant[]> {
    const builder = new build.Operator.TenantBuilder();
    return <Observable<model.Operator.Tenant[]>>this._http.get<build.Operator.TenantBuilder, model.Operator.Tenant>(builder, `${this.BASE_PATH}`);
  }

  getAllOrganizations(tenantId): Observable<organizationModel.Operator.Organization[]> {
    const builder = new organizationBuild.Operator.OrganizationBuilder();
    return <Observable<organizationModel.Operator.Organization[]>>this._http.get<organizationBuild.Operator.OrganizationBuilder, organizationModel.Operator.Organization>(builder, `${this.BASE_PATH}/${tenantId}/organizations`);
  }

  removeOrganization(tenantId, organizationId): Observable<organizationModel.Operator.Organization> {
    const builder = new organizationBuild.Operator.OrganizationBuilder();
    return <Observable<organizationModel.Operator.Organization>>this._http.del<organizationBuild.Operator.OrganizationBuilder, organizationModel.Operator.Organization>(builder, `${this.BASE_PATH}/${tenantId}/organizations/${organizationId}`);
  }

  assignOrganization(tenantId, organizationId): Observable<organizationModel.Operator.Organization> {
    const builder = new organizationBuild.Operator.OrganizationBuilder();
    const params = {
      data: {
        attributes: {
          organization_id: organizationId
        }
      }
    }
    return <Observable<organizationModel.Operator.Organization>>this._http.post<organizationBuild.Operator.OrganizationBuilder, organizationModel.Operator.Organization>(builder, `${this.BASE_PATH}/${tenantId}/organizations`, params);
  }

  getOne(id: string): Observable<model.Operator.Tenant> {
    const builder = new build.Operator.TenantBuilder();
    return <Observable<model.Operator.Tenant>>this._http.get<build.Operator.TenantBuilder, model.Operator.Tenant>(builder, `${this.BASE_PATH}/${id}`);
  }

  create(params: modelInterface.Operator.ITenantParams): Observable<model.Operator.Tenant> {
    const builder = new build.Operator.TenantBuilder();
    const payload = {
      data: {
        attributes: params
      }
    };
    return <Observable<model.Operator.Tenant>>this._http.post<build.Operator.TenantBuilder, model.Operator.Tenant>(builder, this.BASE_PATH, payload);
  }

  update(tenant: model.Operator.Tenant): Observable<model.Operator.Tenant> {
    const builder = new build.Operator.TenantBuilder();
    const payload = builder.toRequest(tenant);
    return <Observable<model.Operator.Tenant>>this._http.put<build.Operator.TenantBuilder, model.Operator.Tenant>(builder, `${this.BASE_PATH}/${tenant.id}`, payload);
  }

  destroy(tenantId: string): Observable<model.Operator.Tenant> {
    const builder = new build.Operator.TenantBuilder();
    return <Observable<model.Operator.Tenant>>this._http.del<build.Operator.TenantBuilder, model.Operator.Tenant>(builder, `${this.BASE_PATH}/${tenantId}`);
  }

  downloadK8SConfig(tenant: model.Operator.Tenant) {
    return this.fhttp.getBlob( `${this.env.tusServer()}/${this.BASE_PATH}/${tenant.id}/k8s`, `spa-compass-audit.domain-setup.${tenant.domain}.yml`, this.tokenSvc.currentAuthData);
  }

  /**
   * Returns all SSO configurations.
   */
  loadAzureSSOConfigs(): Observable<model.Operator.AzureSSOConfig[]> {
    const builder = new build.Operator.AzureSSOConfigBuilder();
    return <Observable<model.Operator.AzureSSOConfig[]>>this._http.get<build.Operator.AzureSSOConfigBuilder, model.Operator.AzureSSOConfig>(builder, this.BASE_PATH_AZURE_SSO);
  }

  /**
   * Returns the SSO configuration with id.
   */
  loadAzureSSOConfig(id): Observable<model.Operator.AzureSSOConfig> {
    const builder = new build.Operator.AzureSSOConfigBuilder();
    return <Observable<model.Operator.AzureSSOConfig>>this._http.get<build.Operator.AzureSSOConfigBuilder, model.Operator.AzureSSOConfig>(builder, `${this.BASE_PATH_AZURE_SSO}/${id}`);
  }

  /**
   * Creates a new SSO configuration.
   * @param config
   */
  createAzureSSOConfig(config): Observable<model.Operator.AzureSSOConfig> {
    const builder = new build.Operator.AzureSSOConfigBuilder();
    const payload = builder.toRequest(config);
    return <Observable<model.Operator.AzureSSOConfig>>this._http.post<build.Operator.AzureSSOConfigBuilder, model.Operator.AzureSSOConfig>(builder, this.BASE_PATH_AZURE_SSO, payload);
  }

  /**
   * Deletes a given SSO configuration.
   * @param id
   */
  destroyAzureSSOConfig(id): Observable<model.Operator.AzureSSOConfig> {
    const builder = new build.Operator.AzureSSOConfigBuilder();
    return <Observable<model.Operator.AzureSSOConfig>>this._http.del<build.Operator.AzureSSOConfigBuilder, model.Operator.AzureSSOConfig>(builder, `${this.BASE_PATH_AZURE_SSO}/${id}`);
  }

  /**
   * Sets the SSO mode of the SSO configuration.
   * Enforced means: All users matched by e-mail domain must be authorized by Azure IDP.
   * Optional: Only users with login setup 'azure' are enforced to use the Azure IDP. All other users
   *           will fallback to username/password authorization by 5F.
   *
   * @param id
   * @param ssoMode
   */
  setAzureSSOEnforcementMode(id, ssoMode: 'optional' | 'enforced'): Observable<model.Operator.AzureSSOConfig> {
    const builder = new build.Operator.AzureSSOConfigBuilder();
    const payload = {
      data: {
        attributes: {
          sso_mode: ssoMode
        }
      }
    }
    return <Observable<model.Operator.AzureSSOConfig>>this._http.post<build.Operator.AzureSSOConfigBuilder, model.Operator.AzureSSOConfig>(builder, `${this.BASE_PATH_AZURE_SSO}/${id}/sso_mode`, payload);
  }

  activateAdDepartmentOnboarding(id, activate: boolean): Observable<model.Operator.AzureSSOConfig> {
    const builder = new build.Operator.AzureSSOConfigBuilder();
    const payload = {
      data: {
        attributes: {
          ad_department_onboarding: activate
        }
      }
    }
    return <Observable<model.Operator.AzureSSOConfig>>this._http.post<build.Operator.AzureSSOConfigBuilder, model.Operator.AzureSSOConfig>(builder, `${this.BASE_PATH_AZURE_SSO}/${id}/activate_ad_department_onboarding`, payload);
  }

  /**
   * Sets the tenant for the given SSO config.
   * @param config
   */
  setTenantForAzureSSO(config: model.Operator.AzureSSOConfig): Observable<model.Operator.AzureSSOConfig> {
    const builder = new build.Operator.AzureSSOConfigBuilder();
    const payload = {
      data: {
        attributes: {
          tenant_account_id: config.fiveFTenantId
        }
      }
    }
    return <Observable<model.Operator.AzureSSOConfig>>this._http.post<build.Operator.AzureSSOConfigBuilder, model.Operator.AzureSSOConfig>(builder, `${this.BASE_PATH_AZURE_SSO}/${config.id}/update_tenant`, payload);
  }

  setDefaultOrganizationForTenant(config: model.Operator.AzureSSOConfig): Observable<model.Operator.AzureSSOConfig> {
    const builder = new build.Operator.AzureSSOConfigBuilder();
    const payload = {
      data: {
        attributes: {
          default_organization_id: config.defaultOrganizationId
        }
      }
    }
    return <Observable<model.Operator.AzureSSOConfig>>this._http.post<build.Operator.AzureSSOConfigBuilder, model.Operator.AzureSSOConfig>(builder, `${this.BASE_PATH_AZURE_SSO}/${config.id}/default_organization`, payload);
  }

  setADDepartmentOnboardingFallback(config: model.Operator.AzureSSOConfig): Observable<model.Operator.AzureSSOConfig> {
    const builder = new build.Operator.AzureSSOConfigBuilder();
    const payload = {
      data: {
        attributes: {
          ad_department_fallback_organization: config.adDepartmentOnboardingFallbackId
        }
      }
    }
    return <Observable<model.Operator.AzureSSOConfig>>this._http.post<build.Operator.AzureSSOConfigBuilder, model.Operator.AzureSSOConfig>(builder, `${this.BASE_PATH_AZURE_SSO}/${config.id}/ad_department_fallback_organization`, payload);
  }

  updateADDepartmentsOfOrganization(tenantId: string, organizationId: string, departments: string): Observable<organizationModel.Operator.Organization> {
    const builder = new organizationBuild.Operator.OrganizationBuilder();
    const payload = {
      data: {
        attributes: {
          ad_departments: departments
        }
      }
    }
    return <Observable<organizationModel.Operator.Organization>>this._http.post<organizationBuild.Operator.OrganizationBuilder, organizationModel.Operator.Organization>(builder, `${this.BASE_PATH}/${tenantId}/organizations/${organizationId}/ad_departments`, payload);
  }

  /**
   * Returns all SSO domain mappings. Domain mappings can be used to find user accounts of domain B by entering the user
   * with e-mail domain A.
   * Requirement: The e-mail part before the @ character must be the same.
   */
  loadAzureSSODomainMappings(): Observable<model.Operator.AzureSSODomainMapping[]> {
    const builder = new build.Operator.AzureSSODomainMappingBuilder();
    return <Observable<model.Operator.AzureSSODomainMapping[]>>this._http.get<build.Operator.AzureSSODomainMappingBuilder, model.Operator.AzureSSODomainMapping>(builder, this.BASE_PATH_AZURE_SSO_DOMAIN_MAPPINGS);
  }

  /**
   * Creates a new domain mapping.
   * @param mapping
   */
  createAzureSSODomainMapping(mapping: model.Operator.AzureSSODomainMapping) {
    const builder = new build.Operator.AzureSSODomainMappingBuilder();
    const payload = builder.toRequest(mapping);
    return <Observable<model.Operator.AzureSSODomainMapping>>this._http.post<build.Operator.AzureSSODomainMappingBuilder, model.Operator.AzureSSODomainMapping>(builder, this.BASE_PATH_AZURE_SSO_DOMAIN_MAPPINGS, payload);
  }

  /**
   * Deletes a given domain mapping.
   * @param id
   */
  destroyAzureSSODomainMapping(id) {
    const builder = new build.Operator.AzureSSODomainMappingBuilder();
    return <Observable<model.Operator.AzureSSODomainMapping>>this._http.del<build.Operator.AzureSSODomainMappingBuilder, model.Operator.AzureSSODomainMapping>(builder, `${this.BASE_PATH_AZURE_SSO_DOMAIN_MAPPINGS}/${id}`);
  }

  saveMailjetKey(mailjetKey: string) {
    const currentAuthData = this.tokenSvc.currentAuthData;
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };
    for (const key in currentAuthData) {
      if (currentAuthData.hasOwnProperty(key)) {
        headersConfig[key] = currentAuthData[key];
      }
    }
    headersConfig['access-token'] = currentAuthData['accessToken'];
    delete headersConfig['tokenType'];
    delete headersConfig['accessToken'];
    const headers = new HttpHeaders(headersConfig);
    const payload = {
      data: {
        attributes: {
          mailjet_key: mailjetKey
        }
      }
    };
    return this.httpClient.post(`${this.env.tusServer()}/operator/api/v1/tenant/mailjet/key`, payload, { headers: headers });
  }
}
