import {ExternalAccessActions, ExternalAccessActionTypes} from './external-access.actions';
import {adapter, initialState, State} from './external-access.state';

export function reducer(state = initialState, action: ExternalAccessActions): State {
  const current = state.selected;
  switch (action.type) {
    case ExternalAccessActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case ExternalAccessActionTypes.Create:
    case ExternalAccessActionTypes.Destroy:
      return {
        ...state,
        loading: true
      };
    case ExternalAccessActionTypes.LoadAllSuccess:
      return adapter.setAll(action.externalAccesses, {
          ...state,
          loading: false,
      });
    case ExternalAccessActionTypes.CreateSuccess:
      return adapter.upsertOne(action.externalAccess, {
        ...state,
        selected: action.externalAccess,
        loading: false,
      });
    case ExternalAccessActionTypes.CreateFail:
    case ExternalAccessActionTypes.LoadAllFail:
    case ExternalAccessActionTypes.DestroyFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
    case ExternalAccessActionTypes.DestroySuccess:
      return adapter.removeOne(action.externalAccess.id, {
        ...state,
        selected: (current && current.id === action.externalAccess.id) ?  null : current,
        loading: false,
      });
  }
  return state;
}

