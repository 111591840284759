import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DavContactService} from './dav-contact.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    DavContactService
  ]
})
export class DavContactStoreModule {
}
