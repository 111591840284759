import { IContact } from '../models/contact.interface';
import * as contactsActions from '../actions/contacts.actions';

export type ContactState = IContact[];
export const initialState: ContactState = [];

export function contactReducer(state: ContactState = initialState, action: contactsActions.Actions): ContactState {
  let contact;
  switch (action.type) {
    case contactsActions.GET_CONTACTS_SUCCESS:
      return action.payload;

    case contactsActions.CREATE_CONTACT_SUCCESS:
      const newArr = [...state, action.payload];
      console.log('newArr', newArr);
      console.log('action.payload', action.payload);
      return newArr;

    case contactsActions.DELETE_CONTACT_SUCCESS:
      return state.filter(_contact => _contact.id !== action.payload.id);

    case contactsActions.GET_CONTACT_SUCCESS:
      contact = state.find(item => item.id === action.payload.id);
      if (contact) {
        Object.assign(contact, action.payload);
      }
      return state;

    case contactsActions.UPDATE_CONTACT_SUCCESS:
      contact = state.find(item => item.id === action.payload.id);
      Object.assign(contact, action.payload);
      return state;

    default: {
      return state;
    }
  }
};

