import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class NewsTickerService {
  nothingToDo: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasProcessInvitations = true;
  public hasContactInvitations = true;
  public hasNewDocuments = true;
  public hasNewComments = true;
  public hasNewTasks = true;
  public hasDueDates = true;
  public hasOpenFastdocsProcess = true;

  constructor() {}

  update() {
    const nothingToDo = !this.hasProcessInvitations
      && !this.hasContactInvitations
      && !this.hasNewDocuments
      && !this.hasNewComments
      && !this.hasDueDates
      && !this.hasNewTasks
      && !this.hasOpenFastdocsProcess;
    this.nothingToDo.next(nothingToDo);
  }

  incomingContactInvitations(present) {
    this.hasContactInvitations = present;
    this.update();
  }

  incomingProcessInvitations(present) {
    this.hasProcessInvitations = present;
    this.update();
  }

  incomingDocuments(present) {
    this.hasNewDocuments = present;
    this.update();
  }

  incomingTasks(present) {
    this.hasNewTasks = present;
    this.update();
  }

  incomingComments(present) {
    this.hasNewComments = present;
    this.update();
  }

  incomingDueDates(present) {
    this.hasDueDates = present;
    this.update();
  }

  incomingFastdocsProcess(present) {
    this.hasOpenFastdocsProcess = present;
    this.update();
  }
}
