<ng-container [ngSwitch]="action">
  <ng-container *ngSwitchCase="AddOrInviteActionType.Button">
    <button mat-button color="primary" (click)="openDialog()"
            [matTooltip]="'PROJECT_ROOM.ADD_AND_INVITE_CONTACT_TOOLTIP' | translate">
      <mat-icon mataffix>person_add</mat-icon>
      {{ 'PROJECT_ROOM.ADD_AND_INVITE_CONTACT_BUTTON' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="AddOrInviteActionType.MenuItem">
    <li class="d-flex align-items-center p-2"
        (click)="openDialog()">
      <mat-icon class="mr-2">person_add</mat-icon>
      {{ 'PROJECT_ROOM.ADD_AND_INVITE_CONTACT_BUTTON' | translate }}
    </li>
  </ng-container>
</ng-container>

<ng-template cdkPortal #titleContext>
  <h2 mat-dialog-title>{{ dialogTitle | translate }}</h2>
  <button class="mat-dialog-close" mat-mini-fab color="warn" (click)="closeDialog()">
    <mat-icon>clear</mat-icon>
  </button>
</ng-template>

<ng-template cdkPortal #context>
  <div class="five-f-add-and-invite">
    <div class="mb-1">
      <dvtx-alert [icon]="'info'" [color]="'info'" [text]="'PROJECT_ROOM.ADD_AND_INVITE_CONTACT_INFO' | translate"></dvtx-alert>
    </div>

    <form [formGroup]="form" novalidate>
      <div class="icon-content-layout mt-2">
        <mat-icon>email</mat-icon>
        <div class="row">
          <div class="col col-md-5 col-sm-6">
            <mat-form-field>
              <input matInput formControlName="email" [placeholder]="'CONTACTS.EMAIL' | translate" email required />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="icon-content-layout mt-2">
        <mat-icon>person</mat-icon>
        <div class="row">
          <div class="col col-md-5 col-sm-6">
            <mat-form-field>
              <input matInput formControlName="first_name" [placeholder]="'CONTACTS.FIRST_NAME' | translate" required />
            </mat-form-field>
          </div>
          <div class="col col-md-5 col-sm-6">
            <mat-form-field>
              <input matInput formControlName="last_name" [placeholder]="'CONTACTS.LAST_NAME' | translate" required />
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template cdkPortal #buttonsToolbar>
  <div class="d-flex justify-content-end">
    <div>
      <button mat-button (click)="closeDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
      <button mat-raised-button color="primary" [disabled]="submitOnGoing || form.invalid" (click)="addParticipant()">
        {{ 'PROJECT_ROOM.ADD_AND_INVITE_SEND_BUTTON' | translate }}
      </button>
    </div>
  </div>
</ng-template>
