import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DatevOauthService} from 'app/+store/datev-oauth/datev-oauth.service';

@Component({
  selector: 'dvtx-datev-oauth-redirect',
  templateUrl: './datev-oauth-redirect.component.html',
  styleUrls: ['./datev-oauth-redirect.component.scss']
})
export class DatevOauthRedirectComponent implements OnInit {
  route;

  constructor(private _router: Router,
              private _route: ActivatedRoute,
              private _datevSvc: DatevOauthService) {
    this.route = localStorage.getItem('route');
  }

  ngOnInit(): void {
    const queryParams = this._route.snapshot.queryParams;
    // this.initDatev().then(redirected => console.log(redirected));

    if (!this.route) {
      this.route = '/dashboard';
    }
    this._router.navigateByUrl(this.route).then(redirected => console.log(redirected));
  }

  // async initDatev() {
  //   // await this._datevSvc.checkRedirectFromDatev();
  // }
}
