<div *ngIf="cart.loading$ | async">
  <mat-spinner [diameter]="20"></mat-spinner>
</div>

<div *ngIf="!(cart.loading$ | async)" class="cart-details-container">
  <h3>Rechnung</h3>

  <p>
    {{ cart?.paymentOption?.firstName + ' ' + cart?.paymentOption?.lastName }}
    <br />
    <span *ngIf="cart?.invoiceAddress?.organizationName">
      {{ cart?.invoiceAddress?.organizationName }}
    </span>
  </p>
  <p>
    {{ cart?.invoiceAddress?.street + ' ' + cart?.invoiceAddress?.streetNo }}
    <br />
    {{ cart?.invoiceAddress?.zip + ' ' + cart?.invoiceAddress?.city }}
    <br />
    {{ cart?.invoiceAddress?.country }}
  </p>
  <table *ngIf="cart?.invoiceAddress?.ustId">
    <tr>
      <td class="label">UStId:</td>
      <td>{{ cart?.invoiceAddress?.ustId }}</td>
    </tr>
  </table>

  <h3>Bankverbindung</h3>
  <table>
    <tr>
      <td class="label">Kontoinhaber:</td>
      <td>{{ cart?.paymentOption?.firstName + ' ' + cart?.paymentOption?.lastName }}</td>
    </tr>
    <tr>
      <td class="label">IBAN:</td>
      <td>{{ cart?.paymentOption?.iban }}</td>
    </tr>
  </table>

  <h3>Lastschrift Informationen</h3>
  <table>
    <tr>
      <td class="label">Gläubiger-Identifikationsnummer:</td>
      <td>DE30ZZZ00002206373</td>
    </tr>
  </table>
  <address>
    <strong>5FSoftware GmbH</strong><br>
    Prüefeninger Str. 17<br>
    93049 Regensburg
  </address>
  <!--<pre>-->
    <!--{{ cart.lineItems | json }}-->
    <!--{{ cart.invoiceAddress | json }}-->
    <!--{{ cart.paymentOption | json }}-->
  <!--</pre>-->
</div>
