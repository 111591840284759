import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TreeData} from '../../../../../process-tree/common/process-tree-data';
import {ProcessTreeNodeType} from 'app/+store/process-tree/process-tree.interface';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'dvtx-process-tree-node',
  templateUrl: './process-tree-node.component.html',
  styleUrls: ['./process-tree-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessTreeNodeComponent implements OnInit {
  public ProcessTreeNodeType = ProcessTreeNodeType;

  public isRoot = false;
  public _node: TreeData;
  public nodeName: string = '';
  @Input() set node(node: TreeData) {
    if (node) {
      this.nodeName = this._translateSvc.instant(node.name);
    }
    this._node = node;
    this.isRoot = node.type === ProcessTreeNodeType.Root;
  }
  @Input() noTitlePlaceholder;
  @Output() onSelect = new EventEmitter();

  constructor(private _translateSvc: TranslateService) {
  }

  ngOnInit() {
  }

  public selectNode(node) {
    if (!node) return;

    this.onSelect.emit(node);
  }
}
