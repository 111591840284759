import {Action} from '@ngrx/store';
import {CollectorCategory} from './collector-category';
import {ItemStatus} from 'app/+store/collector/collector.interface';
import {IBomDataNodeParams} from '../bom/bom-data-node.interface';

export enum CollectorCategoryActionTypes {
  Create = '[CollectorCategory] Create',
  CreateSuccess = '[CollectorCategory] Create Success',
  CreateFail = '[CollectorCategory] Create Fail',
  Remove = '[CollectorCategory] Remove',
  RemoveSuccess = '[CollectorCategory] Remove Success',
  RemoveFail = '[CollectorCategory] Remove Fail',
  Save = '[CollectorCategory] Save',
  SaveSuccess = '[CollectorCategory] Save Success',
  SaveFail = '[CollectorCategory] Save Fail',
  Status = '[CollectorCategory] Status',
  StatusSuccess = '[CollectorCategory] Status Success',
  StatusFail = '[CollectorCategory] Status Fail',
  LoadAllSuccess = '[CollectorCategory] Load All Success',
  UpsertAllSuccess = '[CollectorCategory] Upsert All Success',
  Copy = '[CollectorCategory] Copy',
  CopySuccess = '[CollectorCategory] Copy Success',
  CopyFail = '[CollectorCategory] Copy Fail',
  Reset = '[CollectorCategory] Reset',
}

export class Reset implements Action {
  readonly type = CollectorCategoryActionTypes.Reset;

  constructor(public payload: any = null) {
  }
}

export class Create implements Action {
  readonly type = CollectorCategoryActionTypes.Create;

  constructor(public processId: string, public params: IBomDataNodeParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = CollectorCategoryActionTypes.CreateSuccess;

  constructor(public category: CollectorCategory) {
  }
}

export class CreateFail implements Action {
  readonly type = CollectorCategoryActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = CollectorCategoryActionTypes.Remove;

  constructor(public processId: string, public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = CollectorCategoryActionTypes.RemoveSuccess;

  constructor(public category: CollectorCategory) {
  }
}

export class RemoveFail implements Action {
  readonly type = CollectorCategoryActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = CollectorCategoryActionTypes.Save;

  constructor(public processId: string, public id: string, public params: IBomDataNodeParams) {
  }
}

export class SaveSuccess implements Action {
  readonly type = CollectorCategoryActionTypes.SaveSuccess;

  constructor(public category: CollectorCategory) {
  }
}

export class SaveFail implements Action {
  readonly type = CollectorCategoryActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class Status implements Action {
  readonly type = CollectorCategoryActionTypes.Status;

  constructor(public processId: string, public id: string, public status: ItemStatus) {
  }
}

export class StatusSuccess implements Action {
  readonly type = CollectorCategoryActionTypes.StatusSuccess;

  constructor(public category: CollectorCategory) {
  }
}

export class StatusFail implements Action {
  readonly type = CollectorCategoryActionTypes.StatusFail;

  constructor(public payload: any) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = CollectorCategoryActionTypes.LoadAllSuccess;

  constructor(public categories: CollectorCategory[]) {
  }
}

export class UpsertAllSuccess implements Action {
  readonly type = CollectorCategoryActionTypes.UpsertAllSuccess;

  constructor(public categories: CollectorCategory[]) {
  }
}

export class Copy implements Action {
  readonly type = CollectorCategoryActionTypes.Copy;

  constructor(public processId: string, public id: string) {
  }
}

export class CopySuccess implements Action {
  readonly type = CollectorCategoryActionTypes.CopySuccess;

  constructor(public category: CollectorCategory) {
  }
}

export class CopyFail implements Action {
  readonly type = CollectorCategoryActionTypes.CopyFail;

  constructor(public payload: any) {
  }
}

export type CollectorCategoryActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail
  | Status
  | StatusSuccess
  | StatusFail
  | LoadAllSuccess
  | UpsertAllSuccess
  | Copy
  | CopySuccess
  | CopyFail
  | Reset;
