import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClientRoute} from 'app/shared/modules/page-container/tab-nav-bar/tab-nav-bar.component';
import {Organization} from 'app/models/organization.model';
import {OrganizationSelectors} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Observable} from 'rxjs/internal/Observable';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'dvtx-data-privacy-container',
  templateUrl: './data-privacy-container.component.html',
  styleUrls: ['./data-privacy-container.component.scss']
})
export class DataPrivacyContainerComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();
  public routes: ClientRoute[];
  public activeLink: string;
  public organization: Observable<Organization>;
  
  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.organization = this.store.select(OrganizationSelectors.getSelected);

    this.routes = [{
      title: 'REGISTRATION.DATA_PRIVACY',
      route: `/user-profile/privacy/data-privacy`
    }];
    this.activeLink = this.routes[0].title;

    // Show terms only for licensed employees aka organization is present.
    // Business partners do not have an organization.
    this.organization
      .pipe(filter(o => !!o), takeUntil(this.onDestroy))
      .subscribe(organization => {
        this.routes = [{
          title: 'REGISTRATION.DATA_PRIVACY',
          route: `/user-profile/privacy/data-privacy`
        }, {
          title: 'REGISTRATION.TERMS_OF_USE_TAB_TITLE',
          route: `/user-profile/privacy/terms`
        }];
        this.activeLink = this.routes[0].title;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
