import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {QuickCollector} from './quickcollector';
import {QuickCollectorBuilder} from './quickcollector.builder';
import {IQuickCollectorParams} from './quickcollector.interface';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {QuickCollectorItemOrderBuilder} from './quickcollector-items-order.builder';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';
import {HttpClient} from '@angular/common/http';
@Injectable()
export class QuickCollectorService {
  readonly BASE_PATH = 'api/v1/clr';

  constructor(private _http: ApiResourceService, private _store: Store<AppState> = null,  private _plainHttp:  HttpClient) {
  }

  create(params: IQuickCollectorParams): Observable<QuickCollector> {
    const builder = new QuickCollectorBuilder();
    const payload = {data: {attributes: { title: params.title}}};
    if (params.profile) {
      payload['data']['attributes']['profile'] = params.profile
    }
    const query = params.parentId ? `?parent=${params.parentId}` : '';
    return <Observable<QuickCollector>>this._http.post<QuickCollectorBuilder, QuickCollector>(builder, `${this.BASE_PATH}/checklists${query}`, payload);
  }

  publish(id: string): Observable<QuickCollector> {
    const builder = new QuickCollectorBuilder();
    return <Observable<QuickCollector>>this._http.post<QuickCollectorBuilder, QuickCollector>(builder, `${this.BASE_PATH}/checklists/${id}/publish`, {});
  }


  duplicate(id: string, includeTemplates = false, includeDueDates = false): Observable<QuickCollector> {
    const builder = new QuickCollectorBuilder();
    return <Observable<QuickCollector>>this._http.post<QuickCollectorBuilder, QuickCollector>(builder, `${this.BASE_PATH}/checklists/${id}/duplicate?include_templates=${includeTemplates}&include_due_dates=${includeDueDates}`, {});
  }

  prefetch_attachments(id: string): Observable<any> {
    const builder = new QuickCollectorBuilder();
    return <Observable<QuickCollector>>this._http.post<QuickCollectorBuilder, QuickCollector>(builder, `${this.BASE_PATH}/checklists/${id}/prefetch_templates`, {});
  }

  validateBookmanFibu(id: string): Observable<QuickCollector> {
    const builder = new QuickCollectorBuilder();
    return <Observable<QuickCollector>>this._http.get<QuickCollectorBuilder, QuickCollector>(builder, `${this.BASE_PATH}/checklists/${id}/fibu/validate`);
  }

  validateDatevDuo(id: string): Observable<QuickCollector> {
    const builder = new QuickCollectorBuilder();
    return <Observable<QuickCollector>>this._http.get<QuickCollectorBuilder, QuickCollector>(builder, `${this.BASE_PATH}/checklists/${id}/datev_duo/validate`);
  }

  saveDraft(id: string, params: IQuickCollectorParams): Observable<QuickCollector> {
    const builder = new QuickCollectorBuilder();
    const payload = { data: { attributes: params } };
    if (params.dmsAccountType) {
      payload['data']['attributes']['dms_account_type'] =  params.dmsAccountType === DmsAccountType.Organization ? 'organization' : 'private'
    }
    return <Observable<QuickCollector>>this._http.put<QuickCollectorBuilder, QuickCollector>(builder, `${this.BASE_PATH}/drafts/${id}`, payload);
  }

  getAll(): Observable<QuickCollector[]> {
    const builder = new QuickCollectorBuilder(this._store);
    return <Observable<QuickCollector[]>>this._http.get<QuickCollectorBuilder, QuickCollector>(builder, `${this.BASE_PATH}/checklists`);
  }

  getOne(id: string): Observable<QuickCollector> {
    const builder = new QuickCollectorBuilder(this._store);
    return <Observable<QuickCollector>>this._http.get<QuickCollectorBuilder, QuickCollector>(builder, `${this.BASE_PATH}/checklists/${id}`);
  }

  updateRecipients(id: string, emails: string[]): Observable<QuickCollector> {
    const builder = new QuickCollectorBuilder();
    const payload = {
      'data': {
        'attributes': {
          'recipients': emails
        }
      }
    };
    return <Observable<QuickCollector>>this._http.post<QuickCollectorBuilder, QuickCollector>(builder, `${this.BASE_PATH}/checklists/${id}/recipients`, payload);
  }

  updateWorkers(id: string, emails: string[]): Observable<QuickCollector> {
    const builder = new QuickCollectorBuilder();
    const payload = {
      'data': {
        'attributes': {
          'workers': emails
        }
      }
    };
    return <Observable<QuickCollector>>this._http.post<QuickCollectorBuilder, QuickCollector>(builder, `${this.BASE_PATH}/checklists/${id}/workers`, payload);
  }

  reorder(id: string, items: any[]) {
    const orderedItems = items.map(item => {
      return {
        id: item.id,
        order: item.order,
        parent_id: item.categoryId
      };
    });

    const builder = new QuickCollectorItemOrderBuilder();
    const payload = {
      data: {
        attributes: {
          nodes: orderedItems
        }
      }
    };
    return <Observable<any>>this._http.post<QuickCollectorItemOrderBuilder, any>(builder, `${this.BASE_PATH}/checklists/${id}/reorders`, payload);
  }
}
