import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Invitation } from 'app/+store/invitation/invitation.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'dvtx-invitations-dialog',
  templateUrl: './invitations-dialog.component.html',
  styleUrls: ['./invitations-dialog.component.scss']
})
export class InvitationsDialogComponent {
  invitations: Observable<Invitation[]>

  constructor(
    public dialogRef: MatDialogRef<InvitationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router
  ) {
    this.invitations = data.invitations;
  }

  onClose() {
    this.dialogRef.close();
    this.data.closeAction();
  }

  goToInvitations() {
    this.dialogRef.close();
    this.data.redirectAction();
  }
}
