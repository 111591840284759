import {IResource} from 'app/shared/modules/api/models/resource.interface';

export class ThirdPartyConfirmationContribution implements IResource {
  contactConfirmedAt: Date;
  emailAttachmentFilename: string;

  constructor(
    public id: number,
    public type: string,
    public title: string,
    public preamble: string,
    public recipient_name: string,
    public due_date: string,
    public referenced_at: string,
    public sender_name: string,
    public contractor: string[],
    public contractee: string[],
    public business_year_start: string,
    public business_year_end: string,
    public token: string,
    public recipient_email: string,
    public third_party_firstname: string,
    public third_party_lastname: string,
    public third_party_email: string,
    public third_party_function: string,
    public third_party_company_name: string,
    public third_party_place: string,
    public third_party_date: string,
    public rows: ThirdPartyConfirmationContributionRow[],
    public signature: string,
    public external_preamble_de: string,
    public external_preamble_en: string,
  ) {}

  toForm() {
    return undefined;
  }
}

export class ThirdPartyConfirmationContributionRow {
  constructor(public type: string, public attributes: {[key: string]: string | {[key: string]: string}[]}) {}
}
