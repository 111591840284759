<div class="container d-flex align-items-center justify-content-center">
  <div class="row text-center mb-5">
    <div class="col-12 mb-5">
      <mat-icon class="error--icon display-1 mb-2">report</mat-icon>
      <h1 class="error--text font-weight-light h1 mb-1">{{ error?.error?.data?.attributes?.message_de}}</h1>
      <h2 class="error--text font-weight-light h2 mb-3">{{ error?.error?.data?.attributes?.message_en}}</h2>
      <a mat-raised-button color="primary" (click)="refresh()" class="mb-5"><mat-icon>refresh</mat-icon> {{ 'GENERAL.TRY_AGAIN' | translate }}</a>
    </div>
  </div>
</div>
