import {IContactAddress, SimpleAddress} from '../models/contact.interface';
import {ContactAddress} from '../models/contact';
import {SimplePhoneNumberBuilder} from './contact-phone-number.builder';

export namespace ContactAddressBuilder {
  export function fromResponse(response, catalog = null): IContactAddress {
    const attrs = response.attributes;
    return new ContactAddress(response.id, attrs.location_or_type,
      attrs.full, attrs.street, attrs.street_no,
      attrs.city, attrs.zip, attrs.country_name,
      attrs.geometry, attrs.order, attrs.main_address);
  }

  export function toRequest(record: IContactAddress) {
    return {
      data: {
        id: record.id,
        type: record.type,
        attributes: {
          main_address: record.mainAddress,
          location_or_type: record.locationOrType,
          street: record.street,
          street_no: record.streetNo,
          city: record.city,
          zip: record.zip,
          country_name: record.countryName,
          geometry: record.geometry,
          full: record.full,
          order: record.order
        }
      }
    };
  }
}

export class SimpleAddressBuilder {
  public static fromResponseList(response): SimpleAddress[] {
    const ret: SimpleAddress[] = [];
    for (const item of response.data) {
      ret.push(SimpleAddressBuilder.fromResponse(item));
    }
    return ret;
  }

  public static fromResponse(response): SimpleAddress {
    const ret = new SimpleAddress();
    ret.id = response['id'];
    ret.city = response['attributes']['city'];
    ret.countryName = response['attributes']['country'];
    ret.locationOrType = response['attributes']['address_or_type'];
    ret.street = response['attributes']['street'];
    ret.streetNo = response['attributes']['street_no'];
    ret.zip = response['attributes']['zip'];
    return ret
  }

  public static toRequest(val: SimpleAddress) {
    return {
      data: {
        city: val.city,
        country: val.countryName,
        street: val.street,
        street_no: val.streetNo,
        zip: val.zip,
        address_or_type: val.locationOrType
      }
    }
  }
}
