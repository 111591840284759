import * as contactFilterActions from '../actions/contacts-filter.actions';
import * as contactsActions from '../actions/contacts.actions';

export type ContactFilterState = string;
export const initialState = '';

export function contactFilterReducer(state: string = initialState, action: contactFilterActions.Actions): string {
  switch (action.type) {
    case contactFilterActions.FILTER_CONTACTS:
      return action.payload;
    // case contactsActions.CREATE_CONTACT_SUCCESS:
    //   return initialState;
    case contactFilterActions.RESET_CONTACT_FILTER:
      return initialState;
    default: {
      return state;
    }
  }
};

