import {createEntityAdapter, EntityState} from '@ngrx/entity'
import {ContactPersonOrganizationAssociation} from '../../models/contact-person-organization-association.model';

export interface State extends EntityState<ContactPersonOrganizationAssociation> {
  loading: boolean
}

export const adapter = createEntityAdapter<ContactPersonOrganizationAssociation>()

export const initialState: State = adapter.getInitialState({
  loading: false,
});

