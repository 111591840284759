import {
  Component,
  ViewChild,
  TemplateRef,
  OnInit,
  ViewContainerRef,
  Input
} from "@angular/core";
import { Portal, TemplatePortal } from "@angular/cdk/portal";
import { DropdownContentComponent } from "app/five-f/dropdown-content/component/dropdown-content.component";

@Component({
  selector: 'dvtx-filters-container',
  templateUrl: './filters-container.component.html',
  styleUrls: ['./filters-container.component.scss']
})
export class FiltersContainerComponent implements OnInit {

  @Input() showTitle = true;
  @Input() title = 'GENERAL.FILTERS';
  @Input() selectedCount = 0;
  @Input() labelPositionRight = false;
  @ViewChild('containerDropdownMenu', { static: true }) public containerDropdownMenu: DropdownContentComponent;

  @ViewChild('containerContentTogglerIcon', { static: true }) containerContentTogglerIcon: TemplateRef<any>;
  @ViewChild('containerContent', { static: true }) containerContent: TemplateRef<any>;
  containerContentTogglerIconPortal: Portal<any>;
  containerContentPortal: Portal<any>;

  constructor(
    private _viewContainerRef: ViewContainerRef,
  ) {
  }

  ngOnInit(): void {
    this.initContainerDropdown();
  }

  initContainerDropdown() {
    this.containerContentTogglerIconPortal = new TemplatePortal(
      this.containerContentTogglerIcon,
      this._viewContainerRef
    );

    this.containerContentPortal = new TemplatePortal(
      this.containerContent,
      this._viewContainerRef
    );
  }
}
