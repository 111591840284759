import {Routes, RouterModule} from '@angular/router';
import {AccessTokenNotFoundComponent} from '../external-access/components/access-token-not-found/access-token-not-found.component'; // tslint:disable-line
import {SubmitSuccessComponent} from '../external-access/components/submit-success/submit-success.component';
import {InfoComponent} from './containers/info/info.component';
import {AuthenticatedAccessDispatcherComponent} from './containers/authenticated-access-dispatcher/authenticated-access-dispatcher.component';
import { AuthGuard } from '../../shared/modules/api/services/auth-guard.service';

const routes: Routes = [{
    path: 'access/auth/validate/:token',
    component: AuthenticatedAccessDispatcherComponent,
    canActivate: [ AuthGuard ]
  }, {
    path: 'access/auth/form/success',
    component: SubmitSuccessComponent,
    canActivate: [ AuthGuard ]
  }, {
    path: 'access/auth/form/not_found',
    component: AccessTokenNotFoundComponent,
    canActivate: [ AuthGuard ]
  }, {
    path: 'access/auth/info/:token',
    component: InfoComponent,
    canActivate: [ AuthGuard ]
  }
];

export const authenticatedccessRoutes = RouterModule.forChild(routes);
