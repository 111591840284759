import {Action} from '@ngrx/store';
import {DocumentPreviewDocument} from './document-preview-document';

export enum DocumentPreviewDocumentActionTypes {
  RequestDocuments = '[DocumentPreviewDocument] RequestDocuments',
  RequestDocumentsSuccess = '[DocumentPreviewDocument] RequestDocuments Success',
  RequestDocumentsFail = '[DocumentPreviewDocument] RequestDocuments Fail',
  Reset = '[DocumentPreviewDocument] Reset',
}

export class Reset implements Action {
  readonly type = DocumentPreviewDocumentActionTypes.Reset;

  constructor(public payload: any = null) {
  }
}

export class RequestDocuments implements Action {
  readonly type = DocumentPreviewDocumentActionTypes.RequestDocuments;

  constructor(public ids: string[]) {
  }
}

export class RequestDocumentsSuccess implements Action {
  readonly type = DocumentPreviewDocumentActionTypes.RequestDocumentsSuccess;

  constructor(public documents: DocumentPreviewDocument[]) {
  }
}

export class RequestDocumentsFail implements Action {
  readonly type = DocumentPreviewDocumentActionTypes.RequestDocumentsFail;

  constructor(public payload: any = null) {
  }
}


export type DocumentPreviewDocumentActions =
  | RequestDocuments
  | RequestDocumentsSuccess
  | RequestDocumentsFail
  | Reset;
