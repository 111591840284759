/* tslint:disable:member-ordering */
import { Action } from '@ngrx/store';

// action types
export const USER_NEEDS_TFA = 'USER_NEEDS_TFA';
export const TFA_IS_WRONG = 'TFA_IS_WRONG';
export const RESET_TFA = 'RESET_TFA';

export class UserNeedsTfa implements Action {
  readonly type: string = USER_NEEDS_TFA;

  constructor(public payload: boolean) {
  }
}

export class TfaIsWrong implements Action {
  readonly type: string = TFA_IS_WRONG;

  constructor(public payload: boolean) {
  }
}

export class ResetTfa implements Action {
  readonly type: string = RESET_TFA;

  constructor() {
  }
}

export type TfaActions = UserNeedsTfa;

