import {IResource} from 'app/shared/modules/api/models/resource.interface';

export enum IProcessRoleType {
  Creator = 'CREATOR',
  Owner = 'OWNER',
  Member = 'MEMBER',
  Admin = 'ADMIN',
  Contributor = 'CONTRIBUTOR',
  Priviledged = 'PRIVILEDGED'
}

export interface IProcessRole extends IResource {
  name: string;
}

export interface IProjectRoomAdmin extends IResource {
  email: string;
}
