import {IVerifierCountryRequest} from './subarea-verifier-request-check.interface';

export const RequestCreateMockData: IVerifierCountryRequest[] = [{
  country: 'France',
  countryCode: 'fr',
  isCountryVerified: false,
  comment: '',
  language: 'de',
  companies: [{
    company: 'Mueller France Societé civile',
    isCompanyVerified: true,
    contacts: [{
      firstName: 'Cédric',
      lastName: 'Montagne',
      authorization: null,
      email: 'cedric.montagne@muller.fr',
      mobile: '0033 74456338',
      phone: '',
      isContactVerified: false
    },
      {
        firstName: 'Zoe',
        lastName: 'Fourcade',
        authorization: 'partner',
        email: 'zoe.fourcade@muller.fr',
        mobile: '0033 74456338',
        phone: '0033 74456338',
        isContactVerified: true
      }]
  },
    {
      company: 'Novartis',
      isCompanyVerified: false,
      contacts: [{
        firstName: 'Eric',
        lastName: 'Bonin',
        authorization: 'partner',
        email: 'eric.bonin@muller.fr',
        mobile: '0033 74456338',
        phone: '0033 74456338',
        isContactVerified: true
      }]
    }
  ]
},
  {
    country: 'Italy',
    countryCode: 'it',
    isCountryVerified: false,
    comment: '',
    language: 'de',
    companies: [{
      company: 'Sanofi',
      isCompanyVerified: true,
      contacts: [{
        firstName: 'Julie',
        lastName: 'Ray',
        authorization: 'partner',
        email: 'eric.bonin@muller.fr',
        mobile: '923545667786',
        phone: '',
        isContactVerified: true
      },
      {
        firstName: 'Julie',
        lastName: 'Ray',
        authorization: 'partner',
        email: 'eric.bonin@muller.fr',
        mobile: '923545667786',
        phone: '',
        isContactVerified: true
      }]
    }
    ]
  },
  {
    country: 'Mexico',
    countryCode: 'mx',
    isCountryVerified: true,
    comment: '',
    language: 'en',
    companies: [{
      company: 'Sanofi',
      isCompanyVerified: true,
      contacts: [{
        firstName: 'Julie',
        lastName: 'Ray',
        authorization: 'partner',
        email: 'eric.bonin@muller.fr',
        mobile: '923545667786',
        phone: '', isContactVerified: true
      },
      {
        firstName: 'Julie',
        lastName: 'Ray',
        authorization: 'partner',
        email: 'eric.bonin@muller.fr',
        mobile: '923545667786',
        phone: '',
        isContactVerified: true
      }]
    }
    ]
  },
  {
    country: 'Spain',
    countryCode: 'es',
    isCountryVerified: true,
    comment: '',
    language: 'de',
    companies: [{
      company: 'Sanofi',
      isCompanyVerified: false,
      contacts: [{
        firstName: 'Julie',
        lastName: 'Ray',
        authorization: 'partner',
        email: 'eric.bonin@muller.fr',
        mobile: '923545667786',
        phone: '',
        isContactVerified: false
      },
      {
        firstName: 'Julie',
        lastName: 'Ray',
        authorization: 'partner',
        email: 'eric.bonin@muller.fr',
        mobile: '923545667786',
        phone: '',
        isContactVerified: true
      }]
    }
    ]
  }
];
