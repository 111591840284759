import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AuthModule} from './modules/auth/auth.module';
import {AppComponent} from './app.component';
import {OrganizationModule} from './modules/organization/organization.module';
import {ExternalAccessModule} from './modules/external-access/external-access.module';
import {WebsocketService} from './services/websocket.service';
import {AuthenticatedAccessModule} from './modules/authenticated-access/authenticated-access.module';
import {QuickstartModule} from './modules/quickstart/quickstart.module';
import localeDe from '@angular/common/locales/de';
import {OnboardingModule} from 'app/modules/onboarding/onboarding.module';
import {ContactsModule} from 'app/modules/contacts/contacts.module';
import {UserProfileModule} from './modules/user-profile/user-profile.module';
import {LicencesPricesComponent} from './modules/licences-prices/licences-prices.component';
import {CppApiService} from './services/cpp-api.service';
import {RootStoreModule} from './+store';
import {AngularTokenModule} from 'angular-token';
import {environment} from 'environments/environment';
import {XOrganizationInterceptor} from 'app/services/x-organization.interceptor';
import {XTenantInterceptor} from './services/x-tenant.interceptor';
import {NgPipesModule} from 'ngx-pipes';
import {VersionCheckService} from './services/version-check.service';
import {MatIconModule} from '@angular/material/icon';
import {SystemService} from 'app/services/system.service';
import {AutoLogoutService} from './services/auto-logout.service';
import {IconLoaderService, MatIconRendererComponent} from './services/icon-loader.service';
import {BrowserSupportModule} from './modules/browser-support/browser-support.module';
import {
  TwoFactorVerificationModule
} from './modules/two-factor-auth/modules/two-factor-verification/two-factor-verification.module';
import {BlankComponent} from './shared/components/blank/blank.component';
import {OAuthModule} from './modules/oauth/oauth.module';
import {MainAppModule} from './modules/main/main.module';

// Register german locale to LOCALE_ID
registerLocaleData(localeDe);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?timestamp=' + new Date().getTime());
}

@NgModule({
  declarations: [
    AppComponent,
    LicencesPricesComponent,
    MatIconRendererComponent,
    BlankComponent
  ],
  imports: [
    NgPipesModule,
    HttpClientModule,
    AngularTokenModule.forRoot(environment.token_service_config),
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MainAppModule,
    BrowserAnimationsModule,
    ExternalAccessModule,
    AppRoutingModule,
    BrowserSupportModule,
    MatIconModule,
    RootStoreModule,
    OnboardingModule,
    QuickstartModule,
    AuthenticatedAccessModule,
    ContactsModule,
    AuthModule,
    OrganizationModule,
    UserProfileModule,
    TwoFactorVerificationModule,
    // MilestoneGanttModule,
    OAuthModule
    // Order matters: Routing module contains catch all to 404.
  ],
  providers: [
    AngularTokenModule,
    AutoLogoutService,
    // Deprecated in ng5 in favor of registerLocaleData (see above).
    {provide: LOCALE_ID, useValue: 'de-DE'},
    // { provide: AuthService, useClass: MockAuthService },
    // { provide: AuthGuard, useClass: MockAuthGuard },
    // AuthService, AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XOrganizationInterceptor,
      multi: true,
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: XTenantInterceptor,
      multi: true,
    },
    WebsocketService,
    CppApiService,
    VersionCheckService,
    SystemService,
    IconLoaderService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
