<div class="row" [formGroup]="form">
  <div class="col-12 col-sm-8 col-md-9">
    <dvtx-input [controlName]="'title'"
                [form]="form"
                [placeholder]="'Titel'"
                [value]="form.get('title').value"></dvtx-input>
  </div>
  <div class="col-12 col-sm-4 col-md-3">
    <!--<dvtx-api-listing-dropdown [controlName]="'type'"-->
                               <!--[placeholder]="'Dokumentenart (optional)'"-->
                               <!--[form]="form"-->
                               <!--[listing]="doc_types"></dvtx-api-listing-dropdown>-->
  </div>
  <!--<div class="col-12">-->
    <!--<mat-form-field class="add-tag-list">-->
      <!--<mat-chip-list #tagList>-->
        <!--<mat-chip *ngFor="let tag of tags" [removable]="removable" (remove)="removeTag(tag)">-->
          <!--{{tag.name}}-->
          <!--<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>-->
        <!--</mat-chip>-->
        <!--<input mat-input placeholder="Schlagworte (optional)"-->
               <!--[formControlName]="'tag'"-->
               <!--[matChipInputFor]="tagList"-->
               <!--[matChipInputSeparatorKeyCodes]="TAG_SEPARATOR_KEY_CODES"-->
               <!--[matChipInputAddOnBlur]="addOnBlur"-->
               <!--(matChipInputTokenEnd)="addTag($event)"/>-->
      <!--</mat-chip-list>-->
    <!--</mat-form-field>-->
  <!--</div>-->
</div>
