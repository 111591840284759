import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {stateKey} from './contact-person.selectors';
import {reducer} from './contact-person.reducer';
import {ContactPersonEffects} from './contact-person.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ContactPersonEffects])
  ],
  providers: [ContactPersonEffects]
})
export class ContactsPersonStoreModule {
}
