import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';
import {DmsContactBuilder} from './dms-contact.builder';
import {DmsContact} from './dms-contact';

@Injectable()
export class DmsContactService {
  readonly BASE_PATH = 'api/v1/foxdox/contacts';

  constructor(private _http: ApiResourceService) {}

  getAll(accountType: DmsAccountType): Observable<DmsContact[]> {
    const builder = new DmsContactBuilder();
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<DmsContact[]>>this._http.get<DmsContactBuilder, DmsContact>(builder, `${this.BASE_PATH}?account_type=${param}`);
  }
}
