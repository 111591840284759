import {IProcessAttribute} from 'app/+store/process-attribute/process-attribute.interface';
import {environment} from 'environments/environment';

export class ProcessAttribute implements IProcessAttribute {
  readonly type = 'bom_atom_attributes';

  constructor(public id: string,
              public resourceId: string,
              public name: string,
              public value: string,
              public description: string,
              public valueType: string,
              public xmlTag: string,
              public order: number,
              public createdBy: string,
              public updatedBy: string,
              public createdAt: Date,
              public updatedAt: Date) {
  }
}
