import {Action} from '@ngrx/store';
import {CollectorDownloadTemplate, CollectorTemplate} from './collector-template';

export enum CollectorTemplateActionTypes {
  CreateTemplate = '[CollectorTemplate] Create Template',
  CreateTemplateSuccess = '[CollectorTemplate] Create Template Success',
  CreateTemplateFail = '[CollectorTemplate] Create Template Fail',
  DeleteTemplate = '[CollectorTemplate] Delete',
  DeleteTemplateSuccess = '[CollectorTemplate] Delete Success',
  DeleteTemplateFail = '[CollectorTemplate] Delete Fail',
  LoadAll = '[CollectorTemplate] Load All',
  LoadAllSuccess = '[CollectorTemplate] Load All Success',
  LoadAllFail = '[CollectorTemplate] Load All Fail',
  LoadTemplate = '[CollectorTemplate] Load Template',
  LoadTemplateSuccess = '[CollectorTemplate] Load Template Success',
  LoadTemplateFail = '[CollectorTemplate] Load Template Fail',
  DownloadTemplate = '[CollectorTemplate] Download Template',
  DownloadTemplateSuccess = '[CollectorTemplate] Download Template Success',
  DownloadTemplateFail = '[CollectorTemplate] Download Template Fail'

}

export class CreateTemplate implements Action {
  readonly type = CollectorTemplateActionTypes.CreateTemplate;

  constructor(public id: string, public payload: any) {
  }
}

export class CreateTemplateSuccess implements Action {
  readonly type = CollectorTemplateActionTypes.CreateTemplateSuccess;

  constructor(public template: CollectorTemplate) {
  }
}

export class CreateTemplateFail implements Action {
  readonly type = CollectorTemplateActionTypes.CreateTemplateFail;

  constructor(public payload: any) {
  }
}

export class DeleteTemplate implements Action {
  readonly type = CollectorTemplateActionTypes.DeleteTemplate;

  constructor(public id: string) {
  }
}

export class DeleteTemplateSuccess implements Action {
  readonly type = CollectorTemplateActionTypes.DeleteTemplateSuccess;

  constructor(public template: CollectorTemplate) {
  }
}

export class DeleteTemplateFail implements Action {
  readonly type = CollectorTemplateActionTypes.DeleteTemplateFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = CollectorTemplateActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = CollectorTemplateActionTypes.LoadAllSuccess;

  constructor(public templates: CollectorTemplate[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = CollectorTemplateActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadTemplate implements Action {
  readonly type = CollectorTemplateActionTypes.LoadTemplate;

  constructor(public collectorId: string, public id: string) {
  }
}

export class LoadTemplateSuccess implements Action {
  readonly type = CollectorTemplateActionTypes.LoadTemplateSuccess;

  constructor(public template: CollectorTemplate) {
  }
}

export class LoadTemplateFail implements Action {
  readonly type = CollectorTemplateActionTypes.LoadTemplateFail;

  constructor(public payload: any) {
  }
}
export class DownloadTemplate implements Action {
  readonly type = CollectorTemplateActionTypes.DownloadTemplate;

  constructor(public id: string) {
  }
}

export class DownloadTemplateSuccess implements Action {
  readonly type = CollectorTemplateActionTypes.DownloadTemplateSuccess;

  constructor(public template: CollectorDownloadTemplate) {
  }
}

export class DownloadTemplateFail implements Action {
  readonly type = CollectorTemplateActionTypes.DownloadTemplateFail;

  constructor(public payload: any) {
  }
}


export type CollectorTemplateActions =
  | CreateTemplate
  | CreateTemplateSuccess
  | CreateTemplateFail
  | DeleteTemplate
  | DeleteTemplateSuccess
  | DeleteTemplateFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadTemplate
  | LoadTemplateSuccess
  | LoadTemplateFail
  | DownloadTemplate
  | DownloadTemplateSuccess
  | DownloadTemplateFail;
