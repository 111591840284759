import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ContactListDto, contactListDtoType} from 'app/models/contact-list-dto.model';
import {
  ClientSelectors,
  ContactActions,
  ContactOrganizationActions,
  ContactPersonActions,
  FeatureSelectors,
  OrganizationSelectors,
  PartnerLinkParticipationSelectors, VerifiedUserSelectors
} from 'app/+store';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {CppApiService} from 'app/services/cpp-api.service';
import {debounceTime, filter, first, map, takeUntil} from 'rxjs/operators';
import {MembershipSelectors} from 'app/+store/membership';
import {TranslateService} from '@ngx-translate/core';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {IPartnerLinkParticipationMap} from 'app/+store/partner-link-participation/partner-link-participation.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {EditContactOrganizationDialogComponent} from '../../components/edit-contact-organization-dialog/edit-contact-organization-dialog.component';
import {EditContactPersonDialogComponent} from '../../components/edit-contact-person-dialog/edit-contact-person-dialog.component';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {OrganizationProxyService} from 'app/+store/organization/organization-proxy.service';
import {ProcessParticipantService} from 'app/+store/process-participant/process-participant.service';
import {ProcessParticipation} from 'app/+store/process-participant/process-participant';
import {Router} from '@angular/router';
import {ProcessRoleService} from 'app/+store/process-role/process-role.service';
import {PERSON_FILTER_OPTIONS} from './address-book-table.constants';
import {ContactTypes} from './address-book-table.model';
import {AngularTokenService} from 'angular-token';
import {AddressBookColumnBuilder} from './address-book-column-builder';
import {AddressBookTableHelper} from './address-book-table.helper';
import {AddressBookTableBase} from './address-book-table-base';
import {ContactDeletionDialogComponent} from '../../components/contact-deletion-dialog/contact-deletion-dialog.component';
import {MemberDeletionDialogComponent} from '../../components/member-deletion-dialog/member-deletion-dialog.component';
import {ListingService} from 'app/shared/modules/api/services/listing.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Client} from 'app/+store/client/client';
import {ClientService} from 'app/+store/client/client.service';
import {SelectorAutocompleteComponent} from 'app/shared/modules/person-selector-autocomplete/components/selector-autocomplete/selector-autocomplete.component';
import {EditClientDialogComponent} from 'app/modules/client/modules/client-table/components/edit-client-dialog/edit-client-dialog.component';
import {AddressBookTableRepository} from './address-book-table.repository';
import {WaitingDialogComponent} from 'app/five-f/confirm-dialog/components/waiting-dialog/waiting-dialog.component';

@Component({
  selector: 'dvtx-address-book-table',
  templateUrl: './address-book-table.component.html',
  styleUrls: ['./address-book-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressBookTableComponent extends AddressBookTableBase implements OnInit, OnDestroy, AfterViewInit {
  /*
   * AddressBookTableComponent Interface
   */

  /**
   * Filters the contacts and shows only people/members.
   * Most common case for embedded variants of the address book listing.
   */
  @ViewChild('selectorAutocomplete') selectorAutocomplete: SelectorAutocompleteComponent;
  private _hideOrganizationContacts = false;
  clients$: Observable<Client[]>;
  dialogRef: MatDialogRef<EditClientDialogComponent>;
  allMapping: any[] = [];
  mappingRetrieved: boolean = false;
  clientMappingLoading = true;

  /**
   * Locks the contact type if Project Rooms are attached.
   */
  @Input() lockContactType = true;
  /**
   * Enables the contact import dialog menu entry.
   */
  @Input() showContactImport = true;
  /**
   * Enables the contact creation call to action
   */
  @Input() enableContactCreation = true;
  /**
   *  Enables the Project Room count column
   */
  @Input() enableProjectRoomColumn = false;
  /**
   *  Enables the Two Factor column
   */
  @Input() enableTwoFaColumn = false
  /**
   *  Enables the Partner Link checkbox column, e.g. for the Partner Links visibility variant of this listing
   */
  @Input() enablePartnerLinksColumn = true;
  /**
   * Alternative title for the Partner Links checkbox column
   */
  @Input() partnerLinkColumnTitle = 'PARTNER_LINK.PARTNER_LINKS';
  /**
   * Enables the address book standard action buttons.
   */
  @Input() enableAddressbookActionsColumn = true;
  /**
   * The member and contact column show check icons for according entries.
   */
  @Input() enableMemberColumn = false;
  @Input() enableContactColumn = false;
  /**
   * Enables select column for mass actions.
   * NOTE: This column is currently only present for admins.
   */
  @Input() enableSelectColumn = false;
  /**
   * Enables/Removes the contact deletion button
   */
  @Input() enableDeleteAction = true;
  /**
   * Enables am alternative set of actions for the Project Room Administration variant of the listing
   */
  @Input() enableParticipationActionsColumn = false;

  // For administrational actions: overrides lockContactForDeletion.
  @Input() forceContactDeletionByAdmin = false;

  /**
   * Disables the client column.
   */
  @Input() disableClients = false;

  /**
   * Adds virtual contacts for participants not in address book but having access to Project Rooms.
   * TODO: This is WIP
   */
  @Input() includeOrphanedParticipations = false;

  /**
   * Only show contact entries matching participations aka filters out all contacts without Project Room.
   */
  @Input() set showParticipationsOnly(enable) {
    this.contactDataSource.toggleParticipationsOnly(enable);
  }

  /**
   * Standard address book columns for telephone number, visibility and the organization.
   */
  @Input() disablePhoneColumn = false;
  @Input() disableVisibility = false;
  @Input() disableOrganizationColumn = false;

  @Input() set onFilterByClients(clients) {
    this.filterByClients(clients);
  }

  @Input() set onFilterByOptions(options) {
    this.filterByOptions(options)
  }

  @Input() set clearAllFilters($event) {
    this.onClearAllFilters();
  }

  @Input() set search(term: string) {
    this.filterForm.patchValue({generalFilter: term});
    this.contactDataSource.search(term);
  }

  @Input() set contactType(ctype: ContactTypes) {
    this.filterForm.patchValue({typeFilter: ctype});
    this.contactDataSource.contactType(ctype);
  }

  /*
   * Interface END
   */

  /**
   * Table Setup
   */
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public dataSource: MatTableDataSource<ContactListDto> = new MatTableDataSource<ContactListDto>([]);
  public contactDataSource: AddressBookTableRepository;
  public columnsToDisplay: string[];
  public selection = new SelectionModel<ContactListDto>(true, []);

  /**
   * Filter dropdown for contacts, members, and organization.
   */
  public filterForm: UntypedFormGroup;
  /**
   * Final loading indicator for all address book instance members and initialized table data set.
   */
  public loaded = false;
  private processParticipations = [];
  public processParticipationLoading = true;
  public processParticipationMap: { [id: string]: ProcessParticipation } = {};
  private _processParticipations$ = new BehaviorSubject<ProcessParticipation[]>([]);

  public contactPersonEmailCountMap: { [email: string]: number } = {};
  private memberEmailMap: { [email: string]: boolean } = {};
  public lockContactForDeletion = true;

  // Unneeded: Organization Contacts unsued in favor of clients.
  // legalListingData: IListingElement[];
  filters: any = {
    clients: [],
    options: [this.filterOptions.find(a => a.id === 0)]
  };
  searchForm: UntypedFormGroup;

  @Input() enableToolbar = true;

  @Input() disableEmailEdit = false;

  constructor(protected store: Store<AppState>,
              private fb: UntypedFormBuilder,
              protected _dialog: MatDialog,
              protected _router: Router,
              protected _viewContainerRef: ViewContainerRef,
              private _notifyService: NotificationService,
              private _translateSvc: TranslateService,
              private clientSerivce: ClientService,
              private cppApiService: CppApiService,
              private _organizationProxySvc: OrganizationProxyService,
              private _participantSvc: ProcessParticipantService,
              private _processRoleSvc: ProcessRoleService,
              private _orgProxySvc: OrganizationProxyService,
              private _cdr: ChangeDetectorRef,
              private _ngZone: NgZone,
              private _tokenSvc: AngularTokenService,
              private listingSvc: ListingService,
              public avatarService: AvatarService) {
    super(store, _dialog, _router, _viewContainerRef);

    this.filterForm = this.fb.group({
      typeFilter: ['all'],
      generalFilter: [''],
    });

    this.searchForm = this.fb.group({
      searchTerm: [null]
    });

    this.contactDataSource = new AddressBookTableRepository(this.store, this.clientSerivce,
      this._participantSvc, this._notifyService, this._translateSvc);

    this.updateColumns();
  }

  ngOnInit() {
    this.clients$ = this.store.select(ClientSelectors.getClientsOfSelectedOrg);
    this.contactDataSource.clientMappingLoadingState$.pipe(takeUntil(this.onDestroy)).subscribe(state => this.clientMappingLoading = state);
    this.contactDataSource.processParticipationLoading$.pipe(takeUntil(this.onDestroy)).subscribe(state => this.processParticipationLoading = state);
    this.contactDataSource.loadData();

    // // Initializes the current organization and loads data dependent on organization existence inside +store.
    // // including Partnerlinks and the ProcessParticipant Maps
    this._initializeOrganizationListener();

    // Initializes the userUID, the current user, the administrational persmissions and admin flags.
    this._initializePermissionListener();

    // Initializes the columns based on administrational rights and featureset
    this._initializeColumnsListener();

    // // Select contacts filtered by @Input constraints
    // this._selectContacts();
    //
    // // Final step: Initialize the table data
    // this._createTableDataListener();

    this.updateColumns();

    // // Disabled: Currently unused. We only support Contact People at the moment, contact organizations are filtered
    // // in favor of Client model development. Kept for documentation of the legal-form setup/data base.
    // // this.listingSvc.fetchListingsData('legal-form').pipe(first()).subscribe((res: IListingElement[]) => {
    // //   this.legalListingData = res;
    // // })
  }

  ngOnDestroy() {
    this.contactDataSource.disconnect();
    this.onDestroy.next();
    this.onDestroy.complete();
    this._processParticipations$.complete();
  }

  ngAfterViewInit(): void {
  }

  // Updates the columns depenendet on @Input configuration.
  private updateColumns() {
    this.columnsToDisplay = AddressBookColumnBuilder.updateColumns(this);
  }

  // Initializes the current organization and loads data dependent on organization existence inside +store.
  // including Partnerlinks and the ProcessParticipant Maps
  private _initializeOrganizationListener() {
    this.store.select(OrganizationSelectors.getSelected).pipe(
      filter(org => !!org),
      takeUntil(this.onDestroy),
    ).subscribe((org) => {
      this.organization = org;
      this._cdr.detectChanges();
    });
  }

  private _initializePermissionListener() {
    this.currentUserUID = this._tokenSvc.currentAuthData.uid;

    this.ownId$ = this.store.select('currentUser');
    this.administrationRights$ = this.store.select(MembershipSelectors.getMyMembership);
    this.administrationRights$
      .pipe(filter(p => !!p), takeUntil(this.onDestroy))
      .subscribe(perms => {
        this.isAdmin = perms.hasAdministrationRights;
        this.updateColumns();
      });
  }

  // Initializes the columns based on administrational rights and featureset
  private _initializeColumnsListener() {
    const featureSet$ = this.store.select(FeatureSelectors.getCurrentFeatureSet);
    combineLatest(this.administrationRights$, featureSet$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([perms, featureSet]) => {
        if (perms && featureSet && featureSet['hasPartnerLinks'] && perms.hasAdministrationRights) {
          this.partnerLinksEnabled = true;
          this.updateColumns();
          this._cdr.detectChanges();
        }
      });
  }

  editContact(contact: ContactListDto, i: number) {
    if (contact.type === contactListDtoType.organizationContact) {
      this._dialog.open(EditContactOrganizationDialogComponent, {
        data: {
          contactId: contact.id,
          hasAccount: contact.hasAccount,
          createdBy: contact.createdBy
        }
      });
    } else if (contact.type === contactListDtoType.naturalPersonContact) {
      this._dialog.open(EditContactPersonDialogComponent, {
        data: {
          contactId: contact.id,
          createdBy: contact.createdBy
        }
      });
    }
    this.filterByClients([]);
  }

  masterToggle(selectAll = false) {
    this.isAllSelected() && !selectAll ? this.selection.clear() : this.selection.select(...this.dataSource.filteredData);
    this._isAllSelected();
  }

  isAllSelected() {
    // View functions are called everytime the view renders; This function is O(c^2) because of filter + includes
    // const numSelected = this.selection.selected.filter(a => this.dataSource.filteredData.includes(a)).length;
    // const numRows = this.dataSource.filteredData.length;
    // return numSelected === numRows;
    //
    // Alternative:
    // Change only on actively changing items/clicks. See _isAllSelected()
    return this._isAllSelectedToggle;
  }

  _isAllSelectedToggle = false;

  private _isAllSelected() {
    const numSelected = this.selection.selected.filter(a => this.dataSource.filteredData.includes(a)).length;
    const numRows = this.dataSource.filteredData.length;
    this._isAllSelectedToggle = numSelected === numRows;
    // Change! Global variable. Update view.
    this._cdr.detectChanges();
  }

  toggle($event, row) {
    if ($event && this.selection) {
      this.selection.toggle(row);
      // Change! Update isAllSelected
      this._isAllSelected();
    }
  }

  openContactDeletionDialog($event: MouseEvent, contact: ContactListDto) {
    $event.stopImmediatePropagation();
    this.lockContactForDeletion = this.projectRoomDeletionGuard(contact);
    this._dialog.open(ContactDeletionDialogComponent, {
      data: {
        contact,
        isAdmin: this.isAdmin,
        lockContactForDeletion: this.lockContactForDeletion,
        forceContactDeletionByAdmin: this.forceContactDeletionByAdmin,
        saveChanges: () => {
          if (this.contactDataSource.data.value && this.contactDataSource.data.value.length > 0) {
            this.contactDataSource.data.next(this.contactDataSource.data.value.filter(a => a.email !== contact.email));
          }
        }
      }
    });
  }

  private projectRoomDeletionGuard(contactToBeDeleted) {
    return this.helper.projectRoomDeletionGuard(contactToBeDeleted, this.processParticipationMap, this.contactPersonEmailCountMap);
  }

  openMemberRemovalDialog(member): void {
    this._dialog.open(MemberDeletionDialogComponent, {
      data: {
        organization: this.organization,
        selectedMemberForRemoval: member.naturalProfileId,
        selectedMemberShipForRemoval: member.membershipId,
        selectedMemberEmailForRemoval: member.email,
        currentUserUID: this.currentUserUID,
      }
    });
  }

  editContactFirstName(contact, firstName) {
    if (firstName) {
      this.store.dispatch(new ContactPersonActions.EditFirstName(contact.id, firstName));
      this.contactDataSource.data.value.forEach(element => {
        if (element.id === contact.id) {
          element.firstName = firstName;
          this._cdr.detectChanges();
        }
      });
    }
  }

  editContactLastName(contact, lastName) {
    if (lastName) {
      this.store.dispatch(new ContactPersonActions.EditLastName(contact.id, lastName));
      this.contactDataSource.data.value.forEach(element => {
        if (element.id === contact.id) {
          element.lastName = lastName;
          this._cdr.detectChanges();
        }
      });
    }
  }

  editContactEmail(contact, email) {
    if (email) {
      this.store.dispatch(new ContactPersonActions.EditEmail(contact.id, email));
      this.contactDataSource.data.value.forEach(element => {
        if (element.id === contact.id) {
          element.email = email;
          this._cdr.detectChanges();
        }
      });
    }
  }

  editContactPhoneNumber(contact, phone) {
    if (phone !== undefined && phone !== null) {
      this.store.dispatch(new ContactPersonActions.EditPhoneNumber(contact.id, phone));
      this.contactDataSource.data.value.forEach(element => {
        if (element.id === contact.id) {
          element.telephone = phone;
          this._cdr.detectChanges();
        }
      });
    }
  }

  getContactsByClientId() {}

  createContactClient(contact, client, roleName = '') {
    this.contactDataSource.createClientContact(contact, client, roleName);
  }

  removeContactClient(contact, client) {
    this.contactDataSource.removeClientContact(contact, client);
  }

  private filterByClients(event) {
    this.contactDataSource.selectAssociatedClients(event);
    if (event && event.length > 0) {
      this.filters.clients = event;
    } else {
      this.filters.clients = [];
    }
    this.filterByOptions(this.filters.options);
    this._cdr.detectChanges();
  }

  private filterByOptions(options) {
    if (options && options.length > 0) {
      this.filterForm.controls.typeFilter.patchValue(options[options.length - 1].value);
      this.filters.options = [options[options.length - 1]];
    } else {
      this.filterForm.controls.typeFilter.patchValue('');
      this.filters.options = [];
    }
    setTimeout(() => {
      this._cdr.detectChanges();
      if (this.selectorAutocomplete) {
        this.selectorAutocomplete._cdr.detectChanges();
      }
    }, 100);
  }

  openSettings(contact) {
    this.dialogRef = this._dialog.open(EditClientDialogComponent, {
      panelClass: 'five-f-sidebar-dialog',
      data: {
        contact: contact,
        isContact: true,
        onCloseAction: (data) => {
          if (data) {
            const clientMapping =  this.contactDataSource.generateMapping(data);
            this.contactDataSource.clientMapping$.next(clientMapping);
            this._cdr.detectChanges();
          }
        }
      }
    });

    this.dialogRef.afterClosed().subscribe(_ => {
      if (contact) {
        if (contact.type === contactListDtoType.organizationContact) {
          this.store.dispatch(new ContactOrganizationActions.LoadOne(contact.id));
        } else if (contact.type === contactListDtoType.naturalPersonContact) {
          this.store.dispatch(new ContactActions.LoadOne(contact.id));
        }
      }
    });
  }

  private onClearAllFilters() {
    this.contactDataSource.search({ type: ContactTypes.All, search: null });
    this.contactDataSource.selectAssociatedClients([]);
    this._cdr.detectChanges();
  }

  public identifyByKey(index, item) {
    return item.key;
  }

  public hasTwofactorEnabled(email) {
    return this.store.select(VerifiedUserSelectors.getTwoFactorStatusOfUser(email));
  }
}
