import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AvatarService } from './components/avatar/avatar.service';
import { AvatarsGroupComponent } from './components/avatars-group/avatars-group.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {SimpleAvatarComponent} from './components/simple-avatar/simple-avatar.component';
import {InViewportModule} from 'ng-in-viewport';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        MatTooltipModule,
        InViewportModule,
        MatMenuModule,
        MatCardModule
    ],
    declarations: [
        AvatarComponent,
        SimpleAvatarComponent,
        AvatarsGroupComponent
    ],
    providers: [
        AvatarService
    ],
    exports: [
        AvatarComponent,
        SimpleAvatarComponent,
        AvatarsGroupComponent
    ]
})
export class UserAccountModule {
}
