import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  NavigateToDashboard,
  ProjectActionTypes,
  Publish,
  PublishFail,
  PublishSuccess,
  SaveDraft,
  SaveDraftFail,
  SaveDraftSuccess
} from './project.actions';
import {Project} from './project';
import {ProjectService} from './project.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class ProjectEffects {
  @Effect()
  create$ = this.actions.pipe(
    ofType(ProjectActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.params).pipe(
        first(),
        concatMap((project: Project) => {
          return [new CreateSuccess(project)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  );

  @Effect()
  publish$ = this.actions.pipe(
    ofType(ProjectActionTypes.Publish),
    switchMap((action: Publish) => {
      return this._svc.publish(action.id).pipe(
        first(),
        concatMap((project: Project) => {
          return [new PublishSuccess(project), new NavigateToDashboard(project.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new PublishFail(err));
        }));
    })
  );

  @Effect({dispatch: false})
  navigateToDashboard$ = this.actions.pipe(
    ofType(ProjectActionTypes.NavigateToDashboard),
    switchMap((action: NavigateToDashboard) => {
      try {
        this._router.navigate([`/projects/run/${action.id}/timeline`], {replaceUrl: true})
      } catch (error) {
        console.error(error)
      }
      return of(null);
    })
  );

  @Effect()
  saveDraft$ = this.actions.pipe(
    ofType(ProjectActionTypes.SaveDraft),
    switchMap((action: SaveDraft) => {
      return this._svc.saveDraft(action.id, action.params).pipe(
        first(),
        concatMap((project: Project) => {
          return [new SaveDraftSuccess(project)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveDraftFail(err));
        }));
    })
  );

  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(ProjectActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        concatMap((res: Project[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  loadOne$ = this.actions.pipe(
    ofType(ProjectActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      if (!action.id) {
        const msg = 'Prevented to load a project without ID (id == null)';
        console.error(msg);
        return of(new LoadOneFail(msg));
      }
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((project: Project) => {
          return [new LoadOneSuccess(project)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: ProjectService,
              private _router: Router,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




