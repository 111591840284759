import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './organization.state';

export const stateKey = 'operator-organization';
const getOrganizationState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getOrganizationEntities,
  selectAll: getAllOrganizations,
} = adapter.getSelectors(getOrganizationState);

export const loadingState = createSelector(
  getOrganizationState,
  (_state) => _state.loading
);

export const getOrganizationById = (id: string) => createSelector(
  getOrganizationEntities,
  (organizations) => organizations[id]
);

export const getSelectedOrganization = createSelector(
  getOrganizationState,
  (state) => state.selected
);
