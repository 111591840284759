<div
  *ngIf="text || title"
  class="alert alert-{{color}} d-flex p-0"
  [class.alert-dismissible]="dismissible"
  [class.fade]="dismissible"
  [class.show]="dismissible"
  role="alert">
  <div *ngIf="icon" class="alert--icon">
    <mat-icon class="mr-1">{{icon}}</mat-icon>
  </div>
  <div *ngIf="title || dismissible" class="p-3">
    <div class="d-flex align-items-center alert--content--block">
      <span *ngIf="title" [innerHTML]="title | translate"></span>
    </div>
    <button *ngIf="dismissible" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="text" [innerHTML]="text | translate" class="p-3"></div>
</div>
<ng-content></ng-content>
