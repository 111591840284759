import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {ProcessRoleBuilder, ProjectRoomAdminRoleBuilder} from './process-role.builder';
import {ProcessRole, ProjectRoomAdmin} from './process-role';

@Injectable()
export class ProcessRoleService {
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';
  readonly PROJECT_ROOM_ADMIN_BASE_PATH = 'api/v1/organizations/project_room_admins';

  constructor(private _http: ApiResourceService) {
  }

  getAll(id: string): Observable<ProcessRole[]> {
    const builder = new ProcessRoleBuilder(id);
    return <Observable<ProcessRole[]>>this._http.get<ProcessRoleBuilder, ProcessRole>(builder, `${this.BASE_PATH}/${id}/process_roles`);
  }

  getProjectRoomAdmins(): Observable<ProjectRoomAdmin[]> {
    const builder = new ProjectRoomAdminRoleBuilder();
    return <Observable<ProjectRoomAdmin[]>>this._http.get<ProjectRoomAdminRoleBuilder, ProjectRoomAdmin>(builder, `${this.PROJECT_ROOM_ADMIN_BASE_PATH}`);
  }

  grantProjectRoomAdminRole(email: string): Observable<ProjectRoomAdmin> {
    const builder = new ProjectRoomAdminRoleBuilder();
    const payload = {
      data: {
        attributes: {
          email: email
        }
      }
    };
    return <Observable<ProjectRoomAdmin>>this._http.post<ProjectRoomAdminRoleBuilder, ProjectRoomAdmin>(builder, `${this.PROJECT_ROOM_ADMIN_BASE_PATH}`, payload);
  }

  revokeProjectRoomAdminRole(id: string): Observable<ProjectRoomAdmin> {
    const builder = new ProjectRoomAdminRoleBuilder();
    return <Observable<ProjectRoomAdmin>>this._http.del<ProjectRoomAdminRoleBuilder, ProjectRoomAdmin>(builder, `${this.PROJECT_ROOM_ADMIN_BASE_PATH}/${id}`);
  }
}
