<div>
  <h3>{{ elementName }}
    <mat-icon class="tooltip-info-icon"
              *ngIf="tooltip"
              matTooltip="{{ tooltip }}"
              matTooltipPosition="above"
              matTooltipHideDelay="2000">info</mat-icon>
  </h3>
  <br>
  <mat-radio-group class="example-radio-group"
                   (change)="changeRadio()"
                   [(ngModel)]="selectedModelObject">
    <mat-radio-button [labelPosition]="position" *ngFor="let item of radioChoices" [value]="item.value">
      {{displayPath}}
      {{displayPath !== '' ? item[displayPath] : item.label}}
    </mat-radio-button>
  </mat-radio-group>
</div>
