import {IResource} from 'app/shared/modules/api/models/resource.interface';
import {ITaskStatus} from '../task-status/task-status.interface';
import {TaskResource} from 'app/+store/task-resource/task-resource';
import {TaskComment} from 'app/+store/task-comment/task-comment';
import {IPriority} from "app/shared/modules/priority-selector/component/priority-selector.component";
import {TaskAssignee} from '../task-assignee/task-assignee';

export interface ITaskStatusMap {
  [id: string]: ITaskStatus;
}

export interface TaskPermissions {
  canRead?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  canClose?: boolean;
  canReopen?: boolean;
  canCreate?: boolean;
  canAssignParticipant?: boolean;
  canRemoveParticipant?: boolean;
  canChangeResponsible?: boolean;
  canUploadAttachment?: boolean;
  canDeleteAttachment?: boolean;
  canDownloadAttachment?: boolean;
  canUseLabels?: boolean;
}

export interface ITask extends IResource {
  completedAt: string;
  createdAt: string;
  description: string;
  dueDate: string;
  priority: string;
  processId: string;
  status: ITaskStatus;
  statusScheme: string;
  title: string;
  updatedAt: string;
  color: string;
  favorite: boolean;
  responsibleName: string;
  responsibleEmail: string;
  statuses: ITaskStatus[];
  statusMap: ITaskStatusMap;
  taskType: TaskType;
  documents: TaskResource[];
  collectors: TaskResource[];
  comments: TaskComment[];
  approved: boolean;
  creatorName: string;
  creatorEmail: string;
  order: number;
  processCreatedAt: Date;
}

export enum TaskType {
  Standard = 'standard_task',
  InvoiceApproval = 'invoice_approval'
}

export enum TaskTypeTitle {
  Standard = 'TASK.TASK_TYPES.STANDARD',
  InvoiceApproval = 'TASK.TASK_TYPES.INVOICE_APPROVAL',
  Signature = 'TASK.TASK_TYPES.SIGNATURE'
}

export enum TaskTypeTitleByValue {
  standard_task = 'TASK.TASK_TYPES.STANDARD',
  invoice_approval = 'TASK.TASK_TYPES.INVOICE_APPROVAL',
  ignature = 'TASK.TASK_TYPES.SIGNATURE'
}

export enum TaskColors {
  Red_1 = 'e2445c',
  Red_2 = 'bb3354',
  Pink_1 = 'ff158a',
  Pink_2 = 'ff5ac4',
  Purple_1 = '784bd1',
  Purple_2 = 'a25ddc',
  Green_1 = '00c875',
  Green_2 = '037f4c',
  Blue_1 = '579bfc',
  Blue_2 = '0086c0',
  Blue_3 = '66ccff',
  Grey_1 = 'c4c4c4',
  Grey_2 = '808080',
  Grey_3 = '333333',
  Yellow_1 = 'fdab3d',
  Yellow_2 = 'ffcb00',
  Yellow_3 = 'cab641',
  Lime = '9cd326',
  Orange = 'ff642e',
  Brown = '7f5347'
}

export interface IProcessTaskStat extends IResource {
  allTaskCount: number;
}

// V2

export interface ITaskProcess {
  id?: string;
  title?: string;
  color?: string;
  isClosed?: boolean;
  dueDate?: string;
  createdAt?: string;
  canCreateTask?: boolean;
  processType?: string;
  client?: string;
  icon?:string;
  isSvgIcon?: boolean;
  canAccessProcess?: boolean;
}

export interface ITaskPerson {
  email?: string;
  name?: string;
  status?: string;
}

export interface ITaskDates {
  dueDate?: string;
  completedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  approvedAt?: string;
}

export interface ITaskIndicators {
  documentsCount?: number;
  attachmentsCount?: number;
  referencesCount?: number;
  commentsCount?: number;
  description?: boolean;
}

export interface IIsTask {
  approved?: boolean;
  overdue?: boolean;
  closed?: boolean;
  favorite?: boolean;
}

export interface ITaskMinimal extends IResource {
  order: number;
  title: string;
  color: string;
  process: ITaskProcess;
  taskType: TaskType;
  priority: IPriority;
  status: ITaskStatus;
  statusScheme: string;
  statusMap: ITaskStatusMap;
  statuses: ITaskStatus[];
  creator: ITaskPerson;
  responsible: ITaskPerson;
  assignees: TaskAssignee[];
  dates: ITaskDates;
  indicators: ITaskIndicators;
  overdueDuration: number;
  permissions: TaskPermissions;
  is?: IIsTask;
}

export interface ITaskExtended extends ITaskMinimal {
  description?: string;
  documents?: TaskResource[];
  collectors?: TaskResource[];
  comments?: TaskComment[];
  attachments?: any[];
}
