import {CollectorElementType, ICollectorItem} from '../collector/collector.interface';
import {ItemStatus} from 'app/+store/collector/collector.interface';
import {ApplicableState} from '../cac/cac.interface';

import {IBomDataNode} from './bom-data-node.interface';

/**
 * Generic Data Node.
 *
 * Sub classes are implementing context dependent behavior.
 */
export abstract class BomDataNode implements IBomDataNode {
  type = CollectorElementType.DataNode;

  status: ItemStatus = ItemStatus.Sent;

  processId: string;

  /**
   * Category ID/group ID of node.
   * Conveniance accessor for backwards compatibility.
   * Equals to parentId.
   */
  categoryId: string;

  overdue: boolean;
  documentType: DocumentType;

  trackById: string;

  // Assessment period
  year: number;
  month: number;

  applicable?: ApplicableState;
  important?: boolean;
  low_hanging_fruit?: boolean;
  sum?: number;
  annotation?: string;

  choiceEnabled?: boolean;
  choice?: number;
  choiceOptions?: string[];
  multiChoiceEnabled?: boolean;
  multiChoice?: number[];
  multiChoiceOptions?: string[];

  archivedAt?: Date;

  constructor(public id,
              public parentId,
              public title,
              public description,
              public color,
              public preDueDate,
              public dueDate,
              public startedAt,
              public completedAt,
              public createdAt,
              public updatedAt,
              public order = null,
              public isOverdued: boolean = false,
              public priority = 'medium',
              public startsAt = null,
              public endsAt = null,
              public progress = 0,
              public effort = null,
              public responsibleId = null,
              public lockedAt = null,
              public commentCount = null,
              public unreadCommentCount = null) {
    this.trackById = `${id}|${order}|${updatedAt}`;
  }

  public static updateTrackById(item) {
    item.trackById = `${item.id}|${item.order}|${item.updatedAt}`;
  }

  clone(): BomDataNode {
    throw new Error('Not implemented, yet');
  }
}
