import {Action} from '@ngrx/store';
import {IKanbanItemParams} from './kanban-item.interface';
import {KanbanItem} from './kanban-item';

export enum KanbanItemActionTypes {
  Create = '[KanbanItem] Create',
  CreateSuccess = '[KanbanItem] Create Success',
  CreateFail = '[KanbanItem] Create Fail',
  LoadAll = '[KanbanItem] Load All',
  LoadAllSuccess = '[KanbanItem] Load All Success',
  LoadAllFail = '[KanbanItem] Load All Fail',
  LoadOne = '[KanbanItem] Load One',
  LoadOneSuccess = '[KanbanItem] Load One Success',
  LoadOneFail = '[KanbanItem] Load One Fail',
  Remove = '[KanbanItem] Remove',
  RemoveSuccess = '[KanbanItem] Remove Success',
  RemoveFail = '[KanbanItem] Remove Fail',
  Reset = '[KanbanItem] Reset',

  AddLabel = '[KanbanItem] AddLabel',
  AddLabelSuccess = '[KanbanItem] AddLabel Success',
  AddLabelFail = '[KanbanItem] AddLabel Fail',
  RemoveLabel = '[KanbanItem] RemoveLabel',
  RemoveLabelSuccess = '[KanbanItem] RemoveLabel Success',
  RemoveLabelFail = '[KanbanItem] RemoveLabel Fail',
  UpdateGroup = '[KanbanItem] UpdateGroup',
  UpdateGroupSuccess = '[KanbanItem] UpdateGroup Success',
  UpdateGroupFail = '[KanbanItem] UpdateGroup Fail',
}

export class Create implements Action {
  readonly type = KanbanItemActionTypes.Create;

  constructor(public groupId: string, public params: IKanbanItemParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = KanbanItemActionTypes.CreateSuccess;

  constructor(public kanbanItem: KanbanItem) {
  }
}

export class CreateFail implements Action {
  readonly type = KanbanItemActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = KanbanItemActionTypes.LoadAll;

  constructor(public groupId: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = KanbanItemActionTypes.LoadAllSuccess;

  constructor(public kanbanItems: KanbanItem[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = KanbanItemActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = KanbanItemActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = KanbanItemActionTypes.LoadOneSuccess;

  constructor(public kanbanItem: KanbanItem) {
  }
}

export class LoadOneFail implements Action {
  readonly type = KanbanItemActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = KanbanItemActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = KanbanItemActionTypes.RemoveSuccess;

  constructor(public id: string) {
  }
}

export class RemoveFail implements Action {
  readonly type = KanbanItemActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Reset implements Action {
  readonly type = KanbanItemActionTypes.Reset;

  constructor() {
  }
}


export class AddLabel implements Action {
  readonly type = KanbanItemActionTypes.AddLabel;

  constructor(public referenceId: string, public labelId: string) {
  }
}

export class AddLabelSuccess implements Action {
  readonly type = KanbanItemActionTypes.AddLabelSuccess;

  constructor(public kanbanItem: KanbanItem) {
  }
}

export class AddLabelFail implements Action {
  readonly type = KanbanItemActionTypes.AddLabelFail;

  constructor(public payload: any) {
  }
}

export class RemoveLabel implements Action {
  readonly type = KanbanItemActionTypes.RemoveLabel;

  constructor(public referenceId: string, public labelId: string) {
  }
}

export class RemoveLabelSuccess implements Action {
  readonly type = KanbanItemActionTypes.RemoveLabelSuccess;

  constructor(public kanbanItem: KanbanItem) {
  }
}

export class RemoveLabelFail implements Action {
  readonly type = KanbanItemActionTypes.RemoveLabelFail;

  constructor(public payload: any) {
  }
}

export class UpdateGroup implements Action {
  readonly type = KanbanItemActionTypes.UpdateGroup;

  constructor(public groupId: string, public itemId: string, public newGroupLabelId: string) {
  }
}

export class UpdateGroupSuccess implements Action {
  readonly type = KanbanItemActionTypes.UpdateGroupSuccess;

  constructor(public kanbanItem: KanbanItem) {
  }
}

export class UpdateGroupFail implements Action {
  readonly type = KanbanItemActionTypes.UpdateGroupFail;

  constructor(public payload: any) {
  }
}

export type KanbanItemActions =
  | Create
  | CreateSuccess
  | CreateFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Reset
  | AddLabel
  | AddLabelSuccess
  | AddLabelFail
  | RemoveLabel
  | RemoveLabelSuccess
  | RemoveLabelFail
  | UpdateGroup
  | UpdateGroupSuccess
  | UpdateGroupFail;
