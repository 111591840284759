import {createEntityAdapter, EntityState} from '@ngrx/entity';
import * as dayjs from 'dayjs';
import { TaskResource } from './task-resource';

export interface State extends EntityState<TaskResource> {
  selected?: TaskResource;
  loading: boolean;
}

export const adapter = createEntityAdapter<TaskResource>({
  sortComparer: (l, r) => -(dayjs(l.updatedAt).diff(dayjs(r.updatedAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
