import {Action} from '@ngrx/store';
import {Iam} from 'app/shared/modules/api/models/iam/activity';

export enum IamProcessActivityActionTypes {
  LoadAll = '[IamProcessActivity] Load All',
  LoadAllSuccess = '[IamProcessActivity] Load All Success',
  LoadAllFail = '[IamProcessActivity] Load All Fail',
}

export class LoadAll implements Action {
  readonly type = IamProcessActivityActionTypes.LoadAll;

  constructor(public payload: any) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = IamProcessActivityActionTypes.LoadAllSuccess;

  constructor(public payload: Iam.Activity[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = IamProcessActivityActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export type IamProcessActivityActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail;
