import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Create, CreateFail, CreateSuccess, DocumentSignatureActionTypes} from './document-signature.actions';
import {DocumentSignatureService} from './document-signature.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class DocumentSignatureEffects {
  @Effect()
  create$ = this.actions.pipe(
    ofType(DocumentSignatureActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.getVisualCandidates(action.signature).pipe(
        first(),
        // FIXME @ialhamad @ngrx-upgrade
        concatMap((client: any) => {
          return [new CreateSuccess(client)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: DocumentSignatureService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
