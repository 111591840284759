import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ArtifactKeywordEffects} from './artifact-keyword.effects';
import {ArtifactKeywordService} from './artifact-keyword.service';
import {reducer} from './artifact-keyword.reducer';
import {stateKey} from './artifact-keyword.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ArtifactKeywordEffects])
  ],
  providers: [
    ArtifactKeywordEffects,
    ArtifactKeywordService
  ]
})
export class ArtifactKeywordStoreModule {
}
