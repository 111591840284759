import { ITaskStatusScheme } from "./task-status-scheme.interface";
import { TaskStatus } from "app/+store/task-status/task-status";
import { TaskType } from "app/+store/task/task.interface";

export class TaskStatusScheme implements ITaskStatusScheme {
  readonly type = 'task_status_schemes';

  constructor(public id: string,
              public title: string,
              public description: string,
              public taskType: TaskType,
              public statuses: TaskStatus[],
              public isDefault: boolean = true,
              public isPublished: boolean,
              public creatorEmail: string,
              public modifierEmail: string,
              public publishedAt: Date = null,
              public createdAt: Date,
              public updatedAt: Date = null) {}
}
