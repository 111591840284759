import {SocietyActions, SocietyActionTypes} from './society.actions';
import {adapter, initialState, State} from './society.state';

export function reducer(state = initialState, action: SocietyActions): State {
  switch (action.type) {
    case SocietyActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case SocietyActionTypes.LoadAllSuccess:
      return adapter.setAll(action.societies, {
          ...state,
          loading: false,
      });
    case SocietyActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false
      };
    case SocietyActionTypes.Load:
      return {
        ...state,
        loading: true
      };
    case SocietyActionTypes.LoadSuccess:
      return adapter.upsertOne(action.society, {
        ...state,
        loading: false,
      });
    case SocietyActionTypes.LoadFail:
      return {
        ...state,
        loading: false
      };
  }
  return state;
}

