import {ContactOrganization, createNewContactOrganization} from '../../../models/contact-organization.model';

export interface ContactOrganizationBaseForm {
  discriminator: 'ContactOrganizationBaseForm',
  isValid: boolean,
  contactOrganization: ContactOrganization
}

export function contactOrganizationBaseFormFromContact(contact: ContactOrganization): ContactOrganizationBaseForm {
  return {
    discriminator: 'ContactOrganizationBaseForm',
    isValid: false,
    contactOrganization: contact
  }
}

export function newOrganizationBaseForm(): ContactOrganizationBaseForm {
  return {
    discriminator: 'ContactOrganizationBaseForm',
    isValid: false,
    contactOrganization: createNewContactOrganization()
  }
}
