import {Component} from '@angular/core';

@Component({
  selector: 'dvtx-main-external',
  templateUrl: './main-external.component.html',
  styleUrls: [
    './main-external.component.scss',
    '../main-app/main-app.component.scss'
  ]
})
export class MainExternalComponent {

}
