import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {
  SplitViewContainerDialogComponent
} from 'app/shared/modules/page-container/split-view-container-dialog/split-view-container-dialog.component';
import {
  PreviewBrowserViewmode
} from '../../../../../document-preview/modules/preview-browser/containers/preview-browser/preview-browser.component';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Observable, Subject} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';
import {ProcessOrganizationSelectorService} from 'app/+store/process/process-organization-selector.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ProcessSelectors} from 'app/+store';

export enum PreviewViewType {
    Icon = 'Icon',
    IconButton = 'IconButton',
    ListItem = 'ListItem',
    Dialog = 'Dialog',
    Custom = 'Custom'
}

@Component({
  selector: 'dvtx-document-preview-dialog',
  templateUrl: './document-preview-dialog.component.html',
  styleUrls: ['./document-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentPreviewDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  private onDestroy = new Subject<void>();

  PreviewViewType = PreviewViewType;
  PreviewBrowserViewmode = PreviewBrowserViewmode;

  @Input() action: PreviewViewType = PreviewViewType.IconButton;
  @Input() processId;
  @Input() viewMode = PreviewBrowserViewmode.Document;
  @Input() disabled = true;
  isMember$: Observable<boolean>;

  @Input() documentIds: string[] = [];
  @Input() documentId: string = null;
  @Input() lockedProcess = true;
  @Input() enableTaskCreation = true;
  @Input() selectedDocumentPreviewTab = 0;

  @Output() onCloseDialogue = new EventEmitter();
  @Output() onCreateTask = new EventEmitter();

  @ViewChild('title', {static: true}) title: TemplateRef<any>;
  @ViewChild('context', {static: true}) context: TemplateRef<any>;
  private contextPortal: Portal<any>;
  private titlePortal: Portal<any>;
  private dialogRef: MatDialogRef<SplitViewContainerDialogComponent>;

  isExtraSmall: Observable<BreakpointState>;

  titleOutlet;
  contextOutlet;

  constructor(private _dialog: MatDialog,
    private _store: Store<AppState>,
    private _processOrganizationService: ProcessOrganizationSelectorService,
    private _viewContainerRef: ViewContainerRef,
              private _breakpointObserver: BreakpointObserver) {
    this.isExtraSmall = this._breakpointObserver.observe(
      Breakpoints.XSmall
    );
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this._initContextPortal();
      this._initTitlePortal();
    });
  }

  adjustFullScreen(value) {
    if (value) {
      this.dialogRef.updateSize('100%', '100%');
      this.dialogRef.addPanelClass('five-f-dialogue-fullScreen');
    } else {
      this.dialogRef.updateSize('80vw', '');
      this.dialogRef.removePanelClass('five-f-dialogue-fullScreen');
    }
  }

  openDialog() {
   // console.log(this.disabled)
    if (this.disabled) {
      console.error('The preview dialog is disabled. Exit...')
      return;
    }

    this._initTitlePortal();
    this._initContextPortal();

    this._store.select(ProcessSelectors.getProcessById(this.processId))
    .pipe(first()).subscribe(process => {
      if (process) {
        this._processOrganizationService.select(process);
      }
    });
    this.isMember$ = this._processOrganizationService.isMember$();
    this.dialogRef = this._dialog.open(SplitViewContainerDialogComponent, {
      panelClass: ['five-f-transparent-background-w100-max-width', 'five-f-dialog-border-color'],
      width: '80vw',
      maxWidth: 'unset',
      data: {
        title: this.titlePortal,
        context: this.contextPortal
      }
    });

    const smallDialogSubscription = this.isExtraSmall.pipe(takeUntil(this.onDestroy)).subscribe(size => {
      if (size.matches) {
        this.dialogRef.updateSize('100%', '100%');
      } else {
        this.dialogRef.updateSize('80vw', '');
      }
    });
    this.dialogRef.afterClosed().subscribe(() => {
      if (smallDialogSubscription) smallDialogSubscription.unsubscribe();
    });
    this.dialogRef.beforeClosed().subscribe(() => {
      if (this.onCloseDialogue && this.onCloseDialogue.observers.length > 0) {
        this.onCloseDialogue.emit();
      }
    });
  }

  closeDialog($event = null) {
    try {
      if (this.dialogRef) {
        this.dialogRef.close();
      //  this._processOrganizationService.detach();
      }
    } catch (err) {
      console.error('Cannot close dialog', err);
    }
  }

  private _initTitlePortal() {
    this.titlePortal = this._createPortal(this.titlePortal, this.title);
  }

  private _initContextPortal() {
    this.contextPortal = this._createPortal(this.contextPortal, this.context);
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }
}
