<dvtx-page-header #mainHeader [error]="null" [userSessionEnabled]="false"></dvtx-page-header>

<div class="dvtx-app" style="display: flex;">
  <div class="container-fluid dvtx-app-content dvtx-bg-color"
       id="dvtx-app-content">
    <div class="dvtx-app-content__router-outlet">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
