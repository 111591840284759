import { IOrganization } from './organization.interface';
import {Headquarters} from './headquarters';

export abstract class AbstractOrganization implements IOrganization {
  readonly type = 'organization_records';

  constructor(public id: number, public name: string) {}

  abstract toForm();
}

export class Organization extends AbstractOrganization {
  headQuarters: Headquarters;
  legalFormId: number;

  constructor(public id: number, public name: string) {
    super(id, name);
  }

  toForm() {
    return {
      id: this.id,
      type: this.type,
      name: this.name
    };
  }

  static fromForm(values) {
    return new Organization(values.id, values.name);
  }
}
