import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class ProjectInvitationCountService {

  currentCount$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor() {
  }

  decrement() {
    const current = this.currentCount$.value;
    if (current && current > 0) {
      this.currentCount$.next(current - 1);
    }
  }

  getCount(): Observable<number> {
    return this.currentCount$;
  }

  setCount(count: number) {
    this.currentCount$.next(count);
  }
}
