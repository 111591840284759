<ng-container
  *ngIf="_id && processesNodes$ && (processesNodes$ | async)?.length && (processesNodes$ | async)?.length > 1">
  <!-- <h3 class="mt-3">{{ 'PROJECT.PROJECT_STRUCTURE' | translate }}</h3> -->

  <mat-accordion>
    <mat-expansion-panel style="display: unset !important;padding: 0px !important;"
                         #matExpansionPanel expanded
                         (expandedChange)="setExpansionState($event)"
      hideToggle="true" class="mat-elevation-z0 px-3 px-lg-5">
      <mat-expansion-panel-header (click)="$event.stopPropagation()" style="padding: 0px !important;">
        <mat-panel-title class="d-flex align-items-end m-0">
          <h3 class="m-0">&nbsp;{{ 'PROJECT.PROJECT_STRUCTURE' | translate }}</h3>
        </mat-panel-title>
        <mat-panel-description (click)="$event.stopPropagation()" class="d-flex align-items-center m-0" style="flex-grow: 0;">
          <!-- <button *ngIf="matExpansionPanelElement?.expanded"
          class="dvtx-custom-filter-process-structure-btn"
           mat-button color="gray" matTooltip="{{ 'GENERAL.DISPLAY_OPTIONS' | translate }}"
          [matMenuTriggerFor]="displayMenu">

        </button> -->
        <mat-icon style="margin-right:40px" *ngIf="expansionPanelExpanded"
         matTooltip="{{ 'GENERAL.DISPLAY_OPTIONS' | translate }}"
        [matMenuTriggerFor]="displayMenu" matAffix>tune</mat-icon>
        <div style="position: relative; margin-left: -32px;" class="mb-1">
          <mat-menu #displayMenu="matMenu">
            <div class="px-3 py-1">
              <!-- DISPLAY OPTION MENU: SORT OPTIONS -->
              <a class="h4">{{'SORT.SORTING' | translate}}</a>

              <div style="margin-top: 5px;" *ngIf="settings">
                <mat-radio-group style="display: inline-flex; flex-direction: column;"
                  (change)="changeSortingBy($event)" [value]="settings.id">
                  <div class="mb-1">{{'SORT.ALPHABETICAL' | translate}}</div>
                  <mat-radio-button value="title_asc" style="margin-bottom: 5px;">
                    {{'SORT.ASCENDING' | translate}}
                  </mat-radio-button>
                  <mat-radio-button value="title_desc" style="margin-bottom: 10px;">
                    {{'SORT.DESCENDING' | translate}}
                  </mat-radio-button>
                  <div class="mb-1">{{'SORT.BY_CREATION_DATE' | translate}}</div>
                  <mat-radio-button value="created_at_asc" style="margin-bottom: 5px;">
                    {{'SORT.ASCENDING' | translate}}
                  </mat-radio-button>
                  <mat-radio-button value="created_at_desc" style="margin-bottom: 10px;">
                    {{'SORT.DESCENDING' | translate}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>

              <!-- DISPLAY OPTION MENU: CHECKBOX FILTERS CLOSED -->
              <a class="mb-1 h4">{{'FILTER.FILTER_OPTIONS' | translate}}</a>

              <!-- DISPLAY OPTION MENU: HIDE CLOSED -->
              <div>
                <mat-checkbox (click)="$event.stopPropagation()" [checked]="settings?.hideClosed"
                  matTooltip="{{ 'FILTER.HIDE_CLOSED_WORKFLOWS' | translate }}" (change)="toggleClosed($event)">{{
                  'FILTER.HIDE_CLOSED_WORKFLOWS' | translate }}</mat-checkbox>
              </div>
            </div>
          </mat-menu>
        </div>
          <mat-icon (click)="expansionPanelExpanded = true" style="padding-left: 5px;" *ngIf="!expansionPanelExpanded">unfold_more</mat-icon>
          <mat-icon (click)="expansionPanelExpanded = false" style="padding-left: 5px;" *ngIf="expansionPanelExpanded">unfold_less</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <!-- DISPLAY OPTION MENU -->
      <!-- It is moved to left to be aligned with navigation -->
      <div *ngIf="expansionPanelExpanded" style="padding-left: 10px;">

        <div style="position: relative; margin-left: -25px;">
          <dvtx-process-tree-single-select [nodes]="processesNodes$ | async" (onChange)="navigateToProcess($event)"
            [initialExpandRoot]="true"></dvtx-process-tree-single-select>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
