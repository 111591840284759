import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {MotdEffects} from './motd.effects';
import {MotdService} from './motd.service';
import {reducer} from './motd.reducer';
import {stateKey} from './motd.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([MotdEffects])
  ],
  providers: [
    MotdEffects,
    MotdService
  ]
})
export class MotdStoreModule {
}
