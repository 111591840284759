import {IThirdPartyConfirmation, IThirdPartyConfirmationRow} from './thirdPartyConfirmation';
import {RequestType} from './request-type.enum';
import {RequestCreation} from './request-creation.interface';

export class ThirdPartyConfirmationSafekeepingRow implements IThirdPartyConfirmationRow {
  constructor(public id: number,
              public color: string,
              public lfd: string,
              public referenced_at: string,
              public due_date: string,
              public comment: string,
              public language: string,
              public request_type: RequestType,
              public gegenstand_aufbewahrer_name: string,
              public safekeepingRows: Array<{
                id: number,
                lfd: string,
                menge: number,
                gegenstand_art: string,
                gegenstand_zustand: string,
                gegenstand_aufbewahrer_lagerungart: string,
              }>,
              public gegenstand_ansprechpartner_name: string,
              public gegenstand_ansprechpartner_email: string,
              public gegenstand_ansprechpartner_handy: string
  ) {

  }

  toRequestCreation(): RequestCreation {
    return {
      companyName: this.gegenstand_aufbewahrer_name,
      id: this.id,
      color: this.color,
      sequenceNo: this.lfd,
      requestDate: this.referenced_at,
      dueDate: this.due_date,
      fileAttachments: undefined,
      contactPerson: {
        name: this.gegenstand_ansprechpartner_name,
        email: this.gegenstand_ansprechpartner_email,
        phone: this.gegenstand_ansprechpartner_handy
      },
      requestType: this.request_type,
      language: this.language,
      comment: this.comment,
      safekeepingItems: this.safekeepingRows.map((row) => {
        return {
          id: row.id,
          sequenceNo: this.lfd,
          requestDate: this.referenced_at,
          dueDate: this.due_date,
          quantity: row.menge,
          name: row.gegenstand_art,
          condition: row.gegenstand_zustand,
          storage: row.gegenstand_aufbewahrer_lagerungart,
          confirm: undefined,
          attachment: undefined,
          comment: this.comment
        };
      })
    };
  }
}

export class ThirdPartyConfirmationSafekeeping implements IThirdPartyConfirmation {
  emailAttachmentFilename: string;
  state: string;

  constructor(public id: number,
              public type: string,
              public parent_process_id: number,
              public referenced_at: string,
              public due_date: string,
              public color: string,
              public language: string,
              public request_type: RequestType,
              public rows: ThirdPartyConfirmationSafekeepingRow[]) {

  }

  toForm() {
    return undefined;
  }
}
