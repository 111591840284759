import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {PersonSelectorAutocompleteModule} from 'app/shared/modules/person-selector-autocomplete/person-selector-autocomplete.module';
import {StatusSelectorModule} from 'app/shared/modules/status-selector/status-selector.module';
import {PrioritySelectorModule} from 'app/shared/modules/priority-selector/priority-selector.module';
import {CreateTaskDialogComponent} from './component/create-task-dialog/create-task-dialog.component';
import {TasksTemplatesSelectorComponent} from './component/tasks-templates-selector/tasks-templates-selector.component';
import {MessageEditorModule} from 'app/shared/modules/message-editor/message-editor.module';
import {TasksComponent} from './containers/tasks/tasks.component';
import {ProcessDialogModule} from 'app/modules/workflow-engine/modules/process-dialog/process-dialog.module';
import {MyTasksContainerComponent} from './containers/my-tasks-container/my-tasks-container.component';
import {AlertsModule} from 'app/modules/alerts/alerts.module';
import {PersonsFilterModule} from 'app/shared/modules/persons-filter/persons-filter.module';
import {CreateTaskButtonComponent} from './component/create-task-button/create-task-button.component';
import {FilePickerModule} from 'app/modules/file-picker/file-picker.module';
import {InViewportModule} from 'ng-in-viewport';
import {AllTasksContainerComponent} from './containers/all-tasks-container/all-tasks-container.component';
import {TasksListingHeadlineComponent} from './component/tasks-listing-headline/tasks-listing-headline.component';
import {ProcessNavigationModule} from '../workflow-engine/modules/process-navigation/process-navigation.module';
import {DashboardStatsModule} from './dashboard-stats/dashboard-stats.module';
import {TasksViewerModule} from './modules/task-viewer/task-viewer.module';
import {ListingTasksModule} from './modules/listing-tasks/listig-tasks.module';
import {ProcessTasksContainerComponent} from './containers/process-tasks-container/process-tasks-container.component';
import {TooltipModule} from 'app/five-f/tooltip/tooltip.module';
import {ClientsFilterModule} from 'app/shared/modules/clients-filter/clients-filter.module';
import {ProcessTasksNavigationModule} from './modules/process-tasks-navigation/process-tasks-navigation.module';
import { NoElementFoundModule } from 'app/five-f/no-element-found/no-element-found.module';
import {LabelsModule} from 'app/five-f/labels/labels.module';
import {FiltersContainerModule} from 'app/five-f/filters/filters-container.module';
import {TasksFiltersModule} from './modules/tasks-filters/tasks-filters.module';


@NgModule({
    imports: [
        SharedModule,
        PersonSelectorAutocompleteModule,
        StatusSelectorModule,
        PrioritySelectorModule,
        MessageEditorModule,
        ProcessDialogModule,
        AlertsModule,
        PersonsFilterModule,
        FilePickerModule,
        InViewportModule,
        ProcessNavigationModule,
        DashboardStatsModule,
        TasksViewerModule,
        ListingTasksModule,
        TooltipModule,
        ClientsFilterModule,
        ProcessTasksNavigationModule,
        LabelsModule,
        FiltersContainerModule,
        TasksFiltersModule,
        NoElementFoundModule
    ],
    declarations: [
        TasksComponent,
        ProcessTasksContainerComponent,
        MyTasksContainerComponent,
        AllTasksContainerComponent,
        CreateTaskDialogComponent,
        TasksTemplatesSelectorComponent,
        CreateTaskButtonComponent,
        TasksListingHeadlineComponent,
    ],
    exports: [
        TasksComponent,
        ProcessTasksContainerComponent,
        MyTasksContainerComponent,
        AllTasksContainerComponent,
        CreateTaskDialogComponent,
        CreateTaskButtonComponent,
    ]
})
export class TasksModule {
}
