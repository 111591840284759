import {
  IIsTask,
  IProcessTaskStat,
  ITaskDates,
  ITaskExtended,
  ITaskIndicators,
  ITaskPerson,
  ITaskProcess,
  ITaskStatusMap,
  TaskPermissions,
  TaskType
} from './task.interface';
import {ITaskStatus} from '../task-status/task-status.interface';
import {TaskStatus} from '../task-status/task-status';
import {TaskResource} from 'app/+store/task-resource/task-resource';
import {TaskComment} from 'app/+store/task-comment/task-comment';
import {IPriority} from 'app/shared/modules/priority-selector/component/priority-selector.component';
import {TaskAssignee} from 'app/+store/task-assignee/task-assignee';

export class Task implements ITaskExtended {
  readonly type = TaskType.Standard;
  public id = null;
  public organizationId;

  appendices: TaskResource[] = [];

  constructor(
    public order: number = null,
    public title: string = null,
    public color: string = null,
    public process: ITaskProcess = {},
    public taskType: TaskType = null,
    public priority: IPriority = {},
    public status: TaskStatus = null,
    public statusScheme: string = null,
    public statusMap: ITaskStatusMap = {},
    public statuses: ITaskStatus[] = [],
    public creator: ITaskPerson = {},
    public responsible: ITaskPerson = {},
    public assignees: TaskAssignee[] = [],
    public dates: ITaskDates = {},
    public indicators: ITaskIndicators = {},
    public overdueDuration: number = null,
    public permissions: TaskPermissions = {},
    public is: IIsTask = {},
    public description: string = null,
    public documents: TaskResource[] = [],
    public collectors: TaskResource[] = [],
    public comments: TaskComment[] = [],
    public attachments: any[] = []
  ) {
  }

  static sortByOrder(l, r) {
    if (l.order === r.order) return 0;
    if (!l.order) return -1;
    if (!r.order) return 1;
    return l.order < r.order ? -1 : 1;
  }
}

export class ProcessTaskStat implements IProcessTaskStat {
  readonly type = '';

  constructor(public id: string, public allTaskCount: number = null) {
  }
}
