<div [formGroup]="form" class="container-fluid">
  <div class="row py-1">
    <div class="col-12 col-lg-12">
      <dvtx-contact-chips-cva formControlName="contact_people" [contacts]="contacts">
      </dvtx-contact-chips-cva>
    </div>
  </div>
  <div class="row py-1">
    <div class="col-12">
      <dvtx-base-process-info [form]="form"></dvtx-base-process-info>
    </div>
  </div>
</div>
