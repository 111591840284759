import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './process-management.state';

export const stateKey = 'process-management';
const getPrivilegedProcessState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getPrivilegedProcessEntities,
  selectAll: getAllPrivilegedProcesss,
} = adapter.getSelectors(getPrivilegedProcessState);

export const getLoadingState = createSelector(
  getPrivilegedProcessState,
  state => state.loading
);

export const getById = (id: string) => createSelector(
  getPrivilegedProcessEntities,
  (entities) => {
    if (!id) return null;
    return entities[id];
  });
