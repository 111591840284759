<div [matTooltip]="(disabled ? 'SHOPPING_CART.NO_AVAILABLE_USER_LICENCES' : 'CONTACTS.ADD_OR_INVITE_EMPLOYEE') | translate">
  <button color="primary"
          [matMenuTriggerFor]="addEmployeeMenu"
          [disabled]="disabled"
          mat-raised-button type="button">
    <mat-icon aria-label="back" matAffix>person_add</mat-icon>
    {{ 'CONTACTS.ADD_EMPLOYEE' | translate }}
  </button>
</div>
<mat-menu #addEmployeeMenu="matMenu" yPosition="below" xPosition="before" class="add-employee-dropdown">
  <button class="d-flex align-items-center" mat-menu-item dvtxEmployee [selectedIndexTab]="'0'">
    <mat-icon>person_add</mat-icon>
    <div class="item__text d-flex flex-column">
      <span>{{ 'CONTACTS.CREATE_EMPLOYEE' | translate }}</span>
      <sub>{{ 'CONTACTS.CREATE_EMPLOYEE_INFO' | translate }}</sub>
    </div>
  </button>

  <button class="d-flex align-items-center" mat-menu-item dvtxEmployee [selectedIndexTab]="'1'">
    <mat-icon>email</mat-icon>
    <div class="item__text d-flex flex-column">
      <span>{{ 'INVITATIONS.INVITE_EMPLOYEE' | translate }}</span>
      <sub>{{ 'CONTACTS.INVITE_EMPLOYEE_INFO' | translate }}</sub>
    </div>
  </button>
</mat-menu>
