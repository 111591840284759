import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TaskStatusEffects} from './task-status.effects';
import {TaskStatusService} from './task-status.service';
import {reducer} from './task-status.reducer';
import {stateKey} from './task-status.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([TaskStatusEffects])
  ],
  providers: [
    TaskStatusEffects,
    TaskStatusService
  ]
})
export class TaskStatusStoreModule {
}
