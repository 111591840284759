import {ProcessAttributeActions, ProcessAttributeActionTypes} from './process-attribute.actions';
import {adapter, initialState, State} from './process-attribute.state';

export function reducer(state = initialState, action: ProcessAttributeActions): State {
  switch (action.type) {
    case ProcessAttributeActionTypes.LoadAll:
    case ProcessAttributeActionTypes.LoadOne:
    case ProcessAttributeActionTypes.Create:
    case ProcessAttributeActionTypes.UpdateAttribute:
    case ProcessAttributeActionTypes.Destroy:
      return {
        ...state,
        loading: true
      };
    case ProcessAttributeActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case ProcessAttributeActionTypes.DestroySuccess:
      return adapter.removeOne(action.attribute.id, {
        ...state,
        loading: false,
      });
    case ProcessAttributeActionTypes.LoadOneSuccess:
    case ProcessAttributeActionTypes.CreateSuccess:
    case ProcessAttributeActionTypes.UpdateAttributeSuccess:
      return adapter.upsertOne(action.attribute, {
        ...state,
        loading: false,
      });
    case ProcessAttributeActionTypes.LoadAllFail:
    case ProcessAttributeActionTypes.LoadOneFail:
    case ProcessAttributeActionTypes.CreateFail:
    case ProcessAttributeActionTypes.DestroyFail:
    case ProcessAttributeActionTypes.UpdateAttributeFail:
      return {
        ...state,
        loading: false
      };
  }
  return state;
}

