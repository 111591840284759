import { Action } from '@ngrx/store';
import { ProjectStatistics } from './project-statistics';
import { Update } from '@ngrx/entity';

export enum ProjectStatisticsActionTypes {
  LoadAll = '[ProjectStatistics] Load All',
  LoadAllSuccess = '[ProjectStatistics] Load All Success',
  LoadAllFail = '[ProjectStatistics] Load All Fail',
}

export class LoadAll implements Action {
  readonly type = ProjectStatisticsActionTypes.LoadAll;

  constructor(public processId: string = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ProjectStatisticsActionTypes.LoadAllSuccess;

  constructor(public statistics: ProjectStatistics) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ProjectStatisticsActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export type ProjectStatisticsActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail;
