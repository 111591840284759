import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Process} from 'app/+store/process/process';
import {ClientSelectors} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {Client} from 'app/+store/client/client';

/**
 * Client cell for the process listing with client-with-id column.
 * This cell is optimized on getting the client by UUID.
 */
@Component({
  selector: 'dvtx-client-cell',
  templateUrl: './client-cell.component.html',
  styleUrls: ['./client-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientCellComponent implements OnInit, OnDestroy {
  private clientId$ =  new BehaviorSubject<string>(null);
  public client$: Observable<Client>;

  @Input()
  private set clientId(cid: string) {
    this.clientId$.next(cid);
  }

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.client$ = this.clientId$.pipe(switchMap(cid => this.store.select(ClientSelectors.getClientById(cid))));
  }

  ngOnDestroy() {
    this.clientId$.complete();
  }
}
