import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {reducer} from './process-lookup.reducer';
import {stateKey} from './process-lookup.selectors';
import {ProcessLookupEffects} from './process-lookup.effects';
import {ProcessLookupService} from './process-lookup.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ProcessLookupEffects])
  ],
  providers: [
    ProcessLookupEffects,
    ProcessLookupService
  ]
})
export class ProcessLookupStoreModule {
}
