import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {CollectorDownloadTemplate, CollectorTemplate} from './collector-template';

export class CollectorTemplateBuilder implements IApiResourceBuilder<CollectorTemplate> {
  fromResponse(data): CollectorTemplate {
    return new CollectorTemplate(
      data.id,
      data.attributes.title,
      data.attributes.description,
      data.attributes.creator,
      data.attributes.owner,
      data.attributes.created_at,
      data.attributes.updated_at);
  }

  toRequest(_: CollectorTemplate) {
    return null;
  }
}
export class CollectorDownloadTemplateBuilder implements IApiResourceBuilder<CollectorDownloadTemplate> {
  fromResponse(data): CollectorDownloadTemplate {
    return new CollectorDownloadTemplate(
      data.attributes.id,
      data.attributes.title,
      data.attributes.groups
    );
  }

  toRequest(_: CollectorDownloadTemplate) {
    return null;
  }
}
