import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Quickshare} from './quickshare';
import * as dayjs from 'dayjs';

export interface State extends EntityState<Quickshare> {
  selected?: Quickshare;
  loading: boolean;
}

export const adapter = createEntityAdapter<Quickshare>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
