import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {DocumentPreviewDocument} from './document-preview-document';
import {DocumentPreviewPreview} from '../document-preview-preview/document-preview-preview';

export class DocumentPreviewDocumentBuilder implements IApiResourceBuilder<DocumentPreviewDocument> {
  fromResponse(data): DocumentPreviewDocument {
    const documents = data.attributes.documents;
    return documents.map(doc => {
      const previews = doc.previews.map(prev => new DocumentPreviewPreview(
        prev.id,
        prev.page,
        prev.status,
        null,
        prev.url,
        null,
        prev.width,
        prev.height,
        doc.id
      ));

      return new DocumentPreviewDocument(
        doc.id,
        doc.pages,
        doc.display_name,
        doc.filename,
        doc.mime_type,
        previews
      );
    });
  }

  toRequest(document: DocumentPreviewDocument) {
    return null;
  }
}
