import {IResource} from 'app/shared/modules/api/models/resource.interface';

export namespace GrantThornton {
  export class TeamsChannel implements IResource {
    readonly type = 'ms_teams_channels';

    constructor(public id: string,
                public name: string,
                public team_id: string,
                public team_name: string) {
    }
  }

  export class SharepointFolder implements IResource {
    readonly type = 'ms_sharepoint_folders';
    path: string;
    children: SharepointFolder[] = [];

    constructor(public id: string,
                public name: string,
                public parent_id: string,
                public level: number) {
    }

    static computeTreeStructure(folders: SharepointFolder[]): SharepointFolder[] {
      const folderMap = {};
      const roots = []
      folders.forEach(f => {
        folderMap[f.id] = f;
      });

      folders.forEach(f => {
        if (f.parent_id) {
          const parent: SharepointFolder = folderMap[f.parent_id];
          if (parent) {
            parent.children.push(f);
          } else {
            roots.push(f);
          }
        } else {
          roots.push(f);
        }
      });
      SharepointFolder.computeLevels(roots, 0);
      return SharepointFolder.flattenStructure(roots);
    }

    static computeLevels(folders: SharepointFolder[], level) {
      folders.forEach(f => {
        f.level = level;
        SharepointFolder.computeLevels(f.children, f.level + 1);
      })
    }

    static flattenStructure(roots: SharepointFolder[]): SharepointFolder[] {
      const result: SharepointFolder[] = [];
      roots.forEach(r => {
        SharepointFolder.iterateTree(r, result);
      })
      return result;
    }

    static iterateTree(folder: SharepointFolder, result: SharepointFolder[]) {
      result.push(folder);
      folder.children.forEach(c => {
        SharepointFolder.iterateTree(c, result);
      })
    }
  }

  /**
   *
   * Attributes of API model.
   *
   * @settings.ms_team_id = ms_team_id
   * @settings.ms_team_title = ms_team_title
   * @settings.ms_channel_id = ms_channel_id
   * @settings.ms_channel_title = ms_channel_title
   * @settings.ms_folder_id = ms_folder_id
   * @settings.ms_folder_name = ms_folder_name
   * @settings.ms_folder_path = ms_folder_path
   * @settings.error_message = ms_error_message
   * @settings.performer = performer
   * @settings.process = @process
   */
  export class ChannelSyncSettings implements IResource {
    readonly type = 'grant_thornton_sync_settings';
    path: string

    constructor(public id: string, // Corresponding to process ID.
                public ms_team_id: string,
                public ms_team_title: string,
                public ms_channel_id: string,
                public ms_channel_title: string,
                public ms_folder_id: string,
                public ms_folder_name: string,
                public ms_folder_path: string,
                public auto_sync_enabled: boolean,
                public error_message: string = null) {
    }
  }
}
