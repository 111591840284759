import {first, startWith} from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';
import { ProcessService } from 'app/+store/process/process.service';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';

@Component({
  selector: 'dvtx-truncated-description',
  templateUrl: './truncated-description.component.html',
  styleUrls: ['./truncated-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TruncatedDescriptionComponent implements OnInit, OnDestroy {
  private refreshSubject$: Subject<any> = new Subject();
  @ViewChild('truncatedContentContainer', { static: true }) truncatedContentContainer: ElementRef;
  @Input() numberOfLines = 3;
  //used for the collecto description editing
  @Input() editCollectoDescription = false;
  @Input() disabled = false;
  @Input() private collecto;
  @Output()
  public onDescriptionChange = new EventEmitter();


  @Input() set value(val: string) {
    if (this.truncatedContentContainer) {
      this.truncatedContentContainer.nativeElement.innerHTML = val;
      if (this.value && this.value !== val) {
        this.showMore();
        this.hasTruncated = true;
        if (!val) {
          this.hasTruncated = false;
        }
      }
      this._value = val;
      this.refreshSubject$.next(this.value);
      // used to refresh the show more and less button
      if (this.editCollectoDescription) {
        setTimeout(() => {
          this.showMore();
          setTimeout(() => {
            this.showLess();
          }, 200);
          this._cdr.detectChanges();
        }, 200);
      }
    }
  }

  get value(): string {
    return this._value;
  }

  public hasTruncated = false;
  public truncationDisabled = false;
  public _value: string = '';

  constructor(public _cdr: ChangeDetectorRef, private _processSvc: ProcessService, private _notifyService: NotificationService) {
    this.refreshSubject$.pipe(startWith(''));
  }

  ngOnInit(): void {
    this._cdr.detectChanges();
  }

  ngOnDestroy() {
    this.refreshSubject$.complete();
  }

  public truncatedHandler(res: boolean) {
    if (this.hasTruncated === res) {
      return;
    }

    if (res) {
      this.hasTruncated = res;
    } else {
      if (this.editCollectoDescription) {
        this.hasTruncated = res;
      }
      this._cdr.markForCheck();
    }
    this._cdr.detectChanges();
  }

  public showMore() {
    this.truncationDisabled = true;
    this._cdr.detectChanges();
  }

  public showLess() {
    this.truncationDisabled = false;
    this._cdr.detectChanges();
  }

  // save the collecto description function for editing
    public saveNewDescription(value: string, property: string) {
      this._processSvc.setProcessProperty(this.collecto.id, property, value, true).pipe(first()).subscribe(process => {
        this.value = value;
        if (this.collecto) {
          this.collecto.description = value;
        }
        this._cdr.detectChanges();
        if(this.onDescriptionChange) {
          this.onDescriptionChange.emit(value);
        }
        this._notifyService.success('PROJECT_ROOM_SETTINGS.CHANGES_APPLIED');
      }, err => {
        this._notifyService.error('PROJECT_ROOM_SETTINGS.CHANGES_FAILED');
        console.error(err);
      });
    }
}
