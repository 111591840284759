
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LicenceAssignment } from '../models/licence';
import { ResourceBuilder } from 'app/shared/modules/api/builders/resource.builder';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Organization } from 'app/shared/modules/api/models/organization';

@Injectable()
export class LicenceAssignmentService {
  basePath;
  readonly subpath: string = 'sales/licence_assignments';
  readonly organizationsPath: string = 'sales/organizations';

  constructor(private _tokenService: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.subpath}`;
    this.organizationsPath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.organizationsPath}`;
  }

  getAll(): Observable<LicenceAssignment[]> {
    return this._tokenService.get(this.basePath).pipe(
      map(res => <LicenceAssignment[]>ResourceBuilder.fromResponse(res)),
      catchError(_ => observableOf([])),);
  }

  getAttachedOrganizations(): Observable<Organization[]> {
    return this._tokenService.get(this.organizationsPath).pipe(
      map(res => {
        return <Organization[]>ResourceBuilder.fromResponse(res)
      }),
      catchError(_ => observableOf([])),);
  }

  getMy(): Observable<LicenceAssignment[]> {
    return this._tokenService.get(`${this.basePath}/my`).pipe(
      map(res => <LicenceAssignment[]>ResourceBuilder.fromResponse(res)),
      catchError(_ => observableOf([])),);
  }

  create(organizationId): Observable<LicenceAssignment> {
    const payload = { data: { attributes: { organization_id: organizationId } } };
    return this._tokenService.post(`${this.organizationsPath}/register`, payload).pipe(
      map(res => <LicenceAssignment>ResourceBuilder.fromResponse(res)),
      catchError(_ => observableOf(null)),);
  }

  destroy(membershipId): Observable<LicenceAssignment> {
    return this._tokenService.delete(`${this.basePath}/${membershipId}`).pipe(
      map(res => <LicenceAssignment>ResourceBuilder.fromResponse(res)),
      catchError(_ => observableOf(null)),);
  }
}
