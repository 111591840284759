import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  ClientActionTypes,
  Create,
  CreateFail,
  CreateSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Save,
  SaveFail,
  SaveSuccess,
} from './client.actions';
import {Client} from './client';
import {ClientService} from './client.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class ClientEffects {
  @Effect()
  create$ = this.actions.pipe(
    ofType(ClientActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.client).pipe(
        first(),
        concatMap((client: Client) => {
          return [new CreateSuccess(client)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  );

  @Effect()
  update$ = this.actions.pipe(
    ofType(ClientActionTypes.Save),
    switchMap((action: Save) => {
      return this._svc.update(action.client).pipe(
        first(),
        switchMap((client: Client) => {
          return [new SaveSuccess(client)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveFail(err));
        }));
    })
  );

  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(ClientActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.dense).pipe(
        first(),
        concatMap((clients: Client[]) => {
          // const clients: Update<Client>[] = [];
          // for (const item of res) {
          //   clients.push( item);
          // }
          return [new LoadAllSuccess(clients)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  loadOne$ = this.actions.pipe(
    ofType(ClientActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((client: Client) => {
          return [new LoadOneSuccess(client)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: ClientService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
