import {SimpleAddress, SimpleAddressResponse, SimpleEmailAddress, SimpleEmailAddressResponse, SimplePhoneNumber, SimplePhoneNumberResponse} from '../modules/contacts/models/contact.interface';
import {ResourceIdentifierObject, ResourceObject} from './json-api.model';

export class NaturalPerson {
  public id: string;
  public title: string;
  public honorific: string;
  public name?: string;
  public lastName: string;
  public firstName: string;
  public mainEmailAddress: SimpleEmailAddress;
  public mainAddress: SimpleAddress;
  public mainPhoneNumber: SimplePhoneNumber;
  phoneNumbers: SimplePhoneNumber[];
  emailAddresses: SimpleEmailAddress[];
  addresses: SimpleAddress[];
  public membershipId: string;
}

/**
 * Alternative Implementation for JSON-API From Response
 *
 * @param res Resource, which is a slice of the JSON API response, either from `data` or as part of another `include`
 * @param resources Includes of the whole response, to resolve needed relationships.
 *
 * 1. Example where `NaturalPersonResponse` is part of the includes:
 * const res: Root<NaturalPersonResponse[]> = ... // JSON-API
 * const persons: NaturalPersonResponse[] = res.included
 *   .filter(i => i.type === 'natural_person')
 *   .map(p => naturalPersonFromResponse(p, res.included)
 *
 * 2. Example where `NaturalPersonResponse` is `data` itself:
 * const res: Root<NaturalPersonResponse[]> = ... // JSON-API
 * const person = naturalPersonFromResponse(res.data, res.included)
 */
// TODO: Convert example 1 to its own method, which call this one below, have the key `natural_persons` as a const withing the method itself
export function naturalPersonfromJsonAPI(res: NaturalPersonResponse, resources: ResourceObject[]): NaturalPerson {
  const ret = new NaturalPerson();
  ret.id = res.id
  ret.honorific = res.attributes.honorific;
  ret.lastName = res.attributes.last_name;
  ret.title = res.attributes.title_id;
  ret.firstName = res.attributes.first_name;
  ret.name = res.attributes.last_name + ', ' + res.attributes.first_name;
  const mailData = resources.find(x => res.relationships.main_email_address.data && x.id === res.relationships.main_email_address.data.id) as SimpleEmailAddressResponse;
  if (mailData) {
    ret.mainEmailAddress = new SimpleEmailAddress();
    ret.mainEmailAddress.locationOrType = mailData.attributes.address_type_id;
    ret.mainEmailAddress.emailAddress = mailData.attributes.email_address;
    ret.mainEmailAddress.id = mailData.id;
  }

  // address
  const addressData = resources.find(x => res.relationships.main_address.data && x.id === res.relationships.main_address.data.id) as SimpleAddressResponse;
  if (addressData) {
    ret.mainAddress = new SimpleAddress();
    ret.mainAddress.locationOrType = addressData.attributes.address_type_id;
    ret.mainAddress.city = addressData.attributes.city;
    ret.mainAddress.countryName = addressData.attributes.country;
    ret.mainAddress.street = addressData.attributes.street;
    ret.mainAddress.streetNo = addressData.attributes.street_number;
    ret.mainAddress.zip = addressData.attributes.post_code;
    ret.mainAddress.id = addressData.id;
  } else {
    ret.mainAddress = new SimpleAddress()
  }

  // phonenumber
  const phoneData = resources.find(x => res.relationships.main_phone_number.data && x.id === res.relationships.main_phone_number.data.id) as SimplePhoneNumberResponse;
  if (phoneData) {
    ret.mainPhoneNumber = new SimplePhoneNumber();
    ret.mainPhoneNumber.countryCode = phoneData.attributes.country_code;
    ret.mainPhoneNumber.phoneNumber = phoneData.attributes.phone_number;
    ret.mainPhoneNumber.locationOrType = phoneData.attributes.address_type_id;
    ret.mainPhoneNumber.id = phoneData.id;
  } else {
    ret.mainPhoneNumber = new SimplePhoneNumber()
  }

  // further phone numbers
  const furtherPhoneNumbers: SimplePhoneNumber[] = [];
  for (const phoneNumRel of res.relationships.phone_numbers.data) {
    const phoneNumRelId = phoneNumRel.id;
    const phoneNum = new SimplePhoneNumber();

    const foundPhoneNum = resources.find(x => x.id === phoneNumRelId) as SimplePhoneNumberResponse;
    phoneNum.id = foundPhoneNum.id;
    phoneNum.countryCode = foundPhoneNum.attributes.country_code;
    phoneNum.locationOrType = foundPhoneNum.attributes.address_type_id;
    phoneNum.phoneNumber = foundPhoneNum.attributes.phone_number;

    furtherPhoneNumbers.push(phoneNum)
  }
  ret.phoneNumbers = furtherPhoneNumbers;

  // futher email adresses
  const furtherEmailAddresses: SimpleEmailAddress[] = [];
  for (const emailRel of res.relationships.email_addresses.data) {
    const emailRelId = emailRel.id;
    const emailAddr = new SimpleEmailAddress();

    const foundEmailAddr = resources.find(x => x.id === emailRelId) as SimpleEmailAddressResponse;
    emailAddr.id = foundEmailAddr.id;
    emailAddr.emailAddress = foundEmailAddr.attributes.email_address;
    emailAddr.locationOrType = foundEmailAddr.attributes.address_type_id;

    furtherEmailAddresses.push(emailAddr);
  }
  ret.emailAddresses = furtherEmailAddresses;

  // further adresses
  const furtherAdresses: SimpleAddress[] = [];
  for (const addressRel of res.relationships.addresses.data) {
    const addressId = addressRel.id;
    const foundAddress = resources.find(x => x.id === addressId) as SimpleAddressResponse;
    const address = new SimpleAddress();
    address.city = foundAddress.attributes.city;
    address.countryName = foundAddress.attributes.country;
    address.locationOrType = foundAddress.attributes.address_type_id;
    address.street = foundAddress.attributes.street;
    address.streetNo = foundAddress.attributes.street_number;
    address.zip = foundAddress.attributes.post_code;
    address.id = foundAddress.id;
    furtherAdresses.push(address);
  }
  ret.addresses = furtherAdresses;

  return ret
}

export interface NaturalPersonResponse extends ResourceObject<{
  title_id: string,
  honorific: string,
  first_name: string,
  last_name: string,
  name?: string,
}, {
  addresses: {
    data: ResourceIdentifierObject[]
  },
  email_addresses: {
    data: ResourceIdentifierObject[]
  },
  phone_numbers: {
    data: ResourceIdentifierObject[]
  },
  main_address: {
    data: ResourceIdentifierObject
  },
  main_email_address: {
    data: ResourceIdentifierObject
  },
  main_phone_number: {
    data: ResourceIdentifierObject
  }
}> {
}
