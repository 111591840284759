import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {OperatorSystemHealthService} from './system-health.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    OperatorSystemHealthService
  ]
})
export class OperatorSystemHealthStoreModule {
}
