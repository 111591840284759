import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StringUtils} from 'app/lib/string_utils';
import {adapter, State} from './email-to-name.state';

export const stateKey = 'emailToNameNew';
const getEmailToNameState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getEmailToNameEntities,
  selectAll: getAllEmailToName,
} = adapter.getSelectors(getEmailToNameState);

export const getByEmail = (email: any) => createSelector(
  getEmailToNameEntities,
  emailToNamesMap => {
    const normalizedEmail = StringUtils.normalizeString(email);
    return emailToNamesMap[normalizedEmail]
  }
);

export const getNameByEmail = (email: any) => createSelector(
  getEmailToNameEntities,
  emailToNamesMap => {
    const normalizedEmail = StringUtils.normalizeString(email);
    return emailToNamesMap[normalizedEmail] ? emailToNamesMap[normalizedEmail].name : email;
  }
);
