import {VerifiedUserActions, VerifiedUserActionTypes} from './verified-user.actions';
import {adapter, initialState, State} from './verified-user.state';

export function reducer(state = initialState, action: VerifiedUserActions): State {
  switch (action.type) {
    case VerifiedUserActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case VerifiedUserActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case VerifiedUserActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

