import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ProcessTreeNode} from './process-tree';
import * as dayjs from 'dayjs';

export interface State extends EntityState<ProcessTreeNode> {
  loading: boolean;
}

export const adapter = createEntityAdapter<ProcessTreeNode>({
  sortComparer: (l, r) => -(dayjs(r.createdAt).diff(dayjs(l.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  loading: false
});
