import {Observable, of as observableOf} from 'rxjs';

import {catchError, map, switchMap} from 'rxjs/operators';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Injectable} from '@angular/core';
import * as foxdoxStatusActions from '../actions/foxdox-status.actions';


import {FoxdoxStatusService} from '../shared/modules/api/services/foxdox-status.service';
import {ApiError} from '../models';
import {IResourceError} from '../shared/modules/api/models/resource-error.interface';
import {FoxdoxStatus} from '../shared/modules/api/models/foxdoxStatus';

@Injectable()
export class FoxdoxStatusEffects {

  @Effect()
  getFoxdoxStatus$: Observable<Action> = this.actions$
    .pipe(
      ofType(foxdoxStatusActions.GET_STATUS),
      switchMap((_action: foxdoxStatusActions.GetStatus) => {
        return this._svc.getFoxStatus().pipe(
          catchError(error => {
            return observableOf(error);
          }))
      }),
      map((status) => {  //
        const typeOfApiError: boolean = status[0] instanceof ApiError;
        if (!(typeOfApiError)) {
          return new foxdoxStatusActions.GetStatusSuccess(status)

        } else {
          const initialstate = new FoxdoxStatus();
          for (const i in status) {
            if (i <= status.length) {
              const myError: IResourceError = {
                status: status[i].status,
                title: status[i].title,
              };
              initialstate.errors.push(myError);
            }

          }
          return new foxdoxStatusActions.GetStatusFailure(initialstate);
        }
    }),
    catchError((error: any) => {
      const initialstate = new FoxdoxStatus();
      const myError: IResourceError =  {
        status: error[0].status,
        title:  error[0].title,
      };
      initialstate.errors.push(myError);
      return observableOf(new foxdoxStatusActions.GetStatusFailure(initialstate));
  }),);

  constructor(private _svc: FoxdoxStatusService,
              private actions$: Actions) {
  }
}
