import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {DocumentPreviewEffects} from './document-preview.effects';
import {DocumentPreviewService} from './document-preview.service';
import {reducer} from './document-preview.reducer';
import {stateKey} from './document-preview.selectors';
import {PvmsApiResourceService} from './pvms-api-resource.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([DocumentPreviewEffects])
  ],
  providers: [
    DocumentPreviewEffects,
    PvmsApiResourceService,
    DocumentPreviewService
  ]
})
export class DocumentPreviewStoreModule {
}
