import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './kanban-item.state';

export const stateKey = 'kanban-item';
const getKanbanItemState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getKanbanItemEntities,
  selectAll: getAllKanbanItems,
} = adapter.getSelectors(getKanbanItemState);

export const getSelected = createSelector(
  getKanbanItemState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getKanbanItemEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllKanbanItems,
  (entities) => entities
);

export const getAllForGroup = (groupId) => createSelector(
  getAllKanbanItems,
  (entities) => entities.filter(item => item.labels && item.labels.find(label => label && label.groupId === groupId))
);

export const getByReferenceId = (referenceId) => createSelector(
  getAllKanbanItems,
  (entities) => entities.find(item => item.referenceId === referenceId)
);

export const getLabelsByReferenceId = (referenceId) => createSelector(
  getAllKanbanItems,
  (entities) => (entities.find(item => item.referenceId === referenceId) && entities.find(item => item.referenceId === referenceId).labels) || []
);

export const loadingState = createSelector(
  getKanbanItemState,
  (state) => state.loading
);
