import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {ProcessIconComponent} from './components/process-icon/process-icon.component';


@NgModule({
  declarations: [
    ProcessIconComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    ProcessIconComponent
  ]
})
export class ProcessIconModule {
}
