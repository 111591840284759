import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ArtifactKeyword} from './artifact-keyword';

export interface State extends EntityState<ArtifactKeyword> {
  selected?: ArtifactKeyword;
  loading: boolean;
}

export const adapter = createEntityAdapter<ArtifactKeyword>();

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
