import {AuditContactVerification, AuditContactVerificationRow} from '../models/audit-contact-verification';

export namespace AuditContactVerificationBuilder {
  export function parseRow(row) {
    return new AuditContactVerificationRow(
      row.id,
      row.identifier,
      row.abbreviation,
      row.organization_name,
      row.country_code,
      row.first_name,
      row.last_name,
      row.role,
      row.email,
      row.phone,
      row.mobile,
      row.verified_at,
      row.verified_by_id,
      row.due_date,
      row.notification_language,
      row.modified_by_tbp_at,
      row.modified_by_tbp_name,
      row.comment,
      row.global_comment,
      row.reviewer_first_name,
      row.reviewer_last_name,
      row.reviewer_job_title,
      row.reviewer_organization_name,
      row.reviewer_city,
      row.reviewer_country,
      row.reviewed_at,
      row.reviewer_confirmed,
    )
  }

  export function fromResponse(response): AuditContactVerification {
    const attrs = response.attributes;

    const res = new AuditContactVerification(
      response.id,
      attrs.rows.map(AuditContactVerificationBuilder.parseRow),
      attrs.business_year_end,
      attrs.business_year_start,
      attrs.contractee,
      attrs.contractor,
      attrs.due_date,
      attrs.recipient_name,
      attrs.sender_name,
      attrs.title,
      attrs.token,
      attrs.comment,
      attrs.global_comment,
      attrs.reviewer_first_name,
      attrs.reviewer_last_name,
      attrs.reviewer_job_title,
      attrs.reviewer_organization_name,
      attrs.reviewer_city,
      attrs.reviewer_country,
      attrs.reviewed_at,
      attrs.reviewer_confirmed,
    );
    res.signature = attrs.signature;
    res.auditors = attrs.auditors;
    res.profile = attrs.profile;
    res.redirectUrl = attrs.redirect_url;
    return res;
  }

  export function toRequest(record: AuditContactVerification) {
    return {

      data: {
        id: record.id,
        type: record.type,
        attributes: {
          due_date: record.due_date,
          business_year_end: record.business_year_end,
          business_year_start: record.business_year_start,
          contractee: record.contractee,
          contractor: record.contractor,
          recipient_name: record.recipient_name,
          sender_name: record.sender_name,
          title: record.title,
          token: record.token,
          comment: record.comment,
          global_comment: record.global_comment,
          reviewer_first_name: record.reviewer_first_name,
          reviewer_last_name: record.reviewer_last_name,
          reviewer_job_title: record.reviewer_job_title,
          reviewer_organization_name: record.reviewer_organization_name,
          reviewer_city: record.reviewer_city,
          reviewer_country: record.reviewer_country,
          reviewed_at: record.reviewed_at,
          reviewer_confirmed: record.reviewer_confirmed,
          rows: record.rows.map((row) => {
            return {
              id: row.id,
              identifier: row.identifier,
              abbreviation: row.abbreviation,
              organization_name: row.organizationName,
              country_code: row.countryCode,
              first_name: row.firstName,
              last_name: row.lastName,
              role: row.role,
              email: row.email,
              phone: row.phone,
              mobile: row.mobile,
              verified_at: row.verifiedAt,
              verified_by_id: row.verifiedById,
              due_date: row.due_date,
              notification_language: row.notificationLanguage,
              modified_by_tbp_at: row.modified_by_tbp_at,
              modified_by_tbp_name: row.modified_by_tbp_name,
              comment: row.comment,
              global_comment: row.global_comment,
              reviewer_first_name: row.reviewer_first_name,
              reviewer_last_name: row.reviewer_last_name,
              reviewer_job_title: row.reviewer_job_title,
              reviewer_organization_name: row.reviewer_organization_name,
              reviewer_city: row.reviewer_city,
              reviewer_country: row.reviewer_country,
              reviewed_at: row.reviewed_at,
              reviewer_confirmed: row.reviewer_confirmed,
            };
          })
        }
      }
    };
  }
}
