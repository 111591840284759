import {DocumentTemplateActions, DocumentTemplateActionTypes} from './document-template.actions';
import {adapter, initialState, State} from './document-template.state';

export function reducer(state = initialState, action: DocumentTemplateActions): State {
  switch (action.type) {
    case DocumentTemplateActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case DocumentTemplateActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case DocumentTemplateActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };
    case DocumentTemplateActionTypes.RemoveSuccess:
      return adapter.removeOne(action.payload.id, state);
  }
  return state;
}

