import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { BookmanClient } from './bookman-client';

export interface State extends EntityState<BookmanClient> {
  selected?: BookmanClient;
  loading: boolean;
}

export const adapter = createEntityAdapter<BookmanClient>();

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
