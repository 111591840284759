import {Appendix, SpecializedAppendix} from '../models/appendix';
import {ProcessArtifactBuilder} from 'app/+store/process-artifact/process-artifact.builder';
export class AppendixBuilder {
  public static fromResponseList(dataArray): Appendix[] {
    const ret: Appendix[] = [];
    for (const element of dataArray) {
      ret.push(AppendixBuilder.fromResponse(element))
    }
    return ret;
  }

  public static fromResponse(data): Appendix {
    const obj = new Appendix();
    const builder = new ProcessArtifactBuilder(data['id']);
    obj.id = data['id'];
    obj.title = data['title'];
    obj.applicable = data['applicable'];
    obj.dueDate = data['due_date'];
    obj.comment = data['comment'];
    obj.globalComment = data['global_comment'];
    obj.relativeDmsPath = data['relative_dms_path'];
    obj.currency = data['currency'];
    obj.componentMateriality = data['component_materiality'];
    obj.toleranceMateriality = data['tolerance_materiality'];
    obj.toleranceLimit = data['tolerance_limit'];
    obj.auditComponentAuditorCommunicationsId = data['audit_component_auditor_communication_process_id'];
    obj.auditComponentAuditorCommunicationProcessId = data['audit_component_auditor_communications_id'];
    obj.appendixUploadUrl = data['appendix_upload_url'];
    obj.artifacts = (data['artifacts'] || []).map((artifact) => builder.fromResponse(artifact.data));
    obj.artifactContributions = (data['artifact_contributions'] || []).map((artifact) => builder.fromResponse(artifact.data));
    obj.createdAt = data['created_at'];
    obj.updatedAt = data['updated_at'];
    obj.referenceId = data['reference_id'];
    return obj;
  }

  public static toRequest(obj: Appendix): {} {
    return {
      id: obj.id,
      title: obj.title,
      applicable: obj.applicable,
      due_date: obj.dueDate,
      comment: obj.comment,
      global_comment: obj.globalComment,
      relative_dms_path: obj.relativeDmsPath,
      currency: obj.currency,
      component_materiality: obj.componentMateriality,
      tolerance_materiality: obj.toleranceMateriality,
      tolerance_limit: obj.toleranceLimit,
      audit_component_auditor_communications_id: obj.auditComponentAuditorCommunicationsId,
      audit_component_auditor_communication_process_id: obj.auditComponentAuditorCommunicationProcessId,
      appendix_upload_url: obj.appendixUploadUrl,
      created_at: obj.createdAt,
      updated_at: obj.updatedAt,
    }
  }
}


export class SpecificAppendixBuilder {
  public static fromResponseList(dataArray): SpecializedAppendix[] {
    const ret: SpecializedAppendix[] = [];
    if (dataArray !== undefined) {
      for (const element of dataArray) {
        ret.push(SpecificAppendixBuilder.fromResponse(element))
      }
    }
    return ret;
  }

  public static fromResponse(data): SpecializedAppendix {
    const obj = <SpecializedAppendix>AppendixBuilder.fromResponse(data);
    obj.contactId = data['contact_id'];
    obj.appendixId = data['appendix_id'];
    obj.processId = data['process_id'];
    obj.status = data['status'];

    return obj;
  }

  public static toRequest(obj: SpecializedAppendix): {} {
    return {
      id: obj.id,
      title: obj.title,
      applicable: obj.applicable,
      due_date: obj.dueDate,
      comment: obj.comment,
      global_comment: obj.globalComment,
      relative_dms_path: obj.relativeDmsPath,
      currency: obj.currency,
      component_materiality: obj.componentMateriality,
      tolerance_materiality: obj.toleranceMateriality,
      tolerance_limit: obj.toleranceLimit,
      audit_component_auditor_communications_id: obj.auditComponentAuditorCommunicationsId,
      audit_component_auditor_communication_process_id: obj.auditComponentAuditorCommunicationProcessId,
      created_at: obj.createdAt,
      updated_at: obj.updatedAt,
      contact_id: obj.contactId,
      appendix_id: obj.appendixId,
      process_id: obj.processId,
      status: obj.status,
    }
  }
}
