import {IResource} from 'app/shared/modules/api/models/resource.interface';

export enum ProcessTreeNodeType {
  Process = 'Process',
  Task = 'Task',
  Folder = 'Folder',
  Document = 'Document',
  Default = 'Default',
  Root = 'Root',
  CollectoElement = 'CollectoElement'
}

export interface IProcessTreeNode extends IResource {
  type: ProcessTreeNodeType;

  parentId: string;
  title: string;
  active?: boolean;
  closed?: boolean;
  updatedAt: Date;
  createdAt?: Date;

  icon?: string;
  isSvgIcon?: boolean;
  isProject?: boolean;
  isProjectRoom?: boolean;
  isCollecto?: boolean;
  isCac?: boolean;
}
