import {Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {IContactRequest} from '../../../../../../workflow-variations/models/subarea-verifier-request/step1/subarea-verifier-request-check.interface';
import {SubareaVerifierRequestCreateService} from '../../../../../../../../../../../+store/audit-order/subarea-verifier-request-create.service';
import {AuditContactVerificationRow} from '../../../../../../../../../../../shared/modules/api/models/audit-contact-verification';

@Component({
  selector: 'dvtx-audit-contact-confirm-item',
  templateUrl: './audit-contact-confirm-item.component.html',
  styleUrls: ['./audit-contact-confirm-item.component.scss']
})

export class AuditContactConfirmItemComponent implements OnInit {
  @Input() contact: AuditContactVerificationRow;

  _form: UntypedFormGroup;
  @Input() set form(form: UntypedFormGroup) {
    this._form = form;
    if (this.isReadOnly) {
      this._form.get('firstName').disable();
      this._form.get('lastName').disable();
      this._form.get('role').disable();
      this._form.get('email').disable();
      this._form.get('mobile').disable();
      this._form.get('phone').disable();
    } else {
      this._form.enable();
    }
  }

  get form() {
    return this._form;
  }

  _isReadOnly: boolean;
  @Input() set isReadOnly(isReadOnly: boolean) {
    this._isReadOnly = isReadOnly;
    if (isReadOnly) {
      this._form.get('firstName').disable();
      this._form.get('lastName').disable();
      this._form.get('role').disable();
      this._form.get('email').disable();
      this._form.get('mobile').disable();
      this._form.get('phone').disable();
    } else {
      this._form.enable();
    }
  }
  get isReadOnly() {
    return this._isReadOnly;
  }

  constructor() {}

  ngOnInit() {
  }

  deleteContact() {
    // this.requestCreateService.deleteContact(<IContactRequest>this.form.value);
  }
}
