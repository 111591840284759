import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {ProcessParticipantAcl} from './process-participant-acl';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';

export class ProcessParticipantAclBuilder implements IApiResourceBuilder<ProcessParticipantAcl> {
  constructor(private _processId: string) {}

  fromResponse(data): ProcessParticipantAcl {
    return new ProcessParticipantAcl(
      data.id,
      this._processId,
      data.attributes.email,
      data.attributes.sid,
      data.attributes.participant_id,
      data.attributes.name,
      data.attributes.contact_type,
      data.attributes.process_contact_type,
      data.attributes.access_type,
      data.attributes.organization_admin,
      data.attributes.process_owner,
      data.attributes.process_admin,
      data.attributes.read,
      data.attributes.write,
      data.attributes.close_process,
      data.attributes.add_participant,
      data.attributes.remove_participant,
      data.attributes.alter_settings,
      data.attributes.start_sub_process,
      data.attributes.upload_document,
      data.attributes.download_document,
      data.attributes.delete_document,
      data.attributes.audit_trail,
      data.attributes.sync_folder,
      data.attributes.read_by_app,
      data.attributes.write_by_app,
      data.attributes.read_by_sync_client,
      data.attributes.write_by_sync_client,
      data.attributes.grant_process_admin,
      data.attributes.grant_read,
      data.attributes.grant_write,
      data.attributes.can_create_task,
      data.attributes.can_be_participant_of_task,
      data.attributes.can_access_my_tasks,
      data.attributes.can_access_all_tasks,
      data.attributes.can_set_completed_task,
      data.attributes.can_delete_task
    );
  }

  toRequest(_: ProcessParticipantAcl) {
    return null;
  }
}
