import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CommentEffects} from './comment.effects';
import {CommentService} from './comment.service';
import {reducer} from './comment.reducer';
import {stateKey} from './comment.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([CommentEffects])
  ],
  providers: [
    CommentEffects,
    CommentService
  ]
})
export class CommentStoreModule {
}
