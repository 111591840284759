import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  ProcessActionActionTypes,
  Run,
  RunSuccess,
} from './process-action.actions';
import {ProcessActionService} from './process-action.service';
import {ProcessAction} from './process-action';
import {ApiCommandService} from 'app/+store/process-action/api-command.service';
import * as ProcessParticipantActions from '../process-participant/process-participant.actions';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class ProcessActionEffects {
  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(ProcessActionActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.id).pipe(
        first(),
        concatMap((processActions: ProcessAction[]) => {
          return [new LoadAllSuccess(processActions)];
        }),
        catchError(err => {
          this._notifyService.error('AUTH.NOT_FOUND_OR_NOT_PERMITTED');
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  runCmd$ = this.actions.pipe(
    ofType(ProcessActionActionTypes.Run),
    switchMap((action: Run) => {
      return this._apiCmdSvc.execute(action.command).pipe(
        first(),
        concatMap(result => {
          const id = result.id;
          return [
            new RunSuccess(result),
            new LoadAll(id),
            new ProcessParticipantActions.LoadAllRefresh(id),
            // new ProcessEventActions.LoadAll(id)
          ]
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: ProcessActionService,
              private _apiCmdSvc: ApiCommandService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




