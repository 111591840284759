import * as model from './user.interface';
import * as operatorModel from '../../operator/user/user';

/// <reference path="./user.interface.ts" />
export namespace Tenant {
  export class User implements model.Tenant.IUser {
    readonly type = 'user_accounts';

    lastSignInAt: Date;
    hasOtp = false;

    total: number;
    perPage: number;
    records: number;

    creatorEmail: string;
    creatorName: string;

    authProvider: operatorModel.Operator.UserAuthProviderType;

    memberships: model.Tenant.IOrganizationMembership[] = []

    constructor(public id: string,
                public email: string,
                public firstName: string,
                public lastName: string,
                public license: boolean,
                public createdAt: Date,
                public confirmedAt: Date,
                public lockedAt: Date = null) {}
  }

  export class UserStats {
    readonly id: 'user_stats';
    readonly type: 'user_stats';

    constructor(public all: number,
                public memberOnly: number,
                public bpOnly: number,
                public cavOnly: number) {}
  }

  export class TenantAdmin {
    readonly type = 'tenant_admins';

    constructor(public id,
                public email: string,
                public userId: string,
                public firstName: string,
                public lastName: string,
                public createdAt: string) {}
  }
}
