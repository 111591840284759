import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ProcessManagementService} from './process-management.service';
import {StoreModule} from '@ngrx/store';
import {stateKey} from './process-management.selectors';
import {reducer} from './process-management.reducer';
import {EffectsModule} from '@ngrx/effects';
import {ProcessManagementEffects} from './process-management.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ProcessManagementEffects])
  ],
  providers: [
    ProcessManagementService,
    ProcessManagementEffects
  ]
})
export class ProcessManagementStoreModule {
}
