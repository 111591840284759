import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {Society} from './society';

export class SocietyBuilder implements IApiResourceBuilder<Society> {
  constructor(public cacId: string) {}
  
  fromResponse(data): Society {
    return null;
  }

  toRequest(recipient: Society) {
    return null;
  }
}
