import {Action} from '@ngrx/store';
import {DocumentPreview} from './document-preview';

export enum DocumentPreviewActionTypes {
  RequestPreviews = '[DocumentPreview] RequestPreviews',
  RequestPreviewsSuccess = '[DocumentPreview] RequestPreviews Success',
  RequestPreviewsFail = '[DocumentPreview] RequestPreviews Fail',
}

export class RequestPreviews implements Action {
  readonly type = DocumentPreviewActionTypes.RequestPreviews;

  constructor(public ids: string[]) {
  }
}

export class RequestPreviewsSuccess implements Action {
  readonly type = DocumentPreviewActionTypes.RequestPreviewsSuccess;

  constructor(public previews: DocumentPreview[]) {
  }
}

export class RequestPreviewsFail implements Action {
  readonly type = DocumentPreviewActionTypes.RequestPreviewsFail;

  constructor(public payload: any = null) {
  }
}

export type DocumentPreviewActions =
  | RequestPreviews
  | RequestPreviewsSuccess
  | RequestPreviewsFail;
