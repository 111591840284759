import {LicenceAssignmentActions, LicenceAssignmentActionTypes} from './licence-assignment.actions';
import {adapter, initialState, State} from './licence-assignment.state';

export function reducer(state = initialState, action: LicenceAssignmentActions): State {
  switch (action.type) {
    case LicenceAssignmentActionTypes.Add:
      return {
        ...state,
        loading: true,
      };
    case LicenceAssignmentActionTypes.AddSuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: false,
      });
    case LicenceAssignmentActionTypes.Remove:
      return {
        ...state,
        loading: true,
      };
    case LicenceAssignmentActionTypes.RemoveSuccess:
      return adapter.removeOne(action.payload, {
        ...state,
        loading: false
      });
    case LicenceAssignmentActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case LicenceAssignmentActionTypes.LoadAllSuccess:
      return adapter.setAll(action.payload, {
          ...state,
          loading: false,
      });
    case LicenceAssignmentActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };
    case LicenceAssignmentActionTypes.LoadAttachedOrganizations:
      return {
        ...state,
        loading: true
      };
    case LicenceAssignmentActionTypes.LoadAttachedOrganizationsSuccess:
      return {
        ...state,
        loading: false,
        licencedOrganizations: action.organizations
      };
    case LicenceAssignmentActionTypes.LoadAttachedOrganizationsFail:
      return {
        ...state,
        loading: false
      };
  }
  return state;
}
