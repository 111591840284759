import {DmsAccountType, ISlimFolder} from './slim-folder.interface';

export class SlimFolder implements ISlimFolder {
  readonly type = 'slim_folders';
  children: SlimFolder[] = [];

  constructor(
    public id: string,
    public name: string,
    public sourceId: string,
    public parentId: string,
    public dmsAccountType: DmsAccountType,
    public folderCount: number,
    public processCount: number,
    public documentCount: number,
    public bookmanCockpitEnabled: boolean,
    public deletable: boolean,
    public editable: boolean
  ) {
  }

  static sortByName = (self: SlimFolder, other: SlimFolder) => {
    if (!self || !other) return -1;
    if (self.name < other.name) {
      return -1;
    }
    if (self.name > other.name) {
      return 1;
    }
    return 0;
  };
}
