export const environment = {
  production: true,
  webUrl: 'https://staging.5fsoftware.de',
  envName: 'staging',
  maxFileSize: 250000,
  dmsPath: 'https://5f.foxdox.de',
  websocketServer: 'wss://ws.staging.5fsoftware.de',
  websocketPath: '/cable',
  contactApiBase: 'https://api.staging.5fsoftware.de',
  contactApiPath: 'api/v1/contacts',
  resumableUploadApiBase: '',
  tusServer: '',
  derApiBase: 'https://der.staging.5fsoftware.de',
  ddfeApiBase: 'https://dfe.staging.5fsoftware.de',
  // Contact API service is deprecated in favor of CPP service. TODO: Revise components and remove
  cppApiUrl: 'https://cpp.staging.5fsoftware.de/',
  pvmsApiUrl: 'https://pvms.staging.5fsoftware.de',
  // User profile service. MS to access and manage user avatars (beside User CPP profile).
  upmsApiUrl: 'https://upms.staging.5fsoftware.de',
  recatchaSiteKey: '6LeCC0oUAAAAAHCPXYMAq-wbYjuMrYmbmpfEbC5x',
  froalaKey: 'lSA3D-17C2F2F1B2C1D1B2sGXd1WWTDSGXYOUKh1KINLb1OG1g1H-8D1B3D3D1E6D1F2B4G4I4==',
  kanbanApiUrl: 'https://kanban.staging.5fsoftware.de',
  wtsApiUrl: 'https://wts.staging.5fsoftware.de',
  collectoSrvApi: 'https://msc.staging.5fsoftware.de',
  fpSignLoginPage: 'https://app.staging.fp-sign.com',
  fileApiBase: 'https://api.staging.5fsoftware.de',
  milestoneApiBase: 'https://date.staging.5fsoftware.de',
  signApiBase: 'https://sign.staging.5fsoftware.de',
  customerApiBase: 'https://api.grantthornton.5fsoftware.org',
  msgApiBase: 'https://msg.staging.5fsoftware.de',
  datevApiUrl: 'https://login.datev.de/openid/authorize',
  datevClientId: '1424b74d-ec09-56b2-a40f-1201ac54128f',
  token_service_config: {
    apiBase: 'https://api.staging.5fsoftware.de',
    apiPath: 'api/v1',

    signInPath: 'auth/sign_in',
    signInRedirect: null,
    signInStoredUrlStorageKey: null,

    signOutPath: 'auth/sign_out',
    validateTokenPath: 'auth/validate_token',
    signOutFailedValidate: true,

    registerAccountPath: 'auth',
    deleteAccountPath: 'auth',
    registerAccountCallback: window.location.href,

    updatePasswordPath: 'auth',
    resetPasswordPath: 'auth/password',
    resetPasswordCallback: window.location.href,

    // oAuthBase: window.location.origin,
    // oAuthPaths: {
    //   github: 'auth/github'
    // },
    // oAuthCallbackPath: 'oauth_callback',
    // oAuthWindowType: 'newWindow',
    // oAuthWindowOptions: null,

    userTypes: null,

    globalOptions: {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
  },
};
