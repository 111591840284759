import { Component, EventEmitter, Input, Output } from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FileUtils} from 'app/lib/file_utils';

@Component({
  selector: 'dvtx-quick-upload',
  templateUrl: './quick-upload.component.html',
  styleUrls: ['./quick-upload.component.scss']
})
export class QuickUploadComponent {
  supportedFileTypes: string[] = FileUtils.DEFAULT_SUPPORTED_FILE_TYPES;
  maxFileSize: number = FileUtils.DEFAULT_MAX_FILE_SIZE;

  @Input() uploader;
  hasBaseDropZoneOver = false;

  externalAvailable = false;
  password =  null;

  @Input() dmsPathChooser = true;

  @Input() form: UntypedFormGroup;

  @Output() fileOver = new EventEmitter();
  @Output() onRemove = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  public fileOverBase($event: any): void {
    this.hasBaseDropZoneOver = $event;
  }

  /**
   * @returns {Array} of invalid files because of extension/size
   */
  getInvalidFiles() {
    const files = this.uploader.queue;
    const invalidFiles = [];
    files.forEach(fileItem => {
      const invalidFile = this.validateFile(fileItem);
      if (invalidFile) {
        invalidFiles.push(invalidFile);
      }
    });
    return invalidFiles;
  }

  validateFile(fileItem) {
    const extension = this._getExtension(fileItem.file.name);


    if (this.supportedFileTypes.indexOf(extension) === -1) {
      return {
        name: fileItem.file.name,
        reason: 'Dateiformat wird nicht unterstützt: ' + extension
      };
    }

    if (fileItem.file.size / 1024 / 1024 > this.maxFileSize) {
      return {
        name: fileItem.file.name,
        reason: `Datei größer als ${ this.maxFileSize} MB`
      };
    }
    return null;
  }

  pathSelectorButtonColor() {
    return this.form.get('dms_folder_id').valid ? 'grey' : 'primary';
  }

  private _getExtension(name) {
    return name.split('.').pop().toLowerCase();
  }
}
