import {IEmailFooter, FooterType} from './email-footer.interface';

export class EmailFooter implements IEmailFooter {
  readonly type = FooterType.Orgnizational;

  constructor(public id,
              public title: string,
              public content: string,
              public creatorEmail: string,
              public publishedAt: string,
              public updatedAt: string,
              public createdAt: string,
              public published: boolean,
              ) {}
}

