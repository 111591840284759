import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './motd.state';
import {OrganizationSelectors} from 'app/+store/organization';

export const stateKey = 'motd';
const getMotdState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getMotdEntities,
  selectAll: getAllMotds,
} = adapter.getSelectors(getMotdState);

export const getMotdOfSelectedOrg = createSelector(
  OrganizationSelectors.getSelected,
  getMotdEntities,
  (org, motds) => org ? motds[org.id] : null
);

export const getPublishedMotdOfSelectedOrg = createSelector(
  getMotdOfSelectedOrg,
  (motd) => motd && motd.publishedAt ? motd : null
);

export const loadingState = createSelector(
  getMotdState,
  (state) => state.loading
);

export const getMotdById = (id: string) => createSelector(
  getMotdEntities,
  (motds) => motds[id]
);
