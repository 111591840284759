import {createFeatureSelector, createSelector} from '@ngrx/store'
import {adapter, State} from './role.state'
import {MembershipSelectors} from '../membership'
import {OrganizationSelectors} from '../organization'

export const stateKey = 'role';
const getRoleState = createFeatureSelector<State>(stateKey)

export const {
  selectEntities: getRoleEntities,
  selectAll: getAllRoles,
} = adapter.getSelectors(getRoleState)

export const getLoading = createSelector(
  getRoleState,
  state => state.loading
)

export const getRolesByNatPersonIdForSelectedOrg = (natPersonId: string) => createSelector(
  MembershipSelectors.getMembershipsByNatPersonId(natPersonId),
  OrganizationSelectors.getSelectedId,
  getRoleEntities,
  (memberships, orgId, entities) => {
    const membership = memberships.filter(m => m.organizationId === orgId)[0]
    return membership && membership.roles.map(id => entities[id]).filter(r => !!r)
  }
)

