import {Action} from '@ngrx/store';
import {DocumentSignature} from './document-signature';

export enum DocumentSignatureActionTypes {
  Create = '[DocumentSignature] Create',
  CreateSuccess = '[DocumentSignature] Create Success',
  CreateFail = '[DocumentSignature] Create Fail'
}

export class Create implements Action {
  readonly type = DocumentSignatureActionTypes.Create;

  constructor(public signature: DocumentSignature) {
  }
}

export class CreateSuccess implements Action {
  readonly type = DocumentSignatureActionTypes.CreateSuccess;

  constructor(public signature: DocumentSignature) {
  }
}

export class CreateFail implements Action {
  readonly type = DocumentSignatureActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export type DocumentSignatureActions =
  | Create
  | CreateSuccess
  | CreateFail;
