import {ElementRef, Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {Membership} from 'app/models/membership.model';
import {LICENCES_TYPES} from 'app/+store/licence-assignment/licence-assignment.selectors';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {
    InvitationActions,
    LicenceAssignmentActions,
    LicenceAssignmentSelectors,
    MembershipSelectors,
    MessageSelectors,
    OrganizationSelectors
} from 'app/+store';
import { MatDialog } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {LicenceUpgradeDialogComponent} from 'app/shared/components/dialogs/licence-upgrade-dialog/licence-upgrade-dialog.component';
import {LicenceUpgradeContactAdminDialogComponent} from 'app/shared/components/dialogs/licence-upgrade-contact-admin-dialog/licence-upgrade-contact-admin-dialog.component';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, takeUntil} from 'rxjs/operators';
import {LicenceAssignment} from 'app/shared/modules/api/models/licence';
import {Organization} from 'app/shared/modules/api/models/organization';
import {LoadOfOrganization} from 'app/+store/invitation/invitation.actions';
import {SuccessfulRegistrationComponent} from 'app/modules/user-profile/components/successful-registration/successful-registration.component';
import {UserHintTrial} from 'app/actions/current-user-actions';
import {LicenceTrialRenewDialogComponent} from 'app/shared/components/dialogs/licence-trial-renew-dialog/licence-trial-renew-dialog.component';
import {CreateOrganizationDialogContainerComponent} from 'app/modules/address-book/modules/address-book-table/containers/create-organization-dialog-container/create-organization-dialog-container.component';
import {InvitationsDialogComponent} from 'app/modules/inbox/modules/invitation/containers/invitations-dialog/invitations-dialog.component';
import {UserLogoutRequest} from 'app/actions/user-actions';

@Injectable()
export class LicenceControlService implements OnDestroy {
    private popoverWidth = 400;
    private onDestroy: Subject<void> = new Subject<void>();

    private myMembership: Observable<Membership | undefined>;
    private hasLicenceAssignment: Observable<boolean | false>;
    private myLicenceAssignment: Observable<LicenceAssignment[] | null>;
    private myLicencedOrganizations: Observable<Organization[] | null>;

  private myMembershipSubscription: Subscription;
  private hasLicenceAssignmentSubscription: Subscription;
  private myLicenceAssignmentSubscription: Subscription;
  private myLicencedOrganizationsSubscription: Subscription;

  private link = null;

  private _requiredLicence = LICENCES_TYPES.ENTERPRISE;

  public set requiredLicence(requiredLicence) {
    this._requiredLicence = requiredLicence
  }

  public get requiredLicence() {
    return this._requiredLicence;
  }

  private currentUser = {
    hasRequiredLicence: false,
    hasAdministrationRights: false,
    isOrganizationalOwner: false,
    currentLicence: null,
    attributes: {},
    licencedOrganizations: []
  }
  private licences = [
    LICENCES_TYPES.BASIC,
    LICENCES_TYPES.STANDARD,
    LICENCES_TYPES.PROFESSIONAL,
    LICENCES_TYPES.ENTERPRISE
  ]

  public elementRef: ElementRef;

  public selectedOrganizationId = null;

  private invitationsDialog;
  private invitationsSub;
  private invitations;

  private messages$;

  userSubscription: Subscription;
  orgSubscription: Subscription;

  public constructor(private dialog: MatDialog, private _translateSvc: TranslateService, private _store: Store<AppState>, private router: Router) {
    this.refresh();
  }

  refresh() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    this.userSubscription = this._store.select('currentUser').pipe(filter(u => !!u), distinctUntilKeyChanged('uid'), takeUntil(this.onDestroy)).subscribe((user) => {
      this.currentUser.attributes = user;
    });
    if (this.orgSubscription) {
      this.orgSubscription.unsubscribe();
    }

    this.orgSubscription = this._store.select(OrganizationSelectors.getSelectedId).pipe(filter(o => !!o), distinctUntilChanged(), takeUntil(this.onDestroy)).subscribe(id => {
      if (id) {
        this.selectedOrganizationId = id;

        this._store.dispatch(new LoadOfOrganization(id));
        // this._store.dispatch(new LoadMy(id));
        // this._store.dispatch(new MembershipActions.LoadAll(id));
        this._store.dispatch(new LicenceAssignmentActions.LoadAll(id));
        this._store.dispatch(new LicenceAssignmentActions.LoadAttachedOrganizations);

        // FETCHING THE ADMINISTRATIVE ROLES
        if (this.myMembershipSubscription) {
          this.myMembershipSubscription.unsubscribe();
        }
        this.myMembership = this._store.select(MembershipSelectors.getMyMembership);
        this.myMembershipSubscription = this.myMembership.pipe(takeUntil(this.onDestroy)).subscribe((row) => {
          if (row) {
            this.currentUser.hasAdministrationRights = row.hasAdministrationRights;
            this.currentUser.isOrganizationalOwner = row.isOrganizationalOwner;
          }
        });

        // FETCHING MY LICENCES ASSIGNMENTS
        if (this.myLicenceAssignmentSubscription) {
          this.myLicenceAssignmentSubscription.unsubscribe();
        }
        this.myLicenceAssignment = this._store.select(LicenceAssignmentSelectors.getMyLicenceAssignments);
        this.myLicenceAssignmentSubscription = this.myLicenceAssignment.pipe(takeUntil(this.onDestroy)).subscribe((licences) => {
          this.currentUser.currentLicence = licences[0] ? licences[0] : null;
        });

        // FETCHING MY LICENCED ORGANIZATIONS
        if (this.myLicencedOrganizationsSubscription) {
          this.myLicencedOrganizationsSubscription.unsubscribe();
        }
        this.myLicencedOrganizations = this._store.select(LicenceAssignmentSelectors.getMyLicenceAssignmentsAttachedOrganizations);
        this.myLicencedOrganizationsSubscription = this.myLicencedOrganizations.pipe(takeUntil(this.onDestroy)).subscribe((organizations) => {
          this.currentUser.licencedOrganizations = organizations;
        })
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public showInvitationsDialog() {
    this._store.dispatch(new InvitationActions.LoadMy());

    this.messages$ = this._store.select(MessageSelectors.getPendingInvitations);

    if (this.invitationsSub) {
      this.invitationsSub.unsubscribe();
    }
    this.invitationsSub = this.messages$.pipe(
      filter((messages) => !!messages),
      takeUntil(this.onDestroy)
    ).subscribe((messages) => {
      this.invitations = messages;
      if (this.invitations && this.invitations.length > 0 && !this.invitationsDialog) {
        this.invitationsDialog = this.dialog.open(InvitationsDialogComponent, {
          data: {
            invitations: this.messages$,
            closeAction: () => {
              this.invitationsDialog = null;
              this.invitationsSub.unsubscribe();
            },
            redirectAction: () => {
              this.invitationsDialog = null;
              this.invitationsSub.unsubscribe();
              this.dialog.closeAll();
              this.router.navigate(['/messages/invitations'], { queryParams: { lc: false } });
            }
          }
        });
      }
    });
  }

  public hasRequiredLicence(requiredLicence, onClickCallback = true) {

    if (this.licences.indexOf(requiredLicence))
      this.requiredLicence = requiredLicence;
    if (this.licences[requiredLicence])
      this.requiredLicence = this.licences[requiredLicence];

    // FETCHING IF HAS THE REQUIRED LICENCE
    if (this.hasLicenceAssignmentSubscription) {
      this.hasLicenceAssignmentSubscription.unsubscribe();
    }
    this.hasLicenceAssignment = this._store.select(LicenceAssignmentSelectors.hasLicenceAssignment(this.requiredLicence));
    this.hasLicenceAssignmentSubscription = this.hasLicenceAssignment.pipe(takeUntil(this.onDestroy)).subscribe((hasRequiredLicence) => {
      this.currentUser.hasRequiredLicence = hasRequiredLicence;
    });
    if (onClickCallback) {
      return this.onClick();
    } else {
      return this.checkLicence();
    }

  }

  private checkLicence() {
    try {
      const isTrialValid = this.isTrialValid(this.currentUser);
      const isTrial = this.isTrial(this.currentUser);
      const showTrialPopup = this.currentUser.attributes['showTrialPopup'] !== false;

      if (this.currentUser.hasRequiredLicence || (isTrial && isTrialValid && showTrialPopup && !this.currentUser.currentLicence)) { // tslint:disable-line
        return true;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
    return false;
  }

  private onClick() {
    if (!this.checkLicence()) {
      this.showLicenceDialog();
      return false;
    }
    return true;
  }

  public showLicenceDialog() {
    this.dialog.closeAll();

    const content = {
      title: 'LICENCES.INVALID_LICENCE_DIALOG_TITLE',
      message: 'LICENCES.INVALID_LICENCE_DIALOG_DESCRIPTION'
    };

    // If the user has no licences, we should now see if the trial has ended, if so then the message should be something for the trial is expired.
    if (this.isTrial(this.currentUser) && !this.isTrialValid(this.currentUser) && !this.currentUser.currentLicence) {
      this.showEndTrialDialog();
      return;
    }

    const dialogRef = this.dialog.open(LicenceUpgradeDialogComponent, {
      data: {
        title: content.title,
        message: content.message,
        requiredLicence: this.requiredLicence,
        onSubmitAction: () => {
          if (!this.currentUser.hasAdministrationRights) {
            this.showAdministrationContactDialog();
            return false;
          }
          this.dialog.closeAll();
          this.router.navigate([`/prices`]);
        },
          submitButtonTitle: this._translateSvc.instant('LICENCES.UNLOCK_BUTTON')
      },
      width: '400px',
    });
  }

  public showAdministrationContactDialog() {
    const dialogRef = this.dialog.open(LicenceUpgradeContactAdminDialogComponent, {
      data: {
        allowComment: false,
        onSubmitAction: () => {
        },
          submitButtonTitle: this._translateSvc.instant('LICENCES.CONTACT_ADMIN_BUTTON')
      },
      disableClose: true,
      width: '500px'
    });
  }

  public showRenewBasicAccountDialog() {
    const dialogRef = this.dialog.open(LicenceTrialRenewDialogComponent, {
      data: {
        onSubmitAction: () => {
        },
      },
      disableClose: true,
      width: '500px'
    });
  }

  public showTrialPopup() {
    if (!this.currentUser || !this.currentUser.attributes) {
      this.showInvitationsDialog();
      return;
    } else if (this.isBasicAccount(this.currentUser) || this.currentUser.currentLicence) {
      this.showInvitationsDialog();
      return;
    }

    const isTrialValid = this.isTrialValid(this.currentUser);
    const isTrial = this.isTrial(this.currentUser);
    const showTrialPopup = this.isShowTrialPopup(this.currentUser);

    if (isTrial && isTrialValid && showTrialPopup) { // tslint:disable-line
      this._store.dispatch(new UserHintTrial());

      const trialDialog = this.dialog.open(SuccessfulRegistrationComponent, {
        disableClose: true,
        data: {
          currentUser: this.currentUser,
          isTrialValid: true
        }
      });
      trialDialog.afterClosed().subscribe(continueWithTest => {
        if (continueWithTest) {
          this.showInvitationsDialog();
        }
      });
      return;
    }
    this.showInvitationsDialog();
    this.showEndTrialDialog();
  }

  public showEndTrialDialog() {
    if (!this.currentUser || !this.currentUser.attributes) {
      return;
    } else if (!this.isShowTrialPopup(this.currentUser) || this.isBasicAccount(this.currentUser) || this.currentUser.currentLicence) {
      return;
    }

    const isTrialValid = this.isTrialValid(this.currentUser);
    const isTrial = this.isTrial(this.currentUser);
    const showTrialPopup = this.isShowTrialPopup(this.currentUser);
    // console.log(this.isBusinessPartnerAccount(this.currentUser));
    if (isTrial && !isTrialValid && showTrialPopup) { // tslint:disable-line
      const dialogRef = this.dialog.open(LicenceUpgradeDialogComponent, {
        disableClose: true,
        data: {
          title: 'LICENCES.TRIAL_EXPIRED_DIALOG_TITLE',
          message: 'LICENCES.TRIAL_EXPIRED_DIALOG_DESCRIPTION',
          onSubmitAction: () => {
            if (!this.selectedOrganizationId) {
              const orgaCreationDialog = this.dialog.open(CreateOrganizationDialogContainerComponent, {
                data: {
                  alertMessage: true
                }
              });
              orgaCreationDialog.afterClosed().subscribe((result) => {
                this._store.select(OrganizationSelectors.getSelectedId).pipe(filter(o => !!o), distinctUntilChanged(), takeUntil(this.onDestroy)).subscribe(id => {
                  if (id) {
                    this.selectedOrganizationId = id;
                    this.router.navigate([`/prices`]);
                  } else {
                    this.showEndTrialDialog();
                  }
                });
              })
                return false;
            }
              if (!this.currentUser.hasAdministrationRights) {
                  this.showAdministrationContactDialog();
                  return false;
              }
              this.dialog.closeAll();
              this.router.navigate([`/prices`]);
          },
            submitButtonTitle: this._translateSvc.instant('LICENCES.UNLOCK_BUTTON'),
            onSecondarySubmitAction: () => {
                this.showRenewBasicAccountDialog();
            },
            secondarySubmitButtonTitle: this.isBusinessPartnerAccount(this.currentUser) ? this._translateSvc.instant('LICENCES.RENEW_BASIC_ACCOUNT_BUTTON') : null,
            onCancelAction: () => {
                this._store.dispatch(new UserLogoutRequest());
                this.router.navigate(['/session/sign-in']);
            },
            cancelButtonTitle: this._translateSvc.instant('BANNER.LINK.LOGOUT_TITLE')
        },
        width: '400px',
      });
    }
  }

  public isTrial(user) {
    if (!user) {
      return false;
    }
    return user.attributes['accountAccessType'] === 'trial';
  }

  public isTrialValid(user) {
    if (!user || !user.attributes['endTrial']) {
      return false;
    }
    return user.attributes['endTrial'].getTime() >= new Date().getTime()
  }

  public isShowTrialPopup(user) {
    if (!user) {
      return false;
    }
    return user.attributes['showTrialPopup'] !== false;
  }

  public isBasicAccount(user) {
    if (!user) {
      return false;
    }
    return user.attributes['basicAccount'] === true;
  }

  public isBusinessPartnerAccount(user) {
    if (!user) {
      return false;
    }
    return user.attributes['invitationType'] && user.attributes['invitationType'] === 'business_partner';
  }

  public get _currentUser() {
    return this.currentUser;
  }
}
