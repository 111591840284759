
import {combineLatest as observableCombineLatest, Observable, Subject} from 'rxjs';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import {DavContactService} from 'app/+store/dav-contact/dav-contact.service';
import {DavContact} from 'app/+store/dav-contact/dav-contact';
import {DavContactBuilder} from 'app/+store/dav-contact/dav-contact.builder';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {DavContactType} from 'app/+store/dav-contact/dav-contact.interface';
import {ContactSelectors} from '../../../../+store/contact';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {OrganizationSelectors} from '../../../../+store/organization';
import {LoadAll} from '../../../../+store/contact/contact.actions';
import {ContactPersonFactoryService} from '../../../../+store/contact-person/contact-person-factory.service';
import {visibilityTypes} from '../../../../models/contact-list-dto.model';
import {ContactOrganizationFactoryService} from '../../../../+store/contact-organization/contact-organization-factory.service';

@Component({
  selector: 'dvtx-dav-contact-import',
  templateUrl: './dav-contact-import.component.html',
  styleUrls: ['./dav-contact-import.component.scss']
})
export class DavContactImportComponent implements OnDestroy, OnInit {
  onDestroy = new Subject();
  DavContactType = DavContactType;

  data = {
    'data': [
      {
        'id': 'd8bfaa11-ab89-4e99-b758-954717e988a9',
        'type': 'dav_contacts',
        'attributes': {
          'name': 'Max Meister',
          'first_name': 'Max',
          'last_name': 'Meister',
          'email': 'auditor@dvtx.de'
        }
      },
      {
        'id': 'c23194eb-fdd9-4072-b79b-481b636954d2',
        'type': 'dav_contacts',
        'attributes': {
          'name': 'Brauerei Berghammer',
          'first_name': '',
          'last_name': '',
          'email': null
        }
      },
      {
        'id': '729f48c0-644a-42c0-8a8f-ac80b2e0a8a7',
        'type': 'dav_contacts',
        'attributes': {
          'name': 'ADAC',
          'first_name': '',
          'last_name': '',
          'email': null
        }
      }
    ]
  };

  displayedColumns: string[] = ['select', 'exists', 'firstName', 'lastName', 'name', 'email', 'contactType', 'visibility', 'invite'];
  dataSource;
  selection = new SelectionModel<DavContact>(true, []);

  contacts$: Observable<DavContact[]>;
  contacts: DavContact[];
  addressBookContacts$;
  imported = 0;
  toImport = 0;

  constructor(private _davSvc: DavContactService,
              private _store: Store<AppState>,
              private _contactSvc: ContactPersonFactoryService,
              private _orgContactSvc: ContactOrganizationFactoryService) {
  }

  ngOnInit() {
    this._store.select(OrganizationSelectors.getSelected)
      .pipe(
        filter(x => !!x),
        distinctUntilChanged((prev, next) => prev && next && prev.id === next.id),
        takeUntil(this.onDestroy))
      .subscribe(org => this._store.dispatch(new LoadAll(org)));
    this.addressBookContacts$ = this._store.select(ContactSelectors.getAllContacts);
    const builder = new DavContactBuilder();
    this.contacts$ = this._davSvc.getAll();
    observableCombineLatest(this.contacts$, this.addressBookContacts$, (c: DavContact[], aContacts: any[]) => {
      const knownEmails = aContacts.map(ac => ac.email);
      return c.map(dc => {
        if (dc.email && knownEmails.includes(dc.email)) {
          dc.imported = true;
        }
        return dc;
      }).filter(dc => !!dc.email)
    })
      .pipe(takeUntil(this.onDestroy))
      .subscribe(_contacts => {
        this.contacts = _contacts;
        this.dataSource = new MatTableDataSource<DavContact>(_contacts);
      });
  }

  importContacts() {
    this.toImport = this.selection.selected.length;
    if (this.toImport > 0) {
      this.selection.selected.forEach((contact: DavContact) => {
        try {
          const visibilityType = contact.private ? visibilityTypes.visiblePrivate : visibilityTypes.visiblePublic;
          if (contact.contactType === DavContactType.Person) {
            this._contactSvc.create(contact, contact.invite, { visibleFor: '', type: visibilityType });
          } else {
            this._orgContactSvc.create(contact, { visibleFor: '', type: visibilityType });
          }
        } catch (err) {
          console.error(err);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
