import {ProcessActions, ProcessActionTypes} from './process.actions';
import {adapter, initialState, State} from './process.state';

export function reducer(state = initialState, action: ProcessActions): State {
  switch (action.type) {
    case ProcessActionTypes.LoadAll:
      // Attention: the loading state of LoadAll also triggers the ngIf of components depending on the loading state.
      // This can lead to endless call loops.
      // Example: Timeline banner component was depending on the loading state having dispatches inside:
      // ATTENTION: Having dispatches inside subcomponents is always dangerous: Dispatches should always be placed at
      // surrounding containers.
      // Temporary solution: Force loading state on tables like process table when used together with dense or processIds.
      const loading = true // !action.params.dense && !action.params.processIds || action.params.forceLoadingState;
      return {
        ...state,
        loading: loading,
        authorized: true
      };
    case ProcessActionTypes.Remove:
    case ProcessActionTypes.LoadAllFromParent:
      return {
        ...state,
        loading: true,
        authorized: true
      };
    case ProcessActionTypes.LoadAllSuccess:
    case ProcessActionTypes.LoadAllFromParentSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
        authorized: true
      });
    case ProcessActionTypes.LoadAllFail:
    case ProcessActionTypes.LoadAllFromParentFail:
      return {
        ...state,
        loading: false,
        authorized: true
      };
    case ProcessActionTypes.LoadOne:
      return {
        ...state,
        selected: action.loadingTrue ? null : state.selected,
        loading: action.loadingTrue,
        authorized: true
      };
    case ProcessActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.process, {
        ...state,
        selected: action.process,
        loading: false,
        authorized: true
      });
    case ProcessActionTypes.RemoveSuccess:
      return adapter.removeOne(action.id, {
        ...state,
        selected: null,
        loading: false,
        authorized: true
      });
    case ProcessActionTypes.LoadOneFail:
      return {
        ...state,
        loading: false,
        authorized: true
      };
    case ProcessActionTypes.Move:
      return {
        ...state,
        loading: true,
        authorized: true
      };
    case ProcessActionTypes.MoveSuccess:
      return adapter.upsertOne(action.process, {
        ...state,
        loading: false,
        authorized: true
      });
    case ProcessActionTypes.MoveFail:
    case ProcessActionTypes.RemoveFail:
      return {
        ...state,
        authorized: false,
        loading: false
      };
    case ProcessActionTypes.RunCommand:
      return {
        ...state,
        loading: true,
        authorized: true,
        selectedLoading: true
      };
    case ProcessActionTypes.Unauthorized:
      return {
        ...state,
        loading: false,
        authorized: false
      };
    case ProcessActionTypes.RunCommandSuccess:
      return adapter.upsertOne(action.process, {
        ...state,
        selected: state.selected && state.selected.id === action.process.id ? action.process : state.selected,
        loading: false,
        authorized: true,
        selectedLoading: false
      });
    case ProcessActionTypes.RunCommandFail:
      return {
        ...state,
        loading: false,
        selectedLoading: false,
      };

    case ProcessActionTypes.ProcessLoadingSuccess:
      return {
        ...state,
        selectedLoading: false
      };

    case ProcessActionTypes.ChangeSortingBy:
      return {
        ...state,
        sortComparer: action.sortComparer
      };
    case ProcessActionTypes.ChangePaginationEnd:
      return {
        ...state,
        paginationEnd: action.end
      };
  }
  return state;
}
