import {MessageActions, MessageActionTypes} from './message.actions';
import {adapter, initialState, State} from './message.state';

export function reducer(state = initialState, action: MessageActions): State {
  switch (action.type) {
    case MessageActionTypes.LoadAll:
    case MessageActionTypes.LoadOne:
    case MessageActionTypes.SaveMessage:
    case MessageActionTypes.SendMessage:
    case MessageActionTypes.LoadDrafts:
    case MessageActionTypes.LoadSent:
    case MessageActionTypes.ToggleFavorite:
    case MessageActionTypes.ToggleArchive:
    case MessageActionTypes.ToggleRead:
    case MessageActionTypes.DeleteDraft:
    case MessageActionTypes.DeleteMessage:
    case MessageActionTypes.LoadMessageStats:
      return {
        ...state,
        loading: true
      };
    case MessageActionTypes.LoadAllSuccess:
    case MessageActionTypes.LoadSentSuccess:
      const newState = adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
      const current = state.selected;
      if (current) {
        newState.selected = state.entities[current.id];
      }
      return newState;
    case MessageActionTypes.LoadAllFail:
    case MessageActionTypes.SaveMessageFail:
    case MessageActionTypes.SendMessageFail:
    case MessageActionTypes.LoadSentFail:
    case MessageActionTypes.LoadDraftsFail:
    case MessageActionTypes.LoadOneFail:
      return {
        ...state,
        loading: false,
      };
    case MessageActionTypes.Reset:
      return adapter.removeAll({
        ...state
      });
    case MessageActionTypes.SaveMessageSuccess:
    case MessageActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.message, {
        ...state,
        selected: (state.selected && state.selected.id === action.message.id) ? action.message : state.selected,
        loading: false,
      });
    case MessageActionTypes.SendMessageSuccess:
      return adapter.upsertOne(action.message, {
        ...state,
        selected: (state.selected && state.selected.id === action.message.id) ? null : state.selected,
        loading: false,
      });
    case MessageActionTypes.LoadDraftsSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case MessageActionTypes.SelectMessage:
      return {
        ...state,
        selected: action.message
      };
    case MessageActionTypes.LoadMessageStatsSuccess:
      return {
        ...state,
        unread: (action.stat.unread > 0) ? action.stat.unread : null,
        unreadUpdates: (action.stat.unreadUpdates > 0) ? action.stat.unreadUpdates : null,
        loading: false
      };
    case MessageActionTypes.ToggleArchiveSuccess:
      return adapter.upsertOne(action.message, {
        ...state,
        selected: (state.selected && state.selected.id === action.message.id) ? null : state.selected,
        loading: false,
      });
    case MessageActionTypes.ToggleFavoriteSuccess:
    case MessageActionTypes.ToggleReadSuccess:
      return adapter.upsertOne(action.message, {
        ...state,
        selected: (state.selected && state.selected.id === action.message.id) ? action.message : state.selected,
        loading: false,
      });
    case MessageActionTypes.DeleteMessageSuccess:
    case MessageActionTypes.DeleteDraftSuccess:
      return adapter.removeOne(action.message.id, {
        ...state,
        selected: (state.selected && state.selected.id === action.message.id) ? null : state.selected,
        loading: false,
      });
  }
  return state;
}

