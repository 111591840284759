import {Action} from '@ngrx/store';
import {Comment, CommentProcessStatistics} from './comment';

export enum CommentActionTypes {
  DeleteComment = '[Comment] Delete Comment',
  DeleteCommentSuccess = '[Comment] Delete Comment Success',
  DeleteCommentFail = '[Comment] Delete Comment Fail',
  SelectComment = '[Comment] Select Comment',
  SendComment = '[Comment] Send Comment',
  SendCommentSuccess = '[Comment] Send Comment Success',
  SendCommentFail = '[Comment] Send Comment Fail',
  SaveComment = '[Comment] Save Comment',
  SaveCommentSuccess = '[Comment] Save Comment Success',
  SaveCommentFail = '[Comment] Save Comment Fail',
  LoadAll = '[Comment] Load All',
  LoadAllSuccess = '[Comment] Load All Success',
  LoadAllFail = '[Comment] Load All Fail',
  LoadOne = '[Comment] Load One',
  LoadOneSuccess = '[Comment] Load One Success',
  LoadOneFail = '[Comment] Load One Fail',
  Read = '[Comment] Read Event',
  ReadSuccess = '[Comment] Read Event Success',
  ReadFail = '[Comment] Read Event Fail',
  LoadProcessStatistics = '[Comment] LoadStatistics',
  LoadProcessStatisticsSuccess = '[Comment] LoadStatistics Success',
  LoadProcessStatisticsFail = '[Comment] LoadStatistics Fail',
}

export class LoadAll implements Action {
  readonly type = CommentActionTypes.LoadAll;

  constructor(public processId: string, public recursive = false, public resourceId = null, public isArtifact = false) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = CommentActionTypes.LoadAllSuccess;

  constructor(public comments: Comment[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = CommentActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = CommentActionTypes.LoadOne;

  constructor(public processId: string, public commentId, public isArtifact = false) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = CommentActionTypes.LoadOneSuccess;

  constructor(public comment: Comment) {
  }
}

export class LoadOneFail implements Action {
  readonly type = CommentActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class DeleteComment implements Action {
  readonly type = CommentActionTypes.DeleteComment;

  constructor(public comment: Comment) {
  }
}

export class DeleteCommentSuccess implements Action {
  readonly type = CommentActionTypes.DeleteCommentSuccess;

  constructor(public commentId: string) {
  }
}

export class DeleteCommentFail implements Action {
  readonly type = CommentActionTypes.DeleteCommentFail;

  constructor(public payload: any) {
  }
}

export class SaveComment implements Action {
  readonly type = CommentActionTypes.SaveComment;

  constructor(public comment: Comment) {
  }
}

export class SaveCommentSuccess implements Action {
  readonly type = CommentActionTypes.SaveCommentSuccess;

  constructor(public comment: Comment) {
  }
}

export class SaveCommentFail implements Action {
  readonly type = CommentActionTypes.SaveCommentFail;

  constructor(public payload: any) {
  }
}

export class SendComment implements Action {
  readonly type = CommentActionTypes.SendComment;

  constructor(public processId, public comment: Comment) {
  }
}

export class SendCommentSuccess implements Action {
  readonly type = CommentActionTypes.SendCommentSuccess;

  constructor(public comment: Comment) {
  }
}

export class SendCommentFail implements Action {
  readonly type = CommentActionTypes.SendCommentFail;

  constructor(public payload: any) {
  }
}

export class SelectComment implements Action {
  readonly type = CommentActionTypes.SelectComment;

  constructor(public comment: Comment) {
  }
}

export class Read implements Action {
  readonly type = CommentActionTypes.Read;

  constructor(public comment: Comment) {
  }
}

export class ReadSuccess implements Action {
  readonly type = CommentActionTypes.ReadSuccess;

  constructor(public comment: Comment) {
  }
}

export class ReadFail implements Action {
  readonly type = CommentActionTypes.ReadFail;

  constructor(public payload: any) {
  }
}

export class LoadProcessStatistics implements Action {
  readonly type = CommentActionTypes.LoadProcessStatistics;

  constructor(public processId: string, public recursive = false) {
  }
}

export class LoadProcessStatisticsSuccess implements Action {
  readonly type = CommentActionTypes.LoadProcessStatisticsSuccess;

  constructor(public stats: CommentProcessStatistics) {
  }
}

export class LoadProcessStatisticsFail implements Action {
  readonly type = CommentActionTypes.LoadProcessStatisticsFail;

  constructor(public payload: any) {
  }
}

export type CommentActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | DeleteComment
  | DeleteCommentSuccess
  | DeleteCommentFail
  | SaveComment
  | SaveCommentSuccess
  | SaveCommentFail
  | SendComment
  | SendCommentSuccess
  | SendCommentFail
  | SelectComment
  | Read
  | ReadSuccess
  | ReadFail
  | LoadProcessStatistics
  | LoadProcessStatisticsSuccess
  | LoadProcessStatisticsFail;
