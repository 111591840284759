<ng-template [ngIf]="disabled" [ngIfElse]="enabledMenu">
  <ng-content></ng-content>
</ng-template>

<ng-template #enabledMenu>
  <span #clickMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="confirmMenu">
    <ng-content></ng-content>
  </span>

  <mat-menu #confirmMenu="matMenu" [overlapTrigger]="overlapTrigger" [xPosition]="xPosition">
    <div mat-menu-item disableRipple class="simple-confirm">
      <button mat-raised-button
              [color]="color" (click)="$event.stopPropagation(); onConfirm.emit($event);closeMenu();">{{ confirmButtonText | translate }}</button>
    </div>
  </mat-menu>
</ng-template>
