import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {AuthPassword} from './auth-password';

export interface State extends EntityState<AuthPassword> {
  loading: boolean;
}

export const adapter = createEntityAdapter<AuthPassword>();

export const initialState: State = adapter.getInitialState({
  loading: false,
});
