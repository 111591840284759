import {Action} from '@ngrx/store';
import {Project} from './project';
import {IProjectParams} from './project.interface';

export enum ProjectActionTypes {
  Create = '[Project] Create',
  CreateSuccess = '[Project] Create Success',
  CreateFail = '[Project] Create Fail',
  Publish = '[Project] Publish',
  PublishSuccess = '[Project] Publish Success',
  PublishFail = '[Project] Publish Fail',
  NavigateToDashboard = '[Project] Navigate To Dashboard',
  SaveDraft = '[Project] Save Draft',
  SaveDraftSuccess = '[Project] Save Draft Success',
  SaveDraftFail = '[Project] Save Draft Fail',
  LoadAll = '[Project] Load All',
  LoadAllSuccess = '[Project] Load All Success',
  LoadAllFail = '[Project] Load All Fail',
  LoadOne = '[Project] Load One',
  LoadOneSuccess = '[Project] Load One Success',
  LoadOneFail = '[Project] Load One Fail'
}

export class Create implements Action {
  readonly type = ProjectActionTypes.Create;

  constructor(public params: IProjectParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = ProjectActionTypes.CreateSuccess;

  constructor(public project: Project) {
  }
}

export class CreateFail implements Action {
  readonly type = ProjectActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Publish implements Action {
  readonly type = ProjectActionTypes.Publish;

  constructor(public id: string) {
  }
}

export class PublishSuccess implements Action {
  readonly type = ProjectActionTypes.PublishSuccess;

  constructor(public project: Project) {
  }
}

export class PublishFail implements Action {
  readonly type = ProjectActionTypes.PublishFail;

  constructor(public payload: any) {
  }
}

export class NavigateToDashboard implements Action {
  readonly type = ProjectActionTypes.NavigateToDashboard;

  constructor(public id: string) {
  }
}

export class SaveDraft implements Action {
  readonly type = ProjectActionTypes.SaveDraft;

  constructor(public id: string, public params: IProjectParams) {
  }
}

export class SaveDraftSuccess implements Action {
  readonly type = ProjectActionTypes.SaveDraftSuccess;

  constructor(public project: Project) {
  }
}

export class SaveDraftFail implements Action {
  readonly type = ProjectActionTypes.SaveDraftFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = ProjectActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ProjectActionTypes.LoadAllSuccess;

  constructor(public projects: Project[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ProjectActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = ProjectActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = ProjectActionTypes.LoadOneSuccess;

  constructor(public project: Project) {
  }
}

export class LoadOneFail implements Action {
  readonly type = ProjectActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type ProjectActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Publish
  | PublishSuccess
  | PublishFail
  | NavigateToDashboard
  | SaveDraft
  | SaveDraftSuccess
  | SaveDraftFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
