import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'dvtx-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent {
  @Input() tooltipText;
  @Input() position = 'top left';
  @ViewChild('elementRef') elementRef: ElementRef;

  top = 0;
  left = 0;

  constructor(private _cdr: ChangeDetectorRef) { }

  adjustTooltipPosition(e) {
    if(!this.elementRef) return;
    const tooltipHeight = this.elementRef.nativeElement.offsetHeight;
    const tooltipWidth = this.elementRef.nativeElement.offsetWidth;

    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;

    const triggerElementPosition = e.target.getBoundingClientRect();
    const triggerElementHeight = e.target.offsetHeight;
    const triggerElementWidth = e.target.offsetWidth;

    const p = this.position.split(' ');
    const pY = p[0];
    const pX = p[1];

    switch (pY) {
      case 'top':
        if (triggerElementPosition.top - tooltipHeight < 0) {
          this.top = triggerElementPosition.top + triggerElementHeight;
        } else {
          this.top = triggerElementPosition.top - tooltipHeight;
        }
        break;

      case 'bottom':
        if (triggerElementPosition.top + triggerElementHeight > screenHeight) {
          this.top = triggerElementPosition.top - tooltipHeight;
        } else {
          this.top = triggerElementPosition.top + triggerElementHeight
        }
        break;
    }

    switch (pX) {
      case 'left':
        if (triggerElementPosition.left + tooltipWidth > screenWidth) {
          this.left = triggerElementPosition.left - tooltipWidth + triggerElementWidth;
        } else {
          this.left = triggerElementPosition.left;
        }
        break;

      case 'right':
        if (triggerElementPosition.left - tooltipWidth < 0) {
          this.left = triggerElementPosition.left;
        } else {
          this.left = triggerElementPosition.left - tooltipWidth + triggerElementWidth;
        }
        break;
    }


  }
}
