import {throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ApiErrorBuilder} from 'app/shared/modules/api/builders/api-error.builder';
import {ResourceBuilder} from 'app/shared/modules/api/builders/resource.builder';

import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';

/**
 * Service to manage a sales orders of the customer.
 */
@Injectable()
export class SalesOrderService {

  readonly BASE_PATH = 'sales/orders';
  basePath;

  constructor(private _tokenSvc: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.BASE_PATH}`;
  }

  /**
   * Creates an order from the current shopping cart.
   */
  checkout() {
    return this._tokenSvc.post(this.basePath, {}).pipe(
    map(res => ResourceBuilder.fromResponse(res)),
    catchError(this.handleError),);
  }

  private handleError(error) {
    const status = error.status;
    if (error.json) {
      const errors = ApiErrorBuilder.fromResponse(error, status);
      return observableThrowError(errors);
    }
    return observableThrowError(error);
  }
}
