import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {TaskEvent} from './task-event';
import * as dayjs from 'dayjs';

export interface State extends EntityState<TaskEvent> {
  loading: boolean;
}

export const adapter = createEntityAdapter<TaskEvent>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  loading: false
});
