import {combineLatest, distinctUntilKeyChanged, filter, map, takeUntil} from 'rxjs/operators';
import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {OrganizationActions, OrganizationSelectors} from '../organization';
import {MembershipSelectors} from '../membership';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';

@Injectable()
export class ProcessOrganizationSelectorService {
  private _onDetach = new Subject<{ id: string; organizationId: string; }>();
  private _process: { id: string; organizationId: string; };
  private _listener: Subscription;
  private _organizationId$ = new BehaviorSubject<string>(null);

  constructor(private _store: Store<AppState>,
              private _ngZone: NgZone) { }

  /**
   * Selects the organization of a process if present and not already loaded/selected.
   * Example of usage:
   * ngOnInit() {
   *   const idByQuery$ = this._route.params.pipe(map(params => params['id']), distinctUntilChanged());
   *   this.process$ = idByQuery$
   *     .pipe(switchMap(id => {
   *       if (!id) return of(null);
   *       return this._store.select(ProcessSelectors.getProcessById(id))
   *   }));
   *   const distinctProcess$ = this.process$.pipe(filter(p => !!p), distinctUntilKeyChanged('id'));
   *   distinctProcess$.pipe(takeUntil(this.onDestroy)).subscribe(process => {
   *     if (process) {
   *       this._processOrganizationSelector.select(process);
   *     }
   *    });
   * }
   *
   * ngOnDestroy() {
   *   this._processOrganizationSelector.detach();
   * }
   * @param process
   */
  select(process: { id: string; organizationId: string; }) {
    if (!process || !process.organizationId) {
      console.error('No Process given to select an organization or process does not have an organization id');
      return;
    }

    const organizations$ = this._store.select(OrganizationSelectors.getAllOrganizations);
    const selectedOrganization$ = this._store.select(OrganizationSelectors.getSelected).pipe(filter(o => !!o), distinctUntilKeyChanged('id'));

    // Same process? return. Don't listen twice
    if (this._process && this._listener && this._process.id === process.id) {
      return;
    }

    // Listener present? Detach first.
    if (this._listener) {
      this.detach();
    }

    this._process = process;

    this._listener = organizations$.pipe(combineLatest(organizations$, selectedOrganization$))
      .pipe(takeUntil(this._onDetach))
      .subscribe(([, organizations, selectedOrganization]) => {
        if (this._process && this._process.organizationId && organizations && organizations.length) {
          this._organizationId$.next(this._process.organizationId);

          if (selectedOrganization && process.organizationId === selectedOrganization.id) {
            setTimeout(_ => this._stopListener());
            return;
          }

          this._ngZone.runOutsideAngular(() => {
            const found = organizations.find(org => org.id === this._process.organizationId);
            if (found) {
              // this._store.dispatch(new LoadOfOrganization(found.id));
              // this._store.dispatch(new LoadMy(found.id));
              // this._store.dispatch(new MembershipActions.LoadAll(found.id));
              // this._store.dispatch(new ContactActions.LoadAll(found));
              // this._store.dispatch(new FeatureActions.LoadAll());

              this._store.dispatch(new OrganizationActions.Select(found.id));
              setTimeout(_ => this._stopListener());
            }
          });
        }
      });
  }

  isMember$() {
    const membership$ = this._store.select(MembershipSelectors.getMyMembership);
    return membership$.pipe(combineLatest(this._organizationId$))
      .pipe(
        map(([membership, organizationId]) => {
          if (!membership || !organizationId) {
            return false;
          }
          return !!membership && membership.organizationId === organizationId;
      }));
  }

  private _stopListener() {
    try {
      if (this._listener) {
        this._listener.unsubscribe();
      }
    } catch (e) {
      console.error('Error on detaching Organization Selector listener');
    }

    this._listener = null;
  }

  detach() {
    this._onDetach.next(this._process);
    this._stopListener();
    this._process = null;
    this._organizationId$.next(null);
  }
}
