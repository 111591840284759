import {ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {combineLatest as observableCombineLatest, Observable, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {BookmanClientActions, ClientActions, ClientSelectors, FeatureSelectors, FibuActions, MembershipSelectors} from 'app/+store';
import {first, map, takeUntil} from 'rxjs/operators';
import {Feature} from 'app/+store/feature/feature';
import {Membership} from 'app/models/membership.model';
import {contactListDtoType} from 'app/models/contact-list-dto.model';
import {ClientSelectionComponent} from 'app/modules/workflow-engine/modules/process-dialog/containers/client-selection/client-selection.component';
import {UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Client} from 'app/+store/client/client';

@Component({
  selector: 'dvtx-edit-client-dialog',
  templateUrl: './edit-client-dialog.component.html',
  styleUrls: ['./edit-client-dialog.component.scss']
})
export class EditClientDialogComponent implements OnInit, OnDestroy {
  @ViewChild('dialogContent', {static: true}) dialogContent: ElementRef;
  @ViewChild('clientSelectionComponent') clientSelectionComponent: ClientSelectionComponent;
  contactListDtoType = contactListDtoType;
  protected onDestroy = new Subject();
  selectedTab = 0;
  client$ = new BehaviorSubject<Client>(null);
  clientId: string;
  featureSet$: Observable<Feature>;
  myMembership$: Observable<Membership>;
  showFibu: boolean = false;
  showDatevLogin: boolean = false;
  isClient = false;
  isContact: any = false;
  contact: any;
  form: UntypedFormGroup;
  openedTab: number;

  constructor(
    public dialogRef: MatDialogRef<EditClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store<AppState>,
    private _cdr: ChangeDetectorRef
  ) {
    if (data) {
      this.selectedTab = data.selectedTab ? data.selectedTab : 0;
      this.client$.next(data.client);
      this.clientId = data.client && data.client.id ? data.client.id : data.clientId;
      this.isClient = data.isClient;
      this.isContact = data.isContact;
      this.contact = data.contact;
      this.openedTab = data.openedTab ?? 0;
    }

  }

  ngOnInit() {
    this._route.queryParamMap.pipe(first()).subscribe((params) => {
      const tab = Number(params.get('tab'));
      if (tab) {
        this.selectedTab = tab;
      }
    })
    if (!this.client$.value && this.clientId) {
      this._store.select(ClientSelectors.getClientById(this.clientId)).pipe(takeUntil(this.onDestroy)).subscribe(client => {
        this.client$.next(client);
      });
    }

    this.myMembership$ = this._store.select(MembershipSelectors.getMyMembership);
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);

    if (this.isClient) {
      observableCombineLatest([this.featureSet$, this.myMembership$])
        .pipe(takeUntil(this.onDestroy))
        .subscribe(([featureSet, myMembership]) => {
          if (myMembership && featureSet && featureSet.hasBookman && featureSet.canUpdateBookmanSettings) {
            this.showFibu = true;

            if (this.clientId) {
              this._store.dispatch(new ClientActions.LoadOne(this.clientId));
              this._store.dispatch(new FibuActions.LoadByClient(this.clientId));
              this._store.dispatch(new BookmanClientActions.Refresh());
            }
          }
          this.showDatevLogin = !!featureSet?.['hasDatev'];
          this._cdr.detectChanges();
        });
    }
  }

  updateBookmanSettings(client) {
    this.client$.next(client);
    this.data.onBookmanSave?.(client);
  }

  updateMapping(data) {
    this.data.onCloseAction(data);
  }

  ngOnDestroy() {
    this._router.navigate([], {
      queryParams: {},
    });
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  saveClient() {
    if (this.clientSelectionComponent) {
      this.clientSelectionComponent.submit();
    }
  }

  editSuccess(client) {
    if (this.data.onSubmitAction) {
      this.data.onSubmitAction(client);
    }
  }

  closeSidebar(_$event) {
    this.dialogRef.close();
  }

  updateActionButtons(form) {
    this.form = form;
    this._cdr.detectChanges();
  }

  updateUrl() {

  }

  selectedTabChanged(tab) {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        tab,
      },
      queryParamsHandling: 'merge',
    });
  }

  showMobileActivation(): Observable<boolean> {
    return this.featureSet$.pipe(map((featureSet: Feature) => featureSet.hasMobileSupport));
  }
}
