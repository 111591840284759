import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Process} from 'app/+store/process/process';
import {BehaviorSubject} from 'rxjs';
import {ProcessProfile} from 'app/+store/process/process.interface';

export interface IWorkflowInfoItem {
  id: string;
  title: string;
  processType: string;
  profile: ProcessProfile
}

@Component({
  selector: 'dvtx-workflow-info',
  templateUrl: './workflow-info.component.html',
  styleUrls: ['./workflow-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowInfoComponent implements OnInit, OnDestroy {
  @Input() hasAccessibleParent = false;
  @Input() set process(p: IWorkflowInfoItem) {
    if (p) {
      this.process$.next(p);
    }
  }

  process$ = new BehaviorSubject<IWorkflowInfoItem>(null);

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.process$.complete();
  }

  humanProcessType(process: IWorkflowInfoItem) {
    if (process.processType === 'project' && this.hasAccessibleParent) {
      return 'PROJECT.SUB_PROJECT';
    }
    return Process.humanProcessType(process, true);
  }
}
