import {IResource} from 'app/shared/modules/api/models/resource.interface';

export enum DmsAccountType {
  Organization = 'Organization',
  Private = 'Private'
}

export interface ISlimFolder extends IResource {
  id: string;
  name: string;
  sourceId: string;
  dmsAccountType: DmsAccountType;
  parentId: string;
  children?: ISlimFolder[];
  bookmanCockpitEnabled?: boolean;
}
