import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableTextAndInfoComponent} from './containers/table-text-and-info/table-text-and-info.component';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    MatButtonModule
  ],
  declarations: [
    TableTextAndInfoComponent
  ],
  exports: [
    TableTextAndInfoComponent
  ]
})
export class TableUtilsModule {
}
