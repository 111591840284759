<!--<mat-card-content class="col-12 col-sm-12" *ngIf="!_settings.hideHint">-->
  <!--{{'WORKFLOWS.GA_CA_COMMUNICATION.CA_SELECTION_HINT' | translate}}-->
<!--</mat-card-content>-->

<ng-container *ngIf="!idid">
  <mat-card-content>
    <a *ngIf="excelTemplateUrl"
       mat-button color="primary"
       class="my-1 mr-2"
       [href]="excelTemplateUrl" target="_blank" download>
      <mat-icon matprefix aria-label="download">file_download</mat-icon>
      {{ 'WORKFLOWS.GA_CA_COMMUNICATION.CA_SELECTION_SAMPLE_DOWNLOAD' | translate }} (Excel)
    </a>
    <!--<a mat-raised-button color="gray" (click)="browserCheck($event)" [href]="templateDataUri" target="_blank" download="sample.csv">-->
      <!--<mat-icon matprefix aria-label="download">file_download</mat-icon>-->
      <!--{{ 'WORKFLOWS.GA_CA_COMMUNICATION.CA_SELECTION_SAMPLE_DOWNLOAD' | translate }} (CSV)-->
    <!--</a>-->
  </mat-card-content>

  <mat-card-content>
    <em class="status-color-info">{{_settings.text.sampleTitle | translate}}</em>
    <div class="expected-structure status-color-info table-responsive">
      <table class="expected-structure__table">
        <tbody>
        <tr>
          <ng-container *ngFor="let column of _settings.columns">
            <td *ngIf="!column.isHidden">{{column.title}}</td>
          </ng-container>
        </tr>
        </tbody>
      </table>
    </div>
    <em class="status-color-info sample-info">{{_settings.text.sampleInfo | translate}}</em>
  </mat-card-content>

  <mat-card-content>
      <dvtx-csv-import-drop-zone class="drag-n-drop my-2"
                                 (newFile)="onNewFile($event)"
                                 [fileTypes]="['XLSX', 'XLS', 'CSV']"></dvtx-csv-import-drop-zone>
  </mat-card-content>
</ng-container>

<ng-template [ngIf]="ididLoading" [ngIfElse]="dataContentContainer">
  <div class="w-100 mt-2 mb-3 d-flex justify-content-center">
    <dvtx-loading-panel [loadingText]="'THIRD_PARTY.WIZARD.IMPORT_FILE_LOADING'"></dvtx-loading-panel>
  </div>
</ng-template>

<ng-template #dataContentContainer>
  <ng-content select=".after-drop-zone"></ng-content>
  <ng-container *ngIf="parsedCsv">
    <mat-card-content class="col-12 col-sm-12" *ngIf="parsedCsv?.totalNumberOfErrors">
      <dvtx-csv-import-error-box [fileName]="parsedCsv.filename" [numberOfErrors]="parsedCsv?.totalNumberOfErrors"></dvtx-csv-import-error-box>
    </mat-card-content>

    <dvtx-csv-import-display [children]="parsedCsv?.children" [columns]="_settings.columns" [title]="_settings.text.displayTableTitle | translate"></dvtx-csv-import-display>
  </ng-container>
</ng-template>
