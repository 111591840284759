
import {of as observableOf, Observable} from 'rxjs';

import {delay, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';


import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()

export class PaymentDataService {
  basePath;

  constructor(private tokenService: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}`;

  }

  verifyPromoCode(email: string, promocode: string): Observable<{ success: boolean, message: string }> {
    return this.tokenService
      .post(`${this.basePath}/sales/promo_codes/verify`, {
        data: {
          attributes: {
            email: email,
            code: promocode
          }
        }
      }).pipe(
      map((res: any) => res),
      map((promocodeValidity) => {
          return {
            success: true,
            message: 'PROMO_CODE.APPLIED_SUCCESS'
          };
      }),);
  }

  verifyIban(iban: string) {
    const random = Math.floor(Math.random() * 9) + 1;
    let apiResponse;
    if (random % 2) {
      apiResponse = {
        success: true,
        bankName: 'Commerzbank München',
        iban: 'DE12 3456 1234 3456 1234 23',
        bic: '34561234'
      };
    } else {
      apiResponse = {
        success: false,
        message: 'Ungültiger Iban.'
      };
    }
    return observableOf(apiResponse).pipe(delay(100));
  }

  save(data) {
    const random = Math.floor(Math.random() * 9) + 1;
    let apiResponse;
    if (random % 2) {
      apiResponse = {
        success: true,
        message: 'Zahlungsdaten erfolgreich gespeichert.'
      };
    } else {
      apiResponse = {
        success: false,
        message: 'Fehler beim speichern der Zahlungsdaten.'
      };
    }
    return observableOf(apiResponse).pipe(delay(100));
  }
}
