import {ProjectActions, ProjectActionTypes} from './project.actions';
import {adapter, initialState, State} from './project.state';

export function reducer(state = initialState, action: ProjectActions): State {
  const current = state.selected;
  switch (action.type) {
    case ProjectActionTypes.LoadAll:
    case ProjectActionTypes.Create:
    case ProjectActionTypes.LoadOne:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case ProjectActionTypes.SaveDraft:
    case ProjectActionTypes.Publish:
      return {
        ...state,
        loading: true
      };
    case ProjectActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.projects, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case ProjectActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
    case ProjectActionTypes.LoadOneSuccess:
    case ProjectActionTypes.CreateSuccess:
    case ProjectActionTypes.SaveDraftSuccess:
    case ProjectActionTypes.PublishSuccess:
      return adapter.upsertOne(action.project, {
        ...state,
        selected: action.project,
        loading: false,
      });
    case ProjectActionTypes.LoadOneFail:
    case ProjectActionTypes.SaveDraftFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
    case ProjectActionTypes.PublishFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

