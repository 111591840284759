<div class="mat-dialog-inner-wrap">
  <div class="mat-dialog-header">
    <button class="mat-dialog-close" mat-mini-fab mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
    <h2 mat-dialog-title>
      {{data?.label ? ('LABELS.EDIT_LABEL' | translate) : ('LABELS.CREATE_LABEL' | translate) }}
    </h2>
  </div>

  <div mat-dialog-content class="p-0 o-x-hidden">
    <dvtx-create-label-form *ngIf="!editingMode" [scope]="scope" [origin]="origin" (onSubmitCreation)="submitCreateLabel($event)" (onCancelCreation)="dialogRef.close()"></dvtx-create-label-form>
    <dvtx-edit-label-form *ngIf="editingMode" [scope]="scope" [origin]="origin" (onSubmitEditing)="submitCreateLabel($event)" (onCancelEditing)="dialogRef.close()" [label]="label"></dvtx-edit-label-form>
  </div>
</div>
