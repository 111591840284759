
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProcessGroup} from '../models/process-group';
import {ProcessGroupBuilder} from '../builders/process-group.builder';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';

@Injectable()
export class ProcessGroupService {
  readonly BASE_PATH = '/api/v1/workflow_engine/processes';
  basePath: string;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  getAll(processId: string): Observable<ProcessGroup[]> {
    return this._http.get(`${this.basePath}/${processId}/process_groups`).pipe(
      map((response: any) => response.data.map(processGroup => ProcessGroupBuilder.fromResponse(processGroup))))
  }

  get(id, groupId): Observable<ProcessGroup> {
    return this._http.get(`${this.basePath}/${id}/process_groups/${groupId}`).pipe(
      map((res: any) => ProcessGroupBuilder.fromResponse(res.data)))
  }

  create(processId, processGroup: ProcessGroup): Observable<ProcessGroup> {
    console.log(processGroup);
    const payload = ProcessGroupBuilder.toRequest(processGroup);
    return this._http.post(`${this.basePath}/${processId}/process_groups`, payload).pipe(
      map((res: any) => ProcessGroupBuilder.fromResponse(res)))
  }

  delete(id): Observable<ProcessGroup> {
    return this._http.delete(`${this.basePath}/${id}`).pipe(
      map((res: any) => ProcessGroupBuilder.fromResponse(res)))
  }

  update(id, body): Observable<ProcessGroup> {
    return this._http.put(`${this.basePath}/${id}`, body).pipe(
      map((res: any) => ProcessGroupBuilder.fromResponse(res)))
  }
}
