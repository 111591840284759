import {ExcelExportActions, ExcelExportActionTypes} from './excel-export.actions';
import {initialState, State} from './excel-export.state';

export function reducer(state = initialState, action: ExcelExportActions): State {
  switch (action.type) {
    case ExcelExportActionTypes.GenerateExcel:
      return {
        ...state,
        loading: true
      };

    case ExcelExportActionTypes.GenerateExcelSuccess:
    case ExcelExportActionTypes.GenerateExcelFail:
      return {
        ...state,
        loading: false
      };
  }
}

