import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {CollectorLookupItem} from './collector-item-lookup';

/**
 * Builds the Collecto lookup Item response model for the cache.
 */
export class CollectorLookupItemBuilder implements IApiResourceBuilder<CollectorLookupItem> {
  /**
   * Creates a new builder.
   * The process ID is mandatory and used by the service because the API response does not
   * contain the process ID inside the payload (reduced payload).
   * NOTE: The process ID is already present inside the call.
   *
   * @param processId
   */
  constructor(public processId: string) {
  }

  fromResponse(data): CollectorLookupItem {
    return new CollectorLookupItem(
      data.id,
      this.processId,
      data.attributes.title,
      data.attributes.category_id,
      data.attributes.category_title,
      data.attributes.color);
  }

  toRequest(_: CollectorLookupItem) {
    return null;
  }
}
