import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable, of, Subject} from 'rxjs';
import {BookmanServiceAccount} from 'app/+store/bookman-service-account/bookman-service-account';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {BookmanServiceAccountActions, BookmanServiceAccountSelectors, OrganizationSelectors} from 'app/+store';
import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  first,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import {Organization} from 'app/models/organization.model';
import {ITabNavRoute} from '../../../modules/organization-card/models/tab-nav-route.interface';
import {Net} from 'app/lib/net/uuid';
import {BookmanServiceAccountService} from '../../../../../+store/bookman-service-account/bookman-service-account.service';
import {
  SignInToBookmanServiceAccountFail,
  SignInToBookmanServiceAccountSuccess
} from '../../../../../+store/bookman-service-account/bookman-service-account.actions';
import {Portal, TemplatePortal} from '@angular/cdk/portal';

@Component({
  selector: 'dvtx-bookman-cockpit-settings-frame',
  templateUrl: './bookman-cockpit-settings-frame.component.html',
  styleUrls: ['./bookman-cockpit-settings-frame.component.scss']
})
export class BookmanCockpitSettingsFrameComponent implements AfterViewInit, OnInit, OnDestroy {
  onDestroy = new Subject();

  account$: Observable<BookmanServiceAccount>;
  loading$: Observable<boolean>;
  loading = true;
  credentialError$: Observable<boolean>;

  form: UntypedFormGroup;
  isAccountConnected = false;
  credentialError = false;
  public organization$: Observable<Organization>;

  routes: ITabNavRoute[] = [];
  activeLink: string;
  accountLockedError: boolean;

  @ViewChild('callToActionRef', { static: true }) callToActionRef: TemplateRef<any>;
  callToActionPortal: Portal<any>;

  constructor(public _store: Store<AppState>,
              private router: Router,
              private _route: ActivatedRoute,
              private _fb: UntypedFormBuilder,
              private _serviceAccountSrv: BookmanServiceAccountService,
              private _ngZone: NgZone,
              private _cdr: ChangeDetectorRef,
              private _viewContainerRef: ViewContainerRef) {
    this.initForm();
  }

  ngOnInit() {
    this.loading$ = this._store.select(BookmanServiceAccountSelectors.loadingState);
    this.loading$.pipe(takeUntil(this.onDestroy)).subscribe(loading => {
      this.loading = loading;
      this._cdr.detectChanges();
    });
    this.credentialError$ = this._store.select(BookmanServiceAccountSelectors.errorState);
    this.account$ = this._store.select(BookmanServiceAccountSelectors.getAccount);
    this.organization$ = this._store.select(OrganizationSelectors.getSelected).pipe(filter(organization => !!organization));

    this.organization$.pipe(filter(o => !!o), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy)).subscribe(org => {
      this._ngZone.runOutsideAngular(() => {
        this._store.dispatch(new BookmanServiceAccountActions.GetBookmanServiceAccount());
      });
    });

    this._route.params
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe((params: ParamMap) => {
        const id = params['id'];
        if (Net.validUUID(id)) {
          this.routes = [{
            title: 'FIBU.SERVICE_ACCOUNT',
            route: `/organization/${id}/bookman-cockpit-settings`
          }];
          this.activeLink = this.routes[0].title;
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.callToActionPortal = this._createPortal(this.callToActionPortal, this.callToActionRef);
    });
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }

  initForm() {
    this.form = this._fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onBackClick() {
    this.router.navigate(['user-profile/navigation']);
  }

  unlinkAccount() {
    this._store.dispatch(new BookmanServiceAccountActions.SignOutFromBookmanServiceAccount());
  }

  connect() {
    const payload = {
      email: this.form.value.username,
      password: this.form.value.password
    };
    this._serviceAccountSrv.signInBookmanServiceAccount(payload).pipe(
      first(),
      switchMap((bookmanServiceAccount: BookmanServiceAccount) => {
        this._store.dispatch(new SignInToBookmanServiceAccountSuccess(bookmanServiceAccount));
        this.accountLockedError = false;
        return [new SignInToBookmanServiceAccountSuccess(bookmanServiceAccount)];
      }),
      catchError(err => {
        console.error(err);
        this.accountLockedError = true;
        return of(new SignInToBookmanServiceAccountFail(err));
      })).subscribe()
  }
}
