import { IContactEmailAddress } from '../models/contact.interface';
import { ContactEmailAddress } from '../models/contact';

export namespace ContactEmailAddressBuilder {
  export function fromResponse(response, catalog = null): IContactEmailAddress {
    const attrs = response.attributes;
    return new ContactEmailAddress(response.id, attrs.location_or_type, attrs.email_address,
                                   attrs.order, attrs.main_address);
  }

  export function toRequest(record: IContactEmailAddress) {
    return {
      data: {
        id: record.id,
        type: record.type,
        attributes: {
          main_address: record.mainAddress,
          location_or_type: record.locationOrType,
          email_address: record.emailAddress,
          order: record.order
        }
      }
    };
  }
}
