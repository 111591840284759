import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {FibuProcess} from './fibu-process';

export interface State extends EntityState<FibuProcess> {
  selected?: FibuProcess;
  loading: boolean;
}

export const adapter = createEntityAdapter<FibuProcess>();

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
