import { Component, Input, ChangeDetectionStrategy, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'dvtx-icon-picker-dropdown',
  templateUrl: './icon-picker-dropdown.component.html',
  styleUrls: ['./icon-picker-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconPickerDropdownComponent {
  @ViewChild('buttonToggler') buttonToggler: MatButton;
  public showIconPicker = false;

  icons = ['accessibility', 'mail', 'alarm', 'notifications_off', 'domain_verification', 'phone_forwarded', 'assessment', 'autorenew', 'date_range', 'donut_small', 'event', 'feedback', 'history', 'help', 'event_note', 'hourglass_empty', 'rate_review', 'person_pin', 'visibility', 'work', 'warning', 'beenhere', 'security', 'call_merge', 'forum'];

  @Output() onSelection: EventEmitter<any> = new EventEmitter<any>(null);

  @Input() disabled = false;
  @Input() selected = null;

  @Input() xPosition = 'after';
  @Input() yPosition = 'below';
  @Input() tooltipPosition = 'top right';
  @Input() buttonType = 'icon';

  selectIcon($event) {
    this.onSelection.emit($event);
    this.showIconPicker = false;
  }

  public openIconPicker() {
    this.buttonToggler._elementRef.nativeElement.click()
  }
}
