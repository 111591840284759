import {IResource} from 'app/shared/modules/api/models/resource.interface';

export class WorkflowTemplateCategory implements IResource {
  readonly type = 'workflow_template_category';

  constructor(public id: string,
              public title: string,
              public color: string,
              public createdAt: Date,
              public updatedAt?: Date) {
  }
}

export interface ITemplateCategoryParams {
  title: string;
  color: string;
}
