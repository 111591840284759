import { WorkflowList } from '../models/workflow.mock';
import { Injectable } from '@angular/core';
import { IWorkflow } from '../models/workflow.model';

@Injectable()

export class AuditWorkflowService {
  public workList: IWorkflow[];

  constructor() {
    this.workList = WorkflowList;
  }

  getAuditWorkflowList() {
    return this.workList;
  }

  isInfoScreenShowing () {
    return true;
  }

  isSubareaVerifyCompleted () {
    return false;
  }
}
