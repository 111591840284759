import {ProcessParticipantAclActions, ProcessParticipantAclActionTypes} from './process-participant-acl.actions';
import {adapter, initialState, State} from './process-participant-acl.state';

export function reducer(state = initialState, action: ProcessParticipantAclActions): State {
  switch (action.type) {
    case ProcessParticipantAclActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case ProcessParticipantAclActionTypes.LoadAllSuccess:
      return adapter.setAll(action.acls, {
          ...state,
          loading: false,
      });
    case ProcessParticipantAclActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false
      };
  }
  return state;
}

