<div class="mat-dialog-inner-wrap">
  <div class="mat-dialog-header dvtx-bg-color dvtx-bg-cool-grey-25">
    <h2 mat-dialog-title>Choose File</h2>
    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="mat-dialog-container">
    <div class="container-fluid">
      <div class="row py-1">
        <div class="col-12">
          <div class="drag-n-drop">
            <div class="drag-n-drop__icon">
              <mat-icon aria-label="upload">file_upload</mat-icon>
            </div>
            <div class="drag-n-drop__button">
              <button mat-button color="gray" (click)="onDownload()">
                Dateien hochladen oder hierherziehen
              </button>
              <p>EXCEL / CSV max 20MB</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
