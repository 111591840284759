import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {GrantThorntonService} from './grant-thornton.service';
import {GrantThorntonApiResourceService} from './grant-thornton-api-resource.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    GrantThorntonApiResourceService,
    GrantThorntonService
  ]
})
export class GrantThorntonStoreModule {
}
