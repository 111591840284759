import {first, switchMap, takeUntil} from 'rxjs/operators';
import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import {InvoiceAddress} from 'app/shared/modules/api/models/invoice-address';
import {CartActionTypes, ShoppingCartService} from 'app/+store/licence/shopping-cart.service';
import {ShoppingCartDataSource} from 'app/shared/modules/api/models/shopping-cart-data-source';
import {PaymentOption} from 'app/shared/modules/api/models/payment-option';
import {PaymentOptionService} from 'app/+store/licence/payment-option.service';
import {forkJoin, Subject} from 'rxjs';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {SalesOrderService} from 'app/+store/licence/sales-order.service';
import {InvoiceAddressService} from 'app/+store/licence/invoice-address.service';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import {LicenceAssignmentActions, LicenceAssignmentSelectors, OrganizationSelectors} from 'app/+store';

@Component({
  selector: 'dvtx-license-buying',
  templateUrl: './license-buying.component.html',
  styleUrls: ['./license-buying.component.scss']
})
export class LicenseBuyingComponent implements OnInit, OnDestroy {

  loading = false;
  licenceSelectionFormGroup: UntypedFormGroup;
  invoiceSelectionFormGroup: UntypedFormGroup;
  paymentSelectionFormGroup: UntypedFormGroup;
  paymentCompleted = false;
  orderCompleted = false;
  cart: ShoppingCartDataSource;
  arrayOfCalls = [];
  forkJoinVariable;

  private onDestroy = new Subject();

  @ViewChild('stepper', { static: true }) stepper: MatStepper;

  constructor(private _fb: UntypedFormBuilder,
              private _orderSvc: SalesOrderService,
              private _invoiceService: InvoiceAddressService,
              private _paymentOptionSvc: PaymentOptionService,
              private _cartSvc: ShoppingCartService,
              private _router: Router,
              private _notService: NotificationService,
              private _dialogRef: MatDialogRef<LicenseBuyingComponent>,
              private _store: Store<AppState>,
              @Inject(MAT_DIALOG_DATA) public data) {
  }

  ngOnInit(): void {
    this.invoiceSelectionFormGroup = this._fb.group({});
    this.paymentSelectionFormGroup = this._fb.group({});
    this.licenceSelectionFormGroup = this._fb.group({
      selectedPlan     : [this.data.selectedPlan.type, Validators.required],
      selectedPlanAttributes : [this.data.selectedPlan],
      paymentInterval  : [this.data.paymentInterval],
      creditBalance: [this.data.creditBalance ? this.data.creditBalance : 0]
    });
    if (this.data.actionType) {
      this.licenceSelectionFormGroup.addControl('action_type', new UntypedFormControl(this.data.actionType))
    }

    this.cart = new ShoppingCartDataSource(this._cartSvc);
    this.stepper.selectionChange.subscribe(selection => {
      if (selection.selectedIndex === 3) {
        this.cart.loadingSubject.next(true);
        this.cart.refresh()
      }
    });
  }

  setPaymentCompleted($event: boolean) {
    this.paymentCompleted = $event;
  }

  previousStep() {
    this.stepper.previous();
  }

  addProductToCart() {
    this.arrayOfCalls = [];

    const keysOfControlles = Object.keys(this.licenceSelectionFormGroup.controls);

    const cartItem = {
      actionType: this.licenceSelectionFormGroup.controls['action_type'].value,
      quantity: this.licenceSelectionFormGroup.controls['licence_amount'].value,
      product_id: this.licenceSelectionFormGroup.controls['selectedPlan'].value,
      payment_interval: this.licenceSelectionFormGroup.controls['paymentInterval'].value
    }

    this.arrayOfCalls.push(this._cartSvc.addLicenceProductToCart(cartItem.product_id, cartItem.quantity, cartItem.payment_interval, cartItem.actionType));

    this.forkJoinVariable = forkJoin(...this.arrayOfCalls).pipe(
      takeUntil(this.onDestroy))
      .subscribe(results => {
        if (this.stepper.selectedIndex === 0) {
          this.stepper.next();
        }
      }, (error) => {
        console.log(error, 'error')
        for (let i = 0; i < error.length; i++) {
          this._notService.error(error[i].title);
        }
      })
  }

  saveInvoiceAddress() {
    this._saveInvoiceAddress().pipe(first()).subscribe(_ => this.stepper.next());
  }

  _saveInvoiceAddress() {
    const value = this.invoiceSelectionFormGroup.value;
    const address = new InvoiceAddress(
      value.id,
      value.firstName,
      value.lastName,
      value.organizationName,
      value.street,
      value.streetNo,
      value.zip,
      value.city,
      value.country,
      value.ustId,
      null);
    if (+value.id > 0) {
      return this._invoiceService
        .update(+value.id, address).pipe(
          switchMap((invoiceAddress: InvoiceAddress) => this._cartSvc.selectInvoiceAddress(invoiceAddress.id)))
    } else {
      return this._invoiceService
        .create(address).pipe(
          switchMap((invoiceAddress: InvoiceAddress) => {
            this.invoiceSelectionFormGroup.get('id').patchValue(+invoiceAddress.id);
            return this._cartSvc.selectInvoiceAddress(invoiceAddress.id)
          }));
    }
  }

  savePaymentOption() {
    this._savePaymentOption().pipe(
      first())
      .subscribe(_ => this.stepper.next());
  }

  _savePaymentOption() {
    const value = this.paymentSelectionFormGroup.value;
    if (+value.id > 0) {
      return this._cartSvc.selectPaymentOption(+value.id);
    } else {
      const paymentOption = new PaymentOption(
        value.id,
        value.firstName,
        value.lastName,
        value.iban,
        value.bic,
        value.confirmed);
      return this._paymentOptionSvc
        .create(paymentOption).pipe(
          switchMap((option: PaymentOption) => {
            this.paymentSelectionFormGroup.get('id').patchValue(+option.id);
            return this._cartSvc.selectPaymentOption(option.id)
          }));
    }
  }

  createOrder() {
    this.loading = true;
    this._orderSvc.checkout().pipe(first()).subscribe(_ => {
      this.loading = false;
      this.orderCompleted = true;
      this.stepper.next();
    });
  }

  goToLicenceAssignment() {
    if (this.orderCompleted) {
      this._store.select(OrganizationSelectors.getSelectedId).subscribe(id => {
        if (id) {
          this._store.dispatch(new LicenceAssignmentActions.LoadAll(id));
          this._store.select(LicenceAssignmentSelectors.getMyLicenceAssignments).pipe(
            takeUntil(this.onDestroy)).subscribe((myLicenceAssignment) => {
            if (myLicenceAssignment && myLicenceAssignment[0]) {
              const myCurrentLicence = myLicenceAssignment[0].attributes;
              this._dialogRef.close(myCurrentLicence);
              if (this.data.actionType && this.data.actionType !== CartActionTypes.BUY_USER_LICENCE) {
                this._router.navigate([`organization/${id}/licenses`]);
              }
            }
          });
        }
      });
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
