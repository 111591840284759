import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { stateKey } from './kanban-item.selectors';
import { reducer } from './kanban-item.reducer';
import { KanbanItemEffects } from './kanban-item.effects';
import { KanbanItemService } from './kanban-item.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([KanbanItemEffects])
  ],
  providers: [
    KanbanItemEffects,
    KanbanItemService
  ]
})
export class KanbanItemStoreModule {
}
