import {Component, Input, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'dvtx-person-selected-dropdown',
  templateUrl: './person-selected-dropdown.component.html',
  styleUrls: ['./person-selected-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonSelectedDropdownComponent implements OnDestroy {
  private _onDestroy = new Subject<void>();
  public showDropdownSelectedProfiles = false;
  public _selectedProfiles: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public showDropdown = false;

  @Input() public avatarSize = 'sm';
  @Input() public maxToShow = 3;

  @Input() set selectedProfiles(selectedProfiles) {
    if (selectedProfiles) {
      this._selectedProfiles.next(selectedProfiles);
    }
  };

  get selectedProfiles() {
    return this._selectedProfiles.value;
  }

  constructor(private avatarService: AvatarService) {
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
