
import { of as observableOf, Observable, Subject, BehaviorSubject } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  Input,
  OnInit,
  ChangeDetectorRef, ViewChild, TemplateRef, ViewContainerRef, AfterViewInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import {
  ProcessSelectors
} from 'app/+store';
import { ProcessEvent } from 'app/+store/process-event/process-event';
import { first } from 'rxjs/operators';
import { ProcessEventService } from 'app/+store/process-event/process-event.service';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';
import { FileUtils } from 'app/lib/file_utils';
import { Portal, TemplatePortal } from '@angular/cdk/portal';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SplitViewDialogComponent } from 'app/shared/modules/page-container/split-view-dialog/split-view-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ProcessEventType } from 'app/+store/process-event/process-event.interface';
import { Process } from 'app/+store/process/process';
import { formatDate } from '@angular/common';
import { CommentService } from 'app/+store/comment/comment.service';

export const STORE_KEY_TIMELINE_FILTER_SETTINGS = 'settings.project_room.timeline.filters';

@Component({
  selector: 'dvtx-export-comments',
  templateUrl: './export-comments.component.html',
  styleUrls: ['./export-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportCommentsComponent implements AfterViewInit, OnInit, OnDestroy {
  private onDestroy = new Subject();
  @ViewChild('commentDialogButtonsToolbarContext') commentDialogButtonsToolbarContext: TemplateRef<any>;
  @ViewChild('commentDialogContext') commentDialogContext: TemplateRef<any>;
  @ViewChild('commentTitleDialogContext') commentTitleDialogContext: TemplateRef<any>;
  private commentButtonsToolbarPortal: Portal<any>;
  private commentTitleContextPortal: Portal<any>;
  private commentContextPortal: Portal<any>;
  private commentDialogRef: MatDialogRef<SplitViewDialogComponent>;
  public creatingCommentExport = false;
  public ProcessEventType = ProcessEventType;
  public comments$ = new BehaviorSubject<any[]>([]);
  public commentsLoading = false;
  public selectAllComments = true;
  public selectedComments = {};
  @Input() public id;
  @Input() public currentProcessArtifact;
  @Input() private selectedId;
  public process$: Observable<Process>;
  public eventGroupsDates: {} = {};
  constructor(private _store: Store<AppState>,
    private _eventSvc: ProcessEventService,
    private _notifyService: NotificationService,
    private _viewContainerRef: ViewContainerRef,
    private _processEventService: ProcessEventService,
    private _commentService: CommentService,
    private _dialog: MatDialog,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.process$ = this._store.select(ProcessSelectors.getSelectedProcess);
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this._initCommentDialogPortals();
    });
  }

  private _initCommentDialogPortals() {
    if (!this.commentButtonsToolbarPortal) {
      this.commentButtonsToolbarPortal = new TemplatePortal(
        this.commentDialogButtonsToolbarContext,
        this._viewContainerRef
      );
    }

    if (!this.commentContextPortal) {
      this.commentContextPortal = new TemplatePortal(
        this.commentDialogContext,
        this._viewContainerRef
      );
    }

    if (!this.commentTitleContextPortal) {
      this.commentTitleContextPortal = new TemplatePortal(
        this.commentTitleDialogContext,
        this._viewContainerRef
      );
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public getProcessTitleById(id) {
    return this._store.select(ProcessSelectors.getProcessTitleById(id));
  }

  public showDate(id) {
    const eventIds = {};
    Object.keys(this.eventGroupsDates).forEach((key) => {
      eventIds[this.eventGroupsDates[key]] = key;
    });
    return observableOf(eventIds[id]);
  }

  public openCommentDialog() {
    this.commentsLoading = true;
    this.selectAllComments = true;
    this.selectedComments = {};
    if (this.currentProcessArtifact) {
      this._commentService
        .getAllArtifactComments(this.id)
        .pipe(first())
        .subscribe(comments => {
          comments.forEach(comment => {
            this.selectedComments[comment.id] = true;
            if (!this.eventGroupsDates[formatDate(comment.createdAt, 'dd.MM.yyyy', 'en-US')]) {
              this.eventGroupsDates[formatDate(comment.createdAt, 'dd.MM.yyyy', 'en-US')] = comment.id;
            }
          })
          this.comments$.next(comments);
          this.commentsLoading = false;
          this._cdr.detectChanges();
        }, err => {
          console.error(err);
          this.commentsLoading = false;
        });
    } else {
      this._processEventService
        .getAllComments(this.id)
        .pipe(first())
        .subscribe(comments => {
          if (this.selectedId && comments && comments.length > 0) {
            comments = comments.filter(a => a.itemId === this.selectedId);
          }
          comments.forEach(comment => {
            this.selectedComments[comment.id] = true;
            if (!this.eventGroupsDates[formatDate(comment.createdAt, 'dd.MM.yyyy', 'en-US')]) {
              this.eventGroupsDates[formatDate(comment.createdAt, 'dd.MM.yyyy', 'en-US')] = comment.id;
            }
          })
          this.comments$.next(comments);
          this.commentsLoading = false;
          this._cdr.detectChanges();
        }, err => {
          console.error(err);
          this.commentsLoading = false;
        });
    }
    this.commentDialogRef = this._dialog.open(SplitViewDialogComponent, {
      data: {
        color: '#007aff',
        icon: null,
        context: this.commentContextPortal,
        buttonsToolbar: this.commentButtonsToolbarPortal,
        title: this.commentTitleContextPortal
      },
      width: '800px'
    });
  }

  public closeCommentDialog() {
    this.commentDialogRef.close();
    this.comments$.next([]);
    this.selectedComments = {};
    this.commentsLoading = false;
    this.creatingCommentExport = false;
  }

  public generateCommentExport() {
    const ids = [];
    const selectableids = Object.keys(this.selectedComments);
    selectableids.forEach(selectableId => {
      if (this.selectedComments[selectableId]) {
        ids.push(selectableId);
      }
    });
    this._notifyService.info('PROJECT_ROOM.GENERATING_PDF')
    this.creatingCommentExport = true;
    if (this.currentProcessArtifact) {
      this._commentService.downloadArtifactComments(this.id, ids)
        .pipe(first())
        .subscribe(document => {
          this.creatingCommentExport = false;
          const mimeType = 'application/pdf';
          FileUtils.saveAsFile('Comments.pdf', document.content, mimeType);
        }, error => {
          console.error(error);
          this.creatingCommentExport = false;
          this._notifyService.error('SIGNATURE.ERRORS.FILE_COULD_NOT_BE_LOADED')
        }
        );
    } else {
      this._eventSvc.downloadComments(this.id, ids)
        .pipe(first())
        .subscribe(document => {
          this.creatingCommentExport = false;
          const mimeType = 'application/pdf';
          FileUtils.saveAsFile('Comments.pdf', document.content, mimeType);
        }, error => {
          console.error(error);
          this.creatingCommentExport = false;
          this._notifyService.error('SIGNATURE.ERRORS.FILE_COULD_NOT_BE_LOADED')
        }
        );
    }

  }

  public toggleAllComments($event: MatCheckboxChange) {
    const selectableids = Object.keys(this.selectedComments);
    selectableids.forEach(selectableid => {
      this.selectedComments[selectableid] = $event.checked;
    });
    this._cdr.detectChanges();
  }

  public selectComment($event: MatCheckboxChange, commentEvent: ProcessEvent) {
    this.selectedComments[commentEvent.id] = $event.checked;
    if (!$event.checked) {
      this.selectAllComments = false;
    } else {
      let areAllSelected = true;
      const selectableids = Object.keys(this.selectedComments);
      selectableids.forEach(selectableid => {
        if (!this.selectedComments[selectableid]) {
          areAllSelected = false;
        }
      });
      this.selectAllComments = areAllSelected;
    }
    this._cdr.detectChanges();
  }

  public openCommentDownloadDialog() {
    this.openCommentDialog();
  }
}
