import { Component, Input } from '@angular/core';

@Component({
  selector: 'dvtx-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})

export class AlertComponent {
  @Input() title;
  @Input() text;
  @Input() dismissible;
  @Input() color;
  @Input() icon;

  constructor() { }
}
