import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {KanbanBoard} from './kanban-board';
import {IKanbanBoardParams} from './kanban-board.interface';

export class KanbanBoardBuilder implements IApiResourceBuilder<KanbanBoard> {

  fromResponse(data): KanbanBoard {
    const kanbanBoard = new KanbanBoard(
      data.id,
      data.attributes.configuration_type,
      data.attributes.process_id,
      data.attributes.title,
      data.attributes.is_selected,
      data.attributes.show_priorities,
      data.attributes.show_statuses,
      data.attributes.all_collapsed,
      data.attributes.show_standard_tasks,
      data.attributes.show_approval_tasks,
      data.attributes.show_collecto_groups,
    );

    return kanbanBoard;
  }

  toRequest(params: IKanbanBoardParams) {
    return {
      data: {
        type: 'configuration',
        attributes: {
          title: params.title,
          process_id: params.processId,
          configuration_type: params.boardType,
          is_selected: params.isSelected,
          show_priorities: params.showPriorities,
          show_statuses: params.showStatuses,
          all_collapsed: params.allGroupsCollapsed,
          show_standard_tasks: params.showStandardTasks,
          show_approval_tasks: params.showApprovalTasks,
          show_collecto_groups: params.showCollectoGroups,
        }
      }
    };
  }
}
