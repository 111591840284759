import {TaskStatusActions, TaskStatusActionTypes} from './task-status.actions';
import {adapter, initialState, State} from './task-status.state';

export function reducer(state = initialState, action: TaskStatusActions): State {
  const current = state.selected;
  switch (action.type) {
    case TaskStatusActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case TaskStatusActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });

    case TaskStatusActionTypes.Create:
      return {
        ...state,
        loading: true,
      };

    case TaskStatusActionTypes.CreateSuccess:
    return adapter.upsertOne(action.status , {
      ...state,
      loading: false,
    });

    case TaskStatusActionTypes.Edit:
      return {
        ...state,
        loading: true,
      };

    case TaskStatusActionTypes.EditSuccess:
    return adapter.upsertOne(action.status, {
      ...state,
      loading: false,
    });

    case TaskStatusActionTypes.Delete:
      return {
        ...state,
        loading: true,
      };

    case TaskStatusActionTypes.DeleteSuccess:
    return adapter.removeOne(action.status.id , {
      ...state,
      loading: false,
    });
  }
  return state;
}
