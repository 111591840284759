import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './favorite.state';

export const stateKey = 'favorite';
const getFavoriteState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getFavoritesEntities,
  selectAll: getAllFavorites,
} = adapter.getSelectors(getFavoriteState);

export const getByResourceId = (id: string) => createSelector(
  getAllFavorites,
  favorites => favorites.find(favorite => favorite.resourceId === id)
);

export const favoriteResourceMap = createSelector(
  getAllFavorites,
  favorites => {
    const favoriteMap = {};
    favorites.forEach(fav => favoriteMap[fav.resourceId] = fav);
    return favoriteMap;
  }
);

export const favoriteByResourceId = (resourceId: string) => createSelector(
  favoriteResourceMap,
  favoriteMap => favoriteMap[resourceId]
);

export const getRecentPaginated = (start, end) => createSelector(
  getAllFavorites,
  favorites => favorites.slice(start, end)
);

export const getLoadingState = createSelector(
  getFavoriteState,
  state => state.loading
);
