import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TenantTenantEffects} from './tenant.effects';
import {reducer} from './tenant.reducer';
import {stateKey} from './tenant.selectors';
import {TenantTenantService} from './tenant.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([TenantTenantEffects])
  ],
  providers: [
    TenantTenantEffects,
    TenantTenantService
  ]
})
export class TenantTenantStoreModule {
}
