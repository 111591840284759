import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './project.state';

export const stateKey = 'project';
const getProjectState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getProjectEntities,
  selectAll: getAllProjects,
} = adapter.getSelectors(getProjectState);

export const getSelected = createSelector(
  getProjectState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getProjectEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllProjects,
  (entities) => entities
);

export const loadingState = createSelector(
  getProjectState,
  (state) => state.loading
);
