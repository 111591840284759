export class Wizard {
  constructor(public id: number,
              public title: string,
              public isFilled: boolean,
              public isLocked = false,
              public numberToShow?: number) {}
}

export namespace WizardBuilder {
  export function createFrom(apiWizardDefinition) {
    return new Wizard(apiWizardDefinition.id, apiWizardDefinition.title,
                      apiWizardDefinition.filled || apiWizardDefinition.is_filled,
                      apiWizardDefinition.locked || apiWizardDefinition.is_locked);
  }
}
