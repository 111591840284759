import {ICollectorAttachment} from './collector-attachment.interface';

export class CollectorAttachment implements ICollectorAttachment {
  readonly type = 'collector_attachments';
  public color: string;

  constructor(public id: string,
              public collectorId: string,
              public elementId: string,
              public title: string,
              public filename: string,
              public description: string,
              public uploadedBy: string,
              public version: number,
              public fingerprint: string,
              public size: number,
              public mimeType: string,
              public createdAt: Date,
              public updatedAt: Date,
              public content: string = null) {}
}
