import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CmsBlogEffects} from './cms-blog.effects';
import {CmsBlogService} from './cms-blog.service';
import {reducer} from './cms-blog.reducer';
import {stateKey} from './cms-blog.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([CmsBlogEffects])
  ],
  providers: [
    CmsBlogEffects,
    CmsBlogService
  ]
})
export class CmsBlogStoreModule {
}
