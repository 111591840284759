import {AuditContactImportRow, AuditContactImports} from '../models/audit-contact-imports';

export namespace AuditContactImportsBuilder {
  export function parseRow(row) {
    const item = new AuditContactImportRow(
      row.id,
      row.identifier,
      row.abbreviation,
      row.organization_name,
      row.country_code,
      row.first_name,
      row.last_name,
      row.role,
      row.email,
      row.phone,
      row.mobile,
      row.verified_at,
      row.verified_by_id,
      row.due_date,
      row.notification_language,
      row.comment
    );
    item.perCountryComment = row.global_comment;
    return item;
  }

  export function fromResponse(response, catalog = null): AuditContactImports {
    const attrs = response.attributes;

    const contactImport = new AuditContactImports(response.id,
      attrs.due_date,
      attrs.parent_process_id,
      attrs.rows.map(AuditContactImportsBuilder.parseRow)
    );
    contactImport.title = attrs.title;
    contactImport.profile = attrs.profile;
    contactImport.endsAt = attrs.ends_at;
    contactImport.startsAt = attrs.starts_at;
    contactImport.clientId = attrs.client_uuid;
    contactImport.clientName = attrs.client_name;
    return contactImport;
  }

  export function toRequest(record: AuditContactImports) {
    return {
      data: {
        id: record.id,
        type: record.type,
        attributes: {
          title: record.title,
          due_date: record.dueDate,
          ends_at: record.endsAt,
          starts_at: record.startsAt,
          client_id: record.clientId,
          parent_process_id: record.parentProcessId,
          rows: record.rows.map((row) => {
            return {
              id: row.id,
              identifier: row.identifier,
              abbreviation: row.abbreviation,
              organization_name: row.organizationName,
              country_code: row.countryCode,
              first_name: row.firstName,
              last_name: row.lastName,
              role: row.role,
              email: row.email,
              phone: row.phone,
              mobile: row.mobile,
              verified_at: row.verifiedAt,
              verified_by_id: row.verifiedById,
              due_date: row.dueDate,
              notification_language: row.notificationLanguage,
              comment: row.comment,
              global_comment: row.perCountryComment,
            };
          })
        }
      }
    };
  }
}
