import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiResourceService} from './services/api-resource.service';
import {EnvService} from './services/env.service';
import { KanbanApiResourceService } from './services/kanban-api-resource.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ApiResourceService,
    KanbanApiResourceService,
    EnvService
  ],
})
export class ApiResourceModule { }
