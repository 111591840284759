import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import {AngularTokenService} from 'angular-token';
import {CommentActions, ProcessArtifactActions, ProcessEventActions, TaskCommentActions} from 'app/+store';
import {ProcessEventType} from 'app/+store/process-event/process-event.interface';
import {CommentService} from 'app/+store/comment/comment.service';
import {first} from 'rxjs/operators';
import {
  ArtifactCommentEvent,
  CommentEvent,
  InstantMessageEvent,
  ProcessEvent
} from 'app/+store/process-event/process-event';

export type MessageFromType = 'collecto-item' | 'pr-timeline' | 'task' | 'appendix' | 'artifact_comment';

@Directive({
  selector: '[dvtx-mark-read]',
})
export class MarkReadDirective implements OnDestroy, OnInit, AfterViewInit {
  private readonly currentUserId: string;

  @Input('markReadMessageFrom') messageFrom: MessageFromType;
  @Input('markReadMessage') message;
  @Input('markReadTaskId') taskId;
  @Input('markReadResourceId') resourceId: string;
  @Output() onMarkedRead = new EventEmitter();

  private observer: IntersectionObserver | undefined;

  constructor(private elRef: ElementRef,
              private store: Store<AppState>,
              private commentSvc: CommentService,
              private token: AngularTokenService
  ) {
    this.currentUserId = token.currentUserData.uid;
  }

  ngOnInit() {
    this.createObserver();
  }

  ngAfterViewInit() {
    this.startObservingElements();
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }
  }

  private createObserver() {
    const options = {
      threshold: 0.5,
    };
    const isIntersecting = (entry: IntersectionObserverEntry) => entry.isIntersecting || entry.intersectionRatio > 0.5;
    this.observer = new IntersectionObserver((entries, _observer) => {
      entries.forEach(entry => {
        if (isIntersecting(entry)) {
          this.markAsRead()
        }
      });
    }, options);
  }

  private startObservingElements() {
    if (!this.observer) return;
    this.observer.observe(this.elRef.nativeElement);
  }

  private isFromCurrentUser() {
    return this.message && this.currentUserId === this.message.performer;
  }

  private isTaskCommentAuthorCurrentUser() {
    return this.message && this.currentUserId === this.message.authorEmail;
  }

  private isReadByCurrentUser() {
    return this.message &&
      this.message.seenBy &&
      this.message.seenBy.length &&
      this.message.seenBy.includes(this.currentUserId);
  }

  private markAsRead() {
    if (!this.message) return;
    if (this.isFromCurrentUser() || this.isReadByCurrentUser()) return;

    if (this.messageFrom === 'task' && !this.isTaskCommentAuthorCurrentUser()) {
      return this.store.dispatch(new TaskCommentActions.Read(this.taskId, this.message));
    }

    if (this.messageFrom === 'collecto-item' && !this.isTaskCommentAuthorCurrentUser()) {
      this.commentSvc.markProcessCommentRead(this.message.processId, this.message.id)
        .pipe(first())
        .subscribe(comment => {
          this.store.dispatch(new CommentActions.ReadSuccess(comment));
          this.onMarkedRead.emit(comment);
        });
      return;
    }

    if (this.messageFrom === 'artifact_comment' && !this.isTaskCommentAuthorCurrentUser()) {
      return this.commentSvc
        .markArtifactCommentRead(this.resourceId, this.message.id)
        .pipe(first())
        .subscribe(comment => {
          this.store.dispatch(new CommentActions.LoadOneSuccess(comment));
          this.onMarkedRead.emit(comment);
        });
    }

    switch (this.message.type) {
      case ProcessEventType.Comment:
        if (this.messageFrom === 'pr-timeline') {
          const comment = this.message as CommentEvent;
          return this.commentSvc.markProcessCommentRead(comment.processId, comment.commentId)
            .pipe(first()).subscribe((_comment) => {
              this.message.seenBy = _comment.seenBy;
              this.store.dispatch(new CommentActions.ReadSuccess(_comment));
              this.store.dispatch(new ProcessEventActions.ReadSuccess(this.message));
            }, err => console.error(err));
        } else {
          return this.store.dispatch(new CommentActions.Read(this.message));
        }
      case ProcessEventType.InstantMessage:
      case ProcessEventType.ExternalMessage:
        return this.store.dispatch(new ProcessEventActions.Read(this.message.processId, this.message.id));

      case ProcessEventType.ArtifactComment:
        if (this.messageFrom === 'pr-timeline') {
          const msg = this.message as ArtifactCommentEvent;
          return this.commentSvc
            .markArtifactCommentRead(msg.artifactId, msg.commentId)
            .pipe(first())
            .subscribe(comment => {
              this.store.dispatch(new CommentActions.LoadOneSuccess(comment));
              this.onMarkedRead.emit(comment);
            });
        }
        return;
    }
  }
}
