import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { FiltersContainerModule } from 'app/five-f/filters/filters-container.module';
import { TasksFiltersComponent } from './container/tasks-filters.component';
import { TooltipModule } from 'app/five-f/tooltip/tooltip.module'

@NgModule({
  imports: [
    SharedModule,
    FiltersContainerModule,
    TooltipModule
  ],
  declarations: [
    TasksFiltersComponent
  ],
  exports: [
    TasksFiltersComponent
  ]
})
export class TasksFiltersModule {
}
