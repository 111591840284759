import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {AlertsModule} from '../alerts/alerts.module';
import {UnsupportedBrowserComponent} from './containers/unsupported-browser/unsupported-browser.component';
import { CavBrowserSupportComponent } from './containers/cav-browser-support/cav-browser-support.component';

@NgModule({
  imports: [
    SharedModule,
    AlertsModule
  ],
  declarations: [
    UnsupportedBrowserComponent,
    CavBrowserSupportComponent
  ],
  exports: [
    UnsupportedBrowserComponent,
    CavBrowserSupportComponent,
    SharedModule,
    AlertsModule
  ]
})
export class BrowserSupportModule {
}
