import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Organization } from '../../../models/organization.model';
import {
  ClientActions,
  ContactActions,
  FeatureActions,
  LabelActions,
  LicenceAssignmentActions,
  MembershipActions,
  OrganizationActions,
  OrganizationSelectors
} from '../../../+store';
import { LoadOfOrganization } from 'app/+store/invitation/invitation.actions';
import { LoadMy } from 'app/+store/membership/membership.actions';

import { LicenceControlService } from 'app/shared/services/licence-control.service';
import { LICENCES_TYPES } from 'app/+store/licence-assignment/licence-assignment.selectors';
import { CreateOrganizationDialogContainerComponent } from '../../address-book/modules/address-book-table/containers/create-organization-dialog-container/create-organization-dialog-container.component';
import { LicenceUpgradeDialogComponent } from 'app/shared/components/dialogs/licence-upgrade-dialog/licence-upgrade-dialog.component';
import { LicenceUpgradeContactAdminDialogComponent } from 'app/shared/components/dialogs/licence-upgrade-contact-admin-dialog/licence-upgrade-contact-admin-dialog.component';
import { Router } from '@angular/router';
import { MotdActions } from 'app/+store/motd';

@Component({
  selector: 'dvtx-organization-banner-element',
  templateUrl: './banner-element.component.html',
  styleUrls: ['./banner-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationBannerElementComponent implements OnInit {
  private onDestroy: Subject<void> = new Subject<void>();
  public selectedOrganization: Observable<Organization>;
  private selectedOrganizationId = null;
  private searchTerm$: BehaviorSubject<string> = new BehaviorSubject(null);
  public searchTerm: string;
  @ViewChild('searchFieldRef') searchFieldRef;

  public currentUser = {
    hasRequiredLicence: false,
    hasAdministrationRights: false,
    isOrganizationalOwner: false,
    currentLicence: null,
    attributes: {},
    licencedOrganizations: []
  };
  public allOrganizations: Organization[] = [];
  public filteredOrgs: Organization[] = [];

  constructor(private _store: Store<any>,
    private _dialog: MatDialog,
    private _licenceControl: LicenceControlService,
    private _translateSvc: TranslateService,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.searchTerm$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(term => {
        if (term !== undefined && term != null) {
          this.filteredOrgs = this.allOrganizations.filter(c => c.name.toLowerCase().indexOf(term.toLowerCase()) >= 0);
        }
      });
    this._store.select(OrganizationSelectors.getAllOrganizations).pipe(filter(org => !!org), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(orgs => {
        this.allOrganizations = orgs;
        this.filteredOrgs = JSON.parse(JSON.stringify(orgs));
      })
    this.selectedOrganization = this._store.select(OrganizationSelectors.getSelected);
    this.selectedOrganization
      .pipe(filter(org => !!org), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy))
      .subscribe((org) => {
        if (org) {
          this._ngZone.runOutsideAngular(() => {
            this._store.dispatch(new LoadOfOrganization(org.id));
            this._store.dispatch(new LoadMy(org.id));
            this._store.dispatch(new MembershipActions.LoadAll(org.id));
            this._store.dispatch(new ContactActions.LoadAll(org));
            this._store.dispatch(new LicenceAssignmentActions.LoadAll(org.id));
            this._store.dispatch(new LicenceAssignmentActions.LoadAttachedOrganizations);
            // loaded now by OrganizationActionTypes.SelectSuccess
            // this._store.dispatch(new FeatureActions.LoadAll());
            this._store.dispatch(new MotdActions.LoadOne(org.id));
            this._store.dispatch(new LabelActions.LoadAll());
            setTimeout(_ => this._store.dispatch(new ClientActions.LoadAll(true)));

            this._ngZone.run(() => {
              this.selectedOrganizationId = org.id;
              this._cdr.detectChanges();
            })
          });
        }
      });

    this.currentUser = this._licenceControl._currentUser;
  }

  public async handleOrganizationClick(id: string) {
    this._store.dispatch(new OrganizationActions.Select(id));
    this._router.navigate([`/organization/${id}/navigation`], { queryParams: { lc: false } });
  }

  public openCreateOrganizationDialog() {

    const isTrial = this._licenceControl.isTrial(this.currentUser);

    if (isTrial && this.selectedOrganizationId !== null) {
      this._licenceControl.showLicenceDialog();
      return;
    }

    this._dialog.closeAll();
    if (this.currentUser.licencedOrganizations.length > 0 && this.currentUser.isOrganizationalOwner === false) {
      this._dialog.open(LicenceUpgradeContactAdminDialogComponent, {
        data: {
          allowComment: false,
          onSubmitAction: () => {
          },
          submitButtonTitle: this._translateSvc.instant('LICENCES.CONTACT_ADMIN_BUTTON')
        },
        width: '500px'
      });
      return;
    }

    if (this.currentUser.licencedOrganizations.length === 0) {
      this._dialog.open(CreateOrganizationDialogContainerComponent);
      return;
    }

    if (this.currentUser.licencedOrganizations.length === 1) {
      const licenceChecker = this._licenceControl.hasRequiredLicence(LICENCES_TYPES.ENTERPRISE);
      if (licenceChecker) {
        this._dialog.open(CreateOrganizationDialogContainerComponent, {
          data: {
            licence: this.currentUser.currentLicence
          }
        });
      }
      return;
    }

    this._dialog.open(LicenceUpgradeDialogComponent, {
      data: {
        title: 'LICENCES.NEED_CONTACT_US_TITLE',
        message: 'LICENCES.NEED_CONTACT_US_MESSAGE',
        onSubmitAction: () => {
        },
        submitButtonTitle: this._translateSvc.instant('GENERAL.OK_ACTION')
      },
      width: '400px',
    });

  }

  public applySearch($event) {
    this.searchTerm$.next($event);
  }

  public clearSearch() {
    this.searchTerm = '';
    this.searchTerm$.next('');
    setTimeout(() => {
      this.searchFieldRef.nativeElement.focus();
    }, 100);
  }
}
