import {ProcessEventActions, ProcessEventActionTypes} from './process-event.actions';
import {adapter, initialState, State} from './process-event.state';

export function reducer(state = initialState, action: ProcessEventActions): State {
  switch (action.type) {
    case ProcessEventActionTypes.LoadAll:
      // TODO: Revise: Unclear why necessary to reset the events
      // if (action.page === 1) {
        //   return adapter.setAll([], {
        //     ...state,
      //     loading: true,
      //   });
      // }
      return {
        ...state,
        loading: true
      };
    case ProcessEventActionTypes.LoadAllSuccess:
      if (action.refresh) {
          return adapter.setAll(action.events, {
              ...state,
              loading: false,
          });
      } else {
        return adapter.upsertMany(action.payload, {
          ...state,
          loading: false,
        });
      }
    case ProcessEventActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false
      };
    case ProcessEventActionTypes.LoadOne:
    case ProcessEventActionTypes.SendInstantMessage:
    case ProcessEventActionTypes.Read:
    case ProcessEventActionTypes.React:
      return {
        ...state,
        loading: true
      };
    case ProcessEventActionTypes.LoadOneSuccess:
    case ProcessEventActionTypes.SendInstantMessageSuccess:
    case ProcessEventActionTypes.ReadSuccess:
    case ProcessEventActionTypes.ReactSuccess:
      return adapter.upsertOne(action.event, {
        ...state,
        loading: false,
      });
    case ProcessEventActionTypes.DeleteSuccess:
      return adapter.removeOne(action.eventId, {
        ...state,
        loading: false,
      });
    case ProcessEventActionTypes.LoadOneFail:
    case ProcessEventActionTypes.SendInstantMessageFail:
    case ProcessEventActionTypes.ReadFail:
    case ProcessEventActionTypes.ReactFail:
      return {
        ...state,
        loading: false
      };
    case ProcessEventActionTypes.Reset:
      return Object.assign({}, initialState);
  }
  return state;
}
