import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalModule} from '@angular/cdk/portal';
import {SplitViewComponent} from './split-view/split-view.component';
import {SplitViewDialogComponent} from './split-view-dialog/split-view-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import {TranslateModule} from '@ngx-translate/core';
import {TabNavBarComponent} from './tab-nav-bar/tab-nav-bar.component';
import {RouterModule} from '@angular/router';
import {SplitViewContainerDialogComponent} from './split-view-container-dialog/split-view-container-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        PortalModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatTabsModule,
        TranslateModule,
        RouterModule
    ],
    declarations: [
        SplitViewComponent,
        SplitViewDialogComponent,
        SplitViewContainerDialogComponent,
        TabNavBarComponent
    ],
    exports: [
        SplitViewComponent,
        SplitViewDialogComponent,
        TabNavBarComponent,
    ]
})
export class PageContainerModule {
}
