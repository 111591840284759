import { IKanbanItem } from "./kanban-item.interface";
import { Label } from "app/+store/label/label";
import { IKanbanBoardTypes } from "app/+store/kanban-store/kanban-board/kanban-board.interface";
import { ComponentRef } from "@angular/core";
import { KanbanGroup } from "app/+store/kanban-store/kanban-group/kanban-group";

export class KanbanItem implements IKanbanItem {
  readonly type = 'item';
  public processId;
  public processTitle;
  public title: string = '';
  public participants: any[] = [];
  public attachementsIds: string[] = [];
  public labelsIds: string[];
  public labels: Label[] = [];
  public groupId: string = '';
  public group: KanbanGroup;
  public order: number;
  public groupOrder: number;
  public htmlComponentRef: ComponentRef<any> = null;
  public allowedToDropInGroups: string[] = [];
  public color: string = '#fff';

  public actualItem = null;

  constructor(
    public id: string,
    public referenceId: string,
    public boardType: IKanbanBoardTypes | any
  ) {
  }
}
