import { IContactRequestOverview } from './subarea-verifier-request-overview.interface';

export const RequestOverviewListMock: IContactRequestOverview[] = [
  {
    country: 'France',
    countryCode: 'fr',
    dueDate: '2018-01-02T18:00:00',
    component: 'MuellerFrance Societé civile',
    componentAuditor: 'BDO LLP',
    lastName: 'Montagne',
    firstName: 'Cédric',
    hasAuditorDataChanged: true,
    status: 'verified',
    statusText: 'verifziert'
  },
  {
    country: 'France',
    countryCode: 'fr',
    dueDate: '2018-01-02T18:00:00',
    component: 'Mueller TEC Lausanne',
    componentAuditor: 'BDO LLP',
    lastName: 'Fourcade',
    firstName: 'Zoe',
    hasAuditorDataChanged: true,
    status: 'overdue',
    statusText: 'überfällig'
  },
  {
    country: 'Italy',
    countryCode: 'it',
    dueDate: '2018-01-02T18:00:00',
    component: 'Mueller Italia S.R.L',
    componentAuditor: 'BDO LLP',
    lastName: 'Orecchia',
    firstName: 'Belinda',
    hasAuditorDataChanged: false,
    status: 'open',
    statusText: 'offen'
  },
  {
    country: 'Italy',
    countryCode: 'it',
    dueDate: '2018-01-02T18:00:00',
    component: 'Mueller Napoli S.R.L',
    componentAuditor: 'BDO LLP',
    lastName: 'Di Bella',
    firstName: 'Adriano',
    hasAuditorDataChanged: true,
    status: 'open',
    statusText: 'offen'
  },
  {
    country: 'Mexico',
    countryCode: 'mx',
    dueDate: '2018-01-02T18:00:00',
    component: 'Empresa Mueller',
    componentAuditor: 'BDO LLP',
    lastName: 'Fourcade',
    firstName: 'Zoe',
    hasAuditorDataChanged: false,
    status: 'open',
    statusText: 'offen'
  },
  {
    country: 'GB',
    countryCode: 'gb',
    dueDate: '2018-01-02T18:00:00',
    component: 'Mueller Ltd.',
    componentAuditor: 'BDO LLP',
    lastName: 'Thomton',
    firstName: 'Eve',
    hasAuditorDataChanged: false,
    status: 'open',
    statusText: 'offen'
  }
];
