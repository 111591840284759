export enum InvitationStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Declined = 'Declined',
  Deleted = 'Deleted',
  Undefined = 'Undefined'
}

export enum InvitationType {
  Member = 'Member',
  ContactPerson = 'ContactPerson'
}

export class Invitation {
  public belongsToOrganizationId?: string;
  public email: string = null;

  constructor(
    public id: string,
    public invitee_email: string,
    public inviter_name: string,
    public inviter_email: string,
    public inviter_first_name: string,
    public inviter_last_name: string,
    public current_status: InvitationStatus,
    public created_at: Date,
    public type: InvitationType = InvitationType.Member
  ) {
  }

  static fromResponse() {}
}

export interface InvitationInnerResponse {
  id: string,
  attributes: {
    invitee_email: string,
    inviter_name: string,
    inviter_email: string,
    inviter_first_name: string,
    inviter_last_name: string,
    current_state: string,
    created_at: Date,
  }
}

export interface InvitationApiResponse {
  data: InvitationInnerResponse
}

export interface InvitationResponseList {
  data: InvitationInnerResponse[]
}
