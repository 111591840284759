import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TaskComment } from './task-comment';

export enum TaskCommentActionTypes {
  LoadAll = '[[TaskComment] Load all Task Comment]',
  LoadAllFail = '[[TaskComment] Load all Task Comment Fail]',
  LoadAllSuccess = '[[TaskComment] Load all Task Comment Success]',
  DeleteComment = '[TaskComment] Delete Task Comment',
  DeleteCommentSuccess = '[TaskComment] Delete Task Comment Success',
  DeleteCommentFail = '[TaskComment] Delete Task Comment Fail',
  SendComment = '[TaskComment] Send Task Comment',
  SendCommentSuccess = '[TaskComment] Send Task Comment Success',
  SendCommentFail = '[TaskComment] Send Task Comment Fail',
  UpdateComment = '[TaskComment] Update Task Comment',
  UpdateCommentSuccess = '[TaskComment] Update Task Comment Success',
  UpdateCommentFail = '[TaskComment] Update Task Comment Fail',
  Read = '[TaskComment] Read Event',
  ReadSuccess = '[TaskComment] Read Event Success',
  ReadFail = '[TaskComment] Read Event Fail'
}

export class LoadAll implements Action {
  readonly type = TaskCommentActionTypes.LoadAll;

  constructor(public taskId: string) {
  }
}

export class LoadAllFail implements Action {
  readonly type = TaskCommentActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = TaskCommentActionTypes.LoadAllSuccess;

  constructor(public payload: TaskComment[]) {
  }
}

export class DeleteComment implements Action {
  readonly type = TaskCommentActionTypes.DeleteComment;

  constructor(public taskId, public comment: TaskComment) {
  }
}

export class DeleteCommentSuccess implements Action {
  readonly type = TaskCommentActionTypes.DeleteCommentSuccess;

  constructor(public comment: TaskComment) {
  }
}

export class DeleteCommentFail implements Action {
  readonly type = TaskCommentActionTypes.DeleteCommentFail;

  constructor(public payload: any) {
  }
}

export class SendComment implements Action {
  readonly type = TaskCommentActionTypes.SendComment;

  constructor(public taskId:string, public comment: TaskComment) {
  }
}

export class SendCommentSuccess implements Action {
  readonly type = TaskCommentActionTypes.SendCommentSuccess;

  constructor(public comment: TaskComment) {
  }
}

export class SendCommentFail implements Action {
  readonly type = TaskCommentActionTypes.SendCommentFail;

  constructor(public payload: any) {
  }
}

export class UpdateComment implements Action {
  readonly type = TaskCommentActionTypes.UpdateComment;

  constructor(public taskId:string, public comment: TaskComment) {
  }
}

export class UpdateCommentSuccess implements Action {
  readonly type = TaskCommentActionTypes.UpdateCommentSuccess;

  constructor(public comment: TaskComment) {
  }
}

export class UpdateCommentFail implements Action {
  readonly type = TaskCommentActionTypes.UpdateCommentFail;

  constructor(public payload: any) {
  }
}

export class Read implements Action {
  readonly type = TaskCommentActionTypes.Read;

  constructor(public taskId: string, public comment: TaskComment) {
  }
}

export class ReadSuccess implements Action {
  readonly type = TaskCommentActionTypes.ReadSuccess;

  constructor(public comment: TaskComment) {
  }
}

export class ReadFail implements Action {
  readonly type = TaskCommentActionTypes.ReadFail;

  constructor(public payload: any) {
  }
}

export type TaskCommentActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | DeleteComment
  | DeleteCommentSuccess
  | DeleteCommentFail
  | SendComment
  | SendCommentSuccess
  | SendCommentFail
  | UpdateComment
  | UpdateCommentSuccess
  | UpdateCommentFail
  | Read
  | ReadSuccess
  | ReadFail;
