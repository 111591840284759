import {PartnerLinkActions, PartnerLinkActionTypes} from './partner-link.actions';
import {adapter, initialState, State} from './partner-link.state';

export function reducer(state = initialState, action: PartnerLinkActions): State {
  const current = state.selected;
  switch (action.type) {
    case PartnerLinkActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case PartnerLinkActionTypes.Create:
    case PartnerLinkActionTypes.MarkVisible:
    case PartnerLinkActionTypes.MarkHidden:
    case PartnerLinkActionTypes.LoadOne:
    case PartnerLinkActionTypes.Remove:
    case PartnerLinkActionTypes.Save:
      return {
        ...state,
        loading: true
      };
    case PartnerLinkActionTypes.LoadOneSuccess:
      return adapter.addOne(action.partnerLink, {
        ...state,
        selected: null,
        loading: false,
      });
    case PartnerLinkActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.partnerLinks, {
        ...state,
        selected: null,
        loading: false,
      });
    case PartnerLinkActionTypes.CreateSuccess:
    case PartnerLinkActionTypes.SaveSuccess:
    case PartnerLinkActionTypes.MarkVisibleSuccess:
    case PartnerLinkActionTypes.MarkHiddenSuccess:
      return adapter.upsertOne(action.partnerLink, {
        ...state,
        selected: action.partnerLink,
        loading: false,
      });
    case PartnerLinkActionTypes.CreateFail:
    case PartnerLinkActionTypes.LoadAllFail:
    case PartnerLinkActionTypes.LoadOneFail:
    case PartnerLinkActionTypes.RemoveFail:
    case PartnerLinkActionTypes.SaveFail:
    case PartnerLinkActionTypes.MarkVisibleFail:
    case PartnerLinkActionTypes.MarkHiddenFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
    case PartnerLinkActionTypes.RemoveSuccess:
      return adapter.removeOne(action.partnerLink.id, {
        ...state,
        selected: (current && current.id === action.partnerLink.id) ?  null : current,
        loading: false,
      });
  }
  return state;
}

