import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {CollectorLookupItem} from './collector-item-lookup';

/**
 * Collecto element's lookup store.
 * Contains a fetchStatus property to signalize the API  request effect
 * to skip an item's API request if it is already in fetched or in fetching
 * state.
 */
export interface State extends EntityState<CollectorLookupItem> {
  fetchStatus: {[itemId: string]: boolean}
}

export const adapter = createEntityAdapter<CollectorLookupItem>();

export const initialState: State = adapter.getInitialState({
  fetchStatus: {}
});
