import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {OperatorMemberEffects} from './member.effects';
import {reducer} from './member.reducer';
import {stateKey} from './member.selectors';
import {OperatorMemberService} from './member.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([OperatorMemberEffects])
  ],
  providers: [
    OperatorMemberEffects,
    OperatorMemberService
  ]
})
export class OperatorMemberStoreModule {
}
