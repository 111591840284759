import {Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';


@Component({
  selector: 'dvtx-qr-confirmation-dialog',
  templateUrl: './qr-confirmation-dialog.component.html',
  styleUrls: ['./qr-confirmation-dialog.component.scss']
})
export class QrConfirmationDialogComponent implements OnInit {

  @Input()
  QRCode: string;

  constructor() { }

  ngOnInit() {
  }

}



