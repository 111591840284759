import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AngularTokenService} from 'angular-token';

@Component({
  selector: 'dvtx-oauth-callback',
  templateUrl: './oauth-callback.component.html',
  styleUrls: ['./oauth-callback.component.scss']
})
export class OauthCallbackComponent implements OnInit {

  constructor(private _route: ActivatedRoute,
              private _token: AngularTokenService) { }

  ngOnInit() {
    const returnUrl = this._route.snapshot.queryParams['redirect_url'];
    const uid = this._route.snapshot.queryParams['uid'];
    const accessToken = this._route.snapshot.queryParams['access_token'];
    const client = this._route.snapshot.queryParams['client'];
    const expiry = this._route.snapshot.queryParams['expiry'];

    localStorage.setItem('uid', uid);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('client', client);
    localStorage.setItem('tokenType', 'Bearer');
    localStorage.setItem('expiry', expiry);
    this._token.getAuthDataFromStorage();

    try {
      window.open(atob(returnUrl), '_self')
    } catch (error) {
      console.error(error);
      window.open('/#/dashboard', '_self')
    }
  }
}
