import {Injectable} from '@angular/core';
import {ILabelParams} from './label.interface';
import {Label} from './label';
import {LabelBuilder} from './label.builder';
import {KanbanApiResourceService} from 'app/shared/modules/api-resource/services/kanban-api-resource.service';
import {Observable} from 'rxjs';

@Injectable()
export class LabelService {
  readonly BASE_PATH = 'api/v1/labels/labels';

  constructor(private _http: KanbanApiResourceService) {
  }

  create(params: ILabelParams): Observable<Label> {
    const builder = new LabelBuilder();
    const payload = builder.toRequest(params);
    return <Observable<Label>>this._http.post<LabelBuilder, Label>(builder, `${this.BASE_PATH}`, payload);
  }

  edit(id: string, params: ILabelParams): Observable<Label> {
    const builder = new LabelBuilder();
    const payload = builder.toRequest(params);
    return <Observable<Label>>this._http.put<LabelBuilder, Label>(builder, `${this.BASE_PATH}/${id}`, payload);
  }

  getAll(boardId = null): Observable<Label[]> {
    const builder = new LabelBuilder();
    let q = '';
    if(boardId) {
      q = `?configuration_id=${boardId}`;
    }
    return <Observable<Label[]>>this._http.get<LabelBuilder, Label>(builder, `${this.BASE_PATH}${q}`);
  }

  getOne(id: string): Observable<Label> {
    const builder = new LabelBuilder();
    return <Observable<Label>>this._http.get<LabelBuilder, Label>(builder, `${this.BASE_PATH}/${id}`);
  }

  remove(id: string): Observable<Label> {
    const builder = new LabelBuilder();
    return <Observable<Label>>this._http.del<LabelBuilder, Label>(builder, `${this.BASE_PATH}/${id}`);
  }
}
