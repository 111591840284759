import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {Colors} from 'app/lib/color/hsl';

@Component({
  selector: 'dvtx-color-picker-dropdown',
  templateUrl: './color-picker-dropdown.component.html',
  styleUrls: ['./color-picker-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerDropdownComponent {
  @ViewChild('buttonToggler') buttonToggler: MatButton;
  colorsLib = Colors;
  public showColorPicker = false;

  colors = ['#e2445c', '#bb3354', '#ff158a', '#ff5ac4', '#784bd1', '#a25ddc', '#00c875', '#037f4c', '#579bfc', '#0086c0', '#66ccff', '#c4c4c4', '#808080', '#333333', '#fdab3d', '#ffcb00', '#cab641', '#9cd326', '#ff642e', '#7f5347'];

  @Output() onSelection: EventEmitter<any> = new EventEmitter<any>(null);

  @Input() disabled = false;
  @Input() selected = '#000000';

  @Input() xPosition = 'after';
  @Input() yPosition = 'below';
  @Input() tooltipPosition = 'top right';
  @Input() buttonType = 'icon';
  @Input() tooltip = 'GENERAL.CHOOSE_COLOR';

  selectColor($event) {
    this.onSelection.emit($event);
    this.showColorPicker = false;
  }

  selectCustomColor($event) {
    this.onSelection.emit($event.target.value);
    this.showColorPicker = false;
  }

  public openColorPicker() {
    this.buttonToggler._elementRef.nativeElement.click()
  }
}
