import {saveAs} from 'file-saver';
import * as environment from 'environments/environment';

export namespace FileUtils {
  export const DEFAULT_SUPPORTED_FILE_TYPES: string[] = [
    '7z', 'ac', 'ac_', 'accdb', 'avi', 'csv', 'pdf', 'jpeg', 'jpg', 'doc', 'docx', 'dtd', 'dvsdrw', 'fib', 'fibdoc', 'gz', 'htm', 'html', 'ics', 'pkb', 'pks', 'ppt', 'pptx',
    'gif', 'indd', 'png', 'txt', 'eml', 'kor', 'm4v', 'mdb', 'msg', 'mp3', 'mp4', 'mov', 'wmv', 'log',
    'odt', 'odg', 'odp', 'ods', 'psd', 'ai', 'eps', 'rar', 'rft', 'sta', 'svg', 'tar', 'tax', 'tiff', 'tif', 'vcf', 'vcard',
    'xhtml', 'xlsb', 'xls', 'xlsx', 'xlsm', 'xml', 'xslt', 'webm', 'zip'
  ];
  export const DEFAULT_MAX_FILE_SIZE = environment.environment.maxFileSize;

  export function getExtension(name) {
    return name.split('.').pop().toLowerCase();
  }

  /**
   * Saves a file given as base64 encoded string.
   *
   * Directly writing into the blob fails and creates a corrupted docx.
   * Reference solution
   * - https://stackoverflow.com/questions/12168909/blob-from-dataurl
   */
  export function saveAsFile(fileName, content, mimeType) {
    const byteString = atob(content);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeType });
    saveAs(blob, fileName);
  }

  export function downloadByUrl(url) {
    const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !('MSStream' in window);

    if (isSafari && iOS) {
      // Window open is not supported on click a-tags without local inplace javascript
      window.location.href = url;
    } else if (isSafari) {
      // Triggers popup blocker: We change location instead
      // window.open(url, '_blank');
      window.location.href = url;
    } else {
      window.open(url);
    }
  }
}
