import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {DocumentTemplate} from './document-template';

export interface State extends EntityState<DocumentTemplate> {
  current?: DocumentTemplate
  loading: boolean
}

export const adapter = createEntityAdapter<DocumentTemplate>();

export const initialState: State = adapter.getInitialState({
  current: null,
  loading: false,
});
