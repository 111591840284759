<dvtx-comment-reaction-item class="dvtx-comment-reaction" (onReaction)="onReaction.emit($event)" [icon]="'thumb_up'" *ngIf="!reactions?.length"></dvtx-comment-reaction-item>

<div *ngFor="let r of reactions" class="d-flex">
  <ng-container [ngSwitch]="r.reaction">
    <dvtx-comment-reaction-item class="dvtx-comment-reaction dvtx-comment-reaction--blue" (onReaction)="onReaction.emit($event)" [reaction]="r" [icon]="'thumb_up'" *ngSwitchCase="'like'"></dvtx-comment-reaction-item>
    <dvtx-comment-reaction-item class="dvtx-comment-reaction dvtx-comment-reaction--smiley" (onReaction)="onReaction.emit($event)" [reaction]="r" [icon]="'mood_bad'" *ngSwitchCase="'angry'"></dvtx-comment-reaction-item>
    <dvtx-comment-reaction-item class="dvtx-comment-reaction dvtx-comment-reaction--smiley" (onReaction)="onReaction.emit($event)" [reaction]="r" [icon]="'sentiment_dissatisfied'" *ngSwitchCase="'sad'"></dvtx-comment-reaction-item>
    <dvtx-comment-reaction-item class="dvtx-comment-reaction dvtx-comment-reaction--smiley" (onReaction)="onReaction.emit($event)" [reaction]="r" [icon]="'insert_emoticon'" *ngSwitchCase="'laugh'"></dvtx-comment-reaction-item>
    <dvtx-comment-reaction-item class="dvtx-comment-reaction dvtx-comment-reaction--red" (onReaction)="onReaction.emit($event)" [reaction]="r" [icon]="'favorite'" *ngSwitchCase="'heart'"></dvtx-comment-reaction-item>
    <dvtx-comment-reaction-item class="dvtx-comment-reaction dvtx-comment-reaction--smiley" (onReaction)="onReaction.emit($event)" [reaction]="r" [icon]="'sentiment_neutral'" *ngSwitchCase="'surprised'"></dvtx-comment-reaction-item>
  </ng-container>
</div>
