<div class="mat-dialog-inner-wrap" [style.borderColor]="color">
  <div class="mat-dialog-header">
    <h2 mat-dialog-title>{{ 'ADDRESSBOOK.CREATE_CONTACT' | translate }}</h2>
    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <span class="mb-1 d-block">{{'CONTACTS.SET_VISIBILITY_FOR_INFORMATION' | translate}}</span>
    <dvtx-contact-visibility-chooser
      [(ngModel)]="contactVisibility"
    ></dvtx-contact-visibility-chooser>
    <dvtx-create-contact-person-dialog
      [newContactMode]="true"
      [(ngModel)]="createContactForm"
    ></dvtx-create-contact-person-dialog>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-end">
    <button
      mat-button
      (click)="closeDialog()"
    >{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
    <button
      mat-raised-button
      color="primary"
      (click)="createContact()"
      [disabled]="contactType === contactTypes.naturalPerson && !createContactForm.isValid"
    >{{ 'GENERAL.SAVE_ACTION' | translate }}</button>
    <button
      *ngIf="contactType === contactTypes.naturalPerson && !hideInviteButton"
      mat-raised-button
      color="primary"
      (click)="createContact(true)"
      [disabled]="contactType === contactTypes.naturalPerson && !createContactForm.isValid"
    >{{ 'ADDRESSBOOK.SAVE_AND_INVITE_ACTION' | translate }}</button>
  </div>
</div>
