import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './client.state';
import {OrganizationSelectors} from 'app/+store/organization';

export const stateKey = 'client';
const getClientState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getClientEntities,
  selectAll: getAllClients,
} = adapter.getSelectors(getClientState);

export const getClientsOfSelectedOrg = createSelector(
  OrganizationSelectors.getSelectedId,
  getAllClients,
  (orgId, clients) => clients.filter(c => orgId && c.organizationId === orgId)
);

export const loadingState = createSelector(
  getClientState,
  (state) => state.loading
);

export const getClientsOfSelectedOrgMap = createSelector(
  getClientsOfSelectedOrg,
  (clients) => {
    const clientsMap = {};
    clients.forEach(c => {
      clientsMap[c.id] = c;
    });
    return clientsMap;
  }
);

export const getClientById = (id: string) => createSelector(
  getClientsOfSelectedOrgMap,
  (clientMap) => {
    return clientMap[id]
  }
);

export const getClientCount = createSelector(
  getClientsOfSelectedOrg,
  (clients) => clients ? clients.length : 0
);

export const getClientsByIds = (ids: string[]) => createSelector(
  getClientsOfSelectedOrg,
  (clients) => clients.filter(client => ids && !!(ids.find(id => client.id === id)))
);
