import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {QuickshareAttachment} from './quickshare-attachment';
import * as dayjs from 'dayjs';

export interface State extends EntityState<QuickshareAttachment> {
  loading: boolean;
  selected?: QuickshareAttachment;
  transfering: boolean;
}

export const adapter = createEntityAdapter<QuickshareAttachment>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  selected: null,
  transfering: false
});
