import {UntypedFormControl} from '@angular/forms';

export namespace Validator {
  export function passwordTooShort(control: UntypedFormControl) {
    const password = control.value;
    if (!password || password === '') {
      return null;
    }
    if (password.length < 6) {
      return {
        passwordLength: 'PASSWORD.PASSWORD_TOO_SHORT'
      };
    }
    return null;
  }
}
