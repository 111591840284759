import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RichPdfViewerComponent} from './components/rich-pdf-viewer/rich-pdf-viewer.component';
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";

@NgModule({
  declarations: [
    RichPdfViewerComponent,
  ],
  exports: [
    RichPdfViewerComponent,
  ],
  imports: [
    CommonModule,
    NgxExtendedPdfViewerModule,
  ],
})
export class FiveFPdfViewerModule {
}
