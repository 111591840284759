
import {first, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {RoleActions, RoleSelectors} from '../../../+store/role';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {Role} from 'app/models/roles.model';
import {ContactActions, MembershipActions, NaturalPersonActions, NaturalPersonSelectors} from '../../../+store'
import {MembershipSelectors} from '../../../+store'
import {Membership} from 'app/models/membership.model';
import {TranslateService} from '@ngx-translate/core';
import {NaturalPerson} from 'app/models/natural-person.model';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ConfirmationDialogSimpleComponent} from 'app/shared/components/dialogs/confirmation-dialog-simple/confirmation-dialog-simple.component';
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

/**
 * Frontend listing role model.
 */
export interface RoleVM {
  id?: string;
  type: string;
  removeable?: boolean;
  deleteable?: boolean;
  toggleDeleteAction?: boolean;
  roleName: string;
}

/**
 * Material toggles elements for each row.
 * The "owner" role is only visible if current signed in user _is_ owner.
 *
 * Nomenclature:
 * - Owner: Owner of the organization. There must only be one.
 * - Administrator: Privilidged user.
 * - System Administrator (Sysadmin): Less priviliged admin for "system tasks"
 */
@Component({
  selector: 'dvtx-edit-role-toggle',
  templateUrl: './edit-role-toggle.component.html',
  styleUrls: ['./edit-role-toggle.component.scss']
})
export class EditRoleToggleComponent implements OnInit, OnDestroy {

  @ViewChild('roleInput') roleInput: ElementRef;
  onDestroy = new Subject();
  form: UntypedFormGroup;
  selectedRoles: RoleVM[];
  allRoles: RoleVM[];
  filteredRoles: Observable<RoleVM[]>;
  availableRoles: Observable<RoleVM[]>;
  selectedNatPerson: Observable<NaturalPerson>;
  loading: boolean = false;
  membership: Membership;
  myMembership: Membership;
  roleName: string;
  isOwner = false;
  @Input() data: NaturalPerson;

  constructor(private store: Store<any>,
              private fb: UntypedFormBuilder,
              private _notifyService: NotificationService,
              private _translateSvc: TranslateService,
              private _dialog: MatDialog) {
  }

  ngOnInit() {
    this.selectedNatPerson = this.store.select(NaturalPersonSelectors.getById(this.data.id));
    this.store.select(MembershipSelectors.getMembershipOfSelectedOrgByNatPersonId(this.data.id)).subscribe(membership => {
      this.membership = membership;
    });
    // this.loading = this.store.select(RoleSelectors.getLoading)

    this.form = this.fb.group({
      roles: '',
    });

    this.store.select(MembershipSelectors.getMyMembership).pipe(
      takeUntil(this.onDestroy))
      .subscribe(m => {
        if (m) {
          this.myMembership = m;
          this.isOwner = m.isOrganizationalOwner;
        }
      });

    // All roles
    this.store.select(RoleSelectors.getAllRoles).pipe(
      map(roles => roles.map(r => this.mapRoleToRoleVM(r))),
      takeUntil(this.onDestroy)
    ).subscribe(roles => {this.loading = true; this.allRoles = roles});

    // Available Roles
    this.availableRoles = this.store.select(RoleSelectors.getAllRoles).pipe(
      map(roles => roles
        .map(r => this.mapRoleToRoleVM(r))
        .filter(role => {
          return !this.selectedRoles.find(selRole => selRole.roleName === role.roleName);
        }))
    );

    // Currently selected Roles in the membership
    this.store.select(RoleSelectors.getRolesByNatPersonIdForSelectedOrg(this.data.id)).pipe(
      map(roles => (roles) ? (roles.map(r => this.mapRoleToRoleVM(r))) : roles = null), /// on logout error when selected memeberships are returned null
      takeUntil(this.onDestroy),
    ).subscribe((roles) => {
      this.selectedRoles = roles;
    });

    // Filtered Roles, derived from available roles
    this.filteredRoles = this.rolesControl.valueChanges.pipe(
      startWith(undefined),
      switchMap((role?: string | RoleVM) => {
        if (role) {
          let filterValue: string;
          if (typeof role === 'string') {
            filterValue = role.toLowerCase()
          } else {
            filterValue = role.roleName.toLowerCase();
          }
          return this.availableRoles.pipe(
            map(roles => roles.filter(r => r.roleName.toLowerCase().indexOf(filterValue) === 0))
          )
        } else {
          return this.availableRoles
        }
      })
    )
  }

  /**
   * We are doing a manual update of the model to prevent the necessity of a full membership reload
   * on large data sets and that lets the table jumping.
   * @param $event
   * @param role
   * @param _checked
   */
  onChange($event: MatSlideToggleChange, role, _checked) {
    if ($event.checked) {
      this.onAddRoleToUser(role);

      const membership = Object.assign({}, this.membership)
      membership.hasAdministrationRights = true;
      const found = membership.roles.find(roleId => roleId === role.id);
      if (!found) {
        membership.roles.push(role.id);
      }
      membership.isSysadmin = true;
      this.store.dispatch(new MembershipActions.LoadOne(membership));

    } else {
      this.remove(role);

      const membership = Object.assign({}, this.membership)
      membership.hasAdministrationRights = false;
      membership.isSysadmin = false;
      membership.roles = membership.roles.filter(roleId => roleId !== role.id);
      this.store.dispatch(new MembershipActions.LoadOne(membership));
    }
  }

  onChangeOwner(role, event) {
    this.confirmTransferAdmin(this.membership.naturalProfileId, event.source);
  }

  confirmTransferAdmin(id, slideToggle) {
    this._dialog.open(ConfirmationDialogSimpleComponent, {
      data: {
        title: 'AUTH.TRANSFER_OWNER_ROLE_TITLE',
        message: 'AUTH.TRANSFER_OWNER_ROLE_INFO',
        disableClose: true,
        onSubmitAction: () => {
          this.loading = true;
          this.store.dispatch(new RoleActions.ChangeOwner(id));
        },
        onCancelAction: () => {
          slideToggle.checked = false;
        }
      }
    });
  }

  onAddRoleToUser(role) {
    this.store.dispatch(new RoleActions.Add({membershipId: this.membership.id, roleId: role.id}));
  }

  private get rolesControl(): AbstractControl {
    return this.form.get('roles')!
  }


  isSelectedRoles(role): boolean {
    if (this.selectedRoles.map(function (x) {
        return x.id;
      }).indexOf(role.id) >= 0) {
      return true;
    } else {
      return false;
    }
  }

  isEditable(role): boolean {
    if (role.type === 'owner') {
      if (this.myMembership && this.myMembership.isOrganizationalOwner) {
        return this.myMembership && this.membership.id !== this.myMembership.id;
      } else {
        return false;
      }
    }

    if (this.selectedRoles.map(function(x) {return x.id; }).indexOf( role.id) >= 0) {
      return this.selectedRoles[this.selectedRoles.map(function(x) {return x.id; }).indexOf( role.id)].removeable;
    } else {
      return true;
    }
  }

  // Removes the selected Role
  remove(role: RoleVM): void {
    const index = this.selectedRoles.indexOf(role);
    if (index >= 0) {
      this.selectedRoles.splice(index, 1);
    }
    this.store.dispatch(new RoleActions.Remove({membershipId: this.membership.id, roleId: role.id}));
    this._notifyService.success('AUTH.USER_UPDATED')
  }

  /*
   * Maps a CPP role models to a display role model.
   * @param {Role} role
   * @returns {RoleVM}
   */
  mapRoleToRoleVM(role: Role): RoleVM {
    let roleName = role.role_name;
    if (roleName.startsWith('ROLE.')) {
      roleName = this._translateSvc.instant(roleName)
    }
    return {
      id: role.id,
      roleName,
      type: role.current_roletype,
      removeable: role.current_roletype !== 'owner',
      deleteable: !(role.current_roletype === 'owner' || role.current_roletype === 'administrator')
    };
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
  }
}
