<dvtx-settings-header [routes]="routes"
                      [activeLink]="activeLink"
                      [toOverview]="true"
                      [enableSearch]="true"
                      (onSearch)="search($event)"
                      [title]="'ORGANIZATION_NAVIGATON.LABELS_SETTINGS'"></dvtx-settings-header>

<dvtx-labels-listing [labels]="gloablLabels"
                     [onSearch]="searchTerm"
                     [canCreate]="false"
                     [canDelete]="false"
                     [canEdit]="false"
                     [scope]="scope.GLOBAL"></dvtx-labels-listing>
