import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {BomDataNode} from './bom-data-node';
import {CollectorItemBuilder} from '../collector-item/collector-item.builder';
import {CollectorCategoryBuilder} from '../collector-category/collector-category.builder';
import {BomDataNodeType} from './bom-data-node.interface';

/**
 * Generic data node type builder for the v3 Data Node API.
 * Considered for all BOM node driven workflows as Collecto, Quickshare and CAC.
 */
export class BomDataNodeBuilder implements IApiResourceBuilder<BomDataNode> {
  constructor(public processId: string) {
  }

  fromResponse(data): BomDataNode {
    const attrs = data.attributes;
    let node: BomDataNode = null;
    switch (attrs.node_type) {
      case BomDataNodeType.CollectorItemNode:
        node = new CollectorItemBuilder().fromResponse(data);
        node.processId = this.processId;
        break;
      case BomDataNodeType.CollectorCategory:
        node = new CollectorCategoryBuilder(this.processId).fromResponse(data);
        node.processId = this.processId;
        break;
      case 'quickshare_data_node':
      case 'node':
      default:
        return null;
    }
    return node;
  }

  toRequest(_: BomDataNode) {
    return null;
  }
}
