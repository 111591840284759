import {DocumentSignatureActions, DocumentSignatureActionTypes} from './document-signature.actions';
import {adapter, initialState, State} from './document-signature.state';

export function reducer(state = initialState, action: DocumentSignatureActions): State {
  switch (action.type) {
    case DocumentSignatureActionTypes.Create:
      return {
        ...state,
        loading: true
      };
    case DocumentSignatureActionTypes.CreateSuccess:
      return adapter.upsertOne(action.signature, {
        ...state,
        loading: false,
      });
    case DocumentSignatureActionTypes.CreateFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

