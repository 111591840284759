import { Injectable } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { SystemService } from 'app/services/system.service';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LicenceControlService } from 'app/shared/services/licence-control.service';

@Injectable()
export class RoutingService {
  onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _systemSvc: SystemService,
    private licenceControlService: LicenceControlService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        const disableLicenceCheck: boolean = Boolean(this.route.snapshot.queryParams["lc"]);

        const notRegistrationPages = event.urlAfterRedirects.includes('session') === false;
        const notPricesPages = event.urlAfterRedirects.includes('prices') === false;
        const notMessagesPages = event.urlAfterRedirects.includes('messages/invitations') === false;

        if ( !disableLicenceCheck && notRegistrationPages && notPricesPages && notMessagesPages) {
          this.licenceControlService.showEndTrialDialog();
          return;
        }

        const currUrl = event.urlAfterRedirects.split('?');
        if (currUrl[0] !== '/system/maintenance') {
          this._systemSvc.getStatus().pipe(takeUntil(this.onDestroy)).subscribe((status) => {
          }, (error) => {
            if (error.status === 503) {
              this.router.navigate(['system/maintenance'], { queryParams: { returnUrl: currUrl[currUrl.length - 1] } });
            }
          });
        }

        if (event.urlAfterRedirects !== '/integrations/dms/return') {
          localStorage.setItem('route', event.urlAfterRedirects);
        }
      }
    });
  }
}
