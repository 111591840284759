import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {DocumentPreviewPreview} from './document-preview-preview';

export interface State extends EntityState<DocumentPreviewPreview> {
  selected?: DocumentPreviewPreview;
  loading: boolean;
}

export const adapter = createEntityAdapter<DocumentPreviewPreview>();

export const initialState: State = adapter.getInitialState({
  loading: false,
});
