import {
  ThirdPartyConfirmationClaimInvoice,
  ThirdPartyConfirmationClaimInvoiceRow
} from './claimInvoice';
import {RequestType} from './request-type.enum';

export namespace ThirdPartyConfirmationClaimInvoiceBuilder {
  export function fromResponse(response): ThirdPartyConfirmationClaimInvoice {
    const keyToGroup: {[key: string]: any[]} = {};
    const groups: any[][] = [];

    for (const row of response.attributes.rows) {
      const key = [row.debitor_name, row.debitor_nr, row.debitor_ansprechpartner_title, row.debitor_ansprechpartner_name, row.debitor_ansprechpartner_email].join('|');

      if (keyToGroup.hasOwnProperty(key)) {
        keyToGroup[key].push(row);
      } else {
        const newGroup = [row];
        keyToGroup[key] = newGroup;
        groups.push(newGroup);
      }
    }

    const thirdParty = new ThirdPartyConfirmationClaimInvoice(
      response.id,
      response.type,
      response.attributes.parent_process_id,
      response.attributes.referenced_at,
      response.attributes.due_date,
      response.attributes.color,
      response.attributes.language || 'de',
      response.attributes.request_type || RequestType.Invoice,
      groups.map((group) => {
        const firstInGroup = group[0];

        return new ThirdPartyConfirmationClaimInvoiceRow(
          firstInGroup.id,
          firstInGroup.color || response.attributes.color,
          firstInGroup.lfd,
          firstInGroup.referenced_at || response.attributes.referenced_at,
          firstInGroup.due_date || response.attributes.due_date,
          firstInGroup.comment || '',
          firstInGroup.language || response.attributes.language || 'de',
          firstInGroup.request_type || response.attributes.request_type || RequestType.Invoice,
          firstInGroup.debitor_name,
          firstInGroup.debitor_nr,

          group.map((row) => {
            return {
              id: row.id,
              lfd: row.lfd,
              rechnung_nr: row.rechnung_nr,
              rechnung_datum: row.rechnung_datum,
              rechnung_betrag: row.rechnung_betrag,
              waehrung: row.waehrung,
              kontierung: row.kontierung,
              third_party_comment: row.third_party_comment,
              third_party_invoice_confirmed: row.third_party_invoice_confirmed,
              third_party_invoice_manual: row.third_party_invoice_manual
            }
          }),

          firstInGroup.debitor_ansprechpartner_title,
          firstInGroup.debitor_ansprechpartner_name,
          firstInGroup.debitor_ansprechpartner_email,
        )
      })
    );

    thirdParty.emailAttachmentFilename = response.attributes.email_attachment_filename;
    thirdParty.state = response.attributes.state;

    return thirdParty;
  }

  export function toRequest(resource: ThirdPartyConfirmationClaimInvoice) {
    const rows = [];
    console.error('invoice', resource)

    for (const row of resource.rows) {
      rows.push(...row.invoiceRows.map((invoiceRow) => {
        return {
          id: invoiceRow.id,
          color: row.color,
          lfd: invoiceRow.lfd,
          referenced_at: row.referenced_at,
          due_date: row.due_date,
          comment: row.comment,
          language: row.language,
          request_type: row.request_type,
          debitor_name: row.debitor_name,
          debitor_nr: row.debitor_nr,
          rechnung_nr: invoiceRow.rechnung_nr,
          rechnung_datum: invoiceRow.rechnung_datum,
          rechnung_betrag: invoiceRow.rechnung_betrag,
          waehrung: invoiceRow.waehrung,
          kontierung: invoiceRow.kontierung,
          debitor_ansprechpartner_title: row.debitor_ansprechpartner_title,
          debitor_ansprechpartner_name: row.debitor_ansprechpartner_name,
          debitor_ansprechpartner_email: row.debitor_ansprechpartner_email,
        }
      }))
    }

    return {
      id: resource.id,
      type: resource.type,
      attributes: {
        parent_process_id: resource.parent_process_id,
        referenced_at: resource.referenced_at,
        due_date: resource.due_date,
        language: resource.language,
        request_type: resource.request_type,
        rows
      }
    };
  }
}
