import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './label.state';
import {LabelOrigin} from './label.interface';

export const stateKey = 'label';
const getLabelState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getLabelEntities,
  selectAll: getAllLabels,
} = adapter.getSelectors(getLabelState);

export const getSelected = createSelector(
  getLabelState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getLabelEntities,
  (entities) => entities[id]
);

export const getOneByTitle = (title: string) => createSelector(
  getAll,
  (entities) => entities.find(entity => entity.title === title)
);

export const getAll = createSelector(
  getAllLabels,
  (entities) => entities
);

export const getAllExceptContextual = createSelector(
  getAllLabels,
  (entities) => entities.filter(entity => entity.origin === LabelOrigin.NO_ORIGIN)
);

export const loadingState = createSelector(
  getLabelState,
  (state) => state.loading
);
