
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FileCardFileBuilder} from '../builders/file-card-file.builder';
import {FileCardFile} from '../models/file-card-file';
import {HttpClient} from '@angular/common/http';

/**
 * API organization service.
 */
@Injectable()
export class OrganizationService {

  readonly BASE_PATH = 'organizations/';

  constructor(private _http: HttpClient) {}

  getMyOrganizationsFiles(): Observable<FileCardFile[]> {
    return this._http.get('/organization/my/files').pipe(map((response) => {
      const ret = FileCardFileBuilder.fromResponseList(response);
      console.log(ret);
      return ret;
    }));
  }
}
