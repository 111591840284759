import { IContactPhoneNumber, SimplePhoneNumber } from '../models/contact.interface';
import { ContactPhoneNumber } from '../models/contact';

export namespace ContactPhoneNumberBuilder {
  export function fromResponse(response, _catalog = null): IContactPhoneNumber {
    const attrs = response.attributes;
    return new ContactPhoneNumber(response.id, attrs.location_or_type, attrs.country_code, attrs.phone_number,
      attrs.order, attrs.main_phone_number);
  }

  export function toRequest(record: IContactPhoneNumber) {
    return {
      data: {
        id: record.id,
        type: record.type,
        attributes: {
          main_phone_number: record.mainPhoneNumber,
          location_or_type: record.locationOrType,
          country_code: record.countryCode,
          phone_number: record.phoneNumber,
          order: record.order
        }
      }
    };
  }
}

export class SimplePhoneNumberBuilder {
  static fromResponseList(response): SimplePhoneNumber[] {
    const ret: SimplePhoneNumber[] = [];
    for (const item of response.data) {
      ret.push(SimplePhoneNumberBuilder.fromResponse(item));
    }
    return ret;
  }

  static fromResponse(response): SimplePhoneNumber {
    const ret = new SimplePhoneNumber();
    ret.locationOrType = response['attributes']['location_or_type'];
    ret.countryCode = response['attributes']['country_code'];
    ret.phoneNumber = response['attributes']['phone'];
    ret.id = response['id'];
    return ret;
  }

  static toRequest(val: SimplePhoneNumber) {
    return {
      data: {
        location_or_type: val.locationOrType,
        country_code: val.countryCode,
        phone: val.phoneNumber
      }
    }
  }
}
