import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Sizes} from '../../models/avatar';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Subject, BehaviorSubject, Observable, combineLatest} from 'rxjs';
import {UserAvatarActions, UserAvatarSelectors} from 'app/+store/user-avatar';
import {UserAvatar} from 'app/+store/user-avatar/user-avatar';
import {takeUntil, distinctUntilKeyChanged, filter, map, distinctUntilChanged, switchMap} from 'rxjs/operators';
import { AvatarService } from './avatar.service';

@Component({
  selector: 'dvtx-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnDestroy, OnInit {
  private onDestroy = new Subject();

  public avatarSizeNumber: number;
  public avatarSizeString: string;
  public avatarShowEmail: boolean = false;
  private _profile: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public avatarProfile;
  public avatarShowTitle: boolean = false;
  public avatarButtonCallback: any = null;
  public userStatusIsMember = false;
  public userStatusIsVerified = false;
  private _imageLoadAttempt = false;
  private image$: Observable<UserAvatar>;
  public image: string = null;

  @Input() isVerified: boolean = false;
  @Input() isMember: boolean = false;
  @Input() showTick: boolean = true;

  private currentEmail: string = null;

  private refresh$ = new BehaviorSubject(null);
  private email$ = new BehaviorSubject(null);
  private personEmail$:BehaviorSubject<any> = new BehaviorSubject(null);
  private _email: any;

  @Input() set email(account) {
    if (!account) return;
    this.personEmail$.next(account);
    this._email = account;
  };

  @Input() set size(size: any) {
    this.avatarSizeNumber = +Sizes[size];
    this.avatarSizeString = size;
  };

  @Input() set showTitle(showTitle: boolean) {
    this.avatarShowTitle = showTitle;
  };

  @Input() set showEmail(showEmail: boolean) {
    this.avatarShowEmail = showEmail;
  };

  @Input() set callbackFn(callbackFn: any) {
    this.avatarButtonCallback = callbackFn;
  };

  constructor(private _store: Store<AppState>,
              private _cdr: ChangeDetectorRef,
              private avatarSvc: AvatarService,
              private _ngZone: NgZone) {
  }

  ngOnInit() {
    this.refresh$.pipe(
      filter(profile => !!profile),
      takeUntil(this.onDestroy)
    ).subscribe(profile => {
      this.avatarProfile = profile;
      this._cdr.markForCheck();
    });

    this.image$ = this.email$
      .pipe(
        filter(email => !!email),
        distinctUntilChanged(),
        switchMap((email) => {
          return this._store.select(UserAvatarSelectors.getOne(email));
        })
      );

    this.image$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(avatar => {
        if (avatar && avatar.avatar) {
          this.image = avatar.avatar;
        } else {
          this.image = null;
        }
        this._cdr.markForCheck();
      });

      this.personEmail$.pipe(
        filter(profile => !!profile),
        switchMap(email => this.avatarSvc.getProfileForAvatar$(email)),
        takeUntil(this.onDestroy)
      ).subscribe(profile => {
        if (!profile) return;
        if (!profile.fgColor) {
          profile['fgColor'] = '#fff';
        }
        if (!profile.bgColor) {
          profile['bgColor'] = '#0090cf';
        }
        if (profile.email) {
          this._setImage(profile);
        } else if (profile.mainEmailAddress && profile.mainEmailAddress.emailAddress) {
          profile['email'] = profile.mainEmailAddress.emailAddress;
          this._setImage(profile);
        }
        this.refresh$.next(profile);
      });

      combineLatest([this.avatarSvc.organizationMembers$, this.avatarSvc.emailToName$])
      .pipe(distinctUntilChanged(),takeUntil(this.onDestroy))
      .subscribe(([_contacts, _emailToNames]) => {
        this.personEmail$.next(this._email);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this._profile.complete();
    this.refresh$.complete();
    this.email$.complete();
  }

  private _setImage(profile) {
    if (!profile || !profile.email) return;

    const currentEmail = profile.email;

    if (currentEmail !== this.currentEmail) {
      this.currentEmail = currentEmail;
      this.email$.next(currentEmail);

      // Image was already loaded, reload it.
      if (this._imageLoadAttempt) {
        this._ngZone.runOutsideAngular(() => this._store.dispatch(new UserAvatarActions.LoadOne(this.currentEmail)));
      }
    }
  }

  public loadProfileDetails($event) {
    if (!$event || $event && !$event.visible) {
      return;
    }

    // See also the split up of the Loading in the user avatar effect to separate call invocation
    // and the real loading to avoid massive parallel API fetching.
    if (!this._imageLoadAttempt) {
      this._ngZone.runOutsideAngular(() => {
        this._store.dispatch(new UserAvatarActions.LoadOne(this.currentEmail));
        this._imageLoadAttempt = true;
      });
    }
  }
}
