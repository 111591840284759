import {CollectorTemplateActions, CollectorTemplateActionTypes} from './collector-template.actions';
import {adapter, initialState, State} from './collector-template.state';

export function reducer(state = initialState, action: CollectorTemplateActions): State {
  const current = state.selected;
  switch (action.type) {
    case CollectorTemplateActionTypes.CreateTemplate:
    case CollectorTemplateActionTypes.DeleteTemplate:
    case CollectorTemplateActionTypes.LoadAll:
    case CollectorTemplateActionTypes.LoadTemplate:
    case CollectorTemplateActionTypes.DownloadTemplate:
      return {
        ...state,
        loading: true
      };
    case CollectorTemplateActionTypes.CreateTemplateSuccess:
      return adapter.upsertOne(action.template, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case CollectorTemplateActionTypes.LoadAllSuccess:
      return adapter.setAll(action.templates, {
          ...state,
          loading: false,
      });
    case CollectorTemplateActionTypes.LoadTemplateSuccess:
      return adapter.upsertOne(action.template, {
        ...state,
        loading: false,
      });
    case CollectorTemplateActionTypes.DeleteTemplateSuccess:
      return adapter.removeOne(action.template.id, {
        ...state,
        loading: false,
      });
    case CollectorTemplateActionTypes.DownloadTemplateSuccess:
      return {
        ...state,
        loading: false,
      };
    case CollectorTemplateActionTypes.CreateTemplateFail:
    case CollectorTemplateActionTypes.DeleteTemplateFail:
    case CollectorTemplateActionTypes.LoadAllFail:
    case CollectorTemplateActionTypes.LoadTemplateFail:
    case CollectorTemplateActionTypes.DownloadTemplateFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

