import {Action} from '@ngrx/store';
import {Quickshare} from './quickshare';
import {IQuickshareParams} from './quickshare.interface';

export enum QuickshareActionTypes {
  Create = '[Quickshare] Create',
  CreateSuccess = '[Quickshare] Create Success',
  CreateFail = '[Quickshare] Create Fail',
  Publish = '[Quickshare] Publish',
  PublishSuccess = '[Quickshare] Publish Success',
  PublishFail = '[Quickshare] Publish Fail',
  NavigateToDashboard = '[Quickshare] Navigate To Dashboard',
  SaveDraft = '[Quickshare] Save Draft',
  SaveDraftSuccess = '[Quickshare] Save Draft Success',
  SaveDraftFail = '[Quickshare] Save Draft Fail',
  UpdateRecipients = '[Quickshare] Update Recipients',
  UpdateRecipientsSuccess = '[Quickshare] Update Recipients Success',
  UpdateRecipientsFail = '[Quickshare] Update Recipients Fail',
  UpdateWorkers = '[Quickshare] Update Workers',
  UpdateWorkersSuccess = '[Quickshare] Update Workers Success',
  UpdateWorkersFail = '[Quickshare] Update Workers Fail',
  LoadAll = '[Quickshare] Load All',
  LoadAllSuccess = '[Quickshare] Load All Success',
  LoadAllFail = '[Quickshare] Load All Fail',
  LoadOne = '[Quickshare] Load One',
  LoadOneSuccess = '[Quickshare] Load One Success',
  LoadOneFail = '[Quickshare] Load One Fail'
}

export class Create implements Action {
  readonly type = QuickshareActionTypes.Create;

  constructor(public params: IQuickshareParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = QuickshareActionTypes.CreateSuccess;

  constructor(public quickshare: Quickshare) {
  }
}

export class CreateFail implements Action {
  readonly type = QuickshareActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Publish implements Action {
  readonly type = QuickshareActionTypes.Publish;

  constructor(public id: string) {
  }
}

export class PublishSuccess implements Action {
  readonly type = QuickshareActionTypes.PublishSuccess;

  constructor(public quickshare: Quickshare) {
  }
}

export class PublishFail implements Action {
  readonly type = QuickshareActionTypes.PublishFail;

  constructor(public payload: any) {
  }
}

export class NavigateToDashboard implements Action {
  readonly type = QuickshareActionTypes.NavigateToDashboard;

  constructor(public id: string) {
  }
}

export class SaveDraft implements Action {
  readonly type = QuickshareActionTypes.SaveDraft;

  constructor(public id: string, public params: IQuickshareParams) {
  }
}

export class SaveDraftSuccess implements Action {
  readonly type = QuickshareActionTypes.SaveDraftSuccess;

  constructor(public quickshare: Quickshare) {
  }
}

export class SaveDraftFail implements Action {
  readonly type = QuickshareActionTypes.SaveDraftFail;

  constructor(public payload: any) {
  }
}

export class UpdateRecipients implements Action {
  readonly type = QuickshareActionTypes.UpdateRecipients;

  constructor(public id: string, public recipients: string[]) {
  }
}

export class UpdateRecipientsSuccess implements Action {
  readonly type = QuickshareActionTypes.UpdateRecipientsSuccess;

  constructor(public quickshare: Quickshare) {
  }
}

export class UpdateRecipientsFail implements Action {
  readonly type = QuickshareActionTypes.UpdateRecipientsFail;

  constructor(public payload: any) {
  }
}

export class UpdateWorkers implements Action {
  readonly type = QuickshareActionTypes.UpdateWorkers;

  constructor(public id: string, public workers: string[]) {
  }
}

export class UpdateWorkersSuccess implements Action {
  readonly type = QuickshareActionTypes.UpdateWorkersSuccess;

  constructor(public quickshare: Quickshare) {
  }
}

export class UpdateWorkersFail implements Action {
  readonly type = QuickshareActionTypes.UpdateWorkersFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = QuickshareActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = QuickshareActionTypes.LoadAllSuccess;

  constructor(public quickshares: Quickshare[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = QuickshareActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = QuickshareActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = QuickshareActionTypes.LoadOneSuccess;

  constructor(public quickshare: Quickshare) {
  }
}

export class LoadOneFail implements Action {
  readonly type = QuickshareActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type QuickshareActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Publish
  | PublishSuccess
  | PublishFail
  | NavigateToDashboard
  | SaveDraft
  | SaveDraftSuccess
  | SaveDraftFail
  | UpdateRecipients
  | UpdateRecipientsSuccess
  | UpdateRecipientsFail
  | UpdateWorkers
  | UpdateWorkersSuccess
  | UpdateWorkersFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
