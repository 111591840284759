import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'dvtx-message-bell',
  templateUrl: './message-bell.component.html',
  styleUrls: ['./message-bell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBellComponent {
  @Input() unreadCount = null;
}

