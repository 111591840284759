import {Component, Inject, Input} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {first, switchMap, map, take, takeUntil} from 'rxjs/operators';
import {RxjsBaseComponent} from 'app/shared/base-classes/rxjs-base-component';
import {ContactOrganization} from 'app/models/contact-organization.model';
import {ContactOrganizationBaseForm, contactOrganizationBaseFormFromContact, newOrganizationBaseForm} from '../../../../../organization/models/contact-organization-base-form';
import {ContactListDto, contactListDtoType} from 'app/models/contact-list-dto.model';
import {Observable} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {ContactOrganizationActions, ContactOrganizationSelectors, ContactPersonOrganizationAssociationActions, ContactPersonOrganizationAssociationSelectors, ContactSelectors, MembershipSelectors, NaturalPersonSelectors} from 'app/+store';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {HttpClient} from '@angular/common/http';
import {CppApiService} from 'app/services/cpp-api.service';
import {InviteService} from '../../../../../organization/components/organization-view/invite.service';
import {LoadOfOrganization} from 'app/+store/invitation/invitation.actions';
import {getOpenInvitationsCountByEmail} from 'app/+store/invitation/invitation.selectors';
import {getSelected} from 'app/+store/organization/organization.selectors';
import { AvatarService } from 'app/shared/modules/user-account/components/avatar/avatar.service';

@Component({
  selector: 'dvtx-edit-contact-organization-dialog',
  templateUrl: './edit-contact-organization-dialog.component.html',
  styleUrls: ['./edit-contact-organization-dialog.component.scss']
})
export class EditContactOrganizationDialogComponent extends RxjsBaseComponent {
  editmode: boolean = true;
  orgaContact?: ContactOrganization = undefined;
  // hasAccount: boolean = false;
  orgaContactForm: ContactOrganizationBaseForm = newOrganizationBaseForm();

  description: string = 'ORGANIZATION.MASTER_DATA_DESCRIPTION';

  orgaContactEmployees: Observable<ContactListDto[]>;
  orgaContactEmployeesIds: Observable<string[]>;

  filterType = contactListDtoType;

  contactAssignment?: ContactListDto;
  parentCompanyAssignment?: ContactListDto;
  // This is needed to check if a contact was assigned. If so, the contact list is refreshed, to determine
  // if the contact is now a contact, "that has an account" for com.pass
  contactWasAssigned: boolean = false;

  orgaTypeId: string = '';
  currentPrimaryContact: Observable<ContactListDto>
  currentParentOrg: Observable<ContactListDto>

  primaryContactControl = new UntypedFormControl();
  filteredPrimaryContactOptions: Observable<ContactListDto[]>;
  currentUser: any;
  administrationRights: any;
  @Input() hasAccount: boolean = false;
  @Input() contactId;
  @Input() createdBy;
  constructor(
    private store: Store<any>,
    private notiSer: NotificationService,
    private httpClient: HttpClient,
    private cppApiService: CppApiService,
    private inviteService: InviteService,
    private avatarService: AvatarService
  ) {
    super();
    this.currentUser = this.store.select('currentUser');
    this.administrationRights = this.store.select(MembershipSelectors.getMyMembership);
    // this.hasAccount = this.data.hasAccount;

    // <editor-fold desc="Load organization data">
    this.store.dispatch(new ContactOrganizationActions.LoadOne(this.contactId));
    this.store.select(ContactOrganizationSelectors.getOrganizationContactById(this.contactId)).pipe(
      takeUntil(this.onDestroy)
    ).subscribe((ps: ContactOrganization) => {
      if (ps) {
        this.orgaContact = ps;
        this.currentParentOrg = this.store.select(ContactSelectors.getById(ps.parentId));
        this.currentPrimaryContact = this.store.select(ContactPersonOrganizationAssociationSelectors.getContactEmployeesByPassoId(ps.primaryContactId));
        this.orgaContactEmployees = this.store.select(ContactPersonOrganizationAssociationSelectors.getContactEmployeesOfContactOrganization(ps.id));
        this.orgaContactEmployeesIds = this.store.select(ContactPersonOrganizationAssociationSelectors.getContactEmployeesOfContactOrganization(ps.id)).pipe(
          map((x: ContactListDto[]) => {
            return x.map(y => y && y.id)
          }));
        this.orgaContactForm = contactOrganizationBaseFormFromContact(ps);
        this.orgaTypeId = ps.organization_type_id;
        // <editor-fold desc="initialize ">
        this.store.dispatch(new ContactPersonOrganizationAssociationActions.LoadContactPersonOrganizationAssociations(this.orgaContact.id));
        // </editor-fold>
      }
    });
    // </editor-fold>

    // <editor-fold desc="Reload contacts after closing the dialog if member assignments changed">
    // This is needed, so that the hasAccount property of an organization is properly updated.
    // this.dialogRef.afterClosed().pipe(
    //   first()
    // ).subscribe(() => {
    //   this.store.dispatch(new ContactOrganizationActions.LoadOne(this.contactId));
    // });
    // removed and added when closing sidebar
    // </editor-fold>
  }

  saveData() {
    this.orgaContact = {
      ...this.orgaContact,
      ...this.orgaContactForm.contactOrganization,
      organization_type_id: this.orgaTypeId,
    };
    this.store.dispatch(new ContactOrganizationActions.EditContact(this.orgaContact));
    // this.editmode = false;
  }

  tabClick(event) {
    switch (event.index) {
      case 0: {
        this.description = 'ORGANIZATION.MASTER_DATA_DESCRIPTION'
        break;
      }
      case 1: {
        this.description = 'ORGANIZATION.ADDITIONAL_DATA_DESCRIPTION'
        break;
      }
      case 2: {
        this.description = 'ORGANIZATION.ASSOCIATED_DATA_DESCRIPTION'
        break;
      }
      case 3: {
        this.description = 'ORGANIZATION.FURTHER_DATA_DESCRIPTION'
        break;
      }
      default: {
        // statements;
        break;
      }
    }

  }

  cancelEdit() {
    this.editmode = false;
  }

  assignContact() {
    this.store.dispatch(
      new ContactPersonOrganizationAssociationActions.AssignContactPerson(this.orgaContact.id, this.contactAssignment.id)
    );
    this.contactWasAssigned = true;
    this.contactAssignment = undefined;
  }

  openEmployeePanel() {
    this.store.dispatch(new ContactPersonOrganizationAssociationActions.LoadContactPersonOrganizationAssociations(this.orgaContact.id));
  }

  // Used to display the text in the input field.
  displayFn(contact: ContactListDto): string {
    return contact ? contact.name : '';
  }

  saveParentOrganization() {
    this.store.dispatch(new ContactOrganizationActions.SetParentOrganization({
      orgContactId: this.orgaContact.id,
      parentOrgId: this.parentCompanyAssignment.id
    }))
    this.parentCompanyAssignment = undefined
  }

  removeParentOrganization(parentOrgId: string) {
    this.store.dispatch(new ContactOrganizationActions.RemoveParentOrganization({
      orgContactId: this.orgaContact.id,
      parentOrgId
    }))
  }

  removeContactAssignment(memberId: string) {
    if (!memberId) {
      return;
    }
    this.store.select(ContactPersonOrganizationAssociationSelectors.getContactRelationship(this.orgaContact.id, memberId)).pipe(first()).subscribe((rel) => {
      this.store.dispatch(new ContactPersonOrganizationAssociationActions.UnassignContactPerson(rel));
    });
  }

  inviteBusinessPartner(email: string) {
    if (!email) {
      return;
    }
    this.inviteService.inviteBusinessPartner(email).pipe(
      switchMap(() => this.store.select(getSelected).pipe(take(1)))
    ).subscribe(org => {
      this.store.dispatch(new LoadOfOrganization(org.id))
    })
  }

  hasPendingInvitations(email: string): Observable<boolean> {
    if (!email) {
      return;
    }
    return this.store.select(getOpenInvitationsCountByEmail(email)).pipe(
      map(x => !!x)
    )
  }
}
