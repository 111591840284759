import { Component, Input } from '@angular/core';

@Component({
  selector: 'dvtx-no-element-found',
  templateUrl: './no-element-found.component.html',
  styleUrls: ['./no-element-found.component.scss']
})
export class NoElementFoundComponent {
  @Input() smallIcon = false;
}
