import {QuickshareAttachmentActions, QuickshareAttachmentActionTypes} from './quickshare-attachment.actions';
import {adapter, initialState, State} from './quickshare-attachment.state';

export function reducer(state = initialState, action: QuickshareAttachmentActions): State {
  const current = state.selected;
  switch (action.type) {
    case QuickshareAttachmentActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case QuickshareAttachmentActionTypes.PickFileFromDms:
      return {
        ...state,
        transfering: true,
        loading: true
      };
    case QuickshareAttachmentActionTypes.LoadAllSuccess:
      return adapter.setAll(action.attachments, {
          ...state,
          selected: current ? state.entities[current.id] : null,
          loading: false,
      });
    case QuickshareAttachmentActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.attachment, {
        ...state,
        selected: state.entities[action.attachment.id],
        loading: false,
      });
    case QuickshareAttachmentActionTypes.PickFileFromDmsSuccess:
      return adapter.upsertOne(action.attachment, {
        ...state,
        transfering: false,
        loading: false,
      });
    case QuickshareAttachmentActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
    case QuickshareAttachmentActionTypes.Remove:
      return {
        ...state,
        loading: true
      };
    case QuickshareAttachmentActionTypes.RemoveSuccess:
      return adapter.removeOne(action.attachment.id, {
        ...state,
        selected: current && current.id === action.attachment.id ? null : current,
        loading: false,
      });
    case QuickshareAttachmentActionTypes.RemoveFail:
    case QuickshareAttachmentActionTypes.PickFileFromDmsFail:
      return {
        ...state,
        transfering: false,
        loading: false,
      };
  }
  return state;
}

