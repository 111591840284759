import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class CollectorUiChangesService implements OnDestroy {

  showImportPanel$ = new BehaviorSubject(false);
  showSearchPanel$ = new BehaviorSubject(false);

  constructor() {
  }

  ngOnDestroy() {
    this.showImportPanel$.complete();
    this.showSearchPanel$.complete();
  }

  toggleImportPanel() {
    const current = this.showImportPanel$.value;
    this.showImportPanel$.next(!current);
  }

  showImportPanel() {
    this.showImportPanel$.next(true);
  }

  hideImportPanel() {
    this.showImportPanel$.next(false);
  }

  toggleSearchPanel() {
    const current = this.showSearchPanel$.value;
    this.showSearchPanel$.next(!current);
  }

  showSearchPanel() {
    this.showSearchPanel$.next(true);
  }

  hideSearchPanel() {
    this.showSearchPanel$.next(false);
  }
}
