import {Injectable} from '@angular/core';
import {ProcessActionService} from 'app/+store/process-action/process-action.service';

@Injectable()
export class ApiCommandService {
  constructor(private _processActionSvc: ProcessActionService) {}

  // TODO: Create a command factory
  execute(command) {
    if (command.type === 'close_process') {
      return this._processActionSvc.close(command);
    }
    throw new TypeError('Unknown command');
  }
}
