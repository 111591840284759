import {InvitationStatus} from './invitation.model'
import {InvitationActions, InvitationsActionTypes} from './invitation.actions'
import {adapter, initialState, State} from './invitation.state';

export function reducer(state = initialState, action: InvitationActions): State {
  switch (action.type) {
    case InvitationsActionTypes.LoadMy:
      return {
        ...state,
        loading: true
      };
    case InvitationsActionTypes.LoadFail:
      return {
        ...state,
        loading: false,
      };
    case InvitationsActionTypes.LoadSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case InvitationsActionTypes.AcceptSuccess:
      return adapter.updateOne({id: action.id, changes: {current_status: InvitationStatus.Accepted}}, state);
    case InvitationsActionTypes.DeclineSuccess:
      return adapter.updateOne({id: action.id, changes: {current_status: InvitationStatus.Declined}}, state);
    case InvitationsActionTypes.Destroy:
    case InvitationsActionTypes.DestroySuccess:
      return adapter.removeOne(action.id, {
        ...state,
        loading: false,
      });

  }
  return state
}
