import {Action} from '@ngrx/store';
import * as model from './organization';

export enum TenantOrganizationActionTypes {
  Save = '[Tenant.Organization] Save',
  SaveSuccess = '[Tenant.Organization] Save Success',
  SaveFail = '[Tenant.Organization] Save Fail',
  Status = '[Tenant.Organization] Status',
  LoadAll = '[Tenant.Organization] Load All',
  LoadAllSuccess = '[Tenant.Organization] Load All Success',
  LoadAllFail = '[Tenant.Organization] Load All Fail',
  LoadOne = '[Tenant.Organization] Load One',
  LoadOneSuccess = '[Tenant.Organization] Load One Success',
  LoadOneFail = '[Tenant.Organization] Load One Fail'
}

export class Save implements Action {
  readonly type = TenantOrganizationActionTypes.Save;

  constructor(public tenantId: string, public organization: model.Tenant.Organization) {
  }
}

export class SaveSuccess implements Action {
  readonly type = TenantOrganizationActionTypes.SaveSuccess;

  constructor(public organization: model.Tenant.Organization) {
  }
}

export class SaveFail implements Action {
  readonly type = TenantOrganizationActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = TenantOrganizationActionTypes.LoadAll;

  constructor(public tenantId: string = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = TenantOrganizationActionTypes.LoadAllSuccess;

  constructor(public organizations: model.Tenant.Organization[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = TenantOrganizationActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = TenantOrganizationActionTypes.LoadOne;

  constructor(public tenantId: string, public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = TenantOrganizationActionTypes.LoadOneSuccess;

  constructor(public organization: model.Tenant.Organization) {
  }
}

export class LoadOneFail implements Action {
  readonly type = TenantOrganizationActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type TenantOrganizationActions =
  | Save
  | SaveSuccess
  | SaveFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
