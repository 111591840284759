import {ProcessTreeActions, ProcessTreeActionTypes} from './process-tree.actions';
import {adapter, initialState, State} from './process-tree.state';

export function reducer(state = initialState, action: ProcessTreeActions): State {
  switch (action.type) {
    case ProcessTreeActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case ProcessTreeActionTypes.LoadAllSuccess:
      return adapter.setAll(action.nodes, {
        ...state,
        loading: false,
      });
    case ProcessTreeActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };
    case ProcessTreeActionTypes.Reset:
      return Object.assign({}, initialState);
  }
  return state;
}

