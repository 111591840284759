import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'dvtx-emoji-menu',
  templateUrl: './emoji-menu.component.html',
  styleUrls: ['./emoji-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmojiMenuComponent {
  showDropdown = false;

  @Output() onSelection: EventEmitter<any> = new EventEmitter<any>(null);

  constructor() {
  }

  insertEmoji($event) {
    this.onSelection.emit($event);
  }
}
