<mat-card>
  <mat-card-content class="p-0" *ngIf="success">
    <h2 class="font-weight-light mb-3" [innerHTML]="'LICENCES.RENEW_BASIC_ACCOUNT_DESCRIPTION' | translate"></h2>

    <div class="d-flex mb-3 justify-content-center">
      <div style="width: 40px; margin-right: 15px;">
        <span class="dvtx-inline-valign dvtx-fg-color dvtx-fg-fivef-blue-450">
          <mat-icon style="font-size: 2.4rem">check_circle</mat-icon>
        </span>
      </div>
      <div>
        <h2 class="dvtx-fg-color dvtx-fg-fivef-blue-450 mb-2">{{ 'LICENCES.YOUR_REQUEST_WAS_SENT' | translate }}</h2>
        <p class="dvtx-fg-color dvtx-fg-fivef-blue-450">{{ 'LICENCES.THANK_YOU_FOR_YOUR_INTEREST' | translate }}</p>
      </div>
    </div>
    <p class="mb-3"><strong><span class="dvtx-fg-color dvtx-fg-color-fivef-blue-450">{{ 'LICENCES.CUSTOMER_SUPPORT' | translate }}:</span> +49 941 461 617 01&nbsp;&nbsp;&bull;&nbsp;&nbsp;<a href="mailto:info@5fsoftware.de">info@5fsoftware.de</a></strong></p>
  </mat-card-content>

  <mat-card-content class="p-0" *ngIf="!success">
    <form novalidate [formGroup]="form">
      <div mat-dialog-content class="p-0">
        <h2 class="font-weight-light mb-3" [innerHTML]="'LICENCES.RENEW_BASIC_ACCOUNT_DESCRIPTION' | translate"></h2>

        <mat-form-field>
          <input matInput type="text" formControlName="inviterName" [placeholder]="'LICENCES.BASIC_ACCOUNT_INVITEE_NAME' | translate">
        </mat-form-field>

        <mat-form-field>
          <textarea matInput matTextareaAutosize formControlName="message" [rows]='4' [placeholder]="'LICENCES.HOW_TO_CONTACT_YOU' | translate"></textarea>
        </mat-form-field>
      </div>
      <p><strong><span class="dvtx-fg-color dvtx-fg-color-fivef-blue-450">{{ 'LICENCES.CUSTOMER_SUPPORT' | translate }}:</span> +49 941 461 617 01&nbsp;&nbsp;&bull;&nbsp;&nbsp;<a href="mailto:info@5fsoftware.de">info@5fsoftware.de</a></strong></p>
    </form>
  </mat-card-content>

  <mat-card-actions>
    <div class="d-flex justify-content-end">
      <ng-template [ngIf]="!success" [ngIfElse]="successCloseButton">
        <button mat-button mat-dialog-close>{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
        <button mat-raised-button color="primary"
                [disabled]="form.pristine || form.invalid || sendOngoing"
                (click)="submit()">{{ 'LICENCES.RENEW_BASIC_ACCOUNT_BUTTON' | translate }}</button>
      </ng-template>

      <ng-template #successCloseButton>
        <button mat-button (click)="reload()">{{ 'GENERAL.CLOSE_ACTION' | translate }}</button>
      </ng-template>
    </div>
  </mat-card-actions>
</mat-card>
