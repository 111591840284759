import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import {EnvService} from 'app/shared/modules/api-resource/services/env.service';

/**
 * Customer API for the products sold in the checkout wizard.
 */
@Injectable()
export class SystemService {
  readonly apiPath = 'system';
  basePath;
  constructor(private _tokenService: HttpClient, private env: EnvService) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.apiPath}`;
  }

  getStatus(): Observable<Object> {
    return this._tokenService.get(this.basePath + '/status');
  }

  getTenantName(domain: string) {
    const apiUrl = this.env.apiBase();
    const payload = {
      data: {
        attributes: {
          domain: domain
        }
      }
    };
    return this._tokenService.post(`${apiUrl}/api/v1/tenant/public_settings/domain_details`, payload);
  }
}
