import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Client} from './client';

export interface State extends EntityState<Client> {
  selected?: Client;
  loading: boolean;
}

export const adapter = createEntityAdapter<Client>({
  sortComparer: (l, r) => l.name.localeCompare(r.name)
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
