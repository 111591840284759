import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelsComponent } from 'app/five-f/labels/components/labels/labels.component';
import { DropdownContentModule } from 'app/five-f/dropdown-content/dropdown-content.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TooltipModule } from 'app/five-f/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { LabelComponent } from "./components/label/label.component";
import { LabelsSelectorComponent } from './components/labels-selector/labels-selector.component';
import { BaseFormElementsModule } from 'app/shared/modules/base-form-elements/base-form-elements.module';
import { ColorPickerDropdownModule } from 'app/five-f/color-picker-dropdown/color-picker-dropdown.module';
import { CreateLabelComponent } from './components/create-label-form/create-label-form.component';
import { EditLabelComponent } from './components/edit-label-form/edit-label-form.component';
import { IconPickerDropdownModule } from 'app/five-f/icon-picker-dropdown/icon-picker-dropdown.module';
import { LabelsFilterComponent } from './components/labels-filter/labels-filter.component';
import { CreateLabelTabsFormComponent } from './components/create-label-tabs-form/create-label-tabs-form.component';
import { ShowLabelsComponent } from './components/show-labels/show-labels.component';

@NgModule({
  imports: [
    CommonModule,
    DropdownContentModule,
    TranslateModule,
    TooltipModule,
    MatIconModule,
    BaseFormElementsModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    ColorPickerDropdownModule,
    IconPickerDropdownModule
  ],
  declarations: [
    LabelsComponent,
    LabelComponent,
    LabelsSelectorComponent,
    CreateLabelComponent,
    EditLabelComponent,
    LabelsFilterComponent,
    CreateLabelTabsFormComponent,
    ShowLabelsComponent
  ],
  exports: [
    LabelsComponent,
    LabelComponent,
    LabelsSelectorComponent,
    CreateLabelComponent,
    EditLabelComponent,
    LabelsFilterComponent,
    ShowLabelsComponent
  ]
})
export class LabelsModule {
}
