import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';

@Component({
  selector: 'dvtx-status-selector',
  templateUrl: './status-selector.component.html',
  styleUrls: ['./status-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusSelectorComponent implements OnInit {

  showDropdown = false;
  _statuses;
  _selected;

  @Input() set statuses(statuses: any[]) {
    if (statuses) {
      this._statuses = statuses;
    }
  }
  get statuses() {
    return this._statuses;
  }

  @Input() set selected(selected) {
    if (selected) {
      this._selected = selected;
      this._cdr.detectChanges();
    }
  }
  get selected() {
    return this._selected;
  }

  @Input() buttonDisabled = false;
  @Input() iconOnly = false;
  @Input() disabled = false;
  @Input() isButton = true;
  @Input() multipeSelection = false;

  @Output() onSelection: EventEmitter<any> = new EventEmitter<any>(null);

  constructor(public _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this._cdr.detectChanges();
  }

  selectStatus($event) {
    this.onSelection.emit($event);
    this.showDropdown = false;
  }

  isStatusSelected(status) {
    if (!this.selected) {
      return false;
    }
    if (!this.multipeSelection && (this.selected === status.id || this.selected.id === status.id)) {
      return true;
    }
    if (this.multipeSelection && this.selected.find(selectedStatus => (selectedStatus.id === status.id || selectedStatus === status.id))) {
      return true;
    }
    return false;
  }

  showDropdownDialog() {
    if (this.disabled || this.buttonDisabled) return;
    this.showDropdown = !this.showDropdown;
  }
}
