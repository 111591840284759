import { Action } from '@ngrx/store';

export const AUTO_SAVING = 'AUTO_SAVING';
export const AUTO_SAVED = 'AUTO_SAVED';

export class AutoSaving implements Action {
  readonly type: string = AUTO_SAVING;

  constructor(public payload: any = null) { }
}

export class AutoSaved implements Action {
  readonly type: string = AUTO_SAVED;

  constructor(public payload: any = null) { }
}


export type Actions = AutoSaving
  | AutoSaved;
