import {Component, Inject, OnInit, Input} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dvtx-licence-upgrade-dialog',
  templateUrl: './licence-upgrade-dialog.component.html',
  styleUrls: ['./licence-upgrade-dialog.component.scss']
})
export class LicenceUpgradeDialogComponent {
  requiredLicence;
  submitButtonTitle = 'GENERAL.CONFIRM_ACTION';
  secondarySubmitButtonTitle = 'GENERAL.CONFIRM_ACTION';
  cancelButtonTitle = 'GENERAL.CANCEL_ACTION';

  constructor(
    public dialogRef: MatDialogRef<LicenceUpgradeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LicenceUpgradeDialogData
  ) {
    if (this.data.submitButtonTitle) {
      this.submitButtonTitle = this.data.submitButtonTitle;
    }

    if (this.data.secondarySubmitButtonTitle) {
      this.secondarySubmitButtonTitle = this.data.secondarySubmitButtonTitle;
    }

    if (this.data.cancelButtonTitle) {
      this.cancelButtonTitle = this.data.cancelButtonTitle;
    }
  }

  onSubmitClick(): void {
    this.data.onSubmitAction();
    this.dialogRef.close();
  }

  onSecondarySubmitClick(): void {
    this.data.onSecondarySubmitAction();
    this.dialogRef.close();
  }

  onNoClick(): void {
    if (this.data.onCancelAction) {
      this.data.onCancelAction();
    }
    this.dialogRef.close();
  }
}

export interface LicenceUpgradeDialogData {
  title?: string;
  message?: string;
  requiredLicence?: string,
  onSubmitAction?: () => void;
  onSecondarySubmitAction?: () => void;
  onCancelAction?: () => void;
  submitButtonTitle?: string;
  secondarySubmitButtonTitle?: string;
  cancelButtonTitle?: string;
}
