<!-- Global Websocket Service -->
<dvtx-actioncable></dvtx-actioncable>

<!-- Two Factor Dialog -->
<dvtx-two-factor-verification-dialog #dvtxTwoFactorVerification></dvtx-two-factor-verification-dialog>

<!-- Page header -->
<dvtx-page-header #mainHeader [error]="error"></dvtx-page-header>

<!-- Quick access workflows (blue buttons) -->
<div class="quickstart"
    *ngIf="!error && showQuickstarter && !loading && (currentOrganization$ | async) && (featureSet$ | async)?.canCreateWorkflows">
    <div class="quickstart-list">
        <dvtx-quickstart-bar class="quickstart-list-holder"></dvtx-quickstart-bar>
    </div>
</div>

<!-- Main content container -->
<mat-sidenav-container [hasBackdrop]="false" autosize class="dvtx-app" style="display: flex;">

    <!-- Main left sidebar -->
    <mat-sidenav (mouseleave)="pageSideBarComponent?.showIfCollapsed(false);" #sidebar
        class="dvtx-sidebar-nav-container dvtx-app-sidebar mat-elevation-z4" style="overflow: visible">
        <div id="first-sidebar-container" class="first-sidebar-container"
            (mouseenter)="pageSideBarComponent?.showIfCollapsed(true)">
            <div class="customLeftSiedBar">
                <div class="hleft-sidebar">
                    <div class="dropdown-sidebar">
                        <dvtx-sidebar-navigation [sidebarNavigationClassStyle]="'dvtx-sidebar-navigation-left'"
                            [classList]="'noWidth'" [showOnlyIcons]="!sidebarCollapsed" [routes]="sidebarMainRoutes?.concat(sidebarOtherRoutes)">
                        </dvtx-sidebar-navigation>
                    </div>
                </div>
                <div class="hright-sidebar">
                    <div class="dropdown-sidebar">
                        <dvtx-sidebar-navigation [sidebarNavigationClassStyle]="'dvtx-sidebar-navigation-left'"
                            [classList]="'noWidth'" [showOnlyIcons]="!sidebarCollapsed"
                            [routes]="sidebarSettingsRoutes">
                        </dvtx-sidebar-navigation>
                    </div>
                </div>
            </div>
        </div>

        <dvtx-page-sidebar style="flex: auto;" #pageSideBarComponent (openSidebar)="openSidebar($event)"
            (onShowIconChange)="setSidebarCollapsedState($event)"></dvtx-page-sidebar>

        <dvtx-sidebar-toggle *ngIf="_authTokenService?.userSignedIn()" [icon]="checkSidebarToggleIcon()"
            (onToggle)="pageSideBarComponent?.collapseSidebarApp($event, sidebar)"
            class="dvtx-app-sidebar-toggle dvtx-app-sidebar--show-collapse-btn">
        </dvtx-sidebar-toggle>
    </mat-sidenav>
    <!-- Main content -->
    <mat-sidenav-content style="margin-left: 0px; flex:auto" class="container-fluid dvtx-app-content dvtx-bg-color"
        id="dvtx-app-content">
        <div *ngIf="loading" class="loader">
            <svg class="spinner">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10">
                </circle>
            </svg>
        </div>
        <div [hidden]="loading" class="dvtx-app-content__router-outlet">
            <div class="dvtx-sidebar-resizer-feature dvtx-sidebar-resizer"></div>
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>