import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserAvatarBuilder} from './user-avatar.builder';
import {UserAvatar} from './user-avatar';
import {UpmsApiResourceService} from './upms-api-resource.service';

@Injectable()
export class UserAvatarService {
  readonly getBasePath = 'api/v1/users/profiles';
  readonly putBasePath = 'api/v1/users/profile';
  builder: UserAvatarBuilder;

  constructor(private _http: UpmsApiResourceService) {
    this.builder = new UserAvatarBuilder();
  }

  getOne(email: string): Observable<UserAvatar[]> {
    const emailBase64 = encodeURIComponent(email);
    return <Observable<UserAvatar[]>>this._http.get<UserAvatarBuilder, UserAvatar>(this.builder, `${this.getBasePath}?uids=${emailBase64}`);
  }

  getMany(ids: string[]): Observable<UserAvatar[]> {
    return <Observable<UserAvatar[]>>this._http.get<UserAvatarBuilder, UserAvatar>(this.builder, `${this.getBasePath}?uids=${ids.join(',')}`);
  }

  update(userAvatar: UserAvatar): Observable<UserAvatar> {
    const payload = this.builder.toRequest(userAvatar);
    return this._http.put<UserAvatarBuilder, UserAvatar>(this.builder, `${this.putBasePath}`, payload);
  }

}
