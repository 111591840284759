import {adapter, initialState, State} from './kanban-board.state';
import {KanbanBoardActions, KanbanBoardActionTypes} from './kanban-board.actions';

export function reducer(state = initialState, action: KanbanBoardActions): State {
  const selectedKanban = state.selected;

  switch (action.type) {
    case KanbanBoardActionTypes.LoadAll:
    case KanbanBoardActionTypes.LoadOne:
      return {
        ...state,
        selected: null,
        loading: true
      };

    case KanbanBoardActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.kanbanBoards, {
        ...state,
        selected: selectedKanban,
        loading: false
      });

    case KanbanBoardActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case KanbanBoardActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.kanbanBoard, {
        ...state,
        selected: action.kanbanBoard.title,
        loading: false
      });

    case KanbanBoardActionTypes.LoadOneFail:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case KanbanBoardActionTypes.Create:
    case KanbanBoardActionTypes.Edit:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case KanbanBoardActionTypes.CreateSuccess:
      return adapter.upsertOne(action.kanbanBoard, {
        ...state,
        selected: action.kanbanBoard.title
      });

    case KanbanBoardActionTypes.EditSuccess:
      return adapter.upsertOne(action.kanbanBoard, {
        ...state
      });

    case KanbanBoardActionTypes.EditFail:
      return {
        ...state
      };

    case KanbanBoardActionTypes.Remove:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case KanbanBoardActionTypes.RemoveSuccess:
      return adapter.removeOne(action.id, {
        ...state
      });

    case KanbanBoardActionTypes.CreateFail:
      return {
        ...state
      };

    case KanbanBoardActionTypes.Select:
      return {
        ...state,
        loading: true
      };

    case KanbanBoardActionTypes.SelectSuccess:
      return {
        ...state,
        loading: false,
        selected: action.board
      };

    case KanbanBoardActionTypes.Reset:
      return adapter.removeAll({
        ...state
      });
  }
  return state;
}
