import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { ProcessTasksStructureComponent } from './containers/process-tasks-navigation/process-tasks-navigation.component';
import { ProcessTreeSingleSelectComponent } from './components/process-tree-single-select/process-tree-single-select.component';
import { MatTreeModule } from '@angular/material/tree';
import { ProcessTreeNodeComponent } from './components/process-tree-node/process-tree-node.component';

@NgModule({
  imports: [
    SharedModule,
    MatTreeModule
  ],
  declarations: [
    ProcessTasksStructureComponent,
    ProcessTreeSingleSelectComponent,
    ProcessTreeNodeComponent
  ],
  exports: [
    ProcessTasksStructureComponent,
    ProcessTreeSingleSelectComponent
  ]
})
export class ProcessTasksNavigationModule {
}
