import {of as observableOf} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, concatMap, first, map} from 'rxjs/operators';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {LoadAll, LoadAllFail, LoadAllSuccess, TasksStatsActionTypes} from './tasks-stats.actions';
import {TasksStatsService} from './tasks-stats.service';
import {TasksStats} from './tasks-stats';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';

@Injectable()
export class TasksStatsEffects {
  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(TasksStatsActionTypes.LoadAll),
    concatMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        map((res: TasksStats) => {
            return new LoadAllSuccess(res);
          }
        ),
        catchError(err => {
          console.log(err);
          return observableOf(new LoadAllFail(err))
        }));
    })
  );

  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private _svc: TasksStatsService) {
  }

}
