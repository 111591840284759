import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  DeleteComment,
  DeleteCommentFail,
  DeleteCommentSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  Read,
  ReadFail,
  ReadSuccess,
  SendComment,
  SendCommentFail,
  SendCommentSuccess,
  TaskCommentActionTypes,
  UpdateComment,
  UpdateCommentFail,
  UpdateCommentSuccess
} from './task-comment.actions';
import {TaskCommentService} from './task-comment.service';
import {TaskComment} from './task-comment';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import * as TaskActions from 'app/+store/task/task.actions';

@Injectable()
export class TaskCommentEffects {
  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(TaskCommentActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.loadAll(action.taskId).pipe(
        first(),
        concatMap((res: TaskComment[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.LOAD_COMMENTS_ERROR')
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  sendComment$ = this.actions.pipe(
    ofType(TaskCommentActionTypes.SendComment),
    concatMap((action: SendComment) => {
      return this._svc.sendComment(action.taskId, action.comment).pipe(
        first(),
        switchMap((comment: TaskComment) => {
          return [new SendCommentSuccess(comment), new TaskActions.LoadOne(action.taskId)];
        }),
        catchError(err => {
          console.error(err);
          this._notifyService.error('TASK.ERRORS.ADDING_COMMENT_FAILURE')
          return of(new SendCommentFail(err));
        }));
    })
  );

  @Effect()
  updateComment$ = this.actions.pipe(
    ofType(TaskCommentActionTypes.UpdateComment),
    concatMap((action: UpdateComment) => {
      return this._svc.updateComment(action.taskId, action.comment).pipe(
        first(),
        switchMap((comment: TaskComment) => {
          return [new UpdateCommentSuccess(comment)];
        }),
        catchError(err => {
          console.error(err);
          return of(new UpdateCommentFail(err));
        }));
    })
  );

  @Effect()
  delete$ = this.actions.pipe(
    ofType(TaskCommentActionTypes.DeleteComment),
    switchMap((action: DeleteComment) => {
      return this._svc.deleteComment(action.taskId, action.comment).pipe(
        first(),
        switchMap((res: TaskComment) => {
          this._notifyService.success('TASK.DELETED_COMMENT_SUCCESSFULLY')
          return [new DeleteCommentSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.DELETED_COMMENT_FAILURE')
          return of(new DeleteCommentFail(err))
        }));
    })
  );


  @Effect()
  readMessage$ = this.actions.pipe(
    ofType(TaskCommentActionTypes.Read),
    concatMap((action: Read) => {
      return this._svc.read(action.taskId, action.comment).pipe(
        first(),
        concatMap((message: TaskComment) => {
          return [new ReadSuccess(message)];
        }),
        catchError(err => {
          console.error(err);
          return of(new ReadFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: TaskCommentService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
