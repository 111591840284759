import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {Feature} from './feature';
import {FeatureBuilder} from './feature.builder';

@Injectable()
export class FeatureService {
  readonly BASE_PATH = 'api/v1/sales/features';

  constructor(private _http: ApiResourceService) {}

  getAll(): Observable<Feature[]> {
    const builder = new FeatureBuilder();
    return <Observable<Feature[]>>this._http.get<FeatureBuilder, Feature>(builder, `${this.BASE_PATH}`);
  }
}
