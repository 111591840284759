import {ExportType, IDmsDocumentExport} from './dms-document-export.interface';

export class DmsDocumentExportTransaction {
  readonly type = 'export_transactions';
  constructor(public id) {}
}

export class DmsDocumentExport implements IDmsDocumentExport {
  readonly type = 'export_file_collection_exports';

  constructor(public id: string,
              public title: string,
              public performerEmail: string,
              public processId: string,
              public exportType: ExportType,
              public status: string,
              public size: number,
              public fingerprint: string,
              public hashAlgorithm: string,
              public exportTransactionId: string,
              public createdAt: Date,
              public updatedAt: Date) {}
}
