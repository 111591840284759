import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ClientEffects} from './client.effects';
import {ClientService} from './client.service';
import {reducer} from './client.reducer';
import {stateKey} from './client.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ClientEffects])
  ],
  providers: [
    ClientEffects,
    ClientService
  ]
})
export class ClientStoreModule {
}
