import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {CollectorDownloadTemplate, CollectorTemplate} from './collector-template';
import {CollectorDownloadTemplateBuilder, CollectorTemplateBuilder} from './collector-template.builder';
import {BomNodeDataColumn, SettingsAccessParameter} from '../process-management/process-management';

@Injectable()
export class CollectorTemplateService {
  readonly BASE_PATH = 'api/v1/clr';

  constructor(private _http: ApiResourceService) {}

  getAll(): Observable<CollectorTemplate[]> {
    const builder = new CollectorTemplateBuilder();
    return <Observable<CollectorTemplate[]>>this._http.get<CollectorTemplateBuilder, CollectorTemplate>(builder, `${this.BASE_PATH}/templates`);
  }

  delete(id: string): Observable<CollectorTemplate> {
    const builder = new CollectorTemplateBuilder();
    return <Observable<CollectorTemplate>>this._http.del<CollectorTemplateBuilder, CollectorTemplate>(builder, `${this.BASE_PATH}/templates/${id}`);
  }

  /**
   * Creates a Collecto from .xlsx file.
   *
   * insertion_mode defines if elements are added or are replacing the
   * current setup.
   * Note: In published mode elements are always added and never replacing existing.
   *
   * @param collectorId
   * @param data
   */
  fromXlsxImport(collectorId: string,
                 data: { title: string; permitUpload?: string, permitComments?: string, insertion_mode: string ; description: string; categories: any[]; columnSetup?: BomNodeDataColumn[] }): Observable<CollectorTemplate> {
    const builder = new CollectorTemplateBuilder();
    const payload = {
      data: {
        attributes: {
          title: data.title,
          description: data.description,
          categories: data.categories,
          insertion_mode: data.insertion_mode,
          column_setup: data.columnSetup,
          permit_upload: data.permitUpload,
          permit_comments: data.permitComments
        }
      }
    };
    return <Observable<CollectorTemplate>>this._http.post<CollectorTemplateBuilder, CollectorTemplate>(builder, `${this.BASE_PATH}/checklists/${collectorId}/imports`, payload);
  }


  create(collectorId: string, data: any): Observable<CollectorTemplate> {
    const builder = new CollectorTemplateBuilder();
    const payload = {data: {attributes: {title: data.title}}};
    return <Observable<CollectorTemplate>>this._http.post<CollectorTemplateBuilder, CollectorTemplate>(builder, `${this.BASE_PATH}/checklists/${collectorId}/versions`, payload);
  }

  loadTemplate(collectorId: string, id: string): Observable<CollectorTemplate> {
    const builder = new CollectorTemplateBuilder();
    return <Observable<CollectorTemplate>>this._http.post<CollectorTemplateBuilder, CollectorTemplate>(builder, `${this.BASE_PATH}/checklists/${collectorId}/versions/${id}/from_version`, {});
  }

  downloadTemplate(id: string): Observable<CollectorDownloadTemplate> {
    const builder = new CollectorDownloadTemplateBuilder();
    return <Observable<CollectorDownloadTemplate>>this._http.post<CollectorDownloadTemplateBuilder, CollectorDownloadTemplate>(builder, `${this.BASE_PATH}/templates/${id}`, {});
  }
}
