import {MilestoneActions, MilestoneActionTypes} from './milestone.actions';
import {adapter, initialState, State} from './milestone.state';

export function reducer(state = initialState, action: MilestoneActions): State {
  switch (action.type) {
    case MilestoneActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case MilestoneActionTypes.LoadAllSuccess:
      return adapter.setAll(action.payload, {
          ...state,
          loading: false,
      });
    case MilestoneActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false
      };
  }
  return state;
}

