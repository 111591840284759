import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {
  ProcessListingItemV3Builder,
  ProcessListingV3ParticipantBuilder,
  ProcessListingV3StatisticsBuilder
} from './process.builder';
import {ProcessListingItemV3, ProcessListingV3Participant, ProcessListingV3Statistics} from './process';
import {IProcessV3Query} from './process.interface';

@Injectable()
export class ProcessListingV3Service {
  readonly BASE_PATH = 'api/v3/workflow_engine/processes';

  constructor(private _http: ApiResourceService) {
  }

  getParticipants(parentId: string = null, clientId: string = null): Observable<ProcessListingV3Participant[]> {
    const builder = new ProcessListingV3ParticipantBuilder();
    let query = parentId ? `?parent_id=${parentId}` : '';
    if (clientId) {
      query = query.length > 0 ? `${query}&client=${clientId}` : `?client=${clientId}`;
    }
    return <Observable<ProcessListingV3Participant[]>>this._http.get<ProcessListingV3ParticipantBuilder, ProcessListingV3Participant>(builder, `${this.BASE_PATH}/participants${query}`);
  }

  getStats(identifier: string, parentId: string = null, clientId: string = null): Observable<ProcessListingV3Statistics> {
    const builder = new ProcessListingV3StatisticsBuilder(identifier);
    let query = parentId ? `?parent_id=${parentId}` : '';
    if (clientId) {
      query = query.length > 0 ? `${query}&client=${clientId}` : `?client=${clientId}`;
    }
    return <Observable<ProcessListingV3Statistics>>this._http.get<ProcessListingV3StatisticsBuilder, ProcessListingV3Statistics>(builder, `${this.BASE_PATH}/statistics${query}`);
  }

  getAll(page: number = 1, query: IProcessV3Query = null): Observable<ProcessListingItemV3[]> {
    const builder = new ProcessListingItemV3Builder();
    let queryParams = `?page=${page}`;
    const _queryParams = [];
    if (query && query['workflows']) {
      _queryParams.push(`workflows=${query['workflows'].join(',')}`);
    }

    if (query && query['clients']) {
      _queryParams.push(`clients=${query['clients'].join(',')}`);
    }

    if (query && query['client']) {
      _queryParams.push(`client=${query['client']}`);
    }

    if (query && query['organizations']) {
      _queryParams.push(`oids=${query['organizations'].join(',')}`);
    }

    if (query && query['participants']) {
      _queryParams.push(`uids=${query['participants'].join(',')}`);
    }

    if (query && query['status']) {
      _queryParams.push(`status=${query['status'].join(',')}`);
    }

    if (query && query['search']) {
      _queryParams.push(`q=${query['search']}`);
    }

    if (query && query['parent_id']) {
      _queryParams.push(`parent_id=${query['parent_id']}`);
    }

    if (query && query.ovns) {
      _queryParams.push(`ovns=true`);
    }

    queryParams = _queryParams.length > 0 ? `${queryParams}&${_queryParams.join('&')}` : queryParams;
    return <Observable<ProcessListingItemV3[]>>this._http.get<ProcessListingItemV3Builder, ProcessListingItemV3>(builder, `${this.BASE_PATH}${queryParams}`);
  }

  getAllMinifiedRes(page: number = 1, query: IProcessV3Query = null): Observable<ProcessListingItemV3[]> {
    const builder = new ProcessListingItemV3Builder();
    let queryParams = `?page=${page}`;
    const _queryParams = [];

    if (query && query['status']) {
      _queryParams.push(`status=` + query.status.join(','));
    } else {
      _queryParams.push(`status=in_progress,closed,open`);
    }

    if (query && query['search']) {
      _queryParams.push(`q=${query['search']}`);
    }

    if (query && query['organizations']) {
      _queryParams.push(`oid=${query['organizations']}`);
    }

    if (query && query['client']) {
      _queryParams.push(`cid=${query['client']}`);
    }

    queryParams = _queryParams.length > 0 ? `${queryParams}&${_queryParams.join('&')}` : queryParams;
    return <Observable<ProcessListingItemV3[]>>this._http.get<ProcessListingItemV3Builder, ProcessListingItemV3>(builder, `${this.BASE_PATH}/search${queryParams}`);
  }
}
