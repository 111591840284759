import { Action } from '@ngrx/store';

export const ABC_CONTACTS_FILTER = 'ABC_CONTACTS_FILTER';
export const RESET_ABC_CONTACT_FILTER = 'RESET_CONTACTS';


export class SetContactsABCFilter implements Action {
  readonly type: string = ABC_CONTACTS_FILTER;

  constructor(public payload: any) { }
}

export class ResetContactABCFilter implements Action {
  readonly type: string = RESET_ABC_CONTACT_FILTER;

  constructor(public payload: any = null) { }
}


export type Actions = SetContactsABCFilter
  | ResetContactABCFilter;
