import {adapter, initialState, State} from './email-to-name.state';
import {EmailToNameActions, EmailToNameActionTypes} from './email-to-name.actions';

export function reducer(state = initialState, action: EmailToNameActions): State {
  switch (action.type) {
    case EmailToNameActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.payload, state);
  }
  return state
}
