import { IApiResourceBuilder, IApiRequest } from 'app/shared/modules/api-resource/models/api.interface';
import { TasksStats } from './tasks-stats';

export class TasksStatsBuilder implements IApiResourceBuilder<TasksStats> {

  toRequest(model: TasksStats): IApiRequest {
    throw new Error('Method not implemented.');
  }

  fromResponse(data): TasksStats {
    const tasksStats = new TasksStats(
      data.id,
      data.attributes.sum,
      data.attributes.pending_count,
      data.attributes.open_count,
      data.attributes.closed_count,
      data.attributes.responsible_count,
      data.attributes.open_responsible_count,
      data.attributes.accepted_count,
      data.attributes.rejected_count,
      data.attributes.approval_count,
      data.attributes.denial_count,
      data.attributes.all_open_count,
      data.attributes.all_closed_count,
      data.attributes.my_closed_count,
      data.attributes.updated_at
    );

    return tasksStats;
  }

}
