import { Action } from '@ngrx/store';

export const SET_TITLE = 'SET_TITLE';

export class SetBrowserTitle implements Action {
  readonly type: string = SET_TITLE;

  constructor(public payload: string) {

  }
}

export type Actions = SetBrowserTitle;
