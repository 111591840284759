import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../shared/modules/api/services/auth-guard.service';
import {ClientContainerComponent} from './containers/client-container/client-container.component';
import {ClientOverviewComponent} from './containers/client-overview/client-overview.component';
import {ClientDetailsComponent} from './containers/client-details/client-details.component';
import {ClientWorkflowsComponent} from './containers/client-workflows/client-workflows.component';
import {ClientBookmanComponent} from './containers/client-bookman/client-bookman.component';
import {CanActivateFibu} from 'app/shared/modules/api/services/can-activate-fibu.service';
import {CanActivateAdminRights} from 'app/shared/modules/api/services/can-activate-admin-rights.service';

const routes: Routes = [
  {
    path: '',
    component: ClientContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {path: 'overview', component: ClientOverviewComponent, canActivate: [AuthGuard]},
      {path: ':id/details', component: ClientDetailsComponent, canActivate: [AuthGuard]},
      {path: ':id/workflows', component: ClientWorkflowsComponent, canActivate: [AuthGuard]},
      {
        path: ':id/fibu-settings',
        component: ClientBookmanComponent,
        canActivate: [AuthGuard, CanActivateAdminRights, CanActivateFibu]
      }
    ],


  }
];

export const clientRoutes = RouterModule.forChild(routes);
