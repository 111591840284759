<div [formGroup]="form" class="dvtx-datapicker">
  <mat-form-field class="dvtx-input-full-width" autocomplete="off">
    <input
      matInput
      [id]="controlName"
      [name]="controlName"
      [min]="startAt"
      [matDatepicker]="scopedDatepicker"
      [disabled]="disabled"
      [formControlName]="controlName"
      [placeholder]="placeholder"
      [required]="required"
      (click)="scopedDatepicker.open()"
      (dateChange)="onSelectedDate.emit($event)"
      #dateInput
      autocomplete="new-{{controlName}}">
    <mat-datepicker-toggle matSuffix *ngIf="!!dateInput.value && !disabled && !required">
      <mat-icon matDatepickerToggleIcon
                (click)="clearDueDate()">close</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker-toggle matSuffix [for]="scopedDatepicker"></mat-datepicker-toggle>
    <!-- Restore focus set to off: Producing ExpressionChangedAfterItHasBeenCheckedError on mat-focus change -->
    <mat-datepicker #scopedDatepicker [startAt]="startAt" [restoreFocus]="false"></mat-datepicker>
  </mat-form-field>
</div>

<!--  <mat-form-field class="dvtx-input-full-width">
    <button mdSuffix [matDatepickerToggle]="scopedDatepicker"></button>
    <input matInput [mdDatepicker]="scopedDatepicker" placeholder="{{placeholder}}" [formControlName]="controlName">
  </mat-form-field>
  <mat-datepicker #scopedDatepicker [startAt]="startAt"></mat-datepicker>-->
