import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SimpleAddress} from '../../../../../../modules/contacts/models/contact.interface';

@Component({
  selector: 'dvtx-address-input-group-cva',
  templateUrl: './address-input-group-cva.component.html',
  styleUrls: ['./address-input-group-cva.component.scss'],
})
export class AddressInputGroupCvaComponent {
  @Input() addresses: SimpleAddress[] = [];
  @Input() disabled: boolean = false;
  @Input() enableLocationOrType: boolean;
  @Output() addAddress = new EventEmitter<SimpleAddress>()
  @Output() removeAddress = new EventEmitter<string>()

  addNewField() {
    this.addresses = this.addresses.concat(new SimpleAddress())
  }

  saveAddress(address: SimpleAddress) {
    this.addAddress.emit(address)
  }

  deleteAddress(address: SimpleAddress) {
    if (address.id) {
      this.removeAddress.emit(address.id)
    } else {
      this.addresses = this.addresses.filter(x => x !== address);
    }
  }

}
