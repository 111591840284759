import {DmsDocumentExportActions, DmsDocumentExportActionTypes} from './dms-document-export.actions';
import {adapter, initialState, State} from './dms-document-export.state';
import {Update} from '@ngrx/entity';
import {DmsDocumentExport} from './dms-document-export';

export function reducer(state = initialState, action: DmsDocumentExportActions): State {
  const current = state.selected;
  switch (action.type) {
    case DmsDocumentExportActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case DmsDocumentExportActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.fileExports, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case DmsDocumentExportActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
    case DmsDocumentExportActionTypes.LoadOne:
    case DmsDocumentExportActionTypes.Remove:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case DmsDocumentExportActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.fileExport, {
        ...state,
        selected: action.fileExport,
        loading: false,
      });
    case DmsDocumentExportActionTypes.RemoveSuccess:
      return adapter.removeOne(action.fileExport.id, {
        ...state,
        selected: null,
        loading: false,
      });
    case DmsDocumentExportActionTypes.LoadOneFail:
    case DmsDocumentExportActionTypes.RemoveFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
  }
  return state;
}

