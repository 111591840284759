import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Feature} from './feature';

export interface State extends EntityState<Feature> {
  selected?: Feature;
  loading: boolean;
}

export const adapter = createEntityAdapter<Feature>();

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
