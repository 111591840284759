import {createFeatureSelector} from '@ngrx/store';
import {adapter, State} from './licence.state';

export const stateKey = 'licences';
const getLicenceState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getLicenceEntities,
  selectAll: getAllLicences,
} = adapter.getSelectors(getLicenceState);

