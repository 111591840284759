import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {WorkflowTemplateCategoryEffects} from "./workflow-template-category.effects";
import {WorkflowTemplateCategoryService} from "./workflow-template-category.service";
import {stateKey} from "./workflow-template-category.selectors";
import {reducer} from "./workflow-template-category.reducer";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([WorkflowTemplateCategoryEffects])
  ],
  providers: [
    WorkflowTemplateCategoryEffects,
    WorkflowTemplateCategoryService
  ]
})
export class WorkflowTemplateCategoryStoreModule {
}
