import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './process-folder.state';

export const stateKey = 'process-folder';
const getProcessFolderState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getProcessFolderEntities,
  selectAll: getAllProcessFolders,
} = adapter.getSelectors(getProcessFolderState);

export const getLoadingState = createSelector(
  getProcessFolderState,
  state => state.loading
);

export const getProcessFolderByProcessId = (id: string) => createSelector(
  getProcessFolderEntities,
  (entities) => entities[id]);

export const getProcessFolderByFolderId = (id: string) => createSelector(
  getAllProcessFolders,
  (folders) => folders.find(f => f && f.dmsFolderId === id));
