
<div class="row mx-0 w-100 purchase--address--form align-items-center">
  <div class="col-12">
    <p class="h3">Angaben zur Bankverbindung</p>
  </div>
  <div class="col-8">
    <mat-form-field>
      <mat-select placeholder="Bankeinstellung wählen oder erstellen" disableRipple (selectionChange)="fillForm($event)" #selectPaymentOption>
        <mat-option *ngFor="let option of paymentoptions$ | async" [value]="option">{{ option?.displayName }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-4 text-right">
    <button mat-flat-button color="warn" (click)="resetForm()">{{ 'GENERAL.RESET_ACTION' | translate }}</button>
  </div>
</div>


<div class="row mx-0 w-100 purchase--address--form"  [formGroup]="form">
  <div class="col-4">
    <mat-form-field>
      <input matInput placeholder="Vorname" formControlName="firstName" required>
    </mat-form-field>
  </div>
  <div class="col-4">
    <mat-form-field>
      <input matInput placeholder="Nachname" formControlName="lastName" required>
    </mat-form-field>
  </div>
</div>

<div class="row mx-0 w-100 purchase--address--form align-items-center"  [formGroup]="form">
  <div class="col-4">
    <mat-form-field>
      <input matInput placeholder="IBAN" formControlName="iban" #iban required>
      <mat-error *ngIf="ibanFormControl.hasError('invalidIban')">
        Die eingegebene IBAN ist nicht gültig.
      </mat-error>
    </mat-form-field>
  </div>

  <div class="col-8">
    {{ this.bank || 'Name Kreditinstitut (wird automatisch ergänzt)' }}
  </div>
</div>

<div class="row mx-0 w-100 purchase--address--form"  [formGroup]="form">
  <div class="col-4">
    <mat-form-field>
      <input matInput placeholder="BIC" formControlName="bic" required>
      <mat-error *ngIf="bicFormControl.hasError('invalidBic')">
        Die eingegebene BIC ist nicht gültig.
      </mat-error>
    </mat-form-field>
  </div>
</div>
<div class="row mx-0 w-100 purchase--address--form"  [formGroup]="form">
  <div class="col-8">
    <h3>SEPA-Lastschriftmandat</h3>
    <p>Ich ermächtige/Wir ermächtigen die 5FSoftware GmbH (Zahlungsempfänger), Zahlungen von meinem/unserem Konto mittels Lastschrift einzuziehen. Zugleich weise ich  mein/weisen wir unser Kreditinstitut an, die vom Zahlungsempfänger auf mein/unser Konto gezogenen Lastschriften einzulösen.
    </p>
    <p>Hinweis: Diese Lastschriftmandat dient nur dem Einzug von Lastschriften, die auf Konten von Unternehmen gezogen sind. Ich bin nicht berechtigt, nach der erfolgten Einlösung eine Erstattung des belasteten Betrages zu verlangen. Ich bin berechtigt, mein/unser Kreditinstitut bis zum Fälligkeitstag anzuweisen, Lastschriften nicht einzulösen.</p>
  </div>
</div>
<div class="row mx-0 w-100 purchase--address--form"  [formGroup]="form">
  <div class="col-8">
    <label class="verify-integrity mt-3">
      <mat-checkbox formControlName="confirmed"></mat-checkbox>
      <div>Hiermit bestätige ich den Einzug per SEPA-Lastschriftmandat.</div>
    </label>
  </div>
</div>
