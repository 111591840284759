import {Action} from '@ngrx/store';
import {ExcelExport} from './excel-export';

export enum ExcelExportActionTypes {
  GenerateExcel = '[ExcelExport] GenerateExcel',
  GenerateExcelSuccess = '[ExcelExport] GenerateExcel Success',
  GenerateExcelFail = '[ExcelExport] GenerateExcel Fail',
}

export class GenerateExcel implements Action {
  readonly type = ExcelExportActionTypes.GenerateExcel;

  constructor(public excelExport: ExcelExport) {
  }
}

export class GenerateExcelSuccess implements Action {
  readonly type = ExcelExportActionTypes.GenerateExcelSuccess;

  constructor(public payload: any) {
  }
}

export class GenerateExcelFail implements Action {
  readonly type = ExcelExportActionTypes.GenerateExcelFail;

  constructor(public payload: any) {
  }
}

export type ExcelExportActions =
  | GenerateExcel
  | GenerateExcelSuccess
  | GenerateExcelFail
