import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {DocumentPreview} from './document-preview';

export interface State extends EntityState<DocumentPreview> {
  selected?: DocumentPreview;
  loading: boolean;
}

export const adapter = createEntityAdapter<DocumentPreview>();

export const initialState: State = adapter.getInitialState({
  loading: false,
});
