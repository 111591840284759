import {FeatureActions, FeatureActionTypes} from './feature.actions';
import {adapter, initialState, State} from './feature.state';

export function reducer(state = initialState, action: FeatureActions): State {
  const current = state.selected;
  switch (action.type) {
    case FeatureActionTypes.LoadAll:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: true
      };
    case FeatureActionTypes.LoadAllSuccess:
      return adapter.setAll(action.features, {
          ...state,
          selected: action.features[0],
          loading: false,
      });
    case FeatureActionTypes.LoadAllFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
  }
  return state;
}

