import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, State } from './tasks-stats.state';

export const stateKey = 'tasks-stats';
const getTasksStatsState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getTasksStatsEntities,
  selectAll: getAllTasksStatsModels,
} = adapter.getSelectors(getTasksStatsState);


export const getCurrentStats = () => createSelector(
  getAllTasksStatsModels,
  (entities) => entities[0]
);

export const loadingState = createSelector(
  getTasksStatsState,
  (state) => state.loading
);
