import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SystemService} from 'app/services/system.service';
import {first, startWith, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'dvtx-main-session',
  templateUrl: './main-session.component.html',
  styleUrls: [
    './main-session.component.scss',
    '../main-app/main-app.component.scss'
  ]
})
export class MainSessionComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();

  public error: any;
  public status: any;
  public lang = 'de';

  constructor(private systemSvc: SystemService,
              private i18nSvc: TranslateService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.i18nSvc.onLangChange
      .pipe(
        startWith({lang: this.i18nSvc.currentLang}),
        takeUntil(this.onDestroy))
      .subscribe(lang => {
        this.lang = lang.lang;
        this.cdr.detectChanges();
      });

    this.systemSvc
      .getStatus()
      .pipe(first())
      .subscribe((_status) => {
          this.error = null;
        },
        (error) => {
          this.error = error;
          // error is view public but invoked async.
          // This can create ExpressionChangedAfterItHasBeenCheckedError without detectChanges.
          this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
