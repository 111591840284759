import { ShoppingCartService } from '../../../../+store/licence/shopping-cart.service';
import { LineItem } from './line-item';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { ShoppingCart } from './shopping-cart';

/**
 * DAO for shopping cart access. Implements DataSource to be directly
 * usable in Angular tables (connect/disconnect).
 */
export class ShoppingCartDataSource extends ShoppingCart implements DataSource<LineItem> {
  constructor(protected _cartSvc: ShoppingCartService) {
    super(_cartSvc);
  }

  connect(collectionViewer: CollectionViewer): Observable<LineItem[]> {
    return this.lineItemsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lineItemsSubject.complete();
    this.loadingSubject.complete();
  }
}
