import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ProcessArtifact} from './process-artifact';
import * as dayjs from 'dayjs';

export interface State extends EntityState<ProcessArtifact> {
  loading: boolean;
  transfering: boolean;
}

export const adapter = createEntityAdapter<ProcessArtifact>({
  sortComparer: (l, r) => -(dayjs(l.updatedAt).diff(dayjs(r.updatedAt)))
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  transfering: false
});
