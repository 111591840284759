import { Component, OnInit } from '@angular/core';
import {VersionCheckService} from 'app/services/version-check.service';

@Component({
  selector: 'dvtx-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  versionHash: string;
  version: string;

  constructor(private _versionCheckSvc: VersionCheckService) {
    this.versionHash = this._versionCheckSvc.currentHash;
    this.version = this._versionCheckSvc.version;
  }

  ngOnInit() {
  }
}
