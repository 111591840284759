import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  DocumentPreview,
  DocumentSignatureRequest, OrganizationalVisual, SignatureAccount, SignatureFrameFormat,
  SignatureJob,
  SignatureSettings,
  VisualSignature
} from './document-signature';
import {
  DocumentPreviewBuilder,
  DocumentSignatureRequestBuilder,
  OrganizationalVisualBuilder,
  SignatureAccountBuilder,
  SignatureJobBuilder,
  SignatureSettingsBuilder,
  VisualSignatureBuilder
} from './document-signature.builder';
import {SignApiResourceService} from './sign-api-resource.service';

@Injectable()
export class DocumentSignatureService {
  readonly BASE_PATH = 'api/v1/sign/visual_candidates';
  readonly SIGNATURE_SETTINGS_BASE_PATH = 'api/v1/sign/signature_settings';
  readonly SIGNATURE_ORGANIZATIONAL_VISUAL_BASE_PATH = 'api/v1/sign/organizational_user_visual';
  readonly JOBS_BASE_PATH = 'api/v1/sign/intents';
  readonly ACCOUNT_BASE_PATH = 'api/v1/sign/account';
  readonly DOCUMENTS_BASE_PATH = 'api/v1/sign/documents';
  readonly DEFAULT_SIGNATURE_BASE_PATH = 'api/v1/sign/default_signature';
  readonly WORKFLOW_REQUEST_BASE_PATH = 'api/v1/sign/signature_requests';
  readonly SIGNATURE_PROCESS_BASE_PATH = 'api/v1/sign/signature_processes';

  constructor(private _http: SignApiResourceService) {}

  getVisualCandidates(name): Observable<VisualSignature[]> {
    const builder = new VisualSignatureBuilder();
    const payload = { data: { attributes: { name: name } } }
    return <Observable<VisualSignature[]>>this._http.postAll<VisualSignatureBuilder, VisualSignature>(builder, `${this.BASE_PATH}/all`, payload);
  }

  renderTimeStampOn(signature: VisualSignature): Observable<VisualSignature> {
    const builder = new VisualSignatureBuilder();
    const payload = { data: { attributes: { image: signature.content } } };
    return <Observable<VisualSignature>>this._http.post<VisualSignatureBuilder, VisualSignature>(builder, `${this.BASE_PATH}/generate_timestamp`, payload);
  }

  checkDocumentAccess(id): Observable<DocumentPreview> {
    const builder = new DocumentPreviewBuilder();
    return <Observable<DocumentPreview>>this._http.get<DocumentPreviewBuilder, DocumentPreview>(builder, `${this.DOCUMENTS_BASE_PATH}/${id}`);
  }

  getDocumentPreview(id): Observable<DocumentPreview> {
    const builder = new DocumentPreviewBuilder();
    return <Observable<DocumentPreview>>this._http.get<DocumentPreviewBuilder, DocumentPreview>(builder, `${this.DOCUMENTS_BASE_PATH}/${id}/pdf`);
  }

  getMySignatureSettings(): Observable<SignatureSettings[]> {
    const builder = new SignatureSettingsBuilder();
    return <Observable<SignatureSettings[]>>this._http.get<SignatureSettingsBuilder, SignatureSettings>(builder, `${this.SIGNATURE_SETTINGS_BASE_PATH}?originator=true`);
  }

  getSignatureSettings(email: string): Observable<SignatureSettings[]> {
    const uid = encodeURIComponent(email);
    const builder = new SignatureSettingsBuilder();
    return <Observable<SignatureSettings[]>>this._http.get<SignatureSettingsBuilder, SignatureSettings>(builder, `${this.SIGNATURE_SETTINGS_BASE_PATH}?uid=${uid}&originator=false`);
  }

  getParticipants(signatureRequestId: string): Observable<SignatureJob[]> {
    const builder = new SignatureJobBuilder();
    return <Observable<SignatureJob[]>>this._http.get<SignatureJobBuilder, SignatureJob>(builder, `${this.WORKFLOW_REQUEST_BASE_PATH}/${signatureRequestId}/participants`);
  }

  addParticipant(signatureRequestId: string, job: SignatureJob): Observable<SignatureJob> {
    const builder = new SignatureJobBuilder();
    const payload = builder.toRequest(job);
    return <Observable<SignatureJob>>this._http.post<SignatureJobBuilder, SignatureJob>(builder, `${this.WORKFLOW_REQUEST_BASE_PATH}/${signatureRequestId}/participants`, payload);
  }

  addSignatureFrame(signatureRequestId: string, job: SignatureJob, visual: VisualSignature): Observable<SignatureJob> {
    const builder = new SignatureJobBuilder();
    const payload = {
      data: {
        type: visual.type,
        attributes: {
          skip_meta_info: visual.skipMetaInfo,
          format: visual.format,
          page: visual.page,
          width:  visual.w,
          height:  visual.h,
          offset_left:  visual.x,
          offset_top:  visual.y,
          container_width: visual.cw,
          container_height: visual.ch
        }
      }
    }
    return <Observable<SignatureJob>>this._http.post<SignatureJobBuilder, SignatureJob>(builder, `${this.WORKFLOW_REQUEST_BASE_PATH}/${signatureRequestId}/participants/${job.id}/signature_frames`, payload);
  }

  updateSignatureFrame(signatureRequestId: string, visual: VisualSignature): Observable<SignatureJob> {
    const builder = new SignatureJobBuilder();
    const payload = {
      data: {
        attributes: {
          skip_meta_info: visual.skipMetaInfo,
          format: visual.format,
          page: visual.page,
          width:  visual.w,
          height:  visual.h,
          offset_left:  visual.x,
          offset_top:  visual.y,
          container_width: visual.cw,
          container_height: visual.ch
        }
      }
    }
    return <Observable<SignatureJob>>this._http.put<SignatureJobBuilder, SignatureJob>(builder, `${this.WORKFLOW_REQUEST_BASE_PATH}/${signatureRequestId}/participants/${visual.referenceId}/signature_frames/${visual.id}`, payload);
  }

  removeSignatureFrame(signatureRequestId: string, jobId: string, frameId: string): Observable<SignatureJob> {
    const builder = new SignatureJobBuilder();
    return <Observable<SignatureJob>>this._http.del<SignatureJobBuilder, SignatureJob>(builder, `${this.WORKFLOW_REQUEST_BASE_PATH}/${signatureRequestId}/participants/${jobId}/signature_frames/${frameId}`);
  }

  updateParticipant(signatureRequestId: string, job: SignatureJob): Observable<SignatureJob> {
    const builder = new SignatureJobBuilder();
    const payload = builder.toRequest(job);
    return <Observable<SignatureJob>>this._http.put<SignatureJobBuilder, SignatureJob>(builder, `${this.WORKFLOW_REQUEST_BASE_PATH}/${signatureRequestId}/participants/${job.id}`, payload);
  }

  removeParticipant(signatureRequestId: string, jobId: string): Observable<SignatureJob> {
    const builder = new SignatureJobBuilder();
    return <Observable<SignatureJob>>this._http.del<SignatureJobBuilder, SignatureJob>(builder, `${this.WORKFLOW_REQUEST_BASE_PATH}/${signatureRequestId}/participants/${jobId}`);
  }

  getMyAccount(): Observable<SignatureAccount> {
    const builder = new SignatureAccountBuilder();
    return <Observable<SignatureAccount>>this._http.get<SignatureAccountBuilder, SignatureAccount>(builder, this.ACCOUNT_BASE_PATH);
  }

  unlinkAccount(): Observable<SignatureAccount> {
    const builder = new SignatureAccountBuilder();
    return <Observable<SignatureAccount>>this._http.del<SignatureAccountBuilder, SignatureAccount>(builder, this.ACCOUNT_BASE_PATH);
  }

  updateAccount(username: string, password: string): Observable<SignatureAccount> {
    const builder = new SignatureAccountBuilder();
    const payload = {
      data: {
        attributes: {
          username: username,
          password: password
        }
      }
    };
    return <Observable<SignatureAccount>>this._http.put<SignatureAccountBuilder, SignatureAccount>(builder, this.ACCOUNT_BASE_PATH, payload);
  }

  registerAccount(username: string): Observable<SignatureAccount> {
    const builder = new SignatureAccountBuilder();
    const payload = {
      data: {
        attributes: {
          username: username
        }
      }
    };
    return <Observable<SignatureAccount>>this._http.post<SignatureAccountBuilder, SignatureAccount>(builder, this.ACCOUNT_BASE_PATH, payload);
  }

  updateDefaultSignature(base64Content: string, apiMode: SignatureFrameFormat = 'signature'): Observable<VisualSignature> {
    const builder = new VisualSignatureBuilder();
    const payload = {
      data: {
        attributes: {
          content: base64Content
        }
      }
    };
    return <Observable<VisualSignature>>this._http.put<VisualSignatureBuilder, VisualSignature>(builder, `${this.DEFAULT_SIGNATURE_BASE_PATH}?type=${apiMode}`, payload);
  }

  toggleSkipMetaInfo(skipMetaInfo: boolean): Observable<VisualSignature> {
    const builder = new VisualSignatureBuilder();
    const payload = {
      data: {
        attributes: {
          skip_meta_info: skipMetaInfo
        }
      }
    };
    return <Observable<VisualSignature>>this._http.put<VisualSignatureBuilder, VisualSignature>(builder, `${this.DEFAULT_SIGNATURE_BASE_PATH}/skip_meta_info`, payload);
  }

  getDefaultSignature(apiMode: SignatureFrameFormat = 'signature'): Observable<VisualSignature> {
    const builder = new VisualSignatureBuilder();
    return <Observable<VisualSignature>>this._http.get<VisualSignatureBuilder, VisualSignature>(builder, `${this.DEFAULT_SIGNATURE_BASE_PATH}?type=${apiMode}`);
  }

  deleteDefaultSignature(apiMode: SignatureFrameFormat = 'signature'): Observable<VisualSignature> {
    const builder = new VisualSignatureBuilder();
    return <Observable<VisualSignature>>this._http.del<VisualSignatureBuilder, VisualSignature>(builder, `${this.DEFAULT_SIGNATURE_BASE_PATH}?type=${apiMode}`);
  }

  createWorkflowRequest(processId: string, documentId: string, profile: string): Observable<DocumentSignatureRequest> {
    const builder = new DocumentSignatureRequestBuilder();
    const payload = {
      data: {
        attributes: {
          process_id: processId,
          document_id: documentId,
          request_profile: profile
        }
      }
    };
    return <Observable<DocumentSignatureRequest>>this._http.post<DocumentSignatureRequestBuilder, DocumentSignatureRequest>(builder, this.WORKFLOW_REQUEST_BASE_PATH, payload);
  }

  deleteRequestDraft(processId: string): Observable<DocumentSignatureRequest> {
    const builder = new DocumentSignatureRequestBuilder();
    return <Observable<DocumentSignatureRequest>>this._http.del<DocumentSignatureRequestBuilder, DocumentSignatureRequest>(builder, `${this.WORKFLOW_REQUEST_BASE_PATH}/${processId}`);
  }

  updateWorkflowRequest(signatureRequest: DocumentSignatureRequest): Observable<DocumentSignatureRequest> {
    const builder = new DocumentSignatureRequestBuilder();
    const payload = builder.toRequest(signatureRequest);
    return <Observable<DocumentSignatureRequest>>this._http.put<DocumentSignatureRequestBuilder, DocumentSignatureRequest>(builder, `${this.WORKFLOW_REQUEST_BASE_PATH}/${signatureRequest.id}`, payload);
  }

  getWorkflowRequest(workflowRequestId: string): Observable<DocumentSignatureRequest> {
    const builder = new DocumentSignatureRequestBuilder();
    return <Observable<DocumentSignatureRequest>>this._http.get<DocumentSignatureRequestBuilder, DocumentSignatureRequest>(builder, `${this.WORKFLOW_REQUEST_BASE_PATH}/${workflowRequestId}`);
  }

  publishWorkflowRequest(workflowRequest: DocumentSignatureRequest): Observable<DocumentSignatureRequest> {
    const builder = new DocumentSignatureRequestBuilder();
    const payload = builder.toRequest(workflowRequest);
    return <Observable<DocumentSignatureRequest>>this._http.post<DocumentSignatureRequestBuilder, DocumentSignatureRequest>(builder, `${this.WORKFLOW_REQUEST_BASE_PATH}/${workflowRequest.id}/publish`, payload);
  }

  resendQesTan(workflowRequest: DocumentSignatureRequest, jobId): Observable<DocumentSignatureRequest> {
    const builder = new DocumentSignatureRequestBuilder();
    const payload = {
      data: {
        attributes: {
          job_id: jobId
        }
      }
    };
    return <Observable<DocumentSignatureRequest>>this._http.post<DocumentSignatureRequestBuilder, DocumentSignatureRequest>(builder, `${this.WORKFLOW_REQUEST_BASE_PATH}/${workflowRequest.id}/resend_tan`, payload);
  }

  verifyQesTan(workflowRequest: DocumentSignatureRequest, jobId, tan): Observable<DocumentSignatureRequest> {
    const builder = new DocumentSignatureRequestBuilder();
    const payload = {
      data: {
        attributes: {
          job_id: jobId,
          tan: tan
        }
      }
    };
    return <Observable<DocumentSignatureRequest>>this._http.post<DocumentSignatureRequestBuilder, DocumentSignatureRequest>(builder, `${this.WORKFLOW_REQUEST_BASE_PATH}/${workflowRequest.id}/verify_tan`, payload);
  }

  cancelWorkflow(workflowRequest: DocumentSignatureRequest, comment): Observable<DocumentSignatureRequest> {
    const builder = new DocumentSignatureRequestBuilder();
    const payload = {
      data: {
        attributes: {
          comment: comment
        }
      }
    }
    return <Observable<DocumentSignatureRequest>>this._http.post<DocumentSignatureRequestBuilder, DocumentSignatureRequest>(builder, `${this.SIGNATURE_PROCESS_BASE_PATH}/${workflowRequest.id}/cancel`, payload);
  }

  getCurrentProcessedDocument(id): Observable<DocumentPreview> {
    const builder = new DocumentPreviewBuilder();
    return <Observable<DocumentPreview>>this._http.get<DocumentPreviewBuilder, DocumentPreview>(builder, `${this.SIGNATURE_PROCESS_BASE_PATH}/${id}/current_document`);
  }

  getAuditLog(id): Observable<DocumentPreview> {
    const builder = new DocumentPreviewBuilder();
    return <Observable<DocumentPreview>>this._http.get<DocumentPreviewBuilder, DocumentPreview>(builder, `${this.SIGNATURE_PROCESS_BASE_PATH}/${id}/audit_log`);
  }

  getReport(id): Observable<DocumentPreview> {
    const builder = new DocumentPreviewBuilder();
    return <Observable<DocumentPreview>>this._http.get<DocumentPreviewBuilder, DocumentPreview>(builder, `${this.SIGNATURE_PROCESS_BASE_PATH}/${id}/verification_report`);
  }

  getCurrentProcessingStatus(id): Observable<DocumentSignatureRequest> {
    const builder = new DocumentSignatureRequestBuilder();
    return <Observable<DocumentSignatureRequest>>this._http.get<DocumentSignatureRequestBuilder, DocumentSignatureRequest>(builder, `${this.SIGNATURE_PROCESS_BASE_PATH}/${id}/status`);
  }

  getJobs(id, use_cache = false): Observable<SignatureJob[]> {
    const builder = new SignatureJobBuilder();
    return <Observable<SignatureJob[]>>this._http.get<SignatureJobBuilder, SignatureJob>(builder, `${this.SIGNATURE_PROCESS_BASE_PATH}/${id}/jobs?use_cache=${use_cache}`);
  }

  getMyJob(id, use_cache= false): Observable<SignatureJob> {
    const builder = new SignatureJobBuilder();
    return <Observable<SignatureJob>>this._http.get<SignatureJobBuilder, SignatureJob>(builder, `${this.SIGNATURE_PROCESS_BASE_PATH}/${id}/job?use_cache=${use_cache}`);
  }

  getCurrentJobDocument(id, jobId): Observable<DocumentPreview> {
    const builder = new DocumentPreviewBuilder();
    return <Observable<DocumentPreview>>this._http.get<DocumentPreviewBuilder, DocumentPreview>(builder, `${this.SIGNATURE_PROCESS_BASE_PATH}/${id}/current_document/${jobId}`);
  }

  getJobReport(id, jobId): Observable<DocumentPreview> {
    const builder = new DocumentPreviewBuilder();
    return <Observable<DocumentPreview>>this._http.get<DocumentPreviewBuilder, DocumentPreview>(builder, `${this.SIGNATURE_PROCESS_BASE_PATH}/${id}/verification_report/${jobId}`);
  }

  sign(signatureRequest: DocumentSignatureRequest, jobId): Observable<DocumentSignatureRequest> {
    const builder = new DocumentSignatureRequestBuilder();
    const payload = builder.toRequest(signatureRequest);
    return <Observable<DocumentSignatureRequest>>this._http.post<DocumentSignatureRequestBuilder, DocumentSignatureRequest>(builder, `${this.SIGNATURE_PROCESS_BASE_PATH}/${signatureRequest.id}/sign/${jobId}`, payload);
  }

  reject(signatureRequest: DocumentSignatureRequest, jobId): Observable<DocumentSignatureRequest> {
    const builder = new DocumentSignatureRequestBuilder();
    const payload = builder.toRequest(signatureRequest);
    return <Observable<DocumentSignatureRequest>>this._http.post<DocumentSignatureRequestBuilder, DocumentSignatureRequest>(builder, `${this.SIGNATURE_PROCESS_BASE_PATH}/${signatureRequest.id}/reject/${jobId}`, payload);
  }

  getOrganizationalVisual(): Observable<OrganizationalVisual> {
    const builder = new OrganizationalVisualBuilder();
    return <Observable<OrganizationalVisual>>this._http.get<OrganizationalVisualBuilder, OrganizationalVisual>(builder, this.SIGNATURE_ORGANIZATIONAL_VISUAL_BASE_PATH);
  }

  updateOrganizationalVisual(visual: OrganizationalVisual): Observable<OrganizationalVisual> {
    const builder = new OrganizationalVisualBuilder();
    const payload = builder.toRequest(visual);
    return this._http.put<OrganizationalVisualBuilder, OrganizationalVisual>(builder, this.SIGNATURE_ORGANIZATIONAL_VISUAL_BASE_PATH, payload);
  }

  refreshOrganizationalVisual(): Observable<OrganizationalVisual> {
    const builder = new OrganizationalVisualBuilder();
    return this._http.del<OrganizationalVisualBuilder, OrganizationalVisual>(builder, `${this.SIGNATURE_ORGANIZATIONAL_VISUAL_BASE_PATH}/refresh`);
  }
}
