import {Action} from '@ngrx/store';
import {PartnerLinkParticipation} from './partner-link-participation';

export enum PartnerLinkParticipationActionTypes {
  Create = '[PartnerLinkParticipation] Create',
  CreateSuccess = '[PartnerLinkParticipation] Create Success',
  CreateFail = '[PartnerLinkParticipation] Create Fail',
  Remove = '[PartnerLinkParticipation] Remove',
  RemoveSuccess = '[PartnerLinkParticipation] Remove Success',
  RemoveFail = '[PartnerLinkParticipation] Remove Fail',
  LoadAll = '[PartnerLinkParticipation] Load All',
  LoadAllSuccess = '[PartnerLinkParticipation] Load All Success',
  LoadAllFail = '[PartnerLinkParticipation] Load All Fail'
}

export class Create implements Action {
  readonly type = PartnerLinkParticipationActionTypes.Create;

  constructor(public email: string) {
  }
}

export class CreateSuccess implements Action {
  readonly type = PartnerLinkParticipationActionTypes.CreateSuccess;

  constructor(public partner: PartnerLinkParticipation) {
  }
}

export class CreateFail implements Action {
  readonly type = PartnerLinkParticipationActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = PartnerLinkParticipationActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = PartnerLinkParticipationActionTypes.RemoveSuccess;

  constructor(public partner: PartnerLinkParticipation) {
  }
}

export class RemoveFail implements Action {
  readonly type = PartnerLinkParticipationActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = PartnerLinkParticipationActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = PartnerLinkParticipationActionTypes.LoadAllSuccess;

  constructor(public partners: PartnerLinkParticipation[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = PartnerLinkParticipationActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export type PartnerLinkParticipationActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail;
