import {Component, Inject, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dvtx-tooltip-like-dialog',
  templateUrl: './tooltip-like-dialog.component.html',
  styleUrls: ['./tooltip-like-dialog.component.scss']
})
export class TooltipLikeDialogComponent {
  constructor( @Inject(MAT_DIALOG_DATA) public data ) {}
}
