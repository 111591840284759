import {throwError as observableThrowError} from 'rxjs';

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';

import {catchError} from "rxjs/operators";

@Injectable()
export class TrialPopupService {
  constructor(private tokenService: HttpClient, private http: HttpClient
  ) {}

  public endTrialVersion() {
    return this.http.post(
      `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/sales/trials/show_popup`,
      {
        data: {
          attributes: {
            show_popup: false,
            basic_account: true
          }
        }
      }
    ).pipe(catchError((err) => {
      return observableThrowError(err);
    }));
  }

  public disablePopup() {
    return this.http.post(
      `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/sales/trials/show_popup`,
      {
        data: {
          attributes: {
            show_popup: true
          }
        }
      }
    ).pipe(catchError((err) => {
      return observableThrowError(err);
    }));
  }
}
