import {
  ConnectionPositionPair,
  OriginConnectionPosition,
  OverlayConnectionPosition,
  OverlayPositionBuilder,
  ScrollStrategy,
  ScrollStrategyOptions
} from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {CollectorCategory} from 'app/+store/collector-category/collector-category';
import {CollectorItem} from 'app/+store/collector-item/collector-item';
import {QuickCollector} from 'app/+store/quickcollector/quickcollector';
import {AppState} from 'app/reducers';
import {Subject} from 'rxjs';

@Component({
  selector: 'dvtx-global-action-menu',
  templateUrl: './global-action-menu.component.html',
  styleUrls: ['./global-action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalActionMenuComponent implements OnInit, OnDestroy {
  protected onDestroy = new Subject();
  _showDropdown = false;
  @ViewChild('dropdownOpener', { static: true }) dropdownOpener: ElementRef<HTMLElement>;

  private originPos: OriginConnectionPosition = {
    originX: 'end',
    originY: 'top'
  };
  private overlayPos: OverlayConnectionPosition = {
    overlayX: 'end',
    overlayY: 'top'
  };

  public scrollStrategy: ScrollStrategy;
  private position: ConnectionPositionPair = new ConnectionPositionPair(
    this.originPos,
    this.overlayPos,
    0,
    0
  );

  public positions: ConnectionPositionPair[] = [this.position];
  cdkOverlayClass = '';
  mainContainer;

  @Input() openerIcon: string;
  @Input() collector: QuickCollector;
  @Input() group: CollectorCategory;
  @Input() items: CollectorItem[];

  constructor(
    private _store: Store<AppState>,
    private _dialog: MatDialog,
    sso: ScrollStrategyOptions,
    opb: OverlayPositionBuilder,
    private _ngZone: NgZone,
    private _cdr: ChangeDetectorRef
  ) {
    this._ngZone.runOutsideAngular(() => {
      this.scrollStrategy = sso.block();
    })
  }

  ngOnInit(): void {
    this.mainContainer = document.querySelector('#dvtx-app-content');
  }

  ngOnDestroy() {
    this.mainContainer && this.mainContainer.classList.remove('prevent-scrolling');
    this._showDropdown = false;
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public close() {
    this._showDropdown = false;
    this._cdr.detectChanges();
  }

  public showDropdown() {
    this._showDropdown = !this._showDropdown;
    if (!this._showDropdown) return;
    this.cdkOverlayClass = 'cdk-overlay-transparent-backdrop';
    setTimeout(() => this.calculatePosition(), 0);
  }

  calculatePosition() {
    const dropdownOpener = this.dropdownOpener.nativeElement;
    const dropdownOpenerCoord = dropdownOpener.getBoundingClientRect();

    let dropdown = document.querySelector<HTMLElement>('.cdk-dropdown-menu');
    const backdrop = document.querySelector<HTMLElement>('.cdk-overlay-backdrop');

    if (dropdown === null) return;
    this.mainContainer && this.mainContainer.classList.add('prevent-scrolling')
    backdrop.addEventListener('click', () => this.mainContainer && this.mainContainer.classList.remove('prevent-scrolling'));
    const gap = 20;
    dropdown = dropdown.parentElement;
    const dropdownCoord = dropdown.getBoundingClientRect();
    const openerIcon = dropdownOpener.querySelector('.group-options-icon');
    const openerIconCoord = openerIcon.getBoundingClientRect();

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;


    let expectedLeft;
    if (dropdownCoord.right > windowWidth) {
      expectedLeft = dropdownCoord.right - windowWidth + 20;
      expectedLeft = dropdownCoord.left - expectedLeft;
      dropdown.style.left = `${expectedLeft}px`;
    } else {
      expectedLeft = dropdownCoord.left;
    }

    if ((dropdownCoord.bottom + dropdownOpener.offsetHeight) > windowHeight) {
      let expectedHeight = dropdownCoord.height - (dropdownCoord.bottom - windowHeight);
      expectedHeight = expectedHeight - dropdownOpenerCoord.height - gap;
      dropdown.style.top = `${dropdownOpenerCoord.top - dropdownCoord.height - 10}px`;
      dropdown.classList.add('show-on-top');
    } else {
      let expectedHeight = windowHeight - dropdownCoord.top;
      expectedHeight = expectedHeight - dropdownOpenerCoord.height - gap;
      dropdown.style.maxHeight = `${expectedHeight}px`;
      dropdown.style.top = `${dropdownOpenerCoord.top + dropdownOpenerCoord.height + 10}px`;
      dropdown.classList.remove('show-on-top');
    }
    const arrowPosition = (openerIconCoord.width / 2 - 5) + openerIconCoord.left - expectedLeft;
    document.documentElement.style.setProperty('--dropdown-arrow-left-position', `${arrowPosition}px`);
  }

  closeDropdown() {
    this._showDropdown = false
    this._cdr.detectChanges();
  }
}
