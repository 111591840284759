 <div *ngIf="form">
    <div class="row">
      <div class="col-12 mb-2 d-flex justify-content-between upload-docs-btns" *ngIf="dmsPathChooser">
        <div class="d-flex align-items-center upload-docs-new-path">
          <button mat-raised-button color="{{ pathSelectorButtonColor() }}" class="wide"
                  dvtxDmsFolderPickerSelector
                  [form]="form" [dmsFolderId]="'dms_folder_id'" [dmsPath]="'dms_path'" [dmsAccountType]="'dms_account_type'">
            {{ 'UPLOAD.CHOOSE_FOLDER' | translate }}
          </button>
          <p *ngIf="form.get('dms_path').value">
            <mat-icon aria-label="varified" class="status-color-success">check</mat-icon>
            <span>{{ form.get('dms_path').value }}</span>
          </p>
        </div>
        <!-- Currently disabled in favor of submit button in toolbar -->
        <!--<div>-->
          <!--<button mat-raised-button-->
                  <!--color="primary"-->
                  <!--class="wide"-->
                  <!--(click)="onSubmit.emit()"-->
                  <!--[disabled]="!uploader.getNotUploadedItems().length || getInvalidFiles().length > 0 || !form.valid">-->
            <!--DOKUMENTE HOCHLADEN-->
          <!--</button>-->
        <!--</div>-->
      </div>

      <!-- External availability and optional password -->
      <div class="col-12 mb-2" *ngIf="dmsPathChooser" [formGroup]="form">

        <!-- <div class="d-flex align-items-center upload-docs-new-path">
          <mat-slide-toggle formControlName="externally_available">
            {{ 'DMS.PUBLISH_DOCUMENT_DESC' | translate }}
          </mat-slide-toggle>
        </div> -->
        <br />
        <mat-card-content class="d-flex justify-content-between">
          <mat-form-field *ngIf="form.get('externally_available').value" class="mr-5">
            <input matInput type="password"
                   autocomplete="new-password"
                   [placeholder]="'DMS.SET_OPTIONAL_PASSWORD' | translate"
                   formControlName="password" />
            <mat-error *ngIf="this.form.get('password').hasError('passwordLength')">
              {{ 'PASSWORD.PASSWORD_TOO_SHORT' | translate }} (< 6)
            </mat-error>
          </mat-form-field>

          <dvtx-datepicker-cva
            formControlName="documents_externally_available_until"
            [placeholder]="'GENERAL.PUBLISHED_UNTIL' | translate"
            *ngIf="form.get('externally_available').value">
          </dvtx-datepicker-cva>

        </mat-card-content>
      </div>

      <div class="col-12 mb-3">
        <div class="drag-n-drop"
             ng2FileDrop
             [uploader]="uploader"
             [ngClass]="{'file-over': hasBaseDropZoneOver}"
             (fileOver)="fileOverBase($event)">
          <div class="drag-n-drop__icon">
            <mat-icon aria-label="upload">file_upload</mat-icon>
          </div>
          <div class="drag-n-drop__button">
            <!-- For reference of new styles -->
            <!--<button mat-button color="gray">-->
              <!--Dateien hochladen oder hierherziehen-->
            <!--</button>-->

            <label class="file-upload-label btn-outline waves-effect" for="files-uploader">
              <input type="file"
                     class="outside"
                     ng2FileSelect
                     [uploader]="uploader"
                     multiple
                     id="files-uploader"/>{{ 'UPLOAD.PLACEHOLDER_UPLOAD_BUTTON' | translate }}</label>


            <p>{{ 'UPLOAD.PLACEHOLDER_UPLOAD_INFORMATION' | translate }}
              <br />
              <mat-icon [matTooltip]="'Unterstützte Dateiformate: ' + supportedFileTypes?.join(', ')">info</mat-icon>
            </p>
          </div>
        </div>
      </div>
      <ul class="col-12">
        <li class="import-error" *ngFor="let file of getInvalidFiles()">
          <mat-icon aria-label="import error" class="import-error-sign">priority_high</mat-icon>
          <p class="text-mutted">{{ 'UPLOAD.FILE_COULD_NOT_BE_UPLOAD' | translate }}:</p>
          <p><strong>{{ file.name }}<small>({{ file.reason }})</small></strong></p>
        </li>
      </ul>
    </div>
    <dvtx-file-listing [form]="form" [uploader]="uploader" (onRemove)="onRemove.emit($event)"></dvtx-file-listing>
  </div>
