import {Component, Input, OnInit, EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {ICON_TYPE_MAPPING} from "../../../../../../+store/comment/comment.interface";
declare var $: any;
@Component({
  selector: 'dvtx-comment-reaction-item',
  templateUrl: './comment-reaction-item.component.html',
  styleUrls: ['./comment-reaction-item.component.scss']
})
export class CommentReactionItemComponent implements OnInit {
  public showDropdown = false;
  public keepOpen = false;

  @Input() reaction;
  @Input() icon;
  @Input() showAvatarTitle = true
  @Output() onReaction = new EventEmitter()

  constructor(public avatarService: AvatarService,private _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  toggleKeepOpen($event) {
    this.keepOpen = $event;
  }

  closeShowDropdown($event) {
    setTimeout(() => {
      this.showDropdown = $event;
      this._cdr.detectChanges();
    }, 100);
  }

  openShowDropdown($event) {
      this.showDropdown = $event;
  }

  react(type = null) {
    this.showDropdown = false;
    this.keepOpen = false;
    this._cdr.detectChanges(); 
    let _type = type;
    if (!_type) {
      _type = ICON_TYPE_MAPPING[this.icon];
    }
    this.onReaction.emit(_type);
  }
}
