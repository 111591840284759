import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TaskStatusSchemeEffects} from './task-status-scheme.effects';
import {TaskStatusSchemeService} from './task-status-scheme.service';
import {reducer} from './task-status-scheme.reducer';
import {stateKey} from './task-status-scheme.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([TaskStatusSchemeEffects])
  ],
  providers: [
    TaskStatusSchemeEffects,
    TaskStatusSchemeService
  ]
})
export class TaskStatusSchemeStoreModule {
}
