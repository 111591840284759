import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './user.state';

export const stateKey = 'tenant-user';
const getUserState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getUserEntities,
  selectAll: getAllUsers,
} = adapter.getSelectors(getUserState);

export const loadingState = createSelector(
  getUserState,
  (_state) => _state.loading
);

export const getUserById = (id: string) => createSelector(
  getUserEntities,
  (users) => users[id]
);

export const getSelectedUser = createSelector(
  getUserState,
  (state) => state.selected
);
