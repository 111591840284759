import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './dms-document-export.state';
import {ProcessSelectors} from '../process';

export const stateKey = 'dms-document-export';
const getDmsDocumentExportState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getDmsDocumentExportEntities,
  selectAll: getAllDmsDocumentExports,
} = adapter.getSelectors(getDmsDocumentExportState);

export const getSelected = createSelector(
  getDmsDocumentExportState,
  (state) => state.selected
)

export const getOne = (id: string) => createSelector(
  getDmsDocumentExportEntities,
  (entities) => entities[id]
);

export const getAllOfSelectedProcess = createSelector(
  ProcessSelectors.getSelectedProcess,
  getAllDmsDocumentExports,
  (process, exports) => {
    if (!process || !process.id) {
      return [];
    }
    return exports.filter(fileExport => fileExport.processId === process.id)
  }
);

export const loadingState = createSelector(
  getDmsDocumentExportState,
  (state) => state.loading
)
