import {Action} from '@ngrx/store';
import {DmsDocument} from './dms-document';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';

export enum DmsDocumentActionTypes {
  LoadAll = '[DmsDocument] Load All',
  LoadAllSuccess = '[DmsDocument] Load All Success',
  LoadAllFail = '[DmsDocument] Load All Fail',
  LoadOne = '[DmsDocument] Load One',
  LoadOneSuccess = '[DmsDocument] Load One Success',
  LoadOneFail = '[DmsDocument] Load One Fail',
  LoadAllOfProcessFolder = '[DmsDocument] Load All Of Process Folder',
  LoadAllOfProcessFolderSuccess = '[DmsDocument] Load All Of Process Folder Success',
  LoadAllOfProcessFolderFail = '[DmsDocument] Load All Of Process Folder Fail',
  Remove = '[DmsDocument] Remove',
  RemoveSuccess = '[DmsDocument] Remove Success',
  RemoveFail = '[DmsDocument] Remove Fail',
  Destroy = '[DmsDocument] Destroy',
  DestroySuccess = '[DmsDocument] Destroy Success',
  DestroyFail = '[DmsDocument] Destroy Fail',
  Reset = '[DmsDocument] Reset',
  Rename = '[DmsDocument] Rename'
}

export class Reset implements Action {
  readonly type = DmsDocumentActionTypes.Reset;

  constructor(public payload: any = null) {
  }
}

export class LoadAll implements Action {
  readonly type = DmsDocumentActionTypes.LoadAll;

  constructor(public folderId: string = null, public accountType: DmsAccountType = DmsAccountType.Private) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = DmsDocumentActionTypes.LoadAllSuccess;

  constructor(public documents: DmsDocument[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = DmsDocumentActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadAllOfProcessFolder implements Action {
  readonly type = DmsDocumentActionTypes.LoadAllOfProcessFolder;

  constructor(public processId: string = null) {
  }
}

export class LoadAllOfProcessFolderSuccess implements Action {
  readonly type = DmsDocumentActionTypes.LoadAllOfProcessFolderSuccess;

  constructor(public documents: DmsDocument[]) {
  }
}

export class LoadAllOfProcessFolderFail implements Action {
  readonly type = DmsDocumentActionTypes.LoadAllOfProcessFolderFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = DmsDocumentActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = DmsDocumentActionTypes.LoadOneSuccess;

  constructor(public document: DmsDocument) {
  }
}

export class LoadOneFail implements Action {
  readonly type = DmsDocumentActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = DmsDocumentActionTypes.Remove;

  constructor(public processId: string, public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = DmsDocumentActionTypes.RemoveSuccess;

  constructor(public id: string) {
  }
}

export class RemoveFail implements Action {
  readonly type = DmsDocumentActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Destroy implements Action {
  readonly type = DmsDocumentActionTypes.Destroy;

  constructor(public id: string, public accountType: DmsAccountType) {
  }
}

export class DestroySuccess implements Action {
  readonly type = DmsDocumentActionTypes.DestroySuccess;

  constructor(public id: string) {
  }
}

export class DestroyFail implements Action {
  readonly type = DmsDocumentActionTypes.DestroyFail;

  constructor(public payload: any) {
  }
}
export class Rename implements Action {
  readonly type = DmsDocumentActionTypes.Rename;

  constructor(public id: string, public fileName: string) {
  }
}

export type DmsDocumentActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadAllOfProcessFolder
  | LoadAllOfProcessFolderSuccess
  | LoadAllOfProcessFolderFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Destroy
  | DestroySuccess
  | DestroyFail
  | Reset
  | Rename;
