<form class="example-form">
  <mat-form-field class="example-full-width">
    <input type="text" [placeholder]="'GENERAL.EMAIL_OR_NAME' | translate"
           matInput [formControl]="myControl"
           (keyup.enter)="onKeyEnter()"
           [matAutocomplete]="auto" (blur)="onBlurEventHandler()">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="contactSelected($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        <!-- <div class="account--indicator">
          <mat-icon *ngIf="option.hasAccount"
                    class="account--indicator-icon"
                    style="font-size: 16px;">check_circle_outline</mat-icon>
        </div>
        <mat-icon>{{ option.type == 'Membership' || option.type == 'workflow_engine_process_participants' ? 'person' : 'person_outline' }}</mat-icon>
        <em>{{option.name}}</em>
        <ng-container *ngIf="valueIsEmail && option.email">
          ({{option.email}})
        </ng-container> -->

        <dvtx-avatar [size]="'xs'" [email]="option" [isVerified]="option?.hasAccount" [showTitle]="true" [showEmail]="true"></dvtx-avatar>

      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
