import {UserSignatureActions, UserSignatureActionTypes} from './user-signature.actions';
import {adapter, initialState, State} from './user-signature.state';

export function reducer(state = initialState, action: UserSignatureActions): State {
  switch (action.type) {
    case UserSignatureActionTypes.LoadAll:
    case UserSignatureActionTypes.Create:
    case UserSignatureActionTypes.Updated:
    case UserSignatureActionTypes.Delete:
      return {
        ...state,
        loading: true
      };

      case UserSignatureActionTypes.LoadAllSuccess:
      return adapter.setAll(action.signature, {
          ...state,
          loading: false,
      });

      case UserSignatureActionTypes.UpdatedSuccess:
      return adapter.upsertOne(action.signature , {
        ...state,
        loading: false,
      });

      case UserSignatureActionTypes.CreateSuccess:
      return adapter.addOne( action.signature , {
        ...state,
        loading: false,
      });

      case UserSignatureActionTypes.DeleteSuccess:
      return adapter.removeOne(action.signature , {
        ...state,
        loading: false,
      });

      case UserSignatureActionTypes.LoadAllFail:
      case UserSignatureActionTypes.CreateFail:
      case UserSignatureActionTypes.DeleteFail:
      case UserSignatureActionTypes.UpdatedFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}


