import {MarkSeenSuccess, ProcessArtifactActions, ProcessArtifactActionTypes} from './process-artifact.actions';
import {adapter, initialState, State} from './process-artifact.state';
import {Update} from '@ngrx/entity';
import {ProcessArtifact} from './process-artifact';

export function reducer(state = initialState, action: ProcessArtifactActions): State {
  switch (action.type) {
    case ProcessArtifactActionTypes.UpdateAllSuccess:
      return adapter.updateMany(action.payload, {
        ...state,
        loading: false,
      });
    case ProcessArtifactActionTypes.LoadAll:
    return {
      ...state,
      loading: action.loading
    };
    case ProcessArtifactActionTypes.MarkSeenSuccess:
    case ProcessArtifactActionTypes.MarkDownloaded:
      const artifact = state.entities[action.id];
      if (artifact) {
        const downloadedArtifact = Object.assign({}, artifact) as ProcessArtifact;
        downloadedArtifact.newUpload = false;
        return adapter.upsertOne(downloadedArtifact, {
          ...state,
          loading: false,
        });
      }
      return {
        ...state
      };
    case ProcessArtifactActionTypes.LoadOne:
    case ProcessArtifactActionTypes.LoadAllExternal:
    return {
      ...state,
      loading: true
    };
    case ProcessArtifactActionTypes.Delete:
      return {
        ...state,
        loading: false
      };
    case ProcessArtifactActionTypes.PickFileFromDms:
    case ProcessArtifactActionTypes.CollectorPickFileFromDms:
      return {
        ...state,
        transfering: true,
        loading: true
      };
    case ProcessArtifactActionTypes.LoadAllSuccess:
      if (action.upsertEntities) {
        const artifacts: Update<ProcessArtifact>[] = [];
        for (const item of action.payload) {
          artifacts.push({ id: item.id, changes: item });
        }
        return adapter.updateMany(artifacts, {
          ...state,
          loading: false,
        });
      } else {
        return adapter.setAll(action.payload, {
          ...state,
          loading: false,
        });
      }
    case ProcessArtifactActionTypes.MoveAllSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case ProcessArtifactActionTypes.LoadAllExternalSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case ProcessArtifactActionTypes.UpdateOneSuccess:
      return adapter.upsertOne(action.artifact, {
        ...state
      });
    case ProcessArtifactActionTypes.DeleteSuccess:
      return adapter.removeOne(action.id, {
        ...state,
        loading: false,
      });
    case ProcessArtifactActionTypes.LoadOneSuccess:
    case ProcessArtifactActionTypes.LoadOneByIdSuccess:
      return adapter.upsertOne(action.artifact, {
        ...state,
        loading: false,
      });
    case ProcessArtifactActionTypes.PickFileFromDmsSuccess:
    case ProcessArtifactActionTypes.CollectorPickFileFromDmsSuccess:
      return action.artifact , {
        ...state,
        transfering: false,
        loading: false,
      };
    case ProcessArtifactActionTypes.LoadAllFail:
    case ProcessArtifactActionTypes.DeleteFail:
    case ProcessArtifactActionTypes.LoadOneFail:
    case ProcessArtifactActionTypes.PickFileFromDmsFail:
    case ProcessArtifactActionTypes.CollectorPickFileFromDmsFail:
    case ProcessArtifactActionTypes.LoadAllExternalFail:
      return {
        ...state,
        transfering: false,
        loading: false
      };

    case ProcessArtifactActionTypes.Reset:
      return Object.assign({}, initialState);
  }
  return state;
}
