import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {LicenceEffects} from './licence.effects';
import {reducer} from './licence.reducer';
import {stateKey} from './licence.selectors';
import {ShoppingCartService} from './shopping-cart.service';
import {PaymentOptionService} from './payment-option.service';
import {ProductService} from './product.service';
import {SalesOrderService} from './sales-order.service';
import {InvoiceAddressService} from './invoice-address.service'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([LicenceEffects])
  ],
  providers: [
    LicenceEffects,
    ShoppingCartService,
    PaymentOptionService,
    ProductService,
    SalesOrderService,
    InvoiceAddressService
  ]
})
export class LicenceStoreModule {
}
