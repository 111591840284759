import * as dayjs from 'dayjs';

export namespace Sorters {
  export function sort(left, right, isAsc = true) {
    if (left === right) return 0;

    if (!!left && (right == null || right === undefined) && isAsc) return isAsc ? -1 : 1;

    if ((left === null || left === undefined) && !!right && isAsc) return isAsc ? 1 : -1;

    return (left < right ? -1 : 1) * (isAsc ? 1 : -1);
  }

  export function sortByDates(l, r, isAsc = true) {
    return dayjs(l).diff(dayjs(r)) * (isAsc ? 1 : -1);
  }
}
