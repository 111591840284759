import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, State } from './item-labels.state';
import { ItemType } from './item-labels.interface';

export const stateKey = 'item-labels';
const getItemLabelsState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getItemLabelsEntities,
  selectAll: getAllItemLabels,
} = adapter.getSelectors(getItemLabelsState);

export const getSelected = createSelector(
  getItemLabelsState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getItemLabelsEntities,
  (entities) => entities[id]
);

export const getAll = () => createSelector(
  getAllItemLabels,
  (entities) => entities
);

export const getAllByLabels = (ids: string[]) => createSelector(
  getAllItemLabels,
  (entities) => entities.filter(item => item.labelIds.some(labelId => ids.indexOf(labelId) > -1))
);

export const getAllTasks = () => createSelector(
  getAllItemLabels,
  (entities) => entities.filter(item => item.itemType === ItemType.TASK_ITEM)
);

export const getAllTasksByLabelIds = (labelIds: string[]) => createSelector(
  getAllTasks(),
  (entities) => entities.filter(item => item.labelIds.some(id => !!labelIds.find(labelId => labelId === id)))
);

export const getAllCollectorItems = () => createSelector(
  getAllItemLabels,
  (entities) => entities.filter(item => item.itemType === ItemType.COLLECTOR_ITEM)
);

export const getAllCollectorItemsByLabelIds = (labelIds: string[]) => createSelector(
  getAllCollectorItems(),
  (entities) => entities.filter(item => item.labelIds.some(id => !!labelIds.find(labelId => labelId === id)))
);

export const getAllDocuments = () => createSelector(
  getAllItemLabels,
  (entities) => entities.filter(item => item.itemType === ItemType.DOCUMENT_ITEM)
);

export const getAllDocumentsByLabelIds = (labelIds: string[]) => createSelector(
  getAllDocuments(),
  (entities) => entities.filter(item => item.labelIds.some(id => !!labelIds.find(labelId => labelId === id)))
);


export const getByItemId = (itemId) => createSelector(
  getAll(),
  (entities) => {
    if (!itemId) return null;
    return entities.find(item => item.itemId === itemId);
  }
);

export const loadingState = createSelector(
  getItemLabelsState,
  (state) => state.loading
);
