<form class="row">
  <div class="col-12 d-inline-flex align-items-center">
    <!-- <mat-icon class="mr-3">add_circle_outline</mat-icon> -->
    <mat-form-field>
      <input type="text" [placeholder]="'QUICKSHARE.CREATE_FORM.EMAIL_ADDRESS' | translate"
             aria-label="Number" matInput [formControl]="myControl"
             (keyup.enter)="onKeyEnter()"
             [matAutocomplete]="auto" (blur)="onBlurEventHandler()"
             #inputRef>
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="openConfirmationSelectPerson($event)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          <dvtx-avatar [size]="'xs'" [email]="option" [isVerified]="option?.hasAccount" [showTitle]="true"></dvtx-avatar>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button [disabled]="!isInviteeEmailValid()"
             mat-raised-button color="primary"
            class="ml-3"
            (click)="onKeyEnter()">
      {{'GENERAL.ADD_ACTION' | translate}}
    </button>
  </div>
</form>
