import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {OrganizationSelectors, PageSidebarSelectors} from 'app/+store';
import {PageSidebar} from 'app/+store/page-sidebar/page-sidebar';
import {AppState} from 'app/reducers';
import {Observable} from 'rxjs/internal/Observable';

export class SidebarRoute {
  constructor(public title: string,
              public icon: string,
              public path: string,
              public count: number = null,
              public infoLevel = '',
              public callback = null) {
  }
}

@Component({
  selector: 'dvtx-sidebar-navigation',
  templateUrl: './sidebar-navigation.component.html',
  styleUrls: ['./sidebar-navigation.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarNavigationComponent {
  @Input() navigationWidth = false;
  @Input() title = '';
  @Input() routes: SidebarRoute[];
  @Input() process;
  @Input() hasAccessibleParent;
  @Input() tasksRouteCount;
  @Input() isInboxMessages = false;
  @Input() classList = '';
  @Input() showOnlyIcons = false;
  @Input() sidebarNavigationClassStyle = 'dvtx-sidebar-navigation-right'

  sidebar$: Observable<PageSidebar>;

  private _collapsed: boolean = false;
  private organization$: Observable<string>;

  @Input() set collapsed(collapsed: boolean) {
    this._collapsed = collapsed;
  }

  constructor(private _store: Store<AppState>,
              private _cdr: ChangeDetectorRef,
              private _dialog: MatDialog) {
    this.sidebar$ = this._store.select(PageSidebarSelectors.getSidebar);
    this.organization$ = this._store.select(OrganizationSelectors.getSelectedId);
  }
}
