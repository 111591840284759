import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './partner-link.state';
import {OrganizationSelectors} from 'app/+store/organization';
import {PartnerLinkVisibility} from './partner-link.interface';

export const stateKey = 'partner-link';
const getPartnerLinkState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getPartnerLinkEntities,
  selectAll: getAllPartnerLinks,
} = adapter.getSelectors(getPartnerLinkState);

export const getPartnerLinksOfSelectedOrg = createSelector(
  OrganizationSelectors.getSelectedId,
  getAllPartnerLinks,
  (orgId, partnerLinks) => partnerLinks.filter(c => orgId && c.organizationId === orgId)
);

export const getVisiblePartnerLinks = createSelector(
  getAllPartnerLinks,
  (partnerLinks) => partnerLinks.filter(partnerLink => partnerLink.publishedAt && (partnerLink.visibility === PartnerLinkVisibility.Incoming || partnerLink.visibility === PartnerLinkVisibility.Visible))
);

export const getAllPublishedPartnerLinks = createSelector(
  getAllPartnerLinks,
  (partnerLinks) => partnerLinks.filter(partnerLink => partnerLink.publishedAt)
);

export const loadingState = createSelector(
  getPartnerLinkState,
  (state) => state.loading
);
