import {Action} from '@ngrx/store';

export enum EmailToNameActionTypes {
  LoadAllSuccess = '[EmailToName] Load All Success',
}
export class LoadAllSuccess implements Action {
  readonly type = EmailToNameActionTypes.LoadAllSuccess;
  constructor(public payload: any) {
  }
}

export type EmailToNameActions =
  | LoadAllSuccess
