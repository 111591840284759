import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './collector-category.state';
import {CollectorItemSelectors} from '../collector-item';

export const stateKey = 'collector-category';
const getCollectorCategoryState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getCollectorCategoryEntities,
  selectAll: getAllCollectorCategories,
} = adapter.getSelectors(getCollectorCategoryState);

export const getSelected = createSelector(
  getCollectorCategoryState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getCollectorCategoryEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllCollectorCategories,
  (categories) => categories
);

const categoryProcessMap = createSelector(
  getAllCollectorCategories,
  (categories) => {
    const categoryMap = {};
    categories.forEach(c => {
      if (!categoryMap[c.processId]) {
        categoryMap[c.processId] = [];
      }
      categoryMap[c.processId].push(c);
    });
    return categoryMap;
  }
);

export const getCategoriesByCollectorId = (id: string) => createSelector(
  categoryProcessMap,
  (categoryMap) => {
    if (!id) {
      return [];
    }
    return categoryMap[id] || [];
  }
);

export const getCategoriesWithItemsByCollectorId = (id: string) => createSelector(
  getCategoriesByCollectorId(id),
  CollectorItemSelectors.getAllCollectorItems,
  (categories, items) => {
        // Group by reference id
    const groupedItems = items.reduce((acc, item) => {
      (acc[item['categoryId']] = acc[item['categoryId']] || []).push(item);
      return acc;
    }, {});
    const groups = [];
    categories.forEach(category => {
      const categoryItems = groupedItems[category.id];
      if (categoryItems) {
        groups.push({
          category,
          items: categoryItems
        })
      }
    })
    return groups;
  }
);

export const loadingState = createSelector(
  getCollectorCategoryState,
  (state) => state.loading
);
