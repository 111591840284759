import {IQuickshareAttachment} from './quickshare-attachment.interface';

export class QuickshareAttachment implements IQuickshareAttachment {
  readonly type = 'quickshare_attachments';
  public color: string;

  constructor(public id: string,
              public resourceId: string,
              public title: string,
              public filename: string,
              public description: string,
              public uploadedBy: string,
              public version: number,
              public fingerprint: string,
              public size: number,
              public mimeType: string,
              public createdAt: Date,
              public updatedAt: Date,
              public content: string = null) {}
}
