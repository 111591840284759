import {catchError, filter, first, map, switchMap, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  Add,
  AddSuccess,
  LicenceAssignmentActionTypes,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadAttachedOrganizations,
  LoadAttachedOrganizationsFail,
  LoadAttachedOrganizationsSuccess,
  Remove,
  RemoveSuccess
} from './licence-assignment.actions';
import * as organizationActions from 'app/+store/organization/organization.actions';
import {LicenceAssignmentService} from 'app/shared/modules/api/services/licence-assignment.service';
import {LicenceAssignment} from 'app/shared/modules/api/models/licence';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {LicenceAssignmentActions} from "./index";

@Injectable()
export class LicenceAssignmentEffects {
  @Effect()
  $loadAll = this._actions.pipe(
    ofType(LicenceAssignmentActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        map((res: LicenceAssignment[]) => {
            const activities: LicenceAssignment[] = [];
            for (const item of res) {
              item.attributes = {
                type: item.attributes.type,
                title: item.attributes.title,
                price: item.attributes.price,
                additionalMemberCost: item.attributes.additional_member_cost,
                userLicencesCount: item.attributes.user_licences_count,
                availableUserLicences: item.attributes.available_user_licences_count,
                licencedMemberships: item.attributes.licenced_memberships,
                organizationsLicencesCount: item.attributes.organizations_licences_count,
                paymentInterval: item.attributes.payment_method,
                createdAt: item.attributes.created_at,
                validUntil: item.attributes.valid_until,
                membershipId: item.attributes.membership_id
              };
              activities.push(item);
            }
            return new LoadAllSuccess(activities);
          }
        ),
        catchError((err) => {
          console.error(err);
          return of(new LoadAllFail(err))
        }));
    })
  );

  @Effect()
  $loadAttachedOrganizations = this._actions.pipe(
    ofType(LicenceAssignmentActionTypes.LoadAttachedOrganizations),
    switchMap((action: LoadAttachedOrganizations) => {
      return this._svc.getAttachedOrganizations().pipe(
        map((organizations: any) => {
            return new LoadAttachedOrganizationsSuccess(organizations)
          }
        ),
        catchError((err) => {
          console.error(err);
          return of(new LoadAttachedOrganizationsFail(err))
        }));
    })
  );

  @Effect()
  $add = this._actions.pipe(
    ofType(LicenceAssignmentActionTypes.Add),
    switchMap((action: Add) => {
      return this._svc.create(action.payload).pipe(
        map(result => new AddSuccess(result)),
        catchError(error => {
          console.error(error);
          return of(new LoadAllFail());
        })
      )
    })
  );

  @Effect()
  $destroy = this._actions.pipe(
    ofType(LicenceAssignmentActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.destroy(action.membershipId).pipe(
        switchMap(result => {
          if (result) {
            return [
              new RemoveSuccess(result.id),
              new organizationActions.LoadAll(),
              new LicenceAssignmentActions.LoadAll(action.organizationId),
              new LicenceAssignmentActions.LoadAttachedOrganizations
            ];
          }
          return [new RemoveSuccess(null), new organizationActions.LoadAll()];
        }),
        catchError(error => {
          console.error(error);
          return of(new LoadAllFail());
        })
      )
    })
  );

  @Effect()
  $addSuccess = this._actions.pipe(
    ofType(LicenceAssignmentActionTypes.AddSuccess),
    tap((_: AddSuccess) => {
          this._notificationService.success('LICENCE.LICENCE_ASSIGNMENTS_UPDATED');
      }
    ),
    filter(() => false)
  );

  @Effect()
  $removeSuccess = this._actions.pipe(
    ofType(LicenceAssignmentActionTypes.RemoveSuccess),
    tap((_: RemoveSuccess) => {
      // setTimeout(__ => {
      // this._notificationService.success('LICENCE.LICENCE_ASSIGNMENT_REMOVED')
      // }, 3000);
    }),
    filter(() => false)
  );

  constructor(private _actions: Actions,
              private _svc: LicenceAssignmentService,
              private _notificationService: NotificationService,
              private _translateSvc: TranslateService) {
  }
}
