<div class="mat-dialog-inner-wrap">
  <div class="mat-dialog-header">
    <h2 mat-dialog-title>{{ 'GENERAL.INVITATIONS' | translate }}</h2>
    <button class="mat-dialog-close" mat-mini-fab color="warn" (click)="onClose()">
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>

  <div mat-dialog-content *ngIf="(invitations | async).length > 0">
    <h3>{{ 'INVITATIONS.YOU_HAVE_PENDING_INVITATIONS' | translate:{ invitations: (invitations | async).length } }}</h3>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-end">
    <button (click)="onClose()" type="reset" mat-button>{{ 'GENERAL.CLOSE_ACTION' | translate }}</button>
    <button (click)="goToInvitations()" mat-raised-button color="primary">{{ 'INBOX.INVITATIONS' | translate }}</button>
  </div>
</div>
