import {ProjectStatisticsActions, ProjectStatisticsActionTypes} from './project-statistics.actions';
import {adapter, initialState, State} from './project-statistics.state';

export function reducer(state = initialState, action: ProjectStatisticsActions): State {
  switch (action.type) {
    case ProjectStatisticsActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case ProjectStatisticsActionTypes.LoadAllSuccess:
      return adapter.setAll([action.statistics], {
          ...state,
          loading: false,
      });
    case ProjectStatisticsActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}
