import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClientRoute} from 'app/shared/modules/page-container/tab-nav-bar/tab-nav-bar.component';
import {Organization} from 'app/models/organization.model';
import {OrganizationSelectors} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';

/**
 * Shows the usage terms of the 5F platform.
 * This view is only referenced for licensed employees.
 */
@Component({
  selector: 'dvtx-usage-terms-container',
  templateUrl: './usage-terms-container.component.html',
  styleUrls: ['./usage-terms-container.component.scss']
})
export class UsageTermsContainerComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();
  public routes: ClientRoute[];
  public activeLink: string;
  public organization: Observable<Organization>;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.organization = this.store.select(OrganizationSelectors.getSelected);
    this.routes = [{
      title: 'REGISTRATION.DATA_PRIVACY',
      route: `/user-profile/privacy/data-privacy`
    }, {
      title: 'REGISTRATION.TERMS_OF_USE_TAB_TITLE',
      route: `/user-profile/privacy/terms`
    }];
    this.activeLink = this.routes[1].title;
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
