import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {
  BasicAccountRequest,
  BasicAccountRequestBuilder,
  SalesUser,
  SalesUserBuilder
} from './basic-account-request.builder';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {FileUtils} from '../../lib/file_utils';
import {environment} from 'environments/environment'
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {UserExportType} from './sales.interface';
import * as model from '../operator/user/user';

@Injectable()
export class SalesService {
  readonly BASE_PATH = 'api/v1/sales';

  constructor(private _http: ApiResourceService,
              private _httpClient: HttpClient,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {}

  requestBasicAccount(inviterName: string, message: string): Observable<BasicAccountRequest> {
    const builder = new BasicAccountRequestBuilder();
    const request = new BasicAccountRequest(null, inviterName, message, null, null);
    const payload = builder.toRequest(request);
    return <Observable<BasicAccountRequest>>this._http.post<BasicAccountRequestBuilder, BasicAccountRequest>(builder, `${this.BASE_PATH}/request_basic_accounts`, payload);
  }

  users(showAll = false): Observable<SalesUser[]> {
    const builder = new SalesUserBuilder();
    const queryParam = showAll ? '?all=true' : '';
    return <Observable<SalesUser[]>>this._http.getAll<SalesUserBuilder, SalesUser>(builder, `operator/api/v1/user/accounts${queryParam}`);
  }

  grant(user_id, role, grant = true): Observable<SalesUser> {
    const builder = new SalesUserBuilder();
    const payload = {
      data: {
        attributes: {
          role: role
        }
      }
    };
    const action = grant ? 'grant' : 'revoke';
    return <Observable<SalesUser>>this._http.post<SalesUserBuilder, SalesUser>(builder, `operator/api/v1/user/accounts/${user_id}/${action}`, payload);
  }

  revoke(user_id, role): Observable<SalesUser> {
    return this.grant(user_id, role, false)
  }

  markTestAccount(user_id): Observable<SalesUser> {
    const builder = new SalesUserBuilder();
    return <Observable<SalesUser>>this._http.post<SalesUserBuilder, SalesUser>(builder, `operator/api/v1/user/accounts/${user_id}/test_account`, {});
  }

  changeAuthProvider(user_id, authProvider: model.Operator.UserAuthProviderType): Observable<SalesUser> {
    const builder = new SalesUserBuilder();
    const payload = { data: { attributes: { auth_provider: authProvider } } }
    return <Observable<SalesUser>>this._http.post<SalesUserBuilder, SalesUser>(builder, `operator/api/v1/user/accounts/${user_id}/auth_provider`, payload);
  }

  unMarkTestAccount(user_id): Observable<SalesUser> {
    const builder = new SalesUserBuilder();
    return <Observable<SalesUser>>this._http.del<SalesUserBuilder, SalesUser>(builder, `operator/api/v1/user/accounts/${user_id}/test_account` );
  }

  lockAccount(user_id): Observable<SalesUser> {
    const builder = new SalesUserBuilder();
    return <Observable<SalesUser>>this._http.post<SalesUserBuilder, SalesUser>(builder, `operator/api/v1/user/accounts/${user_id}/lock`, {});
  }

  unlockAccount(user_id): Observable<SalesUser> {
    const builder = new SalesUserBuilder();
    return <Observable<SalesUser>>this._http.del<SalesUserBuilder, SalesUser>(builder, `operator/api/v1/user/accounts/${user_id}/lock` );
  }

  userExport(filter: UserExportType = UserExportType.All) {
    const query = `?filter=${filter}`;
    return this._httpClient.get(`${environment.token_service_config.apiBase}/operator/api/v1/user/export/users${query}`).pipe(first()).subscribe(res => {
      const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      FileUtils.saveAsFile(res['data'].attributes.filename, res['data'].attributes.content, mimeType);
    }, error => {
      console.error(error);
      this._notifyService.error('HTTP_ERROR.DEFAULT');
    });
  }

  disableTwoFactorAuth(user_id): Observable<SalesUser> {
    const builder = new SalesUserBuilder();
    return <Observable<SalesUser>>this._http.del<SalesUserBuilder, SalesUser>(builder, `operator/api/v1/user/accounts/${user_id}/otp` );
  }
}
