import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';

@Component({
  selector: 'dvtx-confirmation-dialog-participant',
  templateUrl: './confirmation-dialog-participant.component.html',
  styleUrls: ['./confirmation-dialog-participant.component.scss']
})
export class ConfirmationDialogParticipantComponent {
  submitButtonTitle = 'GENERAL.CONFIRM_ACTION';
  cancelButtonTitle = 'GENERAL.CANCEL_ACTION';
  person: any;
  color: any = '#007aff';
  AddParticipant: boolean = false;
  remove: boolean = false;

  @ViewChild('recursiveCheckbox', { static: true }) recursiveCheckbox;
  recursive = true;

  @ViewChild('adminCheckbox', { static: true }) adminCheckbox;
  admin = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogParticipantComponent>,
    public avatarService: AvatarService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogParticipantData
  ) {
    if (this.data.submitButtonTitle) {
      this.submitButtonTitle = this.data.submitButtonTitle;
    }

    if (this.data.cancelButtonTitle) {
      this.cancelButtonTitle = this.data.cancelButtonTitle;
    }

    if (this.data.person) {
      this.person = this.data.person;
    }
    if (this.data.color) {
      this.color = this.data.color;
    }
    if (this.data.remove) {
      this.remove = true;
    }
  }

  onSubmitClick(): void {
    this.AddParticipant = true;
    this.data.onSubmitAction(this.recursive, this.admin);
    this.dialogRef.close();
  }

  onNoClick(): void {
    if (this.data.onCancelAction) {
      this.data.onCancelAction();
    }
    this.dialogRef.close();
  }

  recursiveChanged(event) {
    this.recursive = event.checked;
  }

  adminChanged(event) {
    this.admin = event.checked;
  }
}

export interface ConfirmationDialogParticipantData {
  remove: boolean;
  color: any;
  person: any;
  title: string;
  message: string;
  onSubmitAction: (recursive?: boolean, admin?: boolean) => void;
  onCancelAction?: () => void;
  submitButtonTitle?: string;
  cancelButtonTitle?: string;
  adminOption?: boolean;
  recursiveOption?: boolean;
}
