import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  CommentActionTypes,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Read,
  ReadFail,
  ReadSuccess,
  SendComment,
  SendCommentFail,
  SendCommentSuccess,
  LoadProcessStatistics,
  LoadProcessStatisticsFail,
  LoadProcessStatisticsSuccess,
} from './comment.actions';
import {CommentService} from './comment.service';
import {Comment, CommentProcessStatistics} from './comment';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class CommentEffects {
  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(CommentActionTypes.LoadAll),
    concatMap((action: LoadAll) => {
      const call = action.isArtifact ? this._svc.getAllArtifactComments(action.processId) :
      this._svc.getAllProcessComments(action.processId, action.recursive, action.resourceId);
      return call.pipe(
        first(),
        switchMap((comments: Comment[]) => {
          return [new LoadAllSuccess(comments)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  loadOne$ = this.actions.pipe(
    ofType(CommentActionTypes.LoadOne),
    concatMap((action: LoadOne) => {
      const call = action.isArtifact ? this._svc.getOneArtifactComment(action.processId, action.commentId) :
      this._svc.getOneProcessComment(action.processId, action.commentId);
      return call.pipe(
        first(),
        switchMap((comment: Comment) => {
          return [new LoadOneSuccess(comment)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  );

  @Effect()
  loadStats$ = this.actions.pipe(
    ofType(CommentActionTypes.LoadProcessStatistics),
    concatMap((action: LoadProcessStatistics) => {
      return this._svc.getCommentProcessStatistics(action.processId, action.recursive).pipe(
        first(),
        switchMap((stats: CommentProcessStatistics) => {
          return [new LoadProcessStatisticsSuccess(stats)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadProcessStatisticsFail(err));
        }));
    })
  );

  @Effect()
  sendComment$ = this.actions.pipe(
    ofType(CommentActionTypes.SendComment),
    concatMap((action: SendComment) => {
      return this._svc.createProcessComment(action.processId, action.comment).pipe(
        first(),
        switchMap((comment: Comment) => {
          return [new SendCommentSuccess(comment)];
        }),
        catchError(err => {
          console.error(err);
            this._notifyService.error('INBOX.GENERAL_SEND_ERROR');
          return of(new SendCommentFail(err));
        }));
    })
  );

  @Effect()
  readMessage$ = this.actions.pipe(
    ofType(CommentActionTypes.Read),
    concatMap((action: Read) => {
      return this._svc.markProcessCommentRead(action.comment.processId, action.comment.id).pipe(
        first(),
        concatMap((message: Comment) => {
          return [new ReadSuccess(message)];
        }),
        catchError(err => {
          console.error(err);
          return of(new ReadFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: CommentService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
