import { Component, EventEmitter, forwardRef, Injector, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable ,  combineLatest ,  Subject } from 'rxjs';
import { ContactListDto, contactListDtoType } from 'app/models/contact-list-dto.model';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { ContactSelectors } from 'app/+store/contact';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { LoadAll } from 'app/+store/contact/contact.actions';
import { OrganizationSelectors } from 'app/+store/organization';
import { DvtxControlValueAccessor } from 'app/shared/modules/base-form-elements/components/1_control-value-accessor-components/DvtxControlValueAccessor';
import { AngularTokenService } from 'angular-token';
import { EmailValidator } from 'app/lib/validator';
import { ConfirmationDialogParticipantComponent } from 'app/shared/components/dialogs/confirmation-dialog-participant/confirmation-dialog-participant.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'dvtx-quickshare-recipient-autocomplete',
  templateUrl: './recipient-autocomplete.component.html',
  styleUrls: ['./recipient-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecipientAutocompleteComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecipientAutocompleteComponent extends DvtxControlValueAccessor implements OnInit, OnChanges, OnDestroy {
  onDestroy = new Subject();
  @ViewChild('inputRef', { static: true }) public inputRef: ElementRef<any>;

  myControl = new UntypedFormControl();
  filteredOptions: Observable<ContactListDto[]>;

  @Input() filterType?: contactListDtoType;
  @Input() valueIsEmail: boolean = false;
  @Input() excludedIds: string[] = [];

  @Input() set value(val: string) {
    this.writeValue(val);
    this._cdr.detectChanges();
  }

  @Output() onSelect = new EventEmitter();

  constructor(
    protected injector: Injector,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private _tokenSvc: AngularTokenService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.select(OrganizationSelectors.getSelected).pipe(
      filter(x => !!x),
      takeUntil(this.onDestroy)
    ).subscribe((organization) => {
      this.store.dispatch(new LoadAll(organization))
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnChanges(changes) {
    if (changes.excludedIds) {
      this.excludedIds = changes.excludedIds.currentValue;
      this.filteredOptions =
        combineLatest(
          this.myControl.valueChanges,
          this.store.select(ContactSelectors.getContactPersonsOfSelectedOrg(this.filterType, this.excludedIds)),
        ).pipe(
          map(([value, contacts]: [string, ContactListDto[]]) => {
            const filteredContacts = this._filter(value, contacts);
            return filteredContacts.filter(contact => contact.email !== this._tokenSvc.currentAuthData.uid);
          })
        );
    }
  }

  private _filter(value: string, contacts: ContactListDto[]): ContactListDto[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return contacts.filter((entry: ContactListDto) => {
        return entry.name.toLowerCase().indexOf(filterValue) >= 0
          || entry.email.toLowerCase().indexOf(filterValue) >= 0
      }).slice(0, 5);
    } else {
      return [];
    }
  }

  writeValue(obj: any): void {
    this.myControl.setValue(obj);
  }

  openConfirmationSelectPerson($event: MatAutocompleteSelectedEvent) {
    if (!$event.option.value || !$event.option.value.email) return;
    this.dialog.open(ConfirmationDialogParticipantComponent, {
      disableClose: true,
      data: {
        title: 'GENERAL.ADD_PARTICIPANT',
        message: 'GENERAL.ADD_PARTICIPANT_CONFIRMATION',
        submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        person: $event.option.value,
        onSubmitAction: () => {
          this.contactSelected($event);
        },
        onCancelAction: () => {
          this.writeValue(null);
        }
      }
    });
}

  contactSelected($event: MatAutocompleteSelectedEvent) {
    if (this.valueIsEmail) {
      this.notifyOnChange($event.option.value.email);
      this.onSelect.emit($event.option.value.email);
    } else {
      this.notifyOnChange($event.option.value);
      this.onSelect.emit($event.option.value);
    }
    this.writeValue(null);
  }

  // Used to display the text in the input field.
  displayFn(contact: ContactListDto): string {
    return contact ? contact.name : '';
  }

  onKeyEnter() {
    if (!this.isInviteeEmailValid()) return;
    if (typeof this.myControl.value !== 'string' && !this.valueIsEmail) {
      this.onSelect.emit(this.myControl.value);
    } else if (this.valueIsEmail) {
      const value = (this.myControl.value && this.myControl.value.email) ? this.myControl.value.email : this.myControl.value;
      this.onSelect.emit(value);
    }
    this.writeValue(null);
  }

  onBlurEventHandler() {
    if (typeof this.myControl.value !== 'string' && !this.valueIsEmail) {
      this.notifyOnChange(this.myControl.value);
    } else if (this.valueIsEmail) {
      const value = (this.myControl.value && this.myControl.value.email) ? this.myControl.value.email : this.myControl.value;
      this.notifyOnChange(value);
    }
  }

  public isInviteeEmailValid() {
    if (!this.myControl.value || !this.myControl.value.length)
      return false;
    return EmailValidator.emailValid(this.myControl.value);
  }
}
