<dvtx-user-profile-settings-header [routes]="routes"
                                   [activeLink]="activeLink"
                                   [title]="'REGISTRATION.TERMS_OF_USE'"
                                   [subtitle]="'REGISTRATION.TERMS_OF_USE_TAB_TITLE'">
</dvtx-user-profile-settings-header>

<div class="five-f-settings-container">
  <h2 class="mb-2">{{'REGISTRATION.TERMS_OF_USE' | translate}}</h2>

  <div [innerHTML]="'REGISTRATION.TERMS_OF_USE_INFO' | translate"></div>
</div>
