
import {startWith, filter, takeUntil, distinctUntilChanged, first} from 'rxjs/operators';
import {Component, Input, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AppState} from 'app/reducers';
import {Subject, combineLatest} from 'rxjs';
import {OrganizationActions, OrganizationSelectors} from 'app/+store';
import {Organization} from 'app/models/organization.model';
import {LoadMy} from 'app/+store/membership/membership.actions';
import {OrganizationProxyService} from 'app/+store/organization/organization-proxy.service';
import {OrganizationProxy} from 'app/+store/organization/organization-proxy';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';

@Component({
  selector: 'dvtx-organization-master-data',
  templateUrl: './organization-master-data.component.html',
  styleUrls: ['./organization-master-data.component.scss']
})
export class OrganizationMasterDataComponent implements OnDestroy {
  onDestroy: Subject<void> = new Subject<void>();
  orga: Organization;

  // Form model to update values of the API organization proxy.
  // (in contrast to the main CPP organization model).
  organizationProxyForm: UntypedFormGroup;

  private refreshSubject: Subject<void> = new Subject();

  @Input() readonly = true;
  @Input() editmode = false;

  constructor(private _formBuilder: UntypedFormBuilder,
              private _store: Store<AppState>,
              private _fb: UntypedFormBuilder,
              private _notifyService: NotificationService,
              private _organizationProxySvc: OrganizationProxyService
  ) {
    this._initForm();

    this._store.select(OrganizationSelectors.getSelected)
      .pipe(
        filter(orga => !!orga),
        distinctUntilChanged((x, y) => x.id === y.id),
        takeUntil(this.onDestroy)
      )
      .subscribe(orga => {
        this._store.dispatch(new OrganizationActions.LoadDetailed(orga.id));
        this._fetchApiOrganizationProxy(orga.id);
      });


    combineLatest(
      _store.select(OrganizationSelectors.getSelected),
      this.refreshSubject.pipe(startWith(undefined)),
      (organ: Organization) => {
        return organ
      })
      .pipe(takeUntil(this.onDestroy))
      .subscribe((organ: Organization | undefined) => {
        if (organ) {
          this.orga = Object.assign({}, organ);
          this.orga.address = Object.assign({}, organ.address);
          this.orga.telephone = Object.assign({}, organ.telephone);
          this.orga.email = Object.assign({}, organ.email);
          this._store.dispatch(new LoadMy(this.orga.id))
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public refresh() {
    this.refreshSubject.next();
  }

  public save() {
    if (!this.orga || !this.orga.id) {
      this.editmode = false;
      return;
    }

    this._store.dispatch(new OrganizationActions.UpdateOrga(this.orga));
    this._updateApiOrganizationProxy();
    this.editmode = false;
  }

  /**
   * Fetches the organization model at the API to support DATEV document export in the Project Room
   * by the consultant number. This model is an addition to the CPP service organization.
   */
  private _fetchApiOrganizationProxy(id: string) {
    if (!id) return;

    this._organizationProxySvc.getOne(id)
      .pipe(first())
      .subscribe((org: OrganizationProxy) => {
        this.organizationProxyForm.patchValue({consultantId: org.consultantId})
      });
  }

  /**
   * Updates the organization model at the API to support DATEV document export in the Project Room
   * by the consultant number. This model is an addition to the CPP service organization.
   */
  private _updateApiOrganizationProxy() {
    if (!this.orga || !this.orga.id) return;

    const values = this.organizationProxyForm.value;
    const org = new OrganizationProxy(this.orga.id, this.orga.name, null, this.orga.legalFormId, null,
      null, values.consultantId, null, null);
    this._organizationProxySvc.update(this.orga.id, org)
      .pipe(first())
      .subscribe((_org: OrganizationProxy) => {
        this.editmode = false;
        this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
      }, err => {
        console.error(err);
        this._notifyService.error('HTTP_ERROR.DEFAULT');
      });
  }

  _initForm() {
    this.organizationProxyForm = this._fb.group({ consultantId: '' })
  }
}
