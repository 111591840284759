import { IBookmanClient } from './bookman-client.interface';

export class BookmanClient implements IBookmanClient {
  readonly type = 'bookman_client';

  constructor(
    public id: number,
    public name: string,
  ) {
  }
}
