<div *ngIf="avatarProfile"
     class="avatar avatar-{{avatarSizeString}}"
     inViewport [inViewportOptions]="{ threshold: 0 }"
     (inViewportAction)="loadProfileDetails($event)">
  <div class="avatar-icon-placeholder mx-1">
    <ng-template [ngIf]="this.avatarButtonCallback" [ngIfElse]="noActionAvatar">
      <button mat-fab (click)="avatarButtonCallback()" class="avatar-icon"
              [style.color]="image ? 'transparent' : avatarProfile?.fgColor"
              [style.background-color]="image ? 'transparent' : avatarProfile?.bgColor"
              [style.border-color]="image ? 'transparent' : avatarProfile?.bgColor"
              [style.width.px]="avatarSizeNumber" [style.height.px]="avatarSizeNumber">

        <ng-template [ngIf]="image" [ngIfElse]="nameLetters">
          <img [src]="image"/>
        </ng-template>

        <ng-template #nameLetters>
          <ng-template [ngIf]="avatarProfile?.firstName" [ngIfElse]="avatarIcon">
            {{ (avatarProfile?.firstName | slice: 0: 1) | uppercase }}{{ (avatarProfile?.lastName | slice: 0 : 1) | uppercase }}
          </ng-template>
        </ng-template>

        <ng-template #avatarIcon>
          <mat-icon>person</mat-icon>
        </ng-template>

      </button>
    </ng-template>

    <ng-template #noActionAvatar>
      <a style="cursor: default;" (click)="$event.stopPropagation();$event.preventDefault()"
         *ngIf="avatarProfile?.id === 'RemoveCollectoResponsibility'">{{'COLLECTOR.REMOVE_RESPONSIBLE' | translate}}</a>
      <div style="padding: 10px;padding-left: 0px;" [matTooltip]="'COLLECTOR.REMOVE_RESPONSIBLE' | translate"
           *ngIf="avatarProfile?.id === 'RemoveCollectoResponsibility'">
        <mat-icon style="position: absolute; top: 26px">clear</mat-icon> &nbsp;
        <strong style="margin-left: 20px;">
          {{(!avatarProfile?.name || avatarProfile?.name === 'RemoveCollectoResponsibility') ? ('COLLECTOR.REMOVE_RESPONSIBLE' | translate) : avatarProfile?.name}}
        </strong>
      </div>
      <a style="cursor: default;" (click)="$event.stopPropagation();$event.preventDefault()"
         *ngIf="avatarProfile?.id === 'RemoveCollectoResponsibility'">{{'COLLECTOR.CHANGE_RESPONSIBLE' | translate}}</a>
      <span *ngIf="avatarProfile?.id !== 'RemoveCollectoResponsibility'"
            class="avatar-icon avatar-size-{{avatarSizeString}} mat-fab mat-elevation-z"
            [style.color]="image ? 'transparent' : avatarProfile?.fgColor"
            [style.background-color]="image ? 'transparent' : avatarProfile?.bgColor"
            [style.border-color]="image ? 'transparent' : avatarProfile?.bgColor">

        <ng-template [ngIf]="image" [ngIfElse]="nameLetters">
          <img [src]="image"/>
        </ng-template>

        <ng-template #nameLetters>
          <ng-template [ngIf]="avatarProfile?.firstName" [ngIfElse]="avatarIcon">
              {{avatarProfile?.firstName | slice: 0: 1}}{{avatarProfile?.lastName | slice: 0 : 1}}
            </ng-template>
        </ng-template>

        <ng-template #avatarIcon>
          <mat-icon class="person-icon">person</mat-icon>
        </ng-template>
      </span>

      <ng-template
        [ngIf]="(userStatusIsVerified || (avatarProfile?.hasAccount || isVerified ) && !(isMember || avatarProfile?.isMember || userStatusIsMember )) && showTick && avatarProfile?.id !== 'RemoveCollectoResponsibility'">
        <mat-icon class="verification--icon check">check_circle</mat-icon>
      </ng-template>

      <ng-template [ngIf]="(userStatusIsMember  || (isMember || avatarProfile?.isMember))  && showTick && avatarProfile?.id !== 'RemoveCollectoResponsibility'">
        <mat-icon class="verification--icon member-check">check_circle</mat-icon>
      </ng-template>
    </ng-template>
  </div>

  <div class="user-info"
       *ngIf="(avatarShowTitle || avatarShowEmail) && avatarProfile?.id !== 'RemoveCollectoResponsibility'">
    <h4 class="mb-0" *ngIf="avatarShowTitle" mat-line>
      <strong *ngIf="avatarProfile?.firstName || avatarProfile?.lastName || avatarProfile?.name">
        <span [matTooltip]="avatarProfile?.firstName && avatarProfile?.lastName ? (avatarProfile?.firstName + ' ' + avatarProfile?.lastName) : avatarProfile?.email">
          {{ avatarProfile?.firstName && avatarProfile?.lastName ? (avatarProfile?.firstName + ' ' + avatarProfile?.lastName) : avatarProfile?.email }}
        </span>
      </strong>
      <strong *ngIf="!(avatarProfile?.firstName || avatarProfile?.lastName || avatarProfile?.name) && !avatarShowEmail">
        <span [matTooltip]="avatarProfile?.email">{{ avatarProfile?.email }}</span>
      </strong>
    </h4>
    <p class="mb-0 email" *ngIf="avatarShowEmail" mat-line
       [matTooltip]="avatarProfile?.email">{{ avatarProfile?.email }}</p>
  </div>
</div>
