import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {ApiErrorService} from '../services/api-error.service';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class ApiErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  handleError(errors) {
    // console.error('%c ' + errors, 'color: purple;');

    const errorService = this.injector.get(ApiErrorService);
    const notifService = this.injector.get(NotificationService);
    // console.error('ApiErrorHandler', errors);

    if (errors && errors.length) {
      errors.forEach((e) => {
        errorService.addError(e);
        if (e.status === 503) {
          notifService.error(e.title);
        }
      });

    } else {
      // console.log(errors);
      throw errors; // new Error('API Error Handler!');
    }
  }
}
