import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ITabNavRoute} from '../../../organization/modules/organization-card/models/tab-nav-route.interface';
import {OrganizationalVisual, VisualSignature} from 'app/+store/document-signature/document-signature';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {UserAvatar} from '../../../../+store/user-avatar/user-avatar';
import {Subject} from 'rxjs';
import {DocumentSignatureService} from 'app/+store/document-signature/document-signature.service';
import {debounceTime, distinctUntilKeyChanged, filter, first, takeUntil} from 'rxjs/operators';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {OrganizationSelectors} from 'app/+store';

@Component({
  selector: 'dvtx-document-signature-default-signature-settings',
  templateUrl: './document-signature-default-signature-settings.component.html',
  styleUrls: ['./document-signature-default-signature-settings.component.scss']
})
export class DocumentSignatureDefaultSignatureSettingsComponent implements OnInit, OnDestroy {
  public routes: ITabNavRoute[];
  public activeLink: string;
  public currentSignature: VisualSignature;
  public currentSignatureStamp: VisualSignature;
  public currentSignatureSignet: VisualSignature;
  public organization$;
  public visual: OrganizationalVisual;
  public form: UntypedFormGroup;
  public submitOngoing = false;
  private organizationSignatureInitialized = false;
  private onDestroy = new Subject<void>();
  public avatar: UserAvatar;
  public skipMetaInfoSupported = false;

  constructor(private _fb: UntypedFormBuilder,
              private _store: Store<AppState>,
              private _signSvc: DocumentSignatureService,
              private _notifyService: NotificationService,
              private _cdr: ChangeDetectorRef) {
    this.currentSignature = new VisualSignature(null);
    this.currentSignatureStamp = new VisualSignature(null);
    this.currentSignatureSignet = new VisualSignature(null);
    this.form = _fb.group({
      name: null,
      fontSize: null,
      enableProfession: null,
      profession: null,
      enableDate: null,
      enableTime: null
    });

    this.form.controls['fontSize']
      .valueChanges
      .pipe(debounceTime(300), takeUntil(this.onDestroy))
      .subscribe(val => {
        if (this.organizationSignatureInitialized) {
          this.updateOrganizationalSignature();
        }
      });
  }

  ngOnInit() {
    this.organization$ = this._store.select(OrganizationSelectors.getSelected);

    this.organization$
      .pipe(filter(o => !!o), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy))
      .subscribe(org => {
        this._signSvc.getOrganizationalVisual().pipe(first()).subscribe(visual => {
          this.visual = visual;
          this.form.patchValue({
            name: visual.name,
            fontSize: visual.fontSize,
            enableProfession: visual.enableProfession,
            profession: visual.profession,
            enableDate: visual.enableDate,
            enableTime: visual.enableTime
          });
          this.form.markAsPristine();
          this.organizationSignatureInitialized = true;
          this._cdr.markForCheck();
        });
      });

    this.routes = [{
      title: 'SIGNATURE.ACCOUNT.ACCOUNT_DATA',
      route: '/user-profile/signature/account'
    }, {
      title: 'SIGNATURE.ACCOUNT.ACCOUNT_SETTINGS',
      route: '/user-profile/signature/defaults'
    }, {
      title: 'SIGNATURE.ACCOUNT.FP_SIGN_SETTINGS',
      route: '/user-profile/signature/fp-sign-settings'
    }];
    this.activeLink = this.routes[1].title

    this._signSvc.getDefaultSignature()
      .pipe(first())
      .subscribe(visualSignature => {
        this.currentSignature = visualSignature;
        this.skipMetaInfoSupported = VisualSignature.isSkipMetaInfoSupported(visualSignature);
        this._cdr.detectChanges();
      }, err => {
        if (err.status === 404) return;
        console.error(err);
        this._notifyService.error('SIGNATURE.ERRORS.GENERAL_ERROR_RETRY')
      });

    this._signSvc.getDefaultSignature('stamp')
      .pipe(first())
      .subscribe(visualSignature => {
        this.currentSignatureStamp = visualSignature;
        this._cdr.detectChanges();
      }, err => {
        if (err.status === 404) return;
        console.error(err);
        this._notifyService.error('SIGNATURE.ERRORS.GENERAL_ERROR_RETRY')
      });

    this._signSvc.getDefaultSignature('signet')
      .pipe(first())
      .subscribe(visualSignature => {
        this.currentSignatureSignet = visualSignature;
        this._cdr.detectChanges();
      }, err => {
        if (err.status === 404) return;
        console.error(err);
        this._notifyService.error('SIGNATURE.ERRORS.GENERAL_ERROR_RETRY')
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public updateOrganizationalSignature() {
    if (!this.visual) { return; }

    const values = this.form.value;
    if (!values.fontSize) { return; }

    if (this.submitOngoing) { return; }
    this.submitOngoing = true;

    const visual = this.visual;
    visual.name = values.name;
    visual.fontSize = values.fontSize;
    visual.enableProfession = values.enableProfession;
    visual.profession = values.profession;
    visual.enableTime = values.enableTime;
    visual.enableDate = values.enableDate;

    this._signSvc.updateOrganizationalVisual(visual).pipe(first()).subscribe(_visual => {
      this.visual = _visual;
      this.form.markAsPristine();
      this._cdr.markForCheck();
      this.submitOngoing = false;
    }, err => {
      this.submitOngoing = false;
      console.error(err);
    })
  }
}
