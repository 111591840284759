import {TenantMemberActions, TenantMemberActionTypes} from './member.actions';
import {adapter, initialState, State} from './member.state';

export function reducer(state = initialState, action: TenantMemberActions): State {
  const current = state.selected;
  switch (action.type) {
    case TenantMemberActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case TenantMemberActionTypes.Create:
    case TenantMemberActionTypes.LoadOne:
    case TenantMemberActionTypes.Remove:
    case TenantMemberActionTypes.Save:
      return {
        ...state,
        loading: true
      };
    case TenantMemberActionTypes.LoadOneSuccess:
      return adapter.addOne(action.member, {
        ...state,
        selected: action.member,
        loading: false,
      });
    case TenantMemberActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.members, {
        ...state,
        selected: null,
        loading: false,
      });
    case TenantMemberActionTypes.CreateSuccess:
    case TenantMemberActionTypes.SaveSuccess:
      return adapter.upsertOne(action.member, {
        ...state,
        selected: action.member,
        loading: false,
      });
    case TenantMemberActionTypes.CreateFail:
    case TenantMemberActionTypes.LoadAllFail:
    case TenantMemberActionTypes.LoadOneFail:
    case TenantMemberActionTypes.RemoveFail:
    case TenantMemberActionTypes.SaveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
    case TenantMemberActionTypes.RemoveSuccess:
      return adapter.removeOne(action.member.id, {
        ...state,
        selected: (current && current.id === action.member.id) ? null : current,
        loading: false,
      });
      case TenantMemberActionTypes.Reset:
        return adapter.removeAll(state);
  }
  return state;
}
