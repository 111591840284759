import {NaturalPerson} from 'app/models/natural-person.model';
import {createEntityAdapter, EntityState} from '@ngrx/entity';

export interface State extends EntityState<NaturalPerson> {
  my?: string
  loading: boolean,
  myNaturalPerson: NaturalPerson
}

export const adapter = createEntityAdapter<NaturalPerson>();

export const initialState: State = adapter.getInitialState({
  my: undefined,
  loading: false,
  myNaturalPerson: null
});
