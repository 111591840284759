import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {SimplePhoneNumber} from '../../../../../../modules/contacts/models/contact.interface';

@Component({
  selector: 'dvtx-telephone-group-cva',
  templateUrl: './telephone-group-cva.component.html',
  styleUrls: ['./telephone-group-cva.component.scss'],
})
export class TelephoneGroupCvaComponent {
  @Input() telephonenumbers: SimplePhoneNumber[] = [];
  @Input() disabled: boolean = false;
  @Output() addPhone = new EventEmitter<SimplePhoneNumber>();
  @Output() removePhone = new EventEmitter<string>();

  add(phoneNumber: SimplePhoneNumber) {
    this.addPhone.emit(phoneNumber)
  }

  addNew() {
    const phoneNumber = new SimplePhoneNumber()
    this.telephonenumbers = this.telephonenumbers.concat(phoneNumber)
  }

  remove(phoneNumber: SimplePhoneNumber) {
    if (phoneNumber.id) {
      this.removePhone.emit(phoneNumber.id)
    } else {
      this.telephonenumbers = this.telephonenumbers.filter(x => x !== phoneNumber)
    }
  }

}
