import {Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {catchError, first, map, takeUntil, flatMap} from 'rxjs/operators';
import {CppApiService} from '../../../../../../services/cpp-api.service';
import {AppState} from '../../../../../../reducers';
import {Store} from '@ngrx/store';
import {Create, LoadAll, Select} from '../../../../../../+store/organization/organization.actions';
import {ContactOrganizationBaseForm, newOrganizationBaseForm} from '../../../../../organization/models/contact-organization-base-form';
import {getSelected, getSelectedId} from '../../../../../../+store/organization/organization.selectors';
import {RxjsBaseComponent} from '../../../../../../shared/base-classes/rxjs-base-component';
import {Organization} from '../../../../../../models/organization.model';
import { LicenseBuyingComponent } from 'app/modules/sales/containers/license-buying/license-buying.component';
import { CartActionTypes, ShoppingCartService } from 'app/+store/licence/shopping-cart.service';
import { Observable } from 'rxjs';
import { SalesOrderService } from 'app/+store/licence/sales-order.service';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';
import { OrganizationSelectors, OrganizationActions, LicenceAssignmentActions } from 'app/+store';
import { ShoppingCartDataSource } from 'app/shared/modules/api/models/shopping-cart-data-source';

@Component({
  selector: 'dvtx-create-organization-dialog-container',
  templateUrl: './create-organization-dialog-container.component.html',
  styleUrls: ['./create-organization-dialog-container.component.scss']
})
export class CreateOrganizationDialogContainerComponent extends RxjsBaseComponent implements OnInit {

  createOrgaFrom: ContactOrganizationBaseForm = newOrganizationBaseForm();

  arrayOfCalls = [];
  forkJoinVariable;
  currentOrgId;
  cart: ShoppingCartDataSource;

  alertMessage = null;

  constructor(
    private mdr: MatDialogRef<CreateOrganizationDialogContainerComponent>,
    private cppService: CppApiService,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _cartSvc: ShoppingCartService,
    private _orderSvc: SalesOrderService,
    private _notService: NotificationService,
  ) {
    super();
    this.store.select(getSelected).pipe(
      takeUntil(this.onDestroy)
    ).subscribe((orga: Organization) => {
      if (orga && orga.name === this.createOrgaFrom.contactOrganization.name) {
        this.mdr.close();
      }
    });

    if (data && data.alertMessage) {
        this.alertMessage = data.alertMessage;
    }
  }

  ngOnInit() {
  }

  async createOrganization() {
    this.store.select(OrganizationSelectors.getSelectedId).pipe(first()).subscribe(id => {
      this.currentOrgId = id
    });
    await this.store.dispatch(new Create(this.createOrgaFrom));
    if (this.data && this.data.licence) {
      this.attachLicenceToCart();
    }
  }


  attachLicenceToCart() {
    this.store.select(OrganizationSelectors.getSelectedId).pipe(takeUntil(this.onDestroy)).subscribe(id => {
      if (id && id !== this.currentOrgId) {
        this.store.dispatch(new OrganizationActions.Select(id));
        this.store.dispatch(new LicenceAssignmentActions.Add(this.currentOrgId));
      }
    });

  }
}
