<div class="mat-dialog-inner-wrap">
  <div class="mat-dialog-header">
    <h2 mat-dialog-title>{{ 'ORGANIZATION.CREATION.TITLE' | translate }}</h2>
    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <div *ngIf="alertMessage">
      <dvtx-alert [color]="'info'" [text]="'REGISTRATION.ORGANIZATION_IS_NEEDED_MESSAGE'"></dvtx-alert>
    </div>
    <dvtx-create-organization-dialog
      [(ngModel)]="createOrgaFrom"
      headingTranslateKey="REGISTRATION.ORGANIZATIONAL_INFO_TITLE"
    ></dvtx-create-organization-dialog>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-end">
    <button type="reset" mat-button [mat-dialog-close]="false">{{'GENERAL.CANCEL_ACTION' | translate}}</button>
    <button mat-raised-button color="primary" [disabled]="!createOrgaFrom.isValid" [mat-dialog-close]="true" (click)="createOrganization()" >
      {{ 'ORGANIZATION.CREATION.CREATE' | translate }}
    </button>
  </div>
</div>
