<!-- <div class="button-row mb-2">
  <a mat-button routerLink="/organization/{{(selectedOrg$ | async)?.id}}/addressbook/clients">
    <mat-icon matAffix aria-label="back">arrow_back</mat-icon>
    {{ 'GENERAL.PREVIOUS_ACTION' | translate }}
  </a>
</div> -->

<ng-template [ngIf]="loading" [ngIfElse]="loaded">
  <div class="w-100 my-3 d-flex justify-content-center">
    <dvtx-loading-panel></dvtx-loading-panel>
  </div>
</ng-template>

<ng-template #loaded>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="m-4">
        <ng-container *ngIf="!(bookmanServiceAccount$ | async); else haveAccount">
          <ng-container *ngIf="selectedOrg$ | async as org">
            <dvtx-alert [dismissible]="false" [color]="'warning'" [icon]="'warning'"
                        [text]="'FIBU.ORGANIZATIONAL_BOOKMAN_ACCOUNT' | translate:{ orgId: org.id }">
            </dvtx-alert>
          </ng-container>
        </ng-container>
      </div>
      <ng-template #haveAccount>
        <form [formGroup]="settingsForm">
          <div class="d-flex justify-content-center mb-1">
            <div class="bookman-logo">
              <img src="assets/img/bookman_cockpit_colored.png" height="120" alt="Bookman Logo">
            </div>
          </div>
          <div class="d-flex justify-content-end mb-1">
            <ng-container *ngIf="(showAuditTrail$ | async) && selectedBookmanClient">
              <button mat-button
                      color="gray"
                      class="mx-2"
                      (click)="openAuditTrail()">
                {{'AUDIT_TRAIL.AUDIT_TRAIL' | translate}}
              </button>
            </ng-container>

            <button mat-raised-button color="primary"
                    [disabled]="settingsForm.pristine || settingsForm.invalid"
                    (click)="submitSettings()">
              {{'GENERAL.SAVE_ACTION' | translate}}
            </button>
          </div>

          <div class="row m-2 justify-content-start">
            <div class="col-10">
              <h3>{{'FIBU.SELECT_BOOKMAN_CLIENT' | translate}}</h3>
              <div class="d-flex align-items-center bookman-selector">
                <mat-icon svgIcon="bookman_cockpit"></mat-icon>
                <div class="d-flex align-items-center m-1" style="flex: 1">
                  <mat-form-field>
                    <mat-select
                      formControlName="bookmanClientId"
                      (selectionChange)="selectBookmanClient($event)"
                      [value]="selectedBookmanClient?.id"
                      placeholder="{{ 'CLIENT.BOOKMAN_COCKPIT_CLIENT' | translate }}">
                      <mat-option *ngFor="let bookmanClient of bookmanClients$ | async"
                                  [value]="bookmanClient.id">{{bookmanClient.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div *ngFor="let bookmanClient of bookmanClients$ | async">
                  </div>
                </div>
              </div>
            </div>
            <hr>
          </div>
          <div class="row m-2 justify-content-start" *ngIf="selectedBookmanClient">
            <div class="col-10">
              <div class="d-flex  flex-column">
                <h3>{{'FIBU.SELECT_SYNCING_FOLDER' | translate}}</h3>
                <mat-error *ngIf="settingsForm.get('dmsFolder')?.errors?.used">
                  {{'FIBU.FOLDER_ALREADY_USED' | translate}}
                </mat-error>
                <div class="d-flex">
                  <dvtx-folder-selector [selectedId]="selectedFolderId$ | async"
                                        [isFibuSelector]="true"
                                        (onFolderSelection)="folderSelection($event)"></dvtx-folder-selector>
                </div>
              </div>
            </div>
            <hr>
          </div>
          <div class="row m-2 justify-content-start" *ngIf="(selectedFolderId$ | async) && !settingsForm.get('dmsFolder')?.errors?.used">
            <div class="col-12 mb-2">
              <div class="d-flex flex-column bookman-selector">
                <div class="my-1">
                  <h3>{{'FIBU.FOLDER_SYNCING_SETTINGS' | translate}}</h3>
                </div>
                <div class="my-1">
                  <mat-checkbox formControlName="bookmanCockpitEnabled">
                    {{'FIBU.SYNC_WITH_BOOKMAN_COCKPIT' | translate}}
                  </mat-checkbox>
                </div>
                <div class="my-1">
                  <mat-checkbox formControlName="fiveFSyncEnabled">
                    {{'FIBU.SYNC_WITH_FIVE_F' | translate}}
                  </mat-checkbox>
                </div>
                <div class="d-flex align-items-center">
                  <span class="mr-2">{{'FIBU.NUMBER_OF_DAYS_TO_KEEP' | translate}}</span>
                  <mat-form-field style="width: 50px">
                    <input matInput type="number" min="1"
                           formControlName="documentExpiryTime">
                  </mat-form-field>
                  <span matSuffix class="d-flex align-items-center ml-2">{{'FIBU.DAYS' | translate}}</span>

                </div>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
      <dvtx-audit-trail-modal [process]="fibuProcess$ | async" #auditTrailModal></dvtx-audit-trail-modal>
    </div>
  </div>
</ng-template>

<!-- <mat-card class="p-0 dvtx-client-bookman" *ngIf="(client$ | async) as client">
  <mat-card-title>
    <span class="small-title">{{ 'CLIENT.CLIENT' | translate }}</span>
    <h3 class="m-0 p-0">
      {{ client?.name }} {{ client?.clientId ? '(' + client?.clientId + ')' : '' }}
    </h3>
  </mat-card-title>

  <mat-card-content>
    <dvtx-client-nav-bar [routes]="routes" [activeLink]="activeLink"></dvtx-client-nav-bar>


  </mat-card-content>
</mat-card> -->
