import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'dvtx-table-text-and-info',
  templateUrl: './table-text-and-info.component.html',
  styleUrls: ['./table-text-and-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableTextAndInfoComponent implements OnInit {
  @Input() title = '';
  @Input() tooltip = '';
  @Input() h4 = false;
  @Input() icon = 'info';
  @Input() alignmentPosition: 'center' | 'start' = 'center';
  @Input() position: 'after' | 'before' = 'after';

  constructor() { }

  ngOnInit() {
  }
}
