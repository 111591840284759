<div>
  <h3 class="mb-2"> {{ 'INVITATIONS.INVITE_EMPLOYEE' | translate }}</h3>
  <div class="five-f-mat-input five-f-icon-content-layout">
    <mat-icon>email</mat-icon>
    <div class="five-f-icon-content-layout--content">
      <div class="row">
        <div class="col-12">
          <dvtx-contact-select [fetchContactsFromStore]="true"
                               [style.width]="'340px'"
                               [class]="'mw-100'"
                               elementName="E-Mail"
                               [(ngModel)]="inviteeEmail" [filterType]="contactListDtoType.naturalPersonContact"
            [excludedIds]="[]" [valueIsEmail]="true">
          </dvtx-contact-select>
        </div>
      </div>
    </div>
  </div>
</div>
