<div>
  <mat-form-field>
    <input
      matInput
      type="text"
      [(ngModel)]="innerValue"
      (ngModelChange)="notifyOnChange(innerValue)"
      (click)="notifyOnTouch()"
      [placeholder]="elementName"
      [disabled]="isDisabled"
      [errorStateMatcher]="errorStateMatcher"
      [required]="requiredField"
      #textinput
    >
  </mat-form-field>
  <ng-container *ngFor="let err of errorMapper">
    <mat-error *ngIf="dirty && errors && errors[err.error]">
      {{err.message}}
    </mat-error>
  </ng-container>
</div>
