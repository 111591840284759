import {OperatorSmsRecordActions, OperatorSmsRecordActionTypes} from './sms-record.actions';
import {adapter, initialState, State} from './sms-record.state';

export function reducer(state = initialState, action: OperatorSmsRecordActions): State {
  const current = state.selected;
  switch (action.type) {
    case OperatorSmsRecordActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case OperatorSmsRecordActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.sms, {
        ...state,
        selected: null,
        loading: false,
      });
    case OperatorSmsRecordActionTypes.LoadAllFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
  }
  return state;
}
