import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {DatevClient} from './datev-client';

export class DatevClientBuilder implements IApiResourceBuilder<DatevClient> {


  fromResponse(data): DatevClient {
    const client = new DatevClient(
      data.id,
      data.attributes.client_number,
      data.attributes.consultant_number,
      data.attributes.name,
      data.attributes.synced,
      data.attributes.services,
      data.attributes.document_types,
      data.attributes.worker_email,
      data.attributes.worker_name
);

    return client;
  }

  toRequest(client: DatevClient) {
    return null;
  }

}

