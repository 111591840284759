import {IProcessTreeNode, ProcessTreeNodeType} from './process-tree.interface';
import { ProcessStatus } from 'app/+store/process/process';

export type ProcessTreeSortAttribute = 'title' | 'created_at' | 'updated_at';
export type ProcessTreeSortDirection = 'desc' | 'asc';

/**
 * Project structure settings for the Project Room and other implementing
 * views. Defines sorting and some filters.
 * Both are persisted as user settings. See
 * ProcessTreeSettingsBuilder, and ProcessTreeService for further details.
 *
 * Current options:
 * - Sort project structure by different attributes.
 * - Define sort direction.
 * - Hide closed workflows.
 */
export interface IProcessTreeSettings {
  id: string;
  type: string;
  title?: string;
  icon?: string;
  sortBy: ProcessTreeSortAttribute,
  sortDirection: ProcessTreeSortDirection,
  hideClosed?: boolean;
}

export class ProcessTreeSettings implements IProcessTreeSettings {
  public id: string;
  readonly type = 'workflow_engine_process_tree_settings';
  constructor(public sortBy, public sortDirection, public hideClosed) {
    this.id = `${sortBy}_${sortDirection}`;
  }
}

export const DEFAULT_SORTING_OPTIONS: IProcessTreeSettings[] = [
  {
    id: 'title_asc',
    type: 'project_structure_settings',
    title: 'SORT.BY_TITLE_ASC',
    sortBy: 'title',
    sortDirection: 'asc',
    icon: 'sort_by_alpha',
  },
  {
    id: 'title_desc',
    type: 'project_structure_settings',
    title: 'SORT.BY_TITLE_DESC',
    sortBy: 'title',
    sortDirection: 'desc',
    icon: 'sort_by_alpha',
  },
  {
    id: 'created_at_desc',
    type: 'project_structure_settings',
    title: 'SORT.BY_CREATION_DATE_DESC',
    sortBy: 'created_at',
    icon: 'history',
    sortDirection: 'desc',
  },
  {
    id: 'created_at_asc',
    type: 'project_structure_settings',
    title: 'SORT.BY_CREATION_DATE_ASC',
    sortBy: 'created_at',
    icon: 'update',
    sortDirection: 'asc',
  },
  {
    id: 'updated_at_desc',
    type: 'project_structure_settings',
    title: 'SORT.BY_LAST_UPDATED_AT_DESC',
    sortBy: 'updated_at',
    icon: 'history',
    sortDirection: 'desc',
  },
  {
    id: 'updated_at_asc',
    type: 'project_structure_settings',
    title: 'SORT.BY_LAST_UPDATED_AT_ASC',
    sortBy: 'updated_at',
    icon: 'update',
    sortDirection: 'asc',
  }
]

export class ProcessTree {
}

export class ProcessTreeNode implements IProcessTreeNode {
  createdAt: Date;
  active = false;
  closed = false;

  icon: string = null;
  isSvgIcon = false;
  isProject = false;
  isProjectRoom = true;
  isCollecto = false;
  isCac = false;
  count?: number;

  constructor(public id: string,
              public type: ProcessTreeNodeType,
              public parentId: string,
              public title: string,
              public updatedAt: Date,
              public status?: ProcessStatus,
              public canCreateTask: boolean = false) {}
}
