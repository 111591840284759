<div class="five-f-settings-container dvtx-datev-login-admin-panel">
  <div class="dvtx-datev-login-admin-panel__content-container">
    <div class="d-flex">
      <div>
        <mat-icon class="datev-logo" svgIcon="datev_data_service_logo_color"></mat-icon>
      </div>

      <div *ngIf="!(clientLoading$ | async) && (datevClient$ | async)">
        <!--
          * {
          *   "id": "455148-1",
          *   "clientNumber": 1,
          *   "consultantNumber": 455148,
          *   "name": "Alright Tonight incorporated",
          *   "synced": true,
          *   "type": "datev_client"
          * }
        -->
        <h2>{{(datevClient$ | async)?.name}}</h2>
        <h4>{{'DATEV.CLIENT.ID' | translate }}: {{(datevClient$ | async)?.clientNumber}}</h4>
        <h4>{{'CLIENT.DATEV_CONSULTANT_ID' | translate }}: {{(datevClient$ | async)?.consultantNumber}}</h4>
      </div>
    </div>

    <hr>

    <ng-template [ngIf]="!(clientLoading$ | async) && (datevClient$ | async)">
      <!-- <pre><p>{{datevClient$ | async | json}}</p></pre> -->

      <div class="d-flex w-100 flex-column">
        <div class="mt-1 w-100 h4 d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <span class="mr-2">
              <mat-icon class="status-color-success">check_circle</mat-icon>
            </span>
            {{ 'DATEV.CLIENT_AUTHORIZED_SUCCESSFUL' | translate }}
          </div>

          <div>
            <dvtx-simple-confirm (onConfirm)="logout()"
                                 [confirmButtonText]="'DATEV.DISCONNECT_YOUR_DATEV_ACCOUNT_ACTION' | translate">
              <button mat-button>
                <mat-icon matPrefix svgIcon="logout"></mat-icon>
                {{'DATEV.DISCONNECT_DATEV_LINK' | translate }}
              </button>
            </dvtx-simple-confirm>
          </div>
        </div>

        <div class="mb-2 w-100 d-flex text-truncate" *ngIf="(datevClient$ | async)?.workerEmail">
          <small>{{ 'DATEV.DATEV_CLIENT_AUTHORIZED_BY' | translate:{name: (datevClient$ | async)?.workerName, email: (datevClient$ | async)?.workerEmail} }}</small>
        </div>
        <!--
          * <hr>
          * <h3>{{'DATEV.DISCONNECT_CLIENT_FROM_DATEV' | translate }}</h3>
          * <div class="mb-2 w-100 d-flex">
          *   <dvtx-simple-confirm (onConfirm)="logout()"
          *                        [confirmButtonText]="'DATEV.DISCONNECT_YOUR_DATEV_ACCOUNT_ACTION' | translate">
          *     <button mat-button color="warn">
          *       <mat-icon matPrefix>logout</mat-icon>
          *       {{'DATEV.DISCONNECT_DATEV_LINK' | translate }}
          *     </button>
          *   </dvtx-simple-confirm>
          * </div>
        -->
      </div>

      <hr>

      <h3 class="mb-2">{{'DATEV.ACTIVATED_DATEV_MODULES' | translate }}</h3>

      <!-- {{ (datevClient$ | async) | json }} -->

      <ul *ngIf="(datevClient$ | async)?.services">
        <li *ngFor="let service of (datevClient$ | async)?.services">
          <div class="d-flex align-items-center five-f-mat-icon-list-bullet">
            <span class="mr-2">
              <mat-icon class="status-color-success">check</mat-icon>
            </span>
            {{ service?.name }}
          </div>
        </li>
      </ul>

      <hr>

      <ng-container *ngIf="(datevClient$ | async)?.documentTypes?.length">
        <h3 class="mb-2">{{'DATEV.ACTIVATED_DOCUMENT_TYPES' | translate }}</h3>

        <ul *ngIf="(datevClient$ | async)?.documentTypes">
          <li *ngFor="let service of (datevClient$ | async)?.documentTypes">
            <div class="d-flex align-items-center five-f-mat-icon-list-bullet">
              <span class="mr-2">
                <mat-icon class="status-color-default">insert_drive_file</mat-icon>
              </span>
              {{ service?.name }}
            </div>
          </li>
        </ul>
      </ng-container>
    </ng-template>

    <ng-container *ngIf="!(clientLoading$ | async) && !(datevClient$ | async)">
      <h3 class="mb-2">{{'DATEV.AUTHORIZE_CLIENT' | translate }}</h3>

      <button color="primary" mat-raised-button
              (click)="login()">
        <mat-icon matPrefix>input</mat-icon>
        {{'DATEV.AUTHORIZE_CLIENT_BUTTON' | translate}}
      </button>
    </ng-container>

    <div *ngIf="(clientLoading$ | async)" class="loading">
      <mat-spinner [diameter]="20"></mat-spinner>
    </div>
  </div>
</div>
