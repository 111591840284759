export class Employee {
  public id: string | null;
  public email: string;
  public gender: string;
  public firstName: string;
  public lastName: string;
  public title: string;
  public hasTfa = false;
  public hasLicence = false;

  get name() {
    return this.firstName + ' ' + this.lastName;
  }
}
