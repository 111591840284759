import {ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Subject} from 'rxjs';
import {DmsAccountType} from 'app/+store/dms-folder/dms-folder.interface';
import {distinctUntilKeyChanged, filter, first, takeUntil} from "rxjs/operators";
import {OrganizationSelectors} from "app/+store";
import {SlimFolderService} from 'app/+store/slim-folder/slim-folder.service';
import {SlimFolderActions} from 'app/+store/slim-folder';

@Component({
  template: '<router-outlet></router-outlet>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientContainerComponent implements OnInit, OnDestroy {
  onDestroy = new Subject();

  constructor(private _store: Store<AppState>,
              private _slimFolderSvc: SlimFolderService,
              private _ngZone: NgZone) {
  }

  ngOnInit() {
    this._store.select(OrganizationSelectors.getSelected)
      .pipe(filter(org => !!org), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy))
      .subscribe((_organization) => {
        this._slimFolderSvc.getAll(DmsAccountType.Organization).pipe(first()).subscribe(folders => {
          this._store.dispatch(new SlimFolderActions.LoadAllSuccess(folders));
        })
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
