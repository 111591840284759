<div class="card dvtx-registration-wizard-input-override">

  <div class="card-header">
    <h1>{{ 'REGISTRATION.EMAIL_ALREADY_REGISTERED_TITLE' | translate }}</h1>
  </div>

  <div class="card-content">

    <p class="label">Email</p>
    <p>thomas.mueller@mueller-ag.de</p>
    <p class="error">{{ 'REGISTRATION.EMAIL_ALREADY_REGISTERED' | translate }}</p>

    <mat-grid-list cols="6" rowHeight="64px">

      <mat-grid-tile [colspan]="3" class="pull-start no-padding">
        <button mat-raised-button>{{ 'AUTH.LOGIN_TITLE' | translate }}</button>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="3" class="pull-end no-padding">
        <button mat-raised-button color="primary">
          {{ 'REGISTRATION.START_NEW_REGISTRATION' | translate }}
        </button>
      </mat-grid-tile>

    </mat-grid-list>

    <br>

    <!--<p class="login-link">-->
      <!--<a href="javascript: void(0);" class="anchor-text">{{ 'REGISTRATION.PASSWORD_FORGOTTON_LINK' | translate }}</a>-->
    <!--</p>-->

  </div>

</div>


