import {Action} from '@ngrx/store';
import {ILabelParams} from './label.interface';
import {Label} from './label';

export enum LabelActionTypes {
  Create = '[Label] Create',
  CreateSuccess = '[Label] Create Success',
  CreateFail = '[Label] Create Fail',
  LoadAll = '[Label] Load All',
  LoadAllSuccess = '[Label] Load All Success',
  LoadAllFail = '[Label] Load All Fail',
  LoadOne = '[Label] Load One',
  LoadOneSuccess = '[Label] Load One Success',
  LoadOneFail = '[Label] Load One Fail',
  Remove = '[Label] Remove',
  RemoveSuccess = '[Label] Remove Success',
  RemoveFail = '[Label] Remove Fail',
  Edit = '[Label] Edit',
  EditSuccess = '[Label] Edit Success',
  EditFail = '[Label] Edit Fail',
  Reset = '[Label] Reset'
}

export class Create implements Action {
  readonly type = LabelActionTypes.Create;

  constructor(public params: ILabelParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = LabelActionTypes.CreateSuccess;

  constructor(public label: Label) {
  }
}

export class CreateFail implements Action {
  readonly type = LabelActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = LabelActionTypes.LoadAll;

  constructor(public boardId: string = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = LabelActionTypes.LoadAllSuccess;

  constructor(public labels: Label[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = LabelActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = LabelActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = LabelActionTypes.LoadOneSuccess;

  constructor(public label: Label) {
  }
}

export class LoadOneFail implements Action {
  readonly type = LabelActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = LabelActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = LabelActionTypes.RemoveSuccess;

  constructor(public id: string) {
  }
}

export class RemoveFail implements Action {
  readonly type = LabelActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Edit implements Action {
  readonly type = LabelActionTypes.Edit;

  constructor(public id: string, public params: ILabelParams) {
  }
}

export class EditSuccess implements Action {
  readonly type = LabelActionTypes.EditSuccess;

  constructor(public label: Label) {
  }
}

export class EditFail implements Action {
  readonly type = LabelActionTypes.EditFail;

  constructor(public payload: any) {
  }
}

export class Reset implements Action {
  readonly type = LabelActionTypes.Reset;

  constructor() {
  }
}

export type LabelActions =
  | Create
  | CreateSuccess
  | CreateFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Edit
  | EditSuccess
  | EditFail
  | Reset;
