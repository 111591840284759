import {IDocumentPreview} from './document-preview.interface';

export class DocumentPreview implements IDocumentPreview {
  readonly type = 'document_preview';
  constructor(public id,
              public page: number,
              public url: string,
              public createdAt = null,
              public updatedAt = null) {
  }
}
