import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './contact-person-organization-association.state';
import {ContactListDto, contactListDtoType} from '../../models/contact-list-dto.model';
import {getContactPersonsOfSelectedOrg, getAllContacts} from '../contact/contact.selectors';

export const stateKey = 'contact-person-organization-association';
const getContactPersonOrganizationAssociationState = createFeatureSelector<State>(stateKey)

export const {
  selectEntities: getContactPersonOrganizationAssociationEntities,
  selectAll: getAllContactPersonOrganizationAssociation,
} = adapter.getSelectors(getContactPersonOrganizationAssociationState);


/**
 * Returns all employees as ContactListDtos from a given organization
 * @param contactId
 */
export const getContactEmployeesOfContactOrganization = (contactId: string) => createSelector(
  getAllContactPersonOrganizationAssociation,
  getContactPersonsOfSelectedOrg(contactListDtoType.naturalPersonContact, []),
  (entities, contactPersons: ContactListDto[]) =>
    entities
      .filter(x => x.organizationContactId === contactId)
      .map(x => contactPersons.find(y => y.id === x.personContactId)
      )
);


/**
 * Returns all assignedOrganizations as ContactListDtos from a given person contact ID
 * @param personContactId
 */
export const getAssignedOrganizationsByPersonContactId = (personContactId: string) => createSelector(
  getAllContactPersonOrganizationAssociation,
  getAllContacts,
  (entities, contacts) =>
    entities
      .filter(e => e.personContactId === personContactId)
      .map(e => contacts.find(c => c.id === e.organizationContactId)
      )
);

/**
 * Returns a ContactListDto of the given ContactPersonContactOrganizationAssociationId
 * @param contactOrganizationId
 */
export const getContactEmployeesByPassoId = (contactEmployeeAssociationId: string) => createSelector(
  getAllContactPersonOrganizationAssociation,
  getContactPersonsOfSelectedOrg(contactListDtoType.naturalPersonContact, []),
  (entities, contactPersons: ContactListDto[]) => {
    const passo = entities.find(x => x.id === contactEmployeeAssociationId);
    return contactPersons.find((y: ContactListDto) => {
        const lookupId = passo ? passo.personContactId : '';
        return y.id === lookupId;
      }
    )
  });

export const getContactRelationship = (orgContactId: string, personContactId: string) => createSelector(
  getAllContactPersonOrganizationAssociation,
  (entities) => {
    const contactRel = entities.find(x => x.organizationContactId === orgContactId && x.personContactId === personContactId);
    return contactRel;
  }
)

