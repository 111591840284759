import {CdkStepper} from '@angular/cdk/stepper';
import {Component} from '@angular/core';

@Component({
  selector: 'dvtx-fivef-stepper',
  templateUrl: './fivef-stepper.html',
  styleUrls: ['./fivef-stepper.scss'],
  providers: [{provide: CdkStepper, useExisting: FivefStepper}]
})
export class FivefStepper extends CdkStepper {
  isNextButtonHidden() {
    return !(this.steps.length === this.selectedIndex + 1);
  }
}
