import {Action} from '@ngrx/store';
import {Fastdocs} from './fastdocs';

export enum FastdocsActionTypes {
  LoadAll = '[Fastdocs] Load All',
  LoadAllSuccess = '[Fastdocs] Load All Success',
  LoadAllFail = '[Fastdocs] Load All Fail',
  LoadOne = '[Fastdocs] Load One',
  LoadOneSuccess = '[Fastdocs] Load One Success',
  LoadOneFail = '[Fastdocs] Load One Fail',
  Reset = '[Fastdocs] Reset'
}

export class LoadAll implements Action {
  readonly type = FastdocsActionTypes.LoadAll;

  constructor(public loading = true) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = FastdocsActionTypes.LoadAllSuccess;

  constructor(public fastdocsItems: Fastdocs[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = FastdocsActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = FastdocsActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = FastdocsActionTypes.LoadOneSuccess;

  constructor(public fastdocsItem: Fastdocs) {
  }
}

export class LoadOneFail implements Action {
  readonly type = FastdocsActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Reset implements Action {
  readonly type = FastdocsActionTypes.Reset;

  constructor() {
  }
}

export type FastdocsActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Reset;
