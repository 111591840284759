import {adapter, initialState, State} from './contact.state';
import {ContactActions, ContactActionTypes} from './contact.actions';

export function reducer(state = initialState, action: ContactActions): State {
  switch (action.type) {
    case ContactActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case ContactActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
      case ContactActionTypes.LoadOneSuccess:
        return adapter.upsertOne(action.contact, {
          ...state,
          loading: false,
        });
    case ContactActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false
      };
    case ContactActionTypes.NewContactSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false
      });
    case ContactActionTypes.DeleteContactSuccess:
      return adapter.removeOne(action.id, state)
    case ContactActionTypes.ClearAll:
    case ContactActionTypes.Reload:
      return initialState
  }
  return state
}
