import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileRowButtonComponent} from './components/file-row-button/file-row-button.component';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {HumanFileSizePipe} from './pipes/human-file-size.pipe';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    HumanFileSizePipe,
    FileRowButtonComponent
  ],
  exports: [
    FileRowButtonComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ]
})
export class FileContainerModule {
}
