import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Wizard } from '../../../models/wizard';

@Component({
    selector: 'dvtx-wizard-bar',
    templateUrl: './wizard-bar.component.html',
    styleUrls: ['./wizard-bar.component.scss']
})

export class WizardComponent {
    @Input() wizards: Wizard[];
    @Input() currentWizard: Wizard;
    @Output() selectWizard: EventEmitter<Wizard> = new EventEmitter();

    constructor() {}

    setCurrentWizard(wizard: Wizard): void {
        // previous wizard
        const prevWizard = this.wizards[wizard.id - 1];

        // check if prevwizard exists(means that current wizard isn't first) and is filled
        if (prevWizard && !prevWizard.isFilled) {
            return;
        }
        this.selectWizard.emit(wizard);
    }
}
