import {Action} from '@ngrx/store';
import {PrivilegedProcess} from './process-management';

export enum ProcessManagementActionTypes {
  LoadOne = '[ProcessManagement] Load One',
  LoadOneSuccess = '[ProcessManagement] Load One Success',
  LoadOneFail = '[ProcessManagement] Load One Fail',
}

export class LoadOne implements Action {
  readonly type = ProcessManagementActionTypes.LoadOne;

  constructor(public processId: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = ProcessManagementActionTypes.LoadOneSuccess;

  constructor(public process: PrivilegedProcess) {
  }
}

export class LoadOneFail implements Action {
  readonly type = ProcessManagementActionTypes.LoadOneFail;

  constructor(public payload: any = null) {
  }
}

export type ProcessManagementActions =
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
