<div class="five-f-mat-table" *ngIf="dataSource?.data?.length">
  <h2 class="mt-2">{{ 'CUSTOMIZATION.AVAILABLE_LOGOS' | translate }}</h2>

  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="icon">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let logo"> <mat-icon>image</mat-icon></mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef>{{ 'GENERAL.TITLE' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let logo"> {{ logo.title }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="current">
      <mat-header-cell *matHeaderCellDef>{{ 'GENERAL.PUBLISHED' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let logo">
        <mat-slide-toggle color="primary" [checked]="logo?.current" (change)="publish(logo, $event)">
          {{ 'GENERAL.PUBLISHED' | translate }}
        </mat-slide-toggle>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="preview">
      <mat-header-cell *matHeaderCellDef>{{ 'GENERAL.PREVIEW' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let logo">
        <img [src]="logo?.url" height="60px" *ngIf="logo?.url" />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>{{ 'GENERAL.ACTION_TITLE' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let logo">
        <button mat-icon-button color="gray" (click)="remove(logo)">
          <mat-icon>delete</mat-icon>
        </button>
        <!--<br />-->
        <!--<button mat-button>-->
          <!--<mat-icon>edit</mat-icon>-->
          <!--{{ 'GENERAL.RENAME_ACTION' | translate }}-->
        <!--</button>-->
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let rowData; columns: columnsToDisplay"></mat-row>
  </mat-table>
</div>
