import {Action} from '@ngrx/store';
import {QuickCollector} from './quickcollector';
import {IQuickCollectorParams} from './quickcollector.interface';
import {CollectorItem} from '../collector-item/collector-item';
import {CollectorCategory} from '../collector-category/collector-category';
import {IBomDataNode, IBomDataNodeParams} from '../bom/bom-data-node.interface';

export enum QuickCollectorActionTypes {
  Create = '[QuickCollector] Create',
  CreateSuccess = '[QuickCollector] Create Success',
  CreateFail = '[QuickCollector] Create Fail',
  Publish = '[QuickCollector] Publish',
  PublishSuccess = '[QuickCollector] Publish Success',
  PublishFail = '[QuickCollector] Publish Fail',
  NavigateToDashboard = '[QuickCollector] Navigate To Dashboard',
  SaveDraft = '[QuickCollector] Save Draft',
  SaveDraftSuccess = '[QuickCollector] Save Draft Success',
  SaveDraftFail = '[QuickCollector] Save Draft Fail',
  UpdateRecipients = '[QuickCollector] Update Recipients',
  UpdateRecipientsSuccess = '[QuickCollector] Update Recipients Success',
  UpdateRecipientsFail = '[QuickCollector] Update Recipients Fail',
  UpdateWorkers = '[QuickCollector] Update Workers',
  UpdateWorkersSuccess = '[QuickCollector] Update Workers Success',
  UpdateWorkersFail = '[QuickCollector] Update Workers Fail',
  LoadAll = '[QuickCollector] Load All',
  LoadAllSuccess = '[QuickCollector] Load All Success',
  LoadAllFail = '[QuickCollector] Load All Fail',
  LoadOne = '[QuickCollector] Load One',
  LoadOneSuccess = '[QuickCollector] Load One Success',
  LoadOneFail = '[QuickCollector] Load One Fail',
  OrderItems = '[QuickCollector] Order Items',
  OrderItemsSuccess = '[QuickCollector] Order Items Success',
  OrderItemsFail = '[QuickCollector] Order Items Fail',
  RemoveCategoryResponsible = '[QuickCollector] Remove Category Responsible',
  AddCategoryResponsible = '[QuickCollector] Add Category Responsible',
  RemoveItemResponsible = '[QuickCollector] Remove Item Responsible',
  AddItemResponsible = '[QuickCollector] Add Item Responsible',
  LockItemUpload = '[QuickCollector] LockItemUpload',
  LockCategoryUpload = '[QuickCollector] LockCategoryUpload',
  UnlockItemUpload = '[QuickCollector] UnlockItemUpload',
  UnlockCategoryUpload = '[QuickCollector] UnlockCategoryUpload',
  Duplicate = '[QuickCollector] Duplicate',
  DuplicateSuccess = '[QuickCollector] Duplicate Success',
  DuplicateFail = '[QuickCollector] Duplicate Fail',
  PrefetchAttachments = '[QuickCollector] PrefetchAttachments',
  PrefetchAttachmentsSuccess = '[QuickCollector] PrefetchAttachments Success',
  PrefetchAttachmentsFail = '[QuickCollector] PrefetchAttachments Fail',
  LoadDataNodes = '[QuickCollector] LoadDataNodes',
  LoadDataNodesSuccess = '[QuickCollector] LoadDataNodes Success',
  LoadDataNodesFail = '[QuickCollector] LoadDataNodes Fail',
  UpsertDataNodes = '[QuickCollector] UpsertDataNodes',
  UpsertDataNodesSuccess = '[QuickCollector] UpsertDataNodes Success',
  UpsertDataNodesFail = '[QuickCollector] UpsertDataNodes Fail',
}

export class Create implements Action {
  readonly type = QuickCollectorActionTypes.Create;

  constructor(public params: IQuickCollectorParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = QuickCollectorActionTypes.CreateSuccess;

  constructor(public quickcollector: QuickCollector) {
  }
}

export class CreateFail implements Action {
  readonly type = QuickCollectorActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Publish implements Action {
  readonly type = QuickCollectorActionTypes.Publish;

  constructor(public id: string) {
  }
}

export class PublishSuccess implements Action {
  readonly type = QuickCollectorActionTypes.PublishSuccess;

  constructor(public quickcollector: QuickCollector) {
  }
}

export class PublishFail implements Action {
  readonly type = QuickCollectorActionTypes.PublishFail;

  constructor(public payload: any) {
  }
}

export class NavigateToDashboard implements Action {
  readonly type = QuickCollectorActionTypes.NavigateToDashboard;

  constructor(public id: string) {
  }
}

export class SaveDraft implements Action {
  readonly type = QuickCollectorActionTypes.SaveDraft;

  constructor(public id: string, public params) {
  }
}

export class SaveDraftSuccess implements Action {
  readonly type = QuickCollectorActionTypes.SaveDraftSuccess;

  constructor(public quickcollector: QuickCollector) {
  }
}

export class SaveDraftFail implements Action {
  readonly type = QuickCollectorActionTypes.SaveDraftFail;

  constructor(public payload: any) {
  }
}

export class UpdateRecipients implements Action {
  readonly type = QuickCollectorActionTypes.UpdateRecipients;

  constructor(public id: string, public recipients: string[]) {
  }
}
export class AddItemResponsible implements Action {
  readonly type = QuickCollectorActionTypes.AddItemResponsible;

  constructor(public collectorId: string, public categoryId: string, public itemId: string, public participantId: string) {
  }
}
export class RemoveItemResponsible implements Action {
  readonly type = QuickCollectorActionTypes.RemoveItemResponsible;

  constructor(public collectorId: string, public categoryId: string, public itemId: string) {
  }
}
export class AddCategoryResponsible implements Action {
  readonly type = QuickCollectorActionTypes.AddCategoryResponsible;

  constructor(public collectorId: string, public categoryId: string, public participantId: string) {
  }
}
export class RemoveCategoryResponsible implements Action {
  readonly type = QuickCollectorActionTypes.RemoveCategoryResponsible;

  constructor(public collectorId: string, public categoryId: string) {
  }
}
export class LockItemUpload implements Action {
  readonly type = QuickCollectorActionTypes.LockItemUpload;

  constructor(public collectorId: string, public categoryId: string, public itemId: string) {
  }
}
export class LockCategoryUpload implements Action {
  readonly type = QuickCollectorActionTypes.LockCategoryUpload;

  constructor(public collectorId: string, public categoryId: string) {
  }
}
export class UnlockItemUpload implements Action {
  readonly type = QuickCollectorActionTypes.UnlockItemUpload;

  constructor(public collectorId: string, public categoryId: string, public itemId: string) {
  }
}
export class UnlockCategoryUpload implements Action {
  readonly type = QuickCollectorActionTypes.UnlockCategoryUpload;

  constructor(public collectorId: string, public categoryId: string) {
  }
}

export class UpdateRecipientsSuccess implements Action {
  readonly type = QuickCollectorActionTypes.UpdateRecipientsSuccess;

  constructor(public quickcollector: QuickCollector) {
  }
}

export class UpdateRecipientsFail implements Action {
  readonly type = QuickCollectorActionTypes.UpdateRecipientsFail;

  constructor(public payload: any) {
  }
}

export class UpdateWorkers implements Action {
  readonly type = QuickCollectorActionTypes.UpdateWorkers;

  constructor(public id: string, public workers: string[]) {
  }
}

export class UpdateWorkersSuccess implements Action {
  readonly type = QuickCollectorActionTypes.UpdateWorkersSuccess;

  constructor(public quickcollector: QuickCollector) {
  }
}

export class UpdateWorkersFail implements Action {
  readonly type = QuickCollectorActionTypes.UpdateWorkersFail;

  constructor(public payload: any) {
  }
}

export class OrderItems implements Action {
  readonly type = QuickCollectorActionTypes.OrderItems;

  constructor(public id: string, public items: CollectorItem[] | CollectorCategory[]) {
  }
}

export class OrderItemsSuccess implements Action {
  readonly type = QuickCollectorActionTypes.OrderItemsSuccess;

  constructor(public payload: any) {
  }
}

export class OrderItemsFail implements Action {
  readonly type = QuickCollectorActionTypes.OrderItemsFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = QuickCollectorActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = QuickCollectorActionTypes.LoadAllSuccess;

  constructor(public quickcollectors: QuickCollector[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = QuickCollectorActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = QuickCollectorActionTypes.LoadOne;

  constructor(public id: string, public loading = true) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = QuickCollectorActionTypes.LoadOneSuccess;

  constructor(public quickcollector: QuickCollector) {
  }
}

export class LoadOneFail implements Action {
  readonly type = QuickCollectorActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Duplicate implements Action {
  readonly type = QuickCollectorActionTypes.Duplicate;

  constructor(public id: string, public includeTemplates: boolean, public includeDueDates: boolean) {
  }
}

export class DuplicateSuccess implements Action {
  readonly type = QuickCollectorActionTypes.DuplicateSuccess;

  constructor(public quickcollector: QuickCollector) {
  }
}

export class DuplicateFail implements Action {
  readonly type = QuickCollectorActionTypes.DuplicateFail;

  constructor(public payload: any) {
  }
}
export class PrefetchAttachments implements Action {
  readonly type = QuickCollectorActionTypes.PrefetchAttachments;

  constructor(public id: string) {
  }
}

export class PrefetchAttachmentsSuccess implements Action {
  readonly type = QuickCollectorActionTypes.PrefetchAttachmentsSuccess;

  constructor() {
  }
}

export class PrefetchAttachmentsFail implements Action {
  readonly type = QuickCollectorActionTypes.PrefetchAttachmentsFail;

  constructor() {
  }
}

export class LoadDataNodes implements Action {
  readonly type = QuickCollectorActionTypes.LoadDataNodes;

  constructor(public processId: string, public parentNodeId: string = null) {
  }
}

export class LoadDataNodesSuccess implements Action {
  readonly type = QuickCollectorActionTypes.LoadDataNodesSuccess;

  constructor(public nodes: IBomDataNode[]) {
  }
}

export class LoadDataNodesFail implements Action {
  readonly type = QuickCollectorActionTypes.LoadDataNodesFail;

  constructor() {
  }
}

export class UpsertDataNodes implements Action {
  readonly type = QuickCollectorActionTypes.UpsertDataNodes;

  constructor(public processId: string, public parentNodeId: string = null) {
  }
}

export class UpsertDataNodesSuccess implements Action {
  readonly type = QuickCollectorActionTypes.UpsertDataNodesSuccess;

  constructor(public nodes: IBomDataNode[]) {
  }
}

export class UpsertDataNodesFail implements Action {
  readonly type = QuickCollectorActionTypes.UpsertDataNodesFail;

  constructor() {
  }
}

export type QuickCollectorActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Publish
  | PublishSuccess
  | PublishFail
  | NavigateToDashboard
  | SaveDraft
  | SaveDraftSuccess
  | SaveDraftFail
  | UpdateRecipients
  | UpdateRecipientsSuccess
  | UpdateRecipientsFail
  | UpdateWorkers
  | UpdateWorkersSuccess
  | UpdateWorkersFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | OrderItems
  | OrderItemsSuccess
  | OrderItemsFail
  | Duplicate
  | DuplicateSuccess
  | DuplicateFail
  | PrefetchAttachments
  | PrefetchAttachmentsSuccess
  | PrefetchAttachmentsFail
  | LoadDataNodes
  | LoadDataNodesSuccess
  | LoadDataNodesFail
  | UpsertDataNodes
  | UpsertDataNodesSuccess
  | UpsertDataNodesFail;
