import {IFavorite} from './favorite.interface';

export class Favorite implements IFavorite {
  readonly type = 'favorites';
  color: string = '#fff';

  constructor(public id,
              public title: string,
              public resourceId: string,
              public resourceType: string,
              public confirmedAt: Date = null) {}
}
