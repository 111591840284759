import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {UserSignatureBuilder} from './user-signature.builder';
import {UserSignature} from './user-signature';

@Injectable()
export class UserSignatureService {
  readonly basePath = 'api/v1/organizations/email_footers';
  builder: UserSignatureBuilder;

  constructor(private _http: ApiResourceService) {
    this.builder = new UserSignatureBuilder();
  }

  getAll() {
    return <Observable<UserSignature[]>>this._http.get<UserSignatureBuilder, UserSignature>(this.builder, `${this.basePath}`);
  }

  create(userSignature: UserSignature): Observable<UserSignature> {
    const payload = this.builder.toRequest(userSignature);
    return this._http.post<UserSignatureBuilder, UserSignature>(this.builder, `${this.basePath}` , payload);
  }

  update(userSignature: UserSignature): Observable<UserSignature> {
    const payload = this.builder.toRequest(userSignature);
    return this._http.put<UserSignatureBuilder, UserSignature>(this.builder, `${this.basePath}/${userSignature.id}`, payload);
  }

  remove(id: string): Observable<UserSignature> {
    return this._http.del<UserSignatureBuilder, UserSignature>(this.builder, `${this.basePath}/${id}`);
  }

}

