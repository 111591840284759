import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {LabelActions, LabelSelectors, MembershipSelectors, OrganizationSelectors} from 'app/+store';
import {Label} from 'app/+store/label/label';
import {LabelScope} from 'app/+store/label/label.interface';
import {Organization} from 'app/models/organization.model';
import {distinctUntilChanged, filter, first, takeUntil} from 'rxjs/operators';
import {GLOBAL_LABELS} from 'app/five-f/labels/configs';
import {Membership} from 'app/models/membership.model';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ITabNavRoute} from 'app/five-f/organization-card/models/tab-nav-route.interface';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {LabelsListingComponent} from '../../organization-view/labels-listing/labels-listing.component';

@Component({
  selector: 'dvtx-labels-settings-frame',
  templateUrl: './labels-settings-frame.component.html',
  styleUrls: ['./labels-settings-frame.component.scss']
})
export class LabelsSettingsFrameComponent implements AfterViewInit, OnInit, OnDestroy {
  private onDestroy = new Subject();

  public scope = LabelScope;
  myMembership: Observable<Membership | undefined>;
  labels$: Observable<Label[]>;

  public gloablLabels = GLOBAL_LABELS;
  public organizationLabels$: BehaviorSubject<Label[]> = new BehaviorSubject(undefined);
  public userLabels$: BehaviorSubject<Label[]> = new BehaviorSubject(undefined);

  public organization$: Observable<Organization>;
  public returnUrl: string;

  selectedTab: any = 0;

  organization;

  public orgId;
  public routes: ITabNavRoute[] = [];
  public activeLink: string;

  @ViewChild('callToActionRef', { static: true }) callToActionRef: TemplateRef<any>;
  callToActionPortal: Portal<any>;

  @ViewChild('labelListingRef', { static: true }) labelListingRef: LabelsListingComponent;

  searchTerm: string = '';

  constructor(private _store: Store<AppState>,
              public fb: UntypedFormBuilder,
              public dialog: MatDialog,
              private _route: ActivatedRoute,
              private _viewContainerRef: ViewContainerRef) {
    this.myMembership = this._store.select(MembershipSelectors.getMyMembership);

    this.labels$ = this._store.select(LabelSelectors.getAllExceptContextual);
  }

  ngOnInit(): void {
    this.orgId = this._route.snapshot.params.id;

    this.organization$ = this._store.select(OrganizationSelectors.getSelected).pipe(
      filter(organization => !!organization),
      distinctUntilChanged(),
      takeUntil(this.onDestroy)
    )
    const currentUser$ = this._store.select('currentUser').pipe(
      filter(user => !!user),
      first()
    );

    combineLatest(this.organization$, currentUser$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([organization, user]) => {
        if (organization.id && user.uid) {
          this.organization = organization;
          this._store.dispatch(new LabelActions.Reset());
          this._store.dispatch(new LabelActions.LoadAll());
        }
      });

    this.routes = [{
      title: 'LABELS.ORGANIZATOIN_LABELS',
      route: `/organization/${this.orgId}/labels-settings/organization`
    }, {
      title: 'LABELS.5F_GLOBAL_LABELS',
      route: `/organization/${this.orgId}/labels-settings/global`,
    }];
    this.activeLink = this.routes[0].title;

    this.labels$.pipe(
      filter(labels => !!labels),
      distinctUntilChanged(),
      takeUntil(this.onDestroy)
    ).subscribe(labels => {
      const organizationLabels = [];
      const userLabels = [];
      labels.forEach(label => {
        switch (label.scope) {
          case LabelScope.ORGANIZATIONAL:
            organizationLabels.push(label);
            break;
          // case LabelScope.PERSONAL:
          //   userLabels.push(label);
          //   break;
        }
        this.organizationLabels$.next(organizationLabels);
        this.userLabels$.next(userLabels);

        this.routes = [{
          title: 'LABELS.ORGANIZATOIN_LABELS',
          route: `/organization/${this.orgId}/labels-settings/organization`,
          count: organizationLabels.length
        }, {
          title: 'LABELS.5F_GLOBAL_LABELS',
          route: `/organization/${this.orgId}/labels-settings/global`,
        }];
      })
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  createLabel() {
    this.labelListingRef.createLabel();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.callToActionPortal = this._createPortal(this.callToActionPortal, this.callToActionRef);
    });
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }

  public search($event) {
    this.searchTerm = $event;
  }
}
