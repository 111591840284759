import {IShoppingCart} from '../models/shopping-cart.interface';
import { LineItem } from '../models/line-item';
import { RelationBuilder } from './relation.builder';
import { SalesOrder } from '../models/sales-order';

/**
 * Create LineItems - the respresentation of articles in
 * the shopping cart.
 */
export class SalesOrderBuilder {
  public static toRequest(_: IShoppingCart) {
    throw new Error('Not implemented');
  }

  public static fromResponse(response, catalog = null): SalesOrder {
    const attrs = response.attributes;
    const order = new SalesOrder(response.id, attrs.status, attrs.first_name, attrs.last_name, attrs.organization_name,
                                 attrs.street, attrs.street_no, attrs.zip, attrs.city, attrs.country_name,
                                 attrs.ustid, attrs.due_date, attrs.created_at, attrs.updated_at);

    if (response.relationships && catalog) {
      const relationships = response.relationships;
      order.lineItems = <LineItem[]>RelationBuilder.build(relationships.line_items, catalog);
    }
    return order;
  }
}
