import { Component, OnInit } from '@angular/core';
import { ITabNavRoute } from 'app/five-f/organization-card/models/tab-nav-route.interface';
import { User } from 'app/models/user';
import { Observable } from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';

@Component({
  selector: 'dvtx-auto-logout-container',
  templateUrl: './auto-logout-container.component.html',
  styleUrls: ['./auto-logout-container.component.scss']
})
export class AutoLogoutContainerComponent implements OnInit {
  public routes: ITabNavRoute[];
  public activeLink: string;
  public currentUser$: Observable<User>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.currentUser$ = this._store.select('currentUser');
    this.routes = [{
      title: 'AUTH.TITLE',
      route: '/user-profile/security/two-factor-authentication'
    }, {
      title: 'AUTH.PASSWORD_RESET',
      route: '/user-profile/security/password'
    }, {
      title: 'AUTH.AUTO_LOGOUT',
      route: '/user-profile/security/auto-logout'
    }];
    this.activeLink = this.routes[2].title
  }
}
