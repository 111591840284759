import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {Favorite} from './favorite';

export class FavoriteBuilder implements IApiResourceBuilder<Favorite> {
  fromResponse(data): Favorite {
    return new Favorite(
      data.id,
      data.attributes.title,
      data.attributes.resource_id,
      data.attributes.resource_type,
      data.attributes.confirmed_at
    );
  }

  toRequest(favorite: Favorite) {
    return {
      data: {
        attributes: {
          title: favorite.title,
          resource_id: favorite.resourceId,
          resource_type: favorite.resourceType,
        },
        type: 'favorites'
      }
    };
  }
}
