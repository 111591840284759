import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './quickshare-recipient.state';

export const stateKey = 'quickshare-recipient';
const getQuickshareRecipientState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getQuickshareRecipientEntities,
  selectAll: getAllQuickshareRecipients,
} = adapter.getSelectors(getQuickshareRecipientState);

export const getSelected = createSelector(
  getQuickshareRecipientState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getQuickshareRecipientEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllQuickshareRecipients,
  (recipients) => recipients
);

export const getByQuickshareId = (id: string) => createSelector(
  getAllQuickshareRecipients,
  (recipients) => recipients.filter(recipient => recipient.quickshareId === id)
);

export const loadingState = createSelector(
  getQuickshareRecipientState,
  (state) => state.loading
);
