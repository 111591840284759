<button class="d-flex align-items-center" mat-menu-item
        [dvtxRequiredLicence]="'Standard'"
        (dvtxAction)="redirectToLink([link])">
    <span style="position: relative">
      <mat-icon [svgIcon]="icon"></mat-icon>
      <!-- <span class="five-f-beta-feature-button-tag dvtx-pointer"
                 style="right: 5px">{{ 'GENERAL.NEW' | translate }}</span>-->
    </span>
  <div class="item__text d-flex flex-column">
    <span>{{title | translate}}</span>
    <sub>{{subTitle | translate}}</sub>
  </div>
</button>
