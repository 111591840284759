import {EmailFooterActions, EmailFooterActionTypes} from './email-footer.actions';
import {adapter, initialState, State} from './email-footer.state';

export function reducer(state = initialState, action: EmailFooterActions): State {
  switch (action.type) {
    case EmailFooterActionTypes.LoadAll:
    case EmailFooterActionTypes.Create:
    case EmailFooterActionTypes.Updated:
    case EmailFooterActionTypes.Delete:
      return {
        ...state,
        loading: true
      };

      case EmailFooterActionTypes.LoadAllSuccess:
      return adapter.setAll(action.footer, {
          ...state,
          loading: false,
      });

      case EmailFooterActionTypes.UpdatedSuccess:
      return adapter.upsertOne(action.footer , {
        ...state,
        loading: false,
      });

      case EmailFooterActionTypes.CreateSuccess:
      return adapter.addOne( action.footer , {
        ...state,
        loading: false,
      });

      case EmailFooterActionTypes.DeleteSuccess:
      return adapter.removeOne(action.footer.id , {
        ...state,
        loading: false,
      });

      case EmailFooterActionTypes.LoadAllFail:
      case EmailFooterActionTypes.CreateFail:
      case EmailFooterActionTypes.DeleteFail:
      case EmailFooterActionTypes.UpdatedFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}


