import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {TaskEvent} from './task-event';

export enum TaskEventActionTypes {
  LoadAllForTask = '[TaskEvent] Load All',
  LoadAllForTaskSuccess = '[TaskEvent] Load All Success',
  LoadAllForTaskFail = '[TaskEvent] Load All Fail'
}

export class LoadAllForTask implements Action {
  readonly type = TaskEventActionTypes.LoadAllForTask;

  constructor(public id: string) {
  }
}

export class LoadAllForTaskSuccess implements Action {
  readonly type = TaskEventActionTypes.LoadAllForTaskSuccess;

  constructor(public payload: TaskEvent[]) {
  }
}

export class LoadAllForTaskFail implements Action {
  readonly type = TaskEventActionTypes.LoadAllForTaskFail;

  constructor(public payload: any) {
  }
}

export type TaskEventActions =
  | LoadAllForTask
  | LoadAllForTaskSuccess
  | LoadAllForTaskFail;
