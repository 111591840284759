import { Component, OnDestroy, Input, ChangeDetectionStrategy, NgZone } from "@angular/core";
import { Subject ,  Observable } from "rxjs";
import { TaskType, TaskTypeTitle } from "app/+store/task/task.interface";
import { ContactListDto } from "app/models/contact-list-dto.model";
import { UntypedFormGroup } from "@angular/forms";
import { Task } from "app/+store/task/task";
import { Store } from "@ngrx/store";
import { AppState } from "app/reducers";
import { filter, takeUntil, distinctUntilChanged, map } from "rxjs/operators";
import { TaskAssigneeActions, ContactSelectors } from "app/+store";

@Component({
  selector: 'dvtx-task-settings',
  templateUrl: './task-settings.component.html',
  styleUrls: ['./task-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskSettingsComponent implements OnDestroy {
  protected onDestroy = new Subject();
  taskTypes = TaskType;
  taskTypeTitle = TaskTypeTitle;
  contacts$: Observable<ContactListDto[]>;

  @Input() task: Task;
  @Input() form: UntypedFormGroup;

  constructor(private _store: Store<AppState>, private _ngZone: NgZone) {
    this.contacts$ = this._store.select(ContactSelectors.getMembersAndContactPersonsOfSelectedOrg()).pipe(
      filter(contacts => !!contacts),
      distinctUntilChanged(),
      takeUntil(this.onDestroy),
      map(contacts => contacts.filter(contact => contact.hasAccount))
    );
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  onTitleChanges(title) {
    this.form.get('title').setValue(title);
  }

  onResponsibleChanges(email) {
    this.form.get('responsibleEmail').setValue(email);
  }

  onAssigneesAdded(email) {
    if (this.task && this.task.id && !this.task.assignees.find(assignee => assignee.email === email)) {
      this._ngZone.runOutsideAngular(() => {
        this._store.dispatch(new TaskAssigneeActions.AddAssignment(this.task.id, email));
      });
    }
    this.form.get('assignees').setValue([...this.form.get('assignees').value, email]);
  }

  onAssigneesRemoved(email) {
    if (this.task && this.task.id && this.task.assignees.find(assignee => assignee.email === email)) {
      this._ngZone.runOutsideAngular(() => {
        const assignee = this.task.assignees.find(assignee => assignee.email === email);
        this._store.dispatch(new TaskAssigneeActions.Delete(this.task.id, assignee.id));
      });
    }

    this.form.get('assignees').setValue(this.form.get('assignees').value.filter(assignee => assignee !== email));
  }

  onPriorityChanges(priority) {
    this.form.get('priority').setValue(priority.id);
  }
}
