import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './licence-assignment.state';
import {MembershipSelectors} from '../membership'

export const stateKey = 'licenceAssignment';
const getLicenceAssignmentState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getLicenceAssignmentEntities,
  selectAll: getAllLicenceAssignments,
} = adapter.getSelectors(getLicenceAssignmentState);

export const getMyLicenceAssignments = createSelector(
  getAllLicenceAssignments,
  MembershipSelectors.getMyMembershipId,
  (assignments, id) => assignments.filter(assignment => assignment)
)

export const getAvailableLicenceCount = createSelector(
  getMyLicenceAssignments,
  (licences) => {
    return licences && licences[0] && licences[0].attributes && licences[0].attributes.availableUserLicences || 0;
  }
)

export const hasLicenceAssignment = (licence) => createSelector(
  getMyLicenceAssignments,
  (assignments) => {
    return assignments.filter(assignment => {
      switch (licence) {
        case LICENCES_TYPES.STANDARD: {
          return assignment.hasStandardLicence === true || assignment.hasProfessionalLicence === true || assignment.hasEnterpriseLicence === true;
        }
        case LICENCES_TYPES.PROFESSIONAL: {
          return assignment.hasProfessionalLicence === true || assignment.hasEnterpriseLicence === true;
        }
        case LICENCES_TYPES.ENTERPRISE: {
          return assignment.hasEnterpriseLicence === true;
        }
      }
    }).length > 0 ? true : false
  }
);

export const getMyLicenceAssignmentsAttachedOrganizations = createSelector(
  getLicenceAssignmentState,
  (state) => state.licencedOrganizations
)

export const LICENCES_TYPES = {
  BASIC           : 'Basic',
  STANDARD        : 'Standard',
  PROFESSIONAL    : 'Professional',
  ENTERPRISE      : 'Enterprise'
}
