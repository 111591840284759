import { Component, Inject, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dvtx-quick-share-file-choose-dialog-container',
  templateUrl: './quick-share-file-choose-dialog.component.html',
})
export class QuickShareChooseDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<QuickShareChooseDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

  onDownload() {}
}
