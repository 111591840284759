import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {ProcessFolder} from './process-folder';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';

export class ProcessFolderBuilder implements IApiResourceBuilder<ProcessFolder> {
  constructor(private _processId: string) {}

  fromResponse(data): ProcessFolder {
    const _sharedBy = data.attributes.shared_by;

    let sharedBy = null;
    if (_sharedBy === 'private' || _sharedBy === 'User::Account') {
      sharedBy = DmsAccountType.Private;
    } else if (_sharedBy === 'organization') {
      sharedBy = DmsAccountType.Organization;
    }
    return new ProcessFolder(
      this._processId,
      data.attributes.dms_folder_id,
      data.attributes.name,
      data.attributes.path || '',
      data.attributes.admin,
      data.attributes.owned_by,
      sharedBy,
      data.attributes.owned_by_me,
      data.attributes.parent_id,
      data.attributes.folder_count,
      data.attributes.process_count,
      data.attributes.document_count,
      data.attributes.shared,
      data.attributes.audit_proof,
      data.attributes.folder_sync_authorized,
      data.attributes.created_at,
      data.attributes.updated_at);
  }

  toRequest(_: ProcessFolder) {
    return null;
  }
}
