import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './artifact-keyword.state';
import {OrganizationSelectors} from 'app/+store/organization';

export const stateKey = 'artifact-keywords';
const getArtifactKeywordState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getArtifactKeywordEntities,
  selectAll: getAllArtifactKeywords,
} = adapter.getSelectors(getArtifactKeywordState);

/**
 * Returns keywords of the given organization.
 * If the organization is not part of the users memberships then an empty array is returned.
 * @param orgId
 */
export const getArtifactKeywordsOfOrg = (orgId: string) => createSelector(
  OrganizationSelectors.getById(orgId),
  getAllArtifactKeywords,
  (organization, keywords) => {
    if (!organization) {
      return [];
    }
    return keywords.filter(c => orgId && c.organizationId === orgId)
  }
);

export const loadingState = createSelector(
  getArtifactKeywordState,
  (state) => state.loading
);
