import {Injectable} from '@angular/core';
import {ITemplateCategoryParams, WorkflowTemplateCategory} from "./workflow-template-category";
import {WorkflowTemplateCategoryBuilder} from "./workflow-template-category.builder";
import {WtResourceService} from "../workflow-template/wt-api-resource.service";
import {Observable} from 'rxjs';

@Injectable()
export class WorkflowTemplateCategoryService {
  readonly BASE_PATH = 'api/v1';

  constructor(private _http: WtResourceService) {
  }

  getAll(): Observable<WorkflowTemplateCategory[]> {
    const builder = new WorkflowTemplateCategoryBuilder();
    return <Observable<WorkflowTemplateCategory[]>>this._http.get<WorkflowTemplateCategoryBuilder, WorkflowTemplateCategory>(builder, `${this.BASE_PATH}/workflow_template_categories`);
  }

  getOne(id: string): Observable<WorkflowTemplateCategory> {
    const builder = new WorkflowTemplateCategoryBuilder();
    return <Observable<WorkflowTemplateCategory>>this._http.get<WorkflowTemplateCategoryBuilder, WorkflowTemplateCategory>(builder, `${this.BASE_PATH}/workflow_template_categories/${id}`);
  }

  delete(id: string): Observable<WorkflowTemplateCategory> {
    const builder = new WorkflowTemplateCategoryBuilder();
    return <Observable<WorkflowTemplateCategory>>this._http.del<WorkflowTemplateCategoryBuilder, WorkflowTemplateCategory>(builder, `${this.BASE_PATH}/workflow_template_categories/${id}`);
  }

  create({title, color}: ITemplateCategoryParams): Observable<WorkflowTemplateCategory> {
    const builder = new WorkflowTemplateCategoryBuilder();
    const payload = {data: {attributes: {title, color}}};
    return <Observable<WorkflowTemplateCategory>>this._http.post<WorkflowTemplateCategoryBuilder, WorkflowTemplateCategory>(builder, `${this.BASE_PATH}/workflow_template_categories`, payload);
  }

  update(id: string, data: any): Observable<WorkflowTemplateCategory> {
    const builder = new WorkflowTemplateCategoryBuilder();
    const payload = {data: {attributes: {}}};
    if (data.title) payload.data.attributes['title'] = data.title;
    if (data.color) payload.data.attributes['color'] = data.color;
    return <Observable<WorkflowTemplateCategory>>this._http.put<WorkflowTemplateCategoryBuilder, WorkflowTemplateCategory>(builder, `${this.BASE_PATH}/workflow_template_categories/${id}`, payload);
  }
}
