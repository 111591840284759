import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { WorkflowTypeSelectorComponent } from "./component/workflow-type-selector.component";
import { WorkflowIconModule } from "app/five-f/workflow-icon/workflow-icon.module";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatSelectModule,
    WorkflowIconModule
  ],
  declarations: [
    WorkflowTypeSelectorComponent
  ],
  exports: [
    WorkflowTypeSelectorComponent
  ]
})
export class WorkflowTypeSelectorModule {
}
