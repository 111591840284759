<div class="steps-bar mb-2">
  <div class="row flex-nowrap">
    <ng-container *ngFor="let wizard of wizards">
      <div class="col mb-1" *ngIf="wizard" (click)="setCurrentWizard(wizard)">
        <div class="step-item d-flex w-100 h-100" [ngClass]="{'active': wizard.isFilled && wizard.id !== currentWizard.id, 'process': wizard.id === currentWizard.id}">
          <span class="num d-flex align-items-center font-weight-bold">{{ wizard.id }}</span>
          <span class="text d-flex align-items-center hidden-md-down">{{ wizard.title }}</span>
        </div>
      </div>
    </ng-container>

    <!--<div class="col-sm-6 col-lg-3 d-flex mb-1">
      <div class="step-item d-flex">
        <span class="num d-flex align-items-center font-weight-bold">4</span>
        <span class="text d-flex align-items-center font-weight-bold text-uppercase">Start</span>
      </div>
    </div>-->
  </div>
</div>
