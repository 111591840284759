export class UserProfile {
  constructor(
    public title: string,
    public firstName: string,
    public lastName: string,
    public gender: string,
    public email: string,
  ) {
  }
}
