import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './cms-article.state';
import {OrganizationSelectors} from 'app/+store/organization';

export const stateKey = 'cms-article';
const getCmsArticleState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getCmsArticleEntities,
  selectAll: getAllCmsArticles,
} = adapter.getSelectors(getCmsArticleState);

export const getCmsArticlesOfSelectedOrg = createSelector(
  OrganizationSelectors.getSelectedId,
  getAllCmsArticles,
  (orgId, articles) => articles.filter(c => orgId && c.cmsBlogId === orgId)
);

export const getPublishedCmsArticlesOfSelectedOrg = createSelector(
  getCmsArticlesOfSelectedOrg,
  (articles) => articles.filter(article => !!article.publishedAt)
);

export const getStickyPublishedCmsArticlesOfSelectedOrg = createSelector(
  getPublishedCmsArticlesOfSelectedOrg,
  (articles) => articles.filter(article => !!article.sticky)
);

export const getUnstickyPublishedCmsArticlesOfSelectedOrg = createSelector(
  getPublishedCmsArticlesOfSelectedOrg,
  (articles) => articles.filter(article => !article.sticky)
);

export const loadingState = createSelector(
  getCmsArticleState,
  (state) => state.loading
);

export const getCmsArticleById = (id: string) => createSelector(
  getCmsArticlesOfSelectedOrg,
  (clients) => clients.find(client => client.id === id)
);
