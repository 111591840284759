import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {stateKey} from './contact-person-organization-association.selectors';
import {reducer} from './contact-person-organization-association.reducer';
import {ContactPersonOrganizationAssociationEffects} from './contact-person-organization-association.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ContactPersonOrganizationAssociationEffects])
  ],
  providers: [ContactPersonOrganizationAssociationEffects]
})
export class ContactPersonOrganizationAssociationStoreModule {
}
