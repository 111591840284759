import {Action} from '@ngrx/store';
import {Favorite} from './favorite';

export enum FavoriteActionTypes {
  LoadAll = '[Favorite] Load All',
  LoadAllSuccess = '[Favorite] Load All Success',
  LoadAllFail = '[Favorite] Load All Fail',
  Confirm = '[Favorite] Confirm',
  ConfirmSuccess = '[Favorite] Confirm Success',
  ConfirmFail = '[Favorite] Confirm Fail',
  Remove = '[Favorite] Remove Favorite',
  RemoveSuccess = '[Favorite] Remove Favorite Success',
  RemoveFail = '[Favorite] Remove Favorite Fail',
  LoadOne = '[Favorite] LoadOne Favorite',
  LoadOneSuccess = '[Favorite] LoadOne Favorite Success',
  LoadOneFail = '[Favorite] LoadOne Favorite Fail'
}

export class Confirm implements Action {

  readonly type = FavoriteActionTypes.Confirm;

  constructor(public favoriteId: string) {
  }
}

export class ConfirmSuccess implements Action {
  readonly type = FavoriteActionTypes.ConfirmSuccess;

  constructor(public favorite: Favorite) {
  }
}

export class ConfirmFail implements Action {
  readonly type = FavoriteActionTypes.ConfirmFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {

  readonly type = FavoriteActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = FavoriteActionTypes.LoadAllSuccess;

  constructor(public payload: Favorite[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = FavoriteActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = FavoriteActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = FavoriteActionTypes.RemoveSuccess;

  constructor(public favorite: Favorite) {
  }
}

export class RemoveFail implements Action {
  readonly type = FavoriteActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = FavoriteActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = FavoriteActionTypes.LoadOneSuccess;

  constructor(public favorite: Favorite) {
  }
}

export class LoadOneFail implements Action {
  readonly type = FavoriteActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type FavoriteActions =
  | Confirm
  | ConfirmSuccess
  | ConfirmFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
