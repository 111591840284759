export namespace UserAgentUtil {
  export function isIOS() {
    return (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) || (/iPad|iPhone|iPod/.test(navigator.userAgent))
  }

  export function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  export function isFirefox() {
    return /Firefox/.test(navigator.userAgent)
  }
}
