import {Component, OnInit} from '@angular/core';
import {OrganizationSelectors} from 'app/+store/organization';
import {Observable} from 'rxjs';
import {Organization} from 'app/models/organization.model';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ActivatedRoute} from '@angular/router';
import {ITabNavRoute} from 'app/five-f/organization-card/models/tab-nav-route.interface';

@Component({
  selector: 'dvtx-organization-lincences-frame',
  templateUrl: './licenses-frame.component.html',
  styleUrls: ['./licenses-frame.component.scss']
})
export class LicensesFrameComponent implements OnInit {
  public organization$: Observable<Organization>;
  public orgId;
  public routes: ITabNavRoute[] = [];
  public activeLink: string;

  constructor(private store: Store<AppState>, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this.organization$ = this.store.select(OrganizationSelectors.getSelected);
    this.orgId = this._route.snapshot.params.id;
    this.routes = [{
      title: 'ORGANIZATION_NAVIGATON.LICENSES',
      route: `/organization/${this.orgId}/licenses`
    }];
    this.activeLink = this.routes[0].title;
  }
}
