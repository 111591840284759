import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './task.state';
import {ProcessSelectors} from '../process';
import {Task} from './task';
import {TaskAssignmentStatus} from 'app/+store/task-assignee/task-assignee.interface';
import {DateUtil} from 'app/lib/date/date-util';
import {TaskAssignee} from 'app/+store/task-assignee/task-assignee';
import {TaskType} from './task.interface';
import {Sorters} from '../../lib/sorter/sorters';

export const stateKey = 'task';
const getTaskState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getTaskEntities,
  selectAll: getAllTaskModels,
} = adapter.getSelectors(getTaskState);

export const sortedTasksByProcess = () => createSelector(
  getAllTaskModels,
  (tasks) => tasks.sort((l, r) => Sorters.sortByDates(r.process.createdAt, l.process.createdAt))
);

// TASK
export const getTask = (id: string) => createSelector(
  getTaskEntities,
  (tasks) => tasks[id]
);

// ALL TASKS
export const getAllTasks = createSelector(
  sortedTasksByProcess(),
  (tasks) => tasks
);

// ALL TASKS
export const getAllStandardTasks = createSelector(
  sortedTasksByProcess(),
  (tasks) => tasks.filter(task => task.taskType !== TaskType.InvoiceApproval)
);
// ALL TASKS
export const getAllApprovalTasks = createSelector(
  sortedTasksByProcess(),
  (tasks) => tasks.filter(task => task.taskType === TaskType.InvoiceApproval)
);

export const getAllRunningTasks = () => createSelector(
  sortedTasksByProcess(),
  (tasks) => tasks.filter(task => !isTaskClosed(task))
);

export const getAllCompletedTasks = () => createSelector(
  sortedTasksByProcess(),
  (tasks) => tasks.filter(task => isTaskClosed(task))
);

export const getAllOverdueTasks = () => createSelector(
  sortedTasksByProcess(),
  (tasks) => tasks.filter(task => task.is.overdue)
);

export const getAllPendingTasks = () => createSelector(
  sortedTasksByProcess(),
  (tasks) => {
    return tasks.filter(task => {
      if (isTaskClosed(task)) return false;

      return task.assignees && task.assignees.find(assignee => assignee.status === 'PENDING');
    });
  }
);

export const getAllFarDueDateTasks = () => createSelector(
  sortedTasksByProcess(),
  (tasks) => {
    const today = new Date().toString();
    return tasks.filter(task => {
      if (task.is.overdue || isTaskClosed(task)) return false;
      if (!task.dates.dueDate) return true;

      const duration = DateUtil.daysDiff(task.dates.dueDate, today);
      return duration > 14
    });
  }
);

export const getAllNormalDueDateTasks = () => createSelector(
  sortedTasksByProcess(),
  (tasks) => {
    const today = new Date().toString();
    return tasks.filter(task => {
      if (task.is.overdue || !task.dates.dueDate || isTaskClosed(task)) return false;

      const duration = DateUtil.daysDiff(task.dates.dueDate, today);
      return duration <= 14 && duration > 7;
    });
  }
);

export const getAllNearDueDateTasks = () => createSelector(
  sortedTasksByProcess(),
  (tasks) => {
    const today = new Date().toString();
    return tasks.filter(task => {
      if (task.is.overdue || !task.dates.dueDate || isTaskClosed(task)) return false;

      const duration = DateUtil.daysDiff(task.dates.dueDate, today);
      return duration <= 7 && duration > 0;
    });
  }
);

export const getAllOverdueDueDateTasks = () => createSelector(
  sortedTasksByProcess(),
  (tasks) => tasks.filter(task => task.is.overdue && !isTaskClosed(task))
);


// ALL USER TASKS
export const getAllUserTasks = (email: string) => createSelector(
  getAllTasks,
  (tasks) => tasks.filter(task => isCreator(task, email) || isResponsible(task, email) || !!isAssignee(task, email))
);

export const getAllUserPendingTasks = (email: string) => createSelector(
  getAllUserTasks(email),
  (tasks) => tasks.filter(task => !isTaskClosed(task) && isPendingAssignee(task, email))
);

export const getAllUserRunningTasks = (email: string) => createSelector(
  getAllUserTasks(email),
  // (tasks) => tasks.filter(task => !isTaskClosed(task) && (isCreator(task, email) || isResponsible(task, email) || isAcceptedAssignee(task, email)))
  (tasks) => tasks.filter(task => !isTaskClosed(task) && !isPendingAssignee(task, email) && (isCreator(task, email) || isResponsible(task, email) || isAcceptedAssignee(task, email)))
);

export const getAllUserCompletedTasks = (email: string) => createSelector(
  getAllUserTasks(email),
  (tasks) => tasks.filter(task => isTaskClosed(task))
);

export const getUserOverdueTasks = (email: string) => createSelector(
  getAllUserTasks(email),
  (tasks) => tasks.filter(task => task.is.overdue && !isTaskClosed(task))
);

export const getAllUserFarDueDateTasks = (email: string) => createSelector(
  getAllUserTasks(email),
  (tasks) => {
    const today = new Date().toString();
    return tasks.filter(task => {
      if (task.is.overdue || isTaskClosed(task)) return false;
      if (!task.dates.dueDate) return true;

      const duration = DateUtil.daysDiff(task.dates.dueDate, today);
      return duration > 14
    });
  }
);

export const getAllUserNormalDueDateTasks = (email: string) => createSelector(
  getAllUserTasks(email),
  (tasks) => {
    const today = new Date().toString();
    return tasks.filter(task => {
      if (task.is.overdue || isTaskClosed(task) || !task.dates.dueDate) return false;

      const duration = DateUtil.daysDiff(task.dates.dueDate, today);
      return duration <= 14 && duration > 7;
    });
  }
);

export const getAllUserNearDueDateTasks = (email: string) => createSelector(
  getAllUserTasks(email),
  (tasks) => {
    const today = new Date().toString();
    return tasks.filter(task => {
      if (task.is.overdue || isTaskClosed(task) || !task.dates.dueDate) return false;

      const duration = DateUtil.daysDiff(task.dates.dueDate, today);
      return duration <= 7 && duration > 0;
    });
  }
);


// ALL TASKS OF PROCESS
export const getAllTasksOfProcess = (id: string) => createSelector(
  getAllTasks,
  (tasks) => tasks.filter(task => task.process.id === id)
);

export const getTasksOfSelectedProcess = createSelector(
  getAllTasks,
  ProcessSelectors.getSelectedProcess,
  (tasks, process) => {
    if (process) {
      return tasks.filter(task => task.process.id === process.id)
    }
    return [];
  }
);

// ALL TASKS OF COLLECTO
export const getTasksByCollectoResource = (resource) => createSelector(
  resource.processId ? getAllTasksOfProcess(resource.processId) : getAllTasks,
  (tasks) => {
    return tasks.filter((entity: Task) => entity.collectors.find(collector => collector.resourceId === resource.id));
  }
);

export const getTasksCountByCollectoResource = (resource) => createSelector(
  getTasksByCollectoResource(resource),
  (tasks) => tasks.length
);


// ALL TASKS OF DOCUMENT
export const getTasksByDocumentResource = (resourceId, processId) => createSelector(
  processId ? getAllTasksOfProcess(processId) : getAllTasks,
  (tasks) => {
    return tasks.filter((entity: Task) => entity.documents.find(doc => doc.resourceId === resourceId) || entity.attachments.filter(a => !!a && a.documentId).find(attachment => attachment.documentId === resourceId))
  }
);

export const getTasksCountByDocumentResource = (resourceId, processId) => createSelector(
  getTasksByDocumentResource(resourceId, processId),
  (tasks) => tasks.length
);

// ALL TASKS OF APPENDIX
export const getTasksByAppendixResource = (resource) => createSelector(
  resource.processId ? getAllTasksOfProcess(resource.processId) : getAllTasks,
  (tasks) => {
    return tasks.filter((entity: Task) => entity.appendices.find(appendix => appendix.resourceId === resource.id));
  }
);

export const getTasksCountByAppendixResource = (resource) => createSelector(
  getTasksByAppendixResource(resource),
  (tasks) => tasks.length
);

// OTHER SELECTORS
export const getAllQuery = createSelector(
  getTaskEntities,
  (tasks) => {
    return tasks;
  }
);

export const getByQuery = createSelector(
  getAllTasks,
  (tasks) => {
    return tasks.filter(entity => entity)
  }
);

export const getMore = createSelector(
  getAllTasks,
  (tasks) => {
    return tasks;
  }
);

export const getSelected = createSelector(
  getTaskState,
  (state) => state.selected
)

export const loadingState = createSelector(
  getTaskState,
  (state) => state.loading
)

export const updatingState = createSelector(
  getTaskState,
  (state) => state.updating
)

// STATISTICS By DueDate Duration
export const getAllTasksStatisticsByDueDateDuration = createSelector(
  getAllTasks,
  (tasks) => getTasksStatisticsByDueDateDuration(tasks)
);

export const getAllUserTasksStatisticsByDueDateDuration = (email: string) => createSelector(
  getAllUserTasks(email),
  (tasks) => getTasksStatisticsByDueDateDuration(tasks)
);

function getTasksStatisticsByDueDateDuration(tasks) {
  const today = new Date().toString();

  const statistics = {
    far: 0,
    normal: 0,
    near: 0,
    overdue: 0
  }

  tasks.forEach(task => {
    if (task.is.closed) {
      return;
    }

    if (task.is.overdue) {
      statistics.overdue += 1;
      return;
    }

    const dueDate = task.dates.dueDate;
    if (!dueDate) {
      statistics.far += 1;
      return;
    }

    const duration = DateUtil.daysDiff(dueDate, today);

    if (duration > 14) {
      statistics.far += 1;
    } else if (duration > 7) {
      statistics.normal += 1;
    } else {
      statistics.near += 1;
    }
  });

  return statistics;
}

function isResponsible(task: Task, email) {
  return task.responsible.email === email;
}

function isCreator(task: Task, email) {
  return task.creator.email === email;
}

function isAssignee(task: Task, email): TaskAssignee {
  return task.assignees && task.assignees.find((assignee: TaskAssignee) => assignee.email === email)
}

function isAcceptedAssignee(task: Task, email): boolean {
  return !!isAssignee(task, email) && isAssignee(task, email).status === TaskAssignmentStatus.Accepted
}

function isPendingAssignee(task: Task, email): boolean {
  return !!isAssignee(task, email) && isAssignee(task, email).status === TaskAssignmentStatus.Pending
}

function isCompletedAssignee(task: Task, email): boolean {
  return !!isAssignee(task, email) && isAssignee(task, email).status === TaskAssignmentStatus.Completed
}

function isRejectedAssignee(task: Task, email): boolean {
  return !!isAssignee(task, email) && isAssignee(task, email).status === TaskAssignmentStatus.Rejected
}

function isApprovedAssignee(task: Task, email): boolean {
  return !!isAssignee(task, email) && isAssignee(task, email).status === TaskAssignmentStatus.Approved
}

function isDeniededAssignee(task: Task, email): boolean {
  return !!isAssignee(task, email) && isAssignee(task, email).status === TaskAssignmentStatus.Denied
}

function isTaskClosed(task: Task) {
  return task.is.closed || task.dates.completedAt;
}
