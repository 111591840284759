import {createEntityAdapter, EntityState} from '@ngrx/entity';
import * as model from './member';
import {
  AddressBookTableHelper
} from '../../../modules/address-book/modules/address-book-table/containers/address-book-table/address-book-table.helper';

export interface State extends EntityState<model.Operator.Member> {
  selected?: model.Operator.Member;
  loading: boolean;
}

export const adapter = createEntityAdapter<model.Operator.Member>({
  sortComparer: AddressBookTableHelper.sortByLastNameFirstName
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
