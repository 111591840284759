import {ITaskEvent, TaskActivityType} from './task-event.interface';

export class TaskEvent implements ITaskEvent {
  readonly type = 'task_events';
  activity: TaskActivityType;

  constructor(public id: string,
              public taskId: string,
              public performer: string,
              public performer_email: string,
              public changeset: any,
              public resource: any,
              public createdAt: Date) {}
}
