<div class="mat-dialog-inner-wrap dvtx-file-picker-dialog" style="min-height: 600px;">
  <div class="mat-dialog-header dvtx-bg-color dvtx-bg-cool-grey-25">
    <h1 mat-dialog-title>
      {{ 'DMS.FOLDER_OVERVIEW' | translate }}
    </h1>
    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>

  <div mat-dialog-content class="dvtx-file-picker-dialog-content">
    <dvtx-dms-folder-picker-v5 (onSelect)="onSelect($event)"></dvtx-dms-folder-picker-v5>
  </div>

  <div class="mat-dialog-footer">
    <mat-dialog-actions class="d-flex justify-content-end mt-1">
      <button mat-button mat-dialog-close>{{ 'FILE_PICKER.CLOSE' | translate }}</button>
      <button mat-raised-button
              color="primary"
              [disabled]="!selection"
              (click)="selectAndClose()">{{ 'GENERAL.SELECT_ACTION' | translate }}</button>
    </mat-dialog-actions>
  </div>
</div>
