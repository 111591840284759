import {Directive, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Directive()
export class RxjsBaseComponent implements OnDestroy {
  protected onDestroy = new Subject();
  protected refreshSubject: Subject<void> = new Subject();

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
