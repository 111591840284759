import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {FavoriteEffects} from './favorite.effects';
import {FavoriteService} from './favorite.service';
import {reducer} from './favorite.reducer';
import {stateKey} from './favorite.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([FavoriteEffects])
  ],
  providers: [
    FavoriteEffects,
    FavoriteService
  ]
})
export class FavoriteStoreModule {
}
