import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {DmsFolderAcl, RemovedParticipation} from './dms-folder-acl';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';

export class DmsFolderAclBuilder implements IApiResourceBuilder<DmsFolderAcl> {
  constructor(private _folderID: string, private _accountType: DmsAccountType) {}

  fromResponse(data): DmsFolderAcl {
    const id = `${this._folderID}|${data.attributes.sid}`;
    return new DmsFolderAcl(
      id,
      data.attributes.sid,
      this._folderID,
      this._accountType,
      data.attributes.allowed,
      data.attributes.email,
      data.attributes.username);
  }

  toRequest(_: DmsFolderAcl) {
    return null;
  }
}

export class RemovedParticipationBuilder implements IApiResourceBuilder<RemovedParticipation> {
  constructor() {}

  fromResponse(data): RemovedParticipation {
    return new RemovedParticipation(
      data.attributes.dms_folder_id,
      data.attributes.sid,
      data.attributes.deleted_participations,
      data.attributes.closed,
      data.attributes.failed);
  }

  toRequest(_: RemovedParticipation) {
    return null;
  }
}
