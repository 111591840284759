import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {stateKey} from './contact-organization.selectors';
import {reducer} from './contact-organization.reducer';
import {ContactOrganizationEffects} from './contact-organization.effects';
import {ContactOrganizationFactoryService} from './contact-organization-factory.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ContactOrganizationEffects])
  ],
  providers: [
    ContactOrganizationEffects,
    ContactOrganizationFactoryService
  ]
})
export class ContactsOrganizationStoreModule {
}
