<form novalidate [formGroup]="form">
  <div class="d-flex pl-3 pt-2">
    <div *ngIf="form" class="dvtx-email-footer-editor">
      <h2 class="mb-2">{{'FOOTER.SIGNATURE' | translate}}</h2>

      <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="publish">
          <mat-slide-toggle [formControlName]="'published'"
                            [checked]="form?.value.publishedAt"
                            [matTooltip]="form.value.publishedAt? ('FOOTER.UNPUBLISH' | translate) : ('FOOTER.PUBLISH' | translate)">
            <h4 class="mb-0">{{form.value.publishedAt ? ('FOOTER.UNPUBLISH' | translate) : ('FOOTER.PUBLISH' | translate) }}</h4>
          </mat-slide-toggle>
        </div>
        <div style="flex: 1 auto"></div>
        <div>
          <div class="save-update">
            <div class="d-flex justify-content-end">
              <button mat-button color="warn" class="wide" (click)="resetForm()"
                      *ngIf="emailFooterInfo ?.createdAt"> {{'GENERAL.DELETE_ACTION' | translate }}</button>
              <button type="submit" [disabled]="form.invalid || form.pristine" mat-raised-button color="primary"
                      (click)="submit()"
                      class="wide ml-2">{{emailFooterInfo?.createdAt ? ('GENERAL.SAVE_ACTION' | translate) : ('GENERAL.CREATE_ACTION' |
                translate) }}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center mb-2">
        <p *ngIf="form.value.publishedAt" class="dvtx-fg-color dvtx-fg-fivef-blue-300"> {{'FOOTER.PUBLISHED_AT' | translate}}: {{form?.value.publishedAt | date : 'short'}}</p>
        <p *ngIf="emailFooterInfo?.updatedAt" class="dvtx-fg-color dvtx-fg-fivef-blue-300"> {{'FOOTER.LAST_UPDATED' | translate}}: {{emailFooterInfo?.updatedAt | date : 'short'}}</p>
      </div>

      <div class="content">
        <dvtx-wysiwyg-editor-cva [formControlName]="'content'"
                                 [placeholder]="'FOOTER.SIGNATURE'"
                                 [heightMax]="null"
                                 [editableFeature]="false"
                                 required></dvtx-wysiwyg-editor-cva>
      </div>
    </div>

    <div class="preview">
      <div class="flex">
        <mat-icon>visibility</mat-icon>
        <h2> {{'FOOTER.PREVIEW' | translate}}</h2>
      </div>
      <br>
      <div>
        <div class="d-flex justify-content-between">
          <div>
            <div class="flex">
              <mat-icon *ngIf="type === FooterType.Orgnizational">domain</mat-icon>
              <h6> {{type === FooterType.User ? ('FOOTER.YOUR_SIGNATURE' | translate) : ('FOOTER.ORGANIZATION_SIGNATURE' | translate)}}</h6>
            </div>
            <p class="title">{{subtitle | translate}}</p>
          </div>
          <div>
            <button mat-button color="primary"
                    (click)="sendTestMail()">{{ 'FOOTER.SEND_TEST_MAIL' | translate }}</button>
          </div>
        </div>
        <div class="froala-view">
          <p [innerHTML]="'FOOTER.EXAMPLE_EMAIL' | translate"></p>
          <div *ngIf="form.controls['content'].value" [froalaView]="form.controls['content'].value"></div>
          <div *ngIf="!form.controls['content'].value" class="no-signature">{{'FOOTER.NO_CONTENT' | translate}}</div>
        </div>

      </div>
      <br>
      <div *ngIf="type === FooterType.User && orgnizationalSignture?.content" class="org-preview">
        <div class="flex">
          <mat-icon>domain</mat-icon>
          <h6>{{'FOOTER.ORGANIZATION_SIGNATURE' | translate}}</h6>
        </div>
        <div [froalaView]="orgnizationalSignture?.content" class="froala-view"></div>
      </div>
    </div>
  </div>
</form>
