import {OperatorOrganizationActions, OperatorOrganizationActionTypes} from './organization.actions';
import {adapter, initialState, State} from './organization.state';

export function reducer(state = initialState, action: OperatorOrganizationActions): State {
  const current = state.selected;
  switch (action.type) {
    case OperatorOrganizationActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case OperatorOrganizationActionTypes.Create:
    case OperatorOrganizationActionTypes.LoadOne:
    case OperatorOrganizationActionTypes.UpdateStatus:
    case OperatorOrganizationActionTypes.Remove:
    case OperatorOrganizationActionTypes.Save:
      return {
        ...state,
        loading: true
      };
    case OperatorOrganizationActionTypes.LoadOneSuccess:
      return adapter.addOne(action.organization, {
        ...state,
        selected: action.organization,
        loading: false,
      });
    case OperatorOrganizationActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.organizations, {
        ...state,
        selected: null,
        loading: false,
      });
    case OperatorOrganizationActionTypes.CreateSuccess:
    case OperatorOrganizationActionTypes.SaveSuccess:
    case OperatorOrganizationActionTypes.UpdateStatusSuccess:
      return adapter.upsertOne(action.organization, {
        ...state,
        selected: action.organization,
        loading: false,
      });
    case OperatorOrganizationActionTypes.CreateFail:
    case OperatorOrganizationActionTypes.LoadAllFail:
    case OperatorOrganizationActionTypes.LoadOneFail:
    case OperatorOrganizationActionTypes.RemoveFail:
    case OperatorOrganizationActionTypes.SaveFail:
    case OperatorOrganizationActionTypes.UpdateStatusFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
    case OperatorOrganizationActionTypes.RemoveSuccess:
      return adapter.removeOne(action.organization.id, {
        ...state,
        selected: (current && current.id === action.organization.id) ? null : current,
        loading: false,
      });
  }
  return state;
}
