import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {DavContactImportComponent} from './containers/dav-contact-import/dav-contact-import.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    DavContactImportComponent
  ],
  exports: [
    DavContactImportComponent
  ]
})
export class DavModule {
}
