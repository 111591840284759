import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ApiErrorBuilder} from '../builders/api-error.builder';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from 'environments/environment';

@Injectable()

export class NavigationMenuService {
  basePath;
  readonly BASE_PATH = 'navigations/';

  constructor(private tokenService: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.BASE_PATH}`;
  }

  getNavigationMenu(path: string): Observable<any> {
    return this.tokenService.get(`${this.basePath}${path}`).pipe(
      map((res: any) => res.data),
      catchError(this.handleError),);
  }

  private handleError(error: HttpErrorResponse) {
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}
