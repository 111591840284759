import {Action} from '@ngrx/store';
import {AuditContractee} from './audit-contractee';

export enum AuditContracteeActionTypes {
  LoadAll = '[AuditContractee] Load All',
  LoadAllSuccess = '[AuditContractee] Load All Success',
  LoadAllFail = '[AuditContractee] Load All Fail',
}

export class LoadAll implements Action {
  readonly type = AuditContracteeActionTypes.LoadAll;

  constructor(public id: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = AuditContracteeActionTypes.LoadAllSuccess;

  constructor(public payload: AuditContractee[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = AuditContracteeActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export type AuditContracteeActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail;
