import { Action } from '@ngrx/store';

export const GET_ARTIFACTS = 'GET_ARTIFACTS';
export const GET_ARTIFACTS_SUCCESS = 'GET_ARTIFACTS_SUCCESS';
export const GET_ARTIFACT = 'GET_ARTIFACT';
export const GET_ARTIFACT_SUCCESS = 'GET_ARTIFACT_SUCCESS';
export const CREATE_ARTIFACT = 'CREATE_ARTIFACT';
export const CREATE_ARTIFACT_SUCCESS = 'CREATE_ARTIFACT_SUCCESS';
export const DELETE_ARTIFACT = 'DELETE_ARTIFACT';
export const DELETE_ARTIFACT_SUCCESS = 'DELETE_ARTIFACT_SUCCESS';
export const UPDATE_ARTIFACT = 'UPDATE_ARTIFACT';
export const UPDATE_ARTIFACT_SUCCESS = 'UPDATE_ARTIFACT_SUCCESS';
export const RESET_ARTIFACTS = 'RESET_ARTIFACTS';
export const GET_EXTERNAL_ACCESSIBLE_ARTIFACTS = 'GET_EXTERNAL_ACCESSIBLE_ARTIFACTS';
export const GET_EXTERNAL_ACCESSIBLE_ARTIFACTS_SUCCESS = 'GET_EXTERNAL_ACCESSIBLE_ARTIFACTS_SUCCESS';

export class GetArtifacts implements Action {
  readonly type: string = GET_ARTIFACTS;

  constructor(public payload: any = null) { }
}

export class GetArtifactsSuccess implements Action {
  readonly type: string = GET_ARTIFACTS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetExternalAccessibleArtifacts implements Action {
  readonly type: string = GET_EXTERNAL_ACCESSIBLE_ARTIFACTS;

  constructor(public payload: any = null) { }
}

export class GetExternalAccessibleArtifactsSuccess implements Action {
  readonly type: string = GET_EXTERNAL_ACCESSIBLE_ARTIFACTS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetArtifact implements Action {
  readonly type: string = GET_ARTIFACT;

  constructor(public payload: any) {}
}

export class GetArtifactSuccess  implements Action {
  readonly type: string = GET_ARTIFACT_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateArtifact implements Action {
  readonly type: string = CREATE_ARTIFACT;

  constructor(public payload: any) {}
}

export class CreateArtifactSuccess  implements Action {
  readonly type: string = CREATE_ARTIFACT_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteArtifact implements Action {
  readonly type: string = DELETE_ARTIFACT;

  constructor(public payload: any) {}
}

export class DeleteArtifactSuccess  implements Action {
  readonly type: string = DELETE_ARTIFACT_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateArtifact implements Action {
  readonly type: string = UPDATE_ARTIFACT;

  constructor(public payload: any) {}
}

export class UpdateArtifactSuccess  implements Action {
  readonly type: string = UPDATE_ARTIFACT_SUCCESS;

  constructor(public payload: any) {}
}

export class ResetArtifacts implements Action {
  readonly type: string = RESET_ARTIFACTS;

  constructor(public payload: any = null) { }
}


export type Actions = GetArtifact
  | GetArtifactSuccess
  | GetArtifacts
  | GetArtifactsSuccess
  | GetExternalAccessibleArtifacts
  | GetExternalAccessibleArtifactsSuccess
  | CreateArtifact
  | CreateArtifactSuccess
  | DeleteArtifact
  | DeleteArtifactSuccess
  | UpdateArtifact
  | UpdateArtifactSuccess
  | ResetArtifacts;
