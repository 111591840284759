import {BookmanClientActions, BookmanClientActionTypes} from './bookman-client.actions';
import {adapter, initialState, State} from './bookman-client.state';

export function reducer(state = initialState, action: BookmanClientActions): State {
  const current = state.selected;
  switch (action.type) {
    case BookmanClientActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
      case BookmanClientActionTypes.Refresh:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case BookmanClientActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.clients, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case BookmanClientActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
  }
  return state;
}
