import {Action} from '@ngrx/store';
import {ProjectRoom} from './project-room';

export enum ProjectRoomActionTypes {
  LoadAll = '[ProjectRoom] Load All',
  LoadAllSuccess = '[ProjectRoom] Load All Success',
  LoadAllFail = '[ProjectRoom] Load All Fail',
  LoadOne = '[ProjectRoom] Load One',
  LoadOneSuccess = '[ProjectRoom] Load One Success',
  LoadOneFail = '[ProjectRoom] Load One Fail'
}

export interface ILoadOneOptions {
  skipParticipants?: boolean;
  recursive?: boolean;
}

export class LoadOne implements Action {
  readonly type = ProjectRoomActionTypes.LoadOne;

  constructor(public id: string, public options: ILoadOneOptions = { recursive: false }) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = ProjectRoomActionTypes.LoadOneSuccess;

  constructor(public project: ProjectRoom) {
  }
}

export class LoadOneFail implements Action {
  readonly type = ProjectRoomActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type ProjectRoomActions =
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
