import {Injectable} from '@angular/core';
import {Process} from '../process/process';
import {Router} from '@angular/router';

@Injectable()
export class TaskRouterService {

  constructor(private _router: Router) { }

  navigateToTasks(process: Process) {
    if (!process || !process.id) return;
    switch (process.processType) {
      case 'audit_contact_verifications':
      case 'audit_contact_imports':
        this._router.navigate(['workflows', 'audit', 'kap', process.parentId, 'contacts-tasks', process.id]);
        break;
      default:
        this._router.navigate(['tasks', process.id]);
    }
  }
}
