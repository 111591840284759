import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {DmsDocument} from './dms-document';
import * as dayjs from 'dayjs';

export interface State extends EntityState<DmsDocument> {
  selected?: DmsDocument;
  unread: number;
  loading: boolean;
}

export const adapter = createEntityAdapter<DmsDocument>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  unread: null,
  loading: false,
});
