import {Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import {OrganizationSelectors} from 'app/+store/organization';
import {debounceTime, distinctUntilKeyChanged, filter, first, takeUntil} from 'rxjs/operators';
import {Subject, Observable, BehaviorSubject} from 'rxjs';
import {ClientActions} from 'app/+store/client';
import {Client} from 'app/+store/client/client';
import {Feature} from 'app/+store/feature/feature';
import {FeatureSelectors} from 'app/+store/feature';
import {BookmanClientActions} from 'app/+store/bookman-client';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ClientService} from 'app/+store/client/client.service';
import {IClientV3Query} from 'app/+store/client/client.interface';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {Router} from '@angular/router';
import {ClientActionType} from '../../../workflow-engine/modules/process-dialog/containers/client-selection/client-selection.component';

@Component({
  selector: 'dvtx-client-overview',
  templateUrl: './client-overview.component.html',
  styleUrls: ['./client-overview.component.scss']
})
export class ClientOverviewComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject();
  public ClientActionType = ClientActionType;

  data$ = new BehaviorSubject<Client[]>([]);
  private _clients: Client[] = [];
  organization;

  // Pagination setup
  public loading$ = new BehaviorSubject<boolean>(false);
  public page: number = 1;
  public pages: number = 1;

  @ViewChild('searchField', { static: true }) searchField: ElementRef;
  searchForm: UntypedFormGroup;
  searchTerm$: BehaviorSubject<string> = new BehaviorSubject(null);
  currentSearchTerm = '';

  featureSet$: Observable<Feature>;

  @Input() enableToolbar = true;
  @Input() set searchTerm(term) {
    this.searchTerm$.next(term);
  }

  constructor(private _store: Store<AppState>,
              private _clientSvc: ClientService,
              private _fb: UntypedFormBuilder,
              private _router: Router,
              protected _translateSvc: TranslateService,
              private _notifyService: NotificationService,
              private _ngZone: NgZone) {
  }

  ngOnInit() {
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
    this._store.select(OrganizationSelectors.getSelected)
      .pipe(filter(o => !!o), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy))
      .subscribe(_organization => {
        this.organization = _organization;
        this._loadPage(1);
        setTimeout(_ => this._ngZone.runOutsideAngular(__ => this._store.dispatch(new BookmanClientActions.LoadAll())));
      });

    this.createSearchForm();

    this.searchTerm$
      .pipe(takeUntil(this.onDestroy), debounceTime(400))
      .subscribe(term => {
        if (this.currentSearchTerm !== term) {
          this.currentSearchTerm = term;
          if (term !== undefined && term != null) {
            this._loadPage(1);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.loading$.complete();
    this.data$.complete();
  }

  private createSearchForm() {
    this.searchForm = this._fb.group({
      searchTerm: [null]
    });
  }

  resetSearch() {
    this.searchField.nativeElement.value = '';
    this.searchTerm$.next('');
    this.currentSearchTerm = '';

    this._loadPage(1);
  }

  public applySearch() {
    this.searchTerm$.next(this.searchField.nativeElement.value);
  }

  public loadMore(event): void {
    if (this.page >= this.pages || (event && !event.visible)) {
      return;
    }
    this.page += 1;
    this._loadPage(this.page)
  }

  private _loadPage(page) {
    if (page === 1) {
      this._clients = [];
      this.page = 1;
    }

    this.loading$.next(true);
    const params: IClientV3Query = {};

    if (this.currentSearchTerm) {
      params.search = this.currentSearchTerm;
    }

    this._clientSvc.getAllPaginated(page, params)
      .pipe(first())
      .subscribe(clients => {
        if (this.page === 1 && clients.length === 0) {
          this.pages = 0;
        }

        if (this.page === 1) {
          this._clients = [...clients];
        } else {
          this._clients = [...this._clients, ...clients];
        }
        this.data$.next(this._clients);

        // Pagination counter
        if (clients && clients[0]) {
          this.pages = clients[0].total;
        }
        // Disable auto load loading circle and enable viewport detection
        this.loading$.next(false);
      });
  }

  removeClient(client) {
    const item = client;
    this._clientSvc.destroy(item.id)
      .pipe(first())
      .subscribe((_client: Client) => {
        this._store.dispatch(new ClientActions.RemoveSuccess(_client));
        this.data$.next(this.data$.value.filter(p => p.id !== item.id));
        this._notifyService.success('CONTACTS.CLIENT_REMOVED')
      });
  }

  editSuccess(client) {
    const item = client;
    const toBeUpdated = this.data$.value.find(p => p.id === item.id);
    if (!toBeUpdated) {
      console.error('Updated client not found. Skipping update of listing. Exit...');
      return;
    }
    this._store.dispatch(new ClientActions.SaveSuccess(item));
    const oldClients = this.data$.value;
    const newClients = oldClients.map((c) => c.id === toBeUpdated.id ? item : c);
    this.data$.next([...newClients]);
  }

  createSuccess(client) {
    const item = client;
    const oldClients = this.data$.value;
    const newClients = [item, ...oldClients];
    this._store.dispatch(new ClientActions.CreateSuccess(client));
    this.data$.next(newClients);
  }
}
