import {NgModule} from '@angular/core';
import { PersonSelectorAutocompleteModule } from 'app/shared/modules/person-selector-autocomplete/person-selector-autocomplete.module';
import { ProcessDialogModule } from '../workflow-engine/modules/process-dialog/process-dialog.module';
import {AddressBookComponent} from './containers/address-book/address-book.component';
import {AddressBookTableModule} from './modules/address-book-table/address-book-table.module';
import {ScrollingModule} from '@angular/cdk-experimental/scrolling';

@NgModule({
  imports: [
    PersonSelectorAutocompleteModule,
    AddressBookTableModule,
    ProcessDialogModule,
    ScrollingModule
  ],
  declarations: [
    AddressBookComponent
  ],
  exports: [
    AddressBookTableModule,
    AddressBookComponent
  ]
})
export class AddressBookModule {
}
