import {Action} from '@ngrx/store';
import {FibuProcess} from './fibu-process';

export enum FibuProcessActionTypes {
  LoadAll = '[Fibu] LoadAll',
  LoadAllSuccess = '[Fibu] LoadAll Success',
  LoadAllFail = '[Fibu] LoadAll Fail',
}


export class LoadAll implements Action {
  readonly type = FibuProcessActionTypes.LoadAll;

  constructor(public ids: string[] = null, public refresh = true) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = FibuProcessActionTypes.LoadAllSuccess;

  constructor(public fibuProcesses: FibuProcess[], public updatedProcesses: FibuProcess[] = [], public refresh = true) {
  }
}

export class LoadAllFail implements Action {
  readonly type = FibuProcessActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}


export type FibuProcessActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail;
