import {IResource} from 'app/shared/modules/api/models/resource.interface';

export enum CommentType {
  InstantMessage = 'InstantMessage',
  Comment = 'Comment'
}

export const ICON_TYPE_MAPPING = {
  'thumb_up': 'like',
  'mood_bad': 'angry',
  'sentiment_dissatisfied': 'sad',
  'insert_emoticon': 'laugh',
  'favorite': 'heart',
  'sentiment_neutral': 'surprised',
};

export enum CommentReactionType {
  Like = 'like',
  Angry = 'angry',
  Sad = 'sad',
  Laugh = 'laugh',
  Heart = 'heart',
  Surprised = 'surprised'
}

export interface CommentReaction {
  email: string;
  reactionType: CommentReactionType
}

export interface IComment extends IResource {
  type: CommentType;
  commentableId: string;
  performer: string;
  message: string;
  processId?: string;
  backtrackId?: string;
  seenBy?: string[];
}
