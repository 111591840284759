import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {LicenceAssignmentEffects} from './licence-assignment.effects';
import {reducer} from './licence-assignment.reducer';
import {stateKey} from './licence-assignment.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([LicenceAssignmentEffects])
  ],
  providers: [LicenceAssignmentEffects]
})
export class LicenceAssignmentStoreModule {
}
