
import {of as observableOf, Observable, BehaviorSubject} from 'rxjs';

import {delay, map} from 'rxjs/operators';
import {Injectable, TemplateRef, ViewChild} from '@angular/core';


import {RequestOverviewListMock} from '../../modules/workflows/workflows/audit/kap/workflow-variations/models/subarea-verifier-request/step3/subarea-verifier-request-overview.mock';
import {IContactRequestOverview} from '../../modules/workflows/workflows/audit/kap/workflow-variations/models/subarea-verifier-request/step3/subarea-verifier-request-overview.interface';
import {AuditContactVerificationProcess} from '../../shared/modules/api/models/audit-contact-verification-process';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {IContactVerificationEntry} from './contact-verification.interface';
import {ContactVerificationBuilder} from './contact-verification.builder';
import {VersionHistoryItemBuilder} from '../../modules/workflow-engine/modules/data-vizualization/models/version-history';
import {BasicContact} from '../society/basic-contact';

@Injectable()
export class SubareaVerifierRequestOverviewService {
  readonly BASE_PATH = 'api/v1/audit';
  basePath: string;

  itemsObservable: BehaviorSubject<IContactRequestOverview[]>;
  originalItems: IContactRequestOverview[] = [];
  items: IContactRequestOverview[] = [];

  constructor(
    private _tokenService: HttpClient
  ) {
    this.originalItems = RequestOverviewListMock;
    this.items = this.originalItems;
    this.itemsObservable = new BehaviorSubject<IContactRequestOverview[]>(this.items);
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  getSubareaVerifierRequests(id: String) {
    return this.itemsObservable.asObservable();
  }

  filterItems(filter) {

    this.items = this.originalItems;
    if (filter) {
      this.items = this.items.filter(item => filter.includes(item.status));
    }

    this.updateSubject();
  }

  updateSubject() {
    this.itemsObservable.next(this.items);
  }

  getFilterStatusList() {

    const sentCount = this.originalItems.length;
    const receivedCount = this.originalItems.filter(item => 'open'.includes(item.status)).length;
    const overdueCount = this.originalItems.filter(item => 'overdue'.includes(item.status)).length;
    const completedCount = this.originalItems.filter(item => 'verified'.includes(item.status)).length;

    const filterStatusList = [
      {name: 'Alle anzeigen', count: sentCount, filter: ''},
      {name: 'Offen', count: receivedCount, filter: 'open'},
      {name: 'Überfällig', count: overdueCount, filter: 'overdue'},
      {name: 'Verifiziert', count: completedCount, filter: 'verified'}
    ];

    return observableOf(filterStatusList).pipe(delay(1000));
  }

  verifyContactManually(contact: AuditContactVerificationProcess) {
    return this._tokenService.post(
      `${this.basePath}/contact_imports/${contact.id}/verify_contacts`, {
        data: {
          id: contact.id,
          type: 'audit_contact_verification_process'
        }
      });
  }

  verifyBasicContactManually(contact: BasicContact) {
    return this._tokenService.post(
      `${this.basePath}/contact_imports/${contact.verificationProcessId}/verify_contacts`, {
        data: {
          id: contact.verificationProcessId,
          type: 'audit_contact_verification_process'
        }
      });
  }

  createContactEntry(id, params: IContactVerificationEntry) {
    const builder = new ContactVerificationBuilder();
    const payload = builder.toRequest(params);
    return this._tokenService.post(`${this.basePath}/contact_imports/${id}/contact_import_items`, payload);
  }

  saveContactEntry(id, contactId, params: IContactVerificationEntry) {
    const builder = new ContactVerificationBuilder();
    const payload = builder.toRequest(params);
    return this._tokenService.put(`${this.basePath}/contact_imports/${id}/contact_import_items/${contactId}`, payload);
  }

  getContactEntryVersions(id, contactId) {
    return this._tokenService.get(`${this.basePath}/contact_imports/${id}/contact_import_items/${contactId}/versions`)
      .pipe(
        map(res => {
          if (!res['data']) return [];
          const builder = new VersionHistoryItemBuilder();
          return builder.fromResponse(res['data'])
        })
      )
  }

  deleteContactEntry(id, contactId) {
    return this._tokenService.delete(`${this.basePath}/contact_imports/${id}/contact_import_items/${contactId}`);
  }

  getExternalLink(id, contactId) {
    return this._tokenService.post(`${this.basePath}/contact_imports/${id}/contact_import_items/${contactId}/link`, {});
  }

  lock(id) {
    return this._tokenService.post(`${this.basePath}/contact_imports/${id}/close`, {});
  }

  remindContactVerification(contact: AuditContactVerificationProcess) {
    return this._tokenService.post(`${this.basePath}/contact_imports/${contact.id}/remind`, {
      data: {
        id: contact.id,
        type: 'audit_contact_verification_process'
      }
    });
  }

  remindContactVerificationByProcessId(id) {
    return this._tokenService.post(`${this.basePath}/contact_imports/${id}/remind`, {
      data: {
        id: id,
        type: 'audit_contact_verification_process'
      }
    });
  }

  repeatedContactVerification(contact: AuditContactVerificationProcess) {
    return this._tokenService.post(`${this.basePath}/contact_imports/${contact.id}/repeated_verification_request`, {
      data: {
        id: contact.id,
        type: 'audit_contact_verification_process'
      }
    });
  }

}
