import {Injectable} from '@angular/core';
import {catchError, concatMap, filter, first, switchMap, withLatestFrom} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {
  Create,
  CreateFail,
  CreateSuccess,
  Edit,
  EditFail,
  EditSuccess,
  KanbanGroupActionTypes,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Remove,
  RemoveFail,
  RemoveSuccess
} from './kanban-group.actions';
import {KanbanGroup} from './kanban-group';
import {KanbanGroupService} from './kanban-group.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {KanbanBoardSelectors, KanbanItemActions, KanbanItemSelectors} from 'app/+store';
import {KanbanBoard} from 'app/+store/kanban-store/kanban-board/kanban-board';
import {KanbanItem} from 'app/+store/kanban-store/kanban-item/kanban-item';
import {of} from 'rxjs';

@Injectable()
export class KanbanGroupEffects {
  @Effect()
  create$ = this.actions.pipe(
    ofType(KanbanGroupActionTypes.Create),
    withLatestFrom(this._store.select(KanbanBoardSelectors.getSelectedBoard).pipe(
      filter(board => !!board)
    )),
    concatMap(([action, board]: [Create, KanbanBoard]) => {
      return this._svc.create(action.labelId, board.id, action.isContextual, action.contextType, action.referenceId).pipe(
        first(),
        concatMap((kanbanGroup: KanbanGroup) => {
          this._store.dispatch(new KanbanItemActions.LoadAll(kanbanGroup.id));
          return [new CreateSuccess(kanbanGroup)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  );

  @Effect()
  edit$ = this.actions.pipe(
    ofType(KanbanGroupActionTypes.Create),
    withLatestFrom(this._store.select(KanbanBoardSelectors.getSelectedBoard).pipe(
      filter(board => !!board)
    )),
    concatMap(([action, board]: [Edit, KanbanBoard]) => {
      return this._svc.update(board.id, action.id, action.params).pipe(
        first(),
        concatMap((kanbanGroup: KanbanGroup) => {
          return [new EditSuccess(kanbanGroup)];
        }),
        catchError(err => {
          console.error(err);
          return of(new EditFail(err));
        }));
    })
  );

  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(KanbanGroupActionTypes.Create),
    withLatestFrom(this._store.select(KanbanBoardSelectors.getSelectedBoard).pipe(
      filter(board => !!board)
    )),
    concatMap(([action, board]: [LoadAll, KanbanBoard]) => {
      return this._svc.getAll(board.id).pipe(
        first(),
        concatMap((res: KanbanGroup[]) => {
          res.forEach(group => {
            this._store.dispatch(new KanbanItemActions.LoadAll(group.id));
          })
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  loadOne$ = this.actions.pipe(
    ofType(KanbanGroupActionTypes.Create),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.boardId, action.id).pipe(
        first(),
        concatMap((kanbanGroup: KanbanGroup) => {
          return [new LoadOneSuccess(kanbanGroup)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  );

  @Effect()
  remove$ = this.actions.pipe(
    ofType(KanbanGroupActionTypes.Create),
    withLatestFrom(this._store.select(KanbanBoardSelectors.getSelectedBoard).pipe(
      filter(board => !!board)
    )),
    concatMap(([action, board]: [Remove, KanbanBoard]) => {
      return this._svc.remove(board.id, action.id).pipe(
        first(),
        withLatestFrom(this._store.select(KanbanItemSelectors.getAllForGroup(action.id)).pipe(
          filter(items => !!items)
        )),
        concatMap(([kanbanGroup, items]: [KanbanGroup, KanbanItem[]]) => {
          items.forEach(item => {
            item.groupId = '';
            item.labels.find(label => label.groupId === action.id).isGroup = false;
            this._store.dispatch(new KanbanItemActions.UpdateGroupSuccess(item));
          })
          return [new RemoveSuccess(action.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  );

  constructor(
    private actions: Actions,
    private _store: Store<AppState>,
    private _svc: KanbanGroupService,
    private _router: Router,
    private _translateSvc: TranslateService,
    private _notifyService: NotificationService
  ) {
  }
}
