<dvtx-task-status-bar class="d-block mb-3" *ngIf="task?.id" [statuses]="task?.statuses" [activeStatus]="task?.status"></dvtx-task-status-bar>
<ng-container *ngIf="form">
  <form [formGroup]="form">
    <div class="row mb-2">
      <div class="col-12" [class.col-sm-9]="form.get('taskType')">
        <mat-form-field>
          <input matInput formControlName="title" [placeholder]="'GENERAL.TITLE' | translate" />
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-3" *ngIf="form.get('taskType')">
        <mat-form-field>
          <mat-select [placeholder]="'TASK.TASK_TYPE' | translate" formControlName="taskType">
            <mat-option *ngFor="let type of taskTypes | enumKeys" [value]="taskTypes[type]">
              {{taskTypeTitle[type] | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-12 col-sm-6">
        <label>{{'GENERAL.RESPONSIBLE' | translate }}</label>
        <dvtx-person-selector-autocomplete
          [disabled]="form.disabled || task.permissions.canChangeResponsible === false"
          [avatarSize]="'xs'"
          [selectedProfile]="({email: (form.value.responsibleEmail || task?.responsible?.email)})"
          [title]="'TASK.ADD_RESPONSIBLE' | translate"
          [persons]='contacts$ | async'
          (onSelection)="onResponsibleChanges($event)">
        </dvtx-person-selector-autocomplete>
      </div>
      <div class="col-12 col-sm-6">
        <label>{{'TASK.ASSIGNEES' | translate}}</label>
        <dvtx-person-selector-autocomplete
          [disabled]="form.disabled || task.permissions.canAssignParticipant === false || task.permissions.canRemoveParticipant === false"
          [multipeSelection]="true"
          [avatarSize]="'xs'"
          [selectedProfiles]="task?.id ? task?.assignees : form.value.assignees"
          [title]="'TASK.ASSIGNEES' | translate"
          [persons]='contacts$ | async'
          (onSelection)="onAssigneesAdded($event)"
          (onUnSelection)="onAssigneesRemoved($event)">
        </dvtx-person-selector-autocomplete>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-12 col-sm-6">
        <dvtx-datepicker
          [form]="form"
          [startAt]="null"
          [placeholder]="'GENERAL.DUE_DATE' | translate"
          [controlName]="'dueDate'">
        </dvtx-datepicker>
      </div>
      <div class="col-12 col-sm-6">
        <label>{{'GENERAL.PRIORITY' | translate}}</label>
        <dvtx-priority-selector (onSelection)="onPriorityChanges($event)" [selected]="form.get('priority').value" [disabled]="form.disabled"></dvtx-priority-selector>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-12">
        <dvtx-wysiwyg-editor-cva formControlName="description"
                                 [height]="280"
                                 [heightMax]="280"
                                 [editableFeature]="false">
        </dvtx-wysiwyg-editor-cva>

      </div>
    </div>
  </form>

</ng-container>
