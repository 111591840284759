import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {TaskResource} from './task-resource';

export class TaskResourceBuilder implements IApiResourceBuilder<TaskResource> {
  fromResponse(data): TaskResource {
    const resource = new TaskResource(
      data.id,
      data.attributes.title,
      data.attributes.description,
      data.attributes.creator_email,
      data.attributes.resource_id,
      data.attributes.resource_type,
      data.attributes.created_at,
      data.attributes.updated_at
    );

    return resource;
  }

  toRequest(taskResource: TaskResource): any {
    return {
      data: {
        attributes: {
          title: taskResource.title,
          description: taskResource.description,
          resource_id: taskResource.resourceId,
          resource_type: taskResource.resourceType
        }
      }
    }
  }
}
