import {IExternalAccess} from './external-access.interface';

export class ExternalAccess implements IExternalAccess {
  readonly type = 'process_external_accesses';

  senderName: string;
  senderEmail: string;
  recipientFirstName: string;
  recipientLastName: string;
  recipientEmail: string;
  documentIds: string[];
  passphrase: string;
  expiresAt: Date;

  constructor(public id,
              public processId,
              public comment,
              public version,
              public url,
              public uploadEnabled: boolean,
              public commentEnabled: boolean,
              public createdAt = null,
              public updatedAt = null) {
  }
}
