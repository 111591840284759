import {Action} from '@ngrx/store';
import * as model from './member';
import * as modelInterface from './member.interface';
import * as organizationModel from './../organization/organization';

export enum OperatorMemberActionTypes {
  Create = '[Operator.Member] Create',
  CreateSuccess = '[Operator.Member] Create Success',
  CreateFail = '[Operator.Member] Create Fail',
  Remove = '[Operator.Member] Remove',
  RemoveSuccess = '[Operator.Member] Remove Success',
  RemoveFail = '[Operator.Member] Remove Fail',
  Save = '[Operator.Member] Save',
  SaveSuccess = '[Operator.Member] Save Success',
  SaveFail = '[Operator.Member] Save Fail',
  Status = '[Operator.Member] Status',
  LoadAll = '[Operator.Member] Load All',
  LoadAllSuccess = '[Operator.Member] Load All Success',
  LoadAllFail = '[Operator.Member] Load All Fail',
  LoadOne = '[Operator.Member] Load One',
  LoadOneSuccess = '[Operator.Member] Load One Success',
  LoadOneFail = '[Operator.Member] Load One Fail',
  Reset = '[Operator.Member] Reset All'
}

export class Create implements Action {
  readonly type = OperatorMemberActionTypes.Create;

  constructor(public organizationId: string, public params: modelInterface.Operator.IMemberParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = OperatorMemberActionTypes.CreateSuccess;

  constructor(public member: model.Operator.Member) {
  }
}

export class CreateFail implements Action {
  readonly type = OperatorMemberActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = OperatorMemberActionTypes.Remove;

  constructor(public organization: organizationModel.Operator.Organization, public membership: model.Operator.Member) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = OperatorMemberActionTypes.RemoveSuccess;

  constructor(public member: model.Operator.Member) {
  }
}

export class RemoveFail implements Action {
  readonly type = OperatorMemberActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = OperatorMemberActionTypes.Save;

  constructor(public member: model.Operator.Member) {
  }
}

export class SaveSuccess implements Action {
  readonly type = OperatorMemberActionTypes.SaveSuccess;

  constructor(public member: model.Operator.Member) {
  }
}

export class SaveFail implements Action {
  readonly type = OperatorMemberActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = OperatorMemberActionTypes.LoadAll;

  constructor(public userId: number = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = OperatorMemberActionTypes.LoadAllSuccess;

  constructor(public members: model.Operator.Member[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = OperatorMemberActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = OperatorMemberActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = OperatorMemberActionTypes.LoadOneSuccess;

  constructor(public member: model.Operator.Member) {
  }
}

export class LoadOneFail implements Action {
  readonly type = OperatorMemberActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}
export class Reset implements Action {
  readonly type = OperatorMemberActionTypes.Reset;

  constructor() {
  }
}

export type OperatorMemberActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Reset;
