import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'dvtx-simple-dashboard-panel',
  templateUrl: './simple-dashboard-panel.component.html',
  styleUrls: ['./simple-dashboard-panel.component.scss']
})
export class SimpleDashboardPanelComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() icon = '';
  @Input() subtext = '';
  @Input() size?;
  @Input() bgColor?;
  @Output() panelDada: EventEmitter<any> = new EventEmitter();

  getBgColor() {
    return this.bgColor ? this.bgColor : '#f5f5f5';
  }

  onPanelSelect(event) {
    this.panelDada.emit({ event });
  }
}
