import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {LoginSettingsBuilder, OrganizationLogoBuilder} from './organization-logo.builder';
import {LoginSettings, OrganizationLogo} from './organization-logo';
import {Organization} from '../../models/organization.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AngularTokenService} from 'angular-token';
import {map} from 'rxjs/operators';
import {IApiResponse} from 'app/shared/modules/api-resource/models/api.interface';
import {ApiResourceBuilder} from 'app/shared/modules/api-resource/builders/api-resource.builder';
import {EnvService} from 'app/shared/modules/api-resource/services/env.service';

@Injectable()
export class OrganizationLogoService {
  readonly BASE_PATH = 'api/v1/organizations/logos';
  readonly LOGIN_SETTINGS_BASE_PATH = 'api/v1/settings/login_settings';
  builder: OrganizationLogoBuilder;
  apiUrl;

  constructor(private _http: ApiResourceService, private _tokenSvc: AngularTokenService, private _httpClient: HttpClient, private env: EnvService) {
    this.builder = new OrganizationLogoBuilder();
    this.apiUrl = env.apiBase();
  }

  getAll(): Observable<OrganizationLogo[]> {
    return this._http.getAll<OrganizationLogoBuilder, OrganizationLogo>(this.builder, this.BASE_PATH);
  }

  getCurrent(organization: Organization = null): Observable<OrganizationLogo> {
    // Workaround to race condition: Organization is present in store, and subscriptions are triggered, but X-ORGANIZATION header is not set because ID not present in-time.
    // With this implementation we set the authentication headers and access tokens manually.
    if (organization) {
      const currentAuthData = this._tokenSvc.currentAuthData;
      const headersConfig = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };
      for (const key in currentAuthData) {
        if (currentAuthData.hasOwnProperty(key)) {
          headersConfig[key] = currentAuthData[key];
        }
      }
      headersConfig['access-token'] = currentAuthData['accessToken'];
      headersConfig['X-ORGANIZATION'] = organization.id;
      delete headersConfig['tokenType'];
      delete headersConfig['accessToken'];
      const headers = new HttpHeaders(headersConfig);
      return <Observable<OrganizationLogo>>this._httpClient.get(`${this.apiUrl}/${this.BASE_PATH}/current`, {headers: headers })
        .pipe(
          map((res: IApiResponse) => ApiResourceBuilder.fromResponse(this.builder, res))
        );
    }
    return <Observable<OrganizationLogo>>this._http.get<OrganizationLogoBuilder, OrganizationLogo>(this.builder, `${this.BASE_PATH}/current`);
  }

  update(id, publish): Observable<OrganizationLogo> {
    const payload = {
      data: {
        attributes: {
          publish: publish
        }
      }
    };
    return this._http.put<OrganizationLogoBuilder, OrganizationLogo>(this.builder, `${this.BASE_PATH}/${id}`, payload);
  }

  getLoginSettings(domain): Observable<LoginSettings> {
    const payload = {
      data: {
        attributes: {
          domain: domain
        }
      }
    };
    const builder = new LoginSettingsBuilder();
    return this._http.post<LoginSettingsBuilder, LoginSettings>(builder, `${this.LOGIN_SETTINGS_BASE_PATH}`, payload);
  }

  remove(resource: OrganizationLogo): Observable<OrganizationLogo> {
    return this._http.delete<OrganizationLogoBuilder, OrganizationLogo>(this.builder, this.BASE_PATH, resource);
  }
}
