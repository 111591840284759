<div [formGroup]="form" class="dvtx-api-select" [ngClass]="{'form-control-wrap form-control-wrap_iconed': hint}">
  <mat-form-field>
    <mat-select
      [multiple]="multiple"
      [formControlName]="controlName"
      placeholder="{{placeholder}}"
      [id]="controlName"
      (selectionChange)="changeEvent($event)"
      class="dvtx-input-full-width"
      [disabled]="isDisabled">
      <mat-option *ngFor="let item of listing" [value]="item.value">
        <span *ngIf="item.hint" [matTooltip]="item.hint">{{ item.name | translate }}</span>
        <span *ngIf="!item.hint">{{ item.name | translate }}</span>
      </mat-option>
    </mat-select>
    <mat-icon *ngIf="prefixIcon" matPrefix>{{prefixIcon}}</mat-icon>
  </mat-form-field>
  <div [ngClass]="{'form-control-icon form-control-icon_visible': hint}">
    <mat-icon *ngIf="hint" [matTooltip]="hint">info</mat-icon>
  </div>
</div>

