<button mat-icon-button
        cdk-overlay-origin
        (click)="showDropdown = !showDropdown"
        (mouseenter)="showDropdown = true"
        (mouseleave)="showDropdown = false"
        #dropdown="cdkOverlayOrigin"
        [matTooltip]="!title ? title : ''"
        [matTooltipPosition]="'above'"
        color="gray"
        [disableRipple]='true'>
  <mat-icon *ngIf="icon && icon === 'supervised_user_circle'"  svgIcon="supervised_user_circle"></mat-icon>
  <mat-icon *ngIf="icon && icon !== 'supervised_user_circle'">{{icon}}</mat-icon>
  <ng-container *ngIf="title && showTitle">
    <span class="text">
      {{title}}
    </span>
  </ng-container>
</button>
<ng-template cdk-connected-overlay
             [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-auto-size-backdrop'"
             [cdkConnectedOverlayOrigin]="dropdown"
             [cdkConnectedOverlayHasBackdrop]="true"
             [cdkConnectedOverlayOpen]="showDropdown"
             (backdropClick)="showDropdown = false">
  <div style="background-color: white;" class="mat-elevation-z2 px-3">
    <ul style="margin: 0;">
      <li class="my-1" *ngFor="let person of (_persons | async)">
        <dvtx-avatar mat-list-icon [size]="'xs'" [email]="person" [showTitle]='showAvatarTitle'></dvtx-avatar>
      </li>
    </ul>
  </div>
</ng-template>
