import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  TenantOrganizationActionTypes,
  Save,
  SaveFail,
  SaveSuccess
} from './organization.actions';
import * as model from './organization';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {TenantOrganizationService} from './organization.service';

@Injectable()
export class TenantOrganizationEffects {
  @Effect()
  update$ = this.actions.pipe(
    ofType(TenantOrganizationActionTypes.Save),
    switchMap((action: Save) => {
      return this._svc.update(action.tenantId, action.organization).pipe(
        first(),
        switchMap((organization: model.Tenant.Organization) => {
          return [new SaveSuccess(organization)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveFail(err));
        }));
    })
  );

  @Effect()
  loadOne$ = this.actions.pipe(
    ofType(TenantOrganizationActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.tenantId, action.id).pipe(
        first(),
        concatMap((organization: model.Tenant.Organization) => {
          return [new SaveSuccess(organization)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  );

  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(TenantOrganizationActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.tenantId).pipe(
        first(),
        concatMap((res: model.Tenant.Organization[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: TenantOrganizationService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
