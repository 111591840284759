import {Component, Input, OnInit} from '@angular/core';
import {ContactTypes} from '../../modules/address-book-table/containers/address-book-table/address-book-table.model';

@Component({
  selector: 'dvtx-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss']
})
export class AddressBookComponent implements OnInit {

  @Input() enableToolbar = true;
  @Input() searchTerm: string = null;
  @Input() filterByClients = [];
  @Input() filterByOptions = [];
  @Input() clearAllFilters;
  @Input() contactType = ContactTypes.All;

  constructor() { }

  ngOnInit() {
  }
}
