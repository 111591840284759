import {Component, OnInit} from '@angular/core';
import {OrganizationSelectors} from '../../../../../+store/organization';
import {Observable} from 'rxjs';
import {Organization} from '../../../../../models/organization.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../reducers';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'dvtx-organization-customization-frame',
  templateUrl: './customization-frame.component.html',
  styleUrls: ['./customization-frame.component.scss']
})
export class CustomizationFrameComponent implements OnInit {
  public organization$: Observable<Organization>;

  public returnUrl: string;
  public selectedTab: any = 0;

  public tabs = [
    'logo',
    'templates',
    'signature'
  ];

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('tab')) {
      this.selectedTab = this.tabs.indexOf(this.route.snapshot.paramMap.get('tab'));
    }
    if (this.route.snapshot.queryParams['returnUrl']) {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    }
    this.organization$ = this.store.select(OrganizationSelectors.getSelected);
  }
}
