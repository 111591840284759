import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProjectStatisticsEffects } from './project-statistics.effects';
import { ProjectStatisticsService } from './project-statistics.service';
import { reducer } from './project-statistics.reducer';
import { stateKey } from './project-statistics.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ProjectStatisticsEffects])
  ],
  providers: [
    ProjectStatisticsEffects,
    ProjectStatisticsService
  ]
})
export class ProjectStatisticsStoreModule {
}
