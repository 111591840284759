<div class="w-100 p-3 mb-2 mx-2 d-flex flex-column five-f-registration-status">
  <div class="d-flex flex-row mt-1" style="height: 64px;">
    <ng-container *ngIf="!accountCompleted || !dmsAccountPresent">
      <h2 class="mb-3">
        {{ 'REGISTRATION.YOUR_ACCOUNT_WILL_BE_CREATED' | translate }}
      </h2>
      <div class="ml-3">
        <mat-spinner [diameter]="20" aria-label="loading..." style="margin: 0 !important;"></mat-spinner>
      </div>
    </ng-container>
    <h2 *ngIf="accountCompleted && dmsAccountPresent">
      {{ 'REGISTRATION.YOUR_ACCOUNT_WAS_CREATED' | translate }}
    </h2>
  </div>

  <div>
    <ul>
      <li class="checklist-item">
        <div class="d-flex">
          <div class="pr-3"><mat-icon class="checklist-icon checklist-icon-success">check_circle</mat-icon></div>
          <div class="d-flex align-self-center">
            <div class="h3">
              {{ 'REGISTRATION.SET_PASSWORD' | translate }}
            </div>
          </div>
        </div>
      </li>

      <li class="checklist-item">
        <div class="d-flex">
          <ng-container *ngIf="dmsAccountPresent; else creatingDmsAccount">
            <div class="pr-3"><mat-icon class="checklist-icon checklist-icon-success">check_circle</mat-icon></div>
            <div class="d-flex align-self-center">
              <div class="h3 dvtx-loading-indicator">
                {{ 'REGISTRATION.STORAGE_CREATED' | translate }}
              </div>
            </div>
          </ng-container>

          <ng-template #creatingDmsAccount>
            <div class="pr-3"><mat-icon class="checklist-icon checklist-icon-in-progress">check_circle</mat-icon></div>
            <div class="d-flex align-self-center">
              <div class="h3 dvtx-loading-indicator">
                {{ 'REGISTRATION.CREATING_STORAGE' | translate }}<span>.</span><span>.</span><span>.</span>
              </div>
            </div>
          </ng-template>
        </div>
      </li>

      <li class="checklist-item">
        <div class="d-flex">
          <ng-container *ngIf="accountCompleted; else completingAccount">
            <div class="pr-3"><mat-icon class="checklist-icon checklist-icon-success">check_circle</mat-icon></div>
            <div class="d-flex align-self-center">
              <div class="h3">
                {{ 'REGISTRATION.ACCOUNT_CREATED' | translate }}
              </div>
            </div>
          </ng-container>
          <ng-template #completingAccount>
            <div class="pr-3"><mat-icon class="checklist-icon checklist-icon-in-progress">check_circle</mat-icon></div>
            <div class="d-flex align-self-center">
              <div class="h3 dvtx-loading-indicator">
                {{ 'REGISTRATION.CREATING_ACCOUNT' | translate }}<span>.</span><span>.</span><span>.</span>
              </div>
            </div>
          </ng-template>
        </div>
      </li>
    </ul>
  </div>
  <div class="d-flex justify-content-end mt-3">
<!--  DEBUG: Return to previous step.  -->
<!--    <div class="mr-1">-->
<!--      <button mat-button color="primary" (click)="onBack.emit()" [disabled]="accountCompleted && dmsAccountPresent">{{ 'REGISTRATION.BACK_ACTION' | translate }}</button>-->
<!--    </div>-->
    <div>
      <button mat-raised-button color="primary" (click)="onNext.emit()" [disabled]="!accountCompleted || !dmsAccountPresent">{{ 'REGISTRATION.LETS_GO' | translate }}</button>
    </div>
  </div>
</div>
