import {Observable, of as observableOf} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'environments/environment';
import {AngularTokenService} from 'angular-token';
import {TwoFactorAuthBuilder} from './two-factor-auth.builder';
import {IAuthorizationCode} from './two-factor-auth.interface';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorAuthService {
  basePath;
  TwoFactorAuthBuilder = new TwoFactorAuthBuilder();

  private credentials: { email: string, password: string, organizationName: string } = null;

  constructor(private _tokenService: HttpClient,
              private tokenService: AngularTokenService) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}`;
  }

  public setCredentials(email: string, password: string, organizationName: string) {
    this.credentials = {
      email: email,
      password: password,
      organizationName: organizationName
    }
  }

  public readCredentials(reset = true) {
    if (!this.credentials) {
      return null;
    }
    const cred = Object.assign({}, this.credentials);
    if (reset) {
      this.resetCredentials();
    }
    return cred;
  }

  public resetCredentials() {
    this.credentials = null;
  }

  /**
   * Retrieves a generic authorization code for user verification.
   * Always returns the last one. A code is currently valid for 1 minute.
   *
   * This API is independent of the 2-FA OTP API.
   *
   * Currently used to connect user accounts with tenants.
   */
  currentAuthCode(): Observable<IAuthorizationCode> {
    return this._tokenService.get(`${this.basePath}/auth/authorization_codes/current`)
      .pipe(
        map((res: any) => {
          const attr = res.data;
          return {
            id: attr.id,
            type: 'auth_authorization_codes',
            code: attr.attributes.code,
            expiresAt: attr.attributes.expires_at,
          }
        })
      );
  }

  /**
   * Refreshes the generic authorization code for user verification.
   * Old not expired codes are still valid.
   *
   * This API is independent of the 2-FA OTP API.
   *
   * Currently used to connect user accounts with tenants.
   */
  refreshAuthCode(): Observable<IAuthorizationCode> {
    return this._tokenService.post(`${this.basePath}/auth/authorization_codes/refresh`, {})
      .pipe(
        map((res: any) => {
          const attr = res.data;
          return {
            id: attr.id,
            type: 'auth_authorization_codes',
            code: attr.attributes.code,
            expiresAt: attr.attributes.expires_at,
          }
        })
      );
  }

  status(): Observable<any> {
    return this._tokenService.get(`${this.basePath}/auth/two_factor_auth/status`).pipe(map((resp) => {
      return this.TwoFactorAuthBuilder.fromResponse(resp);
    }));
  }

  verify_phone(otp: string): Observable<any> {
    const payload = { data: { attributes: { otp: otp } } };
    return this._tokenService.post(`${this.basePath}/auth/two_factor_auth/verify_phone`, payload).pipe(map(() => {
      return true;
    }));
  }

  verifyPhonePublic(token: string, otp: string): Observable<any> {
    const payload = { data: { attributes: { otp: otp } } };
    return this._tokenService.post(`${this.basePath}/auth/two_factor_auth/verify_phone_public/${token}`, payload).pipe(map(() => {
      return true;
    }));
  }

  enable2FA(): Observable<any> {
    return this._tokenService.post(`${this.basePath}/auth/two_factor_auth/enable`, {});
  }

  disable2FA(otp: string): Observable<any> {
    const payload = {data: {attributes: {otp: otp}}};
    return this._tokenService.post(`${this.basePath}/auth/two_factor_auth/disable`, payload).pipe(
      catchError(error => {
        console.log(error)
        if (error.errors) {
          for (let i = 0; error.errors.length < i; i++) {
            if (error.errors[i].code === 'api.auth.otp.error.code_mismatch') {
              return error.error.error.text;
            }
          }
        } else {
          return observableOf(error);
        }
      }));
  }

  enableEmail(email?: string): Observable<any> {
    const payload = { data: { attributes: { email: email } } };
    return this._tokenService.post(`${this.basePath}/auth/two_factor_auth/enable_mail`, payload).pipe(map(() => {
      return true;
    }));
  }

  enableSMS(phone: string): Observable<any> {
    const payload = { data: { attributes: { phone: phone } } };
    return this._tokenService.post(`${this.basePath}/auth/two_factor_auth/enable_phone`, payload).pipe(map(() => {
      return true;
    }));
  }

  enableSMSPublic(token: string, phone: string): Observable<any> {
    const payload = { data: { attributes: { phone: phone } } };
    return this._tokenService.post(`${this.basePath}/auth/two_factor_auth/enable_phone_public/${token}`, payload).pipe(map(() => {
      return true;
    }));
  }

  requestOTPByEmail(email?: string): Observable<any> {
    const payload = { data: { attributes: { email: email } } };
    return this._tokenService.post(`${this.basePath}/auth/two_factor_auth/mail`, payload).pipe(map(() => {
      return true;
    }));
  }

  requestOTPBySMS(email: string): Observable<any> {
    const payload = { data: { attributes: { email: email } } };
    return this._tokenService.post(`${this.basePath}/auth/two_factor_auth/sms`, payload).pipe(map(() => {
      return true;
    }), catchError(error => {
      if (error.status === '200') {
        return error;
      } else {
        return observableOf(error);
      }

    }));
  }


  setHeaders(): HttpHeaders {
    const currentAuthData: any = this.tokenService.currentAuthData;
    const headersConfig = {
      'Content-Type': 'application/xml',
      'Accept': 'application/xml',
    };
    for (const key in currentAuthData) {
      if (currentAuthData.hasOwnProperty(key)) {
        headersConfig[key] = currentAuthData[key];
      }
    }
    headersConfig['access-token'] = currentAuthData['accessToken'];
    headersConfig['Content-Type'] = 'application/xml';
    headersConfig['Accept'] = 'application/xml';
    delete headersConfig['tokenType'];
    delete headersConfig['accessToken'];
    return new HttpHeaders(headersConfig)
  }

  /**
   * Requests a QR code to be used for authenticator apps.
   * @param otp
   */
  enableMobileAuthenticator(otp: string, verify = false): Observable<any> {
    const payload = { data: { attributes: { otp: otp } } };
    return this._tokenService.post(`${this.basePath}/auth/two_factor_auth/qr${verify ? '?verify=true' : ''}`, payload, {
      headers: this.setHeaders(),
    }).pipe(
      map((response) => {
        return {mobileSuccessfull: true, mobileImage: response['_body']}
      }), catchError(error => {
        if (error.status === '200') {
          return error.error.error.text;
        } else {
          return observableOf(error);
        }

      }));
  }
}

