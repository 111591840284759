import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DmsStatusService} from './dms-status.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    DmsStatusService
  ]
})
export class DmsStatusStoreModule {
}
