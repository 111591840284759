import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {DatevClient} from './datev-client';
import {DatevClientBuilder} from './datev-client.builder';

@Injectable()
export class DatevClientService {
  readonly BASE_PATH = 'api/v1/datev/clients';


  constructor(private _http: ApiResourceService) {
  }

  getAll(): Observable<DatevClient[]> {
    const builder = new DatevClientBuilder();
    return <Observable<DatevClient[]>>this._http.get<DatevClientBuilder, DatevClient>(builder, `${this.BASE_PATH}`);
  }

  syncAll(): Observable<DatevClient[]> {
    const builder = new DatevClientBuilder();
    return <Observable<DatevClient[]>>this._http.postAll<DatevClientBuilder, DatevClient>(builder, `${this.BASE_PATH}/sync`, {});
  }

  syncOne(clientId: string): Observable<DatevClient> {
    const builder = new DatevClientBuilder();
    return <Observable<DatevClient>>this._http.post<DatevClientBuilder, DatevClient>(builder, `${this.BASE_PATH}/sync/${clientId}`, {});
  }

  /**
   * Returns the client info by the long running offline token and the info endpoint (!).
   *
   * @param id: string - 5F Client ID.
   */
  getClientInfo(id: string): Observable<DatevClient> {
    const builder = new DatevClientBuilder();
    return <Observable<DatevClient>>this._http.get<DatevClientBuilder, DatevClient>(builder, `${this.BASE_PATH}/info/${id}`);
  }
}
