<ng-container *ngIf="labels || (showLabelsSelector && _labelsIds)">
  <div class="w-100" #labelsList>
    <ul class="list-inline d-flex flex-wrap mb-0 five-f-labels {{classList}}">
      <ng-template [ngIf]="showAll">
        <ng-container *ngFor="let label of labels" >
          <li class="list-inline-item" *ngIf="label && label.id && label.title">
            <dvtx-label [label]="label" [readonly]="readonly || label?.group?.isContextual" (onClick)="onClick.emit($event)"
                        (onRemove)="onRemove.emit($event)"></dvtx-label>
          </li>
        </ng-container>
      </ng-template>
    <ng-template [ngIf]="!showAll">
      <ng-container *ngFor="let label of (labels | slice:0:numberOfVisibleLabels)" >
        <li class="list-inline-item" *ngIf="label && label.id && label.title">
          <dvtx-label [label]="label" [readonly]="readonly || label?.group?.isContextual" (onClick)="onClick.emit($event)"
                      (onRemove)="onRemove.emit($event)"></dvtx-label>
        </li>
      </ng-container>
    </ng-template> 
      <ng-template [ngIf]="isShowMore && !showAll">
        <ng-container *ngFor="let label of (labels | slice:numberOfVisibleLabels:labels.length)">
          <li class="list-inline-item" *ngIf="label && label.id && label.title">
            <dvtx-label [label]="label" [readonly]="readonly || label?.group?.isContextual" (onClick)="onClick.emit($event)"
                        (onRemove)="onRemove.emit($event)"></dvtx-label>
          </li>
        </ng-container>
      </ng-template>
      <li class="list-inline-item"
          *ngIf="numberOfVisibleLabels < labels.length && !isShowMore && !showLabelsSelector && canShowMore && !showAll">
        <dvtx-tooltip [tooltipText]="'GENERAL.SHOW_MORE' | translate" [position]="'top left'">
        <span class="five-f-labels-item dvtx-pointer" [style.border]="'1px solid #3c3c3c'" [style.color]="'#3c3c3c'"
              (click)="showMoreClickFun ? onShowMoreClickFun.emit() : isShowMore = true">
          +{{ labels.length - maxToShow }}
        </span>
        </dvtx-tooltip>
      </li>
      <li class="list-inline-item"
          *ngIf="maxToShow < labels.length && isShowMore && !showLabelsSelector && canShowMore && !showAll">
        <dvtx-tooltip [tooltipText]="'GENERAL.SHOW_LESS' | translate" [position]="'top left'">
        <span class="five-f-labels-item dvtx-pointer" [style.border]="'1px solid #3c3c3c'" [style.color]="'#3c3c3c'"
              (click)="isShowMore = false">
          -{{ labels.length - maxToShow }}
        </span>
        </dvtx-tooltip>
      </li>
      <li class="list-inline-item" *ngIf="showLabelsSelector && allLabels?.length > 0 && _labelsIds && !showAll">
        <dvtx-labels-selector [labels]="allLabels" [selected]="_labelsIds" (onSelection)="onAdd.emit($event)"
                              [isTextButton]="addLabelButtonIsText"
                              [labelIcon]="true"
                              [hiddenCount]="(labels?.length)"></dvtx-labels-selector>
      </li>
      <li class="list-inline-item" *ngIf="showLabelsSelector && allLabels?.length > 0 && _labelsIds && showAll">
        <dvtx-labels-selector [labels]="allLabels" [selected]="_labelsIds" (onSelection)="onAdd.emit($event)"
                              [isTextButton]="addLabelButtonIsText" [hiddenCount]="(labels?.length)"
                              [labelIcon]="true"></dvtx-labels-selector>
      </li>
    </ul>
  </div>

</ng-container>
