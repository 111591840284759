import {HttpErrorResponse} from '@angular/common/http'
import {Action} from '@ngrx/store'
import {Role} from '../../models/roles.model'

export enum RoleActionTypes {
  LoadAll = '[Role] Load All',
  LoadAllSuccess = '[Role] Load All Success',
  LoadAllFail = '[Role] Load All Fail',
  ChangeOwner = '[Role] Change Owner',
  ChangeOwnerSuccess = '[Role] Change Owner Success',
  ChangeOwnerFail = '[Role] Change Owner Fail',
  Create = '[Role] Create',
  CreateSuccess = '[Role] Create Success',
  CreateFail = '[Role] Create Fail',
  Delete = '[Role] Delete',
  DeleteSuccess = '[Role] Delete Success',
  DeleteFail = '[Role] Delete Fail',
  Add = '[Role] Add',
  AddSuccess = '[Role] Add Success',
  AddFail = '[Role] Add Fail',
  Remove = '[Role] Remove',
  RemoveSuccess = '[Role] Remove Success',
  RemoveFail = '[Role] Remove Fail'
}

export class LoadAll implements Action {
  readonly type = RoleActionTypes.LoadAll
}

export class LoadAllSuccess implements Action {
  readonly type = RoleActionTypes.LoadAllSuccess;

  constructor(public payload: Role[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = RoleActionTypes.LoadAllFail;

  constructor(public payload: HttpErrorResponse) {
  }
}

export class Create implements Action {
  readonly type = RoleActionTypes.Create;

  constructor(public roleName: string) {
  }
}

export class CreateSuccess implements Action {
  readonly type = RoleActionTypes.CreateSuccess;

  constructor(public payload: Role) {
  }
}

export class CreateFail implements Action {
  readonly type = RoleActionTypes.CreateFail;

  constructor(public error: HttpErrorResponse) {
  }
}

export class ChangeOwner implements Action {
  readonly type = RoleActionTypes.ChangeOwner;

  constructor(public personId: string) {
  }
}

export class ChangeOwnerSuccess implements Action {
  readonly type = RoleActionTypes.ChangeOwnerSuccess;

  constructor(public payload = null) {
  }
}

export class ChangeOwnerFail implements Action {
  readonly type = RoleActionTypes.ChangeOwnerFail;

  constructor(public error: HttpErrorResponse) {
  }
}

export class Delete implements Action {
  readonly type = RoleActionTypes.Delete;

  constructor(public roleId: string) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = RoleActionTypes.DeleteSuccess;

  constructor(public roleId: string) {
  }
}

export class DeleteFail implements Action {
  readonly type = RoleActionTypes.DeleteFail;

  constructor(public error: HttpErrorResponse) {
  }
}

export class Add implements Action {
  readonly type = RoleActionTypes.Add;

  constructor(public payload: {
    membershipId: string,
    roleId: string,
  }) {
  }
}

export class AddSuccess implements Action {
  readonly type = RoleActionTypes.AddSuccess;

  constructor(public role: Role) {
  }
}

export class AddFail implements Action {
  readonly type = RoleActionTypes.AddFail;

  constructor(public error: HttpErrorResponse) {
  }
}

export class Remove implements Action {
  readonly type = RoleActionTypes.Remove;

  constructor(public payload: {
    membershipId: string,
    roleId: string,
  }) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = RoleActionTypes.RemoveSuccess;

  constructor(public roleId: string) {
  }
}

export class RemoveFail implements Action {
  readonly type = RoleActionTypes.RemoveFail;

  constructor(public error: HttpErrorResponse) {
  }
}

export type RoleActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | Create
  | CreateSuccess
  | CreateFail
  | ChangeOwner
  | ChangeOwnerSuccess
  | ChangeOwnerFail
  | Add
  | AddSuccess
  | AddFail
  | Remove
  | RemoveSuccess
  | RemoveFail
