import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './two-factor-session.state';

export const stateKey = 'two-factor-session';
const getTwoFactorSessionState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getTwoFactorSessionEntities,
  selectAll: getAllTwoFactorSessions,
} = adapter.getSelectors(getTwoFactorSessionState);

export const loadingState = createSelector(
  getTwoFactorSessionState,
  (state) => state.loading
);

export const getDialogRequest = createSelector(
  getTwoFactorSessionState,
  (state) => state.openDialog
);

export const getByUid = (uid) => createSelector(
  getTwoFactorSessionEntities,
  (sessions) => sessions[uid]);
