import { IApiResourceBuilder } from 'app/shared/modules/api-resource/models/api.interface';
import { QuickCollectorItemOrder } from './quickcollector';

export class QuickCollectorItemOrderBuilder implements IApiResourceBuilder<QuickCollectorItemOrder> {
  fromResponse(data): QuickCollectorItemOrder {
    return new QuickCollectorItemOrder(
      data.attributes.nodes
    );

  }
  toRequest(_: QuickCollectorItemOrder) {
    return null;
  }
}
