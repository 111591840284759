import {Label} from 'app/+store/label/label';

export class Item {
  readonly type = 'label-item';
  public processId: string;
  public processTitle: string;
  public processType: string;
  public title: string = '';
  public labels: Label[] = [];
  public color: string = '#fff';
  public client: string = '';
  public labelIds: string[] = [];

  total: number;
  perPage: number;
  records: number;

  constructor(
    public id: string,
    public referenceId: string,
    public referenceType: LabelItemType,
    public updatedAt: Date
  ) {
  }
}

export enum LabelItemType {
  Task = 'task',
  CollectoItem = 'collecto_item',
  Document = 'document',
  TaskApproval = 'taskApproval'
}
