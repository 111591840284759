import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './membership.state';
import {OrganizationSelectors} from '../organization'

export const stateKey = 'membership';
const getMembershipState = createFeatureSelector<State>(stateKey)

export const {
  selectEntities: getMembershipEntities,
  selectAll: getAllMemberships,
} = adapter.getSelectors(getMembershipState);

export const getMembershipsLoading = createSelector(
  getMembershipState,
  state => state.loading
);

export const getMyMembershipId = createSelector(
  getMembershipState,
  state => state.my
);

export const getMyMembership = createSelector(
  getMembershipEntities,
  getMyMembershipId,
  (entities, id) => id ? entities[id] : undefined
)

export const isAdmin = createSelector(
  getMyMembership,
  (membership) => !!membership && !!membership.hasAdministrationRights
)

export const isOwner = createSelector(
  getMyMembership,
  (membership) => !!membership && !!membership.isOrganizationalOwner
)

export const getMembershipById = (id: string) => createSelector(
  getMembershipEntities,
  entities => entities[id]
)

export const getMembershipsByNatPersonId = (natPersonId: string) => createSelector(
  getAllMemberships,
  entities => entities.filter(entity => entity.naturalProfileId === natPersonId)
)

export const getMembershipsOfSelectedOrg = createSelector(
  OrganizationSelectors.getSelectedId,
  getAllMemberships,
  (id, entities) => entities.filter(entity => entity.organizationId === id)
)

export const getMembershipOfSelectedOrgByNatPersonId = (natPersonId: string) => createSelector(
  OrganizationSelectors.getSelectedId,
  getMembershipsByNatPersonId(natPersonId),
  (orgId, entities) => entities.find(entity => entity.organizationId === orgId)
)

