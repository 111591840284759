import {Injectable} from '@angular/core';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';

/**
 * Collecto item specific actions or service calls.
 *
 * Note: All former API calls are migrated to the new generic
 *       BomDataNodeService.
 */
@Injectable()
export class CollectorItemService {
  readonly BASE_PATH = 'api/v1/clr';
  private lookupItemCache = {};

  constructor(private _http: ApiResourceService) {}
}
