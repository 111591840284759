import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';


import {HttpClient} from '@angular/common/http';

@Injectable()
export class FoxdoxAccountService {
  basePath;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}`;
  }

  signInFoxdoxAccount(credentials) {
    const payload = {
      data: {
        attributes: {
          foxdox_username: credentials.foxdoxUsername,
          foxdox_password: credentials.foxdoxPassword
        }
      }
    };
    return this._http.post(`${this.basePath}/foxdox/sign_in`, payload).pipe(
      map((res: any) => res.data));
  }


}
