import {Injectable} from '@angular/core'
import {Actions, Effect, ofType} from '@ngrx/effects'
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators'
import {
  Create,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadDetailed,
  LoadDetailedFail,
  LoadDetailedSuccess,
  OrganizationActionTypes,
  Select,
  SelectAddressBook,
  SelectSuccess,
  UpdateOrga,
  UpdateOrgaSuccess
} from './organization.actions';
import {of} from 'rxjs';
import {Organization, OrganizationDetailResponse, OrganizationListResponse} from '../../models/organization.model';
import {CppApiService} from '../../services/cpp-api.service';
import {Router} from '@angular/router';
import {ShowHttpError} from '../notification/notification.actions';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {LabelActions} from 'app/+store';


@Injectable()
export class OrganizationEffects {

  @Effect()
  loadOrganizations = this.actions.pipe(
    ofType(OrganizationActionTypes.LoadAll),
    switchMap(() => {
      return this.cppApiService.get<OrganizationListResponse>('organization').pipe(
        map((organizations: OrganizationListResponse) => {
            const orgas: Organization[] = [];
            for (const item of organizations.data) {
              orgas.push(new Organization(
                item.id,
                item.attributes.name,
                item.attributes.address_book_id,
                item.attributes.legal_form_id
              ))
            }
              if (orgas.length > 0) {
                // if selectedOrganizationId is saved in locasl storage then loaded on refresh.
                if (localStorage.getItem('selectedOrganizationId') && orgas.find(orga => orga.id === localStorage.getItem('selectedOrganizationId'))) {
                  this.store.dispatch(new SelectSuccess(localStorage.getItem('selectedOrganizationId') as string));
                } else {
                  localStorage.removeItem('selectedOrganizationId'); // in case not belonging into a certain orga anymore the saved id in storage is removed.
                  this.store.dispatch(new SelectSuccess(orgas[0].id as string));
                }
              }

              return new LoadAllSuccess(orgas)
            }
          ),
          catchError(err => of(new LoadAllFail(err)))
        )
      }
    )
  );

  @Effect()
  selectOrganization = this.actions.pipe(
    ofType(OrganizationActionTypes.Select),
    tap((action: Select) => {
        this.store.dispatch(new LabelActions.Reset());
        // save selected organaization id in local storage
        localStorage.setItem('selectedOrganizationId', action.id);
      }
    ),
    filter(() => false)
  );

  @Effect()
  selectOrganizationAddressbook = this.actions.pipe(
    ofType(OrganizationActionTypes.SelectAddressBook),
    tap((action: SelectAddressBook) => {
        this.router.navigate([`/organization/${action.id}/addressbook/contacts`], {queryParams: {'returnUrl': this.router.url}});
        // save selected organaization id in local storage
        localStorage.setItem('selectedOrganizationId', action.id);
      }
    ),
    filter(() => false)
  );

  @Effect()
  updateOrganization = this.actions.pipe(
    ofType(OrganizationActionTypes.UpdateOrga),
    switchMap((action: UpdateOrga) => {
        return this.cppApiService.put<OrganizationDetailResponse>('organization/' + action.payload.id, {
          legal_form_id: String(action.payload.legalFormId),
          name: action.payload.name,
          main_address_id: action.payload.address.id,
          main_address: {
            address_type_id: action.payload.address.locationOrType,
            street: action.payload.address.street,
            street_number: action.payload.address.streetNo,
            post_code: action.payload.address.zip,
            city: action.payload.address.city,
            country: action.payload.address.countryName
          },
          main_phone_number_id: action.payload.telephone.id,
          main_phone_number: {
            address_type_id: String(action.payload.telephone.locationOrType),
            country_code: action.payload.telephone.countryCode,
            phone_number: action.payload.telephone.phoneNumber
          },
          main_email_address_id: action.payload.email.id,
          main_email_address: {
            address_type_id: String(action.payload.email.locationOrType),
            email_address: action.payload.email.emailAddress
          }
        }).pipe(
          map((resp: OrganizationDetailResponse) => {
              const orga = Organization.fromResponse(resp);
              return new UpdateOrgaSuccess(orga)
            }
          ),
          catchError(err => of(new LoadAllFail(err)))
        )
      }
    )
  );

  @Effect()
  createOrganization = this.actions.pipe(
    ofType(OrganizationActionTypes.Create),
    switchMap((action: Create) => {
        return this.cppApiService.post('organization', {
          name: action.payload.contactOrganization.name,
          legal_form_id: String(action.payload.contactOrganization.legalFormId),
          main_address: {
            address_type_id: String(action.payload.contactOrganization.mainAddress.locationOrType),
            street: action.payload.contactOrganization.mainAddress.street,
            street_number: action.payload.contactOrganization.mainAddress.streetNo,
            post_code: action.payload.contactOrganization.mainAddress.zip,
            city: action.payload.contactOrganization.mainAddress.city,
            country: action.payload.contactOrganization.mainAddress.countryName
          },
          main_phone_number: {
            address_type_id: String(action.payload.contactOrganization.mainPhoneNumber.locationOrType),
            country_code: action.payload.contactOrganization.mainPhoneNumber.countryCode,
            phone_number: action.payload.contactOrganization.mainPhoneNumber.phoneNumber
          },
          main_email_address: {
            address_type_id: String(action.payload.contactOrganization.mainEmailAddress.locationOrType),
            email_address: action.payload.contactOrganization.mainEmailAddress.emailAddress
          }
        }).pipe(
          switchMap((resp: OrganizationDetailResponse) => {
              const orga = Organization.fromResponse(resp);
              return [
                new Select(resp.data.id),
                new LoadAll(),
              ]
            }
          ),
          catchError((err) => {
            return of(new ShowHttpError(err))
          })
        )
      }
    )
  );

  @Effect()
  loadDetailesOrganization = this.actions.pipe(
    ofType(OrganizationActionTypes.LoadDetailed),
    switchMap((action: LoadDetailed) => {
        return this.cppApiService.get<OrganizationDetailResponse>('organization/' + action.orgaId).pipe(
          map((resp: OrganizationDetailResponse) => {
              const orga = Organization.fromResponse(resp);
              return new LoadDetailedSuccess(orga)
            }
          ),
          catchError((err) => of(new LoadDetailedFail(err)))
        )
      }
    )
  );

  constructor(private actions: Actions,
              private cppApiService: CppApiService,
              private router: Router,
              private store: Store<AppState>
  ) {
  }
}
