import {DmsFolder} from './dms-folder';
import {DmsAccountType, IDmsFolder} from './dms-folder.interface';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import {catchError, first, map} from 'rxjs/operators';
import {NodeType} from './node-type';
import {NodeBuilder} from './node.builder';
import {INode} from './node.interface';
import {DmsFolderSelectors} from './index';
import {LoadOneSuccess} from './dms-folder.actions';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';

export class DmsINodeStoreRepository {
  builder: NodeBuilder;
  _folders = new BehaviorSubject<IDmsFolder[]>([]);
  _parentMap = new BehaviorSubject<{ [id: string]: IDmsFolder[] }>({});
  _folderSelector;

  constructor(private sourceId: string,
              private _accountType: DmsAccountType,
              private _store: Store<AppState>,
              private _loadAll = false) {
    this.builder = new NodeBuilder(this._accountType);
    this._folderSelector = this._accountType === DmsAccountType.Private ? DmsFolderSelectors.getPrivateFolders : DmsFolderSelectors.getOrganizationFolders(sourceId);
  }

  public destroy() {
    this._folders.complete();
  }

  getRootNode(): Observable<INode> {
    return this._store.select(this._folderSelector)
      .pipe(
        map((folders: IDmsFolder[]): INode => {
          // Private accounts have exactly one root node.
          const parentMap = {}
          folders.forEach(f => {
            if (!parentMap[f.parentId]) {
              parentMap[f.parentId] = [];
            }
            parentMap[f.parentId].push(f);
          });
          this._parentMap.next(parentMap);

          if (this._accountType === DmsAccountType.Private) {
            this._folders.next(folders);
            const root = folders.find(folder => !folder.parentId);
            if (!root) {
              return {
                id: null,
                type: NodeType.DisabledFolder,
                name: null,
                shared: false,
                writePerm: false,
                deletePerm: false,
                renamePerm: false,
                documentCount: null,
                folderCount: null,
                auditProof: false,
                bookmanCockpitEnabled: false,
                parent: null,
                root: true,
                hasChildren: false,
                info: false,
                accountType: this._accountType
              };
            }
            return this.builder.create(root, true);

          } else {
            // Organizational accounts are shared folders:
            // - No admin, no full access: Many shared folders side by side with parent === null.
            //   Create virtual root.
            // - Admin, root.length === 1 and root is DMS root.
            const roots = folders.filter(folder => !folder.parentId);
            const isSharedFolder = (roots[0] && roots[0].documentCount === null && roots[0].folderCount === null);
            if (roots && roots.length > 1 || isSharedFolder) {
              const root = new DmsFolder('00000000-0000-0000-0000-000000000000', 'Organizational DMS', '/', roots[0].sourceId, this._accountType, null, roots.length, null, null, true, null, null);
              root.admin = false;
              roots.forEach(node => {
                node.folderCount = folders.filter(f => f.parentId === node.id).length;
                node.parentId = root.id;
                node.dmsAccountType = this._accountType;
                node.admin = false;
              });
              folders.push(root);
              this._folders.next(folders);
              this._store.dispatch(new LoadOneSuccess(root));
              return this.builder.create(root, true);

            } else if (roots && roots.length === 1) {
              this._folders.next(folders);
              return this.builder.create(roots[0], true);

            } else {
              console.error('No root node found.');
              return null;
            }
          }
        }),
        catchError(error => {
          console.error(error);
          return of({
            id: '00000000-0000-0000-0000-000000000000',
            type: NodeType.Error,
            name: 'DMS currently not available, please try again, later',
            shared: false,
            writePerm: false,
            deletePerm: false,
            renamePerm: false,
            documentCount: null,
            folderCount: null,
            auditProof: false,
            bookmanCockpitEnabled: false,
            parent: null,
            root: true,
            hasChildren: false,
            info: false,
            accountType: this._accountType
          });
        })
      );
  }

  getChildrenNodes(id: string, foldersOnly = false): Observable<INode[]> {
    const folders = this._parentMap.value[id] || [];
    const result: INode[] = folders.map(folder => this.builder.create(folder));
    return of(result);
  }
}
