import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './fibu.state';
import {Fibu} from "./fibu";

export const stateKey = 'fibu';
const getFibuState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getFibuEntities,
  selectAll: getAllFibuProcesses,
} = adapter.getSelectors(getFibuState);

export const getSelected = createSelector(
  getFibuState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getFibuEntities,
  (entities) => entities[id]
);

export const getByClientId = (clientId: string) => createSelector(
  getAllFibuProcesses,
  (fibuProcesses): Fibu => fibuProcesses.find(f => f.client.id === clientId)
);

export const getAll = createSelector(
  getAllFibuProcesses,
  (entities) => entities
);

export const loadingState = createSelector(
  getFibuState,
  (state) => state.loading
);
