import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {WorkflowTemplateEffects} from "./workflow-template.effects";
import {WorkflowTemplateService} from "./workflow-template.service";
import {reducer} from "./workflow-template.reducer";
import {stateKey} from "./workflow-template.selectors";
import {WtResourceService} from "./wt-api-resource.service";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([WorkflowTemplateEffects])
  ],
  providers: [
    WorkflowTemplateEffects,
    WorkflowTemplateService,
    WtResourceService
  ]
})
export class WorkflowTemplateStoreModule {
}
