import * as model from './member.interface';

/// <reference path="./member.interface.ts" />
export namespace Operator {
  export class Member implements model.Operator.IMember {
    readonly type = 'member_records';

    membershipId?: string;
    fastdocsEnabledAt?: Date;
    bigFilesEnabledAt?: Date;
    digitalbarEnabledAt?: Date;
    fivefAgentEnabledAt?: Date;
    fivefDesktopEnabledAt?: Date;
    datevEnabledAt?: Date;
    bookmanEnabledAt?: Date;
    owner = false;
    admin = false;
    projectRoomAdmin = false;

    constructor(public id,
                public organizationId: string,
                public email: string,
                public firstName: string,
                public lastName: string,
                public createdAt,
                public updatedAt) {
    }

    public name(): string {
      return `${this.firstName} ${this.lastName}`;
    }
  }
  export class MemberPermissions  {
    readonly type = 'member_permissions';

    constructor(public id,
                public workflowCreationEnabled,
                public subWorkflowCreationEnabled,
                public fastdocsEnabled,
                public bigFilesEnabled: boolean,
                public projectRoomAdminRoleId: string,
                public digitalbarEnabled: boolean,
                public fivefAgentEnabled: boolean,
                public fivefDesktopEnabled: boolean,
                public datevEnabled: boolean,
                public bookmanEnabled: boolean) {
    }
  }
}
