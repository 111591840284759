import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {PageSidebar} from './page-sidebar';

export interface State extends EntityState<PageSidebar> {
  selected: PageSidebar;
  open: boolean;
  collapsed: boolean
}

export const adapter = createEntityAdapter<PageSidebar>();

export const initialState: State = adapter.getInitialState({
  selected: null,
  open: false,
  collapsed: false
});
