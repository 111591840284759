import {IUserAvatar} from './user-avatar.interface';
import {AvatarType} from './user-avatar.interface';

export class UserAvatar implements IUserAvatar {
  readonly type = AvatarType;
  loading = false;

  constructor(public id,
              public avatar,
              public firstName,
              public lastName,
              public gender,
              public title) {}
}
