import {Component, Inject, Input} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './waiting-dialog.component.html',
  styleUrls: ['./waiting-dialog.component.scss'],
  selector: 'dvtx-waiting-dialog',
})
export class WaitingDialogComponent {
  @Input() color = '#007aff';
  @Input() title;
  @Input() headline;
  @Input() dynamicWidth = false;
  @Input() disableClose = false;
  @Input() noPadding = false;
  @Input() callback;

  constructor(public dialogRef: MatDialogRef<WaitingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    [
      this.color,
      this.title,
      this.headline,
      this.callback,
      this.dynamicWidth,
      this.noPadding
    ] = [
      data.color || this.color,
      data.title,
      data.headline,
      data.callback(dialogRef),
      data.dynamicWidth || this.dynamicWidth,
      data.noPadding
    ];
  }
}
