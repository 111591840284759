import {NgModule} from '@angular/core';
import {VersionHistoryComponent} from './containers/version-history/version-history.component';
import {SharedModule} from 'app/shared/shared.module';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        VersionHistoryComponent
    ],
    exports: [
        VersionHistoryComponent
    ]
})
export class DataVizualizationModule {
}
