import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { KanbanGroup } from './kanban-group';

export interface State extends EntityState<KanbanGroup> {
  selected?: KanbanGroup;
  loading: boolean;
}

export const adapter = createEntityAdapter<KanbanGroup>({});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
