import {NgModule} from '@angular/core';
import {AddressBookTableComponent} from './containers/address-book-table/address-book-table.component';
import {ImportContactsComponent} from './components/import-contacts/import-contacts.component';
import {EditContactOrganizationDialogComponent} from './components/edit-contact-organization-dialog/edit-contact-organization-dialog.component';
import {EditContactPersonDialogComponent} from './components/edit-contact-person-dialog/edit-contact-person-dialog.component';
import {AlertsModule} from '../../../alerts/alerts.module';
import {CsvImportModule} from 'app/shared/modules/csv-import/csv-import.module';
import {ContactVisibilityChooserComponent} from './components/contact-visibility-chooser/contact-visibility-chooser.component';
import {CreateOrganizationDialogComponent} from './components/create-organization-dialog/create-organization-dialog.component';
import {CreateContactDialogComponent} from './components/create-contact-dialog/create-contact-dialog.component';
import {CustomAttributesComponent} from './components/custom-attributes/custom-attributes.component';
import {ContactDirective} from './directives/contact.directive';
import {CreateOrganizationDialogContainerComponent} from './containers/create-organization-dialog-container/create-organization-dialog-container.component';
import {GlobalActionMenuComponent} from './components/global-action-menu/global-action-menu.component';
import {BatchDialogModule} from '../batch-dialog/batch-dialog.module';
import {InViewportModule} from "ng-in-viewport";
import {MemberDeletionDialogComponent} from './components/member-deletion-dialog/member-deletion-dialog.component';
import {ContactDeletionDialogComponent} from './components/contact-deletion-dialog/contact-deletion-dialog.component';
import { PersonSelectorAutocompleteModule } from 'app/shared/modules/person-selector-autocomplete/person-selector-autocomplete.module';
import { FiltersContainerModule } from 'app/five-f/filters/filters-container.module';
import { TooltipModule } from 'app/five-f/tooltip/tooltip.module';
import {ScrollingModule} from "@angular/cdk-experimental/scrolling";

@NgModule({
    imports: [
        AlertsModule,
        BatchDialogModule,
        CsvImportModule,
        PersonSelectorAutocompleteModule,
        InViewportModule,
        FiltersContainerModule,
        TooltipModule,
        ScrollingModule
    ],
    declarations: [
        ContactVisibilityChooserComponent,
        CustomAttributesComponent,
        ImportContactsComponent,
        AddressBookTableComponent,
        CreateContactDialogComponent,
        CreateOrganizationDialogComponent,
        EditContactOrganizationDialogComponent,
        EditContactPersonDialogComponent,
        ContactDirective,
        CreateOrganizationDialogContainerComponent,
        GlobalActionMenuComponent,
        MemberDeletionDialogComponent,
        ContactDeletionDialogComponent
    ],
    exports: [
        AlertsModule,
        BatchDialogModule,
        CreateOrganizationDialogComponent,
        AddressBookTableComponent,
        ContactDirective,
        GlobalActionMenuComponent,
        EditContactPersonDialogComponent,
        EditContactOrganizationDialogComponent,
    ]
})
export class AddressBookTableModule {
}
