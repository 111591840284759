import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

/**
 * Table view of the shopping cart.
 */
@Component({
  selector: 'dvtx-cart-overview',
  templateUrl: './cart-overview.component.html',
  styleUrls: ['./cart-overview.component.scss']
})
export class CartOverviewComponent implements OnInit {
  @Input()
  form: UntypedFormGroup;
  totalCost = 0;
  totalAdditionalMembersCost = 0;
  selectedPlan;

  ngOnInit() {
    this.selectedPlan = {
      title: this.form.controls['selectedPlanAttributes'].value.title,
      userLicencesCount: this.form.controls['selectedPlanAttributes'].value.userLicencesCount,
      price: this.form.controls['paymentInterval'].value === 'Monthly' ? this.form.controls['selectedPlanAttributes'].value.price.monthly : this.form.controls['selectedPlanAttributes'].value.price.yearly * 12,
      additionalMemberCost: this.form.controls['paymentInterval'].value === 'Monthly' ? this.form.controls['selectedPlanAttributes'].value.additionalMemberCost.monthly : Number(this.form.controls['selectedPlanAttributes'].value.additionalMemberCost.yearly) * 12,
      paymentInterval: this.form.controls['paymentInterval'].value,
      validUntilDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).setDate(new Date().getDate() - 1)
    }

    this.CalcualteTotalCost();
  }

  CalcualteTotalCost() {
    this.totalAdditionalMembersCost = Number(this.form.controls['licence_amount'].value) * Number(this.selectedPlan.additionalMemberCost);
    this.totalCost = Number(this.selectedPlan.price) + Number(this.totalAdditionalMembersCost);
  }
}
