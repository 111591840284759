import {Action} from '@ngrx/store';
import {TwoFactorSession} from './two-factor-session';
import {Update} from '@ngrx/entity';

export enum TwoFactorSessionActionTypes {
  Load = '[TwoFactorSession] Load',
  LoadSuccess = '[TwoFactorSession] Load Success',
  LoadFail = '[TwoFactorSession] Load Fail',
  Reset = '[TwoFactorSession] Reset',
  OpenDialog = '[TwoFactorSession] OpenDialog',
  ResetDialogOpenState = '[TwoFactorSession] Reset Dialog Open State'
}

export class Load implements Action {
  readonly type = TwoFactorSessionActionTypes.Load;

  constructor(public payload: any = null) {
  }
}

export class LoadSuccess implements Action {
  readonly type = TwoFactorSessionActionTypes.LoadSuccess;

  constructor(public session: TwoFactorSession) {
  }
}

export class LoadFail implements Action {
  readonly type = TwoFactorSessionActionTypes.LoadFail;

  constructor(public payload: any) {
  }
}

export class OpenDialog implements Action {
  readonly type = TwoFactorSessionActionTypes.OpenDialog;

  constructor(public payload: any = null) {
  }
}

export class ResetDialogOpenState implements Action {
  readonly type = TwoFactorSessionActionTypes.ResetDialogOpenState;

  constructor(public payload: any = null) {
  }
}

export class Reset implements Action {
  readonly type = TwoFactorSessionActionTypes.Reset;

  constructor(public payload: any = null) {
  }
}

export type TwoFactorSessionActions =
  | Load
  | LoadSuccess
  | LoadFail
  | OpenDialog
  | Reset
  | ResetDialogOpenState;
