import * as modelInterface from './sms-record.interface';

/// <reference path="./sms-record.interface.ts" />
export namespace Operator {
  export class SmsRecord implements modelInterface.Operator.ISmsRecord {
    readonly type = 'sms_records';

    total: number;
    perPage: number;
    records: number;

    constructor(public id,
                public phoneNumber: string,
                public status: modelInterface.Operator.ISmsRecordStatus,
                public performerName: string,
                public performerEmail: string,
                public provider: string,
                public processType: string,
                public createdAt,
                public updatedAt) {

    }
  }
}
