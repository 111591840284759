import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {OrganizationProxy} from './organization-proxy';

export class OrganizationProxyBuilder implements IApiResourceBuilder<OrganizationProxy> {
  fromResponse(data): OrganizationProxy {
    const attrs = data.attributes;
    return new OrganizationProxy(
      data.id,
      attrs.name,
      attrs.abbreviation,
      attrs.legal_form_id,
      attrs.country_id,
      attrs.country_code,
      attrs.datev_consultant_no,
      attrs.grant_clients_folder_to_members,
      attrs.disable_private_dms,
      attrs.disable_business_partner_dms_contacts,
      attrs.contact_cleanup_at,
      attrs.show_client_to_business_partner,
      attrs.show_client_in_email,
      attrs.enforce_two_factor_auth,
      attrs.created_at,
      attrs.updated_at
    );
  }

  toRequest(organization: OrganizationProxy) {
    return {
      data: {
        id: organization.id,
        type: organization.type,
        attributes: {
          name: organization.name,
          legal_form_id: organization.abbreviation,
          abbreviation: organization.abbreviation,
          country_id: organization.countryId,
          country_code: organization.countryCode,
          datev_consultant_no: organization.consultantId
        }
      }
    };
  }
}
