import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DmsFolderAclService} from './dms-folder-acl.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    DmsFolderAclService
  ]
})
export class DmsFolderAclStoreModule {
}
