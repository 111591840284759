import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import * as model from './grant-thornton';

export namespace GrantThornton {
  export class TeamsChannelBuilder implements IApiResourceBuilder<model.GrantThornton.TeamsChannel> {
    fromResponse(data): model.GrantThornton.TeamsChannel {
      const channel = new model.GrantThornton.TeamsChannel(
        data.id,
        data.attributes.name,
        data.attributes.team_id,
        data.attributes.team_name);

      return channel;
    }

    toRequest(_: model.GrantThornton.TeamsChannel) {
      return null;
    }
  }

  export class SharepointFolderBuilder implements IApiResourceBuilder<model.GrantThornton.SharepointFolder> {
    fromResponse(data): model.GrantThornton.SharepointFolder {
      const folder = new model.GrantThornton.SharepointFolder(
        data.id,
        data.attributes.name,
        data.attributes.parent_id,
        data.attributes.level = 0);

      return folder;
    }

    toRequest(_: model.GrantThornton.SharepointFolder) {
      return null;
    }
  }

  export class ChannelSyncSettingsBuilder implements IApiResourceBuilder<model.GrantThornton.ChannelSyncSettings> {
    fromResponse(data): model.GrantThornton.ChannelSyncSettings {
      const folder = new model.GrantThornton.ChannelSyncSettings(
        data.id,
        data.attributes.ms_team_id,
        data.attributes.ms_team_title,
        data.attributes.ms_channel_id,
        data.attributes.ms_channel_title,
        data.attributes.ms_folder_id,
        data.attributes.ms_folder_name,
        data.attributes.ms_folder_path,
        data.attributes.auto_sync_enabled,
        data.attributes.error_message);

      return folder;
    }

    toRequest(settings: model.GrantThornton.ChannelSyncSettings) {
      return {
        data: {
          id: settings.id,
          type: settings.type,
          attributes: {
            ms_team_id: settings.ms_team_id,
            ms_team_title: settings.ms_team_title,
            ms_channel_id: settings.ms_channel_id,
            ms_channel_title: settings.ms_channel_title,
            ms_folder_id: settings.ms_folder_id,
            ms_folder_name: settings.ms_folder_name,
            ms_folder_path: settings.ms_folder_path,
            error_message: settings.error_message,
            auto_sync_enabled: settings.auto_sync_enabled
          }
        }
      };
    }
  }
}
