<a (click)="$event.preventDefault(); selectNode(node)"
   [matTooltip]="node?.name">
  <ng-template [ngIf]="node?.closed" [ngIfElse]="workflowIcon">
    <div class="d-flex align-self-center">
              <span style="color: #00BD00; width: 18px; height: 18px; margin-right: 5px;">
                <mat-icon style="font-size: 18px;">lock</mat-icon>
              </span>
    </div>
  </ng-template>

  <ng-template #workflowIcon>
    <div class="d-flex align-self-center" [class.dvtx-active-link-icon]="node?.active">
      <ng-template [ngIf]="node?.isSvgIcon" [ngIfElse]="defaultIconType">
                <span style="width: 18px; height: 18px; margin-right: 5px;">
                <mat-icon style="height: 18px; width: 18px;" [svgIcon]="node?.icon"></mat-icon>
              </span>
      </ng-template>
      <ng-template #defaultIconType>
                <span style="width: 18px; height: 18px; margin-right: 5px;" [class.dvtx-active-link-icon]="node?.active">
                  <mat-icon style="font-size: 18px;">{{ node?.icon }}</mat-icon>
                </span>
      </ng-template>
    </div>
  </ng-template>

  <span class="text-truncate" [class.active]="node?.active">
    <ng-template [ngIf]="node?.name" [ngIfElse]="nameMissing">
      <ng-container *ngIf="node.name.length">
        <!--{{ node?.name }}-->
        {{ node?.name }}
      </ng-container>
    </ng-template>
    <ng-template #nameMissing>
      <em class="font-style-italic">{{ noTitlePlaceholder }}</em>
    </ng-template>
  </span>
</a>
