import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import * as processContextActions from 'app/actions/process-context.actions';
import { IProcessContext } from 'app/shared/modules/api/models/process-context.interface';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {
  protected onDestroy = new Subject();

  headline = 'Einladung zur digitalen Kommunikation';
  message = 'Hier kann geeigneter Text stehen.';

  accesstoken: string;

  private _context: IProcessContext;
  private _context$: Observable<IProcessContext>;

  values: any;

  download = null;

  constructor(private _store: Store<AppState>,
              private _route: ActivatedRoute) {
    this._context$ = _store.select('processContext');
  }

  ngOnInit(): void {
    this._route
      .params.pipe(
      takeUntil(this.onDestroy))
      .subscribe(params => {
        this.accesstoken = params['token'];
        this._store.dispatch(new processContextActions.GetExternalProcessContext(this.accesstoken));
      });

    this._context$
      .pipe(
        filter((context) => !!context),
        takeUntil(this.onDestroy)
      )
      .subscribe((context) => {
        this._context = context;
        this.values = context.values;
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  submit() {
    this._context.runCommands('on_click');
    // this._store.dispatch(new ProcessActions.RunCommand(this._context.id, 'show', 'on_click'))
  }
}
