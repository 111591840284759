
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { ProcessContextBuilder } from '../builders/process-context.builder';
import { ApiErrorBuilder } from '../builders/api-error.builder';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';

/**
 * API endpoint service for the external (unauthenticated) access.
 * Mostly used in the external-access module.
 * Contains the REST methods GET/PUT and methods for token validation.
 *
 * The request object is the 'access-token' mostly retrieved by the URL query
 * parameter <tt>accessToken</tt> or the process context <tt>ProcessContext</tt>
 * regarding the RESTful service endpoints and retrived by the aaccording pplication state.
 * The ProcessContext must be relivered with the help of the custom implementation of toJSON
 * of the <tt>ProcessContext</tt> class.
 *
 * This service is used by the process-context effect and as such used as sideeffect in
 * the background.
 *
 * In a response the ProcessContext is established by using the builder service
 * <tt>ProcessContextBuilder</tt>.
 *
 */
@Injectable()
export class ExternalAccessService {
  readonly BASE_PATH = 'api/v1/access/external';
  basePath;

  constructor(private _http: HttpClient,
              private _router: Router,
              private _contextBuilder: ProcessContextBuilder) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  validate(token: string) {
    const validationUrl = `${this.basePath}/validate/${token}`;
    return this._http.get(validationUrl).pipe(
      map((res: any) => this._contextBuilder.fromResponse(res.data)),
      catchError(this.handleError),);
  }

  get(token: string) {
    const getUrl = `${this.basePath}/${token}`;
    return this._http.get(getUrl).pipe(
      map((res: any) => this._contextBuilder.fromResponse(res.data)),
      catchError(this.handleError),);
  }

  update(token, processContext) {
    const contextAsJSON = processContext.toJSON();
    const payload = {
      data: {
        type: 'auth-external-accesses',
        attributes: {
          workflow: contextAsJSON.workflow,
          revision: contextAsJSON.revision,
          values: contextAsJSON.values
        }
      }
    };
    const putUrl = `${this.basePath}/${token}`;
    return this._http
      .put(putUrl, payload).pipe(
      map((res: any) => this._contextBuilder.fromResponse(res.data)),
      catchError(this.handleError),);
  }

  private handleError(error: HttpErrorResponse) {
    this._router.navigate(['/access/external/form/not_found']);
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}
