import { IResource } from 'app/shared/modules/api/models/resource.interface';
import { DmsAccountType } from '../dms-folder/dms-folder.interface';

export enum ROLES {
  ORGANIZATION_ADMIN = 'PERMISSIONS_VIEW.ROLES.ORGANIZATION_ADMINISTRATOR',
  PROJECT_ROOM_OWNER = 'PERMISSIONS_VIEW.ROLES.PROJECT_ROOM_OWNER',
  PROJECT_ROOM_ADMIN = 'PERMISSIONS_VIEW.ROLES.PROJECT_ROOM_MODERATOR',
  PROJECT_ROOM_MEMBER_PARTICIPANT = 'PERMISSIONS_VIEW.ROLES.VERIFIED_USER_MEMBER',
  PROJECT_ROOM_CLIENT_PARTICIPANT = 'PERMISSIONS_VIEW.ROLES.VERIFIED_USER_CLIENT',
  PROJECT_ROOM_EXTERNAL_PARTICIPANT = 'PERMISSIONS_VIEW.ROLES.EXTERNAL_USER',
  CONTACT = 'PERMISSIONS_VIEW.ROLES.CONTACT',
  FOXDOX_ACCOUNT = 'PERMISSIONS_VIEW.ROLES.FOXDOX_CONTACT'
}

export interface IProcessParticipantAcl extends IResource {
  processId: string;
  email: string;
  sid: string;
  participantId;
  name: string;
  contactType: string,
  processContactType: string;
  accessType: string;
  organizationAdmin: boolean;
  processOwner: boolean;
  processAdmin: boolean;
  read: boolean;
  write: boolean;
  closeProcess: boolean;
  addParticipant: boolean;
  removeParticipant: boolean;
  alterSettings: boolean;
  startSubProcess: boolean;
  uploadDocument: boolean;
  downloadDocument: boolean;
  deleteDocument: boolean;
  auditTrail: boolean;
  syncFolder: boolean;
  readByApp: boolean;
  writeByApp: boolean;
  readBySyncClient: boolean;
  writeBySyncClient: boolean;
  grantProcessAdmin: boolean;
  grantRead: boolean;
  grantWrite: boolean;
}

export const ROLES_PROJECT_ROOM_PERMISSIONS = {
  'ROLES.ORGANIZATION_ADMIN': [
    {
      close: {
        value: false
      },
      addParticipants: {
        value: false
      },
      removeParticipants: {
        value: false
      },
      changeSettings: {
        value: false
      },
      startSubWorkflow: {
        value: false
      },
      uploadConnectedDocs: {
        value: false
      },
      downloadConnectedDocs: {
        value: false
      },
      deleteConnectedDocs: {
        value: false
      },
      enteringTrailView: {
        value: false
      }
    }
  ],
  'ROLES.PROJECT_ROOM_OWNER': [{
    close: {
      value: true
    },
    addParticipants: {
      value: true
    },
    removeParticipants: {
      value: true
    },
    changeSettings: {
      value: true
    },
    startSubWorkflow: {
      value: true
    },
    uploadConnectedDocs: {
      value: true
    },
    downloadConnectedDocs: {
      value: true
    },
    deleteConnectedDocs: {
      value: true
    },
    enteringTrailView: {
      value: true
    }
  }],
  'ROLES.PROJECT_ROOM_ADMIN': [{
    close: {
      value: true
    },
    addParticipants: {
      value: true
    },
    removeParticipants: {
      value: true
    },
    changeSettings: {
      value: true
    },
    startSubWorkflow: {
      value: false
    },
    uploadConnectedDocs: {
      value: true
    },
    downloadConnectedDocs: {
      value: true
    },
    deleteConnectedDocs: {
      value: true
    },
    enteringTrailView: {
      value: true
    }
  }],
  'ROLES.PROJECT_ROOM_MEMBER_PARTICIPANT': [{
    close: {
      value: false
    },
    addParticipants: {
      value: false
    },
    removeParticipants: {
      value: false
    },
    changeSettings: {
      value: true
    },
    startSubWorkflow: {
      value: true
    },
    uploadConnectedDocs: {
      value: true
    },
    downloadConnectedDocs: {
      value: true
    },
    deleteConnectedDocs: {
      value: false
    },
    enteringTrailView: {
      value: false
    }
  }],
  'ROLES.PROJECT_ROOM_CLIENT_PARTICIPANT': [{
    close: {
      value: false
    },
    addParticipants: {
      value: false
    },
    removeParticipants: {
      value: false
    },
    changeSettings: {
      value: false
    },
    startSubWorkflow: {
      value: false
    },
    uploadConnectedDocs: {
      value: true
    },
    downloadConnectedDocs: {
      value: true
    },
    deleteConnectedDocs: {
      value: false
    },
    enteringTrailView: {
      value: false
    }
  }],
  'ROLES.PROJECT_ROOM_EXTERNAL_PARTICIPANT': [{
    close: {
      value: false
    },
    addParticipants: {
      value: false
    },
    removeParticipants: {
      value: false
    },
    changeSettings: {
      value: false
    },
    startSubWorkflow: {
      value: false
    },
    uploadConnectedDocs: {
      value: true,
      notes: 'PERMISSIONS_VIEW.ONLY_THROUGH_EXTERNAL_VIEW'
    },
    downloadConnectedDocs: {
      value: true,
      notes: 'PERMISSIONS_VIEW.ONLY_THROUGH_EXTERNAL_VIEW'
    },
    deleteConnectedDocs: {
      value: false
    },
    enteringTrailView: {
      value: false
    }
  }],
  'ROLES.FOXDOX_ACCOUNT': [{
    close: {
      value: false
    },
    addParticipants: {
      value: false
    },
    removeParticipants: {
      value: false
    },
    changeSettings: {
      value: false
    },
    startSubWorkflow: {
      value: false
    },
    uploadConnectedDocs: {
      value: false,
    },
    downloadConnectedDocs: {
      value: false,
    },
    deleteConnectedDocs: {
      value: false
    },
    enteringTrailView: {
      value: false
    }
  }]
};

export const ROLES_FOLDER_PERMISSION_PERMISSIONS = {
  'ROLES.ORGANIZATION_ADMIN': [{
    read: {
      value: true,
      sections: {
        accessDocumentsFromMenu: {
          value: true
        },
        accessApp: {
          value: true
        },
        accessSyncedClient: {
          value: true
        }
      }
    },
    write: {
      value: true,
      sections: {
        downloadAndUploadOnDocuemtnsFromMenu: {
          value: true
        },
        downloadAndUploadOnApp: {
          value: true
        },
        downloadAndUploadOnSyncedClient: {
          value: true
        },
        exportToXML: {
          value: false
        }
      }
    }
  }],
  'ROLES.PROJECT_ROOM_OWNER': [{
    read: {
      value: true,
      sections: {
        accessDocumentsFromMenu: {
          value: true
        },
        accessApp: {
          value: true
        },
        accessSyncedClient: {
          value: true
        }
      }
    },
    write: {
      value: true,
      sections: {
        downloadAndUploadOnDocuemtnsFromMenu: {
          value: true
        },
        downloadAndUploadOnApp: {
          value: true
        },
        downloadAndUploadOnSyncedClient: {
          value: true
        },
        exportToXML: {
          value: true
        }
      }
    }
  }],
  'ROLES.PROJECT_ROOM_ADMIN': [{
    read: {
      value: true,
      notes: 'PERMISSIONS_VIEW.ONLY_QUICKSHARE_OWNER_HAS_READ_WRITE_ACCESS',
      sections: {
        accessDocumentsFromMenu: {
          value: true
        },
        accessApp: {
          value: true
        },
        accessSyncedClient: {
          value: true
        }
      }
    },
    write: {
      value: true,
      notes: 'PERMISSIONS_VIEW.ONLY_QUICKSHARE_OWNER_HAS_READ_WRITE_ACCESS',
      sections: {
        downloadAndUploadOnDocuemtnsFromMenu: {
          value: true
        },
        downloadAndUploadOnApp: {
          value: true
        },
        downloadAndUploadOnSyncedClient: {
          value: true
        },
        exportToXML: {
          value: true
        }
      }
    }
  }],
  'ROLES.PROJECT_ROOM_MEMBER_PARTICIPANT': [{
    read: {
      value: true,
      notes: 'PERMISSIONS_VIEW.ONLY_QUICKSHARE_OWNER_HAS_READ_WRITE_ACCESS',
      sections: {
        accessDocumentsFromMenu: {
          value: true
        },
        accessApp: {
          value: true
        },
        accessSyncedClient: {
          value: true
        }
      }
    },
    write: {
      value: true,
      notes: 'PERMISSIONS_VIEW.ONLY_QUICKSHARE_OWNER_HAS_READ_WRITE_ACCESS',
      sections: {
        downloadAndUploadOnDocuemtnsFromMenu: {
          value: true
        },
        downloadAndUploadOnApp: {
          value: true
        },
        downloadAndUploadOnSyncedClient: {
          value: true
        },
        exportToXML: {
          value: true
        }
      }
    }
  }],
  'ROLES.PROJECT_ROOM_CLIENT_PARTICIPANT': [{
    read: {
      value: false,
      sections: {
        accessDocumentsFromMenu: {
          value: false
        },
        accessApp: {
          value: false
        },
        accessSyncedClient: {
          value: false
        }
      }
    },
    write: {
      value: false,
      sections: {
        downloadAndUploadOnDocuemtnsFromMenu: {
          value: false
        },
        downloadAndUploadOnApp: {
          value: false
        },
        downloadAndUploadOnSyncedClient: {
          value: false
        },
        exportToXML: {
          value: false
        }
      }
    }
  }],
  'ROLES.PROJECT_ROOM_EXTERNAL_PARTICIPANT': [
    {
      read: {
        value: false,
        sections: {
          accessDocumentsFromMenu: {
            value: false
          },
          accessApp: {
            value: false
          },
          accessSyncedClient: {
            value: false
          }
        }
      },
      write: {
        value: false,
        sections: {
          downloadAndUploadOnDocuemtnsFromMenu: {
            value: false
          },
          downloadAndUploadOnApp: {
            value: false
          },
          downloadAndUploadOnSyncedClient: {
            value: false
          },
          exportToXML: {
            value: false
          }
        }
      }
    }
  ]
};

export const ROLES_TASKS_PERMISSIONS = {
  'ROLES.ORGANIZATION_ADMIN': [
    {
      canCreateTask: {
        value: false
      },
      canBeParticipantOfTask: {
        value: true
      },
      canAccessMyTasks: {
        value: true
      },
      canAccessAllTasks: {
        value: false
      },
      canSetCompletedTask: {
        value: false
      },
      canDeleteTask: {
        value: false
      }
    }
  ],
  'ROLES.PROJECT_ROOM_OWNER': [
    {
      canCreateTask: {
        value: true,
        notes: 'PERMISSIONS_VIEW.IF_HOLDING_PROFESSIONAL_LICENCE'
      },
      canBeParticipantOfTask: {
        value: true
      },
      canAccessMyTasks: {
        value: true
      },
      canAccessAllTasks: {
        value: true
      },
      canSetCompletedTask: {
        value: true
      },
      canDeleteTask: {
        value: true
      }
    }
  ],
  'ROLES.PROJECT_ROOM_ADMIN': [
    {
      canCreateTask: {
        value: true,
        notes: 'PERMISSIONS_VIEW.IF_HOLDING_PROFESSIONAL_LICENCE'
      },
      canBeParticipantOfTask: {
        value: true
      },
      canAccessMyTasks: {
        value: true
      },
      canAccessAllTasks: {
        value: true
      },
      canSetCompletedTask: {
        value: true
      },
      canDeleteTask: {
        value: true
      }
    }
  ],
  'ROLES.PROJECT_ROOM_MEMBER_PARTICIPANT': [
    {
      canCreateTask: {
        value: true,
        notes: 'PERMISSIONS_VIEW.IF_HOLDING_PROFESSIONAL_LICENCE'
      },
      canBeParticipantOfTask: {
        value: true
      },
      canAccessMyTasks: {
        value: true
      },
      canAccessAllTasks: {
        value: false
      },
      canSetCompletedTask: {
        value: true,
        notes: 'PERMISSIONS_VIEW.IF_CREATOR_OF_TASK'
      },
      canDeleteTask: {
        value: true,
        notes: 'PERMISSIONS_VIEW.IF_CREATOR_OF_TASK'
      }
    }
  ],
  'ROLES.PROJECT_ROOM_CLIENT_PARTICIPANT': [
    {
      canCreateTask: {
        value: false,
        notes: 'PERMISSIONS_VIEW.IF_HOLDING_OWN_LICENCE'
      },
      canBeParticipantOfTask: {
        value: true
      },
      canAccessMyTasks: {
        value: true
      },
      canAccessAllTasks: {
        value: false
      },
      canSetCompletedTask: {
        value: false
      },
      canDeleteTask: {
        value: false
      }
    }
  ],
  'ROLES.PROJECT_ROOM_EXTERNAL_PARTICIPANT': [
    {
      canCreateTask: {
        value: false
      },
      canBeParticipantOfTask: {
        value: false
      },
      canAccessMyTasks: {
        value: false
      },
      canAccessAllTasks: {
        value: false
      },
      canSetCompletedTask: {
        value: false
      },
      canDeleteTask: {
        value: false
      }
    }
  ],
  'ROLES.FOXDOX_ACCOUNT': [
    {
      canCreateTask: {
        value: false
      },
      canBeParticipantOfTask: {
        value: false
      },
      canAccessMyTasks: {
        value: false
      },
      canAccessAllTasks: {
        value: false
      },
      canSetCompletedTask: {
        value: false
      },
      canDeleteTask: {
        value: false
      }
    }
  ]
};

export const DEFAULT_ROLES = [
  {
    id: 1,
    role: ROLES.ORGANIZATION_ADMIN,
    projectRoomPermissions: ROLES_PROJECT_ROOM_PERMISSIONS['ROLES.ORGANIZATION_ADMIN'],
    folderPermissions: ROLES_FOLDER_PERMISSION_PERMISSIONS['ROLES.ORGANIZATION_ADMIN'],
    tasksPermissions: ROLES_TASKS_PERMISSIONS['ROLES.ORGANIZATION_ADMIN']
  },
  {
    id: 2,
    role: ROLES.PROJECT_ROOM_OWNER,
    projectRoomPermissions: ROLES_PROJECT_ROOM_PERMISSIONS['ROLES.PROJECT_ROOM_OWNER'],
    folderPermissions: ROLES_FOLDER_PERMISSION_PERMISSIONS['ROLES.PROJECT_ROOM_OWNER'],
    tasksPermissions: ROLES_TASKS_PERMISSIONS['ROLES.PROJECT_ROOM_OWNER']
  },
  {
    id: 3,
    role: ROLES.PROJECT_ROOM_ADMIN,
    projectRoomPermissions: ROLES_PROJECT_ROOM_PERMISSIONS['ROLES.PROJECT_ROOM_ADMIN'],
    folderPermissions: ROLES_FOLDER_PERMISSION_PERMISSIONS['ROLES.PROJECT_ROOM_ADMIN'],
    tasksPermissions: ROLES_TASKS_PERMISSIONS['ROLES.PROJECT_ROOM_ADMIN']
  },
  {
    id: 4,
    role: ROLES.PROJECT_ROOM_MEMBER_PARTICIPANT,
    projectRoomPermissions: ROLES_PROJECT_ROOM_PERMISSIONS['ROLES.PROJECT_ROOM_MEMBER_PARTICIPANT'],
    folderPermissions: ROLES_FOLDER_PERMISSION_PERMISSIONS['ROLES.PROJECT_ROOM_MEMBER_PARTICIPANT'],
    tasksPermissions: ROLES_TASKS_PERMISSIONS['ROLES.PROJECT_ROOM_MEMBER_PARTICIPANT']
  },
  {
    id: 5,
    role: ROLES.PROJECT_ROOM_CLIENT_PARTICIPANT,
    projectRoomPermissions: ROLES_PROJECT_ROOM_PERMISSIONS['ROLES.PROJECT_ROOM_CLIENT_PARTICIPANT'],
    folderPermissions: ROLES_FOLDER_PERMISSION_PERMISSIONS['ROLES.PROJECT_ROOM_CLIENT_PARTICIPANT'],
    tasksPermissions: ROLES_TASKS_PERMISSIONS['ROLES.PROJECT_ROOM_CLIENT_PARTICIPANT']
  },
  {
    id: 6,
    role: ROLES.PROJECT_ROOM_EXTERNAL_PARTICIPANT,
    projectRoomPermissions: ROLES_PROJECT_ROOM_PERMISSIONS['ROLES.PROJECT_ROOM_EXTERNAL_PARTICIPANT'],
    folderPermissions: ROLES_FOLDER_PERMISSION_PERMISSIONS['ROLES.PROJECT_ROOM_EXTERNAL_PARTICIPANT'],
    tasksPermissions: ROLES_TASKS_PERMISSIONS['ROLES.PROJECT_ROOM_EXTERNAL_PARTICIPANT']
  }
];
