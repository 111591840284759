import {Directive, HostListener, Input, Output, EventEmitter} from '@angular/core';
import {LICENCES_TYPES} from 'app/+store/licence-assignment/licence-assignment.selectors';
import {Router} from '@angular/router';
import {LicenceControlService} from 'app/shared/services/licence-control.service';

@Directive({selector: '[dvtxRequiredLicence], [dvtxRouteLink]'})

export class LicenceUpgradeDialogDirective {

  @Input() dvtxRequiredLicence;
  @Input() dvtxRouteLink;

  @Output() public dvtxAction = new EventEmitter();

  @HostListener('click', ['$event, $event.target'])
  onClick(event, targetElement) {
    if ( this.licenceControl.hasRequiredLicence(this.dvtxRequiredLicence) ) {
      if (this.dvtxRouteLink) {
        this.router.navigate(this.dvtxRouteLink);
      } else {
        this.dvtxAction.emit();
      }
    }
  }

  constructor( private router: Router, private licenceControl: LicenceControlService ) { }
}
