import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {Fibu} from './fibu';
import {Client} from '../client/client';
import {ClientBuilder} from '../client/client.builder';

export class FibuBuilder implements IApiResourceBuilder<Fibu> {
  clients: {
    [id: string]: Client
  } = {};

  fromResponse(data): Fibu {
    const fibu = new Fibu(
      data.id,
      data.attributes.parent_id,
      data.attributes.title,
      data.attributes.identifier,
      data.attributes.reference_id,
      data.attributes.description,
      data.attributes.state,
      data.attributes.creator_name,
      data.attributes.creator_email,
      data.attributes.owner_name,
      data.attributes.owner_email,
      data.attributes.dms_folder_id,
      data.attributes.due_date,
      data.attributes.created_at,
      data.attributes.updated_at,
      data.attributes.bookman_cockpit_enabled,
      data.attributes.five_f_sync_enabled,
      data.attributes.document_expiry_time,
    );

    fibu.color = data.attributes.color;
    fibu.organizationId = data.attributes.organization_uuid;

    let clientId = null;
    try {
      clientId = data.relationships.client && data.relationships.client.data.id ? data.relationships.client.data.id : null;
    } catch (e) { }
    if (clientId) {
      fibu.client = this.clients[clientId];
    }

    return fibu;
  }

  toRequest(_: Fibu) {
    return null;
  }

  addIncludedSection(includes) {
    if (!includes || includes.length === 0) {
      return;
    }
    const cbuilder = new ClientBuilder();

    includes.map((include) => {
      if (include.type === 'client_people' || include.type === 'client_organizations') {
        const _client = cbuilder.fromResponse(include);
        this.clients[include.id] = _client;
      }
    });
  }
}
