import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {FavoriteBuilder} from './favorite.builder';
import {Favorite} from './favorite';

@Injectable()
export class FavoriteService {
  readonly BASE_PATH = 'api/v1/favorites';
  builder: FavoriteBuilder;

  constructor(private _http: ApiResourceService) {
    this.builder = new FavoriteBuilder();

  }

  getOne(processId: string): Observable<Favorite> {
    return <Observable<Favorite>>this._http.get<FavoriteBuilder, Favorite>(this.builder, `${this.BASE_PATH}/${processId}`);
  }

  getAll(): Observable<Favorite[]> {
    return <Observable<Favorite[]>>this._http.get<FavoriteBuilder, Favorite>(this.builder, this.BASE_PATH);
  }

  confirm(favoriteId: string): Observable<Favorite> {
    return <Observable<Favorite>>this._http.post<FavoriteBuilder, Favorite>(this.builder, `${this.BASE_PATH}/${favoriteId}/confirm`, {});
  }

  update(favorite: Favorite): Observable<Favorite> {
    const payload = this.builder.toRequest(favorite);
    return <Observable<Favorite>>this._http.put<FavoriteBuilder, Favorite>(this.builder, `${this.BASE_PATH}/${favorite.id}`, payload);
  }

  remove(id: string): Observable<Favorite> {
    return this._http.del<FavoriteBuilder, Favorite>(this.builder, `${this.BASE_PATH}/${id}`);
  }

}
