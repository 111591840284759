<button #dropdownOpener cdk-overlay-origin (click)="showDropdown()" #dropdown="cdkOverlayOrigin"
        class="d-flex align-items-center group-options-opener">
  <mat-icon class="group-options-icon">{{openerIcon}}</mat-icon>
</button>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayPositions]="positions"
             [cdkConnectedOverlayBackdropClass]="cdkOverlayClass" [cdkConnectedOverlayOrigin]="dropdown"
             [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayOpen]="_showDropdown"
             (backdropClick)="closeDropdown()" [cdkConnectedOverlayScrollStrategy]="scrollStrategy">
  <div class="five-f-card five-f-elevation-z-5 updates-listing cdk-dropdown-menu dvtx-global-action-menu"
       [class.opened]="_showDropdown">
    <div class="d-flex flex-column">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
