import { IApiResourceBuilder } from 'app/shared/modules/api-resource/models/api.interface';
import { TaskComment } from './task-comment';
import {CommentReaction} from "../comment/comment.interface";


export class TaskCommentBuilder implements IApiResourceBuilder<TaskComment> {
  eventSeenByMap = {};
  reactionsMap = {};

  addIncludedSection(includes) {
    if (includes && includes.length) {
      includes.forEach(include => {
        if (include.type === 'comment_reactions') {
          this.reactionsMap[include.id] = {
            email: include.attributes.user_uid,
            reactionType: include.attributes.reaction_type
          };
        } else if (include.type === 'comment_seen_bys') {
          this.eventSeenByMap[include.id] = include.attributes.user_uid;
        }
      });
    }
  }

  fromResponse(data): TaskComment {
    const taskComment = new TaskComment(
      data.id,
      data.attributes.author_email,
      data.attributes.content,
      data.attributes.created_at,
      data.attributes.updated_at,
      []);

    if (data.relationships && data.relationships.seen_bys) {
      data.relationships.seen_bys.data.forEach(seenBy => {
        taskComment.seenBy.push(this.eventSeenByMap[seenBy.id]);
      });
    }

    if (data.relationships && data.relationships.reactions) {
      data.relationships.reactions.data.forEach((reaction) => {
        const r: CommentReaction = this.reactionsMap[reaction.id]
        if (!taskComment.reactionMap[r.reactionType]) {
          taskComment.reactionMap[r.reactionType] = [];
        }
        taskComment.reactionMap[r.reactionType].push(r.email);
        taskComment.reactions.push(r);
        taskComment.reactionList = Object.keys(taskComment.reactionMap).map((idx) => {
          return { reaction: idx, performers: taskComment.reactionMap[idx]};
        });
      });
    }

    return taskComment;
  }

  toRequest(taskComment: TaskComment): any {
    return {
      data: {
        attributes: {
          content:  taskComment.content
        }
      }
    }
  }
}
