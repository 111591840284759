import {AuditTrailActions, AuditTrailActionTypes} from './audit-trail.actions';
import {adapter, initialState, State} from './audit-trail.state';

export function reducer(state = initialState, action: AuditTrailActions): State {
  switch (action.type) {
    case AuditTrailActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case AuditTrailActionTypes.LoadAllSuccess:
      return adapter.setAll(action.attachments, {
        ...state,
        loading: false,
      });
    case AuditTrailActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

