/**
 * Notification snackbar main container. Allows to close it.
 *
 * Author: Andreas Baier <andreas.baier@paperbird.org>
 * Usage:
 *    import { NotificationService } from 'app/shared/modules/notification/services/notification.service';
 *    constructor(...private _notificationSvc: NotificationService) {
 *    ...
 *    setTimeout(() => this._notificationSvc.info('Meldungs-Text'), 2000);
 */
import {Component, Inject, ViewEncapsulation} from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'dvtx-snackbar-message',
  templateUrl: './snackbar-message.component.html',
  styleUrls: ['./snackbar-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SnackbarMessageComponent {
  message;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              @Inject(MatSnackBarRef) public snackbar) {
    this.message = data.message;
  }

  close($event) {
    this.snackbar.dismiss();
  }
}
