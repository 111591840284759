import {IMilestone, MilestoneType} from './milestone.interface';
import * as dayjs from 'dayjs';

export class Milestone implements IMilestone {
  type: MilestoneType;
  state: string;
  assignees = [];
  icon: string;

  dueAt: string;
  momentDueAt;
  formatedDueDate: string;
  todayDiff;

  constructor(public id: string,
              public parentId: string,
              public processId: string,
              public title: string,
              public preDueDate: Date,
              public dueDate: Date,
              public startedAt: Date,
              public completedAt: Date,
              public createdAt: Date,
              public updatedAt: Date) {
    this.title = title;
    this.dueDate = dueDate;
    this.completedAt = completedAt;
    this.id = id;

    this.state = 'open';
    if (dayjs(dueDate).isBefore(dayjs(new Date()))) {
      this.state = 'overdue';
    }

    if (dayjs(completedAt).isBefore(dayjs(dueDate))) {
      this.state = 'done';
    }

    if (dayjs(completedAt).isAfter(dayjs(dueDate))) {
      this.state = 'late'
    }
  }

  toForm() {
    throw new Error('Method not implemented.');
  }
}
