import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {
  ArtifactCommentEvent,
  CavContactEvent,
  CollectorUpdatedAttributesEvent,
  CommentEvent,
  ContactVerifiedEvent,
  DmsFolderAuditProof, DocumentRevisionUploadedEvent,
  DocumentUploadedEvent,
  DoubleOptInEvent,
  EmailSentEvent,
  ExportReadyEvent,
  ExternalAccessLinkEvent,
  ExternalMessageEvent,
  GenericAuditEvent,
  InstantMessageEvent,
  ItemApplicabilityChanged,
  ItemDueDateChanged,
  ItemStatusChanged,
  LabelAddedEvent,
  LabelRemovedEvent,
  ParticipationEvent,
  ProcessAttributeEvent,
  ProcessEvent, ProcessParentProjectChangedEvent,
  ProcessTitleEvent,
  ReminderEvent,
  SharepointSyncSettingsEvent,
  SignatureEvent,
  TaskCreationEvent, ThirdPartyAuditActionEvent,
  ThirdPartyDetailsEvent, ThirdPartyDetailsLineItem, ThirdPartyWorkerInfo
} from './process-event';
import {EventFilterClass, ProcessEventType} from './process-event.interface';
import {CommentBuilder} from '../comment/comment.builder';

export class ProcessEventBuilder implements IApiResourceBuilder<ProcessEvent> {
  eventCommentMap = {};
  eventSeenByMap = {};
  reactionsMap = {};
  total = 1;
  perPage = 1;
  records = 1;

  constructor(private _processId: string) {
  }

  fromResponse(data): ProcessEvent {
    const attrs = data.attributes;
    const relationships = data.relationships || null;
    const event = ProcessEventBuilder.create(data);
    this.setAttributes(event, attrs, relationships);
    event.logLevel = attrs.log_level;
    event.logColor = attrs.log_color;
    event.log = this._createLog(attrs.log);

    event.total = this.total;
    event.perPage = this.perPage;
    event.records = this.records;

    return event;
  }

  toRequest(_: ProcessEvent) {
    return null;
  }

  private _createLog(log: string) {
    if (!log) return null;
    return log.split('\r\n').join('<br>');
  }

  /**
   * Set event specific attributes.
   *
   * TODO: Refactor remaining event specific stuff from the factory method below.
   * @param event
   * @param attrs
   * @param relationships
   */
  setAttributes(event, attrs, relationships) {
    event.parentId = attrs.parent_id;
    switch (event.type) {
      case ProcessEventType.AuditProof:
        try {
          event.lockedUntil = attrs.lock_until;
        } catch (e) {
          console.error(e);
        }
        event.folderName = attrs.folder_name;
        break;
      case ProcessEventType.DoubleOptIn:
        event.name = attrs.name;
        event.email = attrs.email;
        break;
      case ProcessEventType.ExternalMessage:
        event.details = attrs.message;
        event.commentsEnabled = false;
        if (relationships.read_by_user_accounts.data.length > 0) {
          relationships.read_by_user_accounts.data.forEach(seenBy => {
            event.seenBy.push(this.eventSeenByMap[seenBy.id]);
          });
        }
        break;
      case ProcessEventType.InstantMessage:
        event.recipients = attrs.recipients || [];
        event.details = attrs.message;
        event.commentsEnabled = true;
        event.backtrackId = attrs.backtrack_id;
        event.replyTo = attrs.reply_to;
        event.replyToId = attrs.reply_to_id;
        event.replyToDate = attrs.reply_to_date;
        event.replyToAuthor = attrs.reply_to_author;
        event.comments = this.eventCommentMap[event.id] || [];
        event.seenBy = [];
        event.thumbedUpBy = [];
        if (relationships.read_by_user_accounts.data.length > 0) {
          relationships.read_by_user_accounts.data.forEach(seenBy => {
            event.seenBy.push(this.eventSeenByMap[seenBy.id]);
          });
        }
        if (relationships.thumb_ups.data.length > 0) {
          relationships.thumb_ups.data.forEach(reaction => {
            const r = this.reactionsMap[reaction.id];
            if (r && r.reactionType) {
              if (!event.reactionMap[r.reactionType]) {
                event.reactionMap[r.reactionType] = [];
              }
              event.reactionMap[r.reactionType].push(r.email);
              event.reactions.push(r);
              event.reactionList = Object.keys(event.reactionMap).map((idx) => {
                return { reaction: idx, performers: event.reactionMap[idx]};
              });
            }
          });
        }
        break;
      case ProcessEventType.Description:
      case ProcessEventType.Title:
        event.to = attrs.to;
        event.from = attrs.from;
        break;
      case ProcessEventType.Comment:
        event.details = attrs.content;
        event.commentsEnabled = false;
        event.referenceId = event.itemId = attrs.reference_id;
        event.commentId = attrs.comment_id;
        event.title = attrs.reference_title;
        event.replyTo = attrs.reply_to;
        event.replyToId = attrs.reply_to_id;
        event.replyToDate = attrs.reply_to_date;
        event.replyToAuthor = attrs.reply_to_author;
        event.seenBy = [];
        event.thumbedUpBy = [];
        break;
      case ProcessEventType.ArtifactComment:
        event.details = attrs.content;
        event.commentsEnabled = false;
        event.referenceId = event.itemId = attrs.upload_id;
        event.commentId = attrs.comment_id;
        event.enableReply = false;
        event.title = attrs.filename;
        event.artifactId = attrs.artifact_id
        event.replyTo = attrs.reply_to;
        event.replyToId = attrs.reply_to_id;
        event.replyToDate = attrs.reply_to_date;
        event.replyToAuthor = attrs.reply_to_author;
        event.seenBy = [];
        event.thumbedUpBy = [];
        break;
      case ProcessEventType.ParticipantAdded:
      case ProcessEventType.ParticipantRemoved:
        event.participant = attrs.participant;
        event.adminAction = attrs.admin_action;
        break;
      case ProcessEventType.ProcessOwnerChanged:
        event.newOwnerEmail = attrs.new_owner_email;
        event.newOwnerName = attrs.new_owner_name;
        break;
      case ProcessEventType.ExportReady:
        event.export = attrs.export;
        event.title = attrs.title;
        event.expired = attrs.export_expired;
        event.url = attrs.url;
        break;
      case ProcessEventType.DocumentUploaded:
      case ProcessEventType.DocumentDeleted:
      case ProcessEventType.DocumentRemoved:
        event.title = attrs.title;
        event.url = attrs.url;
        event.itemId = attrs.item_id;
        event.backtrackId = attrs.backtrack_id;
        event.deletedAt = attrs.deleted_at;
        event.editedAt = attrs.edited_at;
        // Changelog if the item was altered.
        event.changelog = attrs.changelog;
        break;
      case ProcessEventType.DocumentRevisionUploaded:
        event.parentFilename = attrs.parent_filename;
        event.revisionFilename = attrs.revision_filename;
        event.revision = attrs.revision_no;
        event.backtrackId = attrs.backtrack_id;
        event.deletedAt = attrs.deleted_at;
        event.editedAt = attrs.edited_at;
        // Changelog if the item was altered.
        event.changelog = attrs.changelog;
        event.itemId = attrs.item_id;
        event.url = attrs.url;
        break;
      case ProcessEventType.PublicFileDeleted:
      case ProcessEventType.PublicFileUpload:
        event.title = attrs.title;
        event.itemId = attrs.backtrack_id;
        break;
      case ProcessEventType.DocumentsSynced:
        event.added = attrs.added;
        event.removed = attrs.removed;
        break;
      case ProcessEventType.TaskCreated:
        event.taskId = attrs.task_id;
        event.title = attrs.title;
        break;
      case ProcessEventType.TaskDeleted:
        event.title = attrs.title;
        break;
      case ProcessEventType.DueDate:
      case ProcessEventType.ProcessAttributeUpdate:
        event.to = attrs.to;
        event.from = attrs.from;
        break;
      case ProcessEventType.EmailSent:
        event.title = attrs.title;
        event.recipientName = attrs.recipient_name;
        event.recipientEmail = attrs.recipient_email;
        event.societyName = attrs.society_name;
        break;
      case ProcessEventType.CavContactAdded:
      case ProcessEventType.CavContactRemoved:
      case ProcessEventType.CavContactUpdated:
      case ProcessEventType.CavContactVerified:
        event.firstName = attrs.first_name;
        event.lastName = attrs.last_name;
        event.email = attrs.email;
        event.societyName = attrs.society_name;
        break;
      case ProcessEventType.ContactVerified:
        event.firstName = attrs.first_name;
        event.lastName = attrs.last_name;
        event.email = attrs.email;
        event.societyName = attrs.society_name;
        event.partnerNo = attrs.partner_no;
        event.processTitle = attrs.process_title;
        break;
      case ProcessEventType.ItemApplicability:
        event.applicable = attrs.applicable;
        event.appendixId = attrs.appendix_id;
        event.societyName = attrs.society_name;
        event.title = attrs.appendix_title;
        break;
      case ProcessEventType.ItemDueDateChanged:
        event.to = attrs.next_due_date;
        event.from = attrs.previous_due_date;
        event.title = attrs.item_title;
        break;
      case ProcessEventType.ItemStatus:
        event.previous = attrs.previous_status;
        event.next = attrs.next_status;
        event.appendixId = attrs.appendix_id;
        event.societyName = attrs.society_name;
        event.title = attrs.appendix_title;
        break;
      case ProcessEventType.CollectorGroupCreated:
      case ProcessEventType.CollectorGroupDeleted:
      case ProcessEventType.CollectorItemCreated:
      case ProcessEventType.CollectorItemDeleted:
      case ProcessEventType.DocumentsDownloadedExternally:
        event.title = attrs.title;
        break;
      case ProcessEventType.SignatureCanceled:
      case ProcessEventType.SignatureCompleted:
      case ProcessEventType.SignatureSigned:
      case ProcessEventType.SignatureRejected:
        event.fp_sign_user_name = attrs.fp_sign_user_name;
        event.fp_sign_user_email = attrs.fp_sign_user_email;
        break;
      case ProcessEventType.ExternalUploadCreated:
      case ProcessEventType.ExternalUploadDeleted:
      case ProcessEventType.ExternalLinkDeleted:
        event.url = attrs.url;
        break;
      case ProcessEventType.ExternalLinkCreated:
        event.url = attrs.url;
        event.documents = attrs.documents;
        break;
        case ProcessEventType.ResponsibleChanged:
          event.itemId = attrs.item_id;
          event.responsibleName = attrs.responsible_name;
          event.title = attrs.item_title;
          break;
      case ProcessEventType.LockUpload:
        event.itemId = attrs.item_id;
        event.title = attrs.item_title;
        break;
      case ProcessEventType.UnlockUpload:
        event.itemId = attrs.item_id;
        event.title = attrs.item_title;
        break;

      /**
       * class Process::Event::MovedSerializer < ::EventStore::StreamObjectSerializer
       type :process_event_parent_project_changed
       attributes :process_uuid, :process_title,
       :source_parent_uuid, :source_parent_title,
       :target_parent_uuid, :target_parent_title

       def process_uuid
       object.payload.dig('process_uuid')
       end

       def process_title
       object.payload.dig('process_title')
       end

       def source_parent_uuid
       object.payload.dig('source_parent_uuid')
       end

       def source_parent_title
       object.payload.dig('source_parent_title')
       end

       def target_parent_uuid
       object.payload.dig('target_parent_uuid')
       end

       def target_parent_title
       object.payload.dig('target_parent_title')
       end
       end

       */
      case ProcessEventType.ProcessParentChanged:
        event.processId = attrs.process_id;
        event.processTitle = attrs.process_title;
        event.sourceId = attrs.source_parent_id;
        event.sourceTitle = attrs.source_parent_title;
        event.targetId = attrs.target_parent_id;
        event.targetTitle = attrs.target_parent_title;
        break;
    }
  }

  addIncludedSection(includes) {
    const commentBuilder = new CommentBuilder();
    if (includes && includes.length) {
      includes.forEach(include => {
        if (include.type === 'message_comments') {
          const comment = commentBuilder.fromResponse(include);
          if (!this.eventCommentMap[comment.commentableId]) {
            this.eventCommentMap[comment.commentableId] = []
          }
          this.eventCommentMap[comment.commentableId].push(comment);
        } else if (include.type === 'event_store_read_stream_objects') {
          this.eventSeenByMap[include.id] = include.attributes.user_uid;
        } else if (include.type === 'user_thumb_ups') {
          this.reactionsMap[include.id] = {
            email: include.attributes.user_uid,
            reactionType: include.attributes.reaction_type
          };
        }
      });
    }
  }

  /**
   * Process event factory method.
   *
   * TODO: Many events are using the PartcipantEvent class or the DocumentEvent class.
   *       This works but is confusing. If possible change it to the more generic
   *       GenericAuditEvent, or another specific Event class.
   * TODO: Refactor remaining special attribute handling to the setAttributes method above.
   *
   * @param data
   */
  static create(data) {
    let type: ProcessEventType;
    switch (data.type) {
      /* General events */
      case 'process_event_errors':
        type = ProcessEventType.Error;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      /* Authentication, Access and Authorization */
      case 'participant_event_role_added':
      case 'participant_event_role_removed':
        type = ProcessEventType.ParticipantRoleChange;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.Access,
          data.attributes.activity,
          data.attributes.process_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'auth_event_access_denied':
        type = ProcessEventType.AccessDenied;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.Access,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'auth_event_receiveds':
      case 'audit_event_receiveds':
      case 'audit_event_external_access_requesteds':
        type = ProcessEventType.ExternalAccessReceived;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.Access,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'process_event_visiteds':
        type = ProcessEventType.Visited;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.Access,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'audit_event_applicability_changed':
        type = ProcessEventType.ItemApplicability;
        return new ItemApplicabilityChanged(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'audit_event_status_changed':
        type = ProcessEventType.ItemStatus;
        return new ItemStatusChanged(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      /* JAP/KAP Audit Events */
      case 'audit_event_remindeds':
        type = ProcessEventType.Reminded;
        const event = new ReminderEvent(data.id, type,
          EventFilterClass.Message,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
        event.recipient = data.attributes.recipient;
        return event;
      case 'audit_event_third_party_details':
        type = ProcessEventType.ThirdPartyDetails;
        const details = new ThirdPartyDetailsEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
        details.workerInfo = ThirdPartyWorkerInfo.fromResponse(data.attributes.worker_info);
        details.lineItems = data.attributes.line_items.map(item => {
          return ThirdPartyDetailsLineItem.fromResponse(item);
        });
        details.url = data.attributes.url;
        return details;

      case 'audit_event_third_party_audit_actions':
        type = ProcessEventType.ThirdPartyAuditAction;
        const auditAction = new ThirdPartyAuditActionEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
        auditAction.auditedAttribute = data.attributes.audited_attribute;
        auditAction.previousAuditedValue = data.attributes.previous_audited_value;
        auditAction.auditedValue = data.attributes.audited_value;
        auditAction.actionType = data.attributes.action_type;
        auditAction.commitMessage = data.attributes.commit_message;
        auditAction.unitOfValue = data.attributes.unit_of_value;
        auditAction.identifier = data.attributes.identifier;
        auditAction.recipientOrganizationNo = data.attributes.recipient_organization_no;
        auditAction.recipientOrganizationName = data.attributes.recipient_organization_name;
        auditAction.recipientTitle = data.attributes.recipient_title;
        auditAction.recipientName = data.attributes.recipient_name;
        auditAction.recipientEmail = data.attributes.recipient_email;
        auditAction.invoiceNo = data.attributes.invoice_no;
        auditAction.invoiceDate = data.attributes.invoice_date;
        return auditAction;

      /* Message events */
      case 'message_event_external_messages':
        type = ProcessEventType.ExternalMessage;
        return new ExternalMessageEvent(data.id, type,
          EventFilterClass.Message,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at, data.seenBy);
      case 'message_instant_messages':
        type = ProcessEventType.InstantMessage;
        return new InstantMessageEvent(data.id, type,
          EventFilterClass.Message,
          data.attributes.activity,
          data.attributes.process_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at, data.seenBy, data.thumbedUpBy);
      case 'process_event_comments':
      case 'comment_records':
        type = ProcessEventType.Comment;
        return new CommentEvent(data.id, type,
          EventFilterClass.Message,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at, data.seenBy);
      case 'artifact_event_comments':
        type = ProcessEventType.ArtifactComment;
        return new ArtifactCommentEvent(data.id, type,
          EventFilterClass.Message,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at, data.seenBy);
      case 'comment_edited':
        type = ProcessEventType.CommentEdited;
        return new CommentEvent(data.id, type,
          EventFilterClass.Message,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at, data.seenBy);
      case 'comment_deleted':
        type = ProcessEventType.CommentDeleted;
        return new CommentEvent(data.id, type,
          EventFilterClass.Message,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at, data.seenBy);
      case 'process_event_descriptions':
        type = ProcessEventType.Description;
        return new ProcessAttributeEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at, data.seenBy);

      /* Message related events */
      case 'message_event_seen_bies':
        type = ProcessEventType.SeenBy;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.Message,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'message_event_thumb_ups':
        type = ProcessEventType.ThumbUp;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.Message,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'message_event_thumb_downs':
        type = ProcessEventType.ThumbDown;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.Message,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      /* Email related events */
      case 'process_event_emails':
        type = ProcessEventType.EmailSent;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'process_event_email_errors':
        type = ProcessEventType.EmailSentFailure;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      /* Memberships */
      case 'participant_event_adds':
        type = ProcessEventType.ParticipantAdded;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.Participation,
          data.attributes.activity,
          data.attributes.process_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'participant_event_removes':
        type = ProcessEventType.ParticipantRemoved;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.Participation,
          data.attributes.activity,
          data.attributes.process_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'process_event_recipients':
      case 'quickshare_event_recipients':
        type = ProcessEventType.RecipientAdded;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.Participation,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      /* DMS Events / Document events */
      case 'process_event_audit_proof':
        type = ProcessEventType.AuditProof;
        return new DmsFolderAuditProof(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'process_event_dms_folder_grants':
        type = ProcessEventType.DmsFolderGrant;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'artifact_event_deleteds':
        type = ProcessEventType.DocumentDeleted;
        return new DocumentUploadedEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'artifact_event_adds':
        type = ProcessEventType.DocumentAdded;
        return new DocumentUploadedEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'artifact_event_revisions':
        type = ProcessEventType.DocumentRevisionUploaded;
        return new DocumentRevisionUploadedEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'artifact_event_syncs':
        type = ProcessEventType.DocumentsSynced;
        return new DocumentUploadedEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'artifact_event_downloads':
        type = ProcessEventType.DocumentsDownloaded;
        return new DocumentUploadedEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'artifact_event_removes':
        type = ProcessEventType.DocumentRemoved;
        return new DocumentUploadedEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'artifact_event_uploads':
        type = ProcessEventType.DocumentUploaded;
        return new DocumentUploadedEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      case 'audit_event_item_due_date_changed':
        type = ProcessEventType.ItemDueDateChanged;
        return new ItemDueDateChanged(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      /* Process state and attribute related events */
      case 'process_event_publisheds':
        type = ProcessEventType.ProcessPublished;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      case 'process_event_starts':
        type = ProcessEventType.ProcessStarted;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'process_event_updates':
        type = ProcessEventType.ProcessUpdate;
        const pupdate = new ParticipationEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
        pupdate.changeset = ProcessEventBuilder.createChangeSet(data.attributes.changeset);
        return pupdate;
      case 'process_event_attribute_title':
        type = ProcessEventType.Title;
        const processTitleEvent = new ProcessTitleEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
        processTitleEvent.changeset = ProcessEventBuilder.createChangeSet(data.attributes.changeset);
        return processTitleEvent;
      case 'process_event_closes':
        type = ProcessEventType.ProcessClosed;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'process_event_reopened':
        type = ProcessEventType.ProcessReopened;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'collector_event_updated_attributes':
      case 'bom_event_updated_attributes':
        type = ProcessEventType.CollectorUpdatedAttributes;
        const ev = new CollectorUpdatedAttributesEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
        ev.changeset = data.attributes.changeset;
        ev.title = data.attributes.title;
        ev.itemId = data.attributes.item_id;
        return ev;
      case 'collector_event_group_created':
        type = ProcessEventType.CollectorGroupCreated;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'collector_event_group_deleted':
        type = ProcessEventType.CollectorGroupDeleted;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'collector_event_item_created':
        type = ProcessEventType.CollectorItemCreated;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      case 'collector_event_item_deleted':
        type = ProcessEventType.CollectorItemDeleted;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'process_event_attribute_updates':
        type = ProcessEventType.ProcessAttributeUpdate;
        return new ProcessAttributeEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'process_event_due_dates':
        type = ProcessEventType.DueDate;
        return new ProcessAttributeEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      case 'collector_event_publisheds':
        type = ProcessEventType.CollectorPublished;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

        /** Export related events */
      case 'export_event_export_ready':
        type = ProcessEventType.ExportReady;
        return new ExportReadyEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      /** Task related events */
      case 'task_event_created':
        type = ProcessEventType.TaskCreated;
        return new TaskCreationEvent(data.id, type,
          EventFilterClass.Task,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'task_event_deleted':
        type = ProcessEventType.TaskDeleted;
        return new TaskCreationEvent(data.id, type,
          EventFilterClass.Task,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      /** CAV/CAC events */
      case 'audit_event_sents':
        type = ProcessEventType.EmailSent;
        return new EmailSentEvent(data.id, type,
          EventFilterClass.Participation,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'audit_event_contact_addeds':
        type = ProcessEventType.CavContactAdded;
        return new CavContactEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'audit_event_contact_removeds':
        type = ProcessEventType.CavContactRemoved;
        return new CavContactEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'audit_event_contact_updateds':
        type = ProcessEventType.CavContactUpdated;
        return new CavContactEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'audit_event_contact_verifieds':
        type = ProcessEventType.CavContactVerified;
        return new CavContactEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'audit_event_contact_verified':
        type = ProcessEventType.ContactVerified;
        return new ContactVerifiedEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      case 'auth_event_document_link':
        type = ProcessEventType.ExternalLinkCreated;
        return new ExternalAccessLinkEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'auth_event_document_link_deleted':
        type = ProcessEventType.ExternalLinkDeleted;
        return new ExternalAccessLinkEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'auth_event_upload_link':
        type = ProcessEventType.ExternalUploadCreated;
        return new ExternalAccessLinkEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'auth_event_upload_link_deleted':
        type = ProcessEventType.ExternalUploadDeleted;
        return new ExternalAccessLinkEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'process_event_clients':
        type = ProcessEventType.Audit;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id || data.attributes.process_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      case 'process_event_user_opt_ins':
        type = ProcessEventType.DoubleOptIn;
        return new DoubleOptInEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id || data.attributes.process_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      case 'process_event_folder_changeds':
        type = ProcessEventType.Audit;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id || data.attributes.process_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'artifact_event_document_moveds':
        type = ProcessEventType.Audit;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id || data.attributes.process_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'process_event_owner_changeds':
        type = ProcessEventType.Audit;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.Access,
          data.attributes.activity,
          data.attributes.resource_id || data.attributes.process_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      case 'process_event_owner_changed':
        type = ProcessEventType.ProcessOwnerChanged;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.Access,
          data.attributes.activity,
          data.attributes.resource_id || data.attributes.process_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      case 'fastdocs_event_project_room_entereds':
        type = ProcessEventType.FastdocsWorkerEntered;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.Access,
          data.attributes.activity,
          data.attributes.resource_id || data.attributes.process_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      case 'artifact_public_file_deletion':
        type = ProcessEventType.PublicFileDeleted;
        return new DocumentUploadedEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'artifact_public_file_upload':
        type = ProcessEventType.PublicFileUpload;
        return new DocumentUploadedEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'dms_event_document_signature_request_signed':
        type = ProcessEventType.SignatureSigned;
        return new SignatureEvent(data.id, type,
          EventFilterClass.Participation,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'dms_event_document_signature_request_canceled':
        type = ProcessEventType.SignatureCanceled;
        return new SignatureEvent(data.id, type,
          EventFilterClass.Participation,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'dms_event_document_signature_request_rejected':
        type = ProcessEventType.SignatureRejected;
        return new SignatureEvent(data.id, type,
          EventFilterClass.Participation,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'dms_event_document_signature_request_completed':
        type = ProcessEventType.SignatureCompleted;
        return new SignatureEvent(data.id, type,
          EventFilterClass.Participation,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      case 'artifact_external_downloads':
        type = ProcessEventType.DocumentsDownloadedExternally;
        return new SignatureEvent(data.id, type,
          EventFilterClass.Document,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      case 'label_added_to_item':
        type = ProcessEventType.LabelAdded;
        const labelAddedEvent = new LabelAddedEvent(
          data.id,
          type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id,
          data.attributes.performer,
          data.attributes.created_at,
          data.attributes.updated_at
        );
        labelAddedEvent.itemTitle = data.attributes.item_title;
        labelAddedEvent.itemId = data.attributes.item_id;
        labelAddedEvent.labelTitle = data.attributes.label_title;
        labelAddedEvent.labelId = data.attributes.label_id;
        labelAddedEvent.labelColor = data.attributes.label_color;
        labelAddedEvent.labelIcon = data.attributes.label_icon;
        return labelAddedEvent;

      case 'label_removed_from_item':
        type = ProcessEventType.LabelRemoved;
        const labelRemovedEvent = new LabelRemovedEvent(
          data.id,
          type,
          EventFilterClass.Attributes,
          data.attributes.activity,
          data.attributes.resource_id,
          data.attributes.performer,
          data.attributes.created_at,
          data.attributes.updated_at
        );
        labelRemovedEvent.itemTitle = data.attributes.item_title;
        labelRemovedEvent.itemId = data.attributes.item_id;
        labelRemovedEvent.labelTitle = data.attributes.label_title;
        labelRemovedEvent.labelId = data.attributes.label_id;
        labelRemovedEvent.labelColor = data.attributes.label_color;
        labelRemovedEvent.labelIcon = data.attributes.label_icon;
        return labelRemovedEvent;

        case 'collector_event_responsible_changed':
          type = ProcessEventType.ResponsibleChanged;
          return new GenericAuditEvent(data.id, type,
            EventFilterClass.Participation,
            data.attributes.activity,
            data.attributes.resource_id, data.attributes.performer,
            data.attributes.created_at, data.attributes.updated_at);

      case 'collector_event_item_locked':
        type = ProcessEventType.LockUpload;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      case 'collector_event_item_unlocked':
        type = ProcessEventType.UnlockUpload;
        return new GenericAuditEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      case 'process_event_parent_project_changed':
        type = ProcessEventType.ProcessParentChanged;
        return new ProcessParentProjectChangedEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);

      /**
       * def ms_team_title
       *     payload.dig('sync_settings', 'ms_team_title')
       *   end
       *
       *   def ms_channel_title
       *     payload.dig('sync_settings', 'ms_channel_title')
       *   end
       *
       *   def ms_sharepoint_folder
       *     payload.dig('sync_settings', 'ms_folder_name')
       *   end
       *
       *   def auto_sync_state
       *     payload.dig('sync_settings', 'auto_sync_enabled').to_s == 'true'
       *   end
       */
      case ProcessEventType.SharepointSyncSettings:
        type = ProcessEventType.SharepointSyncSettings;
        const syncSettingsEvent = new SharepointSyncSettingsEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
        syncSettingsEvent.ms_team_title = data.attributes.ms_team_title;
        syncSettingsEvent.ms_channel_title = data.attributes.ms_channel_title;
        syncSettingsEvent.ms_folder_name = data.attributes.ms_sharepoint_folder;
        syncSettingsEvent.auto_sync_state = data.attributes.auto_sync_state;
        return syncSettingsEvent;

      default:
        console.error('Generic/unspecified event', data);
        type = ProcessEventType.Audit;
        return new ParticipationEvent(data.id, type,
          EventFilterClass.GeneralEvent,
          data.attributes.activity,
          data.attributes.resource_id || data.attributes.process_id, data.attributes.performer,
          data.attributes.created_at, data.attributes.updated_at);
      // throw new Error('Unknown Event type');
    }
  }

  static createChangeSet(changeset) {
    if (changeset) {
      const keys = Object.keys(changeset);

      const titleIndx = keys.indexOf('title');
      if (titleIndx > 0) {
        const element = keys[titleIndx];
        keys.splice(titleIndx, 1);
        keys.splice(0, 0, element);
        keys.reverse();
      }

      const _changeset = [];
      for (const key of keys) {
        if (key === 'choice') {
          _changeset.push({key: key, from: changeset[key][0], to: changeset[key][1], source: changeset['available_choice_options']});
        } else if (key === 'multi_choice') {
            _changeset.push({ key: key, from: changeset[key][0], to: changeset[key][1], source: changeset['available_multi_choice_options'] });
        } else {
          _changeset.push({ key: key, from: changeset[key][0], to: changeset[key][1] });
        }
      }
      return _changeset;
    }
    return [];
  }

  addMetaSection(meta: any) {
    if (!meta) return;
    this.total = meta.total;
    this.perPage = meta.per_page;
    this.records = meta.records;
  }
}
