import {Observable, of as observableOf} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {ProcessTaskCountBuilder, TaskBuilder} from './task.builder';
import {ProcessTaskStat, Task} from './task';
import {HttpParams} from '@angular/common/http';
import {ResourceType} from '../message-settings/message-settings.interface';
import {EnvService} from 'app/shared/modules/api-resource/services/env.service';
import {TaskResource} from '../task-resource/task-resource';

@Injectable()
export class TaskService {

  readonly processTasksBasePath = 'api/v1/workflow_engine/processes';
  readonly tasksBasePath = 'api/v1/tasks';
  readonly tasksV3BasePath = 'api/v3/tasks';

  constructor(private _http: ApiResourceService,
              private _env: EnvService) {
  }

  getAll(id: string = null, showArchived, recursive = false): Observable<Task[]> {
    const builder = new TaskBuilder();
    if (id) {
      return <Observable<Task[]>>this._http.get<TaskBuilder, Task>(builder, `${this.processTasksBasePath}/${id}/tasks?all=true&serializer=v2&recursive=${recursive}&archived=${showArchived}`);
    } else {
      return <Observable<Task[]>>this._http.get<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks?all=true&serializer=v2&archived=${showArchived}`);
    }
  }

  getMy(): Observable<Task[]> {
    const builder = new TaskBuilder();
    return <Observable<Task[]>>this._http.get<TaskBuilder, Task>(builder, `${this.tasksV3BasePath}/my`);
  }

  getMany(ids: string[]): Observable<Task[]> {
    const builder = new TaskBuilder();
    const payload = {
      data: {
        attributes: {
          ids: ids
        }
      }
    }
    return <Observable<Task[]>>this._http.postAll<TaskBuilder, Task>(builder, `${this.tasksBasePath}/selected`, payload);
  }

  getOrganizationTasks() {
    const builder = new TaskBuilder();
    return <Observable<Task[]>>this._http.get<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks?organization=only`);
  }

  getOne(id: string): Observable<Task> {
    const builder = new TaskBuilder();
    return <Observable<Task>>this._http.get<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks/${id}`);
  }

  delete(task: Task): Observable<Task> {
    const builder = new TaskBuilder();
    return <Observable<Task>>this._http.delete<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks`, task);
  }

  create(task: Task) {
    const builder = new TaskBuilder();
    const payload = builder.toRequest(task);
    if (task.process.id) {
      return this._http.post<TaskBuilder, Task>(builder, `${this.processTasksBasePath}/${task.process.id}/tasks`, payload);
    } else {
      return this._http.post<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks`, payload);
    }
  }

  update(task: Task): Observable<Task> {
    const builder = new TaskBuilder();
    const payload = builder.toRequest(task);
    return this._http.put<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks/${task.id}`, payload);
  }

  close(taskId: string): Observable<Task> {
    const builder = new TaskBuilder();
    return this._http.post<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks/${taskId}/close`, {});
  }

  open(taskId: string): Observable<Task> {
    const builder = new TaskBuilder();
    return this._http.post<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks/${taskId}/reopen`, {});
  }

  getAllByQuery(search?: HttpParams) {
    const builder = new TaskBuilder();
    return <Observable<Task[]>>this._http.get<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks`, search);
  }

  getAllUserTasks(email, dense = false): Observable<Task[]> {
    const builder = new TaskBuilder();
    let query = `?all=true&assignees=${email}`;
    if (dense) {
      query = `${query}&dense=true`;
    }
    return <Observable<Task[]>>this._http.get<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks${query}`);
  }

  getProcessTaskCounts(): Observable<ProcessTaskStat[]> {
    const builder = new ProcessTaskCountBuilder();
    return <Observable<ProcessTaskStat[]>>this._http.get<ProcessTaskCountBuilder, ProcessTaskStat>(builder, `api/v1/tasks/process_statistics`);
  }

  responsible(id: string, email: string) {
    const builder = new TaskBuilder();
    const payload = {
      data: {
        attributes: {
          email: email
        }
      }
    };
    return <Observable<Task>>this._http.post<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks/${id}/responsible`, payload);
  }

  getAllWithoutPaging() {
    const builder = new TaskBuilder();
    return <Observable<Task[]>>this._http.get<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks?all=true`);
  }

  reorder(tasks: Task[]) {
    const orderedTasks = tasks.map(task => {
      return {
        id: task.id,
        order: task.order
      };
    });
    const builder = new TaskBuilder();
    const payload = {
      data: {
        attributes: {
          tasks: orderedTasks
        }
      }
    };
    return <Observable<Task[]>><unknown>this._http.post<TaskBuilder, Task>(builder, `api/v1/tasks/reorders`, payload);
  }

  downloadResource(task: Task, resource: TaskResource) {
    const id = resource.id;
    const fileName = resource.title;
    const url = `${this._env.apiBase()}/api/v1/tasks/tasks/${task.id}/attachments/${id}`;
    return this._http.getBlob(url, fileName);
  }

  addAttachmentFromDMS(taskId, dmsDocumentId): Observable<Task> {
    const builder = new TaskBuilder();
    const payload = {
      data: {
        attributes: {
          dms_document_id: dmsDocumentId
        }
      }
    };
    return this._http.post<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks/${taskId}/attachments`, payload);
  }

  deleteAttachment(taskId, attachmentId): Observable<Task> {
    const builder = new TaskBuilder();
    return this._http.del<TaskBuilder, Task>(builder, `${this.tasksBasePath}/tasks/${taskId}/attachments/${attachmentId}`);
  }

  // TODO : all readable to be translated
  getStatuses() {
    return observableOf([
      {
        status: 'initiated',
        readableStatus: 'Erstellt',
        icon: 'play_arrow',
        infoLevel: 'default'
      },
      {
        status: 'in_progress',
        readableStatus: 'In Bearbeitung',
        icon: 'timelapse',
        infoLevel: 'default'
      },
      {
        status: 'completed',
        readableStatus: 'Abgeschlossen',
        icon: 'check',
        infoLevel: 'success'
      },
      {
        status: 'delegated',
        readableStatus: 'Delegiert',
        icon: 'send',
        infoLevel: 'info'
      },
      {
        status: 'overdued',
        readableStatus: 'Überfällig',
        icon: 'access_time',
        infoLevel: 'error'
      },
      {
        status: 'rejected',
        readableStatus: 'Abgelehnt',
        icon: 'cancel',
        infoLevel: 'error'
      },
      {
        status: 'canceled',
        readableStatus: 'Abgebrochen',
        icon: 'cancel',
        infoLevel: 'error'
      }
    ])
  }

}
