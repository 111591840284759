import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {CollectorAttachment} from './collector-attachment';
import * as dayjs from 'dayjs';

export interface State extends EntityState<CollectorAttachment> {
  loading: boolean;
  selected?: CollectorAttachment;
  transfering: boolean;
}

export const adapter = createEntityAdapter<CollectorAttachment>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  selected: null,
  transfering: false
});
