import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {
  FeatureSelectors,
  LicenceAssignmentSelectors,
  MembershipSelectors,
  OrganizationSelectors,
  PageSidebarActions,
  PageSidebarSelectors,
  ProcessActions,
  ProcessSelectors,
  ProjectStatisticsSelectors
} from 'app/+store';
import {BehaviorSubject, combineLatest as observableCombineLatest, Observable, Subject} from 'rxjs';
import {PageSidebar} from 'app/+store/page-sidebar/page-sidebar';
import {Process} from 'app/+store/process/process';
import {SidebarRoute} from '../sidebar-navigation/sidebar-navigation.component';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  first,
  map,
  takeUntil
} from 'rxjs/operators';
import {ProcessProfile} from 'app/+store/process/process.interface';
import {ProcessService} from 'app/+store/process/process.service';
import {ProcessTreeNode} from 'app/+store/process-tree/process-tree';
import {CmsBlogSelectors} from 'app/+store/cms-blog';
import {CmsBlog} from 'app/+store/cms-blog/cms-blog';
import {Organization} from 'app/models/organization.model';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {Membership} from 'app/models/membership.model';
import {ProjectRoomAdmin} from 'app/+store/process-role/process-role';
import {ProcessRoleService} from 'app/+store/process-role/process-role.service';
import {AngularTokenService} from 'angular-token';
import {LicenceControlService} from 'app/shared/services/licence-control.service';
import {User} from 'app/models/user';
import {TenantTenantService} from 'app/+store/tenant/tenant/tenant.service';
import * as storeCache from 'store';
import {EnvService} from '../../../api-resource/services/env.service';

@Component({
  selector: 'dvtx-page-sidebar',
  templateUrl: './page-sidebar.component.html',
  styleUrls: ['./page-sidebar.component.scss']
})
export class PageSidebarComponent implements OnDestroy, OnInit {
  private onDestroy: Subject<void> = new Subject<void>();

  sidebar$: Observable<PageSidebar>;

  public collapsed = true;
  _process: Process;

  private parentProcess: Process = null;
  private sidebarWorkflowRoutes: SidebarRoute[] = [];
  private sidebarOrganizationRoutes: SidebarRoute[] = [];
  private sidebarUserRoutes: SidebarRoute[] = [];
  private hasAccessibleParent$ = new BehaviorSubject<boolean>(false);
  private parentProjectRoute: SidebarRoute;
  public tasksRouteCount: number;
  public documentListingPage = false;
  private featureSet$: Observable<any>;
  private blog$: Observable<CmsBlog>;
  private currentOrganization$: Observable<Organization>;
  private myMembership: Observable<Membership | undefined>;
  private davSync: boolean = false;
  public showUserSettings: boolean = false;
  public showOrganizationSettings: boolean = false;
  private preventCollapseBtn: boolean = true;
  public smallScreen: boolean;
  private tenantId: any = '';
  private hasLicenceAssignmentStandard$: Observable<boolean>;
  private currentParams: Params = null;
  @Output() onShowIconChange = new EventEmitter();

  // Marker to remember the current process if project. If an upper and _accessible_ parent project exists
  // this variable can be used to switch the virtuel "Master Project Room" route to "Sub Project Room".
  private mayBeSubProjectRoute: SidebarRoute = null;

  @Output() openSidebar = new EventEmitter();

  constructor(private _store: Store<AppState>, private _route: ActivatedRoute,
              private _processRoleSvc: ProcessRoleService,
              private _tokenSvc: AngularTokenService,
              private _tenantSvc: TenantTenantService,
              private _ngZone: NgZone,
              private router: Router,
              private _env: EnvService,
              private licenceControl: LicenceControlService,
              private _cdr: ChangeDetectorRef, private _processSvc: ProcessService) {
    this.smallScreen = window.innerWidth < 800 ? true : false;

    this._store.select('currentUser').pipe(first(),
      takeUntil(this.onDestroy))
      .subscribe((user: User) => {
        if (user && user.roles) {
          this.davSync = !!user.roles.find(role => role.symbol === 'dav_account');
        }
      });

    this.sidebar$ = _store.select(PageSidebarSelectors.getSidebar);

    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);

    // Feature CMS: Show blog if present.
    this.blog$ = this._store.select(CmsBlogSelectors.getCmsBlogOfSelectedOrg);

    this.currentOrganization$ = this._store.select(OrganizationSelectors.getSelected);
    this.myMembership = this._store.select(MembershipSelectors.getMyMembership);
  }

  ngOnInit(): void {
    if (this.smallScreen) {
      this.configureSidebarPositionForSmallScreens();
    }
    this.checkProcessParamsNavigation(this.getParams(this._route));
    this.checkNavigationSettings(this.getUrl(this._route));
    this.sidebar$.subscribe(sidebar => {
      if (sidebar && (sidebar.portal || sidebar.externalPortal) || this.showUserSettings || this.showOrganizationSettings) {
        this.collapseSideBar('open');
      }
      this.checkUnusedSideBar();
    })
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.getParams(this._route))
    ).subscribe(params => {
      this.checkProcessParamsNavigation(params);
    });

    // get route url, if it contains user-profile
    // then show user settings
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.getUrl(this._route))
    ).subscribe(url => {
      this.checkNavigationSettings(url);
    });


    // get organization id from route , if it matches the selected organization ,
    // then show organization settings
    this._store.select(OrganizationSelectors.getSelectedId)
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.onDestroy)
      )
      .subscribe(id => {
        if (id) {
          this._tenantSvc.getTenantFor(id)
            .pipe(first())
            .subscribe(tenant => {
              if (tenant) {
                this.tenantId = tenant.id;
              }
            })
          this._loadOrganizationSettingsRoutes();
          const orgParams = this.getParams(this._route);
          this.checkOrganizationRoute(orgParams, id);
          this.router.events.pipe(
            map(e => this.getParams(this._route))
          ).subscribe(params => {
            this.checkOrganizationRoute(params, id);
          });
        } else {
          this.showOrganizationSettings = false;
        }
      });

    // Set the user routes.
    // Run in another JS event cycle because it is requesting CDR (must be run after ngOnInit).
    setTimeout(_ => {
      // Run the user sidebar by default (even if no feature set is present, update it afterwards).
      // NOTE: The feature set is only requested on user accounts with organization, so only employees.
      // BP have no feature set, these are unlicensed accounts.
      const user$ = this._store.select('currentUser')
        .pipe(filter(u => !!u), distinctUntilKeyChanged('uid'), takeUntil(this.onDestroy))

      this.setUserSettingRoutes();

      observableCombineLatest(this.featureSet$, user$)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(([featureSet, user]) => this.setUserSettingRoutes(featureSet, user));
    }, 0);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  checkProcessParamsNavigation(params) {
    this.currentParams = params;
    if (params && (params.id || params.processId || params.workflowId)) {
      // place dot processId and workflow id first since sometimes .id is not the process id in the route
      const processId = (params.processId || params.workflowId || params.id);
      this._store.select(ProcessSelectors.getProcessById(processId)).pipe(
        distinctUntilChanged(),
        takeUntil(this.onDestroy)
      ).subscribe(process => {
        if (!process) {
          this.clearProcess();
          return;
        }
        if (this._process && this._process.id === process.id) {
          return;
        }
        // place dot processId and workflow id first since sometimes .id is not the process id in the route
        const currentParam = this.currentParams.processId || this.currentParams.workflowId || this.currentParams.id;
        if (process.id !== currentParam) {
          return;
        }
        if (process.status.code !== 'draft') {
          this._process = process;
          this.sidebarWorkflowRoutes = this.sidebarWorkflowRoutes.filter(a => a.icon === 'arrow_back');
          this.addBackToParentProject(this._process);
          this.setSidebarRoutes(this._process);
          this.collapseSideBar('open');
          this.checkUnusedSideBar();
        } else {
          this.clearProcess();
        }
      });
    } else {
      this.clearProcess();
    }
  }

  checkNavigationSettings(url) {
    if (url && url[0]) {
      if (url[0].path === 'user-profile') {
        this.showUserSettings = true;
        this.collapseSideBar('open');
      } else {
        this.showUserSettings = false;
      }
      if (url[0].path === 'documents') {
        this.documentListingPage = true;
      } else {
        this.documentListingPage = false;
      }
    }
    if (url && url[1] && url[1].path === 'storage') {
      this.showOrganizationSettings = false;
    }
    if (url && url[0] && url[0].path === 'process_management') {
      this.showOrganizationSettings = false;
    }
    this.checkUnusedSideBar();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.configureSideNav()
  }

  private checkOrganizationRoute(params, id) {
    if (params && (params.id || params.orgIdOfAdmin || params.orgId)) {
      const orgId = (params.id || params.orgIdOfAdmin || params.orgId);
      if (id === orgId) {
        this.showOrganizationSettings = true;
        this.collapseSideBar('open');
        this.checkNavigationSettings(this.getUrl(this._route));
      } else {
        this.showOrganizationSettings = false;
      }
    } else {
      this.showOrganizationSettings = false;
    }
    this.checkUnusedSideBar();
  }

  checkUnusedSideBar() {
    this.sidebar$.pipe(first()).subscribe(sidebar => {
      if ((!sidebar || (!sidebar.portal && !sidebar.externalPortal)) && !this._process && !this.showUserSettings && !this.showOrganizationSettings) {
        this.collapseSideBar('close');
        this.preventCollapseBtn = true;
        const navigationWidth: any = document.querySelector('.dvtx-sidebar-container');
        if (navigationWidth) {
          navigationWidth.style['position'] = 'relative';
        }
      } else {
        if (this.smallScreen) {
          this.collapseSideBar('open')
        }
        this.preventCollapseBtn = false;
      }
      if (!this.smallScreen) {
        const sidebarToggle: any = document.querySelector('.dvtx-app-sidebar-toggle');
        if (sidebarToggle) {
          if (this.preventCollapseBtn) {
            sidebarToggle.style['display'] = 'none';
          } else {
            sidebarToggle.style['display'] = 'block';
          }
        }
      }
    })
  }

  private _loadOrganizationSettingsRoutes() {
    setTimeout(_ => {
      this._ngZone.runOutsideAngular(_ => {
        this._processRoleSvc.getProjectRoomAdmins()
          .pipe(first())
          .subscribe((admins: ProjectRoomAdmin[]) => {
            const projectRoomAdminOwnership = !!admins.find(admin => admin.email === this._tokenSvc.currentAuthData.uid);
            this.checkAuthorizedOrgSettings(projectRoomAdminOwnership);
            this._cdr.detectChanges();
          }, err => {
            const projectRoomAdminOwnership = [];
            this.checkAuthorizedOrgSettings(projectRoomAdminOwnership);
          });
      });
    });
  }

  private checkAuthorizedOrgSettings(projectRoomAdminOwnership) {
    this.hasLicenceAssignmentStandard$ = this._store.select(LicenceAssignmentSelectors.hasLicenceAssignment('Standard'));
    observableCombineLatest(this.myMembership, this.currentOrganization$, this.featureSet$, this.hasLicenceAssignmentStandard$)
      .pipe(distinctUntilChanged())
      .subscribe(([membership, organization, featureSet, standardLicenseDonNotUse]) => {
        this.sidebarOrganizationRoutes = [];
        if (this.licenceControl && membership && organization && featureSet) {
          this.sidebarOrganizationRoutes.push(new SidebarRoute('DASHBOARD.OVERVIEW', 'dashboard', `/organization/${organization.id}/navigation`));
          if (this.licenceControl.hasRequiredLicence('Standard', false)) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('ORGANIZATION_NAVIGATON.ADDRESS_BOOK', 'perm_contact_calendar', `/organization/${organization.id}/addressbook`));
            this.sidebarOrganizationRoutes.push(new SidebarRoute('ORGANIZATION_NAVIGATON.PROFIL', 'domain', `/organization/${organization.id}/orgSettings`));
          }
          if (membership.hasAdministrationRights && this.licenceControl.hasRequiredLicence('Standard', false)) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('ORGANIZATION_NAVIGATON.DMS', 'folder_open', `/organization/${organization.id}/storage`));
            this.sidebarOrganizationRoutes.push(new SidebarRoute('ORGANIZATION_NAVIGATON.EMPLOYEES', 'people', `/organization/${organization.id}/employees`));
          }
          if (membership.hasAdministrationRights && projectRoomAdminOwnership && this.licenceControl.hasRequiredLicence('Standard', false)) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('PROCESS_MANAGEMENT.MANAGE_PROJECT_ROOMS', 'process', `/process_management/admin/${organization.id}`));
          }
          if (membership.hasAdministrationRights && projectRoomAdminOwnership && this.licenceControl.hasRequiredLicence('Standard', false) && featureSet.hasTenantAdminSupport) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('TENANT.ADMIN.ADMINISTRATION', 'account_balance', `tenant/${this.tenantId}/overview`));
          }
          if (membership.hasAdministrationRights && this.licenceControl.hasRequiredLicence('Standard', false) && featureSet.hasLicenceManagement) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('ORGANIZATION_NAVIGATON.LICENSES', 'verified_user', `/organization/${organization.id}/licenses`));
          }
          if (membership.hasAdministrationRights) {
            if (this.licenceControl.hasRequiredLicence('Professional', false)) {
              this.sidebarOrganizationRoutes.push(new SidebarRoute('ORGANIZATION_NAVIGATON.CUSTOMIZATION', 'settings_applications', `/organization/${organization.id}/customization`));
            } else {
              this.sidebarOrganizationRoutes.push(new SidebarRoute('ORGANIZATION_NAVIGATON.CUSTOMIZATION', 'settings_applications', `#`, null, 'customizationSettingsRoute'));
            }
          }
          if (membership.hasAdministrationRights && this.licenceControl.hasRequiredLicence('Standard', false) && featureSet.hasLabels) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('ORGANIZATION_NAVIGATON.LABELS_SETTINGS', 'labels', `/organization/${organization.id}/labels-settings`));
          }
          if (membership.hasAdministrationRights && this.licenceControl.hasRequiredLicence('Standard', false)) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('ORGANIZATION_NAVIGATON.TASKS_SETTINGS', 'tasks', `/organization/${organization.id}/tasks-settings`));
            this.sidebarOrganizationRoutes.push(new SidebarRoute('WORKFLOW_TEMPLATE.TEMPLATE_MANAGEMENT', 'templates', `/templates-management/${organization.id}`));

          }
          if (this.davSync && this.licenceControl.hasRequiredLicence('Standard', false)) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('ORGANIZATION_NAVIGATON.IMPORT', 'import_export', `/organization/${organization.id}/contact_imports/dav`));
          }
          if (membership.hasAdministrationRights && featureSet.hasCms) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('CMS.CMS', 'subject', `/cms/admin/${organization.id}`));
          }
          if (membership.hasAdministrationRights && featureSet.hasPartnerLinks) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('PARTNER_LINK.ORGANIZATION_SETTING_TITLE', 'link', `/partner_links/admin/${organization.id}`));
          }
          if (membership.hasAdministrationRights && featureSet.hasFastdocsSettings) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('FASTDOCS.SETTINGS_TITLE', 'lohn', `/fastdocs/admin/${organization.id}`));
          }
          if (featureSet.hasDigitalbarSettings && featureSet.hasDigitalbar) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('DIGITALBAR.SETTINGS_TITLE', 'link', `/digitalbar/admin/${organization.id}/settings`));
          }
          if (featureSet.hasFivefAgentSettings && featureSet.hasFivefAgent) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('FIVEF_AGENT.SETTINGS_TITLE', 'link', `/fivef-agent/admin/${organization.id}/settings`));
          }
          if (featureSet.hasFivefDesktopSettings && featureSet.hasFivefDesktop) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('FIVE_F.FIVEF_DESKTOP.FIVEF_DESKTOP', 'fivef_desktop', `/fivef-desktop/admin/${organization.id}/settings`));
          }
          if (featureSet.hasEloDms) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('ELO.SETTINGS', 'elo_dms', `/elo-dms/${organization.id}`));
          }
          if (featureSet.hasDatevDms) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('DATEV.DMS.PROCESS_SETTINGS', 'datev_data_service_logo', `/datev-dms/${organization.id}`));
          }
          if (featureSet.hasDatevSettings && featureSet.hasDatev) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('DATEV.SETTINGS_DUO_TITLE', 'datev_data_service_logo', `/datev/${organization.id}`));
          }
          if (membership.hasAdministrationRights && featureSet.hasBookman) {
            this.sidebarOrganizationRoutes.push(new SidebarRoute('USER_NAVIGATON.BOOKMAN_COCKPIT_SETTINGS', 'fibu_workflow', `/organization/${organization.id}/bookman-cockpit-settings`));
          }
        }
      })
  }

  private clearProcess() {
    this._process = null;
    this.checkUnusedSideBar();
  }

  private getUrl(route: ActivatedRoute) {
    let url = route.snapshot.url
    url = {...route.snapshot.url, ...url}
    if (route.children) {
      for (const r of route.children) {
        url = {...this.getUrl(r), ...url};
      }
    }
    return url;
  }

  private getParams(route: ActivatedRoute): Params {
    // route param names (eg /a/:personId) must be ditinct within
    // a route otherwise they'll be overwritten
    let params = route.snapshot.params
    params = {...route.snapshot.queryParams, ...params}
    if (route.children) {
      for (const r of route.children) {
        params = {...this.getParams(r), ...params};
      }
    }
    return params;
  }

  addBackToParentProject(process) {
    this.hasAccessibleParent$.next(false);

    if (!process.parentId) {
      this.parentProcess = null;
    }
    // Check for parent access: Work on local copy to prevent a double adding of the parent process link.
    if (process.parentId && process.processType !== 'cac_request') {
      this._processSvc.getOne(process.parentId, true).pipe(first())
        .subscribe((parentProcess: Process) => {
          this.parentProcess = parentProcess;
          let routeTitle = 'PROJECT.MASTER_PROJECT_ROOM';
          let callback = null;

          if (parentProcess.processType !== 'project') {
            routeTitle = 'GENERAL.BACK_ACTION';
            const parentId = process.parentId;
            callback = (): void => {
              const id = parentId;
              this._store.dispatch(new ProcessActions.RunCommand(id, 'index', 'on_click'));
            }
          }

          this.hasAccessibleParent$.next(true);

          if (process.processType === 'project' && this.mayBeSubProjectRoute) {
            this.mayBeSubProjectRoute.title = 'PROJECT.SUB_MASTER_PROJECT_ROOM';
          }

          this.sidebarWorkflowRoutes = this.sidebarWorkflowRoutes.filter(a => a.icon !== 'arrow_back');
          this.parentProjectRoute = new SidebarRoute(routeTitle, 'arrow_back', `/projects/run/${this.parentProcess.id}/timeline`, null, '', callback);
          this.sidebarWorkflowRoutes.unshift(this.parentProjectRoute);

          this._cdr.detectChanges();
        }, err => {
          this.hasAccessibleParent$.next(false);
          this.sidebarWorkflowRoutes = this.sidebarWorkflowRoutes.filter(a => a.icon !== 'arrow_back');
          console.error(err);
        });
    } else {
      if (!process.parentId) {
        this.sidebarWorkflowRoutes = this.sidebarWorkflowRoutes.filter(a => a.icon !== 'arrow_back');
        this.parentProcess = null;
      }
    }
  }

  private setSidebarRoutes(process) {
    // Reset maybe subproject on each recreation.
    // It is set to remember the current process if project and to switch the title to Sub Project if a master project
    // is accessible.
    this.mayBeSubProjectRoute = null;

    switch (process.processType) {
      case 'quickcollector':
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PROJECT.OVERVIEW', 'playlist_add_check', `/collecto/run/${process.id}/dashboard`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('COLLECTOR.COLLECTO_ROOM', 'process', `/collecto/run/${process.id}/timeline`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DMS.DOCUMENTS', 'insert_drive_file', `/documents/${process.id}`));
        // kanbanRoute = new SidebarRoute('KANBAN.TITLE', 'kanban', `/kanban-board/${process.id}`);
        // this.showKanbanRouteIfMember$.next(kanbanRoute);
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PARTICIPANTOVERVIEW.TITLE', 'groups', `/workflows/participations/${process.id}/overview`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('TASK.TITLE', 'tasks', `/tasks/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('SETTINGS.SETTINGS', 'settings', `/workflows/settings/${process.id}`));
        break;

      case 'cac':
        const mainLinkTitle = process.profile === ProcessProfile.AuditingProfile ? 'CAC.OVERVIEW_APPENDICES' : 'PC.OVERVIEW_ATTACHMENTS';
        this.sidebarWorkflowRoutes.push(new SidebarRoute(mainLinkTitle, 'attach_file', `/workflows/audit/kap/${process.id}/appendices`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('CAC.INTERNAL_PROJECT_ROOM', 'process', `/workflows/audit/kap/${process.id}/project-room`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DMS.DOCUMENTS', 'insert_drive_file', `/documents/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PARTICIPANTOVERVIEW.TITLE', 'groups', `/workflows/participations/${process.id}/overview`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('TASK.TITLE', 'tasks', `/tasks/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('SETTINGS.SETTINGS', 'settings', `/workflows/settings/${process.id}`));
        break;

      case 'cac_request':
        const subrequestLinkTitle = process.profile === ProcessProfile.AuditingProfile ? 'CAC.APPENDICES' : 'PC.ATTACHMENTS';
        this.sidebarWorkflowRoutes.push(new SidebarRoute(subrequestLinkTitle, 'attach_file', `/workflows/audit/kap/communicate-respond/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('CAC.SOCIETY_PROJECT_ROOM', 'process', `/workflows/audit/kap/communicate-respond-project-room/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DMS.DOCUMENTS', 'insert_drive_file', `/documents/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PARTICIPANTOVERVIEW.TITLE', 'groups', `/workflows/participations/${process.id}/overview`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('TASK.TITLE', 'tasks', `/tasks/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('SETTINGS.SETTINGS', 'settings', `/workflows/settings/${process.id}`));
        break;

      case 'signature':
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DASHBOARD.OVERVIEW', 'sign', `/signature/request/run/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PROJECT_ROOM.TITLE', 'process', `/workflows/timelines/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DMS.DOCUMENTS', 'insert_drive_file', `/documents/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PARTICIPANTOVERVIEW.TITLE', 'groups', `/workflows/participations/${process.id}/overview`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('TASK.TITLE', 'tasks', `/tasks/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('SETTINGS.SETTINGS', 'settings', `/workflows/settings/${process.id}`));
        break;

      case 'quickstart':
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PROJECT_ROOM.TITLE', 'process', `/workflows/timelines/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DMS.DOCUMENTS', 'insert_drive_file', `/documents/${process.id}`));
        // kanbanRoute = new SidebarRoute('KANBAN.TITLE', 'kanban', `/kanban-board/${process.id}`);
        // this.showKanbanRouteIfMember$.next(kanbanRoute);
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PARTICIPANTOVERVIEW.TITLE', 'groups', `/workflows/participations/${process.id}/overview`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('TASK.TITLE', 'tasks', `/tasks/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('SETTINGS.SETTINGS', 'settings', `/workflows/settings/${process.id}`));
        break;

      case 'project':
        this.mayBeSubProjectRoute = new SidebarRoute('PROJECT.MASTER_PROJECT_ROOM', 'projectroom', `/projects/run/${process.id}/timeline`);
        this.sidebarWorkflowRoutes.push(this.mayBeSubProjectRoute);
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DMS.WORKFLOWS', 'process', `/projects/run/${process.id}/workflows`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('Insights', 'insertChartOutlined', `/projects/run/${process.id}/milestone`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DMS.DOCUMENTS', 'insert_drive_file', `/documents/${process.id}`));
        // this.sidebarWorkflowRoutes.push(new SidebarRoute('KANBAN.TITLE', 'kanban', `/kanban-board/${process.id}`));
        // kanbanRoute = new SidebarRoute('KANBAN.TITLE', 'kanban', `/kanban-board/${process.id}`);
        // this.showKanbanRouteIfMember$.next(kanbanRoute);
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PARTICIPANTOVERVIEW.TITLE', 'groups', `/workflows/participations/${process.id}/overview`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('TASK.TITLE', 'tasks', `/tasks/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('SETTINGS.SETTINGS', 'settings', `/workflows/settings/${process.id}`));
        break;

      case 'quickshare_v2':
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PROJECT_ROOM.TITLE', 'process', `/workflows/timelines/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DMS.DOCUMENTS', 'insert_drive_file', `/documents/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PARTICIPANTOVERVIEW.TITLE', 'groups', `/workflows/participations/${process.id}/overview`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('TASK.TITLE', 'tasks', `/tasks/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('SETTINGS.SETTINGS', 'settings', `/workflows/settings/${process.id}`));
        break;

      case 'third_party':
      case 'leasing':
      case 'bank':
      case 'safekeeping':
      case 'expert':
      case 'insurance':
      case 'claim_invoice':
      case 'claim_balance':
      case 'liability_balance':
      case 'liability_invoice':
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DASHBOARD.OVERVIEW', 'third_party', `/workflows/audit/jap-bestaetigung-dritte/overview/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PROJECT_ROOM.TITLE', 'process', `/workflows/timelines/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DMS.DOCUMENTS', 'insert_drive_file', `/documents/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PARTICIPANTOVERVIEW.TITLE', 'groups', `/workflows/participations/${process.id}/overview`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('TASK.TITLE', 'tasks', `/tasks/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('SETTINGS.SETTINGS', 'settings', `/workflows/settings/${process.id}`));
        break;

      case 'third_party_request':
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PROJECT_ROOM.TITLE', 'process', `/workflows/timelines/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DMS.DOCUMENTS', 'insert_drive_file', `/documents/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PARTICIPANTOVERVIEW.TITLE', 'groups', `/workflows/participations/${process.id}/overview`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('TASK.TITLE', 'tasks', `/tasks/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('SETTINGS.SETTINGS', 'settings', `/workflows/settings/${process.id}`));
        break;

      case 'audit_contact_imports':
        // this.overviewRoute = new SidebarRoute('GENERAL.WORKFLOW_OVERVIEW', 'list', `/workflows/audit/kap/${process.parentId}/dashboard`);
        this.sidebarWorkflowRoutes.push(new SidebarRoute('CAC.CONTACT_LIST', 'people', `/workflows/audit/kap/${process.parentId}/contacts-dashboard/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PROJECT_ROOM.TITLE', 'process', `/workflows/audit/kap/${process.parentId}/contacts-timeline/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DMS.DOCUMENTS', 'insert_drive_file', `/documents/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PARTICIPANTOVERVIEW.TITLE', 'groups', `/workflows/participations/${process.id}/overview`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('TASK.TITLE', 'tasks', `/tasks/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('SETTINGS.SETTINGS', 'settings', `/workflows/settings/${process.id}`));
        break;
      case 'fastdocs_employee_master_data':
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PROJECT_ROOM.TITLE', 'process', `/workflows/timelines/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('DMS.DOCUMENTS', 'insert_drive_file', `/documents/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('PARTICIPANTOVERVIEW.TITLE', 'groups', `/workflows/participations/${process.id}/overview`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('TASK.TITLE', 'tasks', `/tasks/${process.id}`));
        this.sidebarWorkflowRoutes.push(new SidebarRoute('SETTINGS.SETTINGS', 'settings', `/workflows/settings/${process.id}`));
        break;

    }

    this._store.select(
      ProjectStatisticsSelectors.getTasksStatistics()
    ).pipe(
      takeUntil(this.onDestroy)
    ).subscribe(stats => {
      if (stats.all > 0) {
        this.tasksRouteCount = stats.all;
      } else {
        this.tasksRouteCount = null;
      }
      this._cdr.detectChanges();
    });
  }

  collapseSideBar(value = null) {
    if (value === 'close') {
      if (!this.collapsed) {
        this.collapsed = true;
        this.collapseDetailsFunction();
      } else {
        this.collapsed = true;
        const resizerSidebarFeature: any = document.querySelector('.dvtx-sidebar-resizer-feature');
        if (resizerSidebarFeature) {
          resizerSidebarFeature.classList.add('dvtx-sidebar-resizer-remove');
        }
      }

    } else if (value === 'open') {
      if (this.collapsed) {
        this.collapsed = false;
        this.collapseDetailsFunction();
      } else {
        this.collapsed = false;
        const resizerSidebarFeature: any = document.querySelector('.dvtx-sidebar-resizer-feature');
        if (resizerSidebarFeature) {
          resizerSidebarFeature.classList.remove('dvtx-sidebar-resizer-remove');
        }
      }
    } else {
      this.sidebar$.pipe(first()).subscribe(val => {
        if ((!val || (!val.portal && !val.externalPortal)) && !this._process && !this.showUserSettings && !this.showOrganizationSettings) {
          return;
        } else {
          this.collapsed = !this.collapsed;
          this.onShowIconChange.emit(this.collapsed);
          this.collapseDetailsFunction();
        }

      })
    }
    this.onShowIconChange.emit(this.collapsed);
    this._cdr.detectChanges();
  }

  /**
   * this function is used by the mainappcomponent
   * used show sidebar on hover when collapsed
   *
   * @public
   */
  showIfCollapsed(value) {
    // show sidebar on hover when 2nd sidebar is available
    this.sidebar$.pipe(first()).subscribe(sidebar => {
      if ((!sidebar || (!sidebar.portal && !sidebar.externalPortal)) && !this._process && !this.showUserSettings && !this.showOrganizationSettings) {
        return;

      } else {
        const sidebarToggle: any = document.querySelector('.dvtx-app-sidebar-toggle');
        const navigationWidth: any = document.querySelector('.dvtx-sidebar-container');

        if (this.collapsed && value && navigationWidth && sidebarToggle) {
          navigationWidth.style['position'] = 'absolute';
          navigationWidth.style['width'] = '300px';
          sidebarToggle.style['right'] = '-310px'

        } else if (this.collapsed && !value && navigationWidth && sidebarToggle) {
          navigationWidth.style['position'] = 'relative';
          navigationWidth.style['width'] = '0px';
          sidebarToggle.style['right'] = '-10px'
        }
      }
    })
  }

  collapseDetailsFunction() {
    this.smallScreen = window.innerWidth < 800 ? true : false
    // changing width of sidebar when collapsing
    this._store.dispatch(new PageSidebarActions.Toggle(this.collapsed))
    const sidebar: any = document.querySelector('.dvtx-sidebar-nav-container');
    const resizerSidebarFeature: any = document.querySelector('.dvtx-sidebar-resizer-feature');
    const sidebarContainer: any = document.querySelector('.dvtx-sidebar-container');
    const firstSidebar: any = document.querySelector('#first-sidebar-container');
    const sidebarToggle: any = document.querySelector('.dvtx-app-sidebar-toggle');
    if (sidebarToggle) {
      sidebarToggle.style['right'] = '-10px'
    }
    if (this.collapsed) {
      if (sidebar) {
        sidebar.style.flexBasis = '70px';
        sidebar.style['min-width'] = '70px';
      }
      if (firstSidebar) {
        firstSidebar.style['min-width'] = '70px';
        firstSidebar.style['max-width'] = '70px';
        firstSidebar.style['width'] = '70px';
      }
      if (sidebarContainer) {
        sidebarContainer.style['width'] = `${0}px`;
      }
      if (resizerSidebarFeature) {
        resizerSidebarFeature.classList.add('dvtx-sidebar-resizer-remove');
      }
    } else {
      // add resizer feature when uncollapsed
      if (resizerSidebarFeature) {
        resizerSidebarFeature.classList.remove('dvtx-sidebar-resizer-remove');
      }
      if (sidebar && sidebarContainer) {
        const sidebarResizeSize = storeCache.get('sidebar-resize');
        if (sidebarResizeSize) {
          sidebar.style.flexBasis = sidebarResizeSize + 'px';
          sidebar.style['min-width'] = sidebarResizeSize + 'px';
          sidebarContainer.style['width'] = `${sidebarResizeSize - 72}px`
        } else {
          sidebar.style.flexBasis = '300px';
          sidebar.style['min-width'] = '300px';
          sidebarContainer.style['width'] = `${300 - 72}px`
        }
      }
    }
    if (!this.smallScreen && sidebar) {
      sidebar.style['position'] = 'relative';
    }
    this._cdr.detectChanges();
  }

  /**
   * this function is used by the mainappcomponent
   * used to collapse sidebar
   *
   * @public
   */
  public collapseSidebarApp(value, sidebar) {
    this.smallScreen = window.innerWidth < 800 ? true : false
    if (!this.smallScreen) {
      this.collapseSideBar(value);
      return;
    }
    // collapse all sidebar if tablet mode
    const sidebarnav: any = document.querySelector('.dvtx-sidebar-nav-container');
    if (sidebarnav && sidebar) {
      if (value === 'close') {
        sidebarnav.style['left'] = '23px';
        sidebarnav.style.removeProperty('visibility');
        sidebar.close();
      } else if (value === 'open') {
        sidebarnav.style['left'] = '0px';
        sidebarnav.style.removeProperty('visibility');
        sidebar.open();
      } else {
        if (sidebar.opened) {
          sidebarnav.style['left'] = '23px';
          sidebarnav.style.removeProperty('visibility');
          sidebar.close();
        } else {
          sidebarnav.style['left'] = '0px';
          sidebarnav.style['visibility'] = 'unset';
          sidebar.open();
        }
      }
    }
  }

  /**
   * this function is used by the mainappcomponent
   * used to configure the sidebar for mobile or tablet mode.
   *
   * @public
   */
  configureSideNav() {
    this.smallScreen = window.innerWidth < 800 ? true : false
    if (document.querySelector('.dvtx-sidebar-nav-container')) {
      if (!this.smallScreen) {
        this.openSidebar.emit(true);
        const sidebarnav: any = document.querySelector('.dvtx-sidebar-nav-container');
        if (sidebarnav) {
          sidebarnav.style['left'] = '0px';
          sidebarnav.style.removeProperty('visibility');
          sidebarnav.style['position'] = 'relative';
        }
        const resizerSidebarFeature: any = document.querySelector('.dvtx-sidebar-resizer-feature');
        if (resizerSidebarFeature && this.collapsed) {
          resizerSidebarFeature.classList.add('dvtx-sidebar-resizer-remove');
        }
        if (resizerSidebarFeature && !this.collapsed) {
          resizerSidebarFeature.classList.remove('dvtx-sidebar-resizer-remove');
        }
        const sidebarToggle: any = document.querySelector('.dvtx-app-sidebar-toggle');
        if (sidebarToggle) {
          if (this.preventCollapseBtn) {
            sidebarToggle.style['display'] = 'none';
          } else {
            sidebarToggle.style['display'] = 'block';
          }
        }
      } else {
        this.checkUnusedSideBar();
        this.configureSidebarPositionForSmallScreens();
      }
    }
  }

  configureSidebarPositionForSmallScreens() {
    this.smallScreen = window.innerWidth < 800 ? true : false
    if (this.smallScreen) {
      const sidebarnav: any = document.querySelector('.dvtx-sidebar-nav-container');
      const sidebarToggle: any = document.querySelector('.dvtx-app-sidebar-toggle');
      if (sidebarnav) {
        sidebarnav.style['position'] = 'absolute';
      }
      if (sidebarToggle) {
        sidebarToggle.style['display'] = 'block';
      }
      const resizerSidebarFeature: any = document.querySelector('.dvtx-sidebar-resizer-feature');
      if (resizerSidebarFeature && this.collapsed) {
        resizerSidebarFeature.classList.add('dvtx-sidebar-resizer-remove');
      }
    }
  }

  public select(node: ProcessTreeNode) {
    this._scrollTo(node.id);
  }

  private _scrollTo(id) {
    const mainContainer = document.querySelector('#dvtx-app-content');
    // const mainContainer = document.querySelector(`${id}`);
    const idSelector = `dvtx-documents-table__grouping-id__${id}`;
    const target = document.getElementById(idSelector);
    if (!target) {
      console.error(`Target not found: ${idSelector}`, target);
      return;
    }
    const padding = 16;
    const targetTop = target.offsetTop - padding;

    // The ScrollToOptions is not supported in all the browsers
    try {
      mainContainer.scrollTo({
        top: targetTop,
        behavior: 'smooth'
      });
    } catch {
      mainContainer.scrollTo(0, targetTop);
    }
  }

  /**
   * Creates the user profile sidebar.
   *
   * The feature set is evaluated if present. Candidates for feature set are the DMS settings that are not
   * present on 5F on premise (no Foxdox, e.g. GT CH).
   *
   * The function must be run after ngOnInit. It requests a change cycle.
   *
   * @param featureSet
   * @private
   */
  private setUserSettingRoutes(featureSet = null, user: User = null) {
    const sidebarUserRoutes = []

    sidebarUserRoutes.push(new SidebarRoute('DASHBOARD.OVERVIEW', 'dashboard', `/user-profile/navigation`));
    sidebarUserRoutes.push(new SidebarRoute('USER_NAVIGATON.PROFILE', 'account_box', `/user-profile/user`));
    sidebarUserRoutes.push(new SidebarRoute('USER_NAVIGATON.SECURITY', 'lock', `/user-profile/security`));
    // sidebarUserRoutes.push(new SidebarRoute('Abrechnung', 'storage', `/user-profile/billing`));

    if (featureSet && featureSet.hasMyStorage) {
      sidebarUserRoutes.push(new SidebarRoute('USER_NAVIGATON.DMS', 'folder_open', `/user-profile/dms`));
    }

    sidebarUserRoutes.push(new SidebarRoute('SIGNATURE.ACCOUNT.ACCOUNT_SETTINGS', 'sign', `/user-profile/signature`));
    sidebarUserRoutes.push(new SidebarRoute('USER_NAVIGATON.NOTIFICATIONS', 'notifications', `/messages/notification`));

    if (!!user && user.hasMobileAccess() || featureSet && featureSet.hasMobileSupport) {
      sidebarUserRoutes.push(new SidebarRoute('MOBILE_LOGIN.TITLE', 'smartphone', `/user-profile/mobile`));
    }

    sidebarUserRoutes.push(new SidebarRoute('REGISTRATION.DATA_PRIVACY', 'policy', `/user-profile/privacy`));

    this.sidebarUserRoutes = sidebarUserRoutes;
    this._cdr.detectChanges();
  }
}
