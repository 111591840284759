import {
  Component
} from "@angular/core";
import { FilterListSelectionComponent } from "../filter-list-selection/filter-list-selection.component";
import { Colors } from "app/lib/color/hsl";

@Component({
  selector: 'dvtx-filter-list-selection-colored',
  templateUrl: './filter-list-selection-colored.component.html',
  styleUrls: ['./filter-list-selection-colored.component.scss']
})
export class FilterListSelectionColoredComponent extends FilterListSelectionComponent {
  public colors = Colors;

  getNormalStyle(option) {
    const color = option.color.indexOf("#") > -1 ? option.color : '#' + option.color;
    let styles = {
      'border-color': color,
      'color': color
    };
    return styles;
  }

  getActiveStyle(option) {
    const color = option.color.indexOf("#") > -1 ? option.color : '#' + option.color;
    let styles = {
      'background-color': color,
      'border-color': color,
      'color': this.colors.IsDarkColor(color) ? 'white' : 'black'
    };
    return styles;
  }
}
