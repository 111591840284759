import {Action} from '@ngrx/store';
import * as model from './tenant';
import * as modelInterface from './tenant.interface';

export enum TenantTenantActionTypes {
  Create = '[Tenant.Tenant] Create',
  CreateSuccess = '[Tenant.Tenant] Create Success',
  CreateFail = '[Tenant.Tenant] Create Fail',
  Remove = '[Tenant.Tenant] Remove',
  RemoveSuccess = '[Tenant.Tenant] Remove Success',
  RemoveFail = '[Tenant.Tenant] Remove Fail',
  Save = '[Tenant.Tenant] Save',
  SaveSuccess = '[Tenant.Tenant] Save Success',
  SaveFail = '[Tenant.Tenant] Save Fail',
  LoadAll = '[Tenant.Tenant] Load All',
  LoadAllSuccess = '[Tenant.Tenant] Load All Success',
  LoadAllFail = '[Tenant.Tenant] Load All Fail',
  LoadOne = '[Tenant.Tenant] Load One',
  LoadOneSuccess = '[Tenant.Tenant] Load One Success',
  LoadOneFail = '[Tenant.Tenant] Load One Fail'
}

export class Create implements Action {
  readonly type = TenantTenantActionTypes.Create;

  constructor(public params: modelInterface.Tenant.ITenantParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = TenantTenantActionTypes.CreateSuccess;

  constructor(public tenant: model.Tenant.Tenant) {
  }
}

export class CreateFail implements Action {
  readonly type = TenantTenantActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = TenantTenantActionTypes.Remove;

  constructor(public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = TenantTenantActionTypes.RemoveSuccess;

  constructor(public tenant: model.Tenant.Tenant) {
  }
}

export class RemoveFail implements Action {
  readonly type = TenantTenantActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = TenantTenantActionTypes.Save;

  constructor(public tenant: model.Tenant.Tenant) {
  }
}

export class SaveSuccess implements Action {
  readonly type = TenantTenantActionTypes.SaveSuccess;

  constructor(public tenant: model.Tenant.Tenant) {
  }
}

export class SaveFail implements Action {
  readonly type = TenantTenantActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = TenantTenantActionTypes.LoadAll;

  constructor(public userId: number = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = TenantTenantActionTypes.LoadAllSuccess;

  constructor(public tenants: model.Tenant.Tenant[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = TenantTenantActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = TenantTenantActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = TenantTenantActionTypes.LoadOneSuccess;

  constructor(public tenant: model.Tenant.Tenant) {
  }
}

export class LoadOneFail implements Action {
  readonly type = TenantTenantActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type TenantTenantActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
