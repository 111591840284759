import { IAudit, IYear, IPartner, IAuditPerson, IAuditContact } from './audit.model';

export const YearSelectList: IYear[] = [
  { value: '2017', viewValue: '2017' },
  { value: '2018', viewValue: '2018' },
  { value: '2019', viewValue: '2019' },
];

export const partnerList: IPartner[]  = [
  {name: 'KPMG', value: 1},
  {name: 'Kurt GmbH', value: 2},
  {name: 'Test GmbH', value: 3},
];

export const auditorContactList: IAuditPerson[] = [
  {
    name: 'Baristan Selmy',
    value: 1
  },
  {
    name: 'Tirion Lanister',
    value: 2
  },
];

export const teamContactList: IAuditPerson[] = [
  {
    name: 'Eddard Stark',
    value: 1
  },
  {
    name: 'Keitelin Stark',
    value: 2
  },
];

export const clientContactList: IAuditPerson[] = [
  {
    name: 'Client 1',
    value: 1
  },
  {
    name: 'Client 2',
    value: 2
  },
];

export const auditorFromBookContactList: IAuditContact[] = [
  {
    id: 110,
    firstName: 'Melanie',
    lastName: 'Oberhauser',
    email: 'bufffjkld@gmail.com',
    phone: '802299667788',
    gender: 2,
    title: 1,
    licenseCheck: false
  },
  {
    id: 111,
    firstName: 'Michel',
    lastName: 'Jackson',
    email: '1ee1ww@test.com',
    phone: '80221111112',
    gender: 1,
    title: 2,
    licenseCheck: false
  },
  {
    id: 112,
    firstName: 'Tom',
    lastName: 'Cruise',
    email: 'ooodfjuvj@gmail.com',
    phone: '80226678934',
    gender: 1,
    title: 3,
    licenseCheck: false
  }
];


export const auditorFromBookContactTeamList: IAuditContact[] = [
  {
    id: 110,
    firstName: 'Max',
    lastName: 'Mustermann',
    email: '111222333@gmail.com',
    phone: '802299667788',
    gender: 2,
    title: 1,
    licenseCheck: false
  },
  {
    id: 112,
    firstName: 'Max2',
    lastName: 'Mustermann',
    email: 'oosdsdj@gmail.com',
    phone: '80229456768',
    gender: 1,
    title: 3,
    licenseCheck: false
  }
];

export const auditorFromBookContactClientList: IAuditContact[] = [
  {
    id: 177,
    firstName: 'Gianluigi',
    lastName: 'Buffon',
    email: 'buffon@gmail.com',
    phone: '802297572256',
    gender: 2,
    title: 1,
    licenseCheck: false
  },
];

export const RequestClientList: IAudit[] = [
  {
    id: 128,
    companyName: 'Adidas AG',
    requestDate: '2017-11-15T00:00:00',
    status: 'in Bearbeitung',
    statusId: 0,
    auditType: '',
    auditPartner: '',
    fiscalYearFrom: '',
    fiscalYearTo: '',
  },
  {
    id: 3582,
    companyName: 'Daimler AG',
    requestDate: '2018-10-12T00:00:00',
    status: 'in Bearbeitung',
    statusId: 0,
    auditType: '',
    auditPartner: '',
    fiscalYearFrom: '',
    fiscalYearTo: '',
  },
  {
    id: 3567,
    companyName: 'Muller AG',
    requestDate: '2017-10-11T00:00:00',
    status: 'in Bearbeitung',
    statusId: 0,
    auditType: '',
    auditPartner: '',
    fiscalYearFrom: '',
    fiscalYearTo: '',
  },
  {
    id: 12,
    companyName: 'Schaeffler Tehnologies AG & CO. KG',
    requestDate: '2019-07-31T00:00:00',
    status: 'in Bearbeitung',
    statusId: 0,
    auditType: '',
    auditPartner: '',
    fiscalYearFrom: '',
    fiscalYearTo: '',
  },
  {
    id: 3450,
    companyName: 'TEST1',
    requestDate: '2017-11-23T00:00:00',
    status: 'in Bearbeitung',
    statusId: 0,
    auditType: '',
    auditPartner: '',
    fiscalYearFrom: '',
    fiscalYearTo: '',
  },
  {
    id: 3451,
    companyName: 'TEST2',
    requestDate: '2017-11-23T00:00:00',
    status: 'in Bearbeitung',
    statusId: 0,
    auditType: '',
    auditPartner: '',
    fiscalYearFrom: '',
    fiscalYearTo: '',
  },
  {
    id: 3452,
    companyName: 'TEST3',
    requestDate: '2017-11-23T00:00:00',
    status: 'in Bearbeitung',
    statusId: 0,
    auditType: '',
    auditPartner: '',
    fiscalYearFrom: '',
    fiscalYearTo: '',
  },

]

export const RequestAuditList: IAudit[] = [
  {
    id: 8,
    companyName: 'Qwerrt',
    requestDate: '2017-06-27T00:00:00',
    status: 'in Bearbeitung',
    statusId: 0,
    auditType: '',
    auditPartner: '',
    fiscalYearFrom: '',
    fiscalYearTo: '',
  },
  {
    id: 1992,
    companyName: 'Devatax GmbH',
    requestDate: '2017-12-30T00:00:00',
    status: 'Abgeschlossen',
    statusId: 1,
    auditType: '',
    auditPartner: '',
    fiscalYearFrom: '',
    fiscalYearTo: '',
  },
  {
    id: 2103,
    companyName: 'Test1',
    requestDate: '2019-10-20T00:00:00',
    status: 'Abgeschlossen',
    statusId: 1,
    auditType: '',
    auditPartner: '',
    fiscalYearFrom: '',
    fiscalYearTo: '',
  },
  {
    id: 4331,
    companyName: 'Test2',
    requestDate: '2019-01-12T00:00:00',
    status: 'Abgeschlossen',
    statusId: 1,
    auditType: '',
    auditPartner: '',
    fiscalYearFrom: '',
    fiscalYearTo: '',
  },
  {
    id: 2237,
    companyName: 'Schuster AG',
    requestDate: '2017-11-21T00:00:00',
    status: 'in Bearbeitung',
    statusId: 0,
    auditType: '',
    auditPartner: '',
    fiscalYearFrom: '',
    fiscalYearTo: '',
  },
  {
    id: 305,
    companyName: 'Schuster',
    requestDate: '2018-05-11T00:00:00',
    status: 'Abgeschlossen',
    statusId: 1,
    auditType: '',
    auditPartner: '',
    fiscalYearFrom: '',
    fiscalYearTo: '',
  },
];
