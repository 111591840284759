import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {AuditContracteeEffects} from './audit-contractee.effects';
import {AuditContracteeService} from './audit-contractee.service';
import {reducer} from './audit-contractee.reducer';
import {stateKey} from './audit-contractee.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([AuditContracteeEffects])
  ],
  providers: [
    AuditContracteeEffects,
    AuditContracteeService
  ]
})
export class AuditContracteeStoreModule {
}
