import {first} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  version: string;
  private versionChangeNotify$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public versionChangeNotify = this.versionChangeNotify$.asObservable();

  constructor(private http: HttpClient) {
  }

  /**
   * Checks in every set frequency the version of frontend application.
   *
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url, frequency = 1000 * 60 * 15) {
    setInterval(() => {
      this.checkVersionNotification(url);
    }, frequency);
  }

  /**
   * Calls version.json at the server to get the latest version and hash.
   * @param url
   */
  private checkVersionNotification(url) {
    // timestamp these requests to invalidate caches
    this.http.get(url + '/version.json?timestamp=' + new Date().getTime())
      .pipe(first())
      .subscribe((response: any) => {

          const hash = response.hash;
          const hashChanged = this.hasHashChanged(this.currentHash, hash);

          // If new version, do something
          if (hashChanged) {
            this.versionChangeNotify$.next(true);
          } else {
            this.versionChangeNotify$.next(false);
          }
          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          if (!this.versionChangeNotify$.value) {
            this.currentHash = hash;
            this.version = response.version;
          }
        },
        (err) => {
          console.error('checkVersionNotification failed ')
          console.error(err, 'Could not get version');
        }
      );
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  checkVersion(url) {
    // timestamp these requests to invalidate caches
    this.http.get(url + '/version.json?timestamp=' + new Date().getTime()).pipe(
      first())
      .subscribe(
        (response: any) => {
          const hash = response.hash;
          const hashChanged = this.hasHashChanged(this.currentHash, hash);

          // If new version, do something
          if (hashChanged) {
            // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
            location.reload();
          }
          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          this.currentHash = hash;
          this.version = response.version;
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash, newHash) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }

    return currentHash !== newHash;
  }
}
