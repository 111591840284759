<div>
  <mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let item of itemList; let i = index" (click)="removeItem(i)">
      {{ item.label + ' (Wert: ' + item.value + ')' }}
      <mat-icon>clear</mat-icon>
    </mat-list-item>
  </mat-list>

  <div class="button-container">
    <dvtx-text-input-cva
      (keyup)="keyUpOnInput($event)"
      [(ngModel)]="inputLabel"
      elementName="Neuer Eintrag"
    >
    </dvtx-text-input-cva>

    <dvtx-text-input-cva
      (keyup)="keyUpOnInput($event)"
      [(ngModel)]="inputValue"
      elementName="Wert"
    >
    </dvtx-text-input-cva>
    <mat-icon aria-label="add item" (click)="addItem()">add</mat-icon>
  </div>

</div>
