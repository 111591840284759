import {DavContactType, IDavContact} from './dav-contact.interface';

export class DavContact implements IDavContact {
  readonly type = 'dav_contacts';
  invite = false;
  contactType = DavContactType.Person;
  private = false;
  imported = false;

  constructor(public id: string,
              public email: string,
              public name: string,
              public firstName: string,
              public lastName: string) {}
}
