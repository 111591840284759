import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {I18nService} from 'app/shared/modules/api/services/i18n.service';
import {AppState} from 'app/reducers/index';
import {Store} from '@ngrx/store';
import {User} from 'app/models/user';
import {Subject} from 'rxjs/internal/Subject';
import * as store from 'store';
import {I18n} from 'app/lib/i18n/constants';
import {distinctUntilKeyChanged, filter, first, takeUntil} from 'rxjs/operators';

/**
 * Locale switcher component providing available locales
 * currently 'de', 'en' as links.
 * On change the selected locale is saved by the I18nService
 * inside the user account _and_ (*new*) inside the local storage
 * in the variable 'lang'.
 */
@Component({
  selector: 'dvtx-locale-switcher',
  templateUrl: './locale-switcher.component.html',
  styleUrls: ['./locale-switcher.component.scss']
})
export class LocaleSwitcherComponent implements OnInit, OnDestroy {
  locales$;
  isSignedIn = false;
  private onDestroy: Subject<void> = new Subject<void>();

  /** local storage is available: If not the lang variable can only be set by the user account API */
  protected storeSupported = false;

  readonly VALID_LANGUAGES = I18n.SUPPORTED_LANGUAGES;
  readonly DEFAULT_LANG = I18n.DEFAULT_LANG;
  readonly LANG_KEY = I18n.LANG_KEY;
  selectedLang: any;

  /**
   * Shows flags or text only at application.
   * Flags are used inside the 3rd party external view at the header without
   * bottom border effect (see input showUnderlineOnHover below).
   */
  @Input()
  public showFlag = false;

  /**
   * Shows or hides the bottom line hover effect if locale-switcher is used inline
   * (class nav-item on li).
   */
  @Input()
  public showUnderlineOnHover = true;

  constructor(private _store: Store<AppState>,
              private _translate: TranslateService,
              private _i18nSvc: I18nService) {
    this.locales$ = this._i18nSvc.getAvailableLocales();
  }

  ngOnInit() {
    // Select the language if found in local storage.
    this._initLangByBrowser();
    this._handleUserAccountLocale();
  }

  /**
   * Sets the language by ngx-translate and persists the language in
   * local storage and the user account.
   *
   * @param locale - valid params: 'de', 'en'
   */
  selectLanguage(locale: string) {
    this._translate.use(locale);
    try {
      if (this.storeSupported) {
        store.set(this.LANG_KEY, locale);
        this.selectedLang = locale;
      }
    } catch (err) {
      console.error('Persisting the language in the local storage failed: ', err);
    }

    // Persist the language per API in the user settings/account
    if (this.isSignedIn) {
      this._updateServerLocale(locale);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * Fetches the language first by store, if valid it returns (case former session).
   * If invalid it retries by browser language.
   * @private
   */
  private _initLangByBrowser() {
    // Lang already exists by local store?
    this.selectedLang = store.get(this.LANG_KEY);

    const valid = this.selectedLang && this.VALID_LANGUAGES.includes(this.selectedLang);
    if (valid) {
      this._translate.use(this.selectedLang);
      this.storeSupported = true;
      return;
    }

    // No language setup.
    // Try to get it by browser language.
    let browserLanguage = this.DEFAULT_LANG;
    try {
      browserLanguage = this._translate.getBrowserLang().split('-')[0];
    } catch (e) {
      console.error('Retrieving the browser language failed', e);
    }

    console.log( ' * Using browser language', browserLanguage);

    // If language is supported use it, otherwise use default language.
    const validLanguageCode = this.VALID_LANGUAGES.includes(browserLanguage);
    this.selectedLang = validLanguageCode ? browserLanguage : this.DEFAULT_LANG;
    this._translate.use(this.selectedLang);

    // Replay current language into the store.
    try {
      store.set(this.LANG_KEY, this.selectedLang);
      const langByStoreTest = store.get(this.LANG_KEY);
      this.storeSupported = this.selectedLang === langByStoreTest;
    } catch (err) {
      this.storeSupported = false;
      console.error(err);
    }
  }

  // Fallback to server controlled lang management.
  // Also updates the server lang if there is a mismatch between the
  // lang stored in local storage and user account.
  private _handleUserAccountLocale() {
    this._store.select('currentUser').pipe(filter(u => !!u), distinctUntilKeyChanged('uid'), takeUntil(this.onDestroy))
      .subscribe((user: User) => {
        this.isSignedIn = !!user
        if (user) {
          this.isSignedIn = true;
          this._i18nSvc.getUserLocale().pipe(first()).subscribe(locale => {
            if (locale && locale.code) {
              if (this.storeSupported) {
                // Local-server language mismatch: Prefer local, update server.
                const local = store.get(this.LANG_KEY);
                if (locale && locale.code && local && local !== locale.code) {
                  this._updateServerLocale(local);
                }
              } else {
                // Fallback: no store support. Always use server data.
                this.selectLanguage(locale.code);
              }
            }
          }, err => {
            this.selectLanguage('de');
            console.error('Unable to fetch user lang', err)
          });
        }
      });
  }

  // Persists the lang/locale at the server.
  private _updateServerLocale(locale) {
    if (!this.isSignedIn) {
      console.error('Update of lang/locale failed: User not signed in.');
      return;
    }
    this._i18nSvc.setUserLocale(locale).pipe(first()).subscribe(() => {
    }, error => console.error(error));
  }
}
