import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DmsContactService} from './dms-contact.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    DmsContactService
  ]
})
export class DmsContactStoreModule {
}
