import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessToCalendarComponent } from './component/process-to-calendar.component';
import { SharedModule } from 'app/shared/shared.module';
import { TooltipModule } from 'app/five-f/tooltip/tooltip.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        TooltipModule
    ],
    declarations: [
        ProcessToCalendarComponent
    ],
    providers: [],
    exports: [ProcessToCalendarComponent]
})
export class ProcessToCalendarModule {
}
