import {OperatorMemberActions, OperatorMemberActionTypes} from './member.actions';
import {adapter, initialState, State} from './member.state';

export function reducer(state = initialState, action: OperatorMemberActions): State {
  const current = state.selected;
  switch (action.type) {
    case OperatorMemberActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case OperatorMemberActionTypes.Create:
    case OperatorMemberActionTypes.LoadOne:
    case OperatorMemberActionTypes.Remove:
    case OperatorMemberActionTypes.Save:
      return {
        ...state,
        loading: true
      };
    case OperatorMemberActionTypes.LoadOneSuccess:
      return adapter.addOne(action.member, {
        ...state,
        selected: action.member,
        loading: false,
      });
    case OperatorMemberActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.members, {
        ...state,
        selected: null,
        loading: false,
      });
    case OperatorMemberActionTypes.CreateSuccess:
    case OperatorMemberActionTypes.SaveSuccess:
      return adapter.upsertOne(action.member, {
        ...state,
        selected: action.member,
        loading: false,
      });
    case OperatorMemberActionTypes.CreateFail:
    case OperatorMemberActionTypes.LoadAllFail:
    case OperatorMemberActionTypes.LoadOneFail:
    case OperatorMemberActionTypes.RemoveFail:
    case OperatorMemberActionTypes.SaveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
    case OperatorMemberActionTypes.RemoveSuccess:
      return adapter.removeOne(action.member.id, {
        ...state,
        selected: (current && current.id === action.member.id) ? null : current,
        loading: false,
      });
      case OperatorMemberActionTypes.Reset:
        return adapter.removeAll(state);
  }
  return state;
}
