import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {QuickshareRecipient} from './quickshare-recipient';

export class QuickshareRecipientBuilder implements IApiResourceBuilder<QuickshareRecipient> {
  constructor(public quickshareId: string) {}
  
  fromResponse(data): QuickshareRecipient {
    return new QuickshareRecipient(
      data.id,
      null,
      data.attributes.email,
      this.quickshareId,
      data.attributes.notify_by_sms,
      data.attributes.phone_number,
      data.attributes.password_enabled,
      data.attributes.created_at,
      data.attributes.updated_at);
  }

  toRequest(recipient: QuickshareRecipient) {
    return {
      data: {
        id: recipient.id,
        type: recipient.recipientType,
        attributes: {
          email: recipient.email,
          notify_by_sms: recipient.notifyBySms,
          password_enabled: recipient.passwordEnabled,
          phone_number: recipient.phoneNumber
        }
      }
    };
  }
}
