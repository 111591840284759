import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {ProcessFolder} from './process-folder';

export enum ProcessFolderActionTypes {
  Load = '[ProcessFolder] Load One Process Folder',
  LoadSuccess = '[ProcessFolder] Load One Process Folder Success',
  LoadFail = '[ProcessFolder] Load One Process Folder Fail',
  Remove = '[ProcessFolder] Remove Process Folder',
}

export class Load implements Action {
  readonly type = ProcessFolderActionTypes.Load;

  constructor(public id: string, public preventPrivateDmsResult = false) {
  }
}

export class LoadSuccess implements Action {
  readonly type = ProcessFolderActionTypes.LoadSuccess;

  constructor(public folder: ProcessFolder) {
  }
}

export class LoadFail implements Action {
  readonly type = ProcessFolderActionTypes.LoadFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = ProcessFolderActionTypes.Remove;

  constructor(public id: string) {
  }
}

export type ProcessFolderActions =
  | Load
  | LoadSuccess
  | LoadFail
  | Remove;
