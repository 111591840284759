import {Action} from '@ngrx/store';
import {ProcessParticipant} from './process-participant';

export enum ProcessParticipantActionTypes {
  LoadAll = '[ProcessParticipant] Load All Participants',
  LoadAllConcat = '[ProcessParticipant] Load All Concat Participants',
  LoadAllSuccess = '[ProcessParticipant] Load All Participants Success',
  LoadAllFail = '[ProcessParticipant] Load All Participants Fail',
  LoadAllRefresh = '[ProcessParticipant] Load All Refresh Participants',
  LoadAllRefreshSuccess = '[ProcessParticipant] Load All Refresh Participants Success',
  LoadAllRefreshFail = '[ProcessParticipant] Load All Refresh Participants Fail',
  Create = '[ProcessParticipant] Create Participant',
  CreateSuccess = '[ProcessParticipant] Create Participant Success',
  CreateFail = '[ProcessParticipant] Create Participant Fail',
  Delete = '[ProcessParticipant] Delete Participant',
  DeleteSuccess = '[ProcessParticipant] Delete Participant Success',
  DeleteFail = '[ProcessParticipant] Delete Participant Fail',
  DeleteExternal = '[ProcessParticipant] Delete External Participant',
  DeleteExternalSuccess = '[ProcessParticipant] Delete External Participant Success',
  DeleteExternalFail = '[ProcessParticipant] Delete External Participant Fail',
  Reset = '[ProcessParticipant] Reset',
}

export class Reset implements Action {
  readonly type = ProcessParticipantActionTypes.Reset;

  constructor(public payload: any = null) {
  }
}

export class LoadAll implements Action {
  readonly type = ProcessParticipantActionTypes.LoadAll;

  constructor(public id: string, public recursive = false) {
  }
}

export class LoadAllConcat implements Action {
  readonly type = ProcessParticipantActionTypes.LoadAllConcat;

  constructor(public id: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ProcessParticipantActionTypes.LoadAllSuccess;

  constructor(public participants: ProcessParticipant[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ProcessParticipantActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadAllRefresh implements Action {
  readonly type = ProcessParticipantActionTypes.LoadAllRefresh;

  constructor(public id: string) {
  }
}

export class LoadAllRefreshSuccess implements Action {
  readonly type = ProcessParticipantActionTypes.LoadAllRefreshSuccess;

  constructor(public participants: ProcessParticipant[]) {
  }
}

export class LoadAllRefreshFail implements Action {
  readonly type = ProcessParticipantActionTypes.LoadAllRefreshFail;

  constructor(public payload: any) {
  }
}

export class Create implements Action {
  readonly type = ProcessParticipantActionTypes.Create;

  constructor(public id: string, public participant: ProcessParticipant, public admin, public recursive, public successMessage = '', public failMessage = '') {
  }
}

export class CreateSuccess implements Action {
  readonly type = ProcessParticipantActionTypes.CreateSuccess;

  constructor(public participant: ProcessParticipant) {
  }
}

export class CreateFail implements Action {
  readonly type = ProcessParticipantActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Delete implements Action {
  readonly type = ProcessParticipantActionTypes.Delete;

  constructor(public participant: ProcessParticipant) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = ProcessParticipantActionTypes.DeleteSuccess;

  constructor(public participant: ProcessParticipant) {
  }
}

export class DeleteFail implements Action {
  readonly type = ProcessParticipantActionTypes.DeleteFail;

  constructor(public payload: any) {
  }
}

export class DeleteExternal implements Action {
  readonly type = ProcessParticipantActionTypes.DeleteExternal;

  constructor(public participant: ProcessParticipant) {
  }
}

export class DeleteExternalSuccess implements Action {
  readonly type = ProcessParticipantActionTypes.DeleteExternalSuccess;

  constructor(public participant: ProcessParticipant) {
  }
}

export class DeleteExternalFail implements Action {
  readonly type = ProcessParticipantActionTypes.DeleteExternalFail;

  constructor(public payload: any) {
  }
}

export type ProcessParticipantActions =
  | LoadAll
  | LoadAllConcat
  | LoadAllSuccess
  | LoadAllFail
  | LoadAllRefresh
  | LoadAllRefreshSuccess
  | LoadAllRefreshFail
  | Create
  | CreateSuccess
  | CreateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | DeleteExternal
  | DeleteExternalSuccess
  | DeleteExternalFail
  | Reset;
