import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { CollectorCategory } from './collector-category';

export interface State extends EntityState<CollectorCategory> {
  selected?: CollectorCategory;
  loading: boolean;
  creating: boolean;
  updating: boolean;
  removing: boolean;
}

export const adapter = createEntityAdapter<CollectorCategory>({
  sortComparer: (l, r) => ((l.order < r.order) ? -1 : 1)
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
  creating: false,
  updating: false,
  removing: false
});
