import {TaskAssigneeActions, TaskAssigneeActionTypes} from './task-assignee.actions';
import {adapter, initialState, State} from './task-assignee.state';

export function reducer(state = initialState, action: TaskAssigneeActions): State {
  const current = state.selected;
  switch (action.type) {
    case TaskAssigneeActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case TaskAssigneeActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });

    case TaskAssigneeActionTypes.LoadAssignment:
      return {
        ...state,
        loading: true
      };
    case TaskAssigneeActionTypes.LoadAssignmentSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });

    case TaskAssigneeActionTypes.AcceptAssignment:
      return {
        ...state,
        loading: true
      };
    case TaskAssigneeActionTypes.AcceptAssignmentSuccess:
      return adapter.upsertOne(action.assignee, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });

    case TaskAssigneeActionTypes.RejectAssignment:
      return {
        ...state,
        loading: true
      };
    case TaskAssigneeActionTypes.RejectAssignmentSuccess:
      return adapter.upsertOne(action.assignee, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });

    case TaskAssigneeActionTypes.AddAssignment:
      return {
        ...state,
        loading: true
      };
    case TaskAssigneeActionTypes.AddAssignmentSuccess:
      return adapter.upsertOne(action.assignee, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });


    case TaskAssigneeActionTypes.UpdateAssignment:
      return {
        ...state,
        loading: true
      };
    case TaskAssigneeActionTypes.UpdateAssignmentSuccess:
      return adapter.upsertOne(action.assignment, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });

    case TaskAssigneeActionTypes.Delete:
      return {
        ...state,
        loading: true,
      };

    case TaskAssigneeActionTypes.DeleteSuccess:
    return adapter.removeOne(action.assignee.id , {
      ...state,
      loading: false,
    });
  }
  return state;
}
