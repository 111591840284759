import {CollectorItemLookupActions, CollectorItemLookupActionTypes} from './collector-item-lookup.actions';
import {adapter, initialState, State} from './collector-item-lookup.state';

/**
 * Reducer for Collecto comment reference fetching for the project room's activity protocol.
 * @param state
 * @param action
 */
export function reducer(state = initialState, action: CollectorItemLookupActions): State {
  switch (action.type) {
    case CollectorItemLookupActionTypes.FetchOne:
      // Mark item with itemId as fetching to skip further fetch requests.
      const newFetchStatus = Object.assign({}, state.fetchStatus);
      newFetchStatus[action.itemId] = true;
      return {
        ...state,
        fetchStatus: newFetchStatus
      };
    case CollectorItemLookupActionTypes.LoadOneSuccess:
      // Load fetched item into cache:
      return adapter.upsertOne(action.item, {
        ...state
      });
    case CollectorItemLookupActionTypes.ResetOne:
      // Mark item with itemId as fetching to skip further fetch requests.
      const oldFetchStatus = Object.assign({}, state.fetchStatus);
      oldFetchStatus[action.itemId] = null;
      return {
        ...state,
        fetchStatus: oldFetchStatus
      };
    case CollectorItemLookupActionTypes.ResetAll:
      return {
        ...state,
        fetchStatus: {}
      };
  }
  return state;
}
