<dvtx-dropdown-content
  [xPosition]="'before'"
  [icon]="containerContentTogglerIconPortal"
  [content]="containerContentPortal"
  [tooltip]="'GENERAL.FILTERS' | translate"
  [buttonType]="showTitle && !labelPositionRight ? 'iconTextButton' : 'iconButton'"
  [iconButtonColor]="'gray'"
  [backdropClassesList]="'filters-dropdown-menu'"
  [borderColor]="'no-border'"
  #containerDropdownMenu>
</dvtx-dropdown-content>

<ng-template cdkPortal #containerContentTogglerIcon>
  <span
    class="d-inline-flex align-items-center justify-items-center count-indicator"
    [class.zero]="selectedCount === 0"
    [matBadge]="selectedCount"
    matBadgeOverlap="false"
    [matTooltip]="title | translate"
    color="primary">
    <ng-container *ngIf="showTitle && !labelPositionRight">{{title | translate}}</ng-container>
    <mat-icon class="mx-1" svgIcon="filter_alt"></mat-icon>
  </span>
  <span class="pl-1 pr-3" *ngIf="showTitle && labelPositionRight">&nbsp;{{title | translate}}</span>
</ng-template>

<ng-template cdkPortal #containerContent>
  <ng-content></ng-content>
</ng-template>
