<ng-container [ngSwitch]="action">
  <ng-container *ngSwitchCase="PreviewViewType.Icon">
    <a (click)="$event.preventDefault(); openDialog()"
       href="#" class="ml-1" mat-icon-button color="gray"
       [matTooltip]="'PREVIEW_BROWSER.DIALOG_TITLE' | translate">
      <mat-icon svgIcon="file_preview" style="font-size: 18px;"></mat-icon>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="PreviewViewType.IconButton">
    <button mat-icon-button color="gray"
            [matTooltip]="'PREVIEW_BROWSER.DIALOG_TITLE' | translate"
            (click)="openDialog()">
      <mat-icon svgIcon="file_preview"></mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="PreviewViewType.ListItem">
    <li class="d-flex align-items-center p-2 dvtx-pointer"
        [matTooltip]="'PREVIEW_BROWSER.DIALOG_TITLE' | translate"
        (click)="openDialog()">
      <mat-icon svgIcon="file_preview" class="mr-2"></mat-icon>
      {{ 'PREVIEW_BROWSER.DIALOG_TITLE' | translate }}
    </li>
  </ng-container>

  <ng-container *ngSwitchCase="PreviewViewType.Dialog">
    <div class="mat-dialog-dynamic-width">
      <div mat-dialog-content>
        <ng-template [cdkPortalOutlet]="contextOutlet"></ng-template>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="PreviewViewType.Custom">
    <div class="mat-dialog-dynamic-width" (click)="openDialog()">
      <ng-content></ng-content>
    </div>
  </ng-container>
</ng-container>

<ng-template cdkPortal #title>
  <h2 mat-dialog-title>{{ 'DOCUMENTS.DELETE_SELECTED' | translate }}</h2>
  <button class="mat-dialog-close" mat-mini-fab color="warn" (click)="closeDialog()">
    <mat-icon aria-label="close dialog">clear</mat-icon>
  </button>
</ng-template>

<ng-template cdkPortal #context>
  <dvtx-preview-browser
    class="w-100"
    [lockedProcess]="lockedProcess"
    [processId]="processId"
    [viewMode]="viewMode"
    [startOpen]="true"
    [documentId]="documentId"
    [documentIds]="documentIds"
    (onCreateTask)="onCreateTask.emit($event)"
    (onClose)="closeDialog($event)"
    [enableTaskCreation]="enableTaskCreation"
    [isMember]="isMember$ | async"
    (updateDialogueScreen)="adjustFullScreen($event)"
    [selectedDocumentPreviewTab]="selectedDocumentPreviewTab"
  ></dvtx-preview-browser>
</ng-template>
