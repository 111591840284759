<dvtx-user-profile-settings-header [routes]="routes"
                                   [activeLink]="activeLink"
                                   [title]="'USER_NAVIGATON.SECURITY_SETTINGS'"
                                   [subtitle]="'AUTH.AUTO_LOGOUT'">
</dvtx-user-profile-settings-header>

<div class="five-f-settings-container">
  <h2>{{'AUTH.AUTO_LOGOUT' | translate}}</h2>

  <div class="col-8 p-0">
    <dvtx-auto-logout-timer [user]="currentUser$ | async"></dvtx-auto-logout-timer>
  </div>
</div>
