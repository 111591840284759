import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './organization-logo.state';
import {OrganizationSelectors} from 'app/+store/organization';

export const stateKey = 'organizationLogo';
const getOrganizationLogoState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getOrganizationLogoEntities,
  selectAll: getAllOrganizationLogos,
} = adapter.getSelectors(getOrganizationLogoState);

export const getCurrentLogo = createSelector(
  getOrganizationLogoState,
  state => state.current
);

export const getAllLogosOfOrganization = createSelector(
  OrganizationSelectors.getSelectedId,
  getAllOrganizationLogos,
  (id, entities) => entities.filter(entity => entity.organizationId === id && !entity.inviterLogo)
)

// export const getCurrentLogo = createSelector(
//   getOrganizationLogoEntities,
//   getCurrentLogoId,
//   (entities, id) => entities[id]
// );

