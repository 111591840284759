import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './tenant.state';

export const stateKey = 'tenant-tenant';
const getTenantState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getTenantEntities,
  selectAll: getAllTenants,
} = adapter.getSelectors(getTenantState);

export const loadingState = createSelector(
  getTenantState,
  (_state) => _state.loading
);

export const getTenantById = (id: string) => createSelector(
  getTenantEntities,
  (tenants) => tenants[id]
);

export const getSelectedTenant = createSelector(
  getTenantState,
  (state) => state.selected
);
