import {Action} from '@ngrx/store';
import {ProcessParticipantAcl} from './process-participant-acl';

/**
 * Actions for the process permission view/table of the project room.
 * @deprecated This view will be replaced, so this service and API is deprecated.
 */
export enum ProcessParticipantAclActionTypes {
  LoadAll = '[ProcessParticipantAcl] LoadAll',
  LoadAllSuccess = '[ProcessParticipantAcl] LoadAll Success',
  LoadAllFail = '[ProcessParticipantAcl] LoadAll Fail',
  LoadOneSuccess = '[ProcessParticipantAcl] LoadOne Fail',
}

export class LoadAll implements Action {
  readonly type = ProcessParticipantAclActionTypes.LoadAll;

  constructor(public id: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ProcessParticipantAclActionTypes.LoadAllSuccess;

  constructor(public acls: ProcessParticipantAcl[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ProcessParticipantAclActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = ProcessParticipantAclActionTypes.LoadOneSuccess;

  constructor(public participant: ProcessParticipantAcl) {
  }
}

export type ProcessParticipantAclActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOneSuccess;
