import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {SlimFolder} from './slim-folder';
import {DMS_ACCOUNT_TYPE_MAP} from '../dms-folder/dms-folder';
import {DmsAccountType} from './slim-folder.interface';

export class SlimFolderBuilder implements IApiResourceBuilder<SlimFolder> {
  private sourceId: string;
  private dmsAccountType: DmsAccountType;

  constructor() {
  }

  fromResponse(data): SlimFolder {
    const folder = new SlimFolder(
      data.id,
      data.attributes.name,
      data.attributes.source_id,
      data.attributes.parent_id,
      DMS_ACCOUNT_TYPE_MAP[data.attributes.dms_account_type],
      data.attributes.folder_count,
      data.attributes.process_count,
      data.attributes.document_count,
      data.attributes.bookman_cockpit_enabled,
      data.attributes.deletable,
      data.attributes.editable
    );

    // API Change: Source ID and DMS account type are now send by meta section
    // to reduce bandwidth (both values are the same for each element).
    // On 5200 folder this reduces the transferred data from 2.8MB to 2.3MB.
    if (this.sourceId) {
      folder.sourceId = this.sourceId;
      folder.dmsAccountType = this.dmsAccountType;
    }

    return folder;
  }

  toRequest(_: SlimFolder) {
    return null;
  }

  addMetaSection(meta: any) {
    if (!meta) return;
    this.sourceId = meta.source_id;
    this.dmsAccountType = DMS_ACCOUNT_TYPE_MAP[meta.dms_account_type];
  }

  static generateFolderTreeFrom(folders: SlimFolder[]): SlimFolder[] {
    const nest = (items, id = null, link = 'parentId') =>
      items
        .filter(item => item[link] === id)
        .map(item => {
          const folder = item;
          folder.children = nest(items, item.id);
          return folder;
        });
    return nest(folders);
  }
}
