
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {VerifiedUser, OptIn} from './verified-user';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class VerifiedUserService {

  readonly BASE_PATH = 'api/v1/users/verifications';
  basePath;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  getAll(): Observable<VerifiedUser[]> {
    return this._http
      .get(this.basePath).pipe(
      map((response: { data: any }) => response.data.map((res) => new VerifiedUser(res.id, res.attributes.avatar, res.attributes.registered_account, res.attributes.two_factor))))
  }

  getOptIns(processIds): Observable<OptIn[]> {
    const payload = {
      data: {
        attributes: {
          ids: processIds
        }
      }
    }
    return this._http
      .post(`${environment.token_service_config.apiBase}/api/v1/users/opt_ins/batch`, payload).pipe(
      map((response: { data: any }) => response.data.map((res) => new OptIn(res.id, res.attributes.process_id, res.attributes.email, res.attributes.name, res.attributes.confirmed_at))))
  }
}
