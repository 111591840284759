import {
  AfterViewInit, ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {SplitViewDialogComponent} from 'app/shared/modules/page-container/split-view-dialog/split-view-dialog.component';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Subject} from 'rxjs';
import {AngularTokenService} from 'angular-token';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {Net} from 'app/lib/net/uuid';
import {QuickCollectorService} from 'app/+store/quickcollector/quickcollector.service';
import {first} from 'rxjs/operators';
import {QuickCollectorActions} from 'app/+store/quickcollector';
import {QuickshareService} from 'app/+store/quickshare/quickshare.service';
import {Router} from '@angular/router';
import {QuickshareActions} from 'app/+store/quickshare';
import {Observable} from 'rxjs';
import {Feature} from 'app/+store/feature/feature';
import {FeatureSelectors} from 'app/+store/feature';
import {
  QES_FEATURE_TOGGLE,
  SETTINGS_ID__QES_SIGN_ME,
  SETTINGS_ID__QES_SIGN_ME_DTRUST,
  SignatureAccount
} from 'app/+store/document-signature/document-signature';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {DocumentSignatureService} from 'app/+store/document-signature/document-signature.service';

export enum FpSignFormMode {
  Login = 'Login',
  Register = 'Register'
}

export enum FpSignAccountStatus {
  Undefined = 'Undefined',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  Unauthorized = 'Unauthorized',
  Failure = 'Failure'
}

export enum DocumentSignatureUserAccountDialogMenuViewType {
  Embedded = 'Embedded',
  Button = 'Button',
  Custom = 'Custom',
  MenuItem = 'MenuItem',
  IconButton = 'IconButton',
}

@Component({
  selector: 'dvtx-document-signature-user-account-dialog',
  templateUrl: './document-signature-user-account-dialog.component.html',
  styleUrls: ['./document-signature-user-account-dialog.component.scss']
})
export class DocumentSignatureUserAccountDialogComponent implements AfterViewInit, OnDestroy, OnInit {
  private onDestroy = new Subject();

  public readonly QES_FEATURE_TOGGLE = QES_FEATURE_TOGGLE;
  DocumentSignatureUserAccountDialogMenuViewType = DocumentSignatureUserAccountDialogMenuViewType;

  @ViewChild('buttonsToolbar', { static: true }) buttonsToolbar: TemplateRef<any>;
  @ViewChild('context', { static: true }) context: TemplateRef<any>;
  buttonsToolbarPortal: Portal<any>;
  contextPortal: Portal<any>;
  dialogRef: MatDialogRef<SplitViewDialogComponent>;

  @Input() menuItemButtonText = 'SIGNATURE.START_WORKFLOW_DIALOG.CREATE_SIGNATURE';
  @Input() menuItemButtonDisabled = false;

  @Output() onAccountLinked = new EventEmitter<FpSignAccountStatus>();

  @Input() action = null;

  _processId;
  @Input() set processId(id: string) {
    this._processId = id;
    if (Net.validUUID(id)) {
    }
  }

  get processId(): string {
    return this._processId;
  }

  @Input() disabled = false;

  featureSet$: Observable<Feature>;

  FpSignAccountStatus = FpSignAccountStatus;

  sendOngoing = false;

  public accountStatus = FpSignAccountStatus.Undefined;
  public FpSignFormMode = FpSignFormMode;
  public formMode = FpSignFormMode.Login;

  form: UntypedFormGroup;
  account$ = new BehaviorSubject<SignatureAccount>(null);
  registerForm: UntypedFormGroup;
  registerError = null;
  accountCreated = false;
  credentialError = false;
  qesAvailable = false;
  qesSettingsLoading = true;
  textEnabled = true

  @Input() showClose = false;

  constructor(private _dialog: MatDialog,
              private _store: Store<AppState>,
              private _viewContainerRef: ViewContainerRef,
              private _collectorSvc: QuickCollectorService,
              private _quickshareSvc: QuickshareService,
              private _router: Router,
              private _tokenSvc: AngularTokenService,
              private avatarService: AvatarService,
              private _signSvc: DocumentSignatureService,
              private _fb: UntypedFormBuilder,
              private _cdr: ChangeDetectorRef) {
    this.form = this._fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.registerForm = this._fb.group({
      username: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
    this.fetchAccountInfo();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this._initContextPortal();
    });
  }

  private _initContextPortal() {
    if (this.contextPortal) {
      return;
    }
    this.contextPortal = new TemplatePortal(
      this.context,
      this._viewContainerRef
    );
  }

  openWorkflowDialog() {
    this._initContextPortal();

    this.buttonsToolbarPortal = new TemplatePortal(
      this.buttonsToolbar,
      this._viewContainerRef
    );

    this.dialogRef = this._dialog.open(SplitViewDialogComponent, {
      data: {
        color: '#007aff',
        icon: null,
        context: this.contextPortal,
        buttonsToolbar: this.buttonsToolbarPortal
      },
      width: '400px'
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  fetchAccountInfo() {
    this.accountCreated = false;
    this.credentialError = false;
    this.qesSettingsLoading = true;
    this._signSvc.getMyAccount().pipe(first()).subscribe((account: SignatureAccount) => {
      this.account$.next(account);
      this.accountStatus = FpSignAccountStatus.Connected;

      this._signSvc.getMySignatureSettings().pipe(first()).subscribe(settings => {
        this.qesAvailable = !!settings.find(s => s.id === SETTINGS_ID__QES_SIGN_ME || s.id === SETTINGS_ID__QES_SIGN_ME_DTRUST);
        this.qesSettingsLoading = false;
        this._cdr.detectChanges();
      }, err => {
        this.qesSettingsLoading = true;
      })

      this.form.patchValue({
        username: account.id,
        password: null
      });
      this.accountStatus = FpSignAccountStatus.Connected;
      this.onAccountLinked.emit(FpSignAccountStatus.Connected);

      this.form.markAsPristine();
      this.sendOngoing = false;
      this.credentialError = false;
    }, err => {
      this.account$.next(null);
      if (err.status && err.status === 404) {
        this.accountStatus = FpSignAccountStatus.Disconnected;
      } else if (err.status && err.status === 401) {
        this.accountStatus = FpSignAccountStatus.Unauthorized;
      } else {
        this.accountStatus = FpSignAccountStatus.Failure;
      }
      this.sendOngoing = false;
    });
  }

  unlinkAccount() {
    this._signSvc.unlinkAccount()
      .pipe(first())
      .subscribe(_acc => {
        this.accountStatus = FpSignAccountStatus.Undefined;
        this.fetchAccountInfo();
      }, err => {
        console.error(err);
        this.accountStatus = FpSignAccountStatus.Undefined;
        this.fetchAccountInfo();
      });
  }

  update() {
    this.credentialError = false;
    if (this.formMode === FpSignFormMode.Login) {
      this.updateAccount();
    }

    if (this.formMode === FpSignFormMode.Register) {
      this.registerAccount()
    }
  }

  updateAccount() {
    if (this.form.invalid) return;
    this.accountCreated = false;

    this.sendOngoing = true;
    const value = this.form.value;
    this._signSvc.updateAccount(value.username, value.password)
      .pipe(first())
      .subscribe(_acc => {
        this.accountStatus = FpSignAccountStatus.Undefined;
        this.fetchAccountInfo();
      }, err => {
        console.error(err);
        this.form.patchValue({
          password: null
        });
        if (err.status && err.status === 404) {
          this.accountStatus = FpSignAccountStatus.Disconnected;
        } else if (err.status && err.status === 401) {
          this.accountStatus = FpSignAccountStatus.Unauthorized;
          this.credentialError = true;
        } else {
          this.accountStatus = FpSignAccountStatus.Failure;
        }
        this.sendOngoing = false;
      });
  }

  registerAccount() {
    if (this.registerForm.invalid) return;

    this.accountCreated = false;
    this.sendOngoing = true;
    const value = this.registerForm.value;
    this._signSvc.registerAccount(value.username)
      .pipe(first())
      .subscribe(_acc => {
        this.registerError = null;
        this.accountCreated = true;
        this.sendOngoing = false;
        this.formMode = FpSignFormMode.Login;
      }, err => {
        console.error(err);
        this.registerError = err;
        this.sendOngoing = false;
        // 0151 628 26 735
      });
  }


  toggleFormMode() {
    this.credentialError = false;
    this.accountCreated = false;
    if (this.formMode === FpSignFormMode.Login) {
      this.registerError = null;
      this.formMode = FpSignFormMode.Register;
    } else {
      this.formMode = FpSignFormMode.Login;
    }
    this._cdr.detectChanges();
  }

  backToSelection() {
    this.accountCreated = false;
    this.credentialError = false;
    this.accountStatus = FpSignAccountStatus.Disconnected;
    this.formMode = FpSignFormMode.Login;
    this._cdr.detectChanges();
  }
}
