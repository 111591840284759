import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {OrganizationSelectors} from 'app/+store';
import {first} from 'rxjs/operators';
import {SlimFolderActions, SlimFolderSelectors} from 'app/+store/slim-folder';
import {SlimFolder} from 'app/+store/slim-folder/slim-folder';
import {SlimFolderService} from 'app/+store/slim-folder/slim-folder.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs/internal/Observable';

@Component({
  selector: 'dvtx-folder-selection-dialog',
  templateUrl: './folder-selection-dialog.component.html',
  styleUrls: ['./folder-selection-dialog.component.scss'],
})
export class FolderSelectionDialogComponent implements OnInit, AfterViewInit {

  public selectedFolder: SlimFolder;
  public selectedId: string;
  private onFolderSelection: Function;
  public folders$: Observable<SlimFolder[]>;
  public actionsFormEnabled = false;
  public folderAction: 'create' | 'edit' | 'delete';
  public folderNameField: string = '';

  constructor(private dialogRef: MatDialogRef<FolderSelectionDialogComponent>,
              private _store: Store<AppState>,
              private _slimFolderSvc: SlimFolderService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _cdr: ChangeDetectorRef) {
    if (data) {
      this.selectedId = data.selectedId;
      this.onFolderSelection = data.onFolderSelect;
    }
  }

  ngOnInit(): void {
    this._store.select(OrganizationSelectors.getSelectedId).pipe(first()).subscribe(orgId => {
      if (orgId) {
        this.folders$ = this._store.select(SlimFolderSelectors.getOrganizationFoldersTree(orgId))
      }
    })
  }

  ngAfterViewInit(): void {
  }

  /**
   * Select folder and highlight it.
   *
   * Implementation note:
   * This method can be called outside Angular context (CDR must be run).
   *
   * @param $event
   */
  public folderSelect($event) {
    this.selectedFolder = $event;
    // Run CDR or MaterialChangedAfterCheckedError is thrown in development.
    this._cdr.detectChanges();
  }

  select() {
    this.onFolderSelection(this.selectedFolder);
    this.dialogRef.close();
  }

  dblClickSelection(_event: boolean) {
    this.select();
  }

  createFolder() {
    this._slimFolderSvc.create(this.folderNameField, this.selectedFolder.id, this.selectedFolder.dmsAccountType)
      .pipe(first())
      .subscribe(folder => {
        this._store.dispatch(new SlimFolderActions.CreateSuccess(folder));
        this.selectedFolder = folder;
        this.selectedId = folder.id;
        this.cancelAction();
      })
  }

  editFolder() {
    this._slimFolderSvc.rename(this.selectedFolder.id, this.folderNameField, this.selectedFolder.dmsAccountType)
      .pipe(first())
      .subscribe(folder => {
        this._store.dispatch(new SlimFolderActions.RenameSuccess(folder));
        this.selectedFolder = folder;
        this.selectedId = folder.id;
        this.cancelAction();
      })
  }

  deleteFolder() {
    this._slimFolderSvc.delete(this.selectedFolder.id, this.selectedFolder.dmsAccountType)
      .pipe(first())
      .subscribe(folder => {
        this._store.dispatch(new SlimFolderActions.DeleteSuccess(folder));
        this.selectedFolder = null;
        this.selectedId = null;
        this.cancelAction();
      })
  }

  openCreateFolder() {
    this.actionsFormEnabled = true;
    this.folderAction = 'create';
  }

  openEditFolder() {
    this.folderNameField = this.selectedFolder.name;
    this.actionsFormEnabled = true;
    this.folderAction = 'edit';
  }

  openDeleteFolder() {
    this.actionsFormEnabled = true;
    this.folderAction = 'delete';
  }

  cancelAction() {
    this.folderNameField = null;
    this.actionsFormEnabled = false;
    this.folderAction = null;
  }
}
