
import {of as observableOf, combineLatest as observableCombineLatest, Observable, Subject} from 'rxjs';

import {catchError, tap, first,  filter, takeUntil, distinctUntilKeyChanged } from 'rxjs/operators';
import {Component, Inject, OnDestroy} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {IProcessContext} from 'app/shared/modules/api/models/process-context.interface';
import {Process} from 'app/shared/modules/api/models/process';
import {environment} from 'environments/environment';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {GetExternalParticipants} from 'app/actions/external-participants.actions';
import {ContactSelectors, OrganizationSelectors, ProcessActions, ProcessParticipantActions} from 'app/+store';
import {LoadAll} from 'app/+store/contact/contact.actions';
import {ProcessParticipantService} from 'app/+store/process-participant/process-participant.service';
import {ProcessParticipant} from 'app/+store/process-participant/process-participant';
import {AngularTokenService} from 'angular-token';
import {UploadUtils} from 'app/lib/upload_utils';
import {Validator} from 'app/lib/validator';
import {FileUtils} from 'app/lib/file_utils';
import { QuickstartService } from 'app/+store/process/quickstart.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dvtx-quick-share-dialog-container',
  templateUrl: './quick-share-dialog.component.html',
})
export class QuickShareDialogContainerComponent implements OnDestroy {
  SUPPORTED_FILE_TYPES: string[] = FileUtils.DEFAULT_SUPPORTED_FILE_TYPES;
  MAX_FILE_SIZE = FileUtils.DEFAULT_MAX_FILE_SIZE;

  url: string;

  form: UntypedFormGroup;
  uploader: FileUploader;
  uploadedFiles: FileItem[] = [];
  uploadSuccess = false;
  contacts$;
  processId = null;
  onDestroy = new Subject();
  organizationID: string;

  constructor(private _store: Store<AppState>,
              private _fb: UntypedFormBuilder,
              private _dialogRef: MatDialogRef<QuickShareDialogContainerComponent>,
              private _translateSvc: TranslateService,
              private _notificationSvc: NotificationService,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _tokenSvc: AngularTokenService,
              private _quickstartSvc: QuickstartService,
              private _participantSvc: ProcessParticipantService) {
    this._store.select(OrganizationSelectors.getSelected)
      .pipe(filter(o => !!o), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy))
      .subscribe((x) => {
        if (x) {
          this.organizationID = x.id;
          this._store.dispatch(new LoadAll(x))
        }
      });

    this.contacts$ = this._store
      .select(ContactSelectors.getMembersAndContactPersonsOfSelectedOrg());

    this.url = '';
    const config = {
      url: '',
      dms_folder_id: '', // this.data.configuration.dms_folder_id
      dms_path: ''      // this.data.configuration.dms_path
    };
    this._initUploadWorker(config);
    this._initForm(config);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  addFile(fileContext) {
    console.log('addFile#fileContext', fileContext);
    if (this._validateFile(fileContext)) {
      return;
    }

    const control = <UntypedFormArray>this.form.controls['files'];
    control.push(this._fb.group({
      title: [fileContext.file.name],
      type: [''],
      tag: [''],
      tags: this._fb.array([])
    }));
    this.form.markAsDirty();
    return fileContext;
  }

  removeFile(i: number) {
    const control = <UntypedFormArray>this.form.controls['files'];
    control.removeAt(i);
  }

  getFileValue(i: number) {
    const control = <UntypedFormArray>this.form.controls['files'];
    return control.at(i).value;
  }

  submit() {
    const formValue = this.form.value;
    const process = new Process(null, 'quickstart', '0.1.0', formValue.title, formValue.subtitle,
      formValue.description, formValue.due_date, formValue.dms_folder_id);

    this._quickstartSvc.create(process).subscribe((processContext: IProcessContext) => {
      // Muss global werden: Prozesskontext.

      this.url = this._apiUploadUrl(processContext.id);
      this.processId = processContext.id;
      this.uploader.options.url = this.url;

      this._notificationSvc.success(`Die Dokument(e) werden in das DMS geladen.`);

      this.uploader.uploadAll();
      // processContext.runCommands('on_create');
      this._store.dispatch(new ProcessActions.RunCommand(processContext.id, 'create', 'on_create'))
    });
  }

  getInvalidFiles() {
    const files = this.uploader.queue;
    const invalidFiles = [];
    files.forEach(fileItem => {
      const invalidFile = this._validateFile(fileItem);
      if (invalidFile) {
        invalidFiles.push(invalidFile);
      }
    });
    return invalidFiles;
  }

  private _validateFile(fileItem) {
    const extension = FileUtils.getExtension(fileItem.file.name);

    if (this.SUPPORTED_FILE_TYPES.indexOf(extension) === -1) {
      return {
        name: fileItem.file.name,
        reason: 'Dateiformat wird nicht unterstützt: ' + extension
      };
    }

    if (fileItem.file.size / 1024 / 1024 > this.MAX_FILE_SIZE) {
      return {
        name: fileItem.file.name,
        reason: `Datei größer als ${ this.MAX_FILE_SIZE} MB`
      };
    }
    return null;
  }

  private _initUploadWorker(config) {
    const requestAuthHeaders = UploadUtils.authHeaders(this._tokenSvc.currentAuthData);
    this.uploader = new FileUploader(
      {
        url: config.url,
        headers: requestAuthHeaders,
        autoUpload: false,
        itemAlias: 'attachment'
      });
    this.uploader.onBeforeUploadItem = (item => item.url = this.url);
    this.uploader.onAfterAddingFile = (fileContext) => {
      fileContext.withCredentials = false;
      this.addFile(fileContext);
    };
    this.uploader.onBuildItemForm = (item, form) => {
      item.url = this.url;

      const index = this.uploader.queue.indexOf(item);
      const formObject = this.getFileValue(index);
      for (const key in formObject) {
        if (formObject.hasOwnProperty(key)) {
          form.append(key, formObject[key]);
        }
      }
      form.append('dms_folder_id', this.form.get('dms_folder_id').value);
      form.append('dms_path', this.form.get('dms_path').value);
      form.append('externally_available', this.form.get('externally_available').value);
      form.append('attachment_process_id', this.processId);
      form.append('documents_externally_available_until',
        this.form.get('documents_externally_available_until').value);
      form.append('password', this.form.get('password').value);
      console.log('onBuildItemForm#form', form);
      // form.append('tags', JSON.stringify(form.get('tags').value));
    };
    this.uploader.onCancelItem = (item: FileItem, response: string, status: number, headers: any) => {
      const index = this.uploader.queue.indexOf(item);
      this.removeFile(index);
      this._notificationSvc.success(`Das Dokument wurde aus der Warteschlange entfernt.`);
    };

    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: any) => {
      const index = this.uploader.queue.indexOf(item);
      this.uploader.removeFromQueue(item);
      this.removeFile(index);
      this._notificationSvc.success(`Das Dokument "${item.file.name}" wurde erfolgreich in das DMS geladen. Projektraum wird aktualisiert...`);
    };

    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: any) => {
      if (status === 422 || status === 400) {
        this._notificationSvc.error('UPLOAD.UPLOAD_FAILURE_SERVER_ISSUE')
      } else {
        this._notificationSvc.error('UPLOAD.UPLOAD_FAILURE_FILE_ISSUE')
      }
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (status === 200) {
        this.uploadedFiles.push(item);
      } else if (status === 422 || status === 400) {
        this._notificationSvc.error('UPLOAD.UPLOAD_FAILURE_SERVER_ISSUE')
      } else {
        this._notificationSvc.error('UPLOAD.UPLOAD_FAILURE_FILE_ISSUE')
      }
    };


    this.uploader.onCompleteAll = () => {
      if (this.uploader.queue.length === 0) {
        this.uploadSuccess = true;
        this._dialogRef.close();
        this._processContacts(this.processId, this.form.value.contact_people.filter(val => val));
      }
    };

    // Hack for dialog closing on backdrop click
    document.getElementsByClassName('cdk-overlay-backdrop')[0].addEventListener('click', () => {
      this._dialogRef.close(this.uploadedFiles);
    });
  }

  private _initForm(config) {
    this.form = this._fb.group({
      title: ['Quick Share', Validators.required],
      due_date: [''],
      subtitle: [''],
      description: [''],
      externally_available: [true],
      documents_externally_available_until: [null],
      password: [null, Validator.passwordTooShort],
      contact_people: [[]],
      files: this._fb.array([], Validators.required),
      dms_folder_id: [config.dms_folder_id || '', Validators.required],
      dms_path: [config.dms_path || '']
    });
    this.form.patchValue({title: 'Quick Share'});
  }

  private _processContacts(id, contacts) {
    observableCombineLatest(contacts.map(contact => {
      const participant = new ProcessParticipant(null, id, contact.name, contact.email, contact.title_id,
        contact.honorific, contact.first_name,
        contact.last_name);
      participant.roles = [];
        return this._participantSvc.create(id, participant).pipe(first(),tap(_ => {
          this._notificationSvc.success(`${contact.name} wird dem Projektraum hinzugefügt`);
        }, (error) => {
          this._notificationSvc.error(error[0].title);
        }),
          catchError(error => {
          console.error(error);
          return observableOf(null);
        }));
      })
    ).pipe(
      first())
      .subscribe(_ => {
        this._store.dispatch(new ProcessParticipantActions.LoadAll(id));
        this._store.dispatch(new GetExternalParticipants(id));
      });
  }

  private _apiUploadUrl(id) {
    const apiBase = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}`;
    return `${apiBase}/workflow_engine/quickstart/${id}/upload`;
  }
}
