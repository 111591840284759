<div [formGroup]="form">
  <mat-radio-group
    [formControlName]="controlName"
    class="radio-group-element">
    <mat-radio-button
      *ngFor="let radioButton of radioButtons"
      color="primary"
      [value]="radioButton.value"
      [checked]="radioButton.checked"
      (click)="onClick(radioButton.checked)">
      {{ radioButton.label | translate }}
    </mat-radio-button>
  </mat-radio-group>
</div>
