
import {takeUntil} from 'rxjs/operators';
import { ChangeDetectorRef, Directive, EventEmitter, ElementRef, HostBinding, Input, NgZone,
  OnChanges, OnInit, OnDestroy, Optional, Output, SkipSelf
} from '@angular/core';
import {Subject} from 'rxjs';

const DISABLED_OPACITY = 0.3;

export function resolve(optDisabled?: DisabledToggleDirective): DisabledToggleDirective {
  return optDisabled || defaultDisabled;
};

@Directive({
  selector: '[dvtxDisabled]',
})
export class DisabledToggleDirective implements OnInit, OnDestroy, OnChanges {
  @Output() dvtxDisabledChange: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Input() dvtxDisabled: boolean = false;
  @Input() dvtxDisabledStopPropagation: boolean = false;
  private onDestroy = new Subject();

  public disabled: boolean = false;
  private element: HTMLElement;

  constructor(private elementRef: ElementRef,
              @SkipSelf() @Optional() private optParent: DisabledToggleDirective,
              @Optional() private changeDetector: ChangeDetectorRef,
              @Optional() private zone: NgZone) {
    this.dvtxDisabledChange = new EventEmitter<boolean>(false);

    if (optParent) {
      optParent.onChange(this, () => this.checkForChanges());
    }
  }

  ngOnChanges() {
    this.checkForChanges();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnInit() {
    this.element = this.elementRef.nativeElement;
  }

  private checkForChanges() {
    setTimeout(() => {
      let newValue = false;

      if (this.dvtxDisabledStopPropagation || !this.optParent) {
        newValue = !!this.dvtxDisabled;
      } else {
        newValue = !!this.dvtxDisabled || this.optParent.disabled;
      }

      if (this.zone && newValue !== this.disabled) {
        this.zone.run(() => {
          if (this.changeDetector) {
            this.changeDetector.markForCheck();
          }
          this.disabled = newValue;
          this.dvtxDisabledChange.emit(newValue);
        });
      }
    }, 0);
  }

  /**
   * Alerts the callback when the disabled state changes
   */
  onChange(directive: DisabledToggleDirective, callback: (opt?: boolean) => void) {
    this.dvtxDisabledChange.pipe(takeUntil(this.onDestroy)).subscribe(callback);
  }
}

const defaultDisabled = new DisabledToggleDirective(null, null, null, null);

@Directive({
  selector: '[dvtxDefaultDisabled]'
})
export class DefaultDisabledStateDirective {
  disabledDirective: DisabledToggleDirective;

  @HostBinding('style.opacity') opacity: string;
  @HostBinding('style.pointerEvents') pointer: string;

  get disabled(): boolean {
    this.opacity = this.disabledDirective.disabled ? '"' + DISABLED_OPACITY + '"' : undefined;
    this.pointer = this.disabledDirective.disabled ? 'none' : undefined;
    return this.disabledDirective.disabled;
  }

  constructor(@Optional() optDisabled: DisabledToggleDirective) {
    this.disabledDirective = resolve(optDisabled);
  }
}
