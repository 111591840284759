import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {of} from 'rxjs/internal/observable/of';
import {ProcessTreeNode} from 'app/+store/process-tree/process-tree';
import {ProcessTreeNodeType} from 'app/+store/process-tree/process-tree.interface';
import {TreeData} from '../../../../../process-tree/common/process-tree-data';
import {TranslateService} from '@ngx-translate/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

/** Flat node out of the computed process node with expandable and level information */
interface ProcessTreeFlatNode {
  id: string;
  expandable: boolean;
  name: string;
  level: number;
  parentid: string;
  iconname: string;
  children: any[],
  ngClass: string;
  active: boolean;
  icon: string;
  isSvgIcon: boolean;
  closed: boolean;
  type: string;
  trackByKey: string;
}

/**
 * TODO:
 * √ Multi-root: Check according participation.
 * √ Check project room administration.
 * √ Check GT & Curacon Styles.
 * - Check online.
 */

/**
 * @Input nodes: ProcessTreeNode[] - Takes nodes of type ProcessTreeNode.
 * @Output onChange: ProcessTreeNode . Emits the selected node of type ProcessTreeNode;
 */
@Component({
  selector: 'dvtx-process-tree-single-select',
  templateUrl: './process-tree-single-select.component.html',
  styleUrls: ['./process-tree-single-select.component.scss']
})
export class ProcessTreeSingleSelectComponent implements OnInit, AfterViewInit {
  ProcessTreeNodeType = ProcessTreeNodeType;

  treeControl = new FlatTreeControl<ProcessTreeFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  private _nodes = [];
  private _nodeMap = {};

  @ViewChild('tree', { static: true }) tree; // obtain reference to treeControl.
  noTitlePlaceholder: string;

  @Input() selectedNodeId = null;

  // tslint:disable-next-line:no-input-rename
  @Input('onFolderClickCb') onFolderClickCb: (nodeId) => void;
  // call it: this.onFolderClickCb()
  // tslint:disable-next-line:no-input-rename
  @Input('initialExpandAll') initialExpand: boolean = false;
  // tslint:disable-next-line:no-input-rename
  @Input('initialExpandRoot') initialExpandRoot: boolean = false;
  // tslint:disable-next-line:no-input-rename
  @Output() onChange = new EventEmitter<ProcessTreeNode>();

  private _transformer = (node: TreeData, level: number): ProcessTreeFlatNode => {
    return {
      id: node.id,
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      parentid: node.parentid,
      iconname: node.iconname,
      children: [],
      ngClass: node.ngClass,
      active: node.active,
      icon: node.icon,
      isSvgIcon: node.isSvgIcon,
      closed: node.closed,
      type: node.type,
      trackByKey: `${node.id}|${node.closed}|${node.active}`
    };
  };

  // tslint:disable-next-line:member-ordering
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  // tslint:disable-next-line:member-ordering
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  // tslint:disable-next-line:no-input-rename
  @Input() set nodes(treeNodes: ProcessTreeNode[]) {
    const nodes = this.encodeNodes('Input() ', treeNodes);
    this.dataSource.data = nodes;
    this._nodes = treeNodes || [];

    if (this.initialExpand || treeNodes.length && treeNodes.length > 1 && treeNodes.length <= 4) {
      this.treeControlExpandAll();
    } else if (this.initialExpandRoot) {
      this.treeControlExpandRoot();
    }
  }

  constructor(private _translateSvc: TranslateService) {
    this.noTitlePlaceholder = this._translateSvc.instant('GENERAL.NO_TITLE');
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(_ => {
      if (this.initialExpand || this._nodes.length && this._nodes.length > 1 && this._nodes.length <= 4) {
        this.treeControlExpandAll();
      } else if (this.initialExpandRoot) {
        this.treeControlExpandRoot();
      }
    });
  }

  getChildren = (node: TreeData) => of(node.children);

  hasChild = (_: number, node: ProcessTreeFlatNode) => node.expandable;

  change(node: ProcessTreeNode) {
    this.onChange.emit(node);
  }

  onToggleNodeExpanded(node: TreeData) {
    try {
      if (node.type === ProcessTreeNodeType.Folder)
        this.onFolderClickCb(node.id);
    } catch (err) {
      console.error('ProcessTreeSingleSelectComponent#onToggleNodeExpanded', err)
    }
  }

  /**
   * This method is invoked by single select button entry
   * @param id
   */
  selectNode(node: TreeData) {
    // console.log(`ProcessTreeSingleSelectComponent#selectNode clicked! id:${node.id}`)
    this._nodes.filter(n => n.ngClass !== '').forEach(n => n.ngClass = '')
    this._nodes.filter(n => n.id === node.id).forEach(n => {
      n.ngClass = 'dvtx-selected-node';
      // console.log('ProcessTreeSingleSelectComponent#selectNode updated!');
      this.change(new ProcessTreeNode(n.id, ProcessTreeNodeType.Process, n.parentid, n.name, null))
      this.onToggleNodeExpanded(node)
    })
  }

  encodeNodes(cause: string, processNodes: ProcessTreeNode[]): TreeData[] {
    const nodes = [];
    const roots = [];
    if (!processNodes || typeof processNodes === 'undefined') {
      return null;
    }

    const childMap = {};
    const nodeMap = {};
    const isRootNode = {};
    processNodes.forEach(n => {
      if (n.type === ProcessTreeNodeType.Process || n.type === ProcessTreeNodeType.Folder) {
        const thisNode: TreeData = {
          id: n.id,
          parentid: n.parentId,
          name: n.title,
          iconname: this.getIconName(n),
          children: [],
          ngClass: n.active ? 'active' : '',
          active: n.active,
          icon: n.icon,
          isSvgIcon: n.isSvgIcon,
          closed: n.closed,
          type: n.type
        };
        nodes.push(thisNode);
        nodeMap[thisNode.id] = thisNode;

        // Two root options:
        // 1. parentId is null
        // 2. parentId is present but parent node itself is not inside nodes.
        if (n.parentId) {
          if (!childMap[n.parentId]) {
            childMap[n.parentId] = []
          }
          childMap[n.parentId].push(thisNode);

        } else {
          // Root case 1: no parent ID.
          roots.push(thisNode);
          isRootNode[thisNode.id] = true;
        }
      }
    });

    nodes.forEach(n => {
      if (childMap[n.id]) {
        n.children = childMap[n.id];
      }

      // Root case 2: parent ID but parent itself is not in nodes.
      if (n.parentid && !nodeMap[n.parentid] && !isRootNode[n.id]) {
        roots.push(n);
        isRootNode[n.id] = true;
      }
    });

    return roots;
  }

  getIconName(node: ProcessTreeNode) {
    switch (node.type) {
      case(ProcessTreeNodeType.Folder):
        return 'folder';
      case(ProcessTreeNodeType.Document):
        return 'file_copy';
      case(ProcessTreeNodeType.Process):
        return 'trending_up';
      case(ProcessTreeNodeType.Task):
        return 'play_circle_outline';
      default:
        return 'star';
    }
  }

  /**
   * Expand all nodes
   * ====================================================================
   * Working around issue that initially expanding all nodes did not work.
   * ====================================================================
   * For the expandAll functionality to work,
   * make sure the dataNodes variable of the TreeControl must be set to all root level data nodes of the tree
   *
   * Notice the treeControl.dataNodes = nodes; before calling expandAll()
   *
   * following test tells do we having any expanded nodes
   * expect(treeControl.expansionModel.selected.length).toBe(0, `Expect no expanded nodes`);
   */
  treeControlExpandAll() {
    try {
      this.treeControl.expandAll();
    } catch (error) {
      console.log(`Exception: treeControlExpandAll() ${(<Error>error).message}`)
    }
  }

  treeControlExpandRoot() {
    const nodeMap = {};
    const nodeList = [];
    let activeNode = null;
    try {
      this.treeControl.dataNodes.forEach(node => {
        this._fillNodeMap(node, nodeMap, nodeList);
        nodeList.forEach(n => {
          if (n.active) {
            activeNode = n;
          }
        })
      });
      nodeList.forEach(
        node => {
          if (!node.parentid) {
            this.treeControl.expand(node);
          }

          if (activeNode) {
            const nodesToExpand = [activeNode];
            this._expandRecursive(nodeMap, activeNode, nodesToExpand);
          }
        });
    } catch (error) {
      console.log(`Exception: treeControlExpandRoot() ${(<Error>error).message}`)
    }
  }

  private _expandRecursive(nodeMap, activeNode, nodesToExpand = []) {
    if (activeNode.parentid && nodeMap[activeNode.parentid]) {
      const node = nodeMap[activeNode.parentid];
      nodesToExpand.push(node);

      if (node.parentid && nodeMap[node.parentid]) {
        this._expandRecursive(nodeMap, node, nodesToExpand);
      }
    }

    if (nodesToExpand && nodesToExpand.length) {
      for (let i = 0; i < nodesToExpand.length; ++i) {
        if (!this.treeControl.isExpanded(nodesToExpand[i])) {
          this.treeControl.expand(nodesToExpand[i]);
        }
      }
    }
  }

  private _fillNodeMap(node, nodeMap, nodeList) {
    if (node) {
      nodeList.push(node);
      nodeMap[node.id] = node;

      if (node.children && node.children.length) {
        node.children.forEach(c => {
          this._fillNodeMap(c, nodeMap, nodeList)
        });
      }
    }
  }

  /**
   * TrackBy of mat-tree to increase render performance.
   * @param index
   * @param node
   */
  public trackByFn = (index, node) => {
    return node.trackByKey;
  }
}
