import {IApiRequest, IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {
  IMessageResourceStat,
  IMessageStat,
  IUnreadResource,
  MESSAGE_PAGE_SIZE,
  MessageResourceType
} from 'app/+store/message/message.interface';
import {Message, MessageResourceStat, UnreadResource} from './message';
import {Process} from '../process/process';
import {ThirdPartyDetailsEvent, ThirdPartyDetailsLineItem, ThirdPartyWorkerInfo} from '../process-event/process-event';

export class MessageBuilder implements IApiResourceBuilder<Message> {
  /**
   * Pagination meta data for this request.
   */

  /**
   * Total count of elements of request (<= per page size).
   */
  public total = 0;
  public perPage = MESSAGE_PAGE_SIZE;

  /**
   * Total count of elements of the query scope (all candidates).
   */
  public records = 0;

  public unreadCount = 0;

  /**
   * The returned timestamp is the start marker for the pagination.
   * Newer elements will not be considered during one paginated run starting from page 1.
   * It must be delivered on each subsequent page request.
   * It must only be reseted on page call 1.
   */
  public timestamp = 0;

  resourceId: string;
  resourceType: MessageResourceType;

  constructor(resourceId: string = null,
              resourceType: MessageResourceType = null) {
    this.resourceId = resourceId;
    this.resourceType = resourceType;
  }

  fromResponse(data): Message {
    const msg = new Message(
      data.id,
      data.attributes.performer,
      {
        email: data.attributes.inviter_email,
        firstName: data.attributes.inviter_first_name,
        lastName: data.attributes.inviter_last_name
      },
      data.attributes.recipient,
      data.attributes.recipients,
      data.attributes.subject,
      data.attributes.message,
      data.attributes.follow_up_id,
      data.attributes.resource_id,
      data.attributes.blanked_out,
      data.attributes.favorite,
      data.attributes.color,
      data.attributes.archived_at,
      data.attributes.read_at,
      data.attributes.sent_at,
      data.attributes.created_at);

    // Add pagination info
    msg.total = this.total;
    msg.perPage = this.perPage;
    msg.records = this.records;
    msg.timestamp = this.timestamp;
    msg.unreadCount = this.unreadCount;

    // Joined process data.
    msg.performerName = data.attributes.performer_name;
    msg.processId = data.attributes.process_id;
    msg.authorizedProcess = data.attributes.authorized;
    msg.processTitle = data.attributes.process_title;
    msg.processType = data.attributes.process_type;
    msg.organizationName = data.attributes.organization_name;
    msg.identifier = data.attributes.process_identifier;
    msg.dueDate = data.attributes.due_date;
    msg.clientName = data.attributes.client_name;

    // Whitelisted timeline event payload
    msg.eventPayload = data.attributes.event_payload;
    msg.referenceId = data.attributes.reference_id;

    if (data.attributes.process_path && data.attributes.process_path.length) {
      msg.processPath = data.attributes.process_path.map(p => {
        const iconConfig = Process.iconForType(p.type);
        return {
          id: p.id,
          title: p.title,
          icon: iconConfig.icon,
          isSvgIcon: iconConfig.isSvgIcon
        }
      })
    }

    msg.apiType = data.type;
    switch (data.type) {
      case 'notification_type_artifact_commenteds':
        msg.resourceType = MessageResourceType.ArtifactComment;
        msg.icon = 'chat_bubble_outline';
        msg.unreadIcon = 'chat_bubble_outline';
        break;
      case 'notification_type_process_audit_proofs':
        msg.resourceType = MessageResourceType.AuditProofFolder;
        msg.icon = 'security';
        msg.unreadIcon = 'security';
        break;
      case 'notification_type_task_reopeneds':
        msg.resourceType = MessageResourceType.TaskReopened;
        msg.icon = 'settings_backup_restore';
        msg.unreadIcon = 'settings_backup_restore';
        break;
      case 'notification_type_audit_appendix_updateds':
        msg.resourceType = MessageResourceType.CacStatusUpdate;
        msg.icon = 'attach_file';
        msg.unreadIcon = 'attach_file';
        break;
      case 'notification_type_process_commenteds':
        msg.resourceType = MessageResourceType.Comment;
        msg.icon = 'chat_bubble_outline';
        msg.unreadIcon = 'chat_bubble_outline';
        break;
      case 'notification_type_process_descriptions':
        msg.resourceType = MessageResourceType.Description;
        msg.icon = 'subject';
        msg.unreadIcon = 'subject';
        break;
      case 'notification_type_task_closeds':
        msg.resourceType = MessageResourceType.TaskClosed;
        msg.icon = 'offline_pin';
        msg.unreadIcon = 'offline_pin';
        break;
      case 'notification_type_audit_contact_verified':
        msg.resourceType = MessageResourceType.ContactVerified;
        msg.icon = 'check';
        msg.unreadIcon = 'check';
        break;
      case 'notification_type_task_document_addeds':
        msg.resourceType = MessageResourceType.TaskDocumentAdded;
        msg.icon = 'insert_drive_file';
        msg.unreadIcon = 'insert_drive_file';
        break;
      case 'notification_type_task_document_removeds':
        msg.resourceType = MessageResourceType.TaskDocumentRemoved;
        msg.icon = 'insert_drive_file';
        msg.unreadIcon = 'insert_drive_file';
        break;
      case 'notification_type_task_responsible_assigneds':
        msg.resourceType = MessageResourceType.TaskResponsibleAssignment;
        msg.icon = 'tasks';
        msg.unreadIcon = 'tasks';
        msg.isSvgIcon = true;
        break;
      case 'notification_type_task_create_assignees':
        msg.resourceType = MessageResourceType.TaskAssignment;
        msg.icon = 'tasks';
        msg.unreadIcon = 'tasks';
        msg.isSvgIcon = true;
        break;
      case 'notification_type_task_commenteds':
        msg.resourceType = MessageResourceType.TaskCommented;
        msg.icon = 'chat_bubble_outline';
        msg.unreadIcon = 'chat_bubble_outline';
        break;
      case 'notification_type_task_accept_assignments':
        msg.resourceType = MessageResourceType.TaskAssignmentAccepted;
        msg.icon = 'check';
        msg.unreadIcon = 'check';
        break;
      case 'notification_type_task_assignee_approveds':
        msg.resourceType = MessageResourceType.TaskApproved;
        msg.icon = 'check_all';
        msg.unreadIcon = 'check_all';
        break;
      case 'notification_type_task_assignee_declineds':
        msg.resourceType = MessageResourceType.TaskDeclined;
        msg.icon = 'close';
        msg.unreadIcon = 'close';
        break;
      case 'notification_type_collector_group_created':
        msg.resourceType = MessageResourceType.CollectorGroupCreated;
        msg.icon = 'playlist_add_check';
        msg.unreadIcon = 'playlist_add_check';
        break;
      case 'notification_type_collector_group_deleted':
        msg.resourceType = MessageResourceType.CollectorGroupDeleted;
        msg.icon = 'delete';
        msg.unreadIcon = 'delete';
        break;
      case 'notification_type_collector_item_created':
        msg.resourceType = MessageResourceType.CollectorItemCreated;
        msg.icon = 'playlist_add_check';
        msg.unreadIcon = 'playlist_add_check';
        break;
      case 'notification_type_collector_item_deleted':
        msg.resourceType = MessageResourceType.CollectorItemDeleted;
        msg.icon = 'delete';
        msg.unreadIcon = 'delete';
        break;
      case 'notification_type_collector_group_assigned':
        msg.resourceType = MessageResourceType.CollectorGroupAssigned;
        msg.payload = {
          clientName: data.attributes.client_name,
          processTitle: data.attributes.process_title
        };
        msg.icon = 'playlist_add_check';
        msg.unreadIcon = 'playlist_add_check';
        break;
      case 'notification_type_collector_item_assigned':
        msg.resourceType = MessageResourceType.CollectorItemAssigned;
        msg.payload = {
          clientName: data.attributes.client_name,
          processTitle: data.attributes.process_title
        };
        msg.icon = 'playlist_add_check';
        msg.unreadIcon = 'playlist_add_check';
        break;
      case 'notification_type_collector_updated_attributes':
        msg.resourceType = MessageResourceType.CollectorUpdatedAttributes;
        msg.payload = data.attributes.changeset;
        msg.icon = 'refresh';
        msg.unreadIcon = 'refresh';
        break;
      case 'notification_type_task_reject_assignments':
        msg.resourceType = MessageResourceType.TaskAssignmentRejected;
        msg.icon = 'person_add_disabled';
        msg.unreadIcon = 'person_add_disabled';
        break;
      case 'notification_type_participant_adds':
        msg.resourceType = MessageResourceType.ParticipantAdded;
        msg.payload = data.attributes.changeset;
        msg.icon = 'person_add';
        msg.unreadIcon = 'person_add';
        break;
      case 'notification_type_participant_removes':
        msg.resourceType = MessageResourceType.ParticipantRemoved;
        msg.payload = data.attributes.changeset;
        msg.icon = 'person_add_disabled';
        msg.unreadIcon = 'person_add_disabled';
        break;
      case 'notification_type_message_instant_messages':
        msg.resourceType = MessageResourceType.InstantMessage;
        msg.icon = 'chat_bubble_outline';
        msg.unreadIcon = 'chat_bubble_outline';
        break;
      case 'notification_type_message_external_messages':
        msg.resourceType = MessageResourceType.ExternalMessage;
        msg.icon = 'chat_bubble_outline';
        msg.unreadIcon = 'chat_bubble_outline';
        break;
      case 'notification_type_artifact_uploads':
        msg.resourceType = MessageResourceType.DocumentUploaded;
        msg.icon = 'insert_drive_file';
        msg.unreadIcon = 'insert_drive_file';
        break;
      case 'notification_type_process_closes':
        msg.resourceType = MessageResourceType.ProcessClosed;
        msg.icon = 'lock';
        msg.unreadIcon = 'lock';
        break;
      case 'notification_type_collector_publisheds':
        msg.resourceType = MessageResourceType.CollectorPublished;
        msg.icon = 'playlist_add_check';
        msg.unreadIcon = 'playlist_add_check';
        break;
      case 'notification_type_auth_receiveds':
        msg.resourceType = MessageResourceType.ExternalAccessReceived;
        msg.icon = 'call_received';
        msg.unreadIcon = 'call_received';
        break;
      case 'notification_type_audit_receiveds':
        msg.resourceType = MessageResourceType.RequestReceived;
        msg.icon = 'call_received';
        msg.unreadIcon = 'call_received';
        break;
      case 'notification_type_process_due_dates':
        msg.resourceType = MessageResourceType.DueDateChanged;
        msg.icon = 'notification_important';
        msg.unreadIcon = 'notification_important';
        msg.isSvgIcon = true;
        break;
      case 'notification_type_audit_remindeds':
        msg.resourceType = MessageResourceType.Reminded;
        msg.icon = 'alarm';
        msg.unreadIcon = 'alarm';
        break;
      case 'notification_type_audit_third_party_details':
        msg.resourceType = MessageResourceType.ThirdPartyDetails;
        msg.icon = 'refresh';
        msg.unreadIcon = 'refresh';
        if (msg.eventPayload) {
          const thirdPartyWorkerInfo = msg.eventPayload && msg.eventPayload.worker_info ? ThirdPartyWorkerInfo.fromResponse(msg.eventPayload.worker_info) : {};
          let lineItems = []
          if (msg.eventPayload && msg.eventPayload.line_items && msg.eventPayload.line_items.length) {
            lineItems = msg.eventPayload.line_items.map(item => {
              return ThirdPartyDetailsLineItem.fromResponse(item);
            });
          }
          data = {
            workerInfo: thirdPartyWorkerInfo,
            lineItems: lineItems,
            url: msg.eventPayload.url
          }
          msg.eventPayload = data;
        }
        break;
      case 'notification_type_organization_accepted_memberships':
        msg.resourceType = MessageResourceType.AcceptedMemberInvitation;
        msg.icon = 'domain';
        msg.unreadIcon = 'domain';
        break;
      case 'notification_type_organization_declined_memberships':
        msg.resourceType = MessageResourceType.DeclinedMemberInvitation;
        msg.icon = 'domain';
        msg.unreadIcon = 'domain';
        break;
      case 'notification_type_user_accepted_invitations':
        msg.resourceType = MessageResourceType.AcceptedBusinessPartnerInvitation;
        msg.icon = 'person';
        msg.unreadIcon = 'person';
        break;
      case 'notification_type_organization_declined_invitations':
        msg.resourceType = MessageResourceType.DeclinedBusinessPartnerInvitation;
        msg.icon = 'remove_circle_outline';
        msg.unreadIcon = 'remove_circle';
        break;
      case 'notification_type_task_updated_attributes':
        msg.resourceType = MessageResourceType.TaskUpdated;
        msg.payload = data.attributes.changeset;
        msg.icon = 'edit';
        msg.unreadIcon = 'edit';
        break;
      case 'notification_type_process_owner_changed':
        msg.resourceType = MessageResourceType.ProcessOwnerChanged;
        msg.count = data.attributes.count;
        msg.processes = data.attributes.processes;
        msg.icon = 'person_add';
        msg.unreadIcon = 'person_add';
        break;
      default:
        msg.resourceType = MessageResourceType.Message;
        msg.icon = 'mail';
        msg.unreadIcon = 'mail_outline';
    }
    return msg;
  }

  toRequest(_: Message) {
    return null;
  }

  addMetaSection(meta: any) {
    if (!meta) return;

    this.total = meta.total;
    this.perPage = meta.per_page;
    this.records = meta.records;
    this.unreadCount = meta.unread;
    this.timestamp = meta.timestamp;
  }
}

export class MessageDraftBuilder implements IApiResourceBuilder<Message> {
  resourceId: string;
  resourceType: MessageResourceType;

  constructor(resourceId: string = null,
              resourceType: MessageResourceType = null) {
    this.resourceId = resourceId;
    this.resourceType = resourceType;
  }

  fromResponse(data): Message {
    const msg = new Message(
      data.id,
      data.attributes.performer,
      {
        email: data.attributes.inviter_email,
        firstName: data.attributes.inviter_first_name,
        lastName: data.attributes.inviter_last_name
      },
      this._setRecipient(data),
      data.attributes.recipients,
      data.attributes.subject,
      data.attributes.body,
      data.attributes.follow_up_id,
      data.attributes.resource_id,
      false,
      false,
      null,
      null,
      data.attributes.updated_at,
      data.attributes.sent_at,
      data.attributes.created_at,
      data.attributes.updated_at);
    if (msg.sentAt) {
      msg.resourceType = MessageResourceType.SentMessage;
      msg.icon = 'send';
      msg.unreadIcon = 'send';
    } else {
      msg.resourceType = MessageResourceType.MessageDraft;
      msg.icon = 'drafts';
      msg.unreadIcon = 'drafts';
    }
    return msg;
  }

  toRequest(message: Message): IApiRequest {
    return {
      data: {
        type: 'message_drafts',
        attributes: {
          recipients: message.recipients,
          subject: message.subject,
          body: message.message
        }
      }
    }
  }

  private _setRecipient(data) {
    return data.attributes.recipient || (data.attributes.recipients.length > 0 ? data.attributes.recipients[0] : null)
  }
}

export class MessageStatBuilder implements IApiResourceBuilder<IMessageStat> {
  fromResponse(data): IMessageStat {
    return {
      id: null,
      type: 'message_stats',
      unread: data.attributes.unread,
      unreadUpdates: data.attributes.unread_updates
    };
  }

  toRequest(_: IMessageStat) {
    return null
  }
}

export class UnreadResourceBuilder implements IApiResourceBuilder<UnreadResource> {

  fromResponse(data): UnreadResource {
    let artifacts = data.attributes.artifacts ? data.attributes.artifacts : null;
    if (artifacts) {
      artifacts = artifacts.data.map(artifact => ({
        id: artifact.id,
        filename: artifact.attributes.filename,
        performerName: artifact.attributes.performer_name
      }))
    }
    const unread = new UnreadResource(data.id, data.attributes.unread, artifacts);

    unread.title = data.attributes.title;
    unread.subtitle = data.attributes.subtitle;
    unread.url = data.attributes.url;
    unread.processType = data.attributes.process_type;
    unread.commentType = data.attributes.comment_type;
    unread.clientName = data.attributes.client_name;
    unread.clientId = data.attributes.client_id;
    unread.closedProcess = data.attributes.closed;

    unread.referenceId = data.attributes.reference_id;
    unread.itemTitle = data.attributes.item_title;
    unread.categoryTitle = data.attributes.category_title;
    unread.color = data.attributes.color;

    return unread;
  }

  toRequest(_: IUnreadResource) {
    return null
  }
}

export class MessageResourceStatBuilder implements IApiResourceBuilder<MessageResourceStat> {

  fromResponse(data): MessageResourceStat {
    return new MessageResourceStat(data.id, data.attributes.all, data.attributes.unread);
  }

  toRequest(_: IMessageResourceStat) {
    return null
  }
}
