import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {DocumentSignature} from './document-signature';

export interface State extends EntityState<DocumentSignature> {
  selected?: DocumentSignature;
  loading: boolean;
}

export const adapter = createEntityAdapter<DocumentSignature>();

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
