import {IResource} from 'app/shared/modules/api/models/resource.interface';

/**
 * Collecto reference cache item to display the source of a Collecto
 * comment along with the comment itself.
 * Contains the Collecto group's and element's title as well as
 * the item's ID to open the Collecto with correct activated tab.
 */
export class CollectorLookupItem implements IResource {
  readonly type = 'collector_item_lookup';

  constructor(public id: string,
              public processId: string,
              public itemTitle: string,
              public categoryId: string,
              public categoryTitle: string,
              public color: string) {
  }
}
