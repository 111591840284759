<form [formGroup]="form">
  <h3>The verification was provided by:</h3>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-4">
      <mat-form-field>
        <input matInput placeholder="First Name" formControlName="reviewer_first_name" required>
      </mat-form-field>
    </div>
    <div class="col-12 col-sm-6 col-md-4">
      <mat-form-field>
        <input matInput placeholder="Last Name" formControlName="reviewer_last_name" required>
      </mat-form-field>
    </div>
    <div class="col-12 col-sm-6 col-md-4">
      <mat-form-field>
        <input matInput placeholder="Job Title" formControlName="reviewer_job_title">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-form-field>
        <input matInput placeholder="Name of Audit Firm" formControlName="reviewer_organization_name">
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-sm-4 col-md-3">
      <mat-form-field>
        <input matInput placeholder="City" formControlName="reviewer_city">
      </mat-form-field>
    </div>
    <div class="col-12 col-sm-4 col-md-3">
      <mat-form-field>
        <input matInput placeholder="Country" formControlName="reviewer_country">
      </mat-form-field>
    </div>
    <div class="col-12 col-sm-4 col-md-3">
      <dvtx-datepicker [form]="form"
                       [placeholder]="'Datum'"
                       [controlName]="'reviewed_at'"></dvtx-datepicker>
    </div>
  </div>
</form>
