import {CmsBlogActions, CmsBlogActionTypes} from './cms-blog.actions';
import {adapter, initialState, State} from './cms-blog.state';

export function reducer(state = initialState, action: CmsBlogActions): State {
  const current = state.selected;
  switch (action.type) {
    case CmsBlogActionTypes.LoadOne:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case CmsBlogActionTypes.Create:
    case CmsBlogActionTypes.Remove:
    case CmsBlogActionTypes.Save:
      return {
        ...state,
        loading: true
      };
    case CmsBlogActionTypes.LoadOneSuccess:
      return adapter.addOne(action.cmsBlog, {
        ...state,
        selected: action.cmsBlog,
        loading: false,
      });
    case CmsBlogActionTypes.CreateSuccess:
    case CmsBlogActionTypes.SaveSuccess:
      return adapter.upsertOne(action.cmsBlog, {
        ...state,
        selected: action.cmsBlog,
        loading: false,
      });
    case CmsBlogActionTypes.CreateFail:
    case CmsBlogActionTypes.LoadOneFail:
    case CmsBlogActionTypes.RemoveFail:
    case CmsBlogActionTypes.SaveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
    case CmsBlogActionTypes.RemoveSuccess:
      return adapter.removeOne(action.cmsBlog.id, {
        ...state,
        selected: (current && current.id === action.cmsBlog.id) ?  null : current,
        loading: false,
      });
  }
  return state;
}

