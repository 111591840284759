import {
  ThirdPartyConfirmationLiabilityInvoice,
  ThirdPartyConfirmationLiabilityInvoiceRow
} from './liabilityInvoice';
import {RequestType} from './request-type.enum';

export namespace ThirdPartyConfirmationLiabilityInvoiceBuilder {
  export function fromResponse(response): ThirdPartyConfirmationLiabilityInvoice {
    const keyToGroup: { [key: string]: any[] } = {};
    const groups: any[][] = [];

    for (const row of response.attributes.rows) {
      const key = [row.kreditoren_name, row.kreditoren_nr, row.kreditor_ansprechpartner_title, row.kreditor_ansprechpartner_name, row.kreditor_ansprechpartner_email].join('|')

      if (keyToGroup.hasOwnProperty(key)) {
        keyToGroup[key].push(row);
      } else {
        const newGroup = [row];
        keyToGroup[key] = newGroup;
        groups.push(newGroup);
      }
    }

    const thirdParty = new ThirdPartyConfirmationLiabilityInvoice(
      response.id,
      response.type,
      response.attributes.parent_process_id,
      response.attributes.referenced_at,
      response.attributes.due_date,
      response.attributes.color,
      response.attributes.language || 'de',
      response.attributes.request_type || RequestType.Invoice,
      groups.map((group) => {
        const firstInGroup = group[0];

        return new ThirdPartyConfirmationLiabilityInvoiceRow(
          firstInGroup.id,
          firstInGroup.color || response.attributes.color,
          firstInGroup.lfd,
          firstInGroup.referenced_at || response.attributes.referenced_at,
          firstInGroup.due_date || response.attributes.due_date,
          firstInGroup.comment || '',
          firstInGroup.language || response.attributes.language || 'de',
          firstInGroup.request_type || response.attributes.request_type || RequestType.Invoice,
          firstInGroup.kreditor_name,
          firstInGroup.kreditor_nr,

          group.map((row) => {
            return {
              id: row.id,
              lfd: row.lfd,
              rechnung_nr: row.rechnung_nr,
              rechnung_datum: row.rechnung_datum,
              rechnung_betrag: row.rechnung_betrag,
              waehrung: row.waehrung,
              kontierung: row.kontierung,
              third_party_comment: row.third_party_comment,
              third_party_invoice_confirmed: row.third_party_invoice_confirmed,
              third_party_invoice_manual: row.third_party_invoice_manual
            }
          }),

          firstInGroup.kreditor_ansprechpartner_title,
          firstInGroup.kreditor_ansprechpartner_name,
          firstInGroup.kreditor_ansprechpartner_email,
        )
      })
    );
    thirdParty.emailAttachmentFilename = response.attributes.email_attachment_filename;
    thirdParty.state = response.attributes.state;

    return thirdParty;
  }

  export function toRequest(resource: ThirdPartyConfirmationLiabilityInvoice) {
    const rows = [];

    for (const row of resource.rows) {
      rows.push(...row.invoiceRows.map((invoiceRow) => {
        return {
          id: invoiceRow.id,
          color: row.color,
          lfd: invoiceRow.lfd,
          referenced_at: row.referenced_at,
          due_date: row.due_date,
          comment: row.comment,
          language: row.language,
          request_type: row.request_type,
          kreditor_name: row.kreditor_name,
          kreditor_nr: row.kreditor_nr,
          rechnung_nr: invoiceRow.rechnung_nr,
          rechnung_datum: invoiceRow.rechnung_datum,
          rechnung_betrag: invoiceRow.rechnung_betrag,
          waehrung: invoiceRow.waehrung,
          kontierung: invoiceRow.kontierung,
          kreditor_ansprechpartner_title: row.kreditor_ansprechpartner_title,
          kreditor_ansprechpartner_name: row.kreditor_ansprechpartner_name,
          kreditor_ansprechpartner_email: row.kreditor_ansprechpartner_email,
        }
      }))
    }

    return {
      id: resource.id,
      type: resource.type,
      attributes: {
        parent_process_id: resource.parent_process_id,
        referenced_at: resource.referenced_at,
        due_date: resource.due_date,
        language: resource.language,
        request_type: resource.request_type,
        rows
      }
    };
  }
}
