import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {
  ProcessStateActionMenuComponent
} from './containers/process-state-action-menu/process-state-action-menu.component';
import {ProcessReopenDialogComponent} from './containers/process-reopen-dialog/process-reopen-dialog.component';
import {ProcessDeletionDialogComponent} from './containers/process-deletion-dialog/process-deletion-dialog.component';
import {TooltipModule} from 'app/five-f/tooltip/tooltip.module';
import {ProcessClosingDialogComponent} from './containers/process-closing-dialog/process-closing-dialog.component';
import { ProcessTrashDialogComponent } from './containers/process-trash-dialog/process-trash-dialog.component';


@NgModule({
    declarations: [
        ProcessStateActionMenuComponent,
        ProcessReopenDialogComponent,
        ProcessDeletionDialogComponent,
        ProcessTrashDialogComponent,
        ProcessClosingDialogComponent
    ],
    imports: [
        SharedModule,
        TooltipModule
    ],
    exports: [
        ProcessStateActionMenuComponent,
        ProcessReopenDialogComponent,
        ProcessDeletionDialogComponent,
        ProcessTrashDialogComponent,
        ProcessClosingDialogComponent,
        SharedModule
    ]
})
export class ProcessActionsModule {
}
