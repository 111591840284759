import { PartnerType } from './partner-type.interface';

export const PartnerTypes: PartnerType[] = [
  {
    id: 0,
    title: 'Kunde'
  },
  {
    id: 0,
    title: 'Lieferant'
  },
  {
    id: 0,
    title: 'Rechtsanwalt'
  },
  {
    id: 0,
    title: 'Steuerberater'
  },
  {
    id: 0,
    title: 'Versicherungsagent'
  }
];
