import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NodeType} from 'app/+store/dms-folder/node-type';
import {INode} from 'app/+store/dms-folder/node.interface';

@Component({
  selector: 'dvtx-folder-tree-icon',
  templateUrl: './folder-tree-icon.component.html',
  styleUrls: ['./folder-tree-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderTreeIconComponent implements OnInit {
  NodeType = NodeType;

  @Input() iNode: INode;

  constructor() { }

  ngOnInit() {
  }
}
