import { Action } from '@ngrx/store';

export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACT = 'GET_CONTACT';
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const RESET_CONTACTS = 'RESET_CONTACTS';


export class GetContacts implements Action {
  readonly type: string = GET_CONTACTS;

  constructor(public payload: any = null) { }
}

export class GetContactsSuccess implements Action {
  readonly type: string = GET_CONTACTS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetContact implements Action {
  readonly type: string = GET_CONTACT;

  constructor(public payload: any) {}
}

export class GetContactSuccess  implements Action {
  readonly type: string = GET_CONTACT_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateContact implements Action {
  readonly type: string = CREATE_CONTACT;

  constructor(public payload: any) {}
}

export class CreateContactSuccess  implements Action {
  readonly type: string = CREATE_CONTACT_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteContact implements Action {
  readonly type: string = DELETE_CONTACT;

  constructor(public payload: any) {}
}

export class DeleteContactSuccess  implements Action {
  readonly type: string = DELETE_CONTACT_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateContact implements Action {
  readonly type: string = UPDATE_CONTACT;

  constructor(public payload: any) {}
}

export class UpdateContactSuccess  implements Action {
  readonly type: string = UPDATE_CONTACT_SUCCESS;

  constructor(public payload: any) {}
}

export class ResetContacts implements Action {
  readonly type: string = RESET_CONTACTS;

  constructor(public payload: any = null) { }
}


export type Actions = GetContact
  | GetContactSuccess
  | GetContacts
  | GetContactsSuccess
  | CreateContact
  | CreateContactSuccess
  | DeleteContact
  | DeleteContactSuccess
  | ResetContacts
  | UpdateContact
  | UpdateContactSuccess;
