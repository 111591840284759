<div class="dvtx-sidebar-container">
  <div class="pl-1" *ngIf="_process && !(sidebar$ | async)?.portal" style="position: relative;">
    <!-- Top sidebar links -->
    <dvtx-sidebar-navigation [classList]="'customNavigationPadding'"
                             [tasksRouteCount]="tasksRouteCount"
                             [hasAccessibleParent]="hasAccessibleParent$ | async"
                             [process]="_process"
                             [collapsed]="collapsed"
                             [routes]="sidebarWorkflowRoutes">
    </dvtx-sidebar-navigation>

    <hr>

    <!-- Project structure -->
    <dvtx-process-structure *ngIf="!documentListingPage" [processId]="_process?.id">
    </dvtx-process-structure>

    <!-- Document structure for the document view -->
    <dvtx-document-structure *ngIf="documentListingPage" [process]="_process" (onSelect)="select($event)">
    </dvtx-document-structure>
  </div>

  <!-- Portal panel for dynamic content -->
  <div class="pl-1" *ngIf="(sidebar$ | async)?.portal" style="position: relative;padding-top: 7px;">
    <ng-template [cdkPortalOutlet]="(sidebar$ | async)?.portal"></ng-template>
  </div>

  <!-- Organization Settings -->
  <div class="pl-1" *ngIf="showOrganizationSettings" style="position: relative;padding-top: 5px;">
    <dvtx-sidebar-navigation  [navigationWidth]="true"
      [title]="'BANNER.LINK.ORGANIZATION_SETTINGS'" [collapsed]="collapsed" [routes]="sidebarOrganizationRoutes">
    </dvtx-sidebar-navigation>

    <hr>
  </div>

  <!-- User Profile / User Settings -->
  <div class="pl-1" *ngIf="showUserSettings" style="position: relative;padding-top: 5px;">
    <dvtx-sidebar-navigation  [navigationWidth]="true" [title]="'BANNER.LINK.PERSONAL_SETTINGS'"
      [collapsed]="collapsed" [routes]="sidebarUserRoutes">
    </dvtx-sidebar-navigation>

    <hr>
  </div>
</div>
