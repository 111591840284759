import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {CmsBlog} from './cms-blog';
import {CmsBlogBuilder} from './cms-blog.builder';

@Injectable()
export class CmsBlogService {
  readonly BASE_PATH = 'api/v1/cms/blogs';

  constructor(private _http: ApiResourceService) {}

  getOne(organizationId): Observable<CmsBlog> {
    const builder = new CmsBlogBuilder();
    return <Observable<CmsBlog>>this._http.get<CmsBlogBuilder, CmsBlog>(builder, `${this.BASE_PATH}/${organizationId}`);
  }

  create(cmsBlog: CmsBlog): Observable<CmsBlog> {
    const builder = new CmsBlogBuilder();
    const payload = builder.toRequest(cmsBlog);
    return <Observable<CmsBlog>>this._http.post<CmsBlogBuilder, CmsBlog>(builder, `${this.BASE_PATH}`, payload);
  }

  update(cmsBlog: CmsBlog): Observable<CmsBlog> {
    const builder = new CmsBlogBuilder();
    const payload = builder.toRequest(cmsBlog);
    return <Observable<CmsBlog>>this._http.put<CmsBlogBuilder, CmsBlog>(builder, `${this.BASE_PATH}/${cmsBlog.id}`, payload);
  }

  destroy(cmsBlogId: string): Observable<CmsBlog> {
    const builder = new CmsBlogBuilder();
    return <Observable<CmsBlog>>this._http.del<CmsBlogBuilder, CmsBlog>(builder, `${this.BASE_PATH}/${cmsBlogId}`);
  }
}
