/**
 * Visual component for the message text.
 *
 * Author: Andreas Baier <andreas.baier@paperbird.org>
 */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'dvtx-snackbar-message-content',
  templateUrl: './snackbar-message-content.component.html',
  styleUrls: ['./snackbar-message-content.component.scss']
})
export class SnackbarMessageContentComponent {
  @Input() message: string;

  constructor() {
  }
}
