import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TenantMemberEffects} from './member.effects';
import {reducer} from './member.reducer';
import {stateKey} from './member.selectors';
import {TenantMemberService} from './member.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([TenantMemberEffects])
  ],
  providers: [
    TenantMemberEffects,
    TenantMemberService
  ]
})
export class TenantMemberStoreModule {
}
