import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
// import {StoreModule} from '@ngrx/store';
// import {EffectsModule} from '@ngrx/effects';
// import {MilestoneEffects} from './milestone.effects';
import {AuditThirdPartyConfirmationService} from './audit-third-party-confirmation.service';
// import {reducer} from './milestone.reducer';
// import {stateKey} from './milestone.selectors';

@NgModule({
  imports: [
    CommonModule,
    // StoreModule.forFeature(stateKey, reducer),
    // EffectsModule.forFeature([MilestoneEffects])
  ],
  providers: [
    // MilestoneEffects,
    AuditThirdPartyConfirmationService
  ]
})
export class AuditThirdPartyConfirmationStoreModule {
}
