import {Action} from '@ngrx/store';
import {QuickshareAttachment} from './quickshare-attachment';

export enum QuickshareAttachmentActionTypes {
  LoadAll = '[QuickshareAttachment] Load All',
  LoadAllSuccess = '[QuickshareAttachment] Load All Success',
  LoadAllFail = '[QuickshareAttachment] Load All Fail',
  LoadOneSuccess = '[QuickshareAttachment] Load One Fail',
  Remove = '[QuickshareAttachment] Remove',
  RemoveSuccess = '[QuickshareAttachment] Remove Success',
  RemoveFail = '[QuickshareAttachment] Remove Fail',
  Download = '[QuickshareAttachment] Download',
  DownloadSuccess = '[QuickshareAttachment] Download Success',
  DownloadFail = '[QuickshareAttachment] Download Fail',
  SaveDownload = '[QuickshareAttachment] Save Download',
  PickFileFromDms = '[QuickshareAttachment] PickFileFromDms',
  PickFileFromDmsSuccess = '[QuickshareAttachment] PickFileFromDms Success',
  PickFileFromDmsFail = '[QuickshareAttachment] PickFileFromDms Fail',
}

export class LoadAll implements Action {
  readonly type = QuickshareAttachmentActionTypes.LoadAll;

  constructor(public quickshareId: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = QuickshareAttachmentActionTypes.LoadAllSuccess;

  constructor(public attachments: QuickshareAttachment[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = QuickshareAttachmentActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = QuickshareAttachmentActionTypes.LoadOneSuccess;

  constructor(public attachment: QuickshareAttachment) {
  }
}

export class Remove implements Action {
  readonly type = QuickshareAttachmentActionTypes.Remove;

  constructor(public quickshareId: string, public attachmentId: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = QuickshareAttachmentActionTypes.RemoveSuccess;

  constructor(public attachment: QuickshareAttachment) {
  }
}

export class RemoveFail implements Action {
  readonly type = QuickshareAttachmentActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Download implements Action {
  readonly type = QuickshareAttachmentActionTypes.Download;

  constructor(public quickshareId: string, public id: string) {
  }
}

export class DownloadSuccess implements Action {
  readonly type = QuickshareAttachmentActionTypes.DownloadSuccess;

  constructor(public attachment) {
  }
}

export class DownloadFail implements Action {
  readonly type = QuickshareAttachmentActionTypes.DownloadFail;

  constructor(public payload: any = null) {
  }
}

export class SaveDownload implements Action {
  readonly type = QuickshareAttachmentActionTypes.SaveDownload;

  constructor(public filename: string, public content: string, public mimeType: string) {
  }
}

export class PickFileFromDms implements Action {
  readonly type = QuickshareAttachmentActionTypes.PickFileFromDms;

  constructor(public quickshareId: string, public dmsDocumentId: string, public dmsAccountType) {
  }
}

export class PickFileFromDmsSuccess implements Action {
  readonly type = QuickshareAttachmentActionTypes.PickFileFromDmsSuccess;

  constructor(public attachment) {
  }
}

export class PickFileFromDmsFail implements Action {
  readonly type = QuickshareAttachmentActionTypes.PickFileFromDmsFail;

  constructor(public payload: any = null) {
  }
}

export type QuickshareAttachmentActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOneSuccess
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Download
  | DownloadSuccess
  | DownloadFail
  | PickFileFromDms
  | PickFileFromDmsSuccess
  | PickFileFromDmsFail
  | SaveDownload;
