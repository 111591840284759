
import {of as observableOf, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {AuditAuditorCommunicationsBuilder} from 'app/shared/modules/api/builders/auditAuditorCommunications.builder';
import {AuditAuditorCommunications} from 'app/shared/modules/api/models/auditAuditorCommunications';
import { HttpClient } from '@angular/common/http';
import {environment} from 'environments/environment';



@Injectable()
export class AppendicesRequestCreateService {
  readonly BASE_PATH = 'api/v1/audit';
  basePath: string;

  constructor(private tokenService: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  createWorkflow(auditGroupId: string, item: AuditAuditorCommunications) {
    return this.tokenService.post(
      `${this.basePath}/auditings/${auditGroupId}/component_auditor_communications`,
      AuditAuditorCommunicationsBuilder.toRequest(item)
    ).pipe(map((x: any) => {
      return x['data']['id'];
    }))
  }


  getWorkflow(auditGroupId: string, id: string): Observable<AuditAuditorCommunications> {
    return this.tokenService.get(
      `${this.basePath}/auditings/${auditGroupId}/component_auditor_communications/${id}`,
    ).pipe(map((res: any) => {
      return AuditAuditorCommunicationsBuilder.fromResponse(res['data'])
    }))
  }

  updateWorkflow(obj: AuditAuditorCommunications) {
    return this.tokenService.put(
      `${this.basePath}/auditings/${obj.parentProcessId}/component_auditor_communications/${obj.id}`,
      AuditAuditorCommunicationsBuilder.toRequest(obj)
    )

  }

  submitSubareaVerifyAppendicesRequests(appendices, id: string): Observable<any> {
    return this.tokenService.post(
      `${this.basePath}/component_auditor_communications/${id}/send`,
      AuditAuditorCommunicationsBuilder.toRequest(appendices)
    ).pipe(
      map(() => {
        return true;
      }),
      catchError(() => {
        return observableOf(false);
      }),);
  }
}
