import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {reducer} from './society.reducer';
import {stateKey} from './society.selectors';
import {SocietyService} from './society.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer)
  ],
  providers: [
    SocietyService
  ]
})
export class SocietyStoreModule {
}
