import {ProcessFolderActions, ProcessFolderActionTypes} from './process-folder.actions';
import {adapter, initialState, State} from './process-folder.state';

export function reducer(state = initialState, action: ProcessFolderActions): State {
  switch (action.type) {
    case ProcessFolderActionTypes.Load:
      return {
        ...state,
        loading: true
      };
    case ProcessFolderActionTypes.LoadSuccess:
      return adapter.upsertOne(action.folder, {
        ...state,
        loading: false,
      });
    case ProcessFolderActionTypes.LoadFail:
      return {
        ...state,
        loading: false
      };
    case ProcessFolderActionTypes.Remove:
      return adapter.removeOne(action.id, {
        ...state,
        loading: false,
      });
  }
  return state;
}

