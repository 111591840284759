import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './task-event.state';

export const stateKey = 'task-event';
const getTaskEventState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getTaskEventEntities,
  selectAll: getAllTaskEvents,
} = adapter.getSelectors(getTaskEventState);

export const getEventForTask = (id: string) => createSelector(
  getAllTaskEvents,
  events => events.filter(event => event.taskId === id)
)

export const getLoadingState = createSelector(
  getTaskEventState,
  state => state.loading
);
