import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {Label} from "app/+store/label/label";
import {LabelOrigin} from "../../../../+store/label/label.interface";

@Component({
  selector: 'dvtx-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelsComponent implements OnInit, OnDestroy, AfterViewInit {
  sizeObserver;
  @ViewChild('labelsList') labelsList: ElementRef<HTMLDivElement>;
  _labelsIds: string[];
  _labels: Label[];
  contextualLabelsOrigins = [
    LabelOrigin.COLLECTOR_PRIORITY,
    LabelOrigin.COLLECTOR_STATUS,
    LabelOrigin.TASK_PRIORITY,
    LabelOrigin.TASK_STATUS
  ];

  @Input() set labels(labels: Label[]) {
    if (Array.isArray(labels)) {
      this._labels = labels.filter(label => {
        return label && this.filterOutContextual
          ? label.origin === LabelOrigin.NO_ORIGIN
          : label.origin !== LabelOrigin.DOCUMENTS_UNSORTED && label.title !== 'KANBAN.UNSORTED_GROUP';
      });
      this._labelsIds = this._labels.map(label => label.id);
    } else {
      this._labels = [];
      this._labelsIds = [];
    }
  }

  get labels(): Label[] {
    return this._labels;
  }

  @Input() canShowMore: boolean = false;
  @Input() allLabels: Label[];
  @Input() maxToShow: number = 5;
  @Input() readonly: boolean = false;
  @Input() showLabelsSelector: boolean = false;
  @Input() classList: string = '';
  @Input() addLabelButtonIsText: boolean = true;
  @Input() filterOutContextual: boolean = true;
  @Input() dynamicWidth: boolean = false;
  @Input() showAll: boolean = false;

  @Output() onClick: EventEmitter<Label> = new EventEmitter();
  @Output() onShowMoreClickFun: EventEmitter<any> = new EventEmitter();
  @Output() onAdd: EventEmitter<Label> = new EventEmitter();
  @Output() onRemove: EventEmitter<Label> = new EventEmitter();

  isShowMore = false;
  numberOfVisibleLabels = this.dynamicWidth ? 1 : this.maxToShow;

  private MAX_WIDTH_LABEL = 130;
  private LABEL_INDICATOR_WIDTH = 30;

  constructor(private cdr: ChangeDetectorRef, private zone: NgZone) {
  }

  ngOnInit() {
    // @ts-ignore
    this.sizeObserver = new ResizeObserver(this.resized);

  }

  ngAfterViewInit() {
    if (this.labelsList && this.dynamicWidth) {
      this.sizeObserver.observe(this.labelsList.nativeElement)
    }
  }

  resized = (entries, observer) => {
    for (let entry of entries) {
      const availableWidth = entry.contentRect.width;
      this.numberOfVisibleLabels = Math.max(0, Math.floor((availableWidth - this.LABEL_INDICATOR_WIDTH) / this.MAX_WIDTH_LABEL));
    }
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.sizeObserver.unobserve(this.labelsList.nativeElement)
  }
}
