import {NgModule} from '@angular/core';
import {TreeLibModule} from '../tree-lib/tree-lib.module';
import {DmsFilePickerComponent} from './containers/dms-file-picker/dms-file-picker.component';
import {DmsFilePickerSelectorDirective} from './directives/dms-file-picker-selector.directive';
import {DmsFilePickerDialogComponent} from './containers/dms-file-picker-dialog/dms-file-picker-dialog.component';
import {FileTransferOngoingDialogComponent} from './components/file-transfer-ongoing-dialog/file-transfer-ongoing-dialog.component';
import {DmsFolderPickerDialogComponent} from './containers/dms-folder-picker-dialog/dms-folder-picker-dialog.component';
import {DmsFolderPickerSelectorDirective} from './directives/dms-folder-picker-selector.directive';
import {DmsFolderPickerV3Component} from './containers/dms-folder-picker-v3/dms-folder-picker-v3.component';
import {FolderModule} from '../workflow-engine/modules/folder/folder.module';

@NgModule({
    imports: [
        TreeLibModule,
        FolderModule
    ],
    declarations: [
        DmsFilePickerComponent,
        DmsFilePickerSelectorDirective,
        DmsFolderPickerSelectorDirective,
        DmsFilePickerDialogComponent,
        FileTransferOngoingDialogComponent,
        DmsFolderPickerDialogComponent,
        DmsFolderPickerV3Component
    ],
    exports: [
        FolderModule,
        DmsFolderPickerV3Component,
        DmsFilePickerComponent,
        DmsFilePickerSelectorDirective,
        DmsFolderPickerSelectorDirective
    ]
})
export class FilePickerModule {
}
