import {AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {CsvColumn, CsvRow, KnownCsvError} from '../../services/csv-import.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import * as dayjs from 'dayjs';
import {UntypedFormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {InViewportMetadata} from 'ng-in-viewport';

class AlwaysShowErrorsStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid);
  }
}

@Component({
  selector: 'dvtx-csv-import-display-table',
  templateUrl: './csv-import-display-table.component.html',
  styleUrls: ['./csv-import-display-table.component.scss']
})
export class CsvImportDisplayTableComponent implements AfterViewInit {
  public dataSource: MatTableDataSource<CsvRow>;
  @Input() public set parsedCsvRows(csvRows: CsvRow[]) {
    let index = 0;
    csvRows.forEach(row => row.id = index++);
    this.dataSource = new MatTableDataSource(csvRows);
    setTimeout(() => {
      this._cdr.detectChanges();
    }, 200);
  }

  isInViewPort = {};

  public _columns: CsvColumn[];
  public displayedColumns = [];
  @Input() public set columns(columns: CsvColumn[]) {
    this._columns = columns;
    this.displayedColumns = ['errors', ...columns.filter(c => !c.isHidden).map((column) => column.key)];
  }
  @Input() public title: string;
  public csvErrors = KnownCsvError;

  public matcher = new AlwaysShowErrorsStateMatcher();

  constructor(private _cdr: ChangeDetectorRef) {
  }

  public valueChange(newValue, columnIdx, row) {
    if (newValue instanceof Date) {
      newValue = dayjs(newValue).format('DD.MM.YYYY');
    }

    row.data[columnIdx] = newValue;

    if (row.value) {
      row.value[this._columns[columnIdx].key] = newValue;
    }

    this._cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._cdr.detectChanges();
    }, 200);
  }

  public showItem(event, element) {
    const { [InViewportMetadata]: { entry }, target, visible } = event;
    const inViewport = Object.assign({}, this.isInViewPort);
    if (!visible) {
      inViewport[element] = false;
    } else {
      inViewport[element] = true;
    }
    this.isInViewPort = inViewport;
    this._cdr.detectChanges();
  }

  click() {
    this._cdr.detectChanges();
  }
}
