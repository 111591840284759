import { Component, Input } from '@angular/core';
import { Location} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'dvtx-backlink',
  templateUrl: './backlink.component.html'
})
export class BackLinkComponent {
  location = this._location;

  @Input() title: string;
  @Input() route: string = null;

  constructor(private _location: Location, private _router: Router) {}

  goBack() {
    if (this.route) {
      this._router.navigate([this.route]);
    } else {
      this._location.back();
    }
  }
}
