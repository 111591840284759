import {AuditContracteeActions, AuditContracteeActionTypes} from './audit-contractee.actions';
import {adapter, initialState, State} from './audit-contractee.state';

export function reducer(state = initialState, action: AuditContracteeActions): State {
  switch (action.type) {
    case AuditContracteeActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case AuditContracteeActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case AuditContracteeActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

