import {OrganizationActions, OrganizationActionTypes} from './organization.actions';
import {adapter, initialState, State} from './organization.state';

export function reducer(state = initialState, action: OrganizationActions): State {
  switch (action.type) {
    case OrganizationActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case OrganizationActionTypes.LoadAllSuccess:
      const sel = state.selected;
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        selected: sel !== undefined ? sel : action.payload[0] ? String(action.payload[0].id) : undefined
      });
    case OrganizationActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false
      };
    case OrganizationActionTypes.Select:
      return {
        ...state,
        selected: action.id
      };
    case OrganizationActionTypes.SelectSuccess:
      return {
        ...state,
        selected: action.id
      };
    case OrganizationActionTypes.SelectAddressBook:
      return {
        ...state,
        selected: action.id
      };
    case OrganizationActionTypes.SelectAddressBookSuccess:
      return {
        ...state,
        selected: action.id
      };
    case OrganizationActionTypes.LoadDetailed:
      return {
        ...state,
        loading: true
      };
    case OrganizationActionTypes.LoadDetailedSuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: false
      });
    case OrganizationActionTypes.LoadDetailedFail:
      return {
        ...state,
        loading: false
      };
    case OrganizationActionTypes.UpdateOrga:
      return {
        ...state,
        loading: true
      };
    case OrganizationActionTypes.UpdateOrgaSuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: false
      });
    case OrganizationActionTypes.UpdateOrgaFail:
      return {
        ...state,
        loading: false
      };
  }
  return state;
}
