import {UserProfile} from '../models/userProfile';

export class ProfileBuilder {
  static fromResponse(response: {}): UserProfile {
    const actualObject = response as ProfileJson;
    return new UserProfile (
        actualObject.title,
        actualObject.first_name,
        actualObject.last_name,
        actualObject.gender,
        actualObject.email,
    );
  }

  static toRequest(val: UserProfile) {
    return {
      data: {
        title: val.title,
        first_name: val.firstName,
        last_name: val.lastName,
        gender: val.gender,
        email: val.email,
      }
    }
  }
}

interface ProfileJson {
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  gender: string;
}
