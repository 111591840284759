import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from 'app/models/user';
import {WebsocketService} from 'app/services';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil, filter, distinctUntilKeyChanged, tap, catchError} from 'rxjs/operators';
import {environment} from 'environments/environment';
import {ProcessEventType} from 'app/+store/process-event/process-event.interface';
import {Download} from 'app/+store/process-artifact/file-api-resource.service';
import {of} from 'rxjs/internal/observable/of';
import {ExportReadyEvent} from 'app/+store/process-event/process-event';
import {ProcessArtifactService} from 'app/+store/process-artifact/process-artifact.service';
import {NotificationService} from '../../modules/notification/services/notification.service';
import {ProcessEventBuilder} from 'app/+store/process-event/process-event.builder';

@Component({
  selector: 'dvtx-actioncable',
  template: ''
})
export class ActioncableComponent implements OnDestroy, OnInit {
  private onDestroy: Subject<void> = new Subject<void>();
  private userChannelSubscription$;
  private uid;
  private onDownloadComplete = new Subject<void>();

  constructor(private store: Store<AppState>,
              private artifactSvc: ProcessArtifactService,
              private notifyService: NotificationService,
              private ws: WebsocketService) { }

  ngOnInit() {
    this.store.select('currentUser')
      .pipe(filter(u => !!u), distinctUntilKeyChanged('uid'), takeUntil(this.onDestroy))
      .subscribe((user: User) => {
        if (user && user.id === this.uid) {
          return;
        }
        if (user && user.id) {
          this.uid = user.id;
          this.connectUserChannel(user);

        } else {
          if (this.userChannelSubscription$) {
            const userChannel = this.userChannelSubscription$;
            try {
              userChannel.unsubscribe();
            } catch (error) {
              console.error(error);
            }
            this.userChannelSubscription$ = null;
          }
          this.uid = null;
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.destroyUserChannel();
  }

  connectUserChannel(user) {
    if (!environment.production) {
      console.log('[Websocket] User Channel: Connected.');
    }
    if (this.userChannelSubscription$) {
      return;
    }
    this.userChannelSubscription$ = this.ws.subscribe(
      user,
      'User::AccountChannel',
      'follow',
      { user_id: user.id },
      (event) => {
        if (!environment.production) {
          console.log('[Websocket] User Channel: Data received', event);
        }

        if (event && event.data && event.data.type) {
          switch (event.data.type) {
            case 'export_event_export_ready':
              console.log('[Websocket] User Channel: Export ready', event.data.type);
              const builder = new ProcessEventBuilder(event.data.attributes.resource_id)
              this.downloadExport(<ExportReadyEvent>builder.fromResponse(event.data));
              break;
            default:
              console.log('[Websocket] User Channel: Unknown user action. Skipping...', event.data.type);
          }
        }
      });
  }

  destroyUserChannel() {
    if (this.userChannelSubscription$) {
      if (!environment.production) {
        console.log('[Websocket] User Channel: Disconnecting...');
      }
      try {
        this.userChannelSubscription$.unsubscribe();
      } catch (error) {
        console.error(error);
      }
    }
  }

  private downloadExport(event: ExportReadyEvent): void {
    const fileName = event.title;
    this.notifyService.info('FILE_INBOX.PACKSTATION.PRE_DOWNLOAD_INFO')
    this.artifactSvc.downloadProcessExport(event.processId, event.export, fileName)
      .pipe(
        tap((download: Download) => {
          if (download.state === 'DONE') {
            this.onDownloadComplete.next();
          }
        }),
        catchError(err => {
          console.error(err);
          this.onDownloadComplete.next();
          this.notifyService.error('FILE_INBOX.EVENTS.FILE_NOT_FOUND')
          return of({state: 'PENDING', progress: 0, content: null} as Download);
        }),
        takeUntil(this.onDownloadComplete)
      ).subscribe();
  }
}
