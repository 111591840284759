<ng-container *ngIf="!disabled">
  <dvtx-tooltip [tooltipText]="title" [position]="'top left'">
    <button
      mat-icon-button
      color="gray"
      cdk-overlay-origin
      (click)="openSelector()"
      #dropdown="cdkOverlayOrigin"
      [disableRipple]='true'>
      <ng-container>
        <mat-icon style="float: left;" *ngIf="!selectedData || selectedData.length === 0">{{icon}}</mat-icon>
        <span style="float: left;"
              *ngIf="selectedData && selectedData.length === 1">{{(selectedData[0]?.name | slice:0:28) + (selectedData[0]?.name?.length > 28 ? '...' : '')}}</span>
        <span style="float: left;"
              *ngIf="selectedData && selectedData.length > 1">{{(selectedData[0]?.name | slice:0:20) + (selectedData[0]?.name?.length > 20 ? '...' : '')}}
          + {{selectedData.length - 1}} {{'GENERAL.OTHERS' | translate}} </span>
      </ng-container>
    </button>
  </dvtx-tooltip>

  <ng-template
    cdk-connected-overlay
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayOrigin]="dropdown"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="showDropdown"
    (backdropClick)="showDropdown = false">
    <div class="mat-elevation-z8 p-0 list--container">
      <mat-form-field class="mat-select-search-input">
        <input
          matInput
          [placeholder]="acceptTypedEmail ? 'Type and email or search' : 'GENERAL.SEARCH' | translate"
          name="search"
          autocomplete="new-search"
          #searchSelectInput
          (keydown)="_handleKeydown($event)"
          (keyup)="onInputChange($event.target.value)"/>
        <span matPrefix><mat-icon matPrefix>search</mat-icon></span>
      </mat-form-field>

      <cdk-virtual-scroll-viewport [itemSize]="36" style="height: 360px; min-width: 300px;">
        <div class="px-3 py-1">
          <ul class="m-0">
            <ng-container *ngIf="contacts">
              <a style="cursor: default;" (click)="$event.stopPropagation();$event.preventDefault()"
                 *ngIf="selectedData && selectedData.length > 0">{{'CONTACTS.SELECTED_CONTACTS' | translate}}</a>
              <div style="position: relative;padding: 10px;" [matTooltip]="'CONTACTS.SELECTED_CONTACTS' | translate"
                   *ngIf="selectedData && selectedData.length > 0">
                <div *ngFor="let item of selectedData">
                  <div style="cursor: pointer;" (click)="onUnSelection.emit(item);showDropdown = false;">
                    <mat-icon>clear</mat-icon> &nbsp;
                    <dvtx-avatar style="position: absolute;" mat-list-icon [size]="'xs'"
                                 [email]="item?.email"
                                 [showTitle]='true'></dvtx-avatar>
                  </div>
                </div>
              </div>
              <a *ngIf="selectedData && selectedData.length > 0" style="cursor: default;"
                 (click)="$event.stopPropagation();$event.preventDefault()">{{'CONTACTS.CONTACTS' | translate}}</a>
              <div style="padding: 10px;">
                <li #firstLi
                    tabindex="-1" class="mb-1"
                    *cdkVirtualFor="let person of (filteredPersons | async)"
                    (click)="selectPerson(person)">
                  <dvtx-avatar mat-list-icon [size]="'xs'" [email]="person?.email"
                               [showTitle]='true'></dvtx-avatar>
                </li>
                <li
                  *ngIf="searchTerm !== '' && !(filteredPersons | async).length && acceptTypedEmail"
                  [class.disabled]="!this.searchTermIsEmail"
                  (click)="selectPerson({email: searchTerm})">
                  <a style="cursor: default;"
                     (click)="$event.stopPropagation();$event.preventDefault()">{{'CONTACTS.CONTACTS' | translate}}</a>
                  <dvtx-avatar mat-list-icon [size]="'xs'" [email]="searchTerm"
                               [showTitle]='true'></dvtx-avatar>
                </li>
              </div>
            </ng-container>
            <ng-container *ngIf="clients">
              <a style="cursor: default;" (click)="$event.stopPropagation();$event.preventDefault()"
                 *ngIf="selectedData && selectedData.length > 0">{{'CONTACTS.SELECTED_CLIENTS' | translate}}</a>
              <div style="position: relative;padding: 10px;margin-left: 30px;"
                   [matTooltip]="'CONTACTS.SELECTED_CLIENTS' | translate"
                   *ngIf="selectedData && selectedData.length > 0">
                <div *ngFor="let item of selectedData">
                  <div class="mb-1" style="cursor: pointer;" (click)="onUnSelection.emit(item);showDropdown = false;">
                    <mat-icon style="position: absolute;left: -25px;">clear</mat-icon> &nbsp;
                    <mat-icon style="position: absolute;left: -2px;" svgIcon="clients"></mat-icon> &nbsp;
                    <strong>{{item?.name}}</strong>
                  </div>
                </div>
              </div>
              <a *ngIf="selectedData && selectedData.length > 0" style="cursor: default;"
                 (click)="$event.stopPropagation();$event.preventDefault()">{{'CLIENT.CLIENTS' | translate}}</a>
              <div style="position: relative;padding: 10px;margin-left: 10px;">

                <li #firstLi
                    tabindex="-1" class="mb-1"
                    *cdkVirtualFor="let person of (filteredPersons | async)"
                    (click)="selectPerson(person)">
                  <mat-icon style="position: absolute;left: -10px;" svgIcon="clients"></mat-icon> &nbsp;
                  <strong>{{person?.name}}</strong>
                </li>

                <li
                  *ngIf="searchTerm !== '' && !(filteredPersons | async).length && acceptTypedEmail"
                  [class.disabled]="!this.searchTermIsEmail"
                  (click)="selectPerson({email: searchTerm})">
                  <a style="cursor: default;"
                     (click)="$event.stopPropagation();$event.preventDefault()">{{'CLIENT.CLIENTS' | translate}}</a>
                  <mat-icon svgIcon="clients"></mat-icon>
                  <strong>{{item?.name}}</strong>
                </li>
              </div>
            </ng-container>
          </ul>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-template>
</ng-container>
