import {ContactImportItem} from '../models/contact-import-item';
import {ContactImportItemBuilder} from './contact-import-item.builder';
import {AuditContactVerificationProcess} from '../models/audit-contact-verification-process';

export namespace AuditContactVerificationProcessBuilder {
  export function fromResponse(response, catalog = null): AuditContactVerificationProcess {
    const attrs = response.attributes;

    return new AuditContactVerificationProcess(
      response.id,
      attrs.status,
      attrs.due_date,
      attrs.received_at,
      attrs.contact_import_items.map((item) => ContactImportItemBuilder.fromResponse(item)),
    );
  }

  export function toRequest(record: ContactImportItem) {
    return null;
  }
}
