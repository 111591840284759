import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, State } from './kanban-board.state';

export const stateKey = 'kanban-board';
const getKanbanBoardState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getKanbanBoardEntities,
  selectAll: getAllKanbanBoards,
} = adapter.getSelectors(getKanbanBoardState);

export const getSelected = createSelector(
  getKanbanBoardState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getKanbanBoardEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllKanbanBoards,
  (entities) => entities
);

export const getProcessBoards = (processId: string) => createSelector(
  getAll,
  (entities) => entities.filter(entity => entity.processId === processId)
);

export const getOneByTitle = (title: string) => createSelector(
  getAll,
  (entities) => entities.find(entity => entity.title === title)
);

export const getSelectedBoard = createSelector(
  getAll,
  (entities) => entities.find(entity => entity.isSelected)
);

export const loadingState = createSelector(
  getKanbanBoardState,
  (state) => state.loading
);
