import {IShoppingCart} from '../models/shopping-cart.interface';
import { LineItem } from '../models/line-item';
import { RelationBuilder } from './relation.builder';
import { InvoiceAddress } from '../models/invoice-address';
import { PaymentOption } from '../models/payment-option';

/**
 * Create LineItems - the respresentation of articles in
 * the shopping cart.
 */
export class ShoppingCartBuilder {
  public static toRequest(_: IShoppingCart) {
    throw new Error('Not implemented');
  }

  public static fromResponse(response, catalog = null): IShoppingCart {
    const shoppingCart = Object.assign({ lineItems: [], invoiceAddress: null, paymentOption: null });

    if (response.relationships && catalog) {
      const relationships = response.relationships;
      shoppingCart.lineItems = <LineItem[]>RelationBuilder.build(relationships.line_items, catalog);
      shoppingCart.invoiceAddress = <InvoiceAddress>RelationBuilder.build(relationships.invoice_address, catalog);
      shoppingCart.paymentOption = <PaymentOption>RelationBuilder.build(relationships.payment_option, catalog);
    }
    return <IShoppingCart>shoppingCart;
  }
}
