import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'dvtx-comment-reaction-bar',
  templateUrl: './comment-reaction-bar.component.html',
  styleUrls: ['./comment-reaction-bar.component.scss']
})
export class CommentReactionBarComponent implements OnInit {
  @Input() reactions = [];
  @Output() onReaction = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }
}
