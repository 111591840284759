import {IThirdPartyConfirmation, IThirdPartyConfirmationRow} from './thirdPartyConfirmation';
import {RequestCreation} from './request-creation.interface';
import {RequestType} from './request-type.enum';

export class ThirdPartyConfirmationClaimBalanceRow implements IThirdPartyConfirmationRow {
  constructor(public id: number,
              public color: string,
              public lfd: string,
              public referenced_at: string,
              public due_date: string,
              public comment: string,
              public language: string,
              public request_type: RequestType,
              public debitor_name: string,
              public debitor_nr: string,
              public saldo: string,
              public waehrung: string,
              public kontierung: string,
              public debitor_ansprechpartner_title: string,
              public debitor_ansprechpartner_name: string,
              public debitor_ansprechpartner_email: string,
              public third_party_comment: string,
              public third_party_balance_confirmed: boolean,
              public third_party_balance_manual: string
  ) {

  }

  toRequestCreation(): RequestCreation {
    return {
      companyName: this.debitor_name,
      id: this.id,
      color: this.color,
      sequenceNo: this.lfd,
      requestDate: this.referenced_at,
      dueDate: this.due_date,
      fileAttachments: undefined,
      contactPerson: {
        title: this.debitor_ansprechpartner_title,
        name: this.debitor_ansprechpartner_name,
        email: this.debitor_ansprechpartner_email,
      },
      requestType: this.request_type,
      language: this.language,
      comment: this.comment,
      balanceItem: {
        requestDate: this.referenced_at,
        dueDate: this.due_date,
        balance: this.saldo,
        currency: this.waehrung,
        kontierung: this.kontierung,
        isBalanceConfirmed: this.third_party_balance_confirmed,
        balanceManualInput: this.third_party_balance_manual,
        comment: this.third_party_comment
      }
    };
  }
}

export class ThirdPartyConfirmationClaimBalance implements IThirdPartyConfirmation {
  emailAttachmentFilename: string;
  state: string;

  constructor(public id: number,
              public type: string,
              public parent_process_id: number,
              public referenced_at: string,
              public due_date: string,
              public color: string,
              public language: string,
              public request_type: RequestType,
              public rows: ThirdPartyConfirmationClaimBalanceRow[]) {

  }

  toForm() {
    return undefined;
  }
}
