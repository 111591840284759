  <div class="mt-1 mb-2">
    <div class="d-flex mt-1 mb-2 align-items-center col-8">
      <div>
        <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
      </div>
      <p [innerHTML]="'AUTH.DESCRIPTION_TFA' | translate" class="mb-0"></p>
    </div>
  </div>
<div class="activate-button">
  <button mat-raised-button color="primary" class="status-activate"
    *ngIf="twoFactorAuthStatus?.phone && twoFactorAuthStatus?.confirmedAt && twoFactorAuthStatus?.activatedAt && !Activated2FA && Activated2FA != false"
    (click)="enable2faFunction(7);">
    <span>{{'AUTH.2FA_IS_ACTIVE' | translate}} </span>
    <p>{{'AUTH.DEACTIVATION_2FA' | translate}}</p>
  </button>
  <button mat-raised-button color="primary" *ngIf="Activated2FA === false" class="status-deactivated-successfully"
    (click)="enable2faFunction(1);">
    <p> {{'AUTH.ACTIVATION_2FA' | translate}}</p>
  </button>
  <button mat-raised-button color="primary" *ngIf="Activated2FA === true" class="status-acivated-successfully"
    (click)="enable2faFunction(7);">
    <span> {{'AUTH.ACTIVATED_2FA_SUCSESSFULY' | translate}} </span>
    <p> {{'AUTH.DEACTIVATION_2FA' | translate}}</p>
  </button>
  <button mat-raised-button color="primary" class="status-deactive"
    *ngIf="!(twoFactorAuthStatus?.activatedAt) && !Activated2FA && Activated2FA != false"
    (click)="enable2faFunction(1);">
    <p> {{'AUTH.ACTIVATION_2FA' | translate}}</p>
  </button>
  <p class="p-2" *ngIf="twoFactorAuthStatus?.updatedAt"> <i> {{'FOOTER.LAST_UPDATED' | translate}}:
      {{twoFactorAuthStatus?.updatedAt | date : 'short'}}</i> </p>

  <div class="user-status">
    <br>
    <div>
      <div class="check-status" *ngIf="twoFactorAuthStatus?.activatedAt">
        <mat-icon class="status-color-success">check_circle</mat-icon>
        <p> {{'AUTH.2FA_IS_ACTIVE' | translate}} </p>
      </div>
      <div class="check-status" *ngIf="!(twoFactorAuthStatus?.activatedAt)">
        <mat-icon class="status-color-danger">cancel</mat-icon>
        <p> {{'AUTH.2FA_IS_DEACTIVE' | translate}} </p>
      </div>
      <div class="check-status"
        *ngIf="twoFactorAuthStatus?.totpSmsEnabled && twoFactorAuthStatus?.confirmedAt && twoFactorAuthStatus?.activatedAt">
        <mat-icon class="status-color-success">check_circle</mat-icon>
        <p> {{'AUTH.SMS_SERVICE_ACTIVE' | translate}} </p>
      </div>
      <div class="check-status" *ngIf="!(twoFactorAuthStatus?.activatedAt)">
        <mat-icon class="status-color-danger">cancel</mat-icon>
        <p> {{'AUTH.SMS_SERVICE_DEACTIVE' | translate}} </p>
      </div>
      <div class="check-status"
        *ngIf="twoFactorAuthStatus?.totpQrEnabled && twoFactorAuthStatus?.totpSmsEnabled && twoFactorAuthStatus?.confirmedAt && twoFactorAuthStatus?.activatedAt">
        <mat-icon class="status-color-success">check_circle</mat-icon>
        <p> {{'AUTH.APP_SERVICE_ACTIVE' | translate}} </p>
      </div>
      <div class="check-status" *ngIf="!twoFactorAuthStatus?.totpQrEnabled">
        <mat-icon class="status-color-danger">cancel</mat-icon>
        <p> {{'AUTH.APP_SERVICE_DEACTIVE' | translate}} </p>
        <a class="p-1" *ngIf="twoFactorAuthStatus?.totpSmsEnabled && !twoFactorAuthStatus?.totpQrEnabled && twoFactorAuthStatus?.activatedAt "
          (click)="enable2faFunction(5)">
          &nbsp; ({{'AUTH.ACTIVATE' | translate}})
        </a>
      </div>
    </div>
  </div>
</div>
<dvtx-add-authentification [twoFactorAuthEnabled]="twoFactorAuthEnabled | async" [activateStep]="StepActivation"
  (activated)="submittedActivate($event)"></dvtx-add-authentification>