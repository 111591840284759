import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {Client} from './client';
import {ClientContactType} from './client.interface';

export class ContactClientBuilder implements IApiResourceBuilder<any> {
  total = 1;
  perPage = 1;
  records = 1;

  fromResponse(data): any {
    const list = [];
    data.attributes.mappings.forEach(element => {
      list.push({contactId : element.contact_id , relationId: element.relation_id , role : element.role, clientId: element.client_id});
    });
    return list;
  }

  toRequest(client: any) {
    return {
      data: {
        id: client.id,
        type: client.type,
        attributes: {
          contact_type: client.contactType,
          contact_uuid: client.contactId,
          gender: client.gender,
          title: client.title,
          first_name: client.firstName,
          last_name:  client.lastName,
          name: client.name,
          legal_form: client.legalForm,
          street: client.street,
          street_no: client.streetNo,
          city: client.city,
          zip: client.zip,
          phone: client.phone,
          email: client.email,
          client_id: client.clientId,
          reference_id: client.referenceId,
          datev_consultant_no: client.datevConsultantNo,
          datev_client_no: client.datevClientNo,
          bookman_client_id: client.bookmanClientId,
          bookman_client_name: client.bookmanClientName
        }
      }
    };
  }

}
