import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Licence} from 'app/shared/modules/api/models/licence';

export interface State extends EntityState<Licence> {
  my?: string
  loading: boolean
}

export const adapter = createEntityAdapter<Licence>();

export const initialState: State = adapter.getInitialState({
  my: undefined,
  loading: false,
});
