import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Subject, BehaviorSubject, forkJoin, of} from 'rxjs';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {SplitViewDialogComponent} from 'app/shared/modules/page-container/split-view-dialog/split-view-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {INode} from 'app/+store/dms-folder/node.interface';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import {catchError, first, take, takeUntil} from 'rxjs/operators';
import {DmsFolderActions, OrganizationSelectors, ProcessActions} from 'app/+store';
import {DmsFolder} from 'app/+store/dms-folder/dms-folder';
import {DmsFolderService} from 'app/+store/dms-folder/dms-folder.service';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DmsAccountType} from 'app/+store/dms-folder/dms-folder.interface';
import {Process} from 'app/+store/process/process';
import {DmsContactService} from 'app/+store/dms-contact/dms-contact.service';
import {DmsContact} from 'app/+store/dms-contact/dms-contact';
import { Router } from '@angular/router';


export interface IAcl {
  name: string;
  sid: string;
  email: string;
  write: boolean;
  read: boolean;
}

export enum FolderInfoAction {
  None = 'None',
  Button = 'Button',
  MenuEntry = 'MenuEntry'
}

export interface IMinimalFolderInfo {
  id: string;
  accountType: DmsAccountType;
  info: boolean;
}

@Component({
  selector: 'dvtx-dms-folder-info',
  templateUrl: './dms-folder-info.component.html',
  styleUrls: ['./dms-folder-info.component.scss']
})
export class DmsFolderInfoComponent implements AfterViewInit, OnDestroy, OnInit {
  DmsAccountType = DmsAccountType;
  FolderInfoAction = FolderInfoAction;

  @Input() action: FolderInfoAction = FolderInfoAction.None;

  onDestroy = new Subject();
  @ViewChild('buttonsToolbar', { static: true }) buttonsToolbar: TemplateRef<any>;
  @ViewChild('context', { static: true }) context: TemplateRef<any>;
  @ViewChild('title', { static: true }) title: TemplateRef<any>;
  buttonsToolbarPortal: Portal<any>;
  contextPortal: Portal<any>;
  titlePortal: Portal<any>;
  dialogRef: MatDialogRef<SplitViewDialogComponent>;
  saveOngoing = false;

  @Input() buttonColor = 'default';
  @Input() process;

  form: UntypedFormGroup;
  newPerson = new UntypedFormControl(null);
  excludedIds = new BehaviorSubject<string[]>([]);

  loading = false;
  id: string;

  _folder: IMinimalFolderInfo;
  dmsFolder: DmsFolder;
  contacts: DmsContact[];
  public selectedTabIndex = 0;

  @Input() set folder(f: IMinimalFolderInfo) {
    this._folder = f;
    if (f && f.id !== this.id) {
      // this.loading = true;
      this.id = f.id;
      // this._fetchFolderDetails(f);
    }
  }

  constructor(private _dialog: MatDialog,
              private _store: Store<AppState>,
              private router: Router,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService,
              private _viewContainerRef: ViewContainerRef,
              private _svc: DmsFolderService,
              private _contactSvc: DmsContactService,
              private _fb: UntypedFormBuilder,
              private _cdr: ChangeDetectorRef,
              public avatarService: AvatarService) {
  }

  ngOnInit() {
    this.form = this._initForm();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this._initTitlePortal();
      this._initContextPortal();
    });
    // setTimeout(_ => this.openFolderInfoDialog(), 100);
  }

  savePermissions() {
    const ctrls = this.form.get('accessors')['controls'];
    const calls = [];
    ctrls.forEach(ctrl => {
      if (ctrl.dirty) {
        const value = ctrl.value;
        const call = this._svc.chmod(this.dmsFolder.id, value.sid, value.read, value.write, this.dmsFolder.dmsAccountType).pipe(first(), catchError(error => of(error)));
        calls.push(call);
      }
    });
    if (calls.length > 0) {
      this.saveOngoing = true;
      forkJoin(calls).subscribe(responses => {
        this.saveOngoing = false;
        this.refresh();
      }, (err) => {
        console.error(err);
        this.saveOngoing = false;
        this.refresh();
      });
    } else {
      this.saveOngoing = false;
    }
    this.form.markAsPristine();
  }

  toggleWrite($event, i) {
    try {
      if ($event.checked === false) {
        const accessors = this.form.get('accessors') as UntypedFormArray;
        accessors.controls[i].patchValue({ write: false });
      }
    } catch (err) {
      console.error(err)
    }
  }

  toggleRead($event, i) {
    try {
      if ($event.checked === true) {
        const accessors = this.form.get('accessors') as UntypedFormArray;
        accessors.controls[i].patchValue({ read: true });
      }
    } catch (err) {
      console.error(err)
    }
  }

  openFolderInfoDialog() {
    if (!this._folder) {
      return;
    }
    this.loading = true;
    this._fetchFolderDetails(this._folder);

    this._initTitlePortal();
    this._initContextPortal();

    this.buttonsToolbarPortal = new TemplatePortal(
      this.buttonsToolbar,
      this._viewContainerRef
    );

    this.dialogRef = this._dialog.open(SplitViewDialogComponent, {
      data: {
        color: '#007aff',
        icon: null,
        title: this.titlePortal,
        context: this.contextPortal,
        buttonsToolbar: this.buttonsToolbarPortal
      }
    });
    this.dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(res => {
      this.selectedTabIndex = 0;
    })
  }

  addPerson($event) {
    if ($event && $event.id && this.dmsFolder && this.dmsFolder.users) {
      const found = this.dmsFolder.users.find(user => user.email === $event.email);
      if (!found) {
        const name = $event.firstName ? `${$event.firstName} ${$event.lastName}` : $event.userName;
        this.addAccessor({
          read: true,
          write: true,
          email: $event.email,
          sid: $event.id,
          name: name
        }, true);
        if ($event.email) {
          const excludedIds = this.excludedIds.value;
          const newExcludedIds = [];
          for (let i = 0; i < excludedIds.length; ++i) {
            newExcludedIds.push(excludedIds[i]);
          }
          newExcludedIds.push($event.email);
          this.excludedIds.next(newExcludedIds);
        }
        this.form.markAsDirty();
      }
    }
    this.newPerson.reset();
  }

  // add(uid, isAdmin = false, isOwner = false, id = null, recursive = false): void {
  //   const accessors = this.form.get('accessors') as FormArray;
  //   if (accessors.value.map(v => v.sid).includes(uid)) return;
  //   if (this._isCurrentUser(uid)) return;
  //
  //   const idx = this.toBeRemovedUids.indexOf(uid, 0);
  //   if (idx > -1) {
  //     this.toBeRemoved.splice(idx, 1);
  //     this.toBeRemovedUids.splice(idx, 1);
  //   }
  //   participants.push(this._addParticipant(uid, isAdmin, isOwner, id, recursive));
  //   this.form.markAsDirty();
  //   this.excludedIds.next([
  //     ...this.excludedIds.value.filter( email => email !== uid ),
  //     uid
  //   ]);
  // }

  addAccessor(user: IAcl, markDirty = false) {
    const accessors = this.form.get('accessors') as UntypedFormArray;
    accessors.push(this._addAccessor(user, markDirty));
  }

  _addAccessor(user: IAcl, markDirty) {
    const fg = this._fb.group({
      sid: user.sid,
      email: user.email,
      name: user.name,
      write: user.write,
      read: user.read,
    });
    if (markDirty) {
      fg.markAsDirty();
    }
    return fg;
  }

  closeDialog() {
    try {
      if (this.dialogRef) {
        this.dialogRef.close()
      }
    } catch (err) {
      console.error('Cannot close dialog', err);
    }
  }

  navigateToProcess(process: Process) {
    this._store.dispatch(new ProcessActions.RunCommand(process.id, 'show', 'on_click'));
    this.closeDialog();
  }

  refresh() {
    this._store.dispatch(new DmsFolderActions.Refresh(DmsAccountType.Private));
    this._store.select(OrganizationSelectors.getSelected)
      .pipe(take(1))
      .subscribe(org => {
        if (!!org) {
          this._store.dispatch(new DmsFolderActions.Refresh(DmsAccountType.Organization));
        }
      });
  }

  private _initContextPortal() {
    if (this.contextPortal) {
      return;
    }
    this.contextPortal = new TemplatePortal(
      this.context,
      this._viewContainerRef
    );
  }

  private _initTitlePortal() {
    if (this.titlePortal) {
      return;
    }
    this.titlePortal = new TemplatePortal(
      this.title,
      this._viewContainerRef
    );
  }

  private _fetchFolderDetails(node: IMinimalFolderInfo) {
    this._svc.details(node.id, node.accountType)
      .pipe(first())
      .subscribe(folder => {
        this.dmsFolder = folder;
        this.form = this._initForm();
        const excludedIds = [];
        if (this.dmsFolder && this.dmsFolder.users && this.dmsFolder.users.length) {
          this.dmsFolder.users.forEach(u => {
            const user = u as IAcl;
            if (user.email) {
              excludedIds.push(user.email);
            }
            this.addAccessor(user);
          });
          this.form.markAsPristine();
        }
        this.excludedIds.next(excludedIds);
        this.loading = false;
        this.saveOngoing = false;
        this._cdr.detectChanges();
      }, (err) => this._handleFatalError(err));
  }

  private _handleFatalError(error) {
    this.loading = false;
    this.saveOngoing = false;
    this.dmsFolder = null;
    console.error('DmsFolderInfoComponent#Node', this._folder);
    console.error('DmsFolderInfoComponent#handleError', error);
    if (error && error.error && error.error.errors && error.error.errors.length > 0) {
      this._notifyService.error(error.error.errors[0].title);
    }
    this._cdr.detectChanges();
  }

  private _initForm() {
    return this._fb.group({
      accessors: this._fb.array([])
    });
  }

  goToPermissionView() {
    this.closeDialog();
    this.router.navigate([`/workflow/${this.process.id}/permissions`]);
  }
}
