import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './document-preview.state';

export const stateKey = 'document-preview';
const getDocumentPreviewState = createFeatureSelector<State>(stateKey);

/**
 * HELP: Selectors are predefined queries aka prepared statements.
 */

export const {
  selectEntities: getDocumentPreviewEntities,
  selectAll: getAllDocumentPreviews,
} = adapter.getSelectors(getDocumentPreviewState);

export const loadingState = createSelector(
  getDocumentPreviewState,
  (state) => state.loading
);

export const previewWithId = (id: string) => createSelector(
  getDocumentPreviewEntities,
  (previewEntities) => previewEntities[id]
);

export const previewWithUrl = (url: string) => createSelector(
  getAllDocumentPreviews,
  (previews) => previews.find(preview => preview.url === url)
);
