import {IDmsFolderAcl} from './dms-folder-acl.interface';

export class DmsFolderAcl implements IDmsFolderAcl {
  readonly type = 'dms_model_acls';
  changed = false;

  constructor(
    public id,
    public sid,
    public dmsFolderId,
    public accountType,
    public allowed: number,
    public email: string = null,
    public username = '') {
  }
}

export class RemovedParticipation {
  readonly type = 'close_by_dms_folder_and_sid';

  constructor(
    public id,
    public sid,
    public deletedParticipations,
    public closedProcesses,
    public failedProcesses) {
  }
}
