import {createEntityAdapter, EntityState} from '@ngrx/entity'
import { ContactPerson } from '../../models/contact-person.model';

export interface State extends EntityState<ContactPerson> {
  loading: boolean
}

export const adapter = createEntityAdapter<ContactPerson>()

export const initialState: State = adapter.getInitialState({
  loading: false,
});

