import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {FoxdoxStatusBuilder} from '../../shared/modules/api/builders/foxdox-status.builder';

@Injectable({
  providedIn: 'root'
})
export class DmsStatusService {
  readonly BASE_PATH = 'foxdox/status';
  basePath;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.BASE_PATH}`;
  }

  get(accountType = null) {
    const params = accountType === 'organization' ? '?account_type=organization' : '';
    return this._http.get(`${this.basePath}${params}`).pipe(
      map((res: any) => FoxdoxStatusBuilder.fromResponse(res.data)))
  }
}
