export namespace Browser {
  // Lowest standard version at browserstack:
  export const CHROME_VERSION = 73;
  // Firefox had an issue in version 66
  export const FIREFOX_VERSION = 67;
  // Currently lowest edge version: TODO: Valid minimal version is needed
  export const EDGE_VERSION = 14;
  // This number is arbitrary: TODO: Valid version is needed
  export const SAFARI_VERSION = 4;

  export class SupportValidator {
    public isIE: boolean = /*@cc_on!@*/ false || !!document['documentMode'];
    public isUnsupportedBrowser = false;
    public isUnsupportedVersion = false;

    private chromeRegex = /chrome\/(\d+)/i.exec(navigator.userAgent);
    private firefoxRegex = /firefox\/(\d+)/i.exec(navigator.userAgent);
    private edgeRegex = /edge\/(\d+)/i.exec(navigator.userAgent);
    private safariRegex = /version\/(\d+)/i.exec(navigator.userAgent);

   constructor() {
     // Browsers version
     const vers = {
       chrome: this.chromeRegex && parseInt(this.chromeRegex[1], 10),
       firefox: this.firefoxRegex && parseInt(this.firefoxRegex[1], 10),
       edge: this.edgeRegex && parseInt(this.edgeRegex[1], 10),
       safari: this.safariRegex && parseInt(this.safariRegex[1], 10)
     };

     this.isUnsupportedVersion =
       (vers.chrome && vers.chrome < Browser.CHROME_VERSION && vers.edge === null) ||
       (vers.firefox && vers.firefox < Browser.FIREFOX_VERSION) ||
       (vers.edge && vers.edge < Browser.EDGE_VERSION) ||
       (vers.safari && vers.safari < Browser.SAFARI_VERSION);

     this.isUnsupportedBrowser = this.isIE || this.isUnsupportedVersion;
   }

    isEdgeLegacy() {
      const ua = window.navigator.userAgent;
      const edge = ua.indexOf('Edge/');
      let edgeVersion = null;
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        edgeVersion = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      }

      return edgeVersion && edgeVersion < 19;
    }
  }
}
