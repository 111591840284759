import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './page-sidebar.state';

export const stateKey = 'page-sidebar';
const getPageSidebarState = createFeatureSelector<State>(stateKey);

export const getSidebar = createSelector(
  getPageSidebarState,
  state => state.selected
);

export const isOpen = createSelector(
  getPageSidebarState,
  state => state.open
);
export const isCollapsed = createSelector(
  getPageSidebarState,
  state => state.collapsed
);