<div class="dvtx-audit-contact-confirm-item">
  <form [formGroup]="form">
    <div class="row m-0">
      <div class="col-12 col-xl-2 col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <mat-form-field class="form-element">
          <input matInput placeholder="{{ 'CONTACTS.FIRST_NAME' | translate }}" formControlName="firstName"/>
          <mat-error *ngIf="!form.get('firstName').hasError('required')"></mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-xl-2 col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <mat-form-field class="form-element">
          <input matInput placeholder="{{ 'CONTACTS.LAST_NAME' | translate }}" formControlName="lastName"/>
          <mat-error *ngIf="!form.get('lastName').hasError('required')"></mat-error>
        </mat-form-field>
      </div>

      <div class="col col-xl-1 col-lg-2 col-md-6 col-sm-12 col-xs-12">
        <mat-form-field class="form-element">
          <mat-select placeholder="{{ 'CONTACTS.ROLE' | translate }}" formControlName="role">
            <mat-option value="partner">Partner</mat-option>
            <mat-option value="manager">Manager</mat-option>
            <mat-option value="staff">Staff</mat-option>
          </mat-select>
          <mat-error *ngIf="!form.get('role').hasError('required')"></mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-xl-3 col-lg-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <mat-form-field class="form-element">
          <input matInput placeholder="{{ 'CONTACTS.EMAIL' | translate }}" formControlName="email" readonly />
          <mat-error *ngIf="!form.get('email').hasError('required')"></mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-xl-2 col-lg-2 col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <mat-form-field class="form-element">
          <input matInput placeholder="{{ 'CONTACTS.MOBILE' | translate }}" formControlName="mobile"/>
          <mat-error *ngIf="!form.get('mobile').hasError('required')"></mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-xl-2 col-lg-2 col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <mat-form-field class="form-element">
          <input matInput placeholder="{{ 'CONTACTS.PHONE' | translate }}" formControlName="phone"/>
          <mat-error *ngIf="!form.get('phone').hasError('required')"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row m-0" *ngIf="!isReadOnly">
      <div class="col col-xxl-8 col-xl-10 col-md-12 col-sm-12 col-xs-12">
        <mat-form-field class="full-width">
          <input matInput placeholder="{{ 'GENERAL.COMMENT' | translate }}" formControlName="comment"/>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
