import {IContactRequestConfirm} from './request-confirm-contact-interface';

export const RequestConfirmMockData: IContactRequestConfirm = {
  groupName: 'Mueller Int. GmbH',
  companyName: 'Mueller France Societé',
  fiscalYearTo: '2018-12-31',
  requestDate: '2017-11-30',
  dueDate: '2017-12-18',
  comment: '',
  client: {
    name: 'Text XY',
  },
  responsiblePerson: {
    firstName: 'Test',
    lastName: 'Tester',
    phone: '1234',
    companyName: 'Test GmH',
    companyAddress: {
      street: 'Teststraße',
      streetNumber: '4',
      postalCode: '1234',
      city: 'Regensburg',
      country: 'Germany',
    }
  },
  contacts: [
    {
      firstName: 'Cédric',
      lastName: 'Montagne',
      authorization: 'partner',
      email: 'cedric.montagne@muller.fr',
      mobile: '0033 74456338',
      phone: '465466565656',
      isContactConfirmed: true,
      comment: '',
    },
    {
      firstName: 'Cédric2',
      lastName: 'Montagne2',
      authorization: 'partner',
      email: 'cedric.montagne@muller.fr',
      mobile: '0033 74456338',
      phone: '465466565656',
      isContactConfirmed: false,
      comment: '',
    },
  ]
};
