import {Action} from '@ngrx/store';
import {BookmanServiceAccount} from './bookman-service-account';

export enum BookmanServiceAccountActionTypes {
  SignInToBookmanServiceAccount = '[BookmanServiceAccount] Sign In To Bookman Service Account ',
  SignInToBookmanServiceAccountSuccess = '[BookmanServiceAccount] Sign In To Bookman Service Account  Success',
  SignInToBookmanServiceAccountFail = '[BookmanServiceAccount] Sign In To Bookman Service Account  Fail',
  SignOutFromBookmanServiceAccount = '[BookmanServiceAccount] Sign Out From Bookman Service Account ',
  SignOutFromBookmanServiceAccountSuccess = '[BookmanServiceAccount] Sign Out From Bookman Service Account  Success',
  SignOutFromBookmanServiceAccountFail = '[BookmanServiceAccount] Sign Out From Bookman Service Account  Fail',
  GetBookmanServiceAccount = '[BookmanServiceAccount] Get Bookman Service Account ',
  GetBookmanServiceAccountSuccess = '[BookmanServiceAccount] Get Bookman Service Account  Success',
  GetBookmanServiceAccountFail = '[BookmanServiceAccount] Get Bookman Service Account  Fail'
}

export class SignInToBookmanServiceAccount implements Action {
  readonly type = BookmanServiceAccountActionTypes.SignInToBookmanServiceAccount;

  constructor(public payload: any) { }
}

export class SignInToBookmanServiceAccountSuccess implements Action {
  readonly type = BookmanServiceAccountActionTypes.SignInToBookmanServiceAccountSuccess;

  constructor(public payload: BookmanServiceAccount) {
  }
}

export class SignInToBookmanServiceAccountFail implements Action {
  readonly type = BookmanServiceAccountActionTypes.SignInToBookmanServiceAccountFail;

  constructor(public payload: any = null) { }
}

export class SignOutFromBookmanServiceAccount implements Action {
  readonly type = BookmanServiceAccountActionTypes.SignOutFromBookmanServiceAccount;

  constructor() { }
}

export class SignOutFromBookmanServiceAccountSuccess implements Action {
  readonly type = BookmanServiceAccountActionTypes.SignOutFromBookmanServiceAccountSuccess;

  constructor(public payload: BookmanServiceAccount) { }
}

export class SignOutFromBookmanServiceAccountFail implements Action {
  readonly type = BookmanServiceAccountActionTypes.SignOutFromBookmanServiceAccountFail;

  constructor(public payload: any = null) { }
}

export class GetBookmanServiceAccount implements Action {
  readonly type = BookmanServiceAccountActionTypes.GetBookmanServiceAccount;

  constructor() { }
}

export class GetBookmanServiceAccountSuccess implements Action {
  readonly type = BookmanServiceAccountActionTypes.GetBookmanServiceAccountSuccess;

  constructor(public payload: BookmanServiceAccount) {
  }
}

export class GetBookmanServiceAccountFail implements Action {
  readonly type = BookmanServiceAccountActionTypes.GetBookmanServiceAccountFail;

  constructor(public payload: any = null) { }
}

export type BookmanServiceAccountActions =
  GetBookmanServiceAccount
  | GetBookmanServiceAccountSuccess
  | GetBookmanServiceAccountFail
  | SignInToBookmanServiceAccount
  | SignInToBookmanServiceAccountSuccess
  | SignInToBookmanServiceAccountFail
  | SignOutFromBookmanServiceAccount
  | SignOutFromBookmanServiceAccountSuccess
  | SignOutFromBookmanServiceAccountFail;
