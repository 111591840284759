import {IThirdPartyConfirmation, IThirdPartyConfirmationRow} from './thirdPartyConfirmation';
import {RequestCreation} from './request-creation.interface';
import {RequestType} from './request-type.enum';

export class ThirdPartyConfirmationLiabilityInvoiceRow implements IThirdPartyConfirmationRow {
  constructor(public id: number,
              public color: string,
              public lfd: string,
              public referenced_at: string,
              public due_date: string,
              public comment: string,
              public language: string,
              public request_type: RequestType,
              public kreditor_name: string,
              public kreditor_nr: string,
              public invoiceRows: {
                id: number,
                lfd: string,
                rechnung_nr: string,
                rechnung_datum: string,
                rechnung_betrag: string,
                waehrung: string,
                kontierung: string,
                third_party_comment: string,
                third_party_invoice_confirmed: boolean,
                third_party_invoice_manual: string
              }[],
              public kreditor_ansprechpartner_title: string,
              public kreditor_ansprechpartner_name: string,
              public kreditor_ansprechpartner_email: string,
  ) {

  }

  toRequestCreation(): RequestCreation {
    return {
      companyName: this.kreditor_name,
      id: this.id,
      color: this.color,
      sequenceNo: this.lfd,
      requestDate: this.referenced_at,
      dueDate: this.due_date,
      fileAttachments: undefined,
      contactPerson: {
        title: this.kreditor_ansprechpartner_title,
        name: this.kreditor_ansprechpartner_name,
        email: this.kreditor_ansprechpartner_email,
      },
      requestType: this.request_type,
      language: this.language,
      comment: this.comment,
      invoiceItems: this.invoiceRows.map((row) => {
        return {
          id: row.id,
          invoiceNo: row.rechnung_nr,
          invoiceDate: row.rechnung_datum,
          sequenceNo: row.lfd,
          amount: row.rechnung_betrag,
          currency: row.waehrung,
          kontierung: row.kontierung,
          fileAttachments: [],
          isInvoiceConfirmed: row.third_party_invoice_confirmed,
          invoiceManualInput: row.third_party_invoice_manual,
          comment: row.third_party_comment
        };
      })
    };
  }
}

export class ThirdPartyConfirmationLiabilityInvoice implements IThirdPartyConfirmation {
  emailAttachmentFilename: string;
  state: string;

  constructor(public id: number,
              public type: string,
              public parent_process_id: number,
              public referenced_at: string,
              public due_date: string,
              public color: string,
              public language: string,
              public request_type: RequestType,
              public rows: ThirdPartyConfirmationLiabilityInvoiceRow[]) {

  }

  toForm() {
    return undefined;
  }
}
