import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {OrganizationSelectors} from 'app/+store/organization';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';
import {Organization} from 'app/models/organization.model';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {InvitationType} from 'app/+store/invitation/invitation.model';
import {LicenceAssignmentSelectors, NaturalPersonSelectors} from 'app/+store';
import {takeUntil} from 'rxjs/operators';
import {ITabNavRoute} from 'app/five-f/organization-card/models/tab-nav-route.interface';
import {ActivatedRoute} from '@angular/router';
import {Portal, TemplatePortal} from '@angular/cdk/portal';

@Component({
  selector: 'dvtx-organization-employees-frame',
  templateUrl: './employees-frame.component.html',
  styleUrls: ['./employees-frame.component.scss']
})
export class EmployeesFrameComponent implements OnInit, OnDestroy, AfterViewInit {
  public organization$: Observable<Organization>;
  public orgId;
  public InvitationType = InvitationType;

  private onDestroy: Subject<void> = new Subject<void>();

  routes: ITabNavRoute[] = [];
  activeLink: string;

  searchTerm: string = '';

  @ViewChild('globalActionRef', {static: true}) globalActionRef: TemplateRef<any>;
  globalActionPortal: Portal<any>;

  @ViewChild('callToActionRef', {static: true}) callToActionRef: TemplateRef<any>;
  callToActionPortal: Portal<any>;

  filterOptions = [{title: 'ROLE.ADMINISTRATOR', id: '1'}, {
    title: 'PROCESS_MANAGEMENT.ADMINISTRATE_PROJECT_ROOMS',
    id: '2'
  }];
  selectedFilter: any = [];

  public availableUserLicences: number = 0;

  constructor(private _store: Store<AppState>,
              private _route: ActivatedRoute,
              private _viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.organization$ = this._store.select(OrganizationSelectors.getSelected);
    this.orgId = this._route.snapshot.params.id;

    const myLicenceAssignment = this._store.select(LicenceAssignmentSelectors.getAvailableLicenceCount);
    myLicenceAssignment.pipe(takeUntil(this.onDestroy)).subscribe((licenceCount) => {
      this.availableUserLicences = licenceCount;
    });

    this._store.select(NaturalPersonSelectors.getNaturalPersonsOfSelectedOrganization)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(persons => {
        if (persons) {
          this.routes = [{
            title: 'ORGANIZATION.EMPLOYEES',
            route: `/organization/${this.orgId}/employees/index`,
            count: persons.length
          }, {
            title: 'BANNER.LINK.INVITATIONS_TITLE_MEMBER',
            route: `/organization/${this.orgId}/employees/member-invitations`,
          }];
          this.activeLink = this.routes[0].title;
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.callToActionPortal = this._createPortal(this.callToActionPortal, this.callToActionRef);
      this.globalActionPortal = this._createPortal(this.globalActionPortal, this.globalActionRef);
    });
  }

  public search(searchTerm) {
    this.searchTerm = searchTerm;
  }

  onFilterSelect(value) {
    this.selectedFilter = [];
    setTimeout(() => {
      this.selectedFilter = value;
    }, 200);
  }

  onClearAllFilters() {
    this.selectedFilter = [];
    setTimeout(() => {
      this.selectedFilter = [];
    }, 200);
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }
}
