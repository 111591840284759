import {IFeature} from './feature.interface';

export class Feature implements IFeature {
  readonly type = 'sales_features';

  hasAuditProofDms;
  hasBookman;
  canUpdateBookmanSettings;
  hasLabels;
  hasKanban;
  hasCms;
  hasFastdocs;

  /**
   * Reporting module is enabled.
   * E.g. for participant excel export.
   */
  hasReporting: boolean;

  /**
   * 5F Desktop Client feature.
   */
  hasFivefDesktop;

  /**
   * 5F Desktop Client authorization for the user. This panel must be enabled at the employee settings details
   * of the employee.
   */
  hasFivefDesktopSettings;

  /**
   * 5F ELO DMS support for organization settings.
   */
  hasEloDms: boolean;

  /**
   * DATEV DMS feature support.
   */
  hasDatevDms;

  /**
   * DATEV DMS feature authorization for settings manipulation.
   * This feature must be enabled at the employee's details page.
   */
  hasDatevDmsSettings;

  /**
   * Signature workflow support. Enables the workflow inside the document context menus and at the dashboard starter.
   */
  canCreateSignature;

  canCreateWorkflows;
  canCreateSubWorkflows;

  /**
   * Special curacon feature to enable/disable the global workflow settings to switch the visibility
   * of the download timestamp tooltips with downloader and timestamp.
   */
  hasExportMarkerSettingsSupport: boolean;

  /**
   * Organization based mobile support toggle.
   * NOTE: Feature authorization can also be achieved at the user level (see User model).
   *       Only one of both is needed to enable the Mobile PIN panel.
   */
  hasMobileSupport: boolean;

  /**
   * Process Trash support.
   */
  hasProcessTrash: boolean;

  constructor(public id) {}
}
