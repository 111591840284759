<div>
  <div *ngFor="let address of addresses" class="horizontal">
    <div class="row w-100 m-0 address">
      <div class="col-12 col-md-9 p-0">
        <dvtx-address-input-cva
          [enableLocationOrType]="enableLocationOrType"
          [address]="address"
          [isDisabled]="address.id || disabled"
          elementName="Adresssuche"
        ></dvtx-address-input-cva>
      </div>
      <div class="col-12 col-md-3 d-flex align-items-end" *ngIf="!disabled">
        <button mat-icon-button [disabled]="disabled" (click)="saveAddress(address)" *ngIf="!address.id">
          <mat-icon>done</mat-icon>
        </button>
        <button mat-icon-button [disabled]="disabled" (click)="deleteAddress(address)"><mat-icon>clear</mat-icon></button>
      </div>
    </div>
  </div>
  <div class="horizontal" *ngIf="!disabled">
    <button mat-icon-button [disabled]="disabled" color="gray" type="button" (click)="addNewField()" class="add-button"  [disableRipple]="true">
      <mat-icon>add_circle_outline</mat-icon>
      {{ 'CONTACTS.ADD_ADDRESS' | translate }}
    </button>
  </div>
</div>
