import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './member.state';

export const stateKey = 'tenant-member';
const getMemberState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getMemberEntities,
  selectAll: getAllMembers,
} = adapter.getSelectors(getMemberState);

export const loadingState = createSelector(
  getMemberState,
  (_state) => _state.loading
);

export const getMemberById = (id: string) => createSelector(
  getMemberEntities,
  (members) => members[id]
);

export const getSelectedMember = createSelector(
  getMemberState,
  (state) => state.selected
);
