import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {SlimFolder} from './slim-folder';

export interface State extends EntityState<SlimFolder> {
  selected?: SlimFolder;
  unread: number;
  loading: boolean;
  refresh: boolean;
}

export const adapter = createEntityAdapter<SlimFolder>({
  sortComparer: (l, r) => SlimFolder.sortByName(l, r)
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  unread: null,
  loading: false,
  refresh: false
});
