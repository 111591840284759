import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'environments/environment';
import { AngularTokenService } from 'angular-token';

@Injectable()
export class CppApiService {

  constructor(private http: HttpClient, private tokenService: AngularTokenService) {}

  setHeaders(): HttpHeaders {
    const currentAuthData: any = this.tokenService.currentAuthData;
    const headersConfig = { 
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };
    for (const key in currentAuthData) {
      if (currentAuthData.hasOwnProperty(key)) {
        headersConfig[key] = currentAuthData[key];
      }
    }
    headersConfig['access-token'] = currentAuthData['accessToken'];
    delete headersConfig['tokenType'];
    delete headersConfig['accessToken'];
    return new HttpHeaders(headersConfig)
  }

  get<T>(
    path: string,
    params?: HttpParams
  ): Observable<T> {
    return this.http
      .get<T>(this.requestUrl(path), {
        headers: this.setHeaders(),
        params: params,
      })
  }

  put<T>(path: string, body = {}): Observable<T> {
    return this.http
      .put<T>(this.requestUrl(path), JSON.stringify(body), {
        headers: this.setHeaders(),
      })
  }

  post<T>(path: string, body: Object = {}): Observable<T> {
    return this.http
      .post<T>(this.requestUrl(path), JSON.stringify(body), {
        headers: this.setHeaders(),
      })
  }

  delete<T>(path: string): Observable<T> {
    return this.http
        .delete<T>(this.requestUrl(path), {
          headers: this.setHeaders(),
        })
  }

  private requestUrl(path: string) {
    return environment.cppApiUrl + path
  }
}
