import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {QuickshareAttachmentEffects} from './quickshare-attachment.effects';
import {QuickshareAttachmentService} from './quickshare-attachment.service';
import {reducer} from './quickshare-attachment.reducer';
import {stateKey} from './quickshare-attachment.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([QuickshareAttachmentEffects])
  ],
  providers: [
    QuickshareAttachmentEffects,
    QuickshareAttachmentService
  ]
})
export class QuickshareAttachmentStoreModule {
}
