import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './process.state';
import {getAllFavorites} from '../favorite/favorite.selectors';
import {FavoriteProcess} from '../favorite/favorite-process';
import {Process, ProcessStatus} from './process';

export const stateKey = 'process';
const getProcessState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getProcessEntities,
  selectAll: getAllProcessModels,
} = adapter.getSelectors(getProcessState);


export const getAllProcesss = createSelector(
  getAllFavorites,
  getAllProcessModels,
  (favorites, processes) => {
    return processes.map(t => {
      // NGRX immutable objects:
      // Altering an object from the +store is illegal on ngrx >= 8. If a change is needed it must be based on a new object.
      // Illegal
      // t.favorite = !!favorites.find(f => t.id === f.resourceId) || false;
      // return t;
      const p = Object.assign({}, t);
      p.favorite = !!favorites.find(f => t.id === f.resourceId) || false;
      return p;
    })
  }
);

export const getAllProcessesOfFolder = (folderId: string) => createSelector(
  getAllProcesss,
  (processes) => {
    if (!folderId) return [];
    return processes.filter((process: Process) => process.dmsFolderId === folderId)
  }
);


export const getAllProcesssSorted = createSelector(
  getAllProcesss,
  getProcessState,
  (processes, state) => {
    return processes.sort(state.sortComparer)
  }
);

export const getPaginatedProcesssSorted = createSelector(
  getAllProcesss,
  getAllFavorites,
  getProcessState,
  (processes, favorites, state) => {
    processes = processes.filter(process => {
      const isFound = favorites.find(fav => fav.resourceId === process.id);
      return isFound;
    });
    return processes.sort(state.sortComparer).slice(0, state.paginationEnd)
  }
);

export const getPaginatedFavoritesProcessesSorted = createSelector(
  getAllProcesss,
  getAllFavorites,
  getProcessState,
  (processes, favorites, state) => {
    processes = processes
      .filter(process => favorites.find(fav => fav.resourceId === process.id))
      .sort(state.sortComparer)
      .slice(0, state.paginationEnd);
    return processes.map(process => {
      const favorite = favorites.find(fav => fav.resourceId === process.id);
      return new FavoriteProcess(favorite, process);
    });
  }
);

export const getPaginatedFavoritesProcessesSortedUnConfirmedFirst = (unconfirmedFirst = true) => createSelector(
  getPaginatedFavoritesProcessesSorted,
  (sortedProcesses) => {
    if (unconfirmedFirst) {
      const unconfirmed = [];
      const rest = [];
      sortedProcesses.forEach(process => {
        if (process.favorite.confirmedAt === null) {
          unconfirmed.push(process);
        } else {
          rest.push(process)
        }
      });
      return unconfirmed.concat(rest);
    } else {
      return sortedProcesses;
    }
  }
);

export const ProjectsInvitationsCount = createSelector(
  getPaginatedFavoritesProcessesSorted,
  (sortedProcesses) => sortedProcesses.filter(process => process.favorite.confirmedAt === null).length);

export const getOpenProcesses = createSelector(
  getAllProcesss,
  processes => processes.filter(process => {
    return process.status.code !== 'closed' && process.status.code !== 'initiated' && process.status.code !== 'draft' && process.processType !== 'third_party_request' && process.processType !== 'audit_contact_verifications';
  })
);

export const getClosedProcesses = createSelector(
  getAllProcesss,
  processes => processes.filter(process => process.status.code === 'closed')
);

export const getDrafts = createSelector(
  getAllProcesss,
  processes => processes.filter(process => process.status.code === 'draft' || process.status.code === 'initiated')
);

export const getLoadingState = createSelector(
  getProcessState,
  state => state.loading
);

export const getSelectedLoadingState = createSelector(
  getProcessState,
  state => state.selectedLoading
);

export const getSortComparerState = createSelector(
  getProcessState,
  state => state.sortComparer
);

export const getAuthorizationState = createSelector(
  getProcessState,
  state => state.authorized
);

export const getSelectedProcess = createSelector(
  getProcessState,
  state => state.selected
);

export const getProcessById = (id: string) => createSelector(
  getProcessEntities,
  entities => entities[id]
);

export const getProcessTitleById = (id: string) => createSelector(
  getProcessEntities,
  entities => {
    return entities[id] ? entities[id].title : '';
  }
);


export const getOpenSubProcesses = (id: string) => createSelector(
  getAllProcesss,
  processes => processes.filter(process => {
    return (process.parentId === id || process.id === id)
      && process.status.code !== 'closed'
      && process.status.code !== 'initiated'
      && process.status.code !== 'draft'
      && process.processType !== 'third_party_request'
      && process.processType !== 'audit_contact_verifications'
  })
);

const addSubProcessToList = (process: Process, processMap: { [id: string]: Process[] }, processList: Process[]) => {
  if (processMap[process.id] && processMap[process.id].length) {
    processMap[process.id].forEach(p => {
      processList.push(p);
      if (processMap[p.id] && processMap[p.id].length) {
        addSubProcessToList(p, processMap, processList);
      }
    });
  }
}

export const getSubProcesses = (id: string, recursive = false) => createSelector(
  getAllProcesss,
  getProcessEntities,
  (processes, entities) => {
    if (recursive) {
      const root = entities[id];
      if (!id || !root) {
        return [];
      }
      const parentMap = {};
      // Add root node at the top
      const _processes: Process[] = [];

      // Map for all children elements
      processes.forEach(p => {
        if (p.parentId) {
          if (!parentMap[p.parentId]) {
            parentMap[p.parentId] = [];
          }
          parentMap[p.parentId].push(p);
        }
      });

      // Now add recursively
      addSubProcessToList(root, parentMap, _processes)
      return _processes;
    }
    return processes.filter(process => {
      return (process.parentId === id || process.id === id)
    })
  }
);

export const getNestedProcessesOfRoot = (id: string) => createSelector(
  getAllProcesss,
  getProcessEntities,
  (processes, entities) => {
    const root = entities[id];
    if (!id || !root) {
      return [];
    }
    const parentMap = {};

    // Map for all children elements
    processes.forEach(p => {
      if (p.parentId) {
        if (!parentMap[p.parentId]) {
          parentMap[p.parentId] = [];
        }
        parentMap[p.parentId].push(p);
      }
    });
    return [root, ...processSubProcesses(root, parentMap)];
  }
);

export const getNestedProcessIdsOfRoot = (id: string) => createSelector(
  getNestedProcessesOfRoot(id),
  (processes) => processes.map(p => p.id)
);

const processSubProcesses = (process: Process, processMap: { [id: string]: Process[] }) => {
  const processList: Process[] = [];
  const subProcesses = processMap[process.id] || [];
  processList.push(...subProcesses);

  subProcesses.forEach(p => {
    if (processMap[p.id] && processMap[p.id].length) {
      processList.push(...processSubProcesses(p, processMap));
    }
  })
  return processList;
}

export const getOnlySubProcesses = (id: string) => createSelector(
  getAllProcesss,
  processes => processes.filter(process => {
    return (process.parentId === id)
  })
);

export const getOnlySubCollectors = (id: string) => createSelector(
  getOnlySubProcesses(id),
  processes => processes.filter(process => {
    return (process.processType === 'quickcollector')
  })
);

export const getOnlySubProcessesNotCollectors = (id: string) => createSelector(
  getOnlySubProcesses(id),
  processes => processes.filter(process => {
    return (process.processType !== 'quickcollector')
  })
);

export const getClosedSubProcesses = (id: string) => createSelector(
  getAllProcesss,
  processes => processes.filter(process => (process.parentId === id || process.id === id) && process.status.code === 'closed')
);

export const getDraftSubProcesses = (id: string) => createSelector(
  getAllProcesss,
  processes => processes.filter(process => (process.parentId === id || process.id === id) && process.status.code === 'draft')
);

export const getProcessesOfClient = (id: string) => createSelector(
  getAllProcesss,
  processes => processes.filter(process => {
    return (process.clientId === id)
  })
);

/**
 * Returns the closed status of a process.
 * There are two ways to find out if a process is closed depending on the existing data set:
 * A full available process has an internal status object.
 * A dense process object has a state property containing the 'closed' state as string.
 * @param id - Process ID.
 */
export const isClosed = (id: string) => createSelector(
  getProcessEntities,
  entities => {
    const process = entities[id];
    if (process && process.status && (<ProcessStatus>process.status).isClosed()) {
      return true;
    }

    if (process && process['state'] && process['state'] === 'closed') {
      return true;
    }

    return false;
  }
);
