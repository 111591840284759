import { IRole } from './role.interface';
import { Role } from './role';

export class User {
  lastSignInAt: Date = null;
  showTrialPopup: boolean;
  basicAccount: boolean;
  invitationType: 'member' | 'business_partner' | null;
  autoLogout = 30;
  lockedAt?: Date;

  private constructor(public email?: string,
              public id?: string,
              public uid?: string,
              public name?: string,
              public accessToken?: string,
              public client?,
              public roles?: IRole[],
              public phoneNumber?: string,
              public otpRequiredForLogin?: boolean,
              public accountAccessType?: string,
              public endTrial?: Date,
              public trialEndWasHinted = false) {
  }

  static buildFrom(response) {
    let roles = [];
    if (response.roles && response.roles.length) {
      roles = response.roles.map((role) => new Role(role));
    }

    const user = new User(
      response.email,
      response.id,
      response.uid,
      response.name,
      response.accessToken,
      response.client,
      roles,
      response.phone,
      response.otp_required_for_login,
      response.accountAccessType,
      new Date(response.end_trial));
    user.lastSignInAt = response.last_sign_in_at;
    user.showTrialPopup = response.trial_popup;
    user.invitationType = response.invitation_type;
    user.basicAccount = response.basic_account;
    user.autoLogout = response.auto_logout;
    return user;
  }

  isAuditor() {
    return this.roles.some(role => role.symbol === 'auditor');
  }

  hasMobileAccess() {
    return this.roles.some(role => role.symbol === 'mobile');
  }

  uidToParam() {
    return encodeURIComponent(this.uid);
  }

  static userUidToParam(uid) {
    return encodeURIComponent(uid);
  }
}

export enum AccountAccessType {
  trial = 'trial'
}
