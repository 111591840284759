<ng-template #callToActionRef>
  <button mat-raised-button
          *ngIf="forms[TASK_TYPE]"
          color="primary"
          (click)="saveScheme(forms[TASK_TYPE], statusesSchemas[TASK_TYPE])"
          [disabled]="forms[TASK_TYPE].invalid || forms[TASK_TYPE].pristine">
    <mat-icon mataffix>save</mat-icon>
    {{ 'GENERAL.SAVE_ACTION' | translate }}</button>
</ng-template>

<dvtx-settings-header [routes]="routes"
                      [activeLink]="activeLink"
                      [callToActionPortal]="callToActionPortal"
                      [toOverview]="true"
                      [title]="'ORGANIZATION_NAVIGATON.TASKS_SETTINGS'"></dvtx-settings-header>

<div *ngIf="forms[TASK_TYPE]" class="five-f-settings-container">
  <ng-container *ngIf="!statusesSchemas[TASK_TYPE]">
    <dvtx-alert class="d-block mb-2" [color]="'info'"
                [text]="'TASK.ORGANIZATION_FRAME_YOU_SHOULD_CREATE_SCHEME' | translate"></dvtx-alert>
  </ng-container>

  <div [formGroup]="forms[TASK_TYPE]">
    <div class="row">
      <div class="col-12 col-md-4">
        <mat-form-field>
          <input matInput [placeholder]="'GENERAL.TITLE' | translate" formControlName="title"/>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4">
        <mat-form-field>
          <textarea matInput matTextareaAutosize [placeholder]="'GENERAL.DESCRIPTION' | translate"
                    formControlName="description"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngIf="statusesSchemas[TASK_TYPE]" [formGroup]="statusForms[TASK_TYPE]">
    <hr/>

    <div class="row mb-2 align-items-center">
      <div class="col-12 col-sm-4 col-md-3 d-flex align-items-center pr-4">
        <mat-form-field>
          <input class="m-0 p-0" matInput [placeholder]="'TASK.CREATE_NEW_STATUS' | translate"
                 formControlName="title">
          <mat-error
            *ngIf="statusForms[TASK_TYPE].get('title').errors?.existed">{{ 'ORGANIZATION_NAVIGATON.STATUS_ALREADY_EXISTS_ERROR' | translate}}</mat-error>
        </mat-form-field>

        <dvtx-color-picker
          [colors]="taskColors | enumValues"
          [execluded]="execludedColors[TASK_TYPE]"
          [selected]="selectdNewColor"
          (onSelection)="selectNewColor($event, statusForms[TASK_TYPE])">
        </dvtx-color-picker>
      </div>
      <button
        mat-raised-button
        color="primary"
        (click)="addStatus(statusForms[TASK_TYPE])"
        [disabled]="statusForms[TASK_TYPE].invalid || statusForms[TASK_TYPE].pristine"
      >{{ 'GENERAL.ADD_ACTION' | translate }}</button>
    </div>

    <ul class="assignment--status--bar">
      <ng-container *ngFor="let status of statusesSchemas[TASK_TYPE]?.statuses; let i = index">
        <li
          [attr.data-ph]="status.title.length > 22 ? ((status.title | slice:0:22) + '..') : status.title"
          [matTooltip]="status.title"
          [style.background]="'#'+status.color"
          [class.last]="(i + 1) === statusesSchemas[TASK_TYPE]?.statuses?.length">
          {{i + 1}}
          <div class="assignment--status--action">
            <span class="assignment--status--action--edit" (click)="editStatus(status, TASK_TYPE)">
              <mat-icon>settings</mat-icon>
            </span>
            <span class="assignment--status--action--delete" (click)="deleteStatus(status)">
              <mat-icon>delete</mat-icon>
            </span>
          </div>
        </li>
        <li
          class="assignment--status--bar--line"
          *ngIf="(i + 1) !== statusesSchemas[TASK_TYPE]?.statuses?.length">
        </li>
      </ng-container>
    </ul>
  </div>

  <div *ngIf="!statusesSchemas[TASK_TYPE]">
    <hr/>
    <ul class="assignment--status--bar">
      <ng-container *ngFor="let status of (defaultStatusesScheme$ | async)?.statuses; let i = index">
        <li
          [attr.data-ph]="status.title.length > 22 ? ((status.title | slice:0:22) + '..') : status.title"
          [matTooltip]="status.title"
          [style.background]="'#'+status.color"
          [class.last]="(i + 1) === (defaultStatusesScheme$ | async)?.statuses?.length">
          {{i + 1}}
        </li>
        <li
          class="assignment--status--bar--line"
          *ngIf="(i + 1) !== (defaultStatusesScheme$ | async)?.statuses?.length">
        </li>
      </ng-container>
    </ul>
  </div>
</div>
