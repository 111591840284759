import {TenantOrganizationActions, TenantOrganizationActionTypes} from './organization.actions';
import {adapter, initialState, State} from './organization.state';

export function reducer(state = initialState, action: TenantOrganizationActions): State {
  const current = state.selected;
  switch (action.type) {
    case TenantOrganizationActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case TenantOrganizationActionTypes.LoadOne:
    case TenantOrganizationActionTypes.Save:
      return {
        ...state,
        loading: true
      };
    case TenantOrganizationActionTypes.LoadOneSuccess:
      return adapter.addOne(action.organization, {
        ...state,
        selected: action.organization,
        loading: false,
      });
    case TenantOrganizationActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.organizations, {
        ...state,
        selected: null,
        loading: false,
      });
    case TenantOrganizationActionTypes.SaveSuccess:
      return adapter.upsertOne(action.organization, {
        ...state,
        selected: action.organization,
        loading: false,
      });
    case TenantOrganizationActionTypes.LoadAllFail:
    case TenantOrganizationActionTypes.LoadOneFail:
    case TenantOrganizationActionTypes.SaveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
  }
  return state;
}
