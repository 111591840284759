<div *ngIf="!isDisabled">
  <mat-form-field>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let contact of selectedContacts"
                [selectable]="selectable"
                [removable]="removable"
                color="{{contact?.type == 'Membership' || contact?.type == 'workflow_engine_process_participants' ? 'primary' : 'default'}}"
                selected="{{contact?.type == 'Membership' || contact?.type == 'workflow_engine_process_participants'}}"
                (remove)="remove(contact)" [matTooltip]="contact?.email">
        <dvtx-avatar [size]="'xs'" [email]="contact?.email" [isVerified]="contact?.hasAccount" [showTitle]="true"></dvtx-avatar>
        <mat-icon matChipRemove *ngIf="removable" (click)="remove(contact)">cancel</mat-icon>
      </mat-chip>

      <input [placeholder]="placeholder | translate"
             [matChipInputFor]="chipList"
             [matAutocomplete]="auto"
             [matChipInputAddOnBlur]="addOnBlur"
             matInput [formControl]="autoCompleteContactList"
             #contactInput
             (focus)="focusInput()"/>
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="add($event, contactInput)">
      <mat-option *ngFor="let contact of filteredContacts$ | async" [value]="contact">
        <dvtx-avatar [size]="'xs'" [email]="contact?.email" [isVerified]="contact?.hasAccount" [showTitle]="true"></dvtx-avatar>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div *ngIf="isDisabled">
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let contact of selectedContacts" color="{{contact?.type == 'Membership' || contact?.type == 'workflow_engine_process_participants' ? 'primary' : 'default'}}"
              selected="{{contact?.type == 'Membership' || contact?.type == 'workflow_engine_process_participants'}}"
              [matTooltip]="contact?.email">
      <dvtx-avatar [size]="'xs'" [email]="contact?.email" [isVerified]="contact?.hasAccount" [showTitle]="true"></dvtx-avatar>
    </mat-chip>
  </mat-chip-list>
</div>
