import {Action} from '@ngrx/store';
import {Membership} from '../../models/membership.model';

export enum MembershipActionTypes {
  LoadAll = '[Membership] Load All',
  LoadOne = '[Membership] Load One',
  LoadAllSuccess = '[Membership] Load All Success',
  LoadAllFail = '[Membership] Load All Fail',
  LoadMy = '[Membership] Load my',
  LoadMySuccess = '[Membership] Load my Success',
  LoadMyFail = '[Membership] Load my Fail',
  RemoveOne = '[Membership] Remove member',
  RemoveOneSuccess = '[Membership] Removed member successfully',
  Select = '[Membership] Select',
}

export class LoadOne implements Action {
  readonly type = MembershipActionTypes.LoadOne;

  constructor(public payload: Membership) {
  }
}

export class LoadAll implements Action {
  readonly type = MembershipActionTypes.LoadAll;

  constructor(public orgId: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = MembershipActionTypes.LoadAllSuccess;

  constructor(public payload: Membership[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = MembershipActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadMy implements Action {
  readonly type = MembershipActionTypes.LoadMy;

  constructor(public orgaId: string) {

  }
}

export class LoadMySuccess implements Action {
  readonly type = MembershipActionTypes.LoadMySuccess;

  constructor(public payload: Membership) {
  }
}

export class LoadMyFail implements Action {
  readonly type = MembershipActionTypes.LoadMyFail;

  constructor(public payload: any) {
  }
}

export class RemoveOne implements Action {
  readonly type = MembershipActionTypes.RemoveOne;

  constructor(public organization: any, public memberId: string, public membershipId, public memberEmail, public ownerEmail) {
  }
}

export class RemoveOneSuccess implements Action {
  readonly type = MembershipActionTypes.RemoveOneSuccess;

  constructor(public id: string) {
  }
}

export class Select implements Action {
  readonly type = MembershipActionTypes.Select;

  constructor(public id: string) {
  }
}

export type MembershipActions =
  | LoadOne
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadMy
  | LoadMySuccess
  | LoadMyFail
  | RemoveOne
  | RemoveOneSuccess
  | Select
