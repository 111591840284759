import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { of, Subject } from 'rxjs';
import { AngularTokenService } from 'angular-token';
import { AvatarService } from 'app/shared/modules/user-account/components/avatar/avatar.service';
import { Router } from '@angular/router';
import { Organization } from 'app/models/organization.model';
import { ProcessParticipantService } from 'app/+store/process-participant/process-participant.service';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from 'app/+store/client/client.service';
import { catchError, first } from 'rxjs/operators';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';
import { ClientSelectors, ContactSelectors } from 'app/+store';
import { ContactListDto } from 'app/models/contact-list-dto.model';
import { Client } from 'app/+store/client/client';
import { ClientSelectionCvaComponent } from 'app/modules/workflow-engine/modules/process-dialog/containers/client-selection-cva/client-selection-cva.component';

@Component({
  selector: 'dvtx-participant-client-link',
  templateUrl: './participant-client-link.component.html',
  styleUrls: ['./participant-client-link.component.scss']
})
export class ParticipantClientLinkComponent implements AfterViewInit, OnDestroy, OnInit {

  onDestroy = new Subject();
  organization: Organization;
  @Input() isContact = false;
  @Input() isClient = false;
  @Input() selectedClient: any;
  @Input() selectedContact: any;
  clientSelection: any = null;
  public allContacts:ContactListDto[] = [];
  public allContactsMap = {};
  excludedIds: any = [];
  public allClientsMap = {};
  @ViewChild('clientSelectionCvaComponent') clientSelectionCvaComponent: ClientSelectionCvaComponent;
  latestClientMapping: any[];
  @Output() updateMapping = new EventEmitter<any>();
  constructor(private _dialog: MatDialog,
    private router: Router,
    private _clientSvc: ClientService,
    private _store: Store<AppState>,
    private _translateSvc: TranslateService,
    private _viewContainerRef: ViewContainerRef,
    private _fb: UntypedFormBuilder,
    private _tokenSvc: AngularTokenService,
    private _cdr: ChangeDetectorRef,
    private _notifyService: NotificationService,
    private _participantSvc: ProcessParticipantService,
    private avatarService: AvatarService) {
  }

  ngOnInit() {
    this._store.select(ContactSelectors.getContactsOfSelectedOrg).subscribe((contacts) => {
      this.allContacts = contacts;
      if (contacts && contacts.length > 0) {
        contacts.forEach(element => {
          this.allContactsMap[element.id] = element;
        });
      }
    });
    this._store.select(ClientSelectors.getAllClients).subscribe((clients) => {
      if (clients && clients.length > 0) {
        clients.forEach(element => {
          this.allClientsMap[element.id] = element;
        });
      }
    });
    this.loadExcludedIds();
    this._cdr.detectChanges();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.updateMapping.emit(this.latestClientMapping);
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  getContactClientMappings(action, clientId, contactId) {
    this._clientSvc.getContactClientMappings().pipe(first()).subscribe(data => {
      this.latestClientMapping = data;
      if (data) {
        let toReturn = [];
        if (this.isClient) {
          toReturn = this.selectedClient.contacts;
        } else {
          toReturn = this.selectedContact.clients;
        }
        if (action === 'create') {
          const newRecord = data.find(a => a.clientId === clientId && a.contactId === contactId);
          if (!toReturn) {
            toReturn = [];
          }
          if (newRecord) {
            toReturn.push(newRecord);
          }
        } else if (action === 'remove') {
          toReturn = toReturn.filter(a => !(a.clientId === clientId && a.contactId === contactId));
        } else if (action === 'update') {
          const updatedRecord = data.find(a => a.clientId === clientId && a.contactId === contactId);
          toReturn.forEach(element => {
            if (updatedRecord && element.clientId === clientId && element.contactId === contactId) {
              element.role = updatedRecord.role;
            }
          });
        }
        if (this.isClient) {
          this.selectedClient.contacts = toReturn;
        } else {
          this.selectedContact.clients = toReturn;
        }
        this.loadExcludedIds();
      }
      this._cdr.detectChanges();
    }), catchError(err => {
      console.error(err);
      return of();
    });
  }
  loadExcludedIds () {
    if (this.isClient) {
      if (this.selectedClient.contacts) {
       const ids =  this.selectedClient.contacts.map(a => a.contactId);
       if (ids && ids.length > 0) {
        this.excludedIds = this.allContacts.filter(a => ids.includes(a.id)).map(a => a.email);
       }
      }
    } else {
      if (this.selectedContact.clients)
      this.excludedIds = this.selectedContact.clients.map(a => a.clientId);
    }
  }
  createContactClient(contact) {
    const selected = this.allContacts.find(a => a.email === contact.email);
    if (selected) {
      this._clientSvc.createContactClient(this.selectedClient.id, selected.id, '').pipe(first()).subscribe(data => {
        this._notifyService.success('CONTACTS.CONTACT_CLIENT_ASSIGN')
        this.getContactClientMappings('create', this.selectedClient.id, selected.id);
      }), catchError(err => {
        console.error(err);
        this._notifyService.error('CONTACTS.CONTACT_CLIENT_ASSIGN_FAIL')
        return of();
      });
    }
  }

  createClientContact(client) {
    if (!client || !client.id) return;
    if (this.clientSelectionCvaComponent) {
      this.clientSelectionCvaComponent.reset();
    }
    this._clientSvc.createContactClient(client.id, this.selectedContact.id, '').pipe(first()).subscribe(data => {
      this.getContactClientMappings('create', client.id, this.selectedContact.id);
      this._notifyService.success('CONTACTS.CLIENT_CONTACT_ASSIGN')
    }), catchError(err => {
        console.error(err);
        this._notifyService.error('CONTACTS.CLIENT_CONTACT_ASSIGN_FAIL')
        return of();
      });
  }

  updateContactClient(contact, roleName) {
    this._clientSvc.updateContactClient(contact.clientId, contact.contactId, roleName, contact.relationId).pipe(first()).subscribe(data => {
      if (roleName === '') {
        this._notifyService.success('CONTACTS.REMOVE_ROLE')
      } else {
        this._notifyService.success('CONTACTS.EDIT_ROLE')
      }
      this.getContactClientMappings('update', contact.clientId, contact.contactId);
    }), catchError(err => {
      console.error(err);
      if (roleName === '') {
        this._notifyService.error('CONTACTS.REMOVE_ROLE_FAIL')
      } else {
        this._notifyService.error('CONTACTS.EDIT_ROLE_FAIL')
      }
      return of();
    });
  }
  removeContactClient(contact) {
    this._clientSvc.removeContactClient(contact.clientId, contact.relationId).pipe(first()).subscribe(data => {
     if (this.isClient) {
      this._notifyService.success('CONTACTS.CONTACT_CLIENT_REMOVE')
     } else {
      this._notifyService.success('CONTACTS.CLIENT_CONTACT_REMOVE')
     }
     this.getContactClientMappings('remove', contact.clientId, contact.contactId);
    }), catchError(err => {
      console.error(err);
      if (this.isClient) {
        this._notifyService.error('CONTACTS.CONTACT_CLIENT_REMOVE_FAIL')
       } else {
        this._notifyService.error('CONTACTS.CLIENT_CONTACT_REMOVE_FAIL')
       }
      return of();
    });
  }
}
