import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import { DocumentSignatureProfilePreviewComponent } from './containers/document-signature-profile-preview/document-signature-profile-preview.component';
import {ImageCropperModule} from "ngx-image-cropper";



@NgModule({
  declarations: [
    DocumentSignatureProfilePreviewComponent
  ],
  imports: [
    SharedModule,
    ImageCropperModule
  ],
  exports: [
    DocumentSignatureProfilePreviewComponent
  ]
})
export class DocumentSignatureProfilePreviewModule { }
