
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {DmsDocument} from './dms-document';
import {DmsDocumentBuilder} from './dms-document.builder';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {EnvService} from 'app/shared/modules/api-resource/services/env.service';
import {IPublishParams} from './dms-document.interface';
import {ProcessArtifactBuilder} from '../process-artifact/process-artifact.builder';
import {ProcessArtifact} from '../process-artifact/process-artifact';

@Injectable()
export class DmsDocumentService {
  readonly BASE_PATH = 'api/v1/files/documents';

  constructor(private _http: ApiResourceService,
              private _httpClient: HttpClient,
              private _domSanitizer: DomSanitizer,
              private _env: EnvService) {}

  getAll(folderId: string = null, accountType: DmsAccountType = DmsAccountType.Private): Observable<DmsDocument[]> {
    const accountParam = accountType === DmsAccountType.Private ? 'private' : 'organization';
    const param = `?account_type=${accountParam}` + (folderId ? `&folder_id=${folderId}` : '');
    const builder = new DmsDocumentBuilder();
    return <Observable<DmsDocument[]>>this._http.get<DmsDocumentBuilder, DmsDocument>(builder, `${this.BASE_PATH}${param}`);
  }

  getOne(id: string): Observable<DmsDocument> {
    const builder = new DmsDocumentBuilder();
    return <Observable<DmsDocument>>this._http.get<DmsDocumentBuilder, DmsDocument>(builder, `${this.BASE_PATH}/${id}`);
  }

  getProcessFolderDocuments(processId: string): Observable<DmsDocument[]> {
    const builder = new DmsDocumentBuilder();
    return <Observable<DmsDocument[]>>this._http.getAll<DmsDocumentBuilder, DmsDocument>(builder, `api/v1/workflow_engine/processes/${processId}/dms_documents`);
  }

  remove(processId: string, id: string): Observable<DmsDocument> {
    const builder = new DmsDocumentBuilder();
    return <Observable<DmsDocument>>this._http.del<DmsDocumentBuilder, DmsDocument>(builder, `api/v1/workflow_engine/processes/${processId}/dms_documents/${id}`);
  }

  destroy(id: string, accountType: DmsAccountType = DmsAccountType.Organization): Observable<DmsDocument> {
    const builder = new DmsDocumentBuilder();
    const accountParam = accountType === DmsAccountType.Private ? 'private' : 'organization';
    const param = `?account_type=${accountParam}`;
    return <Observable<DmsDocument>>this._http.del<DmsDocumentBuilder, DmsDocument>(builder, `${this.BASE_PATH}/${id}${param}`);
  }

  download(document: DmsDocument, accountType: DmsAccountType = DmsAccountType.Organization) {
    const accountParam = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return this._httpClient
      .get(`${this._env.apiBase()}/${this.BASE_PATH}/${document.id}/download?account_type=${accountParam}\``, { responseType: 'blob' })
  }

  downloadById(id: string, accountType: DmsAccountType = DmsAccountType.Organization) {
    const accountParam = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return this._httpClient
      .get(`${this._env.apiBase()}/${this.BASE_PATH}/${id}/download?account_type=${accountParam}\``, { responseType: 'blob' })
  }

  /**
   * Loads image as a blob and converts it to a img-compatible format.
   * @param url - Url of the image. Auth headers exepcted to be present.
   */
  preview(url: string): Observable<any> {
    return this._httpClient
      .get(url, { responseType: 'blob' }).pipe(
      map(e => this._domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))))
  }

  publish(processId: string, params: IPublishParams): Observable<ProcessArtifact> {
    const payload = {
      data: {
        attributes: {
          dms_document_id: params.dms_document_id,
          public_available: params.externally_available,
          public_available_until: params.documents_externally_available_until,
          public_password: params.password
        }
      }
    };
    // return this._http.post(this.basePath, payload);
    const builder = new ProcessArtifactBuilder(processId);
    return <Observable<ProcessArtifact>>this._http.post<ProcessArtifactBuilder, ProcessArtifact>(builder, `api/v1/workflow_engine/processes/${processId}/foxdox_publish`, payload);
  }

  rename(id: string, fileName: string): Observable<DmsDocument> {
    const payload = {
      data: {
        attributes: {
          filename: fileName
        }
      }
    };
    const builder = new DmsDocumentBuilder();
    return <Observable<DmsDocument>>this._http.post<DmsDocumentBuilder, DmsDocument>(builder, `${this.BASE_PATH}/${id}/rename`, payload);
  }
}
