import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './quickshare-attachment.state';

export const stateKey = 'quickshare-attachment';
const getQuickshareAttachmentState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getQuickshareAttachmentEntities,
  selectAll: getAllQuickshareAttachments,
} = adapter.getSelectors(getQuickshareAttachmentState);

export const getSelected = createSelector(
  getQuickshareAttachmentState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getQuickshareAttachmentEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllQuickshareAttachments,
  (entities) => entities
);

export const getAllByResourceId = (id: string) => createSelector(
  getAllQuickshareAttachments,
  (attachments) => attachments.filter(attachment => attachment.resourceId === id)
);

export const loadingState = createSelector(
  getQuickshareAttachmentState,
  (state) => state.loading
);

export const transferOngoing = createSelector(
  getQuickshareAttachmentState,
  state => state.transfering
);
