export namespace ContactRequestBuilder {

  export function generateDataForApi(rawData) {
    switch (rawData.contactType) {
      case 'contact_organizations':
        return generateOrganizationData(rawData);
      case 'contact_people':
        return generatePersonData(rawData);
    }
  }

  function generatePersonData(rawData) {
    const contact = rawData.contact;
    const data = {
      contact_type: rawData.contactType,
      create_user: rawData.createUser || false,
      attributes: {
        note: '',
        visibility: rawData.visibility,
        person_attributes: {
          gender: contact.gender,
          title: contact.title,
          first_name: contact.firstName,
          last_name: contact.lastName
        }
      }
    };
    if (rawData.phones) {
      const phones = generatePhones(rawData.phones);
      data['attributes']['phone_numbers_attributes'] = phones;
    }
    if (rawData.mails) {
      const mails = generateMails(rawData.mails);
      data['attributes']['mail_addresses_attributes'] = mails;
    }
    if (rawData.addresses) {
      const addresses = generateAddresses(rawData.addresses);
      data['attributes']['contact_addresses_attributes'] = addresses;
    }

    if (rawData.contactId) {
      data['attributes']['contact_id'] = rawData.contactId;
    }

    return {data};
  }

  function generateOrganizationData(rawData) {
    const phones = generatePhones(rawData.phones);
    const mails = generateMails(rawData.mails);
    const addresses = generateAddresses(rawData.addresses);
    const contact = rawData.contact;
    let headquarterAttr = null;
    if (contact.address && contact.address.full) {
      headquarterAttr = {
        street: contact.address.street,
        street_no: contact.address.street_no,
        city: contact.address.city,
        zip: contact.address.zip,
        full: contact.address.full
      };
    }
    const data = {
      contact_type: rawData.contactType,
      attributes: {
        note: '',
        visibility: rawData.visibility,
        organization_attributes: {
          name: contact.name,
          abbreviation: contact.abbreviation,
          publicly_listed_at_stock_exchange: contact.publiclyListedAtStockExchange,
          public_interest: contact.publicInterest,
          legal_form_id: contact.legalFormId,
          headquarter_attributes: headquarterAttr,
          organization_contact_type_id: contact.organization_type_id,
        },
        phone_numbers_attributes: phones,
        mail_addresses_attributes: mails,
        contact_addresses_attributes: addresses
      }
    };
    if (contact.address.id) {
      data.attributes.organization_attributes.headquarter_attributes['id'] = contact.address.id;
    }
    if (contact.id) {
      data.attributes.organization_attributes['id'] = contact.id;
    }
    return {data};
  }

  function generatePhones(phones) {
    return phones.map(phone => {
      const newPhone = {
        location_or_type: phone.locationOrType,
        country_code: phone.countryCode,
        number: phone.number
      };
      if (phone.id) {
        newPhone['id'] = phone.id;
      }
      return newPhone;
    });
  }

  function generateMails(mails) {
    return mails.map(mail => {
      const newMail =  {
        location_or_type: mail.locationOrType,
        mail_address: mail.mailAddress,
        main_address: mail.mainAddress
      };
      if (mail.id) {
        newMail['id'] = mail.id;
      }
      return newMail;
    });
  }

  function generateAddresses(addresses) {
    return addresses.map(address => {
      const newAddress = {
        location_or_type: address.locationOrType,
        main_address: address.mainAddress,
        address_attributes: {
          street: address.street,
          street_no: address.street_no,
          city: address.city,
          zip: address.zip,
          full: address.full
        }
      };
      if (address.id) {
        newAddress['id'] = address.id;
      }

      if (address.relationId) {
        newAddress.address_attributes['id'] = address.relationId;
      }

 /*     if (address.relationId) {
        newAddress['id'] = address.relationI;
      }

      if (address.id) {
        newAddress.address_attributes['id'] = address.id;
      }*/
      return newAddress;
    });
  }
}
