import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './user-signature.state';

export const stateKey = 'user-signature';
const getUserSignatureState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getUserSignatureEntities,
  selectAll: getAllUserSignature,
} = adapter.getSelectors(getUserSignatureState);

export const getAll = createSelector(
  getAllUserSignature,
  (signatures) => signatures
);
