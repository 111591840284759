import {IResource} from 'app/shared/modules/api/models/resource.interface';

export interface IDmsFolderSupportTarget {
  id: string;
  dmsFolderId: string;
}

export interface IDmsFolderParams {
  name: string;
}

export enum DmsAccountType {
  Organization = 'Organization',
  Private = 'Private'
}

export interface IDmsFolderSelection {
  dmsFolderId: string,
  dmsAccountType: DmsAccountType
}

export interface IDmsFolder extends IResource {
  id: string;
  name: string;
  path: string;
  sourceId: string;
  dmsAccountType: DmsAccountType;
  parentId?: string;
  folderCount?: number;
  documentCount?: number;
  shared?: boolean;
  auditProof?: boolean;
  createdAt: Date;
  updatedAt: Date;
  children?: IDmsFolder[];
  ownedBy?: string;
  deletable?: boolean;
  renamable?: boolean;
  auditProofUntil: Date;
  bookmanCockpitEnabled?: boolean;

  // Folder details endpoint.
  userProcessesSize?: number;
  userProcessMap?;
  allProcessesSize?: number;
  furtherProcessesSize?: number;
  users?;
  admin: boolean
}
