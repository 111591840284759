import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Message} from './message';
import * as dayjs from 'dayjs';

export interface State extends EntityState<Message> {
  selected?: Message;
  unread: number;
  unreadUpdates: number;
  loading: boolean;
}

export const adapter = createEntityAdapter<Message>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  unread: 0,
  unreadUpdates: 0,
  loading: false,
});
