export class FibuProcess {
  readonly type = 'fibu-process';

  constructor(public id: string,
              public clientId: string,
              public dmsFolderId: string,
              public dmsPath: string,
              public dmsAccountType: string) {
  }
}
