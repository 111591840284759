
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ResourceBuilder} from '../../../shared/modules/api/builders/resource.builder';
import { ApiErrorBuilder } from '../../../shared/modules/api/builders/api-error.builder';
import { environment } from 'environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';


@Injectable()
export class ContactService {

  basePath;

  constructor(
              private _http: HttpClient) {
    this.basePath = `${environment.contactApiBase}/${environment.contactApiPath}`;
  }

  getAll() {
    return this._http.get(`${this.basePath}`).pipe(
      map((res: any) => ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  create(contact) {
    return this._http.post(`${this.basePath}`, ResourceBuilder.toRequest(contact)).pipe(
      map((res: any) => ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  update(contact) {
    return this._http.put(`${this.basePath}/${contact.id}`, ResourceBuilder.toRequest(contact)).pipe(
      catchError(this.handleError));
  }

  delete(contact) {
    return this._http.delete(`${this.basePath}/${contact.id}`).pipe(
      map((res: any) => ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  get(id) {
    return this._http.get(`${this.basePath}/${id}`).pipe(
      map((res: any) => ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  getContactMembers(id) {
    return this._http.get(`${this.basePath}/${id}/members`).pipe(
      catchError(this.handleError));
  }


  private handleError(error: HttpErrorResponse) {
    console.log('ContactService#handleError', error);
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}
