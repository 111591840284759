
import {takeUntil, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, OnDestroy, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';


@Component({
  selector: 'dvtx-base-process-info',
  templateUrl: './base-process-info.component.html',
  styleUrls: ['./base-process-info.component.scss']
})
export class BaseProcessInfoComponent implements OnDestroy, OnInit {
  @Input() form: UntypedFormGroup;
  @Input() expanded = false;
  @Output() onChanges = new EventEmitter();

  private _destroy$ = new Subject();

  constructor() { }

  ngOnInit() {
    this.form
      .valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this._destroy$),)
      .subscribe(_ => this.onChanges.emit(this.form.value));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }
}
