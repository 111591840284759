/**
 * Error application state returning ApiErrors.
 */
import { Action } from '@ngrx/store';

export const CLEAR_API_ERRORS = 'CLEAR_API_ERRORS';
export const ADD_API_ERROR = 'ADD_API_ERROR';
export const REMOVE_API_ERROR = 'REMOVE_API_ERROR';

export class ClearApiErrors implements Action {
  readonly type: string = CLEAR_API_ERRORS;

  constructor(public payload: any = null) { }
}

export class AddApiError implements Action {
  readonly type: string = ADD_API_ERROR;

  constructor(public payload: any) {}
}

export class RemoveApiError implements Action {
  readonly type: string = REMOVE_API_ERROR;

  constructor(public payload: any) {}
}

export type Actions = ClearApiErrors | AddApiError | RemoveApiError;
