import * as model from './tenant.interface';
import {IResource} from 'app/shared/modules/api/models/resource.interface';

/// <reference path="./tenant.interface.ts" />
export namespace Operator {
  export class Tenant implements model.Operator.ITenant {
    readonly type = 'tenant_records';

    constructor(public id,
                public name: string,
                public sender_name: string,
                public domain: string,
                public email_domain: string,
                public favicon_url: string,
                public login_header: string,
                public login_header_en: string,
                public login_footer: string,
                public login_footer_en: string,
                public imprint_url: string,
                public createdAt: Date,
                public updatedAt: Date) {
    }
  }

  export class AzureSSOConfig implements IResource {
    readonly type = 'azure_sso_configs'

    constructor(public id: string,
                public name: string,
                public domain: string,
                public adTenantId: string,
                public clientId: string,
                public clientSecret: string,
                public fiveFTenantId: string,
                public fiveFTenantName: string,
                public fiveFTenantDomain: string,
                public defaultOrganizationId: string,
                public optional: boolean,
                public adDepartmentOnboardingActivated: boolean,
                public adDepartmentOnboardingFallbackId: string,
                public createdAt: Date = null,
                public updatedAt: Date = null) {
    }
  }

  export class AzureSSODomainMapping implements IResource {
    readonly type = 'azure_sso_domain_mappings'

    constructor(public id: string,
                public sourceDomain: string,
                public targetDomain: string,
                public fiveFTenantId: string,
                public fiveFTenantName: string,
                public fiveFTenantDomain: string,
                public createdAt: Date,
                public updatedAt: Date) {
    }
  }
}
