import {FavoriteActions, FavoriteActionTypes} from './favorite.actions';
import {adapter, initialState, State} from './favorite.state';

export function reducer(state = initialState, action: FavoriteActions): State {
  switch (action.type) {
    case FavoriteActionTypes.LoadOne:
    case FavoriteActionTypes.LoadAll:
    case FavoriteActionTypes.Confirm:
      return {
        ...state,
        loading: true
      };
    case FavoriteActionTypes.LoadOneSuccess:
    case FavoriteActionTypes.ConfirmSuccess:
      return adapter.upsertOne(action.favorite, {
        ...state,
        loading: false,
      });
    case FavoriteActionTypes.LoadAllSuccess:
      return adapter.setAll(action.payload, {
          ...state,
          loading: false,
      });
    case FavoriteActionTypes.LoadOneFail:
    case FavoriteActionTypes.LoadAllFail:
    case FavoriteActionTypes.ConfirmFail:
      return {
        ...state,
        loading: false,
      };
    case FavoriteActionTypes.RemoveSuccess:
      return adapter.removeOne(action.favorite.id, state);
  }
  return state;
}

