import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {MilestoneBuilder} from './milestone.builder';
import {Milestone} from './milestone';
import {MilestoneApiResourceService} from './milestone-api-resource.service';

@Injectable()
export class MilestoneService {
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';

  constructor(private _http: MilestoneApiResourceService) {
  }

  getAll(id: string): Observable<Milestone[]> {
    const builder = new MilestoneBuilder(id);
    const path = id ? `${this.BASE_PATH}/${id}/milestones` : `api/v1/milestones`;
    return <Observable<Milestone[]>>this._http.get<MilestoneBuilder, Milestone>(builder, path);
  }
}
