import {IResource} from 'app/shared/modules/api/models/resource.interface';

export enum ProjectRoomType {
  Default = 'PROJECT_ROOM.DEFAULT'
}

export interface IProjectRoom extends IResource {
  type: ProjectRoomType;
}

