import {IPartnerLinkParticipation} from './partner-link-participation.interface';

export class PartnerLinkParticipation implements IPartnerLinkParticipation {
  readonly type = 'partner_link_participation';
  constructor(public id,
              public organizationId: string,
              public email: string,
              public createdAt = null) {
  }
}
