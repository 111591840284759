import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './collector-confirmation.state';

export const stateKey = 'collector-confirmation';
const getCollectorConfirmationState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getCollectorConfirmationEntities,
  selectAll: getAllCollectorConfirmations,
} = adapter.getSelectors(getCollectorConfirmationState);

export const getAll = createSelector(
  getAllCollectorConfirmations,
  (confirmations) => confirmations
);

export const loadingState = createSelector(
  getCollectorConfirmationState,
  (state) => state.loading
);

export const getConfirmationsByCollectorId = (id: string) => createSelector(
  getAllCollectorConfirmations,
  (confirmations) => confirmations.filter(confirmation => confirmation.collectorId === id)
);
