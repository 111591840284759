import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
// import {ProcessEffects} from './process.effects';
// import {ProcessService} from './process.service';
// import {reducer} from './process.reducer';
// import {stateKey} from './process.selectors';
import {CacService} from './cac.service';

@NgModule({
  imports: [
    CommonModule,
    // StoreModule.forFeature(stateKey, reducer),
    // EffectsModule.forFeature([ProcessEffects])
  ],
  providers: [
    // ProcessEffects,
    CacService
  ]
})
export class CacStoreModule {
}
