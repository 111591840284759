import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TaskEventEffects} from './task-event.effects';
import {TaskEventService} from './task-event.service';
import {reducer} from './task-event.reducer';
import {stateKey} from './task-event.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([TaskEventEffects])
  ],
  providers: [
    TaskEventEffects,
    TaskEventService
  ]
})
export class TaskEventStoreModule {
}
