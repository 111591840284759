import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MessageSettingsService} from './message-settings.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    MessageSettingsService
  ]
})
export class MessageSettingsStoreModule {
}
