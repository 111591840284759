import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './task-status-scheme.state';
import {ITaskStatusScheme} from './task-status-scheme.interface';

export const stateKey = 'task-status-scheme';
const getTaskStatusSchemeState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getTaskStatusSchemeEntities,
  selectAll: getAllTaskStatusSchemes,
} = adapter.getSelectors(getTaskStatusSchemeState);

export const getStatusSchemes = () =>  createSelector(
  getAllTaskStatusSchemes,
  (entities) => entities
);

export const getPublishedStatusSchemes = () =>  createSelector(
  getAllTaskStatusSchemes,
  (entities) => entities.filter(entity => {
    return entity.isPublished === true;
  })
);

export const getDefaultStatusSchemes = () =>  createSelector(
  getAllTaskStatusSchemes,
  (entities) => entities.filter(entity => {
    return entity.isDefault === true;
  })[0]
);

export const getAllStatuses = () =>  createSelector(
  getAllTaskStatusSchemes,
  (entities) => {
    const statuses = [];
    entities.forEach((scheme) => {
      statuses.push(...scheme.statuses);
    })
    return statuses;
  }
);

export const loadingState = createSelector(
  getTaskStatusSchemeState,
  (state) => state.loading
)
