<button
  mat-icon-button
  cdk-overlay-origin
  (click)="showDropdown = !showDropdown"
  #dropdown="cdkOverlayOrigin"
  [disableRipple]='true'>
  <mat-icon
    [matTooltip]="'TASK.CHOOSE_COLOR' | translate"
    [style.color]="'#' + (selected || '000000')">
    invert_colors
  </mat-icon>
</button>
<ng-template cdk-connected-overlay
             [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
             [cdkConnectedOverlayOrigin]="dropdown"
             [cdkConnectedOverlayHasBackdrop]="true"
             [cdkConnectedOverlayOpen]="showDropdown"
             (backdropClick)="showDropdown = false">
  <div class="mat-elevation-z8 list--container p-1">
    <ul class="m-0">
      <ng-container *ngFor="let color of colors">
        <li class="color--block p-2" *ngIf="!execluded || !execluded.includes(color)">
          <span
            [style.background]="'#' + color"
            [class.active]="selected && (selected === color)"
            (click)="selectColor(color)">
          </span>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
