import {Action} from '@ngrx/store';
import {PageSidebar} from './page-sidebar';

export enum PageSidebarActionTypes {
  Open = '[PageSidebar] Open',
  Close = '[PageSidebar] Close',
  Reset = '[PageSidebar] Reset',
  Toggle = '[PageSidebar] Toggle'
}

export class Open implements Action {
  readonly type = PageSidebarActionTypes.Open;

  constructor(public sidebar: PageSidebar) {
  }
}

export class Close implements Action {
  readonly type = PageSidebarActionTypes.Close;

  constructor(public payload: any = null) {
  }
}

export class Reset implements Action {
  readonly type = PageSidebarActionTypes.Reset;

  constructor(public payload: any = null) {
  }
}

export class Toggle implements Action {
  readonly type = PageSidebarActionTypes.Toggle;

  constructor(public state: boolean = null) {
  }
}

export type PageSidebarActions =
  | Open
  | Close
  | Reset
  | Toggle;
