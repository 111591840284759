<div class="mat-dialog-inner-wrap">
  <div class="mat-dialog-header">
    <h2 mat-dialog-title>Neue Kontakte importieren</h2>
    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <dvtx-csv-import [settings]="csvSettings" [(ngModel)]="data">
      <!-- <mat-card-content class="after-drop-zone col-12 col-sm-12">
        <dvtx-csv-import-drop-zone class="drag-n-drop" [fileTypes]="['VCF']" (newFile)="newVcf($event)"></dvtx-csv-import-drop-zone>
      </mat-card-content> -->
    </dvtx-csv-import>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-end">
    <button type="reset" mat-button mat-dialog-close>{{'GENERAL.CANCEL_ACTION' | translate}}</button>
    <button mat-raised-button color="primary" [disabled]="!data" (click)="createContacts()">Speichern</button>
  </div>
</div>
