import {Component, Input} from '@angular/core';

@Component({
  selector: 'dvtx-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss']
})
export class HintComponent {
  @Input() hint: string;
  @Input() position = 'above';
}
