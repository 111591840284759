import {Action} from '@ngrx/store';
import {Task} from './task';
import {TaskResource} from 'app/+store/task-resource/task-resource';
import {TaskResourceType} from '../task-resource/task-resource.interface';

export enum TaskActionTypes {
  LoadAll = '[Task] Load All',
  LoadAllSuccess = '[Task] Load All Success',
  LoadAllFail = '[Task] Load All Fail',
  LoadMy = '[Task] Load My',
  LoadMySuccess = '[Task] Load My Success',
  LoadMyFail = '[Task] Load My Fail',
  LoadAllAndAppend = '[Task] Load All And Append',
  LoadAllAndAppendSuccess = '[Task] Load All And Append Success',
  LoadAllAndAppendFail = '[Task] Load All And Append Fail',
  LoadAllUserTasks = '[Task] Load All User Tasks',
  LoadAllOrganizationTasks = '[Task] Load All Organization Tasks',
  LoadAllOrganizationTasksSuccess = '[Task] Load All Organization Tasks Success',
  LoadAllOrganizationTasksFail = '[Task] Load All Organization Tasks Fail',
  LoadAllWithoutPaging = '[Task] Load All Without Paging',
  LoadAllWithoutPagingSuccess = '[Task] Load All Without Paging Success',
  LoadAllWithoutPagingFail = '[Task] LoadAll Without Paging Fail',
  LoadOne = '[Task] Load One',
  LoadOneSuccess = '[Task] Load One Success',
  LoadOneFail = '[Task] Load One Fail',
  Create = '[Task] Create',
  CreateSuccess = '[Task] Create Success',
  CreateFail = '[Task] Ceate Fail',
  Delete = '[Task] Delete',
  DeleteSuccess = '[Task] Delete Success',
  DeleteFail = '[Task] Delete Fail',
  Edit = '[Task] Edit',
  EditSuccess = '[Task] Edit Success',
  EditFail = '[Task] Edit Fail',
  Close = '[Task] Close',
  CloseSuccess = '[Task] Close Success',
  CloseFail = '[Task] Close Fail',
  Open = '[Task] Open',
  OpenSuccess = '[Task] Open Success',
  OpenFail = '[Task] Open Fail',
  AddResponsible = '[Task] Add Responsible',
  AddResponsibleSuccess = '[Task] Add Responsible Success',
  AddResponsibleFail = '[Task] Add Responsible Fail',
  LoadQuery = '[Task] Load Query',
  LoadQuerySuccess = '[Task] Load Query Success',
  LoadQueryFail = '[Task] Load Query Fail',
  LoadMore = '[Task] Load More',
  LoadMoreSuccess = '[Task] Load More Success',
  LoadMoreFail = '[Task] Load More Fail',
  UpdateNotification = '[MessageSettings] Update Notification',
  UpdateNotificationSuccess = '[MessageSettings] Update Notification Success',
  UpdateNotificationFail = '[MessageSettings] Update Notification Fail',
  GetNotification = '[MessageSettings] Get Notification',
  GetNotificationSuccess = '[MessageSettings] Get Notification Success',
  GetNotificationFail = '[MessageSettings] Get Notification Fail',
  Reorder = '[Task] Reorder',
  ReorderSuccess = '[Task] Reorder Success',
  ReorderFail = '[Task] Reorder Fail',
  AddAttachmentFromDMS = '[Attachment] Add Attachment From DMS',
  AddAttachmentFromDMSSuccess = '[Attachment] Add Attachment From DMS Success',
  AddAttachmentFromDMSFail = '[Attachment] Add Attachment From DMS Fail',
  RemoveAttachment = '[RemoveAttachment] Remove Attachment',
  RemoveAttachmentSuccess = '[RemoveAttachment] Remove Attachment Success',
  RemoveAttachmentFail = '[RemoveAttachment] Remove Remove Fail',
  Reset = '[Reset] Reset',
  SkipFetching = '[Task] Skip Fetching',
}

export class LoadAll implements Action {
  readonly type = TaskActionTypes.LoadAll;

  constructor(public id: any = null, public showArchived = false, public recursive = false) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = TaskActionTypes.LoadAllSuccess;

  constructor(public tasks: Task[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = TaskActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadMy implements Action {
  readonly type = TaskActionTypes.LoadMy;

  constructor(public payload: any = null) {
  }
}

export class LoadMySuccess implements Action {
  readonly type = TaskActionTypes.LoadMySuccess;

  constructor(public tasks: Task[]) {
  }
}

export class LoadMyFail implements Action {
  readonly type = TaskActionTypes.LoadMyFail;

  constructor(public payload: any) {
  }
}

export class LoadAllAndAppend implements Action {
  readonly type = TaskActionTypes.LoadAllAndAppend;

  constructor(public id: any = null, public showArchived = false, public recursive = false) {
  }
}

export class LoadAllAndAppendSuccess implements Action {
  readonly type = TaskActionTypes.LoadAllAndAppendSuccess;

  constructor(public tasks: Task[]) {
  }
}

export class LoadAllAndAppendFail implements Action {
  readonly type = TaskActionTypes.LoadAllAndAppendFail;

  constructor(public payload: any) {
  }
}

export class LoadAllUserTasks implements Action {
  readonly type = TaskActionTypes.LoadAllUserTasks;

  constructor(public email, public dense = false) {
  }
}

export class LoadAllOrganizationTasks implements Action {
  readonly type = TaskActionTypes.LoadAllOrganizationTasks;

  constructor() {
  }
}

export class LoadAllOrganizationTasksSuccess implements Action {
  readonly type = TaskActionTypes.LoadAllOrganizationTasksSuccess;

  constructor(public tasks: Task[]) {
  }
}

export class LoadAllOrganizationTasksFail implements Action {
  readonly type = TaskActionTypes.LoadAllOrganizationTasksFail;

  constructor(public payload: any) {
  }
}

export class LoadAllWithoutPaging implements Action {
  readonly type = TaskActionTypes.LoadAllWithoutPaging;

  constructor(public payload: any = null) {
  }
}

export class LoadAllWithoutPagingSuccess implements Action {
  readonly type = TaskActionTypes.LoadAllWithoutPagingSuccess;

  constructor(public tasks: Task[]) {
  }
}

export class LoadAllWithoutPagingFail implements Action {
  readonly type = TaskActionTypes.LoadAllWithoutPagingFail;

  constructor(public payload: any) {
  }
}

export class Create implements Action {
  readonly type = TaskActionTypes.Create;

  /**
   *
   * @param task
   * @param resource Main reference, collecto item or document.
   * @param referenceId Secondary reference: When invoked in document context the second
   *                    reference allows to additionally provide a node reference.
   */
  constructor(public task: Task, public resource: TaskResource = null, public referenceId: string = null, public referenceType: TaskResourceType = null) {
  }
}

export class CreateSuccess implements Action {
  readonly type = TaskActionTypes.CreateSuccess;

  constructor(public task: Task) {
  }
}

export class CreateFail implements Action {
  readonly type = TaskActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = TaskActionTypes.LoadOne;

  constructor(public id: string, public useCache = false, public disableNotification = false) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = TaskActionTypes.LoadOneSuccess;

  constructor(public task: Task) {
  }
}

export class LoadOneFail implements Action {
  readonly type = TaskActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Delete implements Action {
  readonly type = TaskActionTypes.Delete;

  constructor(public task: Task) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = TaskActionTypes.DeleteSuccess;

  constructor(public task: Task) {
  }
}

export class DeleteFail implements Action {
  readonly type = TaskActionTypes.DeleteFail;

  constructor(public payload: any) {
  }
}

export class Edit implements Action {
  readonly type = TaskActionTypes.Edit;

  constructor(public task: Task) {
  }
}

export class EditSuccess implements Action {
  readonly type = TaskActionTypes.EditSuccess;

  constructor(public task: Task) {
  }
}

export class EditFail implements Action {
  readonly type = TaskActionTypes.EditFail;

  constructor(public payload: any) {
  }
}

export class Close implements Action {
  readonly type = TaskActionTypes.Close;

  constructor(public taskId: string) {
  }
}

export class CloseSuccess implements Action {
  readonly type = TaskActionTypes.CloseSuccess;

  constructor(public task: Task) {
  }
}

export class CloseFail implements Action {
  readonly type = TaskActionTypes.CloseFail;

  constructor(public payload: any) {
  }
}

export class Open implements Action {
  readonly type = TaskActionTypes.Open;

  constructor(public taskId: string) {
  }
}

export class OpenSuccess implements Action {
  readonly type = TaskActionTypes.OpenSuccess;

  constructor(public task: Task) {
  }
}

export class OpenFail implements Action {
  readonly type = TaskActionTypes.OpenFail;

  constructor(public payload: any) {
  }
}

export class AddResponsible implements Action {
  readonly type = TaskActionTypes.AddResponsible;

  constructor(public taskId: string, public email: string) {
  }
}

export class AddResponsibleSuccess implements Action {
  readonly type = TaskActionTypes.AddResponsibleSuccess;

  constructor(public task: Task) {
  }
}

export class AddResponsibleFail implements Action {
  readonly type = TaskActionTypes.AddResponsibleFail;

  constructor(public payload: any) {
  }
}

export class LoadQuery implements Action {
  readonly type = TaskActionTypes.LoadQuery;

  constructor(public payload: any = null) {
  }
}

export class LoadQuerySuccess implements Action {
  readonly type = TaskActionTypes.LoadQuerySuccess;

  constructor(public payload: Task[]) {
  }
}

export class LoadQueryFail implements Action {
  readonly type = TaskActionTypes.LoadQueryFail;

  constructor(public payload: any) {
  }
}

export class LoadMore implements Action {
  readonly type = TaskActionTypes.LoadMore;

  constructor(public payload: any = null) {
  }
}

export class LoadMoreSuccess implements Action {
  readonly type = TaskActionTypes.LoadMoreSuccess;

  constructor(public tasks: Task[]) {
  }
}

export class LoadMoreFail implements Action {
  readonly type = TaskActionTypes.LoadMoreFail;

  constructor(public payload: any) {
  }
}

export class Reorder implements Action {
  readonly type = TaskActionTypes.Reorder;

  constructor(public tasks: Task[]) {
  }
}

export class ReorderSuccess implements Action {
  readonly type = TaskActionTypes.ReorderSuccess;

  constructor(public tasks: Task[]) {
  }
}

export class ReorderFail implements Action {
  readonly type = TaskActionTypes.ReorderFail;

  constructor(public payload: any) {
  }
}

export class AddAttachmentFromDMS implements Action {
  readonly type = TaskActionTypes.AddAttachmentFromDMS;

  constructor(public taskId: string, public fileId: string) {
  }
}

export class AddAttachmentFromDMSSuccess implements Action {
  readonly type = TaskActionTypes.AddAttachmentFromDMSSuccess;

  constructor(public task: Task) {
  }
}

export class AddAttachmentFromDMSFail implements Action {
  readonly type = TaskActionTypes.AddAttachmentFromDMSFail;

  constructor(public payload: any) {
  }
}

export class RemoveAttachment implements Action {
  readonly type = TaskActionTypes.RemoveAttachment;

  constructor(public taskId: string, public attachmentId: string) {
  }
}

export class RemoveAttachmentSuccess implements Action {
  readonly type = TaskActionTypes.RemoveAttachmentSuccess;

  constructor(public task: Task) {
  }
}

export class RemoveAttachmentFail implements Action {
  readonly type = TaskActionTypes.RemoveAttachmentFail;

  constructor(public payload: any) {
  }
}

export class Reset implements Action {
  readonly type = TaskActionTypes.Reset;

  constructor() {
  }
}

export class SkipFetching implements Action {
  readonly type = TaskActionTypes.SkipFetching;

  constructor(public payload: any = null) {
  }
}


export type TaskActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadMy
  | LoadMySuccess
  | LoadMyFail
  | LoadAllAndAppend
  | LoadAllAndAppendSuccess
  | LoadAllAndAppendFail
  | LoadAllOrganizationTasks
  | LoadAllOrganizationTasksSuccess
  | LoadAllOrganizationTasksFail
  | LoadAllWithoutPaging
  | LoadAllWithoutPagingSuccess
  | LoadAllWithoutPagingFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Create
  | CreateSuccess
  | CreateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | Edit
  | EditSuccess
  | EditFail
  | AddResponsible
  | AddResponsibleSuccess
  | AddResponsibleFail
  | LoadQuery
  | LoadQuerySuccess
  | LoadQueryFail
  | LoadMore
  | LoadMoreSuccess
  | LoadMoreFail
  | Close
  | CloseSuccess
  | CloseFail
  | Open
  | OpenSuccess
  | OpenFail
  | Reorder
  | ReorderSuccess
  | ReorderFail
  | AddAttachmentFromDMS
  | AddAttachmentFromDMSSuccess
  | AddAttachmentFromDMSFail
  | RemoveAttachment
  | RemoveAttachmentSuccess
  | RemoveAttachmentFail
  | LoadAllUserTasks
  | Reset
  | SkipFetching;
