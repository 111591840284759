import {WorkflowTemplateActions, WorkflowTemplateActionTypes} from './workflow-template.actions';
import {adapter, initialState, State} from './workflow-template.state';

export function reducer(state = initialState, action: WorkflowTemplateActions): State {
  const current = state.selected;
  switch (action.type) {
    case WorkflowTemplateActionTypes.Create:
    case WorkflowTemplateActionTypes.Delete:
    case WorkflowTemplateActionTypes.LoadAll:
    case WorkflowTemplateActionTypes.LoadOne:
    case WorkflowTemplateActionTypes.Update:
    case WorkflowTemplateActionTypes.Apply:
      return {
        ...state,
        loading: true
      };
    case WorkflowTemplateActionTypes.CreateSuccess:
      return adapter.upsertOne(action.template, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case WorkflowTemplateActionTypes.UpdateSuccess:
      return adapter.upsertOne(action.template, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case WorkflowTemplateActionTypes.LoadAllSuccess:
      return adapter.addMany(action.templates, {
        ...state,
        loading: false,
      });
    case WorkflowTemplateActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.template, {
        ...state,
        loading: false,
      });
    case WorkflowTemplateActionTypes.DeleteSuccess:
      return adapter.removeOne(action.template.id, {
        ...state,
        loading: false,
      });
    case WorkflowTemplateActionTypes.CreateFail:
    case WorkflowTemplateActionTypes.DeleteFail:
    case WorkflowTemplateActionTypes.LoadAllFail:
    case WorkflowTemplateActionTypes.LoadOneFail:
    case WorkflowTemplateActionTypes.UpdateFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

