import * as screenActions from '../actions/screen.actions';

// the interface that describes user part of the app state
export interface ScreenState {
  width: number;
}

// initial user state
export const initialState: ScreenState = {
  width: null
};

export function screenReducer(state: ScreenState = initialState, action: screenActions.Actions): ScreenState {

  switch (action.type) {

    case screenActions.SCREEN_RESIZE:
        return { ...state, width: action.payload };

    default: {
      return state;
    }

  }

};
