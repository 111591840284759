export namespace StringUtils {

  export enum COMPARISON_MOOD {
    SOFT,
    HARD
  }

  /**
   * Compares a collection of strings
   *
   * Input strings: The strings that need to be compared to each other, it's an array.
   * Input mood: The mood of comparing the strings:
   * - SOFT for comparing in lowercasing and trimming the strings.
   * - HARD for comparing the strings by exact case and without removing any spaces before of after the strings.
   *
   * Author: Abdallah Salem
   */
  export function compare(strings: string[], mood: COMPARISON_MOOD = COMPARISON_MOOD.HARD): boolean {
    let match = true;
    switch (mood) {
      case COMPARISON_MOOD.HARD:
        strings.map( (string) => {
          if (string !== strings[0])
            match = false;
        } );
        break;
      case COMPARISON_MOOD.SOFT:
        strings.map( (string) => {
          if (StringUtils.normalizeString(string) !== StringUtils.normalizeString(strings[0]))
            match = false;
        } );
        break;
    }
    return match;
  }

  export function simpleCompare(self: string, other: string) {
    if (!self || !other) return false;
    return StringUtils.normalizeString(self) === StringUtils.normalizeString(other);
  }

  export function normalizeString(str: string) {
    if (typeof str !== 'string') {
      return '';
    }
    return str.toLowerCase().trim();
  }

  /**
   * Creates a camelized string from the given one: asd_cvb asdCvb.
   * @param str
   */
  export function camalize(str) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }

  export function normalizeEmail(email) {
    if (!email || typeof(email) !== 'string') return '';

    try {
      return email.trim().toLocaleLowerCase();
    } catch (e) {
      console.error(e);
      return email;
    }
  }

  export function toHashCode(str) {
    let hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr   = str.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
}
