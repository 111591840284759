import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './audit-contractee.state';

export const stateKey = 'audit-contractee';
const getAuditContracteeState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getAuditContracteeEntities,
  selectAll: getAllAuditContractees,
} = adapter.getSelectors(getAuditContracteeState);

export const getContracteesOfProcessById = (id: string) => createSelector(
  getAllAuditContractees,
  contractees => contractees.filter(c => c.processId === id)
);

export const getLoadingState = createSelector(
  getAuditContracteeState,
  state => state.loading
);
