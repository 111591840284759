import {IKanbanBoard, IKanbanBoardTypes} from "./kanban-board.interface";

export class KanbanBoard implements IKanbanBoard {
  readonly type = 'configuration';

  constructor(
    public id: string,
    public boardType: IKanbanBoardTypes,
    public processId: string,
    public title: string,
    public isSelected: boolean,
    public showPriorities: boolean,
    public showStatuses: boolean,
    public allGroupsCollapsed: boolean,
    public showStandardTasks: boolean,
    public showApprovalTasks: boolean,
    public showCollectoGroups: boolean,
  ) {
  }
}
