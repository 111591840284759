import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TaskCommentEffects } from './task-comment.effects';
import { TaskCommentService } from './task-comment.service';
import { reducer } from './task-comment.reducer';
import { stateKey } from './task-comment.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([TaskCommentEffects])
  ],
  providers: [
    TaskCommentEffects,
    TaskCommentService
  ]
})
export class TaskCommentStoreModule {
}
