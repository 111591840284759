import {Action} from '@ngrx/store';
import {TaskResource} from './task-resource';
import {TaskResourceType} from './task-resource.interface';

export enum TaskResourceActionTypes {
  CreateResource = '[TaskResource] Create Resource',
  CreateResourceSuccess = '[TaskResource] Create Resource Success',
  CreateResourceFail = '[TaskResource] Create Resource Fail',
  UpdateResource = '[TaskResource] Update Resource',
  UpdateResourceSuccess = '[TaskResource] Update Resource Success',
  UpdateResourceFail = '[TaskResource] Update Resource Fail',
  Delete = '[TaskResource] Delete Resource',
  DeleteSuccess = '[TaskResource] Delete Resource Success',
  DeleteFail = '[TaskResource] Delete Resource Fail'
}

export class CreateResource implements Action {
  readonly type = TaskResourceActionTypes.CreateResource;

  constructor(public taskId: string, public resouce: TaskResource, public referenceId: string = null, public referenceType: TaskResourceType = null) {
  }
}

export class CreateResourceSuccess implements Action {
  readonly type = TaskResourceActionTypes.CreateResourceSuccess;

  constructor(public resource: TaskResource) {
  }
}

export class CreateResourceFail implements Action {
  readonly type = TaskResourceActionTypes.CreateResourceFail;

  constructor(public payload: any) {
  }
}

export class UpdateResource implements Action {
  readonly type = TaskResourceActionTypes.UpdateResource;

  constructor(public taskId: string, public resouce: TaskResource) {
  }
}

export class UpdateResourceSuccess implements Action {
  readonly type = TaskResourceActionTypes.UpdateResourceSuccess;

  constructor(public resource: TaskResource) {
  }
}

export class UpdateResourceFail implements Action {
  readonly type = TaskResourceActionTypes.UpdateResourceFail;

  constructor(public payload: any) {
  }
}

export class Delete implements Action {
  readonly type = TaskResourceActionTypes.Delete;

  constructor(public taskId: string, public resourceId: string) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = TaskResourceActionTypes.DeleteSuccess;

  constructor(public resource: TaskResource) {
  }
}

export class DeleteFail implements Action {
  readonly type = TaskResourceActionTypes.DeleteFail;

  constructor(public payload: any) {
  }
}

export type TaskResourceActions =
  | CreateResource
  | CreateResourceSuccess
  | CreateResourceFail
  | UpdateResource
  | UpdateResourceSuccess
  | UpdateResourceFail
  | Delete
  | DeleteSuccess
  | DeleteFail;
