import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertsModule} from 'app/modules/alerts/alerts.module';
import {ProcessToolbarComponent} from './components/upload-dialog/process-toolbar.component';
import {ProcessDialogModule} from '../workflow-engine/modules/process-dialog/process-dialog.module';
import {ProcessToCalendarModule} from '../workflows/modules/process-to-calendar/process-to-calendar.module';
import {TooltipModule} from 'app/five-f/tooltip/tooltip.module';
import {ProcessActionsModule} from '../process-settings/modules/process-actions/process-actions.module';
import {
  TimelineNotificationPanelComponent
} from '../timeline/components/timeline-notification-panel/timeline-notification-panel.component';
import {FiltersContainerModule} from 'app/five-f/filters/filters-container.module';
import {GrantThorntonModule} from '../grant-thornton/grant-thornton.module';

@NgModule({
  imports: [
    CommonModule,
    AlertsModule,
    ProcessDialogModule,
    TooltipModule,
    ProcessToCalendarModule,
    ProcessActionsModule,
    FiltersContainerModule,
    GrantThorntonModule
  ],
  declarations: [
    ProcessToolbarComponent,
    TimelineNotificationPanelComponent
  ],
  exports: [
    ProcessToolbarComponent,
    TimelineNotificationPanelComponent
  ]
})
export class ProcessToolbarModule {
}
