import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {UntypedFormGroup} from '@angular/forms';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import {DmsFolderPickerDialogComponent} from '../containers/dms-folder-picker-dialog/dms-folder-picker-dialog.component';
import {DmsAccountType} from 'app/+store/dms-folder/dms-folder.interface';

@Directive({
  selector: '[dvtxDmsFolderPickerSelector]'
})
export class DmsFolderPickerSelectorDirective {
  @Input() form: UntypedFormGroup;
  @Input() dmsPath: string;
  @Input() dmsFolderId: string;
  @Input() dmsAccountType: DmsAccountType;
  @Input() parentFolderId;

  constructor(el: ElementRef, public dialog: MatDialog,
              private _store: Store<AppState>) {
  }

  @HostListener('click') onClick() {
    this.openDialog();
  }

  openDialog() {
    const dialogRef = this.dialog.open(DmsFolderPickerDialogComponent, {
      width: '250px',
      height: '600px',
      data: {
        dmsFolderId: this.form.controls[this.dmsFolderId] ? this.form.controls[this.dmsFolderId].value : undefined,
        folderPath: this.form.controls[this.dmsPath].value,
        parentFolderId: this.parentFolderId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.dmsFolderId) {
        this.form.controls[this.dmsFolderId].patchValue(result.dmsFolderId);
        this.form.controls[this.dmsAccountType].patchValue(result.dmsAccountType);
        if (this.dmsPath) {
          this.form.controls[this.dmsPath].patchValue(result.dmsPath);
        }
      }
    });
  }
}
