import {SlimFolderActions, SlimFolderActionTypes} from './slim-folder.actions';
import {adapter, initialState, State} from './slim-folder.state';

export function reducer(state = initialState, action: SlimFolderActions): State {
  const current = state.selected;
  const refresh = state.refresh;
  switch (action.type) {
    case SlimFolderActionTypes.LoadAll:
    case SlimFolderActionTypes.LoadAllOrganization:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case SlimFolderActionTypes.Refresh:
      return {
        ...state,
        selected: null,
        loading: true,
        refresh: true
      };
    case SlimFolderActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case SlimFolderActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
    case SlimFolderActionTypes.LoadOne:
      return {
        ...state,
        selected: null
      };
    case SlimFolderActionTypes.Create:
    case SlimFolderActionTypes.Delete:
    case SlimFolderActionTypes.Rename:
      return {
        ...state
      };
    case SlimFolderActionTypes.LoadOneSuccess:
    case SlimFolderActionTypes.CreateSuccess:
    case SlimFolderActionTypes.RenameSuccess:
    case SlimFolderActionTypes.LockFolderSuccess:
      return adapter.upsertOne(action.folder, {
        ...state,
        selected: action.folder,
        loading: false,
      });
    case SlimFolderActionTypes.RefreshSuccess:
      if (refresh) {
        return adapter.setAll(action.payload, {
          ...state,
          loading: false,
          refresh: false
        });
      } else {
        return adapter.upsertMany(action.payload, {
          ...state,
          selected: current ? state.entities[current.id] : null,
          loading: false,
          refresh: false
        });
      }
    case SlimFolderActionTypes.DeleteSuccess:
      return adapter.removeOne(action.folder.id, {
        ...state,
        selected: action.folder,
        loading: false,
      });
    case SlimFolderActionTypes.CreateFail:
    case SlimFolderActionTypes.LoadOneFail:
    case SlimFolderActionTypes.DeleteFail:
    case SlimFolderActionTypes.RefreshFail:
    case SlimFolderActionTypes.RenameFail:
      return {
        ...state,
        selected: null,
        loading: false,
        refresh: false
      };
  }
  return state;
}

