import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './collector-item-lookup.state';

export const stateKey = 'collector-item-lookup';
const getCollectorItemState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getCollectorLookupItemEntities,
  selectAll: getAllCollectorLookupItems,
} = adapter.getSelectors(getCollectorItemState);

export const getFetchStatus = createSelector(
  getCollectorItemState,
  (state) => state.fetchStatus
)

/**
 * Returns the cached Collecto lookup item by ID lookup in +store entities in O(1).
 * @param id
 */
export const getItem = (id: string) => createSelector(
  getCollectorLookupItemEntities,
  (entities) => entities[id]
);
