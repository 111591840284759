
import {of as observableOf, throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ResourceBuilder} from '../builders/resource.builder';
import {ApiErrorBuilder} from '../builders/api-error.builder';
import {environment} from 'environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Task} from 'app/shared/modules/api/models/task';


@Injectable()
export class TaskService {

  basePath;
  readonly BASE_PATH = 'api/v1/workflow_engine';

  constructor(private _tokenService: HttpClient,
              private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  getAll(id) {
    if (id) {
      return this._http.get(`${this.basePath}/processes/${id}/tasks`).pipe(
        map((res: any) => ResourceBuilder.fromResponse(res)),
        catchError(this.handleError),);
    } else {
      return this._http.get(`${this.basePath}/tasks`).pipe(
        map((res: any) => ResourceBuilder.fromResponse(res)),
        catchError(this.handleError),);
    }
  }

  get(id) {
    return this._http.get(`${this.basePath}/tasks/${id}`).pipe(
      map((res: any) => <Task>ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  create(processId, task) {
    return this._http.post(`${this.basePath}/processes/${processId}/tasks`, ResourceBuilder.toRequest(task)).pipe(
      map((res: any) => ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  update(id, task) {
    return this._http.put(`${this.basePath}/tasks/${id}`, ResourceBuilder.toRequest(task)).pipe(
      map((res: any) => ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  private handleError(error: HttpErrorResponse) {
    console.log('ContactService#handleError', error);
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors)
  }

  getStatuses() {
    return observableOf([
      {
        status: 'initiated',
        readableStatus: 'Erstellt',
        icon: 'play_arrow',
        infoLevel: 'default'
      },
      {
        status: 'in_progress',
        readableStatus: 'In Bearbeitung',
        icon: 'timelapse',
        infoLevel: 'default'
      },
      {
        status: 'completed',
        readableStatus: 'Abgeschlossen',
        icon: 'check',
        infoLevel: 'success'
      },
      {
        status: 'delegated',
        readableStatus: 'Delegiert',
        icon: 'send',
        infoLevel: 'info'
      },
      {
        status: 'overdued',
        readableStatus: 'Überfällig',
        icon: 'access_time',
        infoLevel: 'error'
      },
      {
        status: 'rejected',
        readableStatus: 'Abgelehnt',
        icon: 'cancel',
        infoLevel: 'error'
      },
      {
        status: 'canceled',
        readableStatus: 'Abgebrochen',
        icon: 'cancel',
        infoLevel: 'error'
      }
    ])
  }
}

