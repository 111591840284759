<div [formGroup]="userProfileForm">
  
  <div class="icon-content-layout mb-2">
    <mat-icon>account_box</mat-icon>
    <div class="icon-content-layout--content">
      <div class="row">
        <div class="col-12 col-sm-6">
          <dvtx-api-select-cva
            formControlName="title"
            class="type-or-location"
            listingPath="name"
            listingType="gender"
            [elementName]="'CONTACTS.GENDER' | translate"
            [requiredField]="true">
          </dvtx-api-select-cva>
        </div>
        <div class="col-12 col-sm-6">
          <dvtx-text-input-cva
            formControlName="honorific" class="comp-name"
            [elementName]="'CONTACTS.TITLE' | translate">
          </dvtx-text-input-cva>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <dvtx-text-input-cva
            formControlName="firstName"
            [elementName]="'CONTACTS.FIRST_NAME' | translate"
            [requiredField]="true"
            class="comp-name">
          </dvtx-text-input-cva>
        </div>
        <div class="col-12 col-sm-6">
          <dvtx-text-input-cva
            formControlName="lastName" class="comp-name"
            [elementName]="'CONTACTS.LAST_NAME' | translate"
            [requiredField]="true">
          </dvtx-text-input-cva>
        </div>
      </div>
    </div>
  </div>

  <div class="icon-content-layout mb-2">
    <mat-icon>email</mat-icon>
    <div class="icon-content-layout--content">
      <div class="row">
        <div class="col-12 col-md-9">
          <dvtx-email-address-input
            formControlName="mainEmailAddress"
            [elementName]="'CONTACTS.EMAIL' | translate"
            [errorMapper]="[{error:'InvalidEmailAddress', message: 'CONTACTS.ENTER_VALID_EMAIL' | translate }]"
          ></dvtx-email-address-input>
        </div>
        <div class="col-12">
          <dvtx-email-address-group-cva
            [emailAddresses]="myContactDetails?.emailAddresses"
            (addEmail)="addFurtherEmail($event)"
            (removeEmail)="removeFurtherEmail($event)"
            [disabled]="isDisabled"
          ></dvtx-email-address-group-cva>
        </div>
      </div>
    </div>
  </div>

  <div class="icon-content-layout mb-2">
    <mat-icon>phone</mat-icon>
    <div class="icon-content-layout--content">
      <div class="row">
        <div class="col-12 col-md-9">
          <dvtx-telephone-input [formControlName]="'mainPhoneNumber'" [isType]="true"></dvtx-telephone-input>
        </div>
        <div class="col-12">
          <dvtx-telephone-group-cva
            [telephonenumbers]="myContactDetails?.phoneNumbers"
            (addPhone)="addFurtherPhoneNumber($event)"
            (removePhone)="removeFurtherPhoneNumber($event)"
            [disabled]="isDisabled">
          </dvtx-telephone-group-cva>
        </div>
      </div>
    </div>
  </div>

  <div class="icon-content-layout mb-2">
    <mat-icon>place</mat-icon>
    <div class="icon-content-layout--content">
      <div class="row">
        <div class="col-12 col-md-9">
          <dvtx-address-input-cva
          formControlName="mainAddress"
          [elementName]="'CONTACTS.ADDRESS' | translate"
          [isDisabled]="isDisabled">
        </dvtx-address-input-cva>
        </div>
        <div class="col-12">
          <dvtx-address-input-group-cva
            [enableLocationOrType]="true"
            [addresses]="myContactDetails?.addresses"
            (addAddress)="addFurtherAddress($event)"
            (removeAddress)="removeFurtherAddress($event)"
            [disabled]="isDisabled">
          </dvtx-address-input-group-cva>
        </div>
      </div>
    </div>
  </div>

</div>
