export namespace Hsl {
  export const cache = {};
  // Reference:
  // - https://www.sitepoint.com/javascript-generate-lighter-\
  //   darker-color/
  export function TransformLuminance(hex, lum) {
    if (!hex) {
      hex = '#FFFFFF';
    }
    const key = `${hex}|${lum}`;
    if (Hsl.cache[key]) {
      return Hsl.cache[key];
    }
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    let rgb = '#', c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ('00' + c).substr(c.length);
    }
    Hsl.cache[key] = rgb;
    return rgb;
  }
}

export namespace Colors {
  export function getHSP(color) {
    if (!color) return 0;

    // Variables for red, green, blue values
    let r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    } else {

      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    return hsp;
  }

  export function LightOrDark(color) {
    const hsp = this.getHSP(color);
    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 203.5) {
      return 'light';
    } else {
      return 'dark';
    }
  }

  export function IsLightColor(color) {
    const hsp = this.getHSP(color);
    return hsp > 203.5;
  }

  export function IsDarkColor(color) {
    const hsp = this.getHSP(color);
    return hsp <= 203.5;
  }

  export function IsWhite(color) {
    const hsp = this.getHSP(color);
    return hsp > 254;
  }

  export function IsBlack(color) {
    const hsp = this.getHSP(color);
    return hsp < 1;
  }
}
