import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {InvitationEffects} from './invitation.effects';
import {reducer} from './invitation.reducer';
import {stateKey} from './invitation.selectors';
import {MembershipInvitationService} from './membership-invitation.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([InvitationEffects])
  ],
  providers: [
    InvitationEffects,
    MembershipInvitationService
  ]
})
export class InvitationStoreModule {
}
