export class FileCardFile {
  public id: string | undefined;
  public filename: string;
  public size: number;
  public url: string;
  public lastChangeDate: Date;

  constructor() {

  }
}
