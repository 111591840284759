import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './project-room.state';
import {ProjectRoomType} from 'app/+store/project-room/project-room.interface';
import {ProjectRoom} from 'app/+store/project-room/project-room';
import {ProcessSelectors} from '../process';

export const stateKey = 'project-room';
const getProjectState = createFeatureSelector<State>(stateKey);

export const getProjectById = (id: string) => createSelector(
  ProcessSelectors.getProcessById(id),
  process => new ProjectRoom(process.id, ProjectRoomType.Default)
);
