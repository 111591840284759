import {IResource} from 'app/shared/modules/api/models/resource.interface';

export enum ExportType {
  StandardExport = 'standard_export'
}

export const DEFAULT_EXPORT_TYPE = ExportType.StandardExport;
export const DEFAULT_CUSTOM_PREFIX = '';
export const DEFAULT_INCLUDE_INDEX_HTML = true;
export const DEFAULT_INCLUDE_XML = true;
export const DEFAULT_COLLECTOR_ITEM_PREFIX = true;
export const DEFAULT_COLLECTOR_EXCLUDE_TEMPLATES = true;
export const DEFAULT_INCLUDE_AUDIT_TRAIL = false;
export const DEFAULT_MARK_EXPORTED = true;
export const SKIP_EXPORTED_FILES = false;

export interface IDmsDocumentExportParams {
  export_type: ExportType;
  custom_file_prefix: string;
  include_index_html: boolean;
  include_xml: boolean;
  collector_item_prefix: boolean;
  exclude_collector_templates: boolean;
  include_audit_trail: boolean;
  mark_file_exported: boolean;
  skip_exported_files: boolean;
}

export interface IDmsDocumentExport extends IResource {
  title: string;
  performerEmail: string;
  processId: string;
  exportType: ExportType;
  status: string;
  size: number;
  fingerprint: string;
  hashAlgorithm: string;
  exportTransactionId: string;
  createdAt: Date;
  updatedAt: Date;
}
