<div [formGroup]="createOrgaFrom">

  <div class="icon-content-layout">
    <mat-icon>domain</mat-icon>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-5">
        <dvtx-api-select-cva
          formControlName="Rechtsform"
          class="type-or-location"
          listingPath="name"
          listingType="legal-form"
          [elementName]="'CONTACTS.LEGAL_FORM' | translate"
          [requiredField]="true"
        ></dvtx-api-select-cva>
      </div>
      <div class="col-12 col-sm-6 col-md-5">
        <dvtx-text-input-cva formControlName="Name" class="comp-name"
                             [elementName]="'CONTACTS.ORG_NAME' | translate"
                             [requiredField]="true" ></dvtx-text-input-cva>
      </div>
    </div>
  </div>

  <div class="icon-content-layout">
    <mat-icon>email</mat-icon>
    <div class="row">
      <div class="col-12 col-md-10">
        <dvtx-email-address-input
          formControlName="EmailAddress"
          [elementName]="'CONTACTS.ADDRESS' | translate"
          [errorMapper]="[{error:'InvalidEmailAddress', message:'ORGANIZATION.CREATION.INVALID_EMAIL' | translate}]"
          [requiredField]="true"
        ></dvtx-email-address-input>
      </div>
      <div class="col-12">
        <dvtx-email-address-group-cva *ngIf="displayFurtherDataFields && contactOrganization && !isDisabled"
                                      [emailAddresses]="$any(contactOrganization).emailAddresses"
                                      (addEmail)="addFurtherEmail($event)"
                                      (removeEmail)="removeFurtherEmail($event)"
                                      [disabled]="isDisabled"
        ></dvtx-email-address-group-cva>
      </div>
    </div>
  </div>

  <div class="icon-content-layout">
    <mat-icon>phone</mat-icon>
    <div class="row">
      <div class="col-12 col-md-10">
        <dvtx-telephone-input formControlName="PhoneNumber"
                              [elementName]="'CONTACTS.ADDRESS' | translate"
                              [isType]="true"></dvtx-telephone-input>
      </div>
      <div class="col-12">
        <dvtx-telephone-group-cva *ngIf="displayFurtherDataFields && contactOrganization && !isDisabled"
                                  [telephonenumbers]="$any(contactOrganization).phoneNumbers"
                                  (addPhone)="addFurtherPhoneNumber($event)"
                                  (removePhone)="removeFurtherPhoneNumber($event)"
                                  [disabled]="isDisabled">
        </dvtx-telephone-group-cva>
      </div>
    </div>
  </div>

  <div class="icon-content-layout">
    <mat-icon>place</mat-icon>
    <div class="icon-content-layout--content">
      <div class="row">
        <div class="col-12 col-md-10">
          <dvtx-address-input-cva
            formControlName="Address"
            [elementName]="'CONTACTS.ADDRESS' | translate"
            [isDisabled]="isDisabled"
          ></dvtx-address-input-cva>
        </div>
        <div class="col-12">
          <dvtx-address-input-group-cva *ngIf="displayFurtherDataFields && contactOrganization && !isDisabled"
                                        [enableLocationOrType]="true"
                                        [addresses]="$any(contactOrganization).addresses"
                                        (addAddress)="addFurtherAddress($event)"
                                        (removeAddress)="removeFurtherAddress($event)"
                                        [disabled]="isDisabled"
          ></dvtx-address-input-group-cva>
        </div>
      </div>
    </div>
  </div>
</div>
