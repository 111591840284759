import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {DmsDocumentExport, DmsDocumentExportTransaction} from './dms-document-export';

export class DmsDocumentExportTransactionBuilder implements IApiResourceBuilder<DmsDocumentExportTransaction> {
  fromResponse(data): DmsDocumentExportTransaction {
    return new DmsDocumentExportTransaction(data.id);
  }

  toRequest(_: DmsDocumentExportTransaction) {
    return null;
  }
}

export class DmsDocumentExportBuilder implements IApiResourceBuilder<DmsDocumentExport> {
  fromResponse(data): DmsDocumentExport {
    return new DmsDocumentExport(
      data.id,
      data.attributes.title,
      data.attributes.performer_email,
      data.attributes.process_id,
      data.attributes.export_type,
      data.attributes.status,
      data.attributes.size,
      data.attributes.fingerprint,
      data.attributes.hash_algorithm,
      data.attributes.export_transaction_id,
      data.attributes.created_at,
      data.attributes.updated_at);
  }

  toRequest(_: DmsDocumentExport) {
    return null;
  }
}
