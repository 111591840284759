import {ContactImportItem} from './contact-import-item';
import {Appendix} from './appendix';
import {ProcessArtifact} from '../../../../+store/process-artifact/process-artifact';

export class AuditAuditorCommunications {
  id: string | undefined;
  title: string;
  clientId: string;
  dueDate: Date;
  startsAt: Date;
  endsAt: Date;
  parentProcessId: string | undefined;
  contactEntries: ContactImportItem[];
  appendices: Appendix[];
  instructionUploadUrl: string;
  artifacts: ProcessArtifact[];
  contracteeName: string;

  constructor() {
    this.appendices = [];
    this.contactEntries = [];
  }
}

