import {adapter, initialState, State} from './kanban-group.state';
import {KanbanGroupActions, KanbanGroupActionTypes} from './kanban-group.actions';

export function reducer(state = initialState, action: KanbanGroupActions): State {
  const selectedKanban = state.selected;

  switch (action.type) {
    case KanbanGroupActionTypes.LoadAll:
    case KanbanGroupActionTypes.LoadOne:
      return {
        ...state,
        selected: null,
        loading: true
      };

    case KanbanGroupActionTypes.LoadAllSuccess:
      return adapter.setAll(action.kanbanGroups, {
        ...state,
        selected: selectedKanban ? state.entities[selectedKanban.id] : null,
        loading: false,
      });

    case KanbanGroupActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.kanbanGroup, {
        ...state,
        selected: action.kanbanGroup,
        loading: false
      });

    case KanbanGroupActionTypes.LoadAllFail:
    case KanbanGroupActionTypes.LoadOneFail:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case KanbanGroupActionTypes.Create:
    case KanbanGroupActionTypes.Edit:
      return {
        ...state,
        selected: null
      };

    case KanbanGroupActionTypes.CreateSuccess:
      return adapter.upsertOne(action.kanbanGroup, {
        ...state
      });

    case KanbanGroupActionTypes.EditSuccess:
      return adapter.upsertOne(action.kanbanGroup, {
        ...state
      });

    case KanbanGroupActionTypes.CreateFail:
    case KanbanGroupActionTypes.EditFail:
      return {
        ...state
      };

    case KanbanGroupActionTypes.Remove:
      return {
        ...state,
        selected: null
      };

    case KanbanGroupActionTypes.RemoveSuccess:
      return adapter.removeOne(action.id, {
        ...state
      });

    case KanbanGroupActionTypes.Reset:
      return adapter.removeAll({
        ...state
      });

  }
  return state;
}
