<ng-template cdkPortal #title>
  <button class="mat-dialog-close" mat-mini-fab color="warn" (click)="closeDialog()">
    <mat-icon aria-label="close dialog">clear</mat-icon>
  </button>
</ng-template>

<ng-template cdkPortal #context>

  <dvtx-audit-trail [processId]="process?.id"></dvtx-audit-trail>
</ng-template>
