import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'dvtx-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss']
})
export class RadioGroupComponent {

  @Input() form;
  @Input() controlName: UntypedFormControl;
  @Input() radioButtons;
  @Output() onValueChanged: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  onClick(val: boolean) {
    this.onValueChanged.emit(val);
  }
}
