import { Component } from '@angular/core';

@Component({
  selector: 'dvtx-email-registered',
  templateUrl: './email-exists.html',
  styleUrls: ['./email-exists.scss']
})
export class EmailExistsComponent {

  constructor() {}

}

