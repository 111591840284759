import {IProcess} from '../models/process.interface';
import {Process} from '../models/process';
import {DmsAccountType} from '../../../../+store/dms-folder/dms-folder.interface';

/**
 * Singleton to create a simplified process structure.
 */
export namespace ProcessBuilder {
  export function fromResponse(response, catalog = null): IProcess {
    const attrs = response.attributes;
    const process =  new Process(response.id, attrs.process_type, attrs.revision,
      attrs.title, attrs.subtitle,
      attrs.description, attrs.due_date);
    process.status = attrs.status;
    process.identifier = attrs.identifier;
    return process;
  }

  export function toRequest(record: IProcess) {
    const payload = {
      data: {
        id: record.id,
        type: record.type,
        attributes: {
          title: record.title,
          subtitle: record.subtitle,
          description: record.description,
          due_date: record.due_date,
          dms_folder_id: record.dms_folder_id,
          dms_account_type: record.dms_account_type === DmsAccountType.Organization ? 'organization' : 'private',
          color: record.color,
          identifier: record.identifier,
          client_uuid: record.clientId,
          profile: record.profile,
        }
      }
    };
    if (record.dms_folder_id && record.dms_path) {
      payload['data']['attributes']['dms_folder_id'] = record.dms_folder_id;
      payload['data']['attributes']['dms_path'] = record.dms_path;
    }
    return payload;
  }
}
