import { OrganizationMember } from '../models/contact';
import { IContactAddress, IContactEmailAddress, IContactPhoneNumber } from '../models/contact.interface';
import { RelationBuilder } from '../../../shared/modules/api/builders/relation.builder';

export namespace OrganizationMemberBuilder {
  export function fromResponse(response, catalog = null): OrganizationMember {
    const attrs = response.attributes;
    const person = new OrganizationMember(response.id, attrs.visibility, attrs.note, attrs.gender, attrs.title,
                                          attrs.first_name, attrs.last_name);

    if (response.relationships && catalog) {
      const relationships = response.relationships;
      person.address = <IContactAddress>RelationBuilder.build(relationships.address, catalog);
      person.addresses = <IContactAddress[]>RelationBuilder.build(relationships.addresses, catalog);
      person.emailAddress = <IContactEmailAddress>RelationBuilder.build(relationships.email_address, catalog);
      person.emailAddresses = <IContactEmailAddress[]>RelationBuilder.build(relationships.email_addresses, catalog);
      person.phoneNumber = <IContactPhoneNumber>RelationBuilder.build(relationships.phone_number, catalog);
      person.phoneNumbers = <IContactPhoneNumber[]>RelationBuilder.build(relationships.phone_numbers, catalog);
      // person.organizations = <ContactOrganization[]>RelationBuilder.build(relationships.organizations, catalog);
    }
    return person;
  }

  export function toRequest(record: OrganizationMember) {
    return {
      data: {
        id: record.id,
        type: record.type,
        attributes: {
          visibility: record.visibility,
          note: record.note,
          gender: record.gender,
          title: record.title,
          first_name: record.firstName,
          last_name: record.lastName
        }
      }
    };
  }

  // export function toRequest(record: ContactPerson | ContactOrganization) {
  //   const contact = { id: record.id, type: record.type, attributes: {} };
  //   if (record instanceof ContactPerson) {
  //     contact.attributes['gender'] = (<ContactPerson>record).gender;
  //     contact.attributes['title'] = (<ContactPerson>record).title;
  //     contact.attributes['first_name'] = (<ContactPerson>record).firstName;
  //     contact.attributes['last_name'] = (<ContactPerson>record).lastName;
  //
  //   } else if (record instanceof ContactOrganization) {
  //     contact.attributes['name'] = (<ContactOrganization>record).name;
  //     contact.attributes['abbreviation'] = (<ContactOrganization>record).abbreviation;
  //     contact.attributes['legal_form_id'] = (<ContactOrganization>record).legalFormId;
  //   }
  //   contact.attributes['visibility'] = record.visibility;
  //   contact.attributes['note'] = record.note;
  // }
}
