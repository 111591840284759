import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CmsArticleEffects} from './cms-article.effects';
import {CmsArticleService} from './cms-article.service';
import {reducer} from './cms-article.reducer';
import {stateKey} from './cms-article.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([CmsArticleEffects])
  ],
  providers: [
    CmsArticleEffects,
    CmsArticleService
  ]
})
export class CmsArticleStoreModule {
}
