import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {DocumentPreviewPreview} from './document-preview-preview';
import {DocumentPreviewPreviewBuilder} from './document-preview-preview.builder';
import {PvmsApiResourceService} from '../document-preview/pvms-api-resource.service';
import * as store from 'store';

@Injectable()
export class DocumentPreviewPreviewService {
  readonly BASE_PATH = 'api/v1/previews';

  /** local storage is available: If not the lang variable can only be set by the user account API */
  protected storeSupported = true;

  constructor(private _http: PvmsApiResourceService) {}

  getOne(document_id: string, preview_id: string): Observable<DocumentPreviewPreview> {
    const builder = new DocumentPreviewPreviewBuilder();
    return <Observable<DocumentPreviewPreview>>this._http.get<DocumentPreviewPreviewBuilder, DocumentPreviewPreview>(builder, `${this.BASE_PATH}/${document_id}/previews/${preview_id}`);
  }

  getOneByUrl(url: string, invalidateCache: boolean = false): Observable<DocumentPreviewPreview> {
    if (!invalidateCache && this.isPreviewInStore(url)) {
      const prev = this.getPreviewFromStoreByUrl(url);
      if (prev !== null) { return of(prev); }
    }
    const builder = new DocumentPreviewPreviewBuilder();
    const urlEnd = url.split(this.BASE_PATH).pop();
    return <Observable<DocumentPreviewPreview>>this._http.get<DocumentPreviewPreviewBuilder, DocumentPreviewPreview>(builder, `${this.BASE_PATH}${urlEnd}`).pipe(
      tap((data: DocumentPreviewPreview) => {
        if (this.storeSupported) { const succ = this.savePreviewInStore(url, data); }
      })
    );
  }

  private savePreviewInStore(url: string, preview: DocumentPreviewPreview): boolean {
    try {
      if (!this.storeSupported) { return true; }
      store.set(url, JSON.stringify(preview));
      const written = !!store.get(url);
      this.storeSupported = written;
      return written;
    } catch (err) {
      this.storeSupported = false;
      return false;
    }
  }

  private getPreviewFromStoreByUrl(url: string): DocumentPreviewPreview {
    try {
      if (!this.storeSupported) { return null; }
      const prev = store.get(url);
      const prevObj = JSON.parse(prev);
      return Object.assign(new DocumentPreviewPreview(prevObj.id, prevObj.page, prevObj.state), prevObj);
    } catch (err) {
      return null;
    }
  }

  private isPreviewInStore(url: string): boolean {
    try {
      if (!this.storeSupported) { return false; }
      return !!store.get(url);
    } catch (err) {
      return false;
    }
  }

}
