import {Injectable} from '@angular/core';
import {catchError, concatMap, first, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  AddAttachmentFromDMS,
  AddAttachmentFromDMSFail,
  AddAttachmentFromDMSSuccess,
  AddResponsible,
  AddResponsibleFail,
  AddResponsibleSuccess,
  Close,
  CloseFail,
  CloseSuccess,
  Create,
  CreateFail,
  CreateSuccess,
  Delete,
  DeleteFail,
  DeleteSuccess,
  Edit,
  EditFail,
  EditSuccess,
  LoadMy,
  LoadMySuccess,
  LoadMyFail,
  LoadAll,
  LoadAllAndAppend,
  LoadAllAndAppendFail,
  LoadAllAndAppendSuccess,
  LoadAllFail,
  LoadAllOrganizationTasks,
  LoadAllOrganizationTasksFail,
  LoadAllOrganizationTasksSuccess,
  LoadAllSuccess,
  LoadAllUserTasks,
  LoadAllWithoutPagingSuccess,
  LoadMore,
  LoadMoreSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  LoadQuery,
  LoadQuerySuccess,
  Open,
  OpenFail,
  OpenSuccess,
  RemoveAttachment,
  RemoveAttachmentFail,
  RemoveAttachmentSuccess,
  Reorder,
  ReorderFail,
  ReorderSuccess,
  TaskActionTypes,
  SkipFetching
} from './task.actions';
import {TaskService} from './task.service';
import {Task} from './task';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import * as TaskAssigneeActions from 'app/+store/task-assignee/task-assignee.actions';
import * as TaskResourceActions from 'app/+store/task-resource/task-resource.actions';
import * as ProcessActions from 'app/+store/process/process.actions';
import * as ProjectStatisticsActions from 'app/+store/project-statistics/project-statistics.actions';
import {ItemLabelsActions} from '../item-labels';
import {TaskSelectors} from './index';

@Injectable()
export class TaskEffects {
  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(TaskActionTypes.LoadAll),
    concatMap((action: LoadAll) => {
      return this._svc.getAll(action.id, action.showArchived, action.recursive).pipe(
        first(),
        concatMap((res: Task[]) => {
            const taskIds = [];
            res = res.map(task => {
              task.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === task.organizationId;
              taskIds.push(task.id);
              return task
            });
            return [new LoadAllSuccess(res), new ItemLabelsActions.LoadAll(taskIds)];
          }
        ),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err))
        }));
    })
  );

  @Effect()
  loadMy$ = this.actions.pipe(
    ofType(TaskActionTypes.LoadMy),
    concatMap((_action: LoadMy) => {
      return this._svc.getMy().pipe(
        first(),
        concatMap((res: Task[]) => {
            const taskIds = [];
            res = res.map(task => {
              task.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === task.organizationId;
              taskIds.push(task.id);
              return task
            });
          return [new LoadMySuccess(res), new ItemLabelsActions.LoadAll(taskIds)];
          }
        ),
        catchError(err => {
          console.error(err);
          return of(new LoadMyFail(err))
        }));
    })
  );

  @Effect()
  loadAllAndAppend$ = this.actions.pipe(
    ofType(TaskActionTypes.LoadAllAndAppend),
    concatMap((action: LoadAllAndAppend) => {
      return this._svc.getAll(action.id, action.showArchived, action.recursive).pipe(
        first(),
        concatMap((res: Task[]) => {
            return [new LoadAllAndAppendSuccess(res)];
          }
        ),
        catchError(err => {
          console.log(err);
          return of(new LoadAllAndAppendFail(err))
        }));
    })
  );

  @Effect()
  loadAllUserTasks$ = this.actions.pipe(
    ofType(TaskActionTypes.LoadAllUserTasks),
    switchMap((action: LoadAllUserTasks) => {
      return this._svc.getAllUserTasks(action.email, action.dense).pipe(
        first(),
        map((res: Task[]) => {
            res = res.map(task => {
              task.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === task.organizationId;
              return task
            })
            return new LoadAllSuccess(res);
          }
        ),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err))
        }));
    })
  );

  @Effect()
  loadAllOrganizationTasks$ = this.actions.pipe(
    ofType(TaskActionTypes.LoadAllOrganizationTasks),
    switchMap((action: LoadAllOrganizationTasks) => {
      return this._svc.getOrganizationTasks().pipe(
        first(),
        map((res: Task[]) => {
            res = res.map(task => {
              task.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === task.organizationId;
              return task
            })
            return new LoadAllOrganizationTasksSuccess(res);
          }
        ),
        catchError(err => {
          console.error(err);
          return of(new LoadAllOrganizationTasksFail(err))
        }));
    })
  );

  @Effect()
  loadAllWithoutPaging$ = this.actions.pipe(
    ofType(TaskActionTypes.LoadAllWithoutPaging),
    switchMap((action: LoadAll) => {
      return this._svc.getAllWithoutPaging().pipe(
        first(),
        map((res: Task[]) => {
            res = res.map(task => {
              task.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === task.organizationId;
              return task
            })
            return new LoadAllWithoutPagingSuccess(res);
          }
        ),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err))
        }));
    })
  );

  @Effect()
  loadQuery$ = this.actions.pipe(
    ofType(TaskActionTypes.LoadQuery),
    switchMap((action: LoadQuery) => {
      return this._svc.getAllByQuery(action.payload).pipe(
        first(),
        map((res: Task[]) => {
            res = res.map(task => {
              task.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === task.organizationId;
              return task
            })
            return new LoadQuerySuccess(res);
          }
        ),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err))
        }));
    })
  );

  @Effect()
  loadMore$ = this.actions.pipe(
    ofType(TaskActionTypes.LoadMore),
    switchMap((action: LoadMore) => {
      return this._svc.getAllByQuery(action.payload).pipe(
        first(),
        map((res: Task[]) => {
            res = res.map(task => {
              task.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === task.organizationId;
              return task
            })
            return new LoadMoreSuccess(res);
          }
        ),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err))
        }));
    })
  );

  @Effect()
  loadOne$ = this.actions.pipe(
    ofType(TaskActionTypes.LoadOne),
    withLatestFrom(this.store.select(TaskSelectors.getTaskEntities)),
    concatMap(([action, taskEntities]: [LoadOne, any]) => {
      if (action.useCache && taskEntities[action.id]) {
        return of(new SkipFetching());
      }
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((task: Task) => {
          task.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === task.organizationId;
          return [new LoadOneSuccess(task)];
        }),
        catchError(err => {
          if (!action.disableNotification) {
            this._notifyService.error('TASK.ERRORS.LOADING_TASK_ERROR')
          }
          return of(new LoadOneFail(err));
        }));
    })
  );

  @Effect()
  create$ = this.actions.pipe(
    ofType(TaskActionTypes.Create),
    concatMap((action: Create) => {
      // move all notification message before request to prevent notification deffect , but
      // when the request fail an error message will appear.
      this._notifyService.success('TASK.CREATED_TASK_SUCCESS');
      return this._svc.create(action.task).pipe(
        first(),
        concatMap((res: Task) => {
          res.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === res.organizationId;
          if (action.resource) {
            this.store.dispatch(
              new TaskResourceActions.CreateResource(
                res.id,
                action.resource,
                action.referenceId,
                action.referenceType
              )
            );
          }
          if (action.task.assignees && action.task.assignees.length > 0) {
            action.task.assignees.forEach((assignee) => {
              this.store.dispatch(
                new TaskAssigneeActions.AddAssignment(
                  res.id,
                  typeof (assignee) === 'string' ? assignee : assignee.email
                )
              );
            });
          }
          if (res.responsible.email !== res.creator.email && !action.task.assignees.find(assignee => assignee.email === res.creator.email)) {
            this.store.dispatch(
              new TaskAssigneeActions.AddAssignment(
                res.id,
                res.creator.email
              )
            );
          }
          this.store.dispatch(new ProjectStatisticsActions.LoadAll(res.process.id));

          if (!action.task.process.id) {
            return [new CreateSuccess(res), new ProcessActions.RunCommand(res.process.id, 'index', 'on_click')]
          }
          return [new CreateSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.CREATED_TASK_FAILURE')
          return of(new CreateFail(err))
        }));
    })
  );

  @Effect()
  update$ = this.actions.pipe(
    ofType(TaskActionTypes.Edit),
    switchMap((action: Edit) => {
      this._notifyService.success('TASK.UPDATE_TASK_SUCCESS')
      return this._svc.update(action.task).pipe(
        first(),
        switchMap((res: Task) => {
          res.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === res.organizationId;
          return [new EditSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.UPDATE_TASK_FAILURE')
          return of(new EditFail(err))
        }));
    })
  );

  @Effect()
  close$ = this.actions.pipe(
    ofType(TaskActionTypes.Close),
    switchMap((action: Close) => {
      this._notifyService.success('TASK.CLOSE_TASK_SUCCESS')
      return this._svc.close(action.taskId).pipe(
        first(),
        switchMap((res: Task) => {
          res.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === res.organizationId;
          return [new CloseSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.CLOSE_TASK_FAILURE')
          return of(new CloseFail(err))
        }));
    })
  );

  @Effect()
  open$ = this.actions.pipe(
    ofType(TaskActionTypes.Open),
    switchMap((action: Open) => {
      this._notifyService.success('TASK.OPEN_TASK_SUCCESS')
      return this._svc.open(action.taskId).pipe(
        first(),
        switchMap((res: Task) => {
          res.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === res.organizationId;
          return [new OpenSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.OPEN_TASK_FAILURE')
          return of(new OpenFail(err))
        }));
    })
  );

  @Effect()
  addResponsible$ = this.actions.pipe(
    ofType(TaskActionTypes.AddResponsible),
    switchMap((action: AddResponsible) => {
      this._notifyService.success('TASK.ADD_RESPONSIBLE_SUCCESS')
      return this._svc.responsible(action.taskId, action.email).pipe(
        first(),
        switchMap((res: Task) => {
          res.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === res.organizationId;
          return [new AddResponsibleSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.ADD_RESPONSIBLE_FAILURE')
          return of(new AddResponsibleFail(err))
        }));
    })
  );

  @Effect()
  delete$ = this.actions.pipe(
    ofType(TaskActionTypes.Delete),
    switchMap((action: Delete) => {
      this._notifyService.success('TASK.DELETED_TASK_SUCCESS')
      return this._svc.delete(action.task).pipe(
        first(),
        switchMap((res: Task) => {
          this.store.dispatch(new ProjectStatisticsActions.LoadAll(res.process.id));
          return [new DeleteSuccess(res)];
        }),
        catchError(err => {
          if (err && err.status === 404) {
            return of(new DeleteSuccess(action.task))
          } else {
            this._notifyService.error('TASK.ERRORS.DELETED_TASK_FAILURE')
            return of(new DeleteFail(err))
          }
        }));
    })
  );

  @Effect()
  reorder$ = this.actions.pipe(
    ofType(TaskActionTypes.Reorder),
    switchMap((action: Reorder) => {
      return this._svc.reorder(action.tasks).pipe(
        first(),
        concatMap((res: Task[]) => {
          res = res.map(task => {
            task.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === task.organizationId;
            return task
          })
          return [new ReorderSuccess(res)];
        }),
        catchError(err => {
          return of(new ReorderFail(err))
        }));
    })
  );

  @Effect()
  addAttachment$ = this.actions.pipe(
    ofType(TaskActionTypes.AddAttachmentFromDMS),
    switchMap((action: AddAttachmentFromDMS) => {
      this._notifyService.success('UPLOAD.SUCCESS_DEFAULT')
      return this._svc.addAttachmentFromDMS(action.taskId, action.fileId).pipe(
        first(),
        switchMap((res: Task) => {
          res.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === res.organizationId;
          return [new AddAttachmentFromDMSSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('UPLOAD.UPLOAD_FAILURE')
          return of(new AddAttachmentFromDMSFail(err))
        }));
    })
  );

  @Effect()
  removeAttachment$ = this.actions.pipe(
    ofType(TaskActionTypes.RemoveAttachment),
    switchMap((action: RemoveAttachment) => {
      this._notifyService.success('DMS.DOCUMENT_DELETED')
      return this._svc.deleteAttachment(action.taskId, action.attachmentId).pipe(
        first(),
        switchMap((res: Task) => {
          res.permissions.canUseLabels = localStorage.getItem('selectedOrganizationId') === res.organizationId;
          return [new RemoveAttachmentSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('GENERAL.AN_ERROR_OCCURRED')
          return of(new RemoveAttachmentFail(err))
        }));
    })
  );


  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private _svc: TaskService,
    private _translateSvc: TranslateService,
    private _notifyService: NotificationService) {
  }

}
