import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {CreateContactDialogComponent} from '../components/create-contact-dialog/create-contact-dialog.component';
import {LicenceControlService} from 'app/shared/services/licence-control.service';
import {Organization} from 'app/models/organization.model';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {OrganizationSelectors} from 'app/+store';
import {CreateOrganizationDialogContainerComponent} from 'app/modules/address-book/modules/address-book-table/containers/create-organization-dialog-container/create-organization-dialog-container.component';
import {LicenceUpgradeDialogComponent} from 'app/shared/components/dialogs/licence-upgrade-dialog/licence-upgrade-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Directive({
  selector: '[dvtxContact]'
})
export class ContactDirective implements OnInit {

  @Input() contactType: 'contact_people' | 'contact_organizations' = 'contact_people';
  @Input() contactToolbar = true;
  @Input() lockContactType = true;
  @Input() parent = null;
  @Output() onNewContact = new EventEmitter<any>();

  organizations: Observable<Organization[]>;
  organizationsCount: number;

  constructor(private el: ElementRef, public dialog: MatDialog, private _store: Store<AppState>, private licenceControl: LicenceControlService, private router: Router,
              private _translateSvc: TranslateService) {
    this.organizations = this._store.select(OrganizationSelectors.getAllOrganizations);
    this.organizations.subscribe((organizations) => {
      this.organizationsCount = organizations.length;
    })
  }

  @HostListener('click')
  onClick() {
    const licenceChecker = this.licenceControl.hasRequiredLicence('Standard');
    if (licenceChecker) {
      if (this.organizationsCount === 0) {
        this.dialog.closeAll();
        this.dialog.open(LicenceUpgradeDialogComponent, {
          data: {
            title: 'REGISTRATION.ORGANIZATION_IS_NEEDED_TITLE',
            message: 'REGISTRATION.ORGANIZATION_IS_NEEDED_MESSAGE',
            onSubmitAction: () => {
              this.dialog.open(CreateOrganizationDialogContainerComponent);
            },
            submitButtonTitle: this._translateSvc.instant('ORGANIZATION.CREATION.CREATE')
          },
          width: '400px',
        });
      } else {
        this.openDialog();
      }
    }
  }

  /**
   * open dialog with files uploading on click element this directive attached to
   */
  openDialog() {
    const dialogRef = this.dialog.open(CreateContactDialogComponent,
      {
        data: {
          options: {
            contactType: this.contactType,
            lockContactType: this.lockContactType,
            autosave: false,
            contactToolbar: this.contactToolbar,
            parent: this.parent
          }
        }
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.onNewContact.emit(ContactRequestBuilder.generateDataForApi(result));
        console.log(result);
      }
    });
  }

  ngOnInit(): void {
    this.licenceControl.elementRef = this.el;
    // setTimeout(_ => this.openDialog(), 100);
  }
}
