import {IResource} from '../../shared/modules/api/models/resource.interface';

export enum TwoFactorStatus {
  Success = 'Success',
  Denied = 'Denied',
  Canceled = 'Canceled'
}

export interface ITwoFactorSession extends IResource {
  status: TwoFactorStatus;
  expiresAt: Date;
}

export interface ITwoFactorMemberStatus extends IResource {
  otpEnabled: boolean;
}
