import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './sms-record.state';

export const stateKey = 'operator-sms-record';
const getSmsRecordState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getSmsRecordEntities,
  selectAll: getAllSmsRecords,
} = adapter.getSelectors(getSmsRecordState);

export const loadingState = createSelector(
  getSmsRecordState,
  (_state) => _state.loading
);

export const getSmsRecordById = (id: string) => createSelector(
  getSmsRecordEntities,
  (sms) => sms[id]
);

export const getSelectedSmsRecord = createSelector(
  getSmsRecordState,
  (state) => state.selected
);
