/* tslint:disable:member-ordering */
import { Action } from '@ngrx/store';

// action types
export const GET_MAIN_HEADER_HEIGHT = 'GET_MAIN_HEADER_HEIGHT';

// get main header height action creator
export class GetMainHeaderHeight implements Action {
  readonly type: string = GET_MAIN_HEADER_HEIGHT;

  constructor(public payload: number) {

  }
}

export type Actions = GetMainHeaderHeight;
