import {FastdocsActions, FastdocsActionTypes} from './fastdocs.actions';
import {adapter, initialState, State} from './fastdocs.state';

export function reducer(state = initialState, action: FastdocsActions): State {
  switch (action.type) {
    // LOAD ALL
    case FastdocsActionTypes.LoadAll:
      return {
        ...state,
        loading: action.loading
      };

    case FastdocsActionTypes.LoadAllSuccess:
      return adapter.setAll(action.fastdocsItems, {
          ...state,
          selected: null,
          loading: false
      });

    case FastdocsActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false
      };

    // LOAD ONE
    case FastdocsActionTypes.LoadOne:
      return {
        ...state,
        loading: false
      };

    case FastdocsActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.fastdocsItem, {
        ...state,
        selected: action.fastdocsItem,
        loading: false
      });

    case FastdocsActionTypes.LoadOneFail:
      return {
        ...state,
        loading: false
      };

    case FastdocsActionTypes.Reset:
      return Object.assign({}, initialState);
  }

  return state;
}
