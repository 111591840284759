import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {PrivilegedProcess} from './process-management';

export interface State extends EntityState<PrivilegedProcess> {
  loading: boolean;
}

export const adapter = createEntityAdapter<PrivilegedProcess>();

export const initialState: State = adapter.getInitialState({
  loading: false
});
