import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {stateKey} from './collector-export.selectors';
import {reducer} from './collector-export.reducer';
import {CollectorExportEffects} from './collector-export.effects';
import {CollectorExportService} from './collector-export.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([CollectorExportEffects])
  ],
  providers: [
    CollectorExportEffects,
    CollectorExportService
  ]
})
export class CollectorExportStoreModule {
}
