import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {CollectorConfirmation} from './collector-confirmation';
import * as dayjs from 'dayjs';

export interface State extends EntityState<CollectorConfirmation> {
  loading: boolean;
}

export const adapter = createEntityAdapter<CollectorConfirmation>({
  sortComparer: (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)))
});

export const initialState: State = adapter.getInitialState({
  loading: false,
});
