import { IRole } from './role.interface';

export class Role implements IRole {
  constructor(public symbol: string) {}

  get name() {
    switch (this.symbol) {
      case 'contributor':
        return 'Teilnehmer';
      case 'delegator':
        return 'Teilnehmer';
      case 'moderator':
        return 'Moderator';
      case 'admin':
        return 'Admin';
      case 'owner':
        return 'Besitzer';
      case 'creator':
        return 'Ersteller';
      case 'subscriber':
        return 'Beobachter';
      case 'auditor':
        return 'Wirtschaftsprüfer';
    }
  }
}
