import { IContact } from '../models/contact.interface';
import * as currentContactsActions from '../actions/current-contact.actions';
import * as contactsActions from '../actions/contacts.actions';

export type CurrentContactState = IContact;
export const initialState: CurrentContactState = null;

export function currentContactReducer(state: CurrentContactState = initialState, action: currentContactsActions.Actions): CurrentContactState {

  switch (action.type) {
    case contactsActions.GET_CONTACT_SUCCESS:
      return action.payload;

    case currentContactsActions.SET_CURRENT_CONTACT:
      return action.payload;

    case currentContactsActions.GET_CONTACT_MEMBERS_SUCCESS:
      return action.payload;

    case currentContactsActions.CLEAR_CURRENT_CONTACT:
      return initialState;

    default: {
      return state;
    }
  }
};

