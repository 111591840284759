import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {CsvImportComponent} from './components/csv-import/csv-import.component';
import {CsvImportDropZoneComponent} from './components/csv-import-drop-zone/csv-import-drop-zone.component';
import {SharedModule} from '../../shared.module';
import {CsvImportDisplayTableComponent} from './components/csv-import-display-table/csv-import-display-table.component';
import {CsvImportService} from './services/csv-import.service';
import {CsvImportErrorBoxComponent} from './components/csv-import-error-box/csv-import-error-box.component';
import {CsvImportDisplayComponent} from './components/csv-import-display/csv-import-display.component';
import {InViewportModule} from "ng-in-viewport";
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        InViewportModule,
        ScrollingModule
    ],
  declarations: [
    CsvImportComponent,
    CsvImportDropZoneComponent,
    CsvImportDisplayComponent,
    CsvImportDisplayTableComponent,
    CsvImportErrorBoxComponent
  ],
  providers: [
    CsvImportService
  ],
  exports: [
    CsvImportComponent,
    CsvImportDropZoneComponent
  ]
})
export class CsvImportModule {
}
