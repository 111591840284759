import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as dayjs from 'dayjs';
import { TasksStats } from './tasks-stats';

export interface State extends EntityState<TasksStats> {
  selected?: TasksStats;
  loading: boolean
}

// export const adapter = createEntityAdapter<Task>();
export const adapter = createEntityAdapter<TasksStats>({
  sortComparer: (l, r) => (dayjs(l.updatedAt).diff(dayjs(r.updatedAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false
});
