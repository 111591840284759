
import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {startWith,  takeUntil } from 'rxjs/operators';
import {
  Component, OnInit
} from '@angular/core';
import { FilterListSelectionComponent } from '../filter-list-selection/filter-list-selection.component';

@Component({
  selector: 'dvtx-filter-list-selection-organizations',
  templateUrl: './filter-list-selection-organizations.component.html',
  styleUrls: ['./filter-list-selection-organizations.component.scss']
})
export class FilterListSelectionOrganizationsComponent extends FilterListSelectionComponent implements OnInit {

  ngOnInit(): void {
    observableCombineLatest(
      this.options$.pipe(startWith([])),
      this.search$.pipe(startWith('')),
      (options, search) => {
        if (!search || search.length === 0) return this.filterdOptions$.next(options);
        options = options.filter(option =>
          (option && option.name && option.name.toLowerCase().search(search.toLowerCase()) > -1)
        );
        this.filterdOptions$.next(options);
      }
    ).pipe(
      takeUntil(this.onDestroy)
    ).subscribe()
  }
}
