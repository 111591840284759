import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BookmanClientEffects } from './bookman-client.effects';
import { BookmanClientService } from './bookman-client.service';
import { reducer } from './bookman-client.reducer';
import { stateKey } from './bookman-client.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([BookmanClientEffects])
  ],
  providers: [
    BookmanClientEffects,
    BookmanClientService
  ]
})
export class BookmanClientStoreModule {
}
