import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ProcessEventEffects} from './process-event.effects';
import {ProcessEventService} from './process-event.service';
import {reducer} from './process-event.reducer';
import {stateKey} from './process-event.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ProcessEventEffects])
  ],
  providers: [
    ProcessEventEffects,
    ProcessEventService
  ]
})
export class ProcessEventStoreModule {
}
