import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {
  Process,
  ProcessListingItemV3,
  ProcessListingV3Statistics
} from 'app/+store/process/process';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {
  first,
  map,
  takeUntil
} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder} from '@angular/forms';
import {ContactSelectors} from 'app/+store';
import {ProcessListingV3Service} from 'app/+store/process/process-listing-v3.service';
import {MatDialog} from '@angular/material/dialog';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ProcessService} from 'app/+store/process/process.service';
import {PersonContactListDto} from 'app/models/contact-list-dto.model';
import {
  AddressBookTableHelper
} from '../../../address-book/modules/address-book-table/containers/address-book-table/address-book-table.helper';
import {ClientService} from 'app/+store/client/client.service';
import {AbstractUserProcessListing} from 'app/five-f/process-listing/models/abstract-user-process-listing';

@Component({
  selector: 'dvtx-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss']
})
export class ProjectOverviewComponent extends AbstractUserProcessListing implements AfterViewInit, OnInit, OnDestroy {
  chartData$ = new BehaviorSubject<ProcessListingV3Statistics>({
    type: 'workflow_engine_process_statistics',
    id: 'default',
    open: 0,
    closed: 0,
    draft: 0,
    all: 0
  });

  constructor(protected _store: Store<AppState>,
              protected _processV3Svc: ProcessListingV3Service,
              protected _processSvc: ProcessService,
              protected _clientSvc: ClientService,
              protected _router: Router,
              protected _route: ActivatedRoute,
              protected _viewContainerRef: ViewContainerRef,
              protected _dialog: MatDialog,
              protected _notifyService: NotificationService,
              protected _cdr: ChangeDetectorRef,
              protected _ngZone: NgZone,
              protected _fb: UntypedFormBuilder) {
    super(_store,
      _processV3Svc,
      _processSvc,
      _clientSvc,
      _router,
      _route,
      _viewContainerRef,
      _dialog,
      _notifyService,
      _cdr,
      _ngZone,
      _fb)
  }

  ngOnInit() {
    super.ngOnInit();

    this._ngZone.runOutsideAngular(_ => {
      setTimeout(__ => this._getStatistics(), 3000);
    });

    this._initializeParticipants();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.cardHeaderPortal = this._createPortal(this.cardHeaderPortal, this.cardHeaderContext);
    });
  }

  private _getStatistics() {
    const parentId = this._parentId;
    const clientId = this._clientId;
    this._processV3Svc.getStats('all-projects', parentId, clientId).pipe(first()).subscribe(stats => {
      this.chartData$.next(stats);
    }, err => {
      console.error(err);
    });
  }

  public removeDraft(process: ProcessListingItemV3) {
    const item = process;
    this._processSvc.remove(item.id)
      .pipe(first())
      .subscribe((_process: Process) => {
        this.data$.next(this.data$.value.filter(p => p.id !== item.id));
        setTimeout(_ => this._getStatistics(), 1);
      });
  }

  private _initializeParticipants() {
    const contacts$ = this._store.select(ContactSelectors.getMembersAndContactPersonsOfSelectedOrg())
      .pipe(map(contacts => contacts.filter(contact => contact.hasAccount)));
    const parentId = this._parentId;
    const clientId = this._clientId;
    const participantCall$ = this._processV3Svc.getParticipants(parentId, clientId).pipe(first());

    this._ngZone.runOutsideAngular(_ => {
      setTimeout(__ => {
        combineLatest(contacts$, participantCall$)
          .pipe(takeUntil(this.onDestroy))
          .subscribe(([contacts, participants]) => {
            if (!participants) {
              return;
            }

            let missing = [];
            const contactMap = {};
            let _participants = [];
            if (contacts && contacts.length) {
              contacts.forEach(c => {
                contactMap[c.email] = c;
              });
              participants.forEach(p => {
                if (contactMap[p.id]) {
                  _participants.push(contactMap[p.id])
                } else {
                  missing.push(p);
                }
              });
              _participants = _participants.sort(AddressBookTableHelper.sortByString);
            } else {
              missing = participants;
            }
            missing.forEach(m => {
              const p: PersonContactListDto = {
                id: m.id,
                email: m.email,
                name: `${m.lastName}, ${m.firstName}`,
                firstName: m.firstName,
                lastName: m.lastName
              };
              _participants.push(p);
            })
            this.allParticipants = _participants;
            this.participants$.next(_participants);
            if (_participants && this.selectedParticipantsIds && this.selectedParticipantsIds.length > 0) {
              this.selectedParticipants = _participants.filter(a => this.selectedParticipantsIds.includes(a.email));
              setTimeout(() => {
                this._cdr.detectChanges();
              }, 2000);
            }
          });
      });
    });
  }
}
