import {NgModule} from '@angular/core';
import {OrganizationCardComponent} from './containers/organization-card/organization-card.component';
import {SharedModule} from 'app/shared/shared.module';
import {OrganizationCardNavBarComponent} from './components/tab-nav-bar/organization-card-nav-bar.component';
import {OrganizationCardNavBarLightComponent} from './components/tab-nav-bar-light/organization-card-nav-bar-light.component';
import { SettingsHeaderComponent } from './containers/settings-header/settings-header.component';
import {FolderSettingsHeaderComponent} from './containers/folder-settings-header/folder-settings-header.component';
import {TenantSettingsHeaderComponent} from './containers/tenant-settings-header/tenant-settings-header.component';
import {
  UserProfileSettingsHeaderComponent
} from './containers/user-profile-settings-header/user-profile-settings-header.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    OrganizationCardNavBarComponent,
    OrganizationCardNavBarLightComponent,
    OrganizationCardComponent,
    SettingsHeaderComponent,
    TenantSettingsHeaderComponent,
    FolderSettingsHeaderComponent,
    UserProfileSettingsHeaderComponent
  ],
  exports: [
    OrganizationCardComponent,
    SharedModule,
    OrganizationCardNavBarComponent,
    OrganizationCardNavBarLightComponent,
    SettingsHeaderComponent,
    TenantSettingsHeaderComponent,
    FolderSettingsHeaderComponent,
    UserProfileSettingsHeaderComponent
  ]
})
export class OrganizationCardModule {
}
