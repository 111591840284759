import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './fibu-bookman-account.state';

export const stateKey = 'fibu-bookman-account';
const getFibuBookmanAccountState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getFibuBookmanAccountEntities,
  selectAll: getAllFibuBookmanAccounts,
} = adapter.getSelectors(getFibuBookmanAccountState);

export const isSignedIn = createSelector(
  getFibuBookmanAccountState,
  (state) => state.signedIn
);

export const getAccount = createSelector(
  getAllFibuBookmanAccounts,
  (entities) => entities[0]
);

export const loadingState = createSelector(
  getFibuBookmanAccountState,
  (state) => state.loading
);

export const errorState = createSelector(
  getFibuBookmanAccountState,
  (state) => state.error
);
