import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './collector-attachment.state';

export const stateKey = 'collector-attachment';
const getCollectorAttachmentState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getCollectorAttachmentEntities,
  selectAll: getAllCollectorAttachments,
} = adapter.getSelectors(getCollectorAttachmentState);

export const getSelected = createSelector(
  getCollectorAttachmentState,
  (state) => state.selected
);

export const getAll = createSelector(
  getAllCollectorAttachments,
  (attachments) => attachments
);

export const loadingState = createSelector(
  getCollectorAttachmentState,
  (state) => state.loading
);

export const getAttachmentsByCollectorId = (id: string) => createSelector(
  getAllCollectorAttachments,
  (attachments) => attachments.filter(attachment => attachment.collectorId === id)
);

export const getAttachmentsByElementId = (id: string) => createSelector(
  getAllCollectorAttachments,
  (attachments) => attachments.filter(attachment => attachment.elementId === id)
);

export const getAttachmentCountByElementId = (id: string) => createSelector(
  getAttachmentsByElementId(id),
  (attachments) => attachments.length
);

export const transferOngoing = createSelector(
  getCollectorAttachmentState,
  state => state.transfering
);
