import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SimpleEmailAddress} from '../../../../../../modules/contacts/models/contact.interface';

@Component({
  selector: 'dvtx-email-address-group-cva',
  templateUrl: './email-address-group-cva.component.html',
  styleUrls: ['./email-address-group.component-cva.scss'],
})
export class EmailAddressGroupCvaComponent {
  @Input() emailAddresses: SimpleEmailAddress[] = []
  @Input() disabled: boolean = false;
  @Output() addEmail = new EventEmitter<SimpleEmailAddress>()
  @Output() removeEmail = new EventEmitter<string>()

  addNewEmailField() {
    const emailAddress = new SimpleEmailAddress();
    this.emailAddresses = this.emailAddresses.concat(emailAddress)
  }

  saveEmail(email: SimpleEmailAddress) {
    this.addEmail.emit(email)
  }

  deleteEmail(email: SimpleEmailAddress) {
    if (email.id) {
      this.removeEmail.emit(email.id)
    } else {
      this.emailAddresses = this.emailAddresses.filter(x => x !== email);
    }
  }
}
