import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {RoleEffects} from './role.effects';
import {stateKey} from './role.selectors';
import {reducer} from './role.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([RoleEffects])
  ],
  providers: [RoleEffects]
})
export class RoleStoreModule {
}
