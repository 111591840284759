import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {TwoFactorSession} from './two-factor-session';

export interface State extends EntityState<TwoFactorSession> {
  selected?: TwoFactorSession;
  openDialog: boolean,
  loading: boolean;
}

export const adapter = createEntityAdapter<TwoFactorSession>();

export const initialState: State = adapter.getInitialState({
  selected: null,
  openDialog: false,
  loading: false,
});
