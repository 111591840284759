import {Component, Inject, OnInit, Input} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {SalesService} from 'app/+store/sales/sales.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {GetCurrentUser} from 'app/actions/current-user-actions';

@Component({
  selector: 'dvtx-licence-trial-renew-dialog',
  templateUrl: './licence-trial-renew-dialog.component.html',
  styleUrls: ['./licence-trial-renew-dialog.component.scss']
})
export class LicenceTrialRenewDialogComponent implements OnInit {

  submitButtonTitle = 'GENERAL.CONFIRM_ACTION';
  cancelButtonTitle = 'GENERAL.CANCEL_ACTION';

  form: UntypedFormGroup;
  sendOngoing = false;
  success = false;

  constructor(
    public dialogRef: MatDialogRef<LicenceTrialRenewDialogComponent>,
    private _salesSvc: SalesService,
    private _fb: UntypedFormBuilder,
    private _router: Router,
    private _store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.submitButtonTitle) {
      this.submitButtonTitle = this.data.submitButtonTitle;
    }

    if (this.data.cancelButtonTitle) {
      this.cancelButtonTitle = this.data.cancelButtonTitle;
    }
  }

  ngOnInit(): void {
    this._initForm();
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.sendOngoing = true;
    const values = this.form.value;
    this._salesSvc.requestBasicAccount(values.inviterName, values.message).pipe(
      first()
    ).subscribe(res => {
      this.success = true;
      this._store.dispatch(new GetCurrentUser());
    }, err => {
      this.sendOngoing = false;
    })
  }

  onSubmitClick(): void {
    this.dialogRef.close();
  }

  onNoClick(): void {
    if (this.data.onCancelAction) {
      this.data.onCancelAction();
    }
    this.dialogRef.close();
  }

  reload(): void {
    try {
      this.dialogRef.close();
    } catch (err) {
      console.error(err);
    } finally {
      // this._store.dispatch(new UserLogoutRequest());
      // this._router.navigate(['/session/sign-in']);
    }
  }

  private _initForm() {
    this.form = this._fb.group({
      inviterName: ['', Validators.required],
      message: ['']
    })
  }
}
