import {DmsAccountType, IDmsFolder} from './dms-folder.interface';
import {DmsFolder} from './dms-folder';
import {NodeType} from './node-type';
import {IDmsDocument} from '../dms-document/dms-document.interface';
import {INode} from './node.interface';

/**
 * Builder class to create tree angular tree component compatible nodes from
 * DmsFolders or DmsDocuments.
 */
export class NodeBuilder {
  constructor(private _accountType: DmsAccountType) {
  }

  create(iNode: IDmsFolder | IDmsDocument, isRootNode = false): INode {
    if (!iNode) {
      return null;
    }
    let writePerm = true;
    let deletePerm = false;
    let renamePerm = false;
    let documentCount: number;
    let folderCount: number;
    let info = false;

    if (NodeBuilder.isFolder(iNode)) {
      const folder = iNode as DmsFolder;
      let hasChildren = true;
      // Specialty of the foxdox API: Shared folders have no documentCount/folderCount setting. Both are null;
      const admin = folder.documentCount !== null && folder.folderCount !== null;

      if (this._accountType === DmsAccountType.Private) {
        folderCount = +folder.folderCount;
        documentCount = +folder.documentCount;
        hasChildren = folderCount > 0;
        // User has write permission on own DMS
        writePerm = true;
        renamePerm = !isRootNode;
        deletePerm = !hasChildren && +folder.documentCount === 0;
        info = true;

      } else if (this._accountType === DmsAccountType.Organization && admin) {
        folderCount = +folder.folderCount;
        documentCount = +folder.documentCount;
        hasChildren = folderCount > 0;
        deletePerm = admin && !hasChildren && +folder.documentCount === 0 && !isRootNode;
        renamePerm = admin && !isRootNode;
        // User has write permission on organization DMS if admin aka user sees folderCount
        writePerm = true;
        info = true;
      }

      // Case of folders being shared on organization account
      if (folder.parentId) {
        info = true;
      }

      return {
        id: folder.id,
        type: NodeType.Folder,
        name: folder.name,
        shared: folder.shared,
        writePerm: writePerm,
        deletePerm: deletePerm,
        renamePerm: renamePerm,
        auditProof: folder.auditProof,
        documentCount: documentCount,
        folderCount: folderCount,
        hasChildren: hasChildren,
        parent: folder.parentId,
        bookmanCockpitEnabled: folder.bookmanCockpitEnabled,
        path: folder.path,
        accountType: this._accountType,
        root: isRootNode,
        info: info,
        ref: iNode
      };
    } else if (NodeBuilder.isDocument(iNode)) {
      return {
        id: iNode.id,
        type: NodeType.File,
        name: iNode.name,
        shared: iNode.shared,
        writePerm: writePerm,
        deletePerm: deletePerm,
        renamePerm: renamePerm,
        auditProof: iNode.auditProof,
        documentCount: null,
        folderCount: null,
        hasChildren: false,
        bookmanCockpitEnabled: iNode.bookmanCockpitEnabled,
        accountType: this._accountType,
        root: isRootNode,
        info: info,
        ref: iNode
      };
    } else {
      console.error(iNode);
      throw Error('Argument not of type DmsFolder | DmsDocument');
    }
  }

  static isFolder(node): boolean {
    return !!(node as IDmsFolder).type;
  }

  static isDocument(node): boolean {
    return !!(node as IDmsDocument).type;
  }
}
