<ng-container [ngSwitch]="_action" *ngIf="!isDisabled">
  <!-- Button to open the dialog -->
  <ng-container *ngSwitchCase="ClientActionType.CreateButton">
    <button mat-icon-button type="button" [disabled]="readonly" (click)="$event.preventDefault(); openDialog()"
            [disableRipple]="true">
      <mat-icon svgIcon="clients"></mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="ClientActionType.CreateRaisedButton">
    <button mat-raised-button type="button" color="primary" [disabled]="readonly"
            (click)="$event.preventDefault(); openDialog()" [disableRipple]="true">
      <mat-icon mataffix="">add_circle_outline</mat-icon>
      {{ 'CLIENT.CREATE_CLIENT' | translate }}</button>
  </ng-container>

  <ng-container *ngSwitchCase="ClientActionType.CreateBasicButton">
    <button mat-button type="button" color="primary" [disabled]="readonly"
            (click)="$event.preventDefault(); openDialog()" [disableRipple]="true">
      <mat-icon svgIcon="clients" color="primary" class="mr-1"></mat-icon>
      {{ 'CLIENT.CREATE_CLIENT' | translate }}</button>
  </ng-container>

  <ng-container *ngSwitchCase="ClientActionType.EditButton">
    <button mat-icon-button color="gray" type="button" (click)="$event.preventDefault(); openDialog()"
            [disabled]="readonly"
            [disableRipple]="true">
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="ClientActionType.DeleteButton">
    <dvtx-simple-confirm (onConfirm)="$event.preventDefault(); deleteClient(client?.id)"
                         [confirmButtonText]="'GENERAL.DELETE_CONFIRM_ACTION'"
                         [disabled]="readonly || !client?.canDeleteClient">
      <button mat-icon-button
              color="gray"
              [disableRipple]="true"
              [disabled]="!client?.canDeleteClient">
        <mat-icon>delete_outline</mat-icon>
      </button>
    </dvtx-simple-confirm>
  </ng-container>

  <!--&lt;!&ndash; Menu item Button to open the dialog &ndash;&gt;-->
  <!--<ng-container *ngSwitchCase="ParticipantActionType.ParticipantRoleMenuItem">-->
  <!--<button mat-menu-item class="dvtx-menu-item" (click)="openParticipantRoleDialog()">-->
  <!--<mat-icon mataffix>person_add</mat-icon>-->
  <!--{{'PROJECT_ROOM.ADD_MEMBER' | translate}}-->
  <!--</button>-->
  <!--</ng-container>-->

  <!--&lt;!&ndash; Bar Button to open the dialog &ndash;&gt;-->
  <!--<ng-container *ngSwitchCase="ParticipantActionType.ParticipantRoleBarButton">-->
  <!--<button mat-button (click)="openParticipantRoleDialog()">-->
  <!--<mat-icon mataffix>person_add</mat-icon>-->
  <!--{{'PROJECT_ROOM.ADD_MEMBER' | translate}}-->
  <!--</button>-->
  <!--</ng-container>-->


  <!-- Embeddable view to be used inside the settings dialog -->
  <ng-container *ngSwitchCase="ClientActionType.Form">
    <ng-template [ngIf]="contextPortal">
      <div class="d-flex flex-column" style="width: 100%">
        <div>
          <ng-template [cdkPortalOutlet]="contextPortal"></ng-template>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <!-- Embeddable view to be used inside the settings dialog -->
  <ng-container *ngSwitchCase="ClientActionType.Listing">
    <ng-template [ngIf]="contextPortal">
      <div class="d-flex flex-column" style="width: 100%">
        <div>
          <ng-template [cdkPortalOutlet]="contextPortal"></ng-template>
        </div>

        <!--<div class="d-flex flex-row justify-content-end" *ngIf="showSaveButtons">-->
        <!--<button mat-raised-button color="primary"-->
        <!--(click)="save()" [disabled]="this.form.pristine || this.form.invalid">-->
        <!--{{ 'GENERAL.APPLY_CHANGE_ACTION' | translate }}-->
        <!--</button>-->
        <!--</div>-->
      </div>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template cdkPortal #context>

  <div class="dvtx-client-creation-form" *ngIf="showForm">
    <!-- <ng-container *ngIf="enableCreation">
      <div class="mb-3">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-11">
            <dvtx-alert [icon]="'info'" [color]="'info'" [text]="'CLIENT.CREATE_TOOLTIP' | translate"></dvtx-alert>
          </div>
        </div>
      </div>
      <div class="icon-content-layout">
        <mat-icon>perm_contact_calendar</mat-icon>
        <div class="row">
          <div class="col-12 col-sm-10 col-md-10">
            <dvtx-contact-select
              elementName="{{'CLIENT.SELECT_FROM_ADDRESSBOOK' | translate}}"
              [(ngModel)]="contact"
              (ngModelChange)="setContact()"
              (onReset)="_reset()"
              [excludedIds]="[]"
            ></dvtx-contact-select>
          </div>
        </div>
      </div>
    </ng-container> -->

    <!--<div class="icon-content-layout">-->
    <!--<mat-icon>person</mat-icon>-->
    <!--<div class="row">-->
    <!--<div class="col-12 col-sm-8 col-md-8">-->
    <!--<dvtx-client-selection-cva elementName="{{'CONTACTS.NAME_OR_EMAIL' | translate}}"-->
    <!--[(ngModel)]="client"-->
    <!--(ngModelChange)="setClient()"></dvtx-client-selection-cva>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <div class="row justify-items-center">
      <div class="col-12 d-inline-flex justify-content-{{position}}">
        <form [formGroup]="form" novalidate style="max-width: 680px;">

          <div class="row">
            <div class="col-12">
              <div class="icon-content-layout mt-2">
                <mat-icon svgIcon="clients"></mat-icon>
                <div class="row">
                  <div class="col-12">
                    <mat-form-field>
                      <input matInput formControlName="name" [placeholder]="'CLIENT.NAME' | translate" [readonly]="readonly" />
                    </mat-form-field>
                    <input type="hidden" formControlName="legalForm"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 col-12 d-flex align-items-center">
              <!-- <div class="d-flex align-items-center mt-2">
                <div><button mat-icon-button color="gray"><mat-icon>info</mat-icon></button></div>
                <p class="mb-0" [innerHTML]="'CLIENT.CLIENT_ID_INFO' | translate"></p>
              </div>-->

              <div class="icon-content-layout mt-2 flex-grow" style="flex-grow: 1;">
                <mat-icon svgIcon="datev"></mat-icon>
                <div class="row">
                  <div class="col-12">
                    <mat-form-field>
                      <input matInput formControlName="clientId" [placeholder]="'CLIENT.CLIENT_ID' | translate"
                             [readonly]="readonly"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div><button mat-icon-button color="gray" [matTooltip]="'CLIENT.CLIENT_ID_INFO' | translate"><mat-icon>info</mat-icon></button></div>
            </div>

            <!--<div class="col-12">
              <dvtx-address-input-group-cva *ngIf="displayFurtherDataFields && contactOrganization && !isDisabled"
                                            [enableLocationOrType]="true"
                                            [addresses]="$any(contactOrganization).addresses"
                                            (addAddress)="addFurtherAddress($event)"
                                            (removeAddress)="removeFurtherAddress($event)"
                                            [disabled]="isDisabled"></dvtx-address-input-group-cva>
            </div>-->
          </div>

          <!-- <div class="icon-content-layout mt-2">
            <mat-icon>person</mat-icon>
            <div class="row">
              <div class="col-12 col-sm-6 col-md-2">
                <dvtx-api-select-cva
                  formControlName="gender"
                  class="type-or-location"
                  listingPath="name"
                  listingType="gender"
                  [isDisabled]="readonly"
                  [elementName]="'CONTACTS.GENDER' | translate"
                ></dvtx-api-select-cva>
              </div>

              <div class="col-12 col-sm-6 col-md-2">
                <mat-form-field>
                  <input matInput formControlName="title" [placeholder]="'CONTACTS.TITLE' | translate" [readonly]="readonly" />
                </mat-form-field>
              </div>

              <div class="col-12 col-sm-6 col-md-4">
                <mat-form-field>
                  <input matInput formControlName="firstName" [placeholder]="'CONTACTS.FIRST_NAME' | translate" [readonly]="readonly" />
                </mat-form-field>
              </div>

              <div class="col-12 col-sm-6 col-md-4">
                <mat-form-field>
                  <input matInput formControlName="lastName" [placeholder]="'CONTACTS.LAST_NAME' | translate" [readonly]="readonly" />
                </mat-form-field>
              </div>
            </div>
          </div> -->

          <input type="hidden" formControlName="contactId"/>
        </form>
      </div>
    </div>

    <ng-container *ngIf="enableSaveButton">
      <div class="row mt-2 mb-3">
        <div class="col-12">
          <div class="d-flex justify-content-end">
            <dvtx-simple-confirm *ngIf="client?.canDeleteClient"
                                 (onConfirm)="$event.preventDefault(); deleteClient(client?.id, true)"
                                 [confirmButtonText]="'GENERAL.DELETE_CONFIRM_ACTION'"
                                 [disabled]="readonly">
              <button mat-button class="mr-2"
                      [disableRipple]="true" [disabled]="readonly">
                <mat-icon mataffix>delete_outline</mat-icon>
                {{ 'CLIENT.DELETE_CLIENT' | translate }}</button>
            </dvtx-simple-confirm>

            <button mat-raised-button
                    color="primary"
                    [disabled]="form.pristine || form.invalid || submitOngoing"
                    (click)="submit()">
              <mat-icon mataffix>save</mat-icon>
              {{ saveButtonTitle | translate }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <ul class="mt-2" *ngIf="_action === ClientActionType.Listing">
    <li *ngFor="let client of clients$ | async">
      {{ client.name }}
      <dvtx-client-selection [action]="ClientActionType.EditButton"
                             [selectedClient]="client"></dvtx-client-selection>
      <dvtx-client-selection [action]="ClientActionType.DeleteButton"
                             [selectedClient]="client"></dvtx-client-selection>
    </li>
  </ul>
</ng-template>

<ng-template cdkPortal #title>
  <h2 mat-dialog-title>{{ dialogTitle | translate }}</h2>
  <button class="mat-dialog-close" mat-mini-fab color="warn" (click)="$event.preventDefault(); closeDialog()">
    <mat-icon aria-label="close dialog">clear</mat-icon>
  </button>
</ng-template>

<ng-template cdkPortal #buttonsToolbar>
  <div class="d-flex justify-content-end w-100">
    <div>
      <button mat-button (click)="closeDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>

      <button mat-raised-button
              color="primary"
              [disabled]="form.pristine || form.invalid || submitOngoing"
              (click)="submit()">
        <mat-icon mataffix>save</mat-icon>
        {{ saveButtonTitle | translate }}
      </button>
    </div>
  </div>
</ng-template>
