<div class="comment--box d-flex w-100"
     dvtx-mark-read
     [markReadMessage]="message"
     [markReadMessageFrom]="commentType"
     [markReadResourceId]="resourceId"
     (onMarkedRead)="onMarkedRead.emit($event)">
  <span class="comment--box--performer">
    <dvtx-avatar mat-list-icon [size]="'sm'" [email]="message?.performer" [matTooltip]="avatarService.getProfile(message.performer)?.name || message.performer" [showTitle]="false"></dvtx-avatar>
  </span>
  <div class="comment--box--content">
    <div class="user-box-content">
      <ul class="box-header box-header-default">
        <li class="performer d-flex justify-content-between">
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <span class="created-at dvtx-fg-color dvtx-fg-fivef-blue-400">{{ message.createdAt | date : 'short' }} <ng-container *ngIf="message?.createdAt !== message?.updatedAt"><em> ({{ 'GENERAL.EDITED' | translate }}) </em></ng-container> - <strong>{{avatarService.getProfile(message.performer)?.name || message.performer}}</strong></span>
          </div>

          <div>
            <dvtx-message-panel-action-dropdown [title]="'PROJECT_ROOM.ACTIONS' | translate"
                                                class="ml-1"
                                                [isClosed]="isClosed"
                                                [isAuthor]="isFromCurrentUser()"
                                                [enableReplyButton]="enableReply"
                                                (onReply)="toggleComment()"
                                                (onEditMode)="enableEditMode()"
                                                (onDelete)="isArtifact? deleteArtifactComment() : deleteComment()"></dvtx-message-panel-action-dropdown>
          </div>
        </li>
      </ul>
      <ul class="box-body">
        <li class="details" *ngIf="message?.replyTo">
          <div class="dvtx-fg-color dvtx-fg-fivef-blue-300"><em>{{message?.replyToAuthor}} {{'GENERAL.WROTE_AT' | translate}} {{message?.replyToDate | date:'medium'}}</em></div>
          <div class="details-reply-to" [froalaView]="message?.replyTo"></div>
          <!-- <p class="details-reply-to"styles.scss [innerHTML]="(referrerEvent$ | async)?.details"></p> -->
        </li>
        <li class="details">
          <ng-template [ngIf]="editMode" [ngIfElse]="viewMode" [formGroup]="form">
            <dvtx-emoji-input formControlName="content"
                              [value]="message?.message"
                              [editMode]="true"
                              [enableCancelButton]="true"
                              (onSave)="isArtifact ? editArtifactComment() : editComment()"
                              (onCancel)="cancelEdit()"></dvtx-emoji-input>
          </ng-template>
          <ng-template #viewMode>
            <div [froalaView]="message?.message"></div>
          </ng-template>
        </li>

        <li class="message-panel-footer d-flex align-items-center">
          <div class="w-100 d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <dvtx-comment-reaction-bar (onReaction)="react($event)" [reactions]="message?.reactionList"></dvtx-comment-reaction-bar>
              &nbsp;|&nbsp;
              <div class="message-panel-statistics">
                    <span class="d-flex align-items-center" [matTooltip]="'PROJECT_ROOM.SEEN_BY' | translate" [matTooltipPosition]="'above'">
                      <dvtx-item-comment-seen-by-dropdown [icon]="'visibility'" [showTitle]="true" [title]="'(' + message.seenBy.length + ')'" [persons]='message.seenBy' [showAvatarTitle]='true'  [class.active]="message.seenBy.length > 0">
                      </dvtx-item-comment-seen-by-dropdown>
                    </span>
              </div>
            </div>
            <!--<div *ngIf="!isClosed">
              <button mat-icon-button (click)="toggleComment()" [disableRipple]='true'>
                <mat-icon>reply</mat-icon> {{'PROJECT_ROOM.REPLY_ACTION' | translate}}
              </button>
            </div>-->
          </div>
        </li>

        <li *ngIf="showInputComment" class="message-comment-area p-0">
          <dvtx-emoji-input [(ngModel)]="comment" [placeholder]="'MESSAGING.PLACEHOLDER' | translate" (onSend)="sendComment()"></dvtx-emoji-input>
        </li>
      </ul>
    </div>
  </div>
</div>
