import {Action} from '@ngrx/store';
import {CollectorConfirmation} from './collector-confirmation';

export enum CollectorConfirmationActionTypes {
  LoadAll = '[CollectorConfirmation] Load All',
  LoadAllSuccess = '[CollectorConfirmation] Load All Success',
  LoadAllFail = '[CollectorConfirmation] Load All Fail',
  Confirm = '[CollectorConfirmation] Confirm',
  ConfirmSuccess = '[CollectorConfirmation] Confirm Success',
  ConfirmFail = '[CollectorConfirmation] Confirm Fail',
}

export class LoadAll implements Action {
  readonly type = CollectorConfirmationActionTypes.LoadAll;

  constructor(public collectorId: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = CollectorConfirmationActionTypes.LoadAllSuccess;

  constructor(public confirmations: CollectorConfirmation[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = CollectorConfirmationActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Confirm implements Action {
  readonly type = CollectorConfirmationActionTypes.Confirm;

  constructor(public collectorId: string) {
  }
}

export class ConfirmSuccess implements Action {
  readonly type = CollectorConfirmationActionTypes.ConfirmSuccess;

  constructor(public confirmation: CollectorConfirmation) {
  }
}

export class ConfirmFail implements Action {
  readonly type = CollectorConfirmationActionTypes.ConfirmFail;

  constructor(public payload: any) {
  }
}


export type CollectorConfirmationActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Confirm
  | ConfirmSuccess
  | ConfirmFail;
