import {TaskAssignmentStatus, ITaskAssignee} from './task-assignee.interface';

export class TaskAssignee implements ITaskAssignee {
  readonly type = 'task_assignees';

  constructor(public id: string,
              public email: string,
              public firstName: string,
              public lastName: string,
              public taskId: string,
              public createdAt: Date,
              public updatedAt: Date = null,
              public approved: boolean = false,
              public approvedAt: Date = null,
              public comment: string = null,
              public acceptedAt: Date = null,
              public inviterEmail: string = null,
              public status: TaskAssignmentStatus = null) {}
}
