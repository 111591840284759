<ng-container *ngIf="fibuProcess$ | async">
  <ng-container *ngIf="onlyIcon; else fullText">
  <span class="ml-1 fibu-folder-indicator mb-0 only-icon">
    <mat-icon svgIcon="bookman_folder"
              [matTooltip]="'FIBU.FOLDER_SYNCED_TO_BOOKMAN' | translate"></mat-icon>
  </span>
  </ng-container>


  <ng-template #fullText>
    <div class="fibu-folder-indicator d-flex align-items-center">
      <mat-icon svgIcon="bookman_folder"></mat-icon>
      <span class="ml-2 d-flex align-items-center fibu-folder-indicator__full-text" [ngClass]="textClass">{{'FIBU.FOLDER_SYNCED_TO_BOOKMAN' | translate}}</span>
    </div>
  </ng-template>

</ng-container>
