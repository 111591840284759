import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DocumentPreview} from './document-preview';
import {DocumentPreviewBuilder} from './document-preview.builder';
import {PvmsApiResourceService} from './pvms-api-resource.service';

@Injectable()
export class DocumentPreviewService {
  readonly BASE_PATH = 'api/v1/cms/articles';

  constructor(private _http: PvmsApiResourceService) {}

  getAll(): Observable<DocumentPreview[]> {
    const builder = new DocumentPreviewBuilder();
    return <Observable<DocumentPreview[]>>this._http.get<DocumentPreviewBuilder, DocumentPreview>(builder, `${this.BASE_PATH}`);
  }

  getOne(id: string): Observable<DocumentPreview> {
    const builder = new DocumentPreviewBuilder();
    return <Observable<DocumentPreview>>this._http.get<DocumentPreviewBuilder, DocumentPreview>(builder, `${this.BASE_PATH}/${id}`);
  }

  requestPreviews(ids: string[]): Observable<DocumentPreview[]> {
    const builder = new DocumentPreviewBuilder();
    const payload = {
      data: {
        attributes: {
          ids: ids
        }
      }
    };
    return <Observable<DocumentPreview[]>>this._http.postAll<DocumentPreviewBuilder, DocumentPreview>(builder, `${this.BASE_PATH}`, payload);
  }
}
