<form [formGroup]="editLabelForm" class="labels-editing-form px-3 py-1" (keydown.tab)="$event.stopPropagation()" (keydown.space)="$event.stopPropagation()">
  <mat-form-field>
    <input matInput placeholder="Title" [type]="'text'" formControlName="title">
    <mat-error *ngIf="editLabelForm.get('title').errors?.existed">{{ 'LABELS.LABEL_ALREADY_EXISTS_ERROR' | translate}}</mat-error>
    <input type="color" class="d-none" formControlName="color" #colorInput/>
    <div matSuffix class="d-flex">
      <dvtx-color-picker-dropdown  (onSelection)="editLabelChangeColor($event)" [selected]="editLabelForm.value.color" [xPosition]="'before'"></dvtx-color-picker-dropdown>
      <dvtx-icon-picker-dropdown *ngIf="scope === LabelScopeRef.ORGANIZATIONAL" (onSelection)="editLabelChangeIcon($event)" [selected]="editLabelForm.value.icon" [xPosition]="'before'"></dvtx-icon-picker-dropdown>
    </div>

  </mat-form-field>

  <textarea style="min-height: 328px;" class="mb-2 d-block" [placeholder]="'GENERAL.DESCRIPTION' | translate" formControlName="description"></textarea>

  <!-- <dvtx-wysiwyg-editor-cva formControlName="description" [height]="360" [heightMax]="360" [placeholder]="'GENERAL.DESCRIPTION' | translate" class="mb-2 d-block">
  </dvtx-wysiwyg-editor-cva> -->
  <!-- <mat-form-field>
    <textarea matInput placeholder="Description" formControlName="description"></textarea>
  </mat-form-field> -->

  <div class="d-flex align-items-center justify-content-end" *ngIf="label && label.title">
    <button type="button" mat-button (click)="onCancelEditing.emit()" class="mr-1">
      {{ 'GENERAL.CANCEL_ACTION' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)='submitEditLabel()' [disabled]="editLabelForm.invalid || editLabelForm.get('title').value.trim().length === 0">
      {{'GENERAL.SAVE_ACTION' | translate}}
    </button>
  </div>
</form>
