import {InvoiceAddress} from '../models/invoice-address';

export class InvoiceAddressBuilder {
  public static toRequest(invoice: InvoiceAddress) {
    return {
      data: {
        attributes: {
          first_name: invoice.firstName,
          last_name: invoice.lastName,
          organization_name: invoice.organizationName,
          street: invoice.street,
          street_no: invoice.streetNo,
          zip: invoice.zip,
          city: invoice.city,
          country_name: invoice.country,
          ustid: invoice.ustId,
          taxid_de: invoice.taxIdDe
        }
      }
    }
  }

  public static fromResponse(data): InvoiceAddress {
    return new InvoiceAddress(
      data.id,
      data.attributes.first_name,
      data.attributes.last_name,
      data.attributes.organization_name,
      data.attributes.street,
      data.attributes.street_no,
      data.attributes.zip,
      data.attributes.city,
      data.attributes.country_name,
      data.attributes.ustid,
      data.attributes.taxid_de
    )
  }
}
