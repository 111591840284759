import { first, map } from 'rxjs/operators';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'app/models/user';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { Observable } from 'rxjs';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';
import { AuthPasswordService } from 'app/+store/auth-password/auth-password.service';
import { fieldType } from '../../../registration/components/password-form/password-form.component';
import { RegistrationService } from '../../../registration/services/registration.service';

@Component({
  selector: 'dvtx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  private PASSWORD_MIN_LENGTH = 8;
  public form: UntypedFormGroup;
  public twoFactorAuthEnabled: Observable<boolean>;
  @ViewChild('currentPassword') currentPassword: ElementRef;
  public editDisabled: boolean = true;
  public passwordVisbile: string;
  public passwordConfirmationVisbile: string;
  public currentPasswordVisbile: string;
  public verifyPasswordResponse: any;
  public wrongPasswordError: string;
  public wrongCodeError: string;
  public fieldType = fieldType;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private auhtPasswordService: AuthPasswordService,
    private notificationService: NotificationService,
    private registrationService: RegistrationService) {
    this.passwordVisbile = fieldType.Password;
    this.passwordConfirmationVisbile = fieldType.Password;
    this.currentPasswordVisbile = fieldType.Password;
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: [''],
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(this.PASSWORD_MIN_LENGTH)]],
      newPasswordConfirmation: ['', Validators.required]
    },
      {
        validator: ResetPasswordComponent.matchPassword
      });

    this.twoFactorAuthEnabled = this.store.pipe(map(state => state['currentUser']), map((currentUser: User) => {
      if (currentUser) {
        // Email is required for OTP request.
        this.form.patchValue({ email: currentUser.email });
        return currentUser.otpRequiredForLogin;
      } else {
        return false;
      }
    }),);
  }

  public verifyPassword() {
    const password = this.form.value.newPassword;
    this.registrationService.verifyPassword(password).subscribe(response => {
      this.verifyPasswordResponse = response;
    });
  }

  public editEnableDiable() {
    this.editDisabled = !this.editDisabled;
    this.verifyPasswordResponse = null;
    this.wrongPasswordError = null;
    this.form.reset();
    this.passwordVisbile = fieldType.Password;
    this.passwordConfirmationVisbile = fieldType.Password;
    this.currentPasswordVisbile = fieldType.Password;
  }

  public passwordShow(condition: boolean) {
    if (condition) {
      this.passwordVisbile = fieldType.Text;
    } else {
      this.passwordVisbile = fieldType.Password;
    }
  }

  public passwordConfirmatioShow(condition: boolean) {
    if (condition) {
      this.passwordConfirmationVisbile = fieldType.Text;
    } else {
      this.passwordConfirmationVisbile = fieldType.Password;
    }
  }

  public currentPasswordShow(condition: boolean) {
    if (condition) {
      this.currentPasswordVisbile = fieldType.Text;
    } else {
      this.currentPasswordVisbile = fieldType.Password;
    }
  }

  public static matchPassword(AC: AbstractControl) {
    const password = AC.get('newPassword').value; // to get value in input tag
    const passwordConfirm = AC.get('newPasswordConfirmation').value; // to get value in input tag
    if (password !== passwordConfirm && password) {
      return { passwordsNoMatch: true }
    }

    return null;
  }

  public submit() {
    const value = this.form.value;
    const otp = value['firstInput'] + value['secondInput'] + value['thirdInput'] +
      value['fourthInput'] + value['fifthInput'] + value['sixthInput'];

    this.auhtPasswordService.changePassword(value.newPassword, value.newPasswordConfirmation, value.currentPassword, otp).pipe(
      first())
      .subscribe((ret) => {
        this.notificationService.success('Passwort erfolgreich geändert');
        this.form.reset();
        this.editEnableDiable();
        this.verifyPasswordResponse = null;
        this.wrongPasswordError = null;
        this.wrongCodeError = null;
      }, (err) => {
        if (!err || !err.errors || !err.errors.length) {
          this.notificationService.error('Es ist ein unbekannter Fehler aufgetreten');
        }
        if (err.error) {
          this.wrongPasswordError = null;
          this.wrongCodeError = null;

          for (const error of err.error.errors) {
            this.notificationService.error(error.title);
            if (error.code === 'api.auth.error.password.current_password_mismatch') {
              this.wrongPasswordError = error.title;
              this.currentPassword.nativeElement.focus();
              this.currentPassword.nativeElement.value = '';
            } else if (error.code === 'api.auth.otp.error.code_mismatch') {
              this.wrongCodeError = error.title;
            }
          }

        } else {
          for (const error of err.errors) {

            this.notificationService.error(error.title);
          }
        }
      });
  }
}
