import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, map, switchMap, takeUntil} from 'rxjs/operators';
import {combineLatest, Observable, Subject} from 'rxjs';
import {Net} from 'app/lib/net/uuid';
import {ClientRoute} from '../../components/client-nav-bar/client-nav-bar.component';
import {Process} from 'app/+store/process/process';
import {ProcessActions} from 'app/+store/process';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ClientActions, ClientSelectors} from 'app/+store/client';
import {Client} from 'app/+store/client/client';
import {ProcessService} from 'app/+store/process/process.service';
import {FeatureSelectors, MembershipSelectors, OrganizationSelectors} from 'app/+store';
import {Feature} from 'app/+store/feature/feature';
import {Membership} from 'app/models/membership.model';

@Component({
  selector: 'dvtx-client-workflows',
  templateUrl: './client-workflows.component.html',
  styleUrls: ['./client-workflows.component.scss']
})
export class ClientWorkflowsComponent implements OnInit, OnDestroy {
  onDestroy = new Subject();
  routes: ClientRoute[];
  activeLink;
  id: string;
  loading = false;
  processes$: Observable<Process[]>;
  client$: Observable<Client>;
  emptyProcessList = false;
  organizationId: string;
  featureSet$: Observable<Feature>;
  myMembership$: Observable<Membership>;

  constructor(private _store: Store<AppState>,
              private _route: ActivatedRoute,
              private _router: Router,
              private _processSvc: ProcessService,
              private _cdr: ChangeDetectorRef,
              private _ngZone: NgZone) {
  }

  ngOnInit() {
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
    this.myMembership$ = this._store.select(MembershipSelectors.getMyMembership);

    const organization$ = this._store.select(OrganizationSelectors.getSelected)
      .pipe(filter(x => !!x), distinctUntilKeyChanged('id'))

    organization$.pipe(takeUntil(this.onDestroy)).subscribe(organization => {
      this.organizationId = organization.id;
    });

    this.loading = true;

    const params = this._route.params.pipe(distinctUntilChanged());
    combineLatest(organization$, params)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([organization, params]) => {
        if (organization && params && params['id']) {
          this._store.dispatch(new ClientActions.LoadOne(params['id']));
        }
      });

    const idByQuery$ = this._route.params.pipe(map(params => params['id']), distinctUntilChanged());
    this.client$ = idByQuery$.pipe(switchMap(id => this._store.select(ClientSelectors.getClientById(id))));

    combineLatest(params, this.featureSet$, this.myMembership$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([params, featureSet, myMembership]) => {
        const id = params['id'];
        this.id = id;
        if (Net.validUUID(this.id)) {
          this.routes = [{
            title: 'PROJECT_ROOM.PROJECT_ROOMS',
            route: `/clients/${id}/workflows`
          }, {
            title: 'Details',
            route: `/clients/${id}/details`
          }];
          if (myMembership && myMembership.hasAdministrationRights && featureSet && featureSet.hasBookman) {
            this.routes.push({
              title: 'FIBU.CLIENT_SETTINGS',
              route: `/clients/${id}/fibu-settings`
            })
          }
          this.activeLink = this.routes[0].title;
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  click(process: Process) {
    this._store.dispatch(new ProcessActions.RunCommand(process.id, 'index', 'on_click'));
  }

  refresh() {
    this._store.dispatch(new ProcessActions.LoadAll());
  }
}
