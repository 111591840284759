import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {Fastdocs, FastdocsSubscription} from './fastdocs';
import {FastdocsBuilder, FastdocsSubscriptionBuilder} from './fastdocs.builder';
import {PartnerLink} from '../partner-link/partner-link';
import {PartnerLinkBuilder} from '../partner-link/partner-link.builder';

@Injectable()
export class FastdocsService {

  readonly basePath = 'api/v1/fastdocs/employee_master_data';

  constructor(private _http: ApiResourceService) {
  }

  getAll(): Observable<Fastdocs[]> {
    const builder = new FastdocsBuilder();
    return <Observable<Fastdocs[]>>this._http.get<FastdocsBuilder, Fastdocs>(builder, `${this.basePath}`);
  }

  getOne(id: string): Observable<Fastdocs> {
    const builder = new FastdocsBuilder();
    return <Observable<Fastdocs>>this._http.get<FastdocsBuilder, Fastdocs>(builder, `${this.basePath}/${id}`);
  }

  enter(id: string): Observable<Fastdocs> {
    const builder = new FastdocsBuilder();
    return <Observable<Fastdocs>>this._http.post<FastdocsBuilder, Fastdocs>(builder, `${this.basePath}/${id}/enter`, {});
  }

  checkSubscription(): Observable<FastdocsSubscription> {
    const builder = new FastdocsSubscriptionBuilder();
    return <Observable<FastdocsSubscription>>this._http.get<FastdocsSubscriptionBuilder, FastdocsSubscription>(builder, 'api/v1/fastdocs/status');
  }

  createPartnerLink(title, url): Observable<PartnerLink> {
    const builder = new PartnerLinkBuilder();
    const payload = {
      data: {
        attributes: {
          title: title,
          url: url
        }
      }
    }
    return <Observable<PartnerLink>>this._http.post<PartnerLinkBuilder, PartnerLink>(builder, 'api/v1/fastdocs/partner_links', payload);
  }
}
