import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {InViewportModule} from 'ng-in-viewport';
import {SharedModule} from 'app/shared/shared.module';
import {PreviewBrowserComponent} from './containers/preview-browser/preview-browser.component';
import {DvtxLazyPreviewDirective} from './directives/dvtx-lazy-preview.directive';
import {
  LazyPreviewLoadingSkeletonComponent
} from './components/lazy-preview-loading-skeleton/lazy-preview-loading-skeleton.component';
import {
  PreviewBrowserImagelistComponent
} from './components/preview-browser-imagelist/preview-browser-imagelist.component';
import {LabelsModule} from 'app/five-f/labels/labels.module';
import {LabelService} from 'app/+store/label/label.service';
import {
  DocumentSignaturTypeSelectionModule
} from '../../../document-signature/modules/document-signature-type-selection/document-signatur-type-selection.module';
import {MessageEditorModule} from 'app/shared/modules/message-editor/message-editor.module';
import {MessagePanelModule} from 'app/modules/tasks/modules/message-panel/message-panel.module';
import {InfoBlockModule} from 'app/five-f/info-block/info-block.module';
import {InstantMessagingModule} from 'app/modules/inbox/modules/instant-messaging/instant-messaging.module';
import {ExportCommentsModule} from 'app/five-f/export-comments/export-comments.module';
import {ArtifactDetailsComponent} from './components/artifact-details/artifact-details.component';
import {ArtifactPropertiesModule} from "../../../../five-f/artifact-properties/artifact-properties.module";
import {FiveFPdfViewerModule} from "../../../five-f-pdf-viewer/five-f-pdf-viewer.module";

@NgModule({
  imports: [
    SharedModule,
    InViewportModule,
    LabelsModule,
    DocumentSignaturTypeSelectionModule,
    MessageEditorModule,
    MessagePanelModule,
    InfoBlockModule,
    InstantMessagingModule,
    ExportCommentsModule,
    ArtifactPropertiesModule,
    FiveFPdfViewerModule
  ],
  declarations: [
    PreviewBrowserComponent,
    DvtxLazyPreviewDirective,
    LazyPreviewLoadingSkeletonComponent,
    PreviewBrowserImagelistComponent,
    ArtifactDetailsComponent
  ],
  exports: [
    SharedModule,
    PreviewBrowserComponent,
    DvtxLazyPreviewDirective
  ],
  providers: [
    LabelService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PreviewBrowserModule {
}
