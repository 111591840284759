import {Observable, of as observableOf} from 'rxjs';

import {map, mergeMap, switchMap} from 'rxjs/operators';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Injectable} from '@angular/core';
import * as currentContactActions from '../actions/current-contact.actions';


import {ContactService} from '../modules/contacts/services/contact.service';
import {IContact} from '../models';

// TODO IContact. setMembers();
@Injectable()
export class CurrentContactEffects {

  @Effect()
  getOrganizationContactMembersById$: Observable<Action> = this.actions$
    .pipe(
      ofType(currentContactActions.GET_CONTACT_MEMBERS),
      switchMap((action: currentContactActions.GetContactMembers) => {
        return this.contactService.get(action.payload);
      }),
      mergeMap((contact: IContact) => {
        if (contact.type === 'contact_people' || contact.type !== 'contact_organizations') {
          return observableOf(contact);
        }
        return this.contactService.getContactMembers(contact.id).pipe(
          map(value => {
            contact['members'] = value;
            console.log(contact);
            return contact;
          }));
      }),
      map((contact) => {
        return new currentContactActions.GetContactMembersSuccess(contact);
      }),);

  @Effect({dispatch: false})
  getContactMembers$: Observable<Action> = this.actions$
    .pipe(
      ofType(currentContactActions.SET_CURRENT_CONTACT),
      mergeMap((action: currentContactActions.SetCurrentContact) => {
        if (action.payload.type === 'contact_people' || action.payload.type !== 'contact_organizations') {
          return observableOf(action.payload);
        }
        return this.contactService.getContactMembers(action.payload.id).pipe(
          map(value => {
            action.payload['members'] = value;
            return action.payload;
          }));
      }),
      map((contact) => {
        return new currentContactActions.GetContactMembersSuccess(contact);
      }),);

  constructor(private contactService: ContactService,
              private actions$: Actions) {
  }
}
