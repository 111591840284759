import {IMessage, IMessageDraft, IMessageResourceStat, IUnreadResource, MessageResourceType} from './message.interface';
import {ThirdPartyWorkerInfo} from "../process-event/process-event";

export interface IInstantMessage {
  message: string;
  recipients: string[];
  publicComment: boolean;
  referenceId?: string;
  parentId?: string;
}

/**
 * 5F platform INBOX notification.
 *
 * Can be of type process notification, informal or organization notification.
 * Process notifications have one single underlying process as source.
 *
 * NOTE: General process notifications like the owner change (created on employee removal)
 * do not have a direct underlying process but many.
 *
 * Non-process based notifications are the employee/business partner invitations or
 * the informal notifications on their acceptance or denial of the invitation.
 *
 * TODO: the class name Message is existing for historical reason and because of a name clash (Notification was
 *       already in use).
 *       Rename it in future if possible to Notification or InboxNotification to be aligned with the API model and
 *       the domain nomenclature to prevent misunderstanding.
 */
export class Message implements IMessage {
  readonly type = 'messages';
  resourceType: MessageResourceType;
  payload: any;
  icon: string;
  isSvgIcon: boolean = false;
  unreadIcon: string;
  apiType: string;
  dueDate: Date;
  count?: number;
  processes?: { ['id']: string; ['title']: string; ['type']: string; ['created_at']: Date; ['updated_at']: Date;  }[] = [];

  // Message pagination

  /**
   * Total count of elements of request (<= per page size).
   */
  total: number;
  perPage: number;

  /**
   * Total count of elements of the query scope (all candidates).
   */
  records: number;

  unreadCount: number;

  /**
   * The returned timestamp is the start marker for the pagination.
   * Newer elements will not be considered during one paginated run starting from page 1.
   * It must be delivered on each subsequent page request.
   * It must only be reseted on page call 1.
   */
  timestamp: number;

  /**
   * Process data.
   * NOTE: Process data is only available for process bound notifications.
   */
  processId: string;
  processTitle: string;
  processType: string;
  processPath;
  organizationName: string;
  clientName: string;
  identifier: string;

  /**
   * Authorized process is a flag indicating if the user has participation access
   * to the process with processId or if the participation was removed.
   * (old message).
   */
  authorizedProcess: boolean;
  eventId: string;
  eventPayload: any;

  performerName: string;

  dontMarkReadAutomatically = false;

  thirdPartyWorkerInfo: ThirdPartyWorkerInfo;

  /**
   * Reference ID is used in document uploads (follow up ID)
   * to reference a Collecto item if Collector.
   */
  referenceId: string;

  /**
   * Trackkey for trackBy by ngFor or MatTableSource.
   * Contains the UUID and readAt concatenated.
   */
  trackKey: string;

  get plainText() {
    if (this.message) {
      return this.message.replace(/<[^>]*>/g, '');
    } else {
      return '';
    }
  }

  /**
   *
   * @param id ID of the system notification.
   * @param performer E-Mail of the performer of the underlying action.
   * @param performerData
   * @param recipient
   * @param recipients
   * @param subject
   * @param message
   * @param followUpId
   * @param resourceId
   * @param blankedOut
   * @param favorite
   * @param color
   * @param archivedAt
   * @param readAt
   * @param sentAt
   * @param createdAt
   * @param updatedAt
   */
  constructor(public id: string,
              public performer: string,
              public performerData: {email, firstName, lastName} | null,
              public recipient: string,
              public recipients: string[],
              public subject: string,
              public message: string,
              public followUpId: string,
              public resourceId: string,
              public blankedOut: boolean,
              public favorite: boolean,
              public color: string,
              public archivedAt: Date,
              public readAt: Date,
              public sentAt: Date,
              public createdAt: Date,
              public updatedAt: Date = null) {
    this.trackKey = `${id}|${readAt}`;
  }

  static fromDraft(draft: IMessageDraft) {
    const msg = new Message(
      draft.id,
      null,
      null,
      null,
      draft.recipients || [],
      draft.subject,
      draft.body,
      null,
      null,
      false,
      false,
      null,
      null,
      draft.updatedAt,
      null,
      draft.createdAt,
      draft.updatedAt);
    msg.resourceType = MessageResourceType.MessageDraft;
    return msg;
  }
}

export class UnreadResource implements IUnreadResource {
  readonly type: 'messages_unread_counts';

  title: string;
  subtitle: string;
  url: string;
  processType: string;
  clientId: string;
  clientName: string;
  closedProcess: boolean;
  commentType: 'process' | 'cac' | 'task' | 'collector_item' | 'document';
  referenceId: string;
  itemTitle: string;
  categoryTitle: string;
  color: string;

  constructor(public id, public unread, public artifacts?: UnreadResourceArtifacts[]) {}
}

export class MessageResourceStat implements IMessageResourceStat {
  readonly type: 'messages_unread_counts';
  constructor(public id: string, public all: number, public unread: number) {}
}

export interface UnreadResourceArtifacts {
  id: string;
  filename: string;
  performerName: string;
}
