import {Component, Inject, EventEmitter, OnDestroy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {User} from '../../../../models/user';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {TrialPopupService} from 'app/modules/user-profile/services/trial-popup.service';
import {first, startWith, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {I18n} from 'app/lib/i18n/constants';

@Component({
  selector: 'dvtx-successful-registration',
  templateUrl: './successful-registration.component.html',
  styleUrls: ['./successful-registration.component.scss']
})

export class SuccessfulRegistrationComponent implements OnDestroy {

  currentUser;
  isTrialValid = true;
  validilty = 0;
  onDestroy = new Subject();
  lang = I18n.DEFAULT_LANG;

  constructor(private matDialogRef: MatDialogRef<SuccessfulRegistrationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private router: Router,
              private trialPopupService: TrialPopupService,
              private _i18nSvc: TranslateService) {

    this._i18nSvc.onLangChange.pipe(startWith({lang: this._i18nSvc.currentLang}),
      takeUntil(this.onDestroy)).subscribe(lang => this.lang = lang.lang);

    this.currentUser = this.data.currentUser;
    this.isTrialValid = this.data.isTrialValid;

    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const endTrial = this.currentUser.attributes.endTrial;
    const today = new Date();

    this.validilty = Math.ceil((endTrial.getTime() - today.getTime()) / (oneDay));
    if (this.validilty < 0) {
      this.validilty = 0;
    }
  }

  endTrialVersion() {
    this.trialPopupService.endTrialVersion().pipe(first()).subscribe(_ => null, (err => console.error(err)));
    this.matDialogRef.close();
  }

  continueTestPeriod() {
    this.trialPopupService.disablePopup().pipe(first()).subscribe(_ => null, (err => console.error(err)));
    this.matDialogRef.close(true);
  }

  goToPrices() {
    this.trialPopupService.disablePopup().pipe(first()).subscribe(_ => null, (err => console.error(err)));
    this.matDialogRef.close();
    this.router.navigate([`/prices`]);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
