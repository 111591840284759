import {MobileActivation} from './mobile-activation';
import {IApiRequest, IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';

export class MobileActivationBuilder implements IApiResourceBuilder<MobileActivation> {
  fromResponse(data): MobileActivation {
    if (!data || !data.attributes) {
      return null;
    }

    return new MobileActivation(
      data.id,
      data.attributes.mobile_enabled,
    );
  }

  toRequest(model: MobileActivation): IApiRequest {
    return;
  }
}
