import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import {ClientActions, ClientSelectors} from 'app/+store/client';
import {Client} from 'app/+store/client/client';
import {Observable, Subject, Subscription} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ClientContactType} from 'app/+store/client/client.interface';
import {ContactListDto, contactListDtoType} from 'app/models/contact-list-dto.model';
import {ContactOrganization} from 'app/models/contact-organization.model';
import {OrganizationSelectors} from 'app/+store/organization';
import {distinctUntilKeyChanged, filter, first, takeUntil} from 'rxjs/operators';
import {ContactOrganizationActions, ContactOrganizationSelectors} from 'app/+store/contact-organization';
import {ContactPersonActions, ContactPersonSelectors} from 'app/+store/contact-person';
import {ContactPerson} from 'app/models/contact-person.model';
import {ClientService} from 'app/+store/client/client.service';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {SplitViewDialogComponent} from 'app/shared/modules/page-container/split-view-dialog/split-view-dialog.component';
import {ContactPersonFactoryService} from 'app/+store/contact-person/contact-person-factory.service';
import {SimplePhoneNumber} from '../../../../../contacts/models/contact.interface';
import {Router} from '@angular/router';
import {StringValidator} from 'app/lib/validator';
import {Feature} from 'app/+store/feature/feature';
import {FeatureSelectors} from 'app/+store/feature';
import {BookmanClient} from 'app/+store/bookman-client/bookman-client';
import {BookmanClientActions, BookmanClientSelectors} from 'app/+store/bookman-client';
import {MatSelectChange} from '@angular/material/select';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

export enum ClientActionType {
  EditButton = 'EditButton',
  CreateButton = 'CreateButton',
  CreateRaisedButton = 'CreateRaisedButton',
  CreateBasicButton = 'CreateBasicButton',
  DeleteButton = 'DeleteButton',
  Listing = 'Listing',
  Form = 'Form',
  None = 'None'
}

@Component({
  selector: 'dvtx-client-selection',
  templateUrl: './client-selection.component.html',
  styleUrls: ['./client-selection.component.scss']
})
export class ClientSelectionComponent implements AfterViewInit, OnInit, OnDestroy {
  ClientActionType = ClientActionType;

  onDestroy = new Subject();
  AddressbookContactType = contactListDtoType;
  contact?: ContactListDto;
  client: Client;
  deleteDisabled = true;
  bookmanClients$: Observable<BookmanClient[]>;
  bookmanClientsFiltered$: Observable<BookmanClient[]>;
  bookmanClientsLoadingState$: Observable<boolean>;
  bookmanClientsLoaded = false;
  selectedBookmanClient: BookmanClient;
  @Input() readonly = false;
  @Input() position = 'center';
  @Input() set selectedClient(c: Client) {
    this.client = c;
    if (c) {
      this._initForm();
      this.setClient();
      this.form.markAsPristine();
    }
  }

  @Input() enableSaveButton = false;

  submitOngoing = false;

  form: UntypedFormGroup;
  clients$: Observable<Client[]>;
  organizationId: string;
  contactSubscription$: Subscription;
  @ViewChild('buttonsToolbar', {static: true}) buttonsToolbar: TemplateRef<any>;
  @ViewChild('context', {static: true}) context: TemplateRef<any>;
  @ViewChild('title', {static: true}) title: TemplateRef<any>;
  buttonsToolbarPortal: Portal<any>;
  contextPortal: Portal<any>;
  titlePortal: Portal<any>;
  dialogRef: MatDialogRef<SplitViewDialogComponent>;
  organization;

  enableCreation = false;
  dialogTitle = '';
  @Input() saveButtonTitle = 'GENERAL.SAVE_ACTION';

  showForm = true;
  _action: ClientActionType;
  isDisabled = false;

  @Input() set action(a: ClientActionType) {
    this._action = a;
    switch (a) {
      case ClientActionType.Listing:
        this.showForm = false;
        break;
      case ClientActionType.CreateButton:
      case ClientActionType.CreateRaisedButton:
      case ClientActionType.CreateBasicButton:
        this.dialogTitle = 'CLIENT.CREATE_CLIENT';
        this.saveButtonTitle = 'CLIENT.CREATE_CLIENT';
        this.enableCreation = true;
        break;
      case ClientActionType.EditButton:
        this.dialogTitle = 'CLIENT.EDIT_CLIENT';
        this.saveButtonTitle = 'GENERAL.APPLY_CHANGE_ACTION';
        break;
      case ClientActionType.Form:
        this.showForm = true;
        break;
      case ClientActionType.None:
        this.isDisabled = true;
        break;
    }
  }

  @Output() onClientChange = new EventEmitter();
  @Output() onEditSuccess = new EventEmitter();
  @Output() onCreateSuccess = new EventEmitter();
  @Output() onFormUpdate = new EventEmitter();

  featureSet$: Observable<Feature>;

  constructor(private _dialog: MatDialog,
              private _store: Store<AppState>,
              private _router: Router,
              private _fb: UntypedFormBuilder,
              private _clientSvc: ClientService,
              private _contactFactorySvc: ContactPersonFactoryService,
              private _viewContainerRef: ViewContainerRef,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.bookmanClients$ = this._store.select(BookmanClientSelectors.getAll);
    this.bookmanClientsLoadingState$ = this._store.select(BookmanClientSelectors.loadingState);
    this.clients$ = this._store.select(ClientSelectors.getAllClients);
    this._store.select(OrganizationSelectors.getSelected)
      .pipe(filter(x => !!x), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy))
      .subscribe(organization => {
        this.organization = organization;
        // this._store.dispatch(new ContactActions.LoadAll(organization))
        this.organizationId = organization.id;
      });
    this._initForm();

    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this._initTitlePortal();
      this._initContextPortal();
    });
    // setTimeout(_ => this.openParticipantRoleDialog(), 100);
  }

  setContact() {
    if (!this.contact) return;

    this.form.reset();
    const id = this.contact.id;
    const email = this.contact.email;

    if (this.contactSubscription$) {
      this.contactSubscription$.unsubscribe();
    }

    if (this.contact.type === this.AddressbookContactType.organizationContact) {
      this._store.dispatch(new ContactOrganizationActions.LoadOne(id));
      this.contactSubscription$ = this._store.select(ContactOrganizationSelectors.getOrganizationContactById(id)).pipe(
        takeUntil(this.onDestroy)
      ).subscribe((org: ContactOrganization) => {
        if (org) {
          const address = org.mainAddress;
          const phone = org.mainPhoneNumber;
          this.form.patchValue({
            contactId: id,
            name: org.name,
            contactType: ClientContactType.Organization,
            legalForm: org.legalFormId,
            email: email,
            street: address.street,
            streetNo: address.streetNo,
            city: address.city,
            zip: address.zip,
            phone: phone
          });
          this.form.markAsDirty();
        }
      });

    } else if (this.contact.type === this.AddressbookContactType.naturalPersonContact || this.contact.type === this.AddressbookContactType.Membership) {
      this._store.dispatch(new ContactPersonActions.LoadOne(id));

      this.contactSubscription$ = this._store.select(ContactPersonSelectors.getPersonContactById(id)).pipe(
        takeUntil(this.onDestroy)
      ).subscribe((person: ContactPerson) => {
        if (person) {
          const address = person.mainAddress;
          const phone = person.mainPhoneNumber;
          this.form.patchValue({
            contactId: id,
            title: person.honorific,
            gender: person.title,
            firstName: person.firstName,
            lastName: person.lastName,
            contactType: ClientContactType.Person,
            email: email,
            street: address.street,
            streetNo: address.streetNo,
            city: address.city,
            zip: address.zip,
            phone: phone
          });
          this.form.markAsDirty();
        }
      });
    }
  }

  setClient() {
    if (!this.client) return;

    this.deleteDisabled = this.client.processCount > 0;
    this.selectedBookmanClient = new BookmanClient(Number(this.client.bookmanClientId), this.client.bookmanClientName);
    this.form.reset();
    const phone = new SimplePhoneNumber();
    phone.phoneNumber = this.client.phone;
    this.form.patchValue({
      id: this.client.id,
      contactId: this.client.contactId,
      clientId: this.client.clientId,
      bookmanClientId: this.selectedBookmanClient ? this.selectedBookmanClient.id : null,
      name: this.client.name,
      title: this.client.title,
      gender: this.client.gender,
      firstName: this.client.firstName,
      lastName: this.client.lastName,
      contactType: this.client.contactType,
      email: this.client.email,
      street: this.client.street,
      streetNo: this.client.streetNo,
      city: this.client.city,
      zip: this.client.zip,
      phone: phone
    });
    this.form.markAsDirty();
  }

  submit() {
    if (this.form.invalid) return;

    const values = this.form.value;
    const client = new Client(values.id, values.contactType, values.name, values.email, values.gender,
      values.title, values.firstName, values.lastName, values.clientId, this.organizationId, values.contactId);
    if (this.selectedBookmanClient) {
      client.bookmanClientId = this.selectedBookmanClient.id;
      client.bookmanClientName = this.selectedBookmanClient.name;
    }
    client.street = values.street;
    client.streetNo = values.streetNo;
    client.city = values.city;
    client.zip = values.zip;
    client.legalForm = values.legalForm;
    client.phone = values.phone.phoneNumber;

    this.submitOngoing = true;
    if (values.id) {
      this._clientSvc.update(client).pipe(first()).subscribe((_client: Client) => {
        this.form.patchValue({id: _client.id});
        this.form.markAsPristine();
        this._cdr.detectChanges();
        this._store.dispatch(new ClientActions.SaveSuccess(_client));
        this.submitOngoing = false;
        this.onEditSuccess.emit(_client);
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      }, (err) => {
        this.submitOngoing = false;
      });
    } else {
      this._clientSvc.create(client)
        .pipe(first())
        .subscribe((_client: Client) => {
          this.form.patchValue({id: _client.id});
          this.form.markAsPristine();
          this._cdr.detectChanges();
          this._store.dispatch(new ClientActions.CreateSuccess(_client));
          this.submitOngoing = false;
          this.onClientChange.emit(_client);
          this.onCreateSuccess.emit(_client);
          if (this.dialogRef) {
            this.dialogRef.close();
          }
          this._reset();
        }, (err) => {
          this.submitOngoing = false;
        });
    }
  }

  closeDialog() {
    this._reset();
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  deleteClient(clientId, navigateToOverview = false) {
    this._clientSvc.destroy(clientId).pipe(first()).subscribe(_client => {
      this._reset();
      this._store.dispatch(new ClientActions.RemoveSuccess(_client));
      if (navigateToOverview) {
        this._router.navigate([`/organization/${this.organizationId}/addressbook/clients`]);
      }
    }, error => console.error(error));
  }

  openDialog() {
    this._initTitlePortal();
    this._initContextPortal();

    this.buttonsToolbarPortal = new TemplatePortal(
      this.buttonsToolbar,
      this._viewContainerRef
    );

    this.dialogRef = this._dialog.open(SplitViewDialogComponent, {
      data: {
        color: '#007aff',
        icon: null,
        title: this.titlePortal,
        context: this.contextPortal,
        buttonsToolbar: this.buttonsToolbarPortal
      }})
    this.dialogRef.afterClosed().subscribe(_ => {
      this._reset();
    });
  }

  private _initForm() {
    if (this.form) return;

    this.form = this._fb.group({
      id: null,
      name: ['', [Validators.required, StringValidator.noWhitespaceValidator]],
      contactType: [ClientContactType.Organization, Validators.required],
      clientId: '',
      contactId: '',
      bookmanClientId: null,
      gender: '',
      title: '',
      firstName: '',
      lastName: '',
      legalForm: '',
      street: '',
      streetNo: '',
      city: '',
      zip: '',
      phone: new SimplePhoneNumber(),
      email: [null]
    });

    this.onFormUpdate.emit(this.form);
  }

  private _reset() {
    this.submitOngoing = false;
    if (this._action === ClientActionType.EditButton) return;

    this.form.reset();
    this.form.patchValue({contactType: ClientContactType.Organization, phone: new SimplePhoneNumber()});
    this.form.markAsPristine();
    this.contact = null;
    this.client = null;
  }

  private _initTitlePortal() {
    if (this.titlePortal) {
      return;
    }
    this.titlePortal = new TemplatePortal(
      this.title,
      this._viewContainerRef
    );
  }

  private _initContextPortal() {
    if (this.contextPortal) {
      return;
    }
    this.contextPortal = new TemplatePortal(
      this.context,
      this._viewContainerRef
    );
  }

  loadBookmanClients() {
    // if (!this.bookmanClientsLoaded) {
    //   this._store.select(BookmanClientSelectors.loadingState)
    //     .subscribe(loading => {
    //       if (!loading) {
    //         this.bookmanClientsLoaded = true;
    //       }
    //     })
    // }
  }

  selectBookmanClient($event: MatSelectChange) {
    let value = $event.value;
    this.bookmanClients$.pipe(first()).subscribe(cs => {
       this.selectedBookmanClient = cs.find(c => c.id === value);
    });

  }

  refreshBookmanClients() {
    this._store.dispatch(new BookmanClientActions.Refresh());
  }
}
