<div [formGroup]="form" *ngIf="form">
  <div class="row">
    <div class="col-12 col-sm-5">
      <dvtx-input [controlName]="'title'"
                  [form]="form"
                  [placeholder]="'GENERAL.TITLE' | translate"
                  [value]="''"></dvtx-input>
    </div>
    <div class="col-8 col-sm-5">
      <dvtx-datepicker [form]="form"
                       [placeholder]="'GENERAL.DUE_DATE' | translate"
                       [controlName]="'due_date'"></dvtx-datepicker>
    </div>
    <div class="col-4 col-sm-2 color">
      <label>{{'GENERAL.COLOR' | translate}}</label>
      <input type="color" [placeholder]="'GENERAL.COLOR' | translate" [formControlName]="'color'">
    </div>
  </div>
  <mat-accordion>
    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Details
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
      </mat-expansion-panel-header>

      <div class="panel-content">
        <div>
          <dvtx-input [controlName]="'subtitle'"
                      [form]="form"
                      [placeholder]="'GENERAL.SUBTITLE' | translate"
                      [value]="''"></dvtx-input>
          <dvtx-wysiwyg-editor-cva formControlName="description"></dvtx-wysiwyg-editor-cva>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
