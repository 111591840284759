import {IDocumentPreviewDocument} from './document-preview-document.interface';
import {IDocumentPreviewPreview} from '../document-preview-preview/document-preview-preview.interface';

export class DocumentPreviewDocument implements IDocumentPreviewDocument {
  readonly type = 'document_preview_document';
  constructor(public id,
              public pages: number,
              public displayName: string,
              public fileName: string,
              public mimeType: string = null,
              public previews: IDocumentPreviewPreview[] = []
              ) {
  }
}
