import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ProcessTreeEffects} from './process-tree.effects';
import {reducer} from './process-tree.reducer';
import {stateKey} from './process-tree.selectors';
import {ProcessTreeService} from './process-tree.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ProcessTreeEffects])
  ],
  providers: [
    ProcessTreeEffects,
    ProcessTreeService
  ]
})
export class ProcessTreeStoreModule {
}
