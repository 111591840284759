import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {ArtifactKeyword} from './artifact-keyword';

export class ArtifactKeywordBuilder implements IApiResourceBuilder<ArtifactKeyword> {
  constructor(public organizationId) {
  }

  fromResponse(data): ArtifactKeyword {
    if (data) {
      return new ArtifactKeyword(
        data.id,
        this.organizationId,
        data.attributes.name,
        data.attributes.description,
        data.attributes.created_at);
    }
  }

  toRequest(department: ArtifactKeyword) {
    return {
      data: {
        id: department.id,
        type: department.type,
        attributes: {
          name: department.name,
          description: department.description
        }
      }
    };
  }
}
