<ng-container [ngSwitch]="action">
  <!-- Button to open the dialog -->
  <ng-container *ngSwitchCase="DocumentSignatureUserAccountDialogMenuViewType.Button">
    <button mat-icon-button (click)="openWorkflowDialog()" [matTooltip]="'SIGNATURE.START_WORKFLOW_DIALOG.OPEN_SIGNATURE_DIALOG' | translate" color="gray">
      <mat-icon>account_circle</mat-icon>
      <!--<mat-icon>import_export</mat-icon>-->
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="DocumentSignatureUserAccountDialogMenuViewType.IconButton">
    <button (click)="openWorkflowDialog()"
            [matTooltip]="'SIGNATURE.START_WORKFLOW_DIALOG.OPEN_SIGNATURE_DIALOG' | translate"
            mat-icon-button [disabled]="menuItemButtonDisabled">
      <mat-icon>account_circle</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="DocumentSignatureUserAccountDialogMenuViewType.MenuItem">
    <button (click)="openWorkflowDialog()"
            [matTooltip]="'SIGNATURE.START_WORKFLOW_DIALOG.OPEN_SIGNATURE_DIALOG' | translate"
            class="d-flex align-items-center dvtx-process-folder-export-menu-item" [disabled]="menuItemButtonDisabled">
      <mat-icon>account_circle</mat-icon>
      {{ menuItemButtonText | translate }}
    </button>
  </ng-container>

  <!-- Embeddable view to be used inside the settings dialog -->
  <ng-container *ngSwitchCase="DocumentSignatureUserAccountDialogMenuViewType.Embedded">
    <ng-template [ngIf]="contextPortal">
      <div class="w-100 d-flex justify-content-end mb-2" style="max-width: 800px;">
        <div *ngIf="formMode === FpSignFormMode.Login && (accountStatus === FpSignAccountStatus.Unauthorized || accountStatus === FpSignAccountStatus.Disconnected)">
          <button mat-raised-button color="primary" (click)="update()" [disabled]="form.pristine || form.invalid || sendOngoing">
            {{ 'GENERAL.SAVE_ACTION' | translate }}
          </button>
        </div>

        <div *ngIf="formMode === FpSignFormMode.Register && (accountStatus === FpSignAccountStatus.Unauthorized || accountStatus === FpSignAccountStatus.Disconnected)">
          <button mat-raised-button color="primary" (click)="update()" [disabled]="registerForm.pristine || registerForm.invalid || sendOngoing">
            {{ 'SIGNATURE.ACCOUNT.CREATE_SIGNATURE_ACCOUNT' | translate }}
          </button>
        </div>
      </div>
      <div class="w-100 d-flex flex-column">
        <div>
          <ng-template [cdkPortalOutlet]="contextPortal"></ng-template>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <!-- Custom view to be used inside the settings dialog -->
  <ng-container *ngSwitchCase="DocumentSignatureUserAccountDialogMenuViewType.Custom">
    <ng-content></ng-content>
  </ng-container>
</ng-container>

<ng-template cdkPortal #context>
  <div class="w-100 d-flex flex-grow flex-column dvtx-document-signature-user-account-dialog">
    <!--  Dialog Headline  -->
    <div class="w-100 d-flex justify-content-start" *ngIf="action != DocumentSignatureUserAccountDialogMenuViewType.Embedded">
      <p class="mb-2 h2">{{ 'SIGNATURE.ACCOUNT.ACCOUNT_DATA' | translate }}</p>
    </div>

    <!--  Form Container  -->
    <div class="w-100 d-flex justify-content-start">
      <div class="w-100 d-flex flex-column justify-content-start">
        <!-- Prevent autofill -->
        <input autocomplete="new-password" name="hidden" type="text" style="display:none;">

        <ng-template [ngIf]="accountStatus === FpSignAccountStatus.Undefined">
          <div class="h4">
            <div class="dvtx-loading-indicator">
              <div class="d-flex align-items-center justify-content-start">
                <mat-spinner [diameter]="20" style="margin-right: 15px;"></mat-spinner>
                {{ 'SIGNATURE.ACCOUNT.CHECKING_ACCOUNT_STATUS' | translate }}<span>.</span><span>.</span><span>.</span>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template [ngIf]="accountStatus === FpSignAccountStatus.Connected">
          <div class="w-100 d-flex align-items-center flex-column">
            <div class="w-100 d-flex">
              <div class="d-flex align-items-center h4 mb-1">
                <mat-icon class="status-color-success mr-2">check_circle</mat-icon>
                {{'SIGNATURE.ACCOUNT.CONNECTED_ACCOUNT' | translate }}
              </div>
            </div>

            <div class="w-100" style="margin-left: 60px; margin-bottom: 20px">
              {{'SIGNATURE.ACCOUNT.USERNAME' | translate }}: <strong>{{ (account$ | async).id }}</strong>
            </div>

            <div class="w-100 d-flex mb-2">
              <div style="margin-left: 15px">
                <button mat-button color="warn" (click)="unlinkAccount()">
                  {{'SIGNATURE.ACCOUNT.DISCONNECTED_ACCOUNT' | translate }}
                </button>
              </div>
            </div>

            <div class="w-100 d-flex justify-content-start mb-2" *ngIf="QES_FEATURE_TOGGLE">
              <ng-template [ngIf]="qesAvailable && !qesSettingsLoading" [ngIfElse]="qesNotAvailableInfo">
                <div class="w-100 d-flex align-items-center h4">
                  <mat-icon class="status-color-success mr-2">check_circle</mat-icon>
                  {{'SIGNATURE.ACCOUNT.QES_AVAILABLE_BUNDESDRUCKEREI' | translate }}
                </div>
              </ng-template>

              <ng-template #qesNotAvailableInfo>
                <div class="d-flex flex-column" *ngIf="!qesSettingsLoading">
                  <div class="d-flex align-items-center h4 mb-2">
                    <mat-icon class="status-color-default mr-2">radio_button_unchecked</mat-icon>
                    {{'SIGNATURE.ACCOUNT.QES_NOT_AVAILABLE_INFO' | translate }}
                  </div>
                  <div style="margin-left: -20px;">
                    <dvtx-alert [icon]="'info'" [color]="'info'" [text]="'SIGNATURE.ACCOUNT.QES_NOT_AVAILABLE_INFO_2' | translate"></dvtx-alert>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-template>

        <ng-template [ngIf]="accountStatus === FpSignAccountStatus.Disconnected">
          <div class="d-flex align-items-center mt-1 mb-2">
            <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
            <div [innerHTML]="'SIGNATURE.ACCOUNT.ACCOUNT_SETTINGS_INFO_LONG' | translate" class="mb-0"></div>
          </div>

          <div class="w-100 d-flex justify-content-start mb-2" *ngIf="registerError">
            <dvtx-alert [icon]="'warning'" [color]="'warning'" [text]="'SIGNATURE.ERRORS.ERROR_ON_REGISTRATION' | translate"></dvtx-alert>
          </div>

          <div class="w-100 d-flex justify-content-start mb-1" *ngIf="accountCreated">
            <dvtx-alert class="w-100" [icon]="'check_circle'" [color]="'success'" [text]="'SIGNATURE.ACCOUNT.ACCOUNT_CREATED_INFO' | translate"></dvtx-alert>
          </div>

          <div class="w-100 d-flex justify-content-start mb-2" *ngIf="accountCreated">
            <dvtx-alert class="w-100" [icon]="'info'" [color]="'info'" [text]="'SIGNATURE.ACCOUNT.ACCOUNT_CREATED_INFO_2' | translate"></dvtx-alert>
          </div>

          <div class="w-100 d-flex justify-content-start mt-1" *ngIf="!accountCreated">
            <p class="h3">{{ 'SIGNATURE.ACCOUNT.CREATE_SIGNATURE_ACCOUNT' | translate }}</p>
          </div>

          <div class="w-100 d-flex justify-content-start" *ngIf="!accountCreated">
            <div>
              <ng-template [ngIf]="formMode === FpSignFormMode.Login" [ngIfElse]="signInAccountButton">
                <button mat-button (click)="toggleFormMode()" color="primary" class="mr-2 p-0">
                  {{ 'SIGNATURE.ACCOUNT.CREATE_SIGNATURE_ACCOUNT' | translate }}
                </button>
              </ng-template>
              <ng-template #signInAccountButton>
                <button mat-button (click)="backToSelection()" color="primary" class="mr-2">
                  <mat-icon mataffix>arrow_back</mat-icon>
                  {{ 'SIGNATURE.ACCOUNT.BACK_TO_ACCOUNT_OPTIONS' | translate }}
                </button>
              </ng-template>
            </div>
          </div>

          <div class="w-100 mb-2 mt-1 d-flex justify-content-start" *ngIf="formMode === FpSignFormMode.Login && !accountCreated">
            <p class="h2">{{'GENERAL.OR' | translate}}</p>
          </div>

          <div class="w-100 d-flex justify-content-start" *ngIf="!accountCreated">
            <ng-template [ngIf]="formMode === FpSignFormMode.Login">
              <p class="h3">{{ 'SIGNATURE.ACCOUNT.LINK_WITH_EXISTING_ACCOUNT' | translate }}</p>
            </ng-template>
          </div>

          <div class="w-100 d-flex justify-content-start" *ngIf="accountCreated">
            <ng-template [ngIf]="formMode === FpSignFormMode.Login">
              <p class="h3">{{ 'SIGNATURE.ACCOUNT.LOGIN' | translate }}</p>
            </ng-template>
          </div>

          <div class="w-100 d-flex justify-content-start mb-2" *ngIf="credentialError">
            <dvtx-alert [icon]="'warning'" [color]="'warning'" [text]="'SIGNATURE.ACCOUNT.INVALID_CREDENTIALS' | translate"></dvtx-alert>
          </div>

          <div class="w-100 d-flex justify-content-start" *ngIf="formMode == FpSignFormMode.Register">
            <form autocomplete="new-password" class="dvtx-document-signature-user-account-dialog__form-container" novalidate [formGroup]="registerForm">
              <input autocomplete="new-password" name="hidden" type="text" style="display:none;">

              <mat-form-field class="w-100 mb-1">
                <input matInput [placeholder]="'SIGNATURE.ACCOUNT.USERNAME_EMAIL' | translate"
                       email
                       formControlName="username"
                       autocomplete="new-password" />
              </mat-form-field>
            </form>
          </div>

          <div class="w-100 d-flex justify-content-start" *ngIf="formMode == FpSignFormMode.Login">
            <form autocomplete="new-password" class="dvtx-document-signature-user-account-dialog__form-container" novalidate [formGroup]="form">
              <input autocomplete="new-password" name="hidden" type="text" style="display:none;">

              <mat-form-field class="w-100 mb-1">
                <input matInput [placeholder]="'SIGNATURE.ACCOUNT.USERNAME_EMAIL' | translate"
                       email
                       formControlName="username"
                       autocomplete="new-password" />
              </mat-form-field>

              <mat-form-field class="w-100">
                <input matInput type="password" [placeholder]="'SIGNATURE.ACCOUNT.PASSPHRASE' | translate"
                       formControlName="password"
                       autocomplete="new-password" />
              </mat-form-field>
            </form>
          </div>
        </ng-template>

        <ng-template [ngIf]="accountStatus === FpSignAccountStatus.Unauthorized">
          <div class="d-flex align-items-center mt-1 mb-2">
            <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
            <div [innerHTML]="'SIGNATURE.ACCOUNT.ACCOUNT_SETTINGS_INFO_LONG' | translate" class="mb-0"></div>
          </div>

          <div class="w-100 d-flex justify-content-start mb-2" *ngIf="registerError">
            <dvtx-alert [icon]="'warning'" [color]="'warning'" [text]="'SIGNATURE.ERRORS.ERROR_ON_REGISTRATION' | translate"></dvtx-alert>
          </div>

          <div class="w-100 d-flex justify-content-start mb-2">
            <dvtx-alert [icon]="'warning'" [color]="'warning'" [text]="'SIGNATURE.ACCOUNT.INVALID_CREDENTIALS' | translate"></dvtx-alert>
          </div>

          <div class="w-100 d-flex justify-content-start">
            <p class="h4 mb-2 mt-1">{{ 'SIGNATURE.ACCOUNT.PLEASE_INSERT_DATA_AGAIN' | translate }}</p>
          </div>

          <div class="w-100 d-flex justify-content-start mb-2">
            <div>
              <button mat-button (click)="backToSelection()" color="primary" class="mr-2">
                <mat-icon mataffix>arrow_back</mat-icon>
                {{ 'SIGNATURE.ACCOUNT.BACK_TO_ACCOUNT_OPTIONS' | translate }}
              </button>
            </div>
          </div>

          <div class="w-100 d-flex justify-content-start" *ngIf="formMode == FpSignFormMode.Register">
            <form autocomplete="new-password" class="dvtx-document-signature-user-account-dialog__form-container" novalidate [formGroup]="registerForm">
              <input autocomplete="new-password" name="hidden" type="text" style="display:none;">

              <mat-form-field class="w-100 mb-1">
                <input matInput [placeholder]="'SIGNATURE.ACCOUNT.USERNAME_EMAIL' | translate"
                       email
                       formControlName="username"
                       autocomplete="new-password" />
              </mat-form-field>
            </form>
          </div>

          <div class="w-100 d-flex justify-content-start" *ngIf="formMode == FpSignFormMode.Login">
            <form autocomplete="new-password" class="dvtx-document-signature-user-account-dialog__form-container" novalidate [formGroup]="form">
              <input autocomplete="new-password" name="hidden" type="text" style="display:none;">

              <mat-form-field class="w-100 mb-1">
                <input matInput [placeholder]="'SIGNATURE.ACCOUNT.USERNAME_EMAIL' | translate"
                       email
                       formControlName="username"
                       autocomplete="new-password" />
              </mat-form-field>

              <mat-form-field class="w-100">
                <input matInput type="password" [placeholder]="'SIGNATURE.ACCOUNT.PASSPHRASE' | translate"
                       formControlName="password"
                       autocomplete="new-password" />
              </mat-form-field>
            </form>
          </div>
        </ng-template>

        <ng-template [ngIf]="accountStatus === FpSignAccountStatus.Failure">
          <div class="w-100 mt-2 d-flex justify-content-start mb-1">
            <h2><mat-icon class="status-color-danger" style="font-size: 40px;">warning</mat-icon></h2>
          </div>

          <div class="w-100 d-flex justify-content-start">
            <p class="h3">{{ 'SIGNATURE.ERRORS.GENERAL_ERROR_RETRY_LATER' | translate }}</p>
          </div>

          <div class="w-100 mt-2 d-flex justify-content-start mb-2">
            <div>
              <button color="primary" mat-button (click)="fetchAccountInfo()">
                <mat-icon mataffix>refresh</mat-icon>
                {{ 'SIGNATURE.ACTIONS.RELOAD_ACCOUNT_INFO_ACTION' | translate }}
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template cdkPortal #buttonsToolbar>
  <div>
    <button mat-button (click)="closeDialog()" class="mr-2">{{ 'GENERAL.CLOSE_ACTION' | translate }}</button>
  </div>

  <div *ngIf="formMode === FpSignFormMode.Login && (accountStatus === FpSignAccountStatus.Unauthorized || accountStatus === FpSignAccountStatus.Disconnected)">
    <button mat-raised-button color="primary" (click)="update()" [disabled]="form.pristine || form.invalid || sendOngoing">
      {{ 'GENERAL.SAVE_ACTION' | translate }}
    </button>
  </div>

  <div *ngIf="formMode === FpSignFormMode.Register && (accountStatus === FpSignAccountStatus.Unauthorized || accountStatus === FpSignAccountStatus.Disconnected)">
    <button mat-raised-button color="primary" (click)="update()" [disabled]="registerForm.pristine || registerForm.invalid || sendOngoing">
      {{ 'SIGNATURE.ACCOUNT.CREATE_SIGNATURE_ACCOUNT' | translate }}
    </button>
  </div>
</ng-template>
