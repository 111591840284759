<div class="mat-dialog-inner-wrap" [style.borderColor]="color">
  <div class="mat-dialog-header">
    <h2 mat-dialog-title> {{ 'TENANT.USER_MGMT.CREATE_USER_ACCOUNT' | translate }}</h2>
    <!-- This dialog has already cancel button: We must forbid closing to not destroy the component during creation call -->
    <!-- <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>-->
  </div>
  <div class="five-f-mat-tab">
    <mat-tab-group style="padding-left: 13px;" [selectedIndex]="selectedIndexTab" (selectedIndexChange)="resetForms()">
      <mat-tab [disabled]="employeeCreationOngoing">
        <ng-template mat-tab-label>
          {{ 'CONTACTS.CREATE_EMPLOYEE' | translate }}
        </ng-template>

        <div mat-dialog-content
             class="dvtx-create-employee-content"
             [class.hide-progress-bar]="!employeeCreationOngoing"
             [class.show-progress-bar]="employeeCreationOngoing">
          <h4 class="mt-2 mb-1 dvtx-loading-indicator">{{ 'CONTACTS.ADDING_EMPLOYEE_ONGOING' | translate }}<span>.</span><span>.</span><span>.</span></h4>

          <div class="w-100 d-flex">
            <div class="w-100 d-flex align-items-center pr-1">
              <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
            </div>
          </div>
        </div>

        <div class="five-f-mat-input dvtx-create-employee-content" mat-dialog-content  [class.hide-form]="employeeCreationOngoing">
          <div [formGroup]="createEmployeeForm" autocomplete="off">
            <div class="five-f-icon-content-layout">
              <mat-icon>person</mat-icon>
              <div class="five-f-icon-content-layout--content">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-5">
                    <dvtx-text-input-cva formControlName="firstName" class="comp-name" [requiredField]="true"
                      [elementName]="'CONTACTS.FIRST_NAME' | translate"></dvtx-text-input-cva>
                  </div>
                  <div class="col-12 col-sm-6 col-md-5">
                    <dvtx-text-input-cva formControlName="lastName" class="comp-name" [requiredField]="true"
                      [elementName]="'CONTACTS.LAST_NAME' | translate"></dvtx-text-input-cva>
                  </div>
                </div>
              </div>
            </div>

            <div class="five-f-icon-content-layout">
              <mat-icon>vpn_key</mat-icon>
              <div class="five-f-icon-content-layout--content">
                <div class="row">
                  <div class="col-12 col-md-10">
                    <mat-form-field class="password-container">
                      <mat-label>{{'AUTH.PASSWORD' | translate}}</mat-label>
                      <input #passwordInput autocomplete="new-password" formControlName="password" class="comp-name"
                             required
                        matInput [type]="showFirstPass" id="password-employee">
                      <mat-icon class="dvtx-fg-color dvtx-fg-cool-grey-400" *ngIf="showFirstPass === fieldType.Password" (click)="showFirstPassword();">visibility
                      </mat-icon>
                      <mat-icon class="dvtx-fg-color dvtx-fg-cool-grey-400" *ngIf="showFirstPass === fieldType.Text" (click)="hideFirstPassword();">visibility_off
                      </mat-icon>
                    </mat-form-field>
                    <div
                      *ngIf="!!passwordInput.value && verifyPasswordStrength(passwordInput.value) && verifyPasswordStrength(passwordInput.value).level !== 'success'">
                      <span class="dvtx-fg-color dvtx-fg-fivef-blue-300">{{
                        verifyPasswordStrength(passwordInput.value)?.message | translate }}</span>
                    </div>
                    <button mat-icon-button
                    style="position: absolute; top: 0rem; right: -2.5rem;"
                    color="gray" [matTooltip]="'PASSWORD.STRONG_PASSWORD_POLICY' | translate"><mat-icon>info</mat-icon></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="five-f-icon-content-layout">
              <mat-icon>email</mat-icon>
              <div class="five-f-icon-content-layout--content">
                <div class="row">
                  <div class="col-12" style="padding-left:0px;">
                    <dvtx-email-address-input formControlName="email" [showEmailType]="false" [requiredField]="true"
                      [elementName]="'CONTACTS.EMAIL' | translate"
                      [errorMapper]="[{error:'InvalidEmailAddress', message: 'CONTACTS.ENTER_VALID_EMAIL' | translate }]">
                    </dvtx-email-address-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="five-f-icon-content-layout">
                <div class="five-f-icon-content-layout--content">
                  <div class="row">
                    <div class="col-12 col-md-10">
                      <dvtx-text-input-cva formControlName="note" class="comp-name"
                      [elementName]="'Note'"></dvtx-text-input-cva>
                    </div>
                  </div>
                </div>
              </div> -->
            <div class="w-100 my-1 d-flex align-items-center">
              <div class="w-100 ml-1">
                <mat-checkbox [labelPosition]="'after'" color="primary" formControlName="notifyUser">
                  <dvtx-table-text-and-info [alignmentPosition]="'start'" [h4]="true"
                    [title]="'CONTACTS.INFORM_EMPLOYEE_BY_EMAIL'" [tooltip]="'CONTACTS.INFORM_EMPLOYEE_BY_EMAIL_INFO'">
                  </dvtx-table-text-and-info>
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div mat-dialog-actions class="d-flex justify-content-end">
          <button mat-button
                  [disabled]="employeeCreationOngoing"
                  (click)="closeDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
          <button mat-raised-button color="primary" (click)="createEmployee()"
            [disabled]="employeeCreationOngoing || !createEmployeeForm.valid || passwordChangeOngoing || isPasswordInvalid(passwordInput.value)">
            <mat-icon matPrefix>save</mat-icon>
            {{'GENERAL.SAVE_ACTION' | translate }}
          </button>
        </div>
      </mat-tab>

      <mat-tab [disabled]="employeeCreationOngoing">
        <ng-template mat-tab-label>
          {{ 'INVITATIONS.INVITE_EMPLOYEE' | translate }}
        </ng-template>
        <div mat-dialog-content class="dvtx-invite-employee-content">
          <dvtx-membership-invitation #membershipInvitation></dvtx-membership-invitation>
        </div>
        <div mat-dialog-actions class="d-flex justify-content-end">
          <button mat-button (click)="closeDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
          <button [disabled]="!isInviteeEmailValid()" mat-raised-button color="primary" class="ml-3"
            (click)="openMemberInvitationDialog()">
            <mat-icon matPrefix>send</mat-icon>
            {{ 'INVITATIONS.SEND_INVITATION' | translate }}
          </button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
