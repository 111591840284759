import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {QuickshareEffects} from './quickshare.effects';
import {QuickshareService} from './quickshare.service';
import {reducer} from './quickshare.reducer';
import {stateKey} from './quickshare.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([QuickshareEffects])
  ],
  providers: [
    QuickshareEffects,
    QuickshareService
  ]
})
export class QuickshareStoreModule {
}
