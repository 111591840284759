import {createEntityAdapter, EntityState} from '@ngrx/entity';
import * as dayjs from 'dayjs';
import { TaskStatusScheme } from './task-status-scheme';

export interface State extends EntityState<TaskStatusScheme> {
  selected?: TaskStatusScheme;
  loading: boolean;
}

export const adapter = createEntityAdapter<TaskStatusScheme>({
  sortComparer: (l, r) => -(dayjs(l.updatedAt).diff(dayjs(r.updatedAt)))
});

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
