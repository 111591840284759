import { IApiResourceBuilder } from 'app/shared/modules/api-resource/models/api.interface';
import { KanbanItem } from './kanban-item';
import { IKanbanItemParams } from './kanban-item.interface';
import { Label } from 'app/+store/label/label';
import { LabelBuilder } from 'app/+store/label/label.builder';

export class KanbanItemBuilder implements IApiResourceBuilder<KanbanItem> {
  labels: {
    [id: string]: Label
  } = {};

  fromResponse(data): KanbanItem {
    const kanbanItem = new KanbanItem(
      data.id,
      data.attributes.reference_id,
      data.attributes.item_type
    );

    kanbanItem.labelsIds = [];

    try {
      if (data.relationships && data.relationships.labels && data.relationships.labels.data) {
        let labels = [];
        try {
          data.relationships.labels.data.forEach(label => {
            kanbanItem.labelsIds.push(label.id);
            labels.push(this.labels[label.id]);
          });
          kanbanItem.labels = labels;
        } catch (err) {
          console.error('ERROR: Add included labels section failed', err);
        }
      }
    } catch (err) {
      console.error('ERROR: Add included section failed', err);
    }

    return kanbanItem;
  }

  addIncludedSection(includes) {
    if (!includes || includes.length === 0) {
      return;
    }
    const cbuilder = new LabelBuilder();

    includes.map( (include) => {
      if (include.type === 'label') {
        const _label = cbuilder.fromResponse(include);
        this.labels[include.id] = _label;
      }
    });
  }

  toRequest(params: IKanbanItemParams) {
    return {
      data: {
        type: 'label',
        attributes: {
          reference_id: params.referenceId,
          item_type: params.boardType,
          process_id: params.processId,
          label_id: params.labelId
        }
      }
    };
  }
}
