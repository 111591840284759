import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ProcessArtifactEffects} from './process-artifact.effects';
import {ProcessArtifactService} from './process-artifact.service';
import {reducer} from './process-artifact.reducer';
import {stateKey} from './process-artifact.selectors';
import {ProcessArtifactDownloadManagerService} from './process-artifact-download-manager.service';
import {FileApiResourceService, getSaver, SAVER} from './file-api-resource.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([ProcessArtifactEffects])
  ],
  providers: [
    FileApiResourceService,
    ProcessArtifactEffects,
    ProcessArtifactService,
    ProcessArtifactDownloadManagerService,
    {provide: SAVER, useFactory: getSaver}
  ]
})
export class ProcessArtifactStoreModule {
}
