import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './document-template.state';
import {OrganizationSelectors} from 'app/+store/organization';

export const stateKey = 'documentTemplate';
const getDocumentTemplateState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getDocumentTemplateEntities,
  selectAll: getAllDocumentTemplates,
} = adapter.getSelectors(getDocumentTemplateState);

export const getCurrentTemplate = createSelector(
  getDocumentTemplateState,
  state => state.current
);

export const getAllTemplatesOfOrganization = createSelector(
  OrganizationSelectors.getSelectedId,
  getAllDocumentTemplates,
  (id, entities) => entities.filter(entity => entity.organizationId === id)
);
