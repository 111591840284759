import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Create, CreateFail, DmsDocumentExportActionTypes} from './dms-document-export.actions';
import {DmsDocumentExportService} from './dms-document-export.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class DmsDocumentExportEffects {
  @Effect()
  create$ = this.actions.pipe(
    ofType(DmsDocumentExportActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.processId, action.exportType, action.ids, action.params).pipe(
        first(),
        concatMap(_res => {
          // The return value is a file export transaction, so only the success message is important.
          return [];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: DmsDocumentExportService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




