import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ITabNavRoute} from '../../models/tab-nav-route.interface';
import {Location} from '@angular/common';

export enum FiveFCardProfile {
  User = 'User',
  Organization = 'Organization',
  Operator = 'Operator'
}

@Component({
  selector: 'dvtx-organization-card',
  templateUrl: './organization-card.component.html',
  styleUrls: ['./organization-card.component.scss']
})
export class OrganizationCardComponent implements OnInit {
  FiveFCardProfile = FiveFCardProfile;

  @Input() backLink = null;
  @Input() profile = FiveFCardProfile.Organization;
  @Input() enableOnBack = null;
  @Output() onBack = new EventEmitter();

  @Input() topHeadline = null;
  @Input() headline = null;
  @Input() subtitle = null;
  @Input() headlinePortal = null;
  @Input() routes: ITabNavRoute[] = null;
  @Input() activeLink: string;
  @Input() header;
  @Input() browserBackEnabled = false;

  constructor(private _location: Location) { }

  ngOnInit() {
  }

  back() {
    this._location.back();
  }
}
