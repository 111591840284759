import {Action} from '@ngrx/store';
import {Feature} from './feature';

export enum FeatureActionTypes {
  LoadAll = '[Feature] Load All',
  LoadAllSuccess = '[Feature] Load All Success',
  LoadAllFail = '[Feature] Load All Fail'
}

export class LoadAll implements Action {
  readonly type = FeatureActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = FeatureActionTypes.LoadAllSuccess;

  constructor(public features: Feature[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = FeatureActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export type FeatureActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail;
