import {DocumentPreviewPreviewActions, DocumentPreviewPreviewActionTypes} from './document-preview-preview.actions';
import {adapter, initialState, State} from './document-preview-preview.state';

export function reducer(state = initialState, action: DocumentPreviewPreviewActions): State {
  switch (action.type) {
    case DocumentPreviewPreviewActionTypes.RequestPreview:
      return {
        ...state,
        loading: true
      };
    case DocumentPreviewPreviewActionTypes.RequestPreviewSuccess:
      return adapter.upsertOne(action.preview, {
        ...state,
        loading: false,
      });
    case DocumentPreviewPreviewActionTypes.RequestPreviewFail:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
}

