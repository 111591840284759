import { Component, Input } from '@angular/core';
import { AvatarService } from 'app/shared/modules/user-account/components/avatar/avatar.service';
import { Observable ,  BehaviorSubject } from 'rxjs';

@Component({
  selector: 'dvtx-item-comment-seen-by-dropdown',
  templateUrl: './item-comment-seen-by-dropdown.component.html',
  styleUrls: ['./item-comment-seen-by-dropdown.component.scss']
})
export class ItemCommentSeenByDropdownComponent {
  _persons: BehaviorSubject<any[]> = new BehaviorSubject([]);
  showDropdown = false;

  @Input() set persons(persons) {
    this._persons.next(persons);
  };
  @Input() showAvatarTitle;
  @Input() showTitle = false;
  @Input() title;
  @Input() icon;

  constructor(private avatarService: AvatarService) {
  }
}
