import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {InfoSymbolComponent} from './components/info-symbol/info-symbol.component';
import {InfoBlockComponent} from './components/info-block/info-block.component';
import { InfoIconMessageBlockComponent } from './components/info-icon-message-block/info-icon-message-block.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule
  ],
  declarations: [
    InfoBlockComponent,
    InfoSymbolComponent,
    InfoIconMessageBlockComponent
  ],
  exports: [
    InfoBlockComponent,
    InfoSymbolComponent,
    InfoIconMessageBlockComponent
  ]
})
export class InfoBlockModule {
}
