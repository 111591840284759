import { ITasksStats } from './tasks-stats.interface';

export class TasksStats implements ITasksStats {
  readonly type = 'task_stats';

  constructor(
    public id: string,
    public allTasks: number = 0,
    public pendingTasks: number = 0,
    public openTasks: number = 0,
    public closedTasks: number = 0,
    public tasksResponsibilities: number = 0,
    public openTasksResponsibilities: number = 0,
    public acceptedTasks: number = 0,
    public rejectedTasks: number = 0,
    public approvedTasks: number = 0,
    public denialTasks: number = 0,
    public allOpenTasks: number = 0,
    public allClosedTasks: number = 0,
    public myClosedTasks: number = 0,
    public updatedAt: Date
  ) { }
}
