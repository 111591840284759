export enum AppendixStatusEnum {
  sent = 'STATUS.SENT',
  in_progress = 'STATUS.IN_PROGRESS',
  received = 'STATUS.RECEIVED',
  overdued = 'STATUS.OVERDUE',
  completed = 'STATUS.COMPLETED',
  closed = 'STATUS.CLOSED',
  not_applicable = 'STATUS.NOT_APPLICABLE'
}

export enum ApplicableState {
  Applicable = 'WORKFLOWS.STATUS.APPLICABLE',
  NotApplicable = 'WORKFLOWS.STATUS.NOT_APPLICABLE',
  None = ''
}

export enum AppendixStatusTextEnum {
  sent = 'Versendet',
  in_progress = 'In Bearbeitung',
  received = 'Erhalten',
  overdued = 'Überfällig',
  completed = 'Geschlossen',
}

export enum MatrixViewRole {
  Editor = 'editor',
  Status = 'status'
}

export interface ICheckableElement {
  id: string;
  type: string;
  title: string;
  dueDate: string;
  checked: boolean;
  overdued: boolean;
  closed: boolean;
  applicable: ApplicableState;
  status: AppendixStatusEnum;
}

export const MatrixItemStatusIcon = {
  'STATUS.SENT': 'send',
  'STATUS.IN_PROGRESS': 'timelapse',
  'STATUS.RECEIVED': 'call_received',
  'STATUS.OVERDUE': 'alarm',
  'STATUS.COMPLETED': 'check_circle',
  'STATUS.ACCEPTED': 'done_all',
  'STATUS.CLOSED': 'offline_pin',
  'STATUS.NOT_APPLICABLE': 'not_interested'
};

export const MatrixStatusColorMapping = {
  'STATUS.SENT': '#8a8a8a',
  'STATUS.IN_PROGRESS': '#5bc4f1',
  'STATUS.RECEIVED': '#5bc4f1',
  'STATUS.OVERDUE': '#db0000',
  'STATUS.COMPLETED': '#00BD00',
  'STATUS.ACCEPTED': '#00BD00',
  'STATUS.CLOSED': '#00BD00',
  'STATUS.NOT_APPLICABLE': '#db0000'
};
