import {
  ThirdPartyConfirmationContribution,
  ThirdPartyConfirmationContributionRow
} from './contribution';

export namespace ThirdPartyConfirmationContributionBuilder {
  export function fromResponse(response): ThirdPartyConfirmationContribution {
    const thirdParty = new ThirdPartyConfirmationContribution(
      response.id,
      response.type,
      response.attributes.title,
      response.attributes.preamble,
      response.attributes.recipient_name,
      response.attributes.due_date,
      response.attributes.referenced_at,
      response.attributes.sender_name,
      response.attributes.contractor,
      response.attributes.contractee,
      response.attributes.business_year_start,
      response.attributes.business_year_end,
      response.attributes.token,
      response.attributes.recipient_email,
      response.attributes.third_party_firstname,
      response.attributes.third_party_lastname,
      response.attributes.third_party_email,
      response.attributes.third_party_function,
      response.attributes.third_party_company_name,
      response.attributes.third_party_place,
      response.attributes.third_party_date,
      response.attributes.rows.map((row) => {
        return new ThirdPartyConfirmationContributionRow(row.type, row.attributes);
      }),
      response.attributes.signature,
      response.attributes.external_preamble_de,
      response.attributes.external_preamble_en,
    );
    thirdParty.contactConfirmedAt = response.attributes.contact_confirmed_at;
    thirdParty.emailAttachmentFilename = response.attributes.email_attachment_filename;
    return thirdParty;
  }

  export function toRequest(resource: ThirdPartyConfirmationContribution) {
    return {
      id: resource.id,
      type: resource.type,
      attributes: {
        title: resource.title,
        preamble: resource.preamble,
        recipient_name: resource.recipient_name,
        due_date: resource.due_date,
        referenced_at: resource.referenced_at,
        sender_name: resource.sender_name,
        contractor: resource.contractor,
        contractee: resource.contractee,
        business_year_start: resource.business_year_start,
        business_year_end: resource.business_year_end,
        token: resource.token,
        recipient_email: resource.recipient_email,
        third_party_firstname: resource.third_party_firstname,
        third_party_lastname: resource.third_party_lastname,
        third_party_email: resource.third_party_email,
        third_party_function: resource.third_party_function,
        third_party_company_name: resource.third_party_company_name,
        third_party_place: resource.third_party_place,
        third_party_date: resource.third_party_date,
        rows: resource.rows.map((row) => {
          return {
            type: row.type,
            attributes: row.attributes
          };
        })
      }
    };
  }
}
