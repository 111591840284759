
import { Label } from "app/+store/label/label";
import { LabelScope, LabelOrigin } from "app/+store/label/label.interface";

export const GLOBAL_LABELS = <Label[]>[
  new Label(
    'APPLICABLE',
    'done',
    '#32CD32',
    'LABELS.5F_GLOBAL_LABELS_TITLE.APPLICABLE',
    'LABELS.5F_GLOBAL_LABELS_DESCRIPTION.APPLICABLE',
    true,
    LabelScope.GLOBAL,
    LabelOrigin.NO_ORIGIN,
    0,
    false,
    null
  ),
  new Label(
    'NOT_APPLICABLE',
    'not_applicable',
    '#FF0000',
    'LABELS.5F_GLOBAL_LABELS_TITLE.NOT_APPLICABLE',
    'LABELS.5F_GLOBAL_LABELS_DESCRIPTION.NOT_APPLICABLE',
    true,
    LabelScope.GLOBAL,
    LabelOrigin.NO_ORIGIN,
    0,
    true,
    null
  ),
  new Label(
    'SIGNED',
    'signature',
    '#000080',
    'LABELS.5F_GLOBAL_LABELS_TITLE.SIGNED',
    'LABELS.5F_GLOBAL_LABELS_DESCRIPTION.SIGNED',
    true,
    LabelScope.GLOBAL,
    LabelOrigin.NO_ORIGIN,
    0,
    true,
    null
  ),
  new Label(
    'TO_SIGN',
    'signature',
    '#ADD8E6',
    'LABELS.5F_GLOBAL_LABELS_TITLE.TO_SIGN',
    'LABELS.5F_GLOBAL_LABELS_DESCRIPTION.TO_SIGN',
    true,
    LabelScope.GLOBAL,
    LabelOrigin.NO_ORIGIN,
    0,
    true,
    null
  ),
  new Label(
    'CHECKED',
    'done',
    '#32CD32',
    'LABELS.5F_GLOBAL_LABELS_TITLE.CHECKED',
    'LABELS.5F_GLOBAL_LABELS_DESCRIPTION.CHECKED',
    true,
    LabelScope.GLOBAL,
    LabelOrigin.NO_ORIGIN,
    0,
    false,
    null
  ),
  new Label(
    'STAMPED',
    'rubber-stamp',
    '#006400',
    'LABELS.5F_GLOBAL_LABELS_TITLE.STAMPED',
    'LABELS.5F_GLOBAL_LABELS_DESCRIPTION.STAMPED',
    true,
    LabelScope.GLOBAL,
    LabelOrigin.NO_ORIGIN,
    0,
    true,
    null
  ),
]

export const GLOBAL_LABELS_RESERVED_TITLES = [
    'applicable',
    'not applicable',
    'signed',
    'to sign',
    'checked',
    'stamped',
    'anwendbar',
    'nicht anwendbar',
    'signiert',
    'zu signieren',
    'geprüft',
    'Stempel'
  ]
