import {Action} from '@ngrx/store';
import {TaskStatusScheme} from './task-status-scheme';

export enum TaskStatusSchemeActionTypes {
  Create = '[TaskStatusScheme] Create Scheme',
  CreateSuccess = '[TaskStatusScheme] Create Scheme Success',
  CreateFail = '[TaskStatusScheme] Create Scheme Fail',
  Edit = '[TaskStatusScheme] Edit Scheme',
  EditSuccess = '[TaskStatusScheme] Edit Scheme Success',
  EditFail = '[TaskStatusScheme] Edit Scheme Fail',
  Delete = '[TaskStatusScheme] Delete Scheme',
  DeleteSuccess = '[TaskStatusScheme] Delete Scheme Success',
  DeleteFail = '[TaskStatusScheme] Delete Scheme Fail',
  LoadAll = '[TaskStatusScheme] Load All',
  LoadAllSuccess = '[TaskStatusScheme] Load All Success',
  LoadAllFail = '[TaskStatusScheme] Load All Fail',
  LoadOne = '[TaskStatusScheme] Load One',
  LoadOneSuccess = '[TaskStatusScheme] Load One Success',
  LoadOneFail = '[TaskStatusScheme] Load One Fail',
  LoadDefault = '[TaskStatusScheme] Load Default',
  LoadDefaultSuccess = '[TaskStatusScheme] Load Default Success',
  LoadDefaultFail = '[TaskStatusScheme] Load Default Fail'
}

export class Create implements Action {
  readonly type = TaskStatusSchemeActionTypes.Create;

  constructor(public scheme: TaskStatusScheme) {
  }
}

export class CreateSuccess implements Action {
  readonly type = TaskStatusSchemeActionTypes.CreateSuccess;

  constructor(public scheme: TaskStatusScheme) {
  }
}

export class CreateFail implements Action {
  readonly type = TaskStatusSchemeActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Edit implements Action {
  readonly type = TaskStatusSchemeActionTypes.Edit;

  constructor(public scheme: TaskStatusScheme) {
  }
}

export class EditSuccess implements Action {
  readonly type = TaskStatusSchemeActionTypes.EditSuccess;

  constructor(public scheme: TaskStatusScheme) {
  }
}

export class EditFail implements Action {
  readonly type = TaskStatusSchemeActionTypes.EditFail;

  constructor(public payload: any) {
  }
}


export class Delete implements Action {
  readonly type = TaskStatusSchemeActionTypes.Delete;

  constructor(public schemeId: string) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = TaskStatusSchemeActionTypes.DeleteSuccess;

  constructor(public scheme: TaskStatusScheme) {
  }
}

export class DeleteFail implements Action {
  readonly type = TaskStatusSchemeActionTypes.DeleteFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = TaskStatusSchemeActionTypes.LoadAll;

  constructor(public reset = false) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = TaskStatusSchemeActionTypes.LoadAllSuccess;

  constructor(public payload: TaskStatusScheme[], public statusSchemes: TaskStatusScheme[] = [], public reset = false) {
  }
}

export class LoadAllFail implements Action {
  readonly type = TaskStatusSchemeActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadDefault implements Action {
  readonly type = TaskStatusSchemeActionTypes.LoadDefault;

  constructor() {
  }
}

export class LoadDefaultSuccess implements Action {
  readonly type = TaskStatusSchemeActionTypes.LoadDefaultSuccess;

  constructor(public payload: TaskStatusScheme) {
  }
}

export class LoadDefaultFail implements Action {
  readonly type = TaskStatusSchemeActionTypes.LoadDefaultFail;

  constructor(public payload: any) {
  }
}


export class LoadOne implements Action {
  readonly type = TaskStatusSchemeActionTypes.LoadOne;

  constructor(public schemeId: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = TaskStatusSchemeActionTypes.LoadOneSuccess;

  constructor(public payload: TaskStatusScheme) {
  }
}

export class LoadOneFail implements Action {
  readonly type = TaskStatusSchemeActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}


export type TaskStatusSchemeActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Edit
  | EditSuccess
  | EditFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | LoadDefault
  | LoadDefaultSuccess
  | LoadDefaultFail;
