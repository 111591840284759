import {CmsArticleActions, CmsArticleActionTypes} from './cms-article.actions';
import {adapter, initialState, State} from './cms-article.state';

export function reducer(state = initialState, action: CmsArticleActions): State {
  const current = state.selected;
  switch (action.type) {
    case CmsArticleActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case CmsArticleActionTypes.Create:
    case CmsArticleActionTypes.LoadOne:
    case CmsArticleActionTypes.Remove:
    case CmsArticleActionTypes.Save:
      return {
        ...state,
        loading: true
      };
    case CmsArticleActionTypes.LoadOneSuccess:
      return adapter.addOne(action.article, {
        ...state,
        selected: null,
        loading: false,
      });
    case CmsArticleActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.articles, {
        ...state,
        selected: null,
        loading: false,
      });
    case CmsArticleActionTypes.CreateSuccess:
    case CmsArticleActionTypes.SaveSuccess:
      return adapter.upsertOne(action.article, {
        ...state,
        selected: action.article,
        loading: false,
      });
    case CmsArticleActionTypes.CreateFail:
    case CmsArticleActionTypes.LoadAllFail:
    case CmsArticleActionTypes.LoadOneFail:
    case CmsArticleActionTypes.RemoveFail:
    case CmsArticleActionTypes.SaveFail:
      return {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      };
    case CmsArticleActionTypes.RemoveSuccess:
      return adapter.removeOne(action.article.id, {
        ...state,
        selected: (current && current.id === action.article.id) ?  null : current,
        loading: false,
      });
  }
  return state;
}

