import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './collector-item.state';
import {CollectorCategorySelectors} from '../collector-category';

export const stateKey = 'collector-item';
const getCollectorItemState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getCollectorItemEntities,
  selectAll: getAllCollectorItems,
} = adapter.getSelectors(getCollectorItemState);

export const getSelected = createSelector(
  getCollectorItemState,
  (state) => state.selected
)

export const getOne = (id: string) => createSelector(
  getCollectorItemEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllCollectorItems,
  (entities) => entities
);

export const getItemsByCategoryId = (id: string) => createSelector(
  getAllCollectorItems,
  (items) => {
    if (!id) {
      return [];
    }
    return items.filter(i => i.categoryId === id);
  }
);

export const getItemsByCollectorId = (id: string) => createSelector(
  CollectorCategorySelectors.getCategoriesByCollectorId(id),
  getAllCollectorItems,
  (categories, items) => {
    if (!id) {
      return [];
    }

    // Naive approach: Runs through all categories c and for each through all items i
    // => O(c * i) = O(n^2) squared effort.
    // allCollectorItems = allCollectorItems.concat(items.filter(i => i.categoryId === category.id))
    // Better: Build map instead and concat precalculated result:
    // => O(i) + O(c) * O(1)  = O(2n) linear effort.
    let allCollectorItems = [];
    const categoryItemMap = {}
    items.forEach(item => {
      if (!categoryItemMap[item.categoryId]) {
        categoryItemMap[item.categoryId] = [];
      }
      categoryItemMap[item.categoryId].push(item);
    });

    categories.forEach(category => {
      if (categoryItemMap[category.id] && categoryItemMap[category.id].length) {
        allCollectorItems = allCollectorItems.concat(categoryItemMap[category.id])
      }
    });
    return allCollectorItems;
  }
);

export const getStatusStats = createSelector(
  getAllCollectorItems,
  (items) => {
    return items.reduce((acc, item) => {
      if (!acc[item.status]) acc[item.status] = 0;
      acc[item.status] += 1;
      return acc;
    }, {});
  }
);

export const loadingState = createSelector(
  getCollectorItemState,
  (state) => state.loading
);

export const getCommentCount = (id) => createSelector(
  getCollectorItemEntities,
  (entities) => entities[id] && entities[id].commentCount
);

export const getUnreadCommentCount = (id) => createSelector(
  getCollectorItemEntities,
  (entities) => entities[id] && entities[id].unreadCommentCount
);
