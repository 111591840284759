import {createEntityAdapter, EntityState} from '@ngrx/entity'
import { EmailToName } from './email-to-name';

export interface State extends EntityState<EmailToName> {
  loading: boolean
}

export const adapter = createEntityAdapter<EmailToName>()

export const initialState: State = adapter.getInitialState({
  loading: false
});
