import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FavoriteProcessService} from 'app/+store/favorite-process/favorite-process.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    FavoriteProcessService
  ]
})
export class FavoriteProcessStoreModule {
}
