import {Process} from 'app/+store/process/process';

export const WORKFLOWS_TYPES = [
  'project',
  'quickshare_v2',
  'collector',
  'project_room',
  'signature',
  'cac',
  'cav',
  'third_party',
  'fastdocs_employee_master_data',
];

export const WORKFLOWS_TYPES_TITLES = {
  'project': Process.humanProcessTypeFromString('project'),
  'quickshare_v2': Process.humanProcessTypeFromString('quickshare_v2'),
  'collector': Process.humanProcessTypeFromString('collector'),
  'project_room': Process.humanProcessTypeFromString('project_room'),
  'signature': Process.humanProcessTypeFromString('signature'),
  'cac': Process.humanProcessTypeFromString('cac'),
  'cav': Process.humanProcessTypeFromString('cav'),
  'third_party': Process.humanProcessTypeFromString('third_party'),
  'fastdocs_employee_master_data': Process.humanProcessTypeFromString('fastdocs_employee_master_data'),
}
