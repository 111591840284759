import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { ApiModule } from 'app/shared/modules/api/api.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { EmojiMenuComponent } from './component/emoji-menu.component';

@NgModule({
  imports: [
    ApiModule,
    OverlayModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    PickerModule,
    EmojiModule
  ],
  declarations: [
    EmojiMenuComponent
  ],
  exports: [
    EmojiMenuComponent
  ]
})
export class EmojiMenuModule {
}
