import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { ApiModule } from 'app/shared/modules/api/api.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorPickerComponent } from './component/color-picker.component';

@NgModule({
    imports: [
        ApiModule,
        OverlayModule,
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule
    ],
    declarations: [
        ColorPickerComponent
    ],
    providers: [],
    exports: [
        ColorPickerComponent
    ]
})
export class ColorPickerModule {
}
