import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {KanbanGroup} from './kanban-group';
import {IKanbanGroupParams} from './kanban-group.interface';
import {LabelScope} from "../../label/label.interface";

export class KanbanGroupBuilder implements IApiResourceBuilder<KanbanGroup> {

  fromResponse(data): KanbanGroup {
    const labelScopeMap = {
      'global': LabelScope.GLOBAL,
      'organizational': LabelScope.ORGANIZATIONAL,
      'personal': LabelScope.PERSONAL,
    }
    return new KanbanGroup(
      data.id,
      data.attributes.order,
      data.attributes.label_id,
      data.attributes.configuration_id,
      data.attributes.label_title,
      data.attributes.label_color,
      labelScopeMap[data.attributes.label_scope],
      data.attributes.items_order,
      data.attributes.is_expanded,
      data.attributes.is_contextual,
      data.attributes.context_type,
      data.attributes.reference_id,
    );
  }

  toRequest(params: IKanbanGroupParams) {
    return {
      data: {
        type: 'group',
        attributes: {
          order: params.order,
          label_id: params.labelId,
          label_title: params.title,
          label_color: params.color,
          items_order: params.itemsOrder,
          is_expanded: params.isExpanded,
          is_contextual: params.isContextual,
          context_type: params.contextType,
          reference_id: params.referenceId,
        }
      }
    };
  }
}
