import {IDocumentTemplate} from './document-template.interface';

export class DocumentTemplate implements IDocumentTemplate {
  readonly type = 'artifact_document_templates';

  constructor(public id,
              public displayName: string,
              public description: string,
              public revision: string,
              public fileName: string,
              public fileSize: number,
              public fileType: string,
              public organizationId: string,
              public published: boolean,
              public content: string = null) {}
}
