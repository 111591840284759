export interface ContactAttributeGroup {
  id: string;
  name: string;
  items: ContactAttribute[];
}

export interface ContactAttributeGroupResponse {
  data: {
    id: string,
    attributes: {
      name: string
    }
    relationships: {
      data: {}[]
    }
  }
}

export interface ContactAttribute {
  id: string;
  key: string;
  value: string;
}


export function SortContactAttributeGroup(a: ContactAttributeGroup, b: ContactAttributeGroup) {
  if (a.name < b.name) {
    return -1;
  } else if (a.name > b.name) {
    return 1;
  }
  return 0;
}
