
import {of as observableOf, combineLatest as observableCombineLatest,  Subject ,  Observable } from 'rxjs';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from "@angular/core";
import { GLOBAL_LABELS, GLOBAL_LABELS_RESERVED_TITLES } from "app/five-f/labels/configs";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { StringValidator } from "app/lib/validator";
import { Store } from "@ngrx/store";
import { AppState } from "app/reducers";
import { LabelSelectors } from "app/+store";
import { filter, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { Label } from "app/+store/label/label";
import { ILabelParams, LabelScope, LabelOrigin } from "app/+store/label/label.interface";

@Component({
  selector: 'dvtx-edit-label-form',
  templateUrl: './edit-label-form.component.html',
  styleUrls: ['./edit-label-form.component.scss']
})
export class EditLabelComponent implements OnInit, OnDestroy {
  protected onDestroy = new Subject();

  public gloablLabels = GLOBAL_LABELS;
  public gloablLabelsReservedTitles = GLOBAL_LABELS_RESERVED_TITLES;
  editLabelForm: UntypedFormGroup;
  LabelScopeRef = LabelScope;
  @Input() label: Label = null;
  @Input() scope: LabelScope;
  @Input() origin: LabelOrigin;
  @Output() onSubmitEditing: EventEmitter<any> = new EventEmitter();
  @Output() onCancelEditing: EventEmitter<any> = new EventEmitter();

  constructor(
    private _store: Store<AppState>,
    private _fb: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initCreateForm();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  initCreateForm() {
    this.editLabelForm = this._fb.group({
      'title': ['', [Validators.required]],
      'color': ['#000000', [Validators.required]],
      'icon': [],
      'description': []
    });

    this.editLabelForm.get('title').setValidators((control) => StringValidator.notExistedIn(control.value, this.gloablLabelsReservedTitles));
    this.editLabelForm.get('title').updateValueAndValidity();
    observableCombineLatest(
      this._store.select(LabelSelectors.getAll).pipe(
        filter(labels => !!labels),
        distinctUntilChanged(),
        takeUntil(this.onDestroy)
      ),
      observableOf(this.label).pipe(
        filter(label => !!label),
        distinctUntilChanged(),
        takeUntil(this.onDestroy)
      )
    )
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([labels, label]) => {
      const blacklistedLabels = [...this.gloablLabelsReservedTitles, ...labels.filter(l => l.id !== label.id).map(l => l.title)];
      this.editLabelForm.get('title').setValidators((control) => StringValidator.notExistedIn(control.value, blacklistedLabels));
      this.editLabelForm.get('title').updateValueAndValidity();

      if (label) {
        this.editLabelForm.patchValue({
          title: label.title,
          color: label.color,
          icon: label.icon,
          description: label.description
        })
      }

    });

  }

  editLabelChangeColor(color) {
    this.editLabelForm.patchValue({
      color: color
    });
  }

  editLabelChangeIcon(icon) {
    this.editLabelForm.patchValue({
      icon: icon
    });
  }

  submitEditLabel() {
    const labelParams: ILabelParams = {
      color: this.editLabelForm.value.color,
      title: this.editLabelForm.value.title,
      icon: this.editLabelForm.value.icon,
      description: this.editLabelForm.value.description,
      scope: this.scope,
      origin: this.origin
    };
    this.onSubmitEditing.emit(labelParams);
  }
}
