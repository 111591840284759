import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {ExternalAccess} from './external-access';
import {ExternalAccessBuilder} from './external-access.builder';

@Injectable()
export class ExternalAccessService {
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';

  constructor(private _http: ApiResourceService) {}

  getAll(processId): Observable<ExternalAccess[]> {
    const builder = new ExternalAccessBuilder(processId);
    return <Observable<ExternalAccess[]>>this._http.get<ExternalAccessBuilder, ExternalAccess>(builder, `${this.BASE_PATH}/${processId}/external_accesses`);
  }

  create(processId, externalAccess: ExternalAccess): Observable<ExternalAccess> {
    const builder = new ExternalAccessBuilder(processId);
    const payload = builder.toRequest(externalAccess);
    return <Observable<ExternalAccess>>this._http.post<ExternalAccessBuilder, ExternalAccess>(builder, `${this.BASE_PATH}/${processId}/external_accesses`, payload);
  }

  update(processId: string, externalAccess: ExternalAccess): Observable<ExternalAccess> {
    const builder = new ExternalAccessBuilder(processId);
    const payload = {
      data: {
        attributes: {
          expires_at: externalAccess.expiresAt,
          passphrase: externalAccess.passphrase,
          comment_enabled: externalAccess.commentEnabled,
          upload_enabled: externalAccess.uploadEnabled,
          email: externalAccess.recipientEmail,
          first_name: externalAccess.recipientFirstName,
          last_name: externalAccess.recipientLastName
        }
      }
    }
    return <Observable<ExternalAccess>>this._http.put<ExternalAccessBuilder, ExternalAccess>(builder, `${this.BASE_PATH}/${processId}/external_accesses/${externalAccess.id}`, payload);
  }

  destroy(processId: string, externalAccessid: string): Observable<ExternalAccess> {
    const builder = new ExternalAccessBuilder(processId);
    return <Observable<ExternalAccess>>this._http.del<ExternalAccessBuilder, ExternalAccess>(builder, `${this.BASE_PATH}/${processId}/external_accesses/${externalAccessid}`);
  }
}
