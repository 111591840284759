import { Iam } from '../models/iam/activity'

/**
 * Create LineItems - the respresentation of articles in
 * the shopping cart.
 */
export class IamActivityBuilder {
  public static toRequest(_: Iam.Activity) {
    throw new Error('Not implemented');
  }

  public static fromResponse(response, _catalog = null): Iam.Activity {
    const attrs = response.attributes;
    return new Iam.Activity(response.id, response.type, attrs.activity, attrs.service);
  }
}
