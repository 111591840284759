import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PartnerLinkParticipationEffects} from './partner-link-participation.effects';
import {PartnerLinkParticipationService} from './partner-link-participation.service';
import {reducer} from './partner-link-participation.reducer';
import {stateKey} from './partner-link-participation.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([PartnerLinkParticipationEffects])
  ],
  providers: [
    PartnerLinkParticipationEffects,
    PartnerLinkParticipationService
  ]
})
export class PartnerLinkParticipationStore {
}
