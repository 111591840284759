import {Action} from '@ngrx/store';
import {ItemLabels} from './item-labels';
import {ItemType, LabelItemScope} from './item-labels.interface';

export enum ItemLabelsActionTypes {
  Create = '[ItemLabels] Create',
  CreateSuccess = '[ItemLabels] Create Success',
  CreateFail = '[ItemLabels] Create Fail',

  Remove = '[ItemLabels] Remove',
  RemoveSuccess = '[ItemLabels] Remove Success',
  RemoveFail = '[ItemLabels] Remove Fail',

  LoadAll = '[ItemLabels] Load All',
  LoadAllSuccess = '[ItemLabels] Load All Success',
  LoadAllFail = '[ItemLabels] Load All Fail',

  LoadAllByLabels = '[ItemLabels] Load All By Labels',
  LoadAllByLabelsSuccess = '[ItemLabels] Load All Success By Labels',
  LoadAllByLabelsFail = '[ItemLabels] Load All Fail By Labels',

  LoadOne = '[ItemLabels] Load One',
  LoadOneSuccess = '[ItemLabels] Load One Success',
  LoadOneFail = '[ItemLabels] Load One Fail',

  Reset = '[ItemLabels] Reset',

  LoadByProcessIdAndScope = '[ItemLabels] Load Label Items by Process ID and scope',
  LoadByProcessIdAndScopeSuccess = '[ItemLabels] Load Label Items by Process ID and scope succeeded',
  LoadByProcessIdAndScopeFail = '[ItemLabels] Load Label Items by Process ID and scope failed'
}

export class Create implements Action {
  readonly type = ItemLabelsActionTypes.Create;

  constructor(public referenceId: string, public labelId: string, public itemType: ItemType, public processId: string) {
  }
}

export class CreateSuccess implements Action {
  readonly type = ItemLabelsActionTypes.CreateSuccess;

  constructor(public labelItem: ItemLabels) {
  }
}

export class CreateFail implements Action {
  readonly type = ItemLabelsActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = ItemLabelsActionTypes.Remove;

  constructor(public referenceId: string, public labelId: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = ItemLabelsActionTypes.RemoveSuccess;

  constructor(public labelItem: ItemLabels) {
  }
}

export class RemoveFail implements Action {
  readonly type = ItemLabelsActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = ItemLabelsActionTypes.LoadAll;

  constructor(public referencesId: string[]) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ItemLabelsActionTypes.LoadAllSuccess;

  constructor(public labelItems: ItemLabels[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ItemLabelsActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadAllByLabels implements Action {
  readonly type = ItemLabelsActionTypes.LoadAllByLabels;

  constructor(public labelsIds: string[]) {
  }
}

export class LoadAllByLabelsSuccess implements Action {
  readonly type = ItemLabelsActionTypes.LoadAllByLabelsSuccess;

  constructor(public labelItems: ItemLabels[]) {
  }
}

export class Reset implements Action {
  readonly type = ItemLabelsActionTypes.Reset;

  constructor() {
  }
}

export class LoadByProcessIdAndScope implements Action {
  readonly type = ItemLabelsActionTypes.LoadByProcessIdAndScope;

  constructor(public processId: string, public scope: LabelItemScope) {
  }
}

export class LoadByProcessIdAndScopeSuccess implements Action {
  readonly type = ItemLabelsActionTypes.LoadByProcessIdAndScopeSuccess;

  constructor(public labelItems: ItemLabels[]) {
  }
}

export class LoadByProcessIdAndScopeFail implements Action {
  readonly type = ItemLabelsActionTypes.LoadByProcessIdAndScopeFail;

  constructor(public payload: any) {
  }
}

export type ItemLabelsActions =
  | Create
  | CreateSuccess
  | CreateFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadAllByLabels
  | LoadAllByLabelsSuccess
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Reset
  | LoadByProcessIdAndScope
  | LoadByProcessIdAndScopeSuccess
  | LoadByProcessIdAndScopeFail;
