import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  OperatorOrganizationActionTypes,
  Remove,
  RemoveFail,
  RemoveSuccess,
  Save,
  SaveFail,
  SaveSuccess,
  UpdateStatus,
  UpdateStatusFail,
  UpdateStatusSuccess
} from './organization.actions';
import * as model from './organization';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {OperatorOrganizationService} from './organization.service';

@Injectable()
export class OperatorOrganizationEffects {
  @Effect()
  create$ = this.actions.pipe(
    ofType(OperatorOrganizationActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.params).pipe(
        first(),
        concatMap((organization: model.Operator.Organization) => {
          return [new CreateSuccess(organization)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  );

  @Effect()
  update$ = this.actions.pipe(
    ofType(OperatorOrganizationActionTypes.Save),
    switchMap((action: Save) => {
      return this._svc.update(action.organization).pipe(
        first(),
        switchMap((organization: model.Operator.Organization) => {
          return [new SaveSuccess(organization)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveFail(err));
        }));
    })
  );

  @Effect()
  loadOne$ = this.actions.pipe(
    ofType(OperatorOrganizationActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((organization: model.Operator.Organization) => {
          return [new LoadOneSuccess(organization)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  );

  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(OperatorOrganizationActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.userId).pipe(
        first(),
        concatMap((res: model.Operator.Organization[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  remove$ = this.actions.pipe(
    ofType(OperatorOrganizationActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.destroy(action.id).pipe(
        first(),
        concatMap((item: model.Operator.Organization) => {
          return [new RemoveSuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  );

  @Effect()
  updateStatus$ = this.actions.pipe(
    ofType(OperatorOrganizationActionTypes.UpdateStatus),
    switchMap((action: UpdateStatus) => {
      return this._svc.updateStatus(action.id, action.status).pipe(
        first(),
        concatMap((item: model.Operator.Organization) => {
          return [new UpdateStatusSuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new UpdateStatusFail(err));
        }));
    })
  );

  constructor(private actions: Actions,
              private _svc: OperatorOrganizationService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
