import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {EnvService} from 'app/shared/modules/api-resource/services/env.service';
import {UploadUtils} from 'app/lib/upload_utils';
import {AngularTokenService} from 'angular-token';
import {ArtifactPublicUpload} from '../process-artifact/process-artifact';
import {ArtifactPublicUploadBuilder} from '../process-artifact/process-artifact.builder';
import {map} from 'rxjs/operators';
import {IApiResponse} from 'app/shared/modules/api-resource/models/api.interface';
import {Observable} from "rxjs";

export interface DocumentData {
  id: string,
  metadata: {
    filename: string,
    size: number,
    mime_type: string
  }
}

/**
 * TUS Service to finalize chunked file after upload and to attach it to a process.
 */
@Injectable()
export class ResumableUploadService {
  basePath;
  authHeaders: any;

  constructor(private _http: HttpClient, private _env: EnvService, private _tokenSvc: AngularTokenService) {
    this.basePath = this._env.resumableUploadApiBase();
    this.authHeaders = UploadUtils.authHeadersObj(this._tokenSvc.currentAuthData);
  }

  /**
   * Finalizes a TUS upload and creates a file attached to the processId
   * @param processId Process ID of the file.
   * @param document_data TUS document data, needed to identify the uploaded file (of chunks).
   */
  upload(processId, document_data: DocumentData): Observable<ArtifactPublicUpload> {
    const params = new HttpParams().set('document', JSON.stringify(document_data));
    const builder = new ArtifactPublicUploadBuilder();
    return this._http
      .post(`${this.basePath}/api/v3/workflow_engine/processes/${processId}/file_inbox/documents`, {}, {
        params,
        headers: this.authHeaders
      })
      .pipe(map((res: IApiResponse) => {
        return builder.fromResponse(res.data)
      }));
  }
}
