import * as contactFilterActions from '../actions/contacts-abc-filter.actions';

export type ContactABCFilterState = string;
export const initialState = '';

export function contactABCFilterReducer(state: string = initialState, action: contactFilterActions.Actions): string {
  switch (action.type) {
    case contactFilterActions.ABC_CONTACTS_FILTER:
      return action.payload;
    case contactFilterActions.RESET_ABC_CONTACT_FILTER:
      return initialState;
    default: {
      return state;
    }
  }
};

