import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './quickshare.state';

export const stateKey = 'quickshare';
const getQuickshareState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getQuickshareEntities,
  selectAll: getAllQuickshares,
} = adapter.getSelectors(getQuickshareState);

export const getSelected = createSelector(
  getQuickshareState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getQuickshareEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllQuickshares,
  (entities) => entities
);

export const loadingState = createSelector(
  getQuickshareState,
  (state) => state.loading
);
