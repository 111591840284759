export class VersionHistoryItem {
  changeset: any[] = [];

  constructor(public id, public author: string, public createdAt: Date) {}
}

export class VersionHistoryItemBuilder {
  fromResponse(response) {
    if (!response) {
      return [];
    }
    return response.map(item => {
      const version = new VersionHistoryItem(item.id, item.attributes.author, item.attributes.created_at);
      return this._createChangeset(version, item.attributes.changeset);
    });
  }

  private _createChangeset(version, changeset): VersionHistoryItem {
    const keys = Object.keys(changeset);
    for (const key of keys) {
      if (!version.author && key === 'last_updated_by_email') {
        version.author = changeset[key][1];
      }

      if (key === 'last_updated_by_email') continue;

      const previousValue = changeset[key][0];
      const nextValue = changeset[key][1];
      if (previousValue || nextValue) {
        version.changeset.push({ key: key, from: previousValue, to: nextValue });
      }
    }
    return version;
  }
}
