import {Component, OnDestroy, OnInit} from '@angular/core';
import {first} from 'rxjs/operators';
import {BehaviorSubject, Subject} from 'rxjs';
import {TwoFactorAuthService} from 'app/+store/two-factor-auth/two-factor-auth.service';
import {IAuthorizationCode} from 'app/+store/two-factor-auth/two-factor-auth.interface';
import {AngularTokenService} from 'angular-token';
import {UntypedFormGroup} from '@angular/forms';
import {Organization} from 'app/models/organization.model';
import {ITabNavRoute} from 'app/five-f/organization-card/models/tab-nav-route.interface';


@Component({
  selector: 'dvtx-mobile-login-container',
  templateUrl: './mobile-login-container.component.html',
  styleUrls: ['./mobile-login-container.component.scss']
})
export class MobileLoginContainerComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();
  public authorizationCode$ = new BehaviorSubject<IAuthorizationCode>(null);
  public email: string;
  public routes: ITabNavRoute[];
  public activeLink: string;
  public form: UntypedFormGroup;
  public organization: Organization;

  constructor(private _authSvc: TwoFactorAuthService, private _tokenSvc: AngularTokenService) {}

  ngOnInit() {
    this.routes = [{
      title: 'MOBILE_LOGIN.TITLE',
      route: '/user-profile/mobile/mobile-login'
    }];
    this.activeLink = this.routes[0].title
    this.email = this._tokenSvc.currentAuthData.uid;
    this._authSvc.currentAuthCode()
      .pipe(first())
      .subscribe(authCode => {
        this.authorizationCode$.next(authCode);
      }, err => {
        console.error(err);
      });
  }

  public refreshCode() {
    this._authSvc.refreshAuthCode()
      .pipe(first())
      .subscribe(authCode => {
        this.authorizationCode$.next(authCode);
      }, err => {
        console.error(err);
      });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public getQrCodeValue(): string {
    // the email combined with the authorization code is the value of the QR code
    return `${this.email} ${this.authorizationCode$.value.code}`;
  }

}
