import { Action } from '@ngrx/store';

export const SET_CURRENT_CONTACT = 'SET_CURRENT_CONTACT';
export const CLEAR_CURRENT_CONTACT = 'CLEAR_CURRENT_CONTACT';
export const GET_CONTACT_MEMBERS = 'GET_CONTACT_MEMBERS';
export const GET_CONTACT_MEMBERS_SUCCESS = 'GET_CONTACT_MEMBERS_SUCCESS';



export class GetContactMembers implements Action {
  readonly type: string = GET_CONTACT_MEMBERS;

  constructor(public payload: any) {}
}

export class GetContactMembersSuccess implements Action {
  readonly type: string = GET_CONTACT_MEMBERS_SUCCESS;

  constructor(public payload: any) {}
}


export class SetCurrentContact implements Action {
  readonly type: string = SET_CURRENT_CONTACT;

  constructor(public payload: any) {}
}

export class ClearCurrentContact implements Action {
  readonly type: string = CLEAR_CURRENT_CONTACT;

  constructor(public payload: any = null) {}
}

export type Actions = SetCurrentContact | ClearCurrentContact | GetContactMembers | GetContactMembersSuccess;
