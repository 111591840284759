export class VerifiedUser {
  readonly type = 'user_verified_accounts';

  constructor(public id: string, public avatar: string = null, public hasRegisteredAccount: boolean = false, public hasTwoFactorEnabled = false) {}
}

export class OptIn {
  readonly type = 'auth_opt_ins';

  constructor(public id, public processId, public email, public name: string, public confirmedAt: Date) {
  }
}
