import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {OperatorUserEffects} from './user.effects';
import {reducer} from './user.reducer';
import {stateKey} from './user.selectors';
import {OperatorUserService} from './user.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([OperatorUserEffects])
  ],
  providers: [
    OperatorUserEffects,
    OperatorUserService
  ]
})
export class OperatorUserStoreModule {
}
