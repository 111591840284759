import {adapter, initialState, State} from './kanban-item.state';
import {KanbanItemActions, KanbanItemActionTypes} from './kanban-item.actions';

export function reducer(state = initialState, action: KanbanItemActions): State {
  const selectedKanban = state.selected;

  switch (action.type) {
    case KanbanItemActionTypes.LoadAll:
    case KanbanItemActionTypes.LoadOne:
      return {
        ...state,
        selected: null,
        loading: true
      };

    case KanbanItemActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.kanbanItems, {
        ...state,
        selected: selectedKanban ? state.entities[selectedKanban.id] : null,
        loading: false,
      });

    case KanbanItemActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.kanbanItem, {
        ...state,
        selected: action.kanbanItem,
        loading: false
      });

    case KanbanItemActionTypes.LoadAllFail:
    case KanbanItemActionTypes.LoadOneFail:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case KanbanItemActionTypes.Create:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case KanbanItemActionTypes.CreateSuccess:
      return adapter.upsertOne(action.kanbanItem, {
        ...state,
        selected: action.kanbanItem
      });

    case KanbanItemActionTypes.CreateFail:
      return {
        ...state
      };

    case KanbanItemActionTypes.Remove:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case KanbanItemActionTypes.RemoveSuccess:
      return adapter.removeOne(action.id, {
        ...state
      });

    case KanbanItemActionTypes.Reset:
      return adapter.removeAll({
        ...state
      });

    case KanbanItemActionTypes.AddLabel:
    case KanbanItemActionTypes.RemoveLabel:
    case KanbanItemActionTypes.UpdateGroup:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case KanbanItemActionTypes.AddLabelSuccess:
    case KanbanItemActionTypes.RemoveLabelSuccess:
    case KanbanItemActionTypes.UpdateGroupSuccess:
      return adapter.upsertOne(action.kanbanItem, {
        ...state
      });

    case KanbanItemActionTypes.AddLabelFail:
    case KanbanItemActionTypes.RemoveLabelFail:
    case KanbanItemActionTypes.UpdateGroupFail:
      return {
        ...state
      };
  }
  return state;
}
