<ng-template #callToActionRef>
  <div class="d-flex">
    <button *ngIf="!readonly && !editmode"
            (click)="editmode = true;"
            mat-raised-button
            color="primary"
            class="ml-2">
      <mat-icon matPrefix>edit</mat-icon>
      {{ 'GENERAL.EDIT_ACTION' | translate}}
    </button>

    <button *ngIf="!readonly && editmode"
            mat-button
            (click)="cancel()"
            class="ml-2">{{ 'GENERAL.CANCEL_ACTION' | translate}}</button>

    <button *ngIf="!readonly && editmode"
            mat-raised-button
            (click)="save()"
            color="primary"
            class="ml-2">
      <mat-icon matPrefix>save</mat-icon>
      {{ 'GENERAL.APPLY_CHANGE_ACTION' | translate}}
    </button>
  </div>
</ng-template>

<dvtx-settings-header [routes]="routes"
                      [activeLink]="activeLink"
                      [toOverview]="true"
                      [callToActionPortal]="callToActionPortal"
                      [title]="'ORGANIZATION.MASTER_DATA'"></dvtx-settings-header>

<dvtx-organization-master-data class="five-f-settings-container pt-2"
                               #masterDataComponent
                               [editmode]="editmode"
                               [readonly]="!(myMembership | async)?.hasAdministrationRights"></dvtx-organization-master-data>
