import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CollectorConfirmationEffects} from './collector-confirmation.effects';
import {CollectorConfirmationService} from './collector-confirmation.service';
import {reducer} from './collector-confirmation.reducer';
import {stateKey} from './collector-confirmation.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([CollectorConfirmationEffects])
  ],
  providers: [
    CollectorConfirmationEffects,
    CollectorConfirmationService
  ]
})
export class CollectorConfirmationStoreModule {
}
