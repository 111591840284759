import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './cms-blog.state';
import {OrganizationSelectors} from 'app/+store/organization';

export const stateKey = 'cms-blog';
const getCmsBlogState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getCmsBlogEntities,
  selectAll: getAllCmsBlogs,
} = adapter.getSelectors(getCmsBlogState);

export const getCmsBlogOfSelectedOrg = createSelector(
  OrganizationSelectors.getSelectedId,
  getAllCmsBlogs,
  (orgId, cmsBlogs) => cmsBlogs.find(blog => orgId && blog.id === orgId)
);

export const loadingState = createSelector(
  getCmsBlogState,
  (state) => state.loading
);

export const getCmsBlogById = (id: string) => createSelector(
  getCmsBlogEntities,
  (cmsBlogs) => cmsBlogs[id]
);
