import {adapter, initialState, State} from './label.state';
import {LabelActions, LabelActionTypes} from './label.actions';

export function reducer(state = initialState, action: LabelActions): State {
  const selectedLabel = state.selected;

  switch (action.type) {
    case LabelActionTypes.LoadAll:
    case LabelActionTypes.LoadOne:
      return {
        ...state,
        selected: null,
        loading: true
      };

    case LabelActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.labels, {
        ...state,
        selected: selectedLabel ? state.entities[selectedLabel.id] : null,
        loading: false,
      });

    case LabelActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case LabelActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.label, {
        ...state,
        selected: action.label,
        loading: false
      });

    case LabelActionTypes.LoadOneFail:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case LabelActionTypes.Create:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case LabelActionTypes.CreateSuccess:
      return adapter.addOne(action.label, {
        ...state,
        selected: action.label
      });

    case LabelActionTypes.Edit:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case LabelActionTypes.EditSuccess:
      return adapter.upsertOne(action.label, {
        ...state,
        selected: action.label
      });

    case LabelActionTypes.EditFail:
      return {
        ...state
      };

    case LabelActionTypes.Remove:
      return {
        ...state,
        selected: null,
        loading: false
      };

    case LabelActionTypes.RemoveSuccess:
      return adapter.removeOne(action.id, {
        ...state
      });

    case LabelActionTypes.CreateFail:
      return {
        ...state
      };

    case LabelActionTypes.Reset:
      return adapter.removeAll(state);
  }
  return state;
}
