<mat-tree #tree
          [dataSource]="nestedDataSource"
          [treeControl]="nestedTreeControl"
          class="dvtx-process-tree dvtx-project-structure">
  <mat-nested-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <li class="dvtx-leaf-node">
      <div class="dvtx-leaf-node-label">
        <dvtx-process-tree-node [node]="node" (onSelect)="selectNode(node)" [noTitlePlaceholder]="noTitlePlaceholder"></dvtx-process-tree-node>
      </div>
    </li>
  </mat-nested-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <li>
      <div class="dvtx-expand-item d-flex align-items-center">
        <div class="dvtx-expand-toggle">
          <a matTreeNodeToggle (click)="$event.preventDefault(); onToggleNodeExpanded(node)">
            <mat-icon>
              {{expanderIcon(node)}}
            </mat-icon>
          </a>
        </div>
        <div class="dvtx-inner-node">
          <div class="dvtx-inner-node-label">
            <dvtx-process-tree-node matTreeNodeToggle [node]="node" (onSelect)="onToggleNodeExpanded(node); selectNode(node)" [noTitlePlaceholder]="noTitlePlaceholder"></dvtx-process-tree-node>
          </div>
        </div>
      </div>
      <ul [hidden]="!isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
