<dvtx-page-header #mainHeader [error]="null" [userSessionEnabled]="false"></dvtx-page-header>

<div class="dvtx-app" style="display: flex;">
  <div class="container-fluid dvtx-app-content dvtx-bg-color"
       id="dvtx-app-content">
    <div class="dvtx-app-content__router-outlet">
      <!-- On error: maintenance info -->
      <ng-template [ngIf]="error" [ngIfElse]="sessionTpl">
        <ng-template [ngIf]="error?.error?.data?.attributes" [ngIfElse]="generalErrorTpl">
          <div class="w-100 h-100 d-flex justify-content-center"
               style="margin: auto auto"
               *ngIf="error">
            <div style="width: 600px;">
              <ng-container [ngSwitch]="lang">
                <ng-container *ngSwitchCase="'de'">
                  <dvtx-info-icon-message-block [message]="error?.error?.data?.attributes?.message_de"
                                                [icon]="error?.error?.data?.attributes?.icon"
                                                [size]="'lg'"></dvtx-info-icon-message-block>
                </ng-container>
                <ng-container *ngSwitchCase="'en'">
                  <dvtx-info-icon-message-block [message]="error?.error?.data?.attributes?.message_en"
                                                [icon]="error?.error?.data?.attributes?.icon"
                                                [size]="'lg'"></dvtx-info-icon-message-block>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-template>
        <ng-template #generalErrorTpl>
          <div class="w-100 h-100 d-flex justify-content-center"
               style="margin: auto auto"
               *ngIf="error">
            <div style="width: 600px;">
              <ng-container [ngSwitch]="lang">
                <ng-container *ngSwitchCase="'de'">
                  <dvtx-info-icon-message-block [message]="'Die Verbindung zur Plattform ist unterbrochen. Bitte überprüfen Sie Ihre Internetverbindung.'"
                                                [icon]="'wifi_off'"
                                                [size]="'lg'"></dvtx-info-icon-message-block>
                </ng-container>
                <ng-container *ngSwitchCase="'en'">
                  <dvtx-info-icon-message-block [message]="'The connection to the platform is interrupted. Please check your internet connection.'"
                                                [icon]="'wifi_off'"
                                                [size]="'lg'"></dvtx-info-icon-message-block>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </ng-template>
      <ng-template #sessionTpl>
        <router-outlet></router-outlet>
      </ng-template>
    </div>
  </div>
</div>
