import {IMotd} from './motd.interface';

export class Motd implements IMotd {
  readonly type = 'cms_motds';

  constructor(public id,
              public title,
              public subtitle,
              public content,
              public authorEmail,
              public publishedAt = null,
              public createdAt = null,
              public updatedAt = null) {
  }

  toForm() {
    return {
      id: this.id,
      title: this.title,
      subtitle: this.subtitle,
      content: this.content,
      published: !!this.publishedAt
    };
  }
}
