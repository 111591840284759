import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {AuthPasswordService} from './auth-password.service';
import {reducer} from './auth-password.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('auth-password', reducer),
  ],
  providers: [
    AuthPasswordService
  ]
})
export class AuthPasswordStoreModule {
}

