<div class="mat-dialog-inner-wrap">
  <div class="mat-dialog-header dvtx-bg-color dvtx-bg-cool-grey-25">
    <h2 mat-dialog-title>{{ form.get('title').value }}</h2>
    <button class="mat-dialog-close" mat-mini-fab mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <dvtx-quick-share-form [form]="form" [contacts]="contacts$ | async" ></dvtx-quick-share-form>
    <dvtx-quick-upload [uploader]="uploader" [form]="form" (onRemove)="removeFile($event)" (onSubmit)="submit()"></dvtx-quick-upload>
  </div>

  <div class="mat-dialog-footer">
    <mat-dialog-actions class="d-flex justify-content-end">
      <button type="reset" mat-button color="warn" class="wide" mat-dialog-close>{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
      <button type="submit" (click)="submit()"
              [disabled]="form.pristine || !uploader.getNotUploadedItems().length || getInvalidFiles().length > 0 || !form.valid"
              mat-raised-button color="primary" class="wide">{{ 'QUICKSHARE.SHARE_DOCUMENTS_TOOLTIP' | translate }}</button> &nbsp;
    </mat-dialog-actions>
  </div>
</div>
