<div [ngClass]="showEmailType ? 'row' : ''">
  <div class="col-12 d-flex align-items-start flex-column" [ngClass]="showEmailType ? 'col-md-8' : 'col-md-10'"
  [ngStyle]="!showEmailType && {'padding-right' : '0px'}">
    <mat-form-field class="email-address" style="min-width: 250px;">
      <input
        matInput
        [placeholder]="'CONTACTS.EMAIL-TABLE-HEADER' | translate"
        [(ngModel)]="innerValue.emailAddress"
        (ngModelChange)="notifyChange()"
        [errorStateMatcher]="errorStateMatcher"
        [disabled]="isDisabled"
        [required]="requiredField"
        email
      >
    </mat-form-field>
    <div style="max-width: 300px;">
      <ng-container *ngFor="let err of errorMapper">
        <mat-error *ngIf="dirty && errors && errors[err.error]">
          {{err.message}}
        </mat-error>
      </ng-container>
    </div>
  </div>

  <div *ngIf="showEmailType" class="col-12 col-md-4 d-flex align-items-start">
    <dvtx-api-select-cva
      class="type-or-location"
      [elementName]="'ORGANIZATION.CREATION.TYPE' | translate"
      listingPath="name"
      listingType="typed_email-type"
      [(ngModel)]="innerValue.locationOrType"
      (ngModelChange)="notifyChange()"
      [isDisabled]="isDisabled"
    >
    </dvtx-api-select-cva>
  </div>
</div>
