
<div class="md-container">
  <div class="default-view card">

    <div class="default-view-header dvtx-bg-color dvtx-bg-cool-grey-25">
      <p class="default-view-header-title">{{ headline }}</p>
    </div>

    <div class="default-view-content">
      <div class="default-view-content-block">
        <p><strong>{{name}}</strong></p>

        <p style="margin-top: 40px;">
          zur Durchführung unserer Prüfung des Jahresabschlusses der {{organizationName}} zum {{businessYear | date}} bitten wir Sie, uns die nachfolgend aufgeführten Unterlagen zur Verfügung zu stellen.
        </p>
        <p>
          Bitte nutzen Sie hierfür ausschließlich diese Plattform. Sie haben während der gesamten Prüfung Zugriff auf die von Ihnen zur Verfügung gestellten Unterlagen.
        </p>
        <p>
          Sollten Sie selbst nicht zu allen Bereichen die angeforderten Unterlagen bereitstellen können, bitte wir Sie, die entsprechenden Ansprechpartner nachfolgend auszuwählen. Diese erhalten anschließend einen eigenen Link mit der Bitte um Bereitstellung der Unterlagen.
        </p>
        <p style="margin-top: 40px;">
          Mit freundlichen Grüßen
          <br>
          {{ auditorName }}
        </p>

        <p style="margin-top: 40px;">
          <span class="label" *ngIf="auditorPhone">Tel:</span> {{auditorPhone ? auditorPhone : ''}}
          <br>
          <span class="label" *ngIf="auditorEmail">Email:</span> {{ auditorEmail ? auditorEmail : '' }}
        </p>
      </div>
    </div>

    <div class="default-view-footer">
      <button mat-button color="primary" type="button" (click)="onSubmit.emit()">
        Zur Anforderungsliste
      </button>
    </div>
  </div>

</div>
