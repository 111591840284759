import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ProcessAction} from './process-action';

export interface State extends EntityState<ProcessAction> {
  loading: boolean;
}

export const adapter = createEntityAdapter<ProcessAction>();

export const initialState: State = adapter.getInitialState({
  loading: false
});
