import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Invitation} from 'app/+store/invitation/invitation.model';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {InvitationBuilder} from './invitation.builder';

@Injectable()
export class InvitationService {
  readonly BASE_PATH = 'api/v1/users/invitations';
  basePath;
  builder: InvitationBuilder;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
    this.builder = new InvitationBuilder();
  }

  getAll(received = true): Observable<Invitation[]> {
    return this._http.get(`${this.basePath}?received=${received}`).pipe(map((response: { data: any }) => response.data),
      map(data => data.map(item => this.builder.fromResponse(item))),);
  }

  accept(id): Observable<Invitation> {
    const payload = { data: { attributes: { accepted: true } } };
    return this._http.post(`${this.basePath}/${id}/accept`, payload).pipe(
      map((response: { data: any }) => response.data),
      map(item => this.builder.fromResponse(item)),);
  }

  decline(id): Observable<Invitation> {
    const payload = { data: { attributes: { declined: true } } };
    return this._http.post(`${this.basePath}/${id}/decline`, payload).pipe(
      map((response: { data: any }) => response.data),
      map(item => this.builder.fromResponse(item)),);
  }

  Destroy(id): Observable<Invitation> {
    return this._http.delete(`${this.basePath}/${id}`).pipe(
      map((response: { data: any }) => response.data))
  }
}
