import {AuditAuditorCommunications} from '../models/auditAuditorCommunications';
import {ContactImportItemBuilder} from './contact-import-item.builder';
import {Appendix} from '../models/appendix';
import {AppendixBuilder} from './appendix.builder';
import {AuditAuditorContributions} from '../models/auditAuditorContributions';
import {ProcessArtifactBuilder} from 'app/+store/process-artifact/process-artifact.builder';

export class AuditAuditorContributionsBuilder {
  public static fromResponse(data): AuditAuditorContributions {
    const obj =  new AuditAuditorContributions();
    obj.id = data['id'];
    obj.artifacts = (data['attributes']['artifacts'] || []).map((artifact) => new ProcessArtifactBuilder(obj.id).fromResponse(artifact.data));
    obj.contactEntries = ContactImportItemBuilder.fromResponseList(data['attributes']['contact_entries']);
    return obj;
  }

  public static toRequest(entry: AuditAuditorContributions): {} {
    return {
      data: {
        type: 'audit_component_auditor_contributions',
        id: entry.id,
        attributes: {
          contact_entries: ContactImportItemBuilder.toDependentRequestList(entry.contactEntries),
        }
      }
    }
  }
}

