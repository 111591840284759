import { IResource } from "./resource.interface";

export class UserTwoFactorWhitelist implements IUserTwoFactorWhitelist {
  readonly type = 'tfa_whitelisted_accounts';

  constructor(public id: string,
    public firstName: string,
    public lastName: string,
    public createdAt: Date,
    public email: string) {
  }
}

export interface IUserTwoFactorWhitelist extends IResource {
  id: string;
  firstName: string;
  lastName: string;
  createdAt: Date;
  email: string;
}
