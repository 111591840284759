import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {
  MessageSettingsV2Builder,
  NotificationProfileBuilder,
  TimeZoneBuilder
} from './message-settings.builder';
import {MessageSettingsV2, NotificationProfile, TimeZone} from './message-settings';

@Injectable()
export class MessageSettingsService {
  readonly BASE_PATH = 'api/v1/settings/notifications';
  readonly BASE_PATH_V3 = 'api/v3/settings/notifications';
  readonly TIME_ZONE_BASE_PATH = 'api/v1/settings/time_zones';
  readonly NOTIFICATION_PROFILES_BASE_PATH = 'api/v1/settings/notification_profiles';

  constructor(private _http: ApiResourceService) {
  }

  getGlobalSettings(): Observable<MessageSettingsV2> {
    const builder = new MessageSettingsV2Builder();
    return <Observable<MessageSettingsV2>>this._http.get<MessageSettingsV2Builder, MessageSettingsV2>(builder, this.BASE_PATH_V3);
  }

  applyGlobalSettingsToAllProcesses(setting: MessageSettingsV2): Observable<MessageSettingsV2> {
    const builder = new MessageSettingsV2Builder();
    const payload = builder.toRequest(setting);
    return <Observable<MessageSettingsV2>>this._http.post<MessageSettingsV2Builder, MessageSettingsV2>(builder, `${this.BASE_PATH_V3}/apply_to_all`, payload);
  }

  resetProcessesToGlobalSettings(): Observable<MessageSettingsV2> {
    const builder = new MessageSettingsV2Builder();
    return <Observable<MessageSettingsV2>>this._http.post<MessageSettingsV2Builder, MessageSettingsV2>(builder, `${this.BASE_PATH_V3}/reset_to_global`, {});
  }

  updateGlobalSettings(setting: MessageSettingsV2): Observable<MessageSettingsV2> {
    const builder = new MessageSettingsV2Builder();
    const payload = builder.toRequest(setting);
    return this._http.put<MessageSettingsV2Builder, MessageSettingsV2>(builder, `${this.BASE_PATH_V3}`, payload);
  }

  getTimeZones(): Observable<TimeZone[]> {
    const builder = new TimeZoneBuilder();
    return <Observable<TimeZone[]>>this._http.get<TimeZoneBuilder, TimeZone>(builder, this.TIME_ZONE_BASE_PATH);
  }

  getTimeZone(): Observable<TimeZone> {
    const builder = new TimeZoneBuilder();
    return <Observable<TimeZone>>this._http.get<TimeZoneBuilder, TimeZone>(builder, `${this.TIME_ZONE_BASE_PATH}/current`);
  }

  setTimeZone(zone: TimeZone): Observable<TimeZone> {
    const builder = new TimeZoneBuilder();
    const payload = builder.toRequest(zone);
    return <Observable<TimeZone>>this._http.put<TimeZoneBuilder, TimeZone>(builder, `${this.TIME_ZONE_BASE_PATH}/current`, payload);
  }

  getNotificationProfile(): Observable<NotificationProfile> {
    const builder = new NotificationProfileBuilder();
    return <Observable<NotificationProfile>>this._http.get<NotificationProfileBuilder, NotificationProfile>(builder, `${this.NOTIFICATION_PROFILES_BASE_PATH}/current`);
  }

  setNotificationProfile(profile: NotificationProfile): Observable<NotificationProfile> {
    const builder = new NotificationProfileBuilder();
    const payload = builder.toRequest(profile);
    return <Observable<NotificationProfile>>this._http.put<NotificationProfileBuilder, NotificationProfile>(builder, `${this.NOTIFICATION_PROFILES_BASE_PATH}/current`, payload);
  }
}
