<div class="mat-dialog-inner-wrap" *ngIf="!!personContact">
  <!-- <div class="mat-dialog-header">
    <div mat-dialog-title class="pt-2">
      <h2 style="margin-bottom: 0">{{ 'CONTACTS.EDIT_PERSON_TITLE' | translate }}</h2>

    </div>

    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div> -->

  <div>
    <div *ngIf="invitationStatus === InvitationStatus.Pending
    || invitationStatus === InvitationStatus.Declined
    || invitationStatus === InvitationStatus.Accepted"
       style="font-size: 12px;">
    <div>
      <span class="greyTextColor">  {{'ADDRESSBOOK.INVITED_AT' | translate }}: </span>
      {{invitationDate | date:'medium'}} &nbsp;•&nbsp;
      <span class="greyTextColor">  {{'GENERAL.STATUS' | translate }}: </span>
      <ng-container [ngSwitch]="invitationStatus">
        <span *ngSwitchCase="InvitationStatus.Accepted">{{ 'INVITATIONS.ACCEPTED' | translate }}</span>
        <span *ngSwitchCase="InvitationStatus.Declined">{{ 'INVITATIONS.DECLINED' | translate }}</span>
        <span *ngSwitchCase="InvitationStatus.Pending">{{ 'INVITATIONS.PENDING' | translate }}</span>
      </ng-container>
    </div>
  </div>
    <div *ngIf="(administrationRights | async).hasAdministrationRights === false && (currentUser | async).email !== createdBy">
      <dvtx-alert [icon]="'info'" [color]="'info'" [text]="'ADDRESSBOOK.NEED_ADMIN_PRIVILEGES' | translate"></dvtx-alert>
    </div>
    <mat-accordion displayMode="flat" multi>
      <mat-expansion-panel hideToggle expanded [disabled]="true" class="mat-elevation-z0 px-3 px-lg-5">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'CONTACTS.SET_VISIBILITY_FOR_INFORMATION' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <dvtx-contact-visibility-chooser
          [(ngModel)]="personContact.contactVisibility"
          [isDisabled]="!editmode"
        ></dvtx-contact-visibility-chooser>
      </mat-expansion-panel>
      <mat-expansion-panel expanded class="mat-elevation-z0 px-3 px-lg-5">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'CONTACTS.GENERAL_DATA' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <dvtx-create-contact-person-dialog
          [isDisabled]="!editmode"
          [(ngModel)]="personContactForm"
          [displayFurtherDataFields]="true"
          title=""
        ></dvtx-create-contact-person-dialog>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div style="position: absolute;bottom: 0%" mat-dialog-actions class="col-12 d-flex justify-content-end">
    <div class="d-flex justify-content-end align-items-center pr-2">
      <div *ngIf="(administrationRights | async)?.hasAdministrationRights && (featureSet$ | async)?.hasPartnerLinks" class="mr-4">
        <mat-checkbox [checked]="!!partnerLinkId"
                      [labelPosition]="'before'"
                      (change)="authorizePartner($event)">
          {{ (!!partnerLinkId ? 'PARTNER_LINK.DEACTIVATE_PARTNERLINKS' : 'PARTNER_LINK.ACTIVATE_PARTNERLINKS') | translate }}
        </mat-checkbox>
      </div>
      <button *ngIf="!editmode && ((administrationRights | async).hasAdministrationRights || (currentUser | async).email === createdBy)" (click)="editmode = true;"
              mat-raised-button>
        <mat-icon matAffix>edit</mat-icon>
        {{ 'GENERAL.EDIT_ACTION' | translate }}
      </button>
      <button *ngIf="editmode" [disabled]="!personContactForm.isValid" mat-raised-button
              (click)="saveData()">
        <mat-icon matAffix>save</mat-icon>
        {{ 'GENERAL.SAVE_ACTION' | translate }}
      </button>
      <button
        *ngIf="invitationStatus === null"
        mat-raised-button
        (click)="sendMemberInvitation()"
        [disabled]="invitationHasSent">
        <mat-icon matAffix>send</mat-icon>{{ 'INVITATIONS.INVITE' | translate }}</button>
      <button
        *ngIf="invitationStatus === InvitationStatus.Pending"
        mat-raised-button
        (click)="sendMemberInvitation()">
        <mat-icon matAffix>send</mat-icon>
        {{ 'INVITATIONS.RESEND_INVITATION' | translate }}</button>
        <button mat-dialog-close mat-raised-button color="primary">{{'GENERAL.CLOSE_ACTION' | translate}}</button>
    </div>
  </div>
</div>
