import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'dvtx-quick-upload-success-dialog-content',
  templateUrl: './quick-upload-dialog-success-content.component.html',
  styleUrls: ['./quick-upload-dialog-success-content.component.scss']
})
export class QuickUploadSuccessDialogContentComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
