
import {takeUntil} from 'rxjs/operators';
/**
 * Provides the labels for the pagination.
 *
 * Reference:
 * - https://github.com/angular/material2/issues/5573
 */
import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl implements OnDestroy {

  unsubscribe: Subject<void> = new Subject<void>();
  OF_LABEL = 'of';

  constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getAndInitTranslations() {
    this.translate.get([
      'GENERAL.ITEMS_PER_PAGE',
      'GENERAL.NEXT_PAGE',
      'GENERAL.PREVIOUS_PAGE',
      'GENERAL.OF_LABEL'
    ]).pipe(
      takeUntil(this.unsubscribe))
      .subscribe(translation => {
        this.itemsPerPageLabel = translation['GENERAL.ITEMS_PER_PAGE'];
        this.nextPageLabel = translation['GENERAL.NEXT_PAGE'];
        this.previousPageLabel = translation['GENERAL.PREVIOUS_PAGE'];
        this.OF_LABEL = translation['GENERAL.OF_LABEL'];
        this.changes.next();
      });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.OF_LABEL} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.OF_LABEL} ${length}`;
  };
}
