import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { LicenceControlService } from 'app/shared/services/licence-control.service';

@Component({
  selector: 'dvtx-navigation-link',
  templateUrl: './navigation-link.component.html',
  styleUrls: ['./navigation-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationLinkComponent implements OnInit {
  @Input() icon;
  @Input() callback;
  @Input() count;
  @Input() infoLevel;
  @Input() title;
  @Input() path;
  _showOnlyIcons: boolean = false;
  @Input () set showOnlyIcons(value) {
    this._showOnlyIcons = value;
  };
  constructor(private licenceControl: LicenceControlService) { }

  ngOnInit() {
  }

  checkLicense() {
    if (this.infoLevel === 'customizationSettingsRoute') {
      this.licenceControl.hasRequiredLicence('Professional', true)
    }
  }

}
