import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dvtx-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent {
  showDropdown = false;
  _colors = [];

  @Input() execluded = [];
  @Input() selected = null;
  @Input() set colors(colors: string[]) {
    if (colors) {
      this._colors = colors;
    }
  }
  get colors() {
    return this._colors;
  }

  @Output() onSelection: EventEmitter<any> = new EventEmitter<any>(null);

  selectColor($event) {
    this.onSelection.emit($event);
    this.showDropdown = false;
  }
}
