import {Action} from '@ngrx/store';
import {DatevClient} from './datev-client';

export enum DatevClientActionTypes {
  LoadAll = '[DatevClient] Load All',
  LoadAllSuccess = '[DatevClient] Load All Success',
  LoadAllFail = '[DatevClient] Load All Fail',
  SyncAll = '[DatevClient] Sync All',
  SyncAllSuccess = '[DatevClient] Sync All Success',
  SyncAllFail = '[DatevClient] Sync All Fail',
  SyncOne = '[DatevClient] Sync One',
  SyncOneSuccess = '[DatevClient] Sync One Success',
  SyncOneFail = '[DatevClient] Sync One Fail',

}

export class LoadAll implements Action {
  readonly type = DatevClientActionTypes.LoadAll;

  constructor() {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = DatevClientActionTypes.LoadAllSuccess;

  constructor(public clients: DatevClient[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = DatevClientActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class SyncAll implements Action {
  readonly type = DatevClientActionTypes.SyncAll;

  constructor() {
  }
}

export class SyncAllSuccess implements Action {
  readonly type = DatevClientActionTypes.SyncAllSuccess;

  constructor(public clients: DatevClient[]) {
  }
}

export class SyncAllFail implements Action {
  readonly type = DatevClientActionTypes.SyncAllFail;

  constructor(public payload: any) {
  }
}

export class SyncOne implements Action {
  readonly type = DatevClientActionTypes.SyncOne;

  constructor(public client: DatevClient) {}
}

export class SyncOneSuccess implements Action {
  readonly type = DatevClientActionTypes.SyncOneSuccess;

  constructor(public client: DatevClient) {
  }
}

export class SyncOneFail implements Action {
  readonly type = DatevClientActionTypes.SyncOneFail;

  constructor(public payload: any) {
  }
}


export type DatevClientActions =

  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | SyncAll
  | SyncAllSuccess
  | SyncAllFail
  | SyncOne
  | SyncOneSuccess
  | SyncOneFail;
