import {IFastdocsStatus, IFiveFStatus} from "./fastdocs.interface";

// FASTDOCS STATUSES
export enum FastdocsStatus {
  Open = 'open',
  ClientCompleted = 'client_completed',
  OfficeCompleted = 'office_completed',
  Completed = 'completed'
}

export enum FastdocsStatusTitle {
  Open = 'FASTDOCS.CLIENT_STARTED',
  ClientCompleted = 'FASTDOCS.EMPLOYEE_COMPLETED',
  OfficeCompleted = 'FASTDOCS.EXPORTED_AND_COMPLETED'
}

export const FastdocsStatusesMap = {
  Open: {
    id: FastdocsStatus.Open,
    title: FastdocsStatusTitle.Open,
    completed: true
  },
  ClientCompleted: {
    id: FastdocsStatus.ClientCompleted,
    title: FastdocsStatusTitle.ClientCompleted,
    completed: false
  },
  OfficeCompleted: {
    id: FastdocsStatus.OfficeCompleted,
    title: FastdocsStatusTitle.OfficeCompleted,
    completed: false
  }
};

export const FastdocsStatuses: IFastdocsStatus[] = [
  FastdocsStatusesMap.Open,
  FastdocsStatusesMap.ClientCompleted,
  FastdocsStatusesMap.OfficeCompleted
];

export const FastdocsStatusApiMap = {
  'initiated': FastdocsStatusesMap.Open,
  'client_completed': FastdocsStatusesMap.ClientCompleted,
  'office_completed': FastdocsStatusesMap.OfficeCompleted
}

// 5F STATUSES

export enum FiveFStatusTitle {
  Open = 'STATUS.OPEN',
  InProgress = 'STATUS.IN_PROGRESS',
  Closed = 'STATUS.CLOSED'
}

export const FiveFStatusIcon = {
  Open: 'radio_button_unchecked',
  InProgress: 'timelapse',
  Closed: 'check_circle'
};

export const FiveFStatusColor = {
  Open: 'ff8800',
  InProgress: '5bc4f1',
  Closed: '00ca5c'
};

export const FiveFStatusesMap: {[id: string]: IFiveFStatus} = {
  Open: {
    id: FiveFStatusTitle.Open,
    title: FiveFStatusTitle.Open,
    icon: FiveFStatusIcon.Open,
    color: FiveFStatusColor.Open
  },
  InProgress: {
    id: FiveFStatusTitle.InProgress,
    title: FiveFStatusTitle.InProgress,
    icon: FiveFStatusIcon.InProgress,
    color: FiveFStatusColor.InProgress
  },
  Closed: {
    id: FiveFStatusTitle.Closed,
    title: FiveFStatusTitle.Closed,
    icon: FiveFStatusIcon.Closed,
    color: FiveFStatusColor.Closed
  }
};

export const FiveFStatuses: IFiveFStatus[] = [
  FiveFStatusesMap.Open,
  FiveFStatusesMap.InProgress,
  FiveFStatusesMap.Closed
];

export const FiveFStatusApiMap = {
  'open': FiveFStatusesMap.Open,
  'in_progress': FiveFStatusesMap.InProgress,
  'closed': FiveFStatusesMap.Closed
}
