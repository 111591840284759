import {NgModule} from '@angular/core';
import {BaseFormElementsModule} from '../base-form-elements/base-form-elements.module';
import { H1EditableComponent } from './containers/h1-editable/h1-editable.component';
import {TranslateModule} from '@ngx-translate/core';
import { TitleEditableComponent } from 'app/shared/modules/content-editable/containers/title-editable/title-editable.component';
import { LineTruncationLibModule } from 'ngx-line-truncation';

const EXPORTED_COMPONENTS = [
  H1EditableComponent,
  TitleEditableComponent
];

@NgModule({
  imports: [
    BaseFormElementsModule,
    TranslateModule,
    LineTruncationLibModule
  ],
  declarations: [
    ...EXPORTED_COMPONENTS
  ],
  exports: [
    ...EXPORTED_COMPONENTS
  ]
})
export class ContentEditableModule {
}
