import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {QuickCollectorEffects} from './quickcollector.effects';
import {QuickCollectorService} from './quickcollector.service';
import {reducer} from './quickcollector.reducer';
import {stateKey} from './quickcollector.selectors';
import {CollectorUiChangesService} from './collector-ui-changes.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([QuickCollectorEffects])
  ],
  providers: [
    QuickCollectorEffects,
    QuickCollectorService,
    CollectorUiChangesService
  ]
})
export class QuickCollectorStoreModule {
}
