import {IBomDataNodeAttachment} from '../bom/bom-data-node.interface';

export class FileAttachment implements IBomDataNodeAttachment {
  readonly type = 'bom_tree_attachments';
  content = null;

  constructor(public id: string, public displayName, public filename, public description,
              public size, public mimeType, public uploadedBy, public fingerprint,
              public referenceId, public createdAt, public updatedAt) {}
}
