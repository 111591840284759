import {IProcessRole, IProcessRoleType, IProjectRoomAdmin} from './process-role.interface';

/**
 * <tt>ProcessRole</tt> represents a membership role of a participant
 * on a <tt>Process</tt>.
 *
 * Classical roles are creator, owner, admin, sender, recipient, contributor.
 */
export class ProcessRole implements IProcessRole {
  public type: IProcessRoleType = IProcessRoleType.Member;

  static MemberOfProcessOrganization = 'organization_membership';

  get id() {
    return this.name;
  }

  constructor(public name, public processId) {}

  /**
   * Worker are always members of the process' organization that have one
   * additional role: creator, owner,
   * @param symbols Symbolic names
   */
  static isWorker(symbols) {
    if (!symbols || !symbols.length || symbols.length === 0) {
      return false;
    }
    return symbols.includes(ProcessRole.MemberOfProcessOrganization)
      && (symbols.includes('admin')
        || symbols.includes('owner')
        || symbols.includes('creator')
        || symbols.includes('sender'));
  }
}

export class ProjectRoomAdmin implements IProjectRoomAdmin {
  readonly type = 'iam_role_project_room_admins';

  constructor(public id: string, public email: string) {
  }

}

