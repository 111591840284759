import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {FeatureEffects} from './feature.effects';
import {FeatureService} from './feature.service';
import {reducer} from './feature.reducer';
import {stateKey} from './feature.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([FeatureEffects])
  ],
  providers: [
    FeatureEffects,
    FeatureService
  ]
})
export class FeatureStoreModule {
}
