import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {WorkflowTemplateCategory} from "./workflow-template-category";
import {WorkflowTemplateCategoryService} from "./workflow-template-category.service";
import {
  Create,
  CreateFail,
  CreateSuccess,
  Delete,
  DeleteFail,
  DeleteSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  Update,
  UpdateFail,
  UpdateSuccess,
  WorkflowTemplateCategoryActionTypes
} from './workflow-template-category.actions';

@Injectable()
export class WorkflowTemplateCategoryEffects {
  @Effect()
  loadAll$ = this.actions.pipe(
    ofType(WorkflowTemplateCategoryActionTypes.LoadAll),
    switchMap((_action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        concatMap((res: WorkflowTemplateCategory[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  );

  @Effect()
  delete$ = this.actions.pipe(
    ofType(WorkflowTemplateCategoryActionTypes.Delete),
    switchMap((action: Delete) => {
      return this._svc.delete(action.id).pipe(
        first(),
        concatMap((category: WorkflowTemplateCategory) => {
          this._notifyService.success('WORKFLOW_TEMPLATE.CATEGORY_DELETED_SUCCESSFULLY')
          return [
            new DeleteSuccess(category)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new DeleteFail(err));
        }));
    })
  );

  @Effect()
  create$ = this.actions.pipe(
    ofType(WorkflowTemplateCategoryActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.payload).pipe(
        first(),
        concatMap((category: WorkflowTemplateCategory) => {
          this._notifyService.success('WORKFLOW_TEMPLATE.CATEGORY_CREATED_SUCCESSFULLY')
          return [
            new CreateSuccess(category)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  );

  @Effect()
  update$ = this.actions.pipe(
    ofType(WorkflowTemplateCategoryActionTypes.Update),
    switchMap((action: Update) => {
      return this._svc.update(action.id, action.payload).pipe(
        first(),
        concatMap((category: WorkflowTemplateCategory) => {
          this._notifyService.success('WORKFLOW_TEMPLATE.CATEGORY_UPDATED_SUCCESSFULLY')
          return [
            new UpdateSuccess(category)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new UpdateFail(err));
        }));
    })
  );


  constructor(private actions: Actions,
              private _svc: WorkflowTemplateCategoryService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




