
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProcessContextBuilder } from '../builders/process-context.builder';
import { ApiErrorBuilder } from '../builders/api-error.builder';




import {HttpErrorResponse, HttpParams, HttpClient} from '@angular/common/http';
import {ProcessContext} from '../models/process-context';
import { environment } from 'environments/environment';

declare const toastr: any;

@Injectable()
export class WorkflowEngineService {
  readonly BASE_PATH = 'api/v1/workflow_engine';
  basePath: string;

  constructor(private _http: HttpClient,
              private _contextBuilder: ProcessContextBuilder) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  /**
   * Selects the process depending on the workflow type and its
   * revision. <tt>select</tt> is expected to be invocated before
   * process creation.
   *
   * Returns the client URL of the implementing component and the
   * API URL for the process creation.
   *
   * @param type
   * @param revision
   * @returns {any}
   */
  
  select(type, revision = '0.1.0') {
    const payload = {
      data: {
        type: 'process-selection',
        attributes: {
          type: type,
          version: revision
        }
      }
    };

    return this._http
      .post(`${this.basePath}/workflows/select`,
        JSON.stringify(payload)).pipe(
      map((res: any) => res.data),
      catchError(this.handleError),);
  }

  /**
   * Returns the initial configuration for a workflow to bootstraps an
   * initial process context.
   *
   * @param workflow_slug
   * @param revision
   * @returns {Observable<any | any>}
   */
  getInitialConfig(workflow_slug, revision, parentId?): Observable<ProcessContext> {
    console.error('getInitialConfig', workflow_slug, revision, parentId)
    let select = new HttpParams()
      .set('workflow', workflow_slug)
      .set('revision', revision);

    if (parentId) {
      select = select.set('parent_id', parentId);
    }

    return this._http
      .get(`${this.basePath}/processes/new?workflow=${workflow_slug}&revision=${revision}&parent_id=${parentId}`).pipe(
      map((res: any) => this._contextBuilder.fromResponse(res.data) as ProcessContext),
      catchError(this.handleError),);
  }

  getProcess(id): Observable<ProcessContext> {
    return this._http
      .get(`${this.basePath}/processes/${id}`).pipe(
      map((res: any) => this._contextBuilder.fromResponse(res.data) as ProcessContext),
      catchError(this.handleError),);
  }

  getByParentId(parentId, env = false) {
    const envParam = env ? '&environment=true' : '';
    return this._http
      .get(`${this.basePath}/processes?parent_id=${parentId}${envParam}`).pipe(
      map((res: any) => res.data.map(elem => this._contextBuilder.fromResponse(elem))),
      catchError(this.handleError),);
  }

  getByProcessName(processName) {
    const search = new HttpParams();
    search.set('workflow', processName);
    return this._http
      .get(`${this.basePath}/processes`, { params: search }).pipe(
      map((res: any) => res.data.map(elem => this._contextBuilder.fromResponse(elem))),
      catchError(this.handleError),);
  }

  getAll(search?: HttpParams) {
    return this._http.get(`${this.basePath}/processes`, {params: search}).pipe(
      map((res: any) => {
        const data = {};
        data['total'] = res.meta.records;
        data['processes'] = res.data.map(item => {
          return this._contextBuilder.fromResponse(item);
        });
        return data;
      }),
      catchError(this.handleError),);
  }

  create(processContext): Observable<ProcessContext> {
    const contextAsJSON = processContext.toJSON();
    const payload = {
      data: {
        type: 'workflow_engine_processes',
        attributes: {
          process_type: contextAsJSON.workflow,
          revision: contextAsJSON.revision,
          parent_id: contextAsJSON.parentId,

          draft: contextAsJSON.values.draft || false,
          values: contextAsJSON.values
        }
      }
    };

    return this._http
      .post(`${this.basePath}/processes`, JSON.stringify(payload)).pipe(
      map((res: any) => this._contextBuilder.fromResponse(res.data) as ProcessContext),
      catchError(this.handleError),);
  }

  update(id, processContext): Observable<ProcessContext> {
    const contextAsJSON = processContext.toJSON();
    const payload = {
      data: {
        type: 'workflow_engine_processes',
        attributes: {
          process_type: contextAsJSON.workflow,
          revision: contextAsJSON.revision,
          parent_id: contextAsJSON.parentId,

          draft: contextAsJSON.values.draft || false,
          values: Object.assign({}, contextAsJSON.values, {assignee: processContext.assignee})
        }
      }
    };

    return this._http
      .put(`${this.basePath}/processes/${id}`, JSON.stringify(payload)).pipe(
      map((res: any) => this._contextBuilder.fromResponse(res.data) as ProcessContext),
      catchError(this.handleError),);
  }

  getTasks(search?: HttpParams) {
    return this._http.get(`${this.basePath}/tasks`, {params: search}).pipe(
      map((res: any) => res.data.map(item => this._contextBuilder.fromResponse(item))),
      catchError(this.handleError),);
  }

  getStats() {
    return this._http
      .get(`${this.basePath}/processes/stats`).pipe(
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}
