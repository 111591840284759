<form [formGroup]="createLabelForm" class="labels-creation-form px-3 py-1" (keydown.tab)="$event.stopPropagation()"
      (keydown.space)="$event.stopPropagation()">
  <mat-form-field>
    <input matInput [placeholder]="'GENERAL.TITLE' | translate" [type]="'text'" formControlName="title">
    <mat-error
      *ngIf="createLabelForm.get('title').errors?.existed">{{ 'LABELS.LABEL_ALREADY_EXISTS_ERROR' | translate}}</mat-error>
    <input type="color" class="d-none" formControlName="color" #colorInput/>
    <div matSuffix class="d-flex">
      <dvtx-color-picker-dropdown (onSelection)="createLabelChangeColor($event)"
                                  [selected]="createLabelForm.value.color"
                                  [xPosition]="'before'"></dvtx-color-picker-dropdown>
      <dvtx-icon-picker-dropdown *ngIf="scope === LabelScopeRef.ORGANIZATIONAL"
                                 (onSelection)="createLabelChangeIcon($event)" [selected]="createLabelForm.value.icon"
                                 [xPosition]="'before'"></dvtx-icon-picker-dropdown>
    </div>
  </mat-form-field>
  <textarea style="min-height: 328px;" class="mb-2 d-block" [placeholder]="'GENERAL.DESCRIPTION' | translate" formControlName="description"></textarea>
  <!-- <dvtx-wysiwyg-editor-cva formControlName="description" [height]="160" [heightMax]="160" [options]="descriptionFieldOptions" [placeholder]="'GENERAL.DESCRIPTION' | translate" class="mb-2 d-block">
  </dvtx-wysiwyg-editor-cva> -->
  <!-- <mat-form-field>
    <textarea matInput placeholder="Description" formControlName="description"></textarea>
  </mat-form-field> -->

  <div class="d-flex align-items-center justify-content-end">
    <button type="button" mat-button (click)="onCancelCreation.emit()" class="mr-1">
      {{ 'GENERAL.CANCEL_ACTION' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)='submitCreateLabel()' [disabled]="createLabelForm.invalid || createLabelForm.get('title').value.trim().length === 0">
      {{'GENERAL.SAVE_ACTION' | translate}}
    </button>
  </div>
</form>
